import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/core/shared.module'
import { HelpDeskRoutingModule } from 'src/app/modules/help-desk/help-desk-routing.module';
import { ViewTicketInfoComponent } from './view-ticket/view-ticket-info/view-ticket-info.component';
import { ListTicketComponent } from './list-ticket/list-ticket/list-ticket.component';
import { ItemTicketComponent } from './list-ticket/item-ticket/item-ticket.component';

@NgModule({
  declarations: [ViewTicketInfoComponent, ListTicketComponent, ItemTicketComponent],
  imports: [SharedModule.forRoot(), HelpDeskRoutingModule]
})
export class HelpDeskModule { }
