import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Scenario, ScenarioStatus } from 'src/app/core/interfaces/scenario';
import { RoutingService } from '../../routing.service';
import { SCENARIO_STATUS } from 'src/app/core/enums/scenarioStatus.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { Permission } from 'src/app/core/resources/permission';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scenario-list',
  templateUrl: './scenario-list.component.html',
  styleUrls: ['./scenario-list.component.scss']
})
export class ScenarioListComponent implements OnInit {
  listScenarios: Scenario[] = [];
  dateControl: FormControl = new FormControl(new Date());
  permission = Permission;
  pageKey: number = 1;
  pageSize: number = 25;
  scrollDistance: number = 1;
  throttle: number = 300;
  enablePagingScroll: boolean = true;

  constructor(
    private routingService: RoutingService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    private router: Router,
  ) { }

  /**
  * @description executes the "getScenarios" method
  */
  ngOnInit() {
    this.getScenarios();
  }

  /**
  * @description Gets the scenario from a date and the respective pagination
  */
  private getScenarios() {
    this.spinner.show();
    this.routingService.getScenariosByDate(this.dateControl.value, this.pageKey, this.pageSize).toPromise()
      .then((success: Scenario[]) => {
        if (!this.utils.isDefined(success) || success.length < 1) {
          this.enablePagingScroll = false;
        } else {
          if (this.listScenarios && this.listScenarios.length) {
            this.listScenarios = this.listScenarios.concat(success);
          } else {
            this.listScenarios = success;
          }
          if (success.length < this.pageSize) {
            this.enablePagingScroll = false;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  /**
  * @description Navigates to routing creation
  */
  goToCreate() {
    this.router.navigate(['routing/create-route']);
  }

  /**
  * @description Updates the pagination variables and refreshes the scenarios
  */
  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getScenarios();
    }
  }

  /**
  * @description Cleans the current scenarios and refreshes the list
  */
  onChangeDatePicker() {
    this.pageKey = 1;
    this.enablePagingScroll = true;
    this.listScenarios = [];
    this.getScenarios();
  }
}
