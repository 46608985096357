import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../resources/utils';
import { UserState } from '../enums/user-state.enum';

@Pipe({
    name: 'StateUser'
})
export class StateUserPipe implements PipeTransform {

    constructor(
        public utils: Utils
    ) { }

    transform(state: string): string {
        if (!this.utils.isEmpty(state)) {
            let newState = state;
            switch (state) {
                case UserState.WRONG_DRIVER_DATA:
                    newState = UserState.WRONG_DRIVER_DATA;
                    break;
                case UserState.PENDING:
                    newState = 'Estudio de seguridad en proceso';
                    break;
                case UserState.PENDING_VALIDATE_DOCUMENTS:
                    newState = 'Pendiente validar documentos';
                    break;
                case UserState.TRUORA_WRONG_DRIVER_DATA:
                    newState = UserState.TRUORA_WRONG_DRIVER_DATA;
                    break;
                case UserState.INACTIVE:
                    newState = 'Inactivo';
                    break;
                case UserState.ACTIVE:
                    newState = 'Activo';
                    break;
                case UserState.DRIVER_LICENCE_NOT_ACTIVE:
                    newState = UserState.DRIVER_LICENCE_NOT_ACTIVE;
                    break;
                case UserState.DRIVER_LICENCE_ACTIVE:
                    newState = UserState.DRIVER_LICENCE_ACTIVE;
                    break;
                case UserState.ACTIVATE_BY_SCORE:
                    newState = UserState.ACTIVATE_BY_SCORE;
                    break;
                case UserState.WITHOUT_VALIDATION:
                    newState = 'Activo';
                    break;
            }
            return newState;
        }
        return state;
    }

}
