import { Validators } from "@angular/forms"
import { Patterns } from "../resources/patterns"
import { CreateDiscountsAndBonuses } from "./createDiscountAndBonuses";

export interface RateCostAdditionalServices {
    amountRate?: any;
    amount?: number;
    type?: any;
    typeModification?: CreateDiscountsAndBonuses;
    observation?: string;
    userId?: string;
    userName?: string;
    cargoId?: string;
    date?: string;
    adittionalServiceId?: string;

}

export class RateCostAdditionalServicesModel {

    public model: RateCostAdditionalServices = {
        type: 0,
        amountRate: 0,
        amount: 0,
        userId: '',
        userName: '',
        cargoId: '',
        observation: '',
        typeModification: {
            id: '',
            name: '',
            type: ''
        }
    };

    public modelValidators = {
        rate: [
            Validators.pattern(this.patterns.ONLY_NUMBERS.source)
        ],
        cost: [
            Validators.pattern(this.patterns.ONLY_NUMBERS.source)
        ],
        userId: [
          Validators.required
        ],
        userName: [
          Validators.required
        ],
        cargoId: [
          Validators.required
        ],
        typeModification: {
            name: [
                Validators.required
            ]
        }
    }

    constructor(
        private patterns: Patterns
    ) {

    }
}
