

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgotPassword.component';
import { AccountComponent } from './account.component';
import { ValidateTermsComponent } from './validate-terms/validate-terms.component';
import { LoginGuard } from 'src/app/core/guards/login.guard';

const routes: Routes = [
    {
        path: 'validate-terms',
        component: ValidateTermsComponent,
        canActivate: [],
        resolve: {
            UserResolver
        },
    }, {
        path: '',
        component: AccountComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'register',
                component: RegisterComponent,
                canActivate: [LoginGuard]
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
                canActivate: [LoginGuard]
            },

            { path: '**', redirectTo: '/account/login' }
        ],
    },
    { path: '**', redirectTo: 'account/login' }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AccountRoutingModule { }
