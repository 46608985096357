export class File {
    key: string;
    nameFile: string;
    url: string;
    file: File;
    percentage: number;

    contructor(file: File) {
        this.file = file;
    }
}