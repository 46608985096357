import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { CargoListPaymentsComponent } from '../cargo-list-payments/cargo-list-payments.component';
import { MatTabGroup } from '@angular/material';

@Component({
  selector: 'app-cargo-payments',
  templateUrl: './cargo-payments.component.html',
  styleUrls: ['./cargo-payments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CargoPaymentsComponent implements OnInit {

  @ViewChildren(CargoListPaymentsComponent) cargoListPayments: QueryList<CargoListPaymentsComponent>;
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  activeTab: string = 'Anticipos';
  paymentTabs = {
    paymentAdvanceCargo: 'Anticipos',
    paymentExtraAdvanceCargo: 'Sobre Anticipos',
    paymentAdditionalCostsCargo: 'Servicios Adicionales',
    paymentBalanceCargo: 'Saldos'
  }
  constructor() {


  }

  ngOnInit() {
    sessionStorage.setItem('_lastList', 'paymentCargo');
    if (sessionStorage.getItem("_activeTab")) {
      let storageTab = sessionStorage.getItem("_activeTab");
      if (storageTab && this.paymentTabs[storageTab]) {
        let index = Object.keys(this.paymentTabs).indexOf(storageTab);
        if (index !== -1) this.tabGroup.selectedIndex = index;
        this.activeTab = this.paymentTabs[storageTab];
      }
    }

  }
  asIsOrder(a, b) {
    return 1;
  }

  onSelectTab($event: number) {
    const selectedIndex = $event;
    this.activeTab = this.tabGroup && this.tabGroup._tabs && this.tabGroup._tabs.toArray()[selectedIndex] && this.tabGroup._tabs.toArray()[selectedIndex].textLabel ? this.tabGroup._tabs.toArray()[selectedIndex].textLabel : '';
  }

}
