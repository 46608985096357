import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectCompanyService } from 'src/app/shared/select-company/select-company.service';
import { Company } from '../interfaces/company';
import { AuthService } from '../services/authentication.service';

@Pipe({
    name: 'companyName'
})
export class CompanyNamePipe implements PipeTransform {

    constructor(
        private authService: AuthService,
        private selectCompanyService: SelectCompanyService
    ) { }
    transform(nit, type?) {
        const companies = this.authService.getCompanies();
        if (companies && companies.length) {
            const companyActive = companies.filter((company) => {
                return company.companyId === nit;
            });
            if (companyActive.length) {
                if (type === 'full') {
                    return companyActive[0];
                } else {
                    return companyActive[0].name;
                }
            }
        }
        return nit;
    }

}
