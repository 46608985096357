import { Component, OnInit, EventEmitter, Input } from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import { Global } from "src/app/core/resources/global";
import { ManualCreationCargoService } from "src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service";
import { Router } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { Fmt } from "src/app/core/messages/fmt";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { Utils } from "src/app/core/resources/utils";

@Component({
  selector: "app-type-regime",
  templateUrl: "./typeRegime.component.html",
  styleUrls: ["./typeRegime.component.scss"],
  providers: [AuthService, Global],
})
export class TypeRegimeComponent implements OnInit {
  typeRegimen: string = "";
  dateFieldDTA: FormControl = new FormControl();
  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    private snackBarService: SnackBarService,
    private dateFormatPipe: DateFormatPipe,
    public utils: Utils
  ) { }

  ngOnInit() {
    const dateTripType = this.manualCreationCargoService.cargoForm.controls.cargoModel["controls"].tripType;
    if (dateTripType && dateTripType.controls.date && dateTripType.controls.date.value && (dateTripType.controls.regimeType.value === "DUTA" || dateTripType.controls.regimeType.value === "OTM")) {
      this.dateFieldDTA.setValue(new Date(dateTripType.controls.date.value));
    }
  }

  public selectRegimeType(name: string) {
    const tripType = this.manualCreationCargoService.cargoForm.controls.cargoModel[
      "controls"].tripType.controls;
    this.typeRegimen = name;
    if (name === "DUTA" || name === 'OTM') {
      this.dateFieldDTA.setValidators(Validators.required);
      this.dateFieldDTA.updateValueAndValidity();
      this.dateFieldDTA.markAsUntouched();
    } else {
      this.dateFieldDTA.setValue('');
      this.dateFieldDTA.clearValidators();
      this.dateFieldDTA.updateValueAndValidity();
      if (tripType.date && tripType.date.value) {
        tripType.date.setValue('');
      }
    }
    this.manualCreationCargoService.cargoForm.controls.cargoModel[
      "controls"
    ].tripType.controls.regimeType.setValue(name);
  }

  get dtaAlert(): boolean {
    const dateTripType = this.manualCreationCargoService.cargoForm.controls.cargoModel["controls"].tripType;
    return dateTripType && dateTripType.get('regimeType') && dateTripType.get('regimeType').value &&
      ['OTM', 'DUTA'].includes(dateTripType.get('regimeType').value) && (!dateTripType.get('date') || !dateTripType.get('date').value);
  }

  onDateSelected(event: string) {
    const dateFormatted = this.dateFormatPipe.transform(
      this.dateFieldDTA.value,
      "date-time"
    );
    this.manualCreationCargoService.cargoForm.controls.cargoModel[
      "controls"
    ].tripType.controls.date.setValue(dateFormatted);
  }

  nextStep() {
    // Tipo de carga
    this.dateFieldDTA.markAsTouched();
    if (this.manualCreationCargoService.isValidRegimeType()) {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(3);
      this.router.navigate([
        this.manualCreationCargoService.steps.typeCargo.url,
      ]);
    } else {
      let tripType = this.manualCreationCargoService.cargoForm.get('cargoModel.tripType').value;
      if (!tripType.name || !tripType.regimeType) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'el tipo de régimen'), undefined, 'alert');
      }
      else if (this.utils.errorMessagesCustomized(this.dateFieldDTA, 'fecha de descargue')) return;
      else this.manualCreationCargoService.showMessageMissingFields();
    }
  }

  stepBack() {
    const tripTypeName = this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.name.value;
    if (tripTypeName === 'Exportación') {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(6);
      this.router.navigate([this.manualCreationCargoService.steps.tripTypeDocuments.url]);
    }
    else {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(1);
      this.router.navigate([this.manualCreationCargoService.steps.typeTrip.url]);
    }
  }
}
