<aside id="mainCollectionPointList" class="col-md-12 mb-0 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-10">
          <!-- <label class="title-component">
            <i class="fas fa-truck-pickup"></i>
            Vehículos
          </label> -->
        </div>
        <div class="col-md-2 header-list-options text-right">
          <button matTooltip="Crear Vehículo" permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.createVehicle" mat-raised-button type="button"
            class="button-width-auto" (click)="goToCreate()">
            <i class="fas fa-plus-circle font-primary-dark"></i>
            <span class="font-primary-dark">Crear</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container-list-filter">
      <div class="row">
        <div class="col-md-3 filter--company">
          <app-select-company [options]="optionsCompany" [inputFormControl]="companyNitCtrl">
          </app-select-company>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Placa</mat-label>
            <input type="text" appLicensePlate matInput [(ngModel)]="filterlicensePlate">
          </mat-form-field>
        </div>
        <div class="col-md-2" permission-display [module]="permission.administration.module"
          [functionality]="permission.administration.downloadFileRegistreBank">
          <mat-form-field>
            <mat-label>Cuentas Bancarias</mat-label>
            <mat-select [(ngModel)]="stateAccountBanck">
              <mat-option *ngFor="let state of listStateAccountBank" value="{{state.value}}">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Estado</mat-label>
            <mat-select [(ngModel)]="vehicleState">
              <mat-option value="true">
                Activo
              </mat-option>
              <mat-option value="false">
                Inactivo
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3 offset-md-0 align-right">
          <mat-card-actions>
            <button mat-raised-button permission-display [module]="permission.administration.module"
              [functionality]="permission.administration.downloadFileRegistreBank" class="primary-secondary1"
              color="primary-secondary1" title="Inscribir Cuentas de Anticipo"
              (click)="getlistBankAccount(stateAccount === '6' ? 'balance' : 'advance')" *ngIf="itemsSelecteds?.length">
              <i class="fas fa-check"></i>
            </button>
            <button mat-raised-button class="primary-secondary" color="primary" (click)="cleanFilter()"
              title="Eliminar Filtros">
              <i class="far fa-trash-alt"></i>
            </button>
            <button mat-raised-button class="btn-filter" color="primary" (click)="applyFilter()"
              title="Aplicar Filtros">
              <i class="fas fa-search"></i>
            </button>
          </mat-card-actions>
        </div>
      </div>
    </div>
    <div class="container-list-body" infinite-scroll
      [infiniteScrollDistance]="service.paginationList.getScrollDistance()"
      [infiniteScrollThrottle]="service.paginationList.getThrottle()" [scrollWindow]="false"
      (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let vehicle of service.paginationList.getList(); let i = index;">
        <app-item-vehicle [vehicle]="vehicle" [stateAccount]="stateAccount" select-item-list
          [active]="service.getActiveItem(stateAccount, vehicle)" [item]="vehicle"
          [list]="service.paginationList.getList()" (onSelectItem)="onSelectItem($event)">
        </app-item-vehicle>
      </div>
    </div>
    <div class="my-1 d-flex justify-content-start">
      <div class="conv-btn" (click)="openConventions()">
        <i class="fas fa-circle"></i> Convenciones
      </div>
    </div>
  </div>
</aside>