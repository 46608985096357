<div>
  <div class="col-md-12 container-terms">
    <div class="row">
      <div class="col-md-3">
        <label class="label-field title-purple">Fecha de publicación</label>
        <br>
        <span>{{terms?.date?( terms.date | dateFormat:'only-date'):terms?.fingerprint?.date ? ( terms.fingerprint.date |
          dateFormat:'only-date'): ''}}</span>
      </div>
      <div class="col-md-3">
        <label class="label-field title-purple">Versión</label>
        <br>
        <span>{{terms?.version}}</span>
      </div>
      <div class="col-md-3">
        <label class="label-field title-purple">Estado</label>
        <br>
        <span>{{terms?.state ? 'Vigentes' : 'Caducados'}}</span>
      </div>
      <div class="col-md-3 text-right underline detail" (click)="openTerms()">
        Detalle
      </div>
    </div>
  </div>
</div>


<!-- Modal Confirm Delete Collection Point -->
<ng-template #modalTemrs let-modal>
  <div class="modal-body wrapper-modal position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon" (click)="closeTerms()"></i>
    <p class="title text-strong" *ngIf="terms">
      {{title}} {{terms.version}} {{terms?.date?( terms.date | dateFormat:'only-date'):terms?.fingerprint?.date ? (
      terms.fingerprint.date | dateFormat:'only-date'): ''}}
    </p>
    <div [innerHTML]="terms.html" class="container-html-terms">
    </div>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="downloadTerms()">Descargar <span
          class="d-none d-md-inline">{{title}}</span></button>
    </mat-card-actions>
  </div>
</ng-template>