import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';

@Component({
  selector: 'app-calculator-measurements',
  templateUrl: './calculatorMeasurements.component.html',
  styleUrls: ['./calculatorMeasurements.component.scss'],
  providers: [AuthService, Global]
})
export class CalculatorMeasurementsComponent implements OnInit {

  calculatorForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CalculatorMeasurementsComponent>,
    private formBuilder: FormBuilder,
    public utils: Utils,
    private snackbarService: SnackBarService
  ) { }

  ngOnInit() {
    this.calculatorForm = this.formBuilder.group({
      quantityUnits: [1, [Validators.required, Validators.min(1)]],
      tall: ['', [Validators.required, Validators.min(0.01)]],
      width: ['', [Validators.required, Validators.min(0.01)]],
      depth: ['', [Validators.required, Validators.min(0.01)]],
      unit: ['m']
    });
  }

  calculate(): void {
    this.calculatorForm.markAllAsTouched();
    if (this.calculatorForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.calculatorForm.get('tall'), 'altura', null, null, 0.01)) return;
      if (this.utils.errorMessagesCustomized(this.calculatorForm.get('width'), 'anchura', null, null, 0.01)) return;
      if (this.utils.errorMessagesCustomized(this.calculatorForm.get('depth'), 'profundidad', null, null, 0.01)) return;
      if (this.utils.errorMessagesCustomized(this.calculatorForm.get('quantityUnits'), 'cantidad de cajas', null, null, 1)) return;
      else this.snackbarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    let quantityUnits = this.calculatorForm.value.quantityUnits;
    let tall = this.calculatorForm.value.tall;
    let width = this.calculatorForm.value.width;
    let depth = this.calculatorForm.value.depth;
    let unit = this.calculatorForm.value.unit;

    let totalSize = 0;

    if (unit === 'cm') {
      tall = (tall / 100);
      width = (width / 100);
      depth = (depth / 100);
    }

    totalSize = (tall * width * depth) * quantityUnits;
    this.dialogRef.close(totalSize);
  }

}
