import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ListHistory, ListHistoryResponse } from 'src/app/core/interfaces/list-history';

@Component({
  selector: 'app-history-and-metrics-company',
  templateUrl: './history-and-metrics-company.component.html',
  styleUrls: ['./history-and-metrics-company.component.scss']
})
export class HistoryAndMetricsCompanyComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSizeOptions: number[] = [2, 5, 10, 25]; // Opciones de tamaño de página
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  listHistorySlice = [];
  startIndex: number = 0;
  endIndex: number = 2;
  @Input() companyId: string;
  listHistory = [];
  totalHistory = {};
  typeHistoryFilter: string = '';
  resetPagination: boolean;
  constructor(
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private companiesService: CompaniesService
  ) { }
  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'ítems por página';
  }

  getListHistory(listHistoryParams: ListHistory) {
    if (!listHistoryParams.type || !listHistoryParams.type.length) {
      this.listHistory = [];
      this.listHistorySlice = [];
      this.totalHistory = {};
      return;
    }
    listHistoryParams.companyId = this.companyId;
    this.pageIndex = 0;
    const observerHistoryCompanies = {
      next: (success: ListHistoryResponse) => {
        this.spinner.hide();
        this.startIndex = 0;
        this.endIndex = this.startIndex + this.pageSize;
        if (success && success.results && success.pagination && success.pagination.total && success.results.length) {
          document.getElementById('scrollContainer').scrollTop = 0;
          this.listHistory = success.results;
          listHistoryParams.type.forEach(report => {
            this.totalHistory[report] = this.listHistory.reduce((sum, history) => {
              if (history && history.serviceType === report) return sum + 1;
              return sum;
            }, 0);
          });
          this.listHistorySlice = this.listHistory.slice(this.startIndex, this.endIndex);
        }
        else {
          this.totalHistory = {};
          this.listHistory = [];
          this.listHistorySlice = [];
        }
      },
      error: () => {
        this.spinner.hide();
        this.listHistory = [];
        this.listHistorySlice = [];
        this.totalHistory = {};
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.companiesService.getHistoryCompanies(listHistoryParams.companyId, listHistoryParams.type, listHistoryParams.startDate, listHistoryParams.endDate).subscribe(observerHistoryCompanies)
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event && (event.pageIndex || event.pageIndex === 0) ? event.pageIndex : this.pageIndex;
    this.pageSize = event && event.pageSize ? event.pageSize : this.pageSize;
    this.startIndex = this.pageIndex * this.pageSize;
    this.endIndex = this.startIndex + this.pageSize;
    if (this.endIndex > this.listHistory.length) this.endIndex = this.listHistory.length;
    this.listHistorySlice = this.listHistory.slice(this.startIndex, this.endIndex);
  }
}
