<div class="row-point-item">
  <div class="row container-date">
    <!-- Route id  -->
    <div class="col-md-6">
      <span class="title-purple" matTooltip="{{point.routeId}}">
        ID de ruta: {{point.routeId}}
      </span>
    </div>
    <!-- Schedule -->
    <div class="col-md-6">
      <span class="title-purple" matTooltip="{{point.schedule}}">
        {{point.schedule}}
      </span>
    </div>
  </div>

  <div class="row container-data">
    <!-- Farm name -->
    <div class="col-md-4">
      <div class="label">
        Finca
      </div>
      <div class="text-bold label-field">
        <span matTooltip="{{point.farmName}}">
          {{point.farmName}}
        </span>
      </div>
    </div>
    <!-- Type -->
    <div class="col-md-2">
      <div class="label">
        Tipo
      </div>
      <div class="text-bold label-field">
        <span matTooltip="{{point.type}}">
          {{point.type}}
        </span>
      </div>
    </div>
    <!-- Producer -->
    <div class="col-md-6">
      <div class="label">
        Productor
      </div>
      <div class="text-bold label-field">
        <span matTooltip="{{point.producer}}">
          {{point.producer}}
        </span>
      </div>
    </div>
    <!-- Button detail -->
    <figure class="icon-arrow-container" (click)="selectCollectionPoint()">
      <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
    </figure>
    <!-- Button remove  -->
    <figure class="icon-delete" (click)="openModalConfirmDeletePoint()" permission-display
      [module]="permission.point.module" [functionality]="permission.point.removePoint">
      <i class="fas fa-trash-alt"></i>
    </figure>
  </div>
</div>

<!-- Modal Confirm Delete Collection Point -->
<ng-template #modalConfirmDeletePoint let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title">
      ¿Estás seguro que deseas <br> eliminar este punto?
    </p>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary"
        (click)="hideModalConfirmDeletePoint()">No</button>
      <button mat-raised-button color="primary" (click)="deleteCollectionPoint()">Si</button>
    </mat-card-actions>
  </div>
</ng-template>