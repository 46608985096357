import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/core/resources/global';
import { AuthService } from 'src/app/core/services/authentication.service';
import { version } from 'package.json';
import { Utils } from 'src/app/core/resources/utils';
import { Page } from 'src/app/core/interfaces/page';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version: string
  public urlDefaultLogo = this.utils.getDefaultUrlLogo('white');
  public isTeclogi = location.hostname.search('teclogi') >= 0;
  public generalLegalPages: Page[] = [
    {
      title: 'Términos y condiciones',
      path: '/terms',
    },
    {
      title: 'Políticas de privacidad',
      path: '/privacy-policy',
    },
    {
      title: 'Políticas para tratamiento de datos personales',
      path: '/personal-data-policies',
    },
  ];
  public legalPages: Page[] = [
    {
      title: 'HABEAS DATA',
      path: '/habeas-data',
    },
    {
      title: 'Políticas Empresariales',
      path: '/basc-policies',
    },
    {
      title: 'Anexo Manifiesto de Carga',
      path: '/terms-manifest',
    },
    {
      title: 'Pronto Pago',
      path: '/quick-pay',
    },
    {
      title: 'Contáctanos',
      path: '/contact',
    },
  ];

  constructor(
    public authService: AuthService,
    private router: Router,
    public global: Global,
    private utils: Utils
  ) {
  }

  ngOnInit() {
    this.version = version;

  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

}
