import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToPayLoads } from 'src/app/core/interfaces/toPayLoads';
@Component({
  selector: 'app-massive-payments',
  templateUrl: './massive-payments.component.html',
  styleUrls: ['./massive-payments.component.scss']
})
export class MassivePaymentsComponent implements OnInit {
  massivePaymentsInput: FormControl = new FormControl('', Validators.required);
  massivePaymentsList: Array<string> = [];
  pageKey: number = 1;
  pageSize: number = 25;
  massiveAvailableLoadsList: Cargo[] = [];
  massiveAvailableLoadsListShow: Cargo[] = [];
  massiveUnavaliableLoadsList: Cargo[] = [];
  massiveUnavaliableLoadsListShow: Cargo[] = [];
  massiveNonExistentLoadsList: number[] = [];
  massiveNonExistentLoadsListShow: number[] = [];
  loadsSelectedList: Cargo[] = [];
  activeTab: number = 0;
  typeList: string = sessionStorage.getItem('_activeTab');
  constructor(
    public dialogRef: MatDialogRef<MassivePaymentsComponent>,
    public utils: Utils,
    public snackbarService: SnackBarService,
    public toPayService: FreightListService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
  }

  processMassivePayments() {
    if (this.massivePaymentsInput.value && this.massivePaymentsInput.valid) {
      if (this.massivePaymentsInput.value.endsWith(',')) this.massivePaymentsInput.setValue(this.massivePaymentsInput.value.slice(0, -1));
      this.massivePaymentsList = this.massivePaymentsInput.value.split(",");
      this.processLoads();
    } else {
      this.massivePaymentsList = [];
      this.snackbarService.openSnackBar(FormMessages.GENERAL_ERROR_FIELD, undefined, 'alert');
    }

  }
  onSelectTab($event: number) {
    this.activeTab = $event;
  }

  getLoad(consecutive: number) {
    const massivePaymentsObserver = {
      next: (load: ToPayLoads[]) => {
        this.spinner.hide();
        if (load) {
          if (!this.utils.isEmpty(load[0].validatedBankAccount)) load[0].cargo.validatedBankAccount = load[0].validatedBankAccount;
          if (this.getIsPendingApproveBankAccount(load[0].cargo)) this.massiveUnavaliableLoadsList.push(load[0].cargo);
          else this.massiveAvailableLoadsList.push(load[0].cargo);
        }
        else this.massiveNonExistentLoadsList.push(consecutive);
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.toPayService.getListCargoToPay(`consecutive=${consecutive}${sessionStorage.getItem('_filterListStatic')}`, this.pageKey, this.pageSize).subscribe(massivePaymentsObserver);
  }

  processLoads($event?: boolean) {
    this.massiveAvailableLoadsList = [];
    this.massiveUnavaliableLoadsList = [];
    this.massiveAvailableLoadsListShow = [];
    this.massiveNonExistentLoadsList = [];
    this.massiveUnavaliableLoadsListShow = [];
    this.massiveNonExistentLoadsListShow = [];
    this.massivePaymentsList.forEach(consecutive => {
      this.getLoad(Number(consecutive))
    });
    this.massiveNonExistentLoadsListShow = this.massiveNonExistentLoadsList;
    this.massiveUnavaliableLoadsListShow = this.massiveUnavaliableLoadsList;
    this.massiveAvailableLoadsListShow = this.massiveAvailableLoadsList;
  }
  //GETTERS
  getLoadLabel(label: string, numberOfLoads: number) {
    return `${label} (${numberOfLoads})`;
  }

  getIsPendingApproveBankAccount(cargo: Cargo) {
    return (
      this.toPayService.isPaymentCargo(this.typeList) &&
      ((this.utils.isDefined(cargo.validatedBankAccount) &&
        !cargo.validatedBankAccount) ||
        (!this.utils.isDefined(cargo.validatedBankAccount) &&
          cargo.shippingCost &&
          !cargo.shippingCost.paid))
    );
  }

  getNonExistentLoadsList(list: number[]) {
    return list.join(', ');
  }

  loadsSelected($event: Cargo[]) {
    this.loadsSelectedList = [];
    this.loadsSelectedList = $event;
  }

  payMassivePayments() {
    this.dialogRef.close(this.loadsSelectedList);
  }


}
