import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from 'src/app/core/resources/endpoints';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    private endpointResources: Endpoints,
    private http: HttpClient
  ) { }

  getLogs() {
    return this.http.get(
      environment.urlServerTeclogi + this.endpointResources.logs
    );
  }
}
