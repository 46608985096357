import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MapsEnum } from 'src/app/core/enums/maps.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Global } from 'src/app/core/resources/global';
import { INCIDENT_ASSETS } from 'src/app/core/resources/incident_assets';
import { Utils } from 'src/app/core/resources/utils';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { v4 as uuidv4 } from 'uuid';

const GEOFENCE_SIZE = 100;
const GEOFENCE_MAX_SIZE = 100000;

@Component({
  selector: 'app-routes-map',
  templateUrl: './routes-map.component.html',
  styleUrls: ['./routes-map.component.scss']
})
export class RoutesMapComponent implements AfterViewInit, OnChanges {
  // Constants
  private readonly EARTH_RADIUS = 6251800;
  private readonly RADIANS_FACTOR = Math.PI / 180;
  private readonly MIN_DISTANCE_OPTIMIZATOR = 200;

  // Properties
  @Input('cargo') cargo: Cargo = null;
  @Input('route') route: any[] = [];
  @Input('show-options') showOptions: boolean = true;
  @Input('showRouteGoogle') showRouteGoogle: boolean = false;
  @Input('route-google') routeGoogle: any[] = [];

  // Events
  @Output() clickMap: EventEmitter<any> = new EventEmitter();
  @Output() load: EventEmitter<any> = new EventEmitter();
  public showMarkers = new FormControl(false);
  showMarkersSub: Subscription;
  public showGeofences = new FormControl(false);
  showGeofencesSub: Subscription;
  public directionsRenders: google.maps.DirectionsRenderer[] = [];

  private uuid: string;
  private polyline_references = {
    real: null,
    google: null
  }
  private marker_references = {
    origin: [],
    destination: [],
    vehicle: [],
    location: []
  }
  private geofence_references = [];
  public get mapId(): string { return this.uuid; };

  public options: google.maps.MapOptions;
  public map: google.maps.Map;
  constructor(
    public utils: Utils,
    private freightForwarderDetailService: CargoDetailService,
    private global: Global
  ) {
    this.uuid = uuidv4();
    this.showMarkersSub = this.showMarkers.valueChanges.subscribe((show) => show ? this.renderMarkers() : this.hideMarkers());
    this.showGeofencesSub = this.showGeofences.valueChanges.subscribe((show) => show ? this.renderGeofences() : this.hideGeofences());
  }

  ngAfterViewInit() {
    const initMap = () => {
      this.map = new google.maps.Map(document.getElementById(this.uuid), {
        center: { lat: 4.397, lng: -74.644 },
        zoom: 13
      });
      this.map.addListener('click', (e) => this.clickMap.emit(e));
      this.load.emit(this);
    }
    initMap();
  }

  ngOnChanges(changes) {
    if (!!changes['cargo'] && !!changes['cargo'].currentValue) {
      this.renderMainMarkers(this.cargo);
    }
    if (!!changes['route'] && !!changes['route'].currentValue) {
      const coordinates = changes['route'].currentValue;
      const path = coordinates && coordinates.length ? this.optimize(coordinates) : null;
      const lastPosition = coordinates ? coordinates.reverse()[0] : null;
      if (path && path.length) this.polyline_references.real = this.polyline(path, '#02d7dc');
      if (!!lastPosition) {
        this.removeAllMarkers('vehicle');
        this.marker(lastPosition.lat, lastPosition.lng, 'vehicle',
          `
          <b>Fecha:</b> ${lastPosition.fingerprint && lastPosition.fingerprint.date ? lastPosition.fingerprint.date.slice(0, 16) : '-'}<br/>
          <b>Usuario:</b> ${lastPosition.fingerprint && lastPosition.fingerprint.userName ? lastPosition.fingerprint.userName : '-'}<br/>
          <b>Posición:</b> (${this.utils.isDefined(lastPosition.lat) && this.utils.isDefined(lastPosition.lng) ? lastPosition.lat + ', ' + lastPosition.lng : '-'})<br/>
          ${lastPosition && lastPosition.observation ? `<b>Observación:</b> ${lastPosition.observation}` : ''}
          ${!!lastPosition ? `<br/><b>Registro:</b> ${lastPosition.fromWeb ? 'Web' : 'Conductor'}<br/>` : ''}`
        );
      }
      const currentCenter = this.map.getCenter();
      if (currentCenter && currentCenter.lat() === 4.397 && currentCenter.lng() === -74.644 && path && path.length)
        this.map.setCenter(path.reverse()[0]); // centrar en el vh si no ha sido centrado antes
    }

    if (!!changes['showRouteGoogle']) {
      this.processRouteGoogle(!!changes['showRouteGoogle'].currentValue);
    }
  }

  private get mapHtml(): HTMLElement {
    return document.getElementById(this.mapId);
  }

  private generateStopDescription(stop) {
    const duration = stop.durationTime;
    let description = `
      <b>Dirección:</b> ${stop.address}
    `;

    if (!!duration && !!duration.startDate)
      description += `<br/><b>Inicio:</b> ${duration.startDate.slice(0, 16)}`;
    if (!!duration && !!duration.endDate)
      description += `<br/><b>Fin:</b> ${duration.endDate.slice(0, 16)}`;
    return description;
  }

  private renderMainMarkers(cargo: Cargo) {
    if (!cargo) return;
    const addresses = [];
    cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
      addresses.push(address);
    });
    for (const destination of cargo.cargoFeature.uploadDownload.destination) {
      destination.addresses.forEach((address) => {
        addresses.push(address);
      });
    }
    if (addresses.length > 1) {
      const first = addresses[0];
      const last = addresses[addresses.length - 1];

      if (!!first && !!first.location)
        this.marker(first.location.lat, first.location.lng, "origin", this.generateStopDescription(first));
      if (!!last && !!last.location)
        this.marker(last.location.lat, last.location.lng, "destination", this.generateStopDescription(last));
    }
  }

  private getIndicator(incident): string {
    for (const o of INCIDENT_ASSETS) {
      if (o.name === incident) {
        return `/assets/svg/icons/${o.asset}`;
      }
    }
    return undefined;
  }

  public polyline(path, strokeColor) {
    const polyline = new google.maps.Polyline(
      {
        path,
        strokeColor,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        geodesic: false,
      }
    );
    polyline.setMap(this.map);
    return polyline;
  }

  public marker(
    lat,
    lng,
    type: 'origin' | 'destination' | 'vehicle' | 'location',
    content = undefined,
    customIcon: string | google.maps.ReadonlyIcon | google.maps.ReadonlySymbol = undefined,
    label = undefined
  ) {
    const icon = (
      !!customIcon ? customIcon :
        type === 'destination' ? this.global.pathMarkerDestination :
          type === 'origin' ? this.global.pathMarkerOrigin :
            type === 'vehicle' ? this.global.pathMarkerVehicle : undefined
    );
    const options: google.maps.ReadonlyMarkerOptions = {
      icon: icon === `${icon}` ? { url: icon, scaledSize: new google.maps.Size(25, 25) } : icon,
      map: this.map,
      position: { lat, lng },
      label: label
    };
    const marker = new google.maps.Marker(options);
    if (!!content) {
      const infowindow = new google.maps.InfoWindow({ content });
      marker.addListener('click', () => {
        infowindow.open(this.map, marker);
      });
    }
    this.marker_references[type].push(marker);
  }

  processRouteGoogle(showRouteGoogle) {
    if (this.utils.isDefined(this.cargo) && showRouteGoogle) {
      this.freightForwarderDetailService.getRouteGoogleCargo(this.cargo.id).subscribe(
        (response) => {
          if (response && response['overview_polylines'] && response['overview_polylines'][0]) {
            this.routeGoogle = google.maps.geometry.encoding.decodePath(response['overview_polylines'][0]);
            this.removePolyline('google');
            this.polyline_references.google = this.polyline(this.routeGoogle, '#584796');
          }
        }
      );
    } else {
      this.routeGoogle = [];
      this.removePolyline('google');
    }
  }

  private removePolyline(poly: 'google' | 'real') {
    if (!!this.polyline_references[poly]) {
      this.polyline_references[poly].setMap(null);
    }
  }

  removeAllMarkers(type?: 'origin' | 'destination' | 'vehicle' | 'location') {
    if (!!type) {
      this.marker_references[type].forEach((marker) => {
        marker.setMap(null);
      });
    } else {
      ['origin', 'destination', 'vehicle', 'location'].forEach(
        type => {
          this.marker_references[type].forEach((marker) => {
            marker.setMap(null);
          });
        }
      )
    }

  }

  renderMarkers() {
    this.route.forEach(
      (point) => {
        if (!!point.name && this.showMarkers.value && point.name != 'Reiniciando ruta') {
          this.marker(point.lat, point.lng, "location",
            `
          <b>Novedad:</b> ${point.name}<br/>
          <b>Fecha:</b> ${point.fingerprint.date.slice(0, 16)}<br/>
          <b>Observación:</b> ${point.observation}<br/>
          <b>Responsable:</b> (${point.fingerprint.userId}) ${point.fingerprint.userName}<br/>
          <b>Origen:</b> ${point.fromWeb ? 'Web' : 'Conductor'}<br/>
          `, this.getIndicator(point.name));
        }
      });
  }

  hideMarkers() {
    this.marker_references.location.forEach(marker => { marker.setMap(null); });
    this.marker_references.location = []
  }

  renderGeofences() {
    const cargo = this.cargo;
    if (!cargo) return;
    const addresses = [];
    cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
      addresses.push(address);
    });
    for (const destination of cargo.cargoFeature.uploadDownload.destination) {
      destination.addresses.forEach((address) => {
        addresses.push(address);
      });
    }
    for (const stop of addresses) {
      const geofence = new google.maps.Circle({
        strokeColor: "#02d7dc",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#02d7dc",
        fillOpacity: 0.35,
        map: this.map,
        center: new google.maps.LatLng(stop.location.lat, stop.location.lng),
        radius: MapsEnum.GEOFENCE_STANDARD_SIZE,
      });
      this.geofence_references.push(geofence);
    }
  }

  hideGeofences() {
    this.geofence_references.forEach((geofence) => { geofence.setMap(null); });
  }

  private distance(A: any, B: any) {
    A = { lat: A.lat * this.RADIANS_FACTOR, lng: A.lng * this.RADIANS_FACTOR };
    B = { lat: B.lat * this.RADIANS_FACTOR, lng: B.lng * this.RADIANS_FACTOR };
    const dlng = B.lng - A.lng;
    const dlat = B.lat - A.lat;
    const a = Math.pow(Math.sin(dlat / 2.0), 2) + Math.cos(A.lat) * Math.cos(B.lat) * Math.pow(Math.sin(dlng / 2.0), 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return this.EARTH_RADIUS * c;
  }

  private summarize(locations) {
    const optimized = [];
    let lastPosition = { lat: 0, lng: 0 };
    for (let i = 0; i < locations.length - 1; i++) {
      const { lat, lng } = locations[i];
      if (this.distance({ lat, lng }, lastPosition) > this.MIN_DISTANCE_OPTIMIZATOR || !!locations[i]['forced']) {
        lastPosition = { lat, lng };
        optimized.push(lastPosition);
      }
    }
    return optimized;
  }

  public optimize(locations: any[]): any[] {
    const first = locations[0];
    const middle = locations.slice(1, locations.length - 1);
    const last = locations[locations.length - 1];
    return [first, ...this.summarize(middle), last];
  }

  public clearRoutes() {
    this.directionsRenders.forEach((render) => { render.setMap(null); })
    this.directionsRenders = [];
  }

  public printDirection(directions: google.maps.DirectionsResult, options?: google.maps.DirectionsRendererOptions) {
    const render = new google.maps.DirectionsRenderer();
    render.setMap(this.map);
    render.setDirections(directions);
    options ? render.setOptions(options) : undefined;
    this.directionsRenders.push(render);
    return render;
  }

  ngOnDestroy() {
    if (this.showMarkersSub) this.showMarkersSub.unsubscribe();
    if (this.showGeofencesSub) this.showGeofencesSub.unsubscribe();
  }
}


