import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { Cargo } from "src/app/core/interfaces/cargo";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { CargoItemService } from "../cargo-item/cargo-item.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-cargo-request-advance",
  templateUrl: "./cargo-request-advance.component.html",
  styleUrls: ["./cargo-request-advance.component.scss"],
})
export class CargoRequestAdvanceComponent {
  isRequest: boolean = true;
  cargo: Cargo;

  constructor(
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    public cargoItemService: CargoItemService,
    public matDialog: MatDialog,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CargoRequestAdvanceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public paramsDialog: { cargo: Cargo; request: string, description?: string }
  ) {
    if (
      this.paramsDialog &&
      this.paramsDialog.cargo &&
      this.paramsDialog.request
    )
      this.cargo = this.paramsDialog.cargo;
    if (this.paramsDialog.request !== "RequestAdvance") {
      this.isRequest = false;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  requestAdvance() {
    if (this.cargo) {
      this.spinner.show();
      this.cargoItemService
        .requestAdvance(
          this.cargo.id,
          this.isRequest,
          "Transferencia"
        )
        .subscribe(
          (success: any) => {
            this.spinner.hide();
            if (success.message) {
              this.snackBarService.openSnackBar(
                "Se realizó la solicitud correctamente",
                undefined,
                "success"
              );
              this.dialogRef.close({ state: true });
            } else {
              this.snackBarService.openSnackBar(
                "Hubo un problema al realizar la solicitud",
                undefined,
                "error"
              );
              this.closeDialog();
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(
              "Hubo un problema al realizar la solicitud",
              undefined,
              "error"
            );
            this.closeDialog();
          }
        );
    } else {
      this.snackBarService.openSnackBar(
        "Hubo un problema al realizar la solicitud",
        undefined,
        "error"
      );
      this.closeDialog();
    }
  }
}
