import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material';

@Component({
  selector: 'app-complete-address',
  templateUrl: './complete-address.component.html',
  styleUrls: ['./complete-address.component.scss']
})
export class CompleteAddressComponent implements OnInit {
  @ViewChild(MatInput, { static: false }) inputField: MatInput;

  @Input() title: string;
  @Input() icon: string;
  @Input() draggable: boolean;
  @Input() actionIcon: void;
  @Input() deleteButton: boolean = true;
  @Input() control: FormControl = new FormControl('');

  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  @Output() location: EventEmitter<any> = new EventEmitter<any>();

  currentlyDraggable = false;
  constructor() { }

  private hasCleaned = false;
  private address = null;

  ngOnInit() {
    this.currentlyDraggable = this.draggable;
  }

  onBlur() {
    setTimeout(() => {
      this.currentlyDraggable = !this.hasCleaned && this.draggable;
      this.hasCleaned = false;
    }, 100);
  }

  onFocus() {
    this.currentlyDraggable = false;
  }

  onHover() {
    this.currentlyDraggable = this.draggable;
  }

  onRemove() {
    this.remove.emit(true);
  }

  onAutocompleteSelected(e: any) {
    this.address = e;
  }

  onLocationSelected(e: any) {
    this.location.emit({
      address: this.address,
      location: {
        lat: e.latitude,
        lng: e.longitude
      }
    });
    this.inputField.value = this.address.name;
  }
}
