export class DurationTimeAddress {
    
    private startDate: string;
    private endDate: string;
    private duration: number;

    public getStartDate(): string {
        return this.startDate;
    }

    public setStartDate(startDate: string): void {
        this.startDate = startDate;
    }

    public getEndDate(): string {
        return this.endDate;
    }

    public setEndDate(endDate: string): void {
        this.endDate = endDate;
    }

    public getDuration(): number {
        return this.duration;
    }

    public setDuration(duration: number): void {
        this.duration = duration;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }
}
