import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Patterns } from '../resources/patterns';
@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

  patternsConfig = [
    { pattern: this.patterns.FILE_FORMAT_FULL, typeIndex: 1 },
    { pattern: this.patterns.FILE_FORMAT, typeIndex: 0 },
  ];
  constructor(
    public datePipe: DatePipe,
    public patterns: Patterns
  ) { }

  transform(fileName: string): string {
    // El formato debe ser siempre objeto_propiedad_tiempoEnMs.formato o propiedad_tiempoEnMs.formato
    for (const config of this.patternsConfig) {
      if (config.pattern.test(fileName)) {
        const fileParts = fileName.split('_');
        const type = fileParts[config.typeIndex].charAt(0).toUpperCase() + fileParts[config.typeIndex].slice(1);
        const timestamp = parseInt(fileParts[config.typeIndex + 1].slice(0, 13));
        const formattedDate = this.datePipe.transform(new Date(timestamp), "dd-MM-yyyy h:mm a");
        return `${type} ${formattedDate}`;
      }
    }
    return fileName;

  }
}
