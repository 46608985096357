import { Pipe, PipeTransform } from '@angular/core';
import { DateManager } from '../managers/date.manager';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {

    constructor() { }

    transform(durationSeconds) {
        let stringDuration = "";
        let duration = DateManager.durationFormat(durationSeconds, 'seconds');
        if (duration.months > 0)
            stringDuration += duration.months + (duration.months === 1 ? " mes " : " meses ");

        if (duration.days > 0)
            stringDuration += duration.days + (duration.days === 1 ? " día " : " días ");

        if (duration.hours > 0)
            stringDuration += duration.hours + (duration.hours === 1 ? " hora " : " horas ");

        if (duration.hours > 0)
            stringDuration += duration.minutes + (duration.minutes === 1 ? " minuto " : " minutos ");

        if (duration.seconds > 0)
            stringDuration += duration.seconds + (duration.seconds === 1 ? " segundo " : " segundos ");

        return stringDuration;
    }

}