
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from 'src/app/core/resources/endpoints';
@Injectable({
  providedIn: 'root'
})
export class CargoItemTurnService {

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints
  ) {

  }

  deleteTurn(idTurn: string) {
    let params = new HttpParams();
    if (idTurn) {
      params = params.append('id', idTurn);
    }
    return this.http.delete(
      `${environment.urlServerTeclogi}${this.endpointResources.deleteTurn}`,
      { params }
    );
  }

  editTurn(turn) {
    return this.http.put(
      `${environment.urlServerTeclogi}${this.endpointResources.editTurn}`,
      turn
    );
  }
}
