import { Injectable } from "@angular/core";
import { Utils } from "../resources/utils";
import { State } from "../interfaces/state";
import { User } from "../interfaces/user";
import { Roles } from "../enums/roles.enum";
import { FIELDS_STATE, FIELDS_SECURITY_STUDY } from '../config/user.config';
import { AuthService } from "../services/authentication.service";
import { UserState } from "../enums/user-state.enum";

@Injectable()
export class UserManager {

  private holderId: string;

  constructor(
    private authService: AuthService,
    private utils: Utils
  ) {
    this.holderId = this.authService.getCompany() ? this.authService.getCompany().companyId : null;
  }

  public sanitizeUser(originalUser: User, role: Roles.DRIVER | Roles.ADMIN | Roles.OWNER) {
    if (this.utils.objIsEmpty(originalUser))
      return null;
    const user = { ...originalUser };
    if (user && user.state && (!!user.state.active || (!user.state.active && user.state.description && user.state.description != UserState.INACTIVE && user.state.description != UserState.DOES_NOT_MEET_REQUIREMENTS)))
      return user;

    const fieldsState = FIELDS_STATE[this.holderId] ? FIELDS_STATE[this.holderId][role] : [];
    const fieldsSecurityStudy = FIELDS_SECURITY_STUDY[this.holderId] ? FIELDS_SECURITY_STUDY[this.holderId][role] : [];
    user.state = {
      active: true,
      description: UserState.ACTIVE
    };

    if (fieldsState && fieldsState.length) {
      fieldsState.forEach((field) => {
        try {
          if (!user[field] || !user[field].active) {
            if (!user[field]) user[field] = {};
            user[field]['active'] = false;
            user[field]['description'] = UserState.DOES_NOT_MEET_REQUIREMENTS;
            user.state.active = false;
            user.state.description = UserState.DOES_NOT_MEET_REQUIREMENTS;
            return;
          }
        } catch (e) { }
      });
    }

    if (fieldsSecurityStudy && fieldsSecurityStudy.length) {
      fieldsSecurityStudy.forEach((field) => {
        try {
          if (user[field].scoreTruora < 0.7) {
            user.state.active = false;
            user.state.description = UserState.DOES_NOT_MEET_REQUIREMENTS;
            return;
          }
        } catch (e) { }
      });
    }
    return user;
  }

}
