import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[massivePayments]',
})
export class MassivePaymentsDirective {


  constructor(private el: ElementRef, private control: NgControl, private renderer: Renderer2) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const formatted = this.formatInput(input.value);
    this.renderer.setProperty(this.el.nativeElement, 'value', formatted);
    this.control.control.setValue(formatted);
  }

  private formatInput(value: string): string {
    // Elimina todos los caracteres que no sean números, comas o espacios
    value = value.replace(/[^0-9,\s]/g, '');
    // Elimina espacios en blanco al principio y al final, y reduce múltiples espacios a uno solo
    value = value.trim().replace(/\s+/g, ' ');
    // Elimina comas consecutivas
    value = value.replace(/,+/g, ',');
    // Elimina comas al principio del valor
    if (value.startsWith(',')) value = value.substring(1);
    // Divide por espacios y vuelve a unir con comas, pero no agrega comas adicionales si ya existen
    return value.split(' ').join(',');
  }

}
