export enum HistoryType {
    MANIFESTS = "manifests",
    VALIDATION_SECURITY = "validationSecurity",
    CENTRAL_RISKS = "centralRisks",
    MONITOR_CONTROL = "monitorclt",
    DRIVIN = "drivinCargo",
    CARGO = "cargo"
}

export enum HistoryTypeDict {
    "manifests" = 'Manifiestos',
    "validationSecurity" = 'Análisis de seguridad',
    "centralRisks" = 'Centrales de riesgo',
    "monitorclt" = 'Monitoreo GPS',
    "drivinCargo" = "Ruteos",
    "cargo" = "Creación de cargas"
}