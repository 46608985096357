<div class="modal-body wrapper-modal position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <form [formGroup]="form" (submit)="onSubmit()">
        <div class="title-component title-label mb-1">{{title}}</div>
        <div class="d-flex justify-content-start flex-wrap align-items-center">
            <mat-label>¿El Vehiculo cuenta con Tenedor o Administrador?</mat-label>
            <mat-button-toggle-group formControlName="hasAdministrator" class="ml-2">
                <mat-button-toggle [value]="true">Sí</mat-button-toggle>
                <mat-button-toggle [value]="false">No</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <hr *ngIf="form.get('hasAdministrator').value">
        <app-person-vehicle *ngIf="form.get('hasAdministrator').value" [form]="form.get('administrator')"
            title="Administrador">
        </app-person-vehicle>
        <hr>
        <div class="text-center">
            <button mat-raised-button color="primary" class="button-width-auto bg-color-primary">Guardar</button>
        </div>
    </form>
</div>