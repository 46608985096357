import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class confirmDeleteComponent implements OnInit {



  constructor(
    public dialogRef: MatDialogRef<confirmDeleteComponent>,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

}
