import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AutocompleteCityComponent } from 'src/app/shared/autocomplete-city/autocomplete-city.component';
import { CargoReteicaService } from './cargo-reteica.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cargo-reteica',
  templateUrl: './cargo-reteica.component.html',
  styleUrls: ['./cargo-reteica.component.scss']
})
export class CargoReteicaComponent implements OnInit {

  @ViewChild(AutocompleteCityComponent, { static: false }) autocompleteCityComponent: AutocompleteCityComponent;

  instanceModalOpen: NgbModalRef;
  assignReteicaForm: FormGroup;
  optionsCity: OptionsAutocomplete = {
    title: 'Seleccionar ciudad',
    requireFullCity: true
  }
  validate: string = '';
  cityControl: FormControl = new FormControl('', Validators.required);
  citySub: Subscription;
  constructor(private modalService: NgbModal,
    public dialogRef: MatDialogRef<CargoReteicaComponent>,
    public CargoReteicaService: CargoReteicaService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
    this.setSubscriptionCity();
  }

  public createReteicaForm() {

    this.assignReteicaForm = new FormGroup({
      idVehicle: new FormControl(
        '',
        Validators.compose(
          [Validators.required]
        )
      )
    });

  };

  setSubscriptionCity() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => {
        if (value && value.id && value.name) {
          this.data.city = value.id;
          this.data.cityName = value.name;
        } else {
          this.data.city = '';
          this.data.cityName = '';
        }
      })
  }

  public onSelectCity(city) {
    if (city.data) {
      this.autocompleteCityComponent.formControlCity.setValue("");
      this.data.city = city.data.id;
      this.data.cityName = city.data.name;
    }
  }

  public SaveReteica() {
    this.validate = 'touched';
    if (!this.data.city || !this.data.cityName) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'ninguna ciudad'), undefined, 'alert');
      return;
    }
    this.CargoReteicaService.changeReteica(this.data).subscribe((success: any) => {
      if (success) {
        this.snackBarService.openSnackBar('Reteica actualizado correctamente');
        this.dialogRef.close({
          state: true
        });
      } else {
        this.snackBarService.openSnackBar('Ocurrió un error al actualizar el reteica', undefined, 'error');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar('Ocurrió un error al actualizar el reteica', undefined, 'error');
      }
    );
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
  }
}
