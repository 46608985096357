import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ServerRequest } from 'src/app/core/interfaces/serverRequest';
import { FingerPrintsService } from '../finger-prints.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-finger-print-detail',
  templateUrl: './finger-print-detail.component.html',
  styleUrls: ['./finger-print-detail.component.scss'],
  providers: [DatePipe]
})
export class FingerPrintDetailComponent {

  fingerprint: ServerRequest;
  constructor(
    public fingerPrintsService: FingerPrintsService,
    public dialogRef: MatDialogRef<FingerPrintDetailComponent>,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public paramsDialog: {
      fingerprint;
    }
  ) {
    if (this.paramsDialog && paramsDialog.fingerprint) this.fingerprint = this.paramsDialog.fingerprint;
    else this.dialogRef.close();
  }

  formatDate(date: string): string {
    if (date) {
      var partsDate = date.split("/");
      if (partsDate.length && partsDate.length === 3) {
        var day = parseInt(partsDate[0]);
        var month = parseInt(partsDate[1]) - 1;
        var year = parseInt(partsDate[2]);
        var dateFormatted = new Date(year, month, day);
        return this.datePipe.transform(dateFormatted, "d MMMM yyyy HH:mm a");
      }
    }
    return date;
  }
}
