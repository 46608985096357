import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../resources/utils';

@Pipe({
    name: 'capitalizeText'
})
export class CapitalizeTextPipe implements PipeTransform {

    constructor(
        public utils: Utils
    ) { }

    transform(text: string) {
        if (this.utils.isDefined(text)) {
            try {
                const textLower = this.utils.clone(text.toString()).toLocaleLowerCase();
                return textLower.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
            } catch (e) {
                return text;
            }
        }
        return text;
    }

}
