import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[onlyNumbersDouble]'
})
export class OnlyNumberDoubleDirective {

    constructor(private _el: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this._el.nativeElement.value;
        //Negative numbers deleted from the input(to prevent negatives)
        this._el.nativeElement.value = initalValue.replace(/(\.|\,)(\.|\,)+|[^0-9.,]|^(\.|\,)/gi, '');
        if (initalValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

}
