import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Guide } from 'src/app/core/interfaces/guide';
import { ManualCreationCargoService } from '../../../cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { Destination } from 'src/app/core/interfaces/destination';
import { Utils } from 'src/app/core/resources/utils';
import { environment } from '../../../../../environments/environment';
import { DateManager } from 'src/app/core/managers/date.manager';

@Injectable({
    providedIn: 'root'
})
export class ListCargoFromGuidesService {

    latTeclogi = 4.782507;
    lngTeclogi = -74.189362;

    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
        private authService: AuthService,
        private manualCreationCargoService: ManualCreationCargoService,
        public utils: Utils
    ) {

    }

    getAllGuidesInCollection(pageKey: number, pageSize: number) {
        return this.http.get<Guide[]>(
            environment.urlServerTeclogi + this.endpointResources.getGuides + 'state=Created' + '&pageKey=' + pageKey + '&pageSize=' + pageSize
        );
    }

    getAllGuidesInDistribution(pageKey: number, pageSize: number) {
        return this.http.get<Guide[]>(
            environment.urlServerTeclogi + this.endpointResources.getGuides + 'state=In warehouse' + '&pageKey=' + pageKey + '&pageSize=' + pageSize
        );
    }

    getGuidesByState(state: string, pageKey: number, pageSize: number) {
        return this.http.get<Guide[]>(
            environment.urlServerTeclogi + this.endpointResources.getGuides + 'state=' + state + '&pageKey=' + pageKey + '&pageSize=' + pageSize
        );
    }

    getGuidesSelected(guides) {
        return _.filter(guides, function (g) {
            return g.checked >= true;
        });
    }

    private createGuideBase(guidesInCollectionSelected: Array<Guide>) {
        let cargoClone = JSON.stringify(this.manualCreationCargoService.cargo);
        let cargo: Cargo = JSON.parse(cargoClone);

        delete cargo.id;
        delete cargo.distancy;
        delete cargo.driver;
        delete cargo.cargoModel;
        delete cargo.cargoFeature.productType;
        delete cargo.cargoFeature.vehicleType;
        delete cargo.shippingCost;
        delete cargo.estimatedTime;
        delete cargo.realTime;

        cargo.dateLoad = DateManager.dateToString(new Date(), 'YYYY-MM-DD HH:mm ZZ')

        if (this.utils.isDefined(guidesInCollectionSelected[0].guideId)) {
            cargo.numberDocumentSender = guidesInCollectionSelected[0].guideId;
        }

        if (this.utils.isDefined(this.authService.getUserSession().information.document)) {
            cargo.numberDocumentCreatorLoad = this.authService.getUserSession().information.document.toString();
        }
        if (this.utils.isDefined(this.authService.getUserSession().clientCompanyId)) {
            cargo.idCompany = this.authService.getUserSession().clientCompanyId;
        }

        cargo.cargoFeature.uploadDownload.destination = [];
        cargo.cargoFeature.uploadDownload.origin.addresses = [];

        return cargo;
    }

    private processGuidesStateCreatedSelected(guides: Guide[]) {
        const thisService = this;
        let cargo = this.createGuideBase(guides);

        if (this.utils.isDefined(guides[0].originCity) || this.utils.isDefined(guides[0].originName)) {
            cargo.cargoFeature.uploadDownload.origin.name = guides[0].originCity || guides[0].originName;
        }

        guides.forEach((guide: Guide, index: number) => {
            if (thisService.utils.isDefined(guide.addressee)) {
                const address: AddressCargo = {
                    address: guide.addressee.address,
                    cellphone: guide.addressee.phone,
                    email: guide.addressee.email,
                    id: (index + 1).toString(),
                    guidesId: [{ value: parseInt(guide.guideId).toString() }]
                };
                if (this.utils.isDefined(guide.addressee.lat) && this.utils.isDefined(guide.addressee.lng)) {
                    address.location = {
                        lat: guide.addressee.lat,
                        lng: guide.addressee.lng
                    };
                }
                cargo.cargoFeature.uploadDownload.origin.addresses.push(address);
            }
        });

        const destinationAddress: Destination = {
            id: '0',
            name: 'Tenjo - Cundinamarca',
            municipalityCode: '',
            addresses: [{
                id: '0',
                address: 'TECLOGI Centro Logistico, Cundinamarca',
                location: {
                    lat: this.latTeclogi,
                    lng: this.lngTeclogi
                },
                time: '12:00 AM',
                cellphone: '',
                email: ''
            }],
            downloadDate: DateManager.dateToString(DateManager.add(new Date(), 2, 'days'), 'YYYY-MM-DD HH:mm ZZ')
        };
        cargo.cargoFeature.uploadDownload.destination.push(destinationAddress);

        return cargo;
    }

    private processGuidesStateInWarehouseSelected(guides: Guide[]) {

        const thisService = this;
        let cargo = this.createGuideBase(guides);

        cargo.cargoFeature.uploadDownload.origin.name = 'Tenjo - Cundinamarca';
        cargo.cargoFeature.uploadDownload.origin.addresses.push({
            id: '0',
            address: 'TECLOGI Centro Logistico, Cundinamarca',
            location: {
                lat: this.latTeclogi,
                lng: this.lngTeclogi
            },
            time: '12:00 AM',
            cellphone: '',
            email: ''
        });

        if (
            (this.utils.isDefined(guides[0].originCity) || this.utils.isDefined(guides[0].originName)) &&
            this.utils.isDefined(guides[0].deadline)
        ) {
            cargo.cargoFeature.uploadDownload.destination = [{
                id: '0',
                name: guides[0].originCity || guides[0].originName,
                addresses: [],
                downloadDate: DateManager.dateToString(DateManager.add(new Date(), 2, 'days'), 'YYYY-MM-DD HH:mm ZZ'),
                municipalityCode: ''
            }];
        }

        guides.forEach((guide: Guide, index: number) => {
            if (thisService.utils.isDefined(guide.addressee)) {
                const address: AddressCargo = {
                    address: guide.addressee.address + ' - ' + guide.addressee.commune,
                    cellphone: guide.addressee.phone,
                    email: guide.addressee.email,
                    id: (index + 1).toString(),
                    guidesId: [{ value: parseInt(guide.guideId).toString() }]
                };
                if (this.utils.isDefined(guide.addressee.lat) && this.utils.isDefined(guide.addressee.lng)) {
                    address.location = {
                        lat: guide.addressee.lat,
                        lng: guide.addressee.lng
                    };
                }
                cargo.cargoFeature.uploadDownload.destination[0].addresses.push(address);
            }
        });

        return cargo;
    }

    createCargoObjectByGuides(typeServiceCompany: string, guidesCreated: Guide[], guidesInWarehouse?: Guide[]) {

        let dataCargo = {
            cargo: []
        };

        switch (typeServiceCompany) {
            case 'fulfillment':
                if (guidesCreated && guidesCreated.length) {
                    const cargoFromGuidesCreated = this.processGuidesStateCreatedSelected(
                        guidesCreated
                    );
                    dataCargo.cargo.push(cargoFromGuidesCreated);
                }
                if (guidesInWarehouse && guidesInWarehouse.length) {
                    const cargoFromGuidesInWarehouse = this.processGuidesStateInWarehouseSelected(
                        guidesInWarehouse
                    );
                    dataCargo.cargo.push(cargoFromGuidesInWarehouse);
                }

                break;
            default:
                if (guidesCreated && guidesCreated.length) {
                    const cargoFromGuidesCreated = this.processGuidesStateInWarehouseSelected(
                        guidesCreated
                    );
                    dataCargo.cargo.push(cargoFromGuidesCreated);
                }
                break;
        }

        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.urlCreateCargo,
            dataCargo
        );

    }

}
