import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { BoxTypeTripService } from './box-type-trip.service';
import { MatAutocompleteSelectedEvent, MatTooltip } from '@angular/material';
import { ModelCargo } from '../../resources/modelCargo';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';

@Component({
  selector: 'app-box-type-trip',
  templateUrl: './box-type-trip.component.html',
  styleUrls: ['./box-type-trip.component.scss'],
  providers: [AuthService, Global, BoxTypeTripService, ModelCargo]
})
export class BoxTypeTripComponent implements OnInit {
  isSameCities: boolean = false;
  @Input() options: any;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Input() tripSelectedName: String;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.isSameCities = this.isSameCity();
    this.options = this.options.map((option) => {
      if ((option.name == "Nacional" && this.isSameCities) ||
        ((option.name === "Urbana" || option.name === "Última milla") && !this.isSameCities)) {
        return { ...option, disabled: true };
      } else return { ...option };
    })
  }

  public isSameCity(): boolean {
    const originCity = this.manualCreationCargoService.formOrigin.value.name;
    return this.manualCreationCargoService.formDestinations.value.every((city) => city.name === originCity);
  }

  public selectTrip(data) {
    if (!data.disabled) {
      this.tripSelectedName = data.name;
      this.emitToParent.emit(data);
    } else {
      this.isSameCities ?
        this.snackBarService.openSnackBar(CargoMessages.SAME_CITIES_ORIGIN_DESTINATIONS, undefined, 'error')
        : this.snackBarService.openSnackBar(CargoMessages.DIFF_CITIES_ORIGIN_DESTINATIONS, undefined, 'error');
    }
  }

}
