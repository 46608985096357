<div class="d-flex flex-column align-items-center h-100">
  <div *ngIf="showOptions" class="button-map-container d-flex flex-row justify-content-around w-100 my-3 px-2">
    <div class="text-center">
      <mat-label><b>Detalles:</b>&nbsp;</mat-label>
      <mat-button-toggle-group [formControl]="showMarkers">
        <mat-button-toggle [value]="true">Sí</mat-button-toggle>
        <mat-button-toggle [value]="false">No</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="text-center">
      <mat-label><b>Geocercas:</b>&nbsp;</mat-label>
      <mat-button-toggle-group [formControl]="showGeofences">
        <mat-button-toggle [value]="true">Sí</mat-button-toggle>
        <mat-button-toggle [value]="false">No</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div [id]="mapId" class="map"></div>
</div>