import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Endpoints } from "src/app/core/resources/endpoints";
import { environment } from "../../../../environments/environment";
import { Utils } from "src/app/core/resources/utils";
import { Cargo } from "src/app/core/interfaces/cargo";
import { DiscountsAndBonuses } from "src/app/core/interfaces/discountsAndBonuses";
import { CargoPayment } from "src/app/core/interfaces/cargoPayment";
import { PDFTypes } from "src/app/core/enums/pdf-types.enum";
import { INCIDENT_ASSETS } from "src/app/core/resources/incident_assets";
import { AuthService } from "src/app/core/services/authentication.service";
import { CargoStateEnum } from "src/app/core/enums/cargoState.enum";
import { CargoTaxesPipe } from "src/app/core/pipe/cargo-taxes.pipe";

@Injectable({
  providedIn: "root",
})
export class CargoItemService {

  public integralValuesPercentageUrban = 3;
  public integralValuesPercentageGeneral = 3;

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    public utils: Utils,
    public authService: AuthService,
    private cargoTaxesPipe: CargoTaxesPipe
  ) { }

  getTotalDiscounts(cargo: Cargo): number {
    if (cargo && cargo.shippingCost && cargo.shippingCost.modifications) {
      const discounts = cargo.shippingCost.modifications.filter(
        (modification: DiscountsAndBonuses) => {
          return modification.type == 1;
        }
      );
      if (discounts.length) {
        const total = discounts.reduce((a, b) => {
          return { amount: a.amount + b.amount };
        });
        return total.amount;
      }
    }
    return 0;
  }

  getTotalDiscountsCostAdditionalServices(additionalServiceGroupCtrl) {
    if (
      additionalServiceGroupCtrl &&
      additionalServiceGroupCtrl.financialInformation &&
      additionalServiceGroupCtrl.financialInformation.modifications
    ) {
      const discounts =
        additionalServiceGroupCtrl.financialInformation.modifications.filter(
          (modification: DiscountsAndBonuses) => {
            return modification.type == 1;
          }
        );
      if (discounts.length) {
        const total = discounts.reduce((a, b) => {
          return { amount: a.amount + b.amount };
        });
        return total.amount;
      }
    }
    return 0;
  }

  public getTotalAdditionalRate(cargo: Cargo): number {
    if (cargo && cargo.additionalCosts && cargo.additionalCosts.length > 0) {
      return cargo.additionalCosts.reduce(
        (accum, item) =>
          item.financialInformation &&
            item.financialInformation.rate &&
            (item.type.id === "14" || item.type.id === "13")
            ? accum + item.financialInformation.rate
            : accum,
        0
      );
    }
    return 0;
  }

  public getTotalAdditionalCosts(cargo: Cargo): number {
    if (cargo && cargo.additionalCosts && cargo.additionalCosts.length > 0) {
      return cargo.additionalCosts
        .filter((additionalCost) => {
          return (
            additionalCost.type.id == "14" ||
            (additionalCost.type.id == "13" &&
              additionalCost.toPay &&
              additionalCost.toPay.code === "C")
          );
        })
        .reduce(
          (accum, item) =>
            item.financialInformation && item.financialInformation.cost
              ? accum + item.financialInformation.cost
              : accum,
          0
        );
    }
    return 0;
  }

  public getTotalAdditionalCostsDriver(cargo: Cargo): number {
    if (cargo && cargo.additionalCosts && cargo.additionalCosts.length > 0) {
      return cargo.additionalCosts.filter((additionalCost) => { return ((additionalCost.type.id === '14' && additionalCost.toPay && additionalCost.toPay.code === 'C') || (additionalCost.type.id === '13' && additionalCost.toPay && additionalCost.toPay.code === 'C')); }).reduce((accum, item) => item.financialInformation && item.financialInformation.cost ? accum + item.financialInformation.cost : accum, 0)
    }
    return 0;
  }


  public getTotalAdditionalRateDriver(cargo: Cargo): number {
    if (cargo && cargo.additionalCosts && cargo.additionalCosts.length > 0) {
      return cargo.additionalCosts.filter((additionalCost) => { return ((additionalCost.type.id === '14' && additionalCost.toPay && additionalCost.toPay.code === 'C') || (additionalCost.type.id === '13' && additionalCost.toPay && additionalCost.toPay.code === 'C')); }).reduce((accum, item) => item.financialInformation && item.financialInformation.rate ? accum + item.financialInformation.rate : accum, 0)
    }
    return 0;
  }


  getTotalBonuses(cargo: Cargo): number {
    if (cargo && cargo.shippingCost && cargo.shippingCost.modifications) {
      const discounts = cargo.shippingCost.modifications.filter(
        (modification: DiscountsAndBonuses) => {
          return modification.type == 0;
        }
      );
      if (discounts.length) {
        const total = discounts.reduce((a, b) => {
          return { amount: a.amount + b.amount };
        });
        return total.amount;
      }
    }
    return 0;
  }

  getTotalBonusesCostAdditionalServices(additionalServiceGroupCtrl) {
    if (
      additionalServiceGroupCtrl &&
      additionalServiceGroupCtrl.financialInformation &&
      additionalServiceGroupCtrl.financialInformation.modifications
    ) {
      const discounts =
        additionalServiceGroupCtrl.financialInformation.modifications.filter(
          (modification: DiscountsAndBonuses) => {
            return modification.type == 0;
          }
        );
      if (discounts.length) {
        const total = discounts.reduce((a, b) => {
          return { amount: a.amount + b.amount };
        });
        return total.amount;
      }
    }
    return 0;
  }

  getTotalBonusesRate(cargo: Cargo): number {
    if (cargo && cargo.shippingCost && cargo.shippingCost.modifications) {
      const discounts = cargo.shippingCost.modifications.filter(
        (modification: DiscountsAndBonuses) => {
          return modification.type == 0;
        }
      );
      if (discounts.length) {
        const total = discounts.reduce((a, b) => {
          return { amountRate: a.amountRate + b.amountRate };
        });
        if (Number.isNaN(total.amountRate)) {
          return 0;
        }
        return total.amountRate;
      }
    }
    return 0;
  }

  getTotalBonusesRateAdditionalServices(additionalServiceGroupCtrl) {
    if (
      additionalServiceGroupCtrl &&
      additionalServiceGroupCtrl.financialInformation &&
      additionalServiceGroupCtrl.financialInformation.modifications
    ) {
      const discounts =
        additionalServiceGroupCtrl.financialInformation.modifications.filter(
          (modification: DiscountsAndBonuses) => {
            return modification.type == 0;
          }
        );
      if (discounts.length) {
        const total = discounts.reduce((a, b) => {
          return { amountRate: a.amountRate + b.amountRate };
        });
        if (Number.isNaN(total.amountRate)) {
          return 0;
        }
        return total.amountRate;
      }
    }
    return 0;
  }

  getTotalDiscountsRate(cargo: Cargo): number {
    if (cargo && cargo.shippingCost && cargo.shippingCost.modifications) {
      const discounts: DiscountsAndBonuses[] =
        cargo.shippingCost.modifications.filter(
          (modification: DiscountsAndBonuses) => {
            return modification.type == 1 && modification.amountRate > 0;
          }
        );
      if (discounts.length) {
        const total: DiscountsAndBonuses = discounts.reduce((a, b) => {
          return {
            amountRate: a.amountRate + b.amountRate,
          };
        });
        if (Number.isNaN(total.amountRate)) {
          return 0;
        }
        return total.amountRate;
      }
    }
    return 0;
  }

  getTotalDiscountsRateAdditionalServices(additionalServiceGroupCtrl) {
    if (
      additionalServiceGroupCtrl &&
      additionalServiceGroupCtrl.financialInformation &&
      additionalServiceGroupCtrl.financialInformation.modifications
    ) {
      const discounts: DiscountsAndBonuses[] =
        additionalServiceGroupCtrl.financialInformation.modifications.filter(
          (modification: DiscountsAndBonuses) => {
            return modification.type == 1 && modification.amountRate > 0;
          }
        );
      if (discounts.length) {
        const total: DiscountsAndBonuses = discounts.reduce((a, b) => {
          return {
            amountRate: a.amountRate + b.amountRate,
          };
        });
        if (Number.isNaN(total.amountRate)) {
          return 0;
        }
        return total.amountRate;
      }
    }
    return 0;
  }

  getNewValueRate(cargo: Cargo, amount, type?) {
    const value = amount || 0;
    if (type) {
      return cargo.shippingCost.rate - value;
    } else {
      return cargo.shippingCost.rate + value;
    }
  }

  getNewValueRateAdditionals(finantialInfo: any, amount, type?) {
    const value = amount || 0;
    if (type) {
      return finantialInfo.financialInformation.rate - value;
    } else {
      return finantialInfo.financialInformation.rate + value;
    }
  }

  getNewValueTotalCost(cargo: Cargo, amount, type): number {
    const value = amount || 0;
    let sum = type ? -value : value;
    return Math.ceil(this.getRealTotalCost(cargo) - cargo.shippingCost.totalPaid) + sum;
  }

  getNewValueCostAdditionals(finantialInfo: any, amount, type) {
    const value = amount || 0;
    if (type) {
      return finantialInfo.financialInformation.cost - value;
    } else {
      return finantialInfo.financialInformation.cost + value;
    }
  }

  getRealTotalCost(cargo: Cargo) {
    if (
      cargo &&
      cargo.shippingCost &&
      this.utils.isDefined(cargo.shippingCost.totalCost)
    ) {
      let taxes = cargo.shippingCost.retefteValue + cargo.shippingCost.reteicaValue;
      if (this.isTeclogiUser) taxes += cargo.shippingCost.integralValue;
      return (
        cargo.shippingCost.totalCost -
        this.getTotalDiscounts(cargo) +
        this.getTotalBonuses(cargo) -
        taxes
      );
    } else {
      return 0;
    }
  }

  getRealCost(cargo: Cargo) {
    if (
      cargo &&
      cargo.shippingCost &&
      this.utils.isDefined(cargo.shippingCost.totalCost)
    ) {
      return (
        cargo.shippingCost.totalCost -
        this.getTotalDiscounts(cargo) +
        this.getTotalBonuses(cargo) +
        this.getTotalAdditionalCosts(cargo)
      );
    } else {
      return 0;
    }
  }

  getTotalValueAdvance(cargo: Cargo) {
    let value = 0;
    if (
      cargo && cargo.shippingCost &&
      this.utils.isDefined(cargo.shippingCost.valueAdvance) &&
      this.utils.isDefined(cargo.shippingCost.totalPaid)
    ) {
      if (
        cargo.shippingCost.payments &&
        cargo.shippingCost.payments.length > 0
      ) {
        value =
          cargo.shippingCost.valueAdvance +
          this.sumItemArray(cargo.shippingCost.payments);
      } else {
        value = cargo.shippingCost.valueAdvance;
      }
    }
    return value;
  }

  getTotalValueBalance(cargo: Cargo) {
    let value = 0;
    if (cargo && cargo.shippingCost) {
      let retefteValue = this.cargoTaxesPipe.transform(cargo.shippingCost.retefteValue, this.isChargeTaxes(cargo));
      let reteicaValue = this.cargoTaxesPipe.transform(cargo.shippingCost.reteicaValue, this.isChargeTaxes(cargo));
      let taxes = retefteValue + reteicaValue;
      if (this.isTeclogiUser) taxes += cargo.shippingCost.integralValue;
      let modificationValue = 0;
      modificationValue += this.getTotalBonuses(cargo);
      modificationValue -= this.getTotalDiscounts(cargo);
      const totalCost = cargo.shippingCost.totalCost + modificationValue;
      let totalPaid = 0;
      if (cargo.shippingCost.payments)
        cargo.shippingCost.payments.forEach((payment) => {
          if (payment.type === "advance" || payment.type === "extraAdvance" || payment.type === "balance") {
            totalPaid += payment.amount;
          }
        });
      value = (totalCost - (totalPaid + taxes));
    }
    if (value <= 0) {
      value = 0;
    }
    return value;
  }

  getSummaryBalance(cargo: Cargo) {
    let balance = this.getTotalValueBalance(cargo);
    let modificationValue = 0;
    modificationValue += this.getTotalBonuses(cargo);
    modificationValue -= this.getTotalDiscounts(cargo);
    const payments = [];
    if (cargo.shippingCost.payments) {
      cargo.shippingCost.payments.forEach(object => {
        const { type, amount } = object;
        const accObject = payments.find(item => item.type === type);
        if (accObject) {
          accObject.amount += amount;
        } else {
          payments.push({ type, amount });
        }
      });
      payments.forEach(payment => {
        if (payment.type === 'advance') {
          payment.label = 'Anticipo';
        } else if (payment.type === 'extraAdvance') {
          payment.label = 'Extra anticipo';
        } else {
          payment.label = 'Saldo';
        }
      })
    }
    let summaryBalance = {
      reteFuente: this.cargoTaxesPipe.transform(cargo.shippingCost.retefteValue, this.isChargeTaxes(cargo)),
      reteica: this.cargoTaxesPipe.transform(cargo.shippingCost.reteicaValue, this.isChargeTaxes(cargo)),
      bonus: this.getTotalBonuses(cargo),
      discounts: this.getTotalDiscounts(cargo),
      totalCost: cargo.shippingCost.totalCost + modificationValue,
      initialCost: cargo.shippingCost.totalCost,
      payments: payments,
      balance: balance,
      balanceState: cargo.shippingCost.balanceState
    }
    if (this.isTeclogiUser) summaryBalance['integral'] = cargo.shippingCost.integralValue;
    return summaryBalance;
  }

  /*getTotalValueBalance(cargo: Cargo) {
    let value = 0;
    if (
      cargo && cargo.shippingCost &&
      this.utils.isDefined(cargo.shippingCost.totalCost) &&
      this.utils.isDefined(cargo.shippingCost.totalPaid)
    ) {
      if (cargo.shippingCost.balanceState) {
        value = cargo.shippingCost.totalPaid - cargo.shippingCost.valueAdvance;
      } else {
        const taxes =
          cargo.shippingCost.retefteValue +
          cargo.shippingCost.reteicaValue +
          cargo.shippingCost.integralValue;
        value =
          cargo.shippingCost.totalCost -
          taxes +
          this.getTotalBonuses(cargo) -
          this.getTotalDiscounts(cargo);

        if (cargo.shippingCost.payments) {
          if (cargo.shippingCost.advanceState) {
            const paymentAdvance = cargo.shippingCost.payments.filter(
              (payment) => {
                return payment.type === "advance";
              }
            );
            if (paymentAdvance && paymentAdvance.length) {
              value -= paymentAdvance[0].amount;
            }
          }

          const paymentExtraAdvance = cargo.shippingCost.payments.filter(
            (payment) => {
              return payment.type === "extraAdvance";
            }
          );
          let paymentExtraAdvanceValue = null;
          if (paymentExtraAdvance && paymentExtraAdvance.length) {
            paymentExtraAdvanceValue = paymentExtraAdvance.reduce((a, b) => {
              let data: CargoPayment = {
                type: a.type,
                amount: a.amount + b.amount,
                proofs: a.proofs,
              };
              return data;
            });
            if (
              paymentExtraAdvanceValue &&
              paymentExtraAdvanceValue.amount > 0
            ) {
              value -= paymentExtraAdvanceValue.amount;
            }
          }
        } else {
          value -= cargo.shippingCost.valueAdvance;
        }
      }
    }
    return value;
  }*/

  /*getCargoTotalBalance(cargo: Cargo): number {
    let value = 0;
    if (
      this.utils.isDefined(cargo.shippingCost.totalCost) &&
      this.utils.isDefined(cargo.shippingCost.totalPaid)
    ) {
      const taxes =
        cargo.shippingCost.retefteValue +
        cargo.shippingCost.reteicaValue +
        cargo.shippingCost.integralValue;
      value =
        cargo.shippingCost.totalCost -
        taxes +
        this.getTotalBonuses(cargo) -
        this.getTotalDiscounts(cargo) -
        this.getCargoTotalPaid(cargo);
    }
    return value;
  }*/

  /*getCargoBalancePaidOut(cargo: Cargo): number {
    let value = 0;
    if (this.utils.isDefined(cargo.shippingCost.payments)) {
      const paymentList = cargo.shippingCost.payments.filter((payment) => {
        return payment.type === "balance" || payment.type === "extraAdvance";
      });
      let paymentValue = null;
      if (paymentList && paymentList.length) {
        paymentValue = paymentList.reduce((a, b) => {
          let data: CargoPayment = {
            type: a.type,
            amount: a.amount + b.amount,
            proofs: a.proofs,
          };
          return data;
        });
      }
      if (paymentValue && paymentValue.amount > 0) {
        const taxes =
          cargo.shippingCost.retefteValue +
          cargo.shippingCost.reteicaValue +
          cargo.shippingCost.integralValue;
        value =
          cargo.shippingCost.totalCost -
          taxes +
          this.getTotalBonuses(cargo) -
          this.getTotalDiscounts(cargo) -
          cargo.shippingCost.valueAdvance -
          paymentValue.amount;
      } else {
        value = this.getTotalValueBalance(cargo);
      }
    } else {
      value = this.getTotalValueBalance(cargo);
    }
    return value;
  }*/

  getCargoBalancePaid(cargo: Cargo): number {
    let value = 0;
    if (cargo && cargo.shippingCost && cargo.shippingCost.payments) {
      cargo.shippingCost.payments.forEach((payment) => {
        if (payment.type === "balance") {
          value += payment.amount;
        }
      });
    }
    return value;
  }

  getCargoTotalPaid(cargo: Cargo): number {
    let value = 0;
    if (this.utils.isDefined(cargo.shippingCost.payments)) {
      cargo.shippingCost.payments.forEach((payment) => {
        if (payment.type === "advance" || payment.type === "extraAdvance") {
          value += payment.amount;
        } else if (payment.type === "balance") {
          value += this.getTotalValueBalance(cargo);
        }
      });
    }
    return value;
  }

  getUtilityCargo(rate, totalCost) {
    const utilityValue = ((rate - totalCost) / rate) * 100;
    if (!Number.isNaN(utilityValue)) {
      return utilityValue;
    }
    return 0;
  }

  public getCargoInvoicePDF(cargoId: string) {
    let params = new HttpParams();
    params = params.append("cargoId", cargoId);
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.getCargoInvoicePDF,
      {
        params,
        responseType: "blob",
      }
    );
  }

  public getCargoJournalPdf(cargoId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.cargoJournalPdf,
      { params, responseType: 'blob' }
    );
  }

  public sumItemArray(shippingCost) {
    const result = shippingCost
      .filter((item) => item.type == "extraAdvance")
      .map((item) => item.amount)
      .reduce((prev, curr) => prev + curr, 0);
    return result;
  }

  public isApproveBalance(cargo: Cargo): boolean {
    return (
      !this.utils.isEmpty(cargo) &&
      cargo &&
      cargo.shippingCost &&
      !this.utils.isEmpty(cargo.shippingCost.approveBalance) &&
      cargo.shippingCost.approveBalance === true &&
      cargo.shippingCost.balanceState === false
    );
  }

  public confirmDrive(data: any, approval: string): Observable<Cargo> {
    return this._http.post<Cargo>(
      environment.urlServerTeclogi +
      this._endpointResources.confirmDriver +
      "approval=" +
      approval,
      data
    );
  }

  public getInspection(id: string, type: "vehicle" | "container" = "vehicle") {
    if (type == "vehicle")
      return this._http.get(
        `${environment.urlServerTeclogi}${this._endpointResources.inspection}?inspectionId=${id}`
      );
    else if (type == "container")
      return this._http.get(
        `${environment.urlServerTeclogi}${this._endpointResources.inspection}/container?inspectionId=${id}`
      );
  }

  public sendInspection(
    data: any,
    type: "vehicle" | "container",
    cargoId: string
  ): Observable<Cargo> {
    return this._http.post<Cargo>(
      `${environment.urlServerTeclogi}${this._endpointResources.inspection
      }?inspectionType=${type.toUpperCase()}&cargoId=${cargoId}`,
      data
    );
  }

  public approveInspection(
    data: any,
    approval: boolean,
    type: "vehicle" | "container" = "vehicle"
  ): Observable<any> {
    data.type = type;
    return this._http.post(
      `${environment.urlServerTeclogi}${this._endpointResources.inspection
      }/approval?approval=${approval ? "true" : "false"}`,
      data
    );
  }

  public getVehicleInsuranceDiscountCargo(cargo: Cargo) {
    if (
      cargo.cargoModel &&
      cargo.cargoModel.tripType &&
      cargo.cargoModel.tripType.name === "Urbana"
    ) {
      return (
        (cargo.shippingCost.totalCost * this.integralValuesPercentageUrban) /
        100
      );
    } else {
      return (
        (cargo.shippingCost.totalCost * this.integralValuesPercentageGeneral) /
        100
      );
    }
  }

  public approvePayBalance(
    cargoId: string,
    approve: boolean
  ): Observable<Cargo> {
    let params = new HttpParams();
    params = params.append("approve", approve.toString());
    return this._http.put<Cargo>(
      environment.urlServerTeclogi +
      this._endpointResources.approvePayBalance +
      "/" +
      cargoId,
      {},
      { params }
    );
  }

  public getInspectionPDF(
    cargoId: string,
    licensePlate: string,
    type: "vehicle" | "container" = "vehicle"
  ): Observable<Blob> {
    if (type == "vehicle") {
      let params = new HttpParams();
      params = params.append(
        "pdfType",
        PDFTypes.VEHICLE_INSPECTION
      );
      params = params.append("licensePlate", licensePlate);
      params = params.append("cargoId", cargoId);
      return this._http.get(
        environment.urlServerTeclogi +
        this._endpointResources.getVehicleInspectionPDF,
        {
          params,
          responseType: "blob",
        }
      );
    } else if (type == "container") {
      let params = new HttpParams();
      params = params.append(
        "pdfType",
        PDFTypes.CONTAINER_INSPECTION
      );
      params = params.append("licensePlate", licensePlate);
      params = params.append("cargoId", cargoId);
      return this._http.get(
        environment.urlServerTeclogi +
        this._endpointResources.getVehicleInspectionPDF,
        {
          params,
          responseType: "blob",
        }
      );
    }
  }

  public requestAdvance(id: string, request: boolean, paymentType: string) {
    let url = `${environment.urlServerTeclogi}${this._endpointResources.requestAdvance}?cargoId=${id}&request=${request}`;
    if (paymentType !== "" && paymentType !== 'Cualquiera') {
      url += `&paymentType=${paymentType}`;
    }
    let body = {
      id: `${id}`,
      request: `${request}`,
    };
    return this._http.put(url, body);
  }

  public getIndicator(cargo: Cargo) {
    if (cargo.state === CargoStateEnum.START_SERVICE) {
      if (cargo.stateTracking == "Start service" && !cargo.lastPointLocation) {
        return INCIDENT_ASSETS[9];
      } else if (
        cargo.stateTracking == "Destination arrived" &&
        !cargo.lastPointLocation
      ) {
        return INCIDENT_ASSETS[10];
      } else if (
        !!cargo.lastPointLocation &&
        cargo.lastPointLocation.news == "Me detuve a dormir"
      ) {
        return INCIDENT_ASSETS[0];
      } else if (
        !!cargo.lastPointLocation &&
        cargo.lastPointLocation.news == "Me detuve a comer"
      ) {
        return INCIDENT_ASSETS[1];
      } else if (
        !!cargo.lastPointLocation &&
        cargo.lastPointLocation.news == "Cargando combustible"
      ) {
        return INCIDENT_ASSETS[2];
      } else if (
        !!cargo.lastPointLocation &&
        cargo.lastPointLocation.news == "Otro"
      ) {
        return INCIDENT_ASSETS[6];
      } else if (
        !!cargo.lastPointLocation &&
        cargo.lastPointLocation.news == "entregado"
      ) {
        return INCIDENT_ASSETS[7];
      } else if (
        !!cargo.lastPointLocation &&
        cargo.lastPointLocation.news == "Me pinché / varé"
      ) {
        return INCIDENT_ASSETS[3];
      } else if (
        !!cargo.lastPointLocation &&
        cargo.lastPointLocation.news == "Tuve un accidente"
      ) {
        return INCIDENT_ASSETS[4];
      } else if (
        !!cargo.lastPointLocation &&
        cargo.lastPointLocation.news == "La vía está cerrada"
      ) {
        return INCIDENT_ASSETS[5];
      }
    }
    return INCIDENT_ASSETS[8];
  }

  get isTeclogiUser(): boolean {
    return this.authService.getCompany().companyId === environment.rootNit;
  }

  public isChargeTaxes(cargo: Cargo): boolean {
    return !this.utils.isEmpty(cargo.chargeTaxes) ? cargo.chargeTaxes : true;
  }
}
