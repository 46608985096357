import { Component } from '@angular/core';
import { TermsAndConditions } from 'src/app/core/interfaces/termsAndConditions';
import { LegalTermsService } from 'src/app/modules/administration/legal-terms/legal-terms.service';

@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss']
})

export class TermsConditionsComponent {

    termsActive: TermsAndConditions;

    constructor(
        private termsAndConditionsService: LegalTermsService
    ) {

    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnInit() {
        this.getTermsActive();
    }

    getTermsActive() {
        this.termsAndConditionsService.getTermsActive().subscribe(
            (success: TermsAndConditions[]) => {
                this.termsActive = success[0];
            },
            (error) => {

            }
        );
    }

}
