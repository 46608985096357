import { CargoPayment } from "./cargo-payment.model";

export class ShippingCost {

    private freightCost: number;
    private auxiliaryCost: number;
    private totalCost: number;
    private advancePercentage: number;
    private valueAdvance: number;
    private advancePaymentMethod: string;
    private balancePaymentMethod: string;
    private paymentTime: string;
    private payments: CargoPayment[];
    private totalPaid: number;
    private paid: boolean;
    private reteicaPercentage: number;
    private reteftePercentage: number;
    private retefteValue: number;
    private reteicaValue: number;

    public getFreightCost(): number {
        return this.freightCost;
    }

    public setFreightCost(freightCost: number): void {
        this.freightCost = freightCost;
    }

    public getAuxiliaryCost(): number {
        return this.auxiliaryCost;
    }

    public setAuxiliaryCost(auxiliaryCost: number): void {
        this.auxiliaryCost = auxiliaryCost;
    }

    public getTotalCost(): number {
        return this.totalCost;
    }

    public setTotalCost(totalCost: number): void {
        this.totalCost = totalCost;
    }

    public getAdvancePercentage(): number {
        return this.advancePercentage;
    }

    public setAdvancePercentage(advancePercentage: number): void {
        this.advancePercentage = advancePercentage;
    }

    public getValueAdvance(): number {
        return this.valueAdvance;
    }

    public setValueAdvance(valueAdvance: number): void {
        this.valueAdvance = valueAdvance;
    }

    public getAdvancePaymentMethod(): string {
        return this.advancePaymentMethod;
    }

    public setAdvancePaymentMethod(advancePaymentMethod: string): void {
        this.advancePaymentMethod = advancePaymentMethod;
    }

    public getBalancePaymentMethod(): string {
        return this.balancePaymentMethod;
    }

    public setBalancePaymentMethod(balancePaymentMethod: string): void {
        this.balancePaymentMethod = balancePaymentMethod;
    }

    public getPaymentTime(): string {
        return this.paymentTime;
    }

    public setPaymentTime(paymentTime: string): void {
        this.paymentTime = paymentTime;
    }

    public getPayments(): CargoPayment[] {
        return this.payments || [new CargoPayment()];
    }

    public setPayments(payments: CargoPayment[]): void {
        payments.map((object) => {
            const payment = new CargoPayment();
            payment.deserialize(object);
            this.payments.push(payment);
        });
    }

    public getTotalPaid(): number {
        return this.totalPaid;
    }

    public setTotalPaid(totalPaid: number): void {
        this.totalPaid = totalPaid;
    }

    public isPaid(): boolean {
        return this.paid;
    }

    public setPaid(paid: boolean): void {
        this.paid = paid;
    }

    public getReteicaPercentage(): number {
        return this.reteicaPercentage;
    }

    public setReteicaPercentage(reteicaPercentage: number): void {
        this.reteicaPercentage = reteicaPercentage;
    }

    public getReteftePercentage(): number {
        return this.reteftePercentage;
    }

    public setReteftePercentage(reteftePercentage: number): void {
        this.reteftePercentage = reteftePercentage;
    }

    public getRetefteValue(): number {
        return this.retefteValue;
    }

    public setRetefteValue(retefteValue: number): void {
        this.retefteValue = retefteValue;
    }

    public getReteicaValue(): number {
        return this.reteicaValue;
    }

    public setReteicaValue(reteicaValue: number): void {
        this.reteicaValue = reteicaValue;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }


}
