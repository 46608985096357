import { Component, Input, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorage } from '@angular/fire/storage';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';

@Component({
  selector: 'app-item-legal-terms',
  templateUrl: './item-legal-terms.component.html',
  styleUrls: ['./item-legal-terms.component.scss'],
  providers: [AuthService]
})
export class ItemLegalTermsComponent {

  @Input() terms;
  @Input() title;
  @ViewChild('modalTemrs', { static: false }) modalTemrs;
  instanceModalTemrs: NgbModalRef;
  constructor(
    public utils: Utils,
    private modalService: NgbModal,
    private angularFireStorage: AngularFireStorage,
    public snackBarService: SnackBarService,
    public spinner: NgxSpinnerService,
  ) { }

  openTerms() {
    const optionsModal = {
      centered: true,
      size: 'lg',
      autoFocus: false,
    };
    this.instanceModalTemrs = this.modalService.open(this.modalTemrs, optionsModal);
  }

  closeTerms() {
    (this.instanceModalTemrs as any).close();
  }

  downloadTerms() {
    this.spinner.show();
    let pathReference = this.angularFireStorage.ref('teclogi/termsAndConditions/drafts/' + (this.terms.path || this.terms.url));
    pathReference.getDownloadURL().subscribe(
      (data) => {
        this.spinner.hide();
        window.open(data);
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
      }
    );
  }

}
