import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { StandardFileService } from './standard-file.service';
import { Utils } from 'src/app/core/resources/utils';
import { FileNamePipe } from 'src/app/core/pipe/fileName.pipe';
import { Patterns } from 'src/app/core/resources/patterns';
@Component({
  selector: 'app-standard-file',
  templateUrl: './standard-file.component.html',
  styleUrls: ['./standard-file.component.scss'],
  providers: [StandardFileService, FileNamePipe]
})
export class StandardFileComponent implements OnInit {

  @Input() inputFormControl: FormControl;
  @Input() options: { title, path, type, storage, pattern, required, format, storageName, firebasePath };
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Output() fileState: EventEmitter<any> = new EventEmitter();
  file: FileStorage = {
    storageData: {},
    fileData: {}
  };
  visualFileName: FormControl = new FormControl();
  formatError: boolean = false;
  constructor(
    public utils: Utils,
    public fileNamePipe: FileNamePipe,
    public patterns: Patterns
  ) {

  }

  ngOnInit() { }
  handleFileInput($event: Event) {
    if ($event.target['files'].length) {
      const splittedNameFile = $event.target['files'][0]['name'].split('.');
      const formatFile = splittedNameFile[splittedNameFile.length - 1];
      const originalSplitFileName = $event.target['files'][0]['name'].replace(/\s+/g, ' ').trim();
      const dateInMs = new Date().getTime();
      this.file.fileData.name = this.options && this.options.storageName ? `${this.options.storageName}_${dateInMs}.${formatFile}` : originalSplitFileName
      const modifiedFile = new File([$event.target['files'][0]], this.file.fileData.name, {
        type: $event.target['files'][0].type
      });
      this.file.fileData.file = modifiedFile;
      this.visualFileName.setValue(this.userViewFileName(this.file.fileData.name));
      this.formatError = formatFile && this.options && this.options.format && formatFile !== this.options.format;
      if (this.formatError) {
        this.fileState.emit({ error: 'formatError' })
        return;
      } else this.fileState.emit('success');
      this.file.fileData.uploaded = true;
      this.file.fileData.size = this.utils.bytesToSize(this.file.fileData.file.size);
      this.inputFormControlValue = this.file;
      this.emitToParentData = this.file.fileData.file;
    }
  }

  showFileName(file: string) {
    if (this.patterns.FILE_FORMAT_FULL.test(file) || this.patterns.FILE_FORMAT.test(file)) this.visualFileName.setValue(this.userViewFileName(file));
    else this.visualFileName.setValue(file);
  }

  set inputFormControlValue(file: FileStorage) {
    if (this.inputFormControl) {
      if (this.options && this.options.storage) {
        this.inputFormControl.setValue(`${this.options.path}/${file.fileData.name}`);
      } else {
        this.inputFormControl.setValue(file);
      }
    }
  }

  set emitToParentData(data: any) {
    if (this.emitToParent) {
      this.emitToParent.emit(data);
    }
  }

  userViewFileName(fileName: string) {
    return this.fileNamePipe.transform(fileName);
  }
}
