import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TypeMerchandiseService {


    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
    ) { }


    public searchMerchandise(textSearch: string) {
        return this.http.get<{id, description}[]>(
            environment.urlServerTeclogi + this.endpointResources.searchMerchandise + textSearch
        );
    }

}
