<div class="m-0 ml-1 mx-sm-5 py-4 mb-0 h-100-margin">
  <div class="title-logs-container d-flex flex-row align-items-center mb-3">
    <i class="fas fa-desktop mr-3"></i>
    <h3 class="title text-bold">Logs del día</h3>
  </div>
  <div class="d-flex justify-content-between align-items-center flex-row mb-3">
    <mat-button-toggle-group [formControl]="selectedDateControl">
      <mat-button-toggle *ngFor="let option of sortOrderOptions" [value]="option.value">
        <span class="d-none d-lg-inline color-primary-dark">{{ option.label }}</span>
        <i class="fas d-lg-none color-primary-dark" [ngClass]="option.icon"></i>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-button-toggle-group [formControl]="selectedLogLevelControl">
      <mat-button-toggle *ngFor="let option of logLevelOptions" [value]="option.value">
        <span class="d-none d-lg-inline color-primary-dark">{{ option.label }}</span>
        <i class="fas d-lg-none color-primary-dark" [ngClass]="option.icon"></i>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf="formatLogsArray.length" class="card-container mt-3 p-4">
    <div class="mat-card-container h-100 overflow-auto">
      <mat-card *ngFor="let log of formatLogsArraySlice" class="mb-2">
        <mat-card-content>
          <span>{{ log.date }}</span>
          <div>{{ log.remainingText }}</div>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-paginator #paginator [ngClass]="{'display-none':formatLogsArray.length === 0}"
      [length]="formatLogsArray.length" [pageSize]="pageSize" [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)" showFirstLastButtons>
    </mat-paginator>
  </div>
  <div *ngIf="!formatLogsArray.length" class="d-flex align-items-center justify-content-center flex-column h-100">
    <i class="fas fa-calendar-times mb-4"></i>
    <h4 class="text-center text-bold">No hay logs relacionados con la selección</h4>
  </div>
</div>