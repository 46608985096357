import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { User } from 'src/app/core/interfaces/user';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { CargoReportService } from 'src/app/core/services/cargoReport.service';

@Component({
  selector: 'app-vehicle-trips',
  templateUrl: './vehicle-trips.component.html',
  styleUrls: ['./vehicle-trips.component.scss'],
  providers: [CargoReportService]
})
export class VehicleTripsComponent implements OnInit {
  numberOfTrips = 0;
  @Input() vehicle: Vehicle;
  @Input() driver: User;

  /**
  * @description Checks the vehicle's or driver's number of trips and updates numberOfTrips local variable
  */
  ngOnInit() {
    if (this.vehicle && this.vehicle.numberOfTrips) this.numberOfTrips = this.vehicle.numberOfTrips;
    else if (this.driver && this.driver.numberOfTrips) this.numberOfTrips = this.driver.numberOfTrips;
  }

  /**
  * @param {SimpleChanges} changes are the changes from inputs 
  * @description Checks the vehicle or driver's number of trips and updates numberOfTrips local variable
  */
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.driver && changes.driver.currentValue && changes.driver.currentValue.numberOfTrips)
      this.numberOfTrips = changes.driver.currentValue.numberOfTrips;
    if (changes && changes.vehicle && changes.vehicle.currentValue && changes.vehicle.currentValue.numberOfTrips)
      this.numberOfTrips = changes.vehicle.currentValue.numberOfTrips;
  }
}
