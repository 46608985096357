import { Component, Input, OnChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ExportListService } from './export-list.service';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
    selector: 'app-export-list',
    templateUrl: './export-list.component.html',
    styleUrls: ['./export-list.component.scss'],
    providers: [ExportListService]
})

export class ExportListComponent implements OnChanges {

    @Input() typeFileExport: { format, name, contentType };
    @Input() typeList: string;
    @Input() idExport: number;
    @Input() nitCompany: number;
    @Input() params: { dateLoad, endDate, nit, exclusive, reportType };
    instanceModalOpen: NgbModalRef;
    enableButton: boolean;


    constructor(
        private spinner: NgxSpinnerService,
        public utils: Utils,
        private modalService: NgbModal,
        private exportListService: ExportListService,
        private snackBarService: SnackBarService,
    ) {

    }

    ngOnInit() {
        this.enableButton = this.utils.companyEnabledExport(this.nitCompany, this.idExport);
    }

    ngOnChanges(changes: any) {

    }

    openConfirmExportList(modalElement) {
        this.instanceModalOpen = this.modalService.open(modalElement, { centered: true, ariaLabelledBy: 'modal-basic-title' })
    }

    closeConfirmExportList() {
        this.instanceModalOpen.close();
    }

    onExportFile() {
        let service = null;
        this.spinner.show();
        this.exportListService.exportFile(this.idExport).subscribe(
            (response) => {
                this.closeConfirmExportList();
                this.spinner.hide();
                this.utils.downloadFile(response.body, this.typeList + DateManager.dateToString(new Date(), "MM-DD-YYYY-HH-mm"));
            },
            (error) => {
                this.spinner.hide();
                this.snackBarService.openSnackBar("Ocurrió un error generando el archivo", undefined, 'error');
            }
        );
    }

}