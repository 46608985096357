<div class="container">
  <div id="mainFreightTypeLoad" class="col-md-12 mb-md-0 mb-12">

    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          ¿Cuál es la medida del contenedor?
        </label>
      </div>
      <!-- Boxs -->
      <app-box-container-measure [containers]="modelCargo.containers" class="col-md-12 mb-md-0 mb-12"
        [measureContainerSelect]="manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.containerMeasure.controls.size.value"
        (emitToParent)="selectTypeSize($event)"></app-box-container-measure>

      <!-- Button -->
      <div class="col-12 button-container justify-content-center mt-3 w-100">
        <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
        <button mat-raised-button class="bg-color-primary" (click)="nextStep()">Siguiente</button>
      </div>
    </div>
  </div>
</div>
