<div class="modal-body wrapper-modal">
  <!-- Title -->
  <p class="title mx-5">
    {{title}}
  </p>
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="closeDialog()"></i>
  <i *ngIf="!confirmBtn" (click)="downloadFile()" matTooltip="Descargar archivo"
    class="fa-sharp fas fa-file-export fa-2xl download-icon"></i>
  <i class="fas fa-check-square"></i>
  <embed *ngIf="fileURLReference" type="application/pdf" [src]="fileURLReference"
    (error)="onErrorRenderImage($event)" />
  <!-- Bttns -->
  <div class="alert alert-danger text-center" role="alert" *ngIf="alertMessage">
    {{alertMessage}}
  </div>
  <mat-card-actions *ngIf="confirmBtn && !alertMessage">
    <button mat-raised-button color="primary" class="button-width-auto" (click)="downloadFile()">
      {{confirmBtn ? confirmBtn :'Descargar'}}
    </button>
  </mat-card-actions>
</div>