<div class="main-container">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="cards-container">
        <mat-card-header class="title">
            {{title}}
        </mat-card-header>
        <mat-card-content class="content" *ngIf="!license">
            <div class="card" *ngFor="let cargo of cargos">
                {{cargo.licensePlate}}
            </div>
        </mat-card-content>
        <mat-card-content class="content" *ngIf="license">
            <div *ngFor="let data of license" class="container-card-detail"
                [ngClass]="{'color-red': data.isLicenseExpirationDate}">
                <div>
                    <span class="title-item">Categoria: </span>
                    <div>{{data.category}}
                    </div>
                </div>
                <div>
                    <span class="title-item">Numero licencia: </span>
                    <div>{{data.numberLicense}}
                    </div>
                </div>
                <div>
                    <span class="title-item">Restricción: </span>
                    <div>{{data.restrictions}}
                    </div>
                </div>
                <div>
                    <span class="title-item">Fecha de expedición: </span>
                    <div>{{data.expeditionDate | date:'fullDate'}}
                    </div>
                </div>
                <div>
                    <span class="title-item">Fecha de expiración: </span>
                    <div>{{data.expirationDate | date:'fullDate'}}
                    </div>
                </div>
                <div>
                    <span class="title-item">Estado: </span>
                    <div>{{data.active? 'Activo': 'Inactivo'}}
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions class="center">
            <button mat-raised-button color="primary" type="button" class="button-width-auto" (click)="closeDialog()">
                Cerrar
            </button>
        </mat-card-actions>
    </div>
</div>