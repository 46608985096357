import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-time-to-upload-evidences',
  templateUrl: './time-to-upload-evidences.component.html',
  styleUrls: ['./time-to-upload-evidences.component.scss']
})
export class TimeToUploadEvidencesComponent implements OnInit {
  cargo: Cargo;
  secondsLeft: number = 0;
  timeLeft: string = '00:00:00';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { cargo } | undefined,
    private dialog: MatDialogRef<TimeToUploadEvidencesComponent>
  ) {
    this.dialog.disableClose = true;
    this.cargo = this.data.cargo;
    this.getLeftTime();
  }

  ngOnInit() {
  }

  getLeftTime() {

    const formatDate: any = DateManager.stringToDate(this.cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate);
    const formatCurrentDate: any = new Date();
    const secondsCalculate = Math.abs(formatDate - formatCurrentDate) / 1000;
    const estimatedTime = this.cargo.estimatedTime;
    const validTime = estimatedTime * (4 / 5);
    this.secondsLeft = validTime - secondsCalculate > 0 ? validTime - secondsCalculate : 0;
    this.timeLeft = DateManager.secondsToCronometer(this.secondsLeft);
    if (this.secondsLeft > 0)
      setTimeout(() => this.getLeftTime(), 1000);
  }

  close() {
    this.dialog.close(this.secondsLeft);
  }
}
