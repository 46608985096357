<div class="modal-body wrapper-modal">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <p class="title">
        {{data.title}}
    </p>
    <mat-form-field appearance="outline">
        <mat-label>Nombre<span class="asterisk">*</span></mat-label>
        <input matInput type="text" [formControl]="inputTag">
        <mat-error *ngIf="inputTag.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="inputTag.errors?.minlength">
            {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'nombre', minLengthTag) }}
        </mat-error>
        <mat-error *ngIf="inputTag.errors?.maxlength">
            {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'nombre', maxLengthTag) }}
        </mat-error>
    </mat-form-field>
    <p class="alert alert-danger text-bold text-center" role="alert" *ngIf="tagError">
        {{tagError}}
    </p>
    <div class="button-container">
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
            (click)="dialogRef.close()">
            Cancelar
        </button>
        <button mat-raised-button color="primary" class="button-width-auto bg-color-primary" (click)="confirm()">
            {{data.confirmBtn}}
        </button>
    </div>
</div>