import { Component, Inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { AngularFireStorage } from '@angular/fire/storage/storage';
import { AccountService } from '../account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LegalTermsService } from '../../administration/legal-terms/legal-terms.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { TermsAndConditions } from 'src/app/core/interfaces/termsAndConditions';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TermsAndConditionsMessages } from 'src/app/core/messages/terms-and-conditions-messages.enum';

@Component({
  selector: 'app-validate-terms',
  templateUrl: './validate-terms.component.html',
  styleUrls: ['./validate-terms.component.scss'],
  providers: [AuthService, Global, AccountService, AngularFireStorage]
})
export class ValidateTermsComponent {

  termsActive: TermsAndConditions;
  termsSuscription: Subscription;
  response: boolean = false;
  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private termsAndConditionsService: LegalTermsService,
    private snackBarService: SnackBarService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    private data: { create } | undefined,
    public dialogRef: MatDialogRef<ValidateTermsComponent>,
  ) {
    if (
      (this.authService.getUserSession().state.active && this.authService.getUserSession().lastUserTerm.state) ||
      (!this.authService.getUserSession().state.active)
    ) {
      const isRegister = !!this.data && !!this.data.create;
      if (isRegister || (!this.authService.getUserSession().state.active)) {
        this.getTermsAndConditionsActive();
      } else {
        this.router.navigate(['/home']);
      }
    } else {
      if (this.authService.getUserSession()) {
        this.getTermsAndConditionsActive();
      } else {
        this.authService.clearSession();
        this.router.navigate(['/account/login']);
      }
    }
  }

  getTermsAndConditionsActive() {
    this.spinner.show();
    this.termsAndConditionsService.getTermsActive().subscribe(
      (success: TermsAndConditions[]) => {
        this.spinner.hide();
        if (success && success.length) {
          this.termsActive = success[0];
        }
        this.response = true;
      },
      (error) => {
        this.response = true;
        this.spinner.hide();
      }
    );
  }

  confirmTerms() {
    const isRegister = !!this.data && !!this.data.create;
    if (!isRegister) {
      this.spinner.show();
      const bodyTerms: TermsAndConditions = {
        type: this.termsActive && this.termsActive.type ? this.termsActive.type : '',
      }
      this.termsSuscription = this.authService.userClientUpdateTerms(
        this.authService.getUserSession().information.document,
        bodyTerms
      ).subscribe(
        (success) => {
          this.spinner.hide();
          this.router.navigate(['/home']);
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
        },
        () => {
          this.termsSuscription.unsubscribe();
        }
      );
    } else {
      this.dialogRef.close(this.termsActive);
    }
  }
}
