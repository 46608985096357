import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoutesRoutingModule } from './routes-routing.module';
import { NewsComponent } from './news/news.component';
import { UnsafeConditionsComponent } from './unsafe-conditions/unsafe-conditions.component';
import { SitesComponent } from './sites/sites.component';
import { SharedModule } from 'src/app/core/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import { PlanningRouteListComponent } from './planning-route-list/planning-route-list.component';
import { PlanningFormComponent } from './planning-form/planning-form.component';
import { CompleteAddressComponent } from './components/complete-address/complete-address.component';
import { ConfirmRouteCreationComponent } from './planning-form/confirm-route-creation/confirm-route-creation.component';
import { AuthorizedSitesFormComponent } from './sites/authorized-sites-form/authorized-sites-form.component';
import { CityLocationFormComponent } from './planning-form/city-location-form/city-location-form.component';
import { RoutesMapComponent } from './routes-map/routes-map.component';

@NgModule({
  declarations: [
    NewsComponent,
    UnsafeConditionsComponent,
    SitesComponent,
    PlanningRouteListComponent,
    PlanningFormComponent,
    CompleteAddressComponent,
    ConfirmRouteCreationComponent,
    AuthorizedSitesFormComponent,
    CityLocationFormComponent,
    RoutesMapComponent
  ],
  imports: [
    CommonModule,
    RoutesRoutingModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    DragDropModule,
    MatTableModule,
    MatSortModule,
    SharedModule.forRoot(),
  ],
  bootstrap: [
    ConfirmRouteCreationComponent,
    AuthorizedSitesFormComponent,
    CityLocationFormComponent
  ]
})
export class RoutesModule { }
