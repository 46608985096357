import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactUser } from 'src/app/core/interfaces/contactUser';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AdminUsersService } from '../../admin-users/admin-users.service';
import { VehiclesService } from '../list-vehicles.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { User } from 'src/app/core/interfaces/user';
import { Permission } from 'src/app/core/resources/permission';
import { Fmt } from 'src/app/core/messages/fmt';
import { Patterns } from 'src/app/core/resources/patterns';

@Component({
  selector: 'app-vehicle-contacts',
  templateUrl: './vehicle-contacts.component.html',
  styleUrls: ['./vehicle-contacts.component.scss']
})
export class VehicleContactsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() fromCreation: boolean;
  @Input() driver: User;
  @Output() emitDriver: EventEmitter<any> = new EventEmitter();
  permission = Permission;
  constructor(
    private vehiclesService: VehiclesService,
    public utils: Utils,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private adminUsersService: AdminUsersService,
    private patterns: Patterns,
  ) { }

  /**
  * @description Checks if is called from creation or not, if is not executes the setValidators method
  */
  ngOnInit() {
    if (!this.fromCreation) {
      this.setValidators();
    }
  }

  /**
  * @description Sets validators to each FormGroups
  */
  private setValidators() {
    this.referenceLaboralControls.get('name').setValidators(Validators.required);
    this.referenceLaboralControls.get('phone').setValidators([Validators.required, Validators.pattern(this.patterns.PHONE.source)]);
    this.referencePersonalControls.get('name').setValidators(Validators.required);
    this.referencePersonalControls.get('lastName').setValidators(Validators.required);
    this.referencePersonalControls.get('phone').setValidators([Validators.required, Validators.pattern(this.patterns.PHONE.source)]);
    this.emergencyContactControls.get('name').setValidators(Validators.required);
    this.emergencyContactControls.get('lastName').setValidators(Validators.required);
    this.emergencyContactControls.get('phone').setValidators([Validators.required, Validators.pattern(this.patterns.PHONE.source)]);
  }

  /**
  * @returns {FormGroup} returns the driver.referenceLaboral form group
  * @description Gets the driver.referenceLaboral as FormGroup
  */
  get referenceLaboralControls(): FormGroup {
    return this.form.get('driver.referenceLaboral') as FormGroup;
  }

  /**
  * @returns {FormGroup} returns the driver.referencePersonal form group
  * @description Gets the driver.referencePersonal as FormGroup
  */
  get referencePersonalControls(): FormGroup {
    return this.form.get('driver.referencePersonal') as FormGroup;
  }

  /**
  * @returns {FormGroup} returns the driver.emergencyContact form group
  * @description Gets the driver.emergencyContact as FormGroup
  */
  get emergencyContactControls(): FormGroup {
    return this.form.get('driver.emergencyContact') as FormGroup;
  }

  /**
  * @description Validates if every formGroup is valid and updates them with backend service
  */
  public onSubmit(type: string) {
    if (type === 'edit') {
      if (this.utils.errorMessagesCustomized(this.emergencyContactControls.get('name'), 'nombre del contacto de emergencia')) return;
      if (this.utils.errorMessagesCustomized(this.emergencyContactControls.get('lastName'), 'apellido del contacto de emergencia')) return;
      if (this.utils.errorMessagesCustomized(this.emergencyContactControls.get('phone'), 'celular del contacto de emergencia')) return;
      if (this.utils.errorMessagesCustomized(this.referenceLaboralControls.get('name'), 'empresa de la referencia laboral')) return;
      if (this.utils.errorMessagesCustomized(this.referenceLaboralControls.get('phone'), 'celular de la referencia laboral')) return;
      if (this.utils.errorMessagesCustomized(this.referencePersonalControls.get('name'), 'nombre de la referencia personal')) return;
      if (this.utils.errorMessagesCustomized(this.referencePersonalControls.get('lastName'), 'apellido de la referencia personal')) return;
      if (this.utils.errorMessagesCustomized(this.referencePersonalControls.get('phone'), 'celular de la referencia personal')) return;
    }
    else {
      this.emergencyContactControls.reset();
      this.referencePersonalControls.reset();
      this.referenceLaboralControls.reset();
    }
    this.spinner.show();
    const data = {
      emergencyContact: this.emergencyContactControls.value,
      referencePersonal: this.referencePersonalControls.value,
      referenceLaboral: this.referenceLaboralControls.value
    };
    this.adminUsersService.updateUserContacts(this.form.get('driver').value.information.document, data).subscribe(
      (success: any) => {
        this.spinner.hide();
        if (type === 'edit') this.snackBarService.openSnackBar(ServiceMessages.REFERENCES_SUCCESS_EDIT, undefined, 'success');
        else this.snackBarService.openSnackBar(ServiceMessages.REFERENCES_SUCCESS_DELETE, undefined, 'success');
        this.emitDriver.emit(success);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

}
