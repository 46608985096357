<div class="filters-container">
  <div class="row mt-4">
    <mat-form-field appearance="outline" class="col-md-6">
      <mat-label>Desde</mat-label>
      <input matInput [max]="optionsDateFrom.maxDate" [matDatepicker]="pickerDateFrom" [formControl]="dateFrom"
        (dateChange)="onChangeDateFrom($event)">
      <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerDateFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-6">
      <mat-label>Hasta</mat-label>
      <input matInput [max]="optionsDateUntil.maxDate" [min]="optionsDateUntil.minDate"
        [matDatepicker]="pickerDateUntil" [formControl]="dateUntil" (dateChange)="search()">
      <mat-datepicker-toggle matSuffix [for]="pickerDateUntil"></mat-datepicker-toggle>
      <mat-datepicker #pickerDateUntil></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-6 cursor-pointer">
      <mat-label>Tipo de métrica</mat-label>
      <mat-select [formControl]="stateFilterRequestHistory" (selectionChange)="setHistoryState($event)" multiple>
        <mat-option *ngFor="let option of historyOptions" [value]="option.value">
          {{option.label}}
          <span *ngIf="selectedHistoryOption.includes(option?.value)">
            ({{ totalHistory && totalHistory[option.value]? totalHistory[option.value] : 0 }})
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="d-flex col-md-6">
      <button mat-raised-button class="flex-fill bg-color-primary" color="primary" title="Descargar reporte"
        (click)="getReport()">
        Descargar <span class="d-none d-sm-inline">Reporte</span>
      </button>
    </div>
  </div>
</div>