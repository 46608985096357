<div class="scroller-container">
    <div class="row d-flex align-items-start justify-content-start m-1 m-md-4">
        <div class="col-md-4">
            <mat-form-field appearance="standard">
                <mat-label>Recurso</mat-label>
                <mat-select [formControl]="resourceControl" (selectionChange)="changeSelect()">
                    <mat-option *ngFor="let val of resources" [value]="val">
                        {{val.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="resourceControl.value.name === 'Logo principal'">
                <label for="images" class="drop-container" id="dropcontainer" (drop)="onDrop($event)"
                    (dragover)="onDrag($event,true)" (dragleave)="onDrag($event,false)">
                    <span class="drop-title">Arrastra y suelta el recurso</span>
                    <input type="file" id="images" class="d-none"
                        (change)="setInstanceFileStorage($event.target['files'])" name="logo" accept="image/*">
                </label>
            </div>
            <div *ngIf="resourceControl.value.name === 'Camara de comercio'">
                <label for="tradeLicense" class="drop-container" id="dropcontainer" (drop)="onDrop($event)"
                    (dragover)="onDrag($event,true)" (dragleave)="onDrag($event,false)">
                    <span class="drop-title">Arrastra y suelta el recurso</span>
                    <input type="file" id="tradeLicense" class="d-none"
                        (change)="setInstanceFileStorage($event.target['files'])" name="tradeLicense"
                        accept="application/pdf">
                </label>
            </div>
            <div *ngIf="resourceControl.value.name === 'RUT'">
                <label for="tradeLicense" class="drop-container" id="dropcontainer" (drop)="onDrop($event)"
                    (dragover)="onDrag($event,true)" (dragleave)="onDrag($event,false)">
                    <span class="drop-title">Arrastra y suelta el recurso</span>
                    <input type="file" id="tradeLicense" class="d-none"
                        (change)="setInstanceFileStorage($event.target['files'])" name="tradeLicense"
                        accept="application/pdf">
                </label>
            </div>
            <mat-card class="container-responsible mb-3" *ngIf="companyResource?.fingerPrint">
                <div class="row">
                    <div class="container-title-responsible col-md-12">
                        <span class="text-responsible">¿Quién subió el recurso?</span>
                    </div>
                </div>
                <div class="row content-responsible">
                    <div class="col-md-12">
                        <div class="item-responsible">
                            <div>Responsable del recurso</div>
                            <div class="item-price">
                                {{companyResource?.fingerPrint?.userName}}
                            </div>
                        </div>

                    </div>
                    <div class="col-md-12">
                        <div class="item-responsible">
                            <div>ID del responsable:</div>
                            <div class="item-price">
                                {{companyResource?.fingerPrint?.userId}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="item-responsible">
                            <div>Fecha de subida:</div>
                            <div class="item-price">
                                {{companyResource?.fingerPrint?.date |
                                dateFormat}}
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>

        <div class="col-md-8" *ngIf="preview && resourceControl?.value">
            <img *ngIf="resourceControl?.value?.name === 'Logo principal'" [src]="preview" alt="Recurso"
                class="w-100 h-auto">
            <object
                *ngIf="resourceControl?.value?.name === 'Camara de comercio' || resourceControl?.value?.name === 'RUT'"
                [data]="preview" type="application/pdf" style="width: 100%; height: 500px;">
                <p>Este navegador no soporta la visualización de PDFs.</p>
            </object>
        </div>
    </div>
</div>