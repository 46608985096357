import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Filter } from 'src/app/core/models/filter';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { ListFilterComponent } from 'src/app/shared/list-filter/list-filter.component';
import { CurrencyPipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Utils } from "src/app/core/resources/utils";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { CargoDetailComponent } from "../cargo-detail/cargo-detail.component";
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';

@Component({
  selector: 'app-global-tracking-list',
  templateUrl: './global-tracking-list.component.html',
  styleUrls: ['./global-tracking-list.component.scss'],
  providers: [CurrencyPipe]
})
export class GlobalTrackingListComponent implements OnInit {
  @ViewChild(ListFilterComponent, { static: false }) listFilterComponent: ListFilterComponent;
  @Input() public cargos: Cargo[] = [];
  @Input() public cargoSelected: Cargo;
  @Output() onSelectCargo: EventEmitter<Cargo> = new EventEmitter();
  @Output() onFilterChanges: EventEmitter<string> = new EventEmitter();
  typeList = "globalTracking";
  optionsFilter: Filter;
  constructor(
    public service: FreightListService,
    public utils: Utils,
    public matDialog: MatDialog,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('_lastList', this.typeList.toString());
    this.optionsFilter = this.service.getOptionsFilter(this.typeList);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.cargos && changes.cargos.currentValue) {
      this.cargos = this.cargos.filter(cargo => cargo && cargo.licensePlate);
    }
  }
  emitCargoSelected(cargo) {
    this.onSelectCargo.emit(this.cargoSelected && cargo.consecutive === this.cargoSelected.consecutive ? null : cargo);
  }
  openDetailCargo(cargo: Cargo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.utils.clone(cargo),
    };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(CargoDetailComponent, dialogConfig);
    const dialogComponent = dialogRef.componentInstance;
  }

  get cargoStateEnum(): typeof CargoStateEnum {
    return CargoStateEnum;
  }
}
