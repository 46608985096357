import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Utils } from 'src/app/core/resources/utils';


@Component({
  selector: 'app-contact-person-vehicle',
  templateUrl: './contact-person-vehicle.component.html',
  styleUrls: ['./contact-person-vehicle.component.scss']
})
export class ContactPersonVehicleComponent {
  @Input() title: string;
  @Input() form: FormGroup;
  constructor(
    private permissionRole: PermissionRole,
    public utils: Utils
  ) { }

  /**
  * @returns {boolean} returns true if the user has permission to validate and remark vehicle references
  * @description Verifies the permission to validate and remark vehicle references
  */
  get canValidateAndRemarkVehicleReferences(): boolean {
    return this.permissionRole.hasPermission(Permission.administration.module, Permission.administration.validateAndRemarkVehicleReferences)
  }

}
