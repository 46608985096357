import { BodyWorkType } from "./body-work-type.model";

export class VehicleType {

    private name: string;
    private quantity: number;
    private bodyWorkType: BodyWorkType;

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getQuantity(): number {
        return this.quantity;
    }

    public setQuantity(quantity: number): void {
        this.quantity = quantity;
    }

    public getBodyWorkType(): BodyWorkType {
        return this.bodyWorkType || new BodyWorkType();
    }

    public setBodyWorkType(bodyWorkType: BodyWorkType): void {
        this.bodyWorkType = new BodyWorkType();
        this.bodyWorkType.deserialize(bodyWorkType);
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
