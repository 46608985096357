export class TicketTypes {

  constructor() { }

  public types = [
    { title: 'Problema técnico', value: 'Technical Issue', description: 'Son problemas referentes a los equipos y al hardware', icon: "fas fa-cog" },
    { title: 'Incidente en la plataforma web', value: 'Web Issue', description: 'Son problemas ocurridos en la página web de Teclogi', icon: "fas fa-laptop" },
    { title: 'Incidente en la plataforma movil', value: 'Mobile Issue', description: 'Son problemas ocurridos en la aplicación movil de Teclogi', icon: "fas fa-mobile" },
    { title: 'Sugerencias', value: 'Sugestion', description: 'Sugerencias', icon: 'fas fa-comments' },
    { title: 'Reclamos', value: 'Claim', description: 'Reclamos', icon: "fas fa-exclamation-circle" },
    { title: 'Otros', value: 'Others', description: 'Ticket general', icon: "fas fa-ticket-alt" }
  ]
}
