import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { ServerRequest } from 'src/app/core/interfaces/serverRequest';

@Injectable({
  providedIn: 'root'
})
export class FingerPrintsService {

  constructor(
    private endpointResources: Endpoints,
    private http: HttpClient
  ) { }



  getFingerPrints(userDocument?: string, date?: string, statusCode?: string) {
    let params = new HttpParams();
    let paramsFilter = '';
    const data = {
      userDocument,
      date,
      statusCode
    };
    for (const [key, value] of Object.entries(data)) {
      if (value) params = params.append(key, value);
    }
    if (paramsFilter) {
      if (paramsFilter.startsWith('&')) paramsFilter = paramsFilter.replace('&', '').trim();
    }
    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpointResources.fingerPrints}`, { params }
    );
  }

  getCode(code) {
    return this.statusCodeList[code] ? this.statusCodeList[code] : null;
  }

  get statusCodeList() {
    //Dispuestos en formato "Código: [Alias, Descripción, Concurrente?]"
    return {
      //Informativas
      100: ["Continue", "Solicitud pendiente de continuación"],
      101: ["Switching Protocol", "Cambio de protocolo por parte del cliente"],
      102: ["Processing", "Solicitud recibida y en proceso"],
      103: ["Early Hints", "Solicitud de pre-carga de recursos"],
      //Exitosas
      200: ["OK", "Solicitud exitosa", true],
      201: ["Created", "Solicitud de creación de recurso exitosa", true],
      202: ["Accepted", "Solicitud aceptada y en proceso", true],
      203: ["Non-auth information", "Solicitud exitosa extraida de otra fuente"],
      204: ["No Content", "Solicitud exitosa sin contenido", true],
      205: ["Reset Content", "Solicitud exitosa sin contenido, se requiere recargar"],
      206: ["Partial Content", "Solicitud servida parcialmente"],
      207: ["Multi-Status", "Solicitud con múltiples resultados"],
      208: ["Multi-Status", "Solicitud con múltiples resultados"],
      226: ["IM Used", "Solicitud GET exitosa de múltiples instancias"],
      //Redirecciones
      300: ["Multiple Choice", "Solicitud de múltiple respuesta, el usuario debe escoger"],
      301: ["Moved Permanently", "URI de la solicitud removida", true],
      302: ["Found", "URI de la solicitud cambiada temporalmente"],
      303: ["See Other", "Solicitud GET redirigida a otra dirección"],
      304: ["Not Modified", "Respuesta no modificada, usar guardada en caché"],
      307: ["Temporary redirect", "Solicitud redirigida temporalmente"],
      308: ["Permanent redirect", "Solicitud redirigida permanentemente"],
      //Errores del cliente
      400: ["Bad Request", "Solicitud imposible de interpretar", true],
      401: ["Unauthorized", "Se requiere autenticación del usuario", true],
      403: ["Forbidden", "Se requiere permisos adicionales", true],
      404: ["Not Found", "No es posible encontrar el recurso", true],
      405: ["Method Not Allowed", "El método no puede ser utilizado", true],
      407: ["Proxy Auth Required", "Se requiere autenticación del usuario a partir del proxy"],
      408: ["Request Timeout", "Conexión inactiva demasiado tiempo", true],
      409: ["Conflict", "Petición en conflicto con el servidor"],
      410: ["Gone", "Contenido borrado del servidor"],
      411: ["Length required", "El encabezado 'Content-Length' no está definido"],
      412: ["Precondition Failed", "El encabezado tiene pre-condiciones no cumplidas"],
      413: ["Payload too large", "Petición demasiado larga"],
      414: ["URI too long", "URI demasiado larga"],
      415: ["Unsupported Media type", "Formato múltimedia no soportado"],
      416: ["Request range not satisfiable", "El encabezado 'Range' fue excedido"],
      417: ["Expectation Failed", "El encabezado 'Expect' fue excedido"],
      429: ["Too many requests", "Demasiadas peticiones realizadas"],
      430: ["Request Header Fields too large", "Campos del encabezado demasiado extensos"],
      451: ["Unavailable for legal reasons", "Recurso censurado"],
      //Errores del servidor
      500: ["Internal server error", "Solicitud imposible de manejar", true],
      501: ["Not implemented", "Solicitud no soportada"],
      502: ["Bad Gateway", "Solicitud no exitosa en la puerta de enlace"],
      503: ["Service unavailable", "Servicio no disponible", true],
      504: ["Gateway timeout", "Solicitud inactiva demasiado tiempo en la puerta de enlace"],
      505: ["HTTP version not supported", "Versión HTTP no soportada"],
      506: ["Variant Also negotiates", "Error en la configuración del servidor"],
      507: ["Insufficient storage", "Capacidad de almacenamiento insuficiente"],
      508: ["Loop detected", "El servidor detectó un ciclo infinito"],
      510: ["Not extended", "Extensiones adicionales requeridas"],
      511: ["Network auth required", "Se requiere autenticación del cliente"]
    }
  }

  getSemaphore(fingerprint: ServerRequest) {
    if (!fingerprint || !fingerprint.statusCode) return { color: "black" };
    let firstChar = fingerprint.statusCode.toString().charAt(0);
    return {
      color:
        firstChar === "1" ? "#23b6cf" //Informativo
          : firstChar === "2" ? "green"  // Exitoso
            : firstChar === "3" ? "orange"  //Redirigido
              : firstChar === "4" ? "red"  //Error del cliente
                : firstChar === "5" ? "red"  // Error del servidor
                  : "black" // No encontrado
    };
  }
}
