import { Component, OnInit, EventEmitter, Input, AfterContentChecked } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Utils } from 'src/app/core/resources/utils';
import { Router } from '@angular/router';
import { MatButtonToggleChange, MatDialog, MatDialogConfig, MatSelectChange } from '@angular/material';
import { CargoResources } from '../../resources/cargo';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';

import { DownloadService } from './download.service';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoFeature } from 'src/app/core/interfaces/cargoFeature';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { GoogleService } from 'src/app/core/services/google.service';
import { Patterns } from 'src/app/core/resources/patterns';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Companies } from 'src/app/core/resources/companies';

@Component({
  selector: 'app-download-freight',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
  providers: [AuthService, Global]
})

export class DownloadComponent implements OnInit {
  prueba2: any;
  timeDownload = [];
  times: any;
  stimated_time: any;
  timeList: Array<Object> = [];
  hourList: Array<object> = [];
  minuteList: Array<object> = [];
  public guide: string = "";
  formDownload = this.fb.group({
    number: new FormControl('', [Validators.minLength(9), Validators.required, Validators.pattern("^[0-9]*$")]),
    email: new FormControl('', [Validators.email, Validators.required, Validators.pattern(this.patterns.EMAIL.source), Validators.maxLength(100)])
  });
  minLengthCellphone: number = 9;
  maxLengthCellphone: number = 12;
  minTimePact: number = 1;
  constructor(
    public DownloadService: DownloadService,
    public patterns: Patterns,
    public manualCreationCargoService: ManualCreationCargoService,
    public utils: Utils,
    private router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public authService: AuthService,
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private googleService: GoogleService
  ) {
    this.timeList = this.utils.getListTime();
    this.hourList = this.utils.getListHour();
    this.minuteList = this.utils.getListMinute();
  }

  ngOnInit() {
    this.checkEstimatedTimes();
    this.setValidatorsDownload();
  }



  get destinations() {
    return this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination.controls;
  }

  private getAddressGroupActive(index: number, iDestination: number): AbstractControl {
    let ctrl: AbstractControl;
    if (index >= 0) {
      ctrl = this.manualCreationCargoService.cargoForm.get(`cargoFeature.uploadDownload.destination.${iDestination}.addresses.${index}`);
    }
    return ctrl;
  }
  checkEstimatedTimes() {
    const address: AddressCargo = this.getAddressGroupActive(0, 0).value;
    if (address) this.setValidationsTime(address, 0, 0);
  }

  setValidatorsDownload() {
    for (let control of this.manualCreationCargoService.formDestinations.controls) {
      let arrayAddresses = control.get('addresses') as FormArray;
      for (let addressControl of arrayAddresses.controls) {
        if (this.isTeclogi) {
          let randomTime = this.calculateTime;
          let emptyTimePact: boolean = !addressControl.get('timePact').value && !addressControl.get('minutePact').value;
          if (randomTime && randomTime === 60) emptyTimePact && addressControl.get('timePact').setValue(1);
          else emptyTimePact && addressControl.get('minutePact').setValue(randomTime);
        } else {
          addressControl.get('timePact').setValidators(Validators.min(this.minTimePact));
          !addressControl.get('timePact').value && addressControl.get('timePact').setValue(this.minTimePact);
        }
        addressControl.get('time').setValidators(Validators.required);
        addressControl.get('email').setValidators([Validators.pattern(this.patterns.EMAIL.source), Validators.maxLength(100)]);
        addressControl.get('cellphone').setValidators([Validators.minLength(this.minLengthCellphone), Validators.maxLength(this.maxLengthCellphone), Validators.pattern(this.patterns.CELLPHONEANDLANDLINE.source)]);
      }
    }
  }

  setUpperCase($event, iDestination, index) {
    (this.getAddressGroupActive(index, iDestination) as FormGroup).get('email').setValue($event.srcElement.value.toLowerCase());
  }

  public onKeyUpGuideId($event: any, iDestination: number, index: number, indexGuideId: number): void {
    this.getAddressGroupActive(index, iDestination).get(`guidesId.${indexGuideId}.value`).setValue(
      $event.target.value
    );
  }

  public addGuide(iDestination: number, index: number) {
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[iDestination].addresses[index]['guidesId']
      .push(this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[iDestination].addresses[index]['guidesId'][0]));
    this.refreshFormCargo();
  }

  removeGuideIdField(iDestination: number, index: number, indexGuideId: number) {
    (this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.destination.' + iDestination + '.addresses.' + index + '.guidesId') as FormArray).removeAt(indexGuideId);
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[iDestination].addresses[index]['guidesId'].splice(index, 1);
    this.refreshFormCargo();
  }

  public refreshFormCargo() {
    const cloneValue = this.utils.clone(this.manualCreationCargoService.cargoForm.value);
    this.manualCreationCargoService.refreshCargoForm(cloneValue);
    this.setValidatorsDownload();
  }

  findErrorOnDestinations() {
    let haveErrors = false;
    for (let [i, control] of this.manualCreationCargoService.formDestinations.controls.entries()) {
      let arrayAddresses = control.get('addresses') as FormArray;
      for (let [j, addressControl] of arrayAddresses.controls.entries()) {
        if (!haveErrors) {
          if (this.utils.errorMessagesCustomized(addressControl.get('time'), `hora de descargue del destino ${i + 1} de la dirección ${j + 1}`)) {
            haveErrors = true;
            break;
          }
          else if (this.utils.errorMessagesCustomized(addressControl.get('cellphone'), `celular del destino ${i + 1} de la dirección ${j + 1}`, this.minLengthCellphone, this.maxLengthCellphone)) {
            haveErrors = true;
            break;
          }
          else if (this.utils.errorMessagesCustomized(addressControl.get('email'), `correo del destino ${i + 1} de la dirección ${j + 1}`, null, 100)) {
            haveErrors = true;
            break;
          }
        } else break;
      }
      if (haveErrors) break;
    }
    return haveErrors;
  }

  async nextStep() {
    const isValidDates = await this.manualCreationCargoService.isValidAllDateTimeDowndload();
    if (!isValidDates) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.TIME_INVALID2, 'descargue'), undefined, 'alert');
    }
    else if (!this.manualCreationCargoService.isValidDownloadAddressesAdditionalData() || this.manualCreationCargoService.formDestinations.invalid) {
      if (!this.findErrorOnDestinations()) this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'error');
    }
    else if (!this.activeStimatedTimeAlert()) {
      this.manualCreationCargoService.showMessageWrongDuration();
    }
    else {
      this.manualCreationCargoService.getComponentActive().setId(3);
      this.manualCreationCargoService.getComponentActive().getStep().setId(3);
      this.router.navigate([this.manualCreationCargoService.steps.additionalService.url]);
    }
  }

  activeStimatedTimeAlert() {
    const destinations = this.manualCreationCargoService.getCargoForm().get('cargoFeature.uploadDownload.destination').value;
    let valid = true;
    destinations.forEach((destination, indexDestination) => {
      destination.addresses.forEach((address, index) => {
        if (this.manualCreationCargoService.getEstimatedTimeDownload(indexDestination, index, address.time) !== false) valid = false;
      })
    });
    return valid;
  }

  async createCargo() {
    const isValidDates = await this.manualCreationCargoService.isValidAllDateTimeDowndload();
    if (!isValidDates) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.DATE_INVALID2, 'descargue'), undefined, 'alert');
    }
    else if (!this.manualCreationCargoService.isValidAdditionalCosts()) {
      let index = -1
      if (this.manualCreationCargoService.formOriginadditionalCosts.value.length) {
        index = this.manualCreationCargoService.formOriginadditionalCosts.value.findIndex((addCost) => !addCost.cost && !addCost.rate);
      }
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, index !== -1
        ? `tarifa o costo del servicio adicional ${index + 1}`
        : "tarifa o costo de algún servicio adicional"), undefined, 'alert');
    }
    else {
      let path = '/cargo/list/request';
      this.spinner.show();
      this.manualCreationCargoService.processCargo()
        .then((data: Cargo[]) => {
          this.manualCreationCargoService.showResponseCreateCargo(
            'Tu solicitud ha sido creada exitosamente con los consecutivos',
            undefined,
            this.manualCreationCargoService.getConsecutive(data), path
          );
        })
        .catch((error) => {
          this.manualCreationCargoService.showResponseCreateCargo(
            'Ocurrió un error al crear la solicitud',
            (Array.isArray(error) ? error : undefined)
          );
        })
        .finally(() => {
          this.spinner.hide();
        });
    }
  }

  get addresses() {
    return (this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.origin.addresses') as FormArray).controls;
  }

  get addressesDst() {
    return (this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.destination') as FormArray).controls;
  }


  convertupload() {
    this.times = this.DownloadService.convert24(this.addresses).toString();
  }

  private get cargoFeature(): CargoFeature {
    return this.manualCreationCargoService.cargoForm.value.cargoFeature;
  }

  private get dateLoad(): string {
    return this.manualCreationCargoService.cargoForm.value.dateLoad;
  }

  get hasManifest(): boolean {
    return !!this.manualCreationCargoService.cargoForm.value.ministry;
  }

  private getAddresses(): Array<AddressCargo> {
    const origins = this.utils.clone(this.cargoFeature.uploadDownload.origin);
    const destinations = this.utils.clone(this.cargoFeature.uploadDownload.destination);
    const allAddresses = [];
    for (const i in origins.addresses) {
      origins.addresses[i].date = this.dateLoad;
      allAddresses.push(origins.addresses[i]);
    }
    destinations.forEach((destination, index: number) => {
      destination.addresses.forEach((address: AddressCargo, i: number) => {
        address.date = destination.downloadDate;
        address.destinationId = index;
        address.id = i.toString();
        allAddresses.push(address);
      });
    });
    return allAddresses;
  }

  public isValidTime(address, callback) {
    const addresses = this.getAddresses();
    const locations = [];
    for (const i in addresses)
      locations.push(addresses[i].location);
    const currentIndex = locations.findIndex(elem => elem.lat === address.location.lat && elem.lng === address.location.lng);
    if (currentIndex > 0) {
      this.googleService.calculateMinRouteTime(
        locations[currentIndex],
        locations[currentIndex - 1],
        (response, status) => {
          if (status !== 'OK')
            return;
          const times: Array<number> = [];
          const distance: Array<number> = [];
          const description: Object = {}
          response.routes.forEach(route => {
            route.legs.forEach(leg => {
              times.push(leg.duration.value);
              distance.push(leg.distance.value);
              description[`${leg.duration.value}`] = { duration: leg.duration.text, distance: leg.distance.text };
            });
          });
          const origin = addresses[currentIndex - 1];
          let timePact: number = (origin.timePact * 60 * 60) + (origin.minutePact * 60);
          let min = ((4 / 5) * Math.min(...times)) + timePact;
          const destination = addresses[currentIndex];
          const initialTime = DateManager.setTimeToDate(
            DateManager.stringToDate(origin.date.slice(0, 10)),
            parseInt(origin.time.split(':')[0]) + (origin.timeType === "PM" && parseInt(origin.time.split(':')[0]) < 12 ? 12 : 0),
            parseInt(origin.time.split(':')[1])
          );
          const finalTime = DateManager.setTimeToDate(
            DateManager.stringToDate(destination.date.slice(0, 10)),
            parseInt(destination.time.split(':')[0]) + (destination.timeType === "PM" && parseInt(destination.time.split(':')[0]) < 12 ? 12 : 0),
            parseInt(destination.time.split(':')[1])
          );
          const initialUTC = initialTime.getTime();
          const finalUTC = finalTime.getTime();
          const duration = (finalUTC - initialUTC) / 1000;
          const isValid: boolean = !((duration - min) < 0);

          callback(isValid, min);
          return;
        }
      );
    }
    callback(true, 0);
  }

  onSelectTime($event: MatSelectChange, key: string, iDestination: number, index: number) {
    this.getAddressGroupActive(index, iDestination).get(key).setValue($event.value);
    const addressControl = this.getAddressGroupActive(index, iDestination);
    const address: AddressCargo = addressControl.value;
    this.setValidationsTime(address, iDestination, index);
    //check next address if exists
    const addresses: AddressCargo[] = this.getAddresses();
    const currentIndex: number = addresses.findIndex(addressIn => addressIn.location.lat === address.location.lat && addressIn.location.lng === address.location.lng);
    if (currentIndex !== -1) {
      if (addresses && addresses[currentIndex + 1] && addresses[currentIndex + 1].time) {
        this.setValidationsTime(addresses[currentIndex + 1], Number(addresses[currentIndex + 1].destinationId), Number(addresses[currentIndex + 1].id))
      }
    }
  }

  setValidationsTime(address: AddressCargo, iDestination: number, index: number) {
    this.isValidTime(address,
      (valid: boolean, travelTime: number) => {
        const required = DateManager.durationFormat(travelTime, 'seconds')
        const timeRequired = `${required.days ? required.days + ' día/s, ' : ''}${required.hours} hora/s y ${required.minutes} minuto/s`;
        this.normalize(iDestination, index);
        if (!valid)
          this.manualCreationCargoService.estimatedTimes[iDestination][index] = timeRequired;
        else
          this.manualCreationCargoService.estimatedTimes[iDestination][index] = false;
      }
    );
  }



  public normalize(iDestination, iAddress) {
    if (this.manualCreationCargoService.estimatedTimes[iDestination] === undefined) {
      this.manualCreationCargoService.estimatedTimes[iDestination] = {};
      if (this.manualCreationCargoService.estimatedTimes[iDestination][iAddress] === undefined)
        this.manualCreationCargoService.estimatedTimes[iDestination][iAddress] = false;
    }
  }

  public isValidAddress(i, iD) {
    return this.manualCreationCargoService.isValidAddressDateTimeLoad(i, iD);
  }
  public isValidToLast(i, iD) {
    return this.manualCreationCargoService.isValidAddressTimeDownloadToLast(i, iD);
  }
  public isValidToOrigin(i, iD) {
    return this.manualCreationCargoService.isValidFirstAddressTimeDownload(i, iD);
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(3);
    this.manualCreationCargoService.getComponentActive().getStep().setId(1);
    this.router.navigate([this.manualCreationCargoService.steps.upload.url]);
  }




  //GETTERS
  getPreviosAddTime(index: number, iDestination: number): string {
    const addressControl = this.getAddressGroupActive(index, iDestination);
    const address: AddressCargo = addressControl.value;
    let addresses: AddressCargo[] = this.getAddresses();
    let currentIndex: number = -1;
    if (addresses)
      currentIndex = addresses.findIndex(addressIn => addressIn.location.lat === address.location.lat && addressIn.location.lng === address.location.lng);
    if (currentIndex >= 0 && addresses && addresses.length && addresses[currentIndex] && addresses[currentIndex - 1]) return addresses[currentIndex - 1].time;
    return '';
  }
  get calculateTime(): number {
    const min = 45;
    const max = 60;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  get isTeclogi(): boolean {
    return this.authService.getUserSession().clientCompanyId === Companies.companiesNIT.TECLOGI;
  }
}
