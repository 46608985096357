<ng-template #modalElement let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title">
      Listado de Pagos
    </p>
    <br>
    <div class="body-content">

      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="row">

            <div *ngFor="let payment of payments; let iPayment = index" class="col-12">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-2">
                      <span>Pago</span>
                      <br>
                      <label class="label-field">
                        {{iPayment + 1}}
                      </label>
                    </div>
                    <div class="col-12 col-sm-12 col-md-5">
                      <span>Fecha</span>
                      <br>
                      <label class="label-field">
                        {{payment.date}}
                      </label>
                    </div>
                    <div class="col-12 col-sm-12 col-md-5">
                      <span>Valor del pago</span>
                      <br>
                      <label class="label-field">
                        {{payment.amount | currency:'COP':'code'}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div *ngFor="let name of payment.proofs; let i = index" class="document-types col-12 col-sm-12 col-md-6">

                  <label class="document-type">

                    <div class="icon-type file-loaded">
                      <img src="assets/svg/icons/icon-correct.svg" alt="">
                    </div>

                    <div class="data-type">
                      <label class="label-field label-file">
                        <a class="file-name-container" (click)="downloadFile(name)" target="_blank">
                          <i class="fas fa-paperclip"></i>
                          <br>
                          <span class="file-name">Evidencia de pago {{i + 1}}</span>
                        </a>
                      </label>
                    </div>

                  </label>
                </div>
              </div>
              <hr>
            </div>

          </div>

        </div>
      </div>

    </div>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary" (click)="closeModal()">Cerrar</button>
    </mat-card-actions>
  </div>
</ng-template>
