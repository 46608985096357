import { Component, OnInit, Inject, Input, EventEmitter, Output, ViewChild, NgZone, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatSelectChange } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { City } from 'src/app/core/interfaces/city';
import { Company } from 'src/app/core/interfaces/company';
import { ThirdParty } from 'src/app/core/interfaces/thirdParty';
import { AddressCargo } from 'src/app/core/models/address-cargo.model';
import { Global } from 'src/app/core/resources/global';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Titles } from 'src/app/core/resources/titles';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { CatalogService } from 'src/app/core/services/catalog.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DetailCompanyComponent } from 'src/app/modules/administration/companies/form-company/form-company.component';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { FreightListService } from '../../../cargo-list/cargo-list.service';
import { CargoResources } from '../../resources/cargo';
import { FormThirdPartyConsignmentService } from './form-third-party-consignment.service';
import PlaceResult = google.maps.places.PlaceResult;
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
declare var google: any;

@Component({
  selector: 'app-form-third-party-consignment',
  templateUrl: './form-third-party-consignment.component.html',
  styleUrls: ['./form-third-party-consignment.component.scss'],
  providers: [FormThirdPartyConsignmentService]
})
export class FormThirdPartyConsignmentComponent extends ReactiveForm implements OnInit {

  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  @Input() options: any;
  @ViewChild('inputAutocomplete', { static: false }) inputAutocomplete: any;
  @ViewChild(StandardMapComponent, { static: false }) standardMap: StandardMapComponent;
  activeLocation: object = {
    typeLocation: 'thirdParty'
  };
  location: any;
  documentTypeNameControl: FormControl = new FormControl('', Validators.required);
  minDocument: number = 5;
  maxDocument: number = 15;
  keyMarker: string = 'newThirdParty'
  mapOptions: OptionsAutocomplete = {
    keyMarkerListener: this.keyMarker,
    clickMapIcon: this.global.pathMarkerOrigin,
    initialClickMap: true,
  };
  constructor(
    private catalogService: CatalogService,
    private manualCreationCargoService: ManualCreationCargoService,
    private authService: AuthService,
    private freightListService: FreightListService,
    private thirdPartyConsignmentService: FormThirdPartyConsignmentService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    public global: Global,
    public formBuilder: FormBuilder,
    private cargoResources: CargoResources,
    public utils: Utils,
    private snackService: SnackBarService,
    public dialogRef: MatDialogRef<FormThirdPartyConsignmentComponent>,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {
    super(
      formBuilder,
      cargoResources.thirdParty,
      cargoResources.thirdParty,
      cargoResources.thirdParty,
    );
    if (dataDialog && dataDialog.options.city && dataDialog.options.city.municipalityCode) {
      this.form.controls.municipalityCode.setValue(dataDialog.options.city.municipalityCode);
      this.form.controls.parent.setValue(manualCreationCargoService.formCargoOwner.value.documentNumber);
    }
  }

  ngOnInit() {
    this.setValidators();
  }

  setValidators() {
    this.form.get('information.document').setValidators([Validators.required, Validators.minLength(this.minDocument), Validators.maxLength(this.maxDocument)]);
    this.form.get('information.document').updateValueAndValidity();
  }

  onSubmit() {
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('information.documentTypeId'), 'tipo de documento')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('information.document'), 'número de documento', this.minDocument, this.maxDocument)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('information.name'), 'nombre')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('address.address'), 'dirección')) return;
      else this.snackService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    this.spinner.show();
    this.thirdPartyConsignmentService.createThirdParty(this.form.value).subscribe(
      (success: any) => {
        this.spinner.hide();
        if (success && success.errorRNDC && success.errorRNDC.error) {
          this.snackService.openSnackBar(success.errorRNDC.error, undefined, 'error');
        } else {
          this.snackService.openSnackBar(this.dataDialog.title + ' creado exitosamente');
          this.dialogRef.close({ state: true });
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackService.openSnackBar('Ocurrió un problema creando el ' + this.dataDialog.title, undefined, 'error');
      }
    );
  }

  async onClickMap($event) {
    let address = await this.transformCoordinatesToAddress($event.latLng.lat(), $event.latLng.lng());
    let location = { lat: $event.latLng.lat(), lng: $event.latLng.lng() }
    if (address && location) this.form.controls.address.patchValue({ address, location });
  }

  async transformCoordinatesToAddress(lat: number, lng: number): Promise<string> {
    let geocoderService = new google.maps.Geocoder;
    let latlng = { lat: lat, lng: lng };
    let address = '';
    this.spinner.show();
    await geocoderService.geocode({ 'location': latlng }, (results, status) => {
      if (results.length) {
        this.spinner.hide();
        address = results[0].formatted_address;
      } else this.spinner.hide();
    });
    return address;
  }

  onSelectAddress($event) {
    if ($event && $event.data && $event.data.location && $event.data.location.lat && $event.data.location.lng) {
      this.standardMap.removeMarker(this.keyMarker);
      this.standardMap.createMarker(this.keyMarker, $event.data.location.lat, $event.data.location.lng, null, this.global.pathMarkerOrigin);
      this.standardMap.setCenterMap($event.data.location.lat, $event.data.location.lng, 16);
      this.form.controls.address.patchValue($event.data);
    }

  }

  onChangeDocumentType($event: MatSelectChange) {
    if ($event && $event.value) {
      this.form.controls.information.patchValue({
        documentTypeId: $event.value.id,
        documentTypeName: this.utils.toCapitalize($event.value.name)
      });
      this.maxDocument = $event.value.name === "NIT" ? 9 : 15;
      this.setValidators();
    }
  }

  displayDocumentTypeName(documentType): string | undefined {
    return documentType && documentType.name ? documentType.name : undefined;
  }

}
