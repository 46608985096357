import { Component, ComponentFactoryResolver, ComponentRef, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicHostDirective } from 'src/app/core/directives/dynamic-host.directive';
import { AutocompleteAddressComponent } from '../autocomplete-address/autocomplete-address.component';
import { BasicAddress } from 'src/app/core/interfaces/basicAddress';
import { EventEmitter } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-multiple-addresses',
  templateUrl: './multiple-addresses.component.html',
  styleUrls: ['./multiple-addresses.component.scss']
})
export class MultipleAddressesComponent implements OnInit {
  @Output() emitToParent: EventEmitter<BasicAddress[]> = new EventEmitter();
  @ViewChild(DynamicHostDirective, { static: false }) public dynamicHost!: DynamicHostDirective;
  instanceCount: number = 0;
  addressStructure: BasicAddress = {
    address: '',
    location: {
      lat: 0,
      lng: 0
    }
  }
  addresses: Array<BasicAddress> = [];
  componentRefs = [];
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public fb: FormBuilder
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.createComponent();
    }, 10);
  }

  createComponent(increaseCount?: boolean): void {
    if (increaseCount) this.instanceCount++;
    this.addresses.push(this.addressStructure);
    const component = this.componentFactoryResolver.resolveComponentFactory(AutocompleteAddressComponent);
    const componentRef = this.dynamicHost.viewContainerRef.createComponent(component);
    this.componentRefs.push(componentRef);
    const instance = componentRef.instance as AutocompleteAddressComponent;
    instance.title = `Dirección de cargue ${this.instanceCount + 1}`;
    instance.addLatLong = true;
    instance.simpleAddress = true;
    instance.required = true;
    instance.appearance = 'outline';
    instance.dinamicStyles = true;
    instance.idAddress = this.instanceCount;
    const subscription = instance.emitToParent.subscribe(data => {
      if (data) {
        if (data.type === 'delete') {
          this.deleteComponent(data.idAddress);
        } else if (data.type === 'clear') {
          this.addresses[data.idAddress] = this.addressStructure;
          this.emitToParent.emit(this.addresses);
        }
        else {
          const stringAddress = JSON.stringify(data);
          const { location, address, idAddress } = JSON.parse(stringAddress);
          const onlyLocation = { location, address };
          this.addresses[idAddress] = onlyLocation;
          this.emitToParent.emit(this.addresses);
        }
      }

    });
  }

  deleteComponent(index: number): void {
    if (index >= 0 && index < this.componentRefs.length) {
      this.componentRefs[index].destroy();
      this.componentRefs.splice(index, 1);
      this.addresses.splice(index, 1);
      this.componentRefs.forEach((componentRef, i) => {
        const instance = componentRef.instance as AutocompleteAddressComponent;
        instance.idAddress = i;
        instance.title = `Dirección de cargue ${i + 1}`;
      })
      this.instanceCount = this.addresses.length - 1;
      this.emitToParent.emit(this.addresses);
    }
  }
}
