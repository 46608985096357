<section>
  <div class="wrapper">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="content">
      <div class="title">{{data.title}}</div>
      <br *ngIf="!!data.description">
      <div class="subtitle text-center" *ngIf="!!data.description">{{data.description}}</div>
      <br>
      <form [formGroup]="formMinistryCargos.get('cargos')" *ngIf="!loading">
        <div>
          <p><i>*Recordar que las fechas y horas mostradas en <b>la cita de cargue y descargue</b> se refieren a los
              tiempos estimado diligenciados en la creación de la carga</i></p>
          <p>Por favor confirme la fecha y hora de entrega de documentos:</p><br>

          <mat-form-field appearance="outline" class="cursor-pointer" (click)="receipt_date_documents.open()">
            <mat-label>Fecha y hora de entrega</mat-label>
            <input matInput readonly [ngxMatDatetimePicker]="receipt_date_documents" [max]="currentDate"
              placeholder="Fecha y hora de entrega" [formControl]="formMinistryCargos.get('receiptDateDocuments')"
              class="cursor-pointer">
            <mat-datepicker-toggle matSuffix [for]="receipt_date_documents"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #receipt_date_documents>
            </ngx-mat-datetime-picker>
            <mat-error *ngIf="formMinistryCargos.get('receiptDateDocuments').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngFor="let _cargo of formMinistryCargosControls; let cargo = index;" [formGroupName]="cargo">

          <div formGroupName="consignment">
            <div *ngFor="let _consignment of getFormMinistryCargoControls(_cargo); let consignment = index;"
              [formGroupName]="consignment">

              <div class="dark-bg">
                Remesa {{consignment + 1}}: {{_consignment.get('id').value}} -
                {{_consignment.get('address').value}}
              </div>
              <br>
              <div class="upload" *ngIf="data.manual_consignment_dates">
                <p class="text-strong text-center">Tiempos Logísticos Cargue</p>
                <p
                  *ngIf="_consignment?.get('upload.estimatedTime')?.value && _consignment?.get('upload.dateLoad')?.value">
                  <span class="text-strong mr-2">Cita cargue:</span>{{ (_consignment?.get('upload.dateLoad')?.value |
                  dateFormat:'only-date')}} - {{ (_consignment?.get('upload.estimatedTime')?.value)}}
                </p>
                <mat-form-field appearance="outline" class="cursor-pointer" (click)="upload_arrival.open()">
                  <mat-label>Llegada cargue</mat-label>
                  <input matInput readonly
                    [min]="formatMinDateLoadEntry(_consignment?.get('upload.dateLoad')?.value, _consignment?.get('upload.estimatedTime')?.value)"
                    [ngxMatDatetimePicker]="upload_arrival" placeholder="Fecha y hora llegada del cargue" [max]="today"
                    [formControl]="_consignment.get('upload.arrival')" class="cursor-pointer">
                  <mat-datepicker-toggle matSuffix [for]="upload_arrival"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #upload_arrival>
                  </ngx-mat-datetime-picker>
                  <mat-error *ngIf="_consignment.get('upload.arrival').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('upload.arrival').hasError('minDate')">
                    Debe ser mayor a
                    {{_consignment.get('upload.arrival').errors?.minDate | dateFormat}}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('upload.arrival').hasError('maxDate')">
                    Debe ser menor a la fecha y hora actual
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="cursor-pointer" (click)="upload_entry.open()">
                  <mat-label>Entrada cargue</mat-label>
                  <input matInput readonly [min]="_consignment?.get('upload.arrival')?.value" [max]="today"
                    [ngxMatDatetimePicker]="upload_entry" placeholder="Fecha y hora entrada del cargue"
                    [formControl]="_consignment.get('upload.entry')" class="cursor-pointer">
                  <mat-datepicker-toggle matSuffix [for]="upload_entry"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #upload_entry>
                  </ngx-mat-datetime-picker>
                  <mat-error *ngIf="_consignment.get('upload.entry').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('upload.entry').hasError('minDate')">
                    Debe ser mayor a
                    {{_consignment.get('upload.entry').errors?.minDate}}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('upload.entry').hasError('maxDate')">
                    Debe ser menor a la fecha y hora actual
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="cursor-pointer" (click)="upload_departure.open()">
                  <mat-label>Salida cargue</mat-label>
                  <input matInput readonly [min]="_consignment?.get('upload.entry')?.value" [max]="today"
                    [ngxMatDatetimePicker]="upload_departure" placeholder="Fecha y hora salida del cargue"
                    [formControl]="_consignment.get('upload.departure')" class="cursor-pointer">
                  <mat-datepicker-toggle matSuffix [for]="upload_departure"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #upload_departure>
                  </ngx-mat-datetime-picker>
                  <mat-error *ngIf="_consignment.get('upload.departure').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('upload.departure').hasError('minDate')">
                    Debe ser mayor a
                    {{_consignment.get('upload.departure').errors?.minDate}}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('upload.departure').hasError('maxDate')">
                    Debe ser menor a la fecha y hora actual
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="download" *ngIf="data.manual_consignment_dates">
                <p class="text-strong text-center">Tiempos Logísticos Descargue</p>
                <p
                  *ngIf="_consignment?.get('download.estimatedTime')?.value && _consignment?.get('download.dateDownload')?.value">
                  <span class="text-strong mr-2">Cita descargue:</span>{{
                  (_consignment?.get('download.dateDownload')?.value | dateFormat:'date-time-full-24')}}
                </p>

                <mat-form-field appearance="outline" class="cursor-pointer" (click)="download_arrival.open()">
                  <mat-label>Llegada descargue</mat-label>
                  <input matInput readonly
                    [min]="formatMinDateDownloadEntry(_consignment?.get('upload.departure')?.value)" [max]="today"
                    [ngxMatDatetimePicker]="download_arrival" placeholder="Fecha y hora llegada del descargue"
                    [formControl]="_consignment.get('download.arrival')" class="cursor-pointer">
                  <mat-datepicker-toggle matSuffix [for]="download_arrival"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #download_arrival>
                  </ngx-mat-datetime-picker>
                  <mat-error *ngIf="_consignment.get('download.arrival').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('download.arrival').hasError('minDate')">
                    Debe ser mayor a
                    {{_consignment.get('download.arrival').errors?.minDate | dateFormat}}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('download.arrival').hasError('maxDate')">
                    Debe ser menor a la fecha y hora actual
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="cursor-pointer" (click)="download_entry.open()">
                  <mat-label>Entrada descargue</mat-label>
                  <input matInput readonly
                    [min]="formatMinDateDownloadEntry(_consignment?.get('download.arrival')?.value)" [max]="today"
                    [ngxMatDatetimePicker]="download_entry" placeholder="Fecha y hora salida del cargue"
                    [formControl]="_consignment.get('download.entry')" class="cursor-pointer">
                  <mat-datepicker-toggle matSuffix [for]="download_entry"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #download_entry>
                  </ngx-mat-datetime-picker>
                  <mat-error *ngIf="_consignment.get('download.entry').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('download.entry').errors?.minDate">
                    Debe ser mayor a
                    {{_consignment.get('download.entry').errors?.minDate}}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('download.entry').hasError('maxDate')">
                    Debe ser menor a la fecha y hora actual
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="cursor-pointer" (click)="download_departure.open()">
                  <mat-label>Salida descargue</mat-label>
                  <input matInput readonly
                    [min]="formatMinDateDownloadDeparture(_consignment?.get('download.entry')?.value, _consignment.get('download.minutePact')?.value, _consignment.get('download.timePact')?.value)"
                    [ngxMatDatetimePicker]="download_departure" placeholder="Fecha y hora salida del cargue"
                    [max]="today" [formControl]="_consignment.get('download.departure')" class="cursor-pointer">
                  <mat-datepicker-toggle matSuffix [for]="download_departure"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #download_departure>
                  </ngx-mat-datetime-picker>
                  <mat-error *ngIf="_consignment.get('download.departure').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('download.departure').hasError('minDate')">
                    Debe ser mayor a
                    {{_consignment.get('download.departure').errors?.minDate}}
                  </mat-error>
                  <mat-error *ngIf="_consignment.get('download.departure').hasError('maxDate')">
                    Debe ser menor a la fecha y hora actual
                  </mat-error>
                </mat-form-field>
              </div>

              <div *ngIf="!data.manual_consignment_dates">
                <div class="upload">
                  <p class="text-strong text-center">Tiempos Logísticos Cargue</p>
                  <p
                    *ngIf="_consignment?.get('upload.estimatedTime')?.value && _consignment?.get('upload.dateLoad')?.value">
                    <span class="text-strong mr-2">Cita cargue:</span>{{ (_consignment?.get('upload.dateLoad')?.value |
                    dateFormat:'only-date')}} - {{ (_consignment?.get('upload.estimatedTime')?.value)}}
                  </p>
                  <p>
                    <label class="text-strong">Llegada cargue:</label>
                    &nbsp;
                    <span>{{_consignment.get('upload.arrival').value | dateFormat:'date-time-full-24'}}</span>
                  </p>
                  <p>
                    <label class="text-strong">Entrada cargue:</label>
                    &nbsp;
                    <span>{{_consignment.get('upload.entry').value | dateFormat:'date-time-full-24'}}</span>
                  </p>
                  <p>
                    <label class="text-strong">Salida cargue:</label>
                    &nbsp;
                    <span>{{_consignment.get('upload.departure').value | dateFormat:'date-time-full-24'}}</span>
                  </p>
                </div>
                <div class="donwload">
                  <p class="text-strong text-center">Tiempos Logísticos Descargue</p>
                  <p
                    *ngIf="_consignment?.get('download.estimatedTime')?.value && _consignment?.get('download.dateDownload')?.value">
                    <span class="text-strong mr-2">Cita descargue:</span>{{
                    (_consignment?.get('download.dateDownload')?.value | dateFormat:'date-time-full-24')}}
                  </p>
                  <p *ngIf="_consignment?.get('download.arrival')?.value">
                    <span class="text-strong mr-2">Llegada
                      descargue:</span>{{_consignment.get('download.arrival').value |
                    dateFormat:'date-time-full-24'}}
                  </p>
                  <p>
                    <label class="text-strong">Entrada descargue:</label>
                    &nbsp;
                    <span>{{_consignment.get('download.entry').value | dateFormat:'date-time-full-24'}}</span>
                  </p>
                  <p>
                    <label class="text-strong">Salida descargue:</label>
                    &nbsp;
                    <span>{{_consignment.get('download.departure').value | dateFormat:'date-time-full-24'}}</span>
                  </p>

                </div>
              </div>

              <div class="amout">
                <label class="text-strong">Cantidad entregada:</label>
                &nbsp;
                <mat-form-field style="width: 100px;">
                  <input formControlName="amountDelivered" name="amountDelivered" matInput type="number"
                    style="padding-right: 0 !important;" [disabled]="!data.manual_consignment_dates"
                    [readonly]="!data.manual_consignment_dates">
                  <mat-error *ngIf="_consignment.get('amountDelivered').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="actions text-right">
      <button mat-raised-button color="accent" class="primary-secondary" type="button"
        (click)="dialogRef.close(false)">Cancelar</button>
      <button mat-raised-button color="accent" (click)="approve()">Confirmar</button>
    </div>
  </div>
</section>