import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentTypeEnum } from 'src/app/core/enums/document-type.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-metadata-form',
  templateUrl: './metadata-form.component.html',
  styleUrls: ['./metadata-form.component.scss']
})
export class MetadataFormComponent {
  public DocumentTypeEnum = DocumentTypeEnum;
  @Input() singleSignOnData: any = {};
  @Output() public onReturn = new EventEmitter<boolean>();
  @Output() public onSubmitBasicData = new EventEmitter<any>();

  form: FormGroup = new FormGroup({
    documentType: new FormControl(null, [Validators.required]),
    document: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [Validators.required])
  });

  constructor(
    private snackbarService: SnackBarService
  ) { }

  get email(): string {
    return this.singleSignOnData && this.singleSignOnData.user ? this.singleSignOnData.user.email : '';
  }

  onSubmit() {
    const documentType = this.form.get('documentType');
    const document = this.form.get('document');
    const phone = this.form.get('phone');
    if (this.form.valid) {
      this.onSubmitBasicData.emit({
        documentType: documentType.value,
        document: document.value,
        phone: phone.value
      });
    } else {
      if (documentType.invalid) {
        this.snackbarService.openSnackBar(Fmt.string(FormMessages.INVALID_FIELD, 'tipo de documento'), 'Aceptar', 'error');
      } else if (document.invalid) {
        this.snackbarService.openSnackBar(Fmt.string(FormMessages.INVALID_FIELD, 'número de documento'), 'Aceptar', 'error');
      } else if (phone.invalid) {
        this.snackbarService.openSnackBar(Fmt.string(FormMessages.INVALID_FIELD, 'celular'), 'Aceptar', 'error');
      }
    }
  }

  cancel() {
    this.onReturn.emit(true);
  }

}
