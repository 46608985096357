export enum TRIP_TYPES {
    URBAN = "Urbana",
    NATIONAL = "Nacional",
    IMPORT = "Importación",
    EXPORT = "Exportación",
    LAST_MILE = "Última milla",
    LONG_MILE = "Larga Milla",
    EMPTY_CONTAINER = "Contenedor Vacio",
    INTERNATIONAL = "Internacional",
    EMPTY = "",
};
