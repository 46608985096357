<div class="modifications-container row p-1 p-sm-5"
  *ngIf="dialogParams && dialogParams.listReceivables && dialogParams.holder">
  <div class="card col-12 pt-2">
    <div class="card-title row">
      <div class="receivable-title color-secondary-dark col-12">
        <i class="fas fa-user mr-2"></i>Información del tenedor
      </div>
    </div>
    <div class="card-body row pl-0 pr-0 pt-1">
      <div class="col" *ngIf="dialogParams.holder.name">
        <span class="title-purple label">
          Nombre:
        </span>
        <span class="text-bold label-field" title="{{ dialogParams.holder.name }}">
          {{ dialogParams.holder.name }}
        </span>
      </div>
      <div class="col" *ngIf="dialogParams.holder.documentTypeName">
        <span class="title-purple label">
          Tipo de documento:
        </span>
        <span class="text-bold label-field" title="{{ dialogParams.holder.documentTypeName }}">
          {{ dialogParams.holder.documentTypeName }}
        </span>
      </div>
      <div class="col" *ngIf="dialogParams.holder.document">
        <span class="title-purple label">
          N° de documento:
        </span>
        <span class="text-bold label-field" title="{{ dialogParams.holder.document }}">
          {{ dialogParams.holder.document }}
        </span>
      </div>

    </div>
  </div>
  <div class="alert alert-warning col-12 mt-2" role="alert">
    <b>* Recordar que es necesario aceptar o rechazar la modificación a la cartera activa para poder continuar.</b>
  </div>
  <div class="receivable-title color-secondary-dark col-12 pl-1 mt-3 mb-2">
    <i class="fas fa-file-invoice-dollar mr-2"></i>Lista de carteras
  </div>
  <app-receivable-modifications-detail *ngIf="activeReceivable && activeReceivable.length"
    [listReceivables]="activeReceivable" [holder]="dialogParams.holder" [type]="'active'"
    (modificationApproved)="closeDialog($event)" class="col-12"></app-receivable-modifications-detail>
  <app-receivable-modifications-detail *ngIf="arrayPending && arrayPending.length" [listReceivables]="arrayPending"
    [holder]="dialogParams.holder" [type]="'pending'" (modificationApproved)="closeDialog($event)"
    class="col-12"></app-receivable-modifications-detail>
  <app-receivable-modifications-detail *ngIf="arrayPaid && arrayPaid.length" [listReceivables]="arrayPaid"
    [holder]="dialogParams.holder" [type]="'paid'" (modificationApproved)="closeDialog($event)"
    class="col-12"></app-receivable-modifications-detail>
</div>