<div class="operations-container">
    <div class="d-flex mt-3" *ngIf="showSelectorCompany">
        <div class="col-md-4">
            <app-select-company [options]="companyOptions" [inputFormControl]="companyControl"
                [validate]="companyValidate">
            </app-select-company>
        </div>
    </div>
    <iframe width="100%" [height]="showSelectorCompany? '80%':'100%'" [src]="(DASHBOARD_URL | safe)" frameborder="0"
        style="border:0" allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"></iframe>
</div>