import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
import { RoutingService } from '../../routing.service';
import { Scenario } from 'src/app/core/interfaces/scenario';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { RouteScenario } from 'src/app/core/interfaces/routeScenario';
import { StoredFleet } from 'src/app/core/interfaces/storedFleet';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';

@Component({
  selector: 'app-scenario-detail',
  templateUrl: './scenario-detail.component.html',
  styleUrls: ['./scenario-detail.component.scss']
})
export class ScenarioDetailComponent implements OnInit {

  @ViewChild(StandardMapComponent, { static: false }) standardMapComponent: StandardMapComponent;
  currentStep: 1 | 2 = 1;
  form: FormGroup;
  routes: RouteScenario[] = [];
  scenario: Scenario;
  public mapOptions: OptionsAutocomplete = {
    zoom: 12,
    showMarkers: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private routingService: RoutingService,
    private snackBarService: SnackBarService,
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.route.params.subscribe((params: ParamMap) => {
      if (params && params['id']) this.findRouteByToken(params['id']);
      else this.goToList();
    });
  }

  /**
  * @description Initializes the form
  */
  private initForm() {
    this.form = new FormGroup({
      scenarioToken: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      cost: new FormControl('', [Validators.required, Validators.min(1)]),
      rate: new FormControl('', [Validators.required, Validators.min(1)]),
      orders: new FormArray([]),
      selectableVehicles: new FormControl({})
    });
  }

  /**
  * @description Shows a message if an error has ocurred and navigates to route-list
  */
  private goToList() {
    this.spinner.hide();
    this.snackBarService.openSnackBar(RoutingMessages.ROUTING_UNACCESS, undefined, 'error');
    this.router.navigate(['routing/route-list']);
  }

  /**
  * @param {string} token is the token of the route to search
  * @description Searches a route by its token, updates the form and executes the "findScenarioByToken" method
  */
  private findRouteByToken(token: string) {
    this.spinner.show();
    this.routingService.getRouteByScenario(token).subscribe(
      (success) => {
        if (success && success.response) {
          this.routes = success.response;
          this.routes.forEach((route: RouteScenario, index) => {
            (this.form.get('orders') as FormArray).push(new FormGroup({
              id: new FormControl(index),
              deployDate: new FormControl(route.deployDate),
              description: new FormControl(route.description),
              fleetSequence: new FormControl(route.fleetSequence),
              summary: new FormControl(route.summary),
              trips: new FormControl(route.trips),
              vehicle: new FormControl(route.vehicle, Validators.required),
              capacities: new FormControl(this.routingService.getCapacitiesfromRouteScenario(route))
            }))
          });
          this.findScenarioByToken(token);
        } else this.goToList();
      }, () => {
        this.goToList();
      }
    )
  }

  /**
  * @param {string} token is the token of the scenario to search
  * @description Searches a scenario by its token, updates the form and executes the "findFleetByName" method
  */
  private findScenarioByToken(token: string) {
    this.routingService.getScenarioByToken(token).subscribe(
      (scenario) => {
        if (!scenario) return this.goToList();
        this.scenario = scenario;
        if (scenario.token) this.form.get('scenarioToken').setValue(this.scenario.token);
        if (scenario.description) this.form.get('description').setValue(this.scenario.description);
        if (scenario.fleetName) this.findFleetByName(scenario.fleetName);
        else if (this.routes.every(route => !!route.vehicle)) {
          this.spinner.hide();
          const selectableVehicles = {};
          this.routes.forEach(route => selectableVehicles[route.vehicle] = { vehiclePlate: route.vehicle });
          this.form.get('selectableVehicles').setValue(selectableVehicles);
        }
        else this.goToList();
      }, () => {
        this.spinner.hide();
        this.goToList();
      }
    )
  }

  /**
  * @param {string} fleetName is the name of the fleet to search
  * @description Searches a fleet by its name and updates the form's selectableVehicles
  */
  private findFleetByName(fleetName: string) {
    this.routingService.getFleetByName(fleetName).subscribe(
      (fleet: StoredFleet) => {
        if (fleet && fleet.vehicleCapacities) {
          this.spinner.hide();
          let selectableVehicles = {};
          fleet.vehicleCapacities.forEach(capacity => {
            selectableVehicles[capacity.vehiclePlate] = {
              vehiclePlate: capacity.vehiclePlate,
              capacity1: capacity.capacity1,
              capacity2: capacity.capacity2,
              capacity3: capacity.capacity3
            };
          });
          this.form.get('selectableVehicles').setValue(selectableVehicles);
        }
        else if (this.routes.every(route => !!route.vehicle)) {
          this.spinner.hide();
          const selectableVehicles = {};
          this.routes.forEach(route => selectableVehicles[route.vehicle] = { vehiclePlate: route.vehicle });
          this.form.get('selectableVehicles').setValue(selectableVehicles);
        }
        else this.goToList();
      }, () => {
        this.goToList();
      }
    )
  }


  /**
  * @param { [{ route: Object }, boolean] } $event are the params sent from change-orders component to refresh the map
  * @description Updates the mapOptions from the params received
  */
  refreshMap($event: [{ route: Object }, boolean]) {
    this.mapOptions['removeAllPolylines'] = true;
    if (this.standardMapComponent) this.standardMapComponent.removeAllMarkers();
    this.mapOptions['polyline'] = $event[0];
    this.mapOptions['route'] = $event[0];
    this.mapOptions = { ...this.mapOptions };
  }

  /**
  * @param {1|2} step is the step to set
  * @description Updates the current step
  */
  setCurrentStep(step: 1 | 2) {
    this.currentStep = step;
  }

}
