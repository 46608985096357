import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterCompanies',
    pure: false

})
export class filterCompaniesPipe implements PipeTransform {
    transform(data: any, filter: any): any {
        const resultCompanies = [];
        for (const company of data) {
            if (company.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
                resultCompanies.push(company);
            };
        };
        return resultCompanies;
    }
}

