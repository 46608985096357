import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Endpoints } from '../resources/endpoints';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Role } from '../interfaces/role';
import { Utils } from '../resources/utils';

@Injectable({
    providedIn: 'root',
})
export class RoleService {

    roleUser: Role;

    constructor(
        private router: Router,
        private _http: HttpClient,
        public afAuth: AngularFireAuth,
        private _endpointResources: Endpoints,
        public utils: Utils
    ) {
        if (this.getRoleUser()) {
            this.roleUser = this.getRoleUser();
        }
    }

    public getRoleUser(): Role {
        //este get lo que hace es preguntar si hay un rol de usuario retornemelo o sino retorne null
        return RoleService['roleUser'] ? RoleService['roleUser'] : null;
    }

    public setRoleUser(role: Role) {
        RoleService['roleUser'] = role;
    }

    public getRolById(id: string) {
        return this._http.get(
            environment.urlServerTeclogi + this._endpointResources.roleDetail + id
        ).pipe(
            map(
                (data: Role) => {
                    if (data) {
                        let roleUser: Role = {
                            id: data.id,
                            nit: data.nit,
                            name: data.name,
                            modules: []
                        };

                        if (data.modules.length) {
                            roleUser.modules = data.modules;
                        }

                        this.setRoleUser(roleUser);
                        return roleUser;
                    } else {
                        return null;
                    }
                }
            )
        );
    }

}
