<div class="container">
    <div class="row">
        <div class="col-12 col-lg-12 text-center">
            <h2 class="tittle__questions" >¿Cómo te podemos ayudar?</h2>
        </div>
        <div class="col-6 col-lg-6 offset-3 offset-lg-3 text-center" >
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Buscar..." aria-label="Recipient's username" aria-describedby="button-addon2">
                <div class="input-group-append">
                  <button class="btn btn-teclogi" type="button" id="button-addon2"><img class="button__lup" src="/assets/images/frequent/Search.png" alt=""></button>
                </div>
            </div> 
        </div>
        <div class="col-lg-12">
            <mat-tab-group class="mat__tab">
                <mat-tab label="Mi cuenta"></mat-tab>
                <mat-tab label="Transportador"></mat-tab>
                <mat-tab label="Generador de carga"></mat-tab>
                <mat-tab label="Agente comercial"></mat-tab>
              </mat-tab-group>
        </div>
        <div class="color_accordion">
        <div class="col-lg-12 ">
            <mat-accordion >
                <mat-expansion-panel (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false" class="accordion__mat">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="text_panel">
                        Pregunta 1
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <p></p>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false" class="accordion__mat" >
                    <mat-expansion-panel-header>
                            <mat-panel-title class="text_panel">
                                Pregunta 2 
                            </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                        </mat-expansion-panel-header>
                                <p></p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false" class="accordion__mat" >
                    <mat-expansion-panel-header>
                            <mat-panel-title class="text_panel" >
                                Pregunta 3
                            </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                        </mat-expansion-panel-header>
                                <p class="text__info" > Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </mat-expansion-panel>
              </mat-accordion>
        </div>
    </div>
    </div>
</div>
