
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Contact } from 'src/app/core/interfaces/formContact';
import { ContactService } from './contact.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Utils } from 'src/app/core/resources/utils';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ContactService]

})
export class ContactComponent implements OnInit {

  formContact: FormGroup;
  siteKey = environment.recaptchaKey;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private snackBarService: SnackBarService,
    public utils: Utils
  ) { }

  ngOnInit() {
    this.formContact = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      description: ['', Validators.required],
      recaptcha: [null, Validators.required]
    });

  }

  onSubmit() {
    if (this.formContact.invalid) {
      if (this.utils.errorMessagesCustomized(this.formContact.get('name'), 'nombre')) { }
      else if (this.utils.errorMessagesCustomized(this.formContact.get('email'), 'correo')) { }
      else if (this.utils.errorMessagesCustomized(this.formContact.get('phone'), 'celular')) { }
      else if (this.utils.errorMessagesCustomized(this.formContact.get('description'), 'mensaje')) { }
      else if (this.utils.errorMessagesCustomized(this.formContact.get('recaptcha'), 'captcha')) { }
      else this.snackBarService.openSnackBar("Por favor verifique sus datos", undefined, 'alert')
      return;
    }

    let dataContact: Contact = {
      name: this.formContact.controls.name.value,
      email: this.formContact.controls.email.value,
      phone: this.formContact.controls.phone.value,
      description: this.formContact.controls.description.value,
      ethicalLine: false,
    }

    this.contactService.sendDataContact(dataContact).subscribe(
      (success) => {
        this.formContact.reset();
        this.snackBarService.openSnackBar("Se ha enviado la informacion correctamente")
      },
      (error) => {
        this.snackBarService.openSnackBar("No se ha podido enviar la informacion", undefined, 'error')

      }
    )

  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }
}
