<section class="row-freight">
  <div class="row container-data">
    <div class="col-md-4">
      <div class="label">
        NIT
      </div>
      <div class="text-bold label-field">
        <span title="{{company.companyId}}">
          {{company.companyId}}
        </span>
      </div>
    </div>
    <div class="col-md-4">
      <div class="label">
        Razón social
      </div>
      <div class="text-bold label-field">
        <div title="{{company.name}}">
          {{company.name}}
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="label">
        Sitio web
      </div>
      <div class="text-bold label-field">
        <!-- <span > -->
        <a title="{{company.webSite}}" [href]="company.webSite" target="_blank"></a>{{company.webSite}}
        <!-- </span> -->
      </div>
    </div>
  </div>
  <figure class="icon-arrow-container" (click)="openDetail()">
    <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
  </figure>
  <!-- Button remove  -->
  <!-- <figure class="icon-delete" (click)="openModalDeleteCompany()">
    <i mat-list-icon class="fas fa-trash-alt"></i>
  </figure> -->
</section>


<!-- Modal Confirm Delete Comapny -->
<app-modal #modalConfirmDeleteCompany [titleModal]="titleModalDelete" [titleCancel]="titles.titleModalNo"
  [titleConfirm]="titles.titleModalConfirm" (onCancel)="closeModal()" (onConfirm)="deleteCompany()"></app-modal>