import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Driver } from 'src/app/core/models/driver';
import { ExtraDocument } from 'src/app/core/interfaces/extraDocument';
@Component({
  selector: 'app-docs-state',
  templateUrl: './docs-state.component.html',
  styleUrls: ['./docs-state.component.scss'],
  providers: [DatePipe]
})
export class DocsStateComponent implements OnInit {
  // INPUTS
  @Input() expireDocs: boolean = false;
  @Input() documents: ExtraDocument[] = [];
  @Input() type: 'ARL' | 'EPS';
  @Input() userState: boolean = false;
  @Input() dueDateDoc: string = '';
  // PROPERTIES
  tooltipClass: string = '';
  statusDocs: string = '';
  constructor(
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.updateDocumentState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expireDocs || changes.documents || changes.user) this.updateDocumentState();
  }

  updateDocumentState(): void {
    this.statusDocs = this.calculateStatus(this.type, this.documents, this.userState, this.dueDateDoc);
    this.tooltipClass = this.getTooltipClass(this.statusDocs);
  }

  calculateStatus(type: 'ARL' | 'EPS', documents: ExtraDocument[], userState: boolean, dueDateDoc: string): string {
    const matchDoc = documents.find(document => document.type === type);
    return matchDoc && matchDoc['approvalBy'] ?
      this.expireDocs ?
        userState && !dueDateDoc ?
          'optional' :
          'required' :
        'current' :
      'pending';
  }

  formatStringToDate(date: string): string {
    return this.datePipe.transform(DateManager.stringToDate(date), "d MMMM yyyy");
  }

  // GETTERS
  getTooltipClass(status: string): string {
    switch (status) {
      case 'current': return 'custom-tooltip success-tooltip';
      case 'optional': return 'custom-tooltip info-tooltip';
      case 'required': return 'custom-tooltip error-tooltip';
      case 'pending': return 'custom-tooltip warning-tooltip';
      default: return '';
    }
  }

  getDocumentState(): string {
    let message = `El documento cargado de la ${this.type} está `;
    switch (this.statusDocs) {
      case 'current': message += 'vigente'; break;
      case 'optional': message += 'vencido (el cargue del documento es opcional)'; break;
      case 'required': message += 'vencido (el cargue del documento es obligatorio)'; break;
      case 'pending': message = 'El documento cargado está pendiente por aprobación'; break;
      default: message = ''; break;
    }
    const matchDoc = this.documents.find(document => document.type === this.type);
    if (matchDoc && matchDoc['dueDate']) {
      message += this.statusDocs !== 'pending' ? `.\n Fecha de vencimiento: ${this.formatStringToDate(matchDoc['dueDate'])}` : '';
    }
    return message;
  }

  getDocumentSupport(type: 'ARL' | 'EPS', documents: ExtraDocument[]): boolean {
    return documents && documents.length ? documents.some(document => document.type === type) : false;
  }

}
