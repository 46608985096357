import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';

@Component({
  selector: 'app-receivable-filters',
  templateUrl: './receivable-filters.component.html',
  styleUrls: ['./receivable-filters.component.scss']
})
export class ReceivableFiltersComponent implements OnInit {
  licensePlate: FormControl;
  cargoConsecutive: FormControl;
  receivableId: FormControl;
  document: FormControl;
  @Output() emitFilters: EventEmitter<any> = new EventEmitter();
  @Input() storageInputs;
  @Input() emptyStorage: boolean;
  @Input() cleanFields: boolean;
  constructor(
    public snackBarService: SnackBarService
  ) {
    this.licensePlate = new FormControl();
    this.cargoConsecutive = new FormControl();
    this.receivableId = new FormControl();
    this.document = new FormControl();
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.storageInputs && changes.storageInputs.currentValue && this.storageInputs.length > 0) {
        let label = '';
        this.storageInputs.forEach(input => {
          label = input.label;
          if (label === 'licensePlate') {
            this.licensePlate.setValue(input.control);
          } else if (label === 'cargoConsecutive') {
            this.cargoConsecutive.setValue(input.control);
          } else if (label === 'consecutive') {
            this.receivableId.setValue(input.control);
          } else if (label === 'document') {
            this.document.setValue(input.control);
          }
        })
        this.applyFilter();
      }
      if (changes.cleanFields && this.cleanFields !== undefined) {
        this.cleanFilter();
      }
    }
  }

  applyFilter() {
    const filterControls = [
      { control: this.licensePlate, label: 'licensePlate' },
      { control: this.cargoConsecutive, label: 'cargoConsecutive' },
      { control: this.receivableId, label: 'consecutive' },
      { control: this.document, label: 'document' }
    ];

    const filtersValues = filterControls
      .filter(({ control }) => control.value !== null && control.value !== '')
      .map(({ control, label }) => ({ label, value: control.value }));

    if (filtersValues.length > 0) {
      this.emitFilters.emit({ type: 'apply', filtersValues: filtersValues });
    } else {
      this.emitFilters.emit({ type: 'reset', filtersValues: null })
    }
  }


  cleanFilter() {
    this.licensePlate.setValue('');
    this.cargoConsecutive.setValue('');
    this.receivableId.setValue('');
    this.document.setValue('');
    sessionStorage.removeItem("_filterListReceivables");
    this.emitFilters.emit({ type: 'reset', filtersValues: null })
  }

}
