<div class="row container-data">
  <div class="row">
    <div class="col-md-12">
      <div class="text-bold label-field">
        <span>
          {{funcionalities.name  | roleFunctionality}}
        </span>
      </div>
    </div>
  </div>
</div>
