import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Cargo } from 'src/app/core/interfaces/cargo';

@Component({
  selector: 'app-list-massive-payments-loads',
  templateUrl: './list-massive-payments-loads.component.html',
  styleUrls: ['./list-massive-payments-loads.component.scss']
})
export class ListMassivePaymentsLoadsComponent {
  @Input() massiveLoadsList: Array<Cargo> = [];
  @Input() typeList: string = '';
  @Input() activeSelected: boolean = false;
  @Output() closeVehicleDetail: EventEmitter<boolean> = new EventEmitter();
  @Output() loadsSelected: EventEmitter<Cargo[]> = new EventEmitter();
  constructor() { }
}
