<div class="main">
    <div class="container-image-text container-overflow-h">
        <div class="container-text">
            <div class="title color-white">Envía tus cargas de manera <br> rápida, simple y segura</div>
            <div class="subtitle color-white">Aumenta tus ventas al conectarte rápidamente con un mayor
                número de camiones del país </div>
            <div class="button">
                <a class="text-button " routerLink='/account/login'>
                    Comienza a enviar carga
                </a>
            </div>
        </div>
    </div>
    <div class="row container-center container-overflow-h">
        <div class="col-10  margin-standard">
            <section class="color-gray text-principal bold text-center">LoggiApp te conecta con carga disponible de
                manera<span class="color-purple text-secundary bold"> segura y transparente, sin intermediarios.</span>
            </section>
            <section class="row margin-only-top">
                <div class="col-12 col-lg-6">
                    <div class="image">
                        <img src="/assets/images/dshippers/map-loggiapp.png" alt="">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="container-items" *ngFor="let item of data">
                        <div class="container-image">
                            <img src="{{item.icon}}" alt="">
                        </div>
                        <div class="container-information">
                            <div class="title-item bold">
                                {{item.title}}
                            </div>
                            <div class="information-item">
                                {{item.information}}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="row container-overflow-h">
        <div class="col-12 col-lg-6 container-image-app">
            <img class="img-one" src="/assets/images/dshippers/matthew-t-rader-1shWwOrkxEM-unsplash.png">
            <img class="img-two" src="/assets/images/dshippers/map-loggiapp.png">
        </div>
        <div class="col-12 col-lg-6 container-apps">
            <div class="info-apps">
                <section class="color-gray text-principal text-center">Regístrate y comienza<span
                        class="color-purple text-secundary bold"> a enviar carga hoy.</span>
                </section>
                <div class="container-button">
                    <div class="button">
                        <a class="text-button " routerLink='/account/login'>
                            Registrarme
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>