import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MessagingService } from 'src/app/core/services/messages.services';
import { AuthService } from 'src/app/core/services/authentication.service';
import { PushMessage } from 'src/app/core/interfaces/pushMessage';
import { Utils } from 'src/app/core/resources/utils';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Global } from 'src/app/core/resources/global';
import { HeaderComponent } from '../header/header.component';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { AlertCargoComponent } from 'src/app/shared/alert-cargo/alert-cargo.component';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import 'firebase/messaging';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import firebase from "firebase/app";
import "firebase/messaging";
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { SwUpdate } from '@angular/service-worker';
import { FirebaseApi } from 'src/app/core/classes/firebase-api';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Notification } from 'src/app/core/interfaces/notification';
import { NotificationTypes } from 'src/app/core/enums/notification-types.enum';
import { GpsNotificationComponent } from 'src/app/shared/gps-notification/gps-notification.component';
import { DynamicFirebaseService } from 'src/app/core/services/dynamic-firebase.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent {

  refreshListCargo: any;
  typeListFreighForwarderList: string;
  getCargoDetailTracking: any;
  navCollapse = false;
  navHover: boolean = false;
  termsAndConditionsActive: boolean = false;
  listCargo: Array<object> = [];
  public dialogRef;
  @ViewChild('appHeader', { static: false }) appHeader: HeaderComponent;
  permission = Permission;
  listNotifications: PushMessage[] = [];
  rndcContingency: boolean = false;
  private collectionUnsubscribe: () => void;

  constructor(
    private messagingService: MessagingService,
    public authService: AuthService,
    public utils: Utils,
    private router: Router,
    private global: Global,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private cargoDetailSerive: CargoDetailService,
    private spinner: NgxSpinnerService,
    private permissionRole: PermissionRole,
    private snackBar: SnackBarService,
    public dynamicService: DynamicFirebaseService,
    updates: SwUpdate
  ) {
    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });
    if (authService.sessionActive()) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(() => {
        const title = this._buildBreadcrumbs(this.activatedRoute.root).map((title) => {
          return title.label;
        }).join(" / ");;
        this.titleService.setTitle(title.toString());
        try {
          switch (this.activatedRoute.firstChild.routeConfig.path) {
            case 'home':
              this.navCollapse = false;
              break;
            default:
              this.navCollapse = true;
              break;
          }
        } catch (e) {
          this.navCollapse = false;
        }
      });
    }
  }

  private _buildBreadcrumbs(
    activatedRoute: ActivatedRoute,
    url: string = ""
  ) {
    const children: ActivatedRoute[] = activatedRoute.children;

    if (children.length === 0) {
      return [];
    }

    let breadcrumbs = [];

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join("/");

      if (routeURL !== "") {
        url += `/${routeURL}`;
      }

      const routeData = child.snapshot.data;

      if (routeData.breadcrumb) {
        breadcrumbs.push({ label: routeData.breadcrumb, url: url });
      } else if (routeData.apiData && routeData.apiData.breadcrumb) {
        breadcrumbs.push({
          label: routeData.apiData.breadcrumb,
          url: url
        });
      }

      breadcrumbs = breadcrumbs.concat(this._buildBreadcrumbs(child, url));
    }

    return breadcrumbs;
  }

  getChildTitle(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChildTitle(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  async ngOnInit() {
    if (this.authService.sessionActive()) {
      await this.dynamicService.initializeAllFirebaseApps();
      if (this.authService.getUserSession().lastUserTerm && this.authService.getUserSession().lastUserTerm.state) {
        this.termsAndConditionsActive = true;
        // Temporarily commented out for future refactoring !
        // this.requestPermission();

        // Dialog if session is inactivated
        const user = this.authService.getUserSession();
        if (user.role === 'webUserPendingActivate' || user.roleId === null) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            title: 'Su usuario se encuentra pendiente por activar.',
            description: 'Comuníquese con su administrador.',
            hideBtnCancel: true
          };
          dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
          dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
          dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
          dialogConfig.autoFocus = false;
          this.matDialog.open(DialogComponent, dialogConfig)
        }
      } else {
        this.termsAndConditionsActive = false;
        this.router.navigate(['/account/validate-terms']);
      }

      //this.subscribeToRNDCState();

      if (this.permissionRole.hasPermission(Permission.notifications.module, Permission.notifications.negotiationNotifications)) {
        this.subscribeToNotifications(
          NotificationTypes.NEGOTIATION,
          notification => this.createGeneralNotification(notification, '¡Negociación!')
        );
      }

      if (this.permissionRole.hasPermission(Permission.notifications.module, Permission.notifications.trackingNotifications)) {
        this.subscribeToNotifications(
          NotificationTypes.TRACKING,
          notification => this.createGeneralNotification(notification, '¡Seguimiento!')
        );
      }

      if (this.permissionRole.hasPermission(Permission.notifications.module, Permission.notifications.delayBetweenPointsNotifications)) {
        this.subscribeToNotifications(
          NotificationTypes.DELAY_BETWEEN_POINTS,
          notification => this.createGeneralNotification(notification, '¡Carga creada!')
        );
      }

      if (this.permissionRole.hasPermission(Permission.notifications.module, Permission.notifications.createCargoNotifications)) {
        this.subscribeToNotifications(
          NotificationTypes.CREATE_CARGO,
          notification => this.createGeneralNotification(notification, '¡Carga creada!')
        );
      }

      if (this.permissionRole.hasPermission(Permission.notifications.module, Permission.notifications.userDeactivateNotifications)) {
        this.subscribeToNotifications(
          NotificationTypes.USER_DEACTIVATE,
          notification => this.createGeneralNotification(notification, '¡Usuario desactivado!')
        );
      }

      if (this.permissionRole.hasPermission(Permission.notifications.module, Permission.notifications.geofenceNotifications)) {
        this.subscribeToNotifications(
          NotificationTypes.GEOFENCE,
          notification => this.createGeneralNotification(notification, '¡Notificación de geocerca!')
        );
      }

      if (this.permissionRole.hasPermission(Permission.notifications.module, Permission.notifications.panicNotifications)) {
        this.subscribeToNotifications(
          NotificationTypes.PANIC,
          notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
          NotificationTypes.GPS_OFF,
          notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
          NotificationTypes.ANTENNA,
          notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
          NotificationTypes.VEHICLE_OFF,
          notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
          NotificationTypes.LOW_BATTERY,
          notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
          NotificationTypes.OPENED_DOOR,
          notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
          NotificationTypes.DEFAULT_ALERT,
          notification => this.createPanicNotification(notification)
        );
      }
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  async ngOnDestroy() {
    if (this.authService.sessionActive()) {
      await this.authService.updateUId();
    }
    if (this.collectionUnsubscribe) this.collectionUnsubscribe();
  }

  onActivate(componentReference) {

    // Tab Component
    if (componentReference.onGetListCargo) {
      this.refreshListCargo = componentReference.onGetListCargo;
    }
    // String type list active
    if (componentReference.getTypeList) {
      componentReference.getTypeList.subscribe((typeList: string) => {
        this.typeListFreighForwarderList = typeList;
      });

    }
    // Detail Tracking Component
    if (componentReference.onGetCargoDetail) {
      this.getCargoDetailTracking = componentReference.onGetCargoDetail;
    }
  }

  activeNotification(payload: PushMessage) {

    const options = {
      body: payload.data.message,
      icon: this.global.pathImgLogoteclogi,
    };

    if (!('Notification' in window)) {

      alert('Este navegador no soporta las notificaciones del sistema');
    } else if (Notification.permission === 'granted') {

      const notification = new Notification('Teclogi', options);
      setTimeout(notification.close.bind(notification), 5000);
    } else if (Notification.permission !== 'denied') {

      Notification.requestPermission((permission) => {
        if (permission === 'granted') {

          const notification = new Notification('Teclogi', options);
          notification.onclick = ((event) => { });
          setTimeout(notification.close.bind(notification), 5000);
        }
      });
    }
  }

  checkDataNotification(payload: PushMessage) {
    // tslint:disable-next-line: radix
    switch (parseInt(payload.data.type.toString())) {
      case 1:
        // Negotiation
        const hashNegotiationList = window.location.hash.substring(13);
        const hashNegotiationDetail = window.location.hash.substring(8);
        if (hashNegotiationList === 'loadsInNegotiation' && this.refreshListCargo) {
          this.refreshListCargo();
        } else if (hashNegotiationDetail === 'negotiation') {
          if (
            this.utils.isDefined(this.authService.cargoActive.cargo) &&
            this.utils.isDefined(this.authService.cargoActive.cargo.id) &&
            this.authService.cargoActive.cargo.id === payload.data.cargoId) {
            this.router.navigate(['/list/loadsInNegotiation']);
          }
        }
        break;
      case 2:
        // Tracking
        const hashTracking = window.location.hash.substring(8, 16);
        // if (this.utils.isDefined(payload.data.cargoId) && this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.transportedValueSemaphore)) {
        //   this.getCargoDetail(payload.data.cargoId);
        // }
        if (hashTracking === 'tracking' && this.utils.isDefined(payload.data.cargoId) && this.getCargoDetailTracking) {
          const listUrl = window.location.hash.substring(2).split('/');
          if (listUrl.length === 3) {
            if (payload.data.cargoId === listUrl[2]) {
              this.getCargoDetailTracking(payload.data.cargoId, true);
            }
          }
        }
        break;

      case 6:
        this.listNotifications.push(payload);
        break;
    }
  }

  private getCargoDetail(cargoId: string) {
    this.spinner.show();
    this.cargoDetailSerive.detailCargo(cargoId).subscribe(
      (success: Cargo) => {
        this.spinner.hide();
        if (!this.utils.isEmpty(success) && !this.utils.isEmpty(success.consecutive) && !this.utils.isEmpty(success.licensePlate)) {
          let obj = {
            consecutive: success.consecutive,
            licensePlate: success.licensePlate,
          }
          this.listCargo.push(obj);
          if (!this.dialogRef) {
            this.openDialogConfirmStartService();
          }
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  private openDialogConfirmStartService(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      listCargos: this.listCargo,
      dialog: true
    };
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    if (!this.dialogRef) {
      this.dialogRef = this.matDialog.open(AlertCargoComponent, dialogConfig);
    }
    this.dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.listCargo = this.listCargo.filter(value => result.consecutive.indexOf(value) == -1);
      }
      this.dialogRef = undefined;
    });
  }

  get isOutOfCargoCreate() {
    return window.location.hash !== "#/cargo/create";
  }

  goToWA() {
    window.open('https://wa.me/573173678485?text=¡Hola! Quisiera obtener más información.');
  }

  mouseEvent($event: MouseEvent) {
    if ($event.type === 'mouseleave') {
      this.navHover = false;
    }
    if ($event.type === 'mouseenter') {
      this.navHover = true;
    }
  }


  requestPermission() {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission()
        .then(() => {
          AuthService.fMessaging.getToken().then(
            (currentToken) => {
              if (currentToken) {
                this.sendDataToServiceWorker();
                this.authService.updateUId(currentToken).subscribe(() => { }, (error) => { });
                this.listen();
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
              console.error('An error occurred while retrieving token. ', err);
            });
        })
        .catch((error) => {
          console.error(error);
          this.snackBar.openSnackBar(ServiceMessages.CANNOT_SEND_NOTIFICATIONS, undefined, 'alert')
        });
    }
  }

  listen() {
    const messagingSenderId = AuthService.afAuth.app.options['messagingSenderId'];
    AuthService.fMessaging.onMessage((payload) => {
      if (messagingSenderId === payload.from) {
        this.activeNotification(payload);
        this.checkDataNotification(payload);
        this.appHeader.refreshNotifications();
      }
    });
  }

  public onClose(index: number) {
    this.listNotifications.splice(index, 1);
  }

  async sendDataToServiceWorker() {
    const messagingSenderId = AuthService.afAuth.app.options['messagingSenderId'];
    const channel = new BroadcastChannel('sw-messages');
    channel.postMessage(AuthService.afAuth.app.options);

    const channel2 = new BroadcastChannel('sw-messages3');
    channel2.addEventListener('message', (payload) => {
      if (messagingSenderId === payload.data.from) {
        this.activeNotification(payload.data);
        this.checkDataNotification(payload.data);
        this.appHeader.refreshNotifications();
      }
    });
  }

  private subscribeToRNDCState() {
    const rndc_state = FirebaseApi.root.firestore.collection('Configuration').doc('RNDC');
    rndc_state.onSnapshot((snapshot) => {
      const rndc = snapshot.data() as { contingency: boolean };
      this.rndcContingency = rndc.contingency;
    });
  }

  /**
   * @description Create a new type base notification watcher
   * @param {NotificationTypes} type: the type of notification based on NotificationTypes enum
   * @param {(Notification) => void} callback: some callback that recieves a notification as argument.
  */
  private subscribeToNotifications(type: NotificationTypes, callback?: (notification: Notification) => void): void {
    let isFirstTime = true;
    const last_notification = FirebaseApi.firestore.collection('LastNotification').doc(type);
    last_notification.onSnapshot((snapshot) => {
      if (isFirstTime) {
        isFirstTime = false;
        return;
      }
      const notification: Notification = snapshot.data() as Notification;
      this.listNotifications.push({
        data: {
          type: notification.type,
          cargoId: notification.cargoId,
          message: notification.description,
          consecutive: notification.consecutive,
          extraInformation: notification.extraInformation,
        },
        from: '',
        priority: ''
      });
      !!callback ? callback(notification) : 0;
    });
  }

  /**
   * @description Create / try create a browser push notification
   * @param {Notification} data: Notification stored in db
   * @param {string} title: Title of the push notification
   * @returns {MatDialogRef}
  */
  private createGeneralNotification(data, title: string) {
    Notification.requestPermission()
      .then(() => {
        const img = this.global.pathImgLogoteclogi;
        const text = `Carga ${data.consecutive} - ${data.description}`;
        const notification = new Notification(title, { body: text, icon: img });
      })
      .catch((error) => {
        console.error(error);
        this.snackBar.openSnackBar(ServiceMessages.CANNOT_SEND_NOTIFICATIONS, undefined, 'alert')
      });
  }

  /**
   * @description Creates a panic popup and generates a push notification to the browser.
   * @param {Notification} data: Notification stored in db
   * @returns {MatDialogRef}
   */
  private createPanicNotification(data: Notification) {
    const config = new MatDialogConfig();
    config.data = data;
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.LARGE_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    config.disableClose = true

    const dialog = this.matDialog.open(GpsNotificationComponent, config);
    this.createGeneralNotification(data, "¡Crítico!");
    return dialog;
  }

}


