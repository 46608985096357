import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LastPointLocation } from 'src/app/core/interfaces/lastPointLocation';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { GoogleService } from 'src/app/core/services/google.service';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {

  map: google.maps.Map;
  center: google.maps.LatLngLiteral = { lat: 30, lng: -110 };
  @Input() public locations = {};
  polylines = {};
  markers = {};
  infoContentMarkers = {};

  constructor(
    public utils: Utils,
    private googleService: GoogleService,
    private global: Global
  ) { }

  ngOnInit() {
    this.initMap();
  }

  initMap(): void {
    this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: this.center,
      zoom: 16
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.locations && changes.locations.currentValue) {
      this.checkLocations();
    }
  }

  private checkLocations() {
    const colors = this.utils.getRandomColorSize(Object.keys(this.locations).length);
    let count = 0;
    for (let key in this.locations) {
      if (this.locations[key].lastPoint) {
        let lat = this.locations[key].lastPoint.lat ? this.locations[key].lastPoint.lat : this.locations[key].lastPoint.latLong && this.locations[key].lastPoint.latLong.lat ? this.locations[key].lastPoint.latLong.lat : null;
        let lng = this.locations[key].lastPoint.lng ? this.locations[key].lastPoint.lng : this.locations[key].lastPoint.latLong && this.locations[key].lastPoint.latLong.lng ? this.locations[key].lastPoint.latLong.lng : null;
        if (lat && lng) {
          if (this.polylines[key]) {
            this.updateMarker(key, lat, lng);
          } else {
            this.createMarker(key, lat, lng, this.locations[key].contentInfoWindow);
          }
          this.setCenterMap(lat, lng);
        }

      }
      count++
    }
  }

  private async createPolyline(key, locations, color) {
    if (locations.path.length) {
      const cargoPath = await this.googleService.createPath(locations.path);
      this.polylines[key] = new google.maps.Polyline({
        path: cargoPath,
        geodesic: true,
        strokeColor: color,
        strokeWeight: 2,
      });
      this.polylines[key].setMap(this.map);
    }
  }

  public async updatePolyline(key, locations) {
    const cargoPath = await this.googleService.createPath(locations);
    this.polylines[key].setPath(cargoPath);
  }

  private createMarker(key, lat: number, lng: number, contentInfoWindow?) {
    this.markers[key] = new google.maps.Marker({
      icon: this.global.pathMarkerVehicle,
      position: { lat: lat, lng: lng },
      map: this.map,
    });

    if (contentInfoWindow) {
      this.createInfoWindow(key, contentInfoWindow, this.markers[key]);
    }
    this.markers[key].setMap(this.map);
  }

  private updateMarker(key, lat: number, lng: number, contentInfoWindow?) {
    this.markers[key].setPosition({ lat: lat, lng: lng });
    this.markers[key].setMap(this.map);
  }

  private createInfoWindow(key, content: string, marker: google.maps.Marker) {
    this.infoContentMarkers[key] = new google.maps.InfoWindow({
      content,
    });
    marker.addListener("click", () => {
      this.infoContentMarkers[key].open(this.map, marker);
    });
  }

  private updateInfoWindow(key, content: string) {
    this.infoContentMarkers[key].setContent(content);
  }

  public setCenterMap(lat: number, lng: number): void {
    this.map.setCenter(new google.maps.LatLng(lat, lng));
  }

}
