<form class="row" *ngIf="reactiveForm" [formGroup]="reactiveForm.form" (submit)="onSubmit()">

  <div class="col-md-3">
    <mat-form-field class="cargo__generate_filter--field">
      <mat-label>Tipo Archívo</mat-label>
      <mat-select formControlName="typeFile">
        <mat-option value="{{type.id}}" *ngFor="let type of typeFiles;">
          {{type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-3">
    <mat-form-field class="cargo__generate_filter--field">
      <mat-label>Desde</mat-label>
      <input matInput [min]="optionsDateFrom.minDate" [max]="optionsDateFrom.maxDate" [matDatepicker]="pickerDateFrom"
        formControlName="dateFrom" (dateChange)="onChangeDateFrom($event)">
      <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerDateFrom></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field class="cargo__generate_filter--field">
      <mat-label>Hasta</mat-label>
      <input matInput [max]="optionsDateUntil.maxDate" [min]="optionsDateUntil.minDate"
        [matDatepicker]="pickerDateUntil" formControlName="dateUntil" (dateChange)="onChangeDateUntil($event)">
      <mat-datepicker-toggle matSuffix [for]="pickerDateUntil"></mat-datepicker-toggle>
      <mat-datepicker #pickerDateUntil></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    <mat-card-actions>
      <button mat-raised-button color="primary-secondary" title="Generar" type="submit">
        Generar
      </button>
    </mat-card-actions>
  </div>
</form>