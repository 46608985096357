import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ticket } from 'src/app/core/interfaces/ticket';
import { create } from 'domain';


@Injectable({
  providedIn: 'root'
})
export class ViewTicketInfoService {

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private _globalResources: Global,
  ) { }

  getTicket(id) {
    let params = new HttpParams();
    params = params.append('id', id)
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.helpDesk, { params }
    );
  }

  updateTicket(ticket) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.helpDesk, ticket
    );
  }

  createTicket(newticket) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.helpDesk, newticket
    )
  }

  /* getDescription(id: string, name: string) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.helpDesk + id + name
    );
  } */


}
