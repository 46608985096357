<div id="mainFreightItinerary" class="col-md-12 mb-md-0 mb-12">
  <div class="row h-100 pt-5">

    <mat-tab-group (selectedIndexChange)="onSelectTab($event)" permission-tab
      [permissionModule]="permission.administration.module"
      [permissionListName]="[permission.administration.adminTerms]">
      <mat-tab label="Términos y condiciones">
        <app-list-terms-and-conditions *ngIf="activeTab===0"></app-list-terms-and-conditions>
      </mat-tab>
      <mat-tab label="Políticas de privacidad">
        <app-list-privacy-policy *ngIf="activeTab===1"></app-list-privacy-policy>
      </mat-tab>
      <mat-tab label="Políticas para tratamiento de datos personales">
        <app-list-persona-data *ngIf="activeTab===2"></app-list-persona-data>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>