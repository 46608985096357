<aside id="mainCollectionPointList" class="col-md-12 mb-0 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-10">
        </div>
        <div class="col-md-2 header-list-options text-right">
          <button matTooltip="Crear Empresa" permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.createCompany" mat-raised-button type=" button"
            class="button-width-auto" (click)="goToCreate()">
            <i class="fas fa-plus-circle font-primary-dark"></i>
            <span class="font-primary-dark">Crear</span>
          </button>
        </div>
      </div>
    </div>
    <mat-form-field appearance="standard">
      <mat-label>Compañia</mat-label>
      <input type="text" matInput [(ngModel)]="companiesFilter">
    </mat-form-field>
    <span class="separator-horizontal"></span>
    <div class="container-list-body">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let company of listCompanies | filterCompanies:companiesFilter">
        <app-item-company [company]="company"></app-item-company>
      </div>
    </div>
  </div>
</aside>
