<mat-form-field appearance="outline">
    <mat-label>Tipo de carrocería<span class="asterisk"
            *ngIf="utils.isRequiredField(bodyWorkTypeCtrl)">*</span></mat-label>
    <input (keyup)="changeValue()" type="text" matInput [matAutocomplete]="auto" [formControl]="bodyWorkTypeCtrl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectValue($event)">
        <mat-option *ngFor="let bodywork of filteredOptions | async" [value]="bodywork">
            <span>{{bodywork.name}}</span>
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="bodyWorkTypeCtrl.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
</mat-form-field>