<main class="container">
    <div class="">
        <div class='row' style="max-width: 700px; margin: auto; padding-top: 50px;">
            <div class='col-md-8'>
                <p><span
                        style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 243.00px; text-align: center;">
                        <img alt="" src="./assets/images/bascPolicy/INNOVBO.png"
                            style="width: 243.00px; height: 63.64px; margin-left: 0.00px; margin-top: 4.09px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                            title="">
                    </span></p>
            </div>
            <div class='col-md-4'>
                <p><span></span></p>
                <p class="c8"><span class="c16">C&Oacute;DIGO: SG01-PO06</span></p>
                <p class="c8"><span class=c16>VERSI&Oacute;N 02 &nbsp;</span></p>
                <p class="c8"><span class=c16>VIGENCIA: 08-MAY-2023</span></p>
            </div>
        </div>
        <h3 style="text-align: center;font-weight: bold;">POL&Iacute;TICA DE PRIVACIDAD</h3>

        <p class="c38 c33 c18 c39">
            <span class="c1">
                En cumplimiento de la Ley 1581 de 2012 y el Decreto 1377 de 2013, vigentes en la República de Colombia,
                y
                cualquier otra norma aplicable en la materia, la siguiente es la Política de Tratamiento de la
                Información de
                Innovbo S.A.S.
            </span>
        </p>
        <p class="c2 c3 c33"><span class="c1"></span></p>
        <ol class="c4 lst-kix_list_1-0 start" start="1">
            <li class="c2 c24 c25 li-bullet-0">
                <span class="c0">
                    DATOS DEL RESPONSABLE TRATAMIENTO DE INFORMACI&Oacute;N
                </span>
            </li>
        </ol>
        <p class="c13 c25"><span class="c1">&nbsp;&nbsp;&nbsp;Innovbo
                S.A.S.<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NIT
                901.783.572-9<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Domicilio:
                Bogot&aacute;<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Direcci&oacute;n:
                Cll 26 # 96j -
                66<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Correo
                Electr&oacute;nico:
                gerencia@innovbo.com<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tel&eacute;fono:
                +57 315 725 1091
            </span></p>
        <p class="c13 c3"><span class="c1"></span></p>
        <ol class="c4 lst-kix_list_1-0" start="2">
            <li class="c2 c25 c32 li-bullet-0"><span class="c0">DISPOSICIONES GENERALES</span></li>
        </ol>
        <p class="c2 c25"><span class="c12 c0">2.1 Objetivo</span></p>
        <p class="c2 c18"><span class="c1">La presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n tiene
                como
                objetivo garantizar el adecuado cumplimiento de las normas aplicables en materia de protecci&oacute;n de
                datos personales aplicables y poner en conocimiento el uso que Innovbo S.A.S. le da a la
                informaci&oacute;n proporcionada por los Titulares, e informar sobre los procedimientos y mecanismos al
                manejo de los Datos Personales recopilados para las finalidades previstas con la autorizaci&oacute;n
                previa.</span></p>
        <p class="c2 c25"><span class="c12 c0">2.2 Legislaci&oacute;n Aplicable</span></p>
        <p class="c2 c18"><span class="c1">La presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n fue
                elaborada
                de conformidad con las disposiciones contenidas en los art&iacute;culos 15 y 20 de la
                Constituci&oacute;n
                Pol&iacute;tica, la Ley 1581 de 2012 y sus decretos reglamentarios, en especial el Decreto 1377 de
                2013.</span></p>
        <p class="c2 c3"><span class="c12 c0"></span></p>
        <p class="c2 c25"><span class="c12 c0">2.3 &Aacute;mbito de aplicaci&oacute;n</span></p>
        <p class="c2 c18"><span class="c1">La presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n es
                aplicable
                al tratamiento de los datos personales registrados en cualquier base de datos que los haga susceptibles
                de
                Tratamiento por parte de Innovbo S.A.S., bien sea en calidad de Responsable y/o Encargado.</span></p>
        <p class="c2 c3"><span class="c12 c0"></span></p>
        <p class="c2 c25"><span class="c0 c12">2.4 Definiciones</span></p>
        <p class="c2 c18"><span class="c1">Para los efectos de aplicaci&oacute;n de la presente Pol&iacute;tica, y en
                concordancia con lo establecido en el art&iacute;culo 3 de la ley 1581 de 2012 y en el art&iacute;culo 3
                del
                Decreto 1377 de 2013 se entiende por:</span></p>
        <p class="c2 c3"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.1 Autorizaci&oacute;n: </span><span class="c1">Consentimiento previo,
                expreso
                e informado del Titular para llevar a cabo el tratamiento de sus Datos Personales.</span></p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.2 Aviso de Privacidad: </span><span class="c1">Documento f&iacute;sico,
                electr&oacute;nico o en cualquier otro formato, generado por el responsable, dirigido al titular para el
                Tratamiento de sus Datos Personales, mediante el cual se le informar acerca de la existencia de las
                pol&iacute;ticas del tratamiento de la informaci&oacute;n, que le ser&aacute;n aplicables a sus datos,
                la
                forma de acceder a las mismas y la finalidad para la cual ser&aacute;n usados dichos datos.</span></p>
        <p class="c2 c3"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.3 Base de Datos: </span><span class="c1">Conjunto organizado de Datos
                Personales que sea objeto de tratamiento. Puede ser automatizada o f&iacute;sica de acuerdo a su forma
                de
                Tratamiento o almacenamiento.</span></p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.4 Dato Personal: </span><span class="c1">Cualquier informaci&oacute;n
                vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables. </span>
        </p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.5 Dato Privado: </span><span class="c1">Aquel que por su naturaleza
                &iacute;ntima o reservada s&oacute;lo es relevante para el Titular.</span></p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.6 Dato P&uacute;blico: </span><span class="c1">Aquel que no sea
                Semiprivado,
                Privado o Sensible. Son considerados Datos P&uacute;blicos, entre otros, los datos relativos al estado
                civil
                de las personas, a su profesi&oacute;n u oficio y a su calidad de comerciante o de servidor
                p&uacute;blico.
                Por su naturaleza, los datos p&uacute;blicos pueden estar contenidos, entre otros, en registros
                p&uacute;blicos, documentos p&uacute;blicos, gacetas y boletines oficiales y sentencias judiciales
                debidamente ejecutoriadas que no est&eacute;n sometidas a reserva.</span></p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.7 Dato Sensible: </span><span class="c1">Aquel que afecta la intimidad
                del
                Titular o cuyo uso indebido puede generar su discriminaci&oacute;n, incluyendo pero sin limitarse a
                datos
                que revelen el origen racial o &eacute;tnico, la orientaci&oacute;n pol&iacute;tica, las convicciones
                religiosas o filos&oacute;ficas, la pertenencia a sindicatos, organizaciones sociales, de derechos
                humanos o
                que promueva intereses de cualquier partido pol&iacute;tico o que garanticen los derechos y
                garant&iacute;as
                de partidos pol&iacute;ticos de oposici&oacute;n, as&iacute; como los datos relativos a la salud, a la
                vida
                sexual y los datos biom&eacute;tricos.</span></p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.8 Encargado del Tratamiento: </span><span class="c1">Persona natural o
                jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con otros, realice el
                tratamiento de Datos Personales por cuenta del Responsable del Tratamiento.</span></p>
        <p class="c2 c3"><span class="c12 c0"></span></p>
        <p class="c2 c25"><span class="c0">2.4.9 Productos: </span><span class="c1">Hace referencia a bienes o
                servicios.</span></p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.10 Responsable del Tratamiento: </span><span class="c1">Persona natural o
                jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con otros, decida sobre
                la
                Base de Datos y/o el Tratamiento de los datos.</span></p>
        <p class="c2 c3"><span class="c12 c0"></span></p>
        <p class="c2 c25"><span class="c0">2.4.11 Titular: </span><span class="c1">Persona natural cuyos Datos
                Personales
                sean objeto de Tratamiento.</span></p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.12 Transferencia: </span><span class="c1">La Transferencia de datos tiene
                lugar cuando el Responsable y/o Encargado del Tratamiento de datos personales, ubicado en Colombia,
                env&iacute;a la informaci&oacute;n o los datos personales a un receptor, que a su vez es responsable del
                tratamiento y se encuentra dentro o fuera del pa&iacute;s.</span></p>
        <p class="c2 c5"><span class="c12 c0"></span></p>
        <p class="c2 c18"><span class="c0">2.4.13 Transmisi&oacute;n: </span><span class="c1">Tratamiento de Datos
                Personales que implica la comunicaci&oacute;n de los mismos dentro o fuera del territorio de la
                Rep&uacute;blica de Colombia cuando tenga por objeto la realizaci&oacute;n de un Tratamiento por el
                Encargado por cuenta del Responsable.</span></p>
        <p class="c2 c18"><span class="c0">2.4.14 Tratamiento: </span><span class="c1">Cualquier operaci&oacute;n o
                conjunto
                de operaciones sobre Datos Personales, tales como la recolecci&oacute;n, almacenamiento, uso,
                circulaci&oacute;n o supresi&oacute;n.</span></p>
        <p class="c13 c11"><span class="c12 c0"></span></p>
        <p class="c13"><span class="c12 c0">2.5 Principios rectores aplicables al Tratamiento de Datos Personales</span>
        </p>
        <p class="c2"><span class="c1">Con el fin de garantizar el Tratamiento de Datos Personales que realice Innovbo
                S.A.S. como Responsable y/o Encargado de la informaci&oacute;n, aplicar&aacute;n los siguientes
                principios,
                en los t&eacute;rminos del art&iacute;culo 4 de la Ley 1581 de 2012:</span></p>
        <p class="c2 c11"><span class="c12 c0"></span></p>
        <p class="c2"><span class="c0">2.5.1 Legalidad: </span><span class="c1">El Tratamiento que realice Innovbo
                S.A.S. sobre los Datos Personales estar&aacute; sujeto a lo establecido en la Ley 1581 de 2012 y
                dem&aacute;s normas aplicables en la materia.Finalidad</span></p>
        <p class="c2 c11"><span class="c12 c0"></span></p>
        <p class="c2"><span class="c0">2.5.2 El Tratamiento que realice: </span><span class="c1">Innovbo S.A.S.
                obedecer&aacute; a una finalidad leg&iacute;tima de acuerdo con la Constituci&oacute;n y la ley, la cual
                ser&aacute; informada al Titular.</span></p>
        <p class="c2 c11"><span class="c12 c0"></span></p>
        <p class="c2"><span class="c0">2.5.3 Libertad: </span><span class="c1">El Tratamiento s&oacute;lo puede
                ejercerse
                con el consentimiento, previo, expreso e informado del Titular. Los Datos Personales no podr&aacute;n
                ser
                obtenidos o divulgados sin previa autorizaci&oacute;n, o en ausencia de mandato legal o judicial que
                releve
                el consentimiento.</span></p>
        <p class="c2 c11"><span class="c12 c0"></span></p>
        <p class="c2"><span class="c0">2.5.4 Veracidad o Calidad: </span><span class="c1">La informaci&oacute;n sujeta a
                Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se proh&iacute;be
                el
                Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</span></p>
        <p class="c2 c11"><span class="c12 c0"></span></p>
        <p class="c2"><span class="c0">2.5.5 Transparencia: </span><span class="c1">En el Tratamiento se
                garantizar&aacute;
                el derecho del Titular a obtener de Innovbo S.A.S. como Responsable y/o Encargado, en cualquier
                momento y sin restricciones, informaci&oacute;n acerca de la existencia de datos que le
                concierne.</span>
        </p>
        <p class="c2 c11"><span class="c12 c0"></span></p>
        <p class="c2"><span class="c0">2.5.6 Acceso y Circulaci&oacute;n Restringida: </span><span class="c1">El
                Tratamiento
                se sujeta a los l&iacute;mites que se derivan de la naturaleza de los Datos Personales, de las
                disposiciones
                de la Ley 1581 de 2012 y la Constituci&oacute;n. En este sentido, el Tratamiento s&oacute;lo
                podr&aacute;
                hacerse por personas autorizadas por el Titular y/o por las personas previstas en la Ley 1581 de
                2012.</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <p class="c2"><span class="c0">2.5.7 Seguridad: </span><span class="c1">La informaci&oacute;n sujeta a
                Tratamiento
                por Innovbo S.A.S. como Responsable del Tratamiento y/o Encargado del, se deber&aacute; manejar con
                las medidas t&eacute;cnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los
                registros evitando su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o
                fraudulento.</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <p class="c2"><span class="c0">2.5.8 Confidencialidad: </span><span class="c1">Todas las personas que
                intervengan en
                el Tratamiento de Datos Personales que no tengan la naturaleza de p&uacute;blicos est&aacute;n obligadas
                a
                garantizar la reserva de la informaci&oacute;n, inclusive despu&eacute;s de finalizada su
                relaci&oacute;n
                con alguna de las labores que comprende el Tratamiento, pudiendo s&oacute;lo realizar suministro o
                comunicaci&oacute;n de Datos Personales cuando ello corresponda al desarrollo de las actividades
                autorizadas
                en la Ley 1581 de 2012 y en los t&eacute;rminos de la misma.</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <ol class="c4 lst-kix_list_1-0" start="3">
            <li class="c2 c24 li-bullet-0"><span class="c12 c0">TRATAMIENTO AL CUAL ESTAR&Aacute;N SOMETIDOS LOS DATOS
                    PERSONALES E INFORMACI&Oacute;N COMERCIAL Y SU FINALIDAD</span></li>
        </ol>
        <p class="c2 c33"><span class="c1">Los datos personales ser&aacute;n recolectados, almacenados, transmitidos,
                transferidos, procesados y usados para todos aquellos aspectos inherentes a los Productos ofrecidos por
                Innovbo S.A.S., y la ejecuci&oacute;n de los contratos con clientes, usuarios, proveedores, empleados
                y dem&aacute;s colaboradores, lo que implica el uso de los datos ser&aacute;n usados para los siguientes
                fines:</span></p>
        <p class="c2 c33 c11"><span class="c1"></span></p>
        <p class="c13"><span class="c12 c0">3.1 Clientes y Usuarios</span></p>
        <p class="c2"><span class="c0">3.1.1</span><span class="c1">&nbsp;Cumplir las obligaciones contra&iacute;das por
                Innovbo S.A.S. con sus clientes y usuarios.</span></p>
        <p class="c13"><span class="c0">3.1.2</span><span class="c1">&nbsp;Enviar informaci&oacute;n sobre los Productos
                que
                ofrece Innovbo S.A.S.</span></p>
        <p class="c2"><span class="c0">3.1.3</span><span class="c1">&nbsp;Enviar informaci&oacute;n sobre cambios en las
                condiciones de los Productos ofrecidos por Innovbo S.A.S.</span></p>
        <p class="c2"><span class="c0">3.1.4</span><span class="c1">&nbsp;Enviar al correo f&iacute;sico,
                electr&oacute;nico, celular o dispositivo m&oacute;vil, v&iacute;a mensajes de texto (SMS y/o MMS) o a
                trav&eacute;s de cualquier otro medio an&aacute;logo y/o digital de comunicaci&oacute;n creado o por
                crearse, informaci&oacute;n comercial, publicitaria o promocional sobre los Productos de Innovbo
                S.A.S., eventos y/o promociones de tipo comercial o no de estas, con el fin de impulsar, invitar,
                dirigir,
                ejecutar, informar y de manera general, llevar a cabo campa&ntilde;as, promociones o concursos de
                car&aacute;cter comercial o publicitario, adelantados por Innovbo S.A.S. y/o por terceras
                personas.</span></p>
        <p class="c2"><span class="c0">3.1.5</span><span class="c1">&nbsp;Tratar los datos de los clientes y usuarios de
                Innovbo S.A.S. suministrados por terceros para las finalidades aqu&iacute; se&ntilde;aladas.</span>
        </p>
        <p class="c2"><span class="c0">3.1.6 </span><span class="c1">Transferir o transmitir los Datos Personales a
                terceras
                personas en Colombia y en el exterior a t&iacute;tulo gratuito u oneroso para su uso comercial.</span>
        </p>
        <p class="c2"><span class="c0">3.1.7</span><span class="c1">&nbsp;Transferir o transmitir los datos personales,
                de
                los clientes y usuarios de Innovbo S.A.S., a Encargados del Tratamiento en Colombia y en el exterior
                cuando ello sea necesario para la ejecuci&oacute;n de los contratos con los clientes por Innovbo
                S.A.S.</span></p>
        <p class="c2"><span class="c0">3.1.8</span><span class="c1">&nbsp;Elaborar estudios de anal&iacute;tica que
                incluyan
                cualquier detalle relacionado con los Productos ofrecidos por Innovbo S.A.S. con el fin de
                compartirlos con socios vinculados al negocio de Innovbo S.A.S.</span></p>
        <p class="c2"><span class="c0">3.1.9</span><span class="c1">&nbsp;Los dem&aacute;s fines necesarios para dar
                cumplimiento a los contratos suscritos con los clientes y usuarios de Innovbo S.A.S.</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <p class="c13"><span class="c12 c0">3.2 Proveedores</span></p>
        <p class="c13"><span class="c0">3.2.1</span><span class="c1">&nbsp;Para ejecutar los contratos con los
                proveedores
                de Innovbo S.A.S.</span></p>
        <p class="c13"><span class="c0">3.2.2</span><span class="c1">&nbsp;Para referenciarlos con otras personas
                naturales
                o jur&iacute;dicas.</span></p>
        <p class="c11 c38"><span class="c1"></span></p>
        <p class="c13"><span class="c12 c0">3.3 Empleados</span></p>
        <p class="c13"><span class="c0">3.3.1</span><span class="c1">&nbsp;Para elaborar las hojas de vida.</span></p>
        <p class="c2"><span class="c0">3.3.2</span><span class="c1">&nbsp;Para ejecutar y cumplir con el contrato de
                trabajo
                y dem&aacute;s obligaciones que como empleador le corresponden por ley.</span></p>
        <p class="c2"><span class="c0">3.3.3</span><span class="c1">&nbsp;Administrar y operar, directamente o por
                conducto
                de terceros, los procesos de selecci&oacute;n y vinculaci&oacute;n de personal, incluyendo la
                evaluaci&oacute;n y calificaci&oacute;n de los participantes y la verificaci&oacute;n de referencias
                laborales y personales, y la realizaci&oacute;n de estudios de seguridad.</span></p>
        <p class="c2"><span class="c0">3.3.4</span><span class="c1">&nbsp;Desarrollar las actividades propias de la
                gesti&oacute;n de recursos humanos tales como n&oacute;mina, afiliaciones a entidades del sistema
                general de
                seguridad social, actividades de bienestar y salud ocupacional, ejercicio de la potestad sancionatoria
                del
                empleador, entre otras.</span></p>
        <p class="c2"><span class="c0">3.3.5</span><span class="c1">&nbsp;Realizar los pagos necesarios derivados de la
                ejecuci&oacute;n del contrato de trabajo y/o su terminaci&oacute;n, y las dem&aacute;s prestaciones
                sociales
                a que haya lugar de conformidad con la ley aplicable.</span></p>
        <p class="c2"><span class="c0">3.3.6 </span><span class="c1">Contratar beneficios laborales con terceros, tales
                como
                seguros de vida, gastos m&eacute;dicos, entre otros.</span></p>
        <p class="c2"><span class="c0">3.3.7</span><span class="c1">&nbsp;Notificar a contactos autorizados en caso de
                emergencias durante el horario de trabajo o con ocasi&oacute;n del desarrollo del mismo.</span></p>
        <p class="c2"><span class="c0">3.3.8 </span><span class="c1">Coordinar el desarrollo profesional de los
                empleados,
                el acceso de los empleados a los recursos inform&aacute;ticos y asistir en su utilizaci&oacute;n.</span>
        </p>
        <p class="c2"><span class="c0">3.3.9</span><span class="c1">&nbsp;Transferir y transmitir la informaci&oacute;n
                de
                los empleados a Encargados del Tratamiento en Colombia y en el exterior cuando ello sea necesario para
                el
                desarrollo de sus operaciones y gesti&oacute;n de n&oacute;mina.</span></p>
        <p class="c2"><span class="c0">3.3.10 </span><span class="c1">Los dem&aacute;s fines necesarios para dar
                cumplimiento al contrato de trabajo.</span></p>
        <p class="c13"><span class="c12 c0">3.4 Accionistas y Miembros de la Junta Directiva</span></p>
        <p class="c2"><span class="c0">3.4.1</span><span class="c1">&nbsp;Para convocarlos a las reuniones de la
                Asamblea
                General de Accionistas o de la Junta Directiva seg&uacute;n corresponda.</span></p>
        <p class="c2"><span class="c0">3.4.2</span><span class="c1">&nbsp;Para enviarles la informaci&oacute;n necesaria
                de
                la Sociedad con el fin de tomar decisiones informadas en las respectivas reuniones.</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <p class="c13"><span class="c12 c0">3.5 Finalidades aplicables a todos</span></p>
        <p class="c2"><span class="c0">3.5.1 </span><span class="c1">Para el fortalecimiento de las relaciones con sus
                clientes y proveedores, mediante el env&iacute;o de informaci&oacute;n relevante, la toma de pedidos y
                evaluaci&oacute;n de la calidad de los Productos de Innovbo S.A.S.</span></p>
        <p class="c2"><span class="c0">3.5.2</span><span class="c1">&nbsp;Para la determinaci&oacute;n de obligaciones
                pendientes, la consulta de informaci&oacute;n financiera e historia crediticia y el reporte a centrales
                de
                informaci&oacute;n de obligaciones incumplidas, respecto de sus deudores en los t&eacute;rminos de la
                Ley
                1266 de 2008.</span></p>
        <p class="c2"><span class="c0">3.5.3 </span><span class="c1">Controlar el acceso a las oficinas de Innovbo
                S.A.S., y establecer medidas de seguridad, incluyendo el establecimiento de zonas videovigiladas.</span>
        </p>
        <p class="c2"><span class="c0">3.5.4 </span><span class="c1">Dar respuesta a consultas, peticiones, quejas y
                reclamos que sean realizadas por los titulares y transmitir los Datos Personales a organismos de control
                y
                dem&aacute;s autoridades que en virtud de la ley aplicable deban recibir los Datos Personales.</span>
        </p>
        <p class="c2"><span class="c0">3.5.5 </span><span class="c1">Registrar los datos personales en los sistemas de
                informaci&oacute;n de Innovbo S.A.S. y en sus bases de datos comerciales y operativas.</span></p>
        <p class="c2"><span class="c0">3.5.6 </span><span class="c1">Suministrar, compartir, enviar o entregar sus datos
                personales a empresas matrices, filiales, vinculadas, o subordinadas de Innovbo S.A.S. ubicadas en
                Colombia o en el extranjero en el evento que dichas compa&ntilde;&iacute;as requieran la
                informaci&oacute;n
                para los fines aqu&iacute; indicados.</span></p>
        <p class="c2"><span class="c0">3.5.7 </span><span class="c1">Determinar y compartir la ubicaci&oacute;n, para
                que
                funcionen los servicios debemos conocer la ubicaci&oacute;n. Dondequiera que abra y
                use/interact&uacute;e
                con nuestras aplicaciones en su dispositivo m&oacute;vil o vaya a uno de nuestros sitios, usamos la
                informaci&oacute;n de ubicaci&oacute;n de su dispositivo m&oacute;vil o navegador (por ejemplo, latitud
                y
                longitud) para ver el seguimiento de la carga en tiempo real. Esta informaci&oacute;n se comparte con el
                generador de carga.<br><br>Si tienes activada la &#39;ubicaci&oacute;n en segundo plano&#39; y una carga
                en
                ruta, la aplicaci&oacute;n Innovbo nos informar&aacute; sobre la ubicaci&oacute;n de su dispositivo,
                incluso
                si no est&aacute; interactuando directamente con la aplicaci&oacute;n.<br><br>Datos recopilados
                autom&aacute;ticamente cuando usted usa nuestra aplicaci&oacute;n: Si opta por compartir su
                ubicaci&oacute;n
                con nosotros, recibimos autom&aacute;ticamente datos sobre su ubicaci&oacute;n exacta (por ejemplo,
                coordenadas de latitud-longitud) cada vez que utiliza nuestras aplicaciones m&oacute;viles o visita
                nuestro
                sitio web. Si opta por tener una ubicaci&oacute;n en segundo plano en nuestras Apps, recopilaremos datos
                sobre su ubicaci&oacute;n cuando no est&eacute; usando las Apps y cuando las Apps est&eacute;n cerradas
                (seg&uacute;n lo permita la configuraci&oacute;n de su dispositivo).</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <ol class="c4 lst-kix_list_1-0" start="4">
            <li class="c2 c24 li-bullet-0"><span class="c12 c0">DERECHOS DE LOS TITULARES DE DATOS</span></li>
        </ol>
        <p class="c13 c33"><span class="c1">Conforme lo dispone la Ley 1581 de 2012, los titulares de datos personales
                tienen derecho a:</span></p>
        <p class="c2"><span class="c0">4.1 </span><span class="c1">Conocer, actualizar y rectificar sus datos personales
                frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podr&aacute;
                ejercer, entre otros, frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a
                error,
                o aquellos cuyo Tratamiento est&eacute; expresamente prohibido o no haya sido autorizado.</span></p>
        <p class="c2"><span class="c0">4.2 </span><span class="c1">Solicitar prueba de la autorizaci&oacute;n otorgada
                al
                Responsable del Tratamiento salvo cuando expresamente se except&uacute;e como requisito para el
                Tratamiento,
                de conformidad con lo previsto en el art&iacute;culo 10 de la Ley 1581 de 2012.</span></p>
        <p class="c2"><span class="c0">4.3 </span><span class="c1">Ser informado por el Responsable del Tratamiento o el
                Encargado del Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos
                personales.</span>
        </p>
        <p class="c2"><span class="c0">4.4 </span><span class="c1">Presentar ante la Superintendencia de Industria y
                Comercio quejas por infracciones a lo dispuesto en la presente ley y las dem&aacute;s normas que la
                modifiquen, adicionen o complementen.</span></p>
        <p class="c2"><span class="c0">4.5 </span><span class="c1">Revocar la autorizaci&oacute;n y/o solicitar la
                supresi&oacute;n del dato cuando en el Tratamiento no se respeten los principios, derechos y
                garant&iacute;as constitucionales y legales. La revocatoria y/o supresi&oacute;n proceder&aacute; cuando
                la
                Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o
                Encargado
                han incurrido en conductas contrarias a esta ley y a la Constituci&oacute;n.</span></p>
        <p class="c2"><span class="c0">4.5 </span><span class="c1">Acceder en forma gratuita a sus datos personales que
                hayan sido objeto de Tratamiento.</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <ol class="c4 lst-kix_list_1-0" start="5">
            <li class="c2 c24 li-bullet-0"><span class="c12 c0">PERSONA RESPONSABLE DE LA ATENCI&Oacute;N DE PETICIONES,
                    CONSULTAS Y RECLAMOS ANTE LA CUAL EL TITULAR DE LA INFORMACI&Oacute;N</span></li>
        </ol>
        <p class="c2 c33"><span class="c1">La Gerencia de Innovbo S.A.S. ser&aacute; la encargada de atender las
                peticiones, consultas y reclamos de los titulares de la informaci&oacute;n. Cualquier solicitud debe ser
                enviada al correo electr&oacute;nico gerencia@innovbo.com el fin de ejercer los derechos a conocer,
                actualizar, rectificar y suprimir los datos y revocar la autorizaci&oacute;n otorgados por la ley y por
                esta
                Pol&iacute;tica de Tratamiento de Informaci&oacute;n.</span></p>
        <p class="c2 c11 c33"><span class="c1"></span></p>
        <ol class="c4 lst-kix_list_1-0" start="6">
            <li class="c2 c24 li-bullet-0"><span class="c12 c0">PROCEDIMIENTO PARA QUE LOS TITULARES DE LA
                    INFORMACI&Oacute;N PUEDAN EJERCER LOS DERECHOS A CONOCER, ACTUALIZAR, RECTIFICAR Y SUPRIMIR
                    INFORMACI&Oacute;N Y REVOCAR LA AUTORIZACI&Oacute;N.</span></li>
        </ol>
        <p class="c13"><span class="c12 c0">6.1 Consultas</span></p>
        <p class="c2"><span class="c0">6.1.1 </span><span class="c1">Innovbo S.A.S. dispone del correo
                electr&oacute;nico gerencia@innovbo.com para que el titular, sus causahabientes, sus representantes y
                apoderados, y los representantes de menores de edad titulares, formulen consultas respecto de
                cu&aacute;les
                son los datos personales del titular que reposan en las bases de datos de Innovbo S.A.S.</span></p>
        <p class="c2"><span class="c0">6.1.2 </span><span class="c1">Si el solicitante tuviere capacidad para formular
                la
                consulta, en los t&eacute;rminos se&ntilde;alados en la Ley 1581 de 2012 y sus decretos reglamentarios,
                Innovbo S.A.S. recopilar&aacute; toda la informaci&oacute;n sobre el titular que est&eacute; contenida
                las bases de datos de Innovbo S.A.S. y se la har&aacute; conocer al solicitante.</span></p>
        <p class="c2"><span class="c0">6.1.3 </span><span class="c1">El responsable de atender la consulta, seg&uacute;n
                se
                se&ntilde;ala en el numeral 4 arriba, dar&aacute; respuesta al solicitante siempre y cuando tuviere
                derecho
                a ello por ser el titular del dato personal, su causahabiente, apoderado, representante, o sea el
                responsable legal en el caso de menores de edad. Esta respuesta se enviar&aacute; dentro de los diez
                (10)
                d&iacute;as h&aacute;biles contados a partir de la fecha en la que la solicitud fue recibida por la
                Innovbo S.A.S..</span></p>
        <p class="c2"><span class="c0">6.1.4 </span><span class="c1">En caso de que la solicitud no pueda ser atendida a
                los
                diez (10) d&iacute;as h&aacute;biles, se contactar&aacute; al solicitante para comunicarle los motivos
                por
                los cuales el estado de su solicitud se encuentra en tr&aacute;mite. Para ello se utilizar&aacute; el
                mismo
                medio o uno similar al que fue utilizado por el titular para comunicar su solicitud.</span></p>
        <p class="c2"><span class="c0">6.1.5 </span><span class="c1">La respuesta definitiva a todas las solicitudes no
                tardar&aacute; m&aacute;s de quince (15) d&iacute;as h&aacute;biles desde la fecha en la que la
                solicitud
                inicial fue recibida por Innovbo S.A.S. al correo electr&oacute;nico gerencia@innovbo.com.</span></p>
        <p class="c11 c19"><span class="c1"></span></p>
        <p class="c13"><span class="c12 c0">6.2 Reclamos</span></p>
        <p class="c2"><span class="c1">Innovbo S.A.S. dispone del correo electr&oacute;nico gerencia@innovbo.com para
                que el titular, sus causahabientes, sus representantes y apoderados, y los representantes de menores de
                edad
                titulares, formulen reclamos respecto de (i) datos personales tratados por la empresa que deben ser
                objeto
                de correcci&oacute;n, actualizaci&oacute;n o supresi&oacute;n, o (ii) el presunto incumplimiento de las
                normas aplicables en la materia.</span></p>
        <p class="c2"><span class="c0">6.2.1 </span><span class="c1">El reclamo deber&aacute; ser presentado por el
                Titular,
                sus causahabientes o representantes o acreditados de conformidad con la Ley 1581 y sus decretos
                reglamentarios al correo electr&oacute;nico gerencia@innovbo.com y deber&aacute; contener la siguiente
                informaci&oacute;n:</span></p>
        <p class="c13"><span class="c0">6.2.1.1 </span><span class="c1">Nombre y documento de identificaci&oacute;n del
                titular.</span></p>
        <p class="c2"><span class="c0">6.2.1.2 </span><span class="c1">Una descripci&oacute;n de los hechos que dan
                lugar al
                reclamo y el objetivo perseguido (actualizaci&oacute;n, correcci&oacute;n o supresi&oacute;n, o
                cumplimiento
                de deberes).</span></p>
        <p class="c13"><span class="c0">6.2.1.3 </span><span class="c1">La direcci&oacute;n y datos de contacto e
                identificaci&oacute;n del reclamante.</span></p>
        <p class="c13"><span class="c0">6.2.1.4 </span><span class="c1">Acompa&ntilde;arse por toda la
                documentaci&oacute;n
                que el reclamante quiera hacer valer.</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <p class="c2"><span class="c1">Innovbo S.A.S. antes de atender el reclamo verificar&aacute; la identidad del
                titular del dato personal, su representante o apoderado, o la acreditaci&oacute;n de que hubo una
                estipulaci&oacute;n por otro o para otro. Para ello puede exigir la c&eacute;dula de ciudadan&iacute;a o
                documento de identificaci&oacute;n original del titular, y los poderes especiales, generales o
                documentos
                que se exijan seg&uacute;n sea el caso.</span></p>
        <p class="c2"><span class="c0">6.2.2 </span><span class="c1">Si el reclamo o la documentaci&oacute;n adicional
                est&aacute;n incompletos, Innovbo S.A.S. requerir&aacute; al reclamante por una sola vez dentro de los
                cinco (5) d&iacute;as siguientes a la recepci&oacute;n del reclamo para que subsane las fallas. Si el
                reclamante no presenta la documentaci&oacute;n e informaci&oacute;n requerida dentro de los dos (2)
                meses
                siguientes a la fecha del reclamo inicial, se entender&aacute; que ha desistido del reclamo.</span></p>
        <p class="c2"><span class="c0">6.2.3 </span><span class="c1">Si por cualquier hecho la persona que recibe el
                reclamo
                no es competente para resolverlo, dar&aacute; traslado la Gerencia General dentro de los dos (2)
                d&iacute;as
                h&aacute;biles siguientes a haber recibido el reclamo, e informar&aacute; de dicha remisi&oacute;n al
                reclamante.</span></p>
        <p class="c2"><span class="c0">6.2.4 </span><span class="c1">Una vez recibido el reclamo con la
                documentaci&oacute;n
                completa, se incluir&aacute; en la base de datos de Innovbo S.A.S. donde reposen los datos del titular
                sujetos a reclamo una leyenda que diga &ldquo;reclamo en tr&aacute;mite&rdquo; y el motivo del mismo, en
                un
                t&eacute;rmino no mayor a dos (2) d&iacute;as h&aacute;biles. Esta leyenda deber&aacute; mantenerse
                hasta
                que el reclamo sea decidido.</span></p>
        <p class="c2"><span class="c0">6.2.5 </span><span class="c1">El t&eacute;rmino m&aacute;ximo para atender el
                reclamo
                ser&aacute; de quince (15) d&iacute;as h&aacute;biles contados a partir del d&iacute;a siguiente a la
                fecha
                de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho t&eacute;rmino, se
                informar&aacute;
                al interesado los motivos de la demora y la fecha en que se atender&aacute; su reclamo, la cual en
                ning&uacute;n caso podr&aacute; superar los ocho (8) d&iacute;as h&aacute;biles siguientes al
                vencimiento
                del primer t&eacute;rmino.</span></p>
        <p class="c2 c11"><span class="c1"></span></p>
        <ol class="c4 lst-kix_list_1-0" start="7">
            <li class="c2 c24 li-bullet-0"><span class="c12 c0">FECHA DE ENTRADA EN VIGENCIA DE LA POL&Iacute;TICA DE
                    TRATAMIENTO DE LA INFORMACI&Oacute;N Y PER&Iacute;ODO DE VIGENCIA DE LA BASE DE DATOS.</span></li>
        </ol>
        <p class="c2"><span class="c1">La presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n de Innovbo
                S.A.S. rige a partir del 03 de Enero de 2022.<br></span></p>
        <p class="c2"><span class="c1">Los datos personales que sean recolectados, almacenados, transmitidos,
                transferidos,
                procesados y usados permanecer&aacute;n en la base de datos de Innovbo S.A.S., con base en el criterio
                de temporalidad y necesidad, durante el tiempo que sea necesario para las finalidades mencionadas en
                esta
                Pol&iacute;tica, para los cuales fueron recolectados.</span></p>
        <p class="c33 c11 c36"><span class="c12 c26 c29"></span></p>
        <p class="c17"><span class="c26">Esta pol&iacute;tica debe ser entendida y cumplirse por parte de todo el
                personal
                de </span><span class="c26 c34">Innovbo S.A.S.</span><span class="c12 c29 c26">&nbsp;y contratistas,
                estar&aacute; a disposici&oacute;n de cualquier parte interesada que muestre inter&eacute;s por
                ella.</span>
        </p>
        <p class="c13 c3"><span class="c1"></span></p>
        <p class="c13 c3"><span class="c1"></span></p>
        <p class="c13 c3"><span class="c1"></span></p>
        <p class="c8"><span class="c12 c20">CEO</span></p>
        <p class="c8"><span class="c12 c20">Innovbo S.A.S.</span></p>
        <p class="c13 c3"><span class="c1"></span></p>
        <p class="c13 c3"><span class="c1"></span></p>
        <p class="c13 c3"><span class="c1"></span></p>
    </div>
</main>