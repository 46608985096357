import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren, SimpleChanges, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Patterns } from 'src/app/core/resources/patterns';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AutocompleteCityComponent } from 'src/app/shared/autocomplete-city/autocomplete-city.component';

@Component({
  selector: 'app-turn-filter',
  templateUrl: './turn-filter.component.html',
  styleUrls: ['./turn-filter.component.scss']
})

export class turnFilterComponent implements OnInit {
  myFilter = (d: Date | null): boolean => {
    if (!d) return false;
    const currentDate: Date = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(currentDate.getDate() - 2); // Restar 2 días a la fecha actual
    d.setHours(0, 0, 0, 0);
    return d >= currentDate;
  };

  public origin: string;
  public destination: string;
  licensePlate: FormControl = new FormControl();
  licensePlateSub: Subscription;
  filterDate: any;
  filterDateEndLoad: any;
  filterDateInput: any;
  filterDateStartInput: FormControl = new FormControl();
  filterDateStartSub: Subscription;
  filterDateEndInput: FormControl = new FormControl({ value: '', disabled: true });
  endDateOptions = {
    min: null,
    max: null
  };
  reset: boolean;
  deployMenuFilter = false;
  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  @Output() emitDates: EventEmitter<any> = new EventEmitter();
  @Output() showMoreFilters: EventEmitter<any> = new EventEmitter();
  @Output() clearFilters: EventEmitter<any> = new EventEmitter();
  @Input() cleanFilters: any;
  @Input() applyFilters: any;
  @Input() filterParams: any;
  @Input() filledInputs = {
    data: 0,
    display: false
  };
  showButtonDiv = true;
  showIconDiv = true;
  showCleanLicensePlate: boolean = false;
  optionsCityOrigin = {
    title: 'Origen',
    appearance: 'outline',
    showXMark: true
  };
  cityControlOrigin: FormControl = new FormControl();
  cityOriginSub: Subscription;
  validateCityOrigin: string = '';
  optionsCityDestination = {
    title: 'Destino',
    appearance: 'outline',
    showXMark: true
  };
  cityControlDestination: FormControl = new FormControl();
  cityDestinationSub: Subscription;
  validateCityDestination: string = '';
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }
  constructor(
    private snackBarService: SnackBarService,
    private patterns: Patterns,
    public utils: Utils,
  ) { }

  ngOnInit(): void {
    this.checkLicensePlate();
    this.checkScreenSize();
    this.setSubscriptions();
    this.filterDateStartSub = this.filterDateStartInput.valueChanges.subscribe((value) => {
      if (value) {
        if (!DateManager.isValidDate(value)) {
          this.filterDateEndInput.setValue('');
          this.filterDateEndInput.disable();
        } else this.filterDateEndInput.enable();
      } else this.filterDateEndInput.disable();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.cleanFilters && this.cleanFilters !== undefined) {
      this.cleanFilter();
    }
    if (changes && changes.applyFilters && this.applyFilters !== undefined) {
      this.resetFilter();
    }
  }

  setSubscriptions() {
    this.cityOriginSub = this.cityControlOrigin.valueChanges
      .subscribe(value => {
        if (value) {
          if (value.id) {
            this.validateCityOrigin = 'disable';
            this.origin = value.id;
          } else if (value === 'clean') {
            this.validateCityOrigin = 'enable';
            this.origin = '';
          }
          this.resetFilter();
        }
      });

    this.cityDestinationSub = this.cityControlDestination.valueChanges
      .subscribe(value => {
        if (value) {
          if (value.id) {
            this.validateCityDestination = 'disable';
            this.destination = value.id;
          } else if (value === 'clean') {
            this.validateCityDestination = 'enable';
            this.destination = '';
          }
          this.resetFilter();
        }
      });
  }

  checkScreenSize() {
    if (window.innerWidth < 1000) {
      this.showButtonDiv = false;
      this.showIconDiv = true;
    } else {
      this.showButtonDiv = true;
      this.showIconDiv = false;
    }
  }

  cleanDate() {
    this.filterDateStartInput.setValue('');
    this.filterDateEndInput.setValue('');
    this.emitDates.emit({ value: 'empty' });
    this.filter();
  }

  public filter(type?: string) {
    if (type) {
      this.onFilter.emit(type);
    } else {
      const filterParams = this.createFilterParams();
      this.onFilter.emit(filterParams);
    }

  }

  public onChangeDate(typeDate: string, $event: MatDatepickerInputEvent<Date>) {
    this.emitDates.emit({ type: typeDate });
    if (typeDate === 'loadDate') {
      this.endDateOptions.min = $event.value;
    }
    if ($event.value === null) {
      this.emitDates.emit({ type: typeDate, value: 'empty' });
    }
    if (typeDate === 'endDate' || (this.filterDateEndInput && this.filterDateEndInput.value)) {
      this.resetFilter();
    }

  }

  cleanFilter() {
    this.emitDates.emit({ value: 'empty' });
    this.clearFilters.emit(true);
    this.origin = '';
    this.reset = !this.reset;
    this.destination = '';
    this.licensePlate.setValue('');
    this.endDateOptions.min = '';
    this.filterDateStartInput.setValue('');
    this.filterDateEndInput.setValue('');
    this.optionsCityOrigin['initialValue'] = '';
    this.optionsCityDestination['initialValue'] = '';
    this.validateCityOrigin = 'enable';
    this.validateCityDestination = 'enable';
    this.optionsCityOrigin = { ...this.optionsCityOrigin };
    this.optionsCityDestination = { ...this.optionsCityDestination };
    this.filter('reset');
  }


  resetFilter() {
    if (!this.utils.isEmpty(this.licensePlate.value)) {
      if (this.patterns.GET_REGEX('LICENSE_PLATES').test(this.licensePlate.value)) {
        this.showCleanLicensePlate = true;
        this.filter();
      } else {
        this.snackBarService.openSnackBar("Formato de placa incorrecto", undefined, 'alert');
      }
    } else {
      this.filter();
    }
  }

  public createFilterParams(): string {
    let filterData = '';
    if (this.origin) {
      filterData += '&origin=' + this.origin;
    }
    if (this.destination) {
      filterData += '&destination=' + this.destination;
    }
    /* if (moment(decodeURIComponent(this.filterDate), 'YYYY-MM-DD ZZ', true).isValid()) {
        this.filterDateStartInput.setValue(
            new Date(
                moment(decodeURIComponent(this.filterDate), 'YYYY-MM-DD').add(1, 'days').tz(moment.tz.guess()).format('YYYY-MM-DD')
            )
        );
        this.filterDate = '';
    } */
    if (!this.utils.isEmpty(this.filterDateStartInput.value)) {
      const date = DateManager.dateToString(this.filterDateStartInput.value, 'YYYY-MM-DD ZZ');

      if (date) {
        filterData += '&date=' + date;
      }
    }

    if (this.licensePlate && this.licensePlate.value) {
      filterData += '&licensePlate=' + this.licensePlate.value;
    }

    if (!this.utils.isEmpty(this.filterDateEndInput.value)) {
      const date = DateManager.dateToString(this.filterDateEndInput.value, 'YYYY-MM-DD ZZ');
      if (date) {
        filterData += '&endDate=' + date;
      }
    }
    if (this.filterParams && this.filterParams.value) {
      if (this.filterParams.value.turnState) {
        filterData += '&state=' + this.filterParams.value.turnState;
      }
      if (this.filterParams.value.vehicleType && this.filterParams.value.vehicleType.name) {
        filterData += '&vehicleType=' + this.filterParams.value.vehicleType.name;
      }
      if (this.filterParams.value.bodyWorkType) {
        filterData += '&bodyworkType=' + this.filterParams.value.bodyWorkType;
      }
      if (this.filterParams.value.loadCapacityVolume) {
        filterData += '&loadCapacityVolume=' + this.filterParams.value.loadCapacityVolume;
      }
      if (this.filterParams.value.loadCapacityWeight) {
        filterData += '&loadCapacityWeight=' + this.filterParams.value.loadCapacityWeight;
      }
      if (this.filterParams.value.driverName) {
        filterData += '&driverName=' + this.filterParams.value.driverName.toUpperCase();
      }
      if (this.filterParams.value.cellphone) {
        filterData += '&cellphone=57' + this.filterParams.value.cellphone
      }
    }
    return filterData;
  }

  menuFilter(option: boolean) {
    this.deployMenuFilter = option;
  }

  moreFilters(value) {
    this.showMoreFilters.emit(value);
  }

  checkLicensePlate() {
    this.licensePlateSub = this.licensePlate.valueChanges
      .subscribe(changes => {
        this.showCleanLicensePlate = false;
      })
  }

  cleanLicensePlate() {
    this.licensePlate.setValue('');
    this.resetFilter();
  }

  ngOnDestroy() {
    if (this.filterDateStartSub) this.filterDateStartSub.unsubscribe();
    if (this.cityOriginSub) this.cityOriginSub.unsubscribe();
    if (this.cityDestinationSub) this.cityDestinationSub.unsubscribe();
    if (this.licensePlateSub) this.licensePlateSub.unsubscribe();
  }
}
