<div class="container">
  <div id="mainFreightTypeLoad" class="col-md-12 mb-md-0 mb-12">

    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          Selecciona un tipo de servicio
        </label>
      </div>
      <!-- Boxs -->
      <app-box-type-service [serviceTypeSelect]="manualCreationCargoService.cargoForm.value.cargoModel.serviceType.name"
        [serviceSelectedName]="manualCreationCargoService.cargoForm.value.cargoModel.cargoType.name"
        (emitToParent)="selectTypeService($event)" class="type-service"></app-box-type-service>

      <!-- Button -->
      <div class="col-md-12 mb-md-0 mb-12">
        <div class="row">
          <div class="button-container justify-content-center mt-3 w-100">
            <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
            <button mat-raised-button class="bg-color-primary" (click)="nextStep()">Siguiente</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
