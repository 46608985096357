import { MatDialog, MatDialogConfig } from "@angular/material";
import { Observable } from "rxjs";
import { FormVehicleComponent } from "src/app/modules/administration/vehicles/form-vehicle/form-vehicle.component";
import { GpsVehicleComponent } from "src/app/modules/administration/vehicles/gps-vehicle/gps-vehicle.component";
import { User } from "../interfaces/user";
import { Vehicle } from "../interfaces/vehicle";
import { Utils } from "../resources/utils";
import { Injectable } from "@angular/core";
import { ModalEnum } from "../enums/modal.enum";

@Injectable()
export class VehicleManager {
  constructor(public dialog: MatDialog, public utils: Utils) { }

  public updateInformation(
    vehicle: Vehicle,
    onlyGps?: boolean,
    configStepper?: { title; titleButton }
  ): Observable<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle: vehicle,
      onlyGps,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    if (!this.utils.isEmpty(configStepper)) {
      dialogConfig.data.configStepper = configStepper;
    }

    const modalDialog = this.dialog.open(FormVehicleComponent, dialogConfig);

    return new Observable((observer) => {
      modalDialog.afterClosed().subscribe((result) => {
        if (result && result.state) {
          observer.next(true);
        } else if (result && !result.state) {
          observer.error(false);
        }
      });
    });
  }

  public completeTrailerLongMile(trailer: Vehicle): boolean {
    return (
      !this.utils.isEmpty(trailer.propertyCard) &&
      !this.utils.isEmpty(trailer.picture)
    );
  }

  public completeVehicleLongMile(vehicle: Vehicle): boolean {
    return (
      this.isValidFrontPicture(vehicle) &&
      this.isValidPropertyCard(vehicle) &&
      this.isValidRightLateralPicture(vehicle) &&
      this.isValidLeftLateralPicture(vehicle) &&
      this.isValidGps(vehicle)
    );
  }

  public completeDriverLongMile(driver: User): boolean {
    return (
      this.isValidReferenceLaboral(driver) &&
      this.isValidReferencePersonal(driver) &&
      this.isValidEmergencyContact(driver) &&
      this.isValidProfilePicture(driver) &&
      this.isValidFrontDocument(driver) &&
      this.isValidBackDocument(driver)
    );
  }

  public isValidFrontPicture(vehicle: Vehicle) {
    return !this.utils.isEmpty(vehicle.frontPicture);
  }

  public isValidPropertyCard(vehicle: Vehicle) {
    return !this.utils.isEmpty(vehicle.propertyCard);
  }

  public isValidRightLateralPicture(vehicle: Vehicle) {
    return !this.utils.isEmpty(vehicle.rightLateralPicture);
  }

  public isValidLeftLateralPicture(vehicle: Vehicle) {
    return !this.utils.isEmpty(vehicle.leftLateralPicture);
  }

  public isValidGps(vehicle: Vehicle) {
    return (
      !this.utils.isEmpty(vehicle.gps) &&
      !this.utils.isEmpty(vehicle.gps.gpsType) &&
      !this.utils.isEmpty(vehicle.gps.passwordGps) &&
      !this.utils.isEmpty(vehicle.gps.userGps) &&
      !this.utils.isEmpty(vehicle.gps.gpsType)
    );
  }

  public isValidReferenceLaboral(driver: User) {
    return (
      !this.utils.isEmpty(driver.referenceLaboral) &&
      !this.utils.isEmpty(driver.referenceLaboral.name) &&
      !this.utils.isEmpty(driver.referenceLaboral.phone)
    );
  }

  public isValidReferencePersonal(driver: User) {
    return (
      !this.utils.isEmpty(driver.referencePersonal) &&
      !this.utils.isEmpty(driver.referencePersonal.lastName) &&
      !this.utils.isEmpty(driver.referencePersonal.name) &&
      !this.utils.isEmpty(driver.referencePersonal.phone)
    );
  }

  public isValidEmergencyContact(driver: User) {
    return (
      !this.utils.isEmpty(driver.emergencyContact) &&
      !this.utils.isEmpty(driver.emergencyContact.lastName) &&
      !this.utils.isEmpty(driver.emergencyContact.name) &&
      !this.utils.isEmpty(driver.emergencyContact.phone)
    );
  }

  public isValidProfilePicture(driver: User) {
    return !this.utils.isEmpty(driver.profilePicture);
  }

  public isValidFrontDocument(driver: User) {
    return !this.utils.isEmpty(driver.frontDocument);
  }

  public isValidBackDocument(driver: User) {
    return !this.utils.isEmpty(driver.backDocument);
  }

  public getMissingFiels(vehicle: Vehicle, driver: User) {
    const list = [];

    //Vehicle
    if (!this.isValidFrontPicture(vehicle)) {
      list.push("Foto frontal del vehículo");
    }
    if (!this.isValidPropertyCard(vehicle)) {
      list.push("Tarjeta de propiedad del vehículo");
    }
    if (!this.isValidRightLateralPicture(vehicle)) {
      list.push("Imagen lateral derecha del vehículo");
    }
    if (!this.isValidLeftLateralPicture(vehicle)) {
      list.push("Imagen lateral Izquierda del vehículo");
    }
    if (!this.isValidGps(vehicle)) {
      list.push("GPS");
    }

    //Driver
    if (!this.isValidReferenceLaboral(driver)) {
      list.push("Referencia laboral");
    }
    if (!this.isValidReferencePersonal(driver)) {
      list.push("Referencia personal");
    }
    if (!this.isValidEmergencyContact(driver)) {
      list.push("Contacto de emergencia");
    }
    if (!this.isValidProfilePicture(driver)) {
      list.push("Imagen de perfil del conductor");
    }
    if (!this.isValidFrontDocument(driver)) {
      list.push("Imagen del documento frontal del conductor");
    }
    if (!this.isValidBackDocument(driver)) {
      list.push("Imagen del documento al revés del conductor");
    }
    return list;
  }

  public openGps(vehicle: Vehicle, driver: User, form): Observable<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle,
      driver,
      onlyGPS: true,
      form: form.get("vehicle.gps"),
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const modalDialog = this.dialog.open(GpsVehicleComponent, dialogConfig);
    return new Observable((observer) => {
      modalDialog.afterClosed().subscribe((result) => {
        if (result && result.state) {
          observer.next(true);
        } else if (result && !result.state) {
          observer.error(false);
        }
      });
    });
  }
}
