import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { Model } from 'src/app/core/interfaces/vehicle';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { VehicleType } from 'src/app/core/interfaces/vehicleType';
import { MatAutocompleteSelectedEvent, MatDialog, MatDialogConfig, MatSelectChange } from '@angular/material';
import { Patterns } from 'src/app/core/resources/patterns';
import { User } from 'src/app/core/interfaces/user';
import { CatalogService } from 'src/app/core/services/catalog.service';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-basic-person',
  templateUrl: './basic-person.component.html',
  styleUrls: ['./basic-person.component.scss'],
  providers: [AuthService, ManualCreationCargoService, Model]
})
export class BasicPersonComponent implements OnInit {
  @ViewChild('formElement', { static: false }) formElement: any;
  @Input() title: string;
  @Input() form: FormGroup;
  @Input() needTerms: boolean;
  documenTypes: CatalogItem[];
  vehicleTypes: VehicleType[];
  optionsCity = {
    title: 'Ciudad',
  }
  validate: string = '';
  cityControl: FormControl = new FormControl();
  citySub: Subscription;
  documentSub: Subscription;
  documentControlSub: Subscription;
  documentTypeIdSub: Subscription;
  documentTypeIdSub2: Subscription;
  optionsDocumentUser: OptionsAutocomplete = {
    title: 'N° de identificación',
  }
  documentControl: FormControl = new FormControl('', Validators.required);
  constructor(
    public utils: Utils,
    private global: Global,
    public formBuilder: FormBuilder,
    public modelVehicle: Model,
    public dialog: MatDialog,
    public catalogService: CatalogService
  ) {
    this.documenTypes = this.utils.clone(this.global.documenTypes);
  }

  ngOnInit() {
    this.lisenerDocumentType();
    this.setSubscription();
    this.setOptions();
    this.optionsDocumentUser.typeUser = (this.title === 'Conductor' ? 'driver' : (this.title === 'Propietario' ? 'owner' : 'admin'));
  }

  setSubscription() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => {
        if (this.form.get('municipalityCode')) this.form.get('municipalityCode').setValue(value && value.id ? value.id : '');
      })

    if (this.form.get('municipalityCode')) {
      let $citySubscription = this.form.get('municipalityCode').valueChanges
        .subscribe(value => {
          if (value) {
            this.optionsCity['initialMunicipalityCode'] = value;
            this.optionsCity = { ...this.optionsCity };
            $citySubscription.unsubscribe();
          }
        })
      this.documentTypeIdSub2 = this.form.get('documentTypeId').valueChanges
        .subscribe(value => {
          if (value === '3') {
            this.form.get('municipalityCode').setValidators(Validators.required);
            this.cityControl.setValidators(Validators.required);
            this.validate = 'setValidators';
          } else {
            this.form.get('municipalityCode').clearValidators();
            this.cityControl.clearValidators();
            this.validate = 'clearValidators';
          }
          this.form.get('municipalityCode').updateValueAndValidity();
          this.cityControl.updateValueAndValidity();

        })
    }
    this.documentControlSub = this.documentControl.valueChanges
      .subscribe(value => {
        if (value && value.information && value.information.name) {
          this.form.patchValue(value.information)
        } else if (value && typeof value === 'string') {
          this.form.get('document').setValue(value);
        } else {
          this.form.get('document').setValue('');
        }
      })
    let $documentSubscription = this.form.get('document').valueChanges
      .subscribe(value => {
        if (value) {
          this.setOptions();
          $documentSubscription.unsubscribe();
        }
      })
  }

  setOptions() {
    this.needTerms ? this.optionsDocumentUser['type'] = 'trailer' : null;
    this.optionsDocumentUser['initialValue'] = this.form && this.form.get('document') && this.form.get('document').value ? this.form.get('document').value : '';
    this.optionsDocumentUser['documentTypeId'] = this.form && this.form.get('documentTypeId') && this.form.get('documentTypeId').value ? this.form.get('documentTypeId').value : null;
    this.optionsDocumentUser = { ...this.optionsDocumentUser };
  }

  lisenerDocumentType() {
    this.documentTypeIdSub = this.form.get('documentTypeId').valueChanges.subscribe((value) => {
      if (this.form.get('document')) {
        this.form.get('document').setValue('');
      }
      if (this.form.get('municipalityCode')) {
        this.form.get('municipalityCode').setValue('');
      }
      if (this.form.get('reteica')) {
        this.form.get('reteica').setValue('');
      }
      if (this.form.get('address')) {
        this.form.get('address').setValue('');
      }
      if (this.form.get('lat')) {
        this.form.get('lat').setValue('');
      }
      if (this.form.get('lng')) {
        this.form.get('lng').setValue('');
      }
    });
  }

  displayFnDocumentType(data: any): string {
    return data ? data.name : '';
  }

  returnFnDocumentType(data: any): string | undefined {
    return data ? data.id : undefined;
  }

  onChangeDocumentType($event: MatAutocompleteSelectedEvent) {
    this.form.get('documentTypeId').setValue($event.option.value.id);
    this.form.get('documentTypeName').setValue($event.option.value.name);
    this.optionsDocumentUser['documentTypeId'] = this.form.get('documentTypeId').value;
    this.optionsDocumentUser = { ...this.optionsDocumentUser };
  }

  onSubmit() {
    this.validate = 'touched'
  }

  onSelectAddress($event) {
    if (this.utils.isDefined($event) && this.form.get('address')) {
      this.form.get('address').setValue($event);
    }
  }

  docState(event: boolean) {
    if (event) {
      this.form.get('name').setValue('');
      this.form.get('document').setValue('');
    }
  }

  disableEnableFields() {
    this.form.get('name').disable();
    this.documentSub = this.form.get('document').valueChanges.subscribe(value => {
      if (value === null || value === '') {
        this.form.get('name').disable();
      } else {
        this.form.get('name').enable();
      }
    })
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
    if (this.documentControlSub) this.documentControlSub.unsubscribe();
    if (this.documentSub) this.documentSub.unsubscribe();
    if (this.documentTypeIdSub) this.documentTypeIdSub.unsubscribe();
    if (this.documentTypeIdSub2) this.documentTypeIdSub2.unsubscribe();
  }
}
