import { Pipe, PipeTransform } from '@angular/core';
import { UploadGuideService } from 'src/app/modules/guides/upload-guide/upload-guide.service';

@Pipe({
    name: 'stateGuide'
})
export class StateGuidePipe implements PipeTransform {

    constructor(
        private uploadGuideService: UploadGuideService
    ) { }

    transform(stateId) {
        // const guideStates = this.uploadGuideService.getGuideStatesObject();
        // if (guideStates) {
        //     if (guideStates[stateId]) {
        //         return guideStates[stateId].name;
        //     } else {
        //         return stateId;
        //     }
        // } else {
        return stateId;
        // }
    }

}