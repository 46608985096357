import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { AuthService } from '../services/authentication.service';
import { RoleService } from '../services/role.service';

@Injectable({ providedIn: 'root' })
export class BanksResolver implements Resolve<any> {

    constructor(
        private authService: AuthService,
        private router: Router,
        private roleService: RoleService,
        private vehiclesService: VehiclesService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this.vehiclesService.getListBank();
    }

}
