<mat-form-field [appearance]="options?.appearance? options.appearance : 'standard'">
  <mat-label>{{options && options.title ? options.title : 'Ciudad'}}<span class="asterisk"
      *ngIf="utils.isRequiredField(formControlCity)">*</span></mat-label>
  <input (keyup)="changeValue()" type="text" #inputAutocomplete matInput autocomplete="off" matGoogleMapsAutocomplete
    *ngIf="options?.isInternational" [formControl]="formControlCity" [readonly]="formControlCity.disabled">
  <input (keyup)="changeValue()" matInput [matAutocomplete]="autocompleteCity" [formControl]="formControlCity"
    *ngIf="!options?.isInternational" [readonly]="formControlCity.disabled">
  <i *ngIf="options?.pinIcon" matPrefix class="fas fa-map-marker-alt mr-2"></i>
  <i *ngIf="options?.showXMark && formControlCity?.value?.id" matSuffix class="fas fa-times-circle"
    matTooltip="Borrar campo" (click)="cleanField()"></i>
  <mat-autocomplete #autocompleteCity="matAutocomplete" [displayWith]="displayCityName"
    (optionSelected)="selectCity($event)">
    <mat-option *ngFor="let city of listCities | async" [value]="city" [disabled]="disableCity(city)">
      <span>{{city.name}}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="formControlCity?.errors?.required || false">
    {{ utils.giveMessageError('MANDATORY_FIELD') }}
  </mat-error>
</mat-form-field>