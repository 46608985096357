import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FilesStorageService } from './files-storage.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import 'firebase/storage';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { DynamicFirebaseService } from 'src/app/core/services/dynamic-firebase.service';

@Component({
  selector: 'app-files-storage',
  templateUrl: './files-storage.component.html',
  styleUrls: ['./files-storage.component.scss'],
  providers: [FilesStorageService]
})
export class FilesStorageComponent implements OnInit {

  @Input() nameFile: string;
  notFileLoaded: boolean = false;
  nameFileToRender: string;
  fileURLReference: SafeResourceUrl = null;
  fileMeta: any;
  errorFile: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private domSanitizer: DomSanitizer,
    public utils: Utils,
    public dynamicService: DynamicFirebaseService
  ) {

  }

  ngOnInit() { }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nameFile && this.utils.isDefined(changes.nameFile.currentValue)) {
      if (changes.nameFile.currentValue) {
        if (changes.nameFile.currentValue === 'notLoadedFile') {
          this.notFileLoaded = true;
          this.nameFileToRender = null;
          this.errorFile = false;
        }
        else {
          this.notFileLoaded = false;
          this.nameFileToRender = changes.nameFile.currentValue;
          this.searchResource();
        }
      } else {
        this.notFileLoaded = false;
        this.nameFileToRender = null;
      }
    }
  }

  async searchResource() {
    this.spinner.show();
    // auth service storage
    const resourceFound = await this.searchInStorage(true);
    // Dynamic storage
    if (!resourceFound) await this.searchResourceDynamicallyAmongCompanies();
    this.spinner.hide();
  }

  async searchInStorage(isPrincipalCompanyId: boolean = false, nit?: string): Promise<boolean> {
    try {
      const storage = isPrincipalCompanyId ? AuthService.fStorage : this.dynamicService.getStorage(nit);
      const pathReference = storage.ref(this.nameFileToRender);
      this.fileMeta = await pathReference.getMetadata();
      if (!this.fileMeta) {
        this.fileURLReference = null;
        return false;
      }
      const url = await pathReference.getDownloadURL();
      this.fileURLReference = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      this.errorFile = false;
      return true;
    } catch (error) {
      this.fileURLReference = null;
      return false;
    }
  }

  async searchResourceDynamicallyAmongCompanies() {
    let resourceFound = false;
    let companyIds = this.dynamicService.getCompanyIds();
    for (const companyId of companyIds) {
      const found = await this.searchInStorage(false, companyId);
      if (found) {
        resourceFound = true;
        break;
      }
    }
    if (!resourceFound) this.errorFile = true;
  }

  public get isImage() {
    try {
      return this.fileMeta.contentType.indexOf('image') !== -1;
    } catch {
      return false;
    }
  }

}
