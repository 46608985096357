import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CargoAdvancePercentageService } from './cargo-advance-percentage.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Patterns } from 'src/app/core/resources/patterns';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { Utils } from 'src/app/core/resources/utils';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';

@Component({
  selector: 'app-cargo-advance-percentage',
  templateUrl: './cargo-advance-percentage.component.html',
  styleUrls: ['./cargo-advance-percentage.component.scss']
})
export class CargoAdvancePercentageComponent implements OnInit {

  maxAdvancePercentage: number;
  advancePercentage = new FormGroup({
    percentage: new FormControl(
      '',
      [
        Validators.required,
        Validators.max(AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES),
        Validators.min(0)
      ]
    ),
  });
  cargo: Cargo;
  @Input() viewType: string = 'changeAdvance';

  constructor(
    private spinner: NgxSpinnerService,
    private cargoAdvancePercentageService: CargoAdvancePercentageService,
    private snackBarService: SnackBarService,
    public matDialog: MatDialog,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CargoAdvancePercentageService>,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: { cargo: Cargo, viewType: string },
    private companyManager: CompanyManager,
    public utils: Utils,
  ) {
    this.validateDialogData();
  }

  ngOnInit() {
  }

  validateDialogData() {
    if (this.paramsDialog && this.paramsDialog.cargo) {
      this.cargo = this.paramsDialog.cargo;
      this.maxAdvancePercentage = this.companyManager.getAdvancePercentageByCompanyId(this.cargo.idCompany);
      this.advancePercentage.get('percentage').setValidators([
        Validators.required,
        Validators.max(this.maxAdvancePercentage),
        Validators.min(0),
      ])
    }
    if (this.paramsDialog && this.paramsDialog.viewType) {
      this.viewType = this.paramsDialog.viewType;
    }
  }

  updateAdvancePercentage() {
    if (this.advancePercentage.invalid) {
      if (this.utils.errorMessagesCustomized(this.advancePercentage.get('percentage'), 'nuevo porcentaje', null, null, 0, this.maxAdvancePercentage)) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    this.spinner.show();
    this.cargoAdvancePercentageService.updatePercentage(this.cargo.id, this.advancePercentage.value.percentage).toPromise()
      .then((success: Cargo) => {
        this.snackBarService.openSnackBar('Porcentaje actualizado correctamente');
        this.dialogRef.close({
          state: true,
          cargo: success,
        });
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrió un error actualizando el Porcentaje', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  get messageAdvancePercentageAllowed(): string {
    return `El porcentaje máximo permitido por ${this.companyManager.getNameByCompanyId(this.cargo.idCompany)} es ${this.maxAdvancePercentage}%`;
  }

  closeDialog(state: boolean) {
    this.dialogRef.close({
      state
    });
  }

}


