import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectGuard implements CanActivate {
  constructor(
    private router: Router

  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!sessionStorage.getItem('_token')) {
      return true;
    } else if (sessionStorage.getItem('flowNumber')) {
      if (sessionStorage.getItem('flowNumber') === '1') this.router.navigate(['cargo/list/service-requests']);
      else if (sessionStorage.getItem('flowNumber') === '2') this.router.navigate(['cargo/list/service-requests']);
      else this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/home']);
    }
    return false;

  }

}
