import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../resources/utils';
import { ApprovalStateEnum } from '../interfaces/travel-expense';

@Pipe({
    name: 'legalizeState'
})
export class LegalizeStatePipe implements PipeTransform {

    constructor(
        public utils: Utils
    ) { }

    transform(state: ApprovalStateEnum) {
        switch (state) {
            case 'Approved':
                return 'Aprobado';
            case 'Rejected':
                return 'Rechazado';
            default:
                return 'Sin legalizar';
        }
    }

}