<form class="form-container" [formGroup]="functionalitiesForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-12">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-strong">
            Añadir Funcionalidad
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>Nombre<span class="asterisk">*</span></mat-label>
              <input matInput type="text" formControlName="name">
              <mat-error *ngIf="functionalitiesForm.get('name').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12">
          <mat-card-actions class="mat-card-actions-vehcle text-center">
            <button mat-raised-button color="primary" type="submit" class="button-width-auto">
              Añadir
            </button>
          </mat-card-actions>
        </div>
      </mat-expansion-panel>
    </div>
    <span class="separator-horizontal"></span>
    <div class="col-md-12">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-strong">
            Funcionalidades
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-functionalities-list [moduleId]="module"></app-functionalities-list>
      </mat-expansion-panel>
    </div>
  </div>
</form>
<span class="separator-horizontal"></span>