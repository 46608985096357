<div class="tracking__list">
    <app-list-filter [options]="optionsFilter" [typeList]="typeList"
        (refreshList)="onFilterChanges.emit($event)"></app-list-filter>
    <h5 class="tracking__list--title">
        Vehículos en Ruta
    </h5>
    <ul type="square" class="tracking__list--vehicles" infinite-scroll [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300" [scrollWindow]="false">
        <li *ngFor="let cargo of cargos">
            <span *ngIf="cargo && cargo.licensePlate" class="mb-2 text-bold "
                [ngClass]="{'color-primary':cargo.consecutive === cargoSelected?.consecutive}">
                <i class="mr-1" (click)="emitCargoSelected(cargo)" [ngClass]="{'fas fa-check-circle fa-beat': cargo.consecutive === cargoSelected?.consecutive,
                                'fas fa-circle': cargo.consecutive !== cargoSelected?.consecutive}"></i>
                <span (click)="emitCargoSelected(cargo)">{{cargo.licensePlate | uppercase}}</span>
                <i *ngIf="cargo?.state === cargoStateEnum.START_SERVICE" (click)="openDetailCargo(cargo)"
                    class="fas fa-eye cursor-pointer"></i>
            </span>
        </li>
    </ul>
    <div *ngIf="!cargos?.length" class="tracking__list--no-results">
        <div class="icon"><i class="fas fa-calendar-times"></i></div>
        <h5 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h5>
    </div>
</div>