import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSelectChange } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/core/interfaces/user';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../../administration/vehicles/list-vehicles.service';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';

@Component({
  selector: 'app-cargo-driver-documentid-dialog',
  templateUrl: './cargo-driver-documentid-dialog.component.html',
  styleUrls: ['./cargo-driver-documentid-dialog.component.scss']
})
export class CargoDriverDocumentidDialogComponent {
  form: FormGroup;
  documentTypes = this.utils.clone(this.global.documenTypes);
  userName: string = '-';
  userDocument: string = '-';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { vehicle: Vehicle, driver: User },
    public dialogRef: MatDialogRef<CargoDriverDocumentidDialogComponent>,
    public matDialog: MatDialog,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    public utils: Utils,
    public global: Global,
    public vehiclesService: VehiclesService,
    private adminUsersService: AdminUsersService
  ) {
    this.initForm();
    if (this.data.driver && this.data.driver.information) {
      this.userDocument = this.data.driver.information.document ? this.data.driver.information.document : '-';
      this.userName = this.data.driver.information.name ? this.data.driver.information.name : '-';
    }
  }

  private initForm() {
    this.form = new FormGroup({
      documentTypeName: new FormControl('', Validators.required),
      documentTypeId: new FormControl('', Validators.required),
    })
  }

  onChangeDocumentType($event: MatSelectChange) {
    const documentType = this.documentTypes.filter((obj) => {
      return obj.name === $event.value;
    });
    this.form.get('documentTypeId').setValue(documentType[0].id);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('documentTypeName'), 'tipo de idéntificación del conductor')) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    this.updateUser();
  }

  updateUser() {
    const user = {
      information: {
        document: this.userDocument,
        documentTypeId: this.form.value.documentTypeId,
        documentTypeName: this.form.value.documentTypeName
      }
    }
    this.spinner.show();
    this.adminUsersService.updateUser(user).subscribe(
      (success: User) => {
        if (success && success.information && success.information.documentTypeId && success.information.documentTypeName) {
          this.updateVehicle();
        } else {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Ocurrió un error actualizando al usuario", undefined, "error");
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Ocurrió un error actualizando al usuario", undefined, "error");
      }
    );
  }

  updateVehicle() {
    let body: Vehicle = this.utils.clone(this.data.vehicle);
    body.driver['documentTypeId'] = this.form.value.documentTypeId;
    body.driver['documentTypeName'] = this.form.value.documentTypeName;
    if (body.owner && body.owner.document && body.owner.document === body.driver.document) {
      body.owner['documentTypeId'] = this.form.value.documentTypeId;
      body.owner['documentTypeName'] = this.form.value.documentTypeName;
    }
    if (body.administrator && body.administrator.document && body.administrator.document === body.driver.document) {
      body.administrator['documentTypeId'] = this.form.value.documentTypeId;
      body.administrator['documentTypeName'] = this.form.value.documentTypeName;
    }
    this.spinner.show();
    this.vehiclesService.updateVehicleFields(body).subscribe(
      (success) => {
        this.spinner.hide();
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Se actualizó el usuario pero no fue posible actualizar el vehículo", undefined, 'error');
      },
    );
  }

}
