<form class="px-3 px-sm-5 pt-2 pb-3 wrapper-modal position-relative ov-x-hidden" (submit)="onSubmit()"
    [formGroup]="form">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <p class="title mt-4">
        Información faltante del conductor
    </p>
    <p class="mt-2 subtitle">
        Para continuar es necesario diligenciar el tipo de documento del conductor:
    </p>
    <div class="row">
        <div class="col-sm-6">
            <mat-form-field appearance="outline">
                <mat-label>Tipo de identificación<span class="asterisk">*</span></mat-label>
                <mat-select (selectionChange)="onChangeDocumentType($event)"
                    [formControl]="form.get('documentTypeName')" name="documentTypeName">
                    <mat-option *ngFor="let documentType of documentTypes" value="{{documentType.name}}">
                        {{documentType.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('documentTypeName').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <div class="info-card d-flex flex-column justify-content-center pl-3">
                <p class="p-0 m-0 subtitle">N° de identificación</p>
                <p class="p-0 m-0 value text-bold">{{userDocument}}</p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="info-card d-flex flex-column justify-content-center pl-3">
                <p class="p-0 m-0 subtitle">Nombre</p>
                <p class="p-0 m-0 value text-bold">{{userName | titlecase}}</p>
            </div>
        </div>
    </div>

    <div class="button-container">
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary" type="button"
            (click)="dialogRef.close()">
            Cancelar
        </button>
        <button mat-raised-button color="primary" class="button-width-auto bg-color-primary" type="submit">
            Confirmar
        </button>
    </div>

</form>