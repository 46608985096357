import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ModuleRole } from 'src/app/core/interfaces/moduleRole';


@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  moduleSelected: ModuleRole;

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private _globalResources: Global,
  ) {
    
   }

  createModule(modulerole) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.modules, modulerole
    )
  }
  getAllModules() {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.modules 
    );
  }

  getModule(id) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.modules + "/"+ id
    );
  }
}



