<section class="row-freight">
  <div class="row container-data">
    <div class="col-md-4">
      <div class="label">
        Rol
      </div>
      <div class="text-bold label-field">
        <span matTooltip="{{role.name}}">
          {{role.name}}
        </span>
      </div>
    </div>
    <div class="col-md-8">
      <div class="label">
        Permisos activos
      </div>
      <div class="text-bold label-field">
        <div matTooltip="{{getModulePermissions()}}">
          {{getModulePermissions()}} módulos, {{getTotalPermissions()}} permisos
        </div>
      </div>
    </div>
  </div>
  <figure class="icon-arrow-container" (click)="openDetail()">
    <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
  </figure>
</section>
