import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { User } from '../interfaces/user';
import { AuthService } from '../services/authentication.service';
import * as _ from 'lodash';
import { Global } from '../resources/global';
import { NavItem } from '../interfaces/navItem';
import { RoleService } from '../services/role.service';
import { ModuleRole } from '../interfaces/moduleRole';
import { Role } from '../interfaces/role';
import { FunctionalitiesModuleRole } from '../interfaces/functionalitiesModuleRole';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionRole } from '../resources/permission-role';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private roleService: RoleService,
        private global: Global,
        private router: Router,
        private _route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private permissionRole: PermissionRole
    ) {
    }

    //This method gets triggered when the route is hit
    public canActivate(
        activatedRouteSnapshot: ActivatedRouteSnapshot,
        routerStateSnapshot: RouterStateSnapshot
    ): Promise<boolean> {

        return new Promise((resolve) => {
            const userPromise = this.authService.validateUser();
            userPromise.then((success) => {
                if (this.authService.getUserSession().state.active) {
                    let roleUser = this.roleService.getRoleUser();
                    let state: RouterStateSnapshot = routerStateSnapshot;
                    let navItemActive;

                    for (let i = 0; i < this.global.menu.length; i++) {

                        if (navItemActive) {
                            break;
                        }

                        if (roleUser) {

                            this.global.menu[i].childrens.map((children, j) => {
                                if (children.route === state.url) {
                                    if (Array.isArray(children.name)) {
                                        children.name.map((name, k) => {
                                            if (this.permissionRole.hasPermission(
                                                this.global.menu[i].name,
                                                name)) {
                                                navItemActive = children;
                                            }
                                        });
                                    } else {
                                        if (this.permissionRole.hasPermission(
                                            this.global.menu[i].name,
                                            children.name)) {
                                            navItemActive = children;
                                        }
                                    }
                                }
                            });
                        } else {

                            this.router.navigate(['/home']);
                        }

                    }

                    if (navItemActive) {

                        resolve(true);
                    } else {

                        resolve(false);
                    }
                } else {
                    this.router.navigate(['/home']);
                }
            })
                .catch((error) => {

                    resolve(false);
                });

        });
    }
}
