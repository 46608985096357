<aside id="mainCreateGuidesFromExcel" class="h-100">
  <div class="col-md-12 mb-md-0 mb-12">
    <form (ngSubmit)="onSubmit()" [formGroup]="guidesFromExcelForm">
      <span class="separator-horizontal"></span>
      <div class="row align-items-baseline">
        <!-- File -->
        <div class="col-md-4">
          <mat-card-content>
            <app-file [inputFormControl]="guidesFromExcelForm.get('file')"
              (emitToParent)="handleFileInput($event)" [options]="{storageName: 'guidesFromExcel'}"></app-file>
          </mat-card-content>
        </div>
        <!-- Company -->
        <div class="col-md-3">
          <mat-card-content>
            <app-select-company [options]="optionsCompany"
              [inputFormControl]="guidesFromExcelForm.get('companyId')"></app-select-company>
          </mat-card-content>
        </div>
        <!-- Date -->
        <div class="col-md-3">
          <mat-form-field appearance="standard" class="container-input-date-picker cursor-pointer">
            <mat-label>Fecha<span class="asterisk">*</span></mat-label>
            <input readonly type="text" id="setStateGuidesFormDate" matInput [matDatepicker]="datePicker"
              (click)="datePicker.open()" formControlName="date" class="cursor-pointer">
            <mat-error *ngIf="guidesFromExcelForm.get('date').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- First Row -->
        <div class="col-md-2">
          <mat-form-field appearance="standard">
            <mat-label>N° fila inicial<span class="asterisk">*</span></mat-label>
            <input type="number" id="inputRow" min="1" matInput formControlName="firstRow">
            <mat-error *ngIf="guidesFromExcelForm.get('firstRow').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Sheet Name -->
        <div class="col-md-3"
          *ngIf="sheets && sheets.length > 1 && getObjetKeys(titleFieldTemporal).length && guidesFromExcelForm.get('file').value">
          <mat-form-field appearance="standard">
            <mat-label>Hoja</mat-label>
            <mat-select formControlName="sheetName">
              <mat-option *ngFor="let sheet of sheets" [value]="sheet.value">
                {{sheet.key}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Reverse Logistics -->
        <div class="col-md-3 mat-form-field-check">
          <mat-checkbox formControlName="isReverseLogistics">Logística inversa</mat-checkbox>
        </div>
      </div>
      <span class="separator-horizontal"></span>
      <div class="row" *ngIf="getObjetKeys(titleFieldTemporal).length && guidesFromExcelForm.get('file').value">
        <div class="col-md-12">
          <label class="label-field text-left">Por favor seleccione el campo que se relaciona con los datos de cada
            columna</label>
        </div>
      </div>
      <h6 class="col-md-12 text-strong alert alert-warning font-weight-bold"
        *ngIf="getObjetKeys(titleFieldTemporal).length && guidesFromExcelForm.get('file').value">
        Los campos con * son obligatorios.
      </h6>
      <div class="table-responsive"
        *ngIf="getObjetKeys(titleFieldTemporal).length && guidesFromExcelForm.get('file').value">
        <table class="table table-xslx">
          <thead>
            <tr>
              <th *ngFor="let index of getObjetKeys(titleFieldTemporal)">
                <ng-template [ngIf]="!titleFieldTemporal[index].value">
                  <mat-form-field class="example-full-width">
                    <mat-select (selectionChange)="onChangeTitleField($event, index)">
                      <mat-option *ngFor="let title of listTitleFieldsColumns" [value]="title.id"
                        [disabled]="title.disabled">
                        {{title.title}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-template>
                <div *ngIf="titleFieldTemporal[index].value" class="title-field-selected">
                  <span>
                    {{titleFieldTemporal[index].title}}
                  </span>
                  <span class="icon-clean-title-field"
                    (click)="clearValueTitle(titleFieldTemporal[index].value, index)">
                    x
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td *ngFor="let title of getObjetKeys(getRowXLSXToShow())">
                {{getRowXLSXToShow()[title]}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span class="separator-horizontal"></span>
      <!-- Bttn -->
      <div class="row">
        <div class="col-md-12 text-center">
          <mat-card-actions>
            <button type="submit" mat-raised-button color="primary" class="button-width-auto">Crear guías</button>
          </mat-card-actions>
        </div>
      </div>
    </form>
  </div>

</aside>
