import { Injectable } from '@angular/core';
import { DynamicFirebase } from '../classes/dynamic-firebase';
import { Companies } from '../resources/companies';
import { AuthService } from './authentication.service';
import { FormMessages } from '../messages/form-messages.enum';
import { Fmt } from '../messages/fmt';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DynamicFirebaseService {

  constructor(
    private authService: AuthService,
    public spinner: NgxSpinnerService
  ) { }
  async initializeFirebaseAppByNit(companyId: string): Promise<boolean> {
    return DynamicFirebase.configFirebaseByCompanyId(companyId);
  }

  getStorage(companyId: string) {
    return DynamicFirebase.getStorageByCompanyId(companyId);
  }

  async deleteAllFirebaseApps(): Promise<void> {
    await DynamicFirebase.deleteAllApps();
  }

  async deleteAllFirebaseInstances() {
    this.spinner.show();
    try {
      await this.deleteAllFirebaseApps();
    } catch (error) {
      this.spinner.hide();
      console.error(Fmt.string(FormMessages.FIREBASE_INSTANCES_ERROR, 'eliminando'), error);
    } finally {
      this.spinner.hide();
    }
  }

  async initializeAllFirebaseApps(): Promise<void> {
    let filteredCompanyIds = this.getCompanyIds();
    const initializationPromises = filteredCompanyIds.map(companyId => this.initializeFirebaseAppByNit(companyId));
    this.spinner.show();
    try {
      await Promise.all(initializationPromises);
    } catch (error) {
      this.spinner.hide();
      console.error(Fmt.string(FormMessages.FIREBASE_INSTANCES_ERROR, 'creando'), error);
    } finally {
      this.spinner.hide();
    }
  }

  public getCompanyIds(): Array<string> {
    const companiesNit = Companies.companiesNIT;
    const companyIds = Object.values(companiesNit);
    return companyIds.filter(companyId => companyId !== this.authService.getUserSession().clientCompanyId);
  }
}
