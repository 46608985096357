<form class="modal-body wrapper-modal py-5 px-3 px-sm-5" [formGroup]="form" (submit)="onSubmit()">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h2 class="text-center text-bold mx-3">
        {{data.title}}
    </h2>
    <div class="row mt-4">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label class="text-bold">Tipo de viaje <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="tripTypes" multiple>
                <mat-option *ngFor="let type of tripTypesAllowed" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('tripTypes').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label class="text-bold">Escriba y seleccione el nombre<span class="asterisk">*</span></mat-label>
            <input matInput [matAutocomplete]="autocompleteName" [formControl]="name" (keyup)="changeValue()">
            <mat-autocomplete #autocompleteName="matAutocomplete" (optionSelected)="onSelectName($event)"
                [displayWith]="displayName">
                <mat-option *ngFor="let type of filteredComplimentTypes" [value]="type">
                    <span>{{type.name}}</span>
                </mat-option>
                <mat-option value="" (click)="createTitle()" class="color-secondary-dark" *ngIf="canAddComplimentTypes">
                    <i class="fas fa-plus-circle mr-2"></i>
                    Agregar requisito
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="name.errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-hint class="color-secondary-dark text-bold"
                *ngIf="!name.errors?.required && form.get('title').errors?.required">
                Seleccione una opción
            </mat-hint>
        </mat-form-field>
        <div class="col-md-6 mb-4">
            <p class="text-bold">Solicitar documento físico</p>
            <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="requirePhysicalDocument">
                <mat-button-toggle [value]="true">
                    Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false">
                    No
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-md-6">
            <label class="text-bold">Ejemplo del documento</label>
            <app-file [inputFormControl]="form.get('example')" (emitToParent)="handleFileInput($event)"
                [options]="{title: 'Subir archivo', format: 'image/jpeg,image/jpg,image/png,application/pdf', formatStrict: ['jpeg','jpg','png','pdf'] ,path: 'company'+ '/' + data?.companyNit + '/fulfilled', storage: true}">
            </app-file>
        </div>

    </div>
    <p class="alert alert-danger text-bold text-center" role="alert" *ngIf="repeatedName">
        {{repeatedName}}
    </p>
    <div class="button-container mt-4">
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
            (click)="dialogRef.close()" type="button">
            Cancelar
        </button>
        <button mat-raised-button color="primary" class="button-width-auto bg-color-primary" type="submit">
            {{data.confirmBtn}}
        </button>
    </div>
</form>