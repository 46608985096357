import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import * as _ from "lodash";
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { FormControl, Validators } from '@angular/forms';
import { Utils } from 'src/app/core/resources/utils';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-method-payment',
  templateUrl: './method-payment.component.html',
  styleUrls: ['./method-payment.component.scss'],
  providers: [AuthService, Global]
})
export class MethodPaymentComponent implements OnInit {
  minLengthObservation: number = 3;
  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    private authService: AuthService,
    public snackBarService: SnackBarService,
    public utils: Utils
  ) {

  }

  ngOnInit() {
    this.manualCreationCargoService.cargoForm.controls.observation.setValidators([Validators.required, Validators.minLength(this.minLengthObservation)]);
  }

  removeLeadingSpaces() {
    this.manualCreationCargoService.cargoForm.controls.observation.setValue(this.manualCreationCargoService.cargoForm.controls.observation.value.trimLeft());
  }

  nextStep() {
    this.manualCreationCargoService.cargoForm.controls.observation.markAsTouched();
    let validate = this.manualCreationCargoService.isValidObservations() &&
      this.manualCreationCargoService.isValidMethodPayment() &&
      this.manualCreationCargoService.isValidCargoValue() &&
      this.manualCreationCargoService.isValidDownloadAddressesAdditionalData() &&
      this.manualCreationCargoService.isValidAllDateTimeDowndload() &&
      this.manualCreationCargoService.isValidUploadAddressesAdditionalData() &&
      this.manualCreationCargoService.isValidAllDateTimeLoad() &&
      this.manualCreationCargoService.isValidDownloadAddressesCargoMeasure() &&
      this.manualCreationCargoService.isValidVehicleQuantity() &&
      this.manualCreationCargoService.isValidVehicleType() &&
      this.manualCreationCargoService.isValidProductType() &&
      this.manualCreationCargoService.isValidTripTypeDocuments() &&
      this.manualCreationCargoService.isValidServiceType() &&
      this.manualCreationCargoService.isValidContainerMeasure() &&
      this.manualCreationCargoService.isValidCargoType() &&
      this.manualCreationCargoService.isValidRegimeType() &&
      this.manualCreationCargoService.isValidTripType();
    if (validate && this.manualCreationCargoService.cargoForm.controls.observation.valid) {
      // Total
      this.manualCreationCargoService.getComponentActive().setId(4);
      this.manualCreationCargoService.getComponentActive().getStep().setId(3);
      this.router.navigate([this.manualCreationCargoService.steps.totalValue.url]);
    } else {
      if (this.utils.errorMessagesCustomized(this.manualCreationCargoService.cargoForm.get('observation'), 'observación', this.minLengthObservation)) return;
      else this.manualCreationCargoService.showMessageMissingFields();
    }
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(4);
    this.manualCreationCargoService.getComponentActive().getStep().setId(1);
    this.router.navigate([this.manualCreationCargoService.steps.cargoValue.url]);
  }

}
