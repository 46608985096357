import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';

@Injectable({
    providedIn: 'root'
})
export class FilesStorageService {

    constructor(private _http: HttpClient, private _endpointResources: Endpoints) { }

}
