import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Trailer } from 'src/app/core/interfaces/trailer';

@Component({
  selector: 'app-item-trailer',
  templateUrl: './item-trailer.component.html',
  styleUrls: ['./item-trailer.component.scss']
})
export class ItemTrailerComponent implements OnInit {
  @Input() trailer: Trailer;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  openDetail() {
    this.router.navigate(['administration', 'trailers', 'detail', this.trailer.id]);
  }

  goToOwner() {
    this.router.navigate(['administration', 'admin-users', 'detail', this.trailer.owner.document]);
  }

  goToAdmin() {
    this.router.navigate(['administration', 'admin-users', 'detail', this.trailer.administrator.document]);
  }

}
