<div class="modal-body wrapper-modal">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <!-- Title -->
    <p class="title">
        ¡Políticas sin aceptar!
    </p>

    <!-- Description HTML -->
    <p id="descriptionHTML">
        El usuario <b>{{data.name}} ({{data.document}})</b> no tiene vigente una o varias de las siguientes políticas:
    </p>

    <ol>
        <li>Términos y condiciones del servicio</li>
        <li>Política de tratamiento de datos personales</li>
        <li>Política de privacidad</li>
    </ol>

    <p>
        ¿Desea enviarle la solicitud al WhatsApp
        <b>{{data.phone}}</b>
        <i permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.modifyBasicFieldsUser" (click)="changePhone()"
            class="fas fa-pencil-alt color-primary cursor-pointer" matTooltip="Modificar número"
            matTooltipPosition="above"></i>
        ?
    </p>
    <p class="text-center" *ngIf="stateRetry">
        <b>
            ¿No ha recibido ningún mensaje?
        </b>
    </p>

    <div class="row">
        <div class="col-md-6 text-center">
            <button mat-raised-button color="primary" class="primary-secondary"
                (click)="dialogRef.close()">Cerrar</button>
        </div>
        <div class="col-md-6 text-center">
            <button mat-raised-button color="accent" (click)="submit()" *ngIf="!stateRetry">Enviar</button>
            <button mat-raised-button color="accent" (click)="openNonContactDialog()" *ngIf="stateRetry">
                Activación manual
            </button>
        </div>
    </div>
</div>