<div class="modal-body wrapper-modal">
  <i *ngIf="!data.closeModal" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <!-- Icon Correct -->
  <p *ngIf="data.icon">
  <figure class="container-icon-correct">
    <img src="/assets/svg/icons/icon-correct-purple.svg" alt="">
  </figure>
  </p>
  <p *ngIf="data.handShakeIcon">
  <figure class="container-icon-correct">
    <img src="/assets/svg/icons/handshake.svg" alt="">
  </figure>
  </p>
  <p *ngIf="data.iconAlert">
  <figure class="container-icon-correct">
    <img src="/assets/svg/icons/circle-exclamation-solid.svg" alt="">
  </figure>
  </p>
  <p *ngIf="data.iconError">
  <figure class="container-icon-correct">
    <img src="/assets/svg/icons/icon-error-purple.svg" alt="">
  </figure>
  </p>
  <!-- close Modal -->
  <!--div class="closeModal" *ngIf="data.idCargo">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div-->
  <i matTooltip="Cerrar" *ngIf="data.idCargo" class="fas fa-times fa-2xl close-icon-actions"
    (click)="closeDialog()"></i>
  <!-- Title -->
  <p class="title text-break" [ngClass]="{'title-resize': data && data.resize}">
    {{data.title}}
  </p>

  <div *ngIf="data.dataList" class="mb-2">
    <div *ngFor="let dataListItem of data.dataList">
      <div>{{ dataListItem.label }}
        <br>
        <span *ngFor="let data of dataListItem.data; let last = last" class="text-bold">
          - {{ data }}<br *ngIf="!last">
        </span>
      </div>
    </div>
  </div>

  <!-- Description -->
  <p *ngIf="data.description">
    {{data.description}}
  </p>

  <!-- Description HTML -->
  <p id="descriptionHTML" *ngIf="data.descriptionHTML" [innerHTML]="data.descriptionHTML">
    {{data.descriptionHTML}}
  </p>

  <!-- descripcion SaveAccount -->
  <div class="cargo__alert" *ngIf="data.typeAccount">
    <div class="col-md-12 alert alert-warning">
      <i class="fas fa-exclamation-triangle"></i>
      Esta por actualizar los documentos de la placa
      <span class="font-weight-bold">{{data.plate}}</span>
      para el pago de
      <span class="font-weight-bold">{{data.typeAccount}}</span>.
      A nombre de:
      <span class="font-weight-bold">{{data.ownerAccount}}</span>.
    </div>
  </div>



  <!-- Message List -->
  <div *ngIf="data.messageList">
    <ul>
      <li class="title-2" *ngFor="let Message of data.messageList">
        {{Message && Message.toString().includes(':') ? Message.toString().split(':')[0]+': ' : Message}}
        <strong class="text-bold">
          {{Message && Message.toString().includes(':') ? Message.toString().split(":").slice(1).join(' '): ''}}
        </strong>
      </li>
    </ul>
  </div>

  <!-- Textarea -->
  <textarea class="textarea" *ngIf="data.textArea" [(ngModel)]="textAreaMessage"></textarea>

  <!-- Textarea -->
  <app-file *ngIf="data.file" [options]="data.fileOptions" [inputFormControl]="data.inputFormControl"></app-file>

  <!-- inputbill Cargos -->
  <mat-form-field class="col-md-12" appearance="standard" *ngIf="data.inputText">
    <mat-label>{{data.placeholder}}<span class="asterisk">*</span></mat-label>
    <input matInput [(ngModel)]="inputText" [maxLength]="data.maxLength">
  </mat-form-field>

  <!-- inputExtraAdvance -->
  <mat-form-field class="col-md-8" appearance="standard" *ngIf="data.inputExtraAdvance">
    <mat-label>{{data.placeholderAdvance}}<span class="asterisk">*</span></mat-label>
    <input matInput class="form-control" placeholder="$000.000" type="text" [(ngModel)]="inputExtraAdvance">
  </mat-form-field>

  <!--AccounforPyment-->
  <mat-form-field class="col-md-12" appearance="standard" *ngIf="data.viewSelect">
    <mat-label>{{data.listLabel? data.listLabel : 'Seleccione una opción'}}</mat-label>
    <mat-select [(ngModel)]="itemList">
      <mat-option *ngFor="let item of data.list" [value]="item.value">
        {{item.key}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Obsevations Area -->
  <div *ngIf="data.observations" id="observations-box">
    <h6>Observaciones</h6>
    {{data.observations}}
  </div>

  <!-- confirmacion de placa -->
  <div class="cargo__alert" *ngIf="data.idCargo">
    {{'Desea confirmar la placa '}}
    <span class="font-weight-bold">{{data.licen}}</span>
    {{' como vehiculo para la carga con el consecutivo : '}}
    <span class="font-weight-bold">{{data.idCargo}}</span>
  </div>

  <p class="title" *ngIf="data.subtitle">
    {{data.subtitle}}
  </p>

  <!-- Date -->
  <mat-form-field *ngIf="data.date" appearance="standard">
    <mat-label>Selecciona una fecha<span class="asterisk">*</span></mat-label>
    <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker" (click)="picker.open()"
      placeholder="Selecciona una fecha" [formControl]="date" readonly (dateChange)="onChangeDate($event)">
    <mat-error *ngIf="invalidDate || date.errors?.required">
      {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <div class="d-flex flex-column align-items-center my-4" *ngIf="data?.checkbox">
    <mat-checkbox class="example-margin" *ngFor="let elemento of data.checkbox; let i = index"
      (change)="onCheckboxChange($event.checked, elemento.name, elemento.id)">
      <strong>{{elemento.name}}</strong>
    </mat-checkbox>
  </div>

  <app-autocomplete-address class="col-12 my-0" [simpleAddress]="true" [addLatLong]="true" [title]="'Dirección'"
    (emitToParent)="onSelectAddress($event)" *ngIf="data.autocompleteAddress"
    [required]="data?.required"></app-autocomplete-address>

  <!-- Bttns -->

  <div *ngIf="data && data.snackbarMessage" class="alert alert-warning" role="alert">
    {{ data.snackbarMessage }}
  </div>

  <div *ngIf="data.showBtn !== true" class="button-container">


    <button mat-raised-button class="primary-secondary button-width-auto" color="primary" (click)="closeDialog()"
      *ngIf="data.btnDetailCargo">
      Añadir bonificaciones
    </button>

    <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
      (click)="confirmDialog('false')" *ngIf="data.idCargo && !data.btnDetailCargo">
      {{data.hideBtnConfirm ? 'Rechazar' : titles.titleBottonRefuse}}
    </button>

    <!-- No -->
    <button mat-raised-button class="primary-secondary button-width-auto" color="primary" (click)="closeDialog(false)"
      *ngIf="data.showNoBtn">
      No
    </button>
    <!-- Yes -->
    <button mat-raised-button class="button-width-auto bg-color-primary" color="primary" (click)="closeDialog(true)"
      *ngIf="data.showYesBtn">
      Si
    </button>

    <button mat-raised-button class="primary-secondary button-width-auto" color="secondary-dark" (click)="closeDialog()"
      *ngIf="!data.hideBtnCancel && !data.idCargo && !data.showNoBtn && !data.btnDetailCargo">
      {{data.hideBtnConfirm ? 'Cerrar' : titles.titleModalCancel}}
    </button>
    <button mat-raised-button color="primary" class="button-width-auto bg-color-primary" (click)="confirmDialog('true')"
      *ngIf="!data.showYesBtn && !data.hideBtnConfirm">
      {{(data.hideBtnCancel || data.idCargo) ? 'Aceptar' : titles.titleModalConfirm}}
    </button>
    <!-- custom label buttons -->
    <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
      (click)="confirmDialog(data.labelButton1)" *ngIf="data.labelButton1">
      {{data.labelButton1}}
    </button>
    <button mat-raised-button color="primary" class="button-width-auto bg-color-primary"
      (click)="confirmDialog(data.labelButton2)" *ngIf="data.labelButton2">
      {{data.labelButton2}}
    </button>
  </div>


</div>