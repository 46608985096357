import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/core/services/authentication.service';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { Utils } from 'src/app/core/resources/utils';
import { CargoTrackingService } from '../cargo-tracking/cargo-tracking.service';
import { Driver } from "src/app/core/models/driver";
@Component({
  selector: 'app-confirm-end-cargo',
  templateUrl: './confirm-end-cargo.component.html',
  styleUrls: ['./confirm-end-cargo.component.scss'],
  providers: [DateFormatPipe]
})
export class ConfirmEndCargoComponent implements OnInit {
  userName: string = '';
  driver: Driver;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<ConfirmEndCargoComponent>,
    private authService: AuthService,
    private dateFormatPipe: DateFormatPipe,
    public utils: Utils,
    public cargoTrackingService: CargoTrackingService
  ) { }

  ngOnInit() {
    if (/\(.*\)/.test(this.dialogParams.user)) {
      this.userName = this.dialogParams.user.replace(/\(.*\)/g, '').trim();
    } else {
      this.userName = this.dialogParams.user;
    }
  }

}
