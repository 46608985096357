import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { FleetCapacity } from 'src/app/core/interfaces/fleetCapacity';
import { CreateCargoPayload, RouteScenario } from 'src/app/core/interfaces/routeScenario';
import { Client, Scenario, ScenarioOptimized, ScenarioStatus } from 'src/app/core/interfaces/scenario';
import { Scheme } from 'src/app/core/interfaces/scheme';
import { StoredFleet } from 'src/app/core/interfaces/storedFleet';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints
  ) { }

  /**
  * @returns {Observable<Scheme[]>} returns an observable of stored schemes
  * @description Gets the list of stored schemes
  */
  public getSchemes(): Observable<Scheme[]> {
    return this.http.get<Scheme[]>(`${environment.urlServerTeclogi}${this.endpointResources.getSchemes}`);
  }

  /**
  * @returns {Observable<StoredFleet[]>} returns an observable of stored fleets
  * @description Gets the list of stored fleets
  */
  public getFleets(): Observable<StoredFleet[]> {
    return this.http.get<StoredFleet[]>(`${environment.urlServerTeclogi}${this.endpointResources.getStoredFleets}`);
  }

  public getFleetByName(fleetName: string): Observable<StoredFleet> {
    return this.http.get<StoredFleet>(`${environment.urlServerTeclogi}${this.endpointResources.getStoredFleets}/${fleetName}`);
  }

  /**
  * @param {StoredFleet} fleet is the fleet to create
  * @returns {Observable<StoredFleet>} returns the fleet created
  * @description Creates a fleet
  */
  public createFleet(fleet: StoredFleet, fleetName: string): Observable<StoredFleet> {
    return this.http.post<StoredFleet>(`${environment.urlServerTeclogi}${this.endpointResources.updateFleet}${fleetName}`, fleet);
  }

  /**
  * @returns {Observable<FleetCapacity[]>} returns an observable of stored fleet's capacities
  * @description Gets the list of stored fleet's capacities
  */
  public getCapacities(): Observable<FleetCapacity[]> {
    return this.http.get<FleetCapacity[]>(`${environment.urlServerTeclogi}${this.endpointResources.capacities}`);
  }

  /**
  * @param {FleetCapacity} capacity is the capacity to create
  * @returns {Observable<FleetCapacity>} returns the capacity created
  * @description Creates a capacity
  */
  public createCapacity(capacity: FleetCapacity): Observable<FleetCapacity> {
    return this.http.post<FleetCapacity>(`${environment.urlServerTeclogi}${this.endpointResources.capacities}`, capacity);
  }

  /**
  * @param {FleetCapacity} capacity is the capacity to delete
  * @returns {Observable<FleetCapacity>} returns the capacity deleted
  * @description Deletes a capacity
  */
  public deleteCapacity(capacity: FleetCapacity): Observable<FleetCapacity> {
    return this.http.delete<FleetCapacity>(`${environment.urlServerTeclogi}${this.endpointResources.capacities}?id=${capacity.id}`);
  }

  /**
  * @param {File} file is the file to parse as Client array
  * @returns {Observable<{ clients: Client[]}>} returns an observable of file's clients
  * @description Gets the list of clients by the file
  */
  public getClientsFromExcel(file: File): Observable<{ clients: Client[] }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<{ clients: Client[] }>(
      `${environment.urlServerTeclogi}${this.endpointResources.getClientsFromExcel}`,
      formData
    );
  }

  /**
  * @param {Scenario} scenario is the scenario to create
  * @returns {Observable<Scenario>} returns the scenario created
  * @description Creates a scenario
  */
  public createScenario(scenario: Scenario): Observable<Scenario> {
    return this.http.post<Scenario>(`${environment.urlServerTeclogi}${this.endpointResources.scenarios}`, scenario);
  }

  /**
  * @param {Date} date is the date to filter 
  * @param {number} pageKey is the key of the current page
  * @param {number} pageSize is the size of the pages
  * @returns {Observable<Scenario[]>} returns an observable of scenarios to check its status
  * @description Gets the list of scenarios by date param
  */
  public getScenariosByDate(date: Date, pageKey: number, pageSize: number): Observable<Scenario[]> {
    let params = new HttpParams();
    params = params.append('date', DateManager.dateToString(date, 'YYYY-MM-DD'));
    params = params.append('pageKey', pageKey.toString());
    params = params.append('pageSize', pageSize.toString());
    return this.http.get<Scenario[]>(`${environment.urlServerTeclogi}${this.endpointResources.scenarios}`, { params });
  }

  /**
  * @param {string} scenarioToken is the token of the scenary to get its status
  * @returns {Observable<ScenarioStatus>} returns an observable of scenarios status
  * @description Gets a status from a scenario's token
  */
  public getScenarioStatus(scenarioToken: string): Observable<ScenarioStatus> {
    return this.http.get<ScenarioStatus>(`${environment.urlServerTeclogi}${this.endpointResources.scenarios}/${scenarioToken}/status`);
  }

  /**
  * @param {string} token is the token of the scenary to optimize
  * @returns {Observable<ScenarioOptimized>} returns the result of the optimization
  * @description Optimizes a scenario
  */
  public optimizeScenario(token: string): Observable<ScenarioOptimized> {
    return this.http.put<ScenarioOptimized>(
      `${environment.urlServerTeclogi}${this.endpointResources.optimizeScenario}${token}/optimize`, {});
  }

  /**
  * @param {string} token is the token of the scenary to get its routes
  * @returns {Observable<{status: string, response: RouteScenario[]}>} returns the routes of the scenario
  * @description Gets a scenario's routes
  */
  public getRouteByScenario(token: string): Observable<{ status: string, response: RouteScenario[] }> {
    return this.http.get<{ status: string, response: RouteScenario[] }>(`${environment.urlServerTeclogi}${this.endpointResources.getRouteByScenario}${token}`);
  }

  /**
  * @param {string} token is the token of the scenary to get its routes
  * @returns {Observable<{status: string, response: RouteScenario[]}>} returns the routes of the scenario
  * @description Gets a scenario's routes
  */
  public getScenarioByToken(token: string): Observable<Scenario> {
    return this.http.get<Scenario>(`${environment.urlServerTeclogi}${this.endpointResources.scenarios}/${token}`);
  }

  /**
  * @param {CreateCargoPayload[]} body is the body to create the cargos
  * @returns {Observable<Cargo[]>} returns the cargos created
  * @description Creates a list of cargos from a route
  */
  public createCargos(body: CreateCargoPayload[]): Observable<Cargo[]> {
    return this.http.post<Cargo[]>(`${environment.urlServerTeclogi}${this.endpointResources.drivinCreateCargos}`, body);
  }

  /**
  * @param {RouteScenario} route is the route to get its capacities
  * @returns {number[]} returns a list of each three capacities of the route
  * @description Gets a route's capacities
  */
  public getCapacitiesfromRouteScenario(route: RouteScenario): number[] {
    let orderCapacities = [0, 0, 0];
    if (route && route.trips)
      route.trips.forEach(trip => {
        if (trip && trip.results)
          trip.results.forEach(result => {
            if (result && result.orders)
              result.orders.forEach(order => {
                if (order && order.units1) orderCapacities[0] += order.units1;
                if (order && order.units2) orderCapacities[1] += order.units2;
                if (order && order.units3) orderCapacities[2] += order.units3;
              })
          })
      })
    return orderCapacities;
  }
}
