import { Validators } from "@angular/forms";
import { Patterns } from "../resources/patterns";

export interface FragmentedAddress {
    nomenclature: string;
    mainRoad: string;
    secundaryRoad: string;
    complementaryRoad: string;
}

export class ModelFragmentedAddress {

    constructor(
        private patterns: Patterns
    ) {

    }

    public validatorsFragmentedAddress = {
        nomenclature: [
            Validators.required,
            Validators.pattern(this.patterns.NOT_EMPTY.source)
        ],
        mainRoad: [
            Validators.required,
            Validators.pattern(this.patterns.NOT_EMPTY.source)
        ],
        secundaryRoad: [
            Validators.required,
            Validators.pattern(this.patterns.NOT_EMPTY.source)
        ],
        complementaryRoad: [
            Validators.required,
            Validators.pattern(this.patterns.NOT_EMPTY.source)
        ]
    };
}