import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Filter } from 'src/app/core/models/filter';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { Permission } from 'src/app/core/resources/permission';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ListFilterComponent } from 'src/app/shared/list-filter/list-filter.component';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';

@Component({
  selector: 'app-cargo-list-service-requests',
  templateUrl: './cargo-list-service-requests.component.html',
  styleUrls: ['./cargo-list-service-requests.component.scss']
})
export class CargoListServiceRequestsComponent implements OnInit {
  @ViewChild(ListFilterComponent, { static: false }) listFilterComponent: ListFilterComponent;
  typeList: string = 'serviceRequests';
  filters: string = '';
  optionsFilter: Filter;
  paginationList: PaginationList = new PaginationList();
  permission = Permission;
  constructor(
    private spinner: NgxSpinnerService,
    public service: FreightListService,
    public utils: Utils,
    public authService: AuthService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private snackbarService: SnackBarService
  ) { }

  ngOnInit() {
    this.optionsFilter = this.service.getOptionsFilter(this.typeList);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getListCargo($event: string) {
    const serviceRequestsObserver = {
      next: (data: Cargo[]) => {
        this.spinner.hide();
        if (!this.utils.isDefined(data) || data.length === 0) {
          this.paginationList.setEnablePagingScroll(false);
          data = [];
        }

        if (this.paginationList.getList().length) {
          this.paginationList.setList(
            this.paginationList.getList().concat(data)
          );
        } else {
          this.paginationList.setList(data);
        }
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        if (!(this.paginationList.getList().length > 0)) {
          this.resetPagging();
          this.paginationList.setList([]);
        }

      }
    };
    this.spinner.show();
    this.service.getListCargoServiceRequests(
      $event,
      this.paginationList.getPageKey(),
      this.paginationList.getPageSize()
    ).subscribe(serviceRequestsObserver);
  }


  resetPagging() {
    this.paginationList.setPageKey(1);
    this.paginationList.setList([]);
    this.paginationList.setEnablePagingScroll(true);
  }

  refreshList($event?: string) {
    !this.paginationList
      ? this.paginationList.setList([])
      : this.resetPagging();

    this.filters = $event;
    this.getListCargo(this.filters);
  }

  onScrollDown() {
    if (
      this.paginationList.getEnablePagingScroll()
    ) {
      this.paginationList.setPageKey(
        this.paginationList.getPageKey() + 1
      );
      this.getListCargo(this.filters);
    }
  }

}
