import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { CompanyAccessGuard } from 'src/app/core/guards/company-access.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BILLING, DISPATCH_CARGO_LOOKER, FREQUENT_ROUTES_LOOKER, OPERATIONS_ROUTES_LOOKER, STOPED_CARGO_LOOKER, VEHICLE_ANALYTICS_LOOKER } from './dashboards';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Analíticas' },
    children: [
      {
        path: 'operations',
        canActivate: [UserActiveGuard, RoleGuard],
        children: [
          // DONT CHANGE. BY THIS WAY CompanyAccessGuard WILL AWAIT THE PREVIOUS GUARDS
          // IF YOU DO NOT, IT MAY CAUSE LOST SESSION ON RELOADING PAGE
          {
            path: '',
            canActivate: [CompanyAccessGuard],
            component: DashboardComponent,
            data: {
              breadcrumb: 'Operaciones',
              dashboard: OPERATIONS_ROUTES_LOOKER,
              params: {
                'ds1.p1': { hashed: true, valueToken: 'USER_COMPANY' },
                'ds1.p2': { hashed: true, valueToken: 'HOLDER_COMPANY' },
                'ds2.p1': { hashed: true, valueToken: 'USER_COMPANY' },
                'ds2.p2': { hashed: true, valueToken: 'HOLDER_COMPANY' },
                'ds3.p1': { hashed: true, valueToken: 'USER_COMPANY' },
                'ds3.p2': { hashed: true, valueToken: 'HOLDER_COMPANY' },
                'ds4.p1': { hashed: true, valueToken: 'USER_COMPANY' },
                'ds4.p2': { hashed: true, valueToken: 'HOLDER_COMPANY' },
              },
              enableSelectorToRoot: true
            }
          }
        ]
      },
      {
        path: 'vehicleAnalytics',
        canActivate: [UserActiveGuard, RoleGuard],
        children: [
          // DONT CHANGE. BY THIS WAY CompanyAccessGuard WILL AWAIT THE PREVIOUS GUARDS
          // IF YOU DO NOT, IT MAY CAUSE LOST SESSION ON RELOADING PAGE
          {
            path: '',
            canActivate: [CompanyAccessGuard],
            component: DashboardComponent,
            data: {
              breadcrumb: 'Vehículos',
              dashboard: VEHICLE_ANALYTICS_LOOKER,
              params: {
                'ds1.p1': { hashed: true, valueToken: 'USER_COMPANY' },
                'ds2.p1': { hashed: true, valueToken: 'HOLDER_COMPANY' },
              }
            }
          }
        ]
      },
      {
        path: 'frequentRoutes',
        canActivate: [UserActiveGuard, RoleGuard],
        children: [
          // DONT CHANGE. BY THIS WAY CompanyAccessGuard WILL AWAIT THE PREVIOUS GUARDS
          // IF YOU DO NOT, IT MAY CAUSE LOST SESSION ON RELOADING PAGE
          {
            path: '',
            canActivate: [CompanyAccessGuard],
            component: DashboardComponent,
            data: {
              breadcrumb: 'Rutas frecuentes',
              dashboard: FREQUENT_ROUTES_LOOKER,
              params: {
                'ds1.p1': { hashed: true, valueToken: 'HOLDER_COMPANY' },
                'ds1.p2': { hashed: true, valueToken: 'USER_COMPANY', defaultToken: 'HOLDER_COMPANY' },
              },
              enableSelectorToRoot: true
            }
          }
        ]
      },
      {
        path: 'dispatch',
        canActivate: [UserActiveGuard, RoleGuard],
        children: [
          // DONT CHANGE. BY THIS WAY CompanyAccessGuard WILL AWAIT THE PREVIOUS GUARDS
          // IF YOU DO NOT, IT MAY CAUSE LOST SESSION ON RELOADING PAGE
          {
            path: '',
            canActivate: [CompanyAccessGuard],
            component: DashboardComponent,
            data: {
              breadcrumb: 'Despachos',
              dashboard: DISPATCH_CARGO_LOOKER,
              params: {
                'ds0.p1': { hashed: true, valueToken: 'HOLDER_COMPANY' },
              }
            }
          }
        ]
      },
      {
        path: 'stopedCargos',
        canActivate: [UserActiveGuard, RoleGuard],
        children: [
          // DONT CHANGE. BY THIS WAY CompanyAccessGuard WILL AWAIT THE PREVIOUS GUARDS
          // IF YOU DO NOT, IT MAY CAUSE LOST SESSION ON RELOADING PAGE
          {
            path: '',
            canActivate: [CompanyAccessGuard],
            component: DashboardComponent,
            data: {
              breadcrumb: 'Cargas detenidas',
              dashboard: STOPED_CARGO_LOOKER,
              params: {
                'ds0.p1': { hashed: true, valueToken: 'HOLDER_COMPANY' },
                'ds0.p2': { hashed: true, valueToken: 'USER_COMPANY', defaultToken: 'HOLDER_COMPANY' },
              },
              enableSelectorToRoot: true
            },
          }
        ]
      },
      {
        path: 'analyticsBilling',
        canActivate: [UserActiveGuard, RoleGuard],
        children: [
          // DONT CHANGE. BY THIS WAY CompanyAccessGuard WILL AWAIT THE PREVIOUS GUARDS
          // IF YOU DO NOT, IT MAY CAUSE LOST SESSION ON RELOADING PAGE
          {
            path: '',
            canActivate: [CompanyAccessGuard],
            component: DashboardComponent,
            data: {
              breadcrumb: 'Facturación',
              dashboard: BILLING,
              params: {
                'ds0.p1': { hashed: true, valueToken: 'HOLDER_COMPANY' },
              },
            },
          }
        ]
      },

      { path: '**', redirectTo: '/analytics/operations' }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AnalyticsRoutingModule { }
