<div id="container" class="col-md-12S md-md-0 md-10 h-100-margin">
    <section class="container-list h-100-margin">
        <header class="container-header">
            <div class="title-component no-margin mr-5">
                <i class="fas fa-mail-bulk icon-mail"></i>
                Enviar reporte
            </div>
            <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="close(false)"></i>
        </header>
        <article id="content">
            <form class="form_container row" permission-display [module]="permission.cargo.module"
                [functionality]="permission.cargo.sendEmailReportsLastLocations" (ngSubmit)="onSubmit()"
                [formGroup]="mailForm">
                <div class="col-md-12">
                    <mat-form-field appearance="standard">
                        <mat-label>Ingresar Correo</mat-label>
                        <input matInput type="text" type="email" formControlName="mail" [formControl]="emailControl"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEmail">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>
                        <span class="button-add" matSuffix (click)="addEmail()">
                            Añadir
                        </span>
                    </mat-form-field>
                </div>
            </form>
            <div class="card-mails" *ngFor="let mail of arrayMails; let i = index;">
                <div class="text-break">
                    {{ mail.name }}
                </div>
                <i class="fas fa-minus pointer" (click)="delect(i)"></i>
            </div>
            <div class="alert alert-info" role="alert">
                El simbolo (-) le permite quitar temporalmente los correos a los cuales no desea enviar el reporte. Sin
                embargo volveran a aparecer cuando vuelva a entrar a esta ventana.
            </div>
        </article>
        <footer id="footer">
            <div class="col-md-12">
                <mat-card-actions class="card-actions button-center">
                    <button mat-raised-button color="primary" (click)="onSubmit()" class="button-width-auto">
                        Enviar reporte
                    </button>
                </mat-card-actions>
            </div>
            <span class=" separator-horizontal"></span>
        </footer>
    </section>
</div>