import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-manual-accept',
  templateUrl: './manual-accept.component.html',
  styleUrls: ['./manual-accept.component.scss']
})
export class ManualAcceptComponent implements OnInit {

  constructor(
    private snackbarService: SnackBarService,
    private ref: MatDialogRef<ManualAcceptComponent>
  ) { }

  ngOnInit() {
  }

  copyWpToClipboard() {
    navigator.clipboard
      .writeText('https://wa.me/573173678485?text=Buenas%20dia')
      .then(
        () => this.snackbarService.openSnackBar('¡Link copiado al porta papeles!')
      );
  }

  close() {
    this.ref.close();
  }
}
