import { Component } from '@angular/core';

@Component({
    selector: 'app-basc-policy',
    templateUrl: './bascPolicy.component.html',
    styleUrls: ['./bascPolicy.component.scss']
})

export class BascPolicyComponent {

}