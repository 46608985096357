<aside class="user__documents">
  <div class="row title-2 text-strong color-secondary-dark">
    <div class="col-md-12">
      Documentos {{role ? 'del ' + (role | roleTranslate) : ''}}
    </div>
  </div>
  <form class="row" [formGroup]="form" (submit)="onSubmit()">
    <!-- Profile Picture-->
    <div class="col-md-4">
      <input #inputImage class="display-none" type="file" (change)="setInstanceFileStorageImg($event, 'profilePicture')"
        [disabled]="isDisable">
      <mat-form-field appearance="standard" (click)="openDialogProfilePhoto()">
        <mat-label>Foto de perfil</mat-label>
        <input matInput type="text" class="field__form--input" readonly [formControl]="visualProfilePicture"
          [disabled]="isDisable">
        <mat-error *ngIf="form.get('profilePicture').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
      </mat-form-field>
    </div>
    <!-- Back Document-->
    <div class="col-md-4">
      <input #inputBackDocument class="display-none" type="file"
        (change)="setInstanceFileStorage($event,'backDocument')" [disabled]="isDisable">
      <mat-form-field appearance="standard" (click)="openWindowFile('inputBackDocument')">
        <mat-label>Parte trasera de documento</mat-label>
        <input matInput type="text" class="field__form--input" readonly [formControl]="visualBackDocument"
          [disabled]="isDisable">
        <mat-error *ngIf="form.get('backDocument').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <i class="fas fa-paperclip field__form--icon"></i>
      </mat-form-field>
    </div>
    <!-- Front Document-->
    <div class="col-md-4">
      <input #inputFrontDocument class="display-none" type="file"
        (change)="setInstanceFileStorage($event,'frontDocument')" [disabled]="isDisable">
      <mat-form-field appearance="standard" (click)="openWindowFile('inputFrontDocument')">
        <mat-label>Parte frontal de documento</mat-label>
        <input matInput type="text" class="field__form--input" readonly [formControl]="visualFrontDocument"
          [disabled]="isDisable">
        <i class="fas fa-paperclip field__form--icon"></i>
        <mat-error *ngIf="form.get('frontDocument').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12" *ngIf="!hideBtn" permission-display [module]="permission.cargo.module"
      [functionality]="permission.cargo.saveDriverDocuments">
      <mat-card-actions class="text-center">
        <button mat-raised-button color="primary" type="submit" class="button-width-auto">
          Guardar
        </button>
      </mat-card-actions>
    </div>
  </form>

  <div class="row" *ngIf="!hideBtn">
    <div class="col-md-12">
      <!-- Select -->
      <div class="row">
        <!-- Type Documents -->
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-select [(ngModel)]="selectDocumentView" [ngModelOptions]="{standalone: true}"
              (selectionChange)="onChangeTypeDocument()">
              <mat-option *ngFor="let document of listTypeDocuments" [value]="document.value">
                {{document.name}} </mat-option>
            </mat-select>
            <mat-label>
              <i>Elija un Documento</i>
            </mat-label>
          </mat-form-field>
        </div>
      </div>
      <!-- Preview Document -->
      <div class="row" *ngIf="nameFileSelected">
        <app-files-storage class="width-100" [nameFile]="nameFileSelected"></app-files-storage>
      </div>
    </div>
  </div>
</aside>