<div class="d-flex flex-column position-relative">
  <mat-form-field class="input-document" appearance="standard">
    <mat-label>{{options && options.title ? options.title : 'Documento'}}<span class="asterisk"
        *ngIf="utils.isRequiredField(documentInputFormControl)">*</span></mat-label>
    <span class="spinner-input" *ngIf="documentInputFormControl.disabled">
      <i class="fas fa-circle-notch"></i>
    </span>
    <input #documentInput (keyup)="changeValue($event)" id='driverInput' matInput onlyNumbersInt
      [matAutocomplete]="autocompleteUser" type="text" [formControl]="documentInputFormControl">
    <i (click)="searchUser(documentInput)" matSuffix class="fas fa-search color-primary"
      matTooltip="Buscar documento"></i>
    <mat-error *ngIf="documentInputFormControl.errors?.required">
      {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
    <mat-error *ngIf="documentInputFormControl.errors?.maxlength">
      {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'documento', documentMaxLength) }}
    </mat-error>
    <mat-error *ngIf="documentInputFormControl.errors?.minlength">
      {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'documento', documentMinLength) }}
    </mat-error>
    <mat-hint *ngIf="!searchButtonPressed && (options.showSearchHint !== false)" class="color-secondary-dark text-bold">
      Por favor realice una búsqueda
    </mat-hint>
    <mat-hint *ngIf="documentInputFormControl?.value?.information?.name && options.showUserName"
      class="color-secondary-dark text-bold">
      Usuario seleccionado: {{documentInputFormControl.value?.information?.name}}
    </mat-hint>
    <mat-autocomplete #autocompleteUser="matAutocomplete" [displayWith]="displayUserDocument"
      (optionSelected)="onSelectUser($event)">
      <mat-option *ngFor="let user of filteredUsers" [value]="user">
        <span>{{user?.information?.name || user?.information?.document}}</span>
      </mat-option>
      <mat-option [value]="'createDriver'" *ngIf="showCreateDriverOption && options.createDriver">
        <span class="font-italic">Crear Conductor</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>