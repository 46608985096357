<aside class="col-md-12 mb-md-0 mb-12 h-100-margin">
  <div id="mainGenerateGuides" class="container-list h-100-margin">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-12">
          <label class="title-component">
            Generación de múltiples guías
          </label>
        </div>
      </div>
    </div>
    <div class="container-list-filter">
      <div class="row">
        <!-- Company -->
        <div class="col-md-3">
          <mat-card-content class="company-select">
            <app-select-company [options]="optionsCompany" [inputFormControl]="companyFormControl"></app-select-company>
          </mat-card-content>
        </div>
        <!-- List states -->
        <div class="col-md-3" *ngIf="listStates.length">
          <mat-form-field appearance="standard">
            <mat-label>Estado</mat-label>
            <span class="spinner-input" *ngIf="stateLoading">
              <i class="fas fa-circle-notch"></i>
            </span>
            <mat-select [(formControl)]="stateFormControl" id="setStateGuidesFormState">
              <mat-option value="{{state.id}}" *ngFor="let state of listStates">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Input filter  -->
        <div class="col-md-3">
          <mat-form-field appearance="standard">
            <mat-label>No. de guía</mat-label>
            <input matInput type="text" [(ngModel)]="searchGuideText">
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="standard">
            <mat-label>Desde</mat-label>
            <input matInput [matDatepicker]="pickerLoadDate" [(ngModel)]="startDate" (click)="pickerLoadDate.open()"
              readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerLoadDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerLoadDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="standard">
            <mat-label>Hasta</mat-label>
            <input matInput [matDatepicker]="pickerEndDate" [(ngModel)]="endDate" (click)="pickerEndDate.open()"
              readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerEndDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="standard">
            <mat-label>Label</mat-label>
            <mat-select [(ngModel)]="withOutLabel">
              <mat-option [value]="null">Todas</mat-option>
              <mat-option [value]="false">Con label</mat-option>
              <mat-option [value]="true">Sin label</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <mat-card-actions>
            <button mat-raised-button color="primary" class="button-create-cargo" (click)="generateMultiplesGuides()"
              *ngIf="itemsSelecteds.length">
              <i class="fas fa-print"></i>
            </button>
            <button mat-raised-button class="primary-secondary" color="primary" (click)="cleanFilter()"
              title="Eliminar Filtros">
              <i class="far fa-trash-alt"></i>
            </button>
            <button mat-raised-button class="btn-filter" color="primary" (click)="applyFilter()"
              title="Aplicar Filtros">
              <i class="fas fa-search"></i>
            </button>
          </mat-card-actions>
        </div>
      </div>
    </div>
    <div class="container-list-body" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="d-flex justify-content-end">
        <div class="list-select-all mr-2" select-item-list [list]="listGuides" (onSelectItem)="onSelectItem($event)"
          [active]="true" [type]="'selectAll'"></div>
      </div>
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let guide of listGuides; let i = index;">
        <app-item-cargo-from-guides select-item-list [active]="true" [item]="guide" [list]="listGuides"
          (onSelectItem)="onSelectItem($event)" [guide]="guide" [print]="true">
        </app-item-cargo-from-guides>
      </div>
    </div>
  </div>
</aside>
