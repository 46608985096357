export class ImagesEvidence {

    private path: string;
    private url: string;

    public getPath(): string {
        return this.path;
    }

    public setPath(path: string): void {
        this.path = path;
    }

    public getUrl(): string {
        return this.url;
    }

    public setUrl(url: string): void {
        this.url = url;
    }


    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
