import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { timeout } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { environment } from './../../../environments/environment';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
        private globalResources: Global,
        public utils: Utils
    ) {

    }

    public getReports(
        dateLoad,
        endDate,
        ministry,
        plate,
        nit,
        exclusive,
        reportType,
        email,
        downloadType: 'Excel' | 'PDF' | 'Plain',
        finishedBy?: string,
        states?
    ) {
        let url: string = environment.urlServerTeclogi + this.endpointResources.getReports;
        if (downloadType == 'PDF')
            url = environment.urlServerTeclogi + this.endpointResources.getReportPDF;

        let params = new HttpParams();
        params = params.append("reportType", reportType);

        if (downloadType == 'Plain') {
            params = params.append("type", 'UIAF');
            params = params.append("txt", "true");
        }

        if (states && states.length && states.length > 0) {
            let statesToSend = states ? states.slice() : [];
            if (statesToSend.includes('All cargo')) {
                const allCargoIndex = statesToSend.indexOf('All cargo');
                statesToSend.splice(allCargoIndex, 1)
            }
            if (reportType === 'useApp') {
                params = params.append('finishedBy', finishedBy);
            }
            params = params.append('states', JSON.stringify(statesToSend));
        }

        if (downloadType == 'Excel') {
            params = params.append('excel', 'true');
        } else {
            params = params.append('excel', 'false');
        }
        if (email) {
            params = params.append('email', 'true');
        }
        if (this.utils.isDefined(ministry)) {
            params = params.append('ministry', ministry.toString());
        }
        if (nit && nit !== '-1') {
            params = params.append('nit', nit);
            if (this.utils.isDefined(exclusive)) {
                params = params.append('exclusive', exclusive.toString());
            }
        }
        if (dateLoad) {
            params = params.append('dateLoad', dateLoad);
        }
        if (endDate) {
            params = params.append('endDate', endDate);
        }
        if (!this.utils.isEmpty(plate)) {
            params = params.append('licensePlate', plate);
        }
        if ((!!downloadType) && !email) {
            return this.http.get(
                url,
                {
                    params,
                    observe: 'response',
                    responseType: 'blob'
                }
            ).pipe(
                timeout(600000)
            );
        } else {
            return this.http.get(
                url,
                {
                    params
                }
            );
        }
    }

    createTypesReports(configurationReports: Array<any>): Array<any> {
        const typesReports = [];

        configurationReports.forEach((type: any) => {
            const objectTypeReport = {
                id: type.type,
                name: this.getDataTypeReportById(type.type).name,
                units: type.units
            };
            typesReports.push(objectTypeReport);
        });

        return typesReports;
    }

    getDataTypeReportById(type: any, listTypesReports?: Array<any>) {

        const list = listTypesReports || this.globalResources.typesReports;
        const listTypes = _.find(list, (typeReport: any) => {
            return typeReport.type === type;
        });

        return listTypes;

    }

}
