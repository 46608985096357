import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { Component, Input, OnChanges, Output, EventEmitter, NgZone, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnChanges {

    instanceModalOpen: NgbModalRef;
    message: string;
    @Input() titleModal: string;
    @Input() titleCancel: string;
    @Input() titleConfirm: string;
    @Input() textareaShow: boolean;
    @Output() onCancel = new EventEmitter<boolean>();
    @Output() onConfirm = new EventEmitter<any>();
    @ViewChild('modalElement', { static: true }) modalElement: ElementRef;


    constructor(
        private spinner: NgxSpinnerService,
        private ngZone: NgZone,
        public utils: Utils,
        private global: Global,
        private modalService: NgbModal,
        private snackBarService: SnackBarService
    ) {

    }

    ngOnInit() {

    }

    ngOnChanges(changes: any) {

    }

    openConfirm() {
        this.instanceModalOpen = this.modalService.open(this.modalElement, { centered: true, ariaLabelledBy: 'modal-basic-title' });
    }

    closeModal() {
        if (this.instanceModalOpen) {
            this.instanceModalOpen.close();
        }
    }

    cancel() {
        this.closeModal();
        this.onCancel.emit(false);
    }

    confirm() {
        this.onConfirm.emit(
            {
                state: true,
                message: this.message,
            }
        );

    }

}