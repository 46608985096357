import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Endpoints } from './../../core/resources/endpoints';
import { Global } from './../../core/resources/global';
import { environment } from './../../../environments/environment';
import { CollectionPoint } from 'src/app/core/interfaces/collectionPoint';
import { LocationPoint } from 'src/app/core/interfaces/locationPoint';


@Injectable({
    providedIn: 'root'
})
export class CollectionPointService {

    collectionPointSelected: CollectionPoint;

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        private _globalResources: Global
    ) {

    }

    getCollectionPoint(pageKey: number, pageSize: number, farmNameFilter: string) {
        let url: string = environment.urlServerTeclogi + this._endpointResources.collectionPointList + "?pageKey=" + pageKey + "&pageSize=" + pageSize;
        if (farmNameFilter.trim().length) {
            url += "&farm=" + farmNameFilter;
        }
        return this._http.get<CollectionPoint[]>(
            url
        );
    }

    createCollectionPoint(data: CollectionPoint) {
        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.collectionPointCreate,
            [data]
        );
    }

    updateCollectionPoint(data: CollectionPoint) {
        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.collectionPointUpdate,
            [data]
        );
    }

    deleteCollectionPoint(id: string) {
        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.collectionPointDelete,
            [id]
        );
    }

    findHistoryLocationActive(historyLocations: LocationPoint[]) {
        return _.filter(historyLocations, function (location: LocationPoint) {
            return location.state === true;
        });
    }
}
