import { AddressCargo } from "./address-cargo.model";

export class Destination {

    private name: string;
    private addresses: AddressCargo[] = [];
    private downloadDate: string;
    private id: string;
    private municipalityCode: string;

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getAddresses(): AddressCargo[] {
        return this.addresses || [new AddressCargo()];
    }

    public setAddresses(addresses: AddressCargo[]): void {
        this.addresses = [];
        addresses.map((object) => {
            let addressCargo = new AddressCargo();
            addressCargo = addressCargo.deserialize(object);
            this.addresses.push(addressCargo);
        });
    }

    public getDownloadDate(): string {
        return this.downloadDate;
    }

    public setDownloadDate(downloadDate: string): void {
        this.downloadDate = downloadDate;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string): void {
        this.id = id;
    }

    public getMunicipalityCode(): string {
        return this.municipalityCode;
    }

    public setMunicipalityCode(municipalityCode: string): void {
        this.municipalityCode = municipalityCode;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
