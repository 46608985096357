import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Utils } from 'src/app/core/resources/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VehicleInspect } from 'src/app/core/interfaces/vehicleInspect';
import { CargoItemService } from 'src/app/modules/cargo/cargo-item/cargo-item.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent } from '../dialog/dialog.component';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { AngularFireStorage } from '@angular/fire/storage';
import { VehicleDocuments } from 'src/app/core/interfaces/vehicleDocuments';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import ContentTypes from 'src/app/core/resources/content-type-ext.json';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { CheckListQuestionVehicle, CheckLists } from 'src/app/core/interfaces/checkListsInspectVehicle';
import { KeyValue } from '@angular/common';
import { ProtectionElements } from 'src/app/core/interfaces/protectionElementInspectVehicle';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-dialog-survey',
  templateUrl: './dialog-survey.component.html',
  styleUrls: ['./dialog-survey.component.scss'],
})
export class DialogSurveyComponent implements OnInit {
  public options = { "B": "Bueno", "M": "Malo", "N/A": "No Aplica" };
  public questions = {
    "LAT": "Latonería",
    "PYC": "Puertas y Compartimentos",
    "AAC": "Asientos Apoya Cabezas",
    "SBP": "Seguros y Bloqueo de puertas",
    "EVI": "Estado de los vidrios",
    "EES": "Estado de los espejos",
    "SDR": "Sistema de refrigeración",
    "SPT": "Sección del pasajero y estado del techo",
    "EPL": "Estado de las paredes laterales",
    "EFP": "Estado de la pared frontal y las puertas",
    "LDC": "Llantas del cabezote",
    "LDT": "Llantas del Trailer",
    "LDR": "Llantas de repuesto",
    "FDE": "Freno de emergencia",
    "ASR": "Avisos o señales reglamentarias",
    "EIV": "Extintor de vehiculos de acuerdo al tipo de vehiculo",
    "CDI": "Comportamiento del interior - Piso de la cabina y litera",
    "CDH": "Caja de Herramientas",
    "FBA": "Farolas bajas",
    "CCD": "Cocuyos Delanteros",
    "CCT": "Cocuyos Traseros",
    "LDE": "Luces de estacionamiento",
    "DID": "Dirrecionales delanteras",
    "DIT": "Direccionales Traceras",
    "LPR": "Luces y alerta de reversa",
    "STP": "Stop",
    "LST": "Luces del trailer",
    "CDC": "Cinturones de seguridad",
    "LIN": "Linterna",
    "AGV": "Aseo general del vehiculo",
    "IGG": "Inspección del generador (GENSET)",
    "QRP": "Area de la quinta rueda y Patin - KING PING",
    "PMC": "Pata mecanica",
    "BYC": "Bateria and comportamientos",
    "TCA": "Tanques (Combustible y Agua) y Compartimientos",
    "PAR": "Parachoques - Aros",
    "EPA": "Estado puntos de anclaje",
    "SFT": "Señales en forma de triángulo",
    "FPR": "Freno principal",
    "LIM": "Limpiabrisas - Derecho/Izquierdo/Atras",
    "KDD": "Kit de derrame",
    "BPA": "Botiquín de primeros auxilios",
    "JDH": "Juego de herramientas",
    "COT": "Cuñas y/o Tacos",
    "GEM": "Gato ó Elevador Mecánico ó HID",
    "CRU": "Cruceta",
    "FDA": "Filtro de Aire",
    "PAF": "Pared frontal",
    "PIS": "Piso",
    "EYB": "Exterior y Bastidor",
    "COR": "Corral",
    "LAI": "Lado Izquierdo",
    "TEC": "Techo",
    "PIN": "Pines",
    "CAR": "Carpa",
    "LAR": "Lado Derecho",
    "PYM": "Puertas y Mecanismos de cierre",
    "AMR": "Amarres",
    "PLA": "Plancha",
    "TBC": "Tapabocas",
    "GOA": "Gel / Alcohol",
    "GUA": "Guantes",
    "MDS": "Monogafas de seguridad",
    "CHR": "Chaleco reflectivo",
    "CSC": "Casco"
  }

  public vehicleStructure: Array<CheckLists> = [
    { question: CheckListQuestionVehicle.LAT, response: "B" },
    { question: CheckListQuestionVehicle.PYC, response: "B" },
    { question: CheckListQuestionVehicle.AAC, response: "B" },
    { question: CheckListQuestionVehicle.SBP, response: "B" },
    { question: CheckListQuestionVehicle.EVI, response: "B" },
    { question: CheckListQuestionVehicle.EES, response: "B" },
    { question: CheckListQuestionVehicle.SDR, response: "B" },
    { question: CheckListQuestionVehicle.SPT, response: "B" },
    { question: CheckListQuestionVehicle.EPL, response: "B" },
    { question: CheckListQuestionVehicle.EFP, response: "B" },
    { question: CheckListQuestionVehicle.LDC, response: "B" },
    { question: CheckListQuestionVehicle.LDT, response: "B" },
    { question: CheckListQuestionVehicle.LDR, response: "B" },
    { question: CheckListQuestionVehicle.FDE, response: "B" },
    { question: CheckListQuestionVehicle.ASR, response: "B" },
    { question: CheckListQuestionVehicle.EIV, response: "B" },
    { question: CheckListQuestionVehicle.CDI, response: "B" },
    { question: CheckListQuestionVehicle.CDH, response: "B" },
    { question: CheckListQuestionVehicle.FBA, response: "B" },
    { question: CheckListQuestionVehicle.CCD, response: "B" },
    { question: CheckListQuestionVehicle.CCT, response: "B" },
    { question: CheckListQuestionVehicle.LDE, response: "B" },
    { question: CheckListQuestionVehicle.DID, response: "B" },
    { question: CheckListQuestionVehicle.DIT, response: "B" },
    { question: CheckListQuestionVehicle.LPR, response: "B" },
    { question: CheckListQuestionVehicle.STP, response: "B" },
    { question: CheckListQuestionVehicle.LST, response: "B" },
    { question: CheckListQuestionVehicle.CDC, response: "B" },
    { question: CheckListQuestionVehicle.LIN, response: "B" },
    { question: CheckListQuestionVehicle.AGV, response: "B" },
    { question: CheckListQuestionVehicle.IGG, response: "B" },
    { question: CheckListQuestionVehicle.QRP, response: "B" },
    { question: CheckListQuestionVehicle.PMC, response: "B" },
    { question: CheckListQuestionVehicle.BYC, response: "B" },
    { question: CheckListQuestionVehicle.TCA, response: "B" },
    { question: CheckListQuestionVehicle.PAR, response: "B" },
    { question: CheckListQuestionVehicle.EPA, response: "B" },
    { question: CheckListQuestionVehicle.SFT, response: "B" },
    { question: CheckListQuestionVehicle.FPR, response: "B" },
    { question: CheckListQuestionVehicle.LIM, response: "B" },
    { question: CheckListQuestionVehicle.KDD, response: "B" },
    { question: CheckListQuestionVehicle.BPA, response: "B" },
    { question: CheckListQuestionVehicle.JDH, response: "B" },
    { question: CheckListQuestionVehicle.COT, response: "B" },
    { question: CheckListQuestionVehicle.GEM, response: "B" },
    { question: CheckListQuestionVehicle.CRU, response: "B" },
    { question: CheckListQuestionVehicle.FDA, response: "B" },
  ];

  public bodyworkElements: Array<CheckLists> = [
    { question: CheckListQuestionVehicle.PAF, response: "B" },
    { question: CheckListQuestionVehicle.PIS, response: "B" },
    { question: CheckListQuestionVehicle.EYB, response: "B" },
    { question: CheckListQuestionVehicle.COR, response: "B" },
    { question: CheckListQuestionVehicle.LAI, response: "B" },
    { question: CheckListQuestionVehicle.TEC, response: "B" },
    { question: CheckListQuestionVehicle.PIN, response: "B" },
    { question: CheckListQuestionVehicle.CAR, response: "B" },
    { question: CheckListQuestionVehicle.LAR, response: "B" },
    { question: CheckListQuestionVehicle.PYM, response: "B" },
    { question: CheckListQuestionVehicle.AMR, response: "B" },
    { question: CheckListQuestionVehicle.PLA, response: "B" }
  ];

  public elementsProtection: Array<ProtectionElements> = [
    { name: CheckListQuestionVehicle.TBC, available: true, status: "B" },
    { name: CheckListQuestionVehicle.GOA, available: true, status: "B" },
    { name: CheckListQuestionVehicle.GUA, available: true, status: "B" },
    { name: CheckListQuestionVehicle.MDS, available: true, status: "B" },
    { name: CheckListQuestionVehicle.CHR, available: true, status: "B" },
    { name: CheckListQuestionVehicle.CSC, available: true, status: "B" },
  ];
  public optimal: boolean = false;
  public odors: boolean = false;
  public crossedContamination: boolean = false;

  public user: string;
  public vehicle: Vehicle;
  basePath: string;
  public updatePhoto: boolean = false;
  public objComplete: VehicleInspect;
  minObservation: number = 8;
  @ViewChild('inputLeftLateralPicture', { static: false }) inputLeftLateralPicture: ElementRef;
  @ViewChild('inputRightLateralPicture', { static: false }) inputRightLateralPicture: ElementRef;
  @ViewChild('inputFrontalImage', { static: false }) inputFrontalImage: ElementRef;
  @ViewChild('inputPreventativeMaintenance', { static: false }) inputPreventativeMaintenance: ElementRef;
  @ViewChild('inputCorrectiveMaintenance', { static: false }) inputCorrectiveMaintenance: ElementRef;
  form = new FormGroup({
    observation: new FormControl('', [Validators.required, Validators.minLength(this.minObservation)]),
    dateMaintenanceCorrective: new FormControl('', Validators.required),
    dateMaintenancePreventive: new FormControl('', Validators.required),
  });
  instanceLogo: FileStorage;
  formPicture = new FormGroup({
    frontPicture: new FormControl('', Validators.required),
    rightLateralPicture: new FormControl('', Validators.required),
    leftLateralPicture: new FormControl('', Validators.required),
    preventativeMaintenance: new FormControl(''),
    correctiveMaintenance: new FormControl(''),
  });

  public originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => 0;


  constructor(
    public dialogRef: MatDialogRef<DialogSurveyComponent>,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private cargoItemService: CargoItemService,
    public dialog: MatDialog,
    private vehiclesService: VehiclesService,
    private spinner: NgxSpinnerService,
    private angularFireStorage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: {
      user, licensePlate, inspect, disabled, id
    },
  ) { }

  public userInformation: string;

  ngOnInit() {
    if (this.data.inspect && this.data.inspect.id) {
      this.data.inspect.dateMaintenancePreventive = DateManager.stringToDate(this.data.inspect.dateMaintenancePreventive, 'YYYY-MM-DD');
      this.data.inspect.dateMaintenanceCorrective = DateManager.stringToDate(this.data.inspect.dateMaintenanceCorrective, 'YYYY-MM-DD');
      this.form.patchValue(this.data.inspect);
      if (this.data.inspect.preventativeMaintenance) {
        this.formPicture.get("preventativeMaintenance").setValue(this.data.inspect.preventativeMaintenance);
        this.formPicture.get("preventativeMaintenance").disable();
      }
      if (this.data.inspect.correctiveMaintenance) {
        this.formPicture.get("correctiveMaintenance").setValue(this.data.inspect.correctiveMaintenance);
        this.formPicture.get("correctiveMaintenance").disable();
      }

      this.bodyworkElements = this.data.inspect.bodyworkElements;
      this.elementsProtection = this.data.inspect.elements;
      this.vehicleStructure = this.data.inspect.vehicleStructure;

      if (this.data.inspect.approval) {
        this.userInformation = this.data.inspect.confirmedInspectionFingerPrint.userName;
      } else {
        this.userInformation = this.data.inspect.evaluatorFingerPrint.userName;
      }
      this.form.disable();
    } else {
      this.userInformation = this.data.user.name;
    }
    this.getVehicle(this.data.licensePlate);
  }

  public getVehicle(id: string) {
    this.vehiclesService.getVehicle(id).subscribe(
      (success: any) => {
        if (success) {
          this.vehicle = success;
          this.formPicture.patchValue(this.vehicle);
          if (this.data && this.data.inspect && this.data.inspect.id) {
            this.formPicture.disable();
          }
        }
      }
    );
  }

  public confirmRejection() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas rechazar la inspección del vehiculo?`,
    };
    dialogConfig.height = 'auto';
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.approve(false);
      }
    });
  }

  public approve(approval: boolean) {
    let obj = {
      inspectionId: this.data.inspect.id,
      fingerprint: {
        userId: this.data.user.document,
        userName: this.data.user.name,
        date: DateManager.dateToString(new Date())
      }
    };
    this.cargoItemService.approveInspection(obj, approval).subscribe(success => {
      approval ?
        this.snackBarService.openSnackBar('Inspección aprobada correctamente', undefined, "success") :
        this.snackBarService.openSnackBar('Inspección rechazada correctamente', undefined, "success");
      this.dialogRef.close();
    },
      (error: HttpErrorResponse) => {
        if (!this.utils.isEmpty(error) && !this.utils.isEmpty(error.error) && !this.utils.isEmpty(error.error.message)) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'alert');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al aprobar inspección', undefined, 'error');
        }
      }
    );
  }

  public setInstanceFileStorage(e: Event, typeFile?: string) {
    const validTypeFiles = ['rightLateralPicture', 'leftLateralPicture', 'frontPicture', 'correctiveMaintenance', 'preventativeMaintenance'];
    if (validTypeFiles.includes(typeFile)) {
      if (this.utils.isImage(e.target['files'])) {
        this.instanceLogo = {
          fileData: {
            file: e.target['files'][0],
            name: e.target['files'][0]['name'],
            uploaded: true,
            size: this.utils.bytesToSize(e.target['files'][0].size),
            url: null
          },
          storageData: {
            storageRef: null,
            uploadTask: null,
            uploadProgress: null
          }
        };
        this.uploadFileStorage(typeFile);
      } else {
        this.snackBarService.openSnackBar('El archivo no es una imagen', undefined, 'error')
      }
    } else {
      this.instanceLogo = {
        fileData: {
          file: e.target['files'][0],
          name: e.target['files'][0]['name'],
          uploaded: true,
          size: this.utils.bytesToSize(e.target['files'][0].size),
          url: null
        },
        storageData: {
          storageRef: null,
          uploadTask: null,
          uploadProgress: null
        }
      };
      this.uploadFileStorage(typeFile);
    }
  }

  public openWindowFile(typeFile?: string): void {
    if (typeFile == 'leftLateralPicture' && this.formPicture.get('leftLateralPicture').enabled) {
      this.inputLeftLateralPicture.nativeElement.click();
    }
    if (typeFile == 'rightLateralPicture' && this.formPicture.get('rightLateralPicture').enabled) {
      this.inputRightLateralPicture.nativeElement.click();
    }
    if (typeFile == 'frontPicture' && this.formPicture.get('frontPicture').enabled) {
      this.inputFrontalImage.nativeElement.click();
    }
    if (typeFile == 'preventativeMaintenance' && this.formPicture.get('preventativeMaintenance').enabled) {
      this.inputPreventativeMaintenance.nativeElement.click();
    }
    if (typeFile == 'correctiveMaintenance' && this.formPicture.get('correctiveMaintenance').enabled) {
      this.inputCorrectiveMaintenance.nativeElement.click();
    }
  }

  private uploadFileStorage(typeFile?: string): void {
    this.basePath = 'vehicle/' + this.vehicle.id + '/';
    let filePath = this.basePath + this.instanceLogo.fileData.name;
    const fileTypeToField = {
      rightLateralPicture: 'rightLateralPicture',
      leftLateralPicture: 'leftLateralPicture',
      frontPicture: 'frontPicture',
      preventativeMaintenance: 'preventativeMaintenance',
      correctiveMaintenance: 'correctiveMaintenance'
    };
    if (fileTypeToField[typeFile]) {
      this.formPicture.get(fileTypeToField[typeFile]).setValue(filePath);
    }    
    this.spinner.show();
    this.instanceLogo.storageData.storageRef = this.angularFireStorage.ref(filePath);
    this.instanceLogo.storageData.uploadTask = this.angularFireStorage.upload(filePath, this.instanceLogo.fileData.file);
    this.instanceLogo.storageData.uploadTask.then(
      (data) => {
        this.instanceLogo.storageData.storageRef.getDownloadURL().subscribe(
          (urlFile) => {
            this.spinner.hide();
          }
        );
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  saveInspection() {
    this.form.markAllAsTouched();
    if (this.utils.errorMessagesCustomized(this.form.get('dateMaintenanceCorrective'), 'fecha de mantenimiento correctivo')) return;
    else if (this.utils.errorMessagesCustomized(this.form.get('dateMaintenancePreventive'), 'fecha de mantenimiento preventivo')) return;
    else if (this.utils.errorMessagesCustomized(this.form.get('observation'), 'observación', 8)) return;
    else if (this.form.invalid) {
      this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    } else {
      let objUser = {
        userId: this.data.user.document,
        userName: this.data.user.name,
        date: DateManager.dateToString(new Date())
      }

      const dateMaintenancePreventive = DateManager.dateToString(this.form.get('dateMaintenancePreventive').value, 'YYYY-MM-DD');
      const dateMaintenanceCorrective = DateManager.dateToString(this.form.get('dateMaintenanceCorrective').value, 'YYYY-MM-DD');
      this.objComplete = {
        version: 2,
        confirmedInspectionFingerPrint: null,
        rejectionCause: null,
        licensePlate: this.data.licensePlate,
        vehicleStructure: this.vehicleStructure,
        bodyworkElements: this.bodyworkElements,
        elements: this.elementsProtection,
        evaluatorFingerPrint: objUser,
        observation: this.form.get('observation').value,
        dateMaintenancePreventive: dateMaintenancePreventive,
        dateMaintenanceCorrective: dateMaintenanceCorrective,
        optimal: this.optimal,
        odors: this.odors,
        crossedContamination: this.crossedContamination,
        preventativeMaintenance: this.formPicture.value.preventativeMaintenance,
        correctiveMaintenance: this.formPicture.value.correctiveMaintenance,
      }

      if (!this.updatePhoto) {
        this.save();
      } else {
        //  Aqui va la parte de la subida de las fotos
        if (this.utils.errorMessagesCustomized(this.formPicture.get('frontPicture'), 'foto frontal del vehículo')) return;
        else if (this.utils.errorMessagesCustomized(this.formPicture.get('rightLateralPicture'), 'foto lateral derecha del vehículo')) return;
        else if (this.utils.errorMessagesCustomized(this.formPicture.get('leftLateralPicture'), 'foto lateral izquierda del vehículo')) return;
        else if (this.utils.errorMessagesCustomized(this.formPicture.get('preventativeMaintenance'), 'foto mantenimiento preventivo')) return;
        else if (this.utils.errorMessagesCustomized(this.formPicture.get('correctiveMaintenance'), 'foto mantenimiento correctivo')) return;
        else if (this.formPicture.invalid) {
          this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
          return;
        } else {
          this.spinner.show();
          //Para propertyCard toca buscar esa info para cargarla antes de guardar;
          const data: VehicleDocuments = {
            frontPicture: this.formPicture.value.frontPicture,
            rightLateralPicture: this.formPicture.value.rightLateralPicture,
            leftLateralPicture: this.formPicture.value.leftLateralPicture,
            propertyCard: this.vehicle.propertyCard,
          }
          this.vehiclesService.updateVehicleDocuments(this.vehicle.id, data).subscribe(
            (success: any) => {
              this.spinner.hide();
              this.snackBarService.openSnackBar('Datos guardados correctamente', undefined, 'success');
              this.save();
            },
            (error) => {
              this.spinner.hide();
              this.snackBarService.openSnackBar('Ocurrió un error al guardar los datos', undefined, 'error');
            }
          );
        }
      }
    }
  }

  public save() {
    this.cargoItemService.sendInspection(this.objComplete, "vehicle", this.data.id).subscribe(
      (success) => {
        if (success) {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Inspección guardada correctamente');
          this.closeDialog(success);
        } else {
          this.snackBarService.openSnackBar('Existio un error al guardar la inspección', undefined, 'error');
          this.closeDialog('');
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (!this.utils.isEmpty(error) && !this.utils.isEmpty(error.error) && !this.utils.isEmpty(error.error.message)) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'alert');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al enviar inspeción', undefined, 'error');
        }
      }
    );
  }
  public downloadInspection() {
    this.spinner.show();
    this.cargoItemService.getInspectionPDF(this.data.id, this.data.licensePlate).subscribe(
      (response) => {
        this.spinner.hide();
        var file = new Blob([response], { type: ContentTypes.pdf });
        var downloadURL = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `_${this.data.licensePlate}_Inspección-a-las-unidades-de-transporte-de-carga.pdf`;
        link.click();
      }, (error: HttpErrorResponse) => {
        this.spinner.hide()
        this.snackBarService.openSnackBar('Ocurrió un error al descargar la Inspección a las unidades de transporte de carga', undefined, 'error');
      })
  }

  closeDialog(object) {
    this.dialogRef.close(object);
  }
}
