import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChildren, QueryList, Inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { CargoResources } from '../../../manual-creation-cargo/resources/cargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ShippingCost } from 'src/app/core/interfaces/shippingCost';
import { CargoDetailService } from '../../cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileComponent } from 'src/app/shared/files/file.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-external-manifest-cargo',
  templateUrl: './external-manifest-cargo.component.html',
  styleUrls: ['./external-manifest-cargo.component.scss'],
  providers: [AuthService, Global, DatePipe]
})
export class ExternalManifestCargoComponent extends ReactiveForm implements OnInit {

  @Input() cargo: Cargo;
  @ViewChildren(FileComponent) fileComponent: QueryList<FileComponent>;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    public formBuilder: FormBuilder,
    public utils: Utils,
    private cargoResources: CargoResources,
    private snackBarService: SnackBarService,
    private cargoDetail: CargoDetailService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<ExternalManifestCargoComponent>,
    public datePipe: DatePipe
  ) {
    super(
      formBuilder,
      {
        manifest: '', externalManifest: ''
      }
    );
    if (this.dialogParams && this.dialogParams.cargo) {
      this.cargo = this.dialogParams.cargo;
    }
  }

  ngOnInit() {
    this.form.get('manifest').setValidators(Validators.required);
    this.form.get('externalManifest').setValidators(Validators.required);
  }

  onSubmit() {
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('externalManifest'), 'archivo del manifiesto')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('manifest'), 'número de manifiesto')) return;
      else this.manualCreationCargoService.showMessageMissingFields();
      return;
    }
    const data = this.utils.clone(this.form.value);
    this.updateData(data);
  }

  updateData(data: Cargo) {
    this.spinner.show();
    this.cargoDetail.updateFreightForwarder({
      id: this.cargo.id,
      manifest: data.manifest,
      externalManifest: data.externalManifest
    }).subscribe(
      (success) => {
        this.spinner.hide();
        this.loadFile();
        this.snackBarService.openSnackBar('Manifiesto cargado correctamente');
        this.dialogRef.close({
          state: true
        });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error cargando el manifiesto', undefined, 'error');
      });
  }

  loadFile() {
    this.fileComponent.toArray().map((component) => {
      component.loadFileToStorage();
    });
  }

}
