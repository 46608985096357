import { initializeApp, app, auth, firestore, storage, messaging, database } from "firebase";
import { environment } from "src/environments/environment";
import { AuthService } from "../services/authentication.service";
import firebase from "firebase/app";
import { ConfigFirebase } from "../interfaces/configFirebase";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

export class FirebaseApi {
  public static root = (() => {
    const root_config = environment.listFirebaseConfig.default;
    const root_app = initializeApp(root_config, 'root');

    const root = {
      auth: auth(root_app),
      firestore: firestore(root_app),
      messaging: messaging(root_app),
      storage: storage(root_app),
      database: database(root_app)
    }
    return root;
  })();

  private static _auth: auth.Auth;
  private static _firestore: firestore.Firestore;
  private static _messaging: messaging.Messaging;
  private static _storage: storage.Storage;
  private static _database: database.Database;
  private static dynamicBucket: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public static set config(config: ConfigFirebase) {
    const app: app.App = initializeApp(config);
    this._auth = auth(app);
    this._firestore = firestore(app);
    this._messaging = messaging(app);
    this._storage = storage(app);
    this._database = database(app);
    this.updateStorageBucket(config.storageBucket);
    sessionStorage.setItem('firebaseAppConfig', btoa(JSON.stringify(config)));

    AuthService.afAuth = FirebaseApi.auth;
    AuthService.fStorage = FirebaseApi.storage;
    AuthService.fDatabase = FirebaseApi.database;
    AuthService.fMessaging = FirebaseApi.messaging;
  };

  public static get auth(): auth.Auth {
    return this._auth;
  }

  public static get firestore(): firestore.Firestore {
    return this._firestore;
  }

  public static get messaging(): messaging.Messaging {
    return this._messaging;
  }

  public static get storage(): storage.Storage {
    return this._storage;
  }

  public static get database(): database.Database {
    return this._database;
  }

  private static setupAuthService() {
    AuthService.afAuth = FirebaseApi.auth;
    AuthService.fStorage = FirebaseApi.storage;
    AuthService.fDatabase = FirebaseApi.database;
    AuthService.fMessaging = FirebaseApi.messaging;
    AuthService.fFirestore = FirebaseApi.firestore;
  }

  public static _ = (() => {
    const preloadedConfig = sessionStorage.getItem('firebaseAppConfig');
    let plain: string;
    let config: ConfigFirebase;
    if (preloadedConfig) {
      plain = atob(preloadedConfig);
      config = JSON.parse(plain);
    } else {
      config = environment.listFirebaseConfig[environment.rootNit];
    }
    if (!!config) {
      FirebaseApi.config = config;
      FirebaseApi.setupAuthService();
    }
  })();

  public static configFirebaseByCompanyId = async (companyId: string) => {
    try {
      try { await firebase.app().delete(); } catch { undefined; }
      FirebaseApi.config = environment.listFirebaseConfig[companyId];
      FirebaseApi.setupAuthService();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  // Método para actualizar dinámicamente el valor del bucket
  public static updateStorageBucket(bucket: string): void {
    FirebaseApi.dynamicBucket.next(bucket);
  }

  // Método para obtener la URL del bucket dinámico
  public static getDynamicBucket(): Observable<string> {
    return FirebaseApi.dynamicBucket.asObservable();
  }

  // Método para obtener el almacenamiento con la URL del bucket dinámico
  public static getDynamicStorage() {
    return FirebaseApi.getDynamicBucket().pipe(
      // Usa switchMap para cambiar dinámicamente el almacenamiento
      switchMap(async (bucket) => AuthService.fStorage.ref(bucket))
    );
  }

  public static close() {
    try { firebase.app().delete().then(() => 0); } catch { undefined; }
  }
}
