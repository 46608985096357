import { AddressCargo } from "./address-cargo.model";

export class Origin {

    private name: string = '';
    private municipalityCode: string = '';
    private reteica: number = 0;
    private addresses: AddressCargo[] = [];

    public getName(): string {
        return this.name;
    }

    public setName(name: string) {
        this.name = name;
    }

    public getAddresses(): AddressCargo[] {
        return this.addresses || [new AddressCargo()];
    }

    public setAddresses(addresses: AddressCargo[]) {
        this.addresses = [];
        addresses.map((object) => {
            let addressCargo = new AddressCargo();
            addressCargo = addressCargo.deserialize(object);
            this.addresses.push(addressCargo);
        });
    }

    public getMunicipalityCode(): string {
        return this.municipalityCode;
    }

    public setMunicipalityCode(municipalityCode: string) {
        this.municipalityCode = municipalityCode;
    }

    public getReteica(): number {
        return this.reteica;
    }

    public setReteica(reteica: number) {
        this.reteica = reteica;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
