import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';

@Component({
  selector: 'app-subcity-item',
  templateUrl: './subcity-item.component.html',
  styleUrls: ['./subcity-item.component.scss']
})
export class SubcityItemComponent implements OnInit {

  @Input() subcity;
  @Output() delete = new EventEmitter<string>();
  permission = Permission;
  constructor(
    private permissionRole: PermissionRole,
  ) { }

  ngOnInit() {
  }

  public goToDelete() {
    this.delete.emit(this.subcity);
  }

  get canDelete() {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.deleteSubCities
    )
  }
}
