import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from './../../../../environments/environment';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Injectable({
    providedIn: 'root'
})
export class MassCreationCargoService {


    constructor(private _http: HttpClient, private _endpointResources: Endpoints) { }

    massCreationCargo(data: FormData) {
        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.massCreationCargo,
            data
        );
    }

}
