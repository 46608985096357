<aside id="mainCollectionPointList" class="col-md-12 mb-0 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-10">
          <!-- <label class="title-component">
              <i class="fas fa-file-alt"></i>
              Términos y Condiciones
            </label> -->
        </div>
        <div class="col-md-2 header-list-options text-right mb-2" permission-display
          [module]="permission.administration.module" [functionality]="permission.administration.createTermsConditions">
          <button matTooltip="Crear Políticas para tratamiento de datos personales" mat-raised-button color="primary"
            type=" button" class="button-width-auto bg-color-primary" (click)="goToCreate()">
            <i class="fas fa-plus-circle"></i>
            <span>Crear PTDP</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container-list-body">
      <div class="w-100 mb-md-0 mb-12" *ngFor="let terms of listTerms; let i = index;">
        <app-item-legal-terms class="item-list" [terms]="terms"
          [title]='"Políticas para tratamiento de datos personales"' [ngClass]="{'terms-active': terms.state }">
        </app-item-legal-terms>
      </div>
    </div>
  </div>
</aside>