<aside id="mainCollectionPointList" class="col-md-12 mb-0 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-8">
          <label class="title-component">
            <i class="fas fa-map-marker-alt"></i>
            Listado de puntos
          </label>
        </div>
        <div class="col-md-4 header-list-options text-right">
          <mat-card-actions>
            <app-export-list [nitCompany]="authService.getUserSession().clientCompanyId"
              [typeFileExport]="global.typeFilesExport.xls" [typeList]="'puntos'"
              [idExport]="global.typesExportList.collectionPoint"></app-export-list>
          </mat-card-actions>
        </div>
      </div>
    </div>
    <div class="container-list-filter">
      <div class="row">
        <div class="input-icon col-md-6">
          <mat-form-field>
            <figure class="icon-input">
              <img src="/assets/svg/icons/icon-search.svg" alt="">
            </figure>
            <input type="text" matInput placeholder="Buscar por Finca" name="farm" [(ngModel)]="farmNameFilter">
          </mat-form-field>
        </div>
        <div class="col-md-6 align-right filter-actions">
          <mat-card-actions>
            <button mat-raised-button class="primary-secondary" color="primary" (click)="cleanFilter()">Limpiar</button>
            <button mat-raised-button class="btn-filter" color="primary" (click)="applyFilter()">Buscar</button>
          </mat-card-actions>
        </div>
      </div>
    </div>
    <div class="container-list-body" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let point of listCollectionPoints; let i = index;">
        <app-collection-point-item [point]="point" (onRefreshList)="onRefreshList()"></app-collection-point-item>
      </div>
    </div>
  </div>
</aside>