import { Directive, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '../interfaces/role';
import { User } from '../interfaces/user';
import { Global } from '../resources/global';
import { AuthService } from '../services/authentication.service';
import * as _ from 'lodash';
import { NavItem } from '../interfaces/navItem';
import { RoleService } from '../services/role.service';
import { FunctionalitiesModuleRole } from '../interfaces/functionalitiesModuleRole';
import { ModuleRole } from '../interfaces/moduleRole';
import { PermissionRole } from '../resources/permission-role';

@Directive({
    selector: '[role-access]'
})
export class RoleAccessDirective implements OnInit {

    @Input() navItem: NavItem;
    @Input() navItemChildren: NavItem;
    @Input() permissionModule: string;
    @Input() permissionName: string;
    roleUser: Role;

    constructor(
        private elementRef: ElementRef,
        private roleService: RoleService,
        private authService: AuthService,
        private global: Global,
        private renderer: Renderer2,
        private permissionRole: PermissionRole
    ) {
    }

    ngOnInit() {


    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngAfterViewInit() {

        this.roleUser = this.roleService.getRoleUser();

        this.permissionByItemNav();
    }

    permissionByItemNav() {

        this.navItem.childrens.map((children, i) => {
            if (Array.isArray(children.name)) {
                children.name.map((name, j) => {
                    if (this.permissionRole.hasPermission(this.navItem.name, name)) {
                        this.navItem.visible = true;
                        children.visible = true;
                    }
                });
            } else {
                if (this.permissionRole.hasPermission(this.navItem.name, children.name)) {
                    this.navItem.visible = true;
                    children.visible = true;
                }
            }
        });
    }

}
