import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { BoxContainerMeasureService } from './box-container-measure.service';
import { ModelCargo } from '../../resources/modelCargo';

@Component({
  selector: 'app-box-container-measure',
  templateUrl: './box-container-measure.component.html',
  styleUrls: ['./box-container-measure.component.scss'],
  providers: [AuthService, Global, BoxContainerMeasureService, ModelCargo]
})
export class BoxContainerMeasureComponent implements OnInit {

  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Input() measureContainerSelect: String;
  @Input() containers: Array<object>;

  constructor() { }

  ngOnInit() {
  }

  public selectTypeContainerSize(data) {
    this.measureContainerSelect = data;
    this.emitToParent.emit(data);
  }

}
