import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { CargoGenerateService } from 'src/app/core/services/cargoGenerate.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoGenerateValidators } from 'src/app/core/validators/cargoGenerate.validator';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { Field } from 'src/app/core/interfaces/field';
import { Dialog } from 'src/app/core/resources/dialog';
import { DateManager } from 'src/app/core/managers/date.manager';
import { AuthService } from 'src/app/core/services/authentication.service';
import { HttpParams } from '@angular/common/http';
import { emailsToNotify } from 'src/app/core/enums/siigo-config.enum';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cargo-generate-filter',
  templateUrl: './cargo-generate-filter.component.html',
  styleUrls: ['./cargo-generate-filter.component.scss'],
  providers: [CargoGenerateService, DateFormatPipe, CargoGenerateValidators]
})
export class CargoGenerateFilterComponent implements OnInit {

  public reactiveForm: ReactiveForm;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  public optionsDateFrom = {
    minDate: new Date('3-1-2022'),
    maxDate: new Date()
  };
  public optionsDateUntil = {
    minDate: new Date(),
    maxDate: new Date()
  };
  public typeFiles: CatalogItem[] = [
    /*{
      id: 'receipts',
      name: 'Comprobante de Pago'
    }*/
  ];
  dateFormSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private cargoGenerateValidators: CargoGenerateValidators,
    private cargoGenerateService: CargoGenerateService,
    private snackBarService: SnackBarService,
    private utils: Utils,
    private spinner: NgxSpinnerService,
    public dialog: Dialog,
    private authService: AuthService,
    private dateFormatPipe: DateFormatPipe,
  ) {
    this.initForm();
  }

  ngOnInit() {
  }

  private initForm(): void {
    this.reactiveForm = new ReactiveForm(
      this.formBuilder,
      this.cargoGenerateValidators.cargoGenerateModel
    );
    this.reactiveForm.setValidatorsForm(
      this.cargoGenerateValidators.cargoGenerateValidators,
      this.reactiveForm.form
    );
    this.dateFormSub = (this.reactiveForm.form as FormGroup).get('dateFrom').valueChanges.subscribe((value: Date) => {
      this.optionsDateUntil.minDate = this.utils.clone(value);
      this.optionsDateUntil.maxDate = DateManager.add(value, 7, 'days');
    });
    this.getAccountingTypes();
  }

  public onSubmit(): void {
    let params = new HttpParams();

    params = params.append('sendEmail', 'true');

    if (!this.utils.isEmpty(this.reactiveForm.form.value.dateFrom)) {
      params = params.append('dateFrom', this.dateFormatPipe.transform(this.reactiveForm.form.value.dateFrom, 'only-date-tz'));
    }

    if (!this.utils.isEmpty(this.reactiveForm.form.value.dateUntil)) {
      params = params.append('dateUntil', this.dateFormatPipe.transform(this.reactiveForm.form.value.dateUntil, 'only-date-tz'));
    }

    if (!this.utils.isEmpty(this.reactiveForm.form.value.consecutive)) {
      params = params.append('consecutive', this.reactiveForm.form.value.consecutive);
    }
    if (this.reactiveForm.form.valid) {
      if (this.isReceipts()) {
        this.dialog.openDialog(
          { title: 'Observaciones', textArea: true },
        ).then((response) => {
          this.generateFile(response.message);
        });
      } else {
        this.dialog.openDialog(
          { title: `Deseas generar el archivo: ${this.getTypeFileSelected().name}?` },
        ).then((response) => {
          this.generateFile();
        });
      }
    } else {
      this.snackBarService.openSnackBar('Faltan datos por llenar', undefined, 'alert');
    }
  }

  private resetFieldsFilter(): void {
    this.reactiveForm.form.reset();
    this.optionsDateFrom.minDate = new Date('3-1-2022');
    this.optionsDateFrom.maxDate = new Date();
    this.optionsDateUntil.minDate = new Date();
    this.optionsDateUntil.maxDate = new Date();
  }

  private generateFile(message?: string): void {
    this.spinner.show();
    let field: Field;
    if (message) {
      field = {
        observation: message
      }
    }
    this.cargoGenerateService.cargoGenerate(this.reactiveForm.form.value, field).subscribe(
      (success: { message: string }) => {
        this.spinner.hide();
        this.dialog.openDialog({
          title: `¡Generación de ${this.getTypeFileSelected().name} en proceso!`,
          description: `Este proceso puede tardar varios minutos, al finalizar se enviarán los recibos ${this.showOptionReceipts ? 'a los siguientes correos' : 'al correo registrado'} :`,
          messageList: this.showOptionReceipts ? emailsToNotify() : [],
          icon: true,
          hideBtnCancel: true
        },
          true).finally(() => this.resetFieldsFilter());
        this.reactiveForm.form.patchValue(this.cargoGenerateValidators.cargoGenerateModel);
      },
      (error) => {
        this.emitToParent.emit();
        this.spinner.hide();
        this.reactiveForm.form.patchValue(this.cargoGenerateValidators.cargoGenerateModel);
        this.snackBarService.openSnackBar('Ocurrió un error al generar el archívo', undefined, 'error');
      }
    );
  }

  public onChangeDateFrom($event) { }

  public onChangeDateUntil($event) { }

  public getAccountingTypes(): void {
    if (this.showOptionReceipts) {
      this.typeFiles = [{
        id: 'receipts',
        name: 'Comprobante de Pago'
      }];
    } else {
      this.cargoGenerateService.getAccountingTypes().subscribe(
        (succes: CatalogItem[]) => {
          if (succes && succes.length) {
            this.typeFiles = this.typeFiles.concat(succes);
          }
        },
        (error) => {
          console.error(error);
        }
      )
    }
  }

  private isReceipts(): boolean {
    return this.reactiveForm.form.get('typeFile').value === 'receipts';
  }

  private getTypeFileSelected(): CatalogItem {
    if (!this.utils.isEmpty(this.reactiveForm.form.get('typeFile').value)) {
      return this.typeFiles.filter((type) => type.id === this.reactiveForm.form.get('typeFile').value)[0];
    }
    return { id: null, name: 'Recibos de Pago' };
  }

  get showOptionReceipts() {
    return this.authService.getUserSession().clientCompanyId === environment.rootNit
  }

  ngOnDestroy() {
    if (this.dateFormSub) this.dateFormSub.unsubscribe();
  }

}