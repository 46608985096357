import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-input-attachment',
  templateUrl: './input-attachment.component.html',
  styleUrls: ['./input-attachment.component.scss']
})
export class InputAttachmentComponent {

  instanceFile: FileStorage;
  @Input() title: string;
  @Input() isDisable: boolean;
  @Input() basePath: string;
  @Input() inputFormCtrl: FormControl;
  @ViewChild('inputPicture', { static: true }) inputPicture: ElementRef;

  constructor(
    public utils: Utils,
    private angularFireStorage: AngularFireStorage,
    private spinner: NgxSpinnerService,
  ) { }

  /**
  * @param {Event} e is the event of the file to upload 
  * @description Receives a file, saves it into instanceFile and executes "uploadFileStorage" method
  */
  public setInstanceFileStorage(e: Event): void {
    if (!e || !e.target || !e.target['files'] || !e.target['files'][0]) return;
    this.instanceFile = {
      fileData: {
        file: e.target['files'][0],
        name: e.target['files'][0]['name'],
        uploaded: true,
        size: this.utils.bytesToSize(e.target['files'][0].size),
        url: null
      },
      storageData: {
        storageRef: null,
        uploadTask: null,
        uploadProgress: null
      }
    };
    this.uploadFileStorage();
  }

  /**
  * @description Uploads the file into firebase storage and updates the inputFormControl with the filepath
  */
  private uploadFileStorage(): void {
    this.spinner.show();
    const filePath = this.basePath + this.instanceFile.fileData.name;
    this.instanceFile.storageData.storageRef = this.angularFireStorage.ref(filePath);
    this.instanceFile.storageData.uploadTask = this.angularFireStorage.upload(filePath, this.instanceFile.fileData.file);
    this.instanceFile.storageData.uploadTask.then(
      (data) => {
        this.inputFormCtrl.setValue(filePath);
        this.spinner.hide();
      },
      (error) => {
        this.inputFormCtrl.setValue(filePath);
        this.spinner.hide();
      }
    );
  }

  /**
  * @description Makes a click in the picture's input to open the 
  */
  public openFile() {
    this.inputPicture.nativeElement.click();
  }

}
