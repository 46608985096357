import { Component, OnInit, Input, Inject, SimpleChange, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Global } from 'src/app/core/resources/global';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Utils } from 'src/app/core/resources/utils';
import { AdditionalCost } from 'src/app/core/interfaces/additionalCost';
import { AdditionalCostCargo } from 'src/app/core/interfaces/additionalCostCargo';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { DataDialogCargoAdditionalService } from 'src/app/core/interfaces/dataDialogCargoAdditionalService';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { AdditionalServiceService } from './additional-service.service';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
@Component({
  selector: 'app-additional-service',
  templateUrl: './additional-service.component.html',
  styleUrls: ['./additional-service.component.scss'],
  providers: [Global]
})
export class AdditionalServiceComponent implements OnInit {
  selectedService = {};
  suggestionMessage: string = '';
  @Input() cargo: Cargo;
  @Input() cargoId: string;
  @Input() public listAdditionalService: AdditionalCost[] = [];
  @Input() public modelForm: AdditionalCostCargo[];
  @Input() public readonly: boolean;
  @Input() public companyId: string;
  @Input() public approvalState: string;
  @Input() public listActive: string = '';
  @Output() additionalCostSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() emitToParent: EventEmitter<boolean> = new EventEmitter();
  public form: FormArray;
  private reactiveForm: ReactiveForm;

  constructor(
    public utils: Utils,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: DataDialogCargoAdditionalService,
    private snackBarService: SnackBarService,
    public authService: AuthService,
    private permissionRole: PermissionRole,
    public manualCreationCargoService: ManualCreationCargoService,
    private additionalServiceService: AdditionalServiceService,
  ) {
    this.reactiveForm = new ReactiveForm(
      this.formBuilder,
      []
    );
    this.createForm();
    this.additionalServiceService.editAdditionalCostItem.subscribe(
      (response) => {
        for (const i in this.modelForm) {
          if (this.modelForm[i].id == response.serviceId) {
            this.modelForm[i].financialInformation.cost = response.cost;
            this.modelForm[i].financialInformation.rate = response.rate;
          }
        }
        this.emitToParent.emit(true);
      }
    );
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.modelForm && !this.utils.isEmpty(changes.modelForm.currentValue)) {
      this.createForm();
    }
    if (changes && changes.listAdditionalService && !this.utils.isEmpty(changes.listAdditionalService.currentValue)) {
      this.listAdditionalService = changes.listAdditionalService.currentValue;
    }
  }

  async createForm() {
    const modelForm = this.modelForm && this.modelForm.length ? this.modelForm : [];
    let reactiveForm: ReactiveForm = new ReactiveForm(
      this.formBuilder,
      modelForm
    );
    this.form = reactiveForm.form;
    this.validateParamsDialog();
    if (this.readonly) {
      this.form.disable();
    }
  }

  validateParamsDialog() {
    if (!this.utils.isEmpty(this.paramsDialog) && !this.utils.isEmpty(this.paramsDialog.listAdditionalService)) {
      this.listAdditionalService = this.utils.clone(this.paramsDialog.listAdditionalService);
    }
    if (!this.utils.isEmpty(this.paramsDialog) && !this.utils.isEmpty(this.paramsDialog.readonly)) {
      this.readonly = this.utils.clone(this.paramsDialog.readonly);
    }
  }

  createAdditionalCostItem($event) {
    if (this.approvalState && this.approvalState === 'Approved') {
      this.manualCreationCargoService.showMessageNotAllowedCreateAdditionalService();
      return;
    }
    this.selectedService = {};
    const dateNow = DateManager.dateToString(new Date(), 'YYYY-MM-DD HH:mm ZZ');
    const additionalCostSelected: AdditionalCost = $event.value;
    this.additionalCostSelected.emit(true);
    if (this.utils.isDefined(additionalCostSelected.billingTaxes) && additionalCostSelected.billingTaxes.length) {
      additionalCostSelected.billingTaxes = additionalCostSelected.billingTaxes;
      const additionalCost: AdditionalCostCargo = {
        type: additionalCostSelected,
        id: '',
        financialInformation: {
          cost: 0,
          rate: 0
        },
        fingerPrint: {
          userId: '',
          userName: '',
          date: ''
        }
      };
      switch (additionalCostSelected.name) {
        case 'Servicios de escolta':
          additionalCost.observation = 'Electrónico';
          break;
        case 'Servicio descargue':
          additionalCost.toPay = {
            code: 'D',
            description: 'DESTINATARIO'
          }
          break;
        case 'Servicio cargue':
          additionalCost.toPay = {
            code: 'R',
            description: 'REMITENTE'
          }
          break;
        case 'Stand by':
          this.canAttachService ?
            additionalCost.toPay = {
              code: 'C',
              description: 'CONDUCTOR'
            }
            :
            additionalCost.toPay = {
              code: 'R',
              description: 'REMITENTE'
            };
          break;
      }
      additionalCost.fingerPrint.userId = this.authService.getUserSession().information.document;
      additionalCost.fingerPrint.userName = this.authService.getUserSession().information.name;
      additionalCost.fingerPrint.date = dateNow;
      this.reactiveForm.pushGroup(additionalCost, this.form);
      this.addValidatorsForm(this.form.controls.length - 1);
    } else {
      this.snackBarService.openSnackBar('El servicio adicional no se puede agregar debido a que no tiene impuestos registrados', undefined, 'alert');
    }
  }

  removeAdditionalCostItem(index: number) {

    const valueFormControl = this.form.at(index);
    const indexCtrlSelected: number = this.listAdditionalService.findIndex((additionalCost: AdditionalCost) => {
      return additionalCost.name === valueFormControl.value.type.name;
    });
    this.additionalCostSelected.emit(false);

    if (indexCtrlSelected >= 0) {
      this.listAdditionalService[indexCtrlSelected].disabled = false;
    }
    this.reactiveForm.removeAt(index, this.form);
  }

  editAdditionalCostItem(event) {
    if (event) {
      this.createForm();
    }
  }


  showAllAdditionalService() {
    this.listAdditionalService.forEach((additionalService) => {
      this.createAdditionalCostItem({ value: { name: additionalService.name } });
    });
  }

  addValidatorsForm(index: number) {
    this.form.at(index).get('financialInformation.rate').setValidators([
      Validators.required,
      Validators.min(0)
    ]);
  }

  public get canAttachService() {
    const hasPermission = this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.additionalCostCreate);

    if (this.listActive && this.listActive === 'serviceRequests') return hasPermission;
    if (!this.paramsDialog || !this.paramsDialog.cargo) {
      return true;
    }
    const notInvoiced =
      !!(!this.paramsDialog.cargo.billIdSiigo
        && this.paramsDialog.cargo.shippingCost
        && !this.paramsDialog.cargo.shippingCost.billId
        && !this.paramsDialog.cargo.shippingCost.cashed);

    if (notInvoiced) {
      return hasPermission;
    } else if (
      this.paramsDialog.cargo.shippingCost
      && (this.paramsDialog.cargo.shippingCost.paid
        || (this.paramsDialog.cargo.billIdSiigo
          && this.paramsDialog.cargo.shippingCost.billId
          && this.paramsDialog.cargo.shippingCost.cashed)
        || this.paramsDialog.cargo.approval === "Approved")) {
      return this.permissionRole.hasPermission(Permission.payments.module, Permission.payments.addAdditionalServiceCargoPaid) || this.permissionRole.hasPermission(Permission.payments.module, Permission.payments.addAdditionalServiceCargoCashed) || this.permissionRole.hasPermission(Permission.payments.module, Permission.payments.addAdditionalServiceCargoApproved);
    } else
      return notInvoiced;

  }

  public get cargoApprovalValue(): string {
    let value = '';
    if (this.paramsDialog.cargo && this.paramsDialog.cargo.approval) {
      value = this.paramsDialog.cargo.approval;
    }
    return value;
  };

  public get additionalServiceSuggestion(): boolean {
    if (this.manualCreationCargoService && this.manualCreationCargoService.formDestinations && !this.paramsDialog.cargo) {
      let sum = this.manualCreationCargoService.formDestinations.value.reduce((citySum, city) => {
        return citySum + city.addresses.reduce((accumulated, address) => accumulated + address.cargoMeasure.amount, 0)
      }, 0);
      if (sum) {
        if (sum >= AmountsCargoEnum.MEDIUM_END) {
          this.suggestionMessage = "Se recomienda hacer uso de dos escoltas y candado satelital";
          return !(this.form && this.form.length
            && this.form.value.reduce((acc, addServ) => { if (addServ.type.name === "Servicios de escolta") { return acc + 1 } else { return acc } }, 0) >= 2
            && this.form.value.some(addServ => addServ.type.name === "Sello satelital"))
        } else if (sum >= AmountsCargoEnum.LOW_END) {
          this.suggestionMessage = "Se recomienda hacer uso de escolta y candado satelital";
          return !(this.form && this.form.length
            && this.form.value.some(addServ => addServ.type.name === "Servicios de escolta")
            && this.form.value.some(addServ => addServ.type.name === "Sello satelital"))
        } else {
          this.suggestionMessage = "Se recomienda hacer uso de candado satelital";
          return !(this.form && this.form.length
            && this.form.value.some(addServ => addServ.type.name === "Sello satelital"))
        }
      }
      return false;
    }
    return false;
  }

  public get emptyDialog(): boolean {
    return this.paramsDialog ? Object.keys(this.paramsDialog).length === 0 : true;
  }

}


