import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss']
})
export class InactivityModalComponent{
  secondsInactive = 150;
  math= Math;
  onClick$;
  interval;

  constructor(
    public dialogRef: MatDialogRef<InactivityModalComponent>,
    ) {
      this.onClick$= fromEvent(document,'mousedown').subscribe(()=>{
        this.closeDialog();
      });
      this.interval = setInterval(()=>{
        this.secondsInactive--;
        this.secondsInactive===0&&this.reloadPage();
      },1000);
    }


  reloadPage(){
    window.location.reload();
  }
  closeDialog() {
    clearInterval(this.interval);
    this.dialogRef.close();
  }

}
