<main>
  <section class="header">
    <img class="logotype" src="./assets/images/bascPolicy/INNOVBO.png">
    <h5 class="title">
      <strong>Tecnología que transforma la logística</strong>
    </h5>
    <img class="logotype" src="./assets/images/bascPolicy/super_transporte.png">
  </section>
  <section class="container">
    <h4 style="text-align: center;">POLÍTICAS QUE HACEN PARTE DE LOS SISTEMAS DE GESTIÓN EN INNOVBO S.A.S.</h4>
    <p>
      A continuación se relacionan las políticas que hacen parte de los Sistemas de Gestión
      implementados en <strong>INNOVBO S.A.S.</strong>. Si las requieres y deseas conocerlas, te
      invitamos a comunicarte con el Sistema de Gestión de nuestra compañía:
    </p>

    <ol>
      <li>
        <strong>SGCS BASC:</strong>
        <ul>
          <li>SG01-PO01 Política de Gestión en Control y Seguridad.</li>
          <li>SG01-PO18 Política de Responsabilidad Social Empresarial.</li>
          <li>SG01-PO12 Política de Seguridad de la Información.</li>
        </ul>
      </li>
      <li>
        <strong>PESV:</strong>
        <ul>
          <li>SG01-PO02 Política de Seguridad Vial.</li>
          <li>PG06-PO01 Política de Prevención de la Distracción.</li>
          <li>PG06-PO02 Política de Prevención de la Fatiga.</li>
          <li>PG06-PO03 Política de Gestión de la Velocidad.</li>
          <li>PG06-PO04 Política de Protección de Actores Viales Vulnerables.</li>
        </ul>
      </li>
      <li>
        <strong>SG SST:</strong>
        <ul>
          <li>SG01-PO07 Política de Seguridad y Salud en el Trabajo.</li>
          <li>SG01-PO03 Política Prev Consumo A,T,J, y Otras Adicciones.</li>
        </ul>
      </li>
      <li>
        <strong>SIPLAFT-PADM:</strong>
        <ul>
          <li>SG01-PO09 Política Siplaft-PADM.</li>
          <li>SG01-PO10 Política Anticorrupción y Antisoborno.</li>
          <li>SG01-PO11 Política Anticontrabando.</li>
          <li>SG01-PO15 Política Prevención Contra Conspiraciones Internas.</li>
        </ul>
      </li>
      <li>
        <strong>CONTINUIDAD DE NEGOCIO</strong>
        <ul>
          <li>SG01-PO08 Política de Continuidad de Negocio.</li>
          <li>SG01-PO14 Política de Crisis.</li>
        </ul>
      </li>
      <li>
        <strong>AMBIENTAL</strong>
        <ul>
          <li>SG01-PO16 Política Ambiental.</li>
        </ul>
      </li>
    </ol>
    <br>
    <p>
      Estas políticas deben ser entendidas y cumplidas por parte de todo el personal de INNOVBO S.A.S. y sus contratistas, estará a disposición de cualquier parte interesada que muestre interés por
      ella.
      Cordialmente,
    </p>
    <br>
    <strong style="text-align: center;">CEO</strong>
    <br>
    <strong style="text-align: center;">INNOVBO S.A.S.</strong>
  </section>
</main>