import { Component } from '@angular/core';

@Component({
    selector: 'app-personal-data-policies',
    templateUrl: './personal-data-policies.component.html',
    styleUrls: ['./personal-data-policies.component.scss']
})

export class PersonaDataPoliciesComponent {
  
}