import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { VehiclesService } from '../list-vehicles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';

@Component({
  selector: 'app-item-vehicle',
  templateUrl: './item-vehicle.component.html',
  styleUrls: ['./item-vehicle.component.scss'],
  providers: [AuthService]
})
export class ItemVehicleComponent {
  permission = Permission;
  @Input() vehicle: Vehicle;
  @Input() stateAccount: string;
  @Output() onSelectItem: EventEmitter<any> = new EventEmitter();
  isSelected = false;

  constructor(
    public vehiclesService: VehiclesService,
    public utils: Utils,
    private router: Router,
    private permissionRole: PermissionRole,
  ) { }

  /**
  * @returns {Event} returns true if the vehicle has bank's state account pending to validate, false otherwise
  * @description Checks if the vehicle has bank's state account pending to validate
  */
  get showAlertBank(): boolean {
    return !this.vehiclesService.getActiveItem(this.stateAccount, this.vehicle) &&
      (this.stateAccount === '4' || this.stateAccount === '5' || this.stateAccount === '6');
  }

  /**
  * @description Saves the current vehicle on vehiclesService.vehicleSelected and navigates to vehicle detail
  */
  openDetail(): void {
    this.vehiclesService.vehicleSelected = this.vehicle;
    this.router.navigate(['administration/vehicles/detail', this.vehicle.id]);
  }

  /**
  * @returns {{"border-left": string}} returns the color of the border depending the vehicle's bank accounts state
  * @description Gets a border-color depending the vehicle's bank accounts state.
  */
  semaphoreState(): { "border-left": string } {
    const isApprovedAll: boolean = this.vehicle.bankAccountAdvance && this.vehicle.bankAccountAdvance.state
      && this.vehicle.bankAccountAdvance.state.active && this.vehicle.bankAccountBalance
      && this.vehicle.bankAccountBalance.state && this.vehicle.bankAccountBalance.state.active;
    const advanceState = this.vehicle.bankAccountAdvance;
    const balanceState = this.vehicle.bankAccountBalance;
    return { "border-left": `10px solid ${isApprovedAll ? 'green' : advanceState ? (balanceState ? 'blue' : 'yellow') : (balanceState ? 'orange' : 'red')}` };
  }

  /**
  * @description If the user has permission to select vehicles, can select this vehicle and emit it to parent.
  */
  selectVehicle(): void {
    if (this.canSelectVehicles) {
      this.isSelected = !this.isSelected;
      this.onSelectItem.emit({ vehicle: this.vehicle, selected: this.isSelected });
    }
  }

  /**
  * @returns {boolean} returns true if the user has the permission "downloadFileRegistreBank", false otherwise
  * @description Checks if the user has the permission "downloadFileRegistreBank".
  */
  get canSelectVehicles(): boolean {
    return this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.downloadFileRegistreBank);
  }

  /**
  * @returns {boolean} returns the state of the vehicle and the vehicle's stateCompany (if it's defined)
  * @description Checks the vehicle's state and vehicle's stateCompany (if it's defined)
  */
  get stateVehicle(): boolean {
    if (!this.vehicle || !this.vehicle.state || !this.utils.isDefined(this.vehicle.state.active)) return false;

    if (this.vehicle && this.vehicle.stateCompany && this.utils.isDefined(this.vehicle.stateCompany.active))
      return this.vehicle.state.active && this.vehicle.stateCompany.active;
    return this.vehicle.state.active;

  }
}
