import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { of } from 'rxjs';
import { filter, catchError, tap, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import * as _ from "lodash";
declare var google: any;

@Injectable()
export class GeocodeService {
    private geocoder: google.maps.Geocoder = new google.maps.Geocoder();;

    constructor(private mapLoader: MapsAPILoader) {

    }

    private waitForMapsToLoad(): Observable<boolean> {
        if (!this.geocoder) {
            return from(this.mapLoader.load())
                .pipe(
                    tap(() => this.geocoder),
                    map(() => true)
                );
        }
        return of(true);
    }

    async geocodeAddress(location: any, type?: string) {
        let data = {};
        if (!type) {
            type = "address";
        }
        data[type] = location;

        return await this.waitForMapsToLoad().pipe(
            switchMap(() => {
                return new Observable(observer => {
                    this.geocoder.geocode(data, (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            if (type === "address") {
                                observer.next({
                                    lat: results[0].geometry.location.lat(),
                                    lng: results[0].geometry.location.lng(),
                                    results: results
                                });
                            } else {
                                observer.next({
                                    formatted_address: results[0].formatted_address,
                                    lat: results[0].geometry.location.lat(),
                                    lng: results[0].geometry.location.lng(),
                                    results: results
                                });
                            }
                        } else {
                            observer.next({
                                lat: 0,
                                lng: 0,
                                results: []
                            });
                        }
                        observer.complete();
                    });
                })
            })
        )
    }

    getAddress(location: google.maps.LatLngLiteral): Observable<unknown> {
        return new Observable((observer) => {
            this.geocoder.geocode({ location }, (results: google.maps.GeocoderResult[]) => {
                observer.next(results);
                observer.complete();
            })
        })
    }
}