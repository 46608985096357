import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent, MatStepper } from '@angular/material';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-routing-basic-form',
  templateUrl: './routing-basic-form.component.html',
  styleUrls: ['./routing-basic-form.component.scss'],
})
export class RoutingBasicFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() stepper: MatStepper;
  dateCtrl = new FormControl('', Validators.required);
  minDate = new Date();

  constructor(
    public utils: Utils,
    public snackBarService: SnackBarService,
  ) { }

  /**
  * @description Verifies if the date exists in the form to update the local variable "dateCtrl"
  */
  ngOnInit(): void {
    if (!!this.form.get('date').value) this.dateCtrl.setValue(DateManager.stringToDate(this.form.get('date').value));
  }

  /**
  * @param {MatDatepickerInputEvent<Date>} $event is the event with the date selected
  * @description Updates the form's control "date" with the selected date in the format expected
  */
  onChangeDate($event: MatDatepickerInputEvent<Date>): void {
    if ($event) this.form.get('date').setValue(DateManager.dateToString($event.value, 'YYYY-MM-DD'));
  }

  /**
  * @description Verifies if the form's controls "description" and "date" are valid to allow pass to next step
  */
  onSubmit(): void {
    if (this.utils.errorMessagesCustomized(this.form.get('description'), 'nombre', 3)) return;
    if (this.form.get('description').value.trim().length < 3) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MIN_LENGTH_NOT_REACHED, 'nombre', '3'), undefined, 'alert');
      return;
    }
    if (this.utils.errorMessagesCustomized(this.form.get('date'), 'fecha')) return;
    this.stepper.next();
  }

  /**
  * @returns {boolean} returns true if the form's controls "description" and "date" are filled and valid.
  * @description Verifies if the current step is valid
  */
  get isValidStep(): boolean {
    return this.form && this.form.get('description') && this.form.get('description').valid && this.form.get('description').value.trim().length >= 3
      && this.form.get('date') && this.form.get('date').valid;
  }
}
