<div class="main-container">
  <article *ngIf="listCargos.length>1">
    <div class="title-component">Iniciarón las siguientes cargas</div>
    <div class="table-container">
      <table mat-table [dataSource]="listCargos">
        <ng-container matColumnDef="consecutive">
          <th mat-header-cell *matHeaderCellDef> Consecutivo </th>
          <td mat-cell *matCellDef="let element"> {{element.consecutive}} </td>
        </ng-container>
        <ng-container matColumnDef="licensePlate">
          <th mat-header-cell *matHeaderCellDef> Placa </th>
          <td mat-cell *matCellDef="let element"> {{element.licensePlate}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </article>
  <section *ngIf="listCargos.length==1">
    <article class="center title-component" *ngFor="let element of listCargos">
      Inició la carga {{element.consecutive}} con placa {{element.licensePlate}}
    </article>
  </section>
  <span class="separator-horizontal"></span>
  <mat-card-actions class="center">
    <button mat-raised-button color="primary" type="button" class="button-width-auto" (click)="confirm()">
      Aceptar
    </button>
  </mat-card-actions>
</div>