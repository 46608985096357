<form [formGroup]="form" (ngSubmit)="onSubmit()" class="py-4 px-1 px-sm-5 position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h4 class="text-center text-strong">Cargar XML</h4>
    <mat-form-field appearance="outline">
        <mat-label>Número de factura</mat-label>
        <input type="text" matInput formControlName="billId" (keyup)="changeValue()">
        <i (click)="searchBill()" matSuffix class="fas fa-search fa-lg cursor-pointer color-primary"></i>
        <mat-error *ngIf="form.get('billId').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <div *ngIf="searchedBill && getErrors" class="col-md-12 alert alert-danger" role="alert">
        {{getErrors}}
    </div>
    <div *ngIf="searchedBill && !getErrors">
        <div class="d-flex flex-column cargos-asociated-container">
            <p class="text-bold m-0">Cargas asociadas</p>
            <p *ngFor="let cargo of cargos" class="m-0">Carga {{cargo?.consecutive}}</p>
        </div>
        <div class="xml-container mt-3 cursor-pointer" (click)="openXMLInput()">
            <input type="file" id="XMLInput" class="d-none" (change)="onXMLSelected($event)" accept="text/xml">
            <div class="d-flex flex-row">
                <img class="mr-3"
                    [src]="form?.get('file')?.value?.name ? 'assets/svg/icons/icon-correct.svg' : 'assets/svg/icons/icon-upload.svg'"
                    alt="">
                <div>
                    <p class="m-0 text-bold">{{form?.get('file')?.value?.name}}</p>
                    <p class="m-0"><u>Subir XML</u></p>
                </div>
            </div>
        </div>
        <div class="w-100 mt-2 button-container">
            <button type="button" mat-raised-button class="primary-secondary" color="primary"
                (click)="dialogRef.close()">
                Cancelar
            </button>
            <button mat-raised-button class="bg-color-primary" color="primary" type="submit">
                Enviar
            </button>
        </div>
    </div>
</form>