import { Pipe, PipeTransform } from '@angular/core';
import { DateManager } from '../managers/date.manager';

@Pipe({
    name: 'millisecondsDigitalClock'
})
export class MillisecondsDigitalClockPipe implements PipeTransform {

    constructor() { }

    transform(ms) {
        if (!ms) return '0';
        let data = '';
        let duration = DateManager.durationFormat(ms, 'milliseconds');
        if (duration.days) data += duration.days + ' d ';
        if (duration.hours) data += duration.hours + ' h ';
        if (duration.minutes) data += duration.minutes + ' m ';
        if (duration.seconds) data += duration.seconds + ' s ';
        return data;
    }

}