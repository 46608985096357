<div class="row-module-item">
    <div class="row">
        <div class="col-md-4 text-bold label-field">
            <span>
                {{city.name}}
            </span>
        </div>
        <div class="col-md-4 info-city" *ngIf="!isMainCity">
            <span class="color-secondary-dark text-bold underline" *ngIf="!city.mainCity && canLinkCity"
                (click)="editRteica()">
                Asociar ciudad principal
            </span>
            <span *ngIf="city.mainCity && canLinkCity" class="underline" matTooltip="Cambiar ciudad principal"
                (click)="editRteica()">
                <span class="text-bold">
                    Ciudad Principal:
                </span>
                <span>
                    {{city.mainCity.name}}
                </span>
            </span>
        </div>
        <div class="col-md-4 info-city" *ngIf="isMainCity && canLinkCity">
            <span class="text-bold">
                Esta es una ciudad principal
            </span>
        </div>
        <figure class="icon-arrow-container" (click)="goToDetailCity()">
            <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
        </figure>
    </div>
    <div class="row">
        <div class="col-md-4">
            <span class="info-city" [ngClass]="{'red-color':!reteica}">
                <span class="text-bold">
                    Reteica:
                </span>
                <span>
                    {{reteica}}
                </span>
            </span>
        </div>
    </div>
</div>