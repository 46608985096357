import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alert-cargo',
  templateUrl: './alert-cargo.component.html',
  styleUrls: ['./alert-cargo.component.scss'],
})
export class AlertCargoComponent implements OnInit {

  @Input() listCargos: Array<object>;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  listCargoAsync: Observable<any[]>;
  displayedColumns: string[] = ['consecutive', 'licensePlate'];

  constructor(
    public dialogRef: MatDialogRef<AlertCargoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      listCargos
    },
  ) {
    if (this.dialogParams && this.dialogParams.listCargos) {
      this.listCargos = this.dialogParams.listCargos;
    }
  }

  ngOnInit() { }

  public confirm() {
    this.dialogRef.close({
      state: true,
      consecutive: this.listCargos
    });
  }

}
