<div class="container">
    <div class="col-md-12 mb-md-0 mb-12">

        <!-- Grid row -->
        <div class="row">
            <div class="col-md-5">
                <label class="title-wellcome">
                    <b>TÉRMINOS Y CONDICIONES DE LA LICENCIA DE USO DE INNOVBO S.A.S.</b>
                </label>
                <div *ngIf="termsActive && termsActive.date" class="date-terms">
                    Fecha publicación: {{termsActive.date | dateFormat:'only-date'}}
                </div>
            </div>
            <div class="col-md-7">

                <div class="row" *ngIf="termsActive">
                    <div [innerHTML]="termsActive.html">
                    </div>
                </div>

                <div class="row" *ngIf="!termsActive">
                    <div class="c9">
                        <!-- !INTRODUCIR EL CODIGO MODIFICADO ACA -->
                        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">T&Eacute;RMINOS
                                Y CONDICIONES DE LA LICENCIA DE USO DE INNVOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.8909988403320312pt;margin-right: 0.9366455078125pt;text-indent: 0.47299957275390625pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Entre
                                los suscritos, INNVOVBA S.A.S., con NIT 901.331.232-0 (en adelante
                                &ldquo;Teclogi&rdquo;), propietaria de la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">aplicaci&oacute;n
                                m&oacute;vil Teclogi Conductores (en adelante la &ldquo;Aplicaci&oacute;n&rdquo;) y los
                                Usuarios, ya sean personas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">naturales
                                o jur&iacute;dicas con capacidad legal para contratar, que han completado el formulario
                                de registro</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">en
                                la Aplicaci&oacute;n, se acuerdan los siguientes T&eacute;rminos y Condiciones de
                                acuerdo al art&iacute;culo 1602 del</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">C&oacute;digo
                                Civil Colombiano, los cuales son obligatorios para todas las partes:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3639984130859375pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.
                                DEFINICIONES</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8470001220703125pt;margin-right: 0.9403076171875pt;text-indent: 0.197998046875pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conforme
                                se usan en estos T&eacute;rminos y Condiciones, los siguientes t&eacute;rminos
                                tendr&aacute;n el significado</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">asignado
                                a continuaci&oacute;n. Las palabras t&eacute;cnicas o cient&iacute;ficas que no se
                                encuentren definidas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">expresamente
                                en este documento tendr&aacute;n los significados que les correspondan seg&uacute;n la
                                t&eacute;cnica o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ciencia
                                respectiva, y las dem&aacute;s palabras se entender&aacute;n en su sentido natural y
                                obvio, seg&uacute;n el uso</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">general
                                de las mismas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 3.8909988403320312pt;margin-top:15.417510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                LICENCIANTE</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                INNVOVBA S.A.S. como propietario de todos los derechos del SOFTWARE.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.221000671386719pt;margin-right: 0.99566650390625pt;text-indent: -17.68099594116211pt;text-align: justify;margin-top:15.662994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.68099594116211pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                LICENCIATARIO</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                la persona a la cual se le permite &uacute;nicamente el uso de la aplicaci&oacute;n,
                                quien</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">no
                                puede comercializarla, reproducirla o copiarla, solo se le permite el uso sin
                                ning&uacute;n tipo de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">exclusividad.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.957000732421875pt;margin-right: 0.8673095703125pt;text-indent: -18.242000579833984pt;margin-top:15.417510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.242000579833984pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                PARTES</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">es
                                la referencia o menci&oacute;n conjunta que se da al&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIANTE
                                y al LICENCIADO&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">los&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">USUARIOS.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.9459991455078125pt;margin-right: 0.263671875pt;text-indent: -18.010997772216797pt;text-align: justify;margin-top:15.417510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.010997772216797pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIA
                                DE USO</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                el presente contrato en virtud del cual se autoriza y regula el uso</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">permitido
                                de la&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">APLICACI&Oacute;N&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">al
                                licenciatario que constituye el l&iacute;mite y alcance del uso</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                puede hacer&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                                LICENCIATARIO&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                la&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">APLICACI&Oacute;N</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                licencia de uso podr&aacute; ser</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">revocada
                                en cualquier momento por el licenciante, lo cual se entender&aacute; como
                                una</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terminaci&oacute;n
                                de mutuo acuerdo.</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.8470001220703125pt;margin-right: 0.11126708984375pt;text-indent: -17.769001007080078pt;text-align: justify;margin-top:15.417510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.769001007080078pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">MODALIDAD
                                DE LICENCIA:&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Se
                                refiere al permiso de uso objeto de este contrato seg&uacute;n se</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">indica
                                en la Car&aacute;tula del Contrato, que puede ser&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIA
                                REMUNERADA&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIA</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TEMPORAL
                                NO REMUNERADA</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.6269989013671875pt;margin-right: 0.32391357421875pt;text-indent: -18.451000213623047pt;text-align: justify;margin-top:15.417495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.451000213623047pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIA
                                REMUNERADA:&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                aquella que se adquiere mediante un pago &uacute;nico que da</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">derecho
                                al uso de la&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">APLICACI&Oacute;N&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">durante
                                todo el tiempo que&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                                LICENCIANTE&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">tenga</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">derecho
                                a conceder su uso, con soporte t&eacute;cnico.</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.9459991455078125pt;margin-right: 0.74468994140625pt;text-indent: -18.010997772216797pt;text-align: justify;margin-top:15.417495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.010997772216797pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIA
                                NO REMUNERADA o TEMPORAL:&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                aquella mediante la cual se permite el uso</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                algunas aplicaciones o usos b&aacute;sicos de la aplicaci&oacute;n, puede ser gratuita y
                                sin soporte</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">t&eacute;cnico.</span><span
                                style="font-size:11pt;font-family:'Century Gothic',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:29.707504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:2.589872646331787;margin-left: 3pt;margin-right: 3.878662109375pt;text-indent: 1.2759971618652344pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                                Agente Comercial:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                el Usuario que se registra en la aplicaci&oacute;n para recomendar
                                Conductores.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                la presente aplicaci&oacute;n m&oacute;vil operada y administrada por
                                Teclogi.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">j</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                                Carga:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Son
                                los bienes declarados por el Generador de Carga a ser transportados.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.231998443603516pt;margin-right: 2.10784912109375pt;text-indent: -17.64800262451172pt;margin-top:2.9224853515625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.64800262451172pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">k.
                                Conductor:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                el Usuario, debidamente autorizado y habilitado, para conducir el Veh&iacute;culo en
                                el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                se transportar&aacute; la Carga del Generador de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.286998748779297pt;margin-right: 1.28692626953125pt;text-indent: -17.582000732421875pt;text-align: justify;margin-top:15.41748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.582000732421875pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">l.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Empresa
                                de Transporte:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Son
                                aquellas empresas de transporte terrestre de carga habilitadas por</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">el
                                Ministerio de Transporte para prestar el servicio de transporte a nivel nacional o
                                internacional</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                mercanc&iacute;as y quienes ser&aacute;n los responsables del transporte de la Carga
                                ante el Generador de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 4.176998138427734pt;margin-top:15.41748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">m.
                                Generador de Carga:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                el Usuario que contrata los Servicios para el transporte de su Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.275997161865234pt;margin-right: 0.98199462890625pt;text-indent: -17.41699981689453pt;margin-top:15.662994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.41699981689453pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">n.
                                Propietario:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                el Usuario a cuyo nombre se encuentra registrado el Veh&iacute;culo en el que
                                se</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transportar&aacute;
                                la Carga del Generador de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 3.93499755859375pt;margin-top:15.417510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o.
                                Servicios:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Son
                                los servicios ofrecidos por los Usuarios y Teclogi a trav&eacute;s de la
                                Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.243000030517578pt;margin-right: 0.5635986328125pt;text-indent: -17.65899658203125pt;text-align: justify;margin-top:15.662994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.65899658203125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">p.
                                Documento de Transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                entender&aacute; como tal y sin limitarse a: el manifiesto de carga,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">remesa
                                terrestre de carga, la planilla de viaje urbano, la carta de porte internacional por
                                carretera</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(CPIC),
                                manifiesto de carga internacional (MCI), declaraci&oacute;n de tr&aacute;nsito aduanero
                                nacional o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">internacional
                                (DUTA o DTAI), documento de transporte multimodal (DTM)&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">el
                                documento mediante</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">el
                                cual se encargue el transporte de las mercanc&iacute;as a nivel nacional o
                                internacional.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.9790000915527344pt;margin-right: 0.61962890625pt;text-indent: -17.94499969482422pt;margin-top:15.417510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.94499969482422pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">q.
                                Usuario:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                cualquier persona natural o jur&iacute;dica con capacidad legal para contratar, que
                                ha</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">completado
                                el formulario de registro en la Aplicaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 4.221000671386719pt;margin-top:15.417510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">r.
                                Teclogi:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                la sociedad INNVOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.747997283935547pt;margin-right: 0.64739990234375pt;text-indent: -18.110000610351562pt;text-align: justify;margin-top:15.662994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.110000610351562pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">s.
                                Veh&iacute;culo:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                bien propulsado con o sin motor, que se destina al transporte de bienes o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">mercanc&iacute;as,
                                entendi&eacute;ndose para los efectos del presente documento, dentro del significado de
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">palabra
                                &ldquo;veh&iacute;culo&rdquo; y sin limitarse a: Carry, Kangoo, Minivan, Camioneta,
                                Furg&oacute;n, moto, bicicleta,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cami&oacute;n,
                                veh&iacute;culo botellero, tipo ni&ntilde;era, cama baja,&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cami&oacute;n,
                                tracto cami&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">junto
                                con su remolque</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o
                                semi remolque o trailer. El veh&iacute;culo destinado al transporte nacional de
                                mercanc&iacute;as, tambi&eacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">puede
                                estar habilitado o tener el certificado de habilitaci&oacute;n dado por el organismo
                                nacional o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">autoridad
                                competente, para prestar servicio de transporte internacional de
                                mercanc&iacute;as.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.6599998474121094pt;margin-right: 1.144775390625pt;text-indent: -18.560997009277344pt;margin-top:15.417495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.560997009277344pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">t.
                                Ruta:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">enti&eacute;ndase
                                por la v&iacute;a nacional, principal o secundaria, por la cual se transportan
                                las</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">mercanc&iacute;as
                                en un veh&iacute;culo.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:37.20750427246094pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.253997802734375pt;margin-right: 0.27520751953125pt;text-indent: -17.604000091552734pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.604000091552734pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">u.
                                P&oacute;liza de transporte o seguro de mercanc&iacute;as</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                la que ser&aacute; contratada por el GENERADOR</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DE
                                LA CARGA o la EMPRESA DE TRANSPORTE o conjuntamente por estos, quienes tiene
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">obligaci&oacute;n
                                de asegurar la mercanc&iacute;a.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.7700004577636719pt;margin-top:30.41748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.
                                DESCRIPCI&Oacute;N GENERAL</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2949363231658935;margin-left: 0.7479972839355469pt;margin-right: 0.56719970703125pt;text-indent: 0.5500030517578125pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                Aplicaci&oacute;n tiene como funci&oacute;n principal poner en contacto Generadores de
                                Carga, Empresas de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Transporte,
                                Conductores, Propietarios y Agentes Comerciales entre s&iacute;, adem&aacute;s de
                                promocionar y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ofrecer
                                la prestaci&oacute;n de Servicios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.2980003356933594pt;margin-top:14.792724609375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Lea
                                bien estos T&eacute;rminos y Condiciones antes de inscribirse en la
                                Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2949363231658935;margin-left: 1.0449981689453125pt;margin-right: 1.15606689453125pt;text-indent: 0.319000244140625pt;text-align: justify;margin-top:15.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Estos
                                T&eacute;rminos y Condiciones aplican al ofrecimiento de los Servicios a trav&eacute;s
                                de la Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cuando
                                un Usuario utilice la Aplicaci&oacute;n para ofrecer sus Servicios, su aceptaci&oacute;n
                                de estos T&eacute;rminos y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Condiciones
                                durante su uso constituye la aceptaci&oacute;n de estos T&eacute;rminos y Condiciones.
                                S&oacute;lo ser&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">posible
                                modificar estos T&eacute;rminos y Condiciones si Teclogi as&iacute; lo acepta por
                                escrito.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.9899978637695312pt;margin-right: 1.02752685546875pt;text-indent: 0.37400054931640625pt;text-align: justify;margin-top:15.542755126953125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                importante tener en cuenta que la Aplicaci&oacute;n est&aacute; destinada a ser
                                utilizada para publicar y ofrecer</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Servicios
                                de los Usuarios a trav&eacute;s de comercio electr&oacute;nico. El Usuario se obliga a
                                dar cumplimiento a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">los
                                Servicios ofrecidos y ser&aacute; el &uacute;nico responsable por el cumplimiento de los
                                mismos.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.6930007934570312pt;margin-right: 0.76751708984375pt;text-indent: 0.35199737548828125pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                                el acceso a la Aplicaci&oacute;n, se entiende que el Usuario ha le&iacute;do, comprende
                                y acepta estos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">T&eacute;rminos
                                y Condiciones para ofrecer sus Servicios. Si no los acepta, no podr&aacute; ofrecer sus
                                Servicios a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">trav&eacute;s
                                de la Aplicaci&oacute;n ni podr&aacute; inscribirse como Usuario de la misma. Las
                                modificaciones posteriores</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                                estos T&eacute;rminos y Condiciones por parte de Teclogi se notificar&aacute;n y se
                                requerir&aacute; nuevamente su</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">aceptaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.8689994812011719pt;margin-right: 0.81280517578125pt;text-indent: 0.4290008544921875pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                                Usuarios al utilizar la Aplicaci&oacute;n e inscribirse en la misma, aceptan recibir
                                informaci&oacute;n comercial</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                Teclogi para mejorar el servicio y presentar opciones y ofertas comerciales de su
                                inter&eacute;s, de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">acuerdo
                                con la Autorizaci&oacute;n de Tratamiento de Datos Personales de Teclogi
                                S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.47299957275390625pt;margin-right: 1.30035400390625pt;text-indent: 0.25299835205078125pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIANTE&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">podr&aacute;
                                libremente permitir el uso al&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIATARIO&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                actualizaciones o versiones m&aacute;s</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">recientes,
                                distintas a la versi&oacute;n cuya licencia ha sido adquirida por el&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIATARIO</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">No
                                obstante, las</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;p</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">artes
                                expresamente acuerdan que dicho permiso ser&aacute; temporal y podr&aacute; revocarse en
                                cualquier momento por el</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIANTE</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.47299957275390625pt;margin-right: 1.03021240234375pt;text-indent: 0.24200057983398438pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                estructura, organizaci&oacute;n y c&oacute;digo fuente de la APLICACI&Oacute;N son de
                                propiedad &uacute;nica y exclusiva de</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIANTE.&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                APLICACI&Oacute;N est&aacute; protegido por la ley, incluyendo entre otras, las leyes de
                                derechos de</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">autor
                                de la Rep&uacute;blica de Colombia y de otros pa&iacute;ses y por las normas previstas
                                en los tratados internacionales de</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;p</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ropiedad
                                intelectual. Este contrato no concede al&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LICENCIATARIO&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ning&uacute;n
                                derecho sobre la propiedad</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:19.45800018310547pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.5610008239746094pt;margin-right: 1.2071533203125pt;text-indent: 0.25299835205078125pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">intelectual
                                del&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">SOFTWARE</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                                y todos los derechos que no sean otorgados expresamente en este documento se
                                los</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">reserva&nbsp;</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                                LICENCIANTE</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span><span
                                style="font-size:11pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 1.2869987487792969pt;margin-right: 0.53289794921875pt;text-indent: 0.021999359130859375pt;margin-top:14.91802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">NO
                                HAGA USO DE LA APLICACI&Oacute;N TECNOL&Oacute;GICA, NI OFREZCA SUS SERVICIOS A
                                TRAV&Eacute;S</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DE
                                LA MISMA SI NO EST&Aacute; DE ACUERDO CON ESTOS T&Eacute;RMINOS Y
                                CONDICIONES</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.
                                REGISTRO EN LA APLICACI&Oacute;N TECNOL&Oacute;GICA</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.8909988403320312pt;margin-right: 0.729736328125pt;text-indent: 0.4510002136230469pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
                                ofrecer sus Servicios a trav&eacute;s de la Aplicaci&oacute;n es necesario que el
                                Usuario se registre e ingrese</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">los
                                datos solicitados en el formulario de registro. &Uacute;nicamente se podr&aacute;n
                                registrar como Usuarios,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">publicar
                                y ofrecer Servicios, personas jur&iacute;dicas, o personas naturales, mayores de edad y
                                con</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">capacidad
                                para contratar, de acuerdo con la legislaci&oacute;n colombiana aplicable al momento del
                                registro.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                responsabilidad del Usuario que los datos entregados sean reales, v&aacute;lidos,
                                exactos, vigentes y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">aut&eacute;nticos.
                                Teclogi no ser&aacute; responsable si la informaci&oacute;n resulta err&oacute;nea y no
                                permite realizar</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">correctamente
                                la publicaci&oacute;n y oferta de los Servicios ni la prestaci&oacute;n de los
                                mismos.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 0.7708740234375pt;text-indent: 0.8689994812011719pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario accede a su cuenta personal ingresando su correo electr&oacute;nico y
                                contrase&ntilde;a elegida. El</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">manejo
                                y confidencialidad de estos datos ser&aacute; de exclusiva responsabilidad del Usuario.
                                Si un tercero</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">llega
                                a acceder a una cuenta sin consentimiento del Usuario y ejecuta acciones dentro de
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n
                                o viola la confidencialidad de los datos de la cuenta, se considerar&aacute;
                                responsabilidad del</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuario.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3639984130859375pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Una
                                vez el Usuario tenga su cuenta personal creada tendr&aacute; la posibilidad de acceder a
                                los Servicios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.8689994812011719pt;margin-right: 1.06903076171875pt;text-indent: 0.4949989318847656pt;text-align: justify;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Es
                                responsabilidad del Usuario todas las operaciones efectuadas en su cuenta, puesto que el
                                acceso</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                                la misma est&aacute; restringido al ingreso y uso de su contrase&ntilde;a. El Usuario se
                                compromete a notificar</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                inmediato a Teclogi por medio escrito, cualquier uso no autorizado de su cuenta y,
                                as&iacute; como el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ingreso
                                a las mismas por parte de terceros no autorizados.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.6599960327148438pt;margin-right: 0.755859375pt;text-indent: -0.08800125122070312pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.08800125122070312pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Teclogi
                                podr&aacute; suspender de manera temporal o definitiva a los Usuarios cuyos datos no se
                                hayan</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">podido
                                confirmar, as&iacute; como aquellos que sean fraudulentos o falsos, o que hagan un uso
                                indebido de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                Aplicaci&oacute;n en violaci&oacute;n de estos T&eacute;rminos y Condiciones. En casos
                                de inhabilitaci&oacute;n, ser&aacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">suspendidas
                                todas las transacciones realizadas, sin que exista obligaci&oacute;n de resarcimiento.
                                Tambi&eacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                reserva el derecho de rechazar cualquier solicitud de inscripci&oacute;n o de cancelar
                                una inscripci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">previamente
                                aceptada, sin que ello genere alg&uacute;n derecho a indemnizaci&oacute;n o
                                compensaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:2.7261817932128904;margin-left: 0.9129981994628906pt;margin-right: 48.687896728515625pt;text-indent: 0.4290008544921875pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dependiendo
                                de su perfil, los Usuarios deber&aacute;n realizar el registro y utilizar la
                                Aplicaci&oacute;n as&iacute;:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">GENERADORES
                                DE CARGA:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:3.173004150390625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.1.
                                Registro y activaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:9.7030029296875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.96923828125pt;text-indent: 0.5279998779296875pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Registrarse
                                en la Aplicaci&oacute;n consta de dos pasos simples; el registro, donde se solicitan
                                datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b&aacute;sicos
                                del Usuario, y la activaci&oacute;n de su cuenta. Cuando es un Usuario nuevo, Teclogi
                                solicitar&aacute; que</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                adjunten documentos b&aacute;sicos del Usuario que se est&aacute;
                                registrando.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.0449981689453125pt;margin-right: 1.87091064453125pt;text-indent: -0.17600250244140625pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.17600250244140625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                                esta informaci&oacute;n, Teclogi v&aacute;lida al nuevo Usuario en distintas fuentes de
                                informaci&oacute;n previo al</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">inicio
                                de operaciones, garantizando la seguridad de los distintos Usuarios y de sus
                                operaciones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.2.
                                Nuevos usuarios</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.3639984130859375pt;margin-right: 0.77093505859375pt;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Este
                                proceso consta de una verificaci&oacute;n por correo electr&oacute;nico del
                                administrador de la cuenta de cada</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuario,
                                el cual podr&aacute; activar o rechazar al nuevo Usuario que se est&aacute;
                                registrando.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.3.
                                Creaci&oacute;n de Carga</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.4949989318847656pt;margin-right: 0.68585205078125pt;text-indent: -0.4070014953613281pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.4070014953613281pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A
                                trav&eacute;s de la Aplicaci&oacute;n un Usuario Generador de Carga podr&aacute; generar
                                una Carga con toda la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">informaci&oacute;n
                                requerida, como tipo de Carga y las ciudades de origen y destino para este env&iacute;o.
                                De</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">esta
                                forma el Usuario podr&aacute; utilizar una solicitud creada anteriormente y adaptarla a
                                su nuevo envio.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.4.
                                Obligaciones de los Generadores de Carga</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.8909988403320312pt;margin-right: 0.7203369140625pt;text-indent: -17.856998443603516pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.856998443603516pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Abstenerse de generar como Carga, a trav&eacute;s de la Aplicaci&oacute;n, aquellos
                                bienes que est&eacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">prohibidos
                                por las leyes de la Rep&uacute;blica de Colombia, o cuyo transporte terrestre
                                est&eacute; prohibido</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">en
                                el pa&iacute;s. En todo caso, Teclogi se reserva el derecho de retirar de la
                                Aplicaci&oacute;n la publicaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">como
                                Carga de aquellos bienes que considere que no cumplan con los presentes T&eacute;rminos
                                y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Condiciones,
                                que violen las normas de la Rep&uacute;blica de Colombia, o que pongan en riesgo
                                a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Teclogi
                                o a los dem&aacute;s Usuarios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.209999084472656pt;margin-right: 0.81884765625pt;text-indent: -17.48299789428711pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.48299789428711pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Acordar con la Empresa de Transporte las condiciones t&eacute;cnicas y econ&oacute;micas
                                del transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terrestre
                                de la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.923999786376953pt;margin-right: 0.99993896484375pt;text-indent: -18.275001525878906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.275001525878906pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                A pagar las penalidades que se generen por la cancelaci&oacute;n del servicio de
                                transporte terrestre de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 3.868999481201172pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                A pagar a la Empresa de Transporte por el Servicio prestado.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.9020004272460938pt;margin-right: 1.116943359375pt;text-indent: -18.143001556396484pt;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.143001556396484pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                A reportar a la Empresa de Transporte el env&iacute;o de bienes adicionales a los
                                generados como</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 3.5939979553222656pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                                Informar si la Carga requiere de alg&uacute;n cuidado o manejo especial en su transporte
                                o entrega.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 3.8470001220703125pt;margin-right: 2.0018310546875pt;text-indent: -17.691997528076172pt;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.691997528076172pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.
                                A embalar de manera adecuada la Carga con el fin de proteger la misma y para evitar
                                da&ntilde;os al</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Veh&iacute;culo,
                                Conductor y/o terceros.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.221000671386719pt;margin-right: 0.82855224609375pt;text-indent: -17.999996185302734pt;margin-top:13.917503356933594pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.999996185302734pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                                A realizar el seguimiento a la Carga y reportar a la Empresa de Transporte en el evento
                                en que la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">misma
                                no sea entregada seg&uacute;n lo acordado.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:1.2075042724609375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.221000671386719pt;margin-right: 0.84771728515625pt;text-indent: -17.647998809814453pt;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.647998809814453pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
                                el evento en que la Carga no sea recibida por el destinatario, asumir los costos y
                                gastos que</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">dicha
                                devoluci&oacute;n generen.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.5.
                                Oferta de carga a Conductores</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 1.24505615234375pt;text-indent: 0.5499992370605469pt;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cada
                                Carga generada ser&aacute; publicada para todos los Usuarios Conductores disponibles en
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n,
                                y el Usuario podr&aacute; ver en la plataforma cuando un Conductor tome su
                                Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.6.
                                Cancelaci&oacute;n del Servicio</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 0.937255859375pt;text-indent: 0.4290008544921875pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Luego
                                de que su Carga ha sido tomada por un Conductor, el Generador de Carga tiene la
                                opci&oacute;n de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cancelar
                                el Servicio justificando los motivos de dicha cancelaci&oacute;n. Al aceptar estos
                                T&eacute;rminos y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Condiciones
                                el Usuario entiende y as&iacute; lo acepta que, dependiendo del tiempo de la
                                cancelaci&oacute;n del</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Servicio,
                                estar&aacute; sujeto a una penalidad. La Aplicaci&oacute;n le informar&aacute; al
                                Generador de Carga, previo a la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contrataci&oacute;n
                                del servicio de transporte de Carga, cu&aacute;les son las penalidades que le
                                aplicar&aacute;n en caso</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                cancelar el servicio de transporte.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.7.
                                Trazabilidad</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.5939979553222656pt;margin-right: 0.82000732421875pt;text-indent: 0.7700004577636719pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Generador de Carga podr&aacute; realizar un seguimiento en tiempo real de la Carga,
                                visualizaci&oacute;n de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">fotos
                                y POD&rsquo;S, adem&aacute;s de c&aacute;lculos de tiempo estimado de entrega. Teclogi
                                tendr&aacute; la posibilidad de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">suspender
                                o habilitar esta funcionalidad sin previo aviso seg&uacute;n lo requiera la
                                operaci&oacute;n de la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">plataforma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.8.
                                Entrega y calificaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6930007934570312pt;margin-right: 0.6385498046875pt;text-indent: 0.6709976196289062pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Generador de Carga podr&aacute; realizar un reporte sobre el estado de la Carga cuando
                                esta se</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">encuentra
                                en el punto de destino. Adicionalmente, podr&aacute; calificar al Conductor por el
                                servicio de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transporte
                                de la Carga. Teclogi tendr&aacute; la posibilidad de suspender o habilitar esta
                                funcionalidad sin</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">previo
                                aviso seg&uacute;n lo requiera la operaci&oacute;n de la plataforma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.9.
                                Administraci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:2.4535637855529786;margin-left: 0.9129981994628906pt;margin-right: 35.854736328125pt;text-indent: 0.4510002136230469pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario tendr&aacute; acceso a la informaci&oacute;n y gesti&oacute;n de los
                                env&iacute;os programados y/o terminados.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.10.
                                Noticias</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.83599853515625pt;margin-right: 0.6103515625pt;text-indent: 0.5279998779296875pt;margin-top:2.6719970703125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario tendr&aacute; acceso a una secci&oacute;n de contenido variado y de
                                inter&eacute;s, adem&aacute;s de v&iacute;deos gu&iacute;a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">sobre
                                la Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EMPRESAS
                                DE TRANSPORTE:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.1.
                                Registro y activaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:16.4530029296875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.96923828125pt;text-indent: 0.5279998779296875pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Registrarse
                                en la Aplicaci&oacute;n consta de dos pasos simples, el registro, donde se solicitan
                                datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b&aacute;sicos
                                del Usuario, y la activaci&oacute;n de su cuenta. Cuando es un Usuario nuevo, Teclogi
                                solicitar&aacute; que</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                adjunten documentos b&aacute;sicos del Usuario que se est&aacute;
                                registrando.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.0449981689453125pt;margin-right: 1.87091064453125pt;text-indent: -0.17600250244140625pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.17600250244140625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                                esta informaci&oacute;n, Teclogi v&aacute;lida al nuevo Usuario en distintas fuentes de
                                informaci&oacute;n previo al</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">inicio
                                de operaciones, garantizando la seguridad de los distintos Usuarios y de sus
                                operaciones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.2.
                                Nuevos usuarios</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.3639984130859375pt;margin-right: 0.77093505859375pt;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Este
                                proceso consta de una verificaci&oacute;n por correo electr&oacute;nico del
                                administrador de la cuenta de cada</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuario,
                                el cual podr&aacute; activar o rechazar al nuevo Usuario que se est&aacute;
                                registrando.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 1.2015380859375pt;text-indent: 0.12099838256835938pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
                                registrarse en la Aplicaci&oacute;n como Empresa de Transporte deber&aacute; acreditar a
                                Teclogi que ha sido</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">habilitado
                                por el Ministerio de Transporte como transportador de carga terrestre.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.3.
                                Creaci&oacute;n de Carga</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 0.61376953125pt;text-indent: -0.7039985656738281pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.7039985656738281pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A
                                trav&eacute;s de nuestra Aplicaci&oacute;n un usuario podr&aacute; generar una Carga con
                                toda la informaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">requerida,
                                como tipo de Carga y las ciudades de origen y destino para este env&iacute;o. De esta
                                forma el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">usuario
                                podr&aacute; utilizar una solicitud creada anteriormente y adaptarla a su nuevo
                                env&iacute;o.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.8689994812011719pt;margin-right: 0.8433837890625pt;text-indent: 0.4290008544921875pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                Empresa de Transporte ser&aacute; la responsable de cualquier da&ntilde;o o perjuicio
                                que en el transporte de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                Carga sufra el Generador de Carga. La Empresa de Transporte mantendr&aacute; indemne a
                                Teclogi y/o a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cualquiera
                                de sus miembros y/o cualquiera de sus empleados, por cualquier reclamaci&oacute;n,
                                p&eacute;rdida,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">da&ntilde;o,
                                responsabilidad, costo y expensa (incluyendo costos de asesor&iacute;a jur&iacute;dica),
                                causados por</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">reclamaciones
                                o demandas que le fueren hechas por el Generador de Carga o terceros en
                                relaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">con
                                el transporte de la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.4.
                                Obligaciones de las Empresas de Transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.891002655029297pt;margin-right: 1.18572998046875pt;text-indent: -17.977996826171875pt;margin-top:17.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.977996826171875pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Abstenerse de transportar aquellos bienes que est&eacute;n prohibidos por las leyes de
                                la Rep&uacute;blica</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                Colombia, o cuyo transporte terrestre est&eacute; prohibido en el
                                pa&iacute;s.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.20999526977539pt;margin-right: 0.80792236328125pt;text-indent: -17.483001708984375pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.483001708984375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Acordar con el Generador de Carga las condiciones t&eacute;cnicas y econ&oacute;micas
                                del transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terrestre
                                de la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.923999786376953pt;margin-right: 1.2777099609375pt;text-indent: -17.768997192382812pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.768997192382812pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Acordar con el Propietario del Veh&iacute;culo las condiciones t&eacute;cnicas y
                                econ&oacute;micas del transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terrestre
                                de la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.868999481201172pt;margin-right: 1.2806396484375pt;text-indent: -18pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                A pagar las penalidades que se generen por la cancelaci&oacute;n del servicio de
                                transporte terrestre</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 18.901996612548828pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                A facturar al Generador de Carga por el Servicio prestado.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:22.4530029296875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 18.593997955322266pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                                Transportar la carga seg&uacute;n las indicaciones del Generador de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 10.82550048828125pt;text-align: right;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.
                                Entregar la Carga al destinatario de la misma seg&uacute;n lo acordado con el Generador
                                de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.220996856689453pt;margin-right: 0.7679443359375pt;text-indent: -17.65900421142578pt;margin-top:14.16302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.65900421142578pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                                Realizar el seguimiento a la Carga y reportar al Generador de Carga cualquier
                                eventualidad</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                ocurra con la misma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.220996856689453pt;margin-right: 1.2850341796875pt;text-indent: -17.824005126953125pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.824005126953125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.
                                En el evento en que la Carga sufra da&ntilde;os, responder por los mismos ante el
                                Generador de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Carga
                                y a mantener a Teclogi indemne por tal concepto.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 17.999996185302734pt;margin-right: 0.81402587890625pt;text-indent: -18.891006469726562pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.891006469726562pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">j</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                                Cerciorarse que los Veh&iacute;culos que transportan la Carga cumplan con todas las
                                normas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">aplicables
                                en Colombia para el transporte terrestre de carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.41748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.5.
                                Oferta a Conductores</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.4949989318847656pt;margin-right: 0.71063232421875pt;text-indent: 0.5499992370605469pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cada
                                Carga generada ser&aacute; publicada para todos los Usuarios Conductores disponibles en
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n.
                                El Usuario podr&aacute; ver en la plataforma cuando un Conductor tome la Carga o bien
                                podr&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">recibir
                                ofertas de la Empresa de Transporte basadas en el flete original, todo de manera
                                r&aacute;pida y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">simple.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.6.
                                Cancelaci&oacute;n de Servicio</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.98150634765625pt;text-indent: 0.4620018005371094pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Luego
                                de que su carga ha sido tomada por un conductor, el usuario tiene la opci&oacute;n de
                                cancelar el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">servicio
                                justificando sus motivos y con la claridad de que dependiendo del tiempo de la
                                cancelaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">estar&aacute;
                                sujeto a una penalidad.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.7.
                                Trazabilidad</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8909988403320312pt;margin-right: 1.1876220703125pt;text-indent: 0.47299957275390625pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario podr&aacute; realizar un seguimiento en tiempo real de la Carga,
                                visualizaci&oacute;n de fotos y POD&rsquo;S,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">adem&aacute;s
                                de c&aacute;lculos de tiempo estimado de entrega. Teclogi tendr&aacute; la posibilidad
                                de suspender o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">habilitar
                                esta funcionalidad sin previo aviso seg&uacute;n lo requiera la operaci&oacute;n de la
                                plataforma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.8.
                                Entrega y calificaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6930007934570312pt;margin-right: 0.6385498046875pt;text-indent: 0.6709976196289062pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Generador de Carga podr&aacute; realizar un reporte sobre el estado de la Carga cuando
                                &eacute;sta se</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">encuentra
                                en el punto de destino. Adicionalmente, podr&aacute; calificar al Conductor por el
                                servicio de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transporte
                                de la Carga. Teclogi tendr&aacute; la posibilidad de suspender o habilitar esta
                                funcionalidad sin</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">previo
                                aviso seg&uacute;n lo requiera la operaci&oacute;n de la plataforma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.9.
                                Administraci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:2.4535637855529786;margin-left: 0.9129981994628906pt;margin-right: 35.854736328125pt;text-indent: 0.4510002136230469pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario tendr&aacute; acceso a la informaci&oacute;n y gesti&oacute;n de los
                                env&iacute;os programados y/o terminados.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.10.
                                Noticias</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:15.461997985839844pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.83599853515625pt;margin-right: 0.6103515625pt;text-indent: 0.5279998779296875pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario tendr&aacute; acceso a una secci&oacute;n de contenido variado y de
                                inter&eacute;s, adem&aacute;s de v&iacute;deos gu&iacute;a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">sobre
                                la Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CONDUCTORES:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.1.
                                Registro y activaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.96923828125pt;text-indent: 0.5279998779296875pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Registrarse
                                en la Aplicaci&oacute;n consta de dos pasos simples; el registro, donde se solicitan
                                datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b&aacute;sicos
                                del Usuario, y la activaci&oacute;n de su cuenta. Cuando es un Usuario nuevo, Teclogi
                                solicitar&aacute; que</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                adjunten documentos b&aacute;sicos del Usuario que se est&aacute;
                                registrando.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.0449981689453125pt;margin-right: 1.87091064453125pt;text-indent: -0.17600250244140625pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.17600250244140625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                                esta informaci&oacute;n, Teclogi v&aacute;lida al nuevo Usuario en distintas fuentes de
                                informaci&oacute;n previo al</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">inicio
                                de operaciones, garantizando la seguridad de los distintos Usuarios y de sus
                                operaciones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 1.30645751953125pt;text-indent: 0.4949989318847656pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Conductor que desee registrarse en la Aplicaci&oacute;n deber&aacute; tener vigente la
                                licencia de conducci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">debidamente
                                emitida por la autoridad competente que lo habilite a conducir los
                                Veh&iacute;culos.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.2.
                                Ofertas de Carga</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8799972534179688pt;margin-right: 0.81622314453125pt;text-indent: 0.48400115966796875pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario Conductor podr&aacute; visualizar el listado de Cargas disponibles en el
                                pa&iacute;s y aceptar la Carga</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                prefiera, con la posibilidad de negociar el costo de sus Servicios de manera segura con
                                el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Propietario.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.3.
                                Obligaciones del Conductor</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.891002655029297pt;margin-right: 1.18572998046875pt;text-indent: -17.977996826171875pt;margin-top:17.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.977996826171875pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Abstenerse de transportar aquellos bienes que est&eacute;n prohibidos por las leyes de
                                la Rep&uacute;blica</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                Colombia, o cuyo transporte terrestre est&eacute; prohibido en el
                                pa&iacute;s.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.20999526977539pt;margin-right: 0.9366455078125pt;text-indent: -17.483001708984375pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.483001708984375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Pagar a favor de TECLOGI el arrendamiento o por el uso de
                                &ldquo;aplicaci&oacute;n&rdquo; tecnol&oacute;gica, el cual</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                causar&aacute; por cada servicio de transporte ejecutado por cuenta de una empresa
                                de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transporte
                                de carga habilitada y se calcular&aacute; sobre el valor de cada documento de
                                transporte.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.923999786376953pt;margin-right: 0.8551025390625pt;text-indent: -18.121002197265625pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.121002197265625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Acordar con el Propietario las condiciones t&eacute;cnicas y econ&oacute;micas del
                                transporte terrestre de la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.868999481201172pt;margin-right: 1.8701171875pt;text-indent: -17.823997497558594pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.823997497558594pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Cumplir con todas las normas de tr&aacute;nsito y dem&aacute;s normas aplicables en la
                                materia para el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transporte
                                terrestre de carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.901996612548828pt;margin-right: 0.76300048828125pt;text-indent: -17.791000366210938pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.791000366210938pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                Estar habilitado por la autoridad competente para conducir el veh&iacute;culo en el que
                                se</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transportar&aacute;
                                la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.593997955322266pt;margin-right: 0.98583984375pt;text-indent: -18.0989990234375pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.0989990234375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                                Mantener indemne a Teclogi y dem&aacute;s terceros por cualquier da&ntilde;o que le
                                cause a la Carga, a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terceros
                                o a bienes de terceros.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:31.207504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.846996307373047pt;margin-right: 1.2806396484375pt;text-indent: -18.022003173828125pt;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.022003173828125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.
                                A pagar las penalidades que se generen por la cancelaci&oacute;n del servicio de
                                transporte terrestre</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                la carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 19.220996856689453pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                                A cobrar al Propietario por el Servicio prestado.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 19.220996856689453pt;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.
                                Transportar la carga seg&uacute;n las indicaciones del Generador de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 10.82550048828125pt;text-align: right;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">j</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                                Entregar la Carga al destinatario de la misma seg&uacute;n lo acordado con el Generador
                                de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.220996856689453pt;margin-right: 1.25482177734375pt;text-indent: -18pt;margin-top:14.16302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">k.
                                Reportar al Propietario y al Generador de Carga cualquier eventualidad que ocurra con
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">misma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.19900131225586pt;margin-right: 2.21142578125pt;text-indent: -17.878997802734375pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.878997802734375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">l.
                                En el evento en que la Carga sufra da&ntilde;os por su culpa, responder por los mismos
                                ante el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Generador
                                de Carga y a mantener a Teclogi indemne por tal concepto.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.4.
                                Cancelaci&oacute;n del Servicio</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.4949989318847656pt;margin-right: 1.144775390625pt;text-indent: 0.8030014038085938pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Luego
                                de aceptar la Carga el Conductor tiene la opci&oacute;n de cancelar el Servicio
                                justificando sus</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">motivos
                                de dicha cancelaci&oacute;n. Al aceptar estos T&eacute;rminos y Condiciones el Usuario
                                entiende y as&iacute; lo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">acepta
                                que dependiendo del tiempo de la cancelaci&oacute;n del Servicio estar&aacute; sujeto a
                                una penalidad. La</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n
                                le informar&aacute; al Conductor, previo a la aceptaci&oacute;n del servicio de
                                transporte de Carga,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cu&aacute;les
                                son las penalidades que le aplicar&aacute;n en caso de cancelar el servicio de
                                transporte.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.5.
                                Transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.4949989318847656pt;margin-right: 1.914794921875pt;text-indent: -0.25299835205078125pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.25299835205078125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Al
                                iniciar el servicio de transporte el Conductor podr&aacute; ver la ruta de su viaje
                                trazada en la Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Teclogi
                                puede ofrecer al Conductor un acompa&ntilde;amiento, con la posibilidad de reportar
                                novedades,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">comunicaci&oacute;n
                                con la l&iacute;nea 123 y contacto con el Generador de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.417510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.6.
                                Entrega y calificaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 1.08746337890625pt;text-indent: 0.4949989318847656pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Conductor podr&aacute; reportar el estado de la carga transportada cuando se encuentra
                                en el punto de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">destino,
                                seguidamente calificar&aacute; al Generador de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.7.
                                Administraci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:2.4535637855529786;margin-left: 0.9129981994628906pt;margin-right: 78.62655639648438pt;text-indent: 0.4510002136230469pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Conductor tendr&aacute; acceso a informaci&oacute;n de las Cargas programadas y/o
                                terminadas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.8.
                                Compartir la Aplicaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 1.0550537109375pt;text-indent: 0.4949989318847656pt;text-align: justify;margin-top:2.6719970703125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario podr&aacute; invitar a otros conductores para que descarguen la
                                Aplicaci&oacute;n y se registren. El</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuario
                                Conductor podr&aacute; recibir una comisi&oacute;n por cada persona que invite y que se
                                inscriba</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">debidamente
                                como Conductor en la Aplicaci&oacute;n. La Aplicaci&oacute;n le informar&aacute; la
                                comisi&oacute;n a la que tendr&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">derecho.
                                Teclogi podr&aacute; en cualquier momento modificar los montos de las comisiones,
                                as&iacute; como</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">eliminarlas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:13.207504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.9.
                                Noticias</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 0.668701171875pt;text-indent: 0.8689994812011719pt;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario tendr&aacute; acceso a un contenido variado y de inter&eacute;s, adem&aacute;s
                                de v&iacute;deos gu&iacute;a sobre la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PROPIETARIOS:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.1.
                                Registro y activaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.96923828125pt;text-indent: 0.5279998779296875pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Registrarse
                                en la Aplicaci&oacute;n consta de dos pasos simples, el registro, donde se solicitan
                                datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b&aacute;sicos
                                del Usuario, y la activaci&oacute;n de su cuenta. Cuando es un Usuario nuevo, Teclogi
                                solicitar&aacute; que</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                adjunten documentos b&aacute;sicos del Usuario que se est&aacute;
                                registrando.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.0449981689453125pt;margin-right: 1.87091064453125pt;text-indent: -0.17600250244140625pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.17600250244140625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                                esta informaci&oacute;n, Teclogi v&aacute;lida al nuevo Usuario en distintas fuentes de
                                informaci&oacute;n previo al</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">inicio
                                de operaciones, garantizando la seguridad de los distintos Usuarios y de sus
                                operaciones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.4949989318847656pt;margin-right: 0.64892578125pt;text-indent: 0.8689994812011719pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario Propietario deber&aacute; suministrar informaci&oacute;n sobre los
                                Veh&iacute;culos con los cuales se prestar&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">el
                                servicio de transporte de carga terrestre. Los Veh&iacute;culos deber&aacute;n cumplir
                                con todos los requisitos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">exigidos
                                por las normas vigentes aplicables en la materia al momento de realizar el registro y
                                al</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">momento
                                de prestar el servicio de transporte terrestre de carga para poder (i) registrarlo en
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n,
                                y (ii) prestar el servicio de transporte terrestre de carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-right: 0.5899658203125pt;text-indent: 1.3639984130859375pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Propietario del Veh&iacute;culo en el que se preste el servicio de transporte terrestre
                                de carga mantendr&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">indemne
                                a Teclogi y/o a cualquiera de sus miembros y/o cualquiera de sus empleados, por
                                cualquier</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">reclamaci&oacute;n,
                                p&eacute;rdida, da&ntilde;o, responsabilidad, costo y expensa (incluyendo costos de
                                asesor&iacute;a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;j</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ur&iacute;dica),
                                causados por reclamaciones o demandas que le fueren hechas por terceros o por
                                otros</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuarios
                                en relaci&oacute;n con el transporte de la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.2.
                                Ofertas de Cargas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 1.21490478515625pt;text-indent: 0.14299774169921875pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Propietario podr&aacute; postular a sus Conductores, estos &uacute;ltimos podr&aacute;n
                                aceptar o rechazar la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">De
                                igual forma el Usuario tiene la posibilidad de enviar una oferta de flete en un proceso
                                de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">negociaci&oacute;n
                                &aacute;gil y simple.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.3.
                                Obligaciones de los Propietarios</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.891002655029297pt;margin-right: 1.18572998046875pt;text-indent: -17.977996826171875pt;margin-top:17.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.977996826171875pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Abstenerse de transportar aquellos bienes que est&eacute;n prohibidos por las leyes de
                                la Rep&uacute;blica</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                Colombia, o cuyo transporte terrestre est&eacute; prohibido en el
                                pa&iacute;s.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.20999526977539pt;margin-right: 0.9366455078125pt;text-indent: -17.483001708984375pt;text-align: justify;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.483001708984375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Pagar a favor de TECLOGI el arrendamiento o por el uso de
                                &ldquo;aplicaci&oacute;n&rdquo; tecnol&oacute;gica, el cual</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                causar&aacute; por cada servicio de transporte ejecutado por cuenta de una empresa
                                de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transporte
                                de carga habilitada y se calcular&aacute; sobre el valor de cada documento de
                                transporte.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:31.207504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.923999786376953pt;margin-right: 0.81884765625pt;text-indent: -17.768997192382812pt;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.768997192382812pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Acordar con la Empresa de Transporte las condiciones t&eacute;cnicas y econ&oacute;micas
                                del transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terrestre
                                de la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.868999481201172pt;margin-right: 0.7198486328125pt;text-indent: -17.823997497558594pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.823997497558594pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Acordar con el Conductor del Veh&iacute;culo las condiciones t&eacute;cnicas y
                                econ&oacute;micas del transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terrestre
                                de la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 18.901996612548828pt;margin-right: 1.2806396484375pt;text-indent: -17.967002868652344pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.967002868652344pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                A pagar las penalidades que se generen por la cancelaci&oacute;n del servicio de
                                transporte terrestre</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 18.593997955322266pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                                A pagar al Conductor por el Servicio prestado.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 18.846996307373047pt;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.
                                Transportar la carga seg&uacute;n las indicaciones del Generador de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 10.82550048828125pt;text-align: right;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                                Entregar la Carga al destinatario de la misma seg&uacute;n lo acordado con el Generador
                                de Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.220996856689453pt;margin-right: 1.5743408203125pt;text-indent: -17.680999755859375pt;margin-top:14.16302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.680999755859375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.
                                Realizar el seguimiento a la Carga y reportar a la Empresa de Transporte
                                cualquier</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">eventualidad
                                que ocurra con la misma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 17.999996185302734pt;margin-right: 1.2850341796875pt;text-indent: -19.045005798339844pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 19.045005798339844pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">j</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                                En el evento en que la Carga sufra da&ntilde;os, responder por los mismos ante el
                                Generador de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Carga
                                y a mantener a Teclogi indemne por tal concepto.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.220996856689453pt;margin-right: 0.81402587890625pt;text-indent: -17.670005798339844pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.670005798339844pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">k.
                                Cerciorarse que los Veh&iacute;culos que transportan la Carga cumplan con todas las
                                normas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">aplicables
                                en Colombia para el transporte terrestre de carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 19.19900131225586pt;margin-right: 1.41314697265625pt;text-indent: -17.70299530029297pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.70299530029297pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">l.
                                Cerciorarse que los Conductores est&aacute;n habilitados por la autoridad competente
                                para conducir</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">el
                                Veh&iacute;culo en el que se transportar&aacute; la Carga.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.4.
                                Trazabilidad</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 1.30242919921875pt;text-indent: 0.5279998779296875pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario podr&aacute; realizar el seguimiento en tiempo real del Veh&iacute;culo y
                                reporte de novedades del</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conductor.
                                Teclogi tendr&aacute; la posibilidad de suspender o habilitar esta funcionalidad sin
                                previo aviso</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">seg&uacute;n
                                lo requiera la operaci&oacute;n de la plataforma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.5.
                                Compartir la Aplicaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 1.0550537109375pt;text-indent: 0.4949989318847656pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario podr&aacute; invitar a otros conductores para que descarguen la
                                Aplicaci&oacute;n y se registren. El</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuario
                                Conductor podr&aacute; recibir una comisi&oacute;n por cada persona que invite y que se
                                inscriba</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">debidamente
                                como Conductor en la Aplicaci&oacute;n. La Aplicaci&oacute;n le informar&aacute; la
                                comisi&oacute;n a la que tendr&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">derecho.
                                Teclogi podr&aacute; en cualquier momento modificar los montos de las comisiones,
                                as&iacute; como</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">eliminarlas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.417495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.6.
                                Administraci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 1.2210006713867188pt;margin-right: 0.565185546875pt;text-indent: 0.14299774169921875pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario obtendr&aacute; informaci&oacute;n de las Cargas que el Usuario gestion&oacute;
                                y que se encuentran</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">programadas
                                y/o terminadas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:10.458000183105469pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.7.
                                Noticias</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 0.668701171875pt;text-indent: 0.8689994812011719pt;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario tendr&aacute; acceso a un contenido variado y de inter&eacute;s, adem&aacute;s
                                de v&iacute;deos gu&iacute;a sobre la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.
                                Agentes Comerciales</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:15.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.1.
                                Registro y activaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.96923828125pt;text-indent: 0.5279998779296875pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Registrarse
                                en la Aplicaci&oacute;n consta de dos pasos simples, el registro, donde se solicitan
                                datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b&aacute;sicos
                                del Usuario, y la activaci&oacute;n de su cuenta. Cuando es un Usuario nuevo, Teclogi
                                solicitar&aacute; que</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                adjunten documentos b&aacute;sicos del Usuario que se est&aacute;
                                registrando.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.0449981689453125pt;margin-right: 1.87091064453125pt;text-indent: -0.17600250244140625pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.17600250244140625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                                esta informaci&oacute;n, Teclogi v&aacute;lida al nuevo Usuario en distintas fuentes de
                                informaci&oacute;n previo al</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">inicio
                                de operaciones, garantizando la seguridad de los distintos Usuarios y de sus
                                operaciones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.2.
                                Referidos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6930007934570312pt;margin-right: 0.8592529296875pt;text-indent: 0.6709976196289062pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario podr&aacute; invitar a conductores para que descarguen la Aplicaci&oacute;n y se
                                registren como</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conductores,
                                obteniendo as&iacute; una respectiva comisi&oacute;n. La Aplicaci&oacute;n le
                                informar&aacute; la comisi&oacute;n a la que</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">tendr&aacute;
                                derecho el Agente Comercial. Teclogi podr&aacute; en cualquier momento modificar los
                                montos de las</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">comisiones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.3.
                                Ofertas de cargas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.0449981689453125pt;margin-right: 1.022705078125pt;text-indent: -0.16500091552734375pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.16500091552734375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                                una amplia oferta de carga disponible el Agente Comercial podr&aacute; postular a sus
                                Conductores</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">referidos,
                                estos &uacute;ltimos podr&aacute;n aceptar o rechazar el transporte de la Carga. De
                                igual forma el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuario
                                tiene la posibilidad de enviar una oferta de flete en un proceso de negociaci&oacute;n
                                &aacute;gil y simple.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.4.
                                Administraci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 1.03253173828125pt;text-indent: 0.14299774169921875pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario tendr&aacute; acceso a informaci&oacute;n de las Cargas que gestion&oacute; y
                                que se encuentran</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">programadas
                                y/o terminadas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.5.
                                Noticias</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 0.668701171875pt;text-indent: 0.8689994812011719pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario tendr&aacute; acceso a un contenido variado y de inter&eacute;s, adem&aacute;s
                                de v&iacute;deos gu&iacute;a sobre la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.7039985656738281pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.
                                COSTO POR USO DE LA APLICACI&Oacute;N</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.6930007934570312pt;margin-right: 0.8648681640625pt;text-align: center;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                                usuarios&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">conductores
                                o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">propietarios</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;de
                                veh&iacute;culos, por el uso o arrendamiento de
                                &ldquo;aplicaci&oacute;n&rdquo;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">tecnol&oacute;gica,
                                pagar&aacute;n a favor de TECLOGI el dos punto cinco por ciento (2.5%), el cual se
                                calcular&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:10.458000183105469pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.8029975891113281pt;margin-right: 0.6007080078125pt;text-indent: -0.033000946044921875pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.033000946044921875pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">sobre
                                el valor del servicio de cada documento de transporte generado por una empresa de
                                transporte</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                carga habilitada, autorizando el&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">conductor&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">propietario&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                                la empresa de transporte a que realice el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">pago
                                del dos punto cinco por ciento (2.5%) tomado del valor de sus servicios, por el uso de
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">herramienta
                                tecnol&oacute;gica y autoriza se realice el pago directo a INNVOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9790000915527344pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.
                                SERVICIOS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 0.6824951171875pt;text-indent: 0.8689994812011719pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario es el oferente y prestador de los Servicios que desee publicar y ofrecer a
                                trav&eacute;s de la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n,
                                y ser&aacute; responsable de la calidad y prestaci&oacute;n de los mismos ante los otros
                                Usuarios y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Teclogi.
                                Teclogi no ser&aacute; responsable por la calidad, idoneidad y seguridad de los
                                Servicios</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">publicados,
                                ofrecidos y comercializados por el Usuario a trav&eacute;s de la Aplicaci&oacute;n.
                                Teclogi es un simple</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">medio
                                para que los Usuarios ofrezcan sus Servicios. Teclogi no ser&aacute; el vendedor,
                                oferente,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">comercializador
                                o proveedor de los Servicios ofrecidos y comercializados por los Usuarios a
                                trav&eacute;s de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                Aplicaci&oacute;n y por lo tanto no asume responsabilidad alguna sobre los mismos ante
                                los Clientes o las</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">autoridades
                                competentes.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9790000915527344pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.1.
                                PUBLICACI&Oacute;N</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.9239997863769531pt;margin-right: 0.8812255859375pt;text-indent: 0.4399986267089844pt;text-align: justify;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Servicio ofrecido por el Usuario debe ser exactamente descrito en cuanto a sus
                                condiciones y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">caracter&iacute;sticas
                                relevantes. Se entiende y presume que, mediante la publicaci&oacute;n del Servicio,
                                el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuario
                                acepta que tiene la intenci&oacute;n y el derecho de prestarlo, y est&aacute; facultado
                                para ello de acuerdo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">con
                                las normas aplicables en la materia.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 1.2210006713867188pt;margin-right: 1.98809814453125pt;text-indent: 0.14299774169921875pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario ser&aacute; responsable de la publicidad que realice sobre los Servicios a
                                trav&eacute;s de cualquier</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">medio
                                y estar&aacute; obligado por la misma en el evento de ofrecerlos a trav&eacute;s de la
                                Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9790000915527344pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.2.
                                BIENES PROHIBIDOS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 1.0449981689453125pt;margin-right: 0.99896240234375pt;text-indent: 0.319000244140625pt;text-align: justify;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario entiende, y as&iacute; lo acepta, que no se podr&aacute; generar como Carga, u
                                ofrecer transportar como</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Carga,
                                a trav&eacute;s de la Aplicaci&oacute;n, bienes que est&eacute;n prohibidos por las
                                leyes de la Rep&uacute;blica de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Colombia.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.8909988403320312pt;margin-right: 0.91717529296875pt;text-indent: 0.47299957275390625pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
                                todo caso, Teclogi se reserva el derecho de retirar de la Aplicaci&oacute;n la
                                publicaci&oacute;n como Carga de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">aquellos
                                bienes o Servicios que considere que no cumplan con los presentes T&eacute;rminos
                                y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Condiciones,
                                que violen las normas de la Rep&uacute;blica de Colombia, o que pongan en riesgo a
                                Teclogi o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                                los dem&aacute;s Usuarios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9790000915527344pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.3.
                                PRECIOS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8909988403320312pt;margin-right: 1.1572265625pt;text-indent: 0.4070014953613281pt;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                                precios de los Servicios que se publiquen a trav&eacute;s de la Aplicaci&oacute;n
                                estar&aacute;n vigentes mientras</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">aparezcan
                                en la misma. Los precios de los Servicios ofrecidos en la Aplicaci&oacute;n
                                deber&aacute;n expresarse</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:10.207504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">14&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9020004272460938pt;margin-right: 1.033203125pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">en
                                pesos colombianos y deber&aacute;n incluir los impuestos aplicables. El Usuario es el
                                &uacute;nico responsable</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">por
                                publicar los impuestos aplicables a cada Servicio. Los precios ofrecidos
                                corresponden</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">exclusivamente
                                al valor del Servicio ofrecido y no incluyen otros gastos que no se
                                describan</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">expresamente,
                                ni ning&uacute;n otro &iacute;tem adicional.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9020004272460938pt;margin-right: 0.8365478515625pt;text-indent: 0.46199798583984375pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario ser&aacute; responsable por los precios de los Servicios que publique y
                                deber&aacute; respetar los</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">mismos.
                                En caso de error en el precio de los Servicios deber&aacute; informar inmediatamente a
                                Teclogi y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">enmendar
                                el error en la publicaci&oacute;n del Servicio.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9790000915527344pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.4.
                                IMPUESTOS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.9899978637695312pt;margin-right: 1.28314208984375pt;text-indent: 0.3080024719238281pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                                Usuarios ser&aacute;n responsables por los impuestos que genere la prestaci&oacute;n de
                                cada uno de sus</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Servicios.
                                Teclogi no se har&aacute; responsable de los mismos, ni realizar&aacute; retenciones de
                                impuestos sobre</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">los
                                pagos que realicen los otros Usuarios al momento de adquirir un Servicio.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2949363231658935;margin-left: 0.7479972839355469pt;margin-right: 1.23077392578125pt;text-indent: -0.1100006103515625pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.1100006103515625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Teclogi
                                no es responsable por el efectivo cumplimiento de obligaciones fiscales o
                                impositivas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">establecidas
                                por las leyes vigentes aplicables al Usuario, Teclogi s&oacute;lo ser&aacute;
                                responsable de las</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">obligaciones
                                tributarias propias en los procesos de facturaci&oacute;n por cobro al Usuario por
                                las</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">comisiones
                                generadas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2949363231658935;margin-left: 0.63800048828125pt;margin-right: 0.94366455078125pt;text-indent: 0.7259979248046875pt;text-align: justify;margin-top:14.792755126953125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario ser&aacute; quien emita la factura al cliente asumiendo la calidad tributaria
                                que ostente, y deber&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">realizar
                                todos los procesos contables, administrativos y tributarios que le apliquen,
                                oblig&aacute;ndose a su</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">vez
                                con prestaci&oacute;n del Servicio.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9790000915527344pt;margin-top:14.792755126953125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.5.
                                PROMOCIONES Y OFERTAS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.0449981689453125pt;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cada
                                Usuario quedar&aacute; obligado por las promociones y ofertas que realice sobre los
                                Servicios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 0.99224853515625pt;text-indent: 0.4949989318847656pt;margin-top:15.662994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario deber&aacute; se&ntilde;alar la fecha de inicio de la oferta o promoci&oacute;n,
                                as&iacute; como el plazo de vigencia</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                las mismas con una fecha cierta para esa promoci&oacute;n debidamente
                                informados.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9790000915527344pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.6.
                                GARANT&Iacute;A DEL SERVICIO</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 1.1989974975585938pt;margin-right: 1.24468994140625pt;text-indent: 0.09900283813476562pt;margin-top:15.662994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                                Usuarios ser&aacute;n responsables por la calidad de los Servicios que publiquen y
                                ofrezcan a trav&eacute;s de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.6930007934570312pt;margin-right: 1.2908935546875pt;text-indent: 0.054996490478515625pt;margin-top:11.167999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Teclogi
                                al no ser el prestador de los Servicios no responde por la garant&iacute;a de los
                                mismos, la cual en</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">todo
                                caso es responsabilidad de los Usuarios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9790000915527344pt;margin-top:11.167999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.7.
                                PAGO DE LOS SERVICIOS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8579978942871094pt;margin-right: 0.78936767578125pt;text-indent: 0.18700027465820312pt;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cada
                                Usuario que publique una carga expresar&aacute; en los detalles de la misma el mecanismo
                                de pago</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ofrecido.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:16.957504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">15&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.957000732421875pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.
                                FUNCIONALIDADES Y CAMBIOS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 0.73681640625pt;text-indent: 0.8030014038085938pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                Aplicaci&oacute;n est&aacute; en permanente proceso de evoluci&oacute;n y desarrollo.
                                Las funcionalidades de la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n
                                pueden ser modificadas, en cualquier momento de acuerdo a las necesidades de
                                los</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usuarios
                                y del mercado y en general a discreci&oacute;n de Teclogi.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.4949989318847656pt;margin-right: 2.20867919921875pt;text-indent: -0.3409996032714844pt;margin-top:15.66802978515625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.3409996032714844pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Asimismo,
                                los presentes T&eacute;rminos y Condiciones pueden cambiar en cualquier momento, de lo
                                cual</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                                dar&aacute; aviso y se le solicitar&aacute; que los acepte nuevamente.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.957000732421875pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.
                                ASPECTOS T&Eacute;CNICOS Y OPERATIVOS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.2980003356933594pt;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                                Usuarios deben tener en cuenta lo siguiente:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 3.8909988403320312pt;margin-right: 1.02276611328125pt;text-indent: -17.801998138427734pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.801998138427734pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Teclogi adopta las medidas t&eacute;cnicas y operativas necesarias para asegurar la
                                seguridad de las</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transacciones
                                y de los datos personales y comerciales almacenados. Sin embargo, ante
                                hackeo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o
                                delito, advierte que se trata de un servicio tecnol&oacute;gico que en todo caso implica
                                riesgos para las</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">partes.
                                Por lo tanto, se emiten formulaci&oacute;n de verificaci&oacute;n de identidad, y se
                                recomienda utilizar</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">una
                                contrase&ntilde;a que re&uacute;na las condiciones m&iacute;nimas de
                                seguridad.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 4.209999084472656pt;margin-right: 0.58648681640625pt;text-indent: -18.010997772216797pt;margin-top:0.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.010997772216797pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Los Usuarios deben notificar a Teclogi inmediatamente de cualquier violaci&oacute;n de
                                seguridad o uso</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">no
                                autorizado de su cuenta en la Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 3.923999786376953pt;margin-right: 1.06072998046875pt;text-indent: -17.669998168945312pt;margin-top:0.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.669998168945312pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Los Usuarios deben contar con servicio de internet o plan de datos para el
                                correcto</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">funcionamiento
                                de la Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 3.868999481201172pt;margin-right: 0.791748046875pt;text-indent: -17.988998413085938pt;text-align: justify;margin-top:0.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.988998413085938pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Queda expresamente prohibido el uso de la Aplicaci&oacute;n que de cualquier forma
                                sobrecargue, da&ntilde;e</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o
                                inutilice las redes, servidores y dem&aacute;s equipos inform&aacute;ticos o productos y
                                aplicaciones</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">inform&aacute;ticas
                                de Teclogi o de terceros.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 3.9020004272460938pt;margin-right: 1.0543212890625pt;text-indent: -17.790996551513672pt;margin-top:0.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.790996551513672pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                Cualquier uso no autorizado de la Aplicaci&oacute;n da el derecho a Teclogi a suspender,
                                restringir o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terminar
                                de forma unilateral la cuenta y/o al Usuario infractor.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.4949989318847656pt;margin-right: 0.58331298828125pt;text-align: justify;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">As&iacute;
                                mismo, Teclogi no se hace responsable por cualquier da&ntilde;o, perjuicio o
                                p&eacute;rdida, lucro cesante,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">da&ntilde;o
                                emergente al Usuario causados por fallas en la Aplicaci&oacute;n. Teclogi tampoco
                                ser&aacute; responsable</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">por
                                cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso,
                                uso o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">examen
                                de la Aplicaci&oacute;n o a ra&iacute;z de cualquier transferencia de datos, archivos,
                                im&aacute;genes, textos, o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">audio
                                contenidos en el mismo. Teclogi no garantiza el acceso y uso continuado o ininterrumpido
                                de la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aplicaci&oacute;n.
                                La Aplicaci&oacute;n puede eventualmente no estar disponible debido a dificultades
                                t&eacute;cnicas o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">fallas
                                de internet o de datos, o por cualquier otra circunstancia ajena a Teclogi, en tales
                                casos se</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">procurar&aacute;
                                restablecerlo con la mayor celeridad posible sin que por ello pueda imput&aacute;rsele
                                alg&uacute;n tipo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                responsabilidad.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9459991455078125pt;margin-top:15.417495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.
                                PERFILES DE USO</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 1.0449981689453125pt;margin-right: 1.13671875pt;text-indent: -0.15399932861328125pt;margin-top:17.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.15399932861328125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Corresponde
                                a los Usuarios definir, ampliar, restringir, modificar y actualizar los datos
                                registrados en</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                Aplicaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:5.958000183105469pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">16&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.8470001220703125pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.
                                RESERVA DE LA INFORMACI&Oacute;N</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.63800048828125pt;margin-right: 0.7349853515625pt;text-indent: 0.10999679565429688pt;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Teclogi
                                no revelar&aacute; a terceros la informaci&oacute;n personal de registro suministrada
                                por sus Usuarios o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">visitantes,
                                salvo por orden de autoridad competente o a las personas autorizadas por los
                                Usuarios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.9899978637695312pt;margin-right: 1.0614013671875pt;text-indent: -0.220001220703125pt;text-align: justify;margin-top:15.66802978515625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.220001220703125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sin
                                embargo, los Usuarios y visitantes a la Aplicaci&oacute;n, aceptan expresamente que la
                                Aplicaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">reciba,
                                recopile, analice, y ceda a terceros las opiniones y calificaciones de los Usuarios y
                                pueda</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">presentar
                                reportes dirigidos a medios de comunicaci&oacute;n, autoridades, empresas o
                                ciudadanos.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3639984130859375pt;margin-top:15.66802978515625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.
                                AUTORIZACI&Oacute;N PARA EL TRATAMIENTO DE DATOS PERSONALES</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.9239997863769531pt;margin-right: 1.16082763671875pt;text-indent: 0.417999267578125pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
                                poder registrarse en la Aplicaci&oacute;n, el Usuario deber&aacute; aceptar la
                                autorizaci&oacute;n de uso de datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">personales;
                                en todo caso, al momento de la descarga de la Aplicaci&oacute;n, o con el simple uso de
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">misma,
                                se entiende aceptada la &ldquo;autorizaci&oacute;n y el uso de datos personales de
                                clientes y usuarios&rdquo; la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cual
                                hace parte integral del presente documento como anexo n&uacute;mero 2.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3639984130859375pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.
                                USO DE LA INFORMACI&Oacute;N.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 3.8909988403320312pt;margin-right: 0.83447265625pt;text-indent: -17.856998443603516pt;text-align: justify;margin-top:17.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.856998443603516pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Estos T&eacute;rminos y Condiciones conceden una licencia limitada no exclusiva y no
                                transferible que</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">le
                                permite a los Usuarios registrados acceder a los datos publicados, acceder a las
                                ofertas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">disponibles,
                                publicar y ofrecer sus Servicios. Queda expresamente prohibido ceder,
                                vender,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">sublicenciar,
                                manipular o realizar ning&uacute;n tipo de transacci&oacute;n sobre la licencia fuera de
                                estos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">T&eacute;rminos
                                y Condiciones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 4.209999084472656pt;margin-right: 0.677490234375pt;text-indent: -17.68100357055664pt;text-align: justify;margin-top:0.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.68100357055664pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                La informaci&oacute;n que Teclogi permite ser p&uacute;blica al p&uacute;blico en
                                general podr&aacute; ser publicada,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">anunciada,
                                compartida y en general usada por quienes tengan acceso a la misma de acuerdo
                                a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">las
                                normas de propiedad intelectual.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 3.923999786376953pt;margin-right: 1.278564453125pt;text-indent: -17.922996520996094pt;text-align: justify;margin-top:0.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.922996520996094pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Los Usuarios reconocen a Teclogi como autora de los derechos de autor, marcas,
                                patentes,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">datos,
                                software, material fotogr&aacute;fico, dise&ntilde;o gr&aacute;fico, secretos
                                comerciales y est&aacute;n protegidos por</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                Ley 23 de 1982, tratados internacionales a los que la Rep&uacute;blica de Colombia haga
                                parte y en</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">general
                                cualquier ley aplicable. El contenido de la Aplicaci&oacute;n no es modificable, ni se
                                puede usar</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">bajo
                                ninguna forma salvo una licencia expresa por parte de INNVOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 3.83599853515625pt;margin-right: 0.865234375pt;text-indent: -0.033000946044921875pt;margin-top:0.667999267578125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.033000946044921875pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Queda prohibido copiar, reproducir la Aplicaci&oacute;n, sus contenidos y/o
                                funcionalidades.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                Al momento de la descarga de la Aplicaci&oacute;n o con su simple uso, se entiende
                                aceptada &ldquo;la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">pol&iacute;tica
                                de tratamiento de la informaci&oacute;n&rdquo; la cual hace parte integral del presente
                                documento</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">como
                                anexo n&uacute;mero 1.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3639984130859375pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12.
                                Indemnidad</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2279935359954834;margin-left: 0.9020004272460938pt;margin-right: 0.79248046875pt;text-align: right;margin-top:13.412994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario mantendr&aacute; indemne a Teclogi y/o a cualquiera de sus miembros y/o
                                cualquiera de sus</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">empleados,
                                por cualquier reclamaci&oacute;n, p&eacute;rdida, da&ntilde;o, responsabilidad, costo y
                                expensa (incluyendo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">costos
                                de asesor&iacute;a jur&iacute;dica), seg&uacute;n sean efectivamente demostrados y
                                causados por reclamaciones o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">17&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 2.336669921875pt;text-indent: -0.3520011901855469pt;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.3520011901855469pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">demandas
                                que le fueren hechas por terceros en relaci&oacute;n con los Servicios ofrecidos en la
                                Aplicaci&oacute;n,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">por
                                el uso de la misma y con los presentes T&eacute;rminos y Condiciones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.90576171875pt;text-indent: 0.5279998779296875pt;text-align: justify;margin-top:11.66748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Usuario faculta a Teclogi para: i) intervenir y representarlo en dichos reclamos,
                                pudiendo celebrar</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">los
                                acuerdos transaccionales que considere oportunos y que tiendan a evitar mayores costos
                                y/o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">evitar
                                eventuales contingencias sin limitaci&oacute;n, en su nombre y representaci&oacute;n;
                                ii) retener y debitar de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">su
                                cuenta los fondos existentes y/o futuros para recuperar los gastos incurridos en los
                                acuerdos, los</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cuales
                                podr&aacute;n incluir los honorarios de los letrados intervinientes y las costas
                                judiciales en una</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cantidad
                                razonable, y/o iii) generar d&eacute;bitos espec&iacute;ficos en su
                                facturaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3639984130859375pt;margin-top:15.41748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.
                                LEGISLACI&Oacute;N APLICABLE</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.3630908966064452;margin-left: 0.83599853515625pt;margin-right: 0.64898681640625pt;text-indent: 0.5279998779296875pt;text-align: justify;margin-top:17.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Estos
                                T&eacute;rminos y Condiciones se rigen por las leyes de la Rep&uacute;blica de Colombia
                                y podr&aacute;n ser</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">modificados
                                de acuerdo al servicio ofrecido en cualquier momento, lo cual se publicar&aacute;
                                previamente a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">su
                                entrada en vigencia.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3639984130859375pt;margin-top:15.667999267578125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                prestador de los servicios de la Aplicaci&oacute;n es:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.7479972839355469pt;margin-top:17.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Teclogi
                                S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3309974670410156pt;margin-top:2.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">NIT:
                                901.331.232-0</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3419990539550781pt;margin-top:2.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Domicilio:
                                Bogot&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3419990539550781pt;margin-top:0.662994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Direcci&oacute;n:
                                Cll 26 # 96j - 66</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.0449981689453125pt;margin-top:0.662994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Correo
                                Electr&oacute;nico: soporte@loggiapp.com</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.2980003356933594pt;margin-top:0.662994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bogot&aacute;,
                                Colombia</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:284.2030029296875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">18&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ANEXO
                                1</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;text-align: center;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">POL&Iacute;TICA
                                DE TRATAMIENTO DE LA INFORMACI&Oacute;N</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;text-align: center;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INNOVBA
                                S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.0449981689453125pt;margin-right: 0.7862548828125pt;text-indent: 0.319000244140625pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
                                cumplimiento de la Ley 1581 de 2012 y el decreto 1377 de 2013, vigentes en la
                                Rep&uacute;blica de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Colombia,
                                y cualquier otra norma aplicable en la materia, la siguiente es la Pol&iacute;tica de
                                Tratamiento de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                                Informaci&oacute;n de INNOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3639984130859375pt;margin-top:23.66748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.
                                DATOS DEL RESPONSABLE TRATAMIENTO DE INFORMACI&Oacute;N</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.7479972839355469pt;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3309974670410156pt;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">NIT
                                901.331.232 - 0</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3419990539550781pt;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Domicilio:
                                Bogot&aacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3419990539550781pt;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Direcci&oacute;n:
                                Cll 26 # 96j - 66</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.0449981689453125pt;margin-top:0.662994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Correo
                                Electr&oacute;nico: soporte@loggiapp.com</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.7700004577636719pt;margin-top:15.662994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.
                                DISPOSICIONES GENERALES</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 11.864002227783203pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.1.
                                Objetivo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.7479972839355469pt;margin-right: 0.66888427734375pt;text-indent: 0.5500030517578125pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n tiene como objetivo
                                garantizar el adecuado</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cumplimiento
                                de las normas aplicables en materia de protecci&oacute;n de datos personales aplicables
                                y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">poner
                                en conocimiento el uso que INNVOVBA S.A.S. le da a la informaci&oacute;n proporcionada
                                por los</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Titulares,
                                e informar sobre los procedimientos y mecanismos de manejo de los Datos
                                Personales,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">recopilados
                                para las finalidades previstas con la autorizaci&oacute;n previa.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 11.864002227783203pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.2.
                                Legislaci&oacute;n Aplicable</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.98004150390625pt;text-indent: 0.4620018005371094pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n fue elaborada de
                                conformidad con las</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">disposiciones
                                contenidas en los art&iacute;culos 15 y 20 de la Constituci&oacute;n Pol&iacute;tica, la
                                Ley 1581 de 2012 y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">sus
                                decretos reglamentarios, en especial el Decreto 1377 de 2013.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 11.864002227783203pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.3.
                                &Aacute;mbito de aplicaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 0.93670654296875pt;text-indent: 0.07699966430664062pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n es aplicable al
                                tratamiento de los datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">personales
                                registrados en cualquier base de datos que los haga susceptibles de Tratamiento
                                por</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">parte
                                de INNVOVBA S.A.S., bien sea en calidad de Responsable y/o Encargado.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 11.864002227783203pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.4.
                                Definiciones</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8909988403320312pt;margin-right: 2.2813720703125pt;text-indent: 0.4510002136230469pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
                                los efectos de aplicaci&oacute;n de la presente Pol&iacute;tica, y en concordancia con
                                lo establecido en el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">art&iacute;culo
                                3 de la ley 1581 de 2012 y en el art&iacute;culo 3 del Decreto 1377 de 2013 se entiende
                                por:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 9.723690032958984pt;margin-right: 2.24041748046875pt;text-indent: -26.96930694580078pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 26.96930694580078pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Autorizaci&oacute;n:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Consentimiento
                                previo, expreso e informado del Titular para llevar a cabo el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">tratamiento
                                de sus Datos Personales.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:3.4575042724609375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">19&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 10.042682647705078pt;margin-right: 0.64599609375pt;text-indent: -26.837318420410156pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 26.837318420410156pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aviso
                                de Privacidad:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Documento
                                f&iacute;sico, electr&oacute;nico o en cualquier otro formato, generado por</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">el
                                responsable, dirigido al titular para el Tratamiento de sus Datos Personales, mediante
                                el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cual
                                se le informa acerca de la existencia de las pol&iacute;ticas del tratamiento de la
                                informaci&oacute;n,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                le ser&aacute;n aplicables a sus datos, la forma de acceder a las mismas, y la finalidad
                                para la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cual
                                ser&aacute;n usados dichos datos.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 10.373920440673828pt;margin-right: 1.8978271484375pt;text-align: center;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Base
                                de Datos:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conjunto
                                organizado de Datos Personales que sea objeto de tratamiento.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Puede
                                ser automatizada o f&iacute;sica de acuerdo a su forma de Tratamiento o
                                almacenamiento.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 9.70168685913086pt;margin-right: 0.95172119140625pt;text-indent: -27.519309997558594pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 27.519309997558594pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dato
                                Personal:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cualquier
                                informaci&oacute;n vinculada o que pueda asociarse a una o varias</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">personas
                                naturales determinadas o determinables.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 9.734683990478516pt;margin-right: 2.4842529296875pt;text-indent: -27.01331329345703pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 27.01331329345703pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dato
                                Privado:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aquel
                                que por su naturaleza &iacute;ntima o reservada s&oacute;lo es relevante para
                                el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Titular.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 12.486034393310547pt;margin-right: 0.89849853515625pt;text-indent: -24.184967041015625pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 24.184967041015625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dato
                                P&uacute;blico:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aquel
                                que no sea Semiprivado, Privado o Sensible. Son considerados Datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&uacute;blicos,
                                entre otros, los datos relativos al estado civil de las personas, a su profesi&oacute;n
                                u oficio</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">y
                                a su calidad de comerciante o de servidor p&uacute;blico. Por su naturaleza, los datos
                                p&uacute;blicos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">pueden
                                estar contenidos, entre otros, en registros p&uacute;blicos, documentos p&uacute;blicos,
                                gacetas y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">boletines
                                oficiales y sentencias judiciales debidamente ejecutoriadas que no est&eacute;n
                                sometidas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                                reserva.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 9.679683685302734pt;margin-right: 0.89556884765625pt;text-indent: -27.178314208984375pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 27.178314208984375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dato
                                Sensible:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aquel
                                que afecta la intimidad del Titular o cuyo uso indebido puede generar su</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">discriminaci&oacute;n,
                                incluyendo pero sin limitarse a datos que revelen el origen racial o &eacute;tnico,
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">orientaci&oacute;n
                                pol&iacute;tica, las convicciones religiosas o filos&oacute;ficas, la pertenencia a
                                sindicatos,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">organizaciones
                                sociales, de derechos humanos o que promueva intereses de cualquier partido</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">pol&iacute;tico
                                o que garanticen los derechos y garant&iacute;as de partidos pol&iacute;ticos de
                                oposici&oacute;n, as&iacute; como</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">los
                                datos relativos a la salud, a la vida sexual y los datos biom&eacute;tricos.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 10.05368423461914pt;margin-right: 1.177001953125pt;text-indent: -26.81531524658203pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 26.81531524658203pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Encargado
                                del Tratamiento:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Persona
                                natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma
                                o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">en
                                asocio con otros, realice el tratamiento de Datos Personales por cuenta del
                                Responsable</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">del
                                Tratamiento.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 13.724903106689453pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Productos:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Hace
                                referencia a bienes o servicios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 12.503902435302734pt;margin-right: 2.3167724609375pt;text-align: center;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">j</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Responsable
                                del Tratamiento:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Persona
                                natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">misma
                                o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los
                                datos.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 10.670917510986328pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">k.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Titular:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Persona
                                natural cuyos Datos Personales sean objeto de Tratamiento.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 13.70290756225586pt;margin-right: 0.90362548828125pt;text-indent: -22.891090393066406pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 22.891090393066406pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">l.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Transferencia:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                Transferencia de datos tiene lugar cuando el Responsable y/o Encargado</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">del
                                Tratamiento de datos personales, ubicado en Colombia, env&iacute;a la informaci&oacute;n
                                o los datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">personales
                                a un receptor, que a su vez es responsable del tratamiento y se encuentra dentro
                                o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">fuera
                                del pa&iacute;s.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:14.707504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">20&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 7.010440826416016pt;margin-right: 1.21588134765625pt;text-indent: -29.858558654785156pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 29.858558654785156pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">m.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Transmisi&oacute;n:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tratamiento
                                de Datos Personales que implica la comunicaci&oacute;n de los mismos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">dentro
                                o fuera del territorio de la Rep&uacute;blica de Colombia cuando tenga por objeto la
                                realizaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                un Tratamiento por el Encargado por cuenta del Responsable.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 10.05368423461914pt;margin-right: 0.78582763671875pt;text-indent: -26.870315551757812pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 26.870315551757812pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">n.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tratamiento:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cualquier
                                operaci&oacute;n o conjunto de operaciones sobre Datos Personales, tales</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">como
                                la recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o
                                supresi&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 11.864002227783203pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.5.
                                Principios rectores aplicables al Tratamiento de Datos Personales</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8909988403320312pt;margin-right: 0.63848876953125pt;text-indent: 0.15399932861328125pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                                el fin de garantizar el Tratamiento de Datos Personales que realice INNVOVBA S.A.S.
                                como</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Responsable
                                y/o Encargado de la informaci&oacute;n, aplicar&aacute;n los siguientes principios, en
                                los t&eacute;rminos del</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">art&iacute;culo
                                4 de la Ley 1581 de 2012:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8909988403320312pt;margin-right: 1.03106689453125pt;text-indent: -18.000003814697266pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.000003814697266pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Legalidad:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tratamiento&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                realice INNVOVBA S.A.S. sobre los Datos Personales estar&aacute; sujeto</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                                lo establecido en la Ley 1581 de 2012 y dem&aacute;s normas aplicables en la
                                materia.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2099990844726562pt;margin-right: 0.77020263671875pt;text-indent: -17.68100357055664pt;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.68100357055664pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Finalidad</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Tratamiento que realice INNVOVBA S.A.S. obedecer&aacute; a una finalidad leg&iacute;tima
                                de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">acuerdo
                                con la Constituci&oacute;n y la ley, la cual ser&aacute; informada al
                                Titular.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9239997863769531pt;margin-right: 0.94482421875pt;text-indent: -17.94499969482422pt;text-align: justify;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.94499969482422pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Libertad:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Tratamiento s&oacute;lo puede ejercerse con el consentimiento, previo, expreso e
                                informado</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">del
                                Titular. Los Datos Personales no podr&aacute;n ser obtenidos o divulgados sin previa
                                autorizaci&oacute;n, o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">en
                                ausencia de mandato legal o judicial que releve el consentimiento.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 1.60137939453125pt;text-indent: -18.022003173828125pt;text-align: justify;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.022003173828125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Veracidad
                                o Calidad:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                informaci&oacute;n sujeta a Tratamiento debe ser veraz, completa, exacta,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">actualizada,
                                comprobable y comprensible. Se proh&iacute;be el Tratamiento de datos
                                parciales,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">incompletos,
                                fraccionados o que induzcan a error.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9020004272460938pt;margin-right: 0.974853515625pt;text-indent: -17.989002227783203pt;text-align: justify;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.989002227783203pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Transparencia:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
                                el Tratamiento se garantizar&aacute; el derecho del Titular a obtener de
                                TECLOGI</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">S.A.S.
                                como Responsable y/o Encargado, en cualquier momento y sin restricciones,
                                informaci&oacute;n</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">acerca
                                de la existencia de datos que le concierne.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.5939979553222656pt;margin-right: 1.2469482421875pt;text-indent: -18.15399932861328pt;text-align: justify;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.15399932861328pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Acceso
                                y Circulaci&oacute;n Restringida:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                Tratamiento se sujeta a los l&iacute;mites que se derivan de la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">naturaleza
                                de los Datos Personales, de las disposiciones de la Ley 1581 de 2012 y la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Constituci&oacute;n.
                                En este sentido, el Tratamiento s&oacute;lo podr&aacute; hacerse por personas
                                autorizadas por el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Titular
                                y/o por las personas previstas en la Ley 1581 de 2012.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8470001220703125pt;margin-right: 0.60406494140625pt;text-indent: -17.900997161865234pt;text-align: justify;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.900997161865234pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Seguridad:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                informaci&oacute;n sujeta a Tratamiento por INNVOVBA S.A.S. como Responsable
                                del</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tratamiento
                                y/o Encargado del, se deber&aacute; manejar con las medidas t&eacute;cnicas, humanas
                                y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">administrativas
                                que sean necesarias para otorgar seguridad a los registros evitando su</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">adulteraci&oacute;n,
                                p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 1.0799560546875pt;text-indent: -17.526996612548828pt;text-align: justify;margin-top:7.9174957275390625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.526996612548828pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Confidencialidad:&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Todas
                                las personas que intervengan en el Tratamiento de Datos Personales</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                no tengan la naturaleza de p&uacute;blicos est&aacute;n obligadas a garantizar la
                                reserva de la informaci&oacute;n,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">inclusive
                                despu&eacute;s de finalizada su relaci&oacute;n con alguna de las labores que comprende
                                el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tratamiento,
                                pudiendo s&oacute;lo realizar suministro o comunicaci&oacute;n de Datos Personales
                                cuando ello</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">corresponda
                                al desarrollo de las actividades autorizadas en la Ley 1581 de 2012 y en los
                                t&eacute;rminos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                la misma.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:29.707504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">21&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9129981994628906pt;margin-right: 0.36614990234375pt;text-indent: -18.330001831054688pt;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.330001831054688pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.
                                TRATAMIENTO AL CUAL ESTAR&Aacute;N SOMETIDOS LOS DATOS PERSONALES E</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INFORMACI&Oacute;N
                                COMERCIAL Y SU FINALIDAD</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8799972534179688pt;margin-right: 0.8272705078125pt;text-indent: 0.4180030822753906pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                                datos personales ser&aacute;n recolectados, almacenados, transmitidos, transferidos,
                                procesados y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">usados
                                para todos aquellos aspectos inherentes a los Productos ofrecidos por INNVOVBA S.A.S., y
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ejecuci&oacute;n
                                de los contratos con clientes, usuarios, proveedores, empleados y dem&aacute;s
                                colaboradores, lo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                implica el uso de los datos ser&aacute;n usados para los siguientes fines:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.
                                Clientes y Usuarios</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.908327341079712;margin-left: 0.8909988403320312pt;margin-right: 66.76681518554688pt;text-indent: -0.319000244140625pt;margin-top:14.16302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.319000244140625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Cumplir las obligaciones contra&iacute;das por INNVOVBA S.A.S. con sus clientes y
                                usuarios.</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Enviar informaci&oacute;n sobre los Productos que ofrece INNVOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9239997863769531pt;margin-right: 2.3182373046875pt;text-indent: -18.066001892089844pt;margin-top:1.66998291015625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.066001892089844pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Enviar informaci&oacute;n sobre cambios en las condiciones de los Productos ofrecidos
                                por TECLOGI</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 0.9521484375pt;text-indent: -18pt;text-align: justify;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Enviar al correo f&iacute;sico, electr&oacute;nico, celular o dispositivo m&oacute;vil,
                                v&iacute;a mensajes de texto (SMS y/o</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">MMS)
                                o a trav&eacute;s de cualquier otro medio an&aacute;logo y/o digital de
                                comunicaci&oacute;n, creado o por</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">crearse,
                                informaci&oacute;n comercial, publicitaria o promocional sobre los Productos de
                                TECLOGI</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">S.A.S.,
                                eventos y/o promociones de tipo comercial o no de estas, con el fin de impulsar,
                                invitar,</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">dirigir,
                                ejecutar, informar y de manera general, llevar a cabo campa&ntilde;as, promociones o
                                concursos</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                car&aacute;cter comercial o publicitario, adelantados por INNVOVBA S.A.S. y/o por
                                terceras personas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9020004272460938pt;margin-right: 1.03765869140625pt;text-indent: -17.691997528076172pt;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.691997528076172pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                Tratar los datos de los clientes y usuarios de INNVOVBA S.A.S. suministrados por
                                terceros
                                para las</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">finalidades
                                aqu&iacute; se&ntilde;aladas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.5939979553222656pt;margin-right: 0.6890869140625pt;text-indent: -18.0989990234375pt;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.0989990234375pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                                Transferir o transmitir los Datos Personales a terceras personas en Colombia y en el
                                exterior a</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">t&iacute;tulo
                                gratuito u oneroso para su uso comercial.</span><span
                                style="font-size:11pt;font-family:Arial;color:#1b1b1b;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8470001220703125pt;margin-right: 0.5802001953125pt;text-indent: -18.054996490478516pt;text-align: justify;margin-top:7.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.054996490478516pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.
                                Transferir o transmitir los datos personales, de los clientes y usuarios de TECLOGI
                                S.A.S., a</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Encargados
                                del Tratamiento en Colombia y en el exterior cuando ello sea necesario para
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ejecuci&oacute;n
                                de los contratos con los clientes por INNVOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 0.6922607421875pt;text-indent: -17.526996612548828pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.526996612548828pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                                Elaborar estudios de anal&iacute;tica que incluyan cualquier detalle relacionado con los
                                Productos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ofrecidos
                                por INNVOVBA S.A.S. con el fin de compartirlos con socios vinculados al negocio
                                de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 0.8531494140625pt;text-indent: -17.97800064086914pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.97800064086914pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.
                                Los dem&aacute;s fines necesarios para dar cumplimiento a los contratos suscritos con
                                los clientes y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">usuarios
                                de INNVOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:7.9174957275390625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.
                                Proveedores</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.8909988403320312pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Para ejecutar los contratos con los proveedores de INNVOVBA S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.2099990844726562pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Para referenciarlos con otras personas naturales o jur&iacute;dicas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:14.163002014160156pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.
                                Empleados</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:16.4530029296875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">22&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.8909988403320312pt;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Para elaborar las hojas de vida.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2099990844726562pt;margin-right: 0.72576904296875pt;text-indent: -17.714000701904297pt;margin-top:14.16302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.714000701904297pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Para ejecutar y cumplir con el contrato de trabajo y dem&aacute;s obligaciones que como
                                empleador le</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">corresponden
                                por ley.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9239997863769531pt;margin-right: 0.78253173828125pt;text-indent: -17.71399688720703pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.71399688720703pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Administrar y operar, directamente o por conducto de terceros, los procesos de
                                selecci&oacute;n y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">vinculaci&oacute;n
                                de personal, incluyendo la evaluaci&oacute;n y calificaci&oacute;n de los participantes
                                y la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">verificaci&oacute;n
                                de referencias laborales y personales, y la realizaci&oacute;n de estudios de
                                seguridad.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 0.72369384765625pt;text-indent: -17.988998413085938pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.988998413085938pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Desarrollar las actividades propias de la gesti&oacute;n de recursos humanos tales como
                                n&oacute;mina,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">afiliaciones
                                a entidades del sistema general de seguridad social, actividades de bienestar y
                                salud</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ocupacional,
                                ejercicio de la potestad sancionatoria del empleador, entre otras.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9020004272460938pt;margin-right: 0.898681640625pt;text-indent: -17.790996551513672pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.790996551513672pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                Realizar los pagos necesarios derivados de la ejecuci&oacute;n del contrato de trabajo
                                y/o su</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">terminaci&oacute;n,
                                y las dem&aacute;s prestaciones sociales a que haya lugar de conformidad con la
                                ley</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">aplicable.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.5939979553222656pt;margin-right: 0.7958984375pt;text-indent: -18.263999938964844pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.263999938964844pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                                Contratar beneficios laborales con terceros, tales como seguros de vida, gastos
                                m&eacute;dicos, entre</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">otros.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8470001220703125pt;margin-right: 0.7874755859375pt;text-indent: -18.010997772216797pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.010997772216797pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.
                                Notificar a contactos autorizados en caso de emergencias durante el horario de trabajo o
                                con</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ocasi&oacute;n
                                del desarrollo del mismo.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 1.15216064453125pt;text-indent: -17.999996185302734pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.999996185302734pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                                Coordinar el desarrollo profesional de los empleados, el acceso de los empleados a los
                                recursos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">inform&aacute;ticos
                                y asistir en su utilizaci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2210006713867188pt;margin-right: 0.8953857421875pt;text-indent: -17.450000762939453pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.450000762939453pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.
                                Transferir y transmitir la informaci&oacute;n de los empleados a Encargados del
                                Tratamiento en Colombia</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">y
                                en el exterior cuando ello sea necesario para el desarrollo de sus operaciones y
                                gesti&oacute;n de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">n&oacute;mina.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.908327341079712;margin-right: 124.77096557617188pt;text-indent: -0.9129981994628906pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.9129981994628906pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">j</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                                Los dem&aacute;s fines necesarios para dar cumplimiento al contrato de
                                trabajo.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.
                                Accionistas y Miembros de la Junta Directiva</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8909988403320312pt;margin-right: 0.67181396484375pt;text-indent: -17.945003509521484pt;margin-top:7.670013427734375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.945003509521484pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Para convocarlos a las reuniones de la Asamblea General de Accionistas o de la Junta
                                Directiva</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">seg&uacute;n
                                corresponda.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2099990844726562pt;margin-right: 1.23626708984375pt;text-indent: -17.691997528076172pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.691997528076172pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Para enviarles la informaci&oacute;n necesaria de la Sociedad con el fin de tomar
                                decisiones informadas</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">en
                                las respectivas reuniones.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.9129981994628906pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.
                                Finalidades aplicables a todos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8909988403320312pt;margin-right: 0.69158935546875pt;text-indent: -17.856998443603516pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.856998443603516pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Para el fortalecimiento de las relaciones con sus clientes y proveedores, mediante el
                                env&iacute;o de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">informaci&oacute;n
                                relevante, la toma de pedidos y evaluaci&oacute;n de la calidad de los Productos
                                de</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:20.707504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">23&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2099990844726562pt;margin-right: 1.24066162109375pt;text-indent: -17.659000396728516pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.659000396728516pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Para la determinaci&oacute;n de obligaciones pendientes, la consulta de
                                informaci&oacute;n financiera e historia</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">crediticia
                                y el reporte a centrales de informaci&oacute;n de obligaciones incumplidas, respecto de
                                sus</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">deudores
                                en los t&eacute;rminos de la Ley 1266 de 2008.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9239997863769531pt;margin-right: 2.22247314453125pt;text-indent: -18.2969970703125pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.2969970703125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Controlar el acceso a las oficinas de INNVOVBA S.A.S., y establecer medidas de
                                seguridad,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">incluyendo
                                el establecimiento de zonas videovigiladas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.8689994812011719pt;margin-right: 0.802490234375pt;text-indent: -17.823997497558594pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.823997497558594pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Dar respuesta a consultas, peticiones, quejas y reclamos que sean realizadas por los
                                titulares y</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transmitir
                                los Datos Personales a organismos de control y dem&aacute;s autoridades que en virtud de
                                la</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ley
                                aplicable deban recibir los Datos Personales.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9020004272460938pt;margin-right: 1.02777099609375pt;text-indent: -17.966999053955078pt;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.966999053955078pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                Registrar los datos personales en los sistemas de informaci&oacute;n de INNVOVBA S.A.S.
                                y
                                en sus bases</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                                datos comerciales y operativas.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.5939979553222656pt;margin-right: 2.0040283203125pt;text-indent: -18.04399871826172pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.04399871826172pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                                Suministrar, compartir, enviar o entregar sus datos personales a empresas matrices,
                                filiales,</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">vinculadas,
                                o subordinadas de INNVOVBA S.A.S. ubicadas en Colombia o en el extranjero en
                                el</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">evento
                                que dichas compa&ntilde;&iacute;as requieran la informaci&oacute;n para los fines
                                aqu&iacute; indicados.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.7039985656738281pt;margin-top:13.917510986328125pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.
                                DERECHOS DE LOS TITULARES DE DATOS</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.0449981689453125pt;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conforme
                                lo dispone la Ley 1581 de 2012, los titulares de datos personales tienen derecho
                                a:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 11.391002655029297pt;margin-right: 0.59796142578125pt;text-indent: -17.85699462890625pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.85699462890625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Conocer, actualizar y rectificar sus datos personales frente a los Responsables del
                                Tratamiento</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o
                                Encargados del Tratamiento. Este derecho se podr&aacute; ejercer, entre otros frente a
                                datos</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">parciales,
                                inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tratamiento
                                est&eacute; expresamente prohibido o no haya sido autorizado.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 11.70999526977539pt;margin-right: 0.64068603515625pt;text-indent: -17.692001342773438pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.692001342773438pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Solicitar prueba de la autorizaci&oacute;n otorgada al Responsable del Tratamiento salvo
                                cuando</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">expresamente
                                se except&uacute;e como requisito para el Tratamiento, de conformidad con lo
                                previsto</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">en
                                el art&iacute;culo 10 de la Ley 1581 de 2012.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 11.423999786376953pt;margin-right: 1.2886962890625pt;text-indent: -17.912002563476562pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.912002563476562pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento,
                                previa</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">solicitud,
                                respecto del uso que le ha dado a sus datos personales.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 11.368999481201172pt;margin-right: 1.09735107421875pt;text-indent: -18pt;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a
                                lo</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">dispuesto
                                en la presente ley y las dem&aacute;s normas que la modifiquen, adicionen o
                                complementen.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 11.401996612548828pt;margin-right: 0.57403564453125pt;text-indent: -17.934005737304688pt;text-align: justify;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.934005737304688pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                                Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n del dato cuando en el
                                Tratamiento no se</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">respeten
                                los principios, derechos y garant&iacute;as constitucionales y legales. La revocatoria
                                y/o</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">supresi&oacute;n
                                proceder&aacute; cuando la Superintendencia de Industria y Comercio haya
                                determinado</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">que
                                en el Tratamiento el Responsable o Encargado han incurrido en conductas contrarias a
                                esta</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ley
                                y a la Constituci&oacute;n.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:3.7521348953247067;margin-left: 11.093994140625pt;margin-right: 3.0640869140625pt;text-indent: -415.9718017578125pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 415.9718017578125pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                                Acceder en forma gratuita a sus datos personales que hayan sido objeto de
                                Tratamiento.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">24&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.9790000915527344pt;margin-right: 0.85894775390625pt;text-indent: -17.51599884033203pt;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.51599884033203pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.
                                PERSONA RESPONSABLE DE LA ATENCI&Oacute;N DE PETICIONES, CONSULTAS Y RECLAMOS ANTE LA
                                CUAL EL TITULAR DE LA INFORMACI&Oacute;N&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 0.694580078125pt;text-indent: 0.4620018005371094pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                Gerencia</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;de
                                INNVOVBA S.A.S. ser&aacute; la encargada de atender las peticiones, consultas y reclamos
                                de los titulares de la informaci&oacute;n. Cualquier solicitud debe ser enviada al
                                correo electr&oacute;nico soporte@loggiapp.com el fin de ejercer los derechos a conocer,
                                actualizar, rectificar y suprimir los datos y revocar la autorizaci&oacute;n otorgados
                                por la ley y por esta Pol&iacute;tica de Tratamiento de Informaci&oacute;n.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;text-indent: -18.340999603271484pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.340999603271484pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.
                                PROCEDIMIENTO PARA QUE LOS TITULARES DE LA INFORMACI&Oacute;N PUEDAN EJERCER LOS
                                DERECHOS A CONOCER, ACTUALIZAR, RECTIFICAR Y SUPRIMIR INFORMACI&Oacute;N Y REVOCAR LA
                                AUTORIZACI&Oacute;N.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.957000732421875pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.
                                Consultas&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6930007934570312pt;margin-right: 0.98687744140625pt;text-indent: 0.054996490478515625pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S. dispone del correo electr&oacute;nico soporte@loggiapp.com para que el titular,
                                sus causahabientes, sus representantes y apoderados, y los representantes de menores de
                                edad titulares, formulen consultas respecto de cu&aacute;les son los datos personales
                                del titular que reposan en las bases de datos de INNVOVBA S.A.S.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 0.7767333984375pt;text-indent: -36.242000579833984pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 36.242000579833984pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.1.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Si
                                el solicitante tuviere capacidad para formular la consulta, en los t&eacute;rminos
                                se&ntilde;alados en la Ley 1581 de 2012 y sus decretos reglamentarios, INNVOVBA S.A.S.
                                recopilar&aacute; toda la informaci&oacute;n sobre el titular que est&eacute; contenida
                                en las bases de datos de INNVOVBA S.A.S. y se la har&aacute; conocer al
                                solicitante.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 0.65802001953125pt;text-indent: -35.63699722290039pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 35.63699722290039pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.2.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                responsable de atender la consulta, seg&uacute;n se se&ntilde;ala en el numeral 4
                                arriba, dar&aacute; respuesta al solicitante siempre y cuando tuviere derecho a ello por
                                ser el titular del dato personal, su causahabiente, apoderado, representante, o sea el
                                responsable legal en el caso de menores de edad. Esta respuesta se enviar&aacute; dentro
                                de los diez (10) d&iacute;as h&aacute;biles contados a partir de la fecha en la que la
                                solicitud fue recibida por la INNVOVBA S.A.S..&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 1.2879638671875pt;text-indent: -35.87900161743164pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 35.87900161743164pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.3.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
                                caso de que la solicitud no pueda ser atendida a los diez (10) d&iacute;as
                                h&aacute;biles, se contactar&aacute; al solicitante para comunicarle los motivos por los
                                cuales el estado de su solicitud se encuentra en tr&aacute;mite. Para ello se
                                utilizar&aacute; el mismo medio o uno similar al que fue utilizado por el titular para
                                comunicar su solicitud.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 0.76824951171875pt;text-indent: -35.9119987487793pt;text-align: justify;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 35.9119987487793pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.4.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                respuesta definitiva a todas las solicitudes no tardar&aacute; m&aacute;s de quince (15)
                                d&iacute;as h&aacute;biles desde la fecha en la que la solicitud inicial fue recibida
                                por INNVOVBA S.A.S. al correo electr&oacute;nico soporte@loggiapp.com.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.957000732421875pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.
                                Reclamos&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6930007934570312pt;margin-right: 1.18035888671875pt;text-indent: 0.054996490478515625pt;text-align: justify;margin-top:14.162994384765625pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S. dispone del correo electr&oacute;nico soporte@loggiapp.com para que el titular,
                                sus causahabientes, sus representantes y apoderados, y los representantes de menores de
                                edad titulares, formulen reclamos respecto de (i) datos personales tratados por la
                                empresa que deben ser objeto de correcci&oacute;n, actualizaci&oacute;n o
                                supresi&oacute;n, o (ii) el presunto incumplimiento de las normas aplicables en la
                                materia.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:1.2075042724609375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">25&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 1.10174560546875pt;text-indent: -35.93400192260742pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 35.93400192260742pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.1.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                reclamo deber&aacute; ser presentado por el Titular, sus causahabientes o representantes
                                o acreditados de conformidad con la Ley 1581 y sus decretos reglamentarios al correo
                                electr&oacute;nico soporte@loggiapp.com y deber&aacute; contener la siguiente
                                informaci&oacute;n:&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 3.8909988403320312pt;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                                Nombre y documento de identificaci&oacute;n del titular.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 4.209999084472656pt;margin-right: 1.59881591796875pt;text-indent: -17.714000701904297pt;margin-top:0.66302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 17.714000701904297pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                                Una descripci&oacute;n de los hechos que dan lugar al reclamo y el objetivo perseguido
                                (actualizaci&oacute;n, correcci&oacute;n o supresi&oacute;n, o cumplimiento de
                                deberes).&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 3.923999786376953pt;margin-top:0.41748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                                La direcci&oacute;n y datos de contacto e identificaci&oacute;n del
                                reclamante.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 3.868999481201172pt;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                                Acompa&ntilde;arse por toda la documentaci&oacute;n que el reclamante quiera hacer
                                valer.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.7479972839355469pt;margin-right: 1.20574951171875pt;text-indent: -0.15400314331054688pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.15400314331054688pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S. antes de atender el reclamo verificar&aacute; la identidad del titular del dato
                                personal, su representante o apoderado, o la acreditaci&oacute;n de que hubo una
                                estipulaci&oacute;n por otro o para otro. Para ello puede exigir la c&eacute;dula de
                                ciudadan&iacute;a o documento de identificaci&oacute;n original del titular, y los
                                poderes especiales, generales o documentos que se exijan seg&uacute;n sea el
                                caso.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 0.714111328125pt;text-indent: -35.92300033569336pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 35.92300033569336pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.2.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Si
                                el reclamo o la documentaci&oacute;n adicional est&aacute;n incompletos, INNVOVBA S.A.S.
                                requerir&aacute; al reclamante por una sola vez dentro de los cinco (5) d&iacute;as
                                siguientes a la recepci&oacute;n del reclamo para que subsane las fallas. Si el
                                reclamante no presenta la documentaci&oacute;n e informaci&oacute;n requerida dentro de
                                los dos (2) meses siguientes a la fecha del reclamo inicial, se entender&aacute; que ha
                                desistido del reclamo.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 1.51483154296875pt;text-indent: -35.9119987487793pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 35.9119987487793pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.3.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Si
                                por cualquier hecho la persona que recibe el reclamo no es competente para resolverlo,
                                dar&aacute; traslado a la Gerencia General dentro de los dos (2) d&iacute;as
                                h&aacute;biles siguientes a haber recibido el reclamo, e informar&aacute; de dicha
                                remisi&oacute;n al reclamante.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 1.0982666015625pt;text-indent: -35.9119987487793pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 35.9119987487793pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.4.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Una
                                vez recibido el reclamo con la documentaci&oacute;n completa, se incluir&aacute; en la
                                base de datos de INNVOVBA S.A.S. donde reposen los datos del titular sujetos a reclamo,
                                con una leyenda que diga &ldquo;reclamo en tr&aacute;mite&rdquo; y el motivo del mismo,
                                en un t&eacute;rmino no mayor a dos (2) d&iacute;as h&aacute;biles. Esta leyenda
                                deber&aacute; mantenerse hasta que el reclamo sea decidido.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 1.0067138671875pt;text-indent: -35.9119987487793pt;text-align: justify;margin-top:13.917510986328125pt;margin-bottom:0pt;padding:0pt 0pt 0pt 35.9119987487793pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.5.&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                t&eacute;rmino m&aacute;ximo para atender el reclamo ser&aacute; de quince (15)
                                d&iacute;as h&aacute;biles contados a partir del d&iacute;a siguiente a la fecha de su
                                recibo. Cuando no fuere posible atender el reclamo dentro de dicho t&eacute;rmino, se
                                informar&aacute; al interesado los motivos de la demora y la fecha en que se
                                atender&aacute; su reclamo, la cual en ning&uacute;n caso podr&aacute; superar los ocho
                                (8) d&iacute;as h&aacute;biles siguientes al vencimiento del primer
                                t&eacute;rmino.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.957000732421875pt;margin-right: 0.38299560546875pt;text-indent: -18.285999298095703pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 18.285999298095703pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.
                                FECHA DE ENTRADA EN VIGENCIA DE LA POL&Iacute;TICA DE TRATAMIENTO DE LA
                                INFORMACI&Oacute;N Y PER&Iacute;ODO DE VIGENCIA DE LA BASE DE DATOS.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.2980003356933594pt;margin-right: 1.0906982421875pt;text-indent: -0.04399871826171875pt;margin-top:13.917495727539062pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.04399871826171875pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                                presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n de INNVOVBA S.A.S. rige
                                a partir del 01 de Diciembre de 2019.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 1.1989974975585938pt;margin-right: 1.068115234375pt;text-indent: 0.09900283813476562pt;margin-top:13.917495727539062pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                                datos personales que sean recolectados, almacenados, transmitidos, transferidos,
                                procesados y usados permanecer&aacute;n en la base de datos de INNVOVBA S.A.S., con base
                                en el criterio de&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:14.707504272460938pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">26&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6930007934570312pt;margin-right: 0.990234375pt;text-indent: -0.2089996337890625pt;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 0.2089996337890625pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">temporalidad
                                y necesidad, durante el tiempo que sea necesario para las finalidades mencionadas en
                                esta Pol&iacute;tica, para los cuales fueron recolectados.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 0.7259979248046875pt;margin-top:40.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S.&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-left: 1.3309974670410156pt;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">NIT
                                901331232 - 0&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:527.9530029296875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">27&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ANEXO
                                2:</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;text-align: center;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Autorizaci&oacute;n
                                Uso Datos Personales Clientes y Usuarios</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;text-align: center;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;text-align: center;margin-top:0.66302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">NIT
                                901.331.232 - 0</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2286710262298584;margin-left: 0.6709976196289062pt;margin-right: 0.61883544921875pt;text-align: justify;margin-top:14.16302490234375pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
                                cumplimiento de lo previsto en la Ley 1581 de 2012, sobre protecci&oacute;n de datos
                                personales, INNVOVBA S.A.S. le informa que sus datos personales ser&aacute;n
                                recolectados, almacenados en una base de datos, transmitidos, transferidos, procesados y
                                usados para todos aquellos aspectos inherentes a los productos de INNVOVBA S.A.S., lo
                                que
                                implica el uso de los datos que ser&aacute;n usados para los siguientes fines (i)
                                cumplir las obligaciones contra&iacute;das por INNVOVBA S.A.S. con sus clientes y
                                usuarios, (ii) enviar informaci&oacute;n sobre los productos que ofrece INNVOVBA S.A.S.,
                                (iii) enviar informaci&oacute;n sobre cambios en las condiciones de los productos
                                ofrecidos por INNVOVBA S.A.S., (iv) enviar al correo f&iacute;sico, electr&oacute;nico,
                                celular o dispositivo m&oacute;vil, v&iacute;a mensajes de texto (SMS y/o MMS) o a
                                trav&eacute;s de cualquier otro medio an&aacute;logo y/o digital de comunicaci&oacute;n
                                creado o por crearse, informaci&oacute;n comercial, publicitaria o promocional sobre los
                                productos de INNVOVBA S.A.S., eventos y/o promociones de tipo comercial o no de estas,
                                con el fin de impulsar, invitar, dirigir, ejecutar, informar y de manera general, llevar
                                a cabo campa&ntilde;as, promociones o concursos de car&aacute;cter comercial o
                                publicitario, adelantados por TECLOGI S.AS y/o por terceras personas, (v) tratar los
                                datos de los clientes y usuarios de INNVOVBA S.A.S. suministrados por terceros para las
                                finalidades aqu&iacute; se&ntilde;aladas, (vi) transferir o transmitir los Datos
                                Personales a terceras personas en Colombia y en el exterior a t&iacute;tulo gratuito u
                                oneroso para su uso comercial, (vii) elaborar estudios de anal&iacute;tica que incluyan
                                cualquier detalle relacionado con los productos ofrecidos por INNVOVBA S.A.S. con el fin
                                de compartirlos con socios vinculados al negocio de INNVOVBA S.A.S. (viii) transferir o
                                transmitir los datos personales, de los clientes y usuarios de INNVOVBA S.A.S., a
                                Encargados del Tratamiento en Colombia y en el exterior cuando ello sea necesario para
                                la ejecuci&oacute;n de los contratos con los clientes y usuarios de INNVOVBA S.A.S.,
                                (ix)
                                para el fortalecimiento de las relaciones con sus clientes y usuarios mediante el
                                env&iacute;o de informaci&oacute;n relevante, la toma de pedidos y evaluaci&oacute;n de
                                la calidad de los productos de INNVOVBA S.A.S., (x) para la determinaci&oacute;n de
                                obligaciones pendientes, la consulta de informaci&oacute;n financiera e historia
                                crediticia y el reporte a centrales de informaci&oacute;n de obligaciones incumplidas,
                                respecto de sus deudores en los t&eacute;rminos de la Ley 1266 de 2008, (xi) controlar
                                el acceso a las oficinas de INNVOVBA S.A.S., y establecer medidas de seguridad,
                                incluyendo el establecimiento de zonas videovigiladas, (xii) dar respuesta a consultas,
                                peticiones, quejas y reclamos que sean realizadas por los titulares y transmitir los
                                Datos Personales a organismos de control y dem&aacute;s autoridades que en virtud de la
                                ley aplicable deban recibir los Datos Personales, (xiii) registrar los datos personales
                                en los sistemas de informaci&oacute;n de INNVOVBA S.A.S. y en sus bases de datos
                                comerciales y operativas, (xiv) suministrar, compartir, enviar o entregar sus datos
                                personales a empresas filiales, vinculadas, o subordinadas de INNVOVBA S.A.S. ubicadas
                                en
                                Colombia o cualquier otro pa&iacute;s en el evento que dichas compa&ntilde;&iacute;as
                                requieran la informaci&oacute;n para los fines aqu&iacute; indicados, y (xv) los
                                dem&aacute;s fines necesarios para dar cumplimiento a los contratos con los clientes y
                                usuarios de INNVOVBA S.A.S. y dem&aacute;s fines contenidos en la Pol&iacute;tica de
                                Tratamiento de la Informaci&oacute;n de INNVOVBA S.A.S. Usted tiene derecho a conocer,
                                actualizar rectificar y suprimir los datos personales que haya suministrado, que no
                                deriven de un deber legal o contractual, as&iacute; como tener acceso a sus datos, a la
                                prueba y al uso que se les ha dado, y a presentar quejas por violaci&oacute;n a los
                                derechos que&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">28&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.83599853515625pt;margin-right: 1.03460693359375pt;text-indent: 0.36299896240234375pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">le
                                asisten, en los t&eacute;rminos de la Ley 1581 de 2012. El suministro de datos sensibles
                                o sobre los datos de ni&ntilde;as, ni&ntilde;os y adolescentes que usted haga es
                                facultativo. Para ejercer sus derechos como titular de sus datos personales
                                deber&aacute; contactarse con INNVOVBA S.A.S. al correo electr&oacute;nico
                                cto@loggiapp.com. La Pol&iacute;tica de Tratamiento de la Informaci&oacute;n de TECLOGI
                                S.A.S. podr&aacute; ser solicitada al correo electr&oacute;nico
                                cto@loggiapp.com.&nbsp;</span>
                        </p>
                        <p><br></p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6709976196289062pt;margin-right: 0.62945556640625pt;text-indent: 0.6710014343261719pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffff00;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                licenciado autoriza expresamente a&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffff00;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TECLOGI
                                S.A.S.</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffff00;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                                a cualquiera de sus filiales y a cualquiera de sus colaboradores, clientes o a las
                                autoridades, a acceder, procesar y almacenar la informaci&oacute;n de ubicaci&oacute;n y
                                cualquier informaci&oacute;n de telemetr&iacute;a o de cualquier otra naturaleza
                                proporcionada por el sistema de posicionamiento global (GPS) de su dispositivo
                                m&oacute;vil, as&iacute; como el sistema de posicionamiento global (GPS) de su
                                veh&iacute;culo y tr&aacute;iler (cuando aplique), con el fin de realizar la
                                trazabilidad de los servicios de transporte prestados por el usuario o para ofrecer
                                servicios para el transporte de mercanc&iacute;as con empresas habilitadas. Durante el
                                desarrollo de la operaci&oacute;n de transporte y hasta que esta no se termine, no
                                podr&aacute; el contratista (conductor o propietario del veh&iacute;culo) cambiar el
                                usuario o la clave de acceso al GPS.&quot;&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6709976196289062pt;margin-right: 0.62945556640625pt;text-indent: 0.6710014343261719pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                                LICENCIADO manera libre, expresa, voluntaria e informada,&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">AUTORIZO&nbsp;</span><span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                                INNVOVBA S.A.S. y/o a la persona natural o jur&iacute;dica a qui&eacute;n &eacute;ste
                                encargue, a recolectar, almacenar, utilizar, circular, transmitir, transferir, suprimir,
                                comercializar y en general, a realizar cualquier otro tratamiento a los datos personales
                                por mi suministrados, para todos aquellos aspectos inherentes a los productos de TECLOGI
                                S.A.S. lo que implica el uso de los datos para los fines arriba se&ntilde;alados y para
                                actividades de mercadeo, promoci&oacute;n y de ser el caso, cuando la actividad
                                comercial lo requiera, la transferencia y transmisi&oacute;n de los mismos a un tercero
                                (incluyendo terceros pa&iacute;ses), bajo los par&aacute;metros de la Ley 1581 de 2012 y
                                dem&aacute;s normatividad vigente que regule la materia. &nbsp;</span>
                        </p>
                        <p><br></p>
                        <p dir="ltr"
                            style="line-height:1.2267818927764893;margin-left: 0.6709976196289062pt;margin-right: 0.62945556640625pt;text-indent: 0.6710014343261719pt;text-align: justify;margin-top:13.91748046875pt;margin-bottom:0pt;">
                            <span
                                style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                        </p>
                        <p dir="ltr"
                            style="line-height:1.2;margin-right: 3.0640869140625pt;text-align: right;margin-top:433.2074890136719pt;margin-bottom:0pt;">
                            <span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">P&aacute;gina&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de&nbsp;</span><span
                                style="font-size:10pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">29</span>
                        </p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>