<div class="trailers">
    <div class="col-md-12 mb-0 h-100-margin trailers-list-container">
        <form [formGroup]="form" class="row">
            <div class="col-sm-12">
                <app-license-plate #trailerLicensePlate [inputFormControl]="form.get('id')"></app-license-plate>
            </div>
            <mat-form-field class="col-md-4  col-sm-12">
                <mat-label>Ejes <span class="asterisk">*</span></mat-label>
                <input type="number" formControlName="axles" matInput>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-sm-12">
                <mat-label>Modelo <span class="asterisk">*</span></mat-label>
                <input type="text" formControlName="model" matInput>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-sm-12">
                <mat-label>Peso vacío <span class="asterisk">*</span></mat-label>
                <input type="text" formControlName="emptyWeight" matInput>
            </mat-form-field>

            <mat-form-field class="col-md-4 col-sm-12" formGroupName="configuration">
                <mat-label>Clase <span class="asterisk">*</span></mat-label>
                <span class="spinner-input" *ngIf="filterConfigurationLoading">
                    <i class="fas fa-circle-notch"></i>
                </span>
                <input matInput type="text" name="configurationTl" formControlName="description"
                    [matAutocomplete]="autocompleteConfiguration" autocomplete="off">
                <mat-autocomplete autoActiveFirstOption #autocompleteConfiguration="matAutocomplete"
                    (optionSelected)="onSelectConfiguration($event)">
                    <mat-option *ngFor="let configuration of filterConfiguration | async" [value]="configuration">
                        {{configuration.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="form.get('configuration.code').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-4 col-sm-12" formGroupName="bodywork">
                <mat-label>Carrocería<span class="asterisk">*</span></mat-label>
                <input matInput type="text" name="bodyworkTl" formControlName="description"
                    [matAutocomplete]="autocompleteBodywork" autocomplete="off">
                <mat-autocomplete autoActiveFirstOption #autocompleteBodywork="matAutocomplete"
                    (optionSelected)="setCatalogValue('bodywork', $event)">
                    <mat-option *ngFor="let bodywork of filterBodyWork | async" [value]="bodywork">
                        {{bodywork.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="form.get('bodywork.code').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-4 col-sm-12" formGroupName="brand">
                <mat-label>Marca<span class="asterisk">*</span></mat-label>
                <input matInput type="text" name="brandTl" formControlName="description"
                    [matAutocomplete]="autocompleteBrand" autocomplete="off">
                <mat-autocomplete autoActiveFirstOption #autocompleteBrand="matAutocomplete"
                    (optionSelected)="setCatalogValue('brand', $event)">
                    <mat-option *ngFor="let brand of filterTrailerBrand | async" [value]="brand">
                        {{brand.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="form.get('brand.code').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>

            <div class="col-md-6">
                <app-input-attachment [title]="'Tarjeta de Propiedad'" [isDisable]="isDisable"
                    [basePath]="'trailer/'+form.get('id').value+'/'" [inputFormCtrl]="form.get('propertyCard')">
                </app-input-attachment>
                <div class="row">
                    <div class="col-12">
                        <app-files-storage class="width-100" [nameFile]="form.get('propertyCard').value"
                            *ngIf="!!form.get('propertyCard').value">
                        </app-files-storage>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <app-input-attachment [title]="'Foto de Trailer'" [isDisable]="isDisable"
                    [basePath]="'trailer/'+form.get('id').value+'/'" [inputFormCtrl]="form.get('picture')">
                </app-input-attachment>
                <div class="row">
                    <div class="col-12">
                        <app-files-storage class="width-100" [nameFile]="form.get('picture').value"
                            *ngIf="!!form.get('picture').value">
                        </app-files-storage>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="trailers">
    <div class="col-md-12 mb-0 h-100-margin trailers-list-container">
        <form [formGroup]="form" class="row">
            <div class="col-sm-12">
                <app-license-plate #vehicleLicensePlate [inputFormControl]="form.get('licensePlate')"
                    title="Vehículo"></app-license-plate>
                <br>
                <app-basic-person [form]="ownerControls" *ngIf="ownerControls" [title]="'Propietario'"
                    [needTerms]="false"></app-basic-person>
                <span class="separator-horizontal"></span>
                <mat-card>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-label>¿El propietario es el mismo administrador?</mat-label>
                                &nbsp;&nbsp;
                                <mat-button-toggle-group #group="matButtonToggleGroup"
                                    formControlName="hasAdministrator">
                                    <mat-button-toggle [value]="true" (change)="onChangeIsSameAdmin($event)">
                                        Si
                                    </mat-button-toggle>
                                    <mat-button-toggle [value]="false" (change)="onChangeIsSameAdmin($event)">
                                        No
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <span class="separator-horizontal"></span>
                <ng-container *ngIf="!form.get('hasAdministrator').value">
                    <span class="separator-horizontal"></span>
                    <app-basic-person [form]="adminControls" *ngIf="adminControls"
                        [title]="'Administrator'"></app-basic-person>
                </ng-container>
                <!-- <div class="alert alert-warning" role="alert" *ngIf="errorRNDCMessage">
                    <i class="fas fa-exclamation-circle mr-2"></i><span>La información presenta inconsistencias
                        para el RNDC debido
                        al siguiente error: {{ errorRNDCMessage }}</span>
                </div> -->
                <span class="separator-horizontal"></span>
                <mat-card-actions class="mat-card-actions-vehcle text-center" permission-display
                    [module]="permission.administration.module"
                    [functionality]="permission.administration.createVehicle">
                    <button mat-raised-button color="primary" type="button" class="button-width-auto"
                        (click)="submit()">
                        Guardar
                    </button>
                </mat-card-actions>
            </div>
        </form>
    </div>
</div>