import { ChangeDetectorRef, Component, Inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-routing-form',
  templateUrl: './routing-form.component.html',
  styleUrls: ['./routing-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoutingFormComponent {
  @Input() form: FormGroup;
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  firstPassToLastIndex: boolean = false;

  constructor(
    public utils: Utils,
    public snackBarService: SnackBarService,
    private cdRef: ChangeDetectorRef,
  ) { }

  /**
  * @description Verifies if the form's control "file" is filled to skip to last step.
  */
  ngAfterViewChecked(): void {
    if (!!this.form.get('file').value && !this.firstPassToLastIndex) {
      this.stepper.selectedIndex = 3;
      this.firstPassToLastIndex = true;
    }
    this.cdRef.detectChanges();
  }

  /**
  * @returns {boolean} returns true if the form's controls "description" and "date" are valid.
  * @description Verifies if the first step is valid
  */
  isValidStepOne(): boolean {
    return this.form && this.form.get('description') && this.form.get('description').valid && this.form.get('description').value.trim().length >= 3
      && this.form.get('date') && this.form.get('date').valid;
  }

  /**
  * @returns {boolean} returns true if the form's control "schemaName" is valid.
  * @description Verifies if the second step is valid
  */
  isValidStepTwo(): boolean {
    return this.form && this.form.get('schemaName') && this.form.get('schemaName').valid;
  }

  /**
  * @returns {boolean} returns true if the form's control "fleetName" is valid.
  * @description Verifies if the third step is valid
  */
  isValidStepThree(): boolean {
    return this.form && this.form.get('fleetName') && this.form.get('fleetName').valid;
  }

  /**
  * @returns {boolean} returns true if the form's control "file" is valid.
  * @description Verifies if the fourth step is valid
  */
  isValidStepFour(): boolean {
    return this.form && this.form.get('file') && this.form.get('file').valid;
  }

}
