import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateDiscountsAndBonuses, CreateDiscountsAndBonusesModel } from 'src/app/core/interfaces/createDiscountAndBonuses';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DiscountsAndBonusesCargoService } from '../discounts-and-bonuses-cargo/discounts-and-bonuses-cargo.service';
import { Utils } from 'src/app/core/resources/utils';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';


@Component({
  selector: 'app-create-discounts-and-bonuses',
  templateUrl: './create-discounts-and-bonuses.component.html',
  styleUrls: ['./create-discounts-and-bonuses.component.scss'],
  providers: [CreateDiscountsAndBonusesModel, DiscountsAndBonusesCargoService]
})
export class CreateDiscountsAndBonusesComponent extends ReactiveForm implements OnInit {
  title = 'Crear Modificacion'
  constructor(
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private service: DiscountsAndBonusesCargoService,
    public formBuilder: FormBuilder,
    private model: CreateDiscountsAndBonusesModel,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {},
    public dialogRef: MatDialogRef<CreateDiscountsAndBonusesComponent>,
    public dialog: MatDialog,
    public utils: Utils,
  ) {
    super(
      formBuilder,
      model.model
    );
    this.setValidatorsForm(
      this.model.modelValidators,
      this.form
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.form.valid) {
      this.addTypeDiscountsAndBonuses();
    } else {
      if (this.utils.errorMessagesCustomized(this.form.get('type'), 'tipo de acción')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('name'), 'nombre', 5)) return;
      else this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
    }
  }

  onCancel() {
    this.dialogRef.close({
    });
  }

  addTypeDiscountsAndBonuses() {
    this.spinner.show();
    this.service.createModifications(this.form.value as CreateDiscountsAndBonuses)
      .toPromise()
      .then((success) => {
        this.snackBarService.openSnackBar('Modificacion creada correctamente');
        this.dialogRef.close({
          state: true
        });
      })
      .catch((error) => {
        console.error(error);
        this.snackBarService.openSnackBar('Ocurrió un error al realizar este proceso', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

}
