import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Receivable } from 'src/app/core/interfaces/receivable';
@Injectable({
  providedIn: 'root'
})
export class ReceivableService {

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
  ) { }

  getReceivableList(cargoConsecutive?, consecutive?, document?, licensePlate?, state?, type?) {
    let params = new HttpParams();
    let paramsFilter = '';
    const data = {
      cargoConsecutive,
      consecutive,
      document,
      licensePlate,
      state
    };
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        params = params.append(key, value);
        if (key !== 'state' && type === 'storage') {
          paramsFilter += `&${key}=${value}`
        }
      }
    }
    if (paramsFilter) {
      this.viewParamsFilter(paramsFilter);
    }
    return this.http.get<Receivable>(
      `${environment.urlServerTeclogi}${this.endpointResources.urlReceivables}`, { params }
    );
  }

  viewParamsFilter(paramsFilter) {
    if (paramsFilter) {
      if (paramsFilter.startsWith('&')) {
        paramsFilter = paramsFilter.replace('&', '').trim();
      }
    }
    if (paramsFilter !== '') {
      sessionStorage.setItem("_filterListReceivables", paramsFilter);
    }
  }

  createReceivable(receivable: Receivable) {
    const url = `${environment.urlServerTeclogi}${this.endpointResources.urlReceivablesCreation}`
    return this.http.post(
      url, receivable
    )
  }

  deleteReceivable(receivableId: string, data: object) {
    return this.http.request('delete',
      `${environment.urlServerTeclogi}${this.endpointResources.deleteReceivable}${receivableId}`,
      { body: data }
    );
  }

  approveModificationReceivable(approved: string, id: string, modificationId: string) {
    let params = new HttpParams();
    const data = {
      approved,
      id,
      modificationId
    };
    for (const [key, value] of Object.entries(data)) {
      if (value) {
        params = params.append(key, value);
      }
    }
    const url = `${environment.urlServerTeclogi}${this.endpointResources.urlReceivableApproval}`;
    return this.http.put(url, null, { params });
  }

  getUserReceivable(document) {
    return this.http.get(`${environment.urlServerTeclogi}${this.endpointResources.urlCreateUser}/${document}`)
  }

  getUserClientReceivable(document) {
    return this.http.get(`${environment.urlServerTeclogi}${this.endpointResources.urlDetailUserClientByDocument}${document}`)
  }
}
