<form [formGroup]="form" (ngSubmit)="onSubmit()" class="row">
  <div class="col-md-4" formGroupName="cargoModel">
    <div formGroupName="packagingType">
      <mat-form-field appearance="standard">
        <mat-label>Tipo de empaque<span class="asterisk">*</span></mat-label>
        <mat-select (selectionChange)="onSelectPackagingType($event)" formControlName="description">
          <mat-option *ngFor="let packagingType of filteredPackagingTypes" [value]="packagingType.description">
            {{ packagingType.description }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="packagingType.get('description').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>

  </div>
  <div class="col-md-4" formGroupName="cargoFeature">
    <app-cargo-type-merchandise [inputFormControl]="productTypeCtrl" [options]="optionsTypeMerchandise"
      [validate]="validateTypeMerchandise">
    </app-cargo-type-merchandise>
  </div>
  <div class="col-md-1 d-flex align-items-center" *ngIf="activeDangerousProductType">
    <i class="fas fa-exclamation-circle cursor-pointer text-warning" [matTooltip]="messageTypeMerchandise"
      matTooltipPosition="above"></i>
  </div>
  <div class="col-md-3" formGroupName="cargoFeature" *ngIf="canBeRefrigerated">
    <div formGroupName="vehicleType">
      <label class="text-strong">¿Es Refrigerado?&nbsp;&nbsp;</label>
      <mat-button-toggle-group formControlName="quality" #group="matButtonToggleGroup">
        <mat-button-toggle [value]="'Refrigerado'">
          Si
        </mat-button-toggle>
        <mat-button-toggle [value]="''">
          No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="col-md-12" formGroupName="cargoFeature">
    <div class="row" formGroupName="cargoMeasure">
      <div class="col-md-4">
        <mat-form-field appearance="standard">
          <mat-label>Peso total<span class="asterisk">*</span></mat-label>
          <input type="text" currencyMask onlyNumbersInt min="0" matInput placeholder="Peso"
            formControlName="totalWeigth">
          <mat-error *ngIf="cargoMeasure.get('totalWeigth').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="cargoMeasure.get('totalWeigth').errors?.min">
            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'peso', minTotalWeight) }}
          </mat-error>
          <mat-error *ngIf="cargoMeasure.get('totalWeigth').errors?.max">
            {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'peso', dataVehicle ? dataVehicle.weight:
            amountsCargoEnum.MAX_WEIGHT_ALLOWED_KG) }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="standard">
          <mat-label>Unidad<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="unit" placeholder="Unidad">
            <mat-option [value]="'1'">
              Kilos
            </mat-option>
            <mat-option [value]="'2'">
              Galones
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="standard">
          <mat-label>Valor de la carga<span class="asterisk">*</span></mat-label>
          <input type="text" currencyMask onlyNumbersInt min="0" matInput placeholder="Escribe un valor COP"
            formControlName="amount">
          <mat-error *ngIf="cargoMeasure.get('amount').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="cargoMeasure.get('amount').errors?.min">
            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor', minAmount) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div formGroupName="cargoFeature" class="col-md-4">
    <mat-card-content>
      <app-type-vehicle [options]="optionsTypeVehicle" [inputFormControl]="vehicleTypeControl"
        [validate]="validateTypeVehicle">
      </app-type-vehicle>
    </mat-card-content>
  </div>
  <div formGroupName="cargoFeature" class="col-md-4">
    <mat-card-content>
      <app-vehicle-body-work-type *ngIf="vehicleType?.get('name')?.value" [inputFormControl]="bodyworkTypeControl"
        [validate]="validateBodyworkType" [options]="optionsBodyworkType">
      </app-vehicle-body-work-type>
    </mat-card-content>
  </div>
</form>