<aside id="mainComponent" [ngClass]="{'vh-100': authService.sessionActive() && termsAndConditionsActive}">
  <div id="mainHeader">
    <app-header #appHeader></app-header>
  </div>
  <div id="mainBody"
    [ngClass]="{'body-grid':authService.sessionActive() && termsAndConditionsActive, 'nav-hide': navCollapse && !navHover, 'body-grid--hover': !navCollapse && navHover}">
    <div id="navHamburger" (mouseenter)="mouseEvent($event)" (mouseleave)="mouseEvent($event)"
      *ngIf="authService.sessionActive() && termsAndConditionsActive">
      <app-nav [orientation]="'vertical'"></app-nav>
    </div>
    <div id="content">
      <app-alertrndc *ngIf="rndcContingency && authService.sessionActive()"></app-alertrndc>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
      <div class="panel-preview-notification">
        <app-preview-notification *ngFor="let notification of listNotifications; let i = index;"
          (onClose)="onClose($event)" [index]="i" [data]="notification.data"></app-preview-notification>
      </div>
    </div>
  </div>
  <div id="footer" *ngIf="!authService.sessionActive()">
    <app-footer></app-footer>
  </div>
</aside>


<img class="btn__wa" *ngIf="isOutOfCargoCreate && !authService.sessionActive()" src="/assets/img/whatsapp_icon.png"
  width="44" height="44" alt="Whatsapp" (click)="goToWA()">