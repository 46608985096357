import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AdminUsersService } from '../admin-users.service';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { User } from 'src/app/core/interfaces/user';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UserFormComponent } from '../user-form/user-form.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Utils } from 'src/app/core/resources/utils';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';

@Component({
  selector: 'app-admin-users-item',
  templateUrl: './admin-users-item.component.html',
  styleUrls: ['./admin-users-item.component.scss']
})
export class AdminUsersItemComponent {
  permission = Permission;
  @Input() user: User;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private utils: Utils,
    public permissionRole: PermissionRole
  ) { }

  /**
  * @returns {string} returns the prefix of each user.information.documentTypeId.
  * @description transforms the user's documentTypeId into a readable prefix.
  */
  get getDocumentType(): string {
    if (this.user.information) {
      switch (this.user.information.documentTypeId) {
        case '1':
          return "C.C: ";
        default:
          return this.user.information.documentTypeId;
      }
    }
  }

  /**
  * @description if the user has the permission, allows to watch the detail of the user selected
  * by openDetailDriver method (if the user is pending to validate) or by navigate (in othercase).
  */
  selectUser(): void {
    if (!this.showDriverDetail) return;
    if (this.user.state && this.user.state.description === 'Pendiente validar documentos') {
      this.openDetailDriver();
    } else {
      AdminUsersService.userSelected = this.user;
      if (this.user.role !== 'Driver' && this.user.role !== 'Owner' && !this.utils.isEmpty(this.user.role)) {
        this.router.navigate(['/administration/admin-users/detail-user-client-form/', this.user.information.document]);
      } else {
        this.router.navigate(['/administration/admin-users/detail/', this.user.information.document]);
      }
    }
  }

  /**
  * @description opens a modal to show the user's detail and emits an output to refresh the list.
  */
  openDetailDriver() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      driver: this.user
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(UserFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state && this.emitToParent) {
        this.emitToParent.emit();
      }
    });
  }

  /**
  * @returns {boolean} returns true if has the permission to read user's details, false in othercase.
  * @description checks if has permission to read users details.
  */
  get showDriverDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readDriverDetails);
  }
}
