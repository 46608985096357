import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { MatSelectChange } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { AccountService } from 'src/app/modules/account/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/services/authentication.service';
import { v4 as uuidv4 } from 'uuid';
import { ReceivableService } from 'src/app/modules/administration/receivable/receivable.service';
import { UserClient } from 'src/app/core/interfaces/userClient';


@Component({
  selector: 'app-user-document-info',
  templateUrl: './user-document-info.component.html',
  styleUrls: ['./user-document-info.component.scss']
})
export class UserDocumentInfoComponent implements OnInit {
  @Input() docInfo: FormGroup;
  @Input() validate: string;
  documentTypes: CatalogItem[];
  private uuid: string;
  listFiltered: UserClient[] = [];
  public get documentId(): string { return this.uuid; };

  constructor(
    public utils: Utils,
    public global: Global,
    public accountService: AccountService,
    public spinner: NgxSpinnerService,
    private authService: AuthService,
    private receivableService: ReceivableService
  ) {
    this.documentTypes = this.utils.clone(this.global.documenTypes);
    this.uuid = uuidv4();
  }

  ngOnInit() {
    this.docInfo.get('name').disable();
    this.docInfo.get('documentTypeName').disable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.validate && changes.validate.currentValue && changes.validate.currentValue === "touched") {
      this.docInfo.markAllAsTouched();
    }
  }

  onChangeDocumentType($event: MatSelectChange) {
    const documentType = this.documentTypes.filter((obj) => {
      return obj.name === $event.value;
    });
    this.docInfo.get('documentTypeId').setValue(documentType[0].id);
  }

  searchUser() {
    const data = this.docInfo.get('document').value;
    const value =
      typeof data === "object" ? (data ? data.information.document : "") : data;
    if (typeof data === "object" || typeof data === "string") {
      if (value.trim().length >= 1) {
        this.filterUsers(value || "").subscribe((users) => {
          this.listFiltered = users;
          this.documentHtml.click();
        });
      }
    } else {
      this.listFiltered = [];
    }
  }



  private filterUsers(value: string): Observable<any[]> {
    this.spinner.show();
    return this.receivableService.getUserClientReceivable(value).pipe(
      map((response: UserClient[]) => {
        this.spinner.hide();
        if (response && response.length) {
          if (response[0].clientCompanyId === this.authService.getUserSession().clientCompanyId) {
            return [response[0]];
          } else {
            return [];
          }
        } else {
          this.docInfo.get('document').setValue(value);
          return [];
        }
      })
    );
  }

  onSelectUser($event) {
    this.docInfo.get('documentTypeName').setValue($event.option.value.information.documentTypeName);
    this.docInfo.get('documentTypeId').setValue($event.option.value.information.documentTypeId);
    this.docInfo.get('document').setValue($event.option.value.information.document);
    this.docInfo.get('name').setValue($event.option.value.information.name);
  }

  private get documentHtml(): HTMLElement {
    return document.getElementById(this.documentId);
  }
}
