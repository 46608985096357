<div class="row card-edit m-0 w-100" #myDialogContainer>
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="col-md-12">
    <label id="myElement" class="title-component">
      {{data.title}}
    </label>
  </div>
  <div class="col-md-12 d-flex justify-content-center flex-column table-responsive">
    <table>
      <tr>
        <th class="begin">Numero de manifiesto</th>
        <th>Fecha de manifiesto</th>
        <th>Compañia</th>
        <th class="end">Placa</th>
      </tr>
      <tr *ngFor="let manifest of manifests">
        <td data-cell="Numero de manifiesto">{{manifest.ministryId}}</td>
        <td data-cell="Fecha de manifiesto">{{ manifest.manifestDate}}</td>
        <td data-cell="Compañia">{{manifest.companyName}}</td>
        <td data-cell="Placa">{{ manifest.licensePlate }}</td>
      </tr>
    </table>
  </div>
  <mat-card-actions class="text-center">
    <button mat-raised-button class="button-width-auto" color="primary" (click)="dialogRef.close()">
      Cerrar
    </button>
  </mat-card-actions>
</div>