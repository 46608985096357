
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './authentication.service';
import { SnackBarService } from './snackBar.service';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { ServiceMessages } from '../messages/service-messages.enum';

@Injectable()
export class MessagingService {

    public messaging: any;
    public currentMessage: any;

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private authService: AuthService,
        private snackBar: SnackBarService
    ) {
        if ('serviceWorker' in navigator && firebase.messaging.isSupported()) {
            // this.messaging = AuthService.fMessaging;
            //this.currentMessage = new BehaviorSubject(null);

            /*this.angularFireMessaging.messaging.subscribe(
                (messaging) => {
                    messaging.onMessage = messaging.onMessage.bind(messaging);
                    messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
                }
            );*/
        }
    }

    public getPermission() {
        this.messaging.requestPermission()
            .then(() => {
                return this.messaging.getToken();
            })
            .then((token) => {
                this.authService.updateUId(token).subscribe(() => { }, (error) => { });
            })
            .catch((error) => {
                this.snackBar.openSnackBar(ServiceMessages.CANNOT_SEND_NOTIFICATIONS, undefined, 'alert')
            });
    }

}
