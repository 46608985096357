import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { CreateGuidesFromExcelService } from './create-guides-from-excel.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { Company } from 'src/app/core/interfaces/company';
import { Utils } from 'src/app/core/resources/utils';
import { FieldsColumnGuide } from 'src/app/core/interfaces/fieldsColumnGuide';
import { ReadXLSX } from 'src/app/core/resources/readXLSX';
import { SelectCompanyComponent } from 'src/app/shared/select-company/select-company.component';
import { FileComponent } from 'src/app/shared/files/file.component';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { DateManager } from 'src/app/core/managers/date.manager';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM-DD',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM-DD',
  },
};

@Component({
  selector: 'app-create-guides-from-excel',
  templateUrl: './create-guides-from-excel.component.html',
  styleUrls: ['./create-guides-from-excel.component.scss'],
  providers: [AuthService, Global, DatePipe]
})
export class CreateGuidesFromExcelComponent implements OnInit {

  guidesFromExcelForm: FormGroup;
  fieldsExcelForm: FormGroup;
  companySub: Subscription;
  sheetSub: Subscription;
  companyUserData: Company;
  jsonXLSX: any;
  listTitleFieldsColumns: { id, title, disabled }[];
  titleFieldTemporal: object = {};
  sheets: Array<any>;
  sheetName: string;
  previousSheetName: string;
  titlesFile: any;
  @ViewChild(SelectCompanyComponent, { static: false }) selectCompanyComponent: SelectCompanyComponent;
  @ViewChildren(FileComponent) fileComponent: QueryList<FileComponent>;
  optionsCompany = {
    title: 'Compañía'
  };
  constructor(
    private createGuidesFromExcelService: CreateGuidesFromExcelService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    private readXLSX: ReadXLSX,
    private global: Global,
    public datePipe: DatePipe

  ) {
    this.companyUserData = this.authService.getCompany();
    this.listTitleFieldsColumns = this.utils.clone(this.global.fieldsGuide);
  }

  ngOnInit() {
    this.createGuidesFromExcelForm();
    this.createFieldsExcelForm();
  }

  isPreConfiguration(company: Company) {
    let state = false;
    if (this.utils.isDefined(company) && this.utils.isDefined(company.fieldsExcelGuide)) {
      state = true;
    }
    return state;
  }

  setPreConfigFieldsCompany(company: Company) {
    this.listTitleFieldsColumns = this.utils.clone(this.global.fieldsGuide);
    if (this.titlesFile) {
      this.titleFieldTemporal = this.utils.clone(this.titlesFile);
    }
    this.createFieldsExcelForm();
    if (this.isPreConfiguration(company)) {
      for (const field in company.fieldsExcelGuide) {
        if (field !== 'companyId' && !this.utils.isEmpty(company.fieldsExcelGuide[field])) {
          if (!this.utils.isEmpty(this.fieldsExcelForm.controls[field])) {
            this.fieldsExcelForm.controls[field].setValue((company.fieldsExcelGuide[field]));
          }
          if (!this.utils.isEmpty(this.guidesFromExcelForm.controls[field])) {
            this.guidesFromExcelForm.controls[field].setValue((company.fieldsExcelGuide[field]));
          }
          if (!this.utils.isEmpty(this.fieldsExcelForm.controls[field])) {
            this.onChangeTitleField(field, (company.fieldsExcelGuide[field]));
          }
        }
      }
    }
  }

  // setPreConfigTemporalFields() {

  //   // tslint:disable-next-line: forin
  //   for (const field in this.companyUserData.fieldsExcelGuide) {
  //     this.onChangeTitleField(field, (this.companyUserData.fieldsExcelGuide[field]));
  //   }
  // }

  createGuidesFromExcelForm() {

    this.guidesFromExcelForm = new FormGroup({
      file: new FormControl(null, [Validators.required]),
      firstRow: new FormControl('', [Validators.required]),
      companyId: new FormControl('', [Validators.required]),
      date: new FormControl(new Date(), [Validators.required]),
      sheetName: new FormControl('', [Validators.required]),
      isReverseLogistics: new FormControl(false, [Validators.required])
    });

    this.sheetSub = this.guidesFromExcelForm.get('sheetName').valueChanges.subscribe(selectedValue => {
      if (this.sheetName !== selectedValue.key) {
        this.listTitleFieldsColumns = this.utils.clone(this.global.fieldsGuide);
        this.titleFieldTemporal = null;
        this.handleFileInput([this.guidesFromExcelForm.get('file').value.fileData.file], selectedValue);
      }
    });

    this.companySub = this.guidesFromExcelForm.get('companyId').valueChanges.subscribe((value) => {
      this.setPreConfigFieldsCompany(value);
    });
  }

  createFieldsExcelForm() {

    this.fieldsExcelForm = new FormGroup({
      clientId: new FormControl(''),
      clientName: new FormControl('', [Validators.required]),
      clientEmail: new FormControl(''),
      clientCellPhone: new FormControl(''),
      packagesNumber: new FormControl(''),
      destinationAddress: new FormControl('', [Validators.required]),
      addressComplement: new FormControl(''),
      zone: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
      neighborhood: new FormControl(''),
      product: new FormControl('', [Validators.required]),
      guideId: new FormControl('', [Validators.required]),
      productId: new FormControl(''),
      destination: new FormControl('', [Validators.required]),
      cost: new FormControl(''),
      origin: new FormControl('', [Validators.required]),
      store: new FormControl(''),
      deadLine: new FormControl('')
    });
  }

  handleFileInput($event, sheetName?: any) {
    if ($event && $event.length) {
      this.jsonXLSX = null;
      this.listTitleFieldsColumns = this.utils.clone(this.global.fieldsGuide);
      this.titleFieldTemporal = {};
      this.titlesFile = null;
      this.createFieldsExcelForm();
      this.readXLSX.readFile(
        $event,
        this.guidesFromExcelForm.controls.firstRow.value || 0,
        sheetName
      )
        .then((result: { titles, jsonXLSX, sheets, sheetName }) => {
          if (result && result.jsonXLSX) {
            if (result.titles) {
              this.titlesFile = this.utils.clone(result.titles);
              this.titleFieldTemporal = result.titles;
            }
            if (result.sheetName) {
              this.sheetName = result.sheetName.key;
              this.guidesFromExcelForm.get('sheetName').setValue(result.sheetName);
            }
            if (result.sheets) {
              this.sheets = result.sheets;
            }
            this.jsonXLSX = result.jsonXLSX;
            this.setPreConfigFieldsCompany(this.guidesFromExcelForm.get('companyId').value);
          } else {
            this.clearData();
          }
        })
        .catch((error) => {
          this.clearData();
        });
    }
  }

  clearData() {
    this.jsonXLSX = null;
    this.titleFieldTemporal = null;
    this.sheets = null;
    this.sheetName = null;
  }

  getRowXLSXToShow() {

    if (this.jsonXLSX && this.sheetName) {
      return this.jsonXLSX[this.sheetName][this.guidesFromExcelForm.controls.firstRow.value || 0];
    }
    return {};
  }

  getObjetKeys(object) {

    return object ? Object.keys(object) : [];
  }

  onChangeTitleField($event, index) {

    const nameField = $event.value || $event;
    const objectTitleField = _.find(this.listTitleFieldsColumns, (title) => {
      return title.id === nameField;
    });
    if (objectTitleField) {
      try {
        objectTitleField.disabled = true;
        this.titleFieldTemporal[index].title = objectTitleField.title;
        this.titleFieldTemporal[index].value = nameField;
        this.fieldsExcelForm.controls[nameField].setValue(index);
      } catch (error) {

      }
    }
  }

  clearValueTitle(value, index) {

    this.fieldsExcelForm.controls[value].setValue(null);
    this.titleFieldTemporal[index].value = null;
    const objectTitleField = _.find(this.listTitleFieldsColumns, (title) => {
      return title.id === value;
    });
    if (objectTitleField) {
      objectTitleField.disabled = false;
    }
  }

  onSubmit() {
    this.guidesFromExcelForm.get('companyId').markAllAsTouched();
    if (this.guidesFromExcelForm.invalid) {
      if (this.guidesFromExcelForm.get('file').invalid) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.FILE_NOT_UPLOADED, 'creación de guias'), undefined, 'alert');
        return;
      }
      else if (this.utils.errorMessagesCustomized(this.guidesFromExcelForm.get('companyId'), 'compañia')) return;
      else if (this.utils.errorMessagesCustomized(this.guidesFromExcelForm.get('date'), 'fecha')) return;
      else if (this.utils.errorMessagesCustomized(this.guidesFromExcelForm.get('firstRow'), 'no. fila inicial')) return;
      else if (this.utils.errorMessagesCustomized(this.guidesFromExcelForm.get('sheetName'), 'hoja')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
        return;
      }
    } else if (this.fieldsExcelForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('clientName'), 'nombre del cliente')) return;
      else if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('destinationAddress'), 'dirección de destino')) return;
      else if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('product'), 'producto')) return;
      else if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('guideId'), 'id de la guía')) return;
      else if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('destination'), 'destino')) return;
      else if (this.utils.errorMessagesCustomized(this.fieldsExcelForm.get('origin'), 'origen')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
        return;
      }
    }
    this.spinner.show();

    const formData = new FormData();
    const fields: FieldsColumnGuide = (this.utils.getObjWithValues(this.fieldsExcelForm.value) as FieldsColumnGuide);
    fields.firstRow = this.guidesFromExcelForm.value.firstRow;
    fields.companyId = this.guidesFromExcelForm.value.companyId.nit;

    formData.append('date', DateManager.formatDate(this.guidesFromExcelForm.value.date, 'YYYY-MM-DD', 'YYYY-MM-DD'));
    formData.append('fields', JSON.stringify(fields));
    formData.append('file', this.guidesFromExcelForm.value.file.fileData.file);
    formData.append('sheet', this.guidesFromExcelForm.get('sheetName').value.value);
    formData.append('isReverseLogistics', this.guidesFromExcelForm.get('isReverseLogistics').value);

    this.createGuidesFromExcelService.createGuidesFromExcel(formData).subscribe(
      (success) => {
        this.selectCompanyComponent.originListCompanies();
        this.resetFields();
        this.spinner.hide();
        this.snackBarService.openSnackBar('Guías creadas exitosamente');
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Se generó un error intentando crear las guías', undefined, 'error');
      }
    );
  }

  resetFields() {
    this.selectCompanyComponent.companyCtrl.setValue('');
    this.createGuidesFromExcelForm();
    this.createFieldsExcelForm();
    this.setPreConfigFieldsCompany(this.guidesFromExcelForm.get('companyId').value);
    this.jsonXLSX = null;
    this.listTitleFieldsColumns = this.utils.clone(this.global.fieldsGuide);
    this.titleFieldTemporal = {};

    this.titlesFile = null;
    this.sheets = null;
    this.sheetName = null;
    this.fileComponent.toArray().map((component) => {
      component.deleteFile();
    });
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
    if (this.sheetSub) this.sheetSub.unsubscribe();
  }
}
