import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { ViewTicketInfoComponent } from './view-ticket/view-ticket-info/view-ticket-info.component';
import { ItemTicketComponent } from './list-ticket/item-ticket/item-ticket.component';
import { ListTicketComponent } from './list-ticket/list-ticket/list-ticket.component';

const routes: Routes = [
    {
        path: '',
        data: { breadcrumb: 'Tickets' },
        children: [
            {
                path: 'create',
                data: { breadcrumb: 'Creación' },
                component: ViewTicketInfoComponent,
                canActivate: [UserActiveGuard],
                resolve: {
                    UserResolver
                },
            },
            {
                path: 'list',
                data: { breadcrumb: 'Casos' },
                component: ListTicketComponent,
                canActivate: [UserActiveGuard],
                resolve: {
                    UserResolver
                },
            },
            {
                path: 'detail/:id',
                data: { breadcrumb: 'Caso' },
                component: ViewTicketInfoComponent,
                canActivate: [UserActiveGuard],
                resolve: {
                    UserResolver
                },
            },
            { path: '**', redirectTo: '/help-desk/list' }
        ],
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class HelpDeskRoutingModule { }