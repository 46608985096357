import { CargoMeasure } from "./cargo-measure.model";
import { DurationTimeAddress } from "./duration-time-address.model";
import { ImagesEvidence } from "./images-evidence.model";
import { LocationAddress } from "./location-address.model";

export class AddressCargo {

    private address: string;
    private nameCompany: string;
    private nameResponsible: string;
    private cellphone: string;
    private id: string;
    private imagesTruck?: ImagesEvidence[] = [];
    private imagesDocument?: ImagesEvidence[] = [];
    private time?: string;
    private location?: LocationAddress = new LocationAddress();
    private email?: string;
    private timeType?: string;
    private guideId?: string;
    private state?: string;
    private durationTime?: DurationTimeAddress = new DurationTimeAddress();
    private startDate?: string;
    private cargoMeasure?: CargoMeasure = new CargoMeasure();

    public getAddress(): string {
        return this.address;
    }

    public setAddress(address: string): void {
        this.address = address;
    }

    public getNameCompany(): string {
        return this.nameCompany;
    }

    public setNameCompany(nameCompany: string): void {
        this.nameCompany = nameCompany;
    }

    public getNameResponsible(): string {
        return this.nameResponsible;
    }

    public setNameResponsible(nameResponsible: string): void {
        this.nameResponsible = nameResponsible;
    }

    public getCellphone(): string {
        return this.cellphone;
    }

    public setCellphone(cellphone: string): void {
        this.cellphone = cellphone;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string): void {
        this.id = id;
    }

    public getImagesTruck(): ImagesEvidence[] {
        return this.imagesTruck || [new ImagesEvidence()];
    }

    public setImagesTruck(imagesTruck: ImagesEvidence[]): void {
        imagesTruck.map((object) => {
            const image = new ImagesEvidence();
            image.deserialize(object);
            this.imagesTruck.push(image);
        });
    }

    public getImagesDocument(): ImagesEvidence[] {
        return this.imagesDocument || [new ImagesEvidence()];
    }

    public setImagesDocument(imagesDocument: ImagesEvidence[]): void {
        imagesDocument.map((object) => {
            const image = new ImagesEvidence();
            image.deserialize(object);
            this.imagesDocument.push(image);
        });
    }

    public getTime(): string {
        return this.time;
    }

    public setTime(time: string): void {
        this.time = time;
    }

    public getLocation(): LocationAddress {
        return this.location;
    }

    public setLocation(location: LocationAddress): void {
        this.location = new LocationAddress();
        this.location.deserialize(location);
    }

    public getEmail(): string {
        return this.email;
    }

    public setEmail(email: string): void {
        this.email = email;
    }

    public getTimeType(): string {
        return this.timeType;
    }

    public setTimeType(timeType: string): void {
        this.timeType = timeType;
    }

    public getGuideId(): string {
        return this.guideId;
    }

    public setGuideId(guideId: string): void {
        this.guideId = guideId;
    }

    public getState(): string {
        return this.state;
    }

    public setState(state: string): void {
        this.state = state;
    }

    public getDurationTime(): DurationTimeAddress {
        return this.durationTime || new DurationTimeAddress();
    }

    public setDurationTime(durationTime: DurationTimeAddress): void {
        this.durationTime = new DurationTimeAddress();
        this.durationTime.deserialize(durationTime);
    }

    public getStartDate(): string {
        return this.startDate;
    }

    public setStartDate(startDate: string): void {
        this.startDate = startDate;
    }

    public getCargoMeasure(): CargoMeasure {
        return this.cargoMeasure || new CargoMeasure();
    }

    public setCargoMeasure(cargoMeasure: CargoMeasure): void {
        this.cargoMeasure = new CargoMeasure();
        this.cargoMeasure.deserialize(cargoMeasure);
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
