import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import * as _ from 'lodash';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { element } from 'protractor';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, filter } from 'rxjs/operators';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-type-vehicle',
  templateUrl: './type-vehicle.component.html',
  styleUrls: ['./type-vehicle.component.scss'],
  providers: [AuthService, Global]
})
export class TypeVehicleComponent implements OnInit {
  // @Input() edit: boolean;

  listVehicles: Observable<VehicleCargo[]>;
  vehicles: VehicleCargo[] = [];
  vehiclesList: VehicleCargo[] = [];
  @Input() inputFormControl: FormControl;
  vehicleCtrl: FormControl = new FormControl();
  @Input() options: OptionsAutocomplete;
  @Input() validate: string = '';

  @Output() vehicleType = new EventEmitter<string>();
  constructor(
    private spinner: NgxSpinnerService,
    public manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
    private router: Router,
    public utils: Utils
  ) {
  }

  ngOnInit() {
    //if (this.options && this.options.showAutocomplete) this.vehicleCtrl.setValidators(Validators.required);
    if (this.options && this.options.showAutocomplete) this.initVehicles();
    else this.getVehicleType();
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.vehicleCtrl.setValidators(Validators.required);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.options && changes.options.currentValue) {
        if (changes.options.currentValue.initialValue || changes.options.currentValue.initialValue === "") {
          if (changes.options.currentValue.initialValue === "") this.vehicleCtrl.setValue('');
          else {
            if (this.inputFormControl) this.vehicleCtrl.setValue({ name: changes.options.currentValue.initialValue })
          }
        }
      }
      if (changes.validate) {
        switch (this.validate) {
          case 'touched':
            this.vehicleCtrl.markAsTouched();
            break;
          case 'untouched':
            this.vehicleCtrl.markAsUntouched();
            break;
          case 'enable':
            this.vehicleCtrl.enable();
            break;
          case 'disable':
            this.vehicleCtrl.disable();
            break;
          case 'disable&untouched':
            this.vehicleCtrl.markAsUntouched();
            this.vehicleCtrl.disable();
            break;
          case 'setValidators':
            this.vehicleCtrl.setValidators(Validators.required);
            this.vehicleCtrl.updateValueAndValidity();
            break;
          case 'clearValidators':
            this.vehicleCtrl.clearValidators();
            this.vehicleCtrl.updateValueAndValidity();
            break;
          default:
            break;
        }
      }
    }

  }

  changeValue() {
    if (this.inputFormControl) this.inputFormControl.setValue('');
  }

  async initVehicles() {
    await this.getVehicleType();
    this.vehiclesList = this.vehicles;
    this.initAutocomplete();
  }

  getVehicleType(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.spinner.show();
      try {
        const data = await this.manualCreationCargoService.getVehicleType().toPromise();
        let vehiclesData = data.catalog ? data.catalog : this.manualCreationCargoService.listVehicles;
        this.vehicles = vehiclesData.map((item) => {
          if (item.id !== '24' && item.id !== '41') {
            item.selected = false;
            return item;
          }
          return null;
        }).filter(item => item !== null);
        if (this.manualCreationCargoService.vehiclesSelected.length > 0) {
          for (let vehicle of this.vehicles) {
            if (this.manualCreationCargoService.vehiclesSelected.find(element => element.name == vehicle.name)) {
              vehicle.selected = true;
            } else {
              vehicle.selected = false;
            }
          }
        }
        if (this.vehicles && this.vehicleCtrl && this.vehicleCtrl.value && this.vehicleCtrl.value.name) {
          const selected = this.vehicles.find(car => car.name === this.vehicleCtrl.value.name);
          selected && this.vehicleType.emit(JSON.stringify(selected));
        }
        resolve();
      } catch (error) {
        console.error("Error obteniendo tipos de vehículos:", error);
        this.spinner.hide();
        const modelListVehicles = JSON.stringify(this.manualCreationCargoService.listVehicles);
        const modelListVehiclesObj = JSON.parse(modelListVehicles);
        this.vehicles = modelListVehiclesObj.map((item) => {
          if (item.id !== '24' && item.id !== '41') {
            item.selected = false;
            return item;
          }
          return null;
        }).filter(item => item !== null);
        if (this.manualCreationCargoService.vehiclesSelected.length > 0) {
          for (let vehicle of this.vehicles) {
            if (this.manualCreationCargoService.vehiclesSelected.find(element => element.name == vehicle.name)) {
              vehicle.selected = true;
            } else {
              vehicle.selected = false;
            }
          }
        }
        if (this.vehicles && this.vehicleCtrl && this.vehicleCtrl.value && this.vehicleCtrl.value.name) {
          const selected = this.vehicles.find(car => car.name === this.vehicleCtrl.value.name);
          selected && this.vehicleType.emit(JSON.stringify(selected));
        }
        reject(error);
      } finally {
        this.spinner.hide();
      }
    });
  }

  selectTypeVehicle(vehicle: any) {
    if (vehicle.selected) {
      _.remove(this.manualCreationCargoService.vehiclesSelected, (obj) => {
        return obj.id === vehicle.id;
      });
      this.manualCreationCargoService.bodyworkTypeOptions.controls.forEach((bodyworkType, i) => {
        if (bodyworkType.value.initialVehicleTypeId === vehicle.id) {
          this.manualCreationCargoService.bodyworkTypeControls.removeAt(i);
          this.manualCreationCargoService.bodyworkTypeOptions.removeAt(i);
          this.manualCreationCargoService.bodyworkTypeValidates.removeAt(i);
        }
      })
      vehicle.selected = false;
    } else {
      vehicle.selected = true;
      this.manualCreationCargoService.vehiclesSelected.push({
        id: vehicle.id,
        name: vehicle.name,
        quantity: 1,
        quality: '',
        bodyWorkType: {
          id: null,
          name: null
        },
        bodyWorkList: []
      });
      this.manualCreationCargoService.bodyworkTypeControls.push(new FormControl('', Validators.required));
      this.manualCreationCargoService.bodyworkTypeOptions.push(new FormControl({ title: 'Tipo de carrocería', initialVehicleTypeId: vehicle.id }))
      this.manualCreationCargoService.bodyworkTypeValidates.push(new FormControl(''));
    }
  }

  nextStep() {
    if (this.manualCreationCargoService.isValidVehicleType()) {
      // Cantidad de vehículos
      this.manualCreationCargoService.getComponentActive().setId(2);
      this.manualCreationCargoService.getComponentActive().getStep().setId(2);
      this.router.navigate([this.manualCreationCargoService.steps.quantityVehicles.url]);
    } else {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'el tipo de vehículo'), undefined, 'alert');
    }
  }

  private initAutocomplete(): void {
    if (this.vehicleCtrl) {
      this.vehicleCtrl.valueChanges.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((text: string) => {
          if (!text || text.length <= 2) {
            return of(this.vehiclesList);
          }
          const filteredVehicles = this.vehicles.filter(vehicle => {
            if (typeof vehicle === 'object' && vehicle !== null && 'name' in vehicle && typeof text === 'string') {
              return vehicle.name.toLowerCase().includes(text.toLowerCase());
            }
            return false;
          });
          this.vehicles = filteredVehicles;
          return of(this.vehicles);
        })
      ).subscribe((filteredVehicles: VehicleCargo[]) => {
        this.vehicles = filteredVehicles;
      });
    }
  }

  getNameIconVehicle(vehicle) {
    return '/assets/svg/icons/icon-' + vehicle.name.toLowerCase() + '.svg';
  }

  displayVehicleType(vehicle): string | undefined {
    return vehicle ? vehicle.name : undefined;
  }

  onSelectValue($event: MatAutocompleteSelectedEvent) {
    this.inputFormControl.setValue($event.option.value)
    // this.vehicleType.emit(JSON.stringify($event.option.value));
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(1);
    this.manualCreationCargoService.getComponentActive().getStep().setId(5);
    this.router.navigate([this.manualCreationCargoService.steps.typeService.url]);
  }
}
