<article class="wrapper__article">
  <div class="wrapper__article__content">
    <mat-tab-group (selectedIndexChange)="onSelectTab($event)" permission-tab
      [permissionModule]="permission.payments.module" [permissionListName]="listPermissions">
      <mat-tab label="Comprobante Contable" *ngIf="showListWithoutPayments">
        <app-cargo-list [typeList]="'withoutPayment'" (resetFilters)="resetFilters($event)"></app-cargo-list>
      </mat-tab>
      <mat-tab label="Egresos">
        <app-cargo-generate></app-cargo-generate>
      </mat-tab>

    </mat-tab-group>
  </div>
</article>