<app-carousel></app-carousel>
<div class="row container-center container-white">
    <div class="col-10">
        <div class="row section-content-text margin-standard">
            <div class="col-lg-4 col-md-4 col-sm-12 container-content-text">
                <img class="image" src="/assets/images/home/maps-and-location.svg">
                <section class="text-center">Contamos con una gran oferta y variedad de vehículos y cargas.
                </section>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 container-content-text">
                <img class="image" src="/assets/images/home/greeting.svg">
                <section class="text-center">Facilitamos negociaciones ágiles y transparentes desde nuestra
                    plataforma.
                </section>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 container-content-text">
                <img class="image" src="/assets/images/home/maps-and-location.svg">
                <section class="text-center">Optimizamos tiempos de entrega con nuestras rutas planificadas.
                </section>
            </div>
        </div>
    </div>
</div>
<div class="row container-gray container-center">
    <div class="col-10  margin-standard">
        <section class="color-gray text-principal bold text-center">Miles
            de
            transportadores y expedidores de carga
            del país <span class="color-purple text-secundary bold">confían en nosotros</span>
        </section>
        <div class="row section-content-text  margin-only-top">
            <article class="col-lg-4 col-sm-4 col-xs-12 container-content-text">
                <section class="text-center number bold color-gray">+ 1.000</section>
                <section class="text-center color-gray">Cargas disponibles para transportar.</section>
            </article>
            <article class="col-lg-4 col-sm-4 col-xs-12 container-content-text">
                <section class="text-center number bold color-gray">+ 2.000</section>
                <section class="text-center color-gray">Camiones listos para cargar.
                </section>
            </article>
            <article class="col-lg-4 col-sm-4 col-xs-12 container-content-text">
                <section class="text-center number bold color-gray">+ 10.000</section>
                <section class="text-center color-gray">Entregas exitosas en todo el país.</section>
            </article>
        </div>
        <div class="column-center container-info">
            <section class="color-gray info-segundaria text-center bold">Únete a esta una nueva forma de transportar
                carga
            </section>
        </div>
        <div class="row margin-standard">
            <div class="col-lg-6 col-md-6 col-sm-12 center-card">
                <div class="card card-img-text">
                    <img class="card-img-top img-card" src="/assets/images/home/img-conductor.png" alt="Card image cap">
                    <div class="card-body text-center">
                        <h5 class="card-title title color-gray">Soy transportador</h5>
                        <p class="card-text color-gray">Encuentra las mejores cargas para tu camión.</p>
                        <a class="action-secondary bold" (click)="navigateTo('/carriers')">Ver detalles</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 center-card">
                <div class="card card-img-text">
                    <img class="card-img-top img-card" src="/assets/images/home/img-bodega.png" alt="Card image cap">
                    <div class="card-body text-center">
                        <h5 class="card-title title color-gray">Soy generador de carga</h5>
                        <p class="card-text color-gray">Envia tus cargas de manera rápida, simple y segura.</p>
                        <a class="action-secondary bold" (click)="navigateTo('/shippers')">Ver detalles</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row container-center container-white">
    <div class="col-10">
        <div class="row margin-standard container-propuesta">
            <div class="col-lg-5 col-md-5 col-sm-12">
                <section class="color-gray bold title-propuesta">Propuestas de valor especializadas</section>
                <div class="container-action">
                    <a class="action-secondary bold" (click)="navigateTo('/suggestions')">Explorar Ahora</a><i
                        class="fas action-secondary fa-arrow-right"></i>
                </div>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-12"></div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="container-items">
                    <div class="container-info" *ngFor="let item of items">
                        <div class="container-img-circle">
                            <img class="card-img-circle" src="{{item.image}}">
                        </div>
                        <br>
                        <section class="title-item color-purple">{{item.title}}</section>
                        <section class="color-gray text-item">{{item.body}}</section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row container-center container-white">
    <div class="container-text-img-tech">
        <img class="img-tech" src="/assets/images/home/businessman-tech.webp" alt="">
        <div class="row container-title-tech">
            <div class="col-10">
                <section class="color-white title-tech title-tech--one"> Nuestras</section>
                <section class="color-white title-tech title-tech--two"> Tecnologías</section>
            </div>
        </div>
    </div>
    <div class="col-10 margin-only-top ">
        <div class="container-principal-tech">
            <div class="container-items-tech">
                <img class="icon-tech" src="/assets/images/home/imagen-tech-hand.jpg">
                <section class="color-gray text-item-tech">
                    <b>Plataformas </b>Android, IOS y WebApp
                </section>
            </div>
            <div class="container-items-tech">
                <img class="icon-tech" src="/assets/img/AI.png">
                <section class="color-gray text-item-tech">
                    <b>Inteligencia </b>Artificial
                </section>
            </div>
            <div class="container-items-tech">
                <img class="icon-tech" src="/assets/img/API.png">
                <section class="color-gray text-item-tech">
                    Integración de 3eros a través de <b>APIs</b>
                </section>
            </div>
            <div class="container-items-tech">
                <img class="icon-tech" src="/assets/img/Cloud.png">
                <section class="color-gray text-item-tech">
                    <b>Tecnologías Cloud </b>para alta escalabilidad, disponibilidad y seguridad
                </section>
            </div>
            <div class="container-items-tech">
                <img class="icon-tech" src="/assets/img/SaaS.png">
                <section class="color-gray text-item-tech">
                    <b>Plataforma tipo</b> SaaS
                </section>
            </div>
            <div class="container-items-tech">
                <img class="icon-tech" src="/assets/img/Analytics.png">
                <section class="color-gray text-item-tech">
                    <b>Analítica de Datos </b>predictiva y prescriptiva
                </section>
            </div>
        </div>
    </div>
</div>
<div class="row container-white container-center">
    <div class="col-10 margin-standard">
        <section class="color-gray text-center text-principal bold">Nuestros <span
                class="color-purple text-secundary bold">casos de éxito</span>
        </section>
        <div class="carousel">
            <img *ngFor="let image of images" class="img-carousel" [src]="image.path" alt="">
        </div>
    </div>
</div>