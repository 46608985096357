<div class="main-container m-0 px-3 position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <label class="title-component mt-4 mr-5">{{data.title}}</label>
  <div>
    <div class="card-item" *ngFor="let novedades of tracepoints.tracepoints">
      <div class="d-flex flex-column w-100">
        <div class="row align-items-end">
          <div class="col-sm-6 text-bold">
            {{novedades.name?novedades.name:novedades.anomaly}}
            <mat-icon class="cursor-pointer icon-edit" (click)="updateNovelty(novedades)" *ngIf="canEditAnomaly"
              matTooltip="Editar novedad">
              edit
            </mat-icon>
          </div>
          <div class="col-sm-4 date" *ngIf="novedades?.fingerprint?.date">
            {{novedades.fingerprint.date| dateFormat: 'only-date'}} {{novedades.fingerprint.date| dateFormat: 'time'}}
          </div>
          <div class="col-sm-2 date" *ngIf="novedades?.temperature?.value && novedades?.temperature?.unit">
            {{ novedades.temperature.value }}
            °{{novedades.temperature.unit}}
          </div>
          <div class="col-12 observation">
            {{novedades.observation}}
          </div>
        </div>
        <div class="w-100 d-flex flex-wrap justify-content-sm-around justify-content-start mt-2"
          *ngIf="novedades.anomalyDuration">
          <div class="d-block date mx-2" *ngIf="novedades.anomalyDuration.startDate">
            Inicio: <span class="text-bold">{{novedades.anomalyDuration.startDate | dateFormat:
              'dd-MM-yyyy,HH:mm'}}</span>
          </div>
          <div class="d-block date mx-2" *ngIf="novedades.anomalyDuration.endDate">
            Finalización: <span class="text-bold">{{novedades.anomalyDuration.endDate | dateFormat:
              'dd-MM-yyyy,HH:mm'}}</span>
          </div>
          <div class="d-block date mx-2" *ngIf="novedades.anomalyDuration.duration">
            Duración: <span class="text-bold">{{novedades.anomalyDuration.duration | millisecondsDigitalClock}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>