<form class="routing__form--content" [formGroup]="form" (submit)="onSubmit()">
    <div class="routing__form--header">
        <label class="routing__form--label">Seleccionar esquema</label>
    </div>

    <div class="routing__form--body">
        <div class="scheme" *ngFor="let scheme of schemes" (click)="onSelectScheme(scheme)"
            [ngClass]="{'selected':isSelectedScheme(scheme)}">
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center mr-2">
                    <i class="fas fa-flag mr-1" style="color: #584796;"></i>
                    <p class="m-0 text-bold">{{scheme?.name | titlecase}}</p>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fas fa-cog mr-1" style="color: #584796;"></i>
                    <p class="m-0 fs-12">{{getOptimizationMode(scheme)}}</p>
                </div>
                <div class="d-flex align-items-center" *ngIf="scheme?.fleet">
                    <i class="fas fa-truck mr-1" style="color: #584796;"></i>
                    <p class="m-0 fs-12">Flota: <span class="text-bold">{{scheme.fleet}}</span></p>
                </div>
            </div>

        </div>
    </div>

    <div class="button-container routing__form--footer">
        <button mat-raised-button color="primary" type="submit"
            [ngClass]="{'bg-color-primary':isValidStep, 'disabled-btn':!isValidStep}">
            Continuar
        </button>
    </div>

</form>