import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { LegalTermsService } from '../legal-terms.service';
import { Permission } from 'src/app/core/resources/permission';

@Component({
  selector: 'app-list-privacy-policy',
  templateUrl: './list-privacy-policy.component.html',
  styleUrls: ['./list-privacy-policy.component.scss'],
  providers: [AuthService]
})
export class ListPrivacyPolicyComponent implements OnInit {

  listTerms = [];
  permission = Permission;

  constructor(
    private termsAndConditionsService: LegalTermsService,
    public utils: Utils,
    private router: Router
  ) { }

  ngOnInit() {
    this.getListTerms();
  }

  getListTerms() {
    this.termsAndConditionsService.getListTerms().subscribe(
      (success: []) => {
        this.listTerms = success;
        this.listTerms = this.listTerms.filter(term => term.type === 'PrivacyPolicies');
      },
      (error) => {
        this.listTerms = [];
      }
    );
  }

  goToCreate() {
    this.router.navigate(['administration/legal-terms/formPrivacyPolicies']);
  }

}
