import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MicrosoftTokenResult } from 'src/app/core/interfaces/microsoft-token-result';
import { environment } from 'src/environments/environment';
import { Endpoints } from 'src/app/core/resources/endpoints';
@Injectable({
  providedIn: 'root'
})
export class TokenHandlerService {
  constructor(
    private http: HttpClient,
    private _endpointResources: Endpoints,
  ) { }

  validateToken(token: string): Observable<any> {
    let params = new HttpParams();
    if (token) params = params.append('token', token);
    return this.http.get<MicrosoftTokenResult>(`${environment.urlServerLoggiapp}/${this._endpointResources.validateToken}`, { params });
  }

  createUserSession(email: string) {
    let params = new HttpParams();
    if (email) params = params.append('email', email);
    return this.http.get<{ status: string, response: string }>(`${environment.urlServerLoggiapp}/${this._endpointResources.customToken}`, { params });
  }
}
