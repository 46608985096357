import { Validators } from "@angular/forms";

export class CargoGenerateValidators {

    constructor() {

    }

    public cargoGenerateValidators = {
        typeFile: [
            Validators.required
        ],
        dateFrom: [
            Validators.required
        ],
        dateUntil: [
            Validators.required
        ]
    };

    public cargoGenerateModel = {
        typeFile: '',
        dateFrom: '',
        dateUntil: '',
        consecutive: ''
    };

}