import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../resources/utils';

@Pipe({
    name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

    constructor(
        public utils: Utils
    ) { }

    transform(phone: string) {
        if (this.utils.isDefined(phone)) {
            try {
                // phone.replace(/(\+[0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})/, '$1 $2 $3 $4');
                return phone.replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})/, '(+$1) $2 $3 $4');
            } catch (e) {
                return phone;
            }
        }
        return phone;
    }

}