import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roleTranslate'
})
export class RoleTranslatePipe implements PipeTransform {

    constructor() { }
    transform(roleString) {
        switch (roleString) {
            case 'Admin':
                return 'Administrador';
            case 'Reader':
                return 'Lector';
            case 'Driver':
                return 'Conductor';
            case 'Owner':
                return 'Propietario';
            case 'webUserPendingActivate':
                return 'Usuarios web pendientes de activar';
            default:
                return roleString;
        }
    }

}