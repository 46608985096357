import { Injectable } from "@angular/core";
import { Cargo } from "../interfaces/cargo";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ResponseGenerateJournalComponent } from "src/app/modules/cargo/response-generate-journal/response-generate-journal.component";
import { Dialog } from "../resources/dialog";
import { CargoGenerateService } from "../services/cargoGenerate.service";

@Injectable()
export class SiigoManager {

    constructor(
        public matDialog: MatDialog,
        private cargoGenerateService: CargoGenerateService
    ) {

    }

    public generateJournal(date, consecutives: number[]): Promise<{ cargosJournalSuccess: Cargo[], cargosJournalError: Cargo[], error: Error }> {
        return new Promise((resolve, reject) => {
            this.cargoGenerateService.generateJournal(date, consecutives).subscribe(
                (response) => {
                    const value = this.validateResponseGenerateJournal(response);
                    resolve(value);
                },
                (error) => {
                    const value = this.validateResponseGenerateJournal(error);
                    reject(value);
                }
            );
        });
    }

    private validateResponseGenerateJournal(response): { cargosJournalSuccess: Cargo[], cargosJournalError: Cargo[], error: Error } {
        let cargosJournalError = [];
        let cargosJournalSuccess = [];
        let error: Error = null;

        if (response && response.length) {
            cargosJournalError = response.filter((cargo) => {
                return cargo.journalError && cargo.journalError.hasError;
            });
            cargosJournalSuccess = response.filter((cargo) => {
                return !cargo.journalError || !cargo.journalError.hasError;
            });
        } else {
            error = response;
        }
        return {
            cargosJournalError,
            cargosJournalSuccess,
            error
        };
    }

    public showResponseGenerateJournal(response: { cargosJournalSuccess: Cargo[], cargosJournalError: Cargo[], error: Error }) {
        return new Promise((resolve, reject) => {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
                ...response
            };
            dialogConfig.disableClose = true;
            dialogConfig.minWidth = "60%";
            dialogConfig.maxHeight = "80vh";
            const dialogRef = this.matDialog.open(ResponseGenerateJournalComponent, dialogConfig);
            dialogRef.afterClosed().subscribe((result) => {
                if (result && result.cargosError) {
                    resolve(result.cargosError);
                }
            });
        });
    }
}
