<div class="update__additional__cost__cargo col-md-12">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close({ state: true })"></i>
  <div class="additional-service-container">
    <app-additional-service class="additional-service-component"
      (additionalCostSelected)="additionalCostSelected($event)" (emitToParent)="emitToParent($event)" [cargo]="cargo"
      [listAdditionalService]="listAdditionalService" [modelForm]="modelFormAdditionalCost" [readonly]="true"
      [companyId]="cargo.idCompany" [cargoId]="cargo.id" [approvalState]="cargo.approval" [listActive]="paramsDialog.listActive">
    </app-additional-service>
    <!-- Button -->
    <div class="col-md-12">
      <div class="d-flex flex-row align-items-center justify-content-center pb-4"
        *ngIf="showBtnCreateAdditionalService && isAdditionalService">
        <button mat-raised-button class="button-width-auto" color="primary" (click)="save()">
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
