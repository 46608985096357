export enum CargoStateEnum {
  REQUEST = 'Request',
  CREATED = 'Created',
  ACCEPTED = 'Accepted',
  START_SERVICE = 'Start service',
  END_SERVICE = 'End service',
  EXPIRED = 'Expired',
  DELETED = 'Deleted',
}

export enum CargoStateDict {
  'Request' = 'Solicitada',
  'Created' = 'Creada',
  'Accepted' = 'Aceptada',
  'Start service' = 'Iniciada',
  'End service' = 'Finalizada',
  'Expired' = 'Expirada',
  'Deleted' = 'Eliminada',
}
