import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from '../services/authentication.service';
import { RoleService } from '../services/role.service';

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<any> {

    constructor(
        private authService: AuthService,
        private router: Router,
        private roleService: RoleService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        if (this.authService.getUserSession().information.document) {
            return this.authService.getUserSession();
        } else {
            if (sessionStorage.getItem('_email')) {
                return this.authService.getFullUserByEmail(sessionStorage.getItem('_email'));
            } else if (sessionStorage.getItem('_phone')) {
                return this.authService.getFullUserByPhone(sessionStorage.getItem('_phone'));
            } else {
                return null;
            }
        }
    }

}
