import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { BodyWorkType } from 'src/app/core/interfaces/bodyWorkType';
import { CargoType } from 'src/app/core/interfaces/cargoType';
import { MethodPayment } from 'src/app/core/interfaces/methodPayment';
import { ContainerMeasure } from 'src/app/core/interfaces/containerMeasure';
import { RegimeType } from 'src/app/core/interfaces/regimeType';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { TripType } from 'src/app/core/interfaces/tripType';
import { VehicleType } from 'src/app/core/interfaces/vehicleType';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ItemCargoFromGuidesService {


    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        private _globalResources: Global,
        private _authService: AuthService) {

    }

    getGuidePDF(guideId: string) {
        return this._http.get(
            environment.urlServerTeclogi + this._endpointResources.getGuidePDF + guideId, {
                responseType: 'blob'
            }
        );
    }

}
