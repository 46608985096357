import { Component, OnChanges, Inject } from '@angular/core';
import { Utils } from 'src/app/core/resources/utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})

export class ConfirmComponent implements OnChanges {

    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public options,
        public utils: Utils,
    ) {

    }

    ngOnInit() {

    }

    ngOnChanges(changes: any) {

    }

    public onConfirm(value?: boolean) {
        this.dialogRef.close(value);
    }

    get titleYes(): string {
        return this.options.titleYes || 'Si'
    }

    get titleNo(): string {
        return this.options.titleNo || 'No'
    }

    get titleCancel(): string {
        return this.options.titleCancel || 'Cancelar'
    }

}