<section class="additional-costs-history position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <h5 class="text-strong text-center mx-5">
    {{title}}
  </h5>
  <span class="separator-horizontal"></span>
  <div class="col-md-12 containers p-0" *ngIf="additionalCostsDeleted">
    <div class="col-md-12 mb-2" *ngFor="let addCost of additionalCostsDeleted">
      <div class="col-md-12 container-title">
        {{addCost?.type?.name}}
      </div>
      <div class="col-md-12 d-flex flex-column">
        <div class="col-md-12 d-flex justify-content-between"
          *ngIf="addCost.financialInformation && addCost.financialInformation.cost && showCostCargo">
          <div>Costo</div>
          <div class="font-weight-bold text-right">{{addCost?.financialInformation?.cost | currency:'COP':'code'}}</div>
        </div>
        <div class="col-md-12 d-flex justify-content-between"
          *ngIf="addCost.financialInformation && addCost.financialInformation.rate && showRateCargo">
          <div>Tarífa</div>
          <div class="font-weight-bold text-right">{{addCost?.financialInformation?.rate | currency:'COP':'code'}}</div>
        </div>
        <div class="col-md-12 d-flex justify-content-between" *ngIf="addCost.financialInformation">
          <div>Estado del pago</div>
          <div class="font-weight-bold text-right">{{addCost?.financialInformation?.paid? 'Pagado' : 'Sin pagar'}}</div>
        </div>
        <div class="col-md-12 d-flex justify-content-between"
          *ngIf="addCost.fingerPrint && addCost.fingerPrint.userName">
          <div>Nombre del responsable de la eliminación</div>
          <div class="font-weight-bold text-right">{{addCost?.fingerPrint?.userName | capitalizeText}}</div>
        </div>
        <div class="col-md-12 d-flex justify-content-between" *ngIf="addCost.fingerPrint && addCost.fingerPrint.userId">
          <div>Cédula del responsable de la eliminación</div>
          <div class="font-weight-bold text-right">{{addCost?.fingerPrint?.userId}}</div>
        </div>
        <div class="col-md-12 d-flex justify-content-between" *ngIf="addCost.fingerPrint && addCost.fingerPrint.date">
          <div>Fecha de eliminación</div>
          <div class="font-weight-bold text-right">{{addCost?.fingerPrint?.date | dateFormat}}</div>
        </div>
      </div>
    </div>
  </div>
</section>