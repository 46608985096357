<mat-expansion-panel [expanded]="true" (opened)="true" [formGroup]='this.form'>
  <mat-expansion-panel-header>
    <mat-panel-title class="text-strong">
      Cuenta Bancaria
    </mat-panel-title>
    <mat-panel-description class="mat-expansion-panel-header-description-right">
      Los campos con * son obligatorios
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Número de Documento<span class="asterisk">*</span></mat-label>
        <input matInput type="text" onlyNumbersInt formControlName="document" autocomplete="off">
        <i (click)="findUser()" matSuffix class="fas fa-search cursor-pointer color-primary"></i>
        <mat-error *ngIf="form.get('document').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('document').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'número de Documento') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Tipo de Documento<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="documentTypeId" (selectionChange)="onChangeTypeDocumentUser($event)">
          <mat-option *ngFor="let type of documenTypes" [value]="type.id">
            {{type.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('documentTypeId').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Nombre del titular<span class="asterisk">*</span></mat-label>
        <input matInput type="text" formControlName="name" autocomplete="off">
        <mat-error *ngIf="form.get('name').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Correo electronico<span class="asterisk">*</span></mat-label>
        <input matInput type="text" formControlName="email" autocomplete="off">
        <mat-icon matSuffix class="cursor-pointer" color="primary"
          *ngIf="form.get('email').errors && form.get('email').errors.pattern"
          matTooltip="No se permiten caracteres especiales al principio('.test@mail.com'), caracteres especiales seguidos ('te..st@mail.com'), más de un arroba ('te@st@mail.com'), espacios ('te st@mail.com'), caracteres especiales al terminar el dominio ('test.@mail.com'), ni correos de extensión mayor a 100 caracteres">help</mat-icon>
        <mat-error *ngIf="form.get('email').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('email').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo electronico') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Número de Celular<span class="asterisk">*</span></mat-label>
        <input matInput type="text" onlyNumbersInt formControlName="phone" autocomplete="off">
        <mat-error *ngIf="form.get('phone').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'número de Celular') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <app-autocomplete-address [title]="'Dirección'" [required]="true" [simpleAddress]="true"
        (emitToParent)="onSelectAddress($event)" [value]="form.get('address').value">
      </app-autocomplete-address>
      <span class="pattern pattern--invalid" *ngIf="form.get('address').errors">
        Campo obligatorio
      </span>
    </div>
    <div class="col-md-4" formGroupName="bank">
      <mat-form-field appearance="standard">
        <mat-label>Nombre del Banco<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="id" (selectionChange)="onSelectFilteredBanks($event)">
          <mat-option *ngFor="let bank of bankListOrder" [value]="bank.id">
            {{bank.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('bank.id').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Tipo de Cuenta<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="accountType" (selectionChange)="onChangeAccountType($event)">
          <mat-option *ngFor="let accountType of listAccountType" [value]="accountType.name">
            {{accountType.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('accountType').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>

    <!--<div class="col-md-4" *ngIf="type === 'balance'">
      <app-fragmented-address></app-fragmented-address>
    </div>-->


    <div class="col-md-4">
      <mat-form-field appearance="standard">
        <mat-label>Número de cuenta<span class="asterisk">*</span></mat-label>
        <input matInput type="text" onlyNumbersInt formControlName="accountNumber">
        <mat-error *ngIf="form.get('accountNumber').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'número de cuenta') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4" *ngIf="form.get('certificate')">
      <app-file
        [options]="{type: 'input', title: 'Certificado Bancario', path:'vehicles/' + vehicle.id +'/'+ type, storage: true, required: true, storageName: 'bankAccount' + type + '_certificate',hideAlert: true }"
        [inputFormControl]="form.get('certificate')" [id]="type"></app-file>
      <span class="pattern pattern--invalid"
        *ngIf="form.get('certificate').errors && form.get('certificate').errors.required">
        Campo obligatorio
      </span>
    </div>
    <div class="col-md-4" *ngIf="form.get('rut')">
      <app-file
        [options]="{type: 'input', title: 'Rut', path:'vehicles/'+ vehicle.id  +'/'+ type, storage: true, required: true, storageName: 'bankAccount' + type + '_rut',hideAlert: true}"
        [inputFormControl]="form.get('rut')" [id]="type"></app-file>
      <span class="pattern pattern--invalid" *ngIf="form.get('rut').errors && form.get('rut').errors.required">
        Campo obligatorio
      </span>
    </div>
    <div class="col-md-4" *ngIf="form.get('authorization')">
      <app-file
        [options]="{type: 'input', title: 'Autorización de pago a Tercero', path:'vehicles/'+ vehicle.id +'/'+ type, storage: true, required: true, storageName: 'bankAccount' + type + '_authorization',hideAlert: true}"
        [inputFormControl]="form.get('authorization')" [id]="type"></app-file>
      <span class="pattern pattern--invalid"
        *ngIf="documentIsDifferent && utils.isEmpty(this.form.get('authorization').value)">
        Campo obligatorio
      </span>
    </div>
  </div>
  <mat-card-actions class="mat-card-actions-vehcle text-center" *ngIf="showBtnSave">
    <button mat-raised-button color="primary" type="button" (click)="openModalConfirmeSave()"
      class="button-width-auto mt-5">
      Guardar
    </button>
    <!--<button mat-raised-button color="primary" type="button" *ngIf="type === 'balance'"
      (click)="openModalConfirmThirdParty()" class="button-width-auto">
      Crear Tercero
    </button>-->
  </mat-card-actions>
  <span class="separator-horizontal"></span>
</mat-expansion-panel>