<div class="main">
    <div class="container-image-text container-overflow-h">
        <div class="container-text">
            <div class="title color-white">Encuentra las mejores cargas para tu camión</div>
            <div class="subtitle color-white">Tenemos más de 300 cargas disponibles para transportar en todo el país,
                ¡y cada mes se suman más! </div>
            <div class="button">
                <a class="text-button " href="https://play.google.com/store/apps/details?id=com.teclogi.teclogiDriver">
                    Transporta con nosotros
                </a>
            </div>
        </div>
    </div>
    <div class="row container-center container-overflow-h">
        <div class="col-10  margin-standard">
            <section class="color-gray text-principal bold text-center">LoggiApp te conecta con carga disponible de
                manera<span class="color-purple text-secundary bold"> segura y transparente, sin intermediarios.</span>
            </section>
            <section class="row margin-only-top">
                <div class="col-12 col-lg-6">
                    <div class="image">
                        <img src="/assets/images/dcarriers/Group 2.png" alt="">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="container-items" *ngFor="let item of data">
                        <div class="container-image">
                            <img src="{{item.icon}}" alt="">
                        </div>
                        <div class="container-information">
                            <div class="title-item bold">
                                {{item.title}}
                            </div>
                            <div class="information-item">
                                {{item.information}}
                            </div>
                        </div>
                    </div>
                    <div class="container-button">
                        <div class="button">
                            <a class="text-button "
                                href="https://play.google.com/store/apps/details?id=com.teclogi.teclogiDriver">
                                Registrate Ya
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="row container-overflow-h">
        <div class="col-12 col-lg-6 container-image-app">
            <img class="img-one" src="/assets/images/dcarriers/foto2.png">
            <img class="img-two" src="/assets/images/dcarriers/mobile-mockups-loggiapp.png">
        </div>
        <div class="col-12 col-lg-6 container-apps">
            <div class="info-apps">
                <section class="color-gray text-principal text-center">Descarga nuestra app y <span
                        class="color-purple text-secundary bold"> comienza a transportar.</span>
                </section>
                <div class="container-download">
                    <a href="https://apps.apple.com/co/app/teclogi-conductor/id1521396363">
                        <img class="img-download" src="/assets/svg/buttons/ios.svg" alt="">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.teclogi.teclogiDriver">
                        <img class="img-download" src="/assets/svg/buttons/android.svg" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>