import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Observable, of } from 'rxjs';
import { startWith, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { AssignVehicleCargoService } from 'src/app/modules/cargo/assign-vehicle-cargo/assign-vehicle-cargo.service';
import { MatAutocompleteSelectedEvent, MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { FormVehicleComponent } from 'src/app/modules/administration/vehicles/form-vehicle/form-vehicle.component'

@Component({
  selector: 'app-select-license-plate',
  templateUrl: './select-license-plate.component.html',
  styleUrls: ['./select-license-plate.component.scss']
})
export class SelectLicensePlateComponent implements OnInit {
  @Input() options;
  @Input() inputFormControl: FormControl;
  @Input() validate: string = '';
  licensePlateCtrl = new FormControl('')
  listVehicles: Observable<Vehicle[]>;

  constructor(
    private authService: AuthService,
    public utils: Utils,
    private assignVehicleCargoService: AssignVehicleCargoService,
    private matDialog: MatDialog,

  ) { }

  /**
  * @description Verifies if the inputFormControl has the validator "required" to assign it to local licensePlateCtrl, and executes the "subscribeLicensePlate" method
  */
  ngOnInit() {
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.licensePlateCtrl.setValidators(Validators.required);
    this.subscribeLicensePlate();
  }

  /**
  * @description Makes a subscription to licensePlateCtrl valueChanges to get the updated list vehicles.
  */
  private subscribeLicensePlate(): void {
    this.listVehicles = this.licensePlateCtrl.valueChanges
      .pipe(
        startWith(''),
        // debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          try {
            if (val.trim().length > 2) {
              return this.filter(val || '');
            } else {
              return [];
            }
          } catch (e) {
            return [];
          }
        })
      );
  }

  /**
  * @param {SimpleChanges} changes are the changes from the inputs
  * @description Verifies the input's changes and make actions with them.
  */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.validate) {
        switch (this.validate) {
          case 'touched':
            this.licensePlateCtrl.markAsTouched();
            break;
          case 'untouched':
            this.licensePlateCtrl.markAsUntouched();
            break;
          case 'enable':
            this.licensePlateCtrl.enable();
            break;
          case 'disable':
            this.licensePlateCtrl.disable();
            break;
          case 'disable&untouched':
            this.licensePlateCtrl.markAsUntouched();
            this.licensePlateCtrl.disable();
            break;
          default:
            break;
        }
      }
    }
  }

  /**
  * @param {string} value is the text to search license plates
  * @returns {Observable<Vehicle[]>} Returns an updated list of vehicles from the param
  * @description Gets a filtered list of vehicles from the search param
  */
  filter(value: string): Observable<Vehicle[]> {
    const filterValue = value.toUpperCase();
    const holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
    if (holderCompany !== null) {
      return this.assignVehicleCargoService.getVehicleById(filterValue, holderCompany)
        .pipe(
          map(response => {
            if (response && response.length) {
              return response.filter(option => {
                return option.id.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
              })
            } else {
              return [];
            }
          }
          )
        );
    } else return of([]);
  }

  /**
  * @description Opens a modal to create new Vehicles
  */
  openDialogCreateVehicle(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      omitNavigate: true,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(FormVehicleComponent, dialogConfig).afterClosed().subscribe(result => {
      const vehicleCreated: string = this.utils.getNestedValue(result, 'value.vehicle.id');
      if (vehicleCreated)
        this.licensePlateCtrl.setValue(vehicleCreated);
      if (this.licensePlateCtrl.value)
        this.listVehicles = this.filter(this.licensePlateCtrl.value);
    });
  }

  /**
  * @param {Vehicle} vehicle is a vehicle from mat-option
  * @returns {string} is the display name to show (licensePlate)
  * @description Verifies if the current option is a vehicle to show its license plate
  */
  public displayVehicle(vehicle: Vehicle): string {
    return vehicle && vehicle.id ? vehicle.id : '';
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to check
  * @returns {boolean} returns true if the vehicle has a driver with document and name
  * @description Verifies if the vehicle has a driver with document and name
  */
  hasDriver(vehicle: Vehicle): boolean {
    return !!(vehicle && vehicle.driver && vehicle.driver.document && vehicle.driver.name);
  }

  /**
  * @description Cleans the inputFormControl if the user makes a "keyup" action on the input
  */
  changeValue(): void {
    if (this.inputFormControl) this.inputFormControl.setValue("");
  }

  /**
  * @param {MatAutocompleteSelectedEvent} $event is the event with the selected vehicle
  * @description Updates the inputFormControl with the vehicle selected
  */
  onSelectVehicle($event: MatAutocompleteSelectedEvent): void {
    if (this.inputFormControl) this.inputFormControl.setValue($event.option.value);
  }


}
