import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { FileNamePipe } from 'src/app/core/pipe/fileName.pipe';
import { DatePipe } from '@angular/common';
import { FirebaseApi } from 'src/app/core/classes/firebase-api';
import firebase from 'firebase';
@Component({
  selector: 'app-trip-type-documents',
  templateUrl: './trip-type-documents.component.html',
  styleUrls: ['./trip-type-documents.component.scss'],
  providers: [AuthService, Global, FileNamePipe, DatePipe]
})
export class TripTypeDocumentsComponent {

  stepActive: number;
  productType: string = "No";
  storageRef: any;
  uploadTask: any;
  uploadProgress: number | null = null;
  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private angularFireStorage: AngularFireStorage,
    private router: Router,
    public snackBarService: SnackBarService,
    public fileNamePipe: FileNamePipe,
    public datePipe: DatePipe
  ) {
    this.stepActive = 1;
  }

  nextStep() {
    if (this.manualCreationCargoService.isValidTripTypeDocuments()) {
      // Tipo de regimen
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(2);
      this.router.navigate([this.manualCreationCargoService.steps.typeRegime.url]);
    } else {
      this.snackBarService.openSnackBar(FormMessages.EMPTY_DOCUMENT, undefined, 'alert');
    }
  }

  handleFileInput(e: Event) {
    const splittedNameFile = e.target['files'][0]['name'].split('.');
    const formatFile = splittedNameFile[splittedNameFile.length - 1];
    const dateInMs = new Date().getTime();
    this.manualCreationCargoService.fileLetterRetirement['name'] = `tripType_letterRetirement_${dateInMs}.${formatFile}`;
    const modifiedFile = new File([e.target['files'][0]], this.manualCreationCargoService.fileLetterRetirement['name'], {
      type: e.target['files'][0].type
    });
    this.manualCreationCargoService.fileLetterRetirement['file'] = modifiedFile;
    this.manualCreationCargoService.fileLetterRetirement['uploaded'] = true;
    this.manualCreationCargoService.fileLetterRetirement['size'] = this.bytesToSize(this.manualCreationCargoService.fileLetterRetirement['file'].size);
    this.uploadFileLetterRetirement(this.manualCreationCargoService.fileLetterRetirement);
  }

  uploadFileLetterRetirement(file: any) {
    const path = `/uploads/letterRetirement/${new Date().getTime()}/${file['file'].name}`;
    let lastSnapshot: firebase.storage.UploadTaskSnapshot = null;

    FirebaseApi.storage.ref(path).put(file['file']).on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snap) => {
        lastSnapshot = snap;
        this.uploadProgress = lastSnapshot.bytesTransferred / lastSnapshot.totalBytes * 100;
      },
      (err) => {
        console.error(err);
        this.snackBarService.openSnackBar('Ha ocurrido un error al cargar el archivo, intente nuevamente.', 'x', 'error');
      },
      () => {
        if (lastSnapshot.bytesTransferred === lastSnapshot.totalBytes) {
          lastSnapshot.ref
            .getDownloadURL()
            .then(
              (url) => {
                this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.letterRetirement.setValue(
                  url
                );
                file.url = url;
                this.snackBarService.openSnackBar('Archivo cargado con éxito', 'x');
              }
            );
        }
      }
    );
  }

  bytesToSize(bytes) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    let i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  deleteFile() {
    let thisClass = this;
    this.angularFireStorage.storage.refFromURL(this.manualCreationCargoService.fileLetterRetirement['url']).delete().then(
      (data) => {
        this.storageRef = null;;
        this.uploadTask = null;;
        this.uploadProgress = null;
        this.manualCreationCargoService.fileLetterRetirement = {
          file: null,
          name: '',
          uploaded: false,
          size: null,
          url: null
        };
        thisClass.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.letterRetirement.setValue(
          ''
        );
      },
      (error) => {
      },
    );
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(1);
    this.manualCreationCargoService.getComponentActive().getStep().setId(1);
    this.router.navigate([this.manualCreationCargoService.steps.typeTrip.url]);
  }
}
