import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { RoutingMapComponent } from './components/routing-map/routing-map.component';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { ScenarioListComponent } from './components/scenario-list/scenario-list.component';
import { ScenarioDetailComponent } from './components/scenario-detail/scenario-detail.component';


const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Ruteo' },
    children: [
      {
        path: 'create-route',
        data: { breadcrumb: 'Creación de Ruteo' },
        component: RoutingMapComponent,
        canActivate: [UserActiveGuard, RoleGuard],
        resolve: {
          UserResolver
        },
      },
      {
        path: 'route-list',
        data: { breadcrumb: 'Listado de ruteos' },
        component: ScenarioListComponent,
        canActivate: [UserActiveGuard, RoleGuard],
        resolve: {
          UserResolver
        },
      },
      {
        path: 'detail/:id',
        component: ScenarioDetailComponent,
        data: { breadcrumb: 'Detalle' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        },
      },
      { path: '**', redirectTo: 'routing/create-route' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingRoutingModule { }
