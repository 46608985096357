<div class="mt-3">
    <a class="btn-back text-bold" href="/#/cargo/list/request">
        <i class="fa fa-arrow-left"></i> Solicitudes
    </a>
    <div class="container-applications w-100">
        <div class="col-md-12 row box-info-main">
            <div class="title-item">
                <div class="label">
                    ID
                </div>
                <div class="text-bold label-field">
                    <span title="hola">
                        {{'hola'}}
                    </span>
                </div>
            </div>
            <div class="title-item">
                <div class="label">
                    Contratante
                </div>
                <div class="text-bold label-field">
                    <span title="hola">
                        {{'hola'}}
                    </span>
                </div>
            </div>
            <div class="title-item">
                <div class="label">
                    Origen
                </div>
                <div class="text-bold label-field">
                    <span title="hola">
                        {{'hola'}}
                    </span>
                </div>
            </div>
            <div class="title-item">
                <div class="label">
                    Fecha de cargue
                </div>
                <div class="text-bold label-field">
                    <span title="hola">
                        hola
                    </span>
                </div>
            </div>
            <div class="title-item">
                <div class="label">
                    Destino
                </div>
                <div class="text-bold label-field">
                    <span title="hola">
                        {{'hola'}}
                    </span>
                </div>
            </div>
            <div class="title-item">
                <div class="label">
                    Fecha de descargue
                </div>
                <div class="text-bold label-field">
                    <span title="hola">
                        <!-- {{dateManager.transformDateUFC()|date:'medium'}} -->
                    </span>
                </div>
            </div>
            <div class="title-item">
                <div class="label">
                    Flete
                </div>
                <div class="text-bold label-field">
                    <span title="hola">
                        {{'hola'}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="container-applications container-applications-body w-100">
        <div class="row card-edit m-0 w-100">
           <div class="col-md-12 d-flex justify-content-center flex-column table-responsive">
            <table>
                <tr>
                   <th class="begin">Fecha de postulación</th>
                   <th>Empresa</th>
                   <th>NIT</th>
                   <th>Solicitudes asignadas</th>
                   <th>Número de viajes RNDC</th>
                   <th>Valor de flete ofertado</th>
                   <th class="end">Score de la empresa</th>
                </tr>
                <tr *ngFor="let reg of registros">
                   <td data-cell="Fecha de postulación">{{reg.fechaPostulacion}}</td>
                   <td data-cell="Empresa">{{ reg.empresa}}</td>
                   <td data-cell="NIT">{{ reg.nit }}</td>
                   <td data-cell="Solicitudes asignadas">{{ reg.solicitudesAsignadas }}</td>
                   <td data-cell="Número de viajes RNDC">{{ reg.numeroViajesRNDC }}</td>
                   <td data-cell="Valor de flete ofertado">{{ reg.valorFleteOfertado }}</td>
                   <td data-cell="Score de la empresa">{{ reg.scoreEmpresa }}</td>
                   <td><button class="btn assign">Asignar</button></td>
                </tr>
               </table>
           </div>
          </div>
    </div>
</div>