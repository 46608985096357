<div class="container">
  <div class="col-md-12 mb-md-0 mb-12">

    <!-- Grid row -->
    <div class="row">
      <div class="col-md-3">

      </div>
      <div class="col-md-6">
        <div class="row">
          <!-- <ng-scrollbar #scrollable track="all" pointerEventsMethod="scrollbar"> -->
          <div class="col-sm-12">
            <div class="container-form">
              <br><br>
              <div class="title-1">
                Cambiar contraseña
              </div>
              <div class="separator-horizontal"></div>
              <div class="separator-horizontal"></div>
              <div>
                  <p>
                    Haz clic en Cambiar contraseña y enviaremos a tu correo registrado un enlace para que puedas actualizarla.
                  </p>
              </div>
              <div class="separator-horizontal"></div>
              <div class="separator-horizontal"></div>
              <mat-card-actions>
                <button mat-raised-button color="primary" (click)="changePassword()">Cambiar contraseña</button>
              </mat-card-actions>
            </div>
          </div>
          <!-- </ng-scrollbar> -->
        </div>

      </div>
      <div class="col-md-3">

      </div>
    </div>


  </div>
</div>
