<ng-template #modalElement let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title">
      {{titleModal}}
    </p>
    <textarea  class="textarea" *ngIf="textareaShow" [(ngModel)]="message"></textarea>    
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary" (click)="cancel()">{{titleCancel}}</button>
      <button mat-raised-button color="primary" (click)="confirm()">{{titleConfirm}}</button>
    </mat-card-actions>
  </div>
</ng-template>