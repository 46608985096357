import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoRatingService } from './cargo-rating.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateManager } from 'src/app/core/managers/date.manager';
import { DateEnum } from 'src/app/core/enums/date.enum';
import { CargoService } from 'src/app/core/services/cargo.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/authentication.service';
import { CargoManager } from 'src/app/core/managers/cargo.manager';

@Component({
  selector: 'app-cargo-rating',
  templateUrl: './cargo-rating.component.html',
  styleUrls: ['./cargo-rating.component.scss']
})
export class CargoRatingComponent implements OnInit {
  public cargo: Cargo;
  public state: string = "";
  public currentRate = 0;
  public commentRateCargo = "";
  public redirect: boolean;

  constructor(
    private cargoRatingService: CargoRatingService,
    private cargoService: CargoService,
    private authService: AuthService,
    private router: Router,
    private _snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: { cargo: Cargo, redirect: boolean, enableClose?: boolean },
    public dialogRef: MatDialogRef<CargoRatingComponent>,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private cargoManager: CargoManager
  ) { }

  ngOnInit() {
    this.cargo = this.dialogParams.cargo;
    if (this.utils.isDefined(this.dialogParams.redirect)) {
      this.redirect = this.dialogParams.redirect;
    }
  }

  cargoReceived() {
    this.state = "recived";
  }

  cargoNotReceived() {
    this.state = "NoRecived";
  }

  openModalConfirmCargo() {
    this.state = "";
  }
  disabledButtonRate(): boolean {
    return !(this.commentRateCargo.length > 0 && this.currentRate > 0);
  }

  onNoClick(): void {
    if (this.canEndCargoCheck) this.endCargoCheck(false);
    else this.dialogRef.close();
  }

  onSubmit() {
    if (this.canEndCargoCheck) this.endCargoCheck(true);
    else this.rateCargo();
  }

  endCargoCheck(rateCargo: boolean) {
    const destinations = this.cargo.cargoFeature.uploadDownload.destination;
    for (const i in destinations) {
      const destination = destinations[i];
      for (const j in destination.addresses) {
        const address = destination.addresses[j];
        const minDate = DateManager.formatDate(destination.downloadDate, DateEnum.YYYY_MM_DD).substring(0, 10) + ' ' + address.time;
        const end = DateManager.dateToString(DateManager.add(minDate, 15, "minutes"));
        this.cargo.cargoFeature.uploadDownload.destination[i].addresses[j].durationTime.endDate = end;
      }
    }
    this.spinner.show();
    this.cargoService.initialFulfillConsignment(this.cargo).subscribe(() => {
      this.spinner.hide();
      rateCargo ? this.rateCargo() : this.dialogRef.close();
    }, () => {
      this.spinner.hide();
      rateCargo ? this.rateCargo() : this.dialogRef.close();
    });
  }

  get canEndCargoCheck(): boolean {
    return this.cargoManager.isTripTypeNational(this.cargo) && !this.cargo.totalConsignmentsInitialApproval && this.cargo.ministry && this.cargo.cargoFeature.uploadDownload.destination.some((destination) => destination.addresses.some((address) => !address.approvalInitialConsignment)) && this.authService.getCompany().companyId === environment.rootNit;
  }

  rateCargo() {
    let params = {
      "score": this.currentRate,
      "reason": this.commentRateCargo,
    };
    this.spinner.show();
    this.cargoRatingService.rateCargo(this.cargo.id, params).subscribe(
      (data) => {
        this.spinner.hide();
        this._snackBarService.openSnackBar("Calificación exitosa");
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this._snackBarService.openSnackBar("Ocurrió un error al calificar carga", undefined, 'error');
      }
    );
  }

  goToList(routeUrl) {
    this.router.navigate([routeUrl]);
  }

}
