import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { UploadGuideService } from './upload-guide.service';
import * as _ from 'lodash';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { DateManager } from 'src/app/core/managers/date.manager';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM-DD',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM-DD',
  },
};

@Component({
  selector: 'app-upload-guide',
  templateUrl: './upload-guide.component.html',
  styleUrls: ['./upload-guide.component.scss'],
  providers: [AuthService, Global, DatePipe]
})
export class UploadGuideComponent implements OnInit {

  uploadGuideForm: FormGroup;
  setStateGuidesForm: FormGroup;
  activeTab: string;
  listStates = [];
  @ViewChild('timePicker', { static: true }) timePicker: ElementRef;
  timeValue: string;
  permission = Permission;
  companyFormControl: FormControl = new FormControl('', Validators.required);
  companySub: Subscription;
  stateLoading = false;
  optionsCompany = {
    title: 'Compañía'
  };
  constructor(
    public uploadGuideService: UploadGuideService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    public datePipe: DatePipe
  ) {
    this.activeTab = 'uploadGuide';
  }

  ngOnInit() {
    this.initUploadGuideForm();
    this.initSetStateGuidesForm();
    this.companySub = this.companyFormControl.valueChanges.subscribe((value) => {
      this.setStateGuidesForm.get('state').setValue(null);
      if (this.activeTab === 'uploadGuide') {
        this.uploadGuideForm.get('store').setValue(value.companyId);
      }
      if (this.activeTab === 'setStates') {
        this.setStateGuidesForm.get('store').setValue(value.companyId);
      }
      this.getGuideStates(value.companyId);
    });
  }

  initUploadGuideForm() {
    this.uploadGuideForm = new FormGroup({
      file: new FormControl(null, [Validators.required]),
      store: new FormControl(null, [Validators.required])
    });
  }

  initSetStateGuidesForm() {
    this.setStateGuidesForm = new FormGroup({
      file: new FormControl(null, [Validators.required]),
      store: new FormControl(null, [Validators.required]),
      state: new FormControl('', [Validators.required]),
      date: new FormControl(DateManager.dateToString(new Date(), 'YYYY-MM-DD'), [Validators.required]),
      time: new FormControl(null, [Validators.required])
    });
  }

  handleFileInput($event, type: string) {
    if ($event.length) {
      if (type === 'uploadGuide') {
        this.uploadGuideForm.patchValue({
          file: $event[0]
        });
      } else {
        this.setStateGuidesForm.patchValue({
          file: $event[0]
        });
      }
    }
  }

  onSelectTab($event) {
    switch ($event) {
      case 0:
        this.activeTab = 'uploadGuide';
        break;
      case 1:
        this.activeTab = 'setStates';
        break;
      case 2:
        this.activeTab = 'createGuides';
        break;
    }
  }

  uploadFile() {
    if (this.activeTab === 'uploadGuide' && this.uploadGuideForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.uploadGuideForm.get('store'), 'compañia')) return;
      else if (this.uploadGuideForm.get('file').invalid) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.FILE_NOT_UPLOADED, 'cargar guías'), undefined, 'alert');
        return;
      }
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
        return;
      }
    } else if (this.activeTab === 'setStates' && this.setStateGuidesForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.setStateGuidesForm.get('store'), 'compañia')) return;
      else if (this.utils.errorMessagesCustomized(this.setStateGuidesForm.get('state'), 'estado')) return;
      else if (this.utils.errorMessagesCustomized(this.setStateGuidesForm.get('date'), 'fecha')) return;
      else if (this.utils.errorMessagesCustomized(this.setStateGuidesForm.get('time'), 'hora')) return;
      else if (this.setStateGuidesForm.get('file').invalid) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.FILE_NOT_UPLOADED, 'cambio de estado'), undefined, 'alert');
        return;
      }
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
        return;
      }
    } else {
      this.authService.getTokenUser().then((token) => {
        this.sendFile(token);
      });
    }
  }

  sendFile(token) {
    let formData = new FormData();
    if (this.activeTab === 'uploadGuide') {
      formData.append('file', this.uploadGuideForm.value.file);
      formData.append('store', this.uploadGuideForm.value.store);
      this.spinner.show();
      if (formData) {
        this.uploadGuideService.uploadGuides(formData, token).subscribe(
          (response) => {
            this.spinner.hide();
            if (response && response['message'] && response['message'].trim()) {
              this.snackBarService.openSnackBar(response['message'].trim());
            } else {
              this.snackBarService.openSnackBar('Guías cargadas correctamente');
            }
          },
          (error) => {
            this.spinner.hide();
            if (error && error['message'] && error['message'].trim()) {
              this.snackBarService.openSnackBar(error['message'].trim(), undefined, 'error');
            } else {
              this.snackBarService.openSnackBar('Ocurrió un error cargando las Guías', undefined, 'error');
            }
          }
        );
      }
    } else {
      // setStates
      const date = DateManager.formatDate(this.setStateGuidesForm.value.date + ' ' + this.timeValue, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD HH:mm ZZ');
      formData.append('file', this.setStateGuidesForm.value.file.fileData.file);
      formData.append('store', this.setStateGuidesForm.value.store);
      formData.append('state', this.setStateGuidesForm.value.state);
      formData.append('date', date);
      this.spinner.show();
      if (formData) {
        this.uploadGuideService.setStateGuides(formData, token).subscribe(
          (response) => {
            this.spinner.hide();
            if (response && response['message'] && response['message'].trim()) {
              this.snackBarService.openSnackBar(response['message'].trim());
            } else {
              this.snackBarService.openSnackBar('Estado actualizado correctamente para las guías cargadas');
            }
          },
          (error) => {
            this.spinner.hide();
            if (error && error['message'] && error['message'].trim()) {
              this.snackBarService.openSnackBar(error['message'].trim(), undefined, 'error');
            } else {
              this.snackBarService.openSnackBar('Ocurrió un error actualizando el estados de las guías', undefined, 'error');
            }
          }
        );
      }
    }
  }

  changeTab(type: string) {
    this.activeTab = type;
  }

  getGuideStates(nit: string) {
    if (!this.utils.isEmpty(nit)) {
      this.stateLoading = true;
      this.uploadGuideService.getGuideStates(nit).toPromise()
        .then((data) => {
          this.listStates = data;
        })
        .catch(() => {
          this.listStates = [];
        })
        .finally(() => {
          this.stateLoading = false;
        });
    }
  }

  onChangeDatePicker($event) {
    this.setStateGuidesForm.patchValue({
      date: DateManager.formatDate($event.value, 'YYYY-MM-DD', 'YYYY-MM-DD')
    });
  }

  onChangeTime() {

    const timeSplit = this.timePicker.nativeElement.value.split(':');
    let hours;
    let minutes;
    let meridian;

    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = 'PM';
      hours -= 12;
    } else if (hours < 12) {
      meridian = 'AM';
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = 'PM';
    }

    if (hours && minutes && meridian) {
      this.timeValue = hours + ':' + minutes + ' ' + meridian;
    } else {
      this.timeValue = null;
    }
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
  }

}
