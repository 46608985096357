import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from 'src/environments/environment';
import { City } from 'src/app/core/interfaces/city';


@Injectable({
    providedIn: 'root'
})
export class CargoReteicaService {

    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
        private globalResources: Global
    ) {

    }

    public changeReteica(data) {
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.cargoReteica + data.city,
            [data.idCargo]
        );
    }
}
