import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatCheckboxChange, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { RoutingCreateCapacityComponent } from '../routing-create-capacity/routing-create-capacity.component';
import { FleetCapacity } from 'src/app/core/interfaces/fleetCapacity';
import { StoredFleet, VehicleCapacity } from 'src/app/core/interfaces/storedFleet';
import { RoutingService } from '../../routing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';

@Component({
  selector: 'app-routing-create-fleet',
  templateUrl: './routing-create-fleet.component.html',
  styleUrls: ['./routing-create-fleet.component.scss']
})
export class RoutingCreateFleetComponent implements OnInit {
  form: FormGroup;
  mode: 'create' | 'edit' = 'create';
  activeStep: 0 | 1 = 0;
  maxLengthValueAllow: number = 18;

  showVehicleSelector: boolean = false;
  licensePlateCtrl: FormControl = new FormControl('');
  licensePlateSub: Subscription;
  licensePlateOptions = {
    appearance: 'outline'
  }
  baseCapacities: FleetCapacity[] = [
    { name: 'Volumen', measure: 'm3', selected: true, base: true },
    { name: 'Peso', measure: 'k', base: true, selected: true },
    { name: 'Valor mercancía', measure: 'COP', base: true, selected: true }
  ]
  listCapacities: FleetCapacity[] = [];

  constructor(
    public dialogRef: MatDialogRef<RoutingCreateFleetComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: { fleet: StoredFleet },
    public utils: Utils,
    private dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private routingService: RoutingService,
  ) { }

  /**
  * @description Executes the "initForm","getCapacities" and "subscribeLicensePlate" methods
  */
  ngOnInit(): void {
    this.initForm();
    this.subscribeLicensePlate();
    this.getCapacities(true);
  }

  /**
  * @description Initializes the local form
  */
  private initForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      vehicles: new FormArray([])
    });
  }

  /**
  * @description Makes a subscription to licensePlateCtrl changes to add vehicles
  */
  private subscribeLicensePlate(): void {
    this.licensePlateSub = this.licensePlateCtrl.valueChanges.subscribe((vehicle: Vehicle) => {
      if (vehicle && vehicle.id && vehicle.driver && vehicle.driver.document) {
        if (!this.vehicles.getRawValue().some(currentVehicle => currentVehicle.licensePlate === vehicle.id)) {
          this.addVehicle(vehicle);
          this.showVehicleSelector = false;
        } else
          this.snackBarService.openSnackBar(RoutingMessages.VEHICLE_ALREADY_IN_FLEET, undefined, 'alert');
      }
    })
  }

  /**
  * @param {boolean} validateMode validates the mode if is true, resets the capacities otherwise
  * @description Refreshes the list of capacities saved, validates the mode if the param is true and resets the capacities selected otherwise
  */
  private getCapacities(validateMode: boolean = false): void {
    const lastCapacitiesSelected = this.utils.clone(this.capacitiesSelected);
    const sub = this.routingService.getCapacities().subscribe(
      (success) => {
        this.listCapacities = success && success.length ? [...this.baseCapacities].concat(success) : [...this.baseCapacities];
        if (validateMode) this.validateMode();
        else this.resetCapacitiesSelected(lastCapacitiesSelected);
      },
      (error) => {
        console.error(error);
        this.listCapacities = [...this.baseCapacities];
        if (validateMode) this.validateMode();
        else this.resetCapacitiesSelected(lastCapacitiesSelected);
      }, () => {
        sub.unsubscribe();
      }
    )
  }

  /**
  * @description Validates if the current mode is create or edit and updates the form if the mode is edit
  */
  private validateMode(): void {
    if (this.dialogParams && this.dialogParams.fleet) {
      this.mode = 'edit';
      this.form.get('name').setValue(this.dialogParams.fleet.fleetName);
      this.listCapacities.forEach(capacity => capacity.selected = false);
      this.dialogParams.fleet.capacityMeasures.forEach(capacity => {
        const index = this.listCapacities.findIndex(cap => capacity.name === cap.name);
        if (index !== -1) this.listCapacities[index]['selected'] = true;
        else this.listCapacities.push({ name: capacity.name, measure: capacity.measure, selected: true });
      })
      this.dialogParams.fleet.vehicles.forEach((vehicle, index) => {
        this.addVehicle(vehicle, this.dialogParams.fleet.vehicleCapacities[index]);
      });
      this.activeStep = 1;
    }
  }



  //CAPACITIES

  /**
   * @param {FleetCapacity[]} lastCapacitiesSelected are the capacities selected before the refresh
   * @description Refresh the controls and capacities selected based on last capacities selected
   */
  private resetCapacitiesSelected(lastCapacitiesSelected: FleetCapacity[]) {
    this.listCapacities.forEach((capacity: FleetCapacity) => capacity['selected'] = false);
    lastCapacitiesSelected.forEach((capacity: FleetCapacity) => {
      const index = this.listCapacities.findIndex(currentCapacity => currentCapacity.name === capacity.name);
      if (index === -1) this.onChangeCapacity({ checked: false } as MatCheckboxChange, capacity);
      else this.listCapacities[index] = { ...this.listCapacities[index], selected: true };
    })
  }

  /**
  * @description Opens a modal to create a new capacity
  */
  createCapacity(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      list: this.listCapacities
    }
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(RoutingCreateCapacityComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state) this.getCapacities();
    });
  }

  /**
  * @param {FleetCapacity} capacity is the capacity to delete 
  * @description Opens a modal to  confirm the delete and executes the "realDeleteCapacity" if true
  */
  deleteCapacity(capacity: FleetCapacity): void {
    if (capacity.selected && this.capacitiesSelected.length === 1) {
      this.snackBarService.openSnackBar(RoutingMessages.UNABLE_TO_DETELE_CAPACITY, undefined, 'alert');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Desea eliminar la capacidad ${capacity.name} (${capacity.measure})?`,
      labelButton1: 'Cancelar',
      labelButton2: 'Eliminar',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state && result.refuse === "Eliminar")
        this.realDeleteCapacity(capacity);
    });
  }

  /**
  * @param {FleetCapacity} capacity is the capacity to delete 
  * @description Deletes a capacity
  */
  private realDeleteCapacity(capacity: FleetCapacity) {
    this.spinner.show();
    this.routingService.deleteCapacity(capacity).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(RoutingMessages.CAPACITY_DELETED);
        this.getCapacities();
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }

  /**
  * @param {MatCheckboxChange} $event is the event of the capacity's checkbox 
  * @param {FleetCapacity} capacity is the capacity changed
  * @description Changes the capacity's selected attribute and updates the vehicle's controls and validators
  */
  onChangeCapacity($event: MatCheckboxChange, capacity: FleetCapacity) {
    this.vehicles.controls.forEach((vehicle: FormGroup) => {
      if (vehicle.get(capacity.name)) vehicle.removeControl(capacity.name);
      if ($event.checked) vehicle.addControl(capacity.name, new FormControl('', [Validators.required, Validators.min(1), Validators.maxLength(this.maxLengthValueAllow)]));
    })
    const index = this.listCapacities.findIndex(cap => cap.name === capacity.name);
    if (index !== -1) this.listCapacities[index]['selected'] = $event.checked;
  }

  /**
  * @param {FleetCapacity} capacity is the capacity to check
  * @returns {boolean} returns true if the capacity can't be checked, false otherwise
  * @description Checks if the capacity can be checked or not.
  */
  mustDisableCapacity(capacity: FleetCapacity): boolean {
    return (!capacity.selected && this.capacitiesSelected.length === 3)
      || (capacity.selected && this.capacitiesSelected.length === 1);
  }

  /**
  * @returns {FleetCapacity[]} is the list of capacities selected
  * @description Gets the list of capacities selected.
  */
  get capacitiesSelected(): FleetCapacity[] {
    return this.listCapacities.filter(capacity => capacity.selected);
  }

  /**
  * @param {string} capacityName is the name of the capacity to get the sum
  * @returns {number} returns the sum of the vehicles capacities
  * @description returns the sum of the vehicles capacities
  */
  getTotalCapacity(capacityName: string): number {
    return this.vehicles.controls.reduce((totalCapacity, vehicle) => {
      const capacity = vehicle && vehicle.get(capacityName) && vehicle.get(capacityName).value ? parseFloat(vehicle.get(capacityName).value) : 0;
      return totalCapacity + capacity;
    }, 0)
  }

  /**
  * @param {Vehicle} vehicle is the vehicle to add
  * @description Adds a vehicle to vehicles form array
  */
  private addVehicle(vehicle: Vehicle, vehicleCapacity?: VehicleCapacity): void {
    let vehicleGroup = new FormGroup({
      licensePlate: new FormControl(vehicle.id),
      driver: new FormControl(vehicle.driver.document),
      vehicleType: new FormControl(vehicle.vehicleType && vehicle.vehicleType.name ? vehicle.vehicleType.name : '-'),
      isEditing: new FormControl(false)
    });
    this.capacitiesSelected.forEach((capacity, index) => {
      const initialValue = (vehicleCapacity ? index === 0 ? vehicleCapacity.capacity1 : index === 1 ? vehicleCapacity.capacity2 : vehicleCapacity.capacity3 : '') || '';
      vehicleGroup.addControl(capacity.name, new FormControl(initialValue, [Validators.required, Validators.min(1), Validators.maxLength(this.maxLengthValueAllow)]));
    });
    this.vehicles.push(vehicleGroup);
  }

  /**
  * @param {number} index is the index of the vehicle to delete
  * @description Deletes a vehicle from its index and makes a step back if the vehicle array is empty
  */
  deleteVehicle(index: number): void {
    if (this.activeStep === 0) {
      this.vehicles.removeAt(index);
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Seguro desea eliminar el vehículo ${this.vehicles.at(index).value.licensePlate} de la flota?`,
      labelButton1: 'Cancelar',
      labelButton2: 'Eliminar',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state && result.refuse === "Eliminar") {
        this.vehicles.removeAt(index);
        if (!this.vehicles.controls.length) this.goBack();
      }
    });

  }

  /**
  * @param {Event} e is the event of submit from the form 
  * @description Goes to step back
  */
  goBack(e?: Event): void {
    if (e) e.preventDefault();
    this.activeStep = 0;
  }

  /**
  * @param {Event} e is the event of submit from the form 
  * @description Verifies if the form's control "name" is valid and there are some vehicles added to make an step foward
  */
  nextStep(e?: Event): void {
    if (e) e.preventDefault();
    if (this.utils.errorMessagesCustomized(this.form.get('name'), 'nombre', 3)) return;
    if (this.form.get('name').value.trim().length < 3) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MIN_LENGTH_NOT_REACHED, 'nombre', '3'), undefined, 'alert');
      return;
    }
    if (!this.vehicles.length) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'ningún vehículo'), undefined, 'alert');
      return;
    }
    this.activeStep = 1;
  }

  /**
  * @param {number} index is the index of the vehicle to check its capacity
  * @description Verifies if the vehicle's capacity is valid to stop editing that field
  */
  checkCapacity(index: number): void {
    let isSomeCapacityWrong = false;
    for (let capacity of this.capacitiesSelected) {
      if (this.utils.errorMessagesCustomized(this.vehicles.at(index).get(capacity.name), `${capacity.name} del vehículo ${index + 1}`, null, null, 1)) {
        isSomeCapacityWrong = true;
        return;
      }
    }
    if (isSomeCapacityWrong) return;
    this.editVehicleCapacity(index, false);
  }

  /**
  * @param {number} index is the index of the vehicle to edit
  * @param {boolean} isEditing is the value to assign to vehicle's "isEditing" control
  * @param {boolean} resetValue it indicates if is necessary to clean the capacity field.
  * @description Manages the "isEditing" field from a vehicle to allow edit or not, also cleans if resetValue param is true
  */
  editVehicleCapacity(index: number, isEditing: boolean) {
    this.vehicles.at(index).get('isEditing').setValue(isEditing);
  }

  /**
  * @param {string} licensePlate is the license plate of the vehicle to check
  * @return {boolean} returns true if the vehicle already exists in the fleet (edit mode), false otherwise
  * @description Checks if a vehicle exists in the fleet
  */
  isConfirmedVehicle(licensePlate: string): boolean {
    return this.dialogParams && this.dialogParams.fleet && this.dialogParams.fleet.vehicles
      && this.dialogParams.fleet.vehicles.some(vehicle => vehicle.id === licensePlate);
  }

  /**
  * @description Sets showVehicleSelector variable as true
  */
  showVehicleInput(): void {
    this.showVehicleSelector = true;
  }

  /**
  * @description Checks if the vehicles are valid and are not editable to create the fleet
  */
  onSubmit() {
    if (this.vehicles.invalid) {
      for (const [index, vehicle] of this.vehicles.controls.entries()) {
        this.capacitiesSelected.forEach(capacity => {
          if (this.utils.errorMessagesCustomized(vehicle.get(capacity.name), `${capacity.name} del vehículo ${index + 1}`, null, this.maxLengthValueAllow, 1)) return;
        })
      }
      return;
    }
    if (this.vehicles.controls.some(vehicle => !!vehicle.get('isEditing').value)) {
      this.snackBarService.openSnackBar(RoutingMessages.VEHICLES_EDITING, undefined, 'alert');
      return;
    }
    this.createFleet();
  }
  /**
    * @param {Event} e is the event of submit from the form 
    * @description Closes the dialog
    */
  onClose(e?: Event) {
    if (e) e.preventDefault();
    this.dialogRef.close();
  }

  /**
  * @description Opens a modal to confirm the fleet creation
  */
  private createFleet(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Seguro deseas confirmar la flota?`,
      descriptionHTML: '<p>Recuerde que los vehículos agregados <b>NO</b> se podrán eliminar</p>',
      labelButton1: 'Cancelar',
      labelButton2: 'Confirmar',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state && result.refuse === "Confirmar")
        this.realCreateFleet();
    });

  }

  /**
  * @description Creates the fleet with backend service and closes the dialog
  */
  private realCreateFleet(): void {
    let body: StoredFleet = {
      vehicleCapacities: [],
      capacityMeasures: []
    };
    this.capacitiesSelected.forEach((capacity, i) => {
      body.capacityMeasures.push({
        capacityIndex: i + 1,
        name: capacity.name,
        measure: capacity.measure
      })
    });

    this.vehicles.getRawValue().forEach(vehicle => {
      let vehicleToSend = {
        vehiclePlate: vehicle.licensePlate,
        capacity1: 0
      };
      this.capacitiesSelected.forEach((capacity, i) => {
        vehicleToSend[`capacity${i + 1}`] = vehicle[capacity.name];
      })
      body.vehicleCapacities.push(vehicleToSend);
    });
    this.spinner.show();
    this.routingService.createFleet(body, this.form.getRawValue().name).subscribe(
      (success) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(RoutingMessages.FLEET_CREATED);
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
        this.snackBarService.openSnackBar(RoutingMessages.SOME_VEHICLE_IS_WRONG, undefined, 'error');
      },
      () => { this.spinner.hide() }
    )

  }

  /**
  * @description Cleans the licensePlate subscription
  */
  ngOnDestroy(): void {
    this.licensePlateSub && this.licensePlateSub.unsubscribe();
  }

  /**
  * @returns {FormArray} returns the form's control "vehicles" as FormArray
  * @description returns the form's control "vehicles" as FormArray
  */
  get vehicles(): FormArray {
    return this.form.get('vehicles') as FormArray;
  }

  /**
  * @returns {boolean} returns true if the form's control "name" is filled and valid and "vehicles" are filled.
  * @description Verifies if the first step is valid
  */
  get isValidStepOne(): boolean {
    return this.form && this.form.get('name') && this.form.get('name').valid && this.form.get('name').value.trim().length >= 3
      && this.vehicles && !!this.vehicles.controls.length;
  }

  /**
  * @returns {boolean} returns true if the form's control "vehicles" are filled and valid.
  * @description Verifies if the second step is valid
  */
  get isValidStepTwo(): boolean {
    return this.isValidStepOne && this.vehicles.controls.every(vehicle => vehicle.valid && !vehicle.get('isEditing').value);
  }

}
