import { Component, ViewChild, ElementRef, ViewEncapsulation, HostListener, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import Chart, { ChartConfiguration } from 'chart.js';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { Titles } from 'src/app/core/resources/titles';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ReportsFilterComponent } from './reports-filter/reports-filter.component';
import { MillisecondsDigitalClockPipe } from 'src/app/core/pipe/millisecondsDigitalClock.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { Company } from 'src/app/core/interfaces/company';
import { ExportListService } from 'src/app/shared/export-list/export-list.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CurrencyPipe } from '@angular/common';
import { DateManager } from 'src/app/core/managers/date.manager';
import { CargoService } from 'src/app/core/services/cargo.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [AuthService, MillisecondsDigitalClockPipe, CurrencyPipe],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {

  @ViewChild('canvasReport', { static: false }) canvasReport: ElementRef;
  @ViewChild('canvasReportPDF', { static: false }) canvasReportPDF: ElementRef;
  @ViewChild('modalExportReportXLSX', { static: false }) modalExportReportXLSX: ModalComponent;
  @ViewChild('modalExportNovelties', { static: false }) modalExportNovelties: ModalComponent;
  @ViewChild('reportsFilter', { static: false }) reportsFilter: ReportsFilterComponent;
  reportTypeSub: Subscription;
  labelsReports: Array<string> = [];
  dataReports: Array<any> = [];
  colorDataReports: Array<any> = [];
  datasetsReports: Array<any> = [];
  datasetsReportsLegend: Array<any> = [];
  createFile: boolean = true;
  errorSelectedStates: string = '';
  yAxes: Array<object> = [];
  dataChart: object = {};
  typeChart = 'bar';
  isPDFReport = false;
  isPlainReport = false;
  typeFileSelected: 'Excel' | 'PDF' | 'Plain';
  companyUserData: Company;

  canvasReportContext: CanvasRenderingContext2D;
  canvasReportInstance: Chart;
  canvasReportPDFContext: CanvasRenderingContext2D;
  canvasReportPDFInstance: Chart;
  titleChart: string;
  //mensajes
  //generatePdfMessage: string = "Por favor seleccione un tipo de reporte para poder generar el PDF";
  //generateExcelMessage: string = "Por favor seleccione un tipo de reporte para poder generar el excel";
  chartOptions: boolean = false;
  typesChart = [
    {
      name: "bar"
    },
    {
      name: "line"
    },
    {
      name: "pie"
    }
  ];
  constructor(
    private authService: AuthService,
    private millisecondsDigitalClockPipe: MillisecondsDigitalClockPipe,
    public utils: Utils,
    public global: Global,
    public titles: Titles,
    private spinner: NgxSpinnerService,
    private exportListService: ExportListService,
    private snackBarService: SnackBarService,
    private currencyPipe: CurrencyPipe,
    private cargoService: CargoService
  ) {
    this.companyUserData = this.authService.getCompany();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(this.lisenerTypeReportForm.bind(this), 100);
  }

  lisenerTypeReportForm() {
    this.reportTypeSub = this.reportsFilter.formFilter.get('reportType').valueChanges.subscribe(value => {
      this.isPDFReport = value === 'preLiquidation';
      this.isPlainReport = value === 'UIAF';
    });
  }

  getLabelItem(item: Object): string {
    let label = '';
    if (item.hasOwnProperty('averageable')) {
      if (!this.utils.isEmpty(item['averageable'].consecutive)) {
        label = item['averageable'].consecutive;
      } else if (!this.utils.isEmpty(item['averageable'].numberDocumentSender)) {
        label = item['averageable'].numberDocumentSender;
      } else if (!this.utils.isEmpty(item['averageable'].guideId)) {
        label = item['averageable'].guideId;
      }
    }
    return label;
  }

  onFilter($event: { response, titleReport, idReportType, units }) {
    this.labelsReports = [];
    this.dataReports = [];
    this.colorDataReports = [];
    this.datasetsReports = [];
    this.datasetsReportsLegend = [];
    this.dataChart = {};
    let count = 1;
    let countColors = 0;
    const thisClass = this;
    if ($event && $event.titleReport) {
      this.titleChart = $event.titleReport;
    }
    if ($event && $event.response && $event.response.reports) {
      const colors = this.utils.getRandomColorSize(Object.keys($event.response.reports).length);
      // tslint:disable-next-line: forin
      for (const key in $event.response.reports) {

        const color = colors[countColors];
        const dataChart = $event.response.reports[key].average;
        let dataLabel = $event.response.reports[key].average;
        const label = this.getLabelItem($event.response.reports[key]);

        if ($event.units === 'milliseconds') {
          dataLabel = this.millisecondsDigitalClockPipe.transform($event.response.reports[key].average);
        }
        if ($event.units === 'cost') {
          dataLabel = this.currencyPipe.transform($event.response.reports[key].average, 'COP', 'code');
        }

        countColors++;
        this.labelsReports.push(label);

        this.datasetsReportsLegend.push({
          label,
          backgroundColor: color,
          hoverBackgroundColor: color,
          borderColor: color,
          hoverBorderColor: color,
          data: [dataLabel],
          originalValue: dataChart
        });

        if (this.typeChart === "pie") {
          this.dataReports.push(dataChart);
          this.colorDataReports.push(color);
        }

        if (this.typeChart === "bar") {
          this.datasetsReports.push({
            label,
            backgroundColor: color,
            hoverBackgroundColor: color,
            hoverBorderColor: color,
            borderColor: color,
            data: [dataChart]
          });
        }

        if (this.typeChart === "line") {
          this.dataReports.push(dataChart);
          this.colorDataReports.push(color);
        }

        if (count === Object.keys($event.response.reports).length) {

          const maxValue = _.maxBy(this.datasetsReportsLegend, (o) => {
            return o.originalValue;
          });

          if (this.typeChart === "line") {

            this.datasetsReports = [{
              data: this.dataReports,
              backgroundColor: this.colorDataReports,
              hoverBackgroundColor: this.colorDataReports,
              borderColor: this.colorDataReports,
              hoverBorderColor: this.colorDataReports,
              label: ''
            }];
            this.dataChart = {
              labels: this.labelsReports,
              datasets: this.datasetsReports
            };
            this.yAxes = [{
              ticks: thisClass.getTicksChart(thisClass, maxValue.originalValue, $event.units)
            }];
          }

          if (this.typeChart === "bar") {

            this.dataChart = {
              labels: [this.labelsReports],
              datasets: this.datasetsReports
            };
            this.yAxes = [{
              ticks: thisClass.getTicksChart(thisClass, maxValue.originalValue, $event.units)
            }];
          }

          if (this.typeChart === "pie") {

            this.datasetsReports.push({
              label: this.titleChart,
              backgroundColor: this.colorDataReports,
              hoverBackgroundColor: this.colorDataReports,
              borderColor: this.colorDataReports,
              hoverBorderColor: this.colorDataReports,
              data: this.dataReports
            });
            this.dataChart = {
              labels: this.labelsReports,
              datasets: this.datasetsReports
            };
            this.yAxes = [];
          }

          this.renderCanvaReport();
        }
        count++;
      }
    }

  }

  getTicksChart(thisClass, max, units) {

    const maxValue = max + 120000;
    if (units === 'milliseconds') {
      return {
        max: maxValue,
        min: 0,
        userCallback: (v) => {
          return thisClass.millisecondsDigitalClockPipe.transform(v);
        },
        stepSize: 60000
      };
    } else if (units === 'cost') {
      return {
        max: maxValue,
        min: 0,
        userCallback: (v) => {
          return thisClass.currencyPipe.transform(v, 'COP', 'code');
        },
        stepSize: 60000
      };
    } else {
      return {
        min: 0
      };
    }
  }

  renderCanvaReport() {
    let thisClass = this;

    if (this.canvasReportInstance) {
      this.canvasReportInstance.clear();
      this.canvasReportInstance.destroy();
    }

    let objectChart: ChartConfiguration = {
      type: this.typeChart,
      data: this.dataChart,
      options: {
        legend: {
          display: false
        },
        title: {
          display: true,
          position: 'top',
          text: this.titleChart,
          fontSize: 20,
          padding: 15
        },
        scales: {
          yAxes: this.yAxes,
          xAxes: [{
            display: false,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            }
          }]
        }
      }
    }

    if (this.typeChart !== "pie") {
      objectChart.options.tooltips = {
        callbacks: {
          title: (tooltipItem, data) => {
            return null;
          },
          label: (tooltipItems, data) => {
            if (thisClass.typeChart === "bar") {
              let dataValue = '';
              try {
                dataValue = thisClass.datasetsReportsLegend[tooltipItems.datasetIndex].data;
              } catch (e) {
                dataValue = "0";
              }
              return thisClass.datasetsReportsLegend[tooltipItems.datasetIndex].label + ": " + dataValue;
            } else {
              return tooltipItems.xLabel + ": " + tooltipItems.yLabel;
            }
          },
          footer: (tooltipItem, data) => {
            return null;
          }
        }
      };
    }

    if (this.canvasReportPDFInstance) {
      this.canvasReportPDFInstance.clear();
      this.canvasReportPDFInstance.destroy();
    }

    if (this.canvasReportPDF) {
      this.canvasReportPDFContext = this.canvasReportPDF.nativeElement.getContext('2d');
      this.canvasReportPDFInstance = new Chart(this.canvasReportPDFContext, objectChart);
    }

    objectChart.options.responsive = true;
    if (this.canvasReport) {
      this.canvasReportContext = this.canvasReport.nativeElement.getContext('2d');
      this.canvasReportInstance = new Chart(this.canvasReportContext, objectChart);
    }
  }

  changeTypeChart(type) {
    this.onChangeTypeChart({ type, data: this.reportsFilter.onFilterData })
  }

  onChangeTypeChart(event: { type, data }) {
    this.typeChart = event.type;
    this.onFilter(event.data);
  }

  openModalExportReport(typeFile: 'Excel' | 'PDF' | 'Plain' = 'Excel') {
    let typeFileName = typeFile && typeFile === 'Plain' ? 'archivo de texto plano' : typeFile;
    this.typeFileSelected = typeFile;
    if (this.createFile) this.modalExportReportXLSX.openConfirm();
    else if (this.createFile === false && this.errorSelectedStates) this.snackBarService.openSnackBar(Fmt.string(FormMessages.NOT_SELECTED_STATES_REPORT, typeFileName), undefined, 'alert');
    else this.snackBarService.openSnackBar(Fmt.string(FormMessages.NOT_TYPE_REPORT, typeFileName), undefined, 'alert')
  }

  openModalExportNovelties() {
    this.modalExportNovelties.openConfirm();
  }

  generateExcel(genExcel: any) {
    if (genExcel && typeof genExcel === 'object' && 'valid' in genExcel && 'message' in genExcel) {
      this.errorSelectedStates = genExcel.message
      this.createFile = genExcel.valid
    } else {
      this.createFile = genExcel
      this.errorSelectedStates = '';
    }
  }

  closeModal() {
    this.modalExportReportXLSX.closeModal();
  }

  closeModalNovelties() {
    this.modalExportNovelties.closeModal();
  }

  onConfirm() {
    this.reportsFilter.exportReport(this.typeFileSelected);
  }

  onConfirmModalNovelties() {
    this.spinner.show();
    this.exportListService.exportFile(3, this.companyUserData.companyId).subscribe(
      (response) => {
        this.closeModalNovelties();
        this.spinner.hide();
        this.utils.downloadFile(
          response.body,
          'Novedades_' + DateManager.dateToString(new Date(), 'MM-DD-YYYY-HH-mm')
        );
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error generando el archivo', undefined, 'error');
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.renderCanvaReport();
  }

  chartOptionsTrigger($event) {
    this.chartOptions = $event
  }

  ngOnDestroy() {
    if (this.reportTypeSub) this.reportTypeSub.unsubscribe();
  }
}
