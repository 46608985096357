export enum FormMessages {
  INVALID_FIELD = "El campo de %s es inválido",
  MISSING_FIELD = "El campo de %s no se ha diligenciado",
  MANDATORY_FIELD = "Campo obligatorio",
  INCORRECT_FORMAT = "Formato de %s inválido",
  MORE_THAN_ZERO_FIELD = "El campo de %s debe ser mayor a cero",
  MUST_TO_BE_ZERO_FIELD = "El campo de %s debe ser 0 %s",
  UNSELECTED_FIELD = "No se ha seleccionado %s ",
  MAX_LENGTH_EXCEED = "El campo de %s no puede exceder los %d caracteres",
  MAX_LENGTH_EXCEED_DEFAULT = "El campo de %s excedió el número de caracteres máximo permitido",
  MIN_LENGTH_NOT_REACHED = "El campo de %s debe contener mínimo %d caracteres",
  MIN_LENGTH_NOT_REACHED_DEFAULT = "El campo de %s no cuenta con el número de caracteres mínimo requerido",
  MIN_VALUE_NOT_REACHED = "El campo de %s debe ser igual o mayor al valor mínimo (%f) ",
  MIN_VALUE_NOT_REACHED_SHORT = " %s debe ser igual o mayor a %f ",
  MIN_VALUE_NOT_REACHED_STRING = "El campo de %s debe ser igual o mayor al valor mínimo (%s) ",
  MIN_VALUE_NOT_REACHED_DEFAULT = "El campo de %s debe ser igual o mayor al valor mínimo establecido",
  MAX_VALUE_EXCEED = "El campo de %s no puede exceder el valor máximo (%d)",
  MAX_VALUE_EXCEED_DEFAULT = "El campo de %s no puede exceder el valor máximo establecido",
  DATE_EXCEED = "La fecha de %s es posterior a la fecha de %s",
  DATE_NOT_REACHED = "La fecha de %s es anterior a la fecha de %s",
  DATE_INVALID = "La fecha de %s es inválida",
  DATE_INVALID2 = "Las fechas de %s son inválidas",
  TIME_INVALID2 = "Las horas de %s son inválidas",
  DATE_INVALID_DEFAULT = "La fecha ingresada es inválida",
  DATE_INVALID_DEFAULT2 = "Las fechas ingresadas son inválidas",
  MINIMUN_UTILITY_NOT_REACHED = "La utilidad no debe ser menor a %d %%",
  MINIMUN_UTILITY_NOT_REACHED_DEFAULT = "La utilidad es menor a la permitida",
  MAXIMUM_UTILITY_EXCEED = "La utilidad no puede superar el %d %%",
  MAXIMUM_UTILITY_EXCEED_DEFAULT = "La utilidad es mayor a la permitida",
  FIELDS_UNCOMPLETED = "No se han diligenciado todos los campos de %s",
  FILE_NOT_UPLOADED = "No se ha subido el archivo de %s",
  GENERAL_ERROR = "El campo de %s presenta errores, por favor verifique",
  GENERAL_ERROR_DEFAULT = "Hay errores en el formulario, por favor verifique",
  GENERAL_ERROR_FIELD = "El campo presenta errores",
  MISSING_FIELDS = "Por favor complete los campos",
  USER_NOT_FOUND = "No se encontró un usuario registrado con este documento",
  INVALID_DATE = "Se debe ingresar una fecha de vencimiento en los vehiculos: No. %s",
  INVALID_CONTAINER = "El número de contenedor ingresado en los vehículos: No. %s es inválido",
  INVALID_FILTER = "Por favor digita una búsqueda válida para poder filtrar",
  SUCCESS_RECEIVABLE = "Se ha creado la cartera exitosamente",
  NOT_MATCH_PASSWORD = "Las contraseñas no coinciden",
  INVALID_CONTAINER_GENERAL = "Por favor ingresar un numero de contenedor válido antes de continuar. Ej: ABCD1234567",
  INVALID_VERSION = 'Ya hay una versión igual o superior',
  AT_LEAST_ONE_FIELD = "Por favor diligenciar al menos un campo",
  AT_LEAST_ONE_OPTION = "Debe seleccionar una opción de la lista",
  INVALID_FORMAT = "Este formato no está soportado",
  EMPTY_DOCUMENT = "Es necesario subir un archivo para continuar",
  SUCCESS_DELETE_GPS = "El GPS se ha eliminado existosamente",
  NOT_LOAD_IMAGE = "No es posible cargar la imagen adjunta",
  NOT_EXIST_IMAGE = "La imagen cargada no existe en la base de datos",
  NOT_USER_SELECTED = "Por favor seleccione %s",
  TECLOGI_USER = 'Si eres usuario de teclogi no debes registrarte',
  INACTIVE_USER = 'El usuario no está activo. Por favor, actívalo antes de cambiar su rol',
  LAT_LNG_NOT_DEFINED_ORIGIN = "La latitud y longitud de la dirección %d no esta definida por favor ingresarla manualmente",
  LAT_LNG_NOT_DEFINED_DESTINATION = "La latitud y longitud de la dirección %d del destino %d no esta definida por favor ingresarla manualmente",
  ADDRESS_LOCATION_SETTED = "Latitud y longitud asignada correctamente",
  VALIDATE_MISSING_ERROR = "No es posible validar %s si no se ha diligenciado completamente",
  NOT_LOCATION_INFO = "No hay información de localización asociada a esta placa",
  RECOMMENDED_DELETE_LOAD = "La carga está bloqueada, te recomendamos eliminarla y crear una nueva",
  ADDRESS_NOT_DEFINED = "La dirección #%d no esta definida por favor diligenciarla",
  INVALID_FORMAT_FILE = 'El formato del archivo cargado es incorrecto',
  AT_LEAST_ONE_OPTION_VAR = "Debe seleccionar una opción de la lista en el campo de %s",
  NOT_ALLOWED_EDIT_USER = "No es posible editar un usuario con estudio de seguridad en proceso",
  ERROR_OPEN_HISTORY = 'Hubo un error abriendo el historial de novedades. intentelo de nuevo.',
  NOT_REGISTERED_HISTORY = "No hay seguimientos registrados para esta carga",
  MANIFEST_ERROR = "Para continuar con el flujo debes solucionar los errores de manifiesto asociados",
  ERROR_LOAD_RESOURCE = "Hubo un error cargando el recurso por favor vuelve a intentarlo",
  NOT_TYPE_REPORT = "Por favor seleccione un tipo de reporte para poder generar el %s",
  NOT_SELECTED_STATES_REPORT = "Por favor seleccione el o los estado de la carga que requiere para poder generar el %s",
  INVALID_TYPE_REPORT = "Solo puedes generar archivos de %s para reportes de tipo %s",
  START_TRIP_REAL = "Se ha iniciado correctamente el viaje",
  DEACTIVATE_VEHICLE_SUCCESS = "Se actualizó el máximo de días de inactividad para bloquear vehículos",
  CHARGE_OWN_FLEET_SUCCESS = "Se guardó la configuración de cobro de impuestos correctamente",
  REPEATED_DRIVER_PHONE = "El número de celular del conductor tiene otro usuario asignado",
  UNSELECTED_METRIC = "Debe seleccionar al menos un tipo de métrica para generar el reporte",
  NO_DATA_TO_EXPORT = 'No hay datos para exportar',
  FIREBASE_INSTANCES_ERROR = 'Ocurrió un error %s una o mas instacias de firebase',
  DELETE_LOAD_NOT_ALLOWED = "No tienes permisos para eliminar cargas",
  INCOMPLETE_LOAD_FLOW = 'Por favor terminar el flujo de la carga para finalizarla',
  INVALID_TRAVEL_EXPENSES_FORM = 'El formulario de viáticos no es válido, revise los valores.',
  UNSUPPORTED_FILE = 'Archivo no compatible con las siguientes extensiones: %s.',
  INSUFFICIENT_PERMISSION = "No tienes permisos suficientes para %s",
  NOT_TOKEN = "No se ha suministrado un código de acceso"
}

