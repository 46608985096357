import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { AngularFireStorage } from '@angular/fire/storage/storage';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/core/interfaces/user';
import { AccountService } from '../../account/account.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.scss'],
  providers: [AuthService, Global, AccountService, AngularFireStorage]
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private _snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {

  }

  changePassword() {
    var thisClass = this;
    thisClass.spinner.show();
    this.authService.resetPassword(this.authService.getUserSession().email)
      .then((result) => {
        thisClass.spinner.hide();
        thisClass._snackBarService.openSnackBar("Se ha enviado el correo satisfactoriamente");
      })
      .catch((error) => {
        thisClass.spinner.hide();
        thisClass._snackBarService.openSnackBar('Ocurrió un error al enviar el correo', undefined, 'error');
      });
  }

}
