import { Component, Input, OnInit } from '@angular/core';
import { EmailStructure } from 'src/app/core/interfaces/emailStructure';

@Component({
  selector: 'app-email-item',
  templateUrl: './email-item.component.html',
  styleUrls: ['./email-item.component.scss']
})
export class EmailItemComponent implements OnInit {

  @Input() email: EmailStructure;
  constructor() { }

  ngOnInit() {
  }

  //Se podría poner un servicio para eliminar o actualizar los correos

}
