<form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()" class="position-relative"
  [ngClass]="{'form-padding':paramsDialog && paramsDialog.vehicle}">
  <!-- Trailer -->
  <i *ngIf="paramsDialog && paramsDialog.vehicle" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <mat-card>
    <div class="row title-2 text-strong color-secondary-dark">
      <div class="col-md-12">
        {{title}}
      </div>
    </div>
    <mat-card-content>
      <div class="row " permission-display [module]="permission.administration.module"
        [functionality]="permission.administration.createVehicle">
        <div class="col-md-12 text-right">
          <button type="button" *ngIf="this.utils.isDefined(this.trailer)" (click)="changeTrailer()"
            class="btn underline font-primary-light text-strong button-width-auto">
            <i class="fas fa-exchange-alt"></i>
            Cambiar trailer
          </button>
        </div>
      </div>
      <div class="row">
        <mat-panel-description class="mat-expansion-panel-header-description-left">
          Los campos con * son obligatorios
        </mat-panel-description>
      </div>
      <div class="row">
        <app-license-plate [inputFormControl]="form.get('id')"></app-license-plate>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>N° de ejes<span class="asterisk">*</span></mat-label>
            <input matInput type="number" onlyNumbersInt name="axlesTl" formControlName="axles" autocomplete="off">
            <mat-error *ngIf="form.get('configuration.code').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('axles').errors?.pattern">
              {{ utils.giveMessageError('INCORRECT_FORMAT', 'n° de ejes') }}
            </mat-error>
            <mat-error *ngIf="form.get('axles').errors?.min">
              {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'n° de ejes', 1) }}
            </mat-error>
          </mat-form-field>
        </div>



        <div class="col-md-4">
          <mat-form-field appearance="standard" formGroupName="configuration">
            <mat-label>Clase<span class="asterisk">*</span></mat-label>
            <span class="spinner-input" *ngIf="filterConfigurationLoading">
              <i class="fas fa-circle-notch"></i>
            </span>
            <input matInput type="text" name="configurationTl" formControlName="description"
              [matAutocomplete]="autocompleteConfiguration" autocomplete="off">
            <mat-autocomplete autoActiveFirstOption #autocompleteConfiguration="matAutocomplete"
              (optionSelected)="onSelectConfiguration('configuration', $event)">
              <mat-option *ngFor="let configuration of filterConfiguration | async" [value]="configuration">
                {{configuration.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('configuration.code').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>


        <div class="col-md-4">
          <mat-form-field appearance="standard" formGroupName="bodywork">
            <mat-label>Carrocería<span class="asterisk">*</span></mat-label>
            <input matInput type="text" name="bodyworkTl" formControlName="description"
              [matAutocomplete]="autocompleteBodywork" autocomplete="off">
            <mat-autocomplete autoActiveFirstOption #autocompleteBodywork="matAutocomplete"
              (optionSelected)="onSelectCatalog('bodywork', $event)">
              <mat-option *ngFor="let bodywork of filterBodyWork | async" [value]="bodywork">
                {{bodywork.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('bodywork.code').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <!-- <mat-form-field appearance="standard" formGroupName="brand">
            <mat-label>Marca</mat-label>
            <input matInput type="text" name="brandTl" formControlName="description"
              [matAutocomplete]="autocompleteBrand" autocomplete="off" required>
            <mat-autocomplete autoActiveFirstOption #autocompleteBrand="matAutocomplete"
              (optionSelected)="onSelectCatalog('brand', $event)">
              <mat-option *ngFor="let brand of listTrailerBrand " [value]="brand">
                {{brand.name}}
              </mat-option>
            </mat-autocomplete>
            <span class="pattern pattern--invalid"
              *ngIf="form.get('brand.code').errors && form.get('brand.code').errors.required">
              Seleccione una marca
            </span>
          </mat-form-field> -->
          <mat-form-field appearance="standard" formGroupName="brand">
            <mat-label>Marca<span class="asterisk">*</span></mat-label>
            <input matInput type="text" name="brandTl" formControlName="description"
              [matAutocomplete]="autocompleteBrand" autocomplete="off">
            <mat-autocomplete autoActiveFirstOption #autocompleteBrand="matAutocomplete"
              (optionSelected)="onSelectCatalog('brand', $event)">
              <mat-option *ngFor="let brand of filterTrailerBrand | async" [value]="brand">
                {{brand.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('brand.code').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>



        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>Modelo<span class="asterisk">*</span></mat-label>
            <input matInput type="text" onlyNumbersInt maxlength="4" name="modelTl" formControlName="model"
              autocomplete="off">
            <mat-error *ngIf="form.get('model').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('model').errors?.max">
              La fecha no puede superar el año actual
            </mat-error>
            <mat-error *ngIf="form.get('model').errors?.pattern">
              {{ utils.giveMessageError('INCORRECT_FORMAT', 'modelo') }}
            </mat-error>
            <mat-error *ngIf="form.get('model').errors?.minlength">
              {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'modelo', 3) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>Peso (Kg)<span class="asterisk">*</span></mat-label>
            <input matInput type="text" onlyNumbersInt name="emptyWeightTl" formControlName="emptyWeight"
              autocomplete="off">
            <mat-error *ngIf="form.get('emptyWeight').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('emptyWeight').errors?.min">
              {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED_STRING', 'peso', '3000 kg') }}
            </mat-error>
            <mat-error *ngIf="form.get('emptyWeight').errors?.pattern">
              {{ utils.giveMessageError('INCORRECT_FORMAT', 'peso') }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <app-input-attachment [title]="'Tarjeta de Propiedad'" [isDisable]="isDisable"
            [basePath]="'trailer/'+form.get('id').value+'/'" [inputFormCtrl]="form.get('propertyCard')">
          </app-input-attachment>
          <div class="row">
            <div class="col-12">
              <app-files-storage class="width-100" [nameFile]="form.get('propertyCard').value"
                *ngIf="!!form.get('propertyCard').value">
              </app-files-storage>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <app-input-attachment [title]="'Foto de Trailer'" [isDisable]="isDisable"
            [basePath]="'trailer/'+form.get('id').value+'/'" [inputFormCtrl]="form.get('picture')">
          </app-input-attachment>
          <div class="row">
            <div class="col-12">
              <app-files-storage class="width-100" [nameFile]="form.get('picture').value"
                *ngIf="!!form.get('picture').value">
              </app-files-storage>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <span class="separator-horizontal"></span>
  <!-- Owner -->
  <app-basic-person [form]="ownerControls" *ngIf="ownerControls" [title]="'Propietario'"
    [needTerms]="false"></app-basic-person>
  <span class="separator-horizontal"></span>
  <!-- Company & License Plate -->
  <mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-md-6">
          <mat-label>¿El propietario es el mismo administrador?</mat-label>
          &nbsp;&nbsp;
          <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="hasAdministrator">
            <mat-button-toggle [value]="true" (change)="onChangeIsSameAdmin($event)">
              Si
            </mat-button-toggle>
            <mat-button-toggle [value]="false" (change)="onChangeIsSameAdmin($event)">
              No
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <span class="separator-horizontal"></span>
  <!-- Admin -->
  <ng-container *ngIf="!form.get('hasAdministrator').value">
    <span class="separator-horizontal"></span>
    <app-basic-person [form]="adminControls" *ngIf="adminControls" [title]="'Administrator'"></app-basic-person>
  </ng-container>
  <div class="alert alert-warning" role="alert" *ngIf="errorRNDCMessage">
    <i class="fas fa-exclamation-circle mr-2"></i><span>La información presenta inconsistencias para el RNDC debido
      al siguiente error: {{ errorRNDCMessage }}</span>
  </div>
  <span class="separator-horizontal"></span>
  <mat-card-actions class="mat-card-actions-vehcle text-center" *ngIf="!this.updateTrailer" permission-display
    [module]="permission.administration.module" [functionality]="permission.administration.createVehicle">
    <button mat-raised-button color="primary" type="submit" class="button-width-auto">
      Guardar
    </button>
  </mat-card-actions>
  <mat-card-actions class="mat-card-actions-vehcle text-center" *ngIf="this.updateTrailer">
    <button mat-raised-button color="primary" type="submit" class="button-width-auto">
      Actualizar trailer
    </button>
  </mat-card-actions>
</form>