<div class="modal-body wrapper-modal position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <p class="title pt-2 mx-4">¿Sigues ahí? Después de 5 min de inactividad la página será actualizada.</p>
  <p class="subtitle">En
    {{math.floor(secondsInactive/60)}}:{{secondsInactive%60>=10?secondsInactive%60:"0"+secondsInactive%60}} se
    actualizará la página.</p>
  <mat-card-actions>
    <button mat-raised-button color="primary" class="button-width-auto" (click)="reloadPage()">Si</button>
    <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
      (click)="closeDialog()">No</button>
  </mat-card-actions>

</div>