<aside class="col-md-12 mb-md-0 mb-12">
  <div class="container-list h-100">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-10">
          <label class="title-component">
            <i class="fas fa-user-edit"></i>
            Modulos
          </label>
        </div>
        <div class="col-md-2 header-list-options text-right">
          <button matTooltip="Crear Modulo" mat-raised-button type="button" class="button-width-auto"
            (click)="goToCreate()">
            <i class="fas fa-plus-circle font-primary-dark"></i>
            <span class="font-primary-dark">Crear</span>
          </button>
        </div>
        <div class="container-list-body">
          <div class="col-md-12 mb-md-0 mb-12" *ngFor="let ModuleRole of listModules;">
            <div *ngIf="canEditModule(ModuleRole.name)"><app-module-item [module]="ModuleRole"></app-module-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</aside>