import { Component, Input } from '@angular/core';
import { ServerRequest } from 'src/app/core/interfaces/serverRequest';
import { FingerPrintsService } from '../finger-prints.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { FingerPrintDetailComponent } from '../finger-print-detail/finger-print-detail.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-finger-print-item',
  templateUrl: './finger-print-item.component.html',
  styleUrls: ['./finger-print-item.component.scss'],
  providers: [DatePipe]
})
export class FingerPrintItemComponent {
  @Input() fingerprint: ServerRequest;

  constructor(
    public fingerPrintsService: FingerPrintsService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  openDetail() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      fingerprint: this.fingerprint
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(FingerPrintDetailComponent, dialogConfig);
  }

  formatDate(date: string): string {
    if (date) {
      var partsDate = date.split("/");
      if (partsDate.length && partsDate.length === 3) {
        var day = parseInt(partsDate[0]);
        var month = parseInt(partsDate[1]) - 1;
        var year = parseInt(partsDate[2]);
        var dateFormatted = new Date(year, month, day);
        return this.datePipe.transform(dateFormatted, "d MMMM yyyy");
      }
    }
    return date;
  }

}
