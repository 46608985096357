<div class="row">
  <div class="col-12 col-md-6 container-principal">
  </div>
  <div class="col-12 col-md-6 background-white">
    <form class="form_container" id="formContact" [formGroup]="formContact" (ngSubmit)="onSubmit()">
      <div class="container-form w-75">
        <div class="title-1">
          Contáctanos
        </div>
        <div class="text-400 mt-3">¿Deseas presentar una denuncia?
          <u class="font-primary-light cursor-pointer" (click)="navigateTo('/ethical-line')">
            Conoce nuestra linea ética
          </u>
        </div>
        <div class="form-container">
          <mat-form-field appearance="outline" class="mt-4 mb-0">
            <mat-label>Nombre completo<span class="asterisk">*</span></mat-label>
            <input matInput type="text" id="inputPlaceholderUserNameContact" placeholder="Nombres y apellidos"
              formControlName="name">
            <mat-error *ngIf="formContact.get('name').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mt-0 mb-0">
            <mat-label>Correo electrónico<span class="asterisk">*</span></mat-label>
            <input matInput type="text" id="inputPLaceholderEmailContact" placeholder="Escribe tu correo"
              formControlName="email">
            <mat-error *ngIf="formContact.get('email').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mt-0 mb-0">
            <mat-label>Celular<span class="asterisk">*</span></mat-label>
            <input matInput type="text" id="inputPLaceholderPhoneContact" placeholder="Escribe tu número de celular"
              formControlName="phone">
            <mat-error *ngIf="formContact.get('phone').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mt-0 mb-0">
            <mat-label>Mensaje<span class="asterisk">*</span></mat-label>
            <textarea type="text" rows="4" matInput formControlName="description"
              placeholder="Escribe tu mensaje"></textarea>
            <mat-error *ngIf="formContact.get('description').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
          <div class="recaptcha mt-4">
            <ngx-recaptcha2 class="element" #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false"
              formControlName="recaptcha">
            </ngx-recaptcha2>
          </div>
          <div class="button-container mt-3">
            <button mat-raised-button color="primary" class="button bg-color-primary" type="submit"><span
                class="mx-4">Enviar</span></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
