<div class="main_guide_detail position-relative h-100">
  <i *ngIf="isDialog() && !hideBtnClose" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <div class="track-guide-aplication" *ngIf="!isDialog()">
    <div class="container">
      <form class="form_container" id="formContact" [formGroup]="formTrackGuide" (ngSubmit)="onSubmit()">
        <div class="row justify-content-center align-items-baseline">
          <div class="col-8 col-md-4">
            <label class="label-field-1"> Escribe el número de guía </label>
          </div>
          <div class="col-8 col-md-4">
            <mat-form-field>
              <mat-label>Número de guía<span class="asterisk">*</span></mat-label>
              <input matInput type="text" formControlName="guideId" placeholder="Ejm: 01234567890">
              <mat-error *ngIf="formTrackGuide.get('guideId').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-8 col-md-4 button-container">
            <button mat-raised-button color="primary" class="button bg-color-primary" type="submit">Buscar
              guía</button>
          </div>
        </div>
      </form>
    </div>
  </div>


  <div class="track-guide-aplication-detail overflow-scroll">
    <div class="container">
      <div class="row">
        <label class="label-field-2"> Nº de Guía {{guide.guideId}} </label>
      </div>
      <div class="row">
        <div class="status">
          <div class="flex-left">
            <img src="/assets/svg/icons/icon-barcode-report.svg" alt="...">
            <div style="margin-left: 5px;">
              <div class="status-title">Estado actual</div>
              <div class="status-detail">{{guide.stateDocumentName}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row summary-top">

        <div class="col-12 col-lg-4">
          <div class="summary-title">Origen</div>
          <div class="summary-info">Bogotá</div>
        </div>
        <div class="col-12 col-lg-4" *ngIf="guide.destinationName">
          <div class="summary-title">Destino</div>
          <div class="summary-info">{{guide.destinationName}}</div>
        </div>
        <div class="col-12 col-lg-4" *ngIf="guide.amountPackages">
          <div class="summary-title">Cant. de paquetes</div>
          <div class="summary-info">{{guide.amountPackages}}</div>
        </div>

      </div>

      <div class="row">
        <div class="subtitle">
          <div class="text-subtitle">Datos del cliente</div>
        </div>
      </div>
      <div class="container">
        <div class="customer-data">
          <div class="user-info">
            <div class="row" *ngIf="guide.addressee?.name">
              <div class="textbold">
                <i mat-list-icon class="fas fa-user"></i>
                Nombre
              </div>
              <div class="text">{{guide.addressee?.name}}</div>
            </div>

            <div class="row" *ngIf="guide.addressee?.address">
              <div class="textbold">
                <i mat-list-icon class="fas fa-map-marker-alt"></i>
                Dirección
              </div>
              <div class="text">{{guide.addressee?.address}}</div>
            </div>

            <div class="row" *ngIf="guide.addressee?.phone || guide.addressee?.phone2">
              <div class="textbold">
                <i mat-list-icon class="fas fa-phone-alt"></i>
                Celular
              </div>
              <div class="text">{{guide.addressee?.phone}} - {{guide.addressee?.phone2}} </div>
            </div>

          </div>

          <div class="row" *ngIf="guide.addressee?.observations">
            <div class="observations">
              <div class="textbold">Observaciones</div>
              <div class="observations-box">
                {{guide.addressee?.observations}}
              </div>
            </div>
          </div>
          <a *ngIf="guide.images && guide.images.length" class="underline color-secondary-dark text-strong float-right"
            (click)="showEvidence()">
            Ver evidencias
          </a>
        </div>
      </div>
      <div class="row">
        <div class="subtitle">
          <div class="text-subtitle">Historial de la orden</div>
        </div>
      </div>
      <div class="row">
        <div class="historial-guide">
          <div class="row" *ngFor="let history of guide.stateHistoryGuide">
            <div class="col-sm-2">
              <div class="caja">
                <div class="textH">{{history.date| dateFormat:'day'}}</div>
                <div class="textH4">{{(history.date | dateFormat:'month-sbt-3')}} {{history.date |
                  dateFormat:'year'}}</div>
              </div>
            </div>
            <div class="col-sm-10">
              <div class="textH2">{{history.name}}</div>
              <div class="textH3">{{history.date | dateFormat:'time'}}</div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>



</div>