import { Validators } from "@angular/forms";
import { Patterns } from "../resources/patterns";

export interface BranchOffice {
    id?: number,
    name?: string,
    subBranchOffices?: BranchOffice[];
}

export class BranchOfficeModel {
    constructor(
        private patterns: Patterns
    ) {

    }

    public model: BranchOffice = {
        id: 0,
        name: '',
        subBranchOffices: []
    };

    public modelCargoValidators = {
        branchOffice: [
            Validators.required
        ],
        subBranchOffice: [
            Validators.required
        ]
    };
}