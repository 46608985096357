import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ShippingCost } from 'src/app/core/enums/shipping-cost.enum';
import { Company } from 'src/app/core/interfaces/company';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RoutingService } from '../../routing.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { CreateCargoPayload, RouteScenario } from 'src/app/core/interfaces/routeScenario';
import { Router } from '@angular/router';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ModalEnum } from 'src/app/core/enums/modal.enum';

@Component({
  selector: 'app-routing-create-cargo',
  templateUrl: './routing-create-cargo.component.html',
  styleUrls: ['./routing-create-cargo.component.scss'],
})
export class RoutingCreateCargoComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() emitLastStep: EventEmitter<number> = new EventEmitter();
  permission = Permission;
  minUtility: number = ShippingCost.MINIMUN_UTILITY;
  minValue: 0 | 1 = this.hasPermissionNoMinimumUtility ? 0 : 1;
  constructor(
    public utils: Utils,
    public snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private permissionRole: PermissionRole,
    private routingService: RoutingService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.form.get('rate').setValidators([Validators.required, Validators.min(this.minValue)]);
    this.form.get('cost').setValidators([Validators.required, Validators.min(this.minValue)]);
    this.form.updateValueAndValidity();
  }

  /**
  * @description Emits 1 to return to last step
  */
  goBack() {
    this.emitLastStep.emit(1);
  }

  /**
  * @description Verifies if the form is valid to allow create the cargo with the method "createCargo"
  */
  onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.utils.errorMessagesCustomized(this.form.get('rate'), 'tarifa', null, null, this.minValue)) return;
    if (this.utils.errorMessagesCustomized(this.form.get('cost'), 'flete', null, null, this.minValue)) return;
    if (this.utilityCargo < this.minUtility && !this.hasPermissionNoMinimumUtility) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, this.minUtility), undefined, 'alert');
      return;
    }
    this.createCargo();
  }

  /**
  * @description Creates the cargo using the form values
  */
  private createCargo(): void {
    let body: CreateCargoPayload[] = [];
    this.utils.clone(this.form.value).orders.forEach((order: RouteScenario) => {
      delete order['capacities'];
      delete order['id'];
      body.push({
        scenarioToken: this.form.value.scenarioToken,
        licencePlate: order.vehicle,
        rate: this.form.value.rate,
        cost: this.form.value.cost,
        responseRoute: order
      });
    });
    this.spinner.show();
    this.routingService.createCargos(body).subscribe(
      (success) => {
        this.spinner.hide();
        this.showSuccess(success);
      }, (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }

  /**
  * @param {Cargo[]} cargos are the cargos created
  * @description Shows a message with the consecutives of the cargos created
  */
  private showSuccess(cargos: Cargo[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Tu solicitud ha sido creada exitosamente con los consecutivos',
      hideBtnCancel: true,
      messageList: cargos.map(cargo => cargo.consecutive),
      icon: true
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe(() => this.router.navigate(['/cargo/list/request']));
  }

  /**
  * @returns {number} returns the current utility of the cargo to create
  * @description Returns the current utility
  */
  get utilityCargo(): number {
    const rate = this.form.get('rate').value | 0;
    const cost = this.form.get('cost').value | 0;
    if (!rate) return 0;
    const utilityValue = ((rate - cost) / rate * 100);
    return !Number.isNaN(utilityValue) ? utilityValue : 0;
  }

  /**
  * @returns {boolean} returns true if the form is valid and the utility is correct.
  * @description Verifies if the current step is valid
  */
  get isValidStep(): boolean {
    return this.form.valid && (this.utilityCargo >= this.minUtility || this.hasPermissionNoMinimumUtility);
  }

  /**
  * @returns {boolean} returns true if the user has the permission to create cargo without min utility.
  * @description Verifies if the user has the permission to create cargo without min utility.
  */
  get hasPermissionNoMinimumUtility(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createCargosWithoutMinimumUtility
    )
  }

}
