<div class="row">
    <div class="col-12 col-md-6 container-principal">
    </div>
    <div class="col-12 col-md-6 background-white">
        <form class="form_container" id="formContact" [formGroup]="formContact" (ngSubmit)="onSubmit()">
            <div class="w-75 d-flex justify-content-center">
                <div class="form-container">
                    <div class="title-1">
                        Línea ética
                    </div>
                    <div class="text-400 mt-3">
                        Si tiene conocimiento de conductas irregulares y actos no éticos le invitamos a informarlo en
                        este espacio.
                    </div>
                    <mat-form-field appearance="outline" class="mt-4 mb-0">
                        <mat-label>Describa la situación presentada<span class="asterisk">*</span></mat-label>
                        <textarea type="text" rows="4" matInput formControlName="description"
                            placeholder="Escribe la situación"></textarea>
                        <mat-error *ngIf="formContact.get('description').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                    </mat-form-field>
                    <div class="text-bold d-flex justify-content-between flex-wrap align-items-center">
                        <div class="mr-2">¿Desea identificarse?</div>
                        <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="formControlIdentify"
                            (change)="onChangeIdentify($event.value)">
                            <mat-button-toggle [value]="true">
                                <span class="mx-3">Si</span>
                            </mat-button-toggle>
                            <mat-button-toggle [value]="false">
                                <span class="mx-3">No</span>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="mt-2">Su identidad está protegida</div>
                    <div class="info-text mt-1 mb-4">La información suministrada es confidencial y sólo será utilizada
                        para contactarlo en caso de
                        requerir información adicional.</div>
                    <div class="optional-fields">
                        <mat-form-field appearance="outline" class="mt-0 mb-0 field" *ngIf="formControlIdentify.value">
                            <mat-label>Nombre completo<span class="asterisk">*</span></mat-label>
                            <input matInput type="text" id="inputPlaceholderUserNameContact"
                                placeholder="Nombres y apellidos" formControlName="name">
                            <mat-error *ngIf="formContact.get('name').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="mt-0 mb-0 field" *ngIf="formControlIdentify.value">
                            <mat-label>Correo electrónico<span class="asterisk">*</span></mat-label>
                            <input matInput type="email" id="inputPLaceholderEmailContact"
                                placeholder="Escribe tu correo" formControlName="email">
                            <mat-error *ngIf="formContact.get('email').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="mt-0 mb-4 field" *ngIf="formControlIdentify.value">
                            <mat-label>Celular<span class="asterisk">*</span></mat-label>
                            <input matInput type="text" id="inputPLaceholderPhoneContact" onlyNumbersInt
                                placeholder="Escribe tu número de celular" formControlName="phone">
                            <mat-error *ngIf="formContact.get('phone').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                        </mat-form-field>
                        <div class="recaptcha mt-0 field">
                            <ngx-recaptcha2 class="element" #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false"
                                formControlName="recaptcha">
                            </ngx-recaptcha2>
                        </div>
                        <div class="button-container mt-3 field">
                            <button mat-raised-button color="primary" class="button bg-color-primary"
                                type="submit"><span class="mx-4">Enviar</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>