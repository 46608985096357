import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../interfaces/user';
import { Utils } from '../resources/utils';
import { AuthService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class UserActiveGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    public utils: Utils
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve) => {
      const userPromise = this.authService.validateUser();
      userPromise.then((success) => {
        if (this.authService.getUserSession().state.active &&
          this.authService.getUserSession().lastUserTerm.state) {
          resolve(true);
        } else {
          if (this.authService.getUserSession().information.document) {
            if (this.utils.isDefined(sessionStorage.getItem('_phone'))) {
              resolve(true);
            } else {
              if (!this.authService.getUserSession().state.active) {
                this.router.navigate(['/home']);
              } else if (!this.authService.getUserSession().lastUserTerm.state) {
                this.router.navigate(['/account/validate-terms']);
              }
              resolve(false);
            }
          } else {
            this.authService.clearSession();
            if (sessionStorage.getItem('signInType') && sessionStorage.getItem('signInType') === 'redirection') {
              this.router.navigate(['/home']);
              sessionStorage.removeItem('signInType');
            }
            else this.router.navigate(['/account/login']);
            resolve(false);
          }

        }
      });
    });
  }
}
