import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { CargoOptionsComponent } from '../cargo-options/cargo-options.component';
import { CargoTrackingComponent } from '../cargo-tracking/cargo-tracking.component';
import { ListOperationsService } from 'src/app/shared/list-operations/list-operations.service';
@Component({
  selector: 'app-alert-advance-payment',
  templateUrl: './alert-advance-payment.component.html',
  styleUrls: ['./alert-advance-payment.component.scss'],
  providers: [DatePipe]
})
export class AlertAdvancePaymentComponent implements OnInit {
  @ViewChild(CargoOptionsComponent, { static: false }) cargoOptionsComponent: CargoOptionsComponent;
  @ViewChild(CargoTrackingComponent, { static: false }) cargoTrackingComponent: CargoTrackingComponent;
  permission = Permission;
  matchLoadsByVehicle: { licensePlate: string, loads: Cargo[] }[] = [];
  checkedLoadsToPayAdvance: Cargo[] = [];
  @Output() checkedLoadsToPayAdvanceChange: EventEmitter<Cargo[]> = new EventEmitter<Cargo[]>();
  cargoForOptionsAndTracking: Cargo;
  constructor(
    public utils: Utils,
    private spinner: NgxSpinnerService,
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<AlertAdvancePaymentComponent>,
    public permissionRole: PermissionRole,
    private snackbarService: SnackBarService,
    private listOperationsService: ListOperationsService,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: { matchLoadsByVehicle: { [licensePlate: string]: Cargo[] } },
  ) { }

  ngOnInit() {
    if (Object.keys(this.paramsDialog.matchLoadsByVehicle).length) {
      this.matchLoadsByVehicle = this.convertToKeyValuePairs(this.paramsDialog.matchLoadsByVehicle);
      this.cargoForOptionsAndTracking = this.matchLoadsByVehicle[0].loads[0];
      this.setVehicleLoads();
    }
  }

  private setVehicleLoads() {
    this.checkedLoadsToPayAdvance = this.matchLoadsByVehicle
      .filter(vehicle => vehicle.loads[0] && vehicle.loads[0].shippingCost && vehicle.loads[0].shippingCost.advanceState === false)
      .map(vehicle => vehicle.loads[0]);
  }

  handleCheckboxChange($event: MatCheckboxChange, load: Cargo) {
    if ($event && this.utils.isDefined($event.checked)) {
      if ($event.checked === false && this.checkedLoadsToPayAdvance.length) this.checkedLoadsToPayAdvance = this.checkedLoadsToPayAdvance.filter(checkedLoad => checkedLoad !== load);
      else this.checkedLoadsToPayAdvance.push(load);
    }
  }

  convertToKeyValuePairs(matchLoadsByVehicle: { [licensePlate: string]: Cargo[] }): { licensePlate: string, loads: Cargo[] }[] {
    return Object.keys(matchLoadsByVehicle).map(key => ({ licensePlate: key, loads: matchLoadsByVehicle[key] }));
  }

  formatDate(date: string) {
    return this.datePipe.transform(date, "d MMMM yyyy HH:mm a");
  }

  getAdvanceState(load: Cargo): string {
    if (load && load.shippingCost && this.utils.isDefined(load.shippingCost.advanceState)) {
      return load.shippingCost.advanceState ? "Pagado" : "Pendiente";
    }
    return "-";
  }

  deleteLoad(load: Cargo) {
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.removeCargo)) {
      this.cargoOptionsComponent.btnDeleteCarga('fromAdvanceAlert', load);
    }
    else this.snackbarService.openSnackBar(FormMessages.DELETE_LOAD_NOT_ALLOWED, undefined, 'error');
  }

  async loadDeletedOrFinished($event: string) {
    if ($event) {
      let matchLoads: Cargo[] = await this.listOperationsService.getLoadsToPayByParameters('licensePlate', $event);
      let index = this.matchLoadsByVehicle.findIndex(loadsVehicle => loadsVehicle.licensePlate === $event);
      this.matchLoadsByVehicle[index].loads = matchLoads;
      this.setVehicleLoads();
    }
  }

  endLoad(load: Cargo) {
    if (!this.getCanFinalizeCargo(load)) this.snackbarService.openSnackBar(FormMessages.INCOMPLETE_LOAD_FLOW, undefined, 'alert');
    else this.cargoTrackingComponent.endCargo(null, load, 'fromAdvanceAlert');
  }

  public getCanFinalizeCargo(load: Cargo): boolean {
    if (load) {
      const destinations = load.cargoFeature.uploadDownload.destination;
      const addresses = destinations[destinations.length - 1].addresses;
      return addresses[addresses.length - 1].state === 'Cargo unloaded';
    }
    return false;
  }

  public isIncludeInLoadsToPay(load: Cargo): boolean {
    return this.checkedLoadsToPayAdvance.includes(load);
  }

}
