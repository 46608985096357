import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from 'src/environments/environment';
import { Notification } from 'src/app/core/interfaces/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private endpoints: Endpoints,
  ) { }

  getNotifications(pageKey: number, pageSize: number, document: string) {
    return this.http.get<Notification[]>(
      environment.urlServerTeclogi + this.endpoints.getNotifications + '?pageKey=' + pageKey + '&pageSize=' + pageSize + '&document=' + document
    );
  }


  public detailCargo(idCargo) {
    return this.http.get(
      environment.urlServerTeclogi + this.endpoints.urlDetailCargo + idCargo
    );
  }
}
