<div class="d-flex w-100 justify-content-center align-items-center">
  <div class="login-container">
    <div class="header-logotype-login">
      <figure routerLink='/home'>
        <img [src]="urlDefaultLogo" class="logo-primary-responsive" alt="" *ngIf="!authService.sessionActive()">
      </figure>
    </div>
    <a class="nav-home" routerLink='/home'>
      Inicio
    </a>
    <form class="form-container" [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!showMetadataForm">
      <div class="container-form">
        <div class="title-1">
          Inicia sesión
        </div>
        <div>
          <mat-form-field appearance="outline" class="mt-3 pb-0">
            <mat-label>Compañía<span class="asterisk">*</span></mat-label>
            <input (keyup)="changeValue($event)" matInput style="text-transform:uppercase;" type="text" id="inputNit"
              placeholder="EJM: TECLOGI" autocomplete="nit" formControlName="companyName">
            <mat-error *ngIf="loginForm.get('companyName').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="loginForm.get('companyName').errors?.notFound">
              Esta empresa no esta registrada
            </mat-error>
          </mat-form-field>
          <mat-checkbox class="mb-4" (change)="onChangeSubcompanyOption($event)" [value]="withoutGoogle"
            *ngIf="showSubcompanyOption">
            <span class="color-secondary-dark font-weight-normal">Soy cliente de {{
              loginForm.get('companyName').value.toUpperCase()}}</span>
          </mat-checkbox>
          <mat-form-field appearance="outline" class="mb-2" *ngIf="withoutGoogle">
            <mat-label>Correo<span class="asterisk">*</span></mat-label>
            <input matInput type="email" [email]="true" id="inputUserCorporativeMail"
              placeholder="Ejm: micorreo@correo.com" formControlName="userCorporateMail" autocomplete="username">
            <mat-error *ngIf="loginForm.get('userCorporateMail').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="loginForm.get('userCorporateMail').errors?.pattern">
              {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="withoutGoogle">
            <mat-label>Contraseña<span class="asterisk">*</span></mat-label>
            <input matInput [type]="showPassword ? 'text' : 'password'" id="inputPlaceholderEx"
              placeholder="Escribe tu contraseña" formControlName="password" autocomplete="current-password">
            <i matSuffix class="fas color-primary cursor-pointer"
              [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword }"
              (click)="showPassword = !showPassword"></i>
            <mat-error *ngIf="loginForm.get('password').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
          <div class="forgot-password" *ngIf="withoutGoogle">
            <a routerLink="/account/forgot-password">¿Olvidaste tu contraseña?</a>
          </div>
        </div>
        <mat-card class="message-card" *ngIf="showMessageValidation">
          <mat-card-content>
            <p>
              {{message}}
            </p>
          </mat-card-content>
        </mat-card>
        <mat-card-actions class="mt-4">
          <button mat-raised-button color="primary">Iniciar sesión</button>
        </mat-card-actions>
        <mat-card-content>
          <div class="register">
            ¿No tienes cuenta? <a routerLink="/account/register">Regístrate ¡Ya!</a>
          </div>
        </mat-card-content>
      </div>
    </form>
    <app-metadata-form *ngIf="showMetadataForm" (onReturn)="returnToLoginForm()"
      (onSubmitBasicData)="onSubmitBasicData($event)" [singleSignOnData]="sso">
    </app-metadata-form>
  </div>
</div>