import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PreviewNotificationService } from './preview-notification.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataPushMessage } from 'src/app/core/interfaces/pushMessage';
import { timer } from 'rxjs';
import { NotificationTypes } from 'src/app/core/enums/notification-types.enum';

@Component({
  selector: 'app-preview-notification',
  templateUrl: './preview-notification.component.html',
  styleUrls: ['./preview-notification.component.scss'],
  providers: [PreviewNotificationService]
})

export class PreviewNotificationComponent {

  @Output() onClose: EventEmitter<number> = new EventEmitter();
  @Input() index: number;
  @Input() data: DataPushMessage;

  public readonly CRITIC_ALERT_TYPES = [
    NotificationTypes.PANIC,
    NotificationTypes.GPS_OFF,
    NotificationTypes.ANTENNA,
    NotificationTypes.VEHICLE_OFF,
    NotificationTypes.LOW_BATTERY,
    NotificationTypes.OPENED_DOOR,
    NotificationTypes.DEFAULT_ALERT
  ];

  constructor(
    private router: Router,
    public utils: Utils,
    public spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    const timer$ = timer(25000);
    timer$.subscribe(() => {
      this.autoClose();
    });
  }

  public getTitle(notificationType: NotificationTypes) {
    switch (notificationType) {
      case NotificationTypes.PANIC:
        return 'Pánico';
      case NotificationTypes.GPS_OFF:
        return 'GPS Apagado';
      case NotificationTypes.ANTENNA:
        return 'Desconexión antena';
      case NotificationTypes.VEHICLE_OFF:
        return 'Vehículo apagado';
      case NotificationTypes.LOW_BATTERY:
        return 'Batería baja';
      case NotificationTypes.OPENED_DOOR:
        return 'Puerta abierta';
      case NotificationTypes.DEFAULT_ALERT:
        return 'Crítico';
    }
  }

  public isCritic(notificationType: NotificationTypes): boolean {
    return this.CRITIC_ALERT_TYPES.includes(notificationType);
  }

  public goToDetail(): void {
    this.router.navigate(['/cargo/tracking/', this.data.consecutive]);
  }

  private autoClose() {
    this.onClose.emit(this.index)
  }

}
