<aside class="wrapper-user-form">
  <div class="col-md-12">
    <div class="row">

      <div class="col-md-12 wrapper-detail" *ngIf="user.getInformation().getDocument()">
        <i *ngIf="paramsDialog && paramsDialog.driver" matTooltip="Cerrar"
          class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
        <div class="title-component mr-5">
          <div class="col-md-12 p-0">
            <div class="row">
              <div class="col-md-12 pr-0 pl-3">
                <i class="fas fa-user-edit"></i>
                {{this.mode === 'created' ? 'Crear' : 'Ver'}} usuario
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <br>

        <form class="form-container" [formGroup]="formUser" (ngSubmit)="onSubmit()" *ngIf="formUser">
          <mat-tab-group>
            <!-- Información general -->
            <mat-tab label="Información general">
              <span class="separator-horizontal"></span>
              <div class="container">
                <div class="col-md-12">
                  <div class="row align-items-baseline">
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>Nombres<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="name">
                        <mat-error *ngIf="formUser.get('name').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                      <div class="d-flex">
                        <i *ngIf="!formFields.name" (click)="onEditButtonClick('edit','name')"
                          class="mt-2 mr-3 fas fa-pencil-alt color-primary absolute absolute--right-top cursor-pointer"
                          matTooltip="Modificar Nombre" matTooltipPosition="above"></i>
                        <i *ngIf="formFields.name" matTooltip="Confirmar" matTooltipPosition="above"
                          class="mt-2 mr-3 fas fa-check fa-lg color-primary absolute absolute--right-bottom cursor-pointer"
                          (click)="onEditButtonClick('confirm','name')"></i>
                        <i *ngIf="formFields.name" matTooltip="Cancelar" matTooltipPosition="above"
                          class="mt-2 mr-3 fas fa-times fa-lg color-primary absolute absolute--right-top cursor-pointer"
                          (click)="onEditButtonClick('cancel','name')"></i>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>Tipo de documento<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="idDocumentType">
                          <mat-option *ngFor="let type of documenTypes" [value]="type['id']">
                            {{type['name']}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formUser.get('idDocumentType').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>N° de documento<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="document">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" *ngIf="isDriver">
                      <mat-form-field appearance="standard">
                        <mat-label>Fecha de Expedición</mat-label>
                        <i *ngIf="isInactiveWrongDriverData" (click)="openDialogChangeExpeditionDate()"
                          class="fas fa-pencil-alt color-primary absolute absolute--right-top cursor-pointer"
                          matTooltip="Fecha de Expedición" matTooltipPosition="above"></i>
                        <input matInput type="text" formControlName="expeditionDateId">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>Correo<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="email">
                        <mat-icon matSuffix class="cursor-pointer" color="primary"
                          *ngIf="formUser.get('email').errors && formUser.get('email').errors.pattern"
                          matTooltip="No se permiten caracteres especiales al principio('.test@mail.com'), caracteres especiales seguidos ('te..st@mail.com'), más de un arroba ('te@st@mail.com'), espacios ('te st@mail.com'), caracteres especiales al terminar el dominio ('test.@mail.com'), ni correos de extensión mayor a 100 caracteres">help</mat-icon>
                        <mat-error *ngIf="formUser.get('email').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="formUser.get('email').errors?.pattern">
                          {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
                        </mat-error>
                        <mat-error *ngIf="formUser.get('email').errors?.maxlength">
                          {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'correo', 100) }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" *ngIf="isDriver || isOwner || isAdmin">
                      <mat-form-field appearance="standard">
                        <mat-label>Número de Celular<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" onlyNumbersInt formControlName="phone">
                        <mat-error *ngIf="formUser.get('phone').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                      <div class="d-flex">
                        <i *ngIf="!formFields.phone" (click)="onEditButtonClick('edit','phone')"
                          class="mt-2 mr-3 fas fa-pencil-alt color-primary absolute absolute--right-top cursor-pointer"
                          matTooltip="Modificar celular" matTooltipPosition="above"></i>
                        <i *ngIf="formFields.phone" matTooltip="Confirmar" matTooltipPosition="above"
                          class="mt-2 mr-3 fas fa-check fa-lg color-primary absolute absolute--right-bottom cursor-pointer"
                          (click)="onEditButtonClick('confirm','phone')"></i>
                        <i *ngIf="formFields.phone" matTooltip="Cancelar" matTooltipPosition="above"
                          class="mt-2 mr-3 fas fa-times fa-lg color-primary absolute absolute--right-top cursor-pointer"
                          (click)="onEditButtonClick('cancel','phone')"></i>
                      </div>
                    </div>
                    <div class="col-md-4 mt-2">
                      <app-autocomplete-city [inputFormControl]="cityControl" [options]="optionsCity"
                        [validate]="validateCity">
                      </app-autocomplete-city>
                      <div class="d-flex">
                        <i *ngIf="!formFields.city" (click)="onEditButtonClick('edit','city')"
                          class="mt-2 mr-3 fas fa-pencil-alt color-primary absolute absolute--right-top cursor-pointer"
                          matTooltip="Modificar Ciudad" matTooltipPosition="above"></i>
                        <i *ngIf="formFields.city" matTooltip="Confirmar" matTooltipPosition="above"
                          class=" mt-2 mr-3 fas fa-check fa-lg color-primary absolute absolute--right-bottom cursor-pointer"
                          (click)="onEditButtonClick('confirm','city')"></i>
                        <i *ngIf="formFields.city" matTooltip="Cancelar" matTooltipPosition="above"
                          class=" mt-2 mr-3 fas fa-times fa-lg color-primary absolute absolute--right-top cursor-pointer"
                          (click)="onEditButtonClick('cancel','city')"></i>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <!-- <input matInput type="text" formControlName="address"> -->
                      <app-autocomplete-address [title]="'Dirección'" [value]="formUser.get('address').value"
                        [disabled]="formUser.get('address').disabled" [simpleAddress]="true"
                        (emitToParent)="onSelectAddress($event)"></app-autocomplete-address>
                      <mat-error *ngIf="formUser.get('address').errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                      </mat-error>
                      <div class="d-flex">
                        <i *ngIf="!formFields.address" (click)="onEditButtonClick('edit','address')"
                          class="mt-3 mr-3 fas fa-pencil-alt color-primary absolute absolute--right-top cursor-pointer"
                          matTooltip="Modificar Dirección" matTooltipPosition="above"></i>
                        <i style="transform: translateY(-1rem);" *ngIf="formFields.address" matTooltip="Confirmar"
                          matTooltipPosition="above"
                          class="mt-3 mr-3 fas fa-check fa-lg color-primary absolute absolute--right-bottom cursor-pointer"
                          (click)="onEditButtonClick('confirm','address')"></i>
                        <i style="transform: translateY(-1rem);" *ngIf="formFields.address" matTooltip="Cancelar"
                          matTooltipPosition="above"
                          class="mt-3 mr-3 fas fa-times fa-lg color-primary absolute absolute--right-top cursor-pointer"
                          (click)="onEditButtonClick('cancel','address')"></i>
                      </div>
                    </div>
                    <div class="col-md-4"
                      *ngIf="isDriver || isOwner || isAdmin || (paramsDialog && (paramsDialog.showDocuments || paramsDialog.driverIsSameOwner || paramsDialog.ownerIsSameAdmin))">
                      <mat-form-field appearance="standard">
                        <mat-label>ARL<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="ARL">
                        <mat-error *ngIf="formUser.get('ARL').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <app-docs-state
                          *ngIf="user && userInfoSupport?.length && getDocumentSupport('ARL') && !adminUsersService.getTruoraInProgress(originalUser)"
                          matSuffix [expireDocs]="expireArl" [documents]="userInfoSupport"
                          [userState]="user?.getArl()?.getActive()" [dueDateDoc]="user?.getArl()?.getDueDate()"
                          type="ARL"></app-docs-state>
                        <i matSuffix *ngIf="getDocumentSupport('ARL')"
                          class="fas fa-eye color-primary cursor-pointer ml-2" matTooltip="Ver soporte ARL"
                          matTooltipPosition="above" (click)="getURLDocument('ARL','view')"></i>
                        <i matSuffix permission-display [module]="permission.cargo.module"
                          [functionality]="permission.cargo.addDocumentsSupport" (click)="uploadDocument('ARL')"
                          class="fas fa-plus-circle color-primary cursor-pointer ml-1"
                          matTooltip="Agregar soporte de ARL" matTooltipPosition="above"></i>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4"
                      *ngIf="isDriver || isOwner || isAdmin|| (paramsDialog && (paramsDialog.showDocuments || paramsDialog.driverIsSameOwner || paramsDialog.ownerIsSameAdmin))">
                      <mat-form-field appearance="standard">
                        <mat-label>EPS<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="EPS">
                        <mat-error *ngIf="formUser.get('EPS').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <app-docs-state
                          *ngIf="user && userInfoSupport?.length && getDocumentSupport('EPS') && !adminUsersService.getTruoraInProgress(originalUser)"
                          matSuffix [expireDocs]="expireEps" [documents]="userInfoSupport"
                          [userState]="user?.getEps()?.getActive()" [dueDateDoc]="user?.getEps()?.getDueDate()"
                          type="EPS"></app-docs-state>
                        <i matSuffix *ngIf="getDocumentSupport('EPS')"
                          class="fas fa-eye color-primary cursor-pointer ml-2" matTooltip="Ver soporte EPS"
                          matTooltipPosition="above" (click)="getURLDocument('EPS','view')"></i>
                        <i matSuffix permission-display [module]="permission.cargo.module"
                          [functionality]="permission.cargo.addDocumentsSupport" (click)="uploadDocument('EPS')"
                          class="fas fa-plus-circle color-primary cursor-pointer ml-1"
                          matTooltip="Agregar soporte de EPS" matTooltipPosition="above"></i>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" *ngIf="isDriver">
                      <mat-form-field appearance="standard">
                        <mat-label>Licencia De Conduccion<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="License">
                        <mat-error *ngIf="formUser.get('License').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4"
                      *ngIf="isDriver || (isOwner && paramsDialog && (paramsDialog.showDocuments || paramsDialog.driverIsSameOwner || paramsDialog.ownerIsSameAdmin)) || (isAdmin && paramsDialog.showDocuments)">
                      <mat-form-field appearance="standard">
                        <mat-label>Score de Seguridad<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="scoreTruora">
                        <mat-error *ngIf="formUser.get('scoreTruora').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" *ngIf="isDriver">
                      <mat-form-field appearance="standard">
                        <mat-label>Score de Teclogi<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="scoreTeclogi"
                          [ngStyle]="{'color':formUser.get('scoreTeclogi').value<=2&&'red'}">
                        <mat-error *ngIf="formUser.get('scoreTruora').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-4 mt-2">
                      <app-select-company [options]="optionsCompany" [inputFormControl]="company"
                        [validate]="validateCompany">
                      </app-select-company>
                      <div class="d-flex">
                        <i *ngIf="!formFields.operationId" (click)="onEditButtonClick('edit','operationId')"
                          class="mt-2 mr-3 fas fa-pencil-alt color-primary absolute absolute--right-top cursor-pointer"
                          matTooltip="Modificar Operación" matTooltipPosition="above"></i>
                        <i *ngIf="formFields.operationId" matTooltip="Confirmar" matTooltipPosition="above"
                          class="mt-2 mr-3 fas fa-check fa-lg color-primary absolute absolute--right-bottom cursor-pointer"
                          (click)="onEditButtonClick('confirm','operationId')"></i>
                        <i *ngIf="formFields.operationId" matTooltip="Cancelar" matTooltipPosition="above"
                          class="mt-2 mr-3 fas fa-times fa-lg color-primary absolute absolute--right-top cursor-pointer"
                          (click)="onEditButtonClick('cancel','operationId')"></i>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <mat-label>Exclusivo</mat-label>
                      &nbsp;&nbsp;
                      <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="exclusive">
                        <mat-button-toggle [value]="true">
                          Si
                        </mat-button-toggle>
                        <mat-button-toggle [value]="false">
                          No
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>

                    <div class="col-md-4 simple-regimen mb-3">
                      <mat-label>¿Es régimen simple?</mat-label>
                      &nbsp;&nbsp;
                      <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="simpleRegimen"
                        class="mr-5">
                        <mat-button-toggle [value]="true">
                          Si
                        </mat-button-toggle>
                        <mat-button-toggle [value]="false">
                          No
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                      <div class="d-flex flex-column" *ngIf="showButtonEditSimpleRegimen">
                        <i *ngIf="!formFields.simpleRegimen" (click)="onEditButtonClick('edit','simpleRegimen')"
                          class="fas fa-pencil-alt color-primary absolute absolute--right-top cursor-pointer"
                          matTooltip="Modificar régimen simple" matTooltipPosition="above"></i>
                        <i *ngIf="formFields.simpleRegimen" matTooltip="Confirmar" matTooltipPosition="above"
                          class="fas fa-check fa-lg color-primary absolute absolute--right-bottom cursor-pointer"
                          (click)="onEditButtonClick('confirm','simpleRegimen')"></i>
                        <i *ngIf="formFields.simpleRegimen" matTooltip="Cancelar" matTooltipPosition="above"
                          class="fas fa-times fa-lg color-primary absolute absolute--right-top cursor-pointer"
                          (click)="onEditButtonClick('cancel','simpleRegimen')"></i>
                      </div>
                    </div>
                    <div class="col-12"
                      *ngIf="originalUser?.lastPersonalDataPolicy || originalUser?.lastPrivacyPolicies || originalUser?.lastUserTerm">
                      <div class="alert alert-info d-flex flex-md-row flex-column justify-content-between" role="alert">
                        <div class="d-flex flex-column justify-content-between m-2" *ngIf="originalUser?.lastUserTerm">
                          <h5 class="text-bold">Terminos y condiciones</h5>
                          <div>
                            <div>Fecha de aceptación: {{ originalUser?.lastUserTerm?.fingerprint?.date ?
                              formatDate(originalUser?.lastUserTerm?.fingerprint?.date) : '-'}}</div>
                            <div>Hora de aceptación: {{ originalUser?.lastUserTerm?.fingerprint?.date ?
                              dm.onlyTime(originalUser?.lastUserTerm?.fingerprint?.date) : '-'}}</div>
                            <div>Version aceptada: {{ originalUser?.lastUserTerm?.version ?
                              originalUser?.lastUserTerm?.version : '-'}}</div>
                          </div>
                        </div>
                        <div class="d-flex flex-column justify-content-between m-2"
                          *ngIf="originalUser?.lastPersonalDataPolicy">
                          <h5 class="text-bold">Politica de datos personales</h5>
                          <div>
                            <div>Fecha de aceptación: {{ originalUser?.lastPersonalDataPolicy.fingerprint?.date ?
                              formatDate(originalUser?.lastPersonalDataPolicy.fingerprint?.date) : '-'}}</div>
                            <div>Hora de aceptación: {{ originalUser?.lastPersonalDataPolicy.fingerprint?.date ?
                              dm.onlyTime(originalUser?.lastPersonalDataPolicy.fingerprint?.date) : '-'}}
                            </div>
                            <div>Version aceptada: {{ originalUser?.lastPersonalDataPolicy.version ?
                              originalUser?.lastPersonalDataPolicy.version : '-'}}</div>
                          </div>
                        </div>
                        <div class="d-flex flex-column justify-content-between m-2"
                          *ngIf="originalUser?.lastPrivacyPolicies">
                          <h5 class="text-bold">Politicas de privacidad</h5>
                          <div>
                            <div>Fecha de aceptación: {{ originalUser?.lastPrivacyPolicies.fingerprint?.date ?
                              formatDate(originalUser?.lastPrivacyPolicies.fingerprint?.date) : '-'}}</div>
                            <div>Hora de aceptación: {{ originalUser?.lastPrivacyPolicies.fingerprint?.date ?
                              dm.onlyTime(originalUser?.lastPrivacyPolicies.fingerprint?.date) : '-'}}
                            </div>
                            <div>Version aceptada: {{ originalUser?.lastPrivacyPolicies.version ?
                              originalUser?.lastPrivacyPolicies.version : '-'}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="user.getState() && !user.getState().isActive()">
                    <div class="col-md-12 p-0">
                      <mat-label class="color-alert" *ngIf="cannotActivateReason.length > 0">
                        <ng-container *ngFor="let reason of cannotActivateReason;">
                          <i class="fas fa-exclamation-triangle"></i>{{ reason }}
                          <br>
                        </ng-container>
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Documentos">
              <span class="separator-horizontal"></span>
              <div class="container">
                <div class="col-md-12">
                  <app-user-documents *ngIf="reactiveFormDriver && reactiveFormDriver.form"
                    [form]="reactiveFormDriver.form" [hideBtn]="false" (emitToParent)="getDetailUserByDocument($event)">
                  </app-user-documents>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <br>

          <div class="d-flex flex-wrap align-items-center justify-content-around mt-3">
            <button mat-raised-button type="button" permission-display [module]="permission.administration.module"
              [functionality]="permission.administration.validateDocumentsPendingDrivers"
              *ngIf="originalUser.state && (originalUser.state.description === userState.PENDING_VALIDATE_DOCUMENTS || getPendingApproval(originalUser)) && (adminUsersService.getExtraDocumentByType('ARL', originalUser) || adminUsersService.getExtraDocumentByType('EPS', originalUser))"
              [matMenuTriggerFor]="menuOptionsDocuments" class="primary-secondary mb-3 button-small" color="primary">
              Validar documentos
            </button>
            <button mat-raised-button color="primary" permission-display [module]="permission.administration.module"
              [functionality]="permission.administration.showSecurityAnalysis"
              *ngIf="utils.isDefined(user.getTruora()) && utils.isDefined(user.getTruora().getCheckId()) && user?.getState()?.getDescription() != 'Pending' && (this.paramsDialog && this.paramsDialog.validateTruora)"
              class="primary-secondary button-width-auto mb-3 button-small" type="button" (click)="getUrlPDFTruora()">
              Ver análisis de seguridad
            </button>

            <button mat-raised-button *ngIf="showButtonInactivateActivateUser"
              class="button-width-auto mb-3 button-small"
              [ngClass]="{'warn': user.getState().isActive(), 'primary-secondary': !user.getState().isActive()}"
              type="button" (click)="user.getState().isActive() ? openDialogDeactivateUser() : confirmActive()">
              {{user.getState().isActive() ? 'Desactivar Usuario' : 'Activar Usuario'}}
            </button>

            <button mat-raised-button color="primary" class="primary-secondary button-width-auto mb-3 button-small"
              permission-display [module]="permission.administration.module"
              [functionality]="permission.administration.activateByScore" *ngIf="showButtonForceActivation"
              type="button" (click)="confirmForceActivation()">
              Activar por Score
            </button>
            <button type="button" permission-display [module]="permission.administration.module"
              [functionality]="permission.administration.checkUser" *ngIf="showButtonConfirmCheckUser"
              class="button-width-auto primary-secondary mb-3 button-small" mat-raised-button color="primary"
              (click)="checkValidationUser()">
              Validar estudio de seguridad
            </button>
            <button type="button" *ngIf="isInactiveWrongDriverData" permission-display
              [module]="permission.administration.module" [functionality]="permission.administration.updateVehicle"
              class="button-width-auto primary-secondary mb-3 button-small" mat-raised-button color="primary"
              (click)="openDialogChangeExpeditionDate()">
              Editar fecha de expedición
            </button>
            <button mat-raised-button color="primary" type="submit" *ngIf="!disabledForm" class="mb-3 button-small">
              {{this.mode === 'created' ? 'Crear' : 'Guardar'}}
            </button>
          </div>
        </form>

        <!-- Button back -->
        <div class="row md-form " *ngIf="disabledForm && !paramsDialog">
          <label class="label-back-step col-md-2" (click)="backView()">
            <figure>
              <img src="/assets/svg/icons/icon-back.png" alt="">
            </figure>
            Atrás
          </label>
        </div>

      </div>

    </div>
  </div>

</aside>

<mat-menu #menuOptionsDocuments="matMenu">
  <button mat-menu-item (click)="getURLDocument('ARL')"
    *ngIf="adminUsersService.getExtraDocumentByType('ARL', originalUser) && getPendingApprovalByType('ARL', originalUser)">
    ARL
  </button>
  <button mat-menu-item (click)="getURLDocument('EPS')"
    *ngIf="adminUsersService.getExtraDocumentByType('EPS', originalUser) && getPendingApprovalByType('EPS', originalUser)">
    EPS
  </button>
</mat-menu>

<!-- Modal Force Activation User-->
<ng-template #modalConfirmForceActivation let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title" *ngIf="user.getInformation().getDocument()">
      ¿Estás seguro que deseas <br> forzar la activacíon de <br> {{user.getInformation().getName()}}?
    </p>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary" (click)="hideModals()">No</button>
      <button mat-raised-button color="primary" (click)="forzeActivation()">Si</button>
    </mat-card-actions>
  </div>
</ng-template>

<!-- Modal PDF TRUORA -->
<ng-template #modalPDFTruora let-modal>
  <div class="modal-body wrapper-modal modal-content-PDF" *ngIf="urlPDFTruora">
    <embed [src]="urlPDFTruora" type="application/pdf">
  </div>
</ng-template>