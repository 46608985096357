import { Component } from '@angular/core';

export interface PeriodicElement {
    origin: string;
    destino: string;
    modalidad: string;
    dias: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    { origin: 'BOG / MDE', destino: 'BUN', modalidad: 'EXPRESO', dias: '2' },
    { origin: 'BOG / MDE', destino: 'BUN', modalidad: 'CONSOLIDADO', dias: '4' },
    { origin: 'BOG', destino: 'CTG / BAQ / SMR', modalidad: 'EXPRESO', dias: '3' },
    { origin: 'BOG', destino: 'CTG / BAQ / SMR', modalidad: 'CONSOLIDADO', dias: '5' },
    { origin: 'MDE', destino: 'CTG / BAQ / SMR', modalidad: 'EXPRESO', dias: '2' },
    { origin: 'MDE', destino: 'CTG / BAQ / SMR', modalidad: 'CONSOLIDADO', dias: '4' },
    { origin: 'CLO', destino: 'BUN', modalidad: 'EXPRESO', dias: '1', },
    { origin: 'CLO', destino: 'BUN', modalidad: 'CONSOLIDADO', dias: '2' },
    { origin: 'CLO', destino: 'CTG / BAQ / SMR', modalidad: 'EXPRESO', dias: '3' },
    { origin: 'CLO', destino: 'CTG / BAQ / SMR', modalidad: 'CONSOLIDADO', dias: '6' },
    { origin: 'EJE CAFETERO', destino: 'BUN', modalidad: 'EXPRESO', dias: '1' },
    { origin: 'EJE CAFETERO', destino: 'BUN', modalidad: 'CONSOLIDADO', dias: '3' },
    { origin: 'EJE CAFETERO', destino: 'CTG / BAQ / SMR', modalidad: 'EXPRESO', dias: '3' },
    { origin: 'EJE CAFETERO', destino: 'CTG / BAQ / SMR', modalidad: 'CONSOLIDADO', dias: '5' },
    { origin: 'SANTANDERES', destino: 'BUN', modalidad: 'EXPRESO', dias: '3' },
    { origin: 'SANTANDERES', destino: 'BUN', modalidad: 'CONSOLIDADO', dias: '6' },
    { origin: 'SANTANDERES', destino: 'CTG / BAQ / SMR', modalidad: 'EXPRESO', dias: '2' },
    { origin: 'SANTANDERES', destino: 'CTG / BAQ / SMR', modalidad: 'CONSOLIDADO', dias: '4' },
];

@Component({
    selector: 'app-legal-framework-manifests',
    templateUrl: './legal-framework-manifests.component.html',
    styleUrls: ['./legal-framework-manifests.component.scss']
})

export class LegalFrameworkManifestsComponent {



    displayedColumns: string[] = ['origin', 'destino', 'modalidad', 'dias'];
    dataSource = ELEMENT_DATA;

    constructor() {

    }
}