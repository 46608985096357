import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "roleFunctionality",
})
export class RoleFunctionalityPipe implements PipeTransform {
  constructor() { }
  transform(functionality) {
    switch (functionality) {
      //ADMINISTRATION MODULE
      case "adminTerms":
        return "Términos y Condiciones";
      case "adminVehicles":
        return "Vehículos";
      case "adminTrailers":
        return "Trailers";
      case "adminCompanies":
        return "Empresas";
      case "adminUsers":
        return "Usuarios";
      case "setupMonitorIntegration":
        return "Habilitar/Deshabilitar integración GPS (Servicio con costo)";
      case "truoraConfiguration":
        return "Habilitar/Deshabilitar estudio de seguridad";
      case "showDocumentsPendingDrivers":
        return "Mostrar conductores con documentos por validar";
      case "validateDocumentsPendingDrivers":
        return "Validar documentos pendientes de conductores";
      case "showSecurityAnalysis":
        return "Mostrar análisis de seguridad";
      case "disableEnableUsers":
        return "Desactivar / Activar usuarios";
      case "disableEnableDriver":
        return "Desactivar / Activar conductores";
      case "checkUser":
        return "Validar estudio de seguridad";
      case "activateByScore":
        return "Activar conductores por puntaje bajo";
      case "createTermsConditions":
        return "Crear terminos y condiciones";
      case "downloadFileRegistreBank":
        return "Descargar archivo para pagos";
      case "createVehicle":
        return "Crear vehículos";
      case "updateVehicle":
        return "Actualizar vehículos";
      case "activateVehicle":
        return "Activar vehículos";
      case "updateBankAdvance":
        return "Actualizar información bancaria de anticipos | Vehículos";
      case "updateBankBalance":
        return "Actualizar información bancaria de saldos | Vehículos";
      case "createCompany":
        return "Crear empresas";
      case "updateCompany":
        return "Actualizar empresas";
      case "createRoles":
        return "Crear roles";
      case "updateRoles":
        return "Actualizar roles";
      case "changeRole":
        return "Cambiar usuario de rol";
      case "adminRoles":
        return "Roles";
      case "adminModules":
        return "Módulos";
      case "changeDriverVehicle":
        //No se usa esta funcionalidad por ahora
        return "Cambiar conductor del vehículo";
      case "validateAndRemarkVehicleReferences":
        return "Validar y anotar las referencias de los vehículos";
      case "showCreateGps":
        //No se usa ni está referenciada en ningúna parte
        return "Crear GPS";
      case "modifyBasicFieldsUser":
        return "Modificar información básica del usuario";
      case "showDeleteGps":
        return "Eliminar GPS";
      case "editCompliments":
        return "Editar requisitos de cumplido";
      case "createCompanyComplimentType":
        return "Agregar tipos de requisitos de cumplido";
      case "editTags":
        return "Editar etiquetas de carga";
      case "updateCity":
        return "Actualizar ciudades";
      case "linkCities":
        return "Enlazar subciudades y ciudades principales";
      case "addSubCities":
        return "Añadir subciudades";
      case "deleteSubCities":
        return "Eliminar subciudades";
      case "createEmailCompany":
        return "Añadir correos de la compañía";
      case "forceVehicleActivation":
        return "Forzar la activación de un vehículo";
      case "deactivateVehicle":
        return "Desactivar vehículo";
      case "validateVehicleDocuments":
        return "Validar los documentos de un vehículo";
      case "editAdminVehicle":
        return "Editar Administrador de un Vehículo";
      case "editOwnerVehicle":
        return "Editar propietario de un vehículo";
      case "editGpsVehicle":
        return "Editar GPS de un vehículo";
      case "editReferencesVehicle":
        return "Editar referencias y contacto de un vehículo";
      case "turnCreation":
        return "Crear enturnamiento";
      case "reactivateVehicleByInactivity":
        return "Reactivar vehículo por inactividad";
      case "changeFeeToOwnedFleet":
        return "Cambiar el cargo de los impuestos a flota propia";
      //CARGO MODULE
      case "createManualCargo":
        return "Crear carga manual";
      case "changeManifestRequirement":
        return "Cambiar requerimiento de manifiesto";
      case "searchCargo":
        return "Rastrear cargas"
      case "createCargoByGuides":
        return "Crear carga por guías";
      case "createMassiveCargo":
        return "Crear carga masiva";
      case "createCargoFromRoute":
        return "Crear carga desde una ruta";
      case "cargoRequest":
        return "Solicitudes";
      case "cargoRequestNegotiation":
        return "Negociación B2B";
      case "cargosInRoute":
        return "Cargas en ruta";
      case "scheduledCargo":
        return "Cargas programadas";
      case "cargosInNegotiation":
        return "Cargas en negociación";
      case "finishedCargos":
        return "Cargas terminadas";
      case "reports":
        return "Reportes";
      case "cargoItemFinancier":
        return "Carga financiera";
      case "registerLocation":
        return "Agregar localización de conductor";
      case "showManifestError":
        return "Mostrar cargas con error en el manifiesto";
      case "rateCargo":
        return "Calificar carga";
      case "uploadExternalManifest":
        return "Cargar manifiesto externo";
      case "editTravelExpenses":
        return "Editar viáticos de carga";
      case "readDriverDetails":
        return "Leer los detalles del conductor";
      case "readManifest":
        return "Leer el manifiesto de carga o equivalente";
      case "createRoundedCargo":
        return "Crear carga redonda";
      case "watchLiveGPS":
        //Está declarado pero no se usa
        return "Ver el GPS en vivo";
      case "modifyShippingCost":
        return "Agregar bonificaciones y descuentos";
      case "readCargoCompliments":
        return "Consultar requisitos de cumplido";
      case "readDeletedCargoCompliments":
        return "Ver documentos eliminados de requisitos de cumplido";
      case "editCargoCompliments":
        return "Editar requisitos de cumplido";
      case "uploadEvidences":
        return "Cargar evidencias";
      case "uploadExtraDocumentsCargo":
        return "Cargar evidencias extras";
      case "continueTripWithoutEvidences":
        return "Continuar descargue sin evidencias";
      case "negotiationNotifications":
        return "Ver notificaciones de negociación"
      case "trackingNotifications":
        return "Ver notificaciones de seguimiento";
      case "delayBetweenPointsNotifications":
        return "Ver notificaciones de conductor retrasado";
      case "createCargoNotifications":
        return "Ver notificaciones de creación de carga";
      case "userDeactivateNotifications":
        return "Ver notificaciones de desactivaciones de usuarios";
      case "geofenceNotifications":
        return "Ver notificaciones de geocercas";
      case "panicNotifications":
        return "Ver notificaciones de pánico";
      case "removeEvidences":
        return "Eliminar las evidencias de una carga";
      case "confirmLicensePlate":
        return "Confirmar vehículos";
      case "assignLicensePlate":
        return "Asignar vehículo";
      case "startCargo":
        return "Iniciar servicios";
      case "removeCargo":
        return "Eliminar cargas";
      case "cancelCargo":
        return "Desasociar vehiculo de cargas";
      case "approvePayments":
        return "Aprobación de cargas";
      case "expiredCargo":
        return "Cargas expiradas";
      case "showRateCargo":
        return "Mostrar tarifa de carga";
      case "createRequestFromManualCreationCargo":
        return "Crear solicitud desde creación de carga";
      case "createCargoFromRequest":
        return "Crear carga desde solicitud";
      case "showTotalCostCargo":
        return "Mostrar flete de carga";
      case "showPaymentCargo":
        return "Mostrar historial de pagos";
      case "showAnomaliesCargo":
        return "Mostrar historial de novedades";
      case "restoreCargo":
        return "Restaurar carga";
      case "editCargo":
        return "Editar cargas"
      case "finishCargo":
        return "Finalizar cargas";
      case "changeCompany":
        return "Cambiar compañía";
      case "reportAnomalies":
        return "Reportar novedades";
      case "changeRteica":
        return "Cambiar Reteica";
      case "transportedValueSemaphore":
        return "Semáforo por valor de carga";
      case "advanceRequest":
        return "Solicitar pago de anticipo";
      case "changeCargoLoadDate":
        return "Modificar la fecha de una carga";
      case "paymentSemaphore":
        return "Semáforo de pago";
      case "additionalCostCreate":
        return "Crear servicios adicionales";
      case "additionalCostUpdate":
        return "Actualizar servicios adicionales";
      case "additionalCostDelete":
        return "Eliminar servicios adicionales";
      case "approveSemaphore":
        //Está declarado pero no se usa
        return "Semáforo de aprobación";
      case "showBillingData":
        return "Mostrar información de Facturación";
      case "generateAccountingFiles":
        return "Generar archivos contables";
      case "downloadJournalCargoPDF":
        return "Descargar PDF Comprobante Contable";
      case "generateJournalCargo":
        return "Generar Comprobante Contable";
      case "deletedCargo":
        return "Cargas eliminadas";
      case "changeRateFullAccess":
        return "Agregar bonificaciones y descuentos sin restricción";
      case "assignVehicleFullAccess":
        return "Asignar vehículos sin restricción";
      //Ver el GPS en vivo (PERMISO ERRONEO!)
      case "createCargoByRoute":
        //Permiso declarado pero no se usa
        return 'Crear carga por ruta';
      case 'showCostCargo':
        return 'Mostrar costos de carga'
      case 'editAnomalies':
        return 'Modificar eventos de trazabilidad'
      case "showCostCargo":
        return "Mostrar costos de carga";
      case "confirmationAdditionalMandatoryServices":
        return "Confirmación de servicios adicionales obligatorios";
      case "editWeightConsignment":
        return "Editar peso de la remesa";
      case "editCargoAmount":
        return "Editar el valor total de la carga";
      case "bonusAndDiscountsInCargoRejected":
        return "Bonos y descuentos a carga rechazada";
      case "sendEmailReportsLastLocations":
        return "Agregar correos envío reporte últimas localizaciones";
      case "createCargosWithoutMinimumUtility":
        return "Crear cargas urbanas de contenedor vacío sin restricción de utilidad";
      case 'showCreationDateCargo':
        return "Ver fecha de creación de cargas";
      case "editCargoTags":
        return "Editar etiquetas de la carga";
      case "editCargoCompliments":
        return "Editar cumplidos de la carga";
      case 'restartCargo':
        return 'Reiniciar cargas';
      case 'approveCargoUnrestricted':
        return 'Aprobar cargas sin restricción';
      case 'removeCargoUnrestricted':
        return 'Anular cargas sin restricción';
      case 'removeCargoWithPayments':
        return 'Anular cargas con pagos';
      case "loadDownloadStatePoints":
        return "Estado Puntos de Cargue y Descargue";
      case "trackingReports":
        return "Reportes de seguimiento";
      case "addDocumentsSupport":
        return "Agregar soporte de documentos";
      case "saveDriverDocuments":
        return "Guardar documentos del conductor";
      case "sendEmailReport":
        return "Enviar reportes al correo";
      case "editAdvance":
        return "Editar anticipo";
      case "deletePayment":
        return "Eliminar pagos de una carga";
      case "deleteBalancePayment":
        return "Eliminar pago del saldo de una carga";
      case "editTypeVehicle":
        return "Editar tipo de vehículo";
      case "addObservation":
        return "Agregar observacion a la carga";
      case "earlyUploadEvidences":
        return "Subir evidencias de carga de forma temprana";
      case "readVehicleDetails":
        return "Leer los detalles del vehículo";
      case "turn":
        return "Enturnamiento";
      case "editTurn":
        return "Editar enturnamiento";
      case "deleteTurn":
        return "Eliminar enturnamiento";
      case "viewAvailableLoads":
        return "Visualizar cargas disponibles";
      case "assignAvailableLoads":
        return "Asignar cargas disponibles";
      case "viewTurnVehicles":
        return "Visualizar vehículos enturnados";
      case "assignTurnVehicles":
        return "Asignar vehículos enturnados";
      case "additionalServicesWithoutUtilityRestriction":
        return "Servicios adicionales sin restricción";
      case "viewApproveInfo":
        return "Visualizar detalles de aprobación"
      case "approvedBelowSicetac":
        return "Aprobar cargas por debajo del SICETAC";
      case "evidencesFingerprint":
        return "Visualizar quien registró las evidencias";
      case "complimentsFingerprint":
        return "Visualizar quien registró los documentos requeridos de cumplido";
      case "startServiceWithArlExpired":
        return "Iniciar servicios con ARL vencida";
      case "startServiceWithEpsExpired":
        return "Iniciar servicios con EPS vencida";
      case "enableSimultaneousInrouteLoading":
        return "Habilitar cargas en ruta simultánea";
      case "useCarWithExpiredDocuments":
        return "Utilizar vehículos con documentación vencida";
      case "payWithTravelExpenses":
        return "Pagar los viáticos en el pago de anticipos";
      case "changeApprovedTravelExpenses":
        return "Realizar cambios a viáticos legalizados";
      case "createBonusOrPenaltyToCargo":
        return "Crear conceptos de modificaciones al flete y tarifa de una carga";
      case "serviceRequests":
        return "Solicitudes de servicio";
      //PAYMENT MODULE
      case "approveBankAdvance":
        return "Aprobar información bancaria de anticipos | Vehículos";
      case "approveBankBalance":
        return "Aprobar información bancaria de saldos | Vehículos";
      case "adminCities":
        return "Ciudades";
      case "charges":
        return "Facturar Cargas";
      case "createCostCenter":
        return "Crear centros de costo";
      case "associateCostCenter":
        return "Asociar centros de costo";
      case "paymentExtraAdvance":
        return "Pagar sobreanticipos";
      case "billingXML":
        return "Realizar facturación XML";
      case "reverseApproval":
        return "Reversar aprobaciones";
      case "paymentBalance":
        return "Pagar saldos";
      case "paymentAdvance":
        return "Pagar anticipos";
      case "paymentCargo":
        return "Pagar cargas";
      case "editSimpleRegimen":
        return "Editar régimen simple";
      case "modifyAdditionalCostBilled":
        return "Editar servicios adicionales con carga facturada";
      case "modifyAdditionalCostpayed":
        return "Editar tarifa de servicios adicionales pagados";
      case "addAdditionalServiceCargoPaid":
        return "Crear servicios adicionales en cargas pagadas";
      case "addAdditionalServiceCargoCashed":
        return "Crear servicios adicionales en cargas facturadas";
      case "addAdditionalServiceCargoApproved":
        return "Crear servicios adicionales en cargas aprobadas";
      case "editRateCargoUnrestricted":
        return "Editar tarifa de carga sin restricción";
      case "fastPaymentRequest":
        return "Solicitud de pronto pago";
      case "adminReceivable":
        return "Cartera";
      case "createReceivable":
        return "Crear carteras";
      case "deleteReceivable":
        return "Eliminar carteras";
      case "viewPaymentsReceivables":
        return "Ver abonos de carteras";
      case "billingWithFlexibilityChoice":
        return "Facturación con flexibilidad de elección";
      case "unrestrictedAdvancePayments":
        return "Pagar anticipos sin restricción";
      case "unrestrictedAdvancePaymentsRequest":
        return "Solicitar anticipos sin restricción";
      //GUIDES MODULE
      case "loadGuides":
        return "Cargar guías";
      case "changeStateGuides":
        return "Cambiar estado de guías";
      case "createGuidesFromExcel":
        return "Crear guías desde XLS";
      case "generateGuides":
        return "Generación de guías";
      case "trackGuide":
        return "Rastreo de guías";

      //POINTS MODULE
      case "createPoint":
        return "Crear puntos";
      case "listPoints":
        return "Ver puntos";
      case "removePoint":
        return "Eliminar puntos";
      case "updatePoint":
        return "Actualizar puntos";

      //CONFIG MODULE
      case "companyDocuments":
        return "Documentos de la empresa";
      case "changePassword":
        return "Cambiar contraseña";
      case "termsAndConditions":
        return "Términos y condiciones";
      case "privacyPolicy":
        return "Políticas de privacidad";
      case "personalDataPolicies":
        return "Políticas tratamiento de datos personales";
      case "habeasData":
        return "HABEAS DATA";
      case "termsManifest":
        return "Anexo manifiesto de carga";
      case "bascPolicies":
        return "Políticas BASC";
      case "quickPay":
        return "Pronto pago";
      // ROUTES MODULE
      case "routes":
        return "Rutas";
      case "routePlanning":
        return "Planes de ruta";
      case "routeSites":
        return "Sitios Autorizados"

      //HELP DESK MODULE
      case "createTicket":
        return "Crear ticket";
      case "listTicket":
        return "Ver tickets";
      case "replyTechnicalTicket":
        return "Responder tickets de problemas técnicos";
      case "replyWebTicket":
        return "Responder tickets de problemas web";
      case "replyMobileTicket":
        return "Responder tickets de problemas en movil";
      case "replyOtherTicket":
        return "Responder tickets generales";
      case "reopenTicket":
        return "Re-abrir tickets";


      case "useCarNearExpirationDocuments":
        return "Utilizar vehículos con documentación próxima a expirar";

      case "approveBalanceDriver":
        return "Aprobar pago saldos";
      case "specialLicensePlate":
        return "Asignar licencia especial";

      //MONITORING MODULE
      case "monitorlogging":
        return "App logging";
      case "fingerprintslogging":
        return "Solicitudes por usuario";

      //ANALYTICS MODULE
      case "analyticsOperations":
        return "Operaciones";
      case "vehicleAnalytics":
        return "Vehículos";
      case "frequentRoutes":
        return "Rutas frecuentes";
      case "dispatch":
        return "Despachos";
      case "stopedCargos":
        return "Cargas detenidas";
      case "analyticsBilling":
        return "Facturación";
      //ROUTING MODULE
      case "createRouting":
        return "Crear ruteo";
      case "routingList":
        return "Listado de ruteos";

      default:
        return functionality;
    }
  }
}
