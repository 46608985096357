import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  providers: [AuthService]
})
export class QuestionsComponent implements OnInit {

  panelOpenState: boolean = false;

  constructor(private _authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

}
