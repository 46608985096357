import { Component } from '@angular/core';

@Component({
    selector: 'app-about-us',
    templateUrl: './aboutUs.component.html',
    styleUrls: ['./aboutUs.component.scss']
})

export class AboutUsComponent {
  
}