export class TermsConditionsModel {

    private version: string;
    private date: string;
    private state: boolean;
    private html: string;
    private url: string;

    public getVersion(): string {
        return this.version;
    }

    public setVersion(version: string): void {
        this.version = version;
    }

    public getDate(): string {
        return this.date;
    }

    public setDate(date: string): void {
        this.date = date;
    }

    public getState(): boolean {
        return this.state;
    }

    public setState(state: boolean): void {
        this.state = state;
    }

    public getHtml(): string {
        return this.html;
    }

    public setHtml(html: string): void {
        this.html = html;
    }

    public getUrl(): string {
        return this.url;
    }

    public setUrl(url: string): void {
        this.url = url;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
