import { Pipe, PipeTransform } from '@angular/core';
import { DateManager } from '../managers/date.manager';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  constructor() { }

  transform(date, type?: string) {
    try {
      switch (type) {
        case 'day':
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'DD');
        case 'month':
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'MM');
        case 'month-sbt-3': //Ene, Feb ...
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'MMM');
        case 'year':
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'YYYY');
        case 'time':
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'hh:mm a');
        case 'time-24':
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm A ZZ', 'HH:mm');
        case 'only-date':
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD');
        case 'only-date-tz':
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD ZZ');
        case 'date-time':
          return DateManager.formatDate(date);
        case 'date-time-full':
          return `${DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD')} - ${DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'h:mm A')}`
        case 'date-time-full-24':
          return `${DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD')} - ${DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ', 'HH:mm')}`
        case 'only-time':
          return DateManager.formatDate(date, 'HH:mm', 'h:mm A');
        default:
          return DateManager.formatDate(date, 'YYYY-MM-DD HH:mm ZZ a', 'YYYY-MM-DD HH:mm a');
      }
    } catch (e) {
      return date;
    }
  }

}
