import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { nextCursorPositionFor } from 'angular-forms-input-masks';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import * as XLSX from 'xlsx';
import { Utils } from './utils';
import { ModalEnum } from '../enums/modal.enum';

export class ReadXLSX {

  constructor(
    private dialog: MatDialog,
    public utils: Utils,
    private spinner: NgxSpinnerService
  ) {

  }

  readFile($event: any, row: number, sheetName?: any) {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      if ($event && $event.length) {
        try {
          const reader = new FileReader();
          const file = $event[0];
          reader.onload = async (event) => {
            const dataXLSX = reader.result;
            const workBook = XLSX.read(dataXLSX, {
              type: 'binary', cellText: false, cellDates: true
            });
            const jsonXLSX = workBook.SheetNames.reduce((initial, name) => {
              const sheet = workBook.Sheets[name];
              initial[name] = XLSX.utils.sheet_to_json(sheet, {
                header: 1, defval: '-', blankrows: true, raw: true
              });
              return initial;
            }, {});
            if (sheetName) {
              const titles = await this.getProcessTitles(jsonXLSX, sheetName.key, row);
              this.spinner.hide();
              resolve({ titles, jsonXLSX, sheetName });
            } else {
              this.spinner.hide();
              this.checkSizeSheets(jsonXLSX)
                .then(async (success: { sheets, sheetName }) => {
                  const titles = await this.getProcessTitles(jsonXLSX, success.sheetName.key, row);
                  resolve({ titles, jsonXLSX, sheets: success.sheets, sheetName: success.sheetName });
                })
                .catch((error) => {
                  reject();
                });
            }
          };
          reader.readAsBinaryString(file);
        } catch (e) {
          this.spinner.hide();
          reject(e);
        }
      } else {
        this.spinner.hide();
        resolve('Debes seleccionar un archivo valido');
      }
    });
  }

  getProcessTitles(jsonXLSX, sheetName: string, row: number) {
    // tslint:disable-next-line: max-line-length
    const titlesSelected = {};
    for (let i = 0; i < this.getObjetKeys(jsonXLSX[sheetName][row]).length; i++) {
      titlesSelected[i] = {
        value: null
      };
    }
    return titlesSelected;
  }

  checkSizeSheets(jsonXLSX: any) {
    return new Promise((resolve, reject) => {
      if (this.getObjetKeys(jsonXLSX).length > 1) {
        this.openDialogSheets(this.getObjetKeys(jsonXLSX) as [])
          .then((success: { sheets, sheetName }) => {
            resolve(success);
          })
          .catch((error) => {
            reject();
          });
      } else {
        resolve({
          sheets: this.utils.listToDefault((this.getObjetKeys(jsonXLSX) as []).map((sheetName, i) => {
            return { key: sheetName, value: i };
          }), 'key'),
          sheetName: { key: this.getObjetKeys(jsonXLSX)[0], value: 0 }
        });
      }
    });
  }

  openDialogSheets(sheets: []) {
    return new Promise((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();
      const listSheets = this.utils.listToDefault(sheets.map((sheetName, i) => {
        return { key: sheetName, value: i };
      }), 'key');
      dialogConfig.data = {
        state: true,
        title: 'Seleccione una hoja del archivo',
        viewSelect: true,
        list: listSheets,
        hideBtnCancel: true
      };
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        resolve({ sheets: listSheets, sheetName: result && result.itemSelected ? result.itemSelected : listSheets[0].value });
      });
    });
  }

  private getObjetKeys(object) {
    return object ? Object.keys(object) : [];
  }

}
