<div class="padding-modal">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close({state: false})"></i>
  <span class="separator-horizontal"></span>
  <div class="d-flex flex-column">
    <i class="fas fa-exclamation-circle fa-shake mb-4"></i>

    <div class="title-2 header">
      <div class="titulo color-primary-dark">
        ¿ Está seguro {{ userName }} que desea terminar la carga
        <span class="color-secondary-dark">#{{ dialogParams.cargo.consecutive }}</span> ?
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="row subtitle mb-2 justify-content-center">
      Detalles de la carga {{ driver}}
    </div>
    <div class="row ml-1">
      <i class="fas fa-thumbtack mr-1 mt-1 color-primary-dark fa-lg"></i> Operación:<span class="bold-text ml-1">{{
        utils.isDefined(dialogParams.cargo.idCompany) ? (dialogParams.cargo.idCompany | companyName) : '-'}}</span>
    </div>
    <div class="row ml-1">
      <i class="fas fa-thumbtack mr-1 mt-1 color-primary-dark fa-lg"></i> Origen:<span class="bold-text ml-1">{{
        dialogParams.cargo.cargoFeature.uploadDownload.origin.name }}</span>
    </div>
    <div class="row ml-1 mt-1">
      <i class="fas fa-thumbtack mr-1 mt-1 color-primary-dark fa-lg"></i> Fecha de cargue:<span
        class="bold-text ml-1">{{utils.isDefined(dialogParams.cargo.dateLoad) ? (dialogParams.cargo.dateLoad |
        dateFormat:'only-date') : '-'}}</span>
    </div>
    <div class="row ml-1 mt-1">
      <i class="fas fa-thumbtack mr-1 mt-1 color-primary-dark fa-lg"></i> Destino:<span class="bold-text ml-1">{{
        dialogParams.cargo.cargoFeature.uploadDownload.destinationName[dialogParams.cargo.cargoFeature.uploadDownload.destinationName.length
        - 1] }}</span>
    </div>
    <div class="row ml-1 mt-1">
      <i class="fas fa-thumbtack mr-1 mt-1 color-primary-dark fa-lg"></i> Fecha de descargue:<span
        class="bold-text ml-1">{{utils.isDefined(dialogParams.cargo.cargoFeature.uploadDownload.destination[dialogParams.cargo.cargoFeature.uploadDownload.destination.length
        - 1].downloadDate) ?
        (dialogParams.cargo.cargoFeature.uploadDownload.destination[dialogParams.cargo.cargoFeature.uploadDownload.destination.length
        - 1].downloadDate | dateFormat:'only-date') : '-'}}</span>
    </div>
    <div class="row ml-1">
      <i class="fas fa-thumbtack mr-1 mt-1 color-primary-dark fa-lg"></i> Conductor:<span class="bold-text ml-1">{{
        dialogParams.driver.information.name }}</span>
    </div>
    <div class="row ml-1">
      <i class="fas fa-thumbtack mr-1 mt-1 color-primary-dark fa-lg"></i> Placa:<span class="bold-text ml-1">{{
        dialogParams.vehicle.id }}</span>
    </div>
  </div>
  <div class="col-12 container-button">
    <mat-card-actions class="text-center">
      <button mat-raised-button color="primary" (click)="this.dialogRef.close({state: false})"
        class="warn button-width-auto">
        No
      </button>
    </mat-card-actions>
    <mat-card-actions class="text-center">
      <button mat-raised-button color="primary" (click)="this.dialogRef.close({state: true})"
        class="secondary-color-solid button-width-auto">
        Si
      </button>
    </mat-card-actions>
  </div>
</div>