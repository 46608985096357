export class ArlModel {
  private active?: boolean;
  private description?: string;
  private dueDate?: string;

  public getActive() {
    return this.active;
  }

  public getDescription() {
    return this.description;
  }

  public getDueDate() {
    return this.dueDate;
  }

  public setActive(active: boolean) {
    this.active = active;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setDueDate(dueDate: string) {
    this.dueDate = dueDate;
  }

  public deserialize(input: any) {
    Object.assign(this, input);

    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        if (this["set" + key.charAt(0).toUpperCase() + key.slice(1)]) {
          this["set" + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
        }
      }
    }

    return this;
  }
}
