import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LastPointLocation } from 'src/app/core/interfaces/lastPointLocation';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CargoLocationReportComponent } from '../cargo-location-report/cargo-location-report.component';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Permission } from 'src/app/core/resources/permission';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DateManager } from 'src/app/core/managers/date.manager';


@Component({
  selector: 'app-cargo-last-point-location',
  templateUrl: './cargo-last-point-location.component.html',
  styleUrls: ['./cargo-last-point-location.component.scss']
})
export class CargoLastPointLocationComponent implements OnInit {
  permission = Permission;
  @Input() title: string;
  @Input() lastPointLocation: LastPointLocation;
  @Input() cargo: Cargo;
  @Output() lastPointChanged = new EventEmitter<any>();
  constructor(
    public matDialog: MatDialog,
    public utils: Utils,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
  }

  get timeLastPoint(): string {
    if (this.lastPointLocation && this.lastPointLocation.fingerprint && this.lastPointLocation.fingerprint.date)
      return DateManager.dateDiffFromNow(this.lastPointLocation.fingerprint.date, null, false);
    return '-';
  }

  public updateLastPoint(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      report: {
        parent: "lastPointLocation",
      }
    }
    if (this.cargo.lastPointLocation && (this.cargo.lastPointLocation.news || this.cargo.lastPointLocation.name || this.cargo.lastPointLocation.anomaly)) {
      dialogConfig.data.report['name'] = this.cargo.lastPointLocation.news ?
        this.cargo.lastPointLocation.news : this.cargo.lastPointLocation.name ?
          this.cargo.lastPointLocation.name : this.cargo.lastPointLocation.anomaly;
    }
    if (this.cargo.lastPointLocation && this.cargo.lastPointLocation.observation) {
      dialogConfig.data.report['observation'] = this.cargo.lastPointLocation.observation;
    }
    if (this.cargo.lastPointLocation && this.cargo.lastPointLocation.fingerprint.date) {
      dialogConfig.data.report['date'] = this.cargo.lastPointLocation.fingerprint.date;
    }
    if (this.cargo.lastPointLocation && this.cargo.lastPointLocation.city) {
      dialogConfig.data.report['city'] = this.cargo.lastPointLocation.city;
    }
    if (this.cargo.lastPointLocation && this.cargo.lastPointLocation.address) {
      dialogConfig.data.report['address'] = this.cargo.lastPointLocation.address;
    }
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.matDialog.open(CargoLocationReportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state) && result.state && result.cargo) {
        this.lastPointChanged.emit(true);
        this.lastPointLocation = result.cargo.lastPointLocation;
        this.cargo.lastPointLocation = this.lastPointLocation;
        this.snackBarService.openSnackBar('Registro actualizado correctamente');
      }
      if (!this.utils.isEmpty(result) && !this.utils.isEmpty(result.state) && !result.state) {
        this.snackBarService.openSnackBar('Error al actualizar registro', undefined, 'error');
      }
    });
  }

}
