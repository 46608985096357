import { CargoFeature } from './cargo-feature.model';
import { CargoModel } from './cargo-model.model';
import { ShippingCost } from './shipping-cost.model';

export class Cargo {

    private id?: string;
    private idCompany?: string;
    private dateLoad: string;
    private distancy: number;
    private consecutive: number;
    private licensePlate?: string;
    private numberDocumentSender: string;
    private numberDocumentCreatorLoad: string;
    private driver?: string;
    private cargoModel?: CargoModel;
    private cargoFeature: CargoFeature = new CargoFeature();
    private shippingCost: ShippingCost;
    private exclusive: boolean;
    private estimatedTime: number;
    private realTime?: number;
    private state?: string;
    private stateTracking?: string;
    private checked?: boolean;
    private validatedBankAccount?: boolean;
    private observation?: string;

    public getObservation?(): string {
        return this.observation;
    }

    public setObservation?(observation?: string): void {
        this.observation = observation;
    }

    public getId?(): string {
        return this.id;
    }

    public setId?(id?: string): void {
        this.id = id;
    }

    public getConsecutive?(): number {
        return this.consecutive;
    }

    public setConsecutive?(consecutive?: number): void {
        this.consecutive = consecutive;
    }

    public getIdCompany?(): string {
        return this.idCompany;
    }

    public setIdCompany?(idCompany?: string): void {
        this.idCompany = idCompany;
    }

    public getDateLoad(): string {
        return this.dateLoad;
    }

    public setDateLoad(dateLoad: string): void {
        this.dateLoad = dateLoad;
    }

    public getDistancy(): number {
        return this.distancy;
    }

    public setDistancy(distancy: number): void {
        this.distancy = distancy;
    }

    public getLicensePlate?(): string {
        return this.licensePlate;
    }

    public setLicensePlate?(licensePlate?: string): void {
        this.licensePlate = licensePlate;
    }

    public getNumberDocumentSender(): string {
        return this.numberDocumentSender;
    }

    public setNumberDocumentSender(numberDocumentSender: string): void {
        this.numberDocumentSender = numberDocumentSender;
    }

    public getNumberDocumentCreatorLoad(): string {
        return this.numberDocumentCreatorLoad;
    }

    public setNumberDocumentCreatorLoad(numberDocumentCreatorLoad: string): void {
        this.numberDocumentCreatorLoad = numberDocumentCreatorLoad;
    }

    public getDriver?(): string {
        return this.driver;
    }

    public setDriver?(driver?: string): void {
        this.driver = driver;
    }

    public getCargoModel?(): CargoModel {
        return this.cargoModel || new CargoModel();
    }

    public setCargoModel?(cargoModel?: CargoModel): void {
        this.cargoModel = new CargoModel();
        this.cargoModel.deserialize(cargoModel);
    }

    public getCargoFeature(): CargoFeature {
        return this.cargoFeature;
    }

    public setCargoFeature(cargoFeature: CargoFeature) {
        this.cargoFeature = new CargoFeature();
        this.cargoFeature.deserialize(cargoFeature);
    }

    public getShippingCost(): ShippingCost {
        return this.shippingCost || new ShippingCost();
    }

    public setShippingCost(shippingCost: ShippingCost): void {
        this.shippingCost = new ShippingCost();
        this.shippingCost.deserialize(shippingCost);
    }

    public isExclusive(): boolean {
        return this.exclusive;
    }

    public setExclusive(exclusive: boolean): void {
        this.exclusive = exclusive;
    }

    public getEstimatedTime(): number {
        return this.estimatedTime;
    }

    public setEstimatedTime(estimatedTime: number): void {
        this.estimatedTime = estimatedTime;
    }

    public getRealTime?(): number {
        return this.realTime;
    }

    public setRealTime?(realTime?: number): void {
        this.realTime = realTime;
    }

    public getState?(): string {
        return this.state;
    }

    public setState?(state?: string): void {
        this.state = state;
    }

    public getStateTracking?(): string {
        return this.stateTracking;
    }

    public setStateTracking?(stateTracking?: string): void {
        this.stateTracking = stateTracking;
    }

    public isChecked?(): boolean {
        return this.checked;
    }

    public setChecked?(checked?: boolean): void {
        this.checked = checked;
    }

    public isValidatedBankAccount?(): boolean {
        return this.validatedBankAccount;
    }

    public setValidatedBankAccount?(validatedBankAccount?: boolean): void {
        this.validatedBankAccount = validatedBankAccount;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
