import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CargoAdvancePercentageService {

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private _globalResources: Global,
  ) { }

  updatePercentage(idCargo, Percentage) {

    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.modifyAdvancePercentaje + idCargo + '?percentage=' + Percentage, {}
    );
  }



}


