import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Endpoints } from "src/app/core/resources/endpoints";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CargoRatingService {

    constructor(private _http: HttpClient, private _endpointResources: Endpoints) { }

    public rateCargo(idCargo, params) {
        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.urlRateCargo + idCargo + '&role=Creator',
            params
        );
    
    }

}

