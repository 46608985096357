import { Component, Input, OnInit } from '@angular/core';
import { Global } from 'src/app/core/resources/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { FunctionalitiesService } from '../functionalities.service';
import { Utils } from 'src/app/core/resources/utils';
import { FunctionalitiesModuleRole } from 'src/app/core/interfaces/functionalitiesModuleRole';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-functionalities-list',
  templateUrl: './functionalities-list.component.html',
  styleUrls: ['./functionalities-list.component.scss']
})
export class FunctionalitiesListComponent implements OnInit {
  @Input() moduleId: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    id: new FormControl('', Validators.required),
  });

  listFunctionalities: FunctionalitiesModuleRole[];
  detailFunctionality: FunctionalitiesModuleRole;
  private subscriptionFunctionalities: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    public global: Global,
    private fuctionalitiesService: FunctionalitiesService,
    public utils: Utils,

  ) { }

  ngOnInit() {
    this.subscriptionFunctionalities = this.moduleId.get('id').valueChanges.subscribe(id => {
      id && this.getFunctionalitiesByIdModule();
    })
  }

  getFunctionalitiesByIdModule() {
    this.spinner.show();
    this.fuctionalitiesService.getFunctionalitiesByIdModule(this.moduleId.get('id').value).toPromise()
      .then((success: FunctionalitiesModuleRole[]) => {
        this.listFunctionalities = success
      })

      .catch((error) => {
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  ngOnDestroy() {
    if (this.subscriptionFunctionalities) this.subscriptionFunctionalities.unsubscribe();
  }


}


