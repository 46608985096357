<ul *ngIf="formArrayGroup" class="list__additional__services">
    <li *ngFor="let groupCtrl of formArrayGroup.controls; let i = index">
        <app-item-additional-service [cargo]="cargo" [additionalServiceGroupCtrl]="groupCtrl" [index]="i"
            [listAdditionalService]="listAdditionalService"
            (removeAdditionalCostItem)="removeAdditionalCostItem.emit($event)"
            (editAdditionalCostItem)="editAdditionalCostItem.emit($event)" [cargoId]="cargoId" [companyId]="companyId"
            [approvalState]="approvalState">
        </app-item-additional-service>
        <br>
    </li>
</ul>