import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { CargoService } from 'src/app/core/services/cargo.service';

@Component({
  selector: 'app-global-tracking',
  templateUrl: './global-tracking.component.html',
  styleUrls: ['./global-tracking.component.scss']
})
export class GlobalTrackingComponent {

  public cargos: Cargo[];
  public cargoSelected: Cargo = null;
  private filters: string = '';

  constructor(
    public utils: Utils,
    private cargoService: CargoService,
    private spinner: NgxSpinnerService,
  ) { }

  getInRouteCargos() {
    this.cargoSelected = null;
    this.spinner.show();
    if (this.filters.charAt(0) === '&') this.filters = this.filters.substring(1);
    this.cargoService.cargoListByFilters(this.filters).subscribe(
      (response: Cargo[]) => {
        this.spinner.hide();
        if (response) {
          this.cargos = this.sortCargos(response);
          if (this.cargos.length === 1) this.cargoSelected = this.cargos[0];
        } else {
          this.cargos = [];
        }
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
        this.cargos = [];
      }
    );
  }

  sortCargos(cargos: Cargo[]) {
    return cargos.sort((a, b) => {
      const hasLicensePlateA = a.hasOwnProperty('licensePlate');
      const hasLicensePlateB = b.hasOwnProperty('licensePlate');

      if (hasLicensePlateA && hasLicensePlateB) {
        const nameA = a.licensePlate.toLowerCase();
        const nameB = b.licensePlate.toLowerCase();
        return nameA.localeCompare(nameB);
      }
      else if (hasLicensePlateA) return -1;
      else if (hasLicensePlateB) return 1;
      else return 0;
    });
  }

  public onSelectCargo(cargo: Cargo): void {
    this.cargoSelected = cargo;
  }

  refreshList($event: string) {
    this.filters = $event;
    this.getInRouteCargos();
  }
}
