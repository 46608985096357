export class Truora {

    private checkId: string;
    private reason: string;
    private riskProfile: string;
    private scoreTruora: number;

    public getCheckId(): string {
        return this.checkId;
    }

    public setCheckId(checkId: string): void {
        this.checkId = checkId;
    }

    public getReason(): string {
        return this.reason;
    }

    public setReason(reason: string): void {
        this.reason = reason;
    }

    public getRiskProfile(): string {
        return this.riskProfile;
    }

    public setRiskProfile(riskProfile: string): void {
        this.riskProfile = riskProfile;
    }

    public getScoreTruora(): number {
        return this.scoreTruora;
    }

    public setScoreTruora(scoreTruora: number): void {
        this.scoreTruora = scoreTruora;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
