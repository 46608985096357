<article class="page">
    <div class="col-100">
        <h1 class="page_title">Pronto Pago</h1>
    </div>
    <div class="col-100">
        <p align="center" style="margin-bottom: 0in" class="text-strong text-center">
            <b>CONTRATO DE FACTORING COMERCIAL </b>
        </p>

        <p align="justify" style="margin-bottom: 0in"><br />
        </p>

        <p align="justify" style="margin-bottom: 0in">
            <u><b class="text-bold">CONTRATANTES</b></u>:
            Por una parte, INNOVBO S.A.S., con Nit. 901.343.057-1, que en lo sucesivo se denominará EL FACTOR, y
            EL CLIENTE tal como se registró en la app INNOVBO CONDUCTOR, las
            partes manifiestan que es su deseo celebrar el presente <b class="text-bold">contrato de FACTORING</b>, el
            cual se regirá por las siguientes cláusulas:
        </p>

        <p align="justify" style="margin-bottom: 0in"><br />
        </p>

        <p align="justify" style="margin-bottom: 0in">
            <b class="text-bold">PRIMERA.DEFINICIONES:</b>
        </p>

        <p align="justify" style="margin-bottom: 0in"><br />
        </p>

        <!-- contiene los elementos de las definiciones no tiene sublistas -->
        <ol>
            <li>
                <b class="text-bold">FACTORING</b>.
                Operación contractual mediante la cual <u>el cliente</u>, decide
                ceder sus créditos a otra persona ya sea natural o jurídica
                denominada&nbsp;<u>Factor.</u>
            </li>
            <li>
                <b class="text-bold">EL FACTOR: </b>
                la empresa INNOVBO S.A.S. la cual está constituida y
                debidamente registrada en el registro mercantil colombiano.
            </li>
            <li>
                <b class="text-bold">EL CLIENTE</b>:
                se entenderá la persona jurídica o natural, como el
                conductor, propietario, tenedor y/o poseedor del vehículo de carga
                interesado en negociar un título ejecutivo, título valor, o
                documento de transporte.
            </li>
            <li>
                <b class="text-bold">COMISIÓN O VALOR DE FACTORAJE: </b>
                Por la operación de factoring se
                realizará el cobro de una comisión por las facturas, documentos de
                transporte o títulos de valores efectivamente negociados.
            </li>
            <li>
                <b class="text-bold">USO DE LA PLATAFORMA TECNOLÓGICA: </b>
                Por el uso de la plataforma
                tecnológica, el usuario de la misma o EL CLIENTE recibirá
                beneficios o servicios diversos como: alianzas con estaciones de
                combustible, entidades crediticias o financiera o beneficios con
                aliados estratégicos, operaciones de factoring entre otros, por el
                uso de la plataforma se realizará el cobro de una comisión, la
                cual aplicará sólo para los productos o servicios efectivamente
                negociados.
            </li>
            <li>
                <b class="text-bold">DOCUMENTO DE TRANSPORTE</b>:
                se entenderá como tal y sin limitarse a: el manifiesto de carga, remesa terrestre de carga, la planilla
                de viaje urbano, la carta de porte internacional por carretera (CPIC), manifiesto de carga internacional
                (MCI), declaración de tránsito aduanero nacional o internacional (DUTA o DTAI), documento de transporte
                multimodal (DTM) <b class="text-bold">o</b> el documento mediante el cual se encargue el transporte de
                las mercancías a nivel nacional o internacional.
            </li>
            <li>
                <b class="text-bold">CUMPLIDOS / PRUEBAS DE ENTREGA / POD: </b>
                Remesa de transporte y demás
                documentos del cliente (remisiones, facturas, continuaciones de
                viaje, BL´s, albaranes, etc.,) debidamente firmadas y selladas por
                el destinatario de la mercancía.
            </li>
            <li>
                <b class="text-bold">VEHÍCULO: </b>
                el bien propulsado con o sin motor, que se destina al transporte de
                bienes o mercancías, entendiéndose para los efectos del presente
                documento, dentro del significado de la palabra “vehículo” y
                sin limitarse a: Carry, Kangoo, Minivan, Camioneta, Furgón, moto,
                bicicleta, camión, vehículo botellero, tipo niñera, cama baja,
                tracto camión entre otros, que se destinarán al transporte de toda
                clase de bienes, y cumplirá con la normatividad vigente para
                realizar operaciones de transporte bajo la modalidad de encargo a
                tercero.
            </li>
        </ol>

        <p align="justify" style="margin-bottom: 0in"><br />
        </p>

        <p align="justify" style="margin-bottom: 0in">
            <b class="text-bold">SEGUNDA.DECLARACIONES:</b>
        </p>
        <p align="justify" style="margin-bottom: 0in"><br />
        </p>
        <!-- contiene el resto de elementos los cuales si contienen internamente sublistas -->
        <ol>
            <li> <b class="text-bold">DECLARA EL CLIENTE: </b>
                <ol>
                    <li>Que se encuentra plenamente facultado para suscribir el presente contrato de <u>factoring</u>.
                    </li>
                    <li>Que cuenta con el conocimiento para desarrollar el objeto del presente contrato.</li>
                    <li>Que su intención es negociar los documentos de transporte y obtener su pago de manera
                        anticipada. </li>
                    <li>Entiende que debe pagar una comisión por la negociación de los títulos ejecutivos o documentos
                        de transporte.</li>
                </ol>
            </li>
            <li> <b class="text-bold">DECLARA EL FACTOR: </b>
                <ol>
                    <li>Que tiene la capacidad jurídica para obligarse con el objeto del presente contrato y que conoce
                        en su integridad la materia de este contrato, y comprara los documentos de transporte que elija
                        o considere le representan el menor riesgo de negociación o pago por parte del deudor.
                    </li>
                    <li>Que no tiene interés en celebrar un contrato de financiamiento, ni captar dineros de forma
                        masiva del público, que su único interés es adquirir documentos negociables.
                    </li>
                    <li> Que realizará el pago de los documentos de transporte o títulos ejecutivos o títulos valores
                        que efectivamente negocie con <b class="text-bold">EL CLIENTE</b>.</li>
                    <li>EL FACTOR podrá rechazar aquellas operaciones que a su juicio no le merezcan suficiente garantía
                        o no reúnan condiciones adecuadas para su cobro o recaudo.</li>
                </ol>
            </li>
            <li> <b class="text-bold">DECLARAN EL CLIENTE y EL FACTOR:</b>
                <ol>
                    <li>Que el presente documento deja sin efecto, vigencia, anual, reemplaza y/o cancela cualquier
                        contrato anterior y/o cualquier oferta anterior a la firma del presente contrato entre LAS
                        PARTES. Estando ambas partes a paz y salvo por todo concepto al momento de la firma de este
                        contrato.</li>
                    <li>El presente contrato se celebra intuito persona, en atención a la calidad de cada una de las
                        partes que suscriben el presente contrato.</li>
                    <li>Para efectos del presente contrato tanto EL FACTOR como el CLIENTE, conjuntamente se les
                        conocerá como LAS PARTES.</li>
                    <li>Que el presente contrato es independiente de cualquier otra relación comercial que pueda existir
                        entre las PARTES, por lo anterior el presente contrato se regirá por las normas civiles y
                        comerciales aplicables a esta clase de contratos.</li>
                    <li>han convenido en celebrar el presente contrato de factoring que se regirá preferencialmente por
                        las siguientes cláusulas:</li>
                </ol>
            </li>

            <p align="justify" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <b class="text-bold">TERCERA. OBJETO DEL CONTRATO: </b>
                Bajo los términos y condiciones de este contrato, <b class="text-bold">EL CLIENTE</b> se
                obliga a presentar a el <b class="text-bold">FACTOR</b>, las facturas, títulos
                ejecutivos, o documentos de transporte que se encuentren pendientes
                de pago por la prestación de servicios de <u><b class="text-bold">transporte de
                        mercancías,</b></u> EL FACTOR podrá aceptar los títulos ejecutivos
                que considere puede negociar o comprar al CLIENTE.
            </p>

            <p align="justify" style="margin-bottom: 0.17in">
                <li><b class="text-bold">CUARTA.FORMA DE PAGO</b>:
                    los documentos de transporte que presten mérito
                    ejecutivo o las facturas negociadas entre el FACTOR y EL CLIENTE,
                    serán pagadas dentro de las veinticuatro (24 horas) hábiles siguientes a su aceptación por parte del
                    FACTOR a la cuenta bancaria que previamente le haya indicado EL CLIENTE.</li>
            </p>

            <p align="justify" style="margin-bottom: 0.17in">
                <b class="text-bold">PARÁGRAFO SEGUNDO. Comisión o Factoraje:</b>
                A los documentos de transporte, facturas, o títulos ejecutivos se aplicará un descuento por concepto de
                la compra del documento que preste mérito ejecutivo del cuatro por ciento (4%) y se descontarán los
                impuestos que ordena la ley tributaria.
            </p>

            <li><b class="text-bold">QUINTA. OBLIGACIONES DEL EL FACTOR:</b>
                Para el debido cumplimiento del objeto materia de este contrato, EL FACTOR se obliga a:
                <ol>
                    <li>Estudiar todas las operaciones y documentos de transporte, facturas o documentos que presten
                        mérito ejecutivo que le sean presentados por el CLIENTE.
                    </li>
                    <li>Informar al cliente la aprobación o no de las operaciones de factoring.</li>
                    <li>A pagar el precio acordado por cada documento de transporte o factura negociada con el CLIENTE.
                    </li>
                </ol>
            </li>
            <li><b class="text-bold">SEXTA. OBLIGACIONES DEL CLIENTE:</b>
                Para el debido cumplimiento del objeto, materia de este contrato, el CLIENTE se obliga a:
                <ol>
                    <li>Someter a consideración de EL FACTOR la totalidad de las operaciones de venta de servicios de
                        transporte de mercancías, títulos valores o documentos que presten mérito ejecutivo que a bien
                        tenga y que para tal efecto se expidan o emitan a favor del CLIENTE por los compradores de los
                        bienes o servicios que comercializa el CLIENTE.
                    </li>
                    <li>Entregar al FACTOR toda la documentación que éste le solicite para la aprobación de las
                        operaciones de factoring, como y sin limitarse a RUT, cédula, certificación bancaria, cámara de
                        comercio entre otros y según aplique en cada caso.
                    </li>
                    <li>Transferir al FACTOR todas las operaciones aprobadas por este último.</li>
                    <li>El CLIENTE informará a los compradores de sus bienes o servicios, la cesión de crédito a favor
                        de EL FACTOR, designándolo como beneficiario de todos los derechos y acciones emanados del
                        mismo. Por tanto, estará radicado en cabeza del FACTOR, con exclusividad, el cobro de las
                        obligaciones contraídas por los compradores de los bienes o servicios que preste el CLIENTE.
                    </li>
                    <li>Endosar sus facturas o títulos ejecutivos (cambiarias de compraventa o documentos de transporte
                        o documentos que presten mérito ejecutivo), debiendo indicar que las cuentas son pagaderas
                        exclusivamente al FACTOR. Cualquier título valor o cualquier otro documento representativo de
                        las cuentas, créditos o documentos de transporte serán remitidos al FACTOR, donde se relacionen
                        o identifiquen cada uno de ellos, y entregará sin excepción alguna, las facturas comerciales o
                        el documento de cumplido o comprobante de entrega de las mercancías, según corresponda.</li>
                    <li>Entregar al FACTOR los documentos de transporte y la documentación mínima del cumplido o
                        evidencias de entrega de la carga de forma digital (y en físico para las operaciones de los
                        generadores de carga que así lo exijan), y la documentación adicional, tales como y sin
                        limitarse a: Facturas, remisiones, albaranes, notas de inspección o tirillas de entrega de
                        contenedores vacíos, devoluciones en puerto de contenedores o unidades de refrigeración, tiquete
                        de báscula, documento anexo de averías o estado de entrega de la carga. Este requisito es
                        indispensable para proceder con el estudio de documentación y finalizar la operación de
                        factoring / pronto pago.</li>
                </ol>
            </li>

            <p align="justify" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <b class="text-bold">SÉPTIMA. INDEMNIDAD.</b>
                EL CLIENTE mantendrá indemne al EL FACTOR de todo reclamo, demanda o acción legal que pueda causar o que
                surja del incumplimiento de las obligaciones derivadas del presente contrato a cargo del EL CLIENTE.
            </p>

            <p align="justify" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <b class="text-bold">OCTAVA. PENAL PECUNIARIA.</b>
                - La pena pecuniaria se hará efectiva en caso de incumplimiento total o parcial por parte del CLIENTE y
                equivaldrá al veinte por ciento (20%) del valor del documento de transporte, título valor o documento
                que preste mérito ejecutivo que haya sido Incumplido o no prestado el servicio por el CLIENTE. No
                obstante, la pena pecuniaria, EL FACTOR podrá demandar la indemnización por los daños o perjuicios que
                le haya causado por el incumplimiento del CLIENTE.
            </p>

            <p align="justify" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <b class="text-bold">NOVENA. MÉRITO EJECUTIVO.</b>
                - Las partes acuerdan que el presente contrato prestará mérito ejecutivo para el cobro de las
                obligaciones emanadas de cumplimiento o incumplimiento de las aquí contenidas, y de las que por ley les
                correspondan, sin necesidad de protesto; y que para tal fin el presente documento se asemeja a una letra
                de cambio, y se regirá por las normas sobre la materia. Así mismo, el presente contrato se someterá a lo
                estipulado en el TÍTULO IV del Código de Comercio y demás normas concordantes y complementarias. Para
                efectos de certificar las sumas adeudadas por cualquiera de las partes bastará la constancia emitida por
                el Contador de la empresa correspondiente o el de su revisor fiscal.
            </p>

            <p align="justify" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <b class="text-bold">DÉCIMA: CENTRALES DE RIESGO.</b>
                El CLIENTE autoriza al EL FACTOR o a quién éste designe, para incorporar, reportar, procesar, consultar
                y obtener en cualquier momento información en las centrales de riesgo sobre el comportamiento crediticio
                del CLIENTE, así como el cumplimiento de sus obligaciones adquiridas mediante el presente contrato.
            </p>

            <p align="justify" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <b class="text-bold">DÉCIMA PRIMERA. CLÁUSULA COMPROMISORIA.</b>
                - Las partes acuerdan someter, toda controversia o diferencia surgida de este contrato, a la resolución
                de un Tribunal de Arbitramento, que se sujetará al reglamento del Centro de Arbitraje y conciliación de
                la Cámara de Comercio de Bogotá, para Pymes o pequeñas empresas, de acuerdo con las siguientes reglas:
                i) El tribunal estará integrado por un (1) árbitro si la cuantía es inferior o igual a treinta mil
                dólares de Estados Unidos de Norteamérica (USD 30.000) o su equivalente en pesos colombianos y por tres
                (3) árbitros si supera esta cuantía, 2) los árbitros serán designados por sorteo de las listas del
                Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá, a solicitud de cualquiera de las
                partes. ii) El Tribunal decidirá en derecho. iii) la parte que resulte vencida asumirá los costos del
                trámite arbitral.
            </p>

            <p align="justify" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <b class="text-bold">DÉCIMA SEGUNDA. - AUTORIZACIÓN PARA CAPTACIÓN Y TRATAMIENTO DE DATOS
                    PERSONALES.</b>
                EL CLIENTE autoriza a EL FACTOR, a la captación y tratamiento de los datos personales que le suministre
                durante la vigencia del presente contrato, así como los de su personal contratistas o subcontratistas,
                los cuales serán tratados en los términos de la ley 1581 de 2012 y/o de la norma que la adicione o
                modifique, los datos serán tratados para fines contractuales, legales y para comerciales, autoriza a
                recibir información de la compañía o de sus proveedores o clientes y ser reportado a las centrales de
                riesgo en caso de incumplimiento del presente contrato, autoriza a recibir correspondencia y
                notificaciones a su correo electrónico y/o a las demás direcciones o datos de contacto suministrados en
                el presente contrato, con la firma del presente documento se acepta el contenido de los anexos 1 y 2
                juntos y que hacen parte integral del presente contrato.
            </p>

            <p align="justify" style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <b class="text-bold">DÉCIMA TERCERA. - ACEPTACIÓN DEL CONTRATO.</b>
                - Las partes convienen en que por el solo hecho que EL CLIENTE descargue la APP o solicite la prestación
                del servicio de factoring, se entenderá que se han aceptado las condiciones contractuales establecidas
                en este documento.
            </p>
        </ol>
    </div>
</article>
