<form class="modal-body wrapper-modal d-flex flex-wrap w-100 position-relative" [formGroup]="form"
    (ngSubmit)="onSubmit()">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="col-12">
        <mat-form-field appearance="standard">
            <mat-label>Nombre del centro<span class="asterisk">*</span></mat-label>
            <input matInput type="text" formControlName="name">
            <mat-error *ngIf="form.get('name').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-12">
        <mat-form-field appearance="standard">
            <mat-label>Código<span class="asterisk">*</span></mat-label>
            <input matInput type="text" formControlName="code">
            <mat-error *ngIf="form.get('code').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="w-100 button-container">
        <button type="submit" mat-raised-button class="bg-color-primary">Crear</button>
    </div>
</form>