import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../environments/environment';
import { DiscountsAndBonuses } from 'src/app/core/interfaces/discountsAndBonuses';
import { Utils } from 'src/app/core/resources/utils';
import { CreateDiscountsAndBonuses } from 'src/app/core/interfaces/createDiscountAndBonuses';

@Injectable({
    providedIn: 'root'
})
export class DiscountsAndBonusesCargoService {

    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
        public utils: Utils
    ) { }

    shippingCostModify(data: DiscountsAndBonuses) {
        const dataModify: DiscountsAndBonuses = this.utils.clone(data);
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.shippingCostModify,
            dataModify
        );
    }

    createModifications(data: CreateDiscountsAndBonuses) {
        const dataModify: CreateDiscountsAndBonuses = this.utils.clone(data);
        delete dataModify.id;
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.createModify,
            dataModify
        );
    }

    getListTypeModification(type: number) {
        return this.http.get(
            environment.urlServerTeclogi + this.endpointResources.createModify + '?type=' + type
        );
    }

}
