import { Component, OnInit, EventEmitter, DoCheck, IterableDiffers, KeyValueDiffers, SimpleChanges, HostListener, ViewChild } from '@angular/core';
import { CollectionPoint } from 'src/app/core/interfaces/collectionPoint';
import { User } from 'src/app/core/interfaces/user';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Role } from 'src/app/core/interfaces/role';
import { Global } from 'src/app/core/resources/global';
import { Company } from 'src/app/core/interfaces/company';
import { FreightListService } from 'src/app/modules/cargo/cargo-list/cargo-list.service';
import { ListTicketService } from '../list-ticket.service';
import { Ticket, state, team } from 'src/app/core/interfaces/ticket';
import { Permission } from 'src/app/core/resources/permission';
import { TicketTypes } from 'src/app/core/interfaces/ticketTypes';


@Component({
  selector: 'app-list-ticket',
  templateUrl: './list-ticket.component.html',
  styleUrls: ['./list-ticket.component.scss']
})
export class ListTicketComponent implements OnInit {

  pageKey: number = 1;
  pageSize: number = 10;
  throttle = 300;
  scrollDistance = 1;
  enablePagingScroll: boolean = true;
  listTickets: Ticket[];
  case: string;
  state: string;
  type: string;
  types = [];
  roleFilter: string = '';
  permission = Permission;

  states: state[] = [
    { value: 'Abierto', name: 'Abierto', },
    { value: 'En proceso', name: 'En proceso', },
    { value: 'En pausa', name: 'En pausa' },
    { value: 'Cerrado', name: 'Cerrado', }
  ];


  constructor(
    public adminUsersService: AdminUsersService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    public utils: Utils,
    public global: Global,
    public ticketTypes: TicketTypes,
    private freightListService: FreightListService,
    private listTicketService: ListTicketService,
  ) {
    this.types = ticketTypes.types;
  }

  ngOnInit() {
    this.applyFilter();
  }

  getTickets() {
    this.spinner.show();
    this.listTicketService.getAllTickets(this.case, this.state, this.type, this.pageKey, this.pageSize).toPromise()
      .then((success: Ticket[]) => {
        if (!this.utils.isDefined(success) || success.length < 1) {
          this.enablePagingScroll = false;
        } else {
          if (this.listTickets && this.listTickets.length) {
            this.listTickets = this.listTickets.concat(success);
          } else {
            this.listTickets = success;
          }

          if (success.length < this.pageSize) {
            this.enablePagingScroll = false;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  applyFilter() {
    this.resetValuesPagingScroll();
    this.getTickets();
  }

  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getTickets();
    }
  }

  resetValuesPagingScroll() {
    this.enablePagingScroll = true;
    this.listTickets = [];
    this.pageKey = 1;
  }

  goToCreate() {
    this.router.navigate(['help-desk/create'])

  }
  cleanFilter() {
    this.case = '';
    this.state = '';
    this.type = '';
    this.resetValuesPagingScroll();
    this.getTickets();
  }

  onChangeRolFilter() {
    this.resetValuesPagingScroll();
    this.applyFilter();
  }

  handleKeyDown(event: any) {
    if (event.keyCode == 13) {
      this.applyFilter();
    }
  }
}
