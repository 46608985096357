import { Component, ElementRef, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';;
import { SelectCompanyService } from './license-plate.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-license-plate',
  templateUrl: './license-plate.component.html',
  styleUrls: ['./license-plate.component.scss'],
  providers: [AuthService, SelectCompanyService]
})
export class LicensePlateComponent implements OnInit {

  @Input() title: string;
  @Input() inputFormControl: FormControl;
  @Input() amount = 7;
  @ViewChildren('input') inputs: QueryList<ElementRef>;
  inputControlSub: Subscription;
  amountInputs;
  disabledInput = false;

  constructor(
    public utils: Utils,
  ) { }

  ngOnInit() {
    this.amountInputs = Array(this.amount).fill(0).map((x, i) => i);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.inputFormControl && changes.inputFormControl.currentValue) {
      if (this.utils.isDefined(this.inputFormControl.enabled) && this.inputFormControl.enabled === false) {
        this.disabledInput = true;
        this.inputControlSub = this.inputFormControl.valueChanges.subscribe(valueChange => {
          if (valueChange.length) {
            this.setValueLicensePlate(valueChange);
          }
        });
      }
    }
  }

  ngAfterViewInit() {
    if (this.inputFormControl.value) {
      this.setValueLicensePlate(this.inputFormControl.value);
    }
  }

  setValueLicensePlate(value) {
    if (this.inputs) {
      this.inputs.toArray().map((input, i) => {
        input.nativeElement.value = value.split('')[i] ? value.split('')[i] : '';
      });
    }
  }

  onKeyUp($event: any, index: number) {
    try {
      this.inputs.toArray()[index].nativeElement.value = $event.srcElement.value.substr(0, 1).toUpperCase();
      if ($event.keyCode === 8 || $event.keyCode === 37) {
        // antes
        this.focus = index - 1;
      } else if (
        ($event.keyCode >= 48 && $event.keyCode <= 57) ||
        ($event.keyCode >= 65 && $event.keyCode <= 90) ||
        ($event.keyCode >= 96 && $event.keyCode <= 105) ||
        $event.keyCode === 39
      ) {
        // despues
        this.focus = index + 1;
      }
      this.valueLicensePlate = this.valueLicensePlate;
    } catch (error) {

    }
  }

  set focus(index: number) {
    if (this.inputs.toArray()[index]) {
      this.inputs.toArray()[index].nativeElement.focus();
    }
  }

  onFocus(index: number) {
    if (this.inputs.toArray()[index]) {
      this.inputs.toArray()[index].nativeElement.select();
    }
  }

  ngOnDestroy() {
    if (this.inputControlSub) this.inputControlSub.unsubscribe();
  }

  get valueLicensePlate() {
    let value = '';
    this.inputs.toArray().map((i) => {
      value += i.nativeElement.value;
    });
    return value.trim();
  }

  set valueLicensePlate(value: string) {
    if (this.inputFormControl) {
      this.inputFormControl.setValue(value);
    }
  }

}
