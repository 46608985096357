<div class="detail-container">
  <div class="receivable-filters">
    <app-receivable-filters (emitFilters)="emitFilters.emit($event)" [storageInputs]="storageInputs"
      [emptyStorage]="emptyStorage" [cleanFields]="cleanFields"></app-receivable-filters>
  </div>
  <mat-card *ngFor="let receivable of receivablesSlice" class="mb-3 mx-1">
    <mat-card-content>
      <div class="card-container" *ngIf="receivable">
        <div class="header-info-container row">
          <div class="col-9 col-md-11">
            <div class="row">
              <div *ngIf="receivable.creationDate" class="col-sm-6 col-md-3 col-lg-2">
                <span class="title-purple" title="{{ receivable.creationDate | dateFormat:'only-date'}}">
                  {{ receivable.creationDate | dateFormat:'only-date'}}
                </span>
              </div>
              <div *ngIf="receivable.creationDate" class="col-12 col-sm-6 col-md-3 col-lg-2">
                <span class="title-purple" title="{{ receivable.creationDate | dateFormat:'time' }}">
                  {{ receivable.creationDate | dateFormat:'time'}}
                </span>
              </div>
              <div *ngIf="receivable.id" class="col-12 col-md-6 col-lg-3">
                <span class="title-purple" title="{{ receivable.id }}">
                  Cartera N°: {{ receivable.consecutive }}
                </span>
              </div>
              <div *ngIf="receivable.companyName" class="col-12 col-sm-6 col-md-5 col-lg-3">
                <span class="title-purple text-break" title="{{ receivable.companyName }}">
                  Cliente: {{ receivable.companyName }}
                </span>
              </div>
              <div *ngIf="receivable.cargoConsecutive" class="col-12 col-sm-6 col-md-3 col-lg-2">
                <span class="cargo__consecutive"
                  title="ID de la carga de la que se generó la cartera: {{ receivable.cargoConsecutive }}">
                  ID de la carga: {{ receivable.cargoConsecutive }}
                </span>
              </div>
              <div *ngIf="receivable.amountInitial" class="col-12 col-sm-6 col-md-4 col-lg-3">
                <span class="title-purple label">
                  Valor de la cartera:
                </span>
                <span class="text-bold label-field" title="{{receivable.amountInitial | currency:'COP':'code'}}">
                  {{receivable.amountInitial | currency:'COP':'code'}}
                </span>
              </div>
              <div *ngIf="receivable.whoReports && receivable.whoReports.name"
                class="col-12 col-sm-6 col-md-6 col-lg-4">
                <span class="title-purple" title="{{ receivable.whoReports.name }}">
                  Responsable:
                </span>
                <span class="text-bold label-field">
                  {{ receivable.whoReports.name }}
                </span>
              </div>
              <div *ngIf="receivable.licensePlate" class="col-12 col-sm-6 col-md-2 col-lg-2">
                <span class="title-purple label">
                  Placa:
                </span>
                <span class="license-plate text-bold label-field" [ngClass]="{'underline': showVehicleDetail}"
                  title="{{receivable.licensePlate }}" (click)="getDetailVehicle(receivable.licensePlate, 'detail')">
                  {{receivable.licensePlate }}
                </span>
              </div>
              <div permission-display [module]="permission.payments.module"
                [functionality]="permission.payments.viewPaymentsReceivables"
                *ngIf="receivable.modifications && receivable.modifications.length > 0"
                class="col-12 col-sm-6 col-md-2 col-lg-2">
                <span class="title-purple label underline cursor-pointer"
                  (click)="getDetailVehicle(receivable.licensePlate, 'info', receivable)">
                  Ver Abonos
                </span>
              </div>
              <div *ngIf="receivable.reason" class="col-12 col-sm-6 col-md-4 col-lg-3">
                <span class="text-bold label-field underline" matTooltip="{{receivable.reason}}"
                  matTooltipPosition="above" matTooltipClass="extended-tooltip">
                  Observaciones
                </span>
              </div>
            </div>
          </div>
          <div class="col-3 col-md-1 d-flex align-items-center justify-content-around">
            <i permission-display [module]="permission.payments.module"
              [functionality]="permission.payments.deleteReceivable" class="fas fa-trash cursor-pointer"
              matTooltip="Eliminar cartera" (click)="removeReceivable(receivable.id, receivable.consecutive)"></i>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-paginator [ngClass]="{'display-none':receivables.length === 0}" [length]="receivables.length"
    [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"
    showFirstLastButtons>
  </mat-paginator>
</div>