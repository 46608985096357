import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/core/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CargoControlCargoTrackingService {

    private basePath = '/uploads';

    constructor(private _http: HttpClient, private _endpointResources: Endpoints) { }

    public rateCargo(idCargo, params) {
        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.urlRateCargo + idCargo + '&role=Creator',
            params
        );
    }

    public detailCargo(idCargo) {
        return this._http.get(
            environment.urlServerTeclogi + this._endpointResources.urlDetailCargo + idCargo
        );
    }

    public getImageByAddress(idCargo, address) {
        return this._http.get(
            environment.urlServerTeclogi + this._endpointResources.urlImageAddressCargo + idCargo + '&address=' + encodeURIComponent(address)
        );
    }

}


