import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CollectionPointListComponent } from './collection-point-list/collection-point-list.component';
import { CollectionPointItemComponent } from './collection-point-item/collection-point-item.component';
import { SharedModule } from '../../core/shared.module';
import { CollectionPointDetailComponent } from 'src/app/modules/collection-point/collection-point-detail/collection-point-detail.component';
import { CollectionPointRoutingModule } from './collection-point-routing.module';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    CollectionPointRoutingModule
  ],
  declarations: [
    CollectionPointListComponent,
    CollectionPointItemComponent,
    CollectionPointDetailComponent
  ],
  entryComponents: [],
  exports: [
    CollectionPointListComponent,
    CollectionPointItemComponent,
    CollectionPointDetailComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class CollectionPointModule {

}
