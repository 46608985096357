import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Role } from 'src/app/core/interfaces/role';
import { UserClientModel } from 'src/app/core/models/userClient.model';
import { AuthService } from 'src/app/core/services/authentication.service';
import { RoleService } from 'src/app/core/services/role.service';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';

@Component({
  selector: 'app-master-testig-change-role',
  templateUrl: './master-testig-change-role.component.html',
  styleUrls: ['./master-testig-change-role.component.scss']
})
export class MasterTestigChangeRoleComponent implements OnInit {
  roles = [];
  company;
  user: UserClientModel;

  form = new FormGroup({
    role: new FormControl(null, [])
  });

  constructor(
    private authService: AuthService,
    private roleService: RoleService,
    private adminUsersService: AdminUsersService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.company = this.authService.getCompany();
    this.user = this.authService.getUserSession();

    this.adminUsersService.getRolesCompany(this.company.companyId).subscribe(
      (roles: Role[]) => this.roles = roles,
    );
  }

  onSubmit(_: Event) {
    const roleId = this.form.get('role').value;
    if (!roleId)
      return;

    this.spinner.show();
    this.roleService.getRolById(roleId).subscribe({
      next: (role) => {
        this.adminUsersService
          .activateUserClient(this.user.information.document, role.id)
          .subscribe(
            console.log,
            console.trace
          );
      },
      error: () => this.spinner.hide(),
      complete: () => this.spinner.hide()
    });
  }
}
