import { MatDialog } from "@angular/material";
import { Utils } from "../resources/utils";
import { AuthService } from "../services/authentication.service";
import { ShippingCost } from "../enums/shipping-cost.enum";
import { AmountsCargoEnum } from "../enums/amountsCargo.enum";
import { environment } from "src/environments/environment";
import { CompanyNamePipe } from "../pipe/companyName.pipe";

export class CompanyManager {
  private stateComanyOnlyDrive;



  constructor(
    public utils: Utils,
    public matDialog: MatDialog,
    private authService: AuthService,
  ) {

  }

  public getOnlyDriverByCompanyNit(nit) {

    let companies = this.authService.getCompanies();
    this.stateComanyOnlyDrive = false;
    if (companies && companies.length) {
      const company = companies.filter((objet) => {
        return objet.companyId === nit;
      });
      if (company.length) {
        this.stateComanyOnlyDrive = !company[0].onlyDriverCanOperate;
        return this.stateComanyOnlyDrive;
      }
    }

    return true;
  }


  getUtilityByCompanyId(idCompany): number {
    let companies = this.authService.getCompanies();
    if (companies && companies.length) {
      const company = companies.filter((objet) => {
        return objet.companyId === idCompany;
      });
      if (company[0] && this.utils.isDefined(company[0].utility) && company[0].utility >= 0) return company[0].utility
    }
    return ShippingCost.MINIMUN_UTILITY
  }

  getutilityAdditionalServicesByCompanyId(idCompany) {
    let companies = this.authService.getCompanies();
    if (companies && companies.length) {
      const company = companies.filter((objet) => {
        return objet.companyId === idCompany;
      });
      if (company[0] && this.utils.isDefined(company[0].utilityAdditionalService) && company[0].utilityAdditionalService >= 0) {
        return company[0].utilityAdditionalService
      } else {
        if (this.authService.getCompany() && this.authService.getCompany().companyId && this.authService.getCompany().companyId === environment.rootNit) {
          return ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST_TECLOGI;
        }
        return ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST;
      }
    }
  }

  getAdvancePercentageByCompanyId(idCompany): number {
    let companies = this.authService.getCompanies();
    if (companies && companies.length) {
      const company = companies.filter((company) => {
        return company.companyId === idCompany;
      });
      if (company[0] && this.utils.isDefined(company[0].advancePercentage) && company[0].advancePercentage >= 0) return company[0].advancePercentage;
    }
    if (this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit))
      return AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI;
    if (this.authService.getCompany() && this.authService.getCompany().advancePercentage) return this.authService.getCompany().advancePercentage;
    return AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES;
  }

  getNameByCompanyId(idCompany): string {
    const companies = this.authService.getCompanies();
    if (companies && companies.length) {
      const companyActive = companies.find((company) => company.companyId === idCompany);
      if (companyActive && companyActive.name) return companyActive.name;
    }
    return idCompany;
  }


}
