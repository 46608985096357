<aside id="mainFormTermsAndConditions" class="min-h-100-margin">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 title-component">
        <i class="fas fa-file-alt"></i>
        Creación de {{title}}
      </div>
    </div>
    <form class="row" [formGroup]="termsAndConditionsForm">
      <div class="col-md-12">
        <div class="row align-items-center">
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Versión<span class="asterisk">*</span></mat-label>
              <input matInput type="text" placeholder="Ej: 1.1.3" formControlName="version" name="version">
              <mat-error *ngIf="termsAndConditionsForm.get('version').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
              </mat-error>
              <mat-error
                *ngIf="termsAndConditionsForm.get('version').errors && !termsAndConditionsForm.get('version').errors?.required ">
                {{ utils.giveMessageError('INVALID_VERSION') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form">
                <label class="label-field">Publicar</label>
                <mat-button-toggle-group class="button-group-fixed-load" #group="matButtonToggleGroup"
                  [value]="'false'">
                  <mat-button-toggle value="true" (change)="onChangeState($event)">
                    Si
                  </mat-button-toggle>
                  <mat-button-toggle value="false" (change)="onChangeState($event)">
                    No
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </mat-card-content>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>Texto {{title}}<span class="asterisk">*</span></mat-label>
              <textarea matInput class="w-100" formControlName="html" rows="8"></textarea>
              <mat-error *ngIf="termsAndConditionsForm.get('html').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row row-actions">
          <mat-card-actions>
            <button mat-raised-button color="primary" class="button-width-auto" type="button" (click)="onSubmit()">
              Guardar
            </button>
          </mat-card-actions>
        </div>
      </div>
    </form>
  </div>
</aside>