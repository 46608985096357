import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggingComponent } from './logging/logging.component';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { FingerPrintsComponent } from './finger-prints/finger-prints.component';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Monitoring' },
    children: [
      {
        path: 'logging',
        component: LoggingComponent,
        data: { breadcrumb: 'Logging' },
        canActivate: [UserActiveGuard, RoleGuard],
      },
      {
        path: 'fingerprintslogging',
        component: FingerPrintsComponent,
        data: { breadcrumb: 'Solicitudes por usuario' },
        canActivate: [UserActiveGuard, RoleGuard],
      },
      { path: '**', redirectTo: '/monitoring/logging' }
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class MonitoringRoutingModule { }
