import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Global } from 'src/app/core/resources/global';
import { Router } from '@angular/router';
import PlaceResult = google.maps.places.PlaceResult;
import * as _ from 'lodash';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { City } from 'src/app/core/interfaces/city';
import { CargoResources } from '../../resources/cargo';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/authentication.service';
import { MatExpansionPanel } from '@angular/material';
import { ThirdPartyConsignmentComponent } from '../third-party-consignment/third-party-consignment.component';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';

declare var google: any;


@Component({
  selector: 'app-download-location',
  templateUrl: './download-location.component.html',
  styleUrls: ['./download-location.component.scss'],
  providers: [Global]
})

export class DownloadLocationComponent implements OnInit {

  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Output() removeAddressMarker: EventEmitter<object> = new EventEmitter();
  @Output() removeAllDestinationMarkers: EventEmitter<number> = new EventEmitter();
  model: object;
  showLocationsLoad = false;
  activeLocation: object = {
    typeLocation: '',
    indexAddress: 0,
    indexDestination: 0
  };
  panelOpenState = true;
  listCities: City[];
  sessionCityDestination: string = '';
  subscriptions: Subscription = new Subscription();
  @ViewChild('matExpansionPanel', { static: false }) matExpansionPanel: MatExpansionPanel;
  @ViewChildren(ThirdPartyConsignmentComponent) thirdPartyConsignmentList: QueryList<ThirdPartyConsignmentComponent>;

  constructor(
    private router: Router,
    public manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private cargoResources: CargoResources,
    public utils: Utils,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.sessionCityDestination = this.manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.destination[0].addresses[0].name;
  }

  ngOnInit() {
    this.setOptions();
  }

  setOptions() {
    const sub = this.manualCreationCargoService.cityDownloadControls.at(0).valueChanges
      .subscribe(value => {
        try {
          if (this.sessionCityDestination !== this.manualCreationCargoService.cargoForm.value.cargoFeature.uploadDownload.destination[0].addresses[0].name) {
            const basicAddress = this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[0].addresses[0]);
            this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[0].addresses = [
              basicAddress
            ];
            this.refreshFormCargo();
            this.removeAllDestinationMarkers.emit(0);
          }
        } catch (e) { }
        this.manualCreationCargoService.formDestinations.at(0).get('name').setValue(value && value.name ? value.name : '');
        this.manualCreationCargoService.formDestinations.at(0).get('country.name').setValue(value && value.country ? value.country : 'Colombia');
        this.manualCreationCargoService.formDestinations.at(0).get('country.code').setValue(value && value.code ? value.code : 'CO');
        this.manualCreationCargoService.formDestinations.at(0).get('municipalityCode').setValue(value && value.id ? value.id : '');
      })
    this.subscriptions.add(sub);
    this.manualCreationCargoService.formDestinations.controls.forEach((destination, i) => {
      if (destination.get('name') && destination.get('name').value) this.manualCreationCargoService.cityDownloadOptions.at(i).setValue({
        title: 'Ciudad de Destino',
        isInternational: !!this.manualCreationCargoService.getFormDestination(i).get('isInternational').value,
        initialValue: destination.get('name').value
      })
    });
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  addDestinationForm() {
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination
      .push(this.utils.clone(this.cargoResources.cargoMock.cargoFeature.uploadDownload.destination[0]));
    this.manualCreationCargoService.cityDownloadControls.push(new FormControl('', Validators.required));
    this.manualCreationCargoService.cityDownloadOptions.push(new FormControl({
      title: 'Ciudad de Destino',
      isInternational: false
    }));
    this.manualCreationCargoService.cityDownloadValidates.push(new FormControl());
    const index = this.manualCreationCargoService.cityDownloadControls.length - 1;
    const sub = this.manualCreationCargoService.cityDownloadControls.at(index).valueChanges
      .subscribe(value => {
        try {
          this.sessionCityDestination = '';
          const basicAddress = this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[index].addresses[0]);
          this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[index].addresses = [
            basicAddress
          ];
          this.refreshFormCargo();
          this.removeAllDestinationMarkers.emit(index);
        } catch (e) {
        }
        this.manualCreationCargoService.formDestinations.at(index).get('name').setValue(value && value.name ? value.name : '');
        this.manualCreationCargoService.formDestinations.at(index).get('country.name').setValue(value && value.country ? value.country : 'Colombia');
        this.manualCreationCargoService.formDestinations.at(index).get('country.code').setValue(value && value.code ? value.code : 'CO');
        this.manualCreationCargoService.formDestinations.at(index).get('municipalityCode').setValue(value && value.id ? value.id : '');
      });
    this.subscriptions.add(sub);
    this.refreshFormCargo();

  }

  addAddressForm(indexDestination: number) {
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[indexDestination].addresses
      .push(this.utils.clone(this.cargoResources.cargoMock.cargoFeature.uploadDownload.destination[0].addresses[0]));
    this.refreshFormCargo();
  }

  get destinations() {
    return this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination.controls;
  }

  removeAddressField(indexDestination: number, index: number) {
    this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination
      .controls[indexDestination].controls.addresses.removeAt(index);

    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[indexDestination].addresses.splice(index, 1);
    this.removeAddressMarker.emit({ index, indexDestination });
    this.refreshFormCargo();
  }

  removeDestination(indexDestination: number) {
    this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination
      .removeAt(indexDestination);
    this.manualCreationCargoService.cityDownloadControls.removeAt(indexDestination);
    this.manualCreationCargoService.cityDownloadOptions.removeAt(indexDestination);
    this.manualCreationCargoService.cityDownloadValidates.removeAt(indexDestination);
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination.splice(indexDestination, 1);
    this.refreshFormCargo();
    this.matExpansionPanel.expanded = true;
    this.removeAllDestinationMarkers.emit(indexDestination);
  }

  refreshFormCargo() {
    this.emitToParent.emit({
      data: {
        refreshForm: true
      }
    });
  }

  onSelectCityDestination($event: any) {
    try {
      const basicAddress = this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[$event.options.index].addresses[0]);
      this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[$event.options.index].addresses = [];
      this.refreshFormCargo();
      this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[$event.options.index].addresses = [
        basicAddress
      ];
      this.refreshFormCargo();
    } catch (e) {
    }
  }

  onSelectAddress($event: any) {
    this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination.controls
    [$event.options.indexDestination].controls.addresses.controls[$event.options.index].patchValue($event.data);
  }

  onChangeInternational(indexDestination) {
    let initialValue = this.utils.clone(this.cargoResources.cargoMock.cargoFeature.uploadDownload.destination[0])
    delete initialValue.isInternational;
    const basicAddress = initialValue.addresses[0];
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[indexDestination].addresses = [];
    this.refreshFormCargo();
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[indexDestination].addresses = [
      basicAddress
    ];
    this.refreshFormCargo();
    this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.destination.controls[indexDestination].patchValue(initialValue);
    this.manualCreationCargoService.cityDownloadOptions.at(indexDestination).setValue({
      title: 'Ciudad de Destino',
      isInternational: !!this.manualCreationCargoService.getFormDestination(indexDestination).get('isInternational').value
    })
  }

  editOrigin() {
    this.emitToParent.emit({
      data: {
        viewActive: 'upload'
      }
    });
  }

  onSelectThirdParty($event: any) {
    if ($event && $event.data && $event.data.focusInput) {
      this.emitToParent.emit($event);
    } else {
      this.emitToParent.emit($event);
    }
  }

  findErrorOnDestinations(): boolean {
    let haveErrors = false;
    for (let i = 0; i < this.destinations.length; i++) {
      const destination = this.destinations[i] as FormGroup;
      const addressesArray = destination.get('addresses') as FormArray;
      for (let j = 0; j < addressesArray.controls.length; j++) {
        const address = addressesArray.controls[j] as FormGroup;
        const locationDefined: boolean = !!(address.get('location.lat') && address.get('location.lng') && address.get('location.lat').value && address.get('location.lng').value);
        if (!locationDefined) {
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.LAT_LNG_NOT_DEFINED_DESTINATION, j + 1, i + 1), undefined, 'alert');
          haveErrors = true;
          break;
        }
      }
      if (haveErrors) break;
    }
    return haveErrors;
  }

  nextStep() {
    this.manualCreationCargoService.cityDownloadValidates.controls.forEach(validator => {
      validator.setValue('touched');
    })
    if (!this.manualCreationCargoService.isValidDownloadLocation(this.manualCreationCargoService.getCargoForm().get('ministry').value)) {
      if (!this.manualCreationCargoService.formDestinations.value.every(city => !!city.name)) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.FIELDS_UNCOMPLETED, 'ciudad'), undefined, 'alert');
      } else if (!this.manualCreationCargoService.formDestinations.value.every(city => city.addresses.every(add => !!add.address))) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.FIELDS_UNCOMPLETED, 'dirección'), undefined, 'alert');
      } else {
        if (this.findErrorOnDestinations()) return;
        else this.manualCreationCargoService.showMessageMissingFields();
      }
    } else if (!this.frontierDeclarationProtocol()) {
      return;
    } else {
      this.router.navigate([this.manualCreationCargoService.steps.dateLoad.url]);
    }
  }

  frontierDeclarationProtocol(): boolean {
    let foreignOrigin = this.manualCreationCargoService.formUploadDownload.value.origin.country.code !== "CO";
    let someForeignDestinations = this.manualCreationCargoService.formUploadDownload.value.destination.some(destination => destination.country.code !== "CO");
    let everyForeignDestinations = this.manualCreationCargoService.formUploadDownload.value.destination.every(destination => destination.country.code !== "CO");
    let haveMinistry = !!this.manualCreationCargoService.getCargoForm().get('ministry').value;
    let destinations = this.manualCreationCargoService.formUploadDownload.value.destination;

    this.setTripTypeAndReteica(foreignOrigin, someForeignDestinations, everyForeignDestinations, destinations);

    //No requiere manifiesto, o no tiene direcciones extranjeras, o todas sus direcciones son extranjeras
    if ((!foreignOrigin && !someForeignDestinations) || !haveMinistry || (foreignOrigin && everyForeignDestinations)) {
      return true;
    }
    //Origen en Colombia
    if (haveMinistry && !foreignOrigin && destinations[0].country.code !== "CO") {
      this.snackBarService.openSnackBar('El primer destino debe ser la frontera en Colombia con el país de destino', undefined, 'alert');
      return false;
    }
    //Destino en Colombia
    if (haveMinistry && foreignOrigin && !everyForeignDestinations) {
      if (destinations.length == 1 || (destinations[destinations.length - 1].country.code === "CO" && destinations[destinations.length - 2].country.code !== "CO")) {
        this.snackBarService.openSnackBar('La penúltima ciudad de destino debe ser la frontera en Colombia', undefined, 'alert');
        return false;
      }
    }
    if (haveMinistry && !everyForeignDestinations) {
      //Paso por Colombia
      let timePasses = 0;
      let noPasses = 0;
      let index = 0;
      while (index !== destinations.length) {
        if (destinations[index].country.code === "CO") {
          if (noPasses === 0) timePasses += 1;
          index == 0 && !foreignOrigin ? noPasses += 2 : noPasses += 1;
        } else {
          if (noPasses === 1) {
            this.snackBarService.openSnackBar('Deben haber por lo menos dos destinos en el paso por Colombia (fronteras en Colombia)', undefined, 'alert');
            return false;
          } else {
            noPasses = 0;
          }
        }
        index += 1;
      }
      if (timePasses > 1) {
        this.snackBarService.openSnackBar('No es posible cruzar por Colombia más de una vez por carga', undefined, 'alert');
        return false;
      }
    }
    return true;
  }

  setTripTypeAndReteica(foreignOrigin: boolean, someForeignDestinations: boolean, everyForeignDestinations: boolean, destinations) {
    if (foreignOrigin || someForeignDestinations)
      this.manualCreationCargoService.getCargoForm().get('cargoModel.tripType.name').setValue('Internacional');
    else if (this.manualCreationCargoService.getCargoForm().get('cargoModel.tripType.name').value === 'Internacional')
      this.manualCreationCargoService.getCargoForm().get('cargoModel.tripType.name').setValue('');
    if (foreignOrigin && everyForeignDestinations)
      this.manualCreationCargoService.formUploadDownload.get('origin.reteica').setValue(0);
    else if (this.manualCreationCargoService.formUploadDownload.value.origin.reteica === 0) {
      destinations.forEach(destination => {
        destination.reteica !== 0 &&
          this.manualCreationCargoService.formUploadDownload.get('origin.reteica').value === 0 &&
          this.manualCreationCargoService.formUploadDownload.get('origin.reteica').setValue(destination.reteica);
      })
    }
  }

  ngOnDestroy() {
    this.changeDetector.detach();
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }

}
