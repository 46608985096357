<aside id="mainCollectionPointList" class="col-md-12 mb-0 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="row">
      <div class="col-12">
        <div class="container-list-filter">
          <app-list-filter [options]="optionsFilter" (refreshList)="applyFilter($event)"
            [filterBy]="'user'"></app-list-filter>
        </div>
      </div>
    </div>
    <div class="container-list-body" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let user of listUsers; let i = index;">
        <app-admin-users-item [user]="user" *ngIf="user.information && user.information.document"
          (emitToParent)="refreshList()"></app-admin-users-item>
      </div>
    </div>
  </div>
</aside>