<div class="row-module-item">
  <div class="row container-date">
    <div class="col-md-12">
      <span class="title-purple">
        Modulo
      </span>
    </div>
  </div>
  <div class="row container-data">
    <div class="col-md-12">
      <div class="text-bold label-field">
        <span matTooltip="{{module.name | roleModule}}">
          {{module.name | roleModule}}
        </span>
      </div>
    </div>
      <figure class="icon-arrow-container" (click)="goToModule()">
        <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
      </figure>
  </div>
</div>
