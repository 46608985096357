import { AngularFireStorage } from "@angular/fire/storage";
import { Injectable } from "@angular/core";

import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { Observable, combineLatest } from "rxjs";
import { finalize, map, tap, timeout } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "src/app/core/services/authentication.service";
import { HttpClient } from "@angular/common/http";
import { Endpoints } from "src/app/core/resources/endpoints";
import { environment } from "../../../../environments/environment";
import { Utils } from "src/app/core/resources/utils";

@Injectable({
  providedIn: "root",
})
export class FreightForwarderEvidenceService {
  private basePath = "/uploads";

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private angularFireStorage: AngularFireStorage,
    public utils: Utils
  ) { }

  public rateCargo(idCargo, params) {
    return this._http
      .post(
        environment.urlServerTeclogi +
        this._endpointResources.urlRateCargo +
        idCargo +
        "&role=Creator",
        params
      )
      .pipe(timeout(420000));
  }

  public detailCargo(idCargo) {
    return this._http
      .get(
        environment.urlServerTeclogi +
        this._endpointResources.urlDetailCargo +
        idCargo
      )
      .pipe(timeout(420000));
  }

  public getImageByAddress(idCargo, address, addressId, destinationId?) {
    // tslint:disable-next-line: max-line-length
    let url =
      environment.urlServerTeclogi +
      this._endpointResources.urlImageAddressCargo +
      idCargo +
      "&address=" +
      encodeURIComponent(address) +
      "&addressId=" +
      addressId;
    if (this.utils.isDefined(destinationId)) {
      url += "&destinationId=" + destinationId;
    }
    return this._http.get(url);
  }

  public setStateAddressTracking(data: any, state: string) {
    return this._http
      .post(
        environment.urlServerTeclogi +
        this._endpointResources.urlSetStateAddressTracking +
        state,
        data
      )
      .pipe(timeout(420000));
  }

  public addMultipleFilesToStorages(
    directory: string,
    name: string,
    imageFile: any
  ) {
    return new Promise((resolve, reject) => {
      const storageRef = this.angularFireStorage.ref(directory + "/" + name);
      const task = storageRef.put(imageFile);
      task
        .then((success) => { })
        .catch((error) => { })
        .finally(() => {
          resolve(true);
        });
    });
  }

  public addExtraImageToBack(directory: any) {
    let url =
      environment.urlServerTeclogi +
      this._endpointResources.urlExtraImageAddressCargo;
    return this._http.post(url, directory);
  }

  public updateEvidence(params) {
    return this._http.put(
      environment.urlServerTeclogi +
      this._endpointResources.urlUpdateAdrressTracking,
      params
    );
  }
}
