import { Injectable } from '@angular/core';
import { RouteAuthorizedPoint } from '../interfaces/route-authorized-point';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../resources/endpoints';
import { RoutePointType } from '../interfaces/route-point-type';
import { Observable, Observer } from 'rxjs';
import { RouteAuthorizedPointSearchParam } from '../interfaces/route-authorized-point-search-param';
import { RouteItinerary } from '../interfaces/route-itinerary';
import { RoutePlan } from '../interfaces/route-plan';
import { RouteBasicCity } from '../interfaces/route-basic-city';

@Injectable({
  providedIn: 'root'
})
export class PlanningRouteService {

  constructor(
    private endpoints: Endpoints,
    private http: HttpClient
  ) { }

  public getPointTypes(): Observable<RoutePointType[]> {
    return new Observable<RoutePointType[]>((observer: Observer<RoutePointType[]>) => {
      this.http.get(`${environment.urlServerTeclogi}${this.endpoints.urlCatalog}/${this.endpoints.AuthorizedStopPointsTypes}`)
        .subscribe((result: { catalog: RoutePointType[] }) => {
          observer.next(result.catalog);
          observer.complete();
        });
    });
  }

  public createAuthorizedPoint(point: RouteAuthorizedPoint) {
    return this.http.post(`${environment.urlServerTeclogi}${this.endpoints.createAuthorizedPoint}`, point);
  }

  public createAuthorizedPointBatch(points: RouteAuthorizedPoint[]) {
    return this.http.post(`${environment.urlServerTeclogi}${this.endpoints.createAuthorizedPointBatch}`, points);
  }

  public createRouteItinerary(itinerary: RouteItinerary) {
    return this.http.post(`${environment.urlServerTeclogi}${this.endpoints.createRouteItinerary}`, itinerary);
  }

  public updateAuthorizedPoint(point: RouteAuthorizedPoint) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpoints.updateAuthorizedPoint}`, point);
  }

  public updateCity(city: RouteBasicCity): Observable<RouteBasicCity> {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpoints.updateCity}`, city) as unknown as Observable<RouteBasicCity>;
  }

  public deactivateAuthorizedPoint(pointId: string) {
    return this.http.delete(`${environment.urlServerTeclogi}${this.endpoints.deactiveAuthorizedPoint}?id=${pointId}`);
  }

  public getAuthorizedPoints(params?: RouteAuthorizedPointSearchParam) {
    return this.http.get(`${environment.urlServerTeclogi}${this.endpoints.getAuthorizedPoints}`, { params: params as any });
  }

  public getRoutes(origin?: string, destination?: string) {
    const params = {};
    params['active'] = 'true';
    !!origin ? params['origin'] = origin : 0;
    !!destination ? params['destination'] = destination : 0;
    return this.http.get(`${environment.urlServerTeclogi}${this.endpoints.listRoutes}`, { params });
  }

  public getRoute(id: string) {
    return this.http.get(`${environment.urlServerTeclogi}${this.endpoints.listRoutes}/${id}`);
  }

  public getItinerary(id: string) {
    return this.http.get(`${environment.urlServerTeclogi}${this.endpoints.routeItinerary}/${id}`);
  }

  public selectItinerary(id: string) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpoints.routeItinerarySelect}/?id=${id}`, null);
  }

}
