import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { User } from 'src/app/core/interfaces/user';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { merge } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Patterns } from 'src/app/core/resources/patterns';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { VehiclesService } from '../list-vehicles.service';

@Component({
  selector: 'app-duplicated-phone',
  templateUrl: './duplicated-phone.component.html',
  styleUrls: ['./duplicated-phone.component.scss']
})
export class DuplicatedPhoneComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DuplicatedPhoneComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: User[],
    private http: HttpClient,
    private endpoints: Endpoints,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private dialog: MatDialogRef<DuplicatedPhoneComponent>,
    private patterns: Patterns,
    public utils: Utils,
    private vehiclesService: VehiclesService,
  ) { }

  public editing = null;
  public newPhone = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(12),
    Validators.pattern(this.patterns.ONLY_NUMBERS)
  ]);

  /**
  * @description For each user in data gets the picture and removes the country code
  */
  ngOnInit(): void {
    for (const i in this.data) {
      const phone = this.data[i].phone;
      this.getPicture(this.data[i].profilePicture, i);
      this.data[i].phone = this.removeCountryCode(phone);
    }
  }

  /**
  * @description Closes the dialog
  */
  public closeDialog(): void {
    this.dialog.close();
  }

  /**
  * @param {string} picture is the reference to the user's picture
  * @param index is the user to get the picture url
  * @description Gets the user's profile picture url from user's picture ref
  */
  async getPicture(picture: string, index): Promise<void> {
    if (!!picture) {
      const bucket = AuthService.fStorage;
      const reference = bucket.ref(picture);
      reference.getDownloadURL().then((url: string) => this.data[index].profilePictureUrl = url);
    }
  }

  /**
  * @param {number} index is the index of the element to edit
  * @description Makes editable the element with the index selected
  */
  edit(index: number): void {
    this.editing = index;
  }

  /**
  * @param {number} index is the index of the element to cancel editing
  * @description Cancels the edition of the element with the index selected
  */
  cancel() {
    this.editing = null;
  }

  /**
  * @param {number} index is the index of the element to confirm edition
  * @description Checks the validity of the element and saves the changes if it's valid
  */
  confirm(index: number) {

    if (!this.utils.errorMessagesCustomized(this.newPhone, 'número telefónico', 3, 12)) {
      const newPhone = this.newPhone.value;
      const singleNewPhone = this.vehiclesService.getUsersByPhone(
        this.removeCountryCode(newPhone)
      );
      const countryCodePhone = this.vehiclesService.getUsersByPhone(
        this.addCountryCode(newPhone)
      );

      const duplicatedAgain = []
      merge(singleNewPhone, countryCodePhone).subscribe(
        {
          next: (users: User[] | null) => {
            if (Array.isArray(users)) {
              for (const user of users) {
                if (user.information.document !== this.data[index].information.document) {
                  duplicatedAgain.push(user);
                }
              }

            }
          },
          error: (error: Error) => console.error(error),
          complete: () => {
            const currentDocuments = [];
            for (const user of this.data) {
              currentDocuments.push(user.information.document);
            }

            let notice = false;
            for (const user of duplicatedAgain) {
              if (currentDocuments.indexOf(user.information.document) === -1) {
                this.data.push(user);
                notice = true;
              }
            }

            if (notice) {
              this.snackBar.openSnackBar(
                'Se ha encontrado otro usuario con ese número asignado',
                undefined,
                "alert"
              );
            }

            this.data[index].phone = newPhone;
            this.ngOnInit();
            this.editing = null;
          }
        }
      );
    }
  }

  /**
  * @description Saves the changes of every phone number if they are valid and closes the dialog
  */
  save() {
    let phones = [];
    for (const user of this.data) {
      if (phones.indexOf(user.phone) !== -1) {
        this.snackBar.openSnackBar(
          'Aún hay números repetidos, por favor corríjalos',
          undefined,
          "error"
        );
        return;
      }
      phones.push(user.phone);
    }

    const observables = [];
    for (const i in this.data) {
      const request = {
        information: {
          document: this.data[i].information.document
        },
        phone: this.addCountryCode(this.data[i].phone)
      };
      const $ = this.http.put(`${environment.urlServerTeclogi}${this.endpoints.urlUpdateUser}`, request);
      observables.push($);
    }

    let errors = 0;
    let success = 0;
    this.spinner.show();
    merge(...observables).subscribe({
      next: () => { success++; },
      error: (error) => {
        console.error(error);
        errors++;
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
        if (errors > 0) {
          this.snackBar.openSnackBar(
            `Se han actualizado ${success} usuarios con ${errors} errores`,
            undefined,
            "error"
          );
        } else {
          this.snackBar.openSnackBar(
            `Se han actualizado ${success} usuarios`,
            undefined,
            "success"
          );
        }

        this.closeDialog();
      }
    });
  }

  /**
  * @param {string} phone is the phone number to remove the country code
  * @returns {string} returns the phone without country code
  * @description Removes the phone's country code if exists
  */
  private removeCountryCode(phone: string): string {
    return phone.startsWith('57') ? phone.slice(2) : phone;
  }

  /**
  * @param {string} phone is the phone number to add the country code
  * @returns {string} returns the phone with country code
  * @description Adds the phone's country code if is necessary
  */
  private addCountryCode(phone: string): string {
    return phone.startsWith('57') ? phone : `57${phone}`;
  }
}
