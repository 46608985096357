<aside class="cargo__list__service__requests col-md-12">
  <div class="cargo__list__service__requests--filter">
      <app-list-filter
        [options]="optionsFilter"
        [typeList]="typeList"
        (refreshList)="refreshList($event)">
      </app-list-filter>
  </div>
  <div
    *ngIf="paginationList.getList().length"
    class="cargo__list__service__requests--body"
    infinite-scroll
    [infiniteScrollDistance]="paginationList.getScrollDistance()"
    [infiniteScrollThrottle]="paginationList.getThrottle()"
    [scrollWindow]="false"
    (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let cargo of paginationList.getList(); let i = index;">
          <app-cargo-item
              [cargoItemList]="cargo"
              [typeList]="typeList"
              select-item-list
              [item]="cargo"
              [list]="paginationList.getList()"
              (applyFilter)="refreshList()">
          </app-cargo-item>
      </div>
  </div>
  <div
    *ngIf="!paginationList.getList().length"
    class="container_no_results">
      <div class="icon"><i class="fa-solid fa-filter-circle-xmark"></i></div>
      <h4 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h4>
  </div>
</aside>
