<aside class="px-0 px-md-4 mb-md-0 mb-12 h-100-margin">
  <div class="container-list h-100">
    <div class="container-list-header pl-0 pl-md-2">
      <div class="w-100 d-flex justify-content-between flex-wrap my-3">
        <div class="col-md-6 pl-0">
          <mat-form-field appearance="outline">
            <mat-label>Fecha del ruteo<span class="asterisk">*</span></mat-label>
            <input type="text" matInput [matDatepicker]="datePicker" [formControl]="dateControl"
              (click)="datePicker.open()" (dateChange)="onChangeDatePicker()" readonly>
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6 text-md-right">
          <button matTooltip="Crear Ruteo" permission-display [module]="permission.routing.module"
            [functionality]="permission.routing.createRouting" mat-raised-button type="button" class="bg-color-primary"
            color="primary" (click)="goToCreate()">
            <i class="fas fa-plus-circle"></i>
            <span>Crear ruteo</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container-list-body" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()"
      *ngIf="listScenarios?.length">
      <app-scenario-item *ngFor="let scenario of listScenarios;" [scenario]="scenario"></app-scenario-item>
    </div>
    <div *ngIf="!listScenarios?.length" class="container_no_results">
      <div class="icon"><i class="fas fa-calendar-times"></i></div>
      <h5 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h5>
    </div>
  </div>
</aside>