<button *ngIf="enableButton" mat-raised-button color="primary" class="primary-secondary" type="button" (click)="openConfirmExportList(modalExportList)">
  <i mat-list-icon class="fas fa-file-download"></i>
  Exportar {{typeFileExport.name}}
</button>

<ng-template #modalExportList let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title">
      ¿Deseas exportar el listado de {{typeList}} en un archivo {{typeFileExport.name}}?
    </p>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary" (click)="closeConfirmExportList()">No</button>
      <button mat-raised-button color="primary" (click)="onExportFile()">Si</button>
    </mat-card-actions>
  </div>
</ng-template>
