import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CargoTripAnomalyService {

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private _globalResources: Global,
  ) { }

  getTripAnomaly() {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.getTripAnomaly,
    );
  }

  createTripAnomaly(data, idCargo: string) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.createTripAnomaly + idCargo,
      data
    );
  }

  updateTripAnomaly(data, idCargo: string, idAnomaly: number) {
    return this._http.put(
      `${environment.urlServerTeclogi}${this._endpointResources.updateTripAnomaly}${idCargo}?id=${idAnomaly}`,
      data
    )
  }
}


