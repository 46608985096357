<section class="container">
    <header>
        <i matTooltip="Cerrar" class="fas fa-times fa-2xl cursor-pointer" style="float: right;"
            (click)="ref.close()"></i>
        <div class="title-component text-center" *ngIf="typePayment==='Payments'">Histórico de pagos</div>
        <div class="title-component text-center" *ngIf="typePayment==='DiscountsAndBonuses'">Descuentos y bonificaciones
        </div>
        <p *ngIf="typePayment==='DiscountsAndBonuses'">
            Detalle de los movimientos de descuentos y bonificaciones en la carga, las
            bonificaciones serán positivas y los descuentos negativos.
        </p>
        <p *ngIf="typePayment==='Payments'">
            Los pagos estarán marcados con un símbolo de asterisco (*) y en color azul.
        </p>
    </header>
    <main>
        <div class="container movements-container px-0">
            <div *ngFor="let shippingCost of shippingCostToDisplay; let i = index" class="row movement-item"
                [ngClass]="{'background-gray': i%2===0}">
                <div class="col-md-6 d-flex flex-column">
                    <b class="bold text-nowrap mb-1">{{shippingCost.date | date:'yyyy/MM/dd hh:mm a'}}</b>
                    <small *ngIf="shippingCost?.typeModification?.name">
                        {{shippingCost?.typeModification?.name}}
                    </small>
                    <small *ngIf="shippingCost?.type && typePayment==='Payments'">
                        <i>{{getTypePaid(shippingCost?.type)}}</i>
                    </small>
                    <br>
                    <small class="text-nowrap d-flex flex-wrap">
                        <span class="text-bold mr-2">{{users[shippingCost?.userId]?.information?.name}}</span>
                        <span class="mr-2"> ({{shippingCost?.userId}})</span>
                        <span> {{users[shippingCost?.userId]?.email}}</span>
                    </small>
                </div>
                <div class="col-md-6 m-auto d-flex flex-column justify-content-center align-items-md-center">
                    <div *ngIf="shippingCost.amountRate > 0" class="mt-1">
                        <b class="bold">Tarifa:</b>
                        <b class="text-nowrap bold" [ngClass]="
                            {
                                'text-danger': shippingCost.type==1,
                                'text-success' : shippingCost.type==0,
                                'text-info': shippingCost.type != 0 && shippingCost.type != 1
                            }">
                            {{shippingCost.type == 0 ? '+' : shippingCost.type == 1 ? '-' : ''}}
                            {{shippingCost.amountRate | currency:'COP'}}
                        </b>
                    </div>
                    <div *ngIf="shippingCost.amount > 0" class="mt-1">
                        <b class="bold">{{shippingCost.type != 0 && shippingCost.type != 1 ? 'Pago': 'Flete'}}:</b>
                        <b class="text-nowrap bold" [ngClass]="
                            {
                                'text-danger': shippingCost.type==1,
                                'text-success' : shippingCost.type==0,
                                'text-info': shippingCost.type != 0 && shippingCost.type != 1
                            }">
                            {{shippingCost.type == 0 ? '+' : shippingCost.type == 1 ? '-' : ''}}
                            {{shippingCost.amount | currency:'COP'}}
                        </b>
                    </div>
                </div>
                <div class="col-12"
                    *ngIf="shippingCost?.observation &&  shippingCost?.observation != shippingCost?.typeModification?.name">
                    <small>
                        Observación: <br><i>{{shippingCost?.observation}}</i>
                    </small>
                </div>
                <i class="fas fa-trash trash-icon" *ngIf="typePayment==='Payments'"
                    [ngClass]="{'disabled-option':!canDeletePayment(shippingCost)}"
                    (click)="openDialogDelete(shippingCost)" permission-display [module]="permission.cargo.module"
                    [functionality]="permission.cargo.deletePayment"></i>
            </div>
        </div>
    </main>
    <br>
    <footer>
        <div class="text-center">
            <button mat-raised-button color="accent" class="m-auto" (click)="ref.close()">Aceptar</button>
        </div>
    </footer>
</section>