export enum ModalEnum {
  EXTRA_SMALL_WIDTH = '500px',
  SMALL_WIDTH = '700px',
  FORMS_HEIGHT = '700px',
  MEDIUM_WIDTH = '800px',
  FULL_WIDTH = '1000px',
  LARGE_WIDTH = '1200px',
  MAX_WIDTH = '100%',
  MAX_HEIGHT = '100vh',
  MEDIUM_HEIGHT = '500px'
}
