export enum ServiceMessages {
  CANNOT_SEND_NOTIFICATIONS = "No se pueden enviar notificaciones al navegador.",
  GENERAL_HTTP_ERROR = "Ha ocurrido un error procesando la solicitud.",
  GENERAL_RETRY_INDICATION = "Ha ocurrido un error, intentente nuevamente.",
  REFERENCES_SUCCESS_DELETE = "Datos eliminados exitosamente",
  REFERENCES_SUCCESS_EDIT = "Datos guardados exitosamente",
  CITY_UPDATED = "Se actualizó correctamente la ciudad",
  ERROR_UPDATING_CITY = "Ocurrió un error actualizando la ciudad",
  CITIES_ASSOCIATED = "Ciudades asociadas correctamente",
  ERROR_ASSOCIATING_CITIES = "Ocurrió un error asociando las ciudades",
  CITY_ALREADY_LINKED = "La ciudad seleccionada ya existe en la lista de subciudades",
  USER_DEACTIVE_SUCCESS_GENERAL = "Operacion realizada exitosamente",
  USER_CHANGE_SUCCESS = "Se cambió el rol del usuario exitosamente",
  USER_ACTIVATED = "Se activó el usuario correctamente",
  USER_DEACTIVATED = "Se desactivo el usuario correctamente",
  ADDRESS_LOCATION_NOT_SETTED = "No se ha podido guardar la longitud y latitud ingresada. Por favor vuelva a intentarlo",
  VEHICLE_HOLDERCOMPANY_ASSOCIATED = "El vehículo fue asociado correctamente a la compañia",
  LICENSE_PLATE_NOT_FOUND = 'La placa %s no existe',
  LICENSE_PLATE_FORMAT_INCORRECT = "La placa %s tiene un formato incorrecto",
  VEHICLE_WITHOUT_DRIVER = 'La placa %s no cuenta con conductor',
  NOT_CATEGORY_OR_LICENSE_PLATE = 'No hay informacion asociada a la licencia del conductor o a la categoria respectiva'
}
