import { StepComponentActive } from "./step-component-active.model";

export class ComponentActive {

    private id: number;
    private step: StepComponentActive;
    private itineraryComplete: boolean;

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getStep(): StepComponentActive {
        return this.step || new StepComponentActive();
    }

    public setStep(step: StepComponentActive): void {
        this.step = new StepComponentActive();
        this.step.deserialize(step);
    }

    public isItineraryComplete(): boolean {
        return this.itineraryComplete;
    }

    public setItineraryComplete(itineraryComplete: boolean): void {
        this.itineraryComplete = itineraryComplete;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
