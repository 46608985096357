import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';


@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'input[appLicensePlate]',
})
export class LicensePlateDirective implements OnInit {


    constructor(
        private _element: ElementRef,
        private renderer: Renderer2,
    ) {
    }

    ngOnInit() {
        this.renderer.setAttribute(this._element.nativeElement, 'maxLength', '7');
    }

    @HostListener('input', ['$event']) onModelChange(event) {
        let initialValue = this._element.nativeElement.value;
        this._element.nativeElement.value = initialValue.replace(' ', '');
        initialValue = this._element.nativeElement.value;
        this._element.nativeElement.value = initialValue.toUpperCase();
    }

}
