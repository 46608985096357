import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/user';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Titles } from 'src/app/core/resources/titles';
import { CompaniesService } from '../list-companies.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Company } from 'src/app/core/interfaces/company';
import { FreightListService } from 'src/app/modules/cargo/cargo-list/cargo-list.service';
import { Permission } from 'src/app/core/resources/permission';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-list-companies',
  templateUrl: './list-companies.component.html',
  styleUrls: ['./list-companies.component.scss'],
  providers: [AuthService]
})
export class ListCompaniesComponent implements OnInit {

  permission = Permission;
  pageKey = 1;
  pageSize = 25;
  throttle = 300;
  scrollDistance = 1;
  enablePagingScroll = true;
  listCompanies: Company[] = [];
  companyUser: Company;
  companiesFilter: string = '';

  constructor(
    private authService: AuthService,
    private titles: Titles,
    private companiesService: CompaniesService,
    public utils: Utils,
    private global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private router: Router,
    private freightListService: FreightListService
  ) { }

  ngOnInit() {

    this.companyUser = this.authService.getCompany();
    if (this.companyUser && this.companyUser.subCompanies && this.companyUser.subCompanies) {
      this.getAllCompaniesChildrens();

    } else {
      this.getCompanies();
    }
  }

  filterCompanies(company: Company) {
    return
  }

  getCompanies() {
    this.freightListService.getAllCompanies().subscribe(
      (success: Company[]) => {
        this.setListcompanies(success);
      },
      (error) => {
        this.listCompanies = [];
      }
    );
  }

  setListcompanies(success) {
    if (!this.utils.isDefined(success) || success.length < 1) {
      this.enablePagingScroll = false;
    } else {
      if (this.listCompanies && this.listCompanies.length) {
        this.listCompanies = this.listCompanies.concat(success);
      } else {
        this.listCompanies = success;
      }

      if (success.length < this.pageSize) {
        this.enablePagingScroll = false;
      }
    }
  }

  getAllCompaniesChildrens() {
    this.companiesService.getListCompaniesChildren(this.authService.getUserSession().clientCompanyId, this.pageKey, this.pageSize).subscribe(
      (success: Company[]) => {
        this.setListcompanies(success);
      },
      (error) => {
        this.listCompanies = [];
      }
    );
  }

  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getCompanies();
    }
  }

  resetValuesPagingScroll() {
    this.enablePagingScroll = true;
    this.listCompanies = [];
    this.pageKey = 1;
  }

  goToCreate() {
    this.router.navigate(['administration/companies/create']);
  }

}
