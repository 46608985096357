<section class="error-border-alarm">
    <header class="error-header">
        <div class="title light">
            <i class="fas fa-exclamation-triangle"></i> {{notification.title}}
        </div>
    </header>
    <br>
    <main class="prebody">
        <div class="body-wrapper">
            <div class="body">
                <p class="description" [innerHTML]="getDescription()"></p>
                <div>
                    <div class="title subtitle text-center text-panic">Ubicación</div>
                    <div class="row">
                        <div class="col-md-6">
                            <app-standard-map [options]="mapOptions"></app-standard-map>
                        </div>
                        <div class="col-md-6">
                            <div class="address-container">
                                <div class="address-progress initial-address">
                                    <i class="fas fa-circle"></i>
                                </div>
                                <div class="address">
                                    <div class="text-panic">Dirección origen</div>
                                    <p>{{cargo?.cargoFeature?.uploadDownload?.origin?.addresses[0]?.address}}</p>
                                </div>
                            </div>

                            <div class="address-container">
                                <div class="address-progress final-address">
                                    <i class="fas fa-circle"></i>
                                </div>
                                <div class="address">
                                    <div class="text-panic">Dirección destino</div>
                                    <p>{{
                                        cargo
                                        ?.cargoFeature
                                        ?.uploadDownload
                                        ?.destination[
                                        cargo
                                        ?.cargoFeature
                                        ?.uploadDownload
                                        ?.destination.length - 1
                                        ]
                                        ?.addresses[
                                        cargo
                                        ?.cargoFeature
                                        ?.uploadDownload
                                        ?.destination[
                                        cargo
                                        ?.cargoFeature
                                        ?.uploadDownload
                                        ?.destination.length - 1
                                        ]
                                        ?.addresses.length - 1
                                        ]
                                        ?.address
                                        }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="w-100 text-right">
                <button class="goToCargoButton text-panic" (click)="goToCargoDetail()">Ir al detalle de la carga <i
                        class="fas action-secondary fa-arrow-right"></i></button>
            </div>
        </div>
        <div class="w-100">
            <u class="solved-button text-panic">Emergencia controlada</u>
        </div>
    </main>
</section>