import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Global } from 'src/app/core/resources/global';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  public form: FormGroup = new FormGroup(
    {
      municipalityCode: new FormControl('', [Validators.required]),
      situation: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      location: new FormGroup({
        lat: new FormControl(null, [Validators.required]),
        lng: new FormControl(null, [Validators.required])
      })
    }
  );
  cityControl = new FormControl('', Validators.required);
  citySub: Subscription;
  cityOptions: OptionsAutocomplete = { title: 'Ciudad' };
  validate = '';
  public services: string[] = ['Estación de Servicio', 'Hotel', 'Restaurante', 'Parqueadero'];
  keyMarker: string = 'news';
  mapOptions: OptionsAutocomplete = {
    keyMarkerListener: this.keyMarker,
    clickMapIcon: this.global.pathMarkerOrigin,
    initialClickMap: true,
  };
  @ViewChild(StandardMapComponent, { static: false }) standardMap: StandardMapComponent;
  constructor(
    public global: Global,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.setSubscription();
  }
  setSubscription() {
    this.citySub = this.cityControl.valueChanges.subscribe((city) => {
      this.form.get('municipalityCode').setValue(city && city.id ? city.id : '');
    })
  }

  async onClickMap($event) {
    let address = await this.transformCoordinatesToAddress($event.latLng.lat(), $event.latLng.lng());
    let location = { lat: $event.latLng.lat(), lng: $event.latLng.lng() }
    if (address && location) {
      this.form.get('location').setValue(location);
      this.form.get('address').setValue(address);
    }
  }

  async transformCoordinatesToAddress(lat: number, lng: number): Promise<string> {
    let geocoderService = new google.maps.Geocoder;
    let latlng = { lat: lat, lng: lng };
    let address = '';
    this.spinner.show();
    await geocoderService.geocode({ 'location': latlng }, (results, status) => {
      if (results.length) {
        this.spinner.hide();
        address = results[0].formatted_address;
      } else this.spinner.hide();
    });
    return address;
  }

  onSelectAddress($event) {
    if ($event && $event.data && $event.data.location && $event.data.location.lat && $event.data.location.lng) {
      this.standardMap.removeMarker(this.keyMarker);
      this.standardMap.createMarker(this.keyMarker, $event.data.location.lat, $event.data.location.lng, null, this.global.pathMarkerOrigin);
      this.standardMap.setCenterMap($event.data.location.lat, $event.data.location.lng, 16);
      this.form.get('location').setValue($event.data.location);
      this.form.get('address').setValue($event.data.address);
    }
  }

  public submit() {
    this.validate = "touched";
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
  }
}
