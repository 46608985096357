import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';

@Component({
  selector: 'app-type-cargo',
  templateUrl: './type-cargo.component.html',
  styleUrls: ['./type-cargo.component.scss'],
  providers: [AuthService, Global]
})
export class TypeCargoComponent implements OnInit {

  public types = [
    {
      name: "Carga suelta",
      image: "/assets/svg/icons/icon-load-free.svg"
    },
    {
      name: "Contenedor",
      image: "/assets/svg/icons/icon-container.svg"
    }
  ]

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
    private router: Router
  ) {

  }

  ngOnInit() {

  }

  /* selectTypeLoad(loadSelectedName: string) {
    this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.name.setValue(loadSelectedName);
    if (loadSelectedName === 'Carga suelta') {
      // Medidas del contenedor
      this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.containerMeasure.controls.size.setValue(
        ''
      );
      this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.description.setValue(
        ''
      );
      this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.code.setValue(
        ''
      );
    }
  } */

  public selectTypeLoad(data) {
    this.manualCreationCargoService.cargoForm.get('cargoModel.cargoType.name').setValue(data.name);
    if (data.name === 'Carga suelta') {
      // Medidas del contenedor
      this.manualCreationCargoService.cargoForm.get('cargoModel.cargoType.containerMeasure.size').setValue('');
      this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.description').setValue('');
      this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code').setValue('');
    }
    this.manualCreationCargoService.cargoForm.get('cargoModel.serviceType.name').setValue('');
  }

  nextStep() {
    if (this.manualCreationCargoService.isValidCargoType()) {
      switch (this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.name.value) {
        case 'Carga suelta':
          // Tipo de servicio
          this.manualCreationCargoService.getComponentActive().setId(1);
          this.manualCreationCargoService.getComponentActive().getStep().setId(5);
          this.router.navigate([this.manualCreationCargoService.steps.typeService.url]);
          break;
        case 'Contenedor':
          // Tipo de servicio
          this.manualCreationCargoService.getComponentActive().setId(1);
          this.manualCreationCargoService.getComponentActive().getStep().setId(4);
          this.router.navigate([this.manualCreationCargoService.steps.containerMeasure.url]);
          break;
      }
    } else {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'un tipo de carga'), undefined, 'alert');
    }
  }

  stepBack() {
    const cargoTripType = this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.name.value;
    if (cargoTripType === 'Internacional') {
      this.manualCreationCargoService.getComponentActive().setId(0);
      this.manualCreationCargoService.getComponentActive().getStep().setId(2);
      this.router.navigate([this.manualCreationCargoService.steps.dateLoad.url], { state: { type: 2 } });
    }
    else if (cargoTripType === 'Exportación' || cargoTripType === 'Importación') {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(2);
      this.router.navigate([this.manualCreationCargoService.steps.typeRegime.url]);
    }
    else {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(1);
      this.router.navigate([this.manualCreationCargoService.steps.typeTrip.url]);
    }
  }

}
