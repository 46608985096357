// Angular
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// Routing's

// Components
import { SharedModule } from 'src/app/core/shared.module';
import { ChangePasswordComponent } from './change-password/changePassword.component';
import { DocumentsComponent } from './documents/documents.component';
import { SettingsRoutingModule } from './settings-routing.module';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    SettingsRoutingModule
  ],
  declarations: [
    DocumentsComponent,
    ChangePasswordComponent
  ],
  entryComponents: [],
  exports: [
    DocumentsComponent,
    ChangePasswordComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SettingsModule {

}
