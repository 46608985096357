<div class="modal-body wrapper-modal position-relative p-4">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="content">
        <header class="d-flex align-items-center">
            <div class="dialog-title">
                <h1>Georefencia</h1>
                La ciudad seleccionada <b>({{data.city.name}})</b> actualmente no tiene información de
                georeferenciación,
                por favor seleccione una ubicación para fijar la geoposición de la ciudad, pruebe escribir el nombre de
                la ciudad.
            </div>
        </header>
        <br>
        <br>
        <div class="content-child">
            <app-complete-address title="Georefencia de Ciudad" [deleteButton]="false" [draggable]="false"
                (location)="onLocation($event)" [control]="control"></app-complete-address>
            <mat-error *ngIf="!(location?.lat && location?.lng)">
                Seleccione una dirección de Google Maps para <b>({{data.city.name}})</b>
            </mat-error>
        </div>
        <br>
        <br>
        <div class="d-flex justify-content-center">
            <button mat-raised-button color="accent" (click)="confirm()">
                Confirmar
            </button>
        </div>
    </div>
</div>