import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { SelectGpsService } from "src/app/shared/select-gps/select-gps.service";
@Component({
  selector: "app-delete-gps-vehicle",
  templateUrl: "./delete-gps-vehicle.component.html",
  styleUrls: ["./delete-gps-vehicle.component.scss"],
})
export class DeleteGpsVehicleComponent {
  selectedGps: FormControl = new FormControl('', Validators.required);
  validate: string = '';
  constructor(
    public dialogRef: MatDialogRef<DeleteGpsVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public selectGpsService: SelectGpsService,
    public snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
  ) { }

  /**
  * @description If selectedGPS is valid (one GPS was selected) deletes that specific GPS and close the dialog if has success
  */
  deleteGps(): void {
    if (this.selectedGps.valid) {
      this.spinner.show();
      this.selectGpsService.deleteGpsSelected(this.selectedGps.value).subscribe(
        (success) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(
            FormMessages.SUCCESS_DELETE_GPS,
            undefined,
            "success"
          );
          this.dialogRef.close({ state: true });
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(
            FormMessages.GENERAL_ERROR_DEFAULT,
            undefined,
            "error"
          );
        }
      );
    } else {
      this.validate = 'touched';
      if (this.selectedGps.invalid) this.snackBarService.openSnackBar(FormMessages.AT_LEAST_ONE_OPTION, undefined, 'alert');
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    }
  }
}
