import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/user';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Titles } from 'src/app/core/resources/titles';
import { CompaniesService } from '../list-companies.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Company } from 'src/app/core/interfaces/company';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Component({
  selector: 'app-item-company',
  templateUrl: './item-company.component.html',
  styleUrls: ['./item-company.component.scss'],
  providers: [AuthService]
})
export class ItemCompanyComponent implements OnInit {

  @Input() company: Company;
  instanceModalDeleteCompany: NgbModalRef;
  @ViewChild('modalConfirmDeleteCompany', { static: false }) modalConfirmDeleteCompany: ModalComponent;

  constructor(
    private authService: AuthService,
    public titles: Titles,
    private companiesService: CompaniesService,
    public utils: Utils,
    private global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private router: Router,
    private modalService: NgbModal
  ) {

  }

  ngOnInit() {

  }

  openDetail() {

    this.companiesService.compnySelected = this.company;
    this.router.navigate(['administration/companies/detail', this.company.companyId]);
  }

  deleteCompany() {
    this.companiesService.deleteCompany(this.company.companyId).subscribe(
      (success) => {
        this.snackBarService.openSnackBar('Empresa eliminada correctamente');
        // this.backView();
      },
      (error) => {
        this.snackBarService.openSnackBar("Ocurrió un error eliminando la empresa", undefined, 'error');
      }
    );
  }

  openModalDeleteCompany() {

    this.modalConfirmDeleteCompany.openConfirm();
  }

  closeModal() {

    this.modalConfirmDeleteCompany.closeModal();
  }

  get titleModalDelete() {
    return `¿Estás seguro que deseas eliminar la empresa ${this.company.name}?`;
  }

}
