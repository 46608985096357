import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmailStructure } from 'src/app/core/interfaces/emailStructure';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Patterns } from 'src/app/core/resources/patterns';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { CatalogService } from 'src/app/core/services/catalog.service';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent implements OnInit {

  permission = Permission;
  form: FormGroup;
  types: CatalogItem[] = [];

  @Input() nitCompany: string;
  @Input() emails: EmailStructure[] = [];
  @Output() public refreshList: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private patterns: Patterns,
    public utils: Utils,
    private snackBarService: SnackBarService,
    public spinner: NgxSpinnerService,
    public listCompaniesService: CompaniesService,
    private permissionRole: PermissionRole,
    private catalogService: CatalogService
  ) {
    this.setTypes();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(this.patterns.EMAIL.source), Validators.maxLength(100)]],
      type: ['', Validators.required]
    });
  }

  setTypes() {
    this.catalogService.getCatalog('EmailType').subscribe({
      next: (types: Catalog) => {
        this.types = types.catalog;
      },
      error: (error) => {
        console.trace(error);
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, "Aceptar", "error")
      }
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('name'), 'correo electrónico', null, 100)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('type'), 'tipo')) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    const body = this.form.value;
    this.addEmailToCompany(body);
  }

  private addEmailToCompany(body) {
    this.spinner.show();
    const $sub = this.listCompaniesService.addEmailToCompany([body], this.nitCompany).subscribe(
      {
        next: (success: any) => {
          this.snackBarService.openSnackBar("Correo agregado exitosamente");
          this.refreshList.emit();
          this.spinner.hide();
        },
        error: () => {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
          this.spinner.hide();
        },
        complete: () => {
          $sub.unsubscribe();
        }
      }
    )
  }

  getActualEmails(type): Array<EmailStructure> {
    return this.emails.filter(email => email.type === type);
  }

  get hasPermissionToAdd(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.createEmailCompany
    );
  }
}
