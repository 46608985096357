import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../environments/environment';
import { CargoPayment } from 'src/app/core/interfaces/cargoPayment';
import { AuthService } from 'src/app/core/services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class DriverCargoService {

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private endpointResources: Endpoints
    ) { }

    registerPayment(data: CargoPayment) {
        data['userId'] = this.authService.getUserSession().information.document;
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.registerPaymentCargo,
            data
        );
    }

}
