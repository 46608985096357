import { Component } from '@angular/core';
import { User } from 'src/app/core/interfaces/user';
import { Notification } from 'src/app/core/interfaces/notification';
import { AuthService } from 'src/app/core/services/authentication.service';
import { NotificationsService } from './notifications.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [NotificationsService]
})

export class NotificationComponent {
  throttle = 300;
  scrollDistance = 1;
  enablePagingScroll = true;
  pageKey = 1;
  pageSize = 5;
  notifications: Notification[];
  constructor(
    private notificationServices: NotificationsService,
    private authService: AuthService,
    private router: Router,
    public utils: Utils,
    private snackBarService: SnackBarService,
    public spinner: NgxSpinnerService
  ) {
  }


  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    let companyId = this.authService.getUserSession().clientCompanyId;
    this.notificationServices.getNotifications(this.pageKey, this.pageSize, companyId).subscribe(
      (success: Array<Notification>) => {
        if (!this.utils.isDefined(success) || success.length < 1) {
          this.enablePagingScroll = false;
        } else {
          if (this.notifications && this.notifications.length) {
            this.notifications = this.notifications.concat(success);
          } else {
            this.notifications = success;
          }
        }
      },
      (error) => {

      }
    );
  }

  selectNotification(notification: Notification) {
    switch (parseInt(notification.type)) {
      case 1:
        this.router.navigate(['/cargo/list/loadsInNegotiation/']);
        break;
      case 2:
        this.getCargo(notification.cargoId);
        break;
    }

    if (!notification.state) {
      this.setStateNotification(notification);
    }
  }

  getCargo(cargoId: string) {
    const cargoObserver = {
      next: (cargo: Cargo) => {
        if (cargo && cargo.consecutive) this.router.navigate(['/cargo/tracking/', cargo.consecutive.toString()]);
        else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      error: () => {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.notificationServices.detailCargo(cargoId).subscribe(cargoObserver);
  }

  setStateNotification(notification: Notification) {
    notification.state = true;
  }

  onScrollDown() {

    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getNotifications();
    }
  }

  refreshNotifications() {

    this.notifications = [];
    this.pageKey = 1;
    this.enablePagingScroll = true;
  }

}
