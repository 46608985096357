import { ContainerMeasure } from "./container-measure.model";

export class CargoType {

    private name: string;
    private containerMeasure: ContainerMeasure;

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getContainerMeasure(): ContainerMeasure {
        return this.containerMeasure || new ContainerMeasure();
    }

    public setContainerMeasure(containerMeasure: ContainerMeasure): void {
        this.containerMeasure = new ContainerMeasure();
        this.containerMeasure.deserialize(containerMeasure);
    }


    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }
}
