<div class="container px-0">
    <div class="col-md-12 mb-md-0 mb-12 px-0">

        <body class="container-body">
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CONTRATO
                    DE VINCULACI&Oacute;N&nbsp;</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;ANEXO
                    DE T&Eacute;RMINOS Y CONDICIONES DEL</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">MANIFIESTO
                    DE CARGA NO.</span>
            </p>
            <p><br></p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CONTRATANTE</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    &nbsp;INNOVBO S.A.S., con Nit.</span><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;901.343.057-1</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                    ubicada en la Av Calle 26 # 96J 66 Ed. Optimus, Of. 6.6 &nbsp;de la ciudad de Bogot&aacute;,
                    &nbsp;que en lo
                    sucesivo se denominar&aacute; el CONTRATANTE.&nbsp;</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CONTRATISTA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Nombre,
                    c&eacute;dula y nacionalidad: Como aparece en el manifiesto de carga o documento de
                    transporte.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Entre
                    el CONTRATANTE y el CONTRATISTA, a quienes conjuntamente se les conocer&aacute; como <span
                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">las
                        PARTES,</span>
                    manifiestan que
                    es su deseo celebrar el presente&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contrato
                    de vinculaci&oacute;n o de encargo a tercero</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                    el cual se regir&aacute; por las siguientes cl&aacute;usulas:</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PRIMERA.
                    DEFINICIONES:</span>
            </p>
            <p><br></p>
            <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CONTRATANTE:&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                            empresa INNOVBO S.A.S.. la cual cuenta con habilitaci&oacute;n dada por el ministerio
                            de transporte
                            o el organismo nacional de un pa&iacute;s miembro de la Comunidad Andina de Naciones (CAN),
                            para
                            realizar transporte de mercanc&iacute;as.&nbsp;</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CONTRATISTA</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            se entender&aacute; como el conductor, propietario, tenedor y/o poseedor del veh&iacute;culo
                            de carga
                            con el cual se realizar&aacute; el transporte nacional o internacional de mercanc&iacute;as,
                            EL
                            CONTRATISTA se ha vinculado con la herramienta tecnol&oacute;gica INNOVBO CONDUCTOR y
                            INNOVBO.com, mayor, identificado como aparece
                            en el
                            documento de transporte, quien con la emisi&oacute;n del documento de transporte o con el
                            registro digital, acepta el contenido del presente
                            contrato.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ENCARGO
                            A TERCERO.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Negociaci&oacute;n
                            consistente en la que el CONTRATANTE encomienda al CONTRATISTA el transporte de
                            mercanc&iacute;as en los
                            t&eacute;rminos del art&iacute;culo 984 y 991 del C&oacute;digo de Comercio, Decreto 1079 de
                            2015 de
                            Colombia, &nbsp;y Decisi&oacute;n 837 de 2019 de la comunidad andina de naciones (CAN),
                            Resoluci&oacute;n 2101 de 2019 de la secretar&iacute;a general de la Comunidad Andina y por
                            las
                            dem&aacute;s normas que les sean concordantes, que las adicionen, modifiquen o las
                            reemplacen; la
                            entrega de las mercanc&iacute;as se realizar&aacute; al destinatario la mercanc&iacute;a en
                            las
                            condiciones, tiempos, peso, volumen y destino se&ntilde;alado en el documento de transporte
                            que sea
                            generado por el CONTRATANTE.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CONTRATO
                            DE VINCULACI&Oacute;N</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            se entiende el contenido del presente documento mediante el cual el CONTRATISTA se obliga a
                            realizar el
                            transporte nacional o internacional de mercanc&iacute;as, bajo documento de transporte que
                            le emite el
                            CONTRATANTE.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">MERCANC&Iacute;A:</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;se
                            entender&aacute; como todo bien o producto, empacado o no, que, de acuerdo con su naturaleza
                            y
                            caracter&iacute;sticas, ser&aacute; transportado de manera segura de un punto de partida a
                            uno de
                            destino por el CONTRATISTA, la descripci&oacute;n o clase de las mercanc&iacute;as o su
                            cantidad se
                            detallar&aacute;n o relacionar&aacute;n en el documento de transporte.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DOCUMENTO
                            DE TRANSPORTE</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            se entender&aacute; como tal y sin limitarse a: el manifiesto de carga nacional o urbano,
                            remesa terrestre de
                            carga nacional o urbana, la carta de porte internacional por carretera (CPIC), manifiesto
                            de carga
                            internacional (MCI), declaraci&oacute;n de tr&aacute;nsito aduanero nacional o internacional
                            (DTA o
                            DTAI), documento de transporte multimodal (OTM) o el
                            documento mediante el cual se encargue el transporte de las mercanc&iacute;as a nivel
                            nacional o
                            internacional.&nbsp;</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ANOTACIONES:</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                            las anotaciones de da&ntilde;os o faltantes de carga, devoluci&oacute;n del contenedor,
                            entre otras
                            deber&aacute;n constar en el documento de transporte o inventario.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CUMPLIDOS
                            / PRUEBAS DE ENTREGA / POD:</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Remesa
                            de transporte o el documento de transporte y los dem&aacute;s documentos del cliente
                            (remisiones,
                            facturas, continuaciones de viaje, BL&acute;s, albaranes, etc.,) debidamente firmadas y
                            selladas por el
                            destinatario de la mercanc&iacute;a.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">USD</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            D&oacute;lar de Estados Unidos de Norte Am&eacute;rica.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Libreta
                            de Tripulante Terrestre</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            documento expedido por el organismo nacional de migraci&oacute;n del Pa&iacute;s Miembro de
                            la
                            nacionalidad del CONTRATISTA o del que le concedi&oacute; visa de residente, a nombre de una
                            persona
                            natural y a solicitud del CONTRATANTE, que permite a su titular ingresar, transitar,
                            permanecer y salir
                            del territorio de los Pa&iacute;ses Miembros como parte de la tripulaci&oacute;n de un
                            veh&iacute;culo
                            habilitado en una operaci&oacute;n de transporte internacional de mercanc&iacute;as por
                            carretera.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">VEH&Iacute;CULO</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            el bien propulsado con o sin motor, que se destina al transporte de bienes o
                            mercanc&iacute;as,
                            entendi&eacute;ndose para los efectos del presente documento, dentro del significado de la
                            palabra
                            &ldquo;veh&iacute;culo&rdquo; y sin limitarse a: Motocarro, Carry, Kangoo, Minivan,
                            Camioneta,
                            Furg&oacute;n, cami&oacute;n, tr&aacute;iler, remolque, veh&iacute;culo botellero, tipo
                            ni&ntilde;era, cama
                            baja, tracto cami&oacute;n, unidad de carga.&nbsp;</span></p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Veh&iacute;culo
                            Habilitado</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                            el cami&oacute;n o tracto cami&oacute;n al cual el organismo nacional competente le ha
                            otorgado
                            Certificado de Habilitaci&oacute;n. (transporte internacional).</span></p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Veh&iacute;culo
                            Vinculado</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                            el cami&oacute;n o tracto cami&oacute;n y el remolque o semi remolque de propiedad de un
                            tercero que el
                            transportista autorizado incorpora a su flota, para ser utilizados en el transporte
                            internacional de
                            mercanc&iacute;as por carretera. (transporte internacional).</span></p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">UNIDAD
                            DE CARGA</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            el remolque, semi remolque, tr&aacute;iler, balanc&iacute;n o unidad de carga que hala el
                            tracto
                            cami&oacute;n, entre otros, que se destinar&aacute;n al transporte de toda clase de bienes,
                            y
                            cumplir&aacute; con la normatividad vigente para realizar operaciones de transporte.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">SINIESTRO</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            se entiende el evento por cual se presenta un da&ntilde;o faltante a las cosas o personas
                            durante la
                            operaci&oacute;n de transporte, comprendiendo las p&eacute;rdidas parciales o total de las
                            mercanc&iacute;as transportadas bajo el documento de transporte.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">APP
                            INNOVBO CONDUCTOR o HERRAMIENTA
                            TECNOL&Oacute;GICA:</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Aplicaci&oacute;n
                            m&oacute;vil que el conductor puede descargar en las tiendas de aplicaciones (sin limitarse
                            a: playstore, AppGallery, entre otras) en donde el
                            conductor
                            y/o propietario o tenedor, podr&aacute;n entre otras cosas, tomar cargas, enturnarse,
                            recibir pagos de
                            anticipos y
                            saldos, descargar remesas y manifiestos, subir cumplidos de las entregas, acceder los
                            servicios que
                            prestan nuestros aliados estrat&eacute;gicos, y sin limitarse a: Pronto pago mediante
                            operaciones
                            de
                            factoring o venta de cartera, u otro, dispersi&oacute;n de pagos (como: anticipo o saldo del
                            manifiesto de carga),
                            servicios a los veh&iacute;culos, parqueaderos, hoteles,
                            acceder a
                            efectivo en estaciones de servicio, entre otros servicios financieros, tecnol&oacute;gicos
                            y/o comerciales que prestan nuestros
                            m&uacute;ltiples aliados
                            estrat&eacute;gicos, relaciones contractuales que se entiende son independientes del objeto
                            del presente
                            contrato y que se presentan como alternativas o soluciones para el beneficio de los
                            CONTRATISTAS.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TRANSPORTE
                            NACIONAL:&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                            entiende como la operaci&oacute;n de transporte de mercanc&iacute;as que se realiza sin
                            cruzar una
                            frontera internacional.</span></p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TRANSPORTE
                            INTERNACIONAL:&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                            entiende como la operaci&oacute;n de transporte de mercanc&iacute;as que se realiza cruzando
                            una
                            frontera internacional autorizada, en la cual el personal o tripulaci&oacute;n y el
                            veh&iacute;culo de
                            transporte, deber&aacute;n estar autorizados por la autoridad de transporte y de
                            inmigraci&oacute;n para
                            cruzar frontera, portando todos los documentos que los autorizan y se debe contar con una
                            p&oacute;liza
                            andina de transporte.</span></p>
                </li>
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TRIPULACI&Oacute;N</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                            EL CONTRATISTA o las personas contratadas por el CONTRATANTE, para la conducci&oacute;n y
                            atenci&oacute;n del veh&iacute;culo habilitado, la unidad de carga y las mercanc&iacute;as
                            transportadas
                            a nivel internacional.</span></p>
                </li>
            </ol>
            <p><br></p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">SEGUNDA.
                    DECLARACIONES:</span>
            </p>
            <p><br></p>
            <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -18pt;padding-left: 3.3000000000000007pt;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DECLARA
                            EL CONTRATISTA:</span></p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.1.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            se encuentra plenamente facultado para suscribir el presente contrato
                            de&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">prestaci&oacute;n
                            de servicios de transporte</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.2.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            cuenta con el conocimiento y experticia para desarrollar el servicio objeto del
                            presente contrato.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.3.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            no es su intención suscribir un contrato de trabajo, ya que desarrolla su profesión u oficio
                            de manera liberal e independiente, pudiendo prestar sus servicios bajo la modalidad de
                            encargo a tercero, vinculación o suministro de transporte a diferentes empresas de
                            transporte de carga o generadores de carga a nivel nacional o internacional, por lo cual
                            administra su vehículo y designará a su conductor.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.4.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            suministrará como propietario o tenedor, un vehículo destinado a prestar los servicios de
                            transporte, bajo la modalidad de encargo a tercero, en los términos del artículo 984 del
                            código de comercio, el cual cuenta con todos los documentos y accesorios necesarios para la
                            prestación de los servicios, tales como y sin limitarse a: reatas, amarres, maletas,
                            canastillas, imanes, elementos de protección personal de su conductor, entre otros
                            elementos.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.5.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            conoce que su vehículo (es, será o ya se encuentra habilitado o autorizado) para realizar
                            cruce de frontera con los países miembros de la Comunidad Andina de Naciones (en adelante la
                            “CAN”) o Venezuela.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.6.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Declara
                            expresamente el CONTRATISTA en su calidad de propietario del vehículo o de la unidad de
                            carga a vincular, que conoce y acepta que el mismo será destinado al transporte
                            internacional de mercancías por carretera.</span>
                    </p>
                </li>
            </ol>
            <p><br></p>
            <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="2">
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -14.7pt;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DECLARA
                            EL CONTRATANTE:</span></p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.1.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            tiene la capacidad jurídica para obligarse con el objeto del presente contrato y que conoce
                            en su integridad la materia de este contrato, y dispondrá la información que requiera EL
                            CONTRATISTA para prestar los servicios objeto del presente contrato.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.2.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            no tiene interés en celebrar un contrato de trabajo con EL CONTRATISTA.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.3.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            realizará el pago de los documentos de transporte que se emitan (manifiesto o guia de
                            despacho urbana u otro) por los servicios efectivamente presentados por EL
                            CONTRATISTA.</span>
                    </p>
                </li>
            </ol>
            <p><br></p>
            <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="3">
                <li dir="ltr"
                    style="list-style-type:decimal;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -14.7pt;"
                    aria-level="1">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DECLARAN
                            EL CONTRATISTA y EL CONTRATANTE:</span></p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            el presente documento deja sin efecto, vigencia, anula, reemplaza y/o cancela cualquier
                            contrato anterior y/o cualquier oferta anterior a la aceptación del presente contrato entre
                            LAS PARTES. Estando ambas partes a paz y salvo por todo concepto al momento de la aceptación
                            de este contrato.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                            presente contrato se celebra intuito persona, en atención a la calidad de cada una de las
                            partes que suscriben el presente contrato. </span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                            presente contrato es independiente de cualquier otra relación contractual que pueda existir
                            en el presente o futuro entre las partes.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Hace
                            parte integral del presente contrato el anexo 1 que contiene la POLÍTICA DE TRATAMIENTO DE
                            LA INFORMACIÓN INNOVBO S.A.S. y el anexo 2 que contiene la AUTORIZACIÓN USO DATOS PERSONALES
                            CLIENTES Y USUARIOS INNOVBO S.A.S..</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Los
                            contratos que celebre el CONTRATISTA con terceros u otros proveedores y sin limitarse a los
                            servicios de: dispersión de fondos incluidos y sin limitarse a anticipos y saldos del
                            documento de transporte, factoring o compra de cartera, uso de plataformas o herramientas
                            tecnológicas, estaciones de servicio, hoteles entre otros, son relaciones contractuales
                            diferentes al presente contrato de transporte o vinculación, por lo tanto, las tarifas que
                            pague a estos terceros el CONTRATISTA no se entenderán como descuentos al documento de
                            transporte o al presente contrato, por tratarse de relaciones contractuales diferentes. Los
                            costos, honorarios, tarifas, precios, comisiones, intereses o cualquier otro valor que
                            acuerde el CONTRATISTA en otros contratos o con otras empresas, serán asumidos por el
                            CONTRATISTA.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.6.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                            CONTRATISTA entiende que el CONTRATANTE, no está obligado a entregar ninguna suma de dinero
                            o anticipo para la prestación del servicio de transporte, pudiendo el CONTRATISTA solicitar
                            un valor por concepto de anticipo al CONTRATANTE.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.7.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                            CONTRATISTA en los eventos que solicite anticipo, autoriza al CONTRATANTE se realicen los
                            pagos del presente manifiesto de carga a la empresa que elija para la dispersión de los
                            recursos económicos, en los eventos que solicite anticipo el CONTRATISTA.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.8.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                            CONTRATISTA en los eventos que negocie el documento de transporte, autoriza al CONTRATANTE a
                            realizar los pagos del mismo, a la empresa que eligió el CONTRATISTA para el servicio de
                            factoring o compra de cartera.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.9.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                            CONTRATISTA en los eventos que negocie el documento de transporte, autoriza al CONTRATANTE a
                            realizar los pagos del mismo, a la empresa que eligió el CONTRATISTA para el servicio de
                            pronto pago o uso de herramienta tecnológica.</span>
                    </p>
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.10.</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Que
                            el presente contrato se regirá por las normas civiles y comerciales aplicables a esta clase
                            de contratos.</span>
                    </p>
                </li>
            </ol>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:12pt;margin-bottom:12pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TERCERA.
                    OBJETO DEL CONTRATO: &nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bajo
                    los t&eacute;rminos y condiciones de este contrato,&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CONTRATISTA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;se
                    obliga a prestar a&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CONTRATANTE</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                    los servicios de&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">transporte
                    nacional o internacional de mercanc&iacute;as bajo la modalidad de encargo a tercero</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                    vincul&aacute;ndose con el contratante, para tal efecto administra el CONTRATISTA su
                    veh&iacute;culo, designa un
                    conductor, para desarrollar los servicios de transporte de mercanc&iacute;as que le sean encargadas
                    por el
                    CONTRATANTE.</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:12pt;margin-bottom:12pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CUARTA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">IDENTIFICACI&Oacute;N
                    DEL VEH&Iacute;CULO O UNIDAD DE CARGA A VINCULAR</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    El CONTRATISTA manifiesta que vinculara el siguiente veh&iacute;culo:</span>
            </p>
            <div dir="ltr" style="margin-left:0pt;" align="center">
                <table style="border:none;border-collapse:collapse;">
                    <tbody>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Placa:</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo.</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Pa&iacute;s:</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo.</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Marca:</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo.</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tipo
                                        de veh&iacute;culo:</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo.</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">N&uacute;mero
                                        de ejes:</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo&nbsp;</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Peso
                                        vehicular o tara:</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dimensiones
                                        Externas</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Capacidad
                                        m&aacute;xima de arrastre o carga:</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A&ntilde;o
                                        fabricaci&oacute;n:&nbsp;</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">N&uacute;mero
                                        de serie o chasis:</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Como
                                        aparece en el documento de transporte o licencia de tr&aacute;nsito del
                                        veh&iacute;culo</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:12pt;margin-bottom:12pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAR&Aacute;GRAFO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                    CONTRATISTA en su calidad de propietario del veh&iacute;culo o unidad de carga a vincular,
                    expresamente conoce y
                    acepta, para todos los efectos, lo estipulado en la Decisi&oacute;n 617 o de la norma comunitaria
                    que la
                    modifique, sustituya o complemente</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">QUINTA.
                    VIGENCIA.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                    presente contrato tendr&aacute; una duraci&oacute;n o vigencia inicial desde la fecha de la
                    aceptaci&oacute;n
                    del presente
                    contrato o a la fecha de expedici&oacute;n del documento de transporte y hasta:</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.1:
                    <span
                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
                        operaciones de transporte nacional: </span>
                    Hasta la finalizaci&oacute;n del servicio de transporte o el t&eacute;rmino
                    se&ntilde;alado para la entrega de las mercanc&iacute;as en el documento de transporte o
                    devoluci&oacute;n del contenedor vac&iacute;o, o lo que primero ocurra.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.2:
                    <span
                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
                        operaciones de transporte internacional: </span>
                    Hasta el 30 de junio del a&ntilde;o 2030 y hasta finalizar el servicio de transporte, seg&uacute;n
                    corresponda en cada caso.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAR&Aacute;GRAFO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    Despu&eacute;s de transcurrida la vigencia inicial. El presente contrato
                    podr&aacute; darse
                    por terminado en cualquier tiempo para lo cual cualquiera de las PARTES, para lo cual la parte que
                    desee la
                    terminaci&oacute;n del contrato enviar&aacute; una comunicaci&oacute;n a la otra con quince (15)
                    d&iacute;as
                    calendario de antelaci&oacute;n a la fecha en la cual desea se produzca la terminaci&oacute;n del
                    presente
                    contrato. &nbsp;EL CONTRATANTE autorizado comunicar&aacute; el retiro o desvinculaci&oacute;n del
                    veh&iacute;culos y/o unidades de carga de conformidad con el art&iacute;culo&nbsp;</span><a
                    href="https://www.cancilleria.gov.co/sites/default/files/Normograma/docs/decision_comisioncandina_dec837.htm#68"
                    style="text-decoration:none;"><span
                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">68</span></a><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;de
                    la Decisi&oacute;n 837 o conforme lo establezca la norma que la modifique o adicione.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">SEXTA.
                    PRECIO Y FORMA DE PAGO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    El precio del</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">presente
                    contrato es indeterminado y variar&aacute; dependiendo de cada ruta. EL CONTRATISTA deber&aacute;
                    presentar el
                    cumplido digital y f&iacute;sico o comprobante de entrega de las mercanc&iacute;as al CONTRATANTE
                    para efectos de pago. El valor pagado por el
                    CONTRATANTE al
                    CONTRATISTA corresponder&aacute; a la liquidaci&oacute;n del documento de transporte por el servicio
                    efectivamente prestado. Los pagos de los servicios de transporte prestados o el anticipo y/o
                    cualquier saldo del
                    documento de transporte ser&aacute; pagado dentro de los treinta (30) d&iacute;as calendario
                    siguientes a la entrega de cumplidos a satisfacci&oacute;n.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAR&Aacute;GRAFO
                    PRIMERO:&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
                    cumplido o comprobante de entrega de la mercanc&iacute;a se entender&aacute; es la factura o cuenta
                    de cobro por
                    parte del CONTRATISTA a EL CONTRATANTE. &nbsp;El precio pactado en cada documento de transporte
                    incluye el
                    veh&iacute;culo, conductor, salario y seguridad social y la prestaci&oacute;n del servicio de
                    transporte bajo la
                    modalidad de encargo a tercero (contrato de vinculaci&oacute;n), as&iacute; como todos los gastos
                    involucrados en la
                    prestaci&oacute;n del
                    servicio de transporte y la utilidad del CONTRATISTA. &nbsp;</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAR&Aacute;GRAFO
                    SEGUNDO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    A las cuentas de cobro (documentos de transporte o factura) se aplicar&aacute;n y descontar&aacute;n
                    los impuestos que ordena la ley
                    tributaria.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAR&Aacute;GRAFO
                    TERCERO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    El CONTRATISTA podr&aacute; realizar operaciones de factoring, pronto pago, vender la cartera, ceder
                    el
                    t&iacute;tulo ejecutivo y/o realizar cualquier otra negociaci&oacute;n con los documentos de
                    transporte,
                    relaciones contractuales que son independientes al presente contrato y respecto de las cuales el
                    CONTRATANTE no
                    tiene responsabilidad alguna, aun en los eventos que ofrezca la opci&oacute;n de negociar los
                    t&iacute;tulos
                    valores o documentos que presten m&eacute;rito ejecutivo con sus aliados estrat&eacute;gicos o
                    terceros que
                    elija el CONTRATISTA. As&iacute; mismo el contratista podr&aacute; realizar la dispersi&oacute;n de
                    pagos (para el anticipo o saldo del documento de transporte o factura)
                    con otras empresas para facilitar la disposici&oacute;n de los recursos. </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAR&Aacute;GRAFO
                    CUARTO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    El CONTRATISTA autoriza al CONTRATANTE a que realice pagos a los proveedores de servicios tales como
                    y sin limitarse a: Tecnolog&iacute;a,
                    dispersi&oacute;n de pagos, factoring, suministro de combustible o energ&iacute;a, hotel, entre
                    otros, que se encuentren soportados en contratos que haya celebrado el CONTRATISTA
                    e informados por escrito durante la prestaci&oacute;n del servicio objeto del presente al
                    CONTRATANTE,
                    siendo facultativo para el CONTRATANTE aceptar o no el pago a tales servicios prestados por terceros
                    al CONTRATISTA
                    de saldos pendientes del documento de transporte o de su respectiva factura.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAR&Aacute;GRAFO
                    QUINTO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    El CONTRATISTA autoriza se realicen los descuentos por faltantes o da&ntilde;os que haya causado a
                    las mercanc&iacute;as transportadas, de los dineros que le adeude el CONTRATANTE, lo anterior a la
                    luz del art&iacute;culo 1714 del C&oacute;digo Civil Colombiano.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">S&Eacute;PTIMA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">OPERACI&Oacute;N
                    DE TRANSPORTE CON RECAUDO DE DINERO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    Cuando en desarrollo de la operaci&oacute;n de encargo a tercero el CONTRATISTA recaude dinero de
                    los clientes
                    de la empresa por parte del destinatario final de las mercanc&iacute;as (tales como y sin limitarse
                    a cerveza,
                    gaseosas, leche, alimentos, abarrotes, ropa, juguetes, repuestos, paquetes peque&ntilde;os u otros
                    elementos o
                    mercanc&iacute;as) o de manos de tenderos (seg&uacute;n sea corresponda), los dineros que reciba el
                    CONTRATISTA
                    deber&aacute;n ser consignados cada vez que re&uacute;na la suma de QUINIENTOS MIL PESOS (Cop
                    $500.000) a la cuenta de ahorros o
                    corriente que le indique el CONTRATANTE y entregar&aacute; los soportes de las transferencias o
                    consignaciones bancarias, durante
                    la operaci&oacute;n de transporte o el mismo d&iacute;a de la entrega de las mercanc&iacute;as. El
                    CONTRATISTA autoriza que a las cuentas de cobro
                    y/o documento de transporte o facturas, se descuente los dineros que no entregue EL CONTRATISTA en
                    la fecha que le indique EL CONTRATANTE.
                    Asi mismo, ser&aacute; responsable el CONTRATISTA del pago de los costos administrativos que se
                    causen por su incumplimiento tales como y
                    sin limitarse a: cobro por env&iacute;o de escolta, motorizado a recoger dineros, honorarios de
                    abogados
                    y dem&aacute;s que se causen por incumplimiento del CONTRATISTA.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">OCTAVA.
                    NO DEVOLUCI&Oacute;N DE ANTICIPOS, PAGOS EN EXCESO O OPERACIONES NO EJECUTADAS</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    Cuando el CONTRATISTA no realice la devoluci&oacute;n de dineros entregados por concepto de anticipo
                    de
                    operaciones de transporte no ejecutadas o ejecutadas parcialmente, realizar&aacute; la
                    devoluci&oacute;n inmediata del
                    dinero al CONTRATANTE proporcionalmente al transporte efectivamente realizado por el CONTRATISTA.
                    Tambi&eacute;n
                    devolver&aacute; de manera inmediata el dinero el CONTRATISTA cuando no se presente a ejecutar la
                    operaci&oacute;n de
                    transporte que
                    se le haya encargado. &nbsp;El CONTRATISTA autoriza que a las cuentas de cobro y/o documento de
                    transporte o facturas, se
                    descuente los dineros que no entregue EL CONTRATISTA en la fecha que le indique EL
                    CONTRATANTE.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">NOVENA.
                    MULTAS POR DEFICIENTE EJECUCI&Oacute;N DEL CONTRATISTA:&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cuando
                    el CONTRATISTA incumpla las siguientes obligaciones durante la ejecuci&oacute;n de la
                    operaci&oacute;n de
                    transporte que le fue encargada, pagar&aacute; a favor del contratante y autoriza le descuenten las
                    siguientes
                    sumas de dinero, por concepto de:</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.1
                    Demoras en cumplidos</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    Se aplicar&aacute; multa al CONTRATISTA por valor de veinte d&oacute;lares (20 USD) o su equivalente
                    en pesos colombianos, por incumplimiento de&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CUMPLIDOS,
                    PRUEBAS DE ENTREGA o POD,&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f&iacute;sicos
                    o digitales de las pruebas de entrega, seg&uacute;n los siguientes tiempos:</span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                    Cumplido Digital:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Enviar
                    los cumplidos completos digitales por la APP o por el chat de WhatsApp o telegram de soporte
                    m&aacute;ximo 24
                    horas despu&eacute;s del descargue.</span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                    Cumplido F&iacute;sico:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Enviar
                    los cumplidos completos f&iacute;sicos a alguna de las oficinas de INNOVBO a nivel nacional,
                    m&aacute;ximo 48
                    horas despu&eacute;s del descargue.&nbsp;</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.2
                    No reporte en puestos de control o no atender llamados o instrucciones del &aacute;rea de Seguridad
                    de
                    INNOVBO S.A.S..&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Se
                    aplicar&aacute; multa al CONTRATISTA por valor de ochenta d&oacute;lares (80 USD) o su equivalente
                    en pesos colombianos, cuando sin ninguna justificaci&oacute;n el
                    contratista no realice
                    el reporte en los puestos de control f&iacute;sicos o virtuales durante la operaci&oacute;n de
                    transporte de
                    mercanc&iacute;as que se le encarg&oacute; su conducci&oacute;n. &nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.3
                    Multa por el incumplimiento a las llegadas de cargues y descargues</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    Se aplicar&aacute; multa al CONTRATISTA por valor de cincuenta d&oacute;lares (50 USD) o su
                    equivalente en pesos colombianos, las cu&aacute;les aplicar&aacute;n&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cuando
                    el contratista no se presente en la fecha y hora de cargue y/o descargue.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.4
                    Multa por llevar acompa&ntilde;antes no autorizados en la cabina.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CONTRATISTA que incumpla la prohibici&oacute;n de transportar personas no autorizadas en la cabina
                    del
                    veh&iacute;culo de carga, autoriza expresamente al CONTRATANTE a que se aplique una multa del saldo
                    de su
                    documento de transporte por la suma de ochenta d&oacute;lares (80 USD) o
                    su equivalente en pesos colombianos, como sanci&oacute;n de incumplimiento ya que pone en riesgo la
                    vida de
                    otras personas.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.5
                    Multa por incumplimiento en carga refrigerada.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CONTRATISTA que incumpla las instrucciones para el manejo de carga refrigerada,
                    autoriza expresamente al CONTRATANTE a que se aplique una multa del saldo de su documento de
                    transporte o factura,
                    por la suma de ochenta d&oacute;lares (80 USD) o su equivalente en pesos colombianos,
                    como sanci&oacute;n de incumplimiento, quedando obligado a pagar adicionalmente los da&ntilde;os que
                    cause
                    a las mercanc&iacute;as
                    por su incumplimiento.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.6
                    Multa por incumplimiento en carga peligrosa.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CONTRATISTA que incumpla las instrucciones para el manejo de carga peligrosa o
                    no porte lo elementos de seguridad para este tipo de mercanc&iacute;as en el veh&iacute;culo,
                    autoriza expresamente al CONTRATANTE
                    a que se aplique una multa del saldo de su documento de transporte o factura, por la suma de ochenta
                    d&oacute;lares (80 USD)
                    o su equivalente en pesos colombianos, como sanci&oacute;n de incumplimiento, quedando obligado a
                    pagar adicionalmente los da&ntilde;os que cause
                    a las mercanc&iacute;as o a terceros por su incumplimiento</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.7
                    Multa por no entregar a tiempo y en lugar las devoluciones de mercanc&iacute;a.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CONTRATISTA que incumpla las instrucciones para el manejo de devoluciones
                    y log&iacute;stica inversa, autoriza expresamente al CONTRATANTE a que se aplique una multa del
                    saldo de su documento de
                    transporte o factura, por la suma de ochenta d&oacute;lares (80 USD) o su equivalente en pesos
                    colombianos,
                    como sanci&oacute;n de incumplimiento.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">AUTORIZACI&Oacute;N
                    DE DESCUENTO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    El CONTRATISTA autoriza expresamente al CONTRATANTE &nbsp;que de las cuentas de cobro y/o documento
                    de
                    transporte o de los saldos que se le adeuden al CONTRATISTA, se le descuenten los dineros que no
                    entregue en la
                    fecha que le indique EL CONTRATANTE por &nbsp;concepto de:&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i)</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">la
                    operaci&oacute;n de recaudo de dinero de trata la</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cl&aacute;usula
                    quinta</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ii)</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;l</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                    no devoluci&oacute;n de anticipos pagados en exceso o por operaciones no ejecutadas de que trata
                    la</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cl&aacute;usula
                    sexta</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">iii)</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;las
                    multas de que trata la&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">cl&aacute;usula
                    s&eacute;ptima</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">iv)&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Descuento
                    por la activaci&oacute;n del protocolo de seguridad, precintos electr&oacute;nicos y escolta entre
                    otros costos
                    que se generen por causa imputable al CONTRATISTA o su no comunicaci&oacute;n con el departamento de
                    tr&aacute;fico o seguridad del CONTRATANTE</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    v)</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;la
                    penalidad de que trata la cl&aacute;usula d&eacute;cima cuarta, y cuando se incurra en cualquiera de
                    las
                    siguientes conductas:</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.1</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Incumplimiento
                    a protocolo, tarjeta / hoja de ruta / plan de ruta.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cuando
                    el CONTRATISTA cambie de ruta, no responda en un lapso de una hora (1) llamadas o mensajes
                    v&iacute;a WhatsApp
                    del departamento de seguridad o tr&aacute;fico del CONTRATANTE, o cuando incumpla el protocolo de
                    seguridad
                    contenido en la tarjeta / plan de ruta, o cuando no espere al escolta &nbsp;que se le haya asignado
                    o no permita
                    la compa&ntilde;&iacute;a del escolta que le haya sido asignado y esto ocasione el env&iacute;o de
                    escoltas sin
                    justificaci&oacute;n para ello, EL CONTRATISTA pagar&aacute; a el contratante los gastos de escolta
                    &nbsp;en que
                    haya incurrido el CONTRATANTE. El CONTRATISTA autoriza que a las cuentas de cobro y/o documento de
                    transporte
                    que se le adeuden, se descuente los dineros por gastos de escolta en que haya incurrido el
                    CONTRATANTE.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.2</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Moras
                    devoluci&oacute;n contenedor.</span><span
                    style="font-size:11pt;font-family:Arial;color:#ff0000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Compromiso
                    de fecha para devoluci&oacute;n de contenedor vac&iacute;o: Por incumplimiento, se cobrar&aacute;
                    adicionalmente, por d&iacute;a de demora, &nbsp;la suma de ciento cincuenta d&oacute;lares (150 USD)
                    o su equivalente en pesos Colombianos o los valores que cobre la
                    naviera.</span>
            </p>
            <p><br></p>
            <div dir="ltr" style="margin-left:0pt;" align="left">
                <table mat-table class="table" *ngIf="dataSource" [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="origin">
                        <th mat-header-cell *matHeaderCellDef> ORIGEN </th>
                        <td mat-cell *matCellDef="let element"> {{element?.origin}} </td>
                    </ng-container>

                    <ng-container matColumnDef="destino">
                        <th mat-header-cell *matHeaderCellDef> DESTINO </th>
                        <td mat-cell *matCellDef="let element"> {{element?.destino}} </td>
                    </ng-container>


                    <ng-container matColumnDef="modalidad">
                        <th mat-header-cell *matHeaderCellDef> MODALIDAD </th>
                        <td mat-cell *matCellDef="let element"> {{element?.modalidad}} </td>
                    </ng-container>


                    <ng-container matColumnDef="dias">
                        <th mat-header-cell *matHeaderCellDef> D&Iacute;AS </th>
                        <td mat-cell *matCellDef="let element"> {{element?.dias}} </td>
                    </ng-container>

                    <ng-container matColumnDef="table-footer">
                        <td mat-footer-cell *matFooterCellDef colspan="4" [style.text-align]="'center'"
                            style="font-size:11pt;color:#212529;background-color:#ffffff;font-weight:500;font-style:normal;font-variant:normal;vertical-align:baseline;text-align:center;white-space:pre;white-space:pre-wrap;padding:10pt;">
                            *Los d&iacute;as empiezan a contar el d&iacute;a siguiente al descargue y se tiene en cuenta
                            d&iacute;as
                            calendario, solo que si el &uacute;ltimo d&iacute;a para la entrega es un d&iacute;a NO
                            h&aacute;bil (Domingo o
                            festivo), se corre la fecha de devoluci&oacute;n un d&iacute;a m&aacute;s
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="['table-footer']"></tr>
                </table>
            </div>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.3</span><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aver&iacute;as
                    o da&ntilde;os
                    a la carga o faltantes en la entrega.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cuando
                    se presenten aver&iacute;as, da&ntilde;os o faltantes, el CONTRATISTA autoriza se descuente los
                    da&ntilde;os a la
                    carga, de
                    las sumas que le adeude el CONTRATANTE, &nbsp;seg&uacute;n los siguientes par&aacute;metros:</span>
            </p>
            <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                <li dir="ltr"
                    style="list-style-type:upper-alpha;font-size:11pt;font-family:Arial;color:#212529;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aver&iacute;as
                            o faltantes menores:&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Todas
                            aquellas aver&iacute;as o faltantes que tengan un valor&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">igual
                            o inferior&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                            la suma de cuatro mil (4.000 USD)&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d&oacute;lares
                            o su equivalente en pesos colombianos, sumas
                            que</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;ser&aacute;n
                            descontadas al cien por ciento (100%) al CONTRATISTA, que corresponde al diez por ciento
                            (10%) del valor del siniestro o p&eacute;rdida.</span>
                    </p>
                </li>
                <li dir="ltr"
                    style="list-style-type:upper-alpha;font-size:11pt;font-family:Arial;color:#212529;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                    aria-level="1">
                    <p dir="ltr"
                        style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                        <span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aver&iacute;as
                            o faltantes mayores:&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Todas
                            aquellas aver&iacute;as o faltantes que tengan un valor&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">superior&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a
                            la suma&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">de
                            trescientos (USD 300)&nbsp;</span><span
                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d&oacute;lares
                            de estados unidos de norte Am&eacute;rica o su equivalente en pesos colombianos</span><span
                            style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                            EL CONTRATANTE lo reclamar&aacute; a su p&oacute;liza de mercanc&iacute;as y ser&aacute;
                            descontado al
                            CONTRATISTA el cien por ciento (100%) del deducible de la p&oacute;liza.&nbsp;</span>
                    </p>
                </li>
            </ol>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    PRIMERA. OBLIGACIONES DEL CONTRATISTA:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;En
                    desarrollo del presente contrato el CONTRATISTA realizar&aacute; el transporte de las
                    mercanc&iacute;as que le
                    encomiende el CONTRATANTE y relacionadas en el documento de TRANSPORTE de acuerdo con lo establecido
                    en el
                    C&oacute;digo de Comercio y atendiendo las siguientes obligaciones:</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.1
                    Deber&aacute; recoger y entregar la carga o mercanc&iacute;as en los lugares que se&ntilde;ale EL
                    CONTRATANTE en
                    el documento de transporte, en cuyo caso las partes se ajustar&aacute;n a los t&eacute;rminos y
                    condiciones que
                    sobre el particular se acuerden y que cumplan las disposiciones legales, reglamentarias y/o
                    normativas.</span>
            </p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.2
                    Realizar el transporte de mercanc&iacute;as que le fueron encargadas, en un veh&iacute;culo de
                    servicio
                    p&uacute;blico destinado al transporte de mercanc&iacute;as, el cual poseer&aacute; a t&iacute;tulo
                    de
                    propiedad, tenedor o bajo cualquier otro t&iacute;tulo, siendo el &uacute;nico responsable del
                    mantenimiento
                    mec&aacute;nico y de su buen funcionamiento. De no conducir el propietario su veh&iacute;culo,
                    designar&aacute;
                    el CONTRATISTA a su conductor.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.3
                    El CONTRATISTA verificar&aacute; el estado en el cual recibe la mercanc&iacute;a, realizar&aacute;
                    las
                    anotaciones en el documento de transporte, inspeccionar&aacute; que la mercanc&iacute;a est&aacute;
                    bien cargada
                    y asegurada (amarrada) al interior del veh&iacute;culo, para que no se mueva o da&ntilde;e durante
                    el
                    transporte.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.4
                    Realizar&aacute; los pagos mensuales correspondientes a su seguridad social o del conductor
                    designado por el
                    CONTRATISTA al veh&iacute;culo, en el evento que su propietario no sea el mismo conductor.</span>
            </p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.5
                    Conseguir, tramitar, presentar, mantener vigentes y tener al d&iacute;a su c&aacute;mara de
                    comercio, RUT, RIT,
                    certificaci&oacute;n bancaria, afiliaci&oacute;n y pago al sistema de seguridad social y
                    dem&aacute;s que le
                    apliquen, seg&uacute;n sea el caso.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.6
                    Presentar f&iacute;sicamente en m&aacute;ximo 48 horas despu&eacute;s del descargue (en algunas de
                    las oficinas
                    de INNOVBO a nivel nacional) y electr&oacute;nicamente en m&aacute;ximo 24 horas despu&eacute;s del
                    descargue
                    (por medio de la app INNOVBO Conductor) el documento de transporte y dem&aacute;s remisiones con el
                    recibido a
                    satisfacci&oacute;n del cliente, documento que constituir&aacute; la cuenta de cobro o factura del
                    servicio de
                    transporte prestado.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.7
                    Realizar las anotaciones en el documento de transporte o inventario correspondiente, respecto del
                    estado en el
                    cual recibe o entrega las mercanc&iacute;as el contratista.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.8
                    Deber&aacute; regresar el contenedor vac&iacute;o en los t&eacute;rminos se&ntilde;alados en el
                    documento de
                    transporte.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.9
                    Informar al &aacute;rea de tr&aacute;fico de la Empresa, cuando el escolta NO acompa&ntilde;e y
                    custodie la
                    mercanc&iacute;a. Los costos por asignaci&oacute;n de escolta nuevo por NO cumplir la caravana
                    ser&aacute;n
                    asumidos por el CONTRATISTA.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.10
                    Debe cumplir con el Programa de prevenci&oacute;n alcohol, drogas, tabaquismo y juego
                    patol&oacute;gico
                    (ludopat&iacute;a).</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.11
                    Pernoctar durante el tr&aacute;nsito en los lugares establecidos y autorizados por el &aacute;rea de
                    tr&aacute;fico de la empresa.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.12
                    Una vez instalados y entregados en buen estado los precintos de seguridad es responsabilidad del
                    CONTRATISTA la
                    custodia y adecuado control de los mismos por lo cual; Antes de iniciar ruta, en tr&aacute;nsito, en
                    los puestos
                    de control y antes de entregar las mercanc&iacute;as en destino, debe realizar inspecci&oacute;n de
                    seguridad a
                    la mercanc&iacute;a y veh&iacute;culo, verificando el estado de los sellos y precintos instalados,
                    si detecta
                    alguna novedad debe informar inmediatamente al departamento de tr&aacute;fico del CONTRATANTE,
                    quienes
                    tomar&aacute;n la acci&oacute;n que haya a lugar en pro de salvaguardar la integridad de la
                    mercanc&iacute;a que
                    se transporta.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.13
                    De presentarse alteraciones o alg&uacute;n tipo de anomal&iacute;a en los precintos de seguridad,
                    ello debe ser
                    informado de inmediato al &aacute;rea de control tr&aacute;fico por parte del CONTRATISTA, la
                    omisi&oacute;n de
                    la informaci&oacute;n lo hace directamente responsable de los da&ntilde;os, contaminaci&oacute;n o
                    afectaci&oacute;n que presente la carga seg&uacute;n lo estipule la legislaci&oacute;n
                    colombiana.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.14
                    Los rompimientos de sellos y precintos de seguridad realizados por las autoridades deben ser
                    informados de
                    inmediato al &aacute;rea de tr&aacute;fico-seguridad y solicitar a la autoridad (polic&iacute;a o
                    autoridad
                    aduanera en cada pa&iacute;s miembro de la CAN, entre otras) el precintado y generaci&oacute;n de un
                    acta de
                    apertura y cierre, relacionando el nuevo precinto que le ser&aacute; suministrado de la regional
                    m&aacute;s
                    cercana al punto donde se desarrolla la inspecci&oacute;n.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.15
                    Las novedades informadas por el cliente en destino, como faltantes y da&ntilde;os de
                    mercanc&iacute;a, deben ser
                    informadas de inmediato al &aacute;rea de tr&aacute;fico de la empresa, dej&aacute;ndola como
                    registro en el
                    rev&eacute;s de la remesa o en documento anexo.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.16
                    El CONTRATISTA designar&aacute; los conductores de sus veh&iacute;culos, NO debe realizar cambio de
                    conductor,
                    cabezote o veh&iacute;culo si no ha sido autorizado por EL CONTRATANTE previamente la despacho o
                    inicio del
                    viaje, cualquier cambio que se realice debe ser confirmado con el &aacute;rea de control
                    tr&aacute;fico.</span>
            </p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.17
                    CONFIDENCIALIDAD:&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Mantener
                    la informaci&oacute;n de cada despacho como confidencial: &nbsp;Ruta, producto, valor de la
                    mercanc&iacute;a y
                    dem&aacute;s informaci&oacute;n en estricta y total reserva y no revelar ning&uacute;n dato de los
                    despachos y
                    las operaciones a ninguna otra parte, relacionada o no con el servicio, sin el consentimiento previo
                    escrito del
                    &aacute;rea de tr&aacute;fico. El incumplimiento de esta cl&aacute;usula tendr&aacute; una penalidad
                    de mil
                    d&oacute;lares (USD 1.000) de Estados Unidos de Norte America o su equivalente en pesos
                    colombianos.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.18
                    Se podr&aacute; asignar un GPS m&oacute;vil y/o Barra en buenas condiciones f&iacute;sicas y de
                    funcionamiento,
                    el cual debe ser entregado en las mismas condiciones en la oficina de destino. En caso de
                    presentarse
                    da&ntilde;o o aver&iacute;a en estas unidades durante el trayecto ser&aacute; responsabilidad
                    directa del
                    CONTRATISTA, por lo cual se le aplicar&aacute; el descuento que genere la reparaci&oacute;n o
                    restauraci&oacute;n del mismo.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.19
                    El CONTRATISTA acepta que en el desarrollo del presente contrato podr&aacute; ser objeto de
                    auditor&iacute;as
                    y/o pruebas f&iacute;sicas enfocadas a la protecci&oacute;n del mismo y control de la carga
                    transportada a fin
                    de salvaguardar su integridad.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.20
                    ANTE ACCIDENTES, ROBOS O NOVEDADES:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;En
                    caso de presentarse la p&eacute;rdida de las mercanc&iacute;as objeto de transporte, accidente de
                    tr&aacute;nsito, hurto o cualquier otra circunstancia, se presentar&aacute; a dar versi&oacute;n de
                    los hechos
                    en las oficinas del CONTRATANTE, informando las circunstancias de tiempo, modo y lugar de ocurrencia
                    de los
                    hechos, as&iacute; mismo aportar&aacute; los informes de polic&iacute;a, denuncias,
                    poligraf&iacute;as,
                    ex&aacute;menes de toxicolog&iacute;a o cualquier otro documento o requerimiento que le sea
                    solicitado para
                    soportar la reclamaci&oacute;n a la compa&ntilde;&iacute;a de seguros y/o al cliente generador de
                    carga y/o
                    dentro de los procesos de la compa&ntilde;&iacute;a de transporte y se obliga a ampliar la denuncia
                    ante la
                    fiscal&iacute;a o autoridades judiciales que se lo requiera. &nbsp;As&iacute; mismo, debe colaborar
                    con la
                    investigaci&oacute;n, aceptar el acompa&ntilde;amiento de las autoridades y/o de la Gerencia de
                    seguridad de la
                    empresa de transporte. En caso de incumplimiento de esta cl&aacute;usula se aplicar&aacute; un
                    sanci&oacute;n
                    especial y espec&iacute;fica de mil d&oacute;lares de Estados Unidos de Norte America (USD 1.000) o
                    su
                    equivalente en pesos colombianos.&nbsp;</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.21
                    EL CONTRATISTA durante la prestaci&oacute;n del servicio de transporte de mercanc&iacute;as, NO
                    llevar&aacute;,
                    ni transportar&aacute; personas o acompa&ntilde;antes o familiares en cabina durante todo el
                    trayecto o viaje
                    contratado, dado que se trata de una actividad peligrosa y que los veh&iacute;culos no est&aacute;n
                    destinados
                    al turismo si no al transporte de mercanc&iacute;as.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.22
                    El contratante autoriza que le sea descontado el valor de los da&ntilde;os, faltantes o siniestros
                    que se
                    presenten a las mercanc&iacute;as transportadas, lo anterior de conformidad con el art&iacute;culo
                    991 del
                    C&oacute;digo de Comercio de Colombia. &nbsp;En los eventos que por el valor del da&ntilde;o se
                    afecte la
                    p&oacute;liza de seguro, asumir&aacute; el cien por ciento (100%) del deducible aplicado por la
                    p&oacute;liza de
                    seguro.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.23
                    El CONTRATISTA no transportar&aacute; mercanc&iacute;as que puedan contaminar las mercanc&iacute;as
                    que le
                    encarg&oacute; para su conducci&oacute;n el CONTRATANTE, as&iacute; mismo le queda prohibido
                    transportar
                    mercanc&iacute;as il&iacute;citas en los t&eacute;rminos de la legislaci&oacute;n penal colombiana
                    quedando
                    prohibido realizar o facilitar a un tercero la comisi&oacute;n de alg&uacute;n il&iacute;cito
                    durante la
                    ejecuci&oacute;n del presente contrato.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.24
                    De acuerdo con lo anterior certifico que he le&iacute;do y entendido las directrices de seguridad
                    BASC del
                    CONTRATANTE, las cuales hacen parte integral del presente contrato, los acuerdos y responsabilidades
                    de
                    seguridad los cuales acept&oacute; al aceptar el presente documento, comprometi&eacute;ndose a velar
                    por la
                    custodia e integridad de la carga que me ha sido asignada, es decir que autorizo expresamente a
                    INNOVBO
                    SAS &nbsp;a la aplicaci&oacute;n de los descuentos que se generen por el incumplimiento de alguna de
                    las
                    condiciones relacionadas toda vez que me fueron dadas a conocer antes y durante el proceso de
                    contrataci&oacute;n e inicio del plan de viaje.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.25
                    El CONTRATISTA informar&aacute; cualquier cambio de su direcci&oacute;n de domicilio o residencia,
                    n&uacute;mero
                    de tel&eacute;fono o correo electr&oacute;nico y/o cualquier otra informaci&oacute;n de
                    contacto.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.26
                    Manifiesta EL CONTRATISTA que expresamente&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">SI</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;acepta
                    ser notificado personalmente a las direcciones f&iacute;sicas&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">o</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;electr&oacute;nicas
                    que report&oacute; al CONTRATANTE en los t&eacute;rminos del c&oacute;digo general del
                    proceso.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.27
                    Manifiesta EL CONTRATISTA, que autoriza a EL CONTRATANTE y sus proveedores a realizar la consulta y
                    el reporte
                    ante las agremiaciones y centrales de riesgo por el incumplimiento al presente contrato de
                    transporte de encargo
                    a terceros.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.28&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cuando
                    realice transporte internacional de mercanc&iacute;as</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                    entiende y autoriza que el veh&iacute;culo cruzara una Frontera Internacional, en el desarrollo de
                    las
                    operaciones de transporte internacional de mercanc&iacute;as desde o hacia los pa&iacute;ses
                    miembros de la
                    Comunidad Andina de Naciones (CAN) o Venezuela.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.29
                    Cuando realice transporte internacional de mercanc&iacute;as, se obliga a mantener las
                    autorizaciones
                    pertinentes para que el veh&iacute;culo realice cruce de una frontera internacional cumpliendo las
                    normas de la
                    (CAN) o Venezuela.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.30
                    Cuando realice transporte internacional de mercanc&iacute;as, mantendr&aacute; los documentos de la
                    tripulaci&oacute;n vigentes.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.31
                    Cuando realice transporte internacional de mercanc&iacute;as respetara las normas de
                    tr&aacute;nsito.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.32
                    Cuando realice transporte internacional de mercanc&iacute;as, conservar&aacute; y cuidar&aacute; el
                    documento
                    que ampare el transporte internacional de mercanc&iacute;as (manifiesto de carga internacional,
                    declaraci&oacute;n de tr&aacute;nsito aduanero internacional, carta de porte internacional u otro
                    documento de
                    similar naturaleza) realizara la operaci&oacute;n de transporte, atendiendo las normas de los
                    pa&iacute;ses
                    miembro la comunidad andina de naciones o Venezuela.&nbsp;</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.33
                    Cuando termine la operaci&oacute;n de transporte internacional de mercanc&iacute;as, deber&aacute;
                    salir del
                    pa&iacute;s miembros de la Comunidad Andina de Naciones (CAN) o Venezuela, salvo que el
                    veh&iacute;culo
                    est&eacute; registrado o matriculado o la placa del veh&iacute;culo sea del pa&iacute;s en el cual
                    concluy&oacute; la operaci&oacute;n de transporte internacional de mercanc&iacute;as.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.34
                    Cuando realice transporte internacional de mercanc&iacute;as y una vez entregada la
                    mercanc&iacute;a, NO
                    realizar&aacute; transporte (interno) de mercanc&iacute;as dentro de los pa&iacute;ses los
                    pa&iacute;ses
                    miembros de la Comunidad Andina de Naciones (CAN) o Venezuela.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.35
                    No solicitar&aacute; carga para realizar o ejecutar operaciones de transporte nacional de
                    mercanc&iacute;as,
                    dentro de los territorios de los pa&iacute;ses miembros de la Comunidad Andina de Naciones (CAN) o
                    Venezuela,
                    las multas o sanciones por incumplimiento de esta prohibici&oacute;n, ser&aacute; de cargo del
                    propietario o
                    conductor del veh&iacute;culo.</span></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.36
                    Cuando realice transporte internacional de mercanc&iacute;as mantendr&aacute; vigentes las
                    p&oacute;lizas de
                    responsabilidad civil o que ampare a terceros por accidentes de tr&aacute;nsito.</span></p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    SEGUNDA. OBLIGACIONES DEL CONTRATANTE:&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
                    el debido cumplimiento del objeto, materia de este contrato, el CONTRATANTE</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">se
                    obliga a:</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.1
                    A pagar el precio acordado por cada servicio de transporte de mercanc&iacute;as en la forma y
                    t&eacute;rminos
                    acordados en el presente contrato.</span></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.2
                    EL CONTRATANTE previo a cada despacho brindar&aacute; al CONTRATISTA la informaci&oacute;n que se
                    requiera para
                    la correcta manipulaci&oacute;n y conducci&oacute;n de las mercanc&iacute;as como sus
                    caracter&iacute;sticas y/o
                    la especie de las mercanc&iacute;as que deban ser transportadas y que cumplan las disposiciones
                    legales,
                    reglamentarias y / o normativas.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    TERCERA. SEGUROS</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">:
                    EL CONTRATISTA se obliga a mantener vigente: 1) el seguro obligatorio de accidentes de
                    tr&aacute;nsito (SOAT),
                    2) tomar&aacute; una p&oacute;liza de responsabilidad civil extracontractual. PAR&Aacute;GRAFO. EL
                    CONTRATANTE
                    podr&aacute; vender las anteriores p&oacute;lizas al CONTRATISTA y el valor de las mismas
                    ser&aacute; compensado
                    del saldo del precio contratado por expresa autorizaci&oacute;n del CONTRATISTA, aclarando que dicho
                    descuento
                    obedece &uacute;nicamente a la venta de los seguros mencionados y no constituye un descuento no
                    autorizado al
                    Valor a Pagar del flete, sino que corresponde a la venta de otro servicio.&nbsp;</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    CUARTA. RESPONSABILIDAD CIVIL</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    - EL CONTRATISTA responder&aacute; frente a terceros por todos los da&ntilde;os y perjuicios que
                    sean
                    ocasionados por los veh&iacute;culos o personal del CONTRATISTA, si cuenta con p&oacute;lizas de
                    seguro estas
                    podr&aacute;n atender las reclamaciones y en los valores que no sean cubiertos por dichas
                    p&oacute;lizas
                    ser&aacute;n atendidas directamente las indemnizaciones por cuenta del CONTRATISTA.&nbsp;</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    QUINTA. INDEMNIDAD.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;EL
                    CONTRATISTA mantendr&aacute; indemne al CONTRATANTE de todo reclamo, demanda o acci&oacute;n legal
                    que pueda
                    causar o que surja del incumplimiento de las obligaciones derivadas del presente contrato a cargo
                    del
                    CONTRATISTA.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    SEXTA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TRAZABILIDAD
                    DE LA CARGA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                    CONTRATISTA autoriza expresamente a&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INNOVBO
                    S.A.S.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,
                    a cualquiera de sus fi</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">liales
                    y a cualquiera de sus colaboradores, clientes o a las autoridades, a acceder, procesar y almacenar
                    la
                    informaci&oacute;n de ubicaci&oacute;n y cualquier informaci&oacute;n de telemetr&iacute;a o de
                    cualquier otra
                    naturaleza proporcionada por el sistema de posicionamiento global (GPS) de su dispositivo
                    m&oacute;vil,
                    as&iacute; como el sistema de posicionamiento global (GPS) de su veh&iacute;culo y tr&aacute;iler
                    (cuando
                    aplique), con el fin de realizar la trazabilidad de los servicios de transporte prestados por el
                    usuario o para
                    ofrecer servicios para el transporte de mercanc&iacute;as con empresas habilitadas. Durante el
                    desarrollo de la
                    operaci&oacute;n de transporte y hasta que esta no se termine, no podr&aacute; el contratista
                    (conductor o
                    propietario del veh&iacute;culo) cambiar el usuario o la clave de acceso al GPS.&quot;</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    S&Eacute;PTIMA: STAND BY O TIEMPOS DE ESPERA.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                    tiempos de cargue o descargue no podr&aacute;n ser superiores a doce (12) horas h&aacute;biles
                    siguientes al arribo del veh&iacute;culo al lugar de origen o destino, seg&uacute;n corresponda,
                    indicado en el manifiesto electr&oacute;nico de carga.
                    A partir de la hora trece (13) el valor de la hora de tiempo de Stand by, se calcular&aacute;
                    &uacute;nicamente por los d&iacute;as h&aacute;biles,
                    atendiendo jornada laboral establecida en el art&iacute;culo 161 C&oacute;digo Sustantivo del
                    Trabajo,
                    dividida en seis (6) d&iacute;as a la semana, considerando el tipo de veh&iacute;culo,
                    asi:&nbsp;</span>
            </p>
            <p><br></p>
            <div dir="ltr" style="margin-left:0pt;" align="center">
                <table style="border:none;border-collapse:collapse;">
                    <tbody>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tipo
                                        VH</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Turbo
                                        5 Ton</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sencillo
                                        10 Ton</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Minimula
                                        (2S2): 20 Ton</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tracto
                                        Mula (2S3): 35 Tons</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cama
                                        baja / Cama cuna</span>
                                </p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Valor
                                        por Hora</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">0.35
                                        SMLDV</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">0.80
                                        SMLDV</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.2
                                        SMLDV</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.5
                                        SMLDV</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.8
                                        SMLDV</span></p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Valor
                                        dia
                                        (8 horas)
                                    </span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">0.35
                                        * SMLDV* 8</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">0.80
                                        * SMLDV* 8</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.2
                                        * SMLDV* 8</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.5
                                        * SMLDV* 8</span></p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.8
                                        * SMLDV* 8</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">SMLDV
                    = Salario M&iacute;nimo Legal Diario Vigente.
                    <br>
                    0.35 = Corresponde al factor de multiplicaci&oacute;n del SMLDV, para establecer el valor de la
                    hora.</span>
            </p>
            <p><br></p>
            <div dir="ltr" style="margin-left:0pt;" align="center">
                <table style="border:none;border-collapse:collapse;">
                    <tbody>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tipo
                                        VH - Valores 2023</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Turbo
                                        5 Ton</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sencillo
                                        10 Ton</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Minimula
                                        (2S2): 20 Ton</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tracto
                                        Mula (2S3): 35 Tons</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cama
                                        baja / Cama cuna</span>
                                </p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Valor
                                        por Hora</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.600</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">31.000</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">46.400</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">58.000</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">69.600</span>
                                </p>
                            </td>
                        </tr>
                        <tr style="height:0pt;">
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Valor
                                        Dia
                                    </span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">108.800</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">248.000</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">371.200</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">464.000</span>
                                </p>
                            </td>
                            <td
                                style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                                <p dir="ltr"
                                    style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                                        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">556.800</span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAR&Aacute;GRAFO:</span><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;si
                    un cargue o descargue se realiza en d&iacute;a s&aacute;bado,
                    las horas de Stand by, se calcular&aacute;n hasta el mediod&iacute;a (12:00 m) y continuar&aacute;
                    su c&aacute;lculo desde las 8:00 a.m. del d&iacute;a lunes
                    o el d&iacute;a h&aacute;bil siguiente para el caso de los d&iacute;as festivos.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    OCTAVA. AUTORIZACI&Oacute;N DE USO DE DATOS PERSONALES</span><span
                    style="font-size:11pt;font-family:Arial;color:#212529;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                    CONTRATISTA de manera libre,
                    expresa, voluntaria e informada, AUTORIZO a INNOVBO S.A.S. y/o a la persona natural o
                    jur&iacute;dica a
                    qui&eacute;n
                    &eacute;ste encargue, a utilizar, recolectar, almacenar, utilizar, circular, transmitir, transferir,
                    suprimir,
                    comercializar y en general, a realizar cualquier otro tratamiento a los datos personales por mi
                    suministrados,
                    para todos aquellos aspectos inherentes a los productos de INNOVBO S.A.S. lo que implica el uso de
                    los datos
                    para los fines arriba se&ntilde;alados y para actividades de mercadeo, promoci&oacute;n y de ser el
                    caso, cuando
                    la actividad
                    comercial lo requiera, la transferencia y transmisi&oacute;n de los mismos a un tercero (incluyendo
                    terceros pa&iacute;ses),
                    bajo los par&aacute;metros de la Ley 1581 de 2012 y dem&aacute;s normatividad vigente que regule la
                    materia.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&Eacute;CIMA
                    NOVENA. CL&Aacute;USULA PENAL PECUNIARIA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    - La pena pecuniaria se har&aacute;
                    efectiva en caso de incumplimiento total o parcial y equivaldr&aacute; al veinte por ciento (20%)
                    del
                    valor del Contrato DEL DOCUMENTO DE TRANSPORTE DEL SERVICIO INCUMPLICO. No obstante la pena
                    pecuniaria,
                    EL CONTRATANTE podr&aacute; demandar la indemnizaci&oacute;n de perjuicios.</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:7.9pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">VIG&Eacute;SIMA:
                    MERITO EJECUTIVO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    - Las partes acuerdan que el presente contrato prestar&aacute;
                    m&eacute;rito ejecutivo para el cobro de las obligaciones emanadas de cumplimiento o incumplimiento
                    de las
                    aqu&iacute; contenidas,
                    y de las que por ley les correspondan, sin necesidad de protesto; y que para tal fin el presente
                    documento se asemeja
                    a una letra de cambio, y se regir&aacute; por las normas sobre la materia. As&iacute; mismo, el
                    presente contrato
                    se someter&aacute;
                    a lo estipulado en el T&Iacute;TULO IV del C&oacute;digo de Comercio y dem&aacute;s normas
                    concordantes y
                    complementarias.
                    Para efectos de certificar las sumas adeudadas por cualquiera de las partes bastar&aacute; la
                    constancia
                    emitida
                    por el Contador de la empresa correspondiente o el de su revisor fiscal.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">VIG&Eacute;SIMA
                    PRIMERA. CENTRALES DE RIESGO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    - El CONTRATISTA autoriza al CONTRATANTE o a qui&eacute;n &eacute;ste designe,
                    para incorporar, reportar, procesar, consultar y obtener en cualquier
                    momento informaci&oacute;n en las centrales de riesgo sobre el comportamiento crediticio del El
                    CONTRATISTA,
                    as&iacute; como el cumplimiento de sus obligaciones adquiridas mediante el presente contrato.
                    &nbsp;</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">VIG&Eacute;SIMA
                    SEGUNDA. - EXCLUSI&Oacute;N LABORAL</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    - EL CONTRATISTA
                    prestar&aacute; los servicios con sus propios medios y con su propio personal, por lo tanto,
                    todos los salarios, prestaciones sociales, indemnizaciones, aportes a EPS, ARL, PENSIONES,
                    CESANT&Iacute;AS, VACACIONES,
                    SENA, CCF, ICBF subsidios de transporte, etc., dotaci&oacute;n del personal y del CONTRATISTA, que
                    se ocupe
                    en el desarrollo del presente contrato,
                    ser&aacute;n a cargo exclusivo del mismo, es decir que no tienen ninguna relaci&oacute;n laboral ni
                    prestacional
                    entre el CONTRATANTE
                    y el CONTRATISTA por lo anterior podr&aacute; el CONTRATISTA realizar operaciones de encargo a
                    terceros en
                    los t&eacute;rminos del
                    art&iacute;culo 984 del c&oacute;digo de comercio con cualquier persona natural o juridica.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">VIG&Eacute;SIMA
                    TERCERA. CL&Aacute;USULA COMPROMISORIA</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.
                    -
                    Las partes acuerdan someter, toda controversia o diferencia surgida de este contrato, a la
                    resoluci&oacute;n de
                    un Tribunal de Arbitramento, que se sujetar&aacute; al reglamento del Centro de Arbitraje y
                    conciliaci&oacute;n
                    de la C&aacute;mara de Comercio de Bogot&aacute;, para Pymes o peque&ntilde;as empresas, de acuerdo
                    con las
                    siguientes reglas:&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">)
                    El tribunal estar&aacute; integrado por un (1) &aacute;rbitro si la cuant&iacute;a es igual o
                    inferior a 50
                    salarios m&iacute;nimos mensuales legales vigentes y por tres (3) &aacute;rbitros si supera esta
                    cuant&iacute;a,
                    2) los &aacute;rbitros ser&aacute;n designados por sorteo de las listas del Centro de Arbitraje y
                    Conciliaci&oacute;n de la C&aacute;mara de Comercio de Bogot&aacute;, a solicitud de cualquiera de
                    las
                    partes.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ii</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">)
                    El Tribunal decidir&aacute; en derecho.&nbsp;</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">iii</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">)
                    la parte que resulte vencida asumir&aacute; los costos del tr&aacute;mite arbitral.</span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">VIG&Eacute;SIMA
                    TERCERA. - ACEPTACI&Oacute;N DEL CONTRATO</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.-
                    Las partes convienen que por el solo hecho que EL CONTRATANTE expida al CONTRATISTA el
                    documento de
                    transporte o el CONTRATISTA descargue la APP o inicie la prestaci&oacute;n del servicio de
                    transporte
                    encarg&oacute; a tercero, se entender&aacute; que se han aceptado las condiciones contractuales
                    establecidas en
                    este contrato de vinculaci&oacute;n, el cual es suscrito por las partes, en la ciudad de
                    Bogot&aacute;
                    D.C.,
                    Colombia.</span>
            </p>
            <p><br></p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:20pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ANEXO
                    1&nbsp;</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;POL&Iacute;TICA
                    DE TRATAMIENTO DE LA INFORMACI&Oacute;N</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INNOVBO
                    S.A.S.</span>
            </p>
            <p><br></p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
                    cumplimiento de la Ley 1581 de 2012 y el decreto 1377 de 2013,
                    vigentes en la Rep&uacute;blica de Colombia, y cualquier otra norma aplicable en la materia,
                    la siguiente es la Pol&iacute;tica de Tratamiento de la Informaci&oacute;n de INNOVBO
                    S.A.S.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
                    1. DATOS DEL RESPONSABLE TRATAMIENTO DE INFORMACI&Oacute;N </span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
                    INNOVBO S.A.S.. <br>
                    NIT 901.343.057 - 1 <br>
                    Domicilio: Bogot&aacute; <br>
                    Direcci&oacute;n: Cll 26 # 96j - 66 <br>
                    Correo Electr&oacute;nico: soporte@INNOVBO.com <br>
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.
                    DISPOSICIONES GENERALES </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.1.
                    Objetivo</span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                    presente Pol&iacute;tica
                    de Tratamiento de la Informaci&oacute;n tiene como objetivo garantizar el adecuado
                    cumplimiento de las normas aplicables en materia de protecci&oacute;n de datos personales aplicables
                    y
                    poner en conocimiento el uso que INNOVBO S.A.S.. le da a la informaci&oacute;n proporcionada
                    por los
                    Titulares, e informar sobre los procedimientos y mecanismos de manejo de los Datos Personales,
                    recopilados para las finalidades previstas con la autorizaci&oacute;n previa.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.2.
                    Legislaci&oacute;n Aplicable</span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                    presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n fue elaborada de conformidad con
                    las
                    disposiciones contenidas en los art&iacute;culos 15 y 20 de la Constituci&oacute;n Pol&iacute;tica,
                    la Ley 1581 de 2012 y
                    sus decretos
                    reglamentarios, en especial el Decreto 1377 de 2013.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.3.
                    &Aacute;mbito de aplicaci&oacute;n</span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                    presente Pol&iacute;tica de Tratamiento de la Informaci&oacute;n es aplicable al tratamiento de los
                    datos
                    personales
                    registrados en cualquier base de datos que los haga susceptibles de Tratamiento por parte de INNOVBO
                    S.A.S.
                    , bien sea en calidad de Responsable y/o Encargado.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.4.
                    Definiciones</span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
                    los efectos de aplicaci&oacute;n de la
                    presente Pol&iacute;tica, y en concordancia con lo establecido en el
                    art&iacute;culo 3 de la ley 1581 de 2012
                    y en el art&iacute;culo 3 del Decreto 1377 de 2013 se entiende por:&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                    Autorizaci&oacute;n:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Consentimiento
                    previo, expreso e informado del Titular
                    para llevar a cabo el tratamiento de sus Datos Personales.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                    Aviso de Privacidad:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Documento
                    f&iacute;sico, electr&oacute;nico o en cualquier otro formato,
                    generado por el responsable, dirigido al titular para el Tratamiento de sus Datos Personales,
                    mediante el cual se le informa
                    acerca de la existencia de las pol&iacute;ticas del tratamiento de la informaci&oacute;n, que le
                    ser&aacute;n aplicables
                    a sus datos,
                    la forma de acceder a las mismas, y la finalidad para la cual ser&aacute;n usados dichos
                    datos.</span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                    Base de Datos:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Conjunto organizado de Datos Personales que sea objeto de tratamiento.
                    Puede ser automatizada o f&iacute;sica de acuerdo a su forma de Tratamiento o almacenamiento.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                    Dato Personal:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Cualquier informaci&oacute;n vinculada o
                    que pueda asociarse a una o varias personas naturales determinadas o determinables.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                    Dato Privado:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Aquel que por su naturaleza &iacute;ntima o reservada s&oacute;lo es relevante para el Titular.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                    Dato P&uacute;blico:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Aquel que no sea Semiprivado, Privado o Sensible. Son considerados Datos P&uacute;blicos, entre
                    otros, los
                    datos relativos
                    al estado civil de las personas, a su profesi&oacute;n u oficio y a su calidad de comerciante o de
                    servidor
                    p&uacute;blico.
                    Por su naturaleza, los datos p&uacute;blicos pueden estar contenidos, entre otros, en registros
                    p&uacute;blicos,
                    documentos p&uacute;blicos,
                    gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no est&eacute;n
                    sometidas a reserva.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.
                    Dato Sensible:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Aquel que afecta la intimidad del Titular o cuyo uso indebido puede generar su
                    discriminaci&oacute;n,
                    incluyendo pero sin limitarse
                    a datos que revelen el origen racial o &eacute;tnico, la orientaci&oacute;n pol&iacute;tica, las
                    convicciones religiosas
                    o filos&oacute;ficas,
                    la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses
                    de cualquier
                    partido pol&iacute;tico o que garanticen los derechos y garant&iacute;as de partidos
                    pol&iacute;ticos de oposici&oacute;n, as&iacute;
                    como los datos
                    relativos a la salud, a la vida sexual y los datos biom&eacute;tricos.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                    Encargado del Tratamiento:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con
                    otros, realice el
                    tratamiento
                    de Datos Personales por cuenta del Responsable del Tratamiento.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.
                    Productos:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Hace referencia a bienes o servicios.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">j.
                    Responsable del Tratamiento:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con
                    otros, decida sobre
                    la Base de Datos y/o el Tratamiento de los datos.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">k.
                    Titular:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Persona natural cuyos Datos Personales sean objeto de Tratamiento.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">l.
                    Transferencia: </span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    La Transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de datos
                    personales,
                    ubicado en Colombia, env&iacute;a la informaci&oacute;n o los datos personales a un receptor, que a
                    su vez es
                    responsable del
                    tratamiento y se encuentra dentro o fuera del pa&iacute;s.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">m.
                    Transmisi&oacute;n:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Tratamiento de Datos Personales que implica la comunicaci&oacute;n de los mismos dentro o fuera del
                    territorio de la Rep&uacute;blica de Colombia
                    cuando tenga por objeto la realizaci&oacute;n de un Tratamiento por el Encargado por cuenta del
                    Responsable.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">n.
                    Tratamiento:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Cualquier operaci&oacute;n o conjunto de operaciones sobre Datos Personales, tales como la
                    recolecci&oacute;n,
                    almacenamiento, uso, circulaci&oacute;n o supresi&oacute;n.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.5.
                    Principios rectores aplicables al Tratamiento de Datos Personales </span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con
                    el fin de garantizar el Tratamiento de Datos Personales
                    que realice INNOVBO S.A.S.. como Responsable y/o Encargado de la informaci&oacute;n,
                    aplicar&aacute;n los siguientes principios, en los t&eacute;rminos del art&iacute;culo 4 de la Ley
                    1581 de 2012: &nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.
                    Legalidad:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    El Tratamiento que realice INNOVBO S.A.S.. sobre los Datos Personales estar&aacute; sujeto
                    a lo establecido en la Ley 1581 de 2012 y dem&aacute;s normas aplicables en la materia.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.
                    Finalidad:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    El Tratamiento que realice INNOVBO S.A.S.. obedecer&aacute; a una finalidad leg&iacute;tima de
                    acuerdo con la
                    Constituci&oacute;n y la ley,
                    la cual ser&aacute; informada al Titular.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.
                    Libertad: </span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    El Tratamiento s&oacute;lo puede ejercerse con el consentimiento, previo, expreso e informado del
                    Titular.
                    Los Datos Personales no podr&aacute;n ser obtenidos o divulgados sin previa autorizaci&oacute;n, o
                    en ausencia de
                    mandato legal
                    o judicial que releve el consentimiento.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.
                    Veracidad o Calidad:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    La informaci&oacute;n sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada,
                    comprobable y
                    comprensible.
                    Se proh&iacute;be el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a
                    error.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.
                    Transparencia:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    En el Tratamiento se garantizar&aacute; el derecho del Titular a obtener de INNOVBO S.A.S..
                    como Responsable y/o Encargado, en cualquier momento y sin restricciones,
                    informaci&oacute;n acerca de la existencia de datos que le concierne.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.
                    Acceso y Circulaci&oacute;n Restringida:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    El Tratamiento se sujeta a los l&iacute;mites que se derivan de la naturaleza de los Datos
                    Personales, de
                    las disposiciones de la Ley 1581 de 2012
                    y la Constituci&oacute;n. En este sentido, el Tratamiento s&oacute;lo podr&aacute; hacerse por
                    personas autorizadas por
                    el Titular y/o
                    por las personas previstas en la Ley 1581 de 2012.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.
                    Seguridad:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    La informaci&oacute;n sujeta a Tratamiento por INNOVBO S.A.S.. como Responsable del Tratamiento
                    y/o
                    Encargado del,
                    se deber&aacute; manejar con las medidas t&eacute;cnicas, humanas y administrativas que sean
                    necesarias para
                    otorgar seguridad
                    a los registros evitando su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no
                    autorizado o
                    fraudulento.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.
                    Confidencialidad:</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Todas las personas que intervengan en el Tratamiento de Datos Personales que no tengan la naturaleza
                    de p&uacute;blicos est&aacute;n obligadas
                    a garantizar la reserva de la informaci&oacute;n, inclusive despu&eacute;s de finalizada su
                    relaci&oacute;n con alguna de
                    las labores que comprende el Tratamiento,
                    pudiendo s&oacute;lo realizar suministro o comunicaci&oacute;n de Datos Personales cuando ello
                    corresponda al
                    desarrollo de las actividades autorizadas
                    en la Ley 1581 de 2012 y en los t&eacute;rminos de la misma.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
                    3. TRATAMIENTO AL CUAL ESTAR&Aacute;N SOMETIDOS LOS DATOS PERSONALES E INFORMACI&Oacute;N COMERCIAL
                    Y SU FINALIDAD
                </span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
                    datos personales ser&aacute;n recolectados,
                    almacenados, transmitidos, transferidos, procesados y usados para todos aquellos aspectos inherentes
                    a
                    los Productos ofrecidos por INNOVBO S.A.S.., y la ejecuci&oacute;n de los contratos con
                    clientes,
                    usuarios,
                    proveedores, empleados y dem&aacute;s colaboradores, lo que implica el uso de los datos ser&aacute;n
                    usados para
                    los siguientes fines:
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.
                    Clientes y Usuarios
                </span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Cumplir las obligaciones contra&iacute;das por INNOVBO S.A.S.. con sus clientes y usuarios.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Enviar informaci&oacute;n sobre los Productos que ofrece INNOVBO S.A.S..
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Enviar informaci&oacute;n sobre cambios en las condiciones de los Productos ofrecidos por INNOVBO
                    S.A.S.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Enviar al correo f&iacute;sico, electr&oacute;nico, celular o dispositivo m&oacute;vil, v&iacute;a
                    mensajes de texto (SMS y/o
                    MMS) o
                    a trav&eacute;s de cualquier otro medio an&aacute;logo y/o digital de comunicaci&oacute;n, creado o
                    por crearse,
                    informaci&oacute;n comercial,
                    publicitaria o promocional sobre los Productos de INNOVBO S.A.S.., eventos y/o promociones de
                    tipo comercial
                    o no de estas, con el fin de impulsar, invitar, dirigir, ejecutar, informar y de manera general,
                    llevar a cabo campa&ntilde;as, promociones o concursos de car&aacute;cter comercial o publicitario,
                    adelantados por INNOVBO S.A.S.. y/o por terceras personas.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Tratar los datos de los clientes y usuarios de INNOVBO S.A.S.. suministrados por terceros para
                    las finalidades aqu&iacute; se&ntilde;aladas.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Transferir o transmitir los Datos Personales a terceras personas en Colombia y en el exterior a
                    t&iacute;tulo gratuito u oneroso para su uso comercial.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Transferir o transmitir los datos personales, de los clientes y usuarios de INNOVBO S.A.S..,
                    a Encargados del Tratamiento en Colombia y en el exterior cuando ello sea necesario para
                    la ejecuci&oacute;n de los contratos con los clientes por INNOVBO S.A.S..
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Elaborar estudios de anal&iacute;tica que incluyan cualquier detalle relacionado con los Productos
                    ofrecidos por INNOVBO S.A.S.. con el fin de compartirlos con socios vinculados al negocio de
                    INNOVBO S.A.S..
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Los dem&aacute;s fines necesarios para dar cumplimiento a los contratos suscritos con los clientes y
                    usuarios de INNOVBO S.A.S..
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2.
                    Proveedores
                </span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Para ejecutar los contratos con los proveedores de INNOVBO S.A.S..
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Para referenciarlos con otras personas naturales o jur&iacute;dicas.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3.
                    Empleados
                </span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Para elaborar las hojas de vida.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Para ejecutar y cumplir con el contrato de trabajo y dem&aacute;s obligaciones que como empleador le
                    corresponden por ley.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Administrar y operar, directamente o por conducto de terceros, los procesos de selecci&oacute;n y
                    vinculaci&oacute;n de personal,
                    incluyendo la evaluaci&oacute;n y calificaci&oacute;n de los participantes y la verificaci&oacute;n
                    de referencias
                    laborales y personales,
                    y la realizaci&oacute;n de estudios de seguridad.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Desarrollar las actividades propias de la gesti&oacute;n de recursos humanos tales como
                    n&oacute;mina,
                    afiliaciones a entidades del sistema general de seguridad social, actividades de bienestar y salud
                    ocupacional,
                    ejercicio de la potestad sancionatoria del empleador, entre otras.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Realizar los pagos necesarios derivados de la ejecuci&oacute;n del contrato de trabajo y/o su
                    terminaci&oacute;n,
                    y las dem&aacute;s prestaciones sociales a que haya lugar de conformidad con la ley aplicable.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Contratar beneficios laborales con terceros, tales como seguros de vida, gastos m&eacute;dicos,
                    entre
                    otros.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">g.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Notificar a contactos autorizados en caso de emergencias durante el horario de trabajo o con
                    ocasi&oacute;n
                    del desarrollo del mismo.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">h.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Coordinar el desarrollo profesional de los empleados, el acceso de los empleados a los recursos
                    inform&aacute;ticos y asistir en su utilizaci&oacute;n.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">i.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Transferir y transmitir la informaci&oacute;n de los empleados a Encargados del Tratamiento en
                    Colombia
                    y en el exterior cuando ello sea necesario para el desarrollo de sus operaciones y gesti&oacute;n de
                    n&oacute;mina.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">j.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Los dem&aacute;s fines necesarios para dar cumplimiento al contrato de trabajo.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.4.
                    Accionistas y Miembros de la Junta Directiva
                </span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Para convocarlos a las reuniones de la Asamblea General de Accionistas o de la Junta Directiva
                    seg&uacute;n
                    corresponda.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Para enviarles la informaci&oacute;n necesaria de la Sociedad con el fin de tomar decisiones
                    informadas en
                    las respectivas reuniones.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.5.
                    Finalidades aplicables a todos
                </span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Para el fortalecimiento de las relaciones con sus clientes y proveedores, mediante el env&iacute;o
                    de
                    informaci&oacute;n relevante, la toma de pedidos
                    y evaluaci&oacute;n de la calidad de los Productos de INNOVBO S.A.S..
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Para la determinaci&oacute;n de obligaciones pendientes, la consulta de informaci&oacute;n
                    financiera e historia
                    crediticia
                    y el reporte a centrales de informaci&oacute;n de obligaciones incumplidas, respecto de sus deudores
                    en los
                    t&eacute;rminos de la Ley 1266 de 2008.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Controlar el acceso a las oficinas de INNOVBO S.A.S.., y establecer medidas de seguridad,
                    incluyendo el establecimiento de zonas videovigiladas.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Dar respuesta a consultas, peticiones, quejas y reclamos que sean realizadas por los titulares y
                    transmitir los Datos Personales
                    a organismos de control y dem&aacute;s autoridades que en virtud de la ley aplicable deban recibir
                    los
                    Datos Personales.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Registrar los datos personales en los sistemas de informaci&oacute;n de INNOVBO S.A.S.. y en
                    sus bases
                    de datos comerciales y operativas.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Suministrar, compartir, enviar o entregar sus datos personales a empresas matrices, filiales,
                    vinculadas, o subordinadas de
                    INNOVBO S.A.S.. ubicadas en Colombia o en el extranjero en el evento que dichas
                    compa&ntilde;&iacute;as
                    requieran la informaci&oacute;n para los fines aqu&iacute; indicados.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.
                    DERECHOS DE LOS TITULARES DE DATOS
                </span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conforme
                    lo dispone la Ley 1581 de 2012,
                    los titulares de datos personales tienen derecho a:
                </span>
            </p>
            <p><br></p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Conocer, actualizar y rectificar sus datos personales frente a los Responsables del Tratamiento o
                    Encargados del Tratamiento.
                    Este derecho se podr&aacute; ejercer, entre otros frente a datos parciales, inexactos, incompletos,
                    fraccionados, que induzcan a error,
                    o aquellos cuyo Tratamiento est&eacute; expresamente prohibido o no haya sido autorizado.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Solicitar prueba de la autorizaci&oacute;n otorgada al Responsable del Tratamiento salvo cuando
                    expresamente se except&uacute;e como requisito para el Tratamiento,
                    de conformidad con lo previsto en el art&iacute;culo 10 de la Ley 1581 de 2012.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud,
                    respecto del uso
                    que le ha dado a sus datos personales.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en
                    la presente ley
                    y las dem&aacute;s normas que la modifiquen, adicionen o complementen.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">e.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n del dato cuando en el Tratamiento
                    no se respeten
                    los principios,
                    derechos y garant&iacute;as constitucionales y legales. La revocatoria y/o supresi&oacute;n
                    proceder&aacute; cuando la
                    Superintendencia
                    de Industria y Comercio haya determinado que en el Tratamiento el Responsable o Encargado han
                    incurrido en conductas
                    contrarias a esta ley y a la Constituci&oacute;n.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">f.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.
                    PERSONA RESPONSABLE DE LA ATENCI&Oacute;N DE PETICIONES,
                    CONSULTAS Y RECLAMOS ANTE LA CUAL EL TITULAR DE LA INFORMACI&Oacute;N
                </span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                    Gerencia de INNOVBO S.A.S.. ser&aacute; la encargada de atender las peticiones,
                    consultas y reclamos de los titulares de la informaci&oacute;n. Cualquier solicitud debe ser enviada
                    al
                    correo electr&oacute;nico soporte@INNOVBO.com el
                    fin de ejercer los derechos a conocer, actualizar, rectificar y suprimir los datos y revocar la
                    autorizaci&oacute;n otorgados por la ley y
                    por esta Pol&iacute;tica de Tratamiento de Informaci&oacute;n.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.
                    PROCEDIMIENTO PARA QUE LOS
                    TITULARES DE LA INFORMACI&Oacute;N PUEDAN EJERCER LOS DERECHOS A CONOCER, ACTUALIZAR, RECTIFICAR Y
                    SUPRIMIR INFORMACI&Oacute;N Y REVOCAR LA AUTORIZACI&Oacute;N.
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.
                    Consultas</span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INNOVBO
                    S.A.S.
                    dispone del correo electr&oacute;nico soporte@INNOVBO.com para que el titular, sus causahabientes,
                    sus representantes y apoderados, y los representantes de menores de edad titulares,
                    formulen consultas respecto de cu&aacute;les son los datos personales del titular que reposan
                    en las bases de datos de INNOVBO S.A.S..&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.1.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Si
                    el solicitante tuviere
                    capacidad para formular la consulta, en los t&eacute;rminos se&ntilde;alados en la Ley 1581 de 2012
                    y
                    sus decretos reglamentarios, INNOVBO S.A.S. recopilar&aacute; toda la informaci&oacute;n sobre el
                    titular
                    que est&eacute; contenida en las bases de datos de INNOVBO S.A.S.. y se la har&aacute; conocer
                    al
                    solicitante.&nbsp;
                </span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.2.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                    responsable de atender la consulta,
                    seg&uacute;n se se&ntilde;ala en el numeral 4 arriba, dar&aacute; respuesta al solicitante siempre y
                    cuando tuviere
                    derecho a
                    ello por ser el titular del dato personal, su causahabiente, apoderado, representante, o sea el
                    responsable
                    legal en el caso de menores de edad. Esta respuesta se enviar&aacute; dentro de los diez (10)
                    d&iacute;as h&aacute;biles
                    contados
                    a partir de la fecha en la que la solicitud fue recibida por la INNOVBO S.A.S..&nbsp;
                </span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.3.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;En
                    caso de que la solicitud
                    no pueda ser atendida a los diez (10) d&iacute;as h&aacute;biles, se contactar&aacute; al
                    solicitante para comunicarle
                    los motivos por los cuales el estado de su solicitud se encuentra en tr&aacute;mite.
                    Para ello se utilizar&aacute; el mismo medio o uno similar al que fue utilizado por el titular para
                    comunicar su solicitud.&nbsp;
                </span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1.4.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;La
                    respuesta definitiva a
                    todas las solicitudes no tardar&aacute; m&aacute;s de quince (15) d&iacute;as h&aacute;biles desde
                    la fecha en la que la
                    solicitud inicial
                    fue recibida por INNOVBO S.A.S.. al correo electr&oacute;nico soporte@INNOVBO.com.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.
                    Reclamos </span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INNOVBO
                    S.A.S.
                    dispone del correo electr&oacute;nico soporte@INNOVBO.com para que el titular, sus causahabientes,
                    sus representantes y apoderados, y los representantes de menores de edad titulares, formulen
                    reclamos respecto de (i) datos personales
                    tratados por la empresa que deben ser objeto de correcci&oacute;n, actualizaci&oacute;n o
                    supresi&oacute;n, o (ii) el
                    presunto incumplimiento de las normas
                    aplicables en la materia.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.1.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                    reclamo deber&aacute; ser presentado por el Titular,
                    sus causahabientes o representantes o acreditados de conformidad con la Ley 1581 y sus decretos
                    reglamentarios al correo electr&oacute;nico soporte@INNOVBO.com y deber&aacute; contener la
                    siguiente informaci&oacute;n:
                    &nbsp;
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Nombre y documento de identificaci&oacute;n del titular.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Una descripci&oacute;n de los hechos que dan lugar al reclamo y el objetivo perseguido
                    (actualizaci&oacute;n,
                    correcci&oacute;n o supresi&oacute;n, o cumplimiento de deberes).
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    La direcci&oacute;n y datos de contacto e identificaci&oacute;n del reclamante.
                </span>
            </p>
            <p dir="ltr"
                style="line-height:1.3800000000000001;margin-left: 18pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;
                    Acompa&ntilde;arse por toda la documentaci&oacute;n que el reclamante quiera hacer valer.
                </span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INNOVBO
                    S.A.S.
                    antes de atender el reclamo verificar&aacute; la identidad del titular del dato personal, su
                    representante
                    o apoderado, o
                    la acreditaci&oacute;n de que hubo una estipulaci&oacute;n por otro o para otro. Para ello puede
                    exigir la c&eacute;dula
                    de ciudadan&iacute;a o
                    documento de identificaci&oacute;n original del titular, y los poderes especiales, generales o
                    documentos
                    que se exijan seg&uacute;n sea el caso.&nbsp;
                </span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.2.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Si
                    el reclamo o la documentaci&oacute;n adicional est&aacute;n incompletos,
                    INNOVBO S.A.S.. requerir&aacute; al reclamante por una sola vez dentro de los cinco (5)
                    d&iacute;as
                    siguientes a
                    la recepci&oacute;n del reclamo para que subsane las fallas. Si el reclamante no presenta la
                    documentaci&oacute;n
                    e
                    informaci&oacute;n requerida dentro de los dos (2) meses siguientes a la fecha del reclamo inicial,
                    se entender&aacute; que ha desistido del reclamo.&nbsp;
                </span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.3.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Si
                    por cualquier hecho la persona que recibe
                    el reclamo no es competente para resolverlo, dar&aacute; traslado a la Gerencia General dentro de
                    los dos
                    (2) d&iacute;as h&aacute;biles
                    siguientes a haber recibido el reclamo, e informar&aacute; de dicha remisi&oacute;n al reclamante.
                    &nbsp;
                </span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.4.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Una
                    vez recibido el reclamo con
                    la documentaci&oacute;n completa, se incluir&aacute; en la base de datos de INNOVBO S.A.S..
                    donde reposen
                    los datos del titular sujetos a reclamo, con una leyenda que diga “reclamo en tr&aacute;mite” y el
                    motivo
                    del mismo,
                    en un t&eacute;rmino no mayor a dos (2) d&iacute;as h&aacute;biles. Esta leyenda deber&aacute;
                    mantenerse hasta que el reclamo
                    sea decidido.&nbsp;
                </span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2.5.</span><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El
                    t&eacute;rmino m&aacute;ximo para atender el reclamo
                    ser&aacute; de quince (15) d&iacute;as h&aacute;biles contados a partir del d&iacute;a siguiente a
                    la fecha de su recibo.
                    Cuando no fuere posible atender el reclamo dentro de dicho t&eacute;rmino, se informar&aacute; al
                    interesado
                    los motivos de la demora y la fecha en que se atender&aacute; su reclamo, la cual en ning&uacute;n
                    caso podr&aacute;
                    superar los ocho (8) d&iacute;as h&aacute;biles siguientes al vencimiento del primer
                    t&eacute;rmino.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.
                    FECHA DE ENTRADA EN VIGENCIA DE LA POL&Iacute;TICA DE
                    TRATAMIENTO DE LA INFORMACI&Oacute;N Y PER&Iacute;ODO DE VIGENCIA DE LA BASE DE DATOS.
                </span><br><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
                    presente Pol&iacute;tica de Tratamiento de
                    la Informaci&oacute;n de INNOVBO S.A.S. rige a partir del 01 de Diciembre de 2019.
                    <br>Los datos personales que sean recolectados, almacenados, transmitidos, transferidos, procesados
                    y
                    usados permanecer&aacute;n en la base de datos de INNOVBO S.A.S., con base en el criterio de
                    temporalidad y
                    necesidad,
                    durante el tiempo que sea necesario para las finalidades mencionadas en esta Pol&iacute;tica, para
                    los
                    cuales fueron recolectados.
                </span>
            </p>
            <p><br></p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:20pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ANEXO
                    2&nbsp;</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;AUTORIZACI&Oacute;N
                    USO DATOS PERSONALES CLIENTES Y USUARIOS
                    INNOVBO S.A.S.</span>
            </p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: center;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">NIT
                    901.331.232 - 0
                </span>
            </p>
            <p><br></p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
                    cumplimiento de lo previsto en la Ley 1581 de 2012,
                    sobre protecci&oacute;n de datos personales, INNOVBO S.A.S. le informa que sus datos personales
                    ser&aacute;n recolectados, almacenados en una base de datos,
                    transmitidos, transferidos, procesados y usados para todos aquellos aspectos inherentes a los
                    productos de INNOVBO S.A.S.,
                    lo que implica el uso de los datos que ser&aacute;n usados para los siguientes fines (i) cumplir las
                    obligaciones contra&iacute;das por
                    INNOVBO S.A.S. con sus clientes y usuarios, (ii) enviar informaci&oacute;n sobre los productos que
                    ofrece INNOVBO S.A.S., (iii)
                    enviar informaci&oacute;n sobre cambios en las condiciones de los productos ofrecidos por INNOVBO
                    S.A.S., (iv) enviar al correo f&iacute;sico,
                    electr&oacute;nico, celular o dispositivo m&oacute;vil, v&iacute;a mensajes de texto (SMS y/o MMS) o
                    a trav&eacute;s de cualquier otro medio an&aacute;logo y/o digital
                    de comunicaci&oacute;n creado o por crearse, informaci&oacute;n comercial, publicitaria o
                    promocional sobre los productos de INNOVBO S.A.S., eventos
                    y/o promociones de tipo comercial o no de estas, con el fin de impulsar, invitar, dirigir, ejecutar,
                    informar y de manera general,
                    llevar a cabo campa&ntilde;as, promociones o concursos de car&aacute;cter comercial o publicitario,
                    adelantados por INNOVBO S.AS y/o por terceras
                    personas, (v) tratar los datos de los clientes y usuarios de INNOVBO S.A.S. suministrados por
                    terceros para las finalidades aqu&iacute; se&ntilde;aladas,
                    (vi) transferir o transmitir los Datos Personales a terceras personas en Colombia y en el exterior a
                    t&iacute;tulo gratuito u oneroso
                    para su uso comercial, (vii) elaborar estudios de anal&iacute;tica que incluyan cualquier detalle
                    relacionado con los productos ofrecidos
                    por INNOVBO S.A.S. con el fin de compartirlos con socios vinculados al negocio de INNOVBO S.A.S.
                    (viii) transferir o transmitir los
                    datos personales, de los clientes y usuarios de INNOVBO S.A.S., a Encargados del Tratamiento en
                    Colombia y en el exterior cuando ello
                    sea necesario para la ejecuci&oacute;n de los contratos con los clientes y usuarios de INNOVBO
                    S.A.S., (ix) para el fortalecimiento de las
                    relaciones con sus clientes y usuarios mediante el env&iacute;o de informaci&oacute;n relevante, la
                    toma de pedidos y evaluaci&oacute;n de la calidad de los
                    productos de INNOVBO S.A.S., (x) para la determinaci&oacute;n de obligaciones pendientes, la
                    consulta de informaci&oacute;n financiera e historia
                    crediticia y el reporte a centrales de informaci&oacute;n de obligaciones incumplidas, respecto de
                    sus deudores en los t&eacute;rminos de la Ley
                    1266 de 2008, (xi) controlar el acceso a las oficinas de INNOVBO S.A.S., y establecer medidas de
                    seguridad, incluyendo el establecimiento
                    de zonas videovigiladas, (xii) dar respuesta a consultas, peticiones, quejas y reclamos que sean
                    realizadas por los titulares y transmitir
                    los Datos Personales a organismos de control y dem&aacute;s autoridades que en virtud de la ley
                    aplicable deban recibir los Datos Personales,
                    (xiii) registrar los datos personales en los sistemas de informaci&oacute;n de INNOVBO S.A.S. y en
                    sus bases de datos comerciales y operativas,
                    (xiv) suministrar, compartir, enviar o entregar sus datos personales a empresas filiales,
                    vinculadas, o subordinadas de INNOVBO S.A.S.
                    ubicadas en Colombia o cualquier otro pa&iacute;s en el evento que dichas compa&ntilde;&iacute;as
                    requieran la informaci&oacute;n para los fines aqu&iacute; indicados,
                    y (xv) los dem&aacute;s fines necesarios para dar cumplimiento a los contratos con los clientes y
                    usuarios de INNOVBO S.A.S. y dem&aacute;s fines
                    contenidos en la Pol&iacute;tica de Tratamiento de la Informaci&oacute;n de INNOVBO S.A.S. Usted
                    tiene derecho a conocer, actualizar rectificar y
                    suprimir los datos personales que haya suministrado, que no deriven de un deber legal o contractual,
                    as&iacute; como tener acceso a sus datos,
                    a la prueba y al uso que se les ha dado, y a presentar quejas por violaci&oacute;n a los derechos
                    que le asisten, en los t&eacute;rminos de la Ley 1581 de 2012.
                    El suministro de datos sensibles o sobre los datos de ni&ntilde;as, ni&ntilde;os y adolescentes que
                    usted haga es facultativo.
                    Para ejercer sus derechos como titular de sus datos personales deber&aacute; contactarse con INNOVBO
                    S.A.S. al correo electr&oacute;nico cto@INNOVBO.com.
                    La Pol&iacute;tica de Tratamiento de la Informaci&oacute;n de INNOVBO S.A.S. podr&aacute; ser
                    solicitada al correo electr&oacute;nico cto@INNOVBO.com. &nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CLIENTE autoriza expresamente a INNOVBO S.A.S.,
                    a cualquiera de sus filiales y a cualquiera de sus colaboradores, clientes o a las autoridades, a
                    acceder,
                    procesar y almacenar la informaci&oacute;n de ubicaci&oacute;n y cualquier informaci&oacute;n de
                    telemetr&iacute;a o de cualquier otra naturaleza proporcionada
                    por el sistema de posicionamiento global (GPS) de su dispositivo m&oacute;vil, as&iacute; como el
                    sistema de posicionamiento global (GPS) de su veh&iacute;culo
                    y tr&aacute;iler (cuando aplique), con el fin de realizar la trazabilidad de los servicios de
                    transporte prestados por el usuario
                    o para ofrecer servicios para el transporte de mercanc&iacute;as con empresas habilitadas. Durante
                    el desarrollo de la operaci&oacute;n
                    de transporte y hasta que esta no se termine, no podr&aacute; el contratista (conductor o
                    propietario del veh&iacute;culo) cambiar el
                    usuario o la clave de acceso al GPS. EL CLIENTE autoriza igualmente a recibir informaci&oacute;n a
                    trav&eacute;s de la
                    App de mensajer&iacute;a WhatsApp con la finalidad de agilizar la gesti&oacute;n de los
                    procedimientos.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p dir="ltr" style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:0pt;">
                <span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EL
                    CLIENTE manera libre, expresa,
                    voluntaria e informada, AUTORIZO a INNOVBO S.A.S. y/o a la persona natural o jur&iacute;dica a
                    qui&eacute;n &eacute;ste encargue,
                    a recolectar, almacenar, utilizar, circular, transmitir, transferir, suprimir, comercializar y en
                    general,
                    a realizar cualquier otro tratamiento a los datos personales por mi suministrados, para todos
                    aquellos aspectos
                    inherentes a los productos de INNOVBO S.A.S. lo que implica el uso de los datos para los fines
                    arriba se&ntilde;alados
                    y para actividades de mercadeo, promoci&oacute;n y de ser el caso, cuando la actividad comercial lo
                    requiera, la transferencia
                    y transmisi&oacute;n de los mismos a un tercero (incluyendo terceros pa&iacute;ses), bajo los
                    par&aacute;metros de la Ley 1581 de 2012
                    y dem&aacute;s normatividad vigente que regule la materia.&nbsp;
                </span>
            </p>
            <p><br></p>
            <p><br></p>
        </body>
    </div>
</div>