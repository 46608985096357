import { Component, OnInit } from '@angular/core';

// Definición de la interfaz fuera de la clase para que sea accesible globalmente
interface Registro {
  fechaPostulacion: string;
  empresa: string;
  nit: string;
  solicitudesAsignadas: number;
  numeroViajesRNDC: number;
  valorFleteOfertado: number;
  scoreEmpresa: number;
}

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
  // Declaración del arreglo como propiedad de la clase para que sea accesible desde la plantilla
  registros: Registro[] = [
    {
      fechaPostulacion: '2024-04-01',
      empresa: 'Empresa A',
      nit: '123456789',
      solicitudesAsignadas: 10,
      numeroViajesRNDC: 5,
      valorFleteOfertado: 10000,
      scoreEmpresa: 85,
    },
    {
      fechaPostulacion: '2024-04-02',
      empresa: 'Empresa B',
      nit: '987654321',
      solicitudesAsignadas: 15,
      numeroViajesRNDC: 7,
      valorFleteOfertado: 12000,
      scoreEmpresa: 90,
    },
    // Agrega los 5 registros restantes aquí
  ];

  constructor() { }

  ngOnInit() {
  }

}
