export class CargoPayment {

    private cargoId: string;
    private date: string;
    private type: string;
    private amount: number;
    private proofs: string[];

    public getCargoId(): string {
        return this.cargoId;
    }

    public setCargoId(cargoId: string): void {
        this.cargoId = cargoId;
    }

    public getDate(): string {
        return this.date;
    }

    public setDate(date: string): void {
        this.date = date;
    }

    public getType(): string {
        return this.type;
    }

    public setType(type: string): void {
        this.type = type;
    }

    public getAmount(): number {
        return this.amount;
    }

    public setAmount(amount: number): void {
        this.amount = amount;
    }

    public getProofs(): string[] {
        return this.proofs;
    }

    public setProofs(proofs: string[]): void {
        this.proofs = proofs;
    }


    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
