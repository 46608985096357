import { Component } from '@angular/core';
import { AdminUsersService } from '../admin-users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { Permission } from 'src/app/core/resources/permission';
import { Filter } from 'src/app/core/models/filter';
import { CurrencyPipe } from '@angular/common';


@Component({
  selector: 'app-admin-users-list',
  templateUrl: './admin-users-list.component.html',
  styleUrls: ['./admin-users-list.component.scss'],
  providers: [CurrencyPipe]
})
export class AdminUsersListComponent {
  permission = Permission;
  listUsers = [];
  pageKey: number = 1;
  pageSize: number = 25;
  throttle = 300;
  scrollDistance = 1;
  enablePagingScroll: boolean = true;
  actualFilters: string = '';

  optionsFilter: Filter = {
    showFilterCompany: true,
    showFilterTypeUser: true,
    showFilterStateUser: true,
    showFilterRole: true,
    showFilterDocument: true,
    showFilterName: true,
    showFilterPhone: true,
    showFilterEmail: true,
  }

  constructor(
    public adminUsersService: AdminUsersService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    public utils: Utils,
    public global: Global,
  ) { }

  /**
  * @param {string} filters are the current filters in string format.
  * @description receives the filters from list-filters component as string and assigns them to actualFilters, 
  * also calls resetValuesPagingScroll and getUsersList methods.
  */
  applyFilter(filters: string): void {
    this.actualFilters = filters;
    this.resetValuesPagingScroll();
    this.getUsersList();
  }

  /**
  * @description if enablePagingScroll is true, adds 1 to pageKey and executes the getUsersList method.
  */
  onScrollDown(): void {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getUsersList();
    }
  }

  /**
  * @description sets enablePagingScroll to true, empties the listUsers and resets the pageKey to 1.
  */
  resetValuesPagingScroll(): void {
    this.enablePagingScroll = true;
    this.listUsers = [];
    this.pageKey = 1;
  }

  /**
  * @description modifies the filters and sends them to getUserList service to get listUsers updated.
  */
  getUsersList(): void {
    let type: 'user' | 'userClient' = this.actualFilters.includes('type=userClient') ? 'userClient' : 'user';

    let filters = {};

    this.actualFilters && this.actualFilters.split("&").forEach((filter) => {
      if (filter && filter.split("=")[1] && filter.split("=")[1] !== "undefined")
        filters[filter.split("=")[0]] = decodeURIComponent(filter.split("=")[1]);
    })
    if (filters['state'] && filters['state'] === "webUserPendingActivate") {
      filters['description'] = "Pendiente validar documentos";
      delete filters['state'];
    }
    delete filters['type'];
    this.spinner.show();
    const holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
    this.adminUsersService.getUsersList(
      type,
      this.pageKey,
      this.pageSize,
      filters['state'] ? filters['state'] === "true" : null,
      filters['document'],
      filters['role'],
      filters['description'],
      filters['phone'],
      filters['name'],
      filters['email'],
      filters['nit'],
      holderCompany
    ).subscribe(
      (success) => {
        if (!success || success.length < 1) {
          this.enablePagingScroll = false;
        } else if (this.listUsers && this.listUsers.length) {
          this.listUsers = this.listUsers.concat(success);
        } else {
          this.listUsers = success;
        }
        this.spinner.hide();
      },
      (error) => {
        this.listUsers = [];
        this.spinner.hide();
      }
    );
  }

  /**
  * @description uses the resetValuesPagingScroll and getUsersList methods.
  */
  refreshList(): void {
    this.resetValuesPagingScroll();
    this.getUsersList();
  }

}
