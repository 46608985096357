import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
/* import { error } from 'protractor'; */
import { newTicket, Ticket, state } from 'src/app/core/interfaces/ticket';
import { ViewTicketInfoService } from '../view-ticket-info.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';
import { User } from 'src/app/core/interfaces/user';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Global } from 'src/app/core/resources/global';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Patterns } from 'src/app/core/resources/patterns';
import { Utils } from 'src/app/core/resources/utils';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { TicketTypes } from 'src/app/core/interfaces/ticketTypes';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-view-ticket-info',
  templateUrl: './view-ticket-info.component.html',
  styleUrls: ['./view-ticket-info.component.scss']
})
export class ViewTicketInfoComponent implements OnInit {
  @Input() terms;
  instanceModalTemrs: NgbModalRef;
  editorConfig: AngularEditorConfig = this.global.editorConfig;
  ticketform = new FormGroup({
    id: new FormControl('', Validators.required),
    expeditionDate: new FormControl({ value: '', disabled: true }, Validators.required,),
    state: new FormControl('', Validators.required,),
    responsable: new FormControl(''),
    user: new FormControl({ value: '', disabled: true }, Validators.required,),
    phone: new FormControl({ value: '', disabled: true }, Validators.required,),
    endDate: new FormControl({ value: '', disabled: true }, Validators.required,),
    email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern(this.patterns.EMAIL.source), Validators.maxLength(100)]),
    subject: new FormControl('', Validators.required,),
    description: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
  });
  isCreate: boolean;
  ticketClosed: boolean = false;
  listUsers: Observable<User[]>
  detailTicket: Ticket;
  permission = Permission;
  formControlResponsable: FormControl = new FormControl();

  status: state[] = [
    { value: 'Abierto' },
    { value: 'En proceso' },
    { value: 'En pausa' },
    { value: 'Cerrado' },
  ];
  types = [];

  constructor(
    private viewticketinfoservice: ViewTicketInfoService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private patterns: Patterns,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    public adminUsersService: AdminUsersService,
    private global: Global,
    public utils: Utils,
    private ticketTypes: TicketTypes,
    private permissionRole: PermissionRole,
  ) {
    this.types = ticketTypes.types;
  }

  ngOnInit() {
    this.route.params.subscribe((params: ParamMap) => {
      if (params && params['id']) {
        this.isCreate = false;
        this.getTicket(params['id'])
      } else {
        this.isCreate = true;
        this.setSessionData();
      }
    });
  }
  getTicket(id) {
    this.spinner.show();
    this.viewticketinfoservice.getTicket(id).toPromise()
      .then((success: Ticket[]) => {
        if (success && success.length) {
          this.ticketform.patchValue(success[0]);
          this.detailTicket = success[0];
          if (success[0].state === 'Cerrado') {
            this.ticketClosed = true;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }
  setSessionData() {
    this.ticketform.get('user').setValue(this.authService.getUserSession().information.name);
    this.ticketform.get('email').setValue(this.authService.getUserSession().email);
    this.ticketform.get('phone').setValue(this.authService.getUserSession().phone);
  }
  onSelectType(type: string) {
    this.ticketform.get('type').enabled && this.ticketform.get('type').setValue(type);
  }
  onSelectResponsable($event: MatAutocompleteSelectedEvent) {
    if ($event.option.value) {
      this.ticketform.get('responsable').setValue($event.option.value);
    }
  }


  onSubmit() {
    if (this.isCreate) {
      if (this.utils.errorMessagesCustomized(this.ticketform.get('type'), 'tipo de ticket')) { }
      else if (this.utils.errorMessagesCustomized(this.ticketform.get('subject'), 'asunto')) { }
      else if (this.utils.errorMessagesCustomized(this.ticketform.get('description'), 'mensaje')) { }
      else {
        let newticket: newTicket = {
          user: this.ticketform.controls.user.value,
          type: this.ticketform.controls.type.value,
          phone: this.ticketform.controls.phone.value,
          email: this.ticketform.controls.email.value,
          subject: this.ticketform.controls.subject.value,
          description: {
            userId: this.authService.getUserSession().information.document,
            userName: this.authService.getUserSession().information.name,
            description: this.ticketform.controls.description.value,
          }
        }
        this.createticket(newticket);
      }
    } else {
      if (this.utils.errorMessagesCustomized(this.ticketform.get('description'), 'respuesta')) { }
      else {
        let ticket: Ticket = {
          id: this.ticketform.controls.id.value,
          phone: this.ticketform.controls.phone.value,
          email: this.ticketform.controls.email.value,
          state: "En proceso",
          subject: this.ticketform.controls.subject.value,
          description: {
            userId: this.authService.getUserSession().information.document,
            userName: this.authService.getUserSession().information.name,
            description: this.ticketform.controls.description.value,
          },
        }
        if (!this.ticketform.controls.responsable.value && this.ticketform.controls.email.value !== this.authService.getUserSession().email) {
          ticket.responsable = this.authService.getUserSession().information.name;
        }
        this.updateTicket(ticket);
      }
    }
  }
  createticket(newticket) {
    this.spinner.show();
    this.viewticketinfoservice.createTicket(newticket).toPromise()
      .then((success: Ticket) => {
        if (success && success.id) {
          this.router.navigate(['help-desk/detail', success.id]);
          this.snackBarService.openSnackBar('Ticket creado correctamente');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error creando el Ticket', undefined, 'error');
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrió un error creando el Ticket', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  changeStateTicket(state) {
    let ticket: Ticket = {
      id: this.ticketform.controls.id.value,
      responsable: this.ticketform.controls.responsable.value,
      phone: this.ticketform.controls.phone.value,
      email: this.ticketform.controls.email.value,
      state: state,
      subject: this.ticketform.controls.subject.value,
    }
    if (ticket.state === "Cerrado") {
      ticket.endDate = DateManager.dateToString(new Date(), 'YYYY-MM-DD HH:mm ZZ');
    }
    this.updateTicket(ticket, true);
  }
  updateTicket(ticket, changeState?: boolean) {
    this.spinner.show();
    this.viewticketinfoservice.updateTicket(ticket).toPromise()
      .then((success: Ticket) => {
        changeState ?
          this.snackBarService.openSnackBar('Ticket actualizado correctamente')
          : this.snackBarService.openSnackBar('Mensaje enviado correctamente');
        this.ticketform.get('description').setValue('');
        if (success.state === "Cerrado") this.ticketClosed = true;
        else this.ticketClosed = false;
        this.getTicket(ticket.id);
      })
      .catch(() => {
        changeState ?
          this.snackBarService.openSnackBar('Ocurrió un error actualizando el Ticket', undefined, 'error')
          : this.snackBarService.openSnackBar('Ocurrió un error al enviar el mensaje', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  goToList() {
    this.router.navigate(['help-desk/list']);
  }

  public isMe(name: string): boolean {
    return name === this.authService.getUserSession().information.name;
  }

  get isMyTicket(): boolean {
    if (this.ticketform && this.ticketform.get('email') && this.ticketform.get('email').value) {
      return this.ticketform.get('email').value === this.authService.getUserSession().email;
    }
    return false;
  }

  get hasPermissionToAnswer(): boolean {
    if (this.ticketform && this.ticketform.get('type') && this.ticketform.get('type').value) {
      switch (this.ticketform.get('type').value) {
        case this.ticketTypes.types[0].value:
          return this.permissionRole.hasPermission(
            this.permission.helpDesk.module,
            this.permission.helpDesk.replyTechnicalTicket
          )
        case this.ticketTypes.types[1].value:
          return this.permissionRole.hasPermission(
            this.permission.helpDesk.module,
            this.permission.helpDesk.replyWebTicket
          )
        case this.ticketTypes.types[2].value:
          return this.permissionRole.hasPermission(
            this.permission.helpDesk.module,
            this.permission.helpDesk.replyMobileTicket
          )
      }
    }
    return this.permissionRole.hasPermission(
      this.permission.helpDesk.module,
      this.permission.helpDesk.replyOtherTicket
    );
  }

  get currentType() {
    if (this.ticketform && this.ticketform.get('type') && this.ticketform.get('type').value) {
      let type = this.types.find(type => this.ticketform.get('type').value === type.value);
      if (type) return type;
    }
    return this.types[5];
  }
  get currentStateColor() {
    if (this.ticketform && this.ticketform.get('state') && this.ticketform.get('state').value) {
      switch (this.ticketform.get('state').value) {
        case 'Abierto':
          return { "background-color": "#38d138" }
        case 'En proceso':
          return { "background-color": "#48e7cf" }
        case 'En pausa':
          return { "background-color": "#fbb63c" }
        default:
          return { "background-color": "red" }
      }
    }
    return { "background-color": "#38d138" };
  }
}










