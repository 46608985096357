import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-open-detail-manifests',
  templateUrl: './open-detail-manifests.component.html',
  styleUrls: ['./open-detail-manifests.component.scss']
})
export class OpenDetailManifestsComponent implements OnInit {
  // @ViewChild('myElement', {static: false}) myElement: ElementRef;
  manifests: any;
  constructor(
    public dialogRef: MatDialogRef<OpenDetailManifestsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() {
    this.manifests = this.data.manifest;
  }

}
