import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ticket } from 'src/app/core/interfaces/ticket';
@Injectable({
  providedIn: 'root'
})
export class ListTicketService {

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private _globalResources: Global,
  ) { }

  /* getAllTickets() {

    return this._http.get<Ticket[]>(
      environment.urlServerTeclogi + this._endpointResources.helpDesk
    );
  } */

  getAllTickets(id: string, state: string, type: string, pageKey: number, pageSize: number) {
    let params = new HttpParams();
    params = params.append('pageKey', pageKey.toString());
    params = params.append('pageSize', pageSize.toString());
    if (id) params = params.append('id', id);
    if (state) params = params.append('state', state);
    if (type) params = params.append('type', type);
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.helpDesk,
      { params }
    );
  }
}
