<div class="modal-body wrapper-modal">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <!-- Title -->
    <p class="title">
        Reemplazar número de contacto
    </p>

    <p>Esta acción reemplazará el número de contacto del usuario y <b>no se puede deshacer</b>.</p>
    <form>
        <mat-form-field>
            <mat-label>Nuevo número de celular</mat-label>
            <input matInput type="tel" [formControl]="phoneControl">
            <mat-error *ngIf="phoneControl?.errors?.pattern">No es un número de celular válido</mat-error>
        </mat-form-field>
    </form>
    <br>

    <div class="row">
        <div class="col-md-6 text-center">
            <button mat-raised-button color="primary" class="primary-secondary"
                (click)="dialogRef.close()">Cancelar</button>
        </div>
        <div class="col-md-6 text-center">
            <button mat-raised-button color="accent" (click)="submit()">Confirmar</button>
        </div>
    </div>
</div>