<!--Large view-->
<div class="w-100 bg-gray main-bar d-none d-lg-block p-3">
    <div class="d-flex flex-wrap px-3">
        <b class="bolded-text col-2 py-4 text-center">Desembolsado</b>
        <b class="bolded-text col-2 py-4 text-center">Utilizado</b>
        <b class="bolded-text col-2 py-4 text-center">Legalizado</b>
        <b class="bolded-text col-2 py-4 text-center">Sin Legalizar</b>
        <b class="bolded-text col-2 py-4 text-center">Rechazado</b>
        <b class="bolded-text col-2 py-4 text-center">Estado Global</b>
    </div>
    <div class="d-flex flex-wrap px-3 bg-white">
        <b class="col-2 text-center py-3">{{calculateDisbursed | currency:'COP':'code'}}</b>
        <b class="col-2 text-center py-3">{{calculateUsed | currency:'COP':'code'}}</b>
        <b class="col-2 text-center py-3">{{calculateLegalized | currency:'COP':'code'}}</b>
        <b class="col-2 text-center py-3">{{calculateNotLegalized | currency:'COP':'code'}}</b>
        <b class="col-2 text-center py-3">{{calculateRejected | currency:'COP':'code'}}</b>
        <b class="col-2 text-center py-3">{{generalState}}</b>
    </div>
</div>

<!--Medium/small view-->
<div class="w-100 d-flex flex-column d-lg-none main-bar">
    <div class="bg-gray end-line text-center d-flex flex-column pb-3">
        <b class="bolded-text py-4">Desembolsado</b>
        <b class="bg-white py-3 mx-3">{{calculateDisbursed | currency:'COP':'code'}}</b>
    </div>

    <div class="bg-gray end-line text-center d-flex flex-column pb-3">
        <b class="bolded-text py-4">Utilizado</b>
        <b class="bg-white py-3 mx-3">{{calculateUsed | currency:'COP':'code'}}</b>
    </div>

    <div class="bg-gray end-line text-center d-flex flex-column pb-3">
        <b class="bolded-text py-4">Legalizado</b>
        <b class="bg-white py-3 mx-3">{{calculateLegalized | currency:'COP':'code'}}</b>
    </div>

    <div class="bg-gray end-line text-center d-flex flex-column pb-3">
        <b class="bolded-text py-4">Sin Legalizar</b>
        <b class="bg-white py-3 mx-3">{{calculateNotLegalized | currency:'COP':'code'}}</b>
    </div>

    <div class="bg-gray end-line text-center d-flex flex-column pb-3">
        <b class="bolded-text py-4">Rechazado</b>
        <b class="bg-white py-3 mx-3">{{calculateRejected | currency:'COP':'code'}}</b>
    </div>

    <div class="bg-gray end-line text-center d-flex flex-column pb-3">
        <b class="bolded-text py-4">Estado Global</b>
        <b class="bg-white py-3 mx-3">{{generalState}}</b>
    </div>

</div>