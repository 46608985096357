<div class="img-container">
    <div>
        <img class="img-testimonials" src="./../../../assets/d.header/Foto1.png" alt="teclogi testimonials">
    </div>

    <div class="info-container">
        <h1 class="text_tittles">Testimonios de nuestros clientes</h1>
        <h3 class="text_tittles-sub">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam, delectus.
            Quibusdam in laudantium
            necessitatibus officiis reiciendis aliquid consequatur a voluptas, dolores blanditiis beatae, illum ut,
            ullam perferendis totam harum quos?</h3>
        <mat-card-actions>
            <button mat-raised-button color="primary" type="button" class="button-width-auto btn-contact">
                Contactenos
            </button>
        </mat-card-actions>


    </div>
</div>
<div class="content">
    <div class="row testimonials-container m-auto container">
        <div class="testimony-card col-lg-4 col-sm-6" *ngFor="let testimony of testimonials">
            <mat-card>
                <div class="card-header-container">
                    <div class="header-card-img">
                        <img src="./../../../assets/img/bavaria.png" alt="">
                    </div>
                    <div class="title-container">
                        <p class="title"> Nombre Apellido</p>
                        <p class="subTitle">Cargo</p>
                    </div>
                </div>
                <mat-card-content>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi suscipit commodi pariatur
                        omnis
                        dolore laboriosam, at ex deserunt soluta saepe, quidem cum, sit sed labore? Molestias
                        molestiae
                        ipsam consequatur consectetur.</p>
                </mat-card-content>
            </mat-card>

        </div>


    </div>
</div>