import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-bell-alerts',
  templateUrl: './confirm-bell-alerts.component.html',
  styleUrls: ['./confirm-bell-alerts.component.scss']
})
export class ConfirmBellAlertsComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmBellAlertsComponent>
  ) { }

  closeDialog() {
      this.dialogRef.close();
  }
}
