<footer class="main-footer page-footer font-small pt-4" *ngIf="!authService.getUserSession().state.active">
  <div class="container-fluid text-center">
    <div class="row">
      <div class="col-md-1 mb-md-0 mb-1">
      </div>
      <div class="col-md-6 mb-md-0 mb-7 text-md-left">

        <div class="row">
          <div class="col-md-12 container-logotype-white">
            <figure>
              <img [src]="urlDefaultLogo" alt="">
            </figure>
          </div>
        </div>

        <div class="row">

          <div class="col-md-4 mb-md-0 mb-3 col-sm-6">

            <!-- Links -->
            <h5 class="font-weight-bold">Contáctanos</h5>

            <ul class="list-unstyled">
              <li>
                <a href="#!">+57 317 367 8485</a>
              </li>
              <li>
                <a href="#!" class="text-break">soporte@loggiapp.com</a>
              </li>
              <li>
                <a href="#!">Bogotá, Colombia</a>
              </li>
            </ul>

          </div>

          <div class="col-md-4 mb-md-0 mb-3 col-sm-6">

            <!-- Links -->
            <h5 class="font-weight-bold">Conócenos</h5>

            <ul class="list-unstyled">
              <li>
                <a (click)="navigateTo('about-us')">Quiénes somos</a>
              </li>
              <li>
                <!-- <a href="#!"></a> -->
              </li>
              <li>
                <!-- <a href="#!">Contáctanos</a> -->
              </li>
            </ul>

          </div>

          <div class="col-md-4 mb-md-0 mb-3 col-sm-6">

            <!-- Links -->
            <h5 class="font-weight-bold">Recursos</h5>

            <ul class="list-unstyled">
              <li *ngFor="let page of generalLegalPages">
                <a (click)="navigateTo(page.path)">{{page.title}}</a>
              </li>
            </ul>
            <ul class="list-unstyled" *ngIf="isTeclogi">
              <li *ngFor="let page of legalPages">
                <a (click)="navigateTo(page.path)">{{page.title}}</a>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <div class="col-md-5 mb-md-0 mb-4">
        <div class="row">

          <div class="col-md-12 mb-md-0 mb-12 col-sm-12 social-networks-container">
            <h5 class="font-weight-bold">Síguenos en</h5>
            <div class="py-5 flex-center">
              <!-- Facebook -->
              <a class="mr-md-3 mr-sm-2 mr-xs-2" href="https://www.facebook.com/LoggiApp/" target="_blank">
                <figure>
                  <img src="/assets/svg/social-network/facebook.svg" alt="">
                </figure>
              </a>
              <!-- Instagram -->
              <a class="mr-md-3 mr-sm-2 mr-xs-2" href="https://www.instagram.com/loggi.app/" target="_blank">
                <figure>
                  <img src="/assets/svg/social-network/instagram.svg" alt="">
                </figure>
              </a>
              <!--Linkedin -->
              <a class="mr-md-3 mr-sm-2 mr-xs-2" href="https://www.linkedin.com/company/loggi-app/" target="_blank">
                <figure>
                  <img src="/assets/svg/social-network/linkedIn.svg" alt="">
                </figure>
              </a>
            </div>
          </div>

          <div class="col-md-12 mb-md-0 mb-12 col-sm-12">
            <h5 class="font-weight-bold">Descarga nuestra app</h5>

            <ul class="list-unstyled">
              <li>
                <a href="https://apps.apple.com/co/app/teclogi-conductor/id1521396363" target="_blank">
                  <figure>
                    <img src="/assets/svg/buttons/ios.svg" alt="">
                  </figure>
                </a>
              </li>
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.teclogi.teclogiDriver" target="_blank">
                  <figure>
                    <img src="/assets/svg/buttons/android.svg" alt="">
                  </figure>
                </a>
              </li>
            </ul>

          </div>

        </div>
      </div>
    </div>
    <div class="row row-copyright">
      <div class="col-md-1 mb-md-0 mb-1">
      </div>
      <div class="col-md-11 mb-md-0 mb-11">
        <div class="row grid-column-copyright text-md-left">
          <div class="col-md-12 mb-md-0 mb-12">
            <a>© {{global.currentYear}} LoggiApp. Todos los derechos reservados.</a>
          </div>
          <div class="col-md-12 mb-md-0 mb-12">
            <a>Version: {{version}} </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>