import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: 'app-receivable-modifications',
  templateUrl: './receivable-modifications.component.html',
  styleUrls: ['./receivable-modifications.component.scss']
})
export class ReceivableModificationsComponent implements OnInit {
  activeReceivable = [];
  arrayPending = [];
  arrayPaid = [];
  constructor(
    public dialogRef: MatDialogRef<ReceivableModificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
  ) { }

  ngOnInit() {
    this.classifyReceivables();
  }

  closeDialog(event) {
    if (event) this.dialogRef.close();
  }

  classifyReceivables() {
    if (this.dialogParams.listReceivables) {
      this.arrayPending = [];
      this.arrayPaid = [];
      // Recorremos el array original y clasificamos los objetos según el valor de "state"
      for (const receivable of this.dialogParams.listReceivables) {
        if (receivable.state.description === 'pending') {
          if (receivable.modifications && receivable.modifications.length > 0) {
            if (receivable.modifications.some(modification => modification.hasOwnProperty('state') && modification.state.description === 'Pending approval')) {
              this.activeReceivable.push(receivable);
            }
          } else {
            this.arrayPending.push(receivable);
          }
        } else if (receivable.state.description === 'paid') {
          this.arrayPaid.push(receivable);
        }
      }
    }
  }

}
