<div class="dsc-carousel" [ngStyle]="{'min-height': finalHeight}">
    <div class="dsc-carousel__btns">
        <i class="bi  bi-chevron-left" (click)=setBack()></i>
        <i class="bi bi-chevron-right" (click)="setNext()"></i>
    </div>
    <div class="dsc-carousel__content">
        <ng-container *ngFor="let section of items">
            <div class="dsc-carousel__content-item"
                [ngStyle]="{'background-image':'url(' + section.image + ')', 'margin-left':section.marginLeft + '%'}">
                <div class="title">
                    {{section.title}}
                </div>
                <div class="subtitle">
                    {{section.subtitle}}
                </div>
                <div class="button" (click)="navigateTo('/contact')">
                    <div class="text-button">Contáctanos</div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="dsc-carousel__steps">
        <div class="dsc-carousel__steps-step dsc-carousel__steps-step-{{currentPosition===item.id?'active':''}}"
            *ngFor="let item of items" (click)="setCurrentPosition(item.id)">
        </div>
    </div>
</div>