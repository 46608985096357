import { Component, OnInit } from '@angular/core';
import { Testimony } from 'src/app/core/interfaces/testimony';

@Component({
    selector: 'app-testimonials',
    templateUrl: './testimonials.component.html',
    styleUrls: ['./testimonials.component.scss']
})

export class TestimonialsComponent implements OnInit {

    public testimonials: Testimony[];

    constructor() {
        this.testimonials = [
            {
                title: '',
                subtitle: '',
                icon: '',
                review: '',
            },
            {
                title: '',
                subtitle: '',
                icon: '',
                review: '',
            },
            {
                title: '',
                subtitle: '',
                icon: '',
                review: '',
            },
            {
                title: '',
                subtitle: '',
                icon: '',
                review: '',
            },
            {
                title: '',
                subtitle: '',
                icon: '',
                review: '',
            },
            {
                title: '',
                subtitle: '',
                icon: '',
                review: '',
            },
            {
                title: '',
                subtitle: '',
                icon: '',
                review: '',
            },
            {
                title: '',
                subtitle: '',
                icon: '',
                review: '',
            },
        ]
    }

    ngOnInit(): void {

    }

}