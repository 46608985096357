import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  public currentPosition = 0;
  public height = 600;
  public isFullScreen = false;
  public finalHeight: string | number = 0;
  public items: any = [
    {
      title: '¡Tecnología que transforma la logística!',
      subtitle:
        'Conectamos camiones disponibles en el mercado con carga de manera ágil y segura.',
      link: '/',
      image: 'assets/images/home/distribution.webp',
    },
    {
      title: '¡Tecnología que transforma la logística!',
      subtitle:
        'Conectamos camiones disponibles en el mercado con carga de manera ágil y segura.',
      link: '/',
      image: 'assets/images/home/factory-worker.webp',
    },
    {
      title: '¡Tecnología que transforma la logística!',
      subtitle:
        'Conectamos camiones disponibles en el mercado con carga de manera ágil y segura.',
      link: '/',
      image: 'assets/images/home/truck-driver.webp',
    },
  ];

  constructor(
    private router: Router,
  ) {
    this.finalHeight = this.isFullScreen ? '100vh' : `${this.height}px `;
  }

  ngOnInit(): void {
    this.items.map((i, index) => {
      i.id = index;
      i.marginLeft = 0;
    });
  }

  public setCurrentPosition(position: number) {
    this.currentPosition = position;
    this.items.find((i) => i.id === 0).marginLeft = -100 * position;
  }

  public setNext() {
    let finalPercentage = 0;
    let nextPosition = this.currentPosition + 1;
    if (nextPosition <= this.items.length - 1) {
      finalPercentage = -100;
    } else {
      nextPosition = 0;
    }
    this.items.find((i) => i.id === 0).marginLeft = finalPercentage;
    this.currentPosition = nextPosition;
  }

  public setBack() {
    let finalPercentage = 0;
    let backPosition = this.currentPosition - 1;
    if (backPosition >= 0) {
      finalPercentage = -100 * backPosition;
    } else {
      backPosition = this.items.length - 1;
      finalPercentage = -100 * backPosition;
    }
    this.items.find((i) => i.id === 0).marginLeft = finalPercentage;
    this.currentPosition = backPosition;
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

}
