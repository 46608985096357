<div class="list-massive-payments-loads-container h-100">
  <div class="d-flex justify-content-end">
    <div class="list-select-all" select-item-list [list]="massiveLoadsList"
    (onSelectItem)="loadsSelected.emit($event)" [active]="activeSelected" [type]="'selectAll'">
    </div>
  </div>
  <div class="col-md-12 mb-md-0 mb-12" *ngFor="let cargo of massiveLoadsList">
      <app-cargo-item select-item-list [active]="activeSelected" (onSelectItem)="loadsSelected.emit($event)" (closeVehicleDetail)="closeVehicleDetail.emit($event)" [hideOptionsLoad]="true" [cargoItemList]="cargo" [typeList]="typeList"  [item]="cargo" [list]="massiveLoadsList">
      </app-cargo-item>
  </div>
</div>
