import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RouteAuthorizedPoint } from 'src/app/core/interfaces/route-authorized-point';
import { RouteItinerary } from 'src/app/core/interfaces/route-itinerary';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-confirm-route-creation',
  templateUrl: './confirm-route-creation.component.html',
  styleUrls: ['./confirm-route-creation.component.scss']
})
export class ConfirmRouteCreationComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      itinerary: RouteItinerary,
      sites: RouteAuthorizedPoint[]
    },
    public dialogRef: MatDialogRef<ConfirmRouteCreationComponent>,
  ) {

  }

  close() {
    this.dialogRef.close();
  }

  create() {
    this.dialogRef.close(true);
  }

  public getDurationLabel(seconds: number): string {
    const durationMsg = [];
    const duration = DateManager.durationFormat(seconds, 'seconds');

    if (duration.years > 0) durationMsg.push(`${duration.years} ${duration.years > 1 ? 'años' : 'año'}`);
    if (duration.months > 0) durationMsg.push(`${duration.months} ${duration.months > 1 ? 'meses' : 'mes'}`);
    if (duration.days > 0) durationMsg.push(`${duration.days} d`);
    if (duration.hours > 0) durationMsg.push(`${duration.hours} h`);
    if (duration.minutes > 0) durationMsg.push(`${duration.minutes} min`);

    return durationMsg.join(' ');
  }

  get hasSomeAuthorizedPts(): boolean {
    return !!(this.data && this.data.sites && this.data.sites.length);
  }

}
