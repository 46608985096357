import { UserTeclogiScore } from "./userTeclogiScore";
import { UserState } from "./userState";
import { Truora } from "./truora";
import { ARL } from "./arl";
import { EPS } from "./eps";
import { BankAccount } from "./userBank";
import { InformationUser } from "./informationUser";
import { DriverLicense } from "../models/driver";
import { Patterns } from "../resources/patterns";
import { Validators } from "@angular/forms";
import { DriverLicenseCategory } from "./driverLicenseCategory";
import { BasicResponse } from "./basicResponse";
import { ContactUser } from "./contactUser";
import { BranchOffice } from "./branch-offices";
import { DriverManifiest } from "./driverManifiest";
import { TermsAndConditions } from "./termsAndConditions";
import { Policies } from "./policies";
import { ExtraDocument } from "./extraDocument";

export interface User extends BasicResponse {
  // Basic fields
  uId?: string;
  email?: string;
  exclusive?: boolean;
  simpleRegimen?: boolean;
  phone?: string;
  role?: string;
  roleId?: string;
  city?: string;
  municipalityCode?: string;
  // Admin fields
  operationId?: string;
  numberOfTrips?: number;
  companyName?: string;
  // Driver fields
  expeditionDateId?: string;
  driverLicense?: DriverLicense;
  driverLicenseCategory?: DriverLicenseCategory[];
  teclogiScore?: UserTeclogiScore;
  state?: UserState;
  truora?: Truora;
  profilePicture?: string;
  profilePictureUrl?: string;
  frontDocument?: string;
  backDocument?: string;
  arl?: ARL;
  eps?: EPS;
  manifests?: DriverManifiest;
  manifestQuantity?: number;
  //
  password?: string;
  validatedByCompany?: boolean;
  //
  termAndCondition?: TermsAndConditions;
  // New
  licenseNumber?: string;
  bankAccount?: BankAccount;
  bankCertificate?: string;
  address?: string;
  // Delete
  information?: InformationUser;
  extraDocuments?: ExtraDocument[];
  errorRNDC?: { error: string };
  referencePersonal?: ContactUser;
  emergencyContact?: ContactUser;
  referenceLaboral?: ContactUser;
  branchOffices?: BranchOffice;
  lastPersonalDataPolicy?: Policies;
  lastPrivacyPolicies?: Policies;
  lastUserTerm?: Policies;
}

export class Model {
  constructor(private patterns: Patterns) { }

  public modelCreateDriver: User = {
    expeditionDateId: "",
    email: "",
    information: {
      documentTypeId: "",
      documentTypeName: "",
      document: "",
      name: "",
    },
    address: '',
    municipalityCode: "",
    city: "",
    numberOfTrips: 0,
    operationId: "",
    exclusive: false,
    simpleRegimen: false,
    phone: "",
    role: "Driver",
  };

  public modelCreateDriverValidators = {
    expeditionDateId: [Validators.required],
    email: [
      Validators.pattern(this.patterns.EMAIL.source),
      Validators.maxLength(100)
    ],
    information: {
      document: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        Validators.minLength(3),
        Validators.maxLength(10),
      ],
      documentTypeId: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
      ],
      documentTypeName: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
      name: [
        Validators.required,
        Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
      ],
    },
    operationId: [
      Validators.required,
      Validators.pattern(this.patterns.ALL_EXPRESSIONS.source),
    ],
    exclusive: [Validators.required],
    phone: [
      Validators.required,
      Validators.pattern(this.patterns.CELLPHONE.source),
    ],
    role: [Validators.required],
  };
}
