import { Component, OnInit, EventEmitter, DoCheck, IterableDiffers, KeyValueDiffers, SimpleChanges, HostListener, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';

import { Global } from 'src/app/core/resources/global';
import { Router, ActivatedRoute } from '@angular/router';
import { ListCargoFromGuidesService } from './list-cargo-from-guides.service';
import { from } from 'rxjs';
import { Observable } from 'object-observer/dist/object-observer.min.js';
import { PlatformLocation } from '@angular/common';
import { Guide } from 'src/app/core/interfaces/guide';
import * as _ from 'lodash';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { GeocodeService } from 'src/app/core/services/geocoder.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
declare var google: any;

@Component({
  selector: 'app-list-cargo-from-guides',
  templateUrl: './list-cargo-from-guides.component.html',
  styleUrls: ['./list-cargo-from-guides.component.scss'],
  providers: [AuthService, Global]
})
export class ListCargoFromGuidesComponent implements OnInit {

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onSelectGuideEvent: EventEmitter<object> = new EventEmitter();
  @Input() state: string;
  guides: Array<Guide>;
  throttle = 300;
  scrollDistance = 1;
  pageKey: number = 1;
  pageSize: number = 10;
  enablePagingScroll: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public listCargoFromGuidesService: ListCargoFromGuidesService,
    private authService: AuthService,
    private geocodeService: GeocodeService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    public utils: Utils
  ) {
  }

  ngOnInit() {

    this.getGuides();
  }

  getGuides() {

    this.listCargoFromGuidesService.getGuidesByState(this.state, this.pageKey, this.pageSize).subscribe(
      (data) => {
        if (!this.utils.isDefined(data) || data.length < 1) {
          this.enablePagingScroll = false;
        } else {
          if (this.guides && this.guides.length) {
            this.guides = this.guides.concat(data);
          } else {
            this.guides = data;
          }
        }
      },
      (error) => {
        this.resetValuesPagingScroll();
      }
    );
  }

  onSelectGuide(guide: Guide) {

    guide.checked ? guide.checked = false : guide.checked = true;
    const guidesList = this.listCargoFromGuidesService.getGuidesSelected(this.guides);
    this.onSelectGuideEvent.emit({
      state: this.state,
      guides: guidesList
    });
  }

  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getGuides();
    }
  }

  resetValuesPagingScroll() {
    this.enablePagingScroll = true;
    this.guides = [];
    this.pageKey = 1;
  }

}
