import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicCargoEvidencesService {

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
  ) { }


  public detailCargoByConsecutive(consecutive: string, companyId: string) {
    let headers = new HttpHeaders();
    headers = headers.set('companyId', companyId)
    return this._http.get<Cargo>(
      environment.urlServerTeclogi + this._endpointResources.urlDetailCargoByConsecutive + consecutive,
      { headers: headers }
    );
  }
}
