export enum Roles {
    EDITOR = 'Editor',
    FINANCIERS = 'Financiero',
    ADMIN = 'Admin',
    VIWER = 'Lector',
    OPERATIONS = 'Operaciones',
    COMMERCIAL = 'Comercial',
    DRIVER = 'Driver',
    OWNER = 'Owner',
    USER_WEB_PENDING_ACTIVATE = 'Usuarios web pendientes de activar',
}
