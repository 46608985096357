import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from './../../../environments/environment';
import { Utils } from 'src/app/core/resources/utils';

@Injectable({
    providedIn: 'root'
})
export class ExportListService {

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        private _globalResources: Global,
        public utils: Utils
    ) {

    }

    exportFile(typeList: number, companyId?: string) {
        let url: string = environment.urlServerTeclogi + this._endpointResources.exportMainBases + typeList;

        if (this.utils.isDefined(companyId)) {
            url += '&companyId=' + companyId;
        }

        return this._http.get(
            url,
            {
                observe: 'response',
                responseType: 'blob'
            }
        );
    }

}
