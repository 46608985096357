<div class="padding-modal">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <span class="separator-horizontal"></span>
  <div class="title-2 header">
    <div class="titulo color-secondary-dark">
      Eliminar GPS
    </div>
  </div>
  <div class="alert alert-primary" role="alert">
    Por favor seleccione el GPS que desea eliminar
  </div>
  <app-select-gps [inputFormControl]="selectedGps" [validate]="validate"></app-select-gps>
  <div class="row">
    <div class="col-md-12 container-button">
      <mat-card-actions class="text-center">
        <button mat-raised-button color="primary" (click)="deleteGps()" class="button-width-auto">
          Eliminar
        </button>
      </mat-card-actions>
    </div>
  </div>
</div>