<form class="routing__form--content" [formGroup]="form" (submit)="onSubmit()">
    <div class="routing__form--header">
        <label class="routing__form--label">Nueva orden</label>
    </div>

    <div class="routing__form--body">
        <label class="routing__form--label text-center">Agregar ordenes desde plantilla</label>
        <app-file (emitToParent)="handleFileInput($event)"
            [options]="{ title: 'Subir archivo .XLSX', format: '.XLSX', initialFile: form.get('file')?.value }">
        </app-file>
    </div>

    <div class="button-container routing__form--footer">
        <button mat-raised-button color="primary" type="submit"
            [ngClass]="{'bg-color-primary':isValidStep, 'disabled-btn':!isValidStep}">
            Continuar
        </button>
    </div>

</form>