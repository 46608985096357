<div class="item row">
    <div class="col-lg-9 row">
        <div class="col-md-4 col-sm-12 text-break">
            <p class="text-bold mb-1">Nombre</p>
            <p class="mb-0">{{scenario.description}}</p>
        </div>
        <div class="col-md-4 col-sm-6 text-break" *ngIf="scenario?.schemaName">
            <p class="text-bold mb-1">Esquema</p>
            <p class="mb-0">{{scenario?.schemaName}}</p>
        </div>
        <div class="col-md-4 col-sm-6 text-break" *ngIf="scenario?.fleetName">
            <p class="text-bold mb-1">Flota</p>
            <p class="mb-0">{{scenario.fleetName}}</p>
        </div>
    </div>
    <div class="col-lg-3 mt-2 mt-lg-0 d-flex justify-content-center align-items-center">
        <button mat-raised-button class="secondary-sharp" (click)="getFullScenario()">
            <span>Consultar </span><span class="d-none d-sm-inline">estado</span>
        </button>
    </div>
</div>