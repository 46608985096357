import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mToKm'
})
export class MToKmPipe implements PipeTransform {

    constructor() { }

    transform(m) {
        try {
            return (m / 1000) + ' km';
        } catch (e) {
            return m;
        }
    }

}