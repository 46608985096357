<div class="h-100 position-relative" [ngClass]="{'dialog-container': dialogParams && dialogParams.clickMap}">
  <i *ngIf="dialogParams && dialogParams.clickMap" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <div *ngIf="dialogParams && dialogParams.clickMap" class="autocomplete-address-container">
    <app-autocomplete-address [title]="'Dirección'" [value]="addressSelected" [addOptions]="false"
      (emitToParent)="onSelectAddress($event)"></app-autocomplete-address>
  </div>
  <div class="map-container">
    <app-standard-map [options]="mapOptions" (clickMap)="onClickMap($event)"></app-standard-map>
  </div>
  <div class="col-md-12 button-container mt-3" *ngIf="dialogParams && dialogParams.clickMap">
    <button mat-raised-button color="primary" type="button" class="bg-color-primary" (click)="sendDataMapa()">
      Guardar
    </button>
  </div>
</div>