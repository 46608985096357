<div class="container">
    <div class="col-md-12 mb-md-0 mb-12 container-sizes">
        <div class="row">
            <div class="col-md-4 mb-md-0 mb-4">
                <div class="container-icon">
                    <img src="/assets/svg/icons/icon-container.svg">
                </div>
                <div class="container-title">
                    Contenedor
                </div>
            </div>

            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-6" *ngFor="let container of containers">
                        <div class="row size-type" (click)="selectTypeContainerSize(container)"
                            [ngClass]="{'option-step-selected':measureContainerSelect === container.size}">
                            <div class="size">
                                {{container.size}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>