import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cargoTaxes'
})
export class CargoTaxesPipe implements PipeTransform {

    constructor(
    ) { }

    transform(value: number, isChargeTaxes?: boolean): number {
        let newValue = isChargeTaxes ? value : 0;
        return newValue;
    }

}
