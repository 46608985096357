<div class="list-graph-container p-1 p-sm-3 d-flex flex-column">
  <div class="create-button-container d-flex mt-2 pr-3" title="Crear">
    <button permission-display [module]="permission.payments.module"
      [functionality]="permission.payments.createReceivable" type="button" class="btn btn-primary text-bold"
      (click)="createReceivable()"><i class="fas fa-plus mr-2 fa-lg"></i>Crear cartera</button>
  </div>
  <div class="graphs-container pt-3 px-0 px-sm-3">
    <div class="card" [ngClass]="{'display-none': (tab === 2 || emptyPaid)}">
      <div class="card-body d-flex flex-row justify-content-center flex-wrap">
        <div class="d-flex flex-column">
          <span class="mb-3"><i class="fas fa-hand-holding-usd"></i></span>
          <p class="card-title text-bold total" *ngIf="activeLabel && ownerName">Tenedor: {{ ownerName }}</p>
          <p class="card-title text-bold total"
            *ngIf="licensePlateValue && receivables && receivables.length > 0 && receivables[0].licensePlate">Placa del
            vehículo: {{ receivables[0].licensePlate }}</p>
          <p class="card-title text-bold total">Total de carteras abonadas</p>
          <h2 class="card-text text-bold">{{ getFilteredReceivables('paid').length }}</h2>
          <p class="card-title text-bold total">Valor total de carteras abonadas</p>
          <h2 class="card-text text-bold">{{ getTotalValueReceivables('amountReceived') | currency:'COP':'code'}}</h2>
        </div>
        <div>
          <canvas #doughnutChart1></canvas>
        </div>
      </div>
    </div>
    <div class="card" [ngClass]="{'display-none': (tab === 1 || emptyPending)}">
      <div class="card-body d-flex flex-row justify-content-center flex-wrap">
        <div class="d-flex flex-column">
          <span class="mb-3"><i class="fas fa-wallet"></i></span>
          <p class="card-title text-bold total" *ngIf="activeLabel && ownerName">Tenedor: {{ ownerName }}</p>
          <p class="card-title text-bold total"
            *ngIf="licensePlateValue && receivables && receivables.length > 0 && receivables[0].licensePlate">Placa del
            vehículo: {{ receivables[0].licensePlate }}</p>
          <p class="card-title text-bold total">Total de carteras pendientes</p>
          <h2 class="card-text text-bold">{{ getFilteredReceivables('pending').length }}</h2>
          <p class="card-title text-bold total">Valor total de carteras pendientes</p>
          <h2 class="card-text text-bold"
            [ngClass]="{'font-size': (getTotalValueReceivables('amountInitial') | currency:'COP':'code').length > 14}">
            {{ getTotalValueReceivables('amountInitial') | currency:'COP':'code'}}</h2>
          <p class="card-text text-bold" *ngIf="activeLabel && getTotalBonusPerUser('amountReceived') > 0">Abonos: <span
              class="bonus">(+) {{ getTotalBonusPerUser('amountReceived') | currency:'COP':'code'}}</span></p>
        </div>
        <div>
          <canvas #doughnutChart2></canvas>
        </div>
      </div>
    </div>
    <div class="card no-content d-flex flex-column align-items-center justify-content-center"
      *ngIf="((tab === 0 && emptyPending && emptyPaid) || (tab === 1 && emptyPaid) || (tab === 2 && emptyPending))">
      <i class="fas fa-chart-pie mb-2"></i>
      <h1>No hay datos para mostrar</h1>
    </div>
  </div>
  <div class="filters-list-container mt-2 p-1 p-sm-3 mb-3">
    <mat-tab-group (selectedIndexChange)="onSelectTab($event)">
      <mat-tab label="Todas las carteras"><app-receivable-detail *ngIf="tab === 0" [storageInputs]="storageToObject"
          [emptyStorage]="emptyStorage" (emitFilters)="processFilters($event)" (refreshListReceivable)="refreshList()"
          [receivables]="receivables" [resetPagination]="resetPagination"
          [cleanFields]="cleanFields"></app-receivable-detail></mat-tab>
      <mat-tab label="Carteras abonadas"> <app-receivable-detail *ngIf="tab === 1" [storageInputs]="storageToObject"
          [emptyStorage]="emptyStorage" (emitFilters)="processFilters($event)" (refreshListReceivable)="refreshList()"
          [receivables]="receivables" [resetPagination]="resetPagination"
          [cleanFields]="cleanFields"></app-receivable-detail></mat-tab>
      <mat-tab label="Carteras pendientes"><app-receivable-detail *ngIf="tab === 2" [storageInputs]="storageToObject"
          [emptyStorage]="emptyStorage" (emitFilters)="processFilters($event)" (refreshListReceivable)="refreshList()"
          [receivables]="receivables" [resetPagination]="resetPagination"
          [cleanFields]="cleanFields"></app-receivable-detail></mat-tab>
    </mat-tab-group>
    <div *ngIf="receivables.length === 0"
      class="d-flex flex-column align-items-center justify-content-center mt-5 mb-3">
      <i class="fas fa-truck mb-2"></i>
      <h1>No se encontraron coincidencias con la búsqueda realizada</h1>
    </div>
  </div>
</div>