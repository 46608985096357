import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { FleetCapacity } from 'src/app/core/interfaces/fleetCapacity';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RoutingService } from '../../routing.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';

@Component({
  selector: 'app-routing-create-capacity',
  templateUrl: './routing-create-capacity.component.html',
  styleUrls: ['./routing-create-capacity.component.scss']
})
export class RoutingCreateCapacityComponent implements OnInit {

  form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<RoutingCreateCapacityComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: { list: FleetCapacity[] },
    public utils: Utils,
    public snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private routingService: RoutingService,
  ) { }

  /**
  * @description Executes the method "initForm"
  */
  ngOnInit(): void {
    this.initForm();
  }

  /**
  * @description Initializes the form
  */
  private initForm() {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      measure: new FormControl('', Validators.required),
    })
  }

  /**
  * @description Verifies if the form's controls "name" and "measure" are valid to submit
  */
  onSubmit(): void {
    if (this.utils.errorMessagesCustomized(this.form.get('name'), 'nombre')) return;
    if (this.form.get('name').value.trim().length < 1) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MIN_LENGTH_NOT_REACHED, 'nombre', 1), undefined, 'alert');
      return;
    }
    if (this.utils.errorMessagesCustomized(this.form.get('measure'), 'medida')) return;
    if (this.form.get('measure').value.trim().length < 1) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MIN_LENGTH_NOT_REACHED, 'medida', 1), undefined, 'alert');
      return;
    }
    if (this.dialogParams && this.dialogParams.list && this.dialogParams.list.some(
      cap => cap.name.toLocaleLowerCase() === this.form.value.name.toLocaleLowerCase()
    )) {
      this.snackBarService.openSnackBar(RoutingMessages.CAPACITY_REPEATED, undefined, 'alert');
      return;
    }
    this.createCapacity(this.form.value);
  }

  /**
  * @param {FleetCapacity} capacity is the capacity to create 
  * @description Creates a capacity with form's value and closes the dialog
  */
  private createCapacity(capacity: FleetCapacity): void {
    this.spinner.show();
    this.routingService.createCapacity(capacity).subscribe(
      (success) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(RoutingMessages.CAPACITY_CREATED);
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      () => {
        this.spinner.hide();
      }
    )
  }

  /**
  * @returns {boolean} returns true if the form's controls "name" and "measure" are filled and valid.
  * @description Verifies if the current step is valid
  */
  get isValidForm(): boolean {
    return this.form && this.form.get('name') && this.form.get('name').valid && this.form.get('name').value.trim().length >= 1
      && this.form && this.form.get('measure') && this.form.get('measure').valid && this.form.get('measure').value.trim().length >= 1
  }

}
