import { Component, OnInit, Input, EventEmitter, Output, ViewChild, NgZone, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Company } from 'src/app/core/interfaces/company';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { AutocompleteAddressComponent } from 'src/app/shared/autocomplete-address/autocomplete-address.component';
import { environment } from 'src/environments/environment';
import { FreightListService } from '../../../cargo-list/cargo-list.service';
import { FormThirdPartyConsignmentComponent } from '../form-third-party-consignment/form-third-party-consignment.component';
import { ThirdPartyConsignmentService } from './third-party-consignment.service';
import { ShippingCost } from 'src/app/core/enums/shipping-cost.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { AccountService } from 'src/app/modules/account/account.service';
import { BasicCompany } from 'src/app/core/interfaces/basicCompany';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Patterns } from 'src/app/core/resources/patterns';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
declare var google: any;

@Component({
  selector: 'app-third-party-consignment',
  templateUrl: './third-party-consignment.component.html',
  styleUrls: ['./third-party-consignment.component.scss'],
  providers: [ThirdPartyConsignmentService]
})
export class ThirdPartyConsignmentComponent implements OnInit {

  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  @Input() options: any;
  @Input() inputFormControl: FormGroup;
  @Input() simpleAddress;
  @Input() isInternational = false;
  @ViewChild(AutocompleteAddressComponent, { static: false }) autocompleteAddressComponent: AutocompleteAddressComponent;
  @ViewChild('inputAutocomplete', { static: false }) inputAutocomplete: any;
  loadingAddresses: boolean = false;
  loadingThirdParties: boolean = false;
  rootCompanies: any[] = [];
  listThirdParties: any[] = [];
  listThirdPartiesAsync: Observable<any[]>;
  listAddresses: any[] = [];
  listAddressesAsync: Observable<any[]>;
  listCompanies: Company[] = [];
  listCompaniesAsync: Observable<Company[]>;

  companySelect: Company;
  companySelectCtrl = new FormControl('');

  addressSelected: any;
  addressSelectCtrl = new FormControl('');

  thirdPartySelected: any;
  thirdPartySelectCtrl = new FormControl('');

  newAddress: boolean;
  cityNewAddress: string;
  newThirdParty: boolean;
  optionsCity: OptionsAutocomplete = {
    title: 'Ingrese la Ciudad'
  }
  cityControl: FormControl = new FormControl();
  citySub: Subscription;

  private currentCity: string = null;
  private currentCompany: string = null;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public authService: AuthService,
    private freightListService: FreightListService,
    private thirdPartyConsignmentService: ThirdPartyConsignmentService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private accountService: AccountService,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    public patterns: Patterns,
    public cdRef: ChangeDetectorRef
  ) {
  }

  setSubscriptionCity() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => this.cityNewAddress = value && value.id ? value.id : '');
  }

  ngOnInit(): void {
    this.setSubscriptionCity();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
    this.rootCompanies = this.accountService.listRegisterCompanies();
    if (this.simpleAddress) {
      this.getAddress(this.simpleAddress.companyId, this.simpleAddress.city, false, this.simpleAddress.initialValue);
    } else {
      if (this.title === 'Propietario de la Carga') {
        this.getCompanies();
      } else {
        if (this.manualCreationCargoService.cargoForm.value.idCompany) {
          this.companySelect = {
            companyId: this.manualCreationCargoService.cargoForm.value.idCompany
          };
          if (this.manualCreationCargoService.getCargoForm().get('ministry').value) {
            this.getThirdParties(true);
          }
        }
      }
    }
    this.listAddressesAsync = this.inputFormControl.valueChanges.pipe(
      map(address => address ? this.filterAddress(address) : this.listAddresses ? this.listAddresses.slice() : [])
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.simpleAddress && changes && changes.simpleAddress && changes.simpleAddress.currentValue) {
      if (this.currentCity !== this.simpleAddress.city || this.currentCompany !== this.simpleAddress.companyId) {
        this.currentCity = this.simpleAddress.city;
        this.currentCompany = this.simpleAddress.companyId;
        this.getAddress(this.simpleAddress.companyId, this.simpleAddress.city, false);
      }
    }
    if (changes && changes.title && changes.title.currentValue && this.cityNewAddress) {
      let $ownerSubscription = this.manualCreationCargoService.cargoForm.get('cargoOwner').valueChanges.subscribe(value => {
        if (
          this.title !== 'Propietario de la Carga' &&
          this.manualCreationCargoService.getCargoForm().get('ministry') &&
          this.manualCreationCargoService.getCargoForm().get('ministry').value) {
          this.getThirdParties();
          $ownerSubscription.unsubscribe();
        }
      });
    }

  }

  private filterThirdParties(value: any): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.listThirdParties.filter(thirdParty => thirdParty.name.toLowerCase().indexOf(filterValue) === 0);
    } else if (typeof value === 'object') {
      try {
        const filterValue = value.name.toLowerCase();
        return this.listThirdParties.filter(thirdParty => thirdParty.name.toLowerCase().indexOf(filterValue) === 0);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }

  private filterAddress(value: any): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.listAddresses.filter(address => address.address.address.toLowerCase().indexOf(filterValue) === 0);
    } else if (typeof value === 'object') {
      try {
        const filterValue = value.address.toLowerCase();
        return this.listAddresses.filter(address => address.address.address.toLowerCase().indexOf(filterValue) === 0);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }

  private filterCompanies(value: any): Company[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.listCompanies.filter(company => company.name.toLowerCase().indexOf(filterValue) === 0);
    } else if (typeof value === 'object') {
      try {
        const filterValue = value.name.toLowerCase();
        return this.listCompanies.filter(company => company.name.toLowerCase().indexOf(filterValue) === 0);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }

  getAddress(companyId: string, city?: string, autoSetValue?: boolean, initialValue?: string) {
    this.loadingAddresses = true;
    if (this.inputFormControl && this.inputFormControl.get('address')) this.inputFormControl.get('address').disable();
    this.thirdPartyConsignmentService.getThirdPartiesAddressByCompany(1, 100, companyId, city).subscribe(
      (success: any) => {
        this.loadingAddresses = false;
        if (this.inputFormControl && this.inputFormControl.get('address')) this.inputFormControl.get('address').enable();
        this.listAddresses = success;
        if (autoSetValue) {
          if (this.listAddresses && this.listAddresses.length) {
            const address = this.listAddresses.filter((address) => {
              if (address && address.address && address.address.location && address.address.location.lat && address.address.location.lng) {
                return address.address.location.lat === this.inputFormControl.value.location.lat &&
                  address.address.location.lng === this.inputFormControl.value.location.lng;
              } else return false;
            });
            if (address.length) {
              this.onSelectAddress(({
                option: {
                  value: address[0]
                }
              }) as MatAutocompleteSelectedEvent);
            }
          }
        }
        if (initialValue && this.listAddresses && this.listAddresses.length) {
          const address = this.listAddresses.filter((address) => {
            return address.address.address === initialValue;
          });
          if (address.length) {
            this.onSelectAddress(({
              option: {
                value: address[0]
              }
            }) as MatAutocompleteSelectedEvent);
          }
        }

        this.inputFormControl.patchValue(this.inputFormControl.value);
      },
      (error) => {
        this.loadingAddresses = false;
        if (this.inputFormControl && this.inputFormControl.get('address')) this.inputFormControl.get('address').enable();
        this.listAddresses = [];
      }
    );
  }

  getThirdParties(autoSetValue?: boolean) {
    this.loadingThirdParties = true;
    this.thirdPartySelectCtrl.disable();
    this.thirdPartyConsignmentService.getThirdPartiesByParent().subscribe(
      (success: any) => {
        this.loadingThirdParties = false;
        this.thirdPartySelectCtrl.enable();
        this.listThirdParties = success;
        this.listThirdPartiesAsync = this.thirdPartySelectCtrl.valueChanges.pipe(
          startWith(''),
          distinctUntilChanged(),
          map(thirdParty => thirdParty ? this.filterThirdParties(thirdParty) : this.listThirdParties ? this.listThirdParties.slice() : [])
        );
        if (autoSetValue) {
          const thirdParty = this.listThirdParties.filter((thirdParty) => {
            return thirdParty.document === this.inputFormControl.value.thirdPartyConsignment.documentNumber;
          });
          if (thirdParty.length) {
            this.thirdPartySelectCtrl.patchValue(thirdParty[0]);
            this.onSelectThirdParty(({
              option: {
                value: thirdParty[0]
              }
            }) as MatAutocompleteSelectedEvent);
          }
        }
      },
      (error) => {
        this.loadingThirdParties = false;
        this.thirdPartySelectCtrl.enable();
        this.listThirdParties = [];
      }
    );
  }

  getCompanies() {
    if (this.authService.getCompany() && this.authService.getCompany().subCompanies) {
      this.listCompanies = this.authService.getCompany().subCompanies.map((company) => {
        return {
          name: company.description,
          companyId: company.code,
          exclusive: this.authService.getCompany().exclusive,
          ministry: this.authService.getCompany().ministry,
          utility: this.authService.getCompany().utility,
          utilityAdditionalService: this.authService.getCompany().utilityAdditionalService,
          advancePercentage: this.authService.getCompany().advancePercentage || ((this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES)),
        };
      });
      this.listCompanies.unshift(this.authService.getCompany());
      this.listCompaniesAsync = this.companySelectCtrl.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        map(company => company ? this.filterCompanies(company) : this.listCompanies ? this.listCompanies.slice() : [])
      );
      this.setCompanyFormControl();
    } else if (this.rootCompanies.some(rootCompany => rootCompany.nit === this.authService.getUserSession().clientCompanyId)) {
      this.freightListService.getAllCompanies().subscribe(
        (success: Company[]) => {
          this.listCompanies = success.map((company: Company) => {
            let companyMap: Company = { ...company };
            companyMap.ministry = this.utils.isDefined(company.ministry) ? company.ministry : false;
            companyMap.siigoId = company.siigoId ? company.siigoId : null;
            return companyMap;
          });
          this.listCompaniesAsync = this.companySelectCtrl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            map(company => company ? this.filterCompanies(company) : this.listCompanies ? this.listCompanies.slice() : [])
          );
          this.setCompanyFormControl();
        },
        (error) => {
          this.listCompanies = [];
        }
      );
    } else {
      this.listCompanies = [this.authService.getCompany()];
      this.listCompanies[0]['ministry'] = this.utils.isDefined(this.authService.getCompany().ministry) ? this.authService.getCompany().ministry : false;
      this.manualCreationCargoService.cargoForm.get('businessName').setValue(
        this.authService.getCompany().name
      );
      this.manualCreationCargoService.cargoForm.get('idCompany').setValue(
        this.authService.getCompany().companyId
      );
      this.manualCreationCargoService.cargoForm.get('exclusive').setValue(
        this.authService.getCompany().exclusive
      );
      this.manualCreationCargoService.cargoForm.get('ministry').setValue(
        this.authService.getCompany().ministry
      );
      this.listCompaniesAsync = this.companySelectCtrl.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        map(company => company ? this.filterCompanies(company) : this.listCompanies ? this.listCompanies.slice() : [])
      );
      this.setCompanyFormControl();
    }
  }

  setCompanyFormControl() {
    if (this.manualCreationCargoService.cargoForm.get('idCompany').value.length) {
      const companyFilter = this.listCompanies.filter((company) => company.companyId === this.manualCreationCargoService.cargoForm.get('idCompany').value);
      const company = companyFilter[0];
      company.ministry = this.manualCreationCargoService.cargoForm.get('ministry').value;
      this.companySelectCtrl.setValue(company);
      this.onSelectCompany(({
        option: {
          value: company
        }
      }) as MatAutocompleteSelectedEvent, true);
    }
  }

  onSelectThirdParty($event: MatAutocompleteSelectedEvent, autoSetValue?: boolean) {
    if ($event.option.value) {
      if (!autoSetValue) {
        this.addressSelected = null;
      }
      this.thirdPartySelected = $event.option.value;
      this.getAddress(($event.option.value as any).document, this.options.city.municipalityCode, autoSetValue);
    } else {
      this.openDialog();
      this.newThirdParty = true;
    }
  }

  selectionChange() {
    if (this.inputFormControl.get('address')) {
      this.inputFormControl.get('address').setValue("");
      this.thirdPartySelected = null;
    }
  }

  addressChange() {
    this.addressSelected = null;
  }

  onSelectCompany($event: MatAutocompleteSelectedEvent, autoSetValue?: boolean) {
    this.thirdPartySelected = null;
    this.addressSelected = null;
    this.companySelect = { ...$event.option.value };

    if (this.utils.isDefined(this.companySelect.utility) && this.companySelect.utility >= 0) {
      this.manualCreationCargoService.setUtilityOwner(this.companySelect.utility);
    } else {
      this.manualCreationCargoService.setUtilityOwner(ShippingCost.MINIMUN_UTILITY);
    }

    if (this.utils.isDefined(this.companySelect.utilityAdditionalService) && this.companySelect.utilityAdditionalService >= 0) {
      this.manualCreationCargoService.setutilityAdditionalServiceOwner(this.companySelect.utilityAdditionalService);
    } else {
      this.manualCreationCargoService.setutilityAdditionalServiceOwner(ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST);
    }

    if (this.utils.isDefined(this.companySelect.advancePercentage) && this.companySelect.advancePercentage >= 0) {
      this.manualCreationCargoService.setAdvancePercentageOwner(this.companySelect.advancePercentage);
    } else {
      this.manualCreationCargoService.setAdvancePercentageOwner(this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : this.authService.getCompany() && this.authService.getCompany().advancePercentage ? this.authService.getCompany().advancePercentage : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES);
    }

    this.emitToParent.emit(this.companySelect);

    if (this.manualCreationCargoService.cargoForm.get('idCompany').value.length && this.manualCreationCargoService.cargoForm.get('idCompany').value !== this.companySelect.companyId) {
      const valueMinistry = this.manualCreationCargoService.cargoForm.get('ministry').value;
      this.manualCreationCargoService.resetCargoForm();
      this.manualCreationCargoService.cargoForm.get('ministry').setValue(valueMinistry);
    }

    if (this.manualCreationCargoService.cargoForm.get('ministry').value) {
      if (this.title === 'Propietario de la Carga') {
        this.cargoCompanyForm = this.companySelect;
        this.getAddress(this.companySelect.companyId, null, autoSetValue);
      } else {
        this.getThirdParties();
      }
    } else {
      if (this.companySelect)
        this.cargoCompanyForm = this.companySelect;
    }
  }

  set cargoCompanyForm(company: Company) {
    this.manualCreationCargoService.cargoForm.get('idCompany').setValue(
      company.companyId
    );
    this.manualCreationCargoService.cargoForm.get('businessName').setValue(
      company.name
    );
    this.manualCreationCargoService.cargoForm.get('exclusive').setValue(
      company.exclusive
    );
  }

  filterCompany(nit: string) {
    const company = this.listCompanies.filter((company: Company) => {
      return company.companyId === nit;
    });
    if (company.length) {
      return company[0];
    }
    return null;
  }

  onSelectAddress($event, value?) {
    if (($event && $event.option && $event.option.value) || value) {
      this.addressSelected = $event && $event.option && $event.option.value ? $event.option.value : value;
      let data = {};
      if (this.title === 'Propietario de la Carga') {
        data = {
          documentType: this.addressSelected.information.documentTypeId,
          documentNumber: this.addressSelected.information.document,
          branchOffice: this.addressSelected.address.id,
          name: this.addressSelected.information.name,
          location: { lat: '', lng: '' },
          address: this.addressSelected.address.address
        };
        this.inputFormControl.patchValue(data);
      } else if (this.simpleAddress) {
        this.emitToParent.emit(this.addressSelected);
      } else {
        data = {
          location: this.addressSelected && this.addressSelected.address && this.addressSelected.address.location ? this.addressSelected.address.location : { lat: '', lng: '' },
          address: this.addressSelected.address.address,
          id: this.addressSelected.address.id,
          thirdPartyConsignment: {
            documentType: this.addressSelected.information.documentTypeId,
            documentNumber: this.addressSelected.information.document,
            branchOffice: this.addressSelected.address.id,
            name: this.addressSelected.information.name,
            documentTypeName: this.addressSelected.information.documentTypeName
          }
        };
        this.inputFormControl.patchValue(data);
      }
      if (!this.simpleAddress) {
        this.emitToParent.emit({
          data,
          options: this.options
        });
      }

    } else {
      this.newAddress = true;
    }
  }

  openAddLatLng() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Por favor seleccione una ubicación',
      autocompleteAddress: true,
      required: true
    };
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state && result.address && result.address.location && result.address.location.lat && result.address.location.lng) {
        this.setLatLngAddress(result.address.location);
      }
    });
  }

  setLatLngAddress(location) {
    const addressObserver = {
      next: (success) => {
        this.spinner.hide();
        this.addressSelected.address['location'] = {
          lat: Number(location.lat),
          lng: Number(location.lng)
        }
        this.onSelectAddress(null, success);
        this.snackBarService.openSnackBar(FormMessages.ADDRESS_LOCATION_SETTED);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.ADDRESS_LOCATION_NOT_SETTED, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    let addressToSend = this.utils.clone(this.addressSelected);
    addressToSend.address['location'] = {
      lat: Number(location.lat),
      lng: Number(location.lng)
    }
    this.thirdPartyConsignmentService.setLatLngAddresses(addressToSend).subscribe(addressObserver);
  }
  displayThirdPartyName(thirdParty?: any): string | undefined {
    return thirdParty ? thirdParty.name : undefined;
  }

  displayCompanyName(company?: Company): string | undefined {
    return company ? company.name : undefined;
  }

  displayAddressName(address?: any): string | undefined {
    if (typeof address === 'string') {
      return address;
    } else {
      return address && address.address && address.address.address ? address.address.address : undefined;
    }
  }

  clearProperty(property) {
    this.ngZone.run(() => {
      this.newAddress = false;
      this.cityNewAddress = null;
      switch (property) {
        case 'addressSelected':
          this.emitToParent.emit({
            data: {
              focusInput: true
            },
            options: {
              clearListener: true
            }
          });
          break;
      }
    });
  }

  cancelNewAddress() {
    this.newAddress = false;
  }

  onSelectNewAddress($event) {
    if ($event && $event.options) {
      if (this.utils.isDefined($event.options.indexDestination)) {
        const destinationsArray = this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.destination') as FormArray;
        if (destinationsArray && destinationsArray.length) {
          const addressesDestinationsArray = destinationsArray.at($event.options.indexDestination).get('addresses') as FormArray;
          if (addressesDestinationsArray && addressesDestinationsArray.length) {
            const address = addressesDestinationsArray.at($event.options.index) as FormGroup;
            if (address) address.get('time').setValue('');
          }
        }
      } else if (this.utils.isDefined($event.options.index)) {
        const addressesOriginArray = this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.origin.addresses') as FormArray;
        if (addressesOriginArray && addressesOriginArray.length) {
          const address = addressesOriginArray.at($event.options.index) as FormGroup;
          if (address) address.get('time').setValue('');
        }
      }
    }

    if ($event.data && $event.data.focusInput) {
      this.emitToParent.emit($event);
    } else {
      let value = $event.data;
      if ($event && !$event.isValid) {
        value = {
          address: this.addressSelected,
          location: {
            lat: null,
            lng: null
          },
        }
      }
      if (this.manualCreationCargoService.getCargoForm().get('ministry').value && this.isInternational) {
        this.addressSelected = value;
        this.inputFormControl.patchValue(value);
      } else if (this.manualCreationCargoService.getCargoForm().get('ministry').value) {
        this.addressSelected = value;
      } else {
        if (value) this.inputFormControl.patchValue(value);
      }
      if ($event) {
        $event.data['refreshMarkers'] = true;
        this.emitToParent.emit($event);
      }
    }
  }

  createThirdParty() {
    if (
      this.utils.isDefined(this.addressSelected) &&
      this.utils.isDefined(this.addressSelected.address) &&
      this.utils.isDefined(this.addressSelected.location) &&
      this.utils.isDefined(this.addressSelected.location.lat) &&
      this.utils.isDefined(this.addressSelected.location.lng)
    ) {
      const data: any = {
        information: {},
        parent: null,
        address: this.addressSelected,
        municipalityCode: null
      };
      if (this.title === 'Propietario de la Carga') {
        data.parent = this.companySelect.companyId;
        data.municipalityCode = this.cityNewAddress;
        data.information = {
          documentTypeId: '3',
          documentTypeName: this.utils.toCapitalize(
            this.utils.getDocumentType('3').name
          ),
          document: this.companySelect.companyId,
          name: this.companySelect.name
        };
      } else {
        data.parent = this.manualCreationCargoService.formCargoOwner.value.documentNumber;
        data.municipalityCode = this.options.city.municipalityCode;
        data.information = {
          documentTypeId: this.thirdPartySelected.documentType,
          documentTypeName: this.utils.toCapitalize(
            this.utils.getDocumentType(this.thirdPartySelected.documentType).name
          ),
          document: this.thirdPartySelected.document,
          name: this.thirdPartySelected.name
        };
      }
      this.spinner.show();
      this.thirdPartyConsignmentService.createThirdParty(data).subscribe(
        (success: any) => {
          if (success) {
            this.spinner.hide();
            if (success.errorRNDC) {
              this.snackBarService.openSnackBar(success.errorRNDC.error, undefined, 'error');
            } else {
              this.snackBarService.openSnackBar('Dirección creada correctamente');
              this.clearProperty('addressSelected');
              if (this.title === 'Propietario de la Carga') {
                this.getAddress(data.information.document);
                this.onSelectAddress(null, success);
              } else {
                this.getAddress(this.thirdPartySelected.document, this.options.city.municipalityCode);
                this.onSelectAddress(null, success);
              }
            }
          } else {
            this.snackBarService.openSnackBar('Ocurrió un error al agregar la dirección', undefined, 'error');
          }
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al crear la dirección', undefined, 'error');
        }
      );
    } else {
      this.snackBarService.openSnackBar('Debes seleccionar una dirección', undefined, 'alert');
    }
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      newThirdParty: true,
      title: this.title,
      options: this.options
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(FormThirdPartyConsignmentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      result && result.state && this.getThirdParties();
    });
  }

  get showAddressField(): boolean {
    return (
      this.thirdPartySelected && this.thirdPartySelected.name ||
      (this.title === 'Propietario de la Carga' && this.companySelect && this.companySelect.name)
    ) &&
      !this.newAddress && this.manualCreationCargoService.getCargoForm().get('ministry').value;
  }

  disableByCity(address): boolean {
    return false;
  }

  get cargoStateEnum(): typeof CargoStateEnum {
    return CargoStateEnum;
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
    this.cdRef.detach();
  }
}

