<form class="routing__form--content" [formGroup]="form" (submit)="onSubmit()">
    <div class="routing__form--header">
        <label class="routing__form--label">Crear Ruteo</label>
    </div>

    <div class="routing__form--body">
        <mat-form-field appearance="outline" class="plate-field">
            <mat-label>Nombre<span class="asterisk">*</span></mat-label>
            <input type="text" matInput formControlName="description">
            <mat-error *ngIf="form.get('description').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('description').errors?.minlength">
                {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'nombre', 3) }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="date-field" appearance="outline">
            <mat-label>Fecha<span class="asterisk">*</span></mat-label>
            <input matInput (click)="pickerLoadDate.open()" [matDatepicker]="pickerLoadDate" [formControl]="dateCtrl"
                (dateChange)="onChangeDate($event)" readonly class="cursor-pointer" [min]="minDate">
            <mat-datepicker-toggle matPrefix [for]="pickerLoadDate">
            </mat-datepicker-toggle>
            <mat-datepicker #pickerLoadDate></mat-datepicker>
            <mat-error *ngIf="dateCtrl.errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>

    </div>

    <div class="button-container routing__form--footer">
        <button mat-raised-button color="primary" type="submit"
            [ngClass]="{'bg-color-primary':isValidStep, 'disabled-btn':!isValidStep}">
            Continuar
        </button>
    </div>

</form>