<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close({ state: true })"></i>
  <div class="col-md-12 mt-2">
    <label class="title-component">
      Agregar bonos y descuentos a {{ data.finantialInfo
      .value.type.name}}.
    </label>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="discounts-and-bonuses__header text-center">
        <h4 class="header__value text-strong">
          {{data.finantialInfo
          .value.financialInformation.rate | currency:'COP':'code'}}
        </h4>
        <label class="header__title text-strong title-2">
          Valor actual tarifa cliente
        </label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="discounts-and-bonuses__header text-center">
        <h4 class="header__value text-strong">
          {{data.finantialInfo
          .value.financialInformation.cost | currency:'COP':'code'}}
        </h4>
        <label class="header__title text-strong title-2">
          Valor actual costo {{ data.person}}
        </label>
      </div>
    </div>
  </div>
  <span class="separator-horizontal"></span>

  <div class="discounts-and-bonuses__body">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Tipo de acción<span class="asterisk">*</span></mat-label>
          <mat-select (selectionChange)="refreshListTypeModification($event)" formControlName="type">
            <mat-option [value]="0">
              Bonificación
            </mat-option>
            <mat-option [value]="1">
              Descuento
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Tipo de Modificacion<span class="asterisk">*</span></mat-label>
          <mat-select [formControl]="modifications" (selectionChange)="refreshModifications($event)">
            <mat-option *ngFor="let type of typeModifications" [value]="type">
              {{type.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="modifications.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Valor para la tarifa<span class="asterisk">*</span></mat-label>
          <input type="text" currencyMask matInput formControlName="amountRate" (input)="checkValidations()">
          <mat-error *ngIf="form.get('amountRate').errors?.min">
            {{ utils.giveMessageError('AT_LEAST_ONE_FIELD') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Valor para el costo<span class="asterisk">*</span></mat-label>
          <input type="text" currencyMask matInput formControlName="amount" (input)="checkValidations()">
          <mat-error *ngIf="form.get('amount').errors?.min">
            {{ utils.giveMessageError('AT_LEAST_ONE_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12 mt-2">
        <mat-form-field appearance="outline">
          <mat-label>Observación</mat-label>
          <textarea matInput formControlName="observation"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <span class="separator-horizontal"></span>
  <div class="row" *ngIf="!validUtility">
    <div class="col-md-12 text-center text__color color--error text-strong">
      <span *ngIf="utilityCargoResult >= 0; else additionalsElse">
        La utilidad no debe ser menor a {{utilityCargoResult}}%
      </span>
      <ng-template #additionalsElse>
        <span class="">
          La utilidad no debe ser menor a {{ MinUtility }}%
        </span>
      </ng-template>
      <span class="separator-horizontal"></span>
    </div>
  </div>
  <div class="row">
    <!-- totalCost -->
    <div class="col-md-6">
      <div class="discounts-and-bonuses__header text-center">
        <span class="header__value text-strong title-1 font-secondary-dark"
          [ngClass]="{'text__color color--error': cargoItemService.getNewValueRateAdditionals(data.finantialInfo.value, form.get('amountRate').value, form.get('type').value) < 0}">
          {{cargoItemService.getNewValueRateAdditionals(data.finantialInfo.value, form.get('amountRate').value,
          form.get('type').value) |
          currency:'COP':'code'}}
        </span>

        <label class="header__title text-strong title-2"
          [ngClass]="{'text__color color--error': cargoItemService.getNewValueRateAdditionals(data.finantialInfo.value, form.get('amountRate').value, form.get('type').value) < 0}">
          {{ cargoItemService.getNewValueRateAdditionals(data.finantialInfo.value, form.get('amountRate').value,
          form.get('type').value) >= 0 ?
          'Valor de la tarifa aplicando ' + (form.get('type').value ? 'el descuento' : 'la bonificación') : 'El valor no
          puede quedar en negativo' }}
        </label>
      </div>
    </div>


    <!-- rate -->
    <div class="col-md-6">
      <div class="discounts-and-bonuses__header text-center">
        <span class="header__value text-strong title-1 font-secondary-dark"
          [ngClass]="{'text__color color--error': cargoItemService.getNewValueCostAdditionals(data.finantialInfo.value, form.get('amount').value, form.get('type').value) < 0}">
          {{cargoItemService.getNewValueCostAdditionals(data.finantialInfo.value, form.get('amount').value,
          form.get('type').value) |
          currency:'COP':'code'}}
        </span>
        <label class="header__title text-strong title-2"
          [ngClass]="{'text__color color--error': cargoItemService.getNewValueCostAdditionals(data.finantialInfo.value, form.get('amount').value, form.get('type').value) < 0}">
          {{ cargoItemService.getNewValueCostAdditionals(data.finantialInfo.value, form.get('amount').value,
          form.get('type').value) >= 0 ?
          'Costo del ' + data.person + ' aplicando ' + (form.get('type').value ? 'el descuento' : 'la bonificación') :
          'El valor no puede quedar en negativo' }}
        </label>
        <label class="header__title text-strong title-2 mt-3"
          [ngClass]="{'text__color color--error': form.get('type').value === 0 && cargoItemService.getNewValueCostAdditionals(data.finantialInfo.value, form.get('amount').value, form.get('type').value) > cargoItemService.getNewValueRateAdditionals(data.finantialInfo.value, form.get('amountRate').value, form.get('type').value)}"
          *ngIf="form.get('type').value === 0 && cargoItemService.getNewValueCostAdditionals(data.finantialInfo.value, form.get('amount').value, form.get('type').value) > cargoItemService.getNewValueRateAdditionals(data.finantialInfo.value, form.get('amountRate').value, form.get('type').value)">
          {{'El costo no puede quedar mayor a la tarífa'}}
        </label>
      </div>
    </div>
  </div>
  <!-- Actions -->
  <div class="discounts-and-bonuses__footer">
    <div class="button-container">
      <button mat-raised-button class="primary-secondary button-width-auto" type="button" color="primary"
        (click)="dialogRef.close()">Cancelar</button>
      <button mat-raised-button color="primary" class="button-width-auto bg-color-primary"
        type="submit">Guardar</button>
    </div>
  </div>


</form>