

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { CollectionPointListComponent } from './collection-point-list/collection-point-list.component';
import { CollectionPointDetailComponent } from './collection-point-detail/collection-point-detail.component';
import { MainComponent } from 'src/app/layout/main/main.component';

const routes: Routes = [
    {
        path: '',
        data: { breadcrumb: 'Puntos' },
        children: [
            {
                path: 'list',
                data: { breadcrumb: 'Listado' },
                component: CollectionPointListComponent,
                canActivate: [UserActiveGuard, RoleGuard],
                resolve: {
                    UserResolver
                }
            },
            {
                path: 'detail',
                data: { breadcrumb: 'Detalle' },
                component: CollectionPointDetailComponent,
                canActivate: [UserActiveGuard],
                resolve: {
                    UserResolver
                }
            },
            {
                path: 'create',
                data: { breadcrumb: 'Creación' },
                component: CollectionPointDetailComponent,
                canActivate: [UserActiveGuard],
                resolve: {
                    UserResolver
                }
            },
            { path: '**', redirectTo: '/collection-point/list' }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class CollectionPointRoutingModule { }
