import { Component } from '@angular/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacyPolicy.component.html',
    styleUrls: ['./privacyPolicy.component.scss']
})

export class PrivacyPolicyComponent {
  
}