<app-modal #modalExportReportXLSX [titleModal]="titles.titleModalExportXLSX" [titleCancel]="titles.titleModalNo"
  [titleConfirm]="'Si'" (onConfirm)="onConfirm()"></app-modal>
<app-modal #modalExportNovelties [titleModal]="titles.titleModalExportNovelties" [titleCancel]="titles.titleModalNo"
  [titleConfirm]="'Si'" (onConfirm)="onConfirmModalNovelties()"></app-modal>

<aside id="mainReports" class="col-md-12 mb-md-0 mb-12">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="ctn-list-fixes container-list reports-padding">
        <div class="container-list-filter">
          <app-reports-filter #reportsFilter (openModalExportReportEmitter)="openModalExportReport($event)"
            (generateExcel)="generateExcel($event)" (chartOptionsTrigger)="chartOptionsTrigger($event)"
            (onFilter)="onFilter($event)" (onCloseModal)="closeModal()" class="row" [isPlainReport]="isPlainReport"
            [isPDFReport]="isPDFReport"></app-reports-filter>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <div>
    <div>
      <div class="type-chart-container d-flex flex-row align-items-baseline" *ngIf="chartOptions">
        <div>Tipo de gráfica </div>
        <div>
          <ul class="list-types-chart mb-0">
            <li class="item-type-chart cursor-pointer" (click)="changeTypeChart(typeChart.name)"
              *ngFor="let typeChart of typesChart">
              <img src="assets/img/icon-{{typeChart.name}}.png" alt="">
            </li>
          </ul>
        </div>
      </div>
      <canvas #canvasReport id="canvasReport" class="px-3"></canvas>
    </div>
    <br>
    <div class="chart-legend-container">
      <p class="legend-item" *ngFor="let item of datasetsReportsLegend">
        <span class="legend-item-icon" [ngStyle]="{'background-color': item.backgroundColor}"></span>
        <span class="legend-item-label">{{ item.label }}</span>
        <span class="legend-item-sublabel">&nbsp;({{ item.data }})</span>
      </p>
    </div>
  </div>
</aside>



<div id="htmlDataReport">
  <div>
    <canvas #canvasReportPDF id="canvasReportPDF"></canvas>
  </div>
  <br>
  <div class="chart-legend-container chart-legend-container-PDF">
    <p class="legend-item" *ngFor="let item of datasetsReportsLegend">
      <span class="legend-item-icon" [ngStyle]="{'background-color': item.backgroundColor}"></span>
      <span class="legend-item-label">{{ item.label }}</span>
      <span class="legend-item-sublabel">&nbsp;({{ item.data }})</span>
    </p>
  </div>
</div>