import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { startWith, debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import { Utils } from "src/app/core/resources/utils";
import __nomenclatureList from '__mock/nomenclatureDIAN.json';
import { ModelFragmentedAddress } from "src/app/core/interfaces/fragmentedAddress";

@Component({
  selector: "app-fragmented-address",
  templateUrl: "./fragmented-address.component.html",
  styleUrls: ["./fragmented-address.component.scss"],
  providers: [ModelFragmentedAddress]
})
export class FragmentedAddressComponent implements OnInit {

  reactiveFormAddress: ReactiveForm;
  nomenclatureListAsync: Observable<string[]>;
  nomenclatureList: string[] = __nomenclatureList;

  constructor(
    private formBuilder: FormBuilder,
    private utils: Utils,
    private modelFragmentedAddress: ModelFragmentedAddress
  ) {
    this.initFormAddress();
  }

  ngOnInit() {
  }

  private initFormAddress(): void {
    this.reactiveFormAddress = new ReactiveForm(
      this.formBuilder,
      {
        nomenclature: '',
        mainRoad: '',
        secundaryRoad: '',
        complementaryRoad: ''
      }
    );
    this.reactiveFormAddress.setValidatorsForm(
      this.modelFragmentedAddress.validatorsFragmentedAddress,
      this.form
    );
    this.nomenclatureListAsync = this.reactiveFormAddress.form.get('nomenclature').valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      map(value => {
        return this.filterNomenclature(value);
      })
    );
  }

  public get form(): FormGroup {
    if (!this.utils.isEmpty(this.reactiveFormAddress) && !this.utils.isEmpty(this.reactiveFormAddress.form)) {
      return this.reactiveFormAddress.form;
    }
    return null;
  }

  private filterNomenclature(value): string[] {
    if (value && typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.nomenclatureList.filter(option => {
        return option.toLowerCase().indexOf(filterValue) !== -1;
      });
    }
    return [];
  }


}
