import { ProductType } from "./product-type.model";
import { UploadDownload } from "./upload-download.model";
import { VehicleType } from "./vehicle-type.model";

export class CargoFeature {

    private productType?: ProductType = new ProductType();
    private vehicleType?: VehicleType = new VehicleType();
    private uploadDownload?: UploadDownload = new UploadDownload();

    public getProductType(): ProductType {
        return this.productType || new ProductType();
    }

    public setProductType(productType: ProductType): void {
        this.productType = new ProductType();
        this.productType.deserialize(productType);
    }

    public getVehicleType(): VehicleType {
        return this.vehicleType || new VehicleType();
    }

    public setVehicleType(vehicleType: VehicleType): void {
        this.vehicleType = new VehicleType();
        this.vehicleType.deserialize(vehicleType);
    }

    public getUploadDownload(): UploadDownload {
        return this.uploadDownload;
    }

    public setUploadDownload(uploadDownload: UploadDownload) {
        this.uploadDownload = new UploadDownload();
        this.uploadDownload.deserialize(uploadDownload);
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
