<div class="loads-by-vehicle-container">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <p class="title text-break">No se puede pagar el anticipo</p>
  <p>Algunos de los vehículos de las cargas seleccionadas tienen asociadas dos o más cargas en ruta. Para poder
    continuar, debe pagar los anticipos, finalizar o anular las cargas <span class="text-bold">en orden
      cronológico</span> según sea el caso.</p>
  <div *ngFor="let loadsByVehicle of matchLoadsByVehicle">
    <p class="subtitle" *ngIf="loadsByVehicle?.licensePlate && loadsByVehicle?.loads?.length">La placa
      {{loadsByVehicle?.licensePlate}} tiene asociada{{ loadsByVehicle.loads.length === 1 ? '' : 's'}} {{
      loadsByVehicle.loads.length }} carga{{ loadsByVehicle.loads.length === 1 ? '' : 's'}} en ruta:</p>
    <div class="col-md-12 p-0 d-flex justify-content-center flex-column table-responsive">
      <table>
        <tr>
          <th class="title-table">ID carga</th>
          <th class="title-table">Fecha finalización del cargue</th>
          <th class="title-table">Estado de anticipo</th>
          <th class="title-table"></th>
        </tr>
        <tr *ngFor="let load of loadsByVehicle.loads; let i = index;">
          <td data-cell="ID carga">
            <p class="m-0 p-0 mb-2">{{load?.consecutive ? load.consecutive : '-'}}</p>
          </td>
          <td data-cell="Fecha finalización del cargue">
            <p class="m-0 p-0 mb-2">
              {{ load?.cargoFeature?.uploadDownload?.origin?.addresses[0]?.durationTime?.endDate ?
              formatDate(load?.cargoFeature?.uploadDownload?.origin?.addresses[0]?.durationTime?.endDate) : 'No ha
              finalizado el cargue'}}
            </p>
          </td>
          <td data-cell="Estado de anticipo">
            <p class="advance-state-container mb-2"><i class="fas mr-2"
                [ngClass]="{'fa-clock': getAdvanceState(load) === 'Pendiente', 'warning': getAdvanceState(load) === 'Pendiente', 'fa-check-circle': getAdvanceState(load) === 'Pagado'}"></i><span>{{getAdvanceState(load)}}</span>
            </p>
          </td>
          <td data-cell="">
            <p *ngIf="i === 0 && getAdvanceState(load) === 'Pendiente'" class="m-0 p-0 pr-2">
              <mat-checkbox [checked]="true" (change)="handleCheckboxChange($event, load)" class="m-0">
                <span [ngClass]="{'checked': isIncludeInLoadsToPay(load)}">Pagar anticipo</span>
              </mat-checkbox>
            </p>
            <ng-container *ngIf="i === 0 && getAdvanceState(load) === 'Pagado'" class="m-0 p-0 pr-2">
              <div class="button-container">
                <button type="button" mat-raised-button class="primary-secondary" color="primary"
                  (click)="deleteLoad(load)">
                  Anular carga
                </button>
                <button mat-raised-button class="bg-color-primary" color="primary" type="button"
                  (click)="endLoad(load)">
                  Finalizar carga
                </button>
              </div>
            </ng-container>
          </td>
        </tr>
      </table>
      <div *ngFor="let load of loadsByVehicle.loads; let j = index;" class="small-advance-table-container">
        <div>
          <p class="bg-color-primary table-label">Id carga</p>
          <p class="p-3">{{load?.consecutive ? load.consecutive : '-'}}</p>
        </div>
        <div>
          <p class="bg-color-primary table-label">Fecha finalización del cargue</p>
          <p class="p-3">{{ load?.cargoFeature?.uploadDownload?.origin?.addresses[0]?.durationTime?.endDate ?
            formatDate(load?.cargoFeature?.uploadDownload?.origin?.addresses[0]?.durationTime?.endDate) : 'No ha
            finalizado el cargue'}}</p>
        </div>
        <div>
          <p class="bg-color-primary table-label">Estado de anticipo</p>
          <p class="advance-state-container p-3"><i class="fas mr-2"
              [ngClass]="{'fa-clock': getAdvanceState(load) === 'Pendiente', 'warning': getAdvanceState(load) === 'Pendiente', 'fa-check-circle': getAdvanceState(load) === 'Pagado'}"></i><span>{{getAdvanceState(load)}}</span>
        </div>
        <div *ngIf="j === 0" class="mb-3">
          <p class="bg-color-primary table-label">Acción</p>
          <p *ngIf="getAdvanceState(load) === 'Pendiente'" class="p-3">
            <mat-checkbox [checked]="true" (change)="handleCheckboxChange($event, load)" class="m-0">
              <span [ngClass]="{'checked': isIncludeInLoadsToPay(load)}">Pagar anticipo</span>
            </mat-checkbox>
          </p>
          <ng-container *ngIf="getAdvanceState(load) === 'Pagado'">
            <div class="button-container p-3">
              <button type="button" mat-raised-button class="primary-secondary" color="primary"
                (click)="deleteLoad(load)">
                Anular carga
              </button>
              <button mat-raised-button class="bg-color-primary" color="primary" type="button" (click)="endLoad(load)">
                Finalizar carga
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <app-cargo-options *ngIf="cargoForOptionsAndTracking" hidden [cargo]="cargoForOptionsAndTracking"
    (loadDeleted)="loadDeletedOrFinished($event)"></app-cargo-options>
  <app-cargo-tracking *ngIf="cargoForOptionsAndTracking" hidden [entryLoad]="cargoForOptionsAndTracking"
    (loadFinished)="loadDeletedOrFinished($event)"></app-cargo-tracking>
  <div class="button-container mt-3" mat-dialog-actions>
    <button type="button" mat-raised-button class="primary-secondary" color="primary" (click)="dialogRef.close()">
      Cancelar
    </button>
    <button mat-raised-button class="bg-color-primary" [ngClass]="{'disable': checkedLoadsToPayAdvance.length === 0}"
      color="primary" type="button" [disabled]="checkedLoadsToPayAdvance.length === 0"
      (click)="dialogRef.close({loads: checkedLoadsToPayAdvance})">
      Reintentar pago
    </button>
  </div>
</div>