<aside id="mainCollectionPointList" class="col-md-12 mb-0 h-100-margin">

  <div class="container-list h-100-margin">

    <div class="container-list-header">
      <div class="row">
        <div class="col-md-10">
          <label class="title-component">
            <i class="fas fa-id-card"></i>
            Roles
          </label>
        </div>
        <div class="col-md-2 header-list-options text-right" permission-display
          [module]="permission.administration.module" [functionality]="permission.administration.createRoles">
          <button matTooltip="Crear Rol" mat-raised-button type=" button" class="button-width-auto"
            (click)="goToCreate()">
            <i class="fas fa-plus-circle font-primary-dark"></i>
            <span class="font-primary-dark">Crear</span>
          </button>
        </div>
      </div>
    </div>

    <div class="container-list-filter">
      <div class="row">
        <!-- Companies -->
        <div class="col-md-4">
          <app-select-company [options]="optionsCompany" [inputFormControl]="companySelected"></app-select-company>
        </div>
      </div>
    </div>

    <div class="container-list-body">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let role of listRoles; let i = index;">
        <app-item-role [role]="role"></app-item-role>
      </div>
    </div>

  </div>

</aside>