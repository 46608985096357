import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Dialog } from 'src/app/core/resources/dialog';

@Component({
    selector: 'app-modal-license-plate',
    templateUrl: './modal-license-plate.component.html',
    styleUrls: ['./modal-license-plate.component.scss']
})
export class ModalLicensePlateComponent implements OnInit {

    title: string;
    cargos: Array<any>;
    license: string;

    constructor(
        public dialogRef: MatDialogRef<ModalLicensePlateComponent>,
        @Inject(MAT_DIALOG_DATA) public paramsDialog: {
            dialog, title, cargos, license
        },
        private dialog: Dialog,
    ) {
        if (this.paramsDialog && this.paramsDialog.title) {
            this.title = this.paramsDialog.title;
        }
        if (this.paramsDialog && this.paramsDialog.cargos) {
            this.cargos = this.paramsDialog.cargos;
        }
        if (this.paramsDialog && this.paramsDialog.license) {
            this.license = this.paramsDialog.license;
        }
    }

    ngOnInit() { }

    closeDialog() {
        this.dialogRef.close({
            state: true
        });

    }
}
