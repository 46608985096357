import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Global } from 'src/app/core/resources/global';
import { StandardMapComponent } from '../standard-map/standard-map.component';

@Component({
  selector: 'app-modal-selection-map',
  templateUrl: './modal-selection-map.component.html',
  styleUrls: ['./modal-selection-map.component.scss']
})
export class ModalSelectionMapComponent implements OnInit {
  keyMarker: string = 'associateLocationThirdParty'
  mapOptions: OptionsAutocomplete = {
    zoom: 16,
    keyMarkerListener: this.keyMarker,
    clickMapIcon: this.global.pathMarkerDestination,
    initialClickMap: true,
  };
  addressSelected: string = '';
  dataMap = {};
  @ViewChild(StandardMapComponent, { static: false }) standardMap: StandardMapComponent;
  constructor(
    public global: Global,
    public spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<ModalSelectionMapComponent>,
  ) { }

  ngOnInit() { }

  async onClickMap($event) {
    let location;
    this.addressSelected = await this.transformCoordinatesToAddress($event.latLng.lat(), $event.latLng.lng());
    if (this.addressSelected) {
      location = { lat: $event.latLng.lat(), lng: $event.latLng.lng() };
      this.dataMap = { location, address: this.addressSelected };
    }

  }

  async transformCoordinatesToAddress(lat: number, lng: number): Promise<string> {
    let geocoderService = new google.maps.Geocoder;
    let latlng = { lat: lat, lng: lng };
    let address = '';
    this.spinner.show();
    await geocoderService.geocode({ 'location': latlng }, (results, status) => {
      if (results.length) {
        this.spinner.hide();
        address = results[0].formatted_address;
      } else this.spinner.hide();
    });
    return address;
  }

  onSelectAddress($event) {
    if ($event && $event.data && $event.data.location && $event.data.location.lat && $event.data.location.lng) {
      this.standardMap.removeMarker(this.keyMarker);
      this.standardMap.createMarker(this.keyMarker, $event.data.location.lat, $event.data.location.lng, null, this.global.pathMarkerDestination);
      this.standardMap.setCenterMap($event.data.location.lat, $event.data.location.lng, 16);
      this.dataMap = { location: $event.data.location, address: $event.data.address };
    }
  }

  sendDataMapa() {
    this.dialogRef.close({
      state: true,
      data: this.dataMap
    });
    this.dataMap = null;
  }
}
