import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/internal/Subscription';
import { Global } from 'src/app/core/resources/global';
import { AuthService } from 'src/app/core/services/authentication.service';
import AOS from 'aos';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { Updates } from 'src/app/core/messages/updates.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';

export let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})

export class RootComponent {

    subscription: Subscription;
    instanceModal: NgbModalRef;
    @ViewChild("modalNewVersion", { static: false }) modalNewVersion: ElementRef;

    constructor(
        private swUpdate: SwUpdate,
        private router: Router,
        private matDialog: MatDialog,
        private snackbarService: SnackBarService
    ) {
        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                browserRefresh = !this.router.navigated;
            }
        });
    }

    ngOnInit(): void {
        AOS.init({ startEvent: 'load' });
        window.addEventListener('load', AOS.refresh);

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                this.openDialogNewVersion();
            });
        }
    }

    openDialogNewVersion() {
        const config = new MatDialogConfig();
        config.data = {
            icon: false,
            iconError: false,
            title: `Nueva versión de Teclogi`,
            description: 'Hay una nueva versión de la plataforma disponible, ¿Desea cargarla ahora mismo?',
            showYesBtn: true,
            showNoBtn: true
        };
        config.maxHeight = ModalEnum.MAX_HEIGHT;
        config.width = ModalEnum.SMALL_WIDTH;
        config.maxWidth = ModalEnum.MAX_WIDTH;
        config.autoFocus = true;
        this.matDialog
            .open(DialogComponent, config)
            .afterClosed()
            .subscribe(
                (result) => {
                    result && result.state ? this.update() : this.dismiss();
                }
            );
    }

    dismiss() {
        let dismissions: string | number = localStorage.getItem('update_dismission');
        dismissions = !!dismissions ? parseInt(dismissions) + 1 : 1;
        if (dismissions > 3) {
            this.snackbarService.openSnackBar(Fmt.string(Updates.AUTOMATIC_UPDATE, `${3 - dismissions}`, `${dismissions < 2 ? 'veces' : 'vez'}`), 'x', "info");
            setTimeout(() => { this.update(); }, 30 * 1000);
        } else {
            dismissions == 3 ?
                this.snackbarService.openSnackBar(Fmt.string(Updates.NO_DISMISSABLE, `${3 - dismissions}`, `${dismissions < 2 ? 'veces' : 'vez'}`), 'x', "info") :
                this.snackbarService.openSnackBar(Fmt.string(Updates.DISMISSION_MESSAGE, `${3 - dismissions}`, `${dismissions < 2 ? 'veces' : 'vez'}`), 'x', "info");
            setTimeout(() => { this.openDialogNewVersion(); }, 5 * 60 * 1000);
            localStorage.setItem('update_dismission', `${dismissions}`);
        }

    }

    update() {
        localStorage.setItem('update_dismission', '0');

        // @ts-ignore
        !!window.location.reload.length ? window.location.reload(true) : window.location.reload();
    }
}
