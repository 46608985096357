import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Patterns } from 'src/app/core/resources/patterns';

@Component({
  selector: 'app-change-phone-dialog',
  templateUrl: './change-phone-dialog.component.html',
  styleUrls: ['./change-phone-dialog.component.scss']
})
export class ChangePhoneDialogComponent implements OnInit {
  phoneControl = new FormControl('', [Validators.pattern(this.patterns.CELLPHONE)]);

  constructor(
    public dialogRef: MatDialogRef<ChangePhoneDialogComponent>,
    private patterns: Patterns
  ) { }

  ngOnInit() {
  }

  submit() {
    if (this.phoneControl.valid) {
      this.dialogRef.close(this.phoneControl.value);
    }
  }

}
