<section class="item__list flex-column flex-sm-row" *ngIf="activeTab && activeTab==='Centros de Costo'">
    <div class="item__list--col">
        <label>Código</label>
        <span>{{costCenter.code}}</span>
    </div>
    <div class="item__list--col">
        <label>Nombre</label>
        <span>{{costCenter.name}}</span>
    </div>
    <div class="item__list--col">
        <label>Estado</label>
        <span>{{costCenter.active | basicState}}</span>
    </div>
</section>
