<form class="form-container" [formGroup]="form" (submit)="onSubmit()">
    <div class="container-form">
        <div class="title-1">
            Confirma tus datos básicos
        </div>
        <br>
        <div>
            <mat-form-field class="col-md-12">
                <mat-label>Email</mat-label>
                <input matInput [value]="email" type="text" id="email" disabled>
            </mat-form-field>
            <mat-form-field class="col-md-12">
                <mat-label>Tipo de documento</mat-label>
                <mat-select formControlName="documentType">
                    <mat-option [value]="DocumentTypeEnum.CEDULA_CIUDADANIA">Cédula de Ciudadanía</mat-option>
                    <mat-option [value]="DocumentTypeEnum.PASAPORTE">Pasaporte</mat-option>
                    <mat-option [value]="DocumentTypeEnum.NIT">NIT</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12">
                <mat-label>Número de documento</mat-label>
                <input matInput type="text" formControlName="document">
            </mat-form-field>
            <mat-form-field class="col-md-12">
                <mat-label>Celular</mat-label>
                <input matInput type="tel" formControlName="phone">
            </mat-form-field>
        </div>

        <div class="row">
            <div class="col-md-6 text-center">
                <mat-card-actions class="mt-4">
                    <button mat-raised-button color="primary" (click)="cancel()">Volver</button>
                </mat-card-actions>
            </div>
            <div class="col-md-6 text-center">
                <mat-card-actions class="mt-4">
                    <button mat-raised-button color="accent" type="submit">Continuar</button>
                </mat-card-actions>
            </div>
        </div>
    </div>
</form>