import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INCIDENT_ASSETS } from 'src/app/core/resources/incident_assets';

@Injectable({
  providedIn: 'root'
})
export class StandardMapService {

  constructor(
    private http: HttpClient
  ) { }


  public getIcon(icon): string {
    //retorna el icono al marker que corresponde a la incidencia
    for (const incidentAsset of INCIDENT_ASSETS) {
      if (incidentAsset.name === icon) {
        return `/assets/svg/icons/${incidentAsset.asset}`;
      }
    }
    return undefined;
  }

  public getSvgContent(path: string): Observable<string> {
    return this.http.get(path, { responseType: 'text' });
  }

}
