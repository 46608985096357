import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DateManager } from 'src/app/core/managers/date.manager';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-request-dates-to-monitor',
  templateUrl: './request-dates-to-monitor.component.html',
  styleUrls: ['./request-dates-to-monitor.component.scss']
})
export class RequestDatesToMonitorComponent implements OnInit {
  form: FormGroup = new FormGroup({
    start: new FormControl('', [Validators.required]),
    end: new FormControl('', [Validators.required])
  });

  minStartDate: Date = null;
  maxStartDate: Date = null;

  minEndDate: Date = null;
  maxEndDate: Date = null;

  constructor(
    public snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<RequestDatesToMonitorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.startDate) {
      this.form.get('start').setValue(new Date(data.startDate));
      this.form.get('start').disable();
      this.onChangeStartDate({ value: new Date(data.startDate) });
    }
  }

  ngOnInit() {
  }

  onChangeStartDate(e: any) {
    const startDate = e.value;
    this.minEndDate = startDate;
    this.maxEndDate = DateManager.add(startDate, 4, 'days');
  }

  onChangeEndDate(e: any) {
    const endDate = e.value;
    this.minStartDate = DateManager.add(endDate, -4, 'days');
    this.maxStartDate = endDate;
  }

  confirm() {
    if (this.form.valid) {
      const start = DateManager.setStartOfDay(this.form.get('start').value);
      const end = DateManager.setEndOfDay(this.form.get('end').value);

      this.dialogRef.close({
        state: 'confirm',
        dates: {
          start: start,
          end: end
        }
      });
    } else {
      this.snackBarService.openSnackBar('Las fechas no son válidas', 'Aceptar', 'alert');
    }
  }

  goBack() {
    this.dialogRef.close({
      state: 'goBack',
      dates: {
        start: null,
        end: null
      }
    });
  }

}
