import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { UserDocuments } from 'src/app/core/interfaces/userDocuments';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { AdminUsersService } from '../admin-users.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Permission } from 'src/app/core/resources/permission';
import { Roles } from 'src/app/core/enums/roles.enum';
import { FileNamePipe } from 'src/app/core/pipe/fileName.pipe';
import { DatePipe } from '@angular/common';
import { Patterns } from 'src/app/core/resources/patterns';
@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss'],
  providers: [FileNamePipe, DatePipe]
})
export class UserDocumentsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() hideBtn: boolean;
  @Input() role: Roles;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  instanceLogo: FileStorage;
  basePath: string;
  photo: string;
  @ViewChild('inputImage', { static: false }) inputImage: ElementRef;
  @ViewChild('inputFrontDocument', { static: false }) inputFrontDocument: ElementRef;
  @ViewChild('inputBackDocument', { static: false }) inputBackDocument: ElementRef;
  selectDocumentView: string;
  nameFileSelected: string;
  listTypeDocuments = [
    {
      name: 'Foto de perfil',
      value: 'profilePicture'
    },
    {
      name: 'Parte frontal de documento',
      value: 'frontDocument'
    },
    {
      name: 'Parte trasera de documento',
      value: 'backDocument'
    }
  ];
  visualProfilePicture: FormControl = new FormControl();
  visualFrontDocument: FormControl = new FormControl();
  visualBackDocument: FormControl = new FormControl();
  permission = Permission;

  constructor(
    public utils: Utils,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private angularFireStorage: AngularFireStorage,
    public dialog: MatDialog,
    private adminUsersService: AdminUsersService,
    public fileNamePipe: FileNamePipe,
    public datePipe: DatePipe,
    public patterns: Patterns
  ) { }

  /**
  * @description Initializes the visual documents formControls using the translateFileName method based on form input.
  */
  ngOnInit() {
    if (this.form) {
      if (this.form.get('backDocument')) this.visualBackDocument.setValue(this.traslateFileName(this.form.get('backDocument').value));
      if (this.form.get('frontDocument')) this.visualFrontDocument.setValue(this.traslateFileName(this.form.get('frontDocument').value));
      if (this.form.get('profilePicture')) this.visualProfilePicture.setValue(this.traslateFileName(this.form.get('profilePicture').value));
    }
  }

  /**
  * @description opens a modal to attach the user's profile photo, also executes openWindowFile method on modal closing.
  */
  openDialogProfilePhoto(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      observations: 'Por favor al adjuntar la foto verifica que : no debes tener gafas, tapabocas o  gorra. También se debe tomar a mitad de cuerpo',
      showBtnConfirmObservation: true
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refuse) {
        this.openWindowFile();
      }
    });
  }

  /**
  * @param {Event} e is the input's event (file) coming from html 
  * @param {string} type is the type of the image to upload
  * @description receives the image file, transforms and passes it to uploadFileStorage method
  */
  setInstanceFileStorageImg(e: Event, type: string): void {
    const splittedNameFile = e.target['files'][0]['name'].split('.');
    const formatFile = splittedNameFile[splittedNameFile.length - 1];
    const dateInMs = new Date().getTime();
    const fileName = `${type}_${dateInMs}.${formatFile}`;
    const modifiedFile = new File([e.target['files'][0]], fileName, {
      type: e.target['files'][0].type
    });
    if (!this.utils.isImage(e.target['files'])) {
      this.snackBarService.openSnackBar('El archivo no es una imagen', undefined, 'error');
      return;
    }
    this.instanceLogo = {
      fileData: {
        file: modifiedFile,
        name: fileName,
        uploaded: true,
        size: this.utils.bytesToSize(e.target['files'][0].size),
        url: null
      },
      storageData: {
        storageRef: null,
        uploadTask: null,
        uploadProgress: null
      }
    };
    this.uploadFileStorage('profilePicture');
  }

  /**
  * @returns {boolean} if the form's information.document is invalid returns true, if it's valid returns false
  * @description returns true and disables the pictures controls if the document is invalid, 
  * enables the pictures controls and returns false if the document is valid.
  */
  get isDisable(): boolean {
    if (this.form.get('information.document').invalid) {
      this.form.get('profilePicture').disable();
      this.form.get('backDocument').disable();
      this.form.get('frontDocument').disable();
      return true;
    }
    this.form.get('profilePicture').enable();
    this.form.get('backDocument').enable();
    this.form.get('frontDocument').enable();
    return false;
  }

  /**
  * @param {Event} e is the input's event (file) coming from html 
  * @param {string} typeFile is the type of the image to upload
  * @description receives the image file, transforms and passes it to uploadFileStorage method
  */
  setInstanceFileStorage(e: Event, typeFile?: string): void {
    const splittedNameFile = e.target['files'][0]['name'].split('.');
    const formatFile = splittedNameFile[splittedNameFile.length - 1];
    const dateInMs = new Date().getTime();
    const fileName = `${typeFile}_${dateInMs}.${formatFile}`;
    const modifiedFile = new File([e.target['files'][0]], fileName, {
      type: e.target['files'][0].type
    });

    this.instanceLogo = {
      fileData: {
        file: modifiedFile,
        name: fileName,
        uploaded: true,
        size: this.utils.bytesToSize(e.target['files'][0].size),
        url: null
      },
      storageData: {
        storageRef: null,
        uploadTask: null,
        uploadProgress: null
      }
    };
    this.uploadFileStorage(typeFile);
  }

  /**
  * @param {('inputFrontDocument'|'inputImage'|'inputBackDocument')} typeFile is the input asociated (default:'inputImage')
  * @description clicks the input passed from params
  */
  openWindowFile(typeFile: 'inputFrontDocument' | 'inputImage' | 'inputBackDocument' = 'inputImage'): void {
    this[typeFile].nativeElement.click();
  }

  /**
  * @param {string} typeFile is the image type to upload
  * @description uploads to storage the file depending the typeFile param and saves it into the respective formControls
  */
  uploadFileStorage(typeFile?: string): void {
    this.basePath = 'user/' + this.form.get('information.document').value + '/';
    let filePath = this.basePath + this.instanceLogo.fileData.name;

    if (typeFile == 'backDocument') {
      this.form.get('backDocument').setValue(filePath);
      this.visualBackDocument.setValue(this.traslateFileName(filePath));
    }
    if (typeFile == 'frontDocument') {
      this.form.get('frontDocument').setValue(filePath);
      this.visualFrontDocument.setValue(this.traslateFileName(filePath));
    }
    if (typeFile == 'profilePicture') {
      this.form.get('profilePicture').setValue(filePath);
      this.visualProfilePicture.setValue(this.traslateFileName(filePath));
    }
    this.instanceLogo.storageData.storageRef = this.angularFireStorage.ref(filePath);
    this.instanceLogo.storageData.uploadTask = this.angularFireStorage.upload(filePath, this.instanceLogo.fileData.file);
    this.instanceLogo.storageData.uploadTask.then(
      (data) => {
        this.instanceLogo.storageData.storageRef.getDownloadURL().subscribe(
          (urlFile) => {
            this.spinner.hide();
          }
        );
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  /**
  * @param {string} fileName is the file's name
  * @returns {string}  returns the file's name in the format expected to be uploaded.
  * @description transforms the file's name from route format into another specific string format using the userViewFileName method
  */
  traslateFileName(fileName: string): string {
    const nameSplit = fileName.split('/');
    const fileNameFormatted = nameSplit.length > 0 ? nameSplit[nameSplit.length - 1] : nameSplit[0];
    if (this.patterns.FILE_FORMAT_FULL.test(fileNameFormatted) || this.patterns.FILE_FORMAT.test(fileNameFormatted)) return this.userViewFileName(fileNameFormatted);
    return fileName;
  }

  /**
  * @description Checks forms.valid, if is not valid shows an snackBar of the error, 
  * else creates a body and passes it to updateUserDocuments method and emits an Output if the result is successfull
  */
  public onSubmit(): void {
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('information.document'), 'documento del conductor')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('profilePicture'), 'foto de perfil')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('frontDocument'), 'foto frontal del documento')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('backDocument'), 'foto trasera del documento')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      }
    } else if (!this.form.value.profilePicture && !this.form.value.backDocument && !this.form.value.frontDocument) {
      this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
    } else {
      this.spinner.show();
      const data: UserDocuments = {
        profilePicture: this.form.value.profilePicture,
        backDocument: this.form.value.backDocument,
        frontDocument: this.form.value.frontDocument
      }
      this.adminUsersService.updateUserDocuments(this.form.value.information.document, data).subscribe(
        (success: any) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Datos guardados correctamente', undefined, 'success');
          this.emitToParent.emit(this.form.value.information.document);
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al guardar los datos', undefined, 'error');
        }
      );
    }
  }

  /**
  * @description checks the selectDocumentValue from form and assign its value into nameFileSelected if exists.
  */
  public onChangeTypeDocument(): void {
    const value = this.form.get(this.selectDocumentView).value;
    this.nameFileSelected = value ? value : null;
  }

  /**
  * @param {string} fileName is the file's name formatted
  * @returns {string} returns the file's name transformed by fileNamePipe.
  * @description transforms the file's name using the fileNamePipe
  */
  userViewFileName(fileName: string): string {
    return this.fileNamePipe.transform(fileName);
  }

}
