import { Injectable } from "@angular/core";
import swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class MediaService {

  public static audio: HTMLAudioElement;

  constructor(
  ) {
  }

  private async requestNotificationPermission() {
    if (Notification.permission === 'granted')
      return;

    Notification
      .requestPermission()
      .then(
        (permission: NotificationPermission) => {
          if (permission === "default") {
            swal
              .fire('Notificaciones', 'Considera habilitar las notificaciones del navegador')
              .then(() => void 0)
          }
        }
      )
  }

  public async webPushNotification(title, body) {
    if (Notification.permission === 'default')
      await this.requestNotificationPermission();

    const notification = new Notification(title, { body });
    return notification;
  }

  public audioAlert(): void {
    if (this.isPaused) {
      MediaService['audio'] = new Audio('/assets/sound/alert.mp3');
      MediaService['audio']
        .play()
        .catch(e => null); // No hacer logs infinitos si no se ha interactuado con el documento
    }
  }

  public get isPaused(): boolean {
    return !MediaService['audio'] || MediaService['audio'].paused;
  }
}
