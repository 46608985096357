<div class="modal-body wrapper-modal position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="title-component mx-4">Corrija los teléfonos</div>
    <div>
        Se han encontrado los siguientes registros con los mismos teléfonos que intenta registrar,
        por favor corríjalos o corrija en el formulario los números de teléfono para continuar
    </div>
    <br />
    <table class="table table-striped">
        <thead>
            <th scope="col">Perfil</th>
            <th scope="col">Tipo de documento</th>
            <th scope="col">Documento</th>
            <th scope="col">Nombre</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Cambiar</th>
        </thead>
        <tbody>
            <tr *ngFor="let user of data; let i = index">
                <td>
                    <figure>
                        <img [src]="
                            !!user.profilePictureUrl ? user.profilePictureUrl : 'assets/img/default-profile.png'
                        " height="50" width="50">
                    </figure>
                </td>
                <td>{{user.information.documentTypeName}}</td>
                <td>{{user.information.document}}</td>
                <td>{{user.information.name}}</td>
                <td *ngIf="editing === i">
                    <mat-form-field appearance="standard">
                        <mat-label>Celular<span class="asterisk">*</span></mat-label>
                        <input matInput class="phone-input" [id]="'phone-input-' + i" [formControl]="newPhone">
                        <mat-error *ngIf="newPhone.errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="newPhone.errors?.minlength">
                            {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', 3) }}
                        </mat-error>
                        <mat-error *ngIf="newPhone.errors?.maxlength">
                            {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'celular', 12) }}
                        </mat-error>
                        <mat-error *ngIf="newPhone.errors?.pattern">
                            {{ utils.giveMessageError('INCORRECT_FORMAT', 'celular') }}
                        </mat-error>
                    </mat-form-field>

                </td>
                <td *ngIf="editing !== i">
                    {{user.phone}}
                </td>
                <td>
                    <i class="cursor-pointer fas fa-pencil-alt color-primary" (click)="edit(i)"
                        *ngIf="editing === null"></i>
                    <i class="cursor-pointer fas fa-check color-primary" (click)="confirm(i)"
                        *ngIf="editing !== null"></i>
                    <i class="cursor-pointer fas fa-times color-primary" (click)="cancel()"
                        *ngIf="editing !== null"></i>
                </td>
            </tr>
        </tbody>
    </table>
    <hr>
    <div class="w-100 text-center">
        <mat-card-actions>
            <button mat-raised-button color="primary" class="button-width-auto primary-secondary"
                (click)="closeDialog()">Cancelar</button>
            <button mat-raised-button color="primary" class="button-width-auto" (click)="save()">Guardar</button>
        </mat-card-actions>
    </div>
</div>