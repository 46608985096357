<div>
  <div class="container">
    <div id="mainFreightUpload" class="col-md-12 mb-md-0 mb-12">
      <div class="row">
        <!-- Title -->
        <div class="col-md-12 mb-md-0 mb-12">
          <label class="title">
            Datos de cargue
          </label>
        </div>
        <div class="col-md-12 mb-md-0 mb-12" *ngFor="let address of addresses let i = index;">
          <mat-card-content>
            <label class="label-field">Dirección #{{(i + 1)}}</label>
            <br>
            <span>{{address.controls.address.value}}</span>
            <div
              *ngIf="manualCreationCargoService.getVisualEstimatedTimeUpload(i, address.controls.time.value, getPreviosAddTime(i)) !== false">
              <div>
                <mat-card class="mt-3 message-card" style="width: 90%;">
                  <mat-card-content class="d-flex flex-row">
                    <p class="color-alert  mr-2">
                      <i class="fas fa-ban"></i>
                    </p>
                    <p class="color-alert">
                      El tiempo de entrega no es válido, llegar a este punto requiere al menos
                      {{manualCreationCargoService.getVisualEstimatedTimeUpload(i, address.controls.time.value,
                      getPreviosAddTime(i))}} (Recordar que el tiempo estimado incluye el tiempo pactado de la dirección
                      anterior)
                    </p>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
          <div class="row">
            <div class="col-md-12 mb-md-0 mb-12">
              <div class="row">
                <div class="col-lg-6 my-3 d-flex">
                  <!-- Hora de cargue -->
                  <mat-form-field class="time__address-list-time">
                    <mat-label>Hora de cargue<span class="asterisk">*</span></mat-label>
                    <mat-select (selectionChange)="onSelectTime($event,'time', i)" [formControl]="address.get('time')">
                      <mat-option [value]="time.value" *ngFor="let time of timeList">
                        {{time.title}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="address.get('time').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                  <mat-button-toggle-group class="time__address-type-time align-items-center justify-content-center"
                    #group="matButtonToggleGroup" [value]="address.controls.timeType.value">
                    <mat-button-toggle value="AM" (change)="onSelectTime($event, 'timeType', i)">
                      AM
                    </mat-button-toggle>
                    <mat-button-toggle value="PM" (change)="onSelectTime($event,'timeType', i)">
                      PM
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div class="col-lg-6 my-3">
                  <mat-form-field>
                    <mat-label>
                      Celular
                    </mat-label>
                    <input matInput type="text" onlyNumbersInt placeholder="Escribe el número de celular"
                      [formControl]="address.get('cellphone')">

                    <mat-error *ngIf="address.get('cellphone').errors?.pattern">
                      {{ utils.giveMessageError('INCORRECT_FORMAT', 'celular') }}
                    </mat-error>
                    <mat-error
                      *ngIf="address.get('cellphone').errors?.minlength && !address.get('cellphone').errors?.pattern">
                      {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', minLengthCellphone) }}
                    </mat-error>
                    <mat-error *ngIf="address.get('cellphone').errors?.maxlength">
                      {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'celular', maxLengthCellphone) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-lg-6" *ngIf="!isTeclogi">
                  <div class="row align-items-baseline">
                    <div class="col-5">
                      <mat-form-field>
                        <mat-label>Tiempo Pactado(horas):</mat-label>
                        <mat-select (selectionChange)="onSelectTime($event, 'timePact', i)"
                          [(value)]="address.controls.timePact.value">
                          <mat-option *ngFor="let hour of hourList" [value]="hour.value">
                            {{hour.title}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-2 d-flex justify-content-center">
                      <label>:</label>
                    </div>
                    <div class="col-5">
                      <mat-form-field>
                        <mat-label>Tiempo Pactado(minutos):</mat-label>
                        <mat-select (selectionChange)="onSelectTime($event, 'minutePact', i)"
                          [(value)]="address.controls.minutePact.value">
                          <mat-option *ngFor="let minute of minuteList" [value]="minute.value">
                            {{minute.title}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row" *ngIf="!manualCreationCargoService.isValidAddressDateTimeLoad(i)">
                <div class="col-md-12 message message--error">
                  La hora seleccionada de cargue debe ser mayor a la hora de cargue de la anterior
                  Dirección!
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Button -->
        <div class="col-12 button-container justify-content-center mt-3">
          <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
          <app-request-button></app-request-button>
          <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>