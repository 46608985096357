import { Component, Input, OnChanges, Output, EventEmitter, NgZone, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { GoogleService } from 'src/app/core/services/google.service';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { Patterns } from 'src/app/core/resources/patterns';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [GoogleService]
})

export class MapComponent implements OnChanges {
  // @Input() coordinates: string;
  @Input() cargo: Cargo;
  @Input() routeGoogle: Object;
  @Input() showRouteGoogle: boolean;
  @Input() activeLocation: { typeLocation, indexAddress, indexDestination };
  @Input() setLocationZoom: String;
  @Output() onClickMarker = new EventEmitter<any>();
  @Input() pointsHistory: Array<Object>;

  polylines: Array<object> = [];
  historyStrokeColor: string = "#584796";
  routeGoogleStrokeColor: string = "#02d7dc";
  pathClusterMarker: string;
  route: any = [];
  routeHistory: any = [];
  listRouteGoogle: any = [];
  origin: any;
  destination: any;
  currentLocation: any = {};
  markers: Array<object> = [];
  zoom: number = 14;
  center = {
    lat: null,
    lng: null
  };
  dataMap: any;

  constructor(
    private spinner: NgxSpinnerService,
    private ngZone: NgZone,
    public utils: Utils,
    private global: Global,
    private freightForwarderDetailService: CargoDetailService,
    private googleService: GoogleService,
    private adminUsersService: AdminUsersService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<MapComponent>,
    public vehiclesService: VehiclesService,
    private patterns: Patterns
  ) {
    this.pathClusterMarker = this.global.pathClusterMarker;
  }

  ngOnInit() {
    this.getLocation(
      position => this.setCenter(position.latitude || 4.6383104, position.longitude || -74.1359616)
    );
  }

  ngOnChanges(changes: any) {
    // if (changes.coordinates && changes.coordinates.currentValue){
    //   if (this.patterns.COORDINATES_FORMAT.test(this.coordinates)){
    //     const latlng = this.coordinates.split(',');
    //     const lati = parseFloat(latlng[0]);
    //     const lngi = parseFloat(latlng[1]);
    //     this.setCenter(lati, lngi);
    //     this.processMarkers(
    //       [{
    //         id: 'newLocationTracking',
    //         lat: lati,
    //         lng: lngi,
    //         zoom: 25
    //       }],
    //       'newLocationTracking'
    //     );
    //   } else {
    //     this.setCenter(4.6383104, -74.1359616)
    //   }

    // }

    if (changes.showRouteGoogle && changes.showRouteGoogle.currentValue) {
      if (this.utils.isDefined(changes.showRouteGoogle.currentValue)) {
        this.processRouteGoogle(changes.showRouteGoogle.currentValue);
      }
    }

    if (changes.activeLocation && this.utils.isDefined(changes.activeLocation.currentValue)) {
      this.activeLocation = changes.activeLocation.currentValue;
    }

    if (changes.setLocationZoom && this.utils.isDefined(changes.setLocationZoom.currentValue)) {
      this.setCenter(changes.setLocationZoom.currentValue.lat, changes.setLocationZoom.currentValue.lng)
    }

    if (changes.pointsHistory && this.utils.isDefined(changes.pointsHistory.currentValue)) {
      this.removePolyline('pointsHistory');
      this.routeHistory = changes.pointsHistory.currentValue;
      this.polylines.push({
        points: this.routeHistory,
        strokeColor: this.historyStrokeColor,
        id: 'pointsHistory'
      });
      // tslint:disable-next-line: max-line-length
      if (this.routeHistory && this.routeHistory.length)
        this.setCenter(this.routeHistory[this.routeHistory.length - 1].latitude, this.routeHistory[this.routeHistory.length - 1].longitude);
    }
  }

  createMarker(lat, lng, type, markerUrl?, data?) {

    let marker = {
      id: type,
      lat: lat,
      lng: lng,
      iconUrl: {
        url: ''
      }
    };
    if (data) {
      marker['data'] = data;
    }

    switch (type) {
      case this.global.markerPointRoute:
        marker.iconUrl.url = markerUrl;
        break;
      case this.global.markerTrackingFreightForwarder:
        marker.iconUrl.url = this.global.pathMarkerVehicle;
        break;
      default:
        marker.iconUrl.url = this.global.pathMarkerDestination;
        break;
    }
    return marker;
  }

  processRouteGoogle(showRouteGoogle) {
    if (this.utils.isDefined(this.cargo) && showRouteGoogle) {
      this.freightForwarderDetailService.getRouteGoogleCargo(this.cargo.id).subscribe(
        (success: any) => {
          if (success && success.overview_polylines && success.overview_polylines.length) {
            let count = 0;
            const maxCount = success.overview_polylines.length;
            success.overview_polylines.forEach((polyline, i) => {
              this.listRouteGoogle = this.listRouteGoogle.concat(google.maps.geometry.encoding.decodePath(polyline));
              count++;
              if (count === maxCount) {
                this.removePolyline('routeGoogle');
                this.polylines.push({
                  points: this.listRouteGoogle,
                  strokeColor: this.routeGoogleStrokeColor,
                  id: 'routeGoogle'
                });
                this.processMarkers(this.utils.getListLocationsCargo(this.cargo), this.global.markerPointRoute);
              }
            });
          } else {
            this.googleService.getRouteDataCargo(this.cargo).then(
              (response: { cargoDistancy, cargoEstimatedTime, cargoRoute }) => {
                let count = 0;
                const maxCount = response.cargoRoute.length;
                const thisClass = this;
                let overviewsPolyline = [];
                response.cargoRoute.forEach((route: any, i) => {
                  count++;
                  const overviewPolyline = (
                    route.routes[0].overview_polyline.points || route.routes[0].overview_polyline
                  );
                  overviewsPolyline.push(overviewPolyline);
                  const routeDecode = google.maps.geometry.encoding.decodePath(overviewPolyline);
                  thisClass.listRouteGoogle = thisClass.listRouteGoogle.concat(routeDecode);
                  if (count === maxCount) {
                    thisClass.polylines.push({
                      points: thisClass.listRouteGoogle,
                      strokeColor: thisClass.routeGoogleStrokeColor,
                      id: 'routeGoogle'
                    });
                    thisClass.processMarkers(
                      this.utils.getListLocationsCargo(this.cargo),
                      this.global.markerPointRoute
                    );
                    thisClass.saveRouteGoogleCargo(overviewsPolyline);
                  }
                });
              }
            ).catch(
              (error) => {
                this.listRouteGoogle = [];
                this.removePolyline('routeGoogle');
              }
            );
          }
        },
        (error) => {
          this.listRouteGoogle = [];
          this.removePolyline('routeGoogle');
        }
      );
    } else {
      this.listRouteGoogle = [];
      this.removePolyline('routeGoogle');
    }
  }

  processRouteGoogleSimulate(arrayRoute) {

    const overviewPolyline = (
      arrayRoute.routes[0].overview_polyline.points || arrayRoute.routes[0].overview_polyline
    );
    const routeDecode = google.maps.geometry.encoding.decodePath(overviewPolyline);
    this.routeHistory = routeDecode;
    this.removePolyline('pointsHistorySimulate');
    this.polylines.push({
      points: this.routeHistory,
      strokeColor: this.historyStrokeColor,
      id: 'pointsHistorySimulate'
    });
  }

  saveRouteGoogleCargo(overviewsPolyline) {
    this.freightForwarderDetailService.saveRouteGoogleCargo({
      cargoId: this.cargo.id,
      overview_polylines: overviewsPolyline
    }).subscribe(
      (success) => {

      },
      (error) => {
        console.error(error);
      },
    );
  }

  removePolyline(id) {

    this.polylines = this.polylines.filter(polyline => polyline['id'] !== id);
  }

  removeMarker(id) {

    this.markers = this.markers.filter(marker => marker['id'] !== id);
  }

  onSelectAddress($event) {
    if ($event && $event.data && $event.data.location) this.transformCoordinatesToAddress($event.data.location.lat, $event.data.location.lng);
  }

  onMapClick($event) {
    if (this.activeLocation && this.activeLocation.typeLocation || (this.dialogParams && this.dialogParams.clickMap)) {
      this.transformCoordinatesToAddress($event.coords.lat, $event.coords.lng);
    }
  }

  transformCoordinatesToAddress(lat: any, lng: any) {
    let thisClass = this;
    let geocoderService = new google.maps.Geocoder;
    let latlng = { lat: lat, lng: lng };
    this.spinner.show();
    geocoderService.geocode({ 'location': latlng }, function (results, status) {
      if (results.length) {
        thisClass.spinner.hide();
        const data = {
          address: results[0].formatted_address,
          location: {
            lat,
            lng
          },
          typeLocation: thisClass.activeLocation ? thisClass.activeLocation.typeLocation : null,
          indexAddress: thisClass.activeLocation ? thisClass.activeLocation.indexAddress : null,
          indexDestination: thisClass.activeLocation ? thisClass.activeLocation.indexDestination : null
        };
        thisClass.setCenter(lat, lng, 15);
        if (thisClass.dialogParams && thisClass.dialogParams.clickMap) {
          thisClass.dataMap = data;
          thisClass.processMarkers(
            [{ lat: data.location.lat, lng: data.location.lng }], 'default');
        } else {
          // emmit address transform
          thisClass.onClickMarker.emit(data);
        }
      } else {
        thisClass.spinner.hide();
      }
    });
  }

  processMarkers(locations: Array<object>, type: string, zoom?: number, locationZoom?: object, isCreation?: boolean) {
    if (locations.length) {
      const originLat = locations[0]['lat'];
      const destinationLat = locations[locations.length - 1]['lat'];
      this.ngZone.run(() => {
        this.removeMarker(type);
        for (let i = 0; i < locations.length; i++) {
          const markerUrl = this.getUrlMarker(originLat, destinationLat, locations[i]['lat'], i + 1);
          this.markers.push(
            this.createMarker(locations[i]['lat'], locations[i]['lng'], type, markerUrl, locations[i]['data'])
          );
        }
      });
    }
    const zoomValue = zoom || 16;
    if (zoom && locationZoom) {
      this.setCenter(locationZoom['lat'], locationZoom['lng'], zoom);
    } else {
      if (locations.length) {
        if (isCreation && locations[locations.length - 1]['lat'] && locations[locations.length - 1]['lng']) {
          this.setCenter(locations[locations.length - 1]['lat'], locations[locations.length - 1]['lng']);
        } else if (locations[0]['lat'] && locations[0]['lng']) {
          this.setCenter(locations[0]['lat'], locations[0]['lng']);
        } else if (this.center.lat && this.center.lng) {
          this.setCenter(this.center.lat, this.center.lng);
        }
        if (locations.length === 1) {
          this.setCenter(this.center.lat, this.center.lng, zoomValue);
        }
      }
    }
  }

  getUrlMarker(originLat, destinationLat, pointLat, position) {
    let url = '';
    if (originLat === pointLat) {
      url = this.global.pathMarkerOrigin;
    } else if (destinationLat === pointLat) {
      url = this.global.pathMarkerDestination;
    } else {
      url = 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + position + '|584796|ffffff';
    }

    return url;
  }

  applyChanges() {
  }

  getLocation(callback): void {
    let thisClass = this;
    if (window.navigator.geolocation) {
      thisClass.spinner.show();
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          thisClass.currentLocation = position.coords;
          thisClass.spinner.hide();
          callback(position.coords);
        },
        (failure) => {
          thisClass.spinner.hide();
          if (failure.message.indexOf("Only secure origins are allowed") == 0) {
            alert('Only secure origins are allowed by your browser.');
          }
        }
      );
    } else {
    }
  }

  setCenter(lat, lng, zoom?) {

    const thisClass = this;

    this.ngZone.run(() => {
      thisClass.center = {
        lat: null, lng: null
      };
      this.ngZone.run(() => {
        thisClass.center = {
          lat: lat,
          lng: lng
        };
      });

      if (zoom) {
        thisClass.zoom = null;
        this.ngZone.run(() => {
          thisClass.zoom = zoom;
        });
      }
    });
  }

  sendDataMapa() {
    this.dialogRef.close({
      state: true,
      data: this.dataMap
    });
    this.dataMap = null;
  }
}
