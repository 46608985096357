import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Guide } from 'src/app/core/interfaces/guide';
import { environment } from './../../../../environments/environment';
import { Utils } from 'src/app/core/resources/utils';

@Injectable({
    providedIn: 'root'
})
export class ListGenerateGuideService {

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        public utils: Utils
    ) {

    }

    getAllGuidesInCollection() {
        let url = environment.urlServerTeclogi + this._endpointResources.getGuides + 'state=Created' + '&pageKey=1&pageSize=50';
        return this._http.get<Guide[]>(
            url
        );
    }

    getGuide(guideId: string) {
        // let url = environment.urlServerTeclogi + this._endpointResources.getGuideFalabella + guideId + '&pageKey=1&pageSize=20';
        let url = environment.urlServerTeclogi + this._endpointResources.getGuideFalabella + guideId;
        return this._http.get<Guide[]>(
            url
        );
    }

    getAllGuidesInDistribution() {
        let url = environment.urlServerTeclogi + this._endpointResources.getGuides + 'state=In warehouse' + '&pageKey=1&pageSize=50';
        return this._http.get<Guide[]>(
            url
        );
    }

    getGuidesSelected(guides, property?) {
        if (property) {
            return _.filter(guides, function (guide) {
                return guide.checked >= true;
            }).map(function (guide) {
                return guide[property];
            });
        } else {
            return _.filter(guides, function (guide) {
                return guide.checked >= true;
            });
        }
    }

    getMultipleGuidesPDF(guides, isGuide?: boolean) {
        let url = environment.urlServerTeclogi + this._endpointResources.getMultipleGuidesPDF;
        if (this.utils.isDefined(isGuide)) {
            url = url + "&isGuide=" + isGuide;
        }
        return this._http.post(
            url,
            guides,
            {
                observe: 'response',
                responseType: 'blob'
            }
        ).pipe(
            timeout(300000)
        );
    }

    getAllGuidesPDF(state: string, withOutLabel?) {
        let url: string = environment.urlServerTeclogi + this._endpointResources.getAllGuidesPDF + state;
        if (withOutLabel) {
            url = url + '&withOutLabel=' + withOutLabel;
        }
        return this._http.get(
            url,
            {
                responseType: 'blob'
            }
        ).pipe(
            timeout(300000)
        );
    }

}
