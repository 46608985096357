import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  QueryList,
  ViewChildren,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import { Utils } from "src/app/core/resources/utils";
import { Global } from "src/app/core/resources/global";
import { NgxSpinnerService } from "ngx-spinner";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { VehiclesService } from "../list-vehicles.service";
import { Model, Vehicle } from "src/app/core/interfaces/vehicle";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { CatalogItem } from "src/app/core/interfaces/catalogItem";
import { ManualCreationCargoService } from "src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service";
import { VehicleType } from "src/app/core/interfaces/vehicleType";
import {
  MatAutocompleteSelectedEvent,
  MatButtonToggleChange,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Patterns } from "src/app/core/resources/patterns";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import { Observable, Subscription } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  pairwise,
  startWith,
  switchMap,
} from "rxjs/operators";
import { BasicPersonComponent } from "src/app/shared/basic-person/basic-person.component";
import { InputLicensePlateTrailerComponent } from "src/app/shared/input-license-plate-trailer/input-license-plate-trailer";
import { Permission } from "src/app/core/resources/permission";
import { orderBy } from "lodash";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { DateManager } from "src/app/core/managers/date.manager";
import { VehicleConfig } from "src/app/core/interfaces/vehicleConfig";
import { BodyWorkType } from "src/app/core/models/body-work-type.model";
import { BasicResponse } from "src/app/core/interfaces/basicResponse";
import { Trailer } from "src/app/core/interfaces/trailer";
@Component({
  selector: "app-trailer-vehicle",
  templateUrl: "./trailer-vehicle.component.html",
  styleUrls: ["./trailer-vehicle.component.scss"],
  providers: [AuthService, ManualCreationCargoService, Model],
})
export class TrailerVehicleComponent implements OnInit {
  permission = Permission;
  @Input() vehicle: Vehicle;
  @Input() title: string;
  @Input() form;
  trailer: Trailer;
  @ViewChildren(BasicPersonComponent)
  basicPersonComponent: QueryList<BasicPersonComponent>;
  documenTypes: CatalogItem[];
  vehicleTypes: VehicleType[];
  listTrailerBrand: CatalogItem[] = [];
  listBodyWork: CatalogItem[] = [];
  filterTrailerBrand: Observable<CatalogItem[]>;
  filterBodyWork: Observable<CatalogItem[]>;
  filterConfiguration: Observable<VehicleConfig[]>;
  filterConfigurationLoading = false;
  updateTrailer: boolean = false;
  reactiveForm = new ReactiveForm(this.formBuilder, this.modelVehicle.trailer);
  basePath: string;
  vehicleType: number = 41;
  bodyWorkCode: Array<string> = [
    "71",
    "72",
    "73",
    "74",
    "81",
    "82",
    "83",
    "84",
    "85",
  ];
  @ViewChild("inputTrailerPicture", { static: false })
  inputTrailerPicture: ElementRef;
  @ViewChild("inputPropertyCard", { static: false })
  inputPropertyCard: ElementRef;
  errorRNDCMessage: string = '';
  axlesSub: Subscription;
  descriptionSub: Subscription;
  constructor(
    private vehiclesService: VehiclesService,
    public utils: Utils,
    private global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private modelVehicle: Model,
    private matDialog: MatDialog,
    private patterns: Patterns,
    public dialogRef: MatDialogRef<TrailerVehicleComponent>,
    private dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA)
    public paramsDialog: {
      updateTrailer;
      form;
      vehicle: Vehicle;
    }
  ) {
    if (!this.paramsDialog || !this.paramsDialog.form) {
      this.form = this.reactiveForm.form;
      this.reactiveForm.setValidatorsForm(
        modelVehicle.trailerValidators,
        this.form
      );
    }
    this.documenTypes = this.utils.clone(this.global.documenTypes);
    this.getTrailerBrand();
    this.disableEnableFields();
  }

  /**
  * @description Validates the paramsDialog.updateTrailer to update local variables and subscribe to valueChanges of some form's controls
  */
  ngOnInit() {
    if (this.paramsDialog && this.paramsDialog.updateTrailer) {
      this.updateTrailer = this.paramsDialog.updateTrailer;
      this.vehicle = this.paramsDialog.vehicle;
    }
    this.filterTrailerBrand = this.form
      .get("brand.description")
      .valueChanges.pipe(
        startWith(""),
        distinctUntilChanged(),
        map((value: string | CatalogItem) => {
          return this.filterCatalog(value || "", this.listTrailerBrand);
        })
      );
    this.form
      .get("axles")
      .valueChanges.pipe(startWith(""), debounceTime(400), pairwise())
      .subscribe(([prevValue, selectedValue]) => {
        if (prevValue !== selectedValue) {
          this.onSelectConfiguration("configuration", {
            option: { value: { code: null, name: null } },
          } as MatAutocompleteSelectedEvent);
        }
      });
    this.filterConfiguration = this.form.get("axles").valueChanges.pipe(
      startWith(""),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((value) => {
        if (typeof value === "number" && value > 0) {
          this.filterConfigurationLoading = true;
          return this.getVehicleConfig(value)
            .toPromise()
            .then((response) => {
              return response;
            })
            .finally(() => {
              this.filterConfigurationLoading = false;
            });
        } else {
          return [];
        }
      })
    );

    this.onSelectConfiguration('configuration', { option: { value: this.form.get('configuration').value } } as any);
  }

  /**
  * @param {SimpleChanges} changes are the changes coming from parent's params
  * @description Updates the form's license plate and executes getDetailTrailer method if the vehicle input changes 
  */
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.updateTrailer) {
      if (changes) {
        if (changes.vehicle.currentValue) {
          this.form.get("licensePlate").setValue(this.vehicle.id);
          if (this.vehicle.trailerId && this.vehicle.trailerId.trim().length) {
            this.reactiveForm.disabledFieldsForm({ id: "" }, this.form);
            this.getDetailTrailer();
          }
        }
      }
    }
  }

  /**
  * @description Disables or enables some form's fields and makes some subscriptions to disable or enable another fields
  */
  private disableEnableFields() {
    this.form.get("configuration.description").disable();
    this.form.get("bodywork.description").disable();
    this.axlesSub = this.form.get("axles").valueChanges.subscribe((value) => {
      if (value === null || value === "") {
        this.form.get("configuration.description").disable();
      } else {
        this.form.get("configuration.description").enable();
      }
    });
    this.descriptionSub = this.form
      .get("configuration.description")
      .valueChanges.subscribe((value) => {
        if (value === null || value === "") {
          this.form.get("bodywork.description").setValue("");
          this.form.get("bodywork.description").disable();
        }

        if (
          value === "remolque" ||
          value === "semiremolque" ||
          value === "remolque balanceado"
        ) {
          this.form.get("bodywork.description").enable();
        } else {
          this.form.get("bodywork.description").setValue("");
          this.form.get("bodywork.description").disable();
        }
      });
  }

  /**
  * @description Gets the trailer's detail by vehicle's id and trailerId, and updates the form and the trailer with the response
  */
  private getDetailTrailer() {
    this.vehiclesService
      .detailTrailer(this.vehicle.id, this.vehicle.trailerId)
      .subscribe(
        (success: Trailer[]) => {
          if (success && success.length) {
            this.trailer = success[0];

            this.form.get("axles").setValue(this.trailer.axles);
            this.form.patchValue(this.trailer);
          }
        },
        (error) => { }
      );
  }

  /**
  * @param {number} axles is the number of axles of the trailer
  * @returns {Observable<VehicleConfig[]>} returns a list of vehicle configurations depending of trailer's axles
  * @description Gets a list of vehicle config by axles param
  */
  private getVehicleConfig(axles: number): Observable<VehicleConfig[]> {
    return this.vehiclesService.getVehicleConfig(axles);
  }

  /**
  * @param {string | CatalogItem} value is the written description
  * @returns {CatalogItem[]} returns a list of catalogItem filtered
  * @description Gets a list of filtered catalogItem
  */
  private filterCatalog(value: string | CatalogItem, list: CatalogItem[]): CatalogItem[] {
    if (value) {
      const filterValue =
        typeof value === "string"
          ? value.toLowerCase()
          : value.name.toLowerCase();
      return list.filter((option) => {
        return option.name.toLowerCase().indexOf(filterValue) !== -1;
      });
    } else {
      return list;
    }
  }

  /**
  * @param {string} bodyWorkCode is the bodyWork code selected
  * @description Updates the listBodyWork and the filterBodyWork
  */
  private getBodyWork(bodyWorkCode: string): void {
    let found = false;
    this.bodyWorkCode.forEach((code) => {
      if (bodyWorkCode === code) {
        this.vehicleType = 24;
        found = true;
      }
      if (!found) {
        this.vehicleType = 41;
      }
    });

    //servicio que me trae las marcas y las carrocerias
    this.vehiclesService.getBodyWork(this.vehicleType).subscribe(
      (success: BodyWorkType[]) => {
        if (success) {
          this.listBodyWork = orderBy(success, ["name"]);
        }
      },
      (error) => { },
      () => {
        this.filterBodyWork = this.form
          .get("bodywork.description")
          .valueChanges.pipe(
            startWith(""),
            distinctUntilChanged(),
            map((value: string | CatalogItem) => {
              return this.filterCatalog(value || "", this.listBodyWork);
            })
          );
      }
    );
  }

  /**
  * @description Fills the listTrailerBrand with the Catalog of Trailer brands obtained from backend
  */
  private getTrailerBrand() {
    this.vehiclesService.getTrailerBrand().subscribe((data) => {
      this.listTrailerBrand = data.catalog;
    });
  }

  /**
  * @returns {FormGroup} returns the form's owner FormGroup
  * @description Gets the form's owner FormGroup
  */
  get ownerControls(): FormGroup {
    return this.form.get("owner") as FormGroup;
  }

  /**
  * @returns {FormGroup} returns the form's admin FormGroup
  * @description Gets the form's admin FormGroup
  */
  get adminControls(): FormGroup {
    return this.form.get("administrator") as FormGroup;
  }

  /**
  * @param {MatButtonToggleChange} $event is the event of checking if the admin is the same owner
  * @description If the admin is the same owner disables the adminControls, otherwise enables them. Also executes clearOwnerForm method.
  */
  onChangeIsSameAdmin($event: MatButtonToggleChange) {
    $event.value ? this.adminControls.disable() : this.adminControls.enable();
    this.clearOwnerForm();
  }

  /**
  * @description Resets the adminControls with default values (modelVehicle values)
  */
  private clearOwnerForm() {
    this.adminControls.patchValue(this.modelVehicle.trailer.administrator);
  }

  /**
  * @description Validates the form and updates the trailer if everything is valid with changeNewTrailer method
  */
  onSubmit() {
    this.form.get("licensePlate").setValue(this.vehicle.id);
    this.validateIsSameAdmin();
    (this.basicPersonComponent as QueryList<BasicPersonComponent>)
      .toArray()
      .map((component) => {
        component.formElement.nativeElement.click();
      });
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('id'), 'placa', 6, 6)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('axles'), 'número de ejes', null, null, 1)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('configuration.code'), 'clase')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('configuration.description'), 'clase')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('bodywork.code'), 'carrocería')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('bodywork.description'), 'carrocería')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('brand.code'), 'marca')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('brand.description'), 'marca')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('model'), 'modelo', 4, 4, null, DateManager.getYear())) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('emptyWeight'), 'peso', null, null, 3000)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('licensePlate'), 'placa del vehículo', 6, 6)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('propertyCard'), 'tarjeta de propiedad')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('picture'), 'foto del trailer')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('owner.documentTypeId'), 'tipo de identificación del propietario')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('owner.documentTypeName'), 'tipo de identificación del propietario')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('owner.document'), 'no. de identificación del propietario', 3, 10)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('owner.name'), 'nombre del propietario')) return;
      else if (!this.form.get('hasAdministrator').value && this.utils.errorMessagesCustomized(this.form.get('administrator.documentTypeId'), 'tipo de identificación del administrador')) return;
      else if (!this.form.get('hasAdministrator').value && this.utils.errorMessagesCustomized(this.form.get('administrator.documentTypeName'), 'tipo de identificación del administrador')) return;
      else if (!this.form.get('hasAdministrator').value && this.utils.errorMessagesCustomized(this.form.get('administrator.document'), 'no. de identificación del administrador', 3, 10)) return;
      else if (!this.form.get('hasAdministrator').value && this.utils.errorMessagesCustomized(this.form.get('administrator.name'), 'nombre del administrador')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      }
    } else {
      this.spinner.show();
      if (this.updateTrailer) {
        this.changeNewTrailer();
      } else {
        const dataTrailer = this.vehiclesService.getTrailerObject(
          this.form.value
        );
        if (this.utils.isEmpty(dataTrailer.id)) {
          dataTrailer.id = this.trailer && this.trailer.id ? this.utils.clone(this.trailer.id) : this.vehicle.trailerId;
        }
        this.vehiclesService
          .createTrailer(dataTrailer)
          .toPromise()
          .then((success: BasicResponse) => {
            if (success && success.errorRNDC && success.errorRNDC.error) this.errorRNDCMessage = success.errorRNDC.error;
            else if (success && success.message) this.snackBarService.openSnackBar(success.message);
            else {
              this.snackBarService.openSnackBar(
                "Trailer actualizado correctamente"
              );
            }
          })
          .catch((error) => {
            this.snackBarService.openSnackBar(
              "Ocurrió un error al actualizar el trailer",
              undefined,
              "error"
            );
          })
          .finally(() => {
            this.spinner.hide();
          });
      }
    }
  }

  /**
  * @description Updates the trailer
  */
  private changeNewTrailer() {
    const licencePlate = this.vehicle.id;
    this.form.get("licensePlate").setValue(licencePlate);
    this.vehiclesService
      .updateTrailer(
        this.vehiclesService.getTrailerObject(this.form.value),
        licencePlate
      )
      .toPromise()
      .then((success) => {
        this.snackBarService.openSnackBar("Trailer asignado correctamente");
        this.dialogRef.close({ state: true, trailerId: this.form.value.id });
      })
      .catch((error) => {
        if (error && error.errorRNDC && error.errorRNDC.error) {
          this.snackBarService.openSnackBar(
            error.errorRNDC.error,
            undefined,
            "error"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al asignar el nuevo el trailer",
            undefined,
            "error"
          );
        }
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  /**
  * @description Checks if the admin is the same owner to put the owner info into admin info.
  */
  private validateIsSameAdmin() {
    if (this.form.get("hasAdministrator").value) {
      this.form.get("administrator").patchValue(this.form.get("owner").value);
    }
  }

  /**
  * @param {string} nameControl is the name of the control to update
  * @param {MatAutocompleteSelectedEvent} $event is the event with the calalog element selected
  * @description Updates the fields asociated with nameControl with the element selected
  */
  onSelectCatalog(nameControl: string, $event: MatAutocompleteSelectedEvent) {
    this.form.get(`${nameControl}.code`).setValue($event.option.value.id);
    this.form
      .get(`${nameControl}.description`)
      .setValue($event.option.value.name);
  }

  /**
  * @param {string} nameControl is the name of the control to update
  * @param {MatAutocompleteSelectedEvent} $event is the event with the calalog element selected
  * @description Updates the fields asociated with nameControl with the element selected and executes getBodyWork method
  */
  onSelectConfiguration(
    nameControl: string,
    $event: MatAutocompleteSelectedEvent
  ) {
    this.form.get(`${nameControl}.code`).setValue($event.option.value.code);
    this.form
      .get(`${nameControl}.description`)
      .setValue($event.option.value.name);
    this.getBodyWork($event.option.value.code);
  }

  /**
  * @description Opens a modal to change the trailer, depending of result executes "openDialogCreateTrailer" or "vehicleChangeTrailer" methods
  */
  changeTrailer() {
    const formControl = new FormControl();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Ingresa la placa del trailer",
      dialog: true,
      options: {
        autocomplete: true,
      },
      inputFormControl: formControl,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const modalDialog = this.matDialog.open(
      InputLicensePlateTrailerComponent,
      dialogConfig
    );
    modalDialog.afterClosed().subscribe((result) => {
      if (result && result.state) {
        if (result.data === null) {
          this.openDialogCreateTrailer();
        } else if (this.utils.isDefined(result.data)) {
          this.vehicleChangeTrailer(result.data);
        }
      }
    });
  }

  /**
  * @description Opens a modal to create new trailer and updates the vehicle trailer with modal result
  */
  private openDialogCreateTrailer() {
    let reactiveForm: ReactiveForm = new ReactiveForm(
      this.form,
      this.modelVehicle.trailer
    );

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      updateTrailer: true,
      vehicle: this.vehicle,
    };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(TrailerVehicleComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.vehicle.trailerId = result.trailerId;
        this.getDetailTrailer();
      }
    });
  }

  /**
  * @description Changes the vehicle's trailer with backend service
  */
  private vehicleChangeTrailer(data) {
    const licencePlate = this.vehicle.id;
    this.spinner.show();
    this.vehiclesService
      .updateTrailer(data, licencePlate)
      .toPromise()
      .then((success) => {
        if (success && success.message) {
          this.snackBarService.openSnackBar(success.message);
        } else {
          this.snackBarService.openSnackBar(
            "Se hizo el cambio de trailer exitosamente",
            undefined,
            "success"
          );
        }
      })
      .catch((error) => {
        if (error && error.errorRNDC && error.errorRNDC.error) {
          this.snackBarService.openSnackBar(
            error.errorRNDC.error,
            undefined,
            "error"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al cambiar de trailer",
            undefined,
            "error"
          );
        }
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  /**
  * @returns {boolean} returns true if the vehicle's trailer plate is valid
  * @description Checks if the trailer plate is valid to enable propertyCard and picture form controls, otherwise disables them
  */
  get isDisable(): boolean {
    if (
      this.form.get("id").value &&
      this.patterns.GET_REGEX('TRAILER_PLATES').test(this.form.get("id").value)
    ) {
      this.form.get("propertyCard").enable();
      this.form.get("picture").enable();
      return false;
    } else {
      this.form.get("propertyCard").disable();
      this.form.get("picture").disable();
      return true;
    }
  }

  ngOnDestroy() {
    if (this.axlesSub) this.axlesSub.unsubscribe();
    if (this.descriptionSub) this.descriptionSub.unsubscribe();
  }
}
