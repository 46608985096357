<div class="wrapper-modal">
  <!-- vehicle assign manual -->
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div *ngIf="dialogParams?.type === 'assign' && !vehicleInfo">
    <form (ngSubmit)="onSubmit()" [formGroup]="assignVehicleForm" *ngIf="assignVehicleForm">
      <p class="title mx-4">
        Asignación manual de Vehículo
      </p>
      <hr>
      <div class="body-content">
        <div class="row">
          <!-- Input -->
          <div class="col-12 col-sm-12 col-md-5">
            <mat-form-field>
              <mat-label>Placa del vehículo<span class="asterisk">*</span></mat-label>
              <input type="text" [angularFormsMask]="getMask()" [validateMaskInput]="false" matInput
                [matAutocomplete]="auto" formControlName="idVehicle">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
                <mat-option *ngFor="let vehicle of listVehicles | async" [value]="vehicle?.id"
                  [disabled]="secureTravelProtocol(vehicle)||!hasDriver(vehicle)"
                  data-idowner="{{vehicle?.owner?.document}}" data-idadmin="{{vehicle?.administrator?.document}}"
                  data-nameowner="{{vehicle?.owner?.name}}" data-typedocumentowner="{{vehicle?.owner?.documentTypeId}}"
                  data-namedriver="{{vehicle?.driver?.name}}" data-iddriver="{{vehicle?.driver?.document}}"
                  data-vehicletype="{{vehicle?.vehicleType?.name}}"
                  data-vehiclestate="{{vehicle?.state ? vehicle.state.active : false}}"
                  data-vehiclestatecompany="{{vehicle?.stateCompany ? vehicle?.stateCompany?.active : false}}"
                  data-vehiclestatecompanydescription="{{vehicle?.stateCompany?.description ? vehicle?.stateCompany?.description : ''}}">
                  <span>
                    {{vehicle?.id}}
                    {{
                    vehicle?.numberOfTrips ?
                    '('+vehicle.numberOfTrips+' viajes)':
                    '(0 viajes)'
                    }}
                  </span> |
                  <small>{{hasDriver(vehicle)? vehicle.driver.name : 'Sin conductor' }}</small>
                </mat-option>
                <mat-option [disabled]="secureTravelProtocol()" [value]="assignVehicleForm.controls.idVehicle.value">
                  <span class="font-italic">Crear Vehículo</span>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="assignVehicleForm.get('idVehicle').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Selected -->
          <div class="col-12 col-sm-12 col-md-7" *ngIf="!vehicleSelected && !PermissionAssignVehicle">
            <div class="container-vehicle">
              <div class="vehicle-data text-center"
                *ngIf="cargo?.cargoFeature?.cargoMeasure?.amount>amountsCargoEnum.LOW_END && cargo?.cargoFeature?.cargoMeasure?.amount<=amountsCargoEnum.MEDIUM_END">
                Solo es posible asignar vehículos con <b>{{TripsToCargo.TRIPS_TO_MEDIUM_END}} o más viajes</b>
              </div>
              <div class="vehicle-data text-center"
                *ngIf="cargo?.cargoFeature?.cargoMeasure?.amount>amountsCargoEnum.MEDIUM_END">
                Solo es posible asignar vehículos con <b>{{TripsToCargo.TRIPS_TO_HIGH_END}} o más viajes</b>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-7" *ngIf="vehicleSelected">
            <div class="container-vehicle">
              <div class="vehicle-data">
                <div>
                  <label>Placa: </label>
                  <span class="label-field">&nbsp;{{vehicleSelected.id}}</span>
                </div>
                <div *ngIf="vehicleSelected.owner">
                  <label>Propietario: </label>
                  <span class="label-field">&nbsp;{{vehicleSelected.owner.name}}</span>
                </div>
                <div *ngIf="vehicleSelected.driver">
                  <label>Conductor: </label>
                  <span class="label-field">&nbsp;{{vehicleSelected.driver.name}}</span>
                </div>
                <div *ngIf="vehicleSelected.driver">
                  <label>Documento Conductor: </label>
                  <span class="label-field">&nbsp;{{vehicleSelected.driver.document}}</span>
                </div>
                <div *ngIf="vehicleSelected.vehicleType && vehicleSelected.vehicleType.name">
                  <label>Tipo de Vehículo: </label>
                  <span class="label-field">&nbsp;{{vehicleSelected.vehicleType.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container mb-3">
        <button type="button" mat-raised-button class="primary-secondary" color="primary"
          (click)="closeModal()">Cancelar</button>
        <button class="bg-color-primary" mat-raised-button color="primary" type="submit">Asignar Vehículo</button>
      </div>
    </form>
  </div>

  <!-- vehicle assign in turn -->
  <div *ngIf="vehicleInfo && vehicleSelected" class="row">
    <p class="title col-12 px-5">
      Información del vehículo por asignar
    </p>
    <div class="info-vehicle col-12 mt-3">
      <div class="vehicle-icon px-3">
        <i class="fas fa-car"></i>
      </div>
      <div class="container-vehicle mx-2">
        <div class="vehicle-data">
          <div>
            <label>Placa: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.id}}</span>
          </div>
          <div *ngIf="vehicleSelected.owner">
            <label>Propietario: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.owner.name}}</span>
          </div>
          <div *ngIf="vehicleSelected.driver">
            <label>Conductor: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.driver.name}}</span>
          </div>
          <div *ngIf="vehicleSelected.driver">
            <label>Documento Conductor: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.driver.document}}</span>
          </div>
          <div *ngIf="vehicleSelected.vehicleType && vehicleSelected.vehicleType.name">
            <label>Tipo de Vehículo: </label>
            <span class="label-field">&nbsp;{{vehicleSelected.vehicleType.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container w-100 mb-3">
      <button mat-raised-button class="primary-secondary" color="primary" (click)="dialogRef.close()">Cancelar</button>
      <button mat-raised-button color="primary" class="bg-color-primary" (click)="onSubmit(null, null, 'cargo')">Asignar
        Vehículo</button>
    </div>
  </div>

  <!-- avaliable turn list -->
  <div class="avaliable-turn-list-container" *ngIf="dialogParams?.type === 'turn' && !vehicleInfo && !vehicleSelected">
    <h2 class="title-component mt-2 mb-4">Vehículos disponibles para la carga {{
      dialogParams.filteredCargosTurns.cargoConsecutive}}</h2>
    <div class="col-12 mt-3 p-0 mb-4" *ngIf="!PermissionAssignVehicle && assignTurnVehicles">
      <div class="container-vehicle row">
        <div class="vehicle-data text-center col-12"
          *ngIf="dialogParams.cargo?.cargoFeature?.cargoMeasure?.amount>amountsCargoEnum.LOW_END && dialogParams.cargo?.cargoFeature?.cargoMeasure?.amount<=amountsCargoEnum.MEDIUM_END">
          <div class="alert alert-warning" role="alert">
            Solo es posible asignar vehículos con <b>{{TripsToCargo.TRIPS_TO_MEDIUM_END}} o más viajes</b>
          </div>
        </div>
        <div class="vehicle-data text-center col-12"
          *ngIf="dialogParams.cargo?.cargoFeature?.cargoMeasure?.amount>amountsCargoEnum.MEDIUM_END">
          <div class="alert alert-warning" role="alert">
            Solo es posible asignar vehículos con <b>{{TripsToCargo.TRIPS_TO_HIGH_END}} o más viajes</b>
          </div>
        </div>
      </div>
    </div>
    <div class="cards-container">
      <div *ngFor="let match of dialogParams.filteredCargosTurns.matches" class="card">
        <mat-card class="card-overlay">
          <mat-card-content>
            <div class="license-plate mb-2">
              {{match.licensePlate}}
            </div>
            <div>
              <i class="fas fa-thumbtack mr-1 mt-1 fa-lg"></i> Enturnado el día:<span class="text-bold ml-1">{{
                match.date | dateFormat:'only-date' }}</span>
            </div>
            <div>
              <i class="fas fa-thumbtack mr-1 mt-1 fa-lg"></i> Destino:<span class="text-bold ml-1">{{
                match.destination.name}}</span>
            </div>
            <div>
              <i class="fas fa-thumbtack mr-1 mt-1 fa-lg"></i> Número de viajes:<span class="text-bold ml-1">{{
                match.numberOfTrips}}</span>
            </div>
            <div *ngIf="assignTurnVehicles" class="col-12 mt-4">
              <div class="actions-card d-flex justify-content-center align-items-center">
                <button mat-raised-button color="primary"
                  (click)="getVehicleInfo(match.licensePlate, null, match.numberOfTrips)">
                  <span>Asignar vehículo</span>
                  <i class="fas fa-truck"></i>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>