

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { ChangePasswordComponent } from './change-password/changePassword.component';
import { DocumentsComponent } from './documents/documents.component';
import { MainComponent } from 'src/app/layout/main/main.component';

const routes: Routes = [
    {
        path: '',
        children: [
            /*{
                path: 'documents',
                component: DocumentsComponent,
                canActivate: [UserActiveGuard, RoleGuard],
                resolve: {
                    UserResolver
                }
            },*/
            {
                path: 'password',
                component: ChangePasswordComponent,
                canActivate: [UserActiveGuard],
                resolve: {
                    UserResolver
                }
            },
            { path: '**', redirectTo: '/settings/password' }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingsRoutingModule { }
