<div class="filters-container row mt-3 mb-2 pl-3 pr-3">
  <mat-form-field class="col-12 col-lg-2">
    <mat-label>Placa del vehículo</mat-label>
    <input matInput appLicensePlate placeholder="Por ej. ABC123" [formControl]="licensePlate">
  </mat-form-field>
  <mat-form-field class="col-12 col-lg-3">
    <mat-label>Consecutivo de la carga</mat-label>
    <input matInput placeholder="Por ej. 11234" [formControl]="cargoConsecutive">
  </mat-form-field>
  <mat-form-field class="col-12 col-lg-3">
    <mat-label>Consecutivo de la cartera</mat-label>
    <input matInput placeholder="Por ej. 10" [formControl]="receivableId">
  </mat-form-field>
  <mat-form-field class="col-12 col-lg-2">
    <mat-label>N° de documento</mat-label>
    <input matInput onlyNumbersInt placeholder="Por ej. 10203040" [formControl]="document">
  </mat-form-field>
  <mat-card-actions class="search col-12 col-lg-2 d-flex flex-row m-0 justify-content-around">
    <button mat-raised-button class="primary-secondary" color="primary" title="Eliminar Filtros" (click)="cleanFilter()">
      <i class="far fa-trash-alt"></i>
    </button>
    <button mat-raised-button class="btn-filter" color="primary" title="Aplicar Filtros" (click)="applyFilter()">
      <i class="fas fa-search"></i>
    </button>
  </mat-card-actions>
</div>
