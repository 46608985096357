<form [formGroup]="form" *ngIf="form" class="col-md-12">
  <div class="row title-2 text-strong color-secondary-dark">
    <div class="col-md-12">
      {{title}}
    </div>
  </div>

  <div class="row">
    <!-- documentType -->
    <div class="col-md-4">
      <mat-form-field appearance="standard" formGroupName="information">
        <mat-label>Tipo identificación<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('information.documentTypeId'))">*</span></mat-label>
        <mat-select (selectionChange)="onChangeDocumentType($event)" formControlName="documentTypeId"
          [displayWith]="displayFnDocumentType" [returnWith]="returnFnDocumentType">
          <mat-option *ngFor="let type of documenTypes" [value]="type.id">
            {{type.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('information.documentTypeId').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- document -->
    <div class="col-md-4">
      <app-input-document-user [inputFormControl]="documentControl" [options]="optionsDocumentUser"
        [validate]="validate">
      </app-input-document-user>
    </div>
    <!-- Name -->
    <div class="col-md-4">
      <mat-form-field appearance="standard" formGroupName="information">
        <mat-label>Nombre<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('information.name'))">*</span></mat-label>
        <input matInput type="text" name="tlInput" formControlName="name" [disabled]="true">
        <mat-error *ngIf="form.get('information.name').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'nombre') }}
        </mat-error>
        <mat-error *ngIf="form.get('information.name').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- phoneNumber -->
  <div class="row">
    <div class="col-md-4" *ngIf="form.get('phone')">
      <mat-form-field appearance="standard">
        <mat-label>Celular<span class="asterisk" *ngIf="utils.isRequiredField(form.get('phone'))">*</span></mat-label>
        <input matInput type="text" onlyNumbersInt name="tlInput" formControlName="phone">
        <mat-error *ngIf="form.get('phone').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'celular') }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.minlength">
          {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', 7) }}
        </mat-error>
        <mat-error *ngIf="form.get('phone').errors?.maxlength">
          {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'celular', 12) }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- expeditionDate -->
    <div class="col-md-4 mt-3 mt-md-0" *ngIf="title === 'Conductor'">
      <mat-form-field appearance="standard">
        <mat-label>Fecha de expedición<span class="asterisk"
            *ngIf="utils.isRequiredField(expeditionDateId)">*</span></mat-label>
        <input type="text" matInput [matDatepicker]="datePicker" readonly (click)="datePicker.open()"
          (dateChange)="onChangeDatePicker($event)" [formControl]="expeditionDateId"
          [disabled]="form.get('expeditionDateId').disabled" [max]="today">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="expeditionDateId.errors?.required && !form.get('expeditionDateId').disabled">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- Profile Picture-->

    <div class="col-md-4" *ngIf="title === 'Conductor' && form.get('email')">
      <mat-form-field appearance="standard">
        <mat-label>Correo electrónico<span class="asterisk"
            *ngIf="utils.isRequiredField(form.get('email'))">*</span></mat-label>
        <input type="text" matInput formControlName="email">
        <mat-icon matSuffix class="cursor-pointer" color="primary" *ngIf="form.get('email').errors?.pattern"
          matTooltip="No se permiten caracteres especiales al principio('.test@mail.com'), caracteres especiales seguidos ('te..st@mail.com'), más de un arroba ('te@st@mail.com'), espacios ('te st@mail.com'), caracteres especiales al terminar el dominio ('test.@mail.com'), ni correos de extensión mayor a 100 caracteres">help</mat-icon>
        <mat-error *ngIf="form.get('email').errors?.pattern">
          {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- City -->

    <div class="col-md-4">
      <app-autocomplete-city [options]="optionsCity" [inputFormControl]="cityControl" [validate]="validate">
      </app-autocomplete-city>
    </div>

    <!-- Address -->

    <div class="col-md-4">
      <app-autocomplete-address [title]="'Dirección'" [required]="true" [simpleAddress]="true"
        (emitToParent)="onSelectAddress($event)" [value]="form.get('address').value"
        [disabled]="form.get('address').disabled">
      </app-autocomplete-address>
      <span class="pattern pattern--invalid pattern--document"
        *ngIf="form.get('address') && form.get('address').errors && form.get('address').errors.required">
        Campo obligatorio
      </span>
    </div>
    <div class="col-md-4 simple-regimen">
      <mat-label>¿Es régimen simple?</mat-label>
      &nbsp;&nbsp;
      <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="simpleRegimen">
        <mat-button-toggle [value]="true">
          Si
        </mat-button-toggle>
        <mat-button-toggle [value]="false">
          No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</form>
