export class CargoMeasure {

    private quantity: number;
    private totalMeasurement: number;
    private totalWeigth: string;
    private amount: number;
    private type: string;
    private unit: string;

    public getQuantity(): number {
        return this.quantity;
    }

    public setQuantity(quantity: number): void {
        this.quantity = quantity;
    }

    public getTotalMeasurement(): number {
        return this.totalMeasurement;
    }

    public setTotalMeasurement(totalMeasurement: number): void {
        this.totalMeasurement = totalMeasurement;
    }

    public getTotalWeigth(): string {
        return this.totalWeigth;
    }

    public setTotalWeigth(totalWeigth: string): void {
        this.totalWeigth = totalWeigth;
    }

    public getAmount(): number {
        return this.amount;
    }

    public setAmount(amount: number): void {
        this.amount = amount;
    }

    public getType(): string {
        return this.type;
    }

    public setType(type: string): void {
        this.type = type;
    }

    public getUnit(): string {
        return this.unit;
    }

    public setUnit(unit: string): void {
        this.unit = unit;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
