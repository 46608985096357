import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Utils } from '../resources/utils';
import { AuthService } from '../services/authentication.service';
import { Companies } from '../resources/companies';

@Injectable({ providedIn: 'root' })
export class CompanyAccessGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        public utils: Utils,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            const userPromise = this.authService.validateUser();
            userPromise.then(() => {
                if (this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId && sessionStorage.getItem('_companyId')) {
                    resolve(Companies.companiesAccess(state && state.url ? state.url : null, this.authService.getUserSession().clientCompanyId, sessionStorage.getItem('_companyId')));
                    return;
                }
                this.router.navigate(['/home']);
                resolve(false);
                return;
            }).catch(() => {
                resolve(false);
            });
        });
    }
}
