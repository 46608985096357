import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Driver } from 'src/app/core/models/driver';
import { Permission } from 'src/app/core/resources/permission';
import { AdminUsersService } from '../admin-users/admin-users.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ChangePhoneDialogComponent } from '../change-phone-dialog/change-phone-dialog.component';
import { ManualAcceptComponent } from './manual-accept/manual-accept.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { LegalTermsService } from '../legal-terms/legal-terms.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-request-terms-dialog',
  templateUrl: './request-terms-dialog.component.html',
  styleUrls: ['./request-terms-dialog.component.scss']
})
export class RequestTermsDialogComponent implements OnInit {
  permission = Permission;
  stateRetry = false;

  constructor(
    public spinner: NgxSpinnerService,
    private termsAndConditionsService: LegalTermsService,
    public dialogRef: MatDialogRef<RequestTermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { name: string, document: string, phone: string, user: Driver | any },
    private adminUsersService: AdminUsersService,
    private snackbarService: SnackBarService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  submit() {
    const user = this.data.user;
    if (!user || !user.information) {
      this.snackbarService.openSnackBar("No se ha podido leer la información del usuario.", "Aceptar", "error");
      return;
    }
    this.spinner.show();
    this.termsAndConditionsService
      .requestTermsAndPoliciesAcceptance(user.information.document, user.information.name, `57${this.data.phone}`)
      .subscribe(
        () => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(`Se ha enviado la petición de políticas correctamente al +57 ${this.data.phone}`, "Aceptar", "success");
        },
        () => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(`Ocurrió un error al enviar las políticas al +57 ${this.data.phone}`, undefined, "error");
        },
        () => { this.stateRetry = true; }
      );
  }

  changePhone() {
    this.dialog
      .open(ChangePhoneDialogComponent)
      .afterClosed()
      .subscribe(
        (phone) => {
          if (phone) {
            phone = phone.startsWith('57') ? phone : `57${phone}`;
            this.data.user.phone = phone;
            this.adminUsersService.updateUser(this.data.user).subscribe(
              () => {
                this.data.phone = phone.slice(2);
                this.snackbarService.openSnackBar('Se ha actualizado el número de teléfono del usuario.', 'Aceptar', "success");
              },
              () => {
                this.snackbarService.openSnackBar('Ha ocurrido un error actualizando el número de teléfono del usuario.', 'Aceptar', "error")
              }
            );
          }
        }
      );
  }

  openNonContactDialog(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;

    this.dialog
      .open(ManualAcceptComponent, config)
      .afterOpened()
      .subscribe(() => {
        this.dialogRef.close(this.data.user);
      });
  }

}
