<div class="modal-body wrapper-modal position-relative confirm-route">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="content">
        <header class="d-flex align-items-center"
            matTooltip="Esta acción marcará como activo el itinerario actual y desactivará los demás en esta ruta.">

            <div class="dialog-title">
                Confirmación del itinerario activo
            </div>
        </header>
        <br>
        <br>
        <div class="content-child">
            <div class="row">
                <header class="col-md-12">
                    <div class="d-flex">
                        <div class="route-sumary-text">
                            Tiempo: <b>{{getDurationLabel(data.itinerary.estimatedTime)}}</b>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="route-sumary-text">
                            Distancia: <b>{{data.itinerary.estimatedDistance / 1000 | number:'1.1-1'}} km</b>
                        </div>
                    </div>
                </header>
            </div>
            <br>
            <br>
            <main class="row">
                <section class="cities right-bar"
                    [ngClass]="{'col-md-4':hasSomeAuthorizedPts, 'col-md-6': !hasSomeAuthorizedPts}">
                    <h6>Ciudades</h6>
                    <br>
                    <div class="d-flex align-items-center stage">
                        <div class="icon">
                            <img src="../../../../../assets/svg/icons/tl_ico__pin_google_maps_origen.svg" alt="Origen">
                            <img class="dots"
                                src="../../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_dots.svg"
                                alt="Puntos de indicación origen-destino">
                        </div>
                        <div class="info">
                            <div>Origen</div>
                            <b>{{data.itinerary.originPoint.name}}</b>
                        </div>
                    </div>
                    <div class="d-flex align-items-center stage"
                        *ngFor="let controlPoint of data.itinerary.controlPoints; let i = index">
                        <div class="icon">
                            <img src="../../../../../assets/svg/icons/tl_ico__pin_google_maps_destino.svg" alt="Origen">
                            <img class="dots"
                                src="../../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_dots.svg"
                                alt="Puntos de indicación origen-destino">
                        </div>
                        <div class="info">
                            <div>Control {{i+1}}</div>
                            <b>{{controlPoint.name}}</b>
                        </div>
                    </div>
                    <div class="d-flex align-items-center stage">
                        <div class="icon">
                            <img src="../../../../../assets/svg/icons/tl_ico__pin_google_maps_destino.svg" alt="Origen">
                        </div>
                        <div class="info">
                            <div>Destino Final</div>
                            <b>{{data.itinerary.destinationPoint.name}}</b>
                        </div>
                    </div>
                </section>
                <section class="col-md-4 authorized-points right-bar" *ngIf="hasSomeAuthorizedPts">
                    <h6>Puntos autorizados</h6>
                    <br>
                    <div class="body">
                        <div *ngFor="let result of data.sites | keyvalue;">
                            <img *ngIf="result.value?.details?.pointType?.id == '1'"
                                src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_hotel.svg">
                            <img *ngIf="result.value?.details?.pointType?.id == '2'"
                                src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_gas.svg">
                            <img *ngIf="result.value?.details?.pointType?.id == '3'"
                                src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_parking.svg">
                            <img *ngIf="result.value?.details?.pointType?.id == '4'"
                                src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_restaurant.svg">
                            {{result.value?.details?.name}}
                        </div>
                    </div>
                </section>
                <section class="news" [ngClass]="{'col-md-4':hasSomeAuthorizedPts, 'col-md-6': !hasSomeAuthorizedPts}">
                    <h6>Novedades</h6>
                    <br>
                    <div class="body">
                        <p>
                            <img src="../../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_ok.svg"
                                alt="Estación de combustible">
                            Sin novedades
                        </p>
                    </div>
                </section>
            </main>
        </div>
        <br>
        <br>
        <div class="actions">
            <button mat-button color="accent" style="border: 2px solid #02d7dc;" (click)="dialogRef.close()">
                Volver
            </button>&nbsp;
            <button mat-raised-button color="accent" (click)="create()">
                Activar
            </button>
        </div>
    </div>
</div>