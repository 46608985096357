<div class="branch_office">
    <mat-form-field appearance="standard" *ngIf="reactiveForm.form">
        <mat-label>{{title}}<span class="asterisk">*</span></mat-label>
        <input matInput [matAutocomplete]="autoBranchOffice" [placeholder]="title"
            [formControl]="reactiveForm.form.get('name')">
        <mat-autocomplete #autoBranchOffice="matAutocomplete" [displayWith]="displayBranchName"
            (optionSelected)="onSelect($event)">
            <mat-option *ngFor="let branch of listBranchOfficeAsync | async" [value]="branch">
                <span>{{branch.name}}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>