<aside class="col-md-12S md-md-0 md-10 h-70-margin">
  <div class="container-list h-100">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-12">
          <label class="title-component" *ngIf="moduleForm">
            <i class="fas fa-user-edit"></i>
            {{isCreate ? 'Creación de Modulo': 'Detalle del modulo'}}
          </label>
        </div>
      </div>
    </div>
    <form class="form_container" [formGroup]="moduleForm" (ngSubmit)="onSubmit()">
      <div class="col-md-6">
        <mat-form-field appearance="standard">
          <mat-label>Nombre<span class="asterisk">*</span></mat-label>
          <input matInput type="text" formControlName="name">
          <mat-error *ngIf="moduleForm.get('name').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <span class="separator-horizontal"></span>
      <div class="col-md-12">
        <mat-card-actions class="mat-card-actions-vehcle text-center">
          <button mat-raised-button color="primary" type="submit" class="button-width-auto">
            Guardar
          </button>
        </mat-card-actions>
      </div>
      <span class="separator-horizontal"></span>
    </form>
    <app-functionalities-form [module]="moduleForm" *ngIf="!isCreate"></app-functionalities-form>
  </div>
</aside>