<aside class="view view__container h-100">
  <div class="view__filter">
    <app-cargo-generate-filter (emitToParent)="onGeneretaFile($event)">
    </app-cargo-generate-filter>
  </div>
  <div class="view__body" *ngIf="cargos && cargos.length">
    <h3>Cargas con error</h3>
    <hr>
    <ul class="view__body--list">
      <li *ngFor="let cargo of cargos;" (click)="openDetailCargo(cargo.cargoId)">
        Carga {{cargo.consecutive}} - {{cargo.message}}
      </li>
    </ul>
  </div>
</aside>