import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { Utils } from 'src/app/core/resources/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';

@Component({
  selector: 'app-cargo-billing-xml',
  templateUrl: './cargo-billing-xml.component.html',
  styleUrls: ['./cargo-billing-xml.component.scss']
})
export class CargoBillingXmlComponent implements OnInit {
  form: FormGroup;
  cargos: Array<Cargo> = [];
  searchedBill = '';

  constructor(
    public dialogRef: MatDialogRef<CargoBillingXmlComponent>,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    public cargoListService: FreightListService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = new FormGroup({
      billId: new FormControl('', Validators.required),
      file: new FormControl('', Validators.required),
    })
  }

  changeValue() {
    this.searchedBill = '';
    this.cargos = [];
  }

  searchBill() {
    if (!this.form.get('billId').value || !this.form.get('billId').value.trim()) {
      this.snackBarService.openSnackBar('Por favor escribe la factura para buscar', undefined, 'alert');
      return;
    }
    this.form.get('billId').setValue(this.form.get('billId').value.toLocaleUpperCase());
    this.spinner.show();
    this.cargoListService.getListCargo(`billId=${this.form.get('billId').value}`).subscribe(
      (success: Array<Cargo>) => {
        this.spinner.hide();
        this.searchedBill = this.form.get('billId').value;
        if (success && success.length) this.cargos = success;
        else this.cargos = [];
      },
      () => {
        this.spinner.hide();
        this.searchedBill = this.form.get('billId').value;
        this.cargos = [];
      }
    )
  }

  openXMLInput() {
    if (document.getElementById('XMLInput'))
      document.getElementById('XMLInput').click();
  }

  onXMLSelected($event) {
    if (!$event || !$event.target || !$event.target.files || !$event.target.files[0]) return;
    const xml = $event.target.files[0];
    if (xml.type !== "text/xml") {
      this.snackBarService.openSnackBar(FormMessages.INVALID_FORMAT, undefined, 'alert');
      return;
    }
    this.form.get('file').setValue(xml);
  }

  onSubmit() {
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('billId'), 'número de factura')) return;
      else this.snackBarService.openSnackBar('Para enviar la factura adjunta el archivo XML', undefined, 'alert');
      return;
    }
    const formData = new FormData();
    formData.append('file', this.form.get('file').value);
    formData.append('billId', this.form.get('billId').value);
    this.spinner.show();
    this.cargoListService.billXML(formData).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Se ha realizado la facturación correctamente");
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          error && error.error && error.error.message ? error.error.message : ServiceMessages.GENERAL_HTTP_ERROR,
          undefined, 'error'
        );
      }
    )
  }

  get getErrors(): string {
    if (!this.cargos || !this.cargos.length)
      return `La factura ${this.searchedBill} no tiene cargas asociadas`;

    if (this.cargos.some(cargo => cargo.uploadedBillingXML))
      return `El XML de la factura ${this.searchedBill} ya fue subido`;

    if (this.cargos.some(cargo => !cargo.approval || cargo.approval !== "Approved")) {
      const consecutives: number[] = this.cargos.filter(cargo => !cargo.approval || cargo.approval !== "Approved").map(cargo => cargo.consecutive);
      return `La cargas ${consecutives.join(', ')} de la factura ${this.searchedBill} no han sido cumplidas`;
    }

    return '';
  }
}
