import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatStepper } from '@angular/material';
import { Utils } from 'src/app/core/resources/utils';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RoutingCreateFleetComponent } from '../routing-create-fleet/routing-create-fleet.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { RoutingService } from '../../routing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoredFleet } from 'src/app/core/interfaces/storedFleet';

@Component({
  selector: 'app-routing-fleet-form',
  templateUrl: './routing-fleet-form.component.html',
  styleUrls: ['./routing-fleet-form.component.scss'],
})
export class RoutingFleetFormComponent {
  @Input() form: FormGroup;
  @Input() stepper: MatStepper;
  searchCtrl = new FormControl();
  fleets: StoredFleet[] = [];

  constructor(
    public utils: Utils,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private routingService: RoutingService,
  ) { }

  /**
  * @description Executes the method "getFleets"
  */
  ngOnInit(): void {
    this.getFleets();
  }

  /**
  * @description Gets the fleets and updates the fleets list
  */
  private getFleets() {
    this.spinner.show();
    const sub = this.routingService.getFleets().subscribe(
      (success) => {
        this.spinner.hide();
        this.fleets = success && success.length ? success : [];
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
        this.fleets = [];
      }, () => {
        sub.unsubscribe();
      }
    )
  }

  /**
  * @param {StoredFleet} fleet Is the fleet to compare
  * @returns {boolean} returns true if the fleet from params is the same fleet from the form 
  * @description Verifies if the current fleet is the same of the form
  */
  isSelectedFleet(fleet: StoredFleet): boolean {
    return this.form.get('fleetName').value && this.form.get('fleetName').value === fleet.fleetName;
  }

  /**
  * @param {StoredFleet} fleet Is the fleet selected
  * @description Assign the fleet selected to form's control "fleetName"
  */
  onSelectFleet(fleet: StoredFleet): void {
    this.form.get('fleetName').setValue(fleet.fleetName);
  }

  /**
  * @param {StoredFleet} fleet Is the fleet selected
  * @description Opens a modal to edit a fleet
  */
  onEditFleet(fleet: StoredFleet): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { fleet };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(RoutingCreateFleetComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result && result.state) this.getFleets();
    });
  }

  /**
  * @description Opens a modal to create a Fleet and reloads the fleet list on modal's closing
  */
  createFleet(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(RoutingCreateFleetComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result && result.state) this.getFleets();
    });
  }

  /**
  * @description Verifies if the form's control "fleetName" is valid to allow pass to next step
  */
  onSubmit() {
    if (this.form.get('fleetName').invalid) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'ningúna flota'), undefined, 'alert');
      return;
    }
    this.stepper.next();
  }

  /**
  * @returns {boolean} returns true if the form's control "fleetName" is valid.
  * @description Verifies if the current step is valid
  */
  get isValidStep(): boolean {
    return this.form && this.form.get('fleetName') && this.form.get('fleetName').valid;
  }

  /**
  * @returns {StoredFleet[]} returns the filtered fleets array from searchCtrl value comparison
  * @description Compares the searchCtrl value with the fleets list to get a filtered list.
  */
  get filteredFleets(): StoredFleet[] {
    if (!!this.searchCtrl.value && !!this.searchCtrl.value.trim().length)
      return this.fleets.filter(fleet => fleet.fleetName.toLocaleLowerCase().includes(this.searchCtrl.value.toLocaleLowerCase()));
    return this.fleets;
  }

  /**
  * @returns {boolean} returns true if the fleet from schema belongs to fleets, otherwise false
  * @description Checks if the schema's fleet exist on fleets list
  */
  get schemaFleetExists(): boolean {
    return this.form && this.form.get('fleetFromSchema').value && this.fleets && this.fleets.length && this.fleets.some(fleet => this.isSelectedFleet(fleet));
  }


}
