import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FunctionalitiesService {

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private _globalResources: Global,
  ) { }

  createFunctionality(functionalitiesModuleRole) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.functionalities, functionalitiesModuleRole
    )
  }

  getFunctionalitiesByIdModule(moduleId?) {
    let params = new HttpParams();
    if (moduleId) {
      params = params.append('moduleId', moduleId);
    }
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.functionalitiesByIdModule, { params }
    );
  }
}
