import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CityService } from '../city.service';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { City } from 'src/app/core/interfaces/city';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ica-form',
  templateUrl: './ica-form.component.html',
  styleUrls: ['./ica-form.component.scss']
})
export class IcaFormComponent {

  public initialMainCityName = "";
  public newMainCity: City;
  public city: City;
  optionsCity: OptionsAutocomplete = {
    title: 'Seleccione ciudad principal',
    filterByMainCities: true,
    requireFullCity: true,
  }
  validate: string = '';
  cityControl: FormControl = new FormControl('', Validators.required);
  citySub: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    private cityService: CityService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<IcaFormComponent>,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    if (this.dialogParams && this.dialogParams.city) {
      this.city = this.dialogParams.city;
      if (this.dialogParams.city.mainCity) this.initialMainCityName = this.city.mainCity.name;
      this.optionsCity['initialValue'] = this.initialMainCityName;
    }
    this.setSubscriptionCity();
  }

  setSubscriptionCity() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => {
        if (value && value.id) this.newMainCity = value;
        else this.newMainCity = undefined;
      })
  }

  public changeMainCity() {
    this.validate = 'touched';
    if (!this.newMainCity) {
      this.snackBarService.openSnackBar('No se ha escogido una ciudad principal', undefined, 'alert');
    } else if (this.city.mainCity && this.newMainCity.id === this.city.mainCity.id) {
      this.snackBarService.openSnackBar(ServiceMessages.CITIES_ASSOCIATED);
      this.dialogRef.close();
    } else {
      this.spinner.show();
      let body = {
        mainCity: this.newMainCity,
        subCities: [{
          id: this.city.id,
          name: this.city.name,
        }]
      }
      this.cityService.addSubcities(body).toPromise()
        .then((success) => {
          this.spinner.hide();
          if (success && success['subCities'] && success['subCities'].length) {
            this.dialogRef.close({ city: success['subCities'][0] });
            this.snackBarService.openSnackBar(ServiceMessages.CITIES_ASSOCIATED);
          } else {
            this.dialogRef.close();
            this.snackBarService.openSnackBar(ServiceMessages.ERROR_ASSOCIATING_CITIES, undefined, 'error');
          }
        })
        .catch((error) => {
          this.spinner.hide();
          this.dialogRef.close();
          const errorMessage = error && typeof error.error === 'string' ? error.error : ServiceMessages.ERROR_ASSOCIATING_CITIES;
          this.snackBarService.openSnackBar(errorMessage, undefined, 'error');
        })
    }

  }

  public reteica(city: City) {
    return this.cityService.getReteicaCity(city);
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
  }
}
