<form class="routing__modal--content position-relative py-3 px-2 px-sm-5" [formGroup]="form">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="routing__modal--header">
        <h3 *ngIf="activeStep===0" class="routing__modal--title">Agregar flota</h3>
        <h3 *ngIf="activeStep===1" class="routing__modal--title text-break pr-5">{{form.get('name').value | titlecase}}
        </h3>
        <label *ngIf="activeStep===1" class="routing__modal--label">
            {{vehicles.controls.length}} vehículos
            <span *ngFor="let capacity of capacitiesSelected">
                , {{capacity.name}}: {{getTotalCapacity(capacity.name)}} ({{capacity.measure}})
            </span>
        </label>
        <div *ngIf="activeStep===1 && mode==='create'" class="capacity-div">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="text-bold">
                        Selecciona las capacidades de los vehículos
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mat-expansion-capacity-body">
                    <div class="d-flex justify-content-between flex-wrap"
                        *ngFor="let capacity of listCapacities; let i = index;">
                        <mat-checkbox class="d-block" (change)="onChangeCapacity($event, capacity)"
                            [checked]="!!capacity?.selected" [disabled]="mustDisableCapacity(capacity)">
                            <div class="d-flex justify-content-between flex-wrap">
                                {{capacity?.name}} ({{capacity?.measure}})
                            </div>
                        </mat-checkbox>
                        <i class="fas fa-trash cursor-pointer color-primary" *ngIf="!capacity?.base"
                            (click)="deleteCapacity(capacity)"></i>
                    </div>
                    <div (click)="createCapacity()" class="cursor-pointer">
                        <i class="fas fa-plus-circle"></i>
                        Crear capacidad
                    </div>
                </div>

            </mat-expansion-panel>
        </div>
    </div>

    <div class="routing__modal--body">
        <mat-form-field *ngIf="activeStep===0" class="plate-field mw-500">
            <mat-label>Nombre<span class="asterisk">*</span></mat-label>
            <input type="text" matInput formControlName="name">
            <mat-error *ngIf="form.get('name').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('name').errors?.minlength">
                {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'nombre', 3) }}
            </mat-error>
        </mat-form-field>
        <p class="text-bold my-2" *ngIf="activeStep===0">Vehículos</p>

        <p class="alert alert-warning text-bold text-center" *ngIf="activeStep===1">
            Una vez confirmada la flota, no es posible eliminar los vehículos agregados
        </p>
        <table class="w-100 my-3" *ngIf="activeStep===1">
            <thead>
                <th class="border-right">Placa</th>
                <th class="border-right d-none d-sm-table-cell">Conductor</th>
                <th class="border-right d-none d-md-table-cell">Características</th>
                <th *ngFor="let capacity of capacitiesSelected">{{capacity?.name}} ({{capacity?.measure}})</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let vehicle of vehicles.controls; let i = index;">
                    <td class="border-right"> {{vehicle?.value?.licensePlate}} </td>
                    <td class="d-none d-sm-table-cell border-right"> {{vehicle?.value?.driver}} </td>
                    <td class="d-none d-md-table-cell border-right">{{vehicle?.value?.vehicleType}}</td>
                    <td *ngFor="let capacity of capacitiesSelected">
                        <span *ngIf="!vehicle?.value?.isEditing">
                            {{vehicle.get(capacity.name).value? vehicle.get(capacity.name).value : 0}}
                        </span>
                        <mat-form-field *ngIf="vehicle?.value?.isEditing" class="plate-field">
                            <mat-label>{{capacity?.name}}<span class="asterisk">*</span></mat-label>
                            <input type="text" matInput onlyNumbersInt [formControl]="vehicle.get(capacity.name)"
                                min="0">
                            <mat-error *ngIf="vehicle.get(capacity.name).errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                            <mat-error *ngIf="vehicle.get(capacity.name).errors?.min">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                            <mat-error *ngIf="vehicle.get(capacity.name).errors?.maxlength">
                                {{ utils.giveMessageError('MAX_LENGTH_EXCEED', capacity?.name, maxLengthValueAllow) }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <i matTooltip="Editar" (click)="editVehicleCapacity(i,true)" *ngIf="!vehicle?.value?.isEditing"
                            class="fas fa-pencil-alt color-primary cursor-pointer"></i>
                        <i matTooltip="Confirmar" class="mr-1 fas fa-check fa-lg color-primary cursor-pointer"
                            (click)="checkCapacity(i)" matSuffix *ngIf="vehicle?.value?.isEditing"></i>
                        <i matTooltip="Salir" class="fas fa-times fa-lg color-primary cursor-pointer"
                            (click)="editVehicleCapacity(i,false)" matSuffix *ngIf="vehicle?.value?.isEditing"></i>
                        <i matTooltip="Eliminar" class="fas fa-times fa-lg cursor-pointer" (click)="deleteVehicle(i)"
                            style="color: red;"
                            *ngIf="!isConfirmedVehicle(vehicle?.value?.licensePlate) && !vehicle?.value?.isEditing"></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <p *ngIf="activeStep===1 && !showVehicleSelector" class="cursor-pointer font-secondary-dark"
            (click)="showVehicleInput()">
            <i class="fas fa-plus-circle mr-1"></i>
            Agregar vehículo
        </p>
        <div class="mw-500" *ngIf="activeStep===0 || showVehicleSelector">
            <app-select-license-plate [inputFormControl]="licensePlateCtrl"
                [options]="licensePlateOptions"></app-select-license-plate>
        </div>
        <ul *ngIf="activeStep===0" class="mw-500 pl-2">
            <li *ngFor="let vehicle of vehicles.controls; let i = index;">
                <p class="my-1">{{vehicle.value.licensePlate}}</p>
                <i matTooltip="Eliminar" class="fas fa-times fa-lg cursor-pointer" (click)="deleteVehicle(i)"
                    style="color: red;"></i>
            </li>
        </ul>
    </div>


    <div class="routing__modal--footer button-container">
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary" type="button"
            (click)="onClose($event)" *ngIf="activeStep===0">
            Cancelar
        </button>
        <button mat-raised-button color="primary" type="button" (click)="nextStep($event)" *ngIf="activeStep===0"
            [ngClass]="{'bg-color-primary':isValidStepOne, 'disabled-btn':!isValidStepOne}">
            Continuar
        </button>
        <button mat-raised-button class="primary-secondary button-width-auto" color="primary" (click)="goBack($event)"
            *ngIf="activeStep===1 && mode==='create'" type="button">
            Volver
        </button>
        <button mat-raised-button color="primary" type="submit" (click)="onSubmit()" *ngIf="activeStep===1"
            [ngClass]="{'bg-color-primary':isValidStepTwo, 'disabled-btn':!isValidStepTwo}">
            Confirmar
        </button>
    </div>

</form>