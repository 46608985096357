import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ServerRequest } from 'src/app/core/interfaces/serverRequest';
import { FingerPrintsService } from './finger-prints.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-finger-prints',
  templateUrl: './finger-prints.component.html',
  styleUrls: ['./finger-prints.component.scss']
})
export class FingerPrintsComponent implements OnInit {
  fingerPrintList: ServerRequest[] = [];
  fingerPrintListSlice: ServerRequest[] = [];
  filters: FormGroup;

  //pagination config
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30]; // Opciones de tamaño de página
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  startIndex: number = 0;
  endIndex: number = 10;
  resetPagination: boolean;
  constructor(
    public spinner: NgxSpinnerService,
    public fingerPrintsService: FingerPrintsService,
    public snackbarService: SnackBarService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.getFingerPrints();
  }

  initializeForm() {
    this.filters = new FormGroup({
      userDocument: new FormControl('', [Validators.minLength(6), Validators.maxLength(15)]),
      date: new FormControl(''),
      statusCode: new FormControl(''),
    })
  }

  private getFingerPrints(userDocument?: string, date?: string, statusCode?: string) {
    this.spinner.show();
    this.pageIndex = 0;
    this.fingerPrintsService.getFingerPrints(userDocument, date, statusCode).subscribe({
      next: (fingerprints: ServerRequest[]) => {
        this.spinner.hide();
        if (document && document.getElementById('scrollContainer')) document.getElementById('scrollContainer').scrollTop = 0;
        this.startIndex = 0;
        this.endIndex = this.startIndex + this.pageSize;
        if (fingerprints) {
          this.fingerPrintList = fingerprints;
          this.fingerPrintListSlice = this.fingerPrintList.slice(this.startIndex, this.endIndex);
        } else {
          this.fingerPrintList = [];
          this.fingerPrintListSlice = [];
        }
      },
      error: (error) => {
        this.spinner.hide();
        this.fingerPrintList = [];
        this.fingerPrintListSlice = [];
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event && (event.pageIndex || event.pageIndex === 0) ? event.pageIndex : this.pageIndex;
    this.pageSize = event && event.pageSize ? event.pageSize : this.pageSize;
    this.startIndex = this.pageIndex * this.pageSize;
    this.endIndex = this.startIndex + this.pageSize;
    if (this.endIndex > this.fingerPrintList.length) this.endIndex = this.fingerPrintList.length;
    this.fingerPrintListSlice = this.fingerPrintList.slice(this.startIndex, this.endIndex);
  }

  processFilters($event) {
    if (($event && $event.type && $event.type === 'apply')) {
      const userDocument = this.getValueFromEvent($event.filtersValues, 'userDocument');
      const date = this.getValueFromEvent($event.filtersValues, 'date');
      const statusCode = this.getValueFromEvent($event.filtersValues, 'statusCode');
      this.getFingerPrints(userDocument, date, statusCode);
    } else {
      this.cleanAndReload();
    }
  }

  getValueFromEvent(event: any, label: string): string {
    const filter = event.find((item: any) => item.label === label);
    return filter ? filter.value : null;
  }

  cleanAndReload() {
    this.pageIndex = 0;
    this.getFingerPrints();
  }

}
