import { Inject, Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoEvidenceComponent } from 'src/app/modules/cargo/cargo-evidence/cargo-evidence.component';
import { TrackGuideService } from '../track-guide-services';
import { ModalEnum } from 'src/app/core/enums/modal.enum';

@Component({
  selector: 'app-track-guide-detail',
  templateUrl: './track-guide-detail.component.html',
  styleUrls: ['./track-guide-detail.component.scss']
})
export class TrackGuideDetailComponent implements OnInit {

  @Input() guide: TrackGuide;
  @Input() hideBtnClose: boolean;
  formTrackGuide: FormGroup;
  listPathImageGuide: []

  constructor(
    private formBuilder: FormBuilder,
    private trackGuideService: TrackGuideService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<TrackGuideDetailComponent>,
    public utils: Utils
  ) {
    if (this.dialogParams && this.dialogParams.guide) {
      this.guide = this.dialogParams.guide;
    }
  }

  ngOnInit() {
    this.formTrackGuide = this.formBuilder.group({
      guideId: ['', Validators.required]
    });
  }

  isDialog() {
    if (this.dialogParams && this.dialogParams.guide) {
      return true;
    }
    return false;
  }

  onSubmit() {
    if (this.formTrackGuide.controls.guideId.value.trim()) {
      this.trackGuideService.getDetailTrackGuide(this.formTrackGuide.controls.guideId.value.trim()).subscribe(
        (success: TrackGuide) => {
          if (!this.utils.isEmpty(success)) {
            this.guide = success;
          } else {
            this.snackBarService.openSnackBar("La guia que desea rastrear no existe", undefined, 'error');
          }
        },
        (error) => {
          this.snackBarService.openSnackBar("La guia que desea rastrear no existe", undefined, 'error');
        }
      )

    } else {
      this.snackBarService.openSnackBar("Por favor ingrese un número de guía", undefined, 'alert');
    }
  }

  showEvidence() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      guide: this.guide
    };
    dialogConfig.height = 'auto';
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    this.dialog.open(CargoEvidenceComponent, dialogConfig);
  }
}
