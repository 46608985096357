import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from 'src/app/core/interfaces/formContact';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        private _globalResources: Global
    ) { }

    public sendDataContact( dataContact:Contact ){
        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.urlformContact,
            dataContact
        );
    }
}
