<mat-card>
  <div>
    <label class="label-field">{{additionalServiceGroupCtrl.value.type.name}}</label>
    <mdb-icon (click)="removeAdditionalCostItem.emit(index)" *ngIf="!additionalServiceGroupCtrl.disabled && !activeEdit"
      class="float-right cursor-pointer" fas icon="times">
    </mdb-icon>
    <i *ngIf="this.additionalServiceGroupCtrl.get('financialInformation.paid')?.value !== null && showBtnDeleteAdditionalService"
      (click)="deleteAdditionalService()"
      [class.notId]="additionalServiceGroupCtrl.value.id === undefined || additionalServiceGroupCtrl.value.id === null || additionalServiceGroupCtrl.value.id === ''"
      [class.disabledTrash]="!!additionalServiceGroupCtrl?.get('financialInformation.paid')?.value"
      class="fas fa-trash fa-lg trash float-right cursor-pointer"></i>
  </div>
  <mat-form-field *ngIf="showAdditionalRate" appearance="standard" class="col-md-6">
    <mat-label class="text-strong"><i class="fas fa-building"></i>Valor Adicional Tarifa
      Clientes<span class="asterisk">*</span></mat-label>
    <i class="fas fa-info-circle color-secondary-dark absolute absolute--right-top"
      matTooltip="Hace referencia al valor que {{companyName}} cobra al CLIENTE" matTooltipClass="tooltip__alert"
      matTooltipPosition="right"></i>
    <input matInput type="text" currencyMask min="0"
      [formControl]="additionalServiceGroupCtrl.get('financialInformation.rate')">
    <mat-error *ngIf="additionalServiceGroupCtrl.get('financialInformation.rate').errors?.required">
      {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="showAdditionalCost" appearance="standard" class="col-md-6">
    <mat-label class="text-strong"><i class="fas fa-truck"></i>Valor Incremento Costo {{personCost}}<span
        class="asterisk">*</span>
    </mat-label>
    <i class="fas fa-info-circle color-secondary-dark absolute absolute--right-top"
      matTooltip="Hace referencia al valor que {{companyName}} paga al {{personCost}}" matTooltipClass="tooltip__alert"
      matTooltipPosition="right"></i>
    <input matInput type="text" currencyMask min="0"
      [formControl]="additionalServiceGroupCtrl.get('financialInformation.cost')">
    <mat-error *ngIf="additionalServiceGroupCtrl.get('financialInformation.cost').errors?.required">
      {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
  </mat-form-field>
  <div class="alert text-center" role="alert" *ngIf="checkUtility"
    [ngClass]="{'alert-primary':currentUtility===utilityRequired, 'alert-success':currentUtility>utilityRequired, 'alert-danger':currentUtility<utilityRequired}">
    Utilidad actual: <b>{{currentUtility | number: '1.0-1'}}%</b>
    <div class="min-utility-subtitle" *ngIf="currentUtility<utilityRequired">La utilidad requerida para crear el
      servicio adicional es: {{utilityRequired}}%
    </div>
  </div>
  <!-- opciones de bonos y descuentos -->
  <div class="bonus-and-discounts-options">
    <!-- editar tarifa y costo -->
    <div
      *ngIf="this.additionalServiceGroupCtrl.get('financialInformation.paid')?.value !== null && showBtnEditAdditionalService"
      [class.notId]="additionalServiceGroupCtrl?.value?.id === undefined || this.additionalServiceGroupCtrl?.value?.id === null ||this.additionalServiceGroupCtrl?.value?.id === ''"
      class="item-price underline color-secondary-dark" (click)="showRateCostAdditionalService()">
      Agregar bonos y descuentos
    </div>

    <div class="dropdown item-price color-secondary-dark d-flex align-items-center"
      (click)="toggleCollapsehistoryAdditionalServices()" *ngIf="showtoggleCollapsehistoryOption">
      <div class="mr-2">
        <u>Ver historial de bonos y descuentos</u>
      </div>
      <div>
        <i *ngIf="showArrowDown" class="fas fa-caret-down fa-lg"></i><i *ngIf="!showArrowDown"
          class="fas fa-caret-up fa-lg"></i>
      </div>
    </div>

  </div>

  <!-- Visualizacion de historial de bonificaciones y descuentos a la tarifa y al costo -->
  <div class="container-history-additional-services mt-3 ml-3 "
    [ngClass]="{'collapsed': isCollapsedhistoryAdditionalServices}">

    <div class="item-additional-service"
      *ngIf="cargoItemService.getTotalDiscountsRateAdditionalServices(additionalServiceGroupCtrl.value) && showAdditionalRate">
      <div>Valor total de los Descuentos a la Tarifa <i class="fas fa-history color-secondary-dark cursor-pointer"
          matTooltip="Historico de descuentos" matTooltipPosition="above"
          (click)="openHistoryDiscountsAndBonusesAdditionalServices(1)"></i></div>
      <div class="item-price text__color color--error">
        {{cargoItemService.getTotalDiscountsRateAdditionalServices(additionalServiceGroupCtrl.value)
        | currency:'COP':'code'}}
      </div>
    </div>

    <div class="item-additional-service"
      *ngIf="cargoItemService.getTotalDiscountsCostAdditionalServices(additionalServiceGroupCtrl.value) && showAdditionalCost">
      <div>Valor total de los Descuentos al Costo <i class="fas fa-history color-secondary-dark cursor-pointer"
          matTooltip="Historico de descuentos" matTooltipPosition="above"
          (click)="openHistoryDiscountsAndBonusesAdditionalServices(1)"></i></div>
      <div class="item-price  text__color color--error">
        {{cargoItemService.getTotalDiscountsCostAdditionalServices(additionalServiceGroupCtrl.value) |
        currency:'COP':'code'}}
      </div>
    </div>

    <div class="item-additional-service"
      *ngIf="cargoItemService.getTotalBonusesRateAdditionalServices(additionalServiceGroupCtrl.value) && showAdditionalRate">
      <div>Valor total de las Bonificaciones a la Tarifa <i class="fas fa-history color-secondary-dark cursor-pointer"
          matTooltip="Historico de bonificaciones" matTooltipPosition="above"
          (click)="openHistoryDiscountsAndBonusesAdditionalServices(0)"></i></div>
      <div class="item-price text__color color--success">
        {{cargoItemService.getTotalBonusesRateAdditionalServices(additionalServiceGroupCtrl.value)
        | currency:'COP':'code'}}
      </div>
    </div>

    <div class="item-additional-service"
      *ngIf="cargoItemService.getTotalBonusesCostAdditionalServices(additionalServiceGroupCtrl.value) && showAdditionalCost">
      <div>Valor total de las Bonificaciones al Costo <i class="fas fa-history color-secondary-dark cursor-pointer"
          matTooltip="Historico de bonificaciones" matTooltipPosition="above"
          (click)="openHistoryDiscountsAndBonusesAdditionalServices(0)"></i></div>
      <div class="item-price text__color color--success">
        {{cargoItemService.getTotalBonusesCostAdditionalServices(additionalServiceGroupCtrl.value) |
        currency:'COP':'code'}}
      </div>
    </div>

  </div>

  <div *ngIf="additionalServiceGroupCtrl.value.type.name === 'Servicio cargue'" class="col-md-6 d-inline-block p-0">
    <mat-form-field appearance="standard"
      *ngIf="additionalServiceGroupCtrl.value.type.name === 'Servicio cargue' && additionalServiceGroupCtrl.get('toPay.code')"
      class="col-md-6">
      <mat-label>Cuenta para pagar a<span class="asterisk">*</span></mat-label>
      <mat-select (selectionChange)="onChangeUploadDownloadFor($event)"
        [disabled]="additionalServiceGroupCtrl.get('toPay.code').disabled"
        [value]="additionalServiceGroupCtrl.get('toPay.code').value">
        <mat-option *ngFor="let uploadDownloadFor of cargoResources.listUploadDownloadFor timeList"
          [value]="uploadDownloadFor.code">
          {{uploadDownloadFor.description}}
        </mat-option>
      </mat-select>
      <i *ngIf="this.additionalServiceGroupCtrl.get('financialInformation.paid')?.value !== null && !(additionalServiceGroupCtrl.value.type.name === 'Sello satelital' || additionalServiceGroupCtrl.value.type.name === 'Servicio de almacenamiento' || additionalServiceGroupCtrl.value.type.name === 'Servicio ITR') && showBtnEditAdditionalService"
        (click)="editAdditionalService()" matTooltip="Editar servicio adicional" matSuffix
        [class.disabledEdit]="!!additionalServiceGroupCtrl.get('financialInformation.paid')?.value && approvalState === 'Pending approval'"
        class="fa-sharp fas fa-pen editIcon"></i>
    </mat-form-field>
  </div>

  <div *ngIf="additionalServiceGroupCtrl.value.type.name === 'Servicio descargue'" class="col-md-6 d-inline-block p-0">
    <mat-form-field appearance="standard"
      *ngIf="additionalServiceGroupCtrl.value.type.name === 'Servicio descargue' && additionalServiceGroupCtrl.get('toPay.code')"
      class="col-md-6">
      <mat-label>Cuenta para pagar a<span class="asterisk">*</span></mat-label>
      <mat-select (selectionChange)="onChangeUploadDownloadFor($event)"
        [disabled]="additionalServiceGroupCtrl.get('toPay.code').disabled" [value]="additionalServiceGroupCtrl.get('toPay.code').value
            ">
        <mat-option *ngFor="let uploadDownloadFor of cargoResources.listUploadDownloadFor timeList"
          [value]="uploadDownloadFor.code">
          {{uploadDownloadFor.description}}
        </mat-option>
      </mat-select>
      <i *ngIf="additionalServiceGroupCtrl.get('financialInformation.paid')?.value !== null && !(additionalServiceGroupCtrl.value.type.name === 'Sello satelital' || additionalServiceGroupCtrl.value.type.name === 'Servicio de almacenamiento' || additionalServiceGroupCtrl.value.type.name === 'Servicio ITR') && showBtnEditAdditionalService"
        (click)="editAdditionalService()" matTooltip="Editar servicio adicional" matSuffix
        [class.disabledEdit]="!!additionalServiceGroupCtrl.get('financialInformation.paid')?.value && approvalState === 'Pending approval'"
        class="fa-sharp fas fa-pen editIcon"></i>
    </mat-form-field>
  </div>
  <div *ngIf="additionalServiceGroupCtrl.value.type.name === 'Stand by'" class="col-md-6 d-inline-block p-0">
    <mat-form-field appearance="standard"
      *ngIf="additionalServiceGroupCtrl.value.type.name === 'Stand by' && additionalServiceGroupCtrl.get('toPay.code')"
      class="col-12">
      <mat-label>Stand by por cuenta de<span class="asterisk">*</span></mat-label>
      <mat-select (selectionChange)="onChangeUploadDownloadFor($event)"
        [disabled]="additionalServiceGroupCtrl.get('toPay.code').disabled"
        [value]="additionalServiceGroupCtrl.get('toPay.code').value">
        <mat-option *ngFor="let uploadDownloadFor of cargoResources.listUploadDownloadFor timeList"
          [value]="uploadDownloadFor.code">
          {{uploadDownloadFor.description}}
        </mat-option>
      </mat-select>
      <i *ngIf="additionalServiceGroupCtrl.get('financialInformation.paid')?.value !== null && !(additionalServiceGroupCtrl.value.type.name === 'Sello satelital' || additionalServiceGroupCtrl.value.type.name === 'Servicio de almacenamiento' || additionalServiceGroupCtrl.value.type.name === 'Servicio ITR') && showBtnEditAdditionalService"
        (click)="editAdditionalService()" matTooltip="Editar servicio adicional" matSuffix
        [class.disabledEdit]="!!additionalServiceGroupCtrl.get('financialInformation.paid')?.value && approvalState === 'Pending approval'"
        class="fa-sharp fas fa-pen fa-lg editIcon"></i>
    </mat-form-field>
    <span></span>
  </div>
  <div
    *ngIf="additionalServiceGroupCtrl.value.type.name === 'Servicios de escolta' && additionalServiceGroupCtrl.get('observation')"
    class="col-md-6 d-inline-block p-0">
    <div class="md-form">
      <label class="label-field">Tipo de escolta<span class="asterisk">*</span></label>
      <br>
      <!-- a [value ]le esta asignando lo que hay en  additionalServiceGroupCtrl.get('observation').value-->
      <!-- a [disabled] se le esta asignando el estado de additionalServiceGroupCtrl.get('observation').disabled en este caso es true -->
      <div class="d-flex flex-row toggle-button">
        <mat-button-toggle-group class="value-load-button-toggle" #group="matButtonToggleGroup"
          [disabled]="additionalServiceGroupCtrl.get('observation').disabled"
          [value]="additionalServiceGroupCtrl.get('observation').value">
          <mat-button-toggle value="Vehícular" (change)="onSelectBodyGuardType($event)">
            Vehícular
          </mat-button-toggle>
          <mat-button-toggle value="Electrónico" (change)="onSelectBodyGuardType($event)">
            Electrónico
          </mat-button-toggle>
          <i *ngIf="this.additionalServiceGroupCtrl.get('financialInformation.paid')?.value !== null && !(additionalServiceGroupCtrl.value.type.name === 'Sello satelital' || additionalServiceGroupCtrl.value.type.name === 'Servicio de almacenamiento' || additionalServiceGroupCtrl.value.type.name === 'Servicio ITR') && showBtnEditAdditionalService"
            (click)="editAdditionalService()" matTooltip="Editar servicio adicional" matSuffix
            [class.disabledEdit]="this.additionalServiceGroupCtrl.get('financialInformation.paid')?.value ? true : false && approvalState === 'Pending approval'"
            class="fa-sharp fas fa-pen fa-lg editIcon"></i>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <!-- opciones de bonos y descuentos -->
  <div class="d-inline-flex flex-column"
    [ngClass]="{'col-md-12 align-items-end':!['Servicios de escolta','Stand by','Servicio descargue','Servicio cargue'].includes(additionalServiceGroupCtrl.value.type.name),
                'col-md-6 align-items-center':['Servicios de escolta','Stand by','Servicio descargue','Servicio cargue'].includes(additionalServiceGroupCtrl.value.type.name)}">
    <div class="dropdown item-price color-secondary-dark d-flex justify-content-center col-lg-6"
      (click)="showFingerPrintInfo()"
      *ngIf="additionalServiceGroupCtrl.value.fingerPrint && additionalServiceGroupCtrl.disabled">
      <div class="mr-2">
        <u>¿Quién creó el servicio?</u>
      </div>
      <div>
        <i *ngIf="!isCollapsedFingerPrint" class="fas fa-caret-down fa-lg"></i>
        <i *ngIf="isCollapsedFingerPrint" class="fas fa-caret-up fa-lg"></i>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-end flex-column col-md-12 fingerprint-dropdown"
    *ngIf="additionalServiceGroupCtrl.value.fingerPrint && additionalServiceGroupCtrl.disabled"
    [ngClass]="{'collapsed': isCollapsedFingerPrint}">
    <div class="col-md-6">
      <div class="item-financial">
        <div>Responsable</div>
        <div class="item-price">
          {{additionalServiceGroupCtrl.value.fingerPrint.userName}}
        </div>
      </div>

    </div>

    <div class="col-md-6">
      <div class="item-financial">
        <div>ID del responsable:</div>
        <div class="item-price">
          {{additionalServiceGroupCtrl.value.fingerPrint.userId}}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="item-financial">
        <div>Fecha:</div>
        <div class="item-price">
          {{(additionalServiceGroupCtrl.value.fingerPrint.date |
          dateFormat)}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="activeSave" class="d-flex justify-content-center">
    <button mat-raised-button class="button-width-auto" (click)="saveEdit()">Guardar</button>
    <button mat-raised-button class="button-width-auto" class="ml-3" (click)="cancelEdit()">Cancelar</button>
  </div>
</mat-card>
<!-- <app-rate-cost-additional-service></app-rate-cost-additional-service> -->