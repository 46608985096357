<div class="modal-body wrapper-modal position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <p class="title mx-4">
    Seleccione una compañia
  </p>
  <app-select-company [options]="optionsCompany" [validate]="validateCompany"
    [inputFormControl]="companySelected"></app-select-company>
  <mat-card-actions>
    <button mat-raised-button class="primary button-width-auto" color="primary" (click)="changeCompany()">
      Cambiar Compañia
    </button>
  </mat-card-actions>
</div>