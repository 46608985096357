<aside class="wrapper-collection-point-detail">

  <div class="col-md-12">
    <div class="row">

      <div class="col-md-5 wrapper-detail" *ngIf="collectionPoint">

        <div class="title-component">
          <i class="fas fa-map-marker-alt"></i>
          {{this.mode === 'create' ? 'Crear' : 'Actualizar'}} punto
        </div>
        <mat-divider></mat-divider>
        <br>

        <div *ngIf="mode === 'detail'" permission-display [module]="permission.point.module"
          [functionality]="permission.point.updatePoint">
          <label class="label-edit-collection-point text-right" (click)="disabledForm ? enableFrom() : disableFrom()">
            <i *ngIf="disabledForm" class="fas fa-edit"></i>
            {{disabledForm ? 'Editar Punto' : 'Cancelar edición'}}
          </label>
        </div>

        <form class="form-container" [formGroup]="formCollectionPoint" (ngSubmit)="onSubmit()"
          *ngIf="formCollectionPoint">
          <mat-form-field *ngIf="mode === 'create'">
            <mat-label>Zona</mat-label>
            <mat-select formControlName="zone">
              <mat-option *ngFor="let zone of collectionPointZones" [value]="zone.name">
                {{zone.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="mode !== 'create'">
            <input matInput type="text" placeholder="Ruta" formControlName="routeId">
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" placeholder="Código SAP" formControlName="sapCode" onlyNumbersInt>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" placeholder="Productor" formControlName="producer">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Finca<span class="asterisk">*</span></mat-label>
            <input matInput type="text" placeholder="Finca" formControlName="farmName">
            <mat-error *ngIf="formCollectionPoint.get('farmName').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tipo<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of collectionPointTypes" [value]="type.name">
                {{type.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="mode !== 'create'">
            <input matInput type="text" placeholder="Hora de recolección" formControlName="schedule">
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" placeholder="Promedio" formControlName="average" onlyNumbersDouble>
          </mat-form-field>

          <mat-list>
            <h3 mat-subheader *ngIf="mode === 'detail'">
              Historial de puntos
            </h3>
            <mat-divider></mat-divider>
            <div *ngIf="disabledForm">
              <mat-list-item *ngFor="let location of collectionPoint.historyLocations"
                (click)="selectHistoryLocation(location)"
                [ngClass]="{'location-active': location.state,'history-location-selected': historyLocationSelected && historyLocationSelected.lat === location.lat}">
                <i class="fas fa-map-marker-alt"></i>
                <h4 mat-line>{{location.date}} - Estado: {{location.state}}</h4>
                <p mat-line>Lat: {{location.lat}} - Lng: {{location.lng}} </p>
              </mat-list-item>
            </div>
            <div *ngIf="mode === 'create' || !disabledForm">
              <mat-list-item class="history-location-selected">
                <i class="fas fa-map-marker-alt"></i>
                <mat-form-field>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <input matInput type="text" formControlName="lat">
                        </div>
                        <div class="col-md-6">
                          <input matInput type="text" formControlName="lng">
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-form-field>
              </mat-list-item>
            </div>
          </mat-list>
          <br>
          <mat-card-actions class="mat-card-actions-collection-point">
            <button mat-raised-button color="primary" permission-display [module]="permission.point.module"
              [functionality]="permission.point.removePoint" class="primary-secondary" type="button"
              *ngIf="mode !== 'create'" (click)="openModalConfirmDeletePoint()">
              Eliminar
            </button>
            <button mat-raised-button color="primary" type="submit" *ngIf="!disabledForm">
              {{this.mode === 'create' ? 'Crear' : 'Guardar'}}
            </button>
          </mat-card-actions>
        </form>

        <!-- Button back -->
        <div class="row md-form" *ngIf="disabledForm">
          <label class="label-back-step" (click)="backView()">
            <figure>
              <img src="/assets/svg/icons/icon-back.png" alt="">
            </figure>
            Atrás
          </label>
        </div>

      </div>

      <div class="col-md-7 wrapper-map">
        <app-standard-map [options]="mapOptions" (clickMap)="onLoadMap($event)"></app-standard-map>
      </div>

    </div>
  </div>

</aside>

<!-- Modal Confirm Delete Collection Point -->
<ng-template #modalConfirmDeletePoint let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title">
      ¿Estás seguro que deseas <br> eliminar este punto?
    </p>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary"
        (click)="hideModalConfirmDeletePoint()">No</button>
      <button mat-raised-button color="primary" (click)="deleteCollectionPoint()">Si</button>
    </mat-card-actions>
  </div>
</ng-template>
