<div class="container col-md-12 mb-md-0 mb-12">
    <div *ngFor="let option of options" class="container-type-load">
        <div class=" type-load" (click)="selectTypeLoad(option)"
            [ngClass]="{'option-step-selected': TypeCargaSelectedName === option.name}">
            <div class="load">
                <div class="container-icon">
                    <div class="load-icon">
                        <img src="{{option.image}}">
                    </div>
                </div>
                <div class="load-title">
                    {{option.name}}
                </div>
            </div>
        </div>
    </div>
</div>