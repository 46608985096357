import { Component, OnInit, ViewChild } from '@angular/core';
import { LoggingService } from './logging.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
interface Log {
  date: string;
  remainingText: string;
}
@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.scss']
})
export class LoggingComponent implements OnInit {
  logs: string = '';
  formatLogsArray = [];
  selectedLogLevelControl = new FormControl('ALL');
  selectedLogLevelSub: Subscription;
  selectedDateControl = new FormControl('OLD');
  selectedDateSub: Subscription;
  formatLogsArraySlice = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSizeOptions: number[] = [2, 5, 10, 20, 30, 50]; // Opciones de tamaño de página
  pageSize: number = this.pageSizeOptions[2];
  first: boolean = true;
  sortOrderOptions = [
    { value: 'OLD', label: 'Más antiguo al más reciente', icon: 'fa-arrow-down' },
    { value: 'NEW', label: 'Más reciente al más antiguo', icon: 'fa-arrow-up' }
  ];
  logLevelOptions = [
    { value: 'ALL', label: 'Todos', icon: 'fa-star-of-life' },
    { value: 'ERROR', label: 'Errores', icon: 'fas fa-times-circle' },
    { value: 'WARNING', label: 'Advertencias', icon: 'fa-exclamation-triangle' },
    { value: 'INFO', label: 'Información', icon: 'fa-info-circle' }
  ]
  pageIndex: number = 0;
  constructor(
    private loggingService: LoggingService,
    private spinner: NgxSpinnerService,
  ) {
    this.changeSelection();
  }

  ngOnInit() {
    this.getTodayLogs()
  }

  getTodayLogs() {
    const logsObserver = {
      next: (data) => {
        this.spinner.hide();
      },
      error: (error) => {
        this.logs = error.error.text;
        const array = this.formatLogs();
        this.assignLogElements(this.selectedLogLevelControl.value, array);
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.loggingService.getLogs().subscribe(logsObserver);
  }

  formatLogs() {
    const logLines = this.logs.split('\n');
    let formattedLines = [];

    for (const line of logLines) {
      const match = line.match(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3})/);

      if (match) {
        const date = match[1];
        const remainingText = line.replace(match[0], '');
        formattedLines.push({ date, remainingText });
      }
    }
    return formattedLines;
  }

  assignLogElements(type: string, array: Log[]) {
    if (type !== 'ALL') {
      array = array.filter(log => {
        return log.remainingText.includes(this.selectedLogLevelControl.value);
      })
    }
    // no puedo borrar la variable ya que es la referencia para el total de elementos
    this.formatLogsArray = array;
    this.orderBy(this.selectedDateControl.value);
  }


  changeSelection() {
    this.selectedLogLevelSub = this.selectedLogLevelControl.valueChanges.subscribe(() => {
      this.getTodayLogs();
    });

    this.selectedDateSub = this.selectedDateControl.valueChanges.subscribe((value) => {
      this.orderBy(value);
    });
  }

  onPageChange(event?: PageEvent) {
    this.pageIndex = event && (event.pageIndex || event.pageIndex === 0) ? event.pageIndex : this.pageIndex;
    this.pageSize = event && event.pageSize ? event.pageSize : this.pageSize
    const startIndex = this.pageIndex * this.pageSize;
    let endIndex = startIndex + this.pageSize;
    if (endIndex > this.formatLogsArray.length) {
      endIndex = this.formatLogsArray.length
    }
    // Actualiza el arreglo 'formatLogsArray' con los elementos de la página actual
    this.formatLogsArraySlice = this.formatLogsArray.slice(startIndex, endIndex);
  }

  //Filtros asc desc
  orderBy(type: string) {
    this.formatLogsArray.sort((a, b) => {
      const timeA = this.getTimeFromDateString(a.date);
      const timeB = this.getTimeFromDateString(b.date);
      if (type === 'NEW') return timeB - timeA;
      return timeA - timeB;
    });
    this.pageIndex = 0;
    this.onPageChange();
  }

  getTimeFromDateString(dateString: string): number {
    const [, timeString] = dateString.split(' ');
    const [time, milliseconds] = timeString.split(',');
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const totalMilliseconds = hours * 3600000 + minutes * 60000 + seconds * 1000 + parseInt(milliseconds, 10);
    return totalMilliseconds;
  }

  ngOnDestroy() {
    if (this.selectedLogLevelSub) this.selectedLogLevelSub.unsubscribe();
    if (this.selectedDateSub) this.selectedDateSub.unsubscribe();
  }
}
