<!-- Session active -->
<ul *ngIf="authService.sessionActive() && orientation === 'horizontal'"
  class="nav justify-content-end font-weight-bold">
  <li class="tooltip-container-profile">
    <a class="nav-link">campana</a>
    <a class="nav-link">
      campana,Hola, {{userName}}
    </a>
    <a class="link-sign-out" (click)="openModalConfirmLogOut(modalConfirmLogOut)">Cerrar sesión</a>
  </li>
</ul>

<button class="btn btn-collapse-nav-responsive" *ngIf="authService.sessionActive()" (click)="toggleNavResponsive()">
  <i class="fas fa-bars"></i>
</button>

<div class="nav-vertical animated" *ngIf="authService.sessionActive() && orientation === 'vertical'"
  [ngClass]="{'display-none': !showNavResponsive}">
  <mat-accordion class="scroller">
    <mat-expansion-panel *ngFor="let item of menu; let i = index;" [hidden]="!item.visible" [expanded]="item.expanded"
      [ngClass]="{'nav-parent-responsive': item.responsive,'nav-parent': !item.responsive,'nav-parent-active': isItemActive(item), 'hide-expansion-panel': !item.childrens}"
      (opened)="onOpen(item)" (closed)="onClose(item)" role-access [navItem]="item">
      <mat-expansion-panel-header class="header-nav-parent" (click)="expansionPanelHeader(item)">
        <mat-panel-title>
          <i class="fas fa-{{item.iconName}} menu-item-icon"></i>
          {{item.displayName}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansion-panel-body">
        <mat-expansion-panel *ngFor="let itemChildren of item.childrens; let iC = index;"
          [ngClass]="{'nav-item-active': activeNav === itemChildren.route, 'display-none': !itemChildren.visible || !canBeShown(itemChildren)}"
          (click)="onClickItemMenu(itemChildren, item)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{itemChildren.displayName}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="expansion-panel-body"></div>
        </mat-expansion-panel>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="hide-expansion-panel" [expanded]="false"
      *ngIf="!environment?.production && authService.sessionActive() && !redirectionSignIn()">
      <mat-expansion-panel-header class="header-nav-parent" (click)="openMasterTools()">
        <mat-panel-title>
          <i class="fas fa-wrench menu-item-icon"></i>
          Herramientas
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <!-- Log out  responsive -->
    <mat-expansion-panel class="hide-expansion-panel" [expanded]="false">
      <mat-expansion-panel-header class="header-nav-parent" (click)="openModalConfirmLogOut(modalConfirmLogOut)">
        <mat-panel-title class="align-items-center">
          <i class="fas fa-sign-out-alt menu-item-icon"></i>
          Cerrar sesión
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- Footer  -->
  <figure class="logo-nav-bottom text-center">
    <div>
      <span class="copy__right--year">© {{global.currentYear}}</span>
      <span class="copy__right--title"> Todos los derechos reservados.</span>
    </div>
    <div class="version__app">
      v {{version}}
    </div>
  </figure>
</div>

<button class="btn btn-collapse-nav-responsive" *ngIf="!authService.sessionActive()" (click)="toggleNavResponsive()">
  <i class="fas fa-bars"></i>
</button>

<!-- Session no active -->
<ul *ngIf="!authService.sessionActive()" class="nav justify-content-end font-weight-bold"
  [ngClass]="{'display-none': !showNavResponsive && !authService.sessionActive()}">
  <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/carriers' || activeNav === '/shippers'}">
    <a class="nav-link">Servicios</a>
    <ul>
      <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/carriers'}">
        <a class="nav-link" (click)="navigateTo('/carriers')">Transportador</a>
      </li>
      <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/shippers'}">
        <a class="nav-link" (click)="navigateTo('/shippers')">Generador</a>
      </li>
    </ul>
  </li>
  <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/suggestions'}">
    <a class="nav-link" (click)="navigateTo('/suggestions')">Propuestas de valor</a>
  </li>
  <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/contact' || activeNav === '/ethical-line'}">
    <a class="nav-link">Contacto</a>
    <ul>
      <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/contact'}">
        <a class="nav-link" (click)="navigateTo('/contact')">Contáctanos</a>
      </li>
      <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/ethical-line'}">
        <a class="nav-link" (click)="navigateTo('/ethical-line')">Línea ética</a>
      </li>
    </ul>
  </li>
  <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/track-guide'}">
    <a class="nav-link" (click)="navigateTo('/track-guide')">Rastreo de Guias</a>
  </li>
  <li class="nav-item"
    [ngClass]="{'nav-item-active': activeNav === '/account/login' || activeNav === '/account/register'}">
    <a class="nav-link">Iniciar sesión</a>
    <ul>
      <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/account/login'}">
        <a class="nav-link" (click)="navigateTo('/account/login')">Inicia sesión</a>
      </li>
      <li class="nav-item" [ngClass]="{'nav-item-active': activeNav === '/account/register'}">
        <a class="nav-link" (click)="navigateTo('/account/register')">Regístrate</a>
      </li>
    </ul>
  </li>
</ul>

<ng-template #modalConfirmLogOut let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title">
      ¿Estás seguro que deseas <br> cerrar esta sesión?
    </p>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary"
        (click)="closeModalConfirmLogOut()">No</button>
      <button mat-raised-button color="primary" (click)="logOut()">Si</button>
    </mat-card-actions>
  </div>
</ng-template>
