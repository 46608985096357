<div class="cargo__billing">
  <div *ngIf="error">
    <h5 class="text-center text-strong">Error al generar el Comprobante Contable</h5>
  </div>
  <div *ngIf="cargosJournalError.length">
    <h5 class="text-center text-strong">Error al generar el Comprobante Contable para las siguientes Cargas:</h5>
    <ul class="cargo__billing__list">
      <li *ngFor="let cargo of cargosJournalError;">
        <span class="text-strong">
          Carga {{cargo.consecutive}}: {{cargo.journalError.description}}
        </span>
      </li>
    </ul>
  </div>
  <div class="cargo__billing__footer" *ngIf="cargosJournalError.length">
    <mat-card-actions>
      <button mat-raised-button class="button-width-auto" color="primary" (click)="onConfirm()">
        Generar Comprobantes de nuevo
      </button>
    </mat-card-actions>
  </div>
  <hr *ngIf="cargosJournalError.length">
  <div *ngIf="cargosJournalSuccess.length">
    <h5 class="text-center text-strong">Comprobante Contable generado para las siguientes Cargas:</h5>
    <ul class="cargo__billing__list">
      <li *ngFor="let cargo of cargosJournalSuccess;">
        <span class="text-strong">
          Carga {{cargo.consecutive}}
        </span>
      </li>
    </ul>
  </div>
  <div class="cargo__billing__footer" *ngIf="cargosJournalSuccess.length || error">
    <mat-card-actions>
      <span class="color-secondary-dark text-strong btn-cancel" (click)="onCancel()">
        Cerrar
      </span>
    </mat-card-actions>
  </div>
</div>