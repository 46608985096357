import { Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { RateCostAdditionalServicesModel } from 'src/app/core/interfaces/rateCostAdditionalServices';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RateCostAdditionalServicesService {
  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
  ) { }

  modifyValuesAdditionalCost(body: RateCostAdditionalServicesModel, additionalCostId: string) {
    let url = environment.urlServerTeclogi + this.endpointResources.modifyValuesAdditionalCost;
    let params = new HttpParams();
    params = params.append('additionalCostId', additionalCostId)
    return this.http.post(url, body, {params})
  }



}
