import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Router } from "@angular/router";
import { User } from "firebase";
import { NgxSpinnerService } from "ngx-spinner";
import { Gps } from "src/app/core/interfaces/gps";
import { Model, Vehicle } from "src/app/core/interfaces/vehicle";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import { Utils } from "src/app/core/resources/utils";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { SelectGpsComponent } from "src/app/shared/select-gps/select-gps.component";
import { CreateGpsVehicleComponent } from "../create-gps-vehicle/create-gps-vehicle.component";
import { VehiclesService } from "../list-vehicles.service";
import { AuthService } from "src/app/core/services/authentication.service";
import { Permission } from "src/app/core/resources/permission";
import { DeleteGpsVehicleComponent } from "../delete-gps-vehicle/delete-gps-vehicle.component";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { OptionsAutocomplete } from "src/app/core/interfaces/optionsAutocomplete";
import { Patterns } from "src/app/core/resources/patterns";
@Component({
  selector: "app-gps-vehicle",
  templateUrl: "./gps-vehicle.component.html",
  styleUrls: ["./gps-vehicle.component.scss"],
  providers: [Model],
})
export class GpsVehicleComponent implements OnInit {
  @Input() title: string;
  @Input() form: FormGroup;
  @Input() updateGPS?: boolean;
  @Input() vehicle?: Vehicle;
  @Input() driver?: User;
  reactiveForm: ReactiveForm;
  permission = Permission;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @ViewChild(SelectGpsComponent, { static: false })
  selectGpsComponent: SelectGpsComponent;
  validate: string = '';
  optionsGps: OptionsAutocomplete = {
    hint: 'Por favor seleccione una opcion de la lista, no copiar url del GPS'
  };
  constructor(
    public dialogRef: MatDialogRef<GpsVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private vehiclesService: VehiclesService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    public patterns: Patterns
  ) {
    if (this.dialogParams && this.dialogParams.form) {
      if (this.dialogParams.form) {
        this.form = this.dialogParams.form;
        this.form.enable();
        this.optionsGps['initialValue'] = this.gpsControls.value;
      }
      if (this.dialogParams.onlyGPS) {
        this.updateGPS = this.dialogParams.onlyGPS;
      }

      if (this.dialogParams.vehicle) {
        this.vehicle = this.dialogParams.vehicle;
      }

      if (this.dialogParams.driver) {
        this.driver = this.dialogParams.driver;
      }
    }
    if (this.updateGPS) {
      this.form.get('gpsType').setValidators(Validators.required);
      this.form.get('userGps').setValidators(Validators.required);
      this.form.get('passwordGps').setValidators(Validators.required);
    }
  }

  /**
  * @description Sets the URL_FORMAT pattern as validator to form's gpsType
  */
  ngOnInit(): void {
    this.form.get('gpsType').setValidators(Validators.pattern(this.patterns.URL_FORMAT.source));
  }

  /**
  * @returns {FormControl} returns the form's gpsType as FormControl
  * @description Gets the form's gpsType as FormControl
  */
  private get gpsControls(): FormControl {
    return this.form.get("gpsType") as FormControl;
  }

  /**
  * @description Verifies if the form is valid and updates the vehicle's gps and closes the modal
  */
  public onSubmit() {
    this.validate = 'touched';
    if ((this.form.get('gpsType').value || this.form.get('userGps').value || this.form.get('passwordGps').value) &&
      !(this.form.get('gpsType').value && this.form.get('userGps').value && this.form.get('passwordGps').value)) {
      if (this.form.get('gpsType').invalid) {
        this.snackBarService.openSnackBar(FormMessages.AT_LEAST_ONE_OPTION, undefined, 'alert');
        return;
      }
      else if (this.utils.errorMessagesCustomized(this.form.get('gpsType'), 'tipo de GPS')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('userGps'), 'usuario del GPS')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('passwordGps'), 'contraseña de GPS')) return;
      return;
    } else if (!this.form.get('gpsType').value && !this.form.get('userGps').value && !this.form.get('passwordGps').value) {
      this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
      return;
    }
    this.spinner.show();
    const vehicle = this.utils.clone(this.vehicle);
    vehicle.gps = this.form.value;
    const driver = this.utils.clone(this.driver);
    delete driver.profilePictureUrl;
    const data: Gps = this.form.value;
    this.vehiclesService.updateGpsDocuments(this.vehicle.id, data).subscribe(
      (success) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Datos guardados correctamente",
          undefined,
          "success"
        );
        this.emitToParent.emit();
        this.dialogRef.close({ state: true });
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al guardar los datos",
          undefined,
          "error"
        );
      }
    );

  }

  /**
  * @description Opens a modal to create a new GPS
  */
  public createGPS(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const modalDialog = this.dialog.open(
      CreateGpsVehicleComponent,
      dialogConfig
    );
    modalDialog.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.emitToParent.emit();
        this.refreshListGps();
      }
    });
  }

  /**
  * @description Opens a modal to delete some GPS
  */
  public deleteGPS(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const modalDialog = this.dialog.open(
      DeleteGpsVehicleComponent,
      dialogConfig
    );
    modalDialog.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.emitToParent.emit();
        this.refreshListGps();
      }
    });
  }

  /**
  * @description Closes the current modal
  */
  public closeDialog(value?: boolean): void {
    if (this.dialogRef && this.dialogRef.close) {
      if (this.utils.isDefined(value)) {
        this.dialogRef.close({ state: true });
      } else {
        this.dialogRef.close();
      }
    }
  }

  private refreshListGps(): void {
    if (this.selectGpsComponent && this.selectGpsComponent.getListGpsTypes) {
      this.selectGpsComponent.getListGpsTypes();
    }
  }
}
