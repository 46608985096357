<div class="row" [formGroup]="docInfo">
  <div class="col-12 col-md-6 mb-2">
    <mat-form-field class="documentTypeName-input">
      <mat-label>Tipo de identificación<span class="asterisk"
          *ngIf="utils.isRequiredField(docInfo.get('documentTypeName'))">*</span></mat-label>
      <mat-select (selectionChange)="onChangeDocumentType($event)" formControlName="documentTypeName"
        name="documentTypeName">
        <mat-option *ngFor="let documentType of documentTypes" value="{{documentType.name}}">
          {{documentType.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="docInfo.get('documentTypeName').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <mat-form-field>
      <mat-label>N° de identificación<span class="asterisk"
          *ngIf="utils.isRequiredField(docInfo.get('document'))">*</span></mat-label>
      <input [id]="documentId" type="text" matInput onlyNumbersInt formControlName="document" placeholder="Ej. 10203040"
        [matAutocomplete]="autocompleteUser">
      <i matSuffix class="fas fa-search fa-lg color-primary cursor-pointer" matTooltip="Buscar usuario"
        (click)="searchUser()"></i>
      <mat-autocomplete #autocompleteUser="matAutocomplete" autoActiveFirstOption
        (optionSelected)="onSelectUser($event)">
        <mat-option *ngFor="let item of listFiltered" [value]="item">
          {{ item.information.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="docInfo.get('document').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="docInfo.get('document').errors?.minlength">
        {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'n° de identificación', 3) }}
      </mat-error>
      <mat-error *ngIf="docInfo.get('document').errors?.maxlength">
        {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'n° de identificación', 15) }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <mat-form-field class="name-input">
      <mat-label>Nombre<span class="asterisk" *ngIf="utils.isRequiredField(docInfo.get('name'))">*</span></mat-label>
      <input type="text" matInput formControlName="name" placeholder="Ej. pepito perez">
      <mat-error *ngIf="docInfo.get('name').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>
  </div>
</div>