<hr *ngIf="!isDialog">
<div class="head position-relative">
  <i *ngIf="isDialog" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <div class="col-md-12">
    <!-- Valor del RETEICA -->
    <div class="row" [ngClass]="{'dialog-config': isDialog}">
      <div class="col-md-4" *ngFor="let additionalService of additionalCosts">
        <div class="col-md-12">
          <div class="row container-title-financial">
            <span class="col-md-12">
              {{additionalService?.type?.name}}
              <span [ngClass]="{'color-secondary-dark': additionalService?.financialInformation?.paid}">
                ({{additionalService?.financialInformation?.paid ?
                'Pagado' : 'Por Pagar'}})
              </span>
            </span>
          </div>
        </div>
        <div class="col-md-12" *ngIf="showAdditionalCost">
          <div class="row" *ngIf="authService.getCompany().companyId === environment.rootNit">
            <div class="col-md-12 item-financial">
              <div>Costo</div>
              <div class="item-price">
                {{additionalService.financialInformation?.cost | currency:'COP':'code'}}
              </div>
            </div>
            <div class="col-md-12 item-financial justify-content-end"
              *ngIf="getTotalBonusesOrDiscounts(additionalService, 'bonus','amount')">
              <div class="item-price text__color color--success">
                (+){{getTotalBonusesOrDiscounts(additionalService, 'bonus','amount')| currency:'COP':'code'}}
              </div>
            </div>
            <div class="col-md-12 item-financial justify-content-end"
              *ngIf="getTotalBonusesOrDiscounts(additionalService, 'discount','amount')">
              <div class="item-price text__color color--error">
                (-){{getTotalBonusesOrDiscounts(additionalService, 'discount','amount')| currency:'COP':'code'}}
              </div>
            </div>
          </div>
          <div class="row mt-2" *ngIf="showAdditionalRate">
            <div class=" col-md-12 item-financial">
              <div>Tarífa</div>
              <div class="item-price">
                {{additionalService?.financialInformation?.rate | currency:'COP':'code'}}
              </div>
            </div>
            <div class="col-md-12 item-financial justify-content-end"
              *ngIf="getTotalBonusesOrDiscounts(additionalService, 'bonus','amountRate')">
              <div class="item-price text__color color--success">
                (+){{getTotalBonusesOrDiscounts(additionalService, 'bonus','amountRate')| currency:'COP':'code'}}
              </div>
            </div>
            <div class="col-md-12 item-financial justify-content-end"
              *ngIf="getTotalBonusesOrDiscounts(additionalService, 'discount','amountRate')">
              <div class="item-price text__color color--error">
                (-){{getTotalBonusesOrDiscounts(additionalService, 'discount','amountRate')| currency:'COP':'code'}}
              </div>
            </div>
          </div>
          <div
            class="cursor-pointer item-financial col-md-12 color-secondary-dark d-flex align-items-center justify-content-between p-0"
            (click)="showFingerPrintInfo(!additionalService.show, additionalService)"
            *ngIf="additionalService?.fingerPrint">
            <u class="mr-1"><strong>¿Quién creó el servicio? </strong></u>
            <mat-icon *ngIf="!additionalService.show">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="additionalService.show">keyboard_arrow_up</mat-icon>
          </div>
          <div class="items-dropdown row" [ngClass]="{'collapsed': additionalService.show}">
            <div class="col-md-12 item-financial" *ngIf="additionalService?.fingerPrint">
              <div>Responsable</div>
              <div class="item-price color-secondary-dark text__color">
                {{additionalService.fingerPrint.userName}}
              </div>
            </div>

            <div class="col-md-12 item-financial" *ngIf="additionalService?.fingerPrint">
              <div>ID</div>
              <div class="item-price color-secondary-dark text__color">
                {{additionalService.fingerPrint.userId}}
              </div>
            </div>

            <div class="col-md-12 item-financial" *ngIf="additionalService?.fingerPrint">
              <div>Fecha</div>
              <div class="item-price color-secondary-dark text__color">
                {{ ( additionalService.fingerPrint.date|dateFormat)}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
<hr *ngIf="!isDialog">