<div class="row-point-item" (click)="goToTicket()">
  <div class="row container-date">
    <div class="col-md-3">
      <div class="title-purple">
        Caso {{ticket.id}}
      </div>
    </div>
    <div class="col-md-3">
      <div matTooltip="{{ticket.state}}" class="text-bold">
        <div class="circle" [ngStyle]="currentStateColor"></div> {{ticket.state}}
      </div>
    </div>
    <div class="col-md-6" *ngIf="ticket.type">
      <div class="text-bold">
        <i class="color-primary" [ngClass]="currentType.icon"></i> {{currentType.title}}
      </div>
    </div>
  </div>

  <div class="row container-data">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6">
          <div class="label">
            Propietario
          </div>
          <div class="text-bold label-field">
            <span matTooltip="{{ticket.user}}">
              {{ticket.user}}
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="label">
            Fecha de Creacion
          </div>
          <div class="text-bold label-field">
            <span matTooltip="{{ticket.expeditionDate | dateFormat}}">
              {{ticket.expeditionDate | dateFormat}}
            </span>
          </div>
        </div>
        <div class="col-md-6" *ngIf="ticket.responsable">
          <div class="label">
            Responsable
          </div>
          <div class="text-bold label-field">
            <span matTooltip="{{ticket.responsable}}">
              {{ticket.responsable}}
            </span>
          </div>
        </div>
        <div class="col-md-6" *ngIf="ticket.endDate && ticket.state==='Cerrado'"
          [ngClass]="{'offset-md-6':!ticket.responsable}">
          <div class="label">
            Fecha de Cierre
          </div>
          <div class="text-bold label-field">
            <span matTooltip="{{ticket.endDate | dateFormat}}">
              {{ticket.endDate | dateFormat}}
            </span>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-6 pr-4">
      <div class="label">
        Asunto
      </div>
      <div class="text-bold label-field">
        <span matTooltip="{{ticket.subject}}">
          {{ticket.subject}}
        </span>
      </div>
    </div>

    <figure class="icon-arrow-container" (click)="goToTicket()">
      <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
    </figure>
  </div>
</div>