<div class="guide" [ngClass]="{ 'item-list-selected': guide.checked }">
  <div class="row container-date">
    <!-- N° guide  -->
    <div class="col-md-4" *ngIf="guide.guideId">
      <span class="title-purple" matTooltip="{{guide.guideId}}">
        N° Guía: {{guide.guideId}}
      </span>
    </div>
    <!-- Deadline  -->
    <div class="col-md-3" *ngIf="guide.deadline">
      <span class="title-purple" matTooltip="{{guide.deadline}}">
        {{guide.deadline}}
      </span>
    </div>
    <!-- State  -->
    <div class="col-md-3" *ngIf="guide.stateDocument | stateGuide">
      <span class="title-purple" matTooltip="{{guide.stateDocument | stateGuide}}">
        Estado: {{guide.stateDocument | stateGuide}}
      </span>
    </div>
  </div>
  <div class="row container-data">
    <div class="col-md-2">
      <div class="label">
        Ciudad de origen
      </div>
      <div class="text-bold label-field">
        <span *ngIf="guide.originCity" matTooltip="{{guide.originCity}}">
          {{guide.originCity}}
        </span>
      </div>
    </div>
    <div class="col-md-2">
      <div class="label">
        Ciudad de destino
      </div>
      <div class="text-bold label-field">
        <span *ngIf="guide.destinationCity" matTooltip="{{guide.destinationCity}}">
          {{guide.destinationCity}}
        </span>
      </div>
    </div>
    <div class="col-md-2">
      <div class="label">
        Seller
      </div>
      <div class="text-bold label-field">
        <span *ngIf="guide.sender && guide.sender.nameCompany" matTooltip="{{guide.sender.nameCompany}}">
          {{guide.sender.nameCompany}}
        </span>
      </div>
    </div>
    <div class="col-md-2">
      <div class="label">
        N° Paquetes
      </div>
      <div class="text-bold label-field">
        <span *ngIf="guide.amountPackages" matTooltip="{{guide.amountPackages}}">
          {{guide.amountPackages}}
        </span>
      </div>
    </div>
    <div [ngClass]="{'col-md-2': print, 'col-md-4': !print}">
      <div class="label">
        Tipo de contenido
      </div>
      <div class="text-bold label-field">
        <span *ngIf="guide.typeContent" matTooltip="{{guide.typeContent}}">
          {{guide.typeContent}}
        </span>
      </div>
    </div>
    <!-- <div class="col-md-2" *ngIf="print"> -->
    <!-- Print lazer  -->
    <!-- <button *ngIf="guide.guideId" mat-raised-button color="primary" class="button-create-cargo" (click)="printGuide(guide.guideId)">
        <i class="fas fa-print"></i> &nbsp; Láser
      </button>
      <br>
      <br> -->
    <!-- Print Zebra  -->
    <!-- <button *ngIf="guide.guideId" mat-raised-button color="primary" class="button-create-cargo" (click)="printGuide(guide.guideId)">
        <i class="fas fa-print"></i> &nbsp; Zebra
      </button> -->
    <!-- </div> -->
  </div>

</div>

<app-print-guide id="guidePrint{{guide.guideId}}" [guide]="guide" style="display:none;"></app-print-guide>
