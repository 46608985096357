import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { AdditionalCost } from '../interfaces/additionalCost';
import { Catalog } from '../interfaces/catalog';
import { CatalogItem } from '../interfaces/catalogItem';
import { AuthService } from '../services/authentication.service';
import { RoleService } from '../services/role.service';

@Injectable({ providedIn: 'root' })
export class AditionalServicesResolver implements Resolve<any> {

    constructor(
        private authService: AuthService,
        private router: Router,
        private roleService: RoleService,
        private manualCreationCargoService: ManualCreationCargoService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<AdditionalCost[]> {
        if (this.manualCreationCargoService.getListAdditionalCost() && this.manualCreationCargoService.getListAdditionalCost().length) {
            return of(this.manualCreationCargoService.getListAdditionalCost());
        } else {
            return this.manualCreationCargoService.getValueAdditionalCost();
        }
    }

}
