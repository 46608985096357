<aside class="col-md-12 mb-md-0 mb-12 container">
    <div class="row">
        <div class="col-md-6">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12">
                        <app-autocomplete-city [options]="cityOptions" [inputFormControl]="cityControl"
                            [validate]="validate">
                        </app-autocomplete-city>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <app-autocomplete-address title="Ingrese la dirección" [addLatLong]="true"
                            (emitToParent)="onSelectAddress($event)" [address]="form.get('address').value">
                        </app-autocomplete-address>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Descripción de la novedad</mat-label>
                            <input matInput type="tel" formControlName="situation" />
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6">
            <app-standard-map [options]="mapOptions" (clickMap)="onClickMap($event)"></app-standard-map>
        </div>
        <div class="col-12">
            <button mat-raised-button color="primary" class="button-width-auto" (click)="submit()">
                Reportar
            </button>
        </div>
    </div>
</aside>
