import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FormThirdPartyConsignmentService {


    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
    ) { }

    getThirdPartiesByParent(pageKey: number, pageSize: number, parent: string) {
        let params = new HttpParams();
        params = params.append('pageKey', pageKey.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('parent', parent);
        return this.http.get(
            environment.urlServerTeclogi + this.endpointResources.thirdParties,
            { params }
        );
    }

    getThirdPartiesAddressByCompany(pageKey: number, pageSize: number, companyId: string) {
        let params = new HttpParams();
        params = params.append('pageKey', pageKey.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('companyId', companyId);
        return this.http.get(
            environment.urlServerTeclogi + this.endpointResources.thirdPartiesAddress,
            { params }
        );
    }

    createThirdParty(data: any) {
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.thirdParties,
            data
        );
    }

}
