

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { UploadGuideComponent } from './upload-guide/upload-guide.component';
import { GenerateGuideComponent } from './generate-guide/generate-guide.component';
import { TrackGuideComponent } from 'src/app/public/track-guide/track-guide.component';
import { MainComponent } from 'src/app/layout/main/main.component';

const routes: Routes = [
    {
        path: '',
        data: { breadcrumb: 'Guías' },
        children: [
            {
                path: 'upload-guides',
                data: { breadcrumb: 'Cargar' },
                component: UploadGuideComponent,
                canActivate: [UserActiveGuard, RoleGuard],
                resolve: {
                    UserResolver
                }
            },
            {
                path: 'generate-guides',
                data: { breadcrumb: 'Generar' },
                component: GenerateGuideComponent,
                canActivate: [UserActiveGuard, RoleGuard],
                resolve: {
                    UserResolver
                }
            },
            {
                path: 'track-guide',
                data: { breadcrumb: 'Seguimiento' },
                component: TrackGuideComponent,
                canActivate: [UserActiveGuard],
                resolve: {
                    UserResolver
                }
            },
            { path: '**', redirectTo: '/guide/upload-guides' }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class GuideRoutingModule { }
