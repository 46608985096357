import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { BranchOfficeModel, BranchOffice } from 'src/app/core/interfaces/branch-offices';
import { Company } from 'src/app/core/interfaces/company';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';

@Component({
  selector: 'app-branch-office-owner-cargo',
  templateUrl: './branch-office-owner-cargo.component.html',
  styleUrls: ['./branch-office-owner-cargo.component.scss'],
  providers: [BranchOfficeModel]
})
export class BranchOfficeOwnerCargoComponent implements OnInit {

  @Input() public companyNit: string;
  @Input() public listBranch: BranchOffice[];
  @Input() public title: string;
  @Input() public config: { value: any };
  @Output() public selectedBranch: EventEmitter<BranchOffice> = new EventEmitter();
  public listBranchOfficeAsync: Observable<Company[]>;
  public reactiveForm: ReactiveForm;

  constructor(
    private authService: AuthService,
    public formBuilder: FormBuilder,
    private branchOfficeModel: BranchOfficeModel,
    private manualCreationCargoService: ManualCreationCargoService
  ) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.companyNit) {
      this.getCompanyData(changes.companyNit.currentValue);
    }
    if (changes && changes.listBranch) {
      this.initForm();
    }
    if (changes && changes.config) {
      this.initForm();
    }
  }

  public onSelect($event: MatAutocompleteSelectedEvent): void {
    this.selectedBranch.emit($event.option.value);
  }

  private getCompanyData(nit: string): void {
    const companyFilter = this.authService.getCompanies().filter((company) => company.companyId === nit);
    if (companyFilter.length) {
      this.listBranch = companyFilter[0].branchOffices;
      this.initForm();
    }
  }

  private initForm(): void {
    if (this.listBranch && this.listBranch.length) {
      this.reactiveForm = new ReactiveForm(this.formBuilder, this.branchOfficeModel.model);
      this.listBranchOfficeAsync = this.reactiveForm.form.get('name').valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        map(company => company ? this.filterCompanies(company) : this.listBranch ? this.listBranch.slice() : [])
      );
      if (this.config && this.config.value) {
        this.reactiveForm.form.patchValue(this.config.value);
      }
    }
  }

  private filterCompanies(value: any): BranchOffice[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.listBranch.filter(branch => branch.name.toLowerCase().indexOf(filterValue) === 0);
    } else if (typeof value === 'object') {
      try {
        const filterValue = value.name.toLowerCase();
        return this.listBranch.filter(branch => branch.name.toLowerCase().indexOf(filterValue) === 0);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }

  public displayBranchName(branch: BranchOffice): string {
    return typeof branch === 'string' && branch ? branch : branch && branch.name ? branch.name : '';
  }

}
