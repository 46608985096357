import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Utils } from '../resources/utils';

@Directive({
  selector: 'input[onlyNumbersInt]'
})
export class OnlyNumberIntDirective {

  constructor(
    private element: ElementRef,
    public utils: Utils,
    private control: NgControl
  ) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.utils.clone(this.element.nativeElement.value);
    const newValue = initialValue ? initialValue.replace(/[^0-9]*/g, '') : initialValue;
    if (initialValue !== newValue) {
      this.setValueControl = newValue;
      event.stopPropagation();
    }
  }

  set setValueControl(value: any) {
    if (this.control && this.control.control) {
      this.control.control.setValue(value);
    }
  }

}
