<form class="row d-flex align-items-center justify-content-start">
    <div class="col-md-6" *ngIf="canAssociateCostCenter">
        <mat-form-field appearance="standard">
            <mat-label>Centro de Costo<span class="asterisk">*</span></mat-label>
            <input type="text" #inputAutoComplete matInput [formControl]="costCenterCtrl" [matAutocomplete]="auto">
            <mat-error *ngIf="costCenterCtrl.errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCostCenterSelected">
                <mat-option *ngFor="let costCenter of listGeneralCostCenter | async" [value]="costCenter">
                    {{costCenter.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="col-md-4" *ngIf="canAssociateCostCenter">
        <div class="button-container justify-content-start">
            <button type="button" (click)="onSubmit()" mat-raised-button class="bg-color-primary">
                Asociar
            </button>
        </div>
    </div>
    <div class="col-12" *ngIf="canCreateCostCenter">
        <div class="button-container justify-content-end">
            <button matTooltip="Crear centro de costo" mat-raised-button type="button" class="button-width-auto"
                (click)="openCreateCostCenter()">
                <i class="fas fa-plus-circle font-primary-dark"></i>
                <span class="font-primary-dark">Crear</span>
            </button>
        </div>
    </div>
</form>
<hr>
<aside class="cost__center--list">
    <app-cost-center-item *ngFor="let costCenter of costCenters; let i = index;" [costCenter]="costCenter" [activeTab]="activeTab">
    </app-cost-center-item>
</aside>
