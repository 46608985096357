import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatSelectChange } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ApprovalState, ApprovalStateEnum, ImageFingerprint, PreviewImagesMap, TravelExpense, TravelExpenseDetail, TravelExpensesImages } from 'src/app/core/interfaces/travel-expense';
import { LegalizeStatePipe } from 'src/app/core/pipe/legalize-state.pipe';
import { Dialog } from 'src/app/core/resources/dialog';
import { TravelExpensesService } from 'src/app/core/services/travel-expenses.service';
import { TravelExpensesDetailComponent } from '../travel-expenses-detail/travel-expenses-detail.component';
import { AuthService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';

@Component({
  selector: 'app-legalize-travel-expenses',
  templateUrl: './legalize-travel-expenses.component.html',
  styleUrls: ['./legalize-travel-expenses.component.scss']
})
export class LegalizeTravelExpensesComponent implements OnInit {

  showMoreState: Array<boolean> = [];
  travelExpenses: Array<TravelExpense> = [];
  states: string[] = ['Pending approval', 'Rejected', 'Approved'];
  travelExpenseForm: FormGroup;
  private pathNoImage: string = "/assets/svg/icons/icon-no-file.svg";
  public listPreviewImages: PreviewImagesMap = {};

  constructor(
    public ref: MatDialogRef<LegalizeTravelExpensesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cargo: Cargo,
      travelExpenses: Array<TravelExpense>
    },
    private fb: FormBuilder,
    private dialog: Dialog,
    private dialogMat: MatDialog,
    private travelExpensesService: TravelExpensesService,
    private legalizeStatePipe: LegalizeStatePipe,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private permissionRole: PermissionRole,
  ) {

  }

  ngOnInit() {
    this.travelExpenses = this.data.travelExpenses;
    this.initData();
  }

  get canChangeApprovedTravelExpenses() {
    return this.permissionRole.hasPermission(
      Permission.cargo.module,
      Permission.cargo.changeApprovedTravelExpenses
    );
  }

  get isClosed() {
    const anyOpen = this.travelExpenses.some(
      (travelExpense: TravelExpense) => travelExpense.approval !== ApprovalStateEnum.APPROVED
    );
    return anyOpen;
  }

  private initData(): void {
    this.listPreviewImages = {};
    this.getUrlFirestorage();
    this.createForm();
    for (const i in this.travelExpenses) {
      this.showMoreState[i] = false;
      this.expenses.push(this.newExpense(this.travelExpenses[i]));
    }
  }

  private createForm(): void {
    this.travelExpenseForm = this.fb.group({
      expenses: this.fb.array([])
    });
  }

  get expenses(): FormArray {
    return this.travelExpenseForm.get('expenses') as FormArray;
  }

  private newExpense(travelExpenses: TravelExpense): FormGroup {
    return this.fb.group({
      id: [travelExpenses.id, Validators.required],
      travelExpensesType: this.fb.group({
        id: [travelExpenses.travelExpensesType.id, Validators.required],
        name: [travelExpenses.travelExpensesType.name, Validators.required]
      }),
      totalPaid: [travelExpenses.totalPaid, [Validators.required, Validators.min(0)]],
      state: [travelExpenses.state, Validators.required],
      approval: [travelExpenses.approval, Validators.required],
    });
  }

  public closeDialog(): void {
    this.ref.close();
  }

  public calculateUnlegalized(travelExpense: TravelExpense): number {
    return travelExpense.approval === ApprovalStateEnum.PENDING ? travelExpense.totalSpentValue : 0;
    /*const unlegalized = this.filterDetailsByApprovalState(
      travelExpense,
      ApprovalStateEnum.PENDING
    );
    return unlegalized.reduce((accumulator, travelExpenseDetail) => accumulator + travelExpenseDetail.value, 0);*/
  }

  public calculateRejected(travelExpense: TravelExpense): number {
    // Pending approval, Approved, Rejected
    return travelExpense.approval === ApprovalStateEnum.REJECTED ? travelExpense.totalSpentValue : 0;
    /*const rejections = this.filterDetailsByApprovalState(
      travelExpense,
      ApprovalStateEnum.REJECTED
    );

    return rejections.reduce((accumulator, travelExpenseDetail) => accumulator + travelExpenseDetail.value, 0);*/
  }

  private filterDetailsByApprovalState(travelExpense: TravelExpense, approvalState: ApprovalState): TravelExpenseDetail[] {
    return travelExpense.travelExpensesDetail.filter(
      (travelExpenseDetail: TravelExpenseDetail) =>
        travelExpenseDetail.approval == approvalState
    )
  }

  public showMore(i: string): void {
    this.showMoreState[i] = !this.showMoreState[i];
  }

  public onChangeApprovalExpense(travelExpense: TravelExpense, $event: MatSelectChange): void {
    this.dialog
      .openDialog({
        title: `¿Estás seguro que deseas dejar ${this.legalizeStatePipe.transform($event.value)} el viatico ${travelExpense.travelExpensesType.name}?`,
      })
      .then((response) => {
        if (response && response.state)
          this.changeApprovalExpense(travelExpense, $event.value);
        else
          this.getDataLegalizeTravelExpenses();
      })
      .catch((error) => this.getDataLegalizeTravelExpenses());
  }

  private changeApprovalExpense(travelExpense: TravelExpense, approval: ApprovalState): void {
    this.spinner.show();
    const body: TravelExpense = {
      approval
    }
    this.travelExpensesService.updateTravelExpense(travelExpense.id, body).subscribe(
      (success) => {
        this.spinner.hide();
        this.getDataLegalizeTravelExpenses();
        this.snackbarService.openSnackBar(
          "Se ha cambiado el estado de la legalización correctamente",
          undefined,
          "success"
        );
      },
      (error) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(
          "Ocurrió un error al cambiar el estado de la legalización",
          undefined,
          "error"
        );
      }
    );
  }

  public openFormAddTravelExpensesDetail(travelExpense: TravelExpense): void {
    if (travelExpense.approval === 'Approved') return;
    const config = new MatDialogConfig();
    config.data = {
      travelExpense,
      cargo: this.data.cargo
    }
    config.width = ModalEnum.MEDIUM_WIDTH;
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.maxWidth = ModalEnum.MAX_WIDTH;

    this.dialogMat
      .open(TravelExpensesDetailComponent, config)
      .afterClosed()
      .subscribe(
        (refresh) => {
          this.getDataLegalizeTravelExpenses();
        }
      );
  }

  private getListPathImages(): TravelExpensesImages {
    const listPaths = {};
    this.travelExpenses.forEach((travelExpense) => {
      travelExpense.travelExpensesDetail.forEach((travelExpenseDetail) => {
        if (!listPaths[travelExpense.id])
          listPaths[travelExpense.id] = []
        listPaths[travelExpense.id] = listPaths[travelExpense.id].concat([...travelExpenseDetail.imageWithFingerprints]);
      });
    });
    return listPaths;
  }

  private getUrlFirestorage(): void {
    const imagesById: TravelExpensesImages = this.getListPathImages();
    const storage = AuthService.fStorage;
    for (let id in imagesById) {
      imagesById[id].forEach(image => {
        const pathReference = storage.ref(image.path);
        pathReference
          .getDownloadURL()
          .then(
            (success) => {
              if (!this.listPreviewImages[id])
                this.listPreviewImages[id] = [];
              this.listPreviewImages[id].push({
                path: success,
                fingerprint: image.fingerprint
              });
            },
            (error) => {
              if (!this.listPreviewImages[id])
                this.listPreviewImages[id] = [];
              this.listPreviewImages[id].push({
                path: this.pathNoImage,
                fingerprint: image.fingerprint
              });
            }
          )
          .catch(() => {
            if (!this.listPreviewImages[id])
              this.listPreviewImages[id] = [];
            this.listPreviewImages[id].push({
              path: this.pathNoImage,
              fingerprint: image.fingerprint
            });
          });
      });
    }
  }

  private getDataLegalizeTravelExpenses(): void {
    this.spinner.show();
    this.travelExpensesService.byCargoId(this.data.cargo.id).subscribe(
      (expenses: Array<TravelExpense>) => {
        this.travelExpenses = expenses;
        this.initData();
        this.spinner.hide();
      }
    );
  }

  canUploadImages(expense: TravelExpense): boolean {
    return expense.approval !== 'Approved';
  }

}
