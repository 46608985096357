import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren, Inject, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Utils } from 'src/app/core/resources/utils';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cargo-edit-type-vehicle',
  templateUrl: './cargo-edit-type-vehicle.component.html',
  styleUrls: ['./cargo-edit-type-vehicle.component.scss']
})

export class CargoEditTypeVehicleComponent implements OnInit {
  public bodyWorkType: string;
  public vehicleType = new FormGroup({
    bodyWorkType: new FormGroup(
      {
        name: new FormControl('', Validators.required)
      }
    ),
    name: new FormControl('', Validators.required)
  });
  optionsTypeVehicle: OptionsAutocomplete = {
    showAutocomplete: true
  }
  vehicleTypeControl: FormControl = new FormControl('', Validators.required);
  vehicleTypeSub: Subscription;
  validateTypeVehicle: string = '';
  validateBodyworkType: string = '';
  bodyworkTypeControl: FormControl = new FormControl('', Validators.required);
  bodyWorkTypeSub: Subscription;
  optionsBodyworkType: OptionsAutocomplete = {
    title: 'Tipo de carrocería'
  }
  public vehicleSelect = [];
  constructor(
    public dialogRef: MatDialogRef<CargoEditTypeVehicleComponent>,
    public snackBarService: SnackBarService,
    public manualCreationCargoService: ManualCreationCargoService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      vehicleType
    },
    public cdRef: ChangeDetectorRef,
    public utils: Utils
  ) {

  }

  ngOnInit() {
    this.setOptions();
    this.setSubscription();
  }
  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  setOptions() {
    this.optionsBodyworkType['initialVehicleTypeName'] = this.vehicleType && this.vehicleType.get('name') && this.vehicleType.get('name').value ? this.vehicleType.get('name').value : '';
    this.optionsBodyworkType = { ...this.optionsBodyworkType };
  }

  setSubscription() {
    this.vehicleTypeSub = this.vehicleTypeControl.valueChanges
      .subscribe(value => {
        if (value) {
          this.vehicleType.get('name').setValue(value.name);
          this.optionsBodyworkType['initialVehicleTypeId'] = value.id ? value.id : '';
          this.optionsBodyworkType['initialVehicleTypeName'] = null;
          this.optionsBodyworkType['initialValue'] = '';
          this.optionsBodyworkType = { ...this.optionsBodyworkType };
        } else this.vehicleType.get('name').setValue('');
        this.vehicleType.get('bodyWorkType.name').setValue('');
      })

    this.bodyWorkTypeSub = this.bodyworkTypeControl.valueChanges
      .subscribe(value => this.vehicleType.get('bodyWorkType.name').setValue(value && value.name ? value.name : ''));
  }

  public getBodyworkByVehicleType(vehicle) {
    this.spinner.show();
    this.manualCreationCargoService.getBodyworkByVehicleType(vehicle.id).toPromise()
      .then((success) => {
        if (Array.isArray(success)) {
          this.vehicleSelect = success;
        } else {
          this.vehicleSelect = [];
        }
      })
      .catch((error) => {
        console.error(error);
        vehicle.bodyWorkList = [];
      })
      .finally(() => this.spinner.hide());
  }

  public edit() {
    this.validateTypeVehicle = 'touched';
    this.validateBodyworkType = 'touched';
    if (this.vehicleType.get('bodyWorkType.name').valid && this.vehicleType.get('name').valid) {
      let data = {
        vehicleType: {
          name: this.vehicleType.get('name').value,
          bodyWorkType: this.vehicleType.get('bodyWorkType').value.name
        },
        state: true,
      }
      this.dialogRef.close(data);
    } else {
      if (this.utils.errorMessagesCustomized(this.vehicleType.get('name'), 'tipo de vehículo')) return;
      else if (this.utils.errorMessagesCustomized(this.vehicleType.get('bodyWorkType.name'), 'tipo de carrocería')) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    }
  }

  ngOnDestroy() {
    if (this.bodyWorkTypeSub) this.bodyWorkTypeSub.unsubscribe();
    if (this.vehicleTypeSub) this.vehicleTypeSub.unsubscribe();
    this.cdRef.detach();
  }
}
