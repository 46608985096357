<div class="routing__form--content py-4 px-2 px-sm-4" [formGroup]="form">
    <div class="routing__form--header">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            class="cursor-pointer" (click)="goBack()">
            <path
                d="M18.7912 11.005H7.62124L12.5012 6.12496C12.8912 5.73496 12.8912 5.09496 12.5012 4.70496C12.3144 4.51771 12.0608 4.41248 11.7962 4.41248C11.5317 4.41248 11.2781 4.51771 11.0912 4.70496L4.50124 11.295C4.11124 11.685 4.11124 12.315 4.50124 12.705L11.0912 19.295C11.4812 19.685 12.1112 19.685 12.5012 19.295C12.8912 18.905 12.8912 18.275 12.5012 17.885L7.62124 13.005H18.7912C19.3412 13.005 19.7912 12.555 19.7912 12.005C19.7912 11.455 19.3412 11.005 18.7912 11.005Z"
                fill="#495356" />
        </svg>
        <label class="routing__form--label">{{form?.value?.description}}</label>
    </div>

    <div class="routing__form--body">
        <mat-expansion-panel *ngFor="let order of orders?.controls; let i = index;" [expanded]="orderSelected===i"
            [ngClass]="{'selected':orderSelected===i}" (opened)="onOpen(i)" class="order mb-3">

            <mat-expansion-panel-header class="header-nav-parent">
                <mat-panel-title>Orden {{i+1}}</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="pt-3">
                <mat-form-field class="mb-2">
                    <mat-label>Vehículo asignado<span class="asterisk">*</span></mat-label>
                    <mat-select [formControl]="order?.get('vehicle')" (selectionChange)="onChangeVehicle(i, $event)">
                        <mat-option *ngFor="let vehicle of selectableVehicles; let j = index"
                            [value]="vehicle.vehiclePlate" [disabled]="!canAssignVehicle(vehicle, order?.value)"
                            [matTooltip]="!canAssignVehicle(vehicle, order?.value)? 
                            'Este vehículo no puede ser asignado a la orden porque no cuenta con la capacidad requerida' : ''">
                            {{vehicle?.vehiclePlate}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="order?.get('vehicle').errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>

                </mat-form-field>

                <div *ngFor="let address of getOrderAddresses(initialOrders[i]); let i= index;"
                    class="pl-4 address__item">
                    <i class="fas fa-map-marker-alt" style="color: #584796; width: 24px;"></i>
                    <span class="text-break">{{i+1}}. {{address}}</span>
                </div>

            </div>

        </mat-expansion-panel>

    </div>

    <div class="button-container routing__form--footer">
        <button mat-raised-button color="primary" type="button" class="bg-color-primary" (click)="nextStep()">
            Confirmar
        </button>
    </div>
</div>

<!---->