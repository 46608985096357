
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Contact } from 'src/app/core/interfaces/formContact';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Utils } from 'src/app/core/resources/utils';
import { ContactService } from '../contact/contact.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-ethical-line',
  templateUrl: './ethical-line.component.html',
  styleUrls: ['./ethical-line.component.scss']
})
export class EthicalLineComponent implements OnInit {
  formContact: FormGroup;
  formControlIdentify = new FormControl(true);
  siteKey = "6Lfe1tEZAAAAAOi5Gq4C9JkSx5GjpzVtLzQSCn2b";

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private snackBarService: SnackBarService,
    public utils: Utils,
    public matDialog: MatDialog,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formContact = this.formBuilder.group({
      name: ['', this.formControlIdentify.value ? Validators.required : null],
      email: ['', this.formControlIdentify.value ? Validators.required : null],
      phone: ['', this.formControlIdentify.value ? Validators.required : null],
      description: ['', Validators.required],
      recaptcha: [null, Validators.required]
    });
  }

  onChangeIdentify($event) {
    this.formContact.get('name').setValidators($event ? Validators.required : null);
    this.formContact.get('email').setValidators($event ? Validators.required : null);
    this.formContact.get('phone').setValidators($event ? Validators.required : null);
    this.formContact.get('name').setValue('');
    this.formContact.get('email').setValue('');
    this.formContact.get('phone').setValue('');
  }

  onSubmit() {
    if (this.formContact.invalid) {
      if (this.utils.errorMessagesCustomized(this.formContact.get('description'), 'situación')) { }
      else if (this.utils.errorMessagesCustomized(this.formContact.get('email'), 'correo')) { }
      else if (this.utils.errorMessagesCustomized(this.formContact.get('phone'), 'celular')) { }
      else if (this.utils.errorMessagesCustomized(this.formContact.get('name'), 'nombre')) { }
      else if (this.utils.errorMessagesCustomized(this.formContact.get('recaptcha'), 'captcha')) { }
      else this.snackBarService.openSnackBar("Por favor verifique sus datos", undefined, 'alert')
      return;
    }
    let dataContact: Contact = {
      name: this.formContact.controls.name.value,
      email: this.formContact.controls.email.value,
      phone: this.formContact.controls.phone.value,
      description: this.formContact.controls.description.value,
      ethicalLine: true,
    }

    this.contactService.sendDataContact(dataContact).subscribe(
      (success) => {
        this.formContact.reset();
        this.openDialogComponent();
      },
      (error) => {
        this.snackBarService.openSnackBar("No se ha podido enviar la informacion", undefined, 'error')

      }
    )

  }

  openDialogComponent() {
    const config = new MatDialogConfig();
    config.data = {
      handShakeIcon: true,
      title: `Gracias por ayudarnos con el cumplimiento ético`,
      descriptionHTML: '<div class="text-center">Se ha enviado la información correctamente</div>',
      hideBtnConfirm: true,
      hideBtnCancel: true
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = true;
    this.matDialog.open(DialogComponent, config);
  }

}
