import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Utils } from './utils';

export class ReactiveForm {

    form: any;

    constructor(
        public formBuilder: FormBuilder,
        public modelExample: any,
        public enabledFields?: any,
        public requiredFields?: any
    ) {
        this.setForm();
    }

    setForm(value?: any) {
        let data = this.modelExample;
        if (!value) {
            if (Array.isArray(this.modelExample)) {
                if (!Utils.prototype.isDefined(this.form))
                    this.form = new FormArray([]);
            } else {
                if (!Utils.prototype.isDefined(this.form))
                    this.form = new FormGroup({});
            }
        } else {
            data = value;
        }
        this.createFormGroup(data, this.form, value, this.enabledFields, this.requiredFields);
    }

    rebuildForm(model: any) {
        this.form = new FormGroup({});
        this.modelExample = model;
        this.enabledFields = model;
        this.requiredFields = model;
        this.setForm();
    }

    updateForm(data) {
        this.form.patchValue(data);
    }

    createFormGroup(group, form?, value?, disabledGroup?, requiredFields?) {
        let formGroup = new FormGroup({});
        if (form) {
            formGroup = form;
        }
        if (formGroup instanceof FormArray) {
            group.map((obj, i) => {
                const formArray = this.createFormGroup(obj, (formGroup.controls[i] || null), value, disabledGroup, requiredFields);
                formGroup.setControl(
                    i,
                    formArray
                );
            });
        } else {
            for (const i in group) {
                if (Array.isArray(group[i])) {
                    const formData = [];
                    group[i].map((obj, j) => {
                        if (formGroup && formGroup.controls[i] && formGroup.controls[i]['controls'][j]) {
                            formData.push(
                                this.createFormGroup(
                                    obj,
                                    formGroup.controls[i]['controls'][j],
                                    disabledGroup && disabledGroup[i] && disabledGroup[i][j] && Utils.prototype.isDefined(disabledGroup[i][j]) ? disabledGroup[i][j] : {},
                                    requiredFields && requiredFields[i] && requiredFields[i][j] && Utils.prototype.isDefined(requiredFields[i][j]) ? requiredFields[i][j] : {},
                                )
                            );
                        } else {
                            formData.push(
                                this.createFormGroup(
                                    obj,
                                    new FormGroup({}),
                                    disabledGroup && disabledGroup[i] && disabledGroup[i][j] && Utils.prototype.isDefined(disabledGroup[i][j]) ? disabledGroup[i][j] : {},
                                    requiredFields && requiredFields[i] && requiredFields[i][j] && Utils.prototype.isDefined(requiredFields[i][j]) ? requiredFields[i][j] : {},
                                )
                            );
                        }
                    });
                    formGroup.addControl(
                        i,
                        new FormArray(formData)
                    );
                    formGroup.setControl(
                        i,
                        new FormArray(formData)
                    );
                } else if (typeof group[i] === 'object') {
                    const formData = this.createFormGroup(
                        group[i],
                        (formGroup.controls[i] || null),
                        value,
                        disabledGroup && Utils.prototype.isDefined(disabledGroup[i]) ? disabledGroup[i] : {},
                        requiredFields && Utils.prototype.isDefined(requiredFields[i]) ? requiredFields[i] : {},
                    );
                    formGroup.addControl(
                        i,
                        formData
                    );
                } else {
                    this.setControl(
                        formGroup,
                        i,
                        group[i],
                        disabledGroup && Utils.prototype.isDefined(disabledGroup[i]) ? false : true,
                        requiredFields && Utils.prototype.isDefined(requiredFields[i]) ? requiredFields[i] : null,
                    );
                }
            }
            // await this.createFormByData(group, formGroup, value, disabledGroup, requiredFields);
        }

        return formGroup;
    }

    async createFormArray(group, form?, value?, disabledGroup?, requiredFields?): Promise<FormArray> {
        const formArray = new FormArray([]);
        await this.createFormByData(group, formArray, value, disabledGroup, requiredFields);
        return formArray;
    }

    createFormByData(data: object, form: FormGroup | FormArray, value?, disabledGroup?, requiredFields?) {
        for (const i in data) {
            if (Array.isArray(data[i])) {
                const formData = [];
                data[i].map((obj, j) => {
                    if (form && form.controls[i] && form.controls[i]['controls'][j]) {
                        formData.push(
                            this.createFormGroup(
                                obj,
                                form.controls[i]['controls'][j],
                                disabledGroup && disabledGroup[i] && disabledGroup[i][j] && Utils.prototype.isDefined(disabledGroup[i][j]) ? disabledGroup[i][j] : {},
                                requiredFields && requiredFields[i] && requiredFields[i][j] && Utils.prototype.isDefined(requiredFields[i][j]) ? requiredFields[i][j] : {},
                            )
                        );
                    } else {
                        formData.push(
                            this.createFormGroup(
                                obj,
                                new FormGroup({}),
                                disabledGroup && disabledGroup[i] && disabledGroup[i][j] && Utils.prototype.isDefined(disabledGroup[i][j]) ? disabledGroup[i][j] : {},
                                requiredFields && requiredFields[i] && requiredFields[i][j] && Utils.prototype.isDefined(requiredFields[i][j]) ? requiredFields[i][j] : {},
                            )
                        );
                    }
                });
                (form as FormGroup).addControl(
                    i,
                    new FormArray(formData)
                );
                (form as FormGroup).setControl(
                    i,
                    new FormArray(formData)
                );
            } else if (typeof data[i] === 'object') {
                const formData = this.createFormGroup(
                    data[i],
                    (form.controls[i] || null),
                    value,
                    disabledGroup && Utils.prototype.isDefined(disabledGroup[i]) ? disabledGroup[i] : {},
                    requiredFields && Utils.prototype.isDefined(requiredFields[i]) ? requiredFields[i] : {},
                );
                (form as FormGroup).addControl(
                    i,
                    formData
                );
            } else {
                this.setControl(
                    form,
                    i,
                    data[i],
                    disabledGroup && Utils.prototype.isDefined(disabledGroup[i]) ? false : true,
                    requiredFields && Utils.prototype.isDefined(requiredFields[i]) ? requiredFields[i] : null,
                );
            }
        }
    }

    setControl(form, key, data, disabled, requiredFields) {
        const required = !_.isNil(requiredFields) ? Validators.required : null;
        if (!form[key] || !form.get(key)) {
            form.addControl(
                key,
                new FormControl({
                    value: data,
                    disabled: false
                }, required)
            );
            form.setControl(
                key,
                new FormControl({
                    value: data,
                    disabled: false
                }, required)
            );
        } else {
            if (form.get(key)) {
                form.get(key).setValue(form.get(key).value);
            } else if (form[key]) {
                form[key].setValue(
                    form[key].value
                );
            }
        }
    }

    disabledFieldsForm(model: any, form) {
        try {
            for (const i in model) {
                if (Array.isArray(model[i])) {
                    model[i].map((obj, j) => {
                        this.disabledFieldsForm(
                            obj, form.get(i).at(j)
                        );
                    });
                } else if (typeof model[i] === 'object') {
                    this.disabledFieldsForm(
                        model[i], form.get(i)
                    );
                } else {
                    form.get(i).disable();
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    enabledFieldsForm(model: any, form) {
        for (const i in model) {
            if (Array.isArray(model[i])) {
                model[i].map((obj, j) => {
                    this.disabledFieldsForm(
                        obj, form.get(i).at(j)
                    );
                });
            } else if (typeof model[i] === 'object') {
                this.disabledFieldsForm(
                    model[i], form.get(i)
                );
            } else {
                form.get(i).enable();
            }
        }
    }

    setValidatorsForm(model: any, form) {
        for (const i in form.value) {
            if (Array.isArray(form.value[i])) {
                form.value[i].map((obj, j) => {
                    this.setValidatorsForm(
                        obj, form.get(i).at(j)
                    );
                });
            } else if (typeof form.value[i] === 'object') {
                if (model[i]) {
                    this.setValidatorsForm(
                        model[i], form.get(i)
                    );
                }
            } else {
                form.get(i).setValidators(model[i]);
            }
        }
    }

    pushGroup(group: any, form: FormArray): void {
        const formGroup: FormGroup = this.createFormGroup(group);
        form.push(formGroup);
    }

    removeAt(index: number, form: FormArray): void {
        form.removeAt(index);
    }
}
