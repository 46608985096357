<form (submit)="onSubmit()" [formGroup]="form" class="select__guide--state">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="row pt-3">
    <!-- State -->
    <div class="col-md-6">
      <mat-form-field appearance="standard">
        <mat-label>Estado<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="state">
          <mat-option value="{{state.id}}" *ngFor="let state of listGuideStates">
            {{state.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('state').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- Date -->
    <div class="col-md-3">
      <mat-form-field appearance="standard" class="container-input-date-picker cursor-pointer">
        <mat-label>Fecha<span class="asterisk">*</span></mat-label>
        <input readonly class="cursor-pointer" type="text" id="setStateGuidesFormDate" matInput
          [matDatepicker]="datePicker" (click)="datePicker.open()" formControlName="date"
          (dateChange)="onChangeDatePicker($event)">
        <mat-error *ngIf="form.get('date').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <!-- Time-->
    <div class="col-md-3">
      <mat-form-field appearance="standard" class="container-input-date-picker">
        <mat-label>Hora<span class="asterisk">*</span></mat-label>
        <input type="time" #timePicker id="setStateGuidesFormTime" matInput formControlName="time"
          (change)="onChangeTime()" (click)="onChangeTime()">
        <mat-error *ngIf="form.get('time').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <span class="separator-horizontal"></span>
  <mat-card-actions class="text-center">
    <button mat-raised-button color="primary" type="submit" class="button-width-auto">
      Confirmar
    </button>
  </mat-card-actions>
</form>