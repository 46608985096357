<div class="position-relative">
    <i *ngIf="isDialog" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
        (click)="dialogRef.close()"></i>
    <mat-form-field appearance="standard" [ngClass]="{'input__document__user': isDialog}">
        <mat-label>{{title}}<span class="asterisk" *ngIf="utils.isRequiredField(inputFormControl)">*</span></mat-label>
        <ng-container *ngIf="isDialog">
            <span class="spinner-input" *ngIf="loading">
                <i class="fas fa-circle-notch"></i>
            </span>
            <input matInput appLicensePlate [matAutocomplete]="autoTrailers" [formControl]="inputFormControl">
            <mat-autocomplete #autoTrailers="matAutocomplete" [displayWith]="displayTrailer"
                (optionSelected)="onSelectTrailer($event)">
                <mat-option *ngFor="let trailer of listTrailers | async" [value]="trailer">
                    <span>{{trailer.id}}</span>
                </mat-option>
                <mat-option [value]="null" *ngIf="loading === false">
                    <span class="font-italic">Crear Trailer</span>
                </mat-option>
            </mat-autocomplete>
        </ng-container>
        <mat-error *ngIf="inputFormControl.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
    </mat-form-field>
    <div class="button-container pb-3" *ngIf="isDialog">
        <button mat-raised-button color="primary" type="button" class="button-width-auto bg-color-primary"
            (click)="confirmSelectedTrailer()">
            Confirmar
        </button>
    </div>
</div>