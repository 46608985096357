export class Patterns {
  public CELLPHONE = /^([57]{2})?([3]{1}[0-9]{9})$/;
  public CELLPHONE_WITH_SPACES = /^(\s*[57]{2}\s*)?(\s*[3]{1}\s*[0-9]{9}\s*)$/;
  public CELLPHONEANDLANDLINE = /^(((([57]{2})?[3][0-9]{9})|(([60]{2})?([0-9]{1})?[0-9]{7})))$/
  public PHONE = /^([57]{2})?([3]{1}[0-9]{9})?([0-9]{5,})$/;
  public LOCALPHONE = /^[1-9]\d{6}$/;
  public NIT = /(^[0-9]+-{1}[0-9]{1})$/;
  public ONLY_NUMBERS = /^[0-9]+$/;
  public ONLY_NUMBERS_WITH_SPACES = /^[0-9\s]+$/;
  public ONLY_WORDS = /^[a-zA-ZÀ-ú ]+$/;
  public WORDS_POINTS = /^[a-zA-ZÀ-ú. ]+$/;
  public DATE_YYYY_MM_DD = /^([0-9]{4})(-){1}([0-9]{2})(-){1}([0-9]{2}){1}$/;
  public ALL_EXPRESSIONS = /^(?!\s*$).+/;
  public ALPHANUMERIC = /^[a-zA-ZÀ-ú0-9 ]+$/;
  public EMAIL = /^[a-zA-Z0-9]([-+\.]?[\w]+)*[-+\.]?[a-zA-Z0-9]@([a-zA-Z0-9][\w-]*\.)+[a-zA-Z0-9][\w-]{1,}$/gim;
  public MIN_TWO_WORDS = /(\b\S+.*?){2,}/;
  public BILL_FORMAT = /^[a-zA-Z0-9]{1,}-\d{1,}$/;
  public COORDINATES_FORMAT = /^(-?[0-8]?[0-9]|-?90)\.\d+\s*,\s*(-?[0-8]?[0-9]|-?90)\.\d+$/
  public LAT_COORDINATE_FORMAT = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
  public LNG_COORDINATE_FORMAT = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
  public CONTAINER_FORMAT = /^[a-zA-Z]{0,4}$/;
  public CONTAINER_FORMAT_FULL = /^[a-zA-Z]{4}[0-9]{7}$/;
  public CORPORATIVE_EMAIL_TECLOGI = /@teclogi.com$/;
  public PASSWORD_REGISTER_FORMAT = '^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€^*+=:_./;-?`~|&]+$';
  public URL_FORMAT = /^(https?|ftp):\/\/(-\.)?([^\s/?\.#-]+\.?)+([^\s]*)$/i;
  public TRAILER_PLATES = {
    CO: /([rsRS]{1}[0-9]{5})?/,
    VE: /([a-zA-ZÀ-ú]{1}[0-9]{2}[a-zA-ZÀ-ú]{2}[0-9]{1}[a-zA-ZÀ-ú]{1})?/,
    EC: /([a-zA-ZÀ-ú]{3}[0-9]{4})?([a-zA-ZÀ-ú]{2}[0-9]{4})?/,
    PE: /([a-zA-ZÀ-ú]{3}[0-9]{3})?/,
  }
  public NOT_EMPTY = /^(\s+\S+\s*)*(?!\s).*$/;
  public LICENSE_PLATES = {
    CO: /([0-9]{3}[a-zA-ZÀ-ú]{3})?([a-zA-ZÀ-ú]{3}[0-9]{3})?([a-zA-ZÀ-ú]{3}[0-9]{2}[a-zA-ZÀ-ú]{1})?([a-zA-ZÀ-ú]{3}[0-9]{2})?/,
    VE: /([a-zA-ZÀ-ú]{2}[0-9]{3}[a-zA-ZÀ-ú]{2})?([a-zA-ZÀ-ú]{1}[0-9]{2}[a-zA-ZÀ-ú]{2}[0-9]{1}[a-zA-ZÀ-ú]{1})?/,
    EC: /([a-zA-ZÀ-ú]{3}[0-9]{4})?([a-zA-ZÀ-ú]{2}[0-9]{4})?/,
    PE: /([a-zA-ZÀ-ú]{2}[0-9]{3})?([a-zA-ZÀ-ú]{3}[0-9]{3})?([a-zA-ZÀ-ú]{1}[0-9]{5})?([a-zA-ZÀ-ú]{1}[0-9]{1}[a-zA-ZÀ-ú]{1}[0-9]{3})?/,
  };
  public FILE_FORMAT_FULL = /^[a-zA-Z]+_[a-zA-Z]+_\d{13}\.[a-zA-Z]+$/;
  public FILE_FORMAT = /^[a-zA-Z]+_\d{13}\.[a-zA-Z]+$/;

  public GET_REGEX(type: 'TRAILER_PLATES' | 'LICENSE_PLATES', countries?: string[]): RegExp {
    let sources;
    if (countries && countries.length) {
      countries.forEach(country => {
        if (this[type][country]) sources ? sources += this[type][country].source : sources = this[type][country].source;
      })
      if (sources) return new RegExp(`^${sources}$`);
    }
    Object.values(this[type]).forEach(value => sources ? sources += value.source : sources = value.source);
    return new RegExp(`^${sources}$`);
  }

}
