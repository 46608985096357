import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Patterns } from 'src/app/core/resources/patterns';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./../account.component.scss', './forgotPassword.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AuthService]
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  forgotPasswordSteps = {
    stepActive: 1
  };
  message: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _authService: AuthService,
    public utils: Utils,
    public snackbarService: SnackBarService,
    private patterns: Patterns
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      userCorporateMail: ['', [Validators.required, Validators.pattern(this.patterns.EMAIL.source)]]
    });
  }

  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.forgotPasswordForm.get('userCorporateMail'), 'correo')) return;
      else return;
    } else if (this.forgotPasswordForm.value.userCorporateMail.includes('@teclogi.com') ||
      this.forgotPasswordForm.value.userCorporateMail.includes('@innovbo.com')) {
      this.snackbarService.openSnackBar('Los usuarios de Teclogi no requieren contraseña para iniciar sesión', undefined, 'alert');
      return;
    }
    this._authService.resetPassword(this.forgotPasswordForm.value.userCorporateMail)
      .then(() => {
        this.forgotPasswordSteps['stepActive'] = 2;
        this.snackbarService.openSnackBar("Se ha enviado el correo satisfactoriamente");
      })
      .catch((error) => {
        let errorMessage = error.code && error.code === "auth/user-not-found" ? "No existe un usuario registrado con este correo" : "Ocurrió un problema con la solicitud";
        //Este mensaje de error se puede ampliar con los códigos disponibles en la doc de Firebase haciendo un switch por cada código
        this.snackbarService.openSnackBar(errorMessage, undefined, 'error');
      });

  }

}
