import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/core/resources/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModulesService } from '../modules.service';
import { ModuleRole } from 'src/app/core/interfaces/moduleRole';
import { Utils } from 'src/app/core/resources/utils';
import { Companies } from 'src/app/core/resources/companies';
import { AuthService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss']
})
export class ModuleListComponent implements OnInit {

  listModules: ModuleRole[];
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    public global: Global,
    private modulesService: ModulesService,
    public utils: Utils,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.getModules();
  }

  goToCreate() {
    this.router.navigate(['administration/module/create'])
  }

  getModules() {
    this.spinner.show();
    this.modulesService.getAllModules().toPromise()
      .then((success: ModuleRole[]) => {
        this.listModules = success;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  canEditModule(module: string): boolean {
    return Companies.modulesAccess(module, sessionStorage.getItem('_companyId'));
  }

  canEditPermission(permission: string): boolean {
    return Companies.permissionAccess(permission, this.authService.getUserSession().clientCompanyId, sessionStorage.getItem('_companyId'));
  }
}
