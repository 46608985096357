import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-summary-balance',
  templateUrl: './summary-balance.component.html',
  styleUrls: ['./summary-balance.component.scss']
})
export class SummaryBalanceComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<SummaryBalanceComponent>,
  ) { }

  ngOnInit() { }

}
