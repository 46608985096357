import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { environment } from './../../../environments/environment';
import { User } from 'src/app/core/interfaces/user';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { BasicCompany } from 'src/app/core/interfaces/basicCompany';
import { Companies } from 'src/app/core/resources/companies';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private _angularFireDatabase: AngularFireDatabase,
    public utils: Utils,
    private global: Global
  ) { }

  public validateEntity(type: number, value: string) {
    let url = "";

    if (type === 1) {
      url = this._endpointResources.urlDetailUserByDocument + value;
    } else {
      url = this._endpointResources.urlDetailCompany + value;
    }

    return this._http.get(
      environment.urlServerTeclogi + url
    );
  }

  public createUser(dataUser: User) {
    let dataUserCopy = this.utils.copyElementJSON(dataUser);
    delete dataUserCopy.password;
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlCreateUserClient,
      dataUserCopy
    );
  }

  public createCompany(value: Object) {
    // validate
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlCreateCompany,
      value
    );
  }

  getFileUploads(basePath): AngularFireList<any> {
    return this._angularFireDatabase.list(basePath, ref =>
      ref.limitToLast(7));
  }

  listRegisterCompanies(): BasicCompany[] {
    return Companies.companies;
  }

}
