import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FileService } from './file.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PathsStorages } from 'src/app/core/resources/paths-storages';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Utils } from 'src/app/core/resources/utils';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/services/authentication.service';
import { FileNamePipe } from 'src/app/core/pipe/fileName.pipe';
import { Patterns } from 'src/app/core/resources/patterns';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
declare var google: any;

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  providers: [FileService, FileNamePipe]
})
export class FileComponent implements OnInit {

  @Input() inputFormControl: FormControl;
  @Input() id: string;
  @Input() options: {
    title,
    path,
    type,
    storage,
    pattern,
    required,
    format,
    formatStrict: Array<string>,
    storageName,
    firebasePath,
    docUrl,
    readOnly,
    hideAlert
  };
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @ViewChild('input', { static: true }) input: ElementRef;
  file: FileStorage = {
    storageData: {},
    fileData: {}
  };
  visualFileName: FormControl = new FormControl();
  docUrl: string = '';
  constructor(
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private pathsStorages: PathsStorages,
    private domSanitizer: DomSanitizer,
    public utils: Utils,
    private angularFireStorage: AngularFireStorage,
    private service: FileService,
    public fileNamePipe: FileNamePipe,
    public patterns: Patterns
  ) {

  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes && changes.options && changes.options.currentValue) {
        if (changes.options.currentValue.docUrl)
          this.docUrl = changes.options.currentValue.docUrl;
        if (changes.options.currentValue.initialFile &&
          changes.options.currentValue.initialFile.name &&
          changes.options.currentValue.initialFile.size) {
          this.file.fileData = {
            file: changes.options.currentValue.initialFile,
            name: changes.options.currentValue.initialFile.name,
            uploaded: true,
            size: changes.options.currentValue.initialFile.size,
            url: null
          };
        }
      }
      if (changes.inputFormControl && changes.inputFormControl.currentValue && changes.inputFormControl.currentValue.value && this.options && this.options.storage) {
        try {
          if (this.options && this.options.firebasePath) {
            const url = decodeURIComponent(this.options.firebasePath);
            const match = url.match(/\/([^\/]+)\?alt=media/);
            const pathFromUrl = url.match(/\/o\/([^?]+)/);
            const fileNameDecode = match ? match[1] : null;
            this.getURLDocument(pathFromUrl[1]);
            this.showFileName(fileNameDecode);
          }
          else {
            const nameSplit = this.inputFormControl.value.split('/');
            this.file.fileData.name = nameSplit.length > 0 ? nameSplit[nameSplit.length - 1] : nameSplit[0];
            this.getURLDocument(`${this.options.path}/${this.file.fileData.name}`);
            this.showFileName(this.file.fileData.name);
          }
        } catch (e) {
          this.file.fileData.name = this.inputFormControl.value;
          this.visualFileName.setValue(this.file.fileData.name);
        }
      }
    }
  }
  showFileName(file: string) {
    if (this.patterns.FILE_FORMAT_FULL.test(file) || this.patterns.FILE_FORMAT.test(file)) this.visualFileName.setValue(this.userViewFileName(file));
    else this.visualFileName.setValue(file);
  }
  deleteFile() {
    if (this.options && this.options.storage) {
      if (this.file.fileData.url) {
        this.angularFireStorage.storage.refFromURL(this.file.fileData.url).delete().then(
          (data) => {

          },
          (error) => {

          },
        );
      } else {
        this.clearFileData();
      }
    } else {
      this.clearFileData();
    }
  }

  clearFileData() {
    this.input.nativeElement.value = null;
    this.file.storageData.storageRef = null;
    this.file.storageData.uploadTask = null;
    this.file.storageData.uploadProgress = null;
    this.file.fileData = {
      file: null,
      name: '',
      uploaded: false,
      size: null,
      url: null
    };
    this.inputFormControlValue = null;
    this.emitToParentData = null;
    this.visualFileName.setValue(null);
  }

  handleFileInput($event: Event & { target: { files: FileList } }) {
    if ($event.target['files'].length) {
      const file: File = $event.target['files'][0];
      if (this.options && this.options.formatStrict && this.options.formatStrict.length) {
        const extension = file.name.split('.').reverse()[0];
        if (!this.options.formatStrict.includes(extension.toLocaleLowerCase()) || !file.type) {
          this.snackBarService.openSnackBar(`El archivo no es compatible con formatos ${this.options.formatStrict.join(', ')}`, 'x', 'error');
          this.input.nativeElement.value = null;
          return;
        }
      }
      const splittedNameFile = file['name'].split('.');
      const formatFile = splittedNameFile[splittedNameFile.length - 1];
      const originalSplitFileName = file['name'].replace(/\s+/g, ' ').trim();
      const dateInMs = new Date().getTime();
      this.file.fileData.name = this.options && this.options.storageName ? `${this.options.storageName}_${dateInMs}.${formatFile}` : originalSplitFileName
      const modifiedFile = new File([file], this.file.fileData.name, {
        type: file.type
      });
      this.file.fileData.file = modifiedFile;
      this.visualFileName.setValue(this.userViewFileName(this.file.fileData.name));
      this.file.fileData.uploaded = true;
      this.file.fileData.size = this.utils.bytesToSize(this.file.fileData.file.size);
      this.inputFormControlValue = this.file;
      this.emitToParentData = this.file.fileData.file;
      this.input.nativeElement.value = null;
    }
  }

  loadFileToStorage() {
    if (this.options && this.options.path && this.file.fileData.name && this.file.fileData.file) {
      this.spinner.show();
      this.service.loadFileToStorage(this.options.path, this.file.fileData.name, this.file.fileData.file)
        .then(() => {
          this.getURLDocument(`${this.options.path}/${this.file.fileData.name}`);
        })
        .catch()
        .finally(() => {
          this.spinner.hide();
        });
    }
  }

  getLoadFileStorage(): { path, file: FileStorage, id } {
    let data = {
      path: null, file: null, id: null
    };
    if (this.options && this.options.path && this.file.fileData.name && this.file.fileData.file) {
      data = {
        path: this.options.path,
        file: this.file,
        id: this.id
      };
    }
    return data;
  }


  getURLDocument(path: string) {
    const storage = AuthService.fStorage;
    const pathReference = storage.ref(path);
    this.spinner.show();
    pathReference.getDownloadURL().then(
      (url) => {
        if (url) this.docUrl = url;
        else this.docUrl = null;
        this.spinner.hide();
      },
      (error) => {
        if (!this.options.hideAlert) this.snackBarService.openSnackBar(FormMessages.ERROR_LOAD_RESOURCE, undefined, 'error');
        this.spinner.hide();
      }
    );
  }


  set inputFormControlValue(file: FileStorage) {
    if (this.inputFormControl) {
      if (this.options && this.options.storage) {
        this.inputFormControl.setValue(`${this.options.path}/${file.fileData.name}`);
      } else {
        this.inputFormControl.setValue(file);
      }
    }
  }

  set emitToParentData(data: any) {
    if (this.emitToParent) {
      this.emitToParent.emit(data);
    }
  }

  openWindowFile() {
    if (this.input)
      this.input.nativeElement.click();
  }

  userViewFileName(fileName: string) {
    return this.fileNamePipe.transform(fileName);
  }



}
