<div class="filters-container row mt-3 mb-2 pl-lg-2 pr-lg-3">
  <mat-form-field appearance="outline" class="col-12 col-lg-3 pr-lg-0">
    <mat-label>Código de estado</mat-label>
    <mat-select [formControl]="statusCode">
      <mat-option *ngFor="let statusCode of statusCodeList.statusCodes" [value]="statusCode">
        {{statusCode}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field appearance="outline" class="col-12 col-lg-3 pr-lg-0">
    <mat-label>Fecha de búsqueda</mat-label>
    <input [max]="maxDate" type="text" matInput [matDatepicker]="datePicker" [formControl]="date"
      (click)="datePicker.open()" readonly class="cursor-pointer">
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-12 col-lg-3">
    <mat-label>N° de documento</mat-label>
    <input matInput onlyNumbersInt placeholder="Por ej. 10203040" [formControl]="userDocument">
  </mat-form-field>
  <div class="search col-12 col-lg-3 button-container">
    <button mat-raised-button class="primary-secondary" color="primary" title="Eliminar Filtros"
      (click)="cleanFilter()">
      <i class="far fa-trash-alt"></i>
    </button>
    <button mat-raised-button class="bg-color-primary" color="primary" title="Aplicar Filtros" (click)="applyFilter()">
      <i class="fas fa-search"></i>
    </button>
  </div>
</div>
