import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { FreightListService } from './cargo-list-turn.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CargoTurnFormComponent } from '../cargo-turn-form/cargo-turn-form.component';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { FreightListService as ListServiceCargo } from '../cargo-list/cargo-list.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { MatDrawer } from '@angular/material';
import { MatSidenav } from '@angular/material/sidenav';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { BodyWorkType } from 'src/app/core/interfaces/bodyWorkType';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Permission } from 'src/app/core/resources/permission';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-cargo-list-turn',
  templateUrl: './cargo-list-turn.component.html',
  styleUrls: ['./cargo-list-turn.component.scss'],
  providers: [
    DatePipe
  ],
})

export class CargoListTurnComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawer!: MatDrawer;
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  public filters: any;
  typeDate: string = '';
  filteredCargosTurns = {};
  cargos: any;
  showFiller = false;
  filterParams: FormGroup = this.fb.group({
    turnState: new FormControl(),
    vehicleType: new FormControl(),
    bodyWorkType: new FormControl({ value: '', disabled: true }),
    loadCapacityWeight: new FormControl(),
    loadCapacityVolume: new FormControl(),
    driverName: new FormControl(),
    cellphone: new FormControl()
  })
  filterParamsSub: Subscription;
  state = [
    { value: 'Available', viewValue: 'Disponible' },
    { value: 'Assigned', viewValue: 'Asignado' },
  ];
  vehicles: VehicleCargo[] = [];
  bodyWorkList: BodyWorkType[];
  cleanFilters: boolean;
  applyFilters: boolean;
  turns: any = [];
  filledInputs = {
    data: 0,
    display: false
  };
  permission = Permission;
  constructor(
    public service: FreightListService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    public matDialog: MatDialog,
    private snackBarService: SnackBarService,
    private datePipe: DatePipe,
    public listCargoService: ListServiceCargo,
    public manualCreationCargoService: ManualCreationCargoService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getVehicleType();
    this.service.resetPaginationList();
    this.initialFilterParams();
    this.getScheduledCargos();
    this.filtersActive();
  }

  public onFilter(event) {
    if (event === 'reset' || event === '') {
      this.resetPagging();
      this.initialFilterParams();
    } else {
      this.filters = event;
      this.resetPagging();
      this.initialFilterParams(this.filters);
    }

  }

  public refreshList($event?) {
    if ($event) {
      this.filters = $event;
    }
    this.resetPagging();
    this.initialFilterParams(this.filters);
  }

  public resetPagging() {
    this.service.paginationList.setPageKey(1);
    this.service.paginationList.setList([]);
    this.service.paginationList.setEnablePagingScroll(true);
  }

  public getListTurn(event?) {
    this.spinner.show();
    this.service.getListTurn(event).subscribe(
      (success: any[]) => {
        if (!this.utils.isDefined(success) || success.length < 1) {
          this.service.paginationList.setEnablePagingScroll(false);
        } else {
          this.turns = success;
          if (this.service.paginationList.getList().length) {
            this.service.paginationList.setList(
              this.service.paginationList.getList().concat(this.turns));
          } else {
            this.service.paginationList.setList(this.turns);
          }
          if (success.length < this.service.paginationList.getPageSize()) {
            this.service.paginationList.setEnablePagingScroll(false);
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.service.paginationList.setList([]);
        this.spinner.hide();
      }
    );
  }

  public onScrollDown() {
    if (this.service.paginationList.getEnablePagingScroll()) {
      this.service.paginationList.setPageKey(
        this.service.paginationList.getPageKey() + 1
      );
      this.initialFilterParams(this.filters);
    }
  }

  public Create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      type: 'create'
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const modalDialog = this.matDialog.open(CargoTurnFormComponent, dialogConfig);
    modalDialog.afterClosed().subscribe(result => {
      if (result && result.state && result.type === 'create') {
        this.spinner.show();
        this.service.postEnturnamiento(result.value).subscribe(
          (success) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar('Enturnamiento creado correctamente');
            this.initialFilterParams(this.filters);
          },
          (error) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar('Ocurrió un error al crear el enturnamiento', undefined, 'error');
          }
        );
      }
    });

  }

  initialFilterParams(filters?: string) {
    this.service.resetPaginationList();
    let filterData = '';
    if (!this.typeDate || this.typeDate === '') {
      const currentDateFormatted = moment().subtract(2, 'days').format('YYYY-MM-DD ZZ');
      filterData += '&date=' + currentDateFormatted;
    }
    if (!filters || (filters && !filters.includes('&state='))) {
      filterData += '&state=Available';
    }
    if (filters) {
      filterData += filters;
    }
    this.getListTurn(filterData);
  }

  datesEmitted($event) {
    if ($event && $event.type) {
      this.typeDate = $event.type
    } else if ($event && $event.value) {
      this.typeDate = '';
    }
  }

  getScheduledCargos() {
    this.listCargoService.getListCargo(
      '&state=Created'
    ).subscribe(data => {
      if (data !== null) {
        this.cargos = data;
        this.sortTurnCargo();
      }
    })

  }

  sortTurnCargo() {
    this.filteredCargosTurns = {};
    this.cargos.forEach(cargo => {
      if (this.filteredCargosTurns[cargo.cargoFeature.uploadDownload.origin.municipalityCode]) {
        if (this.filteredCargosTurns[cargo.cargoFeature.uploadDownload.origin.municipalityCode][cargo.cargoFeature.vehicleType.name]) {
          this.filteredCargosTurns[cargo.cargoFeature.uploadDownload.origin.municipalityCode][cargo.cargoFeature.vehicleType.name].push(cargo)
        } else {
          this.filteredCargosTurns[cargo.cargoFeature.uploadDownload.origin.municipalityCode][cargo.cargoFeature.vehicleType.name] = [];
          this.filteredCargosTurns[cargo.cargoFeature.uploadDownload.origin.municipalityCode][cargo.cargoFeature.vehicleType.name].push(cargo);
        }

      } else {
        this.filteredCargosTurns[cargo.cargoFeature.uploadDownload.origin.municipalityCode] = [];
        this.filteredCargosTurns[cargo.cargoFeature.uploadDownload.origin.municipalityCode][cargo.cargoFeature.vehicleType.name] = [];
        this.filteredCargosTurns[cargo.cargoFeature.uploadDownload.origin.municipalityCode][cargo.cargoFeature.vehicleType.name].push(cargo);
      }
    })
  }

  getFilteredCargo(turnId: string, origin: string, date: string, vehicleType?: string) {
    let matches = [];
    let dateTurnFormatted = moment(date, 'YYYY-MM-DD ZZ').utc().format('YYYY-MM-DD ZZ');
    if (this.filteredCargosTurns && this.filteredCargosTurns[origin] && this.filteredCargosTurns[origin][vehicleType]) matches = this.filteredCargosTurns[origin][vehicleType].filter(cargo => this.validDates(dateTurnFormatted, cargo.dateLoad));
    return { turnId: turnId, matches: matches };
  }

  validDates(turnDate, originDateLoad) {
    switch (moment(originDateLoad, 'YYYY-MM-DD ZZ').utc().format('YYYY-MM-DD ZZ')) {
      case moment(turnDate, 'YYYY-MM-DD ZZ').utc().format('YYYY-MM-DD ZZ'):
      case moment(turnDate, 'YYYY-MM-DD ZZ').add(1, 'days').utc().format('YYYY-MM-DD ZZ'):
      case moment(turnDate, 'YYYY-MM-DD ZZ').add(2, 'days').utc().format('YYYY-MM-DD ZZ'):
        return true;
      default:
        return false;
    }
  }

  showMoreFilters($event) {
    if ($event) {
      this.sidenav.toggle();
    }
  }

  getVehicleType() {
    this.spinner.show();
    this.manualCreationCargoService.getVehicleType().subscribe(
      (data: Catalog) => {
        this.spinner.hide();
        if (data.catalog && data.catalog.length > 0) {
          this.vehicles = (data.catalog as VehicleCargo[]).filter((item: VehicleCargo) => {
            if (item.id !== '24' && item.id !== '41') {
              item.selected = false;
              return item;
            }
          });
        }
        else {
          const modelListVehicles = JSON.stringify(this.manualCreationCargoService.listVehicles);
          const modelListVehiclesObj = JSON.parse(modelListVehicles);
          this.vehicles = modelListVehiclesObj.map((item) => {
            if (item.id !== '24' && item.id !== '41') {
              item.selected = false;
              return item;
            }
          });
        }
      })
  }

  getBodyworkByVehicleType(vehicle: VehicleCargo) {
    this.spinner.show();
    this.manualCreationCargoService.getBodyworkByVehicleType(vehicle.id).toPromise()
      .then((success) => {
        if (Array.isArray(success)) {
          this.bodyWorkList = success;
          this.filterParams.get('bodyWorkType').enable();
        } else {
          this.bodyWorkList = [];
          this.filterParams.get('bodyWorkType').disable();
        }
      })
      .catch((error) => {
        console.error(error);
        this.bodyWorkList = [];
        this.filterParams.get('bodyWorkType').disable();
      })
      .finally(() => this.spinner.hide());
  }

  onVehicleTypeSelected(vehicleType) {
    this.getBodyworkByVehicleType(vehicleType.value);
  }

  applyFilter() {
    if (this.filterParams.get('loadCapacityVolume') && this.filterParams.get('loadCapacityVolume').value) this.filterParams.get('loadCapacityVolume').setValue(Number(this.filterParams.get('loadCapacityVolume').value));
    if (this.filterParams.get('loadCapacityWeight') && this.filterParams.get('loadCapacityWeight').value) this.filterParams.get('loadCapacityWeight').setValue(Number(this.filterParams.get('loadCapacityWeight').value));
    this.sidenav.toggle();
    this.filledInputs.display = true;
    this.applyFilters = !this.applyFilters;
  }

  cleanFilter(type?) {
    this.filterParams.get('bodyWorkType').setValue(null);
    this.filterParams.get('cellphone').setValue(null);
    this.filterParams.get('loadCapacityVolume').setValue(null);
    this.filterParams.get('loadCapacityWeight').setValue(null);
    this.filterParams.get('turnState').setValue(null);
    this.filterParams.get('driverName').setValue(null);
    this.filterParams.get('vehicleType').setValue(null);
    this.filterParams.get('bodyWorkType').disable();
    this.filterParams.get('loadCapacityVolume').enable();
    this.filterParams.get('loadCapacityWeight').enable();
    this.filledInputs.display = false;
    if (!type) {
      this.cleanFilters = !this.cleanFilters;
    }

  }

  refreshListTurn(event) {
    if (event) {
      this.initialFilterParams(this.filters);
    }
  }

  controlChanges(type: string) {
    if (type === 'volume') {
      if (this.filterParams.get('loadCapacityVolume').value) this.filterParams.get('loadCapacityWeight').disable();
      else this.filterParams.get('loadCapacityWeight').enable();
    } else {
      if (this.filterParams.get('loadCapacityWeight').value) this.filterParams.get('loadCapacityVolume').disable();
      else this.filterParams.get('loadCapacityVolume').enable();
    }
  }

  clearFilters($event: boolean) {
    if ($event) {
      this.cleanFilter('event')
    }
  }

  filtersActive() {
    this.filterParamsSub = this.filterParams.valueChanges.subscribe(() => {
      this.filledInputs.data = Object.values(this.filterParams.controls).filter(
        control => control.value !== '' && control.value !== null
      ).length;
    });
  }

  ngOnDestroy() {
    if (this.filterParamsSub) this.filterParamsSub.unsubscribe();
  }
}
