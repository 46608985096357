import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { Catalog } from '../interfaces/catalog';
import { CatalogItem } from '../interfaces/catalogItem';
import { City } from '../interfaces/city';
import { Endpoints } from '../resources/endpoints';
import { Global } from '../resources/global';
import { Utils } from '../resources/utils';
import { environment } from './../../../environments/environment'
import { BasicCity } from '../interfaces/basicCity';

@Injectable({ providedIn: 'root' })
export class CatalogService {

  constructor(
    private http: HttpClient,
    private endpoints: Endpoints
  ) { }

  public getCatalog(typeCatalog: string) {
    return this.http.get<Catalog>(
      environment.urlServerTeclogi + this.endpoints.urlCatalog + '/' + typeCatalog
    );
  }

  public getBasicCityByName(name: string, pageKey: number, pageSize: number) {

    return this.http.get<BasicCity[]>(
      // tslint:disable-next-line: max-line-length
      environment.urlServerTeclogi + this.endpoints.urlCatalog + '/cities/basic?name=' + name + '&pageKey=' + pageKey + '&pageSize=' + pageSize
    );
  }

  public getBasicCityByMunicipalityCode(municipalityCode: string, pageKey: number, pageSize: number) {
    return this.http.get<BasicCity[]>(
      // tslint:disable-next-line: max-line-length
      environment.urlServerTeclogi + this.endpoints.urlCatalog + '/cities/basic?id=' + municipalityCode + '&pageKey=' + pageKey + '&pageSize=' + pageSize
    );
  }

  public getCityByName(name: string, pageKey: number, pageSize: number) {

    return this.http.get<City[]>(
      // tslint:disable-next-line: max-line-length
      environment.urlServerTeclogi + this.endpoints.urlCatalog + '/cities?name=' + name + '&pageKey=' + pageKey + '&pageSize=' + pageSize
    );
  }

  public getCityByMunicipalityCode(municipalityCode: string, pageKey: number, pageSize: number) {
    return this.http.get<City[]>(
      // tslint:disable-next-line: max-line-length
      environment.urlServerTeclogi + this.endpoints.urlCatalog + '/cities?id=' + municipalityCode + '&pageKey=' + pageKey + '&pageSize=' + pageSize
    );
  }

}
