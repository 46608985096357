// Angular
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// Routing's

// Components
import { SharedModule } from 'src/app/core/shared.module';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { ForgotPasswordComponent } from './forgot-password/forgotPassword.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ValidateTermsComponent } from './validate-terms/validate-terms.component';
import { AccountValidationComponent } from './validation/validation.component';
import { MetadataFormComponent } from './metadata-form/metadata-form.component';
import { TokenHandlerComponent } from '../../public/token-handler/token-handler.component';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    AccountRoutingModule
  ],
  declarations: [
    AccountComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ValidateTermsComponent,
    AccountValidationComponent,
    MetadataFormComponent,
    TokenHandlerComponent
  ],
  entryComponents: [MetadataFormComponent],
  exports: [
    AccountComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ValidateTermsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AccountModule {

}
