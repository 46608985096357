import { Component, OnInit, Input, } from '@angular/core';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MatDialog } from '@angular/material';
import { Ticket } from 'src/app/core/interfaces/ticket';
import { TicketTypes } from 'src/app/core/interfaces/ticketTypes';


@Component({
  selector: 'app-item-ticket',
  templateUrl: './item-ticket.component.html',
  styleUrls: ['./item-ticket.component.scss']
})
export class ItemTicketComponent implements OnInit {

  @Input() ticket: Ticket;
  types = [];

  constructor(
    private adminUsersService: AdminUsersService,
    private router: Router,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    public ticketTypes: TicketTypes
  ) {
    this.types = this.ticketTypes.types;
  }

  ngOnInit() {

  }

  goToTicket() {
    this.router.navigate(['help-desk/detail', this.ticket.id]);
  }
  get currentStateColor() {
    if (this.ticket && this.ticket && this.ticket.state) {
      switch (this.ticket.state) {
        case 'Abierto':
          return { "background-color": "#38d138" }
        case 'En proceso':
          return { "background-color": "#48e7cf" }
        case 'En pausa':
          return { "background-color": "#fbb63c" }
        default:
          return { "background-color": "red" }
      }
    }
    return { "background-color": "#38d138" };
  }

  get currentType() {
    if (this.ticket && this.ticket.type) {
      let type = this.types.find(type => this.ticket.type === type.value);
      if (type) return type;
    }
    return this.types[5];
  }

}
