<div id="appAccount">

  <div class="main__account">

    <ngb-carousel>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="'/assets/img/login-slide.png'" alt="Random first slide">
        </div>
        <div class="carousel-caption">
          <h3 class="font-weight-bold">Una gran variedad de vehículos</h3>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="'/assets/img/login-slide2.png'" alt="Random second slide">
        </div>
        <div class="carousel-caption">
          <h3 class="font-weight-bold">Conductores disponibles en cualquier momento</h3>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="'/assets/img/login-slide3.png'" alt="Random third slide">
        </div>
        <div class="carousel-caption">
          <h3 class="font-weight-bold">100 cargas disponibles para transportar</h3>
        </div>
      </ng-template>
    </ngb-carousel>

    <div class="account-form">
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
