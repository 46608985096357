import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../../core/shared.module';
import { ReportsComponent } from 'src/app/modules/reports/reports.component';
import { ReportsFilterComponent } from 'src/app/modules/reports/reports-filter/reports-filter.component';
import { ReportsRoutingModule } from './reports-routing.module';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    ReportsRoutingModule,
  ],
  declarations: [
    ReportsComponent,
    ReportsFilterComponent
  ],
  entryComponents: [],
  exports: [
    ReportsComponent,
    ReportsFilterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class ReportsModule {

}
