import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Scenario, ScenarioStatus } from 'src/app/core/interfaces/scenario';
import { Utils } from 'src/app/core/resources/utils';
import { SCENARIO_STATUS_DICT } from 'src/app/core/enums/scenarioStatus.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoutingService } from '../../routing.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';

@Component({
  selector: 'app-scenario-modal',
  templateUrl: './scenario-modal.component.html',
  styleUrls: ['./scenario-modal.component.scss']
})
export class ScenarioModalComponent implements OnInit {
  scenario: ScenarioStatus;
  constructor(
    public dialogRef: MatDialogRef<ScenarioModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: { scenario: ScenarioStatus },
    public utils: Utils,
    private router: Router,
    private spinner: NgxSpinnerService,
    private routingService: RoutingService,
    private snackBarService: SnackBarService,
  ) { }

  /**
  * @description Updates the local variables from dialogParams
  */
  ngOnInit() {
    this.scenario = this.dialogParams.scenario;
  }

  /**
  * @description Navigates to routing detail
  */
  goToDetail() {
    this.router.navigate(['routing/detail', this.scenario.response.token]);
    this.dialogRef.close();
  }

  /**
  * @description Optimizes the scenario and closes the dialog
  */
  optimize() {
    this.spinner.show();
    this.routingService.optimizeScenario(this.scenario.response.token).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(RoutingMessages.OPTIMIZING);
        this.dialogRef.close();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        console.error(error);
      }
    )
  }

  /**
  * @returns {SCENARIO_STATUS_DICT} returns the scenario status from the dictionary
  * @description Gets the scenario status readable
  */
  get scenarioState(): SCENARIO_STATUS_DICT {
    return this.scenario && this.scenario.response && this.scenario.response.status
      ? SCENARIO_STATUS_DICT[this.scenario.response.status]
      : SCENARIO_STATUS_DICT.Geocoding;
  }

  /**
  * @returns {boolean} returns true if the scenario is able to create cargos, otherwise false
  * @description Verifies if the scenario is able to create cargos
  */
  get isReadyToCreateCargos(): boolean {
    return !!(this.scenario && this.scenario.success && this.scenario.status === "OK"
      && this.scenarioState === SCENARIO_STATUS_DICT.Optimized
    );
  }

  /**
  * @returns {boolean} returns true if the scenario is able to be optimized, otherwise false
  * @description Verifies if the scenario is able to be optimized
  */
  get isReadyToBeOptimized(): boolean {
    return !!(this.scenario && this.scenario.success && this.scenario.status === "OK"
      && this.scenarioState === SCENARIO_STATUS_DICT.Ready
    );
  }

  /**
  * @returns {boolean} returns true if the scenario is blocked, otherwise false
  * @description Verifies if the scenario is blocked
  */
  get isBlocked(): boolean {
    return !!(this.scenario && [
      SCENARIO_STATUS_DICT.Incomplete,
      SCENARIO_STATUS_DICT.Error,
      SCENARIO_STATUS_DICT.Canceled,
      SCENARIO_STATUS_DICT.Infeasible
    ].includes(this.scenarioState));
  }



}
