<div class="container">
  <div id="mainFreightTypeLoad" class="col-md-12 mb-md-0 mb-12">
    <!-- Carta de retiro -->
    <div class="row">
      <div class="col-sm-12 wrapper-letter-retirement">
        <div class="row">
          <div class="col-lg-6">
            <div class="trip">
              <div class="trip-icon">
                <img src="/assets/svg/icons/icon-export.svg">
              </div>
              <div class="trip-title">
                Exportación
              </div>
            </div>
          </div>
          <div class="col-lg-6 mt-2">
            <label class="title">Sube la carta de retiro</label>
            <div class="document-types">
              <label class="document-type">
                <input type="file" *ngIf="!manualCreationCargoService?.fileLetterRetirement?.url"
                  id="fileLetterRetirement" class="display-none" (change)="handleFileInput($event)">
                <div class="icon-type"
                  [ngClass]="{'file-loaded': uploadProgress === 100}||manualCreationCargoService?.fileLetterRetirement?.url">
                  <img *ngIf="uploadProgress !== 100 && !manualCreationCargoService?.fileLetterRetirement?.url"
                    src="assets/svg/icons/icon-upload.svg" alt="">
                  <img *ngIf="(uploadProgress === 100)||manualCreationCargoService?.fileLetterRetirement?.url"
                    src="assets/svg/icons/icon-correct.svg" alt="">
                </div>
                <div class="delete-container" *ngIf="manualCreationCargoService?.fileLetterRetirement?.url">
                  <i title="Eliminar carta de retiro" (click)="deleteFile()"
                    class="fas fa-trash color-primary cursor-pointer"></i>
                </div>
                <div class="data-type">
                  <label class="label-field title-letter">Carta de retiro</label>
                  <br>
                  <label *ngIf="uploadProgress === 100 || manualCreationCargoService?.fileLetterRetirement?.url"
                    class="label-field label-file">
                    <a class="file-name-container" *ngIf="manualCreationCargoService?.fileLetterRetirement?.url"
                      [href]="manualCreationCargoService?.fileLetterRetirement?.url" target="_blank">
                      <i class="fas fa-paperclip"></i>
                      <span class="file-name color-primary"
                        title="Ver carta de retiro"><u>{{manualCreationCargoService?.fileLetterRetirement?.name
                          | fileName}}</u></span>
                      <span class="file-size"
                        title="{{manualCreationCargoService?.fileLetterRetirement?.size}}">{{manualCreationCargoService?.fileLetterRetirement?.size}}</span>
                    </a>
                  </label>
                  <span *ngIf="!(uploadProgress) && !manualCreationCargoService?.fileLetterRetirement?.url"
                    class="label-load-file">
                    <a>Subir archivo</a>
                  </span>
                  <div *ngIf="(uploadProgress) && uploadProgress !== 100">
                    <mat-progress-bar [value]="uploadProgress">
                    </mat-progress-bar>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 button-container justify-content-center mt-3">
            <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
            <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>