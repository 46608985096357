import { Validators } from "@angular/forms";
import { Patterns } from "src/app/core/resources/patterns";
import { BasicResponse } from "./basicResponse";
import { Injectable } from "@angular/core";
import { WhoReports } from "./whoReports";
import { Modifications } from "./modifications";
import { State } from "./state";
export interface Receivable extends BasicResponse {
  cargoId?: string;
  whoReports: WhoReports;
  amountInitial: number | string;
  reason: string;
  amountReceived?: number;
  cargoConsecutive: string;
  companyId?: string;
  companyName?: string;
  consecutive?: number;
  creationDate?: string;
  driverDocument?: string;
  id?: string;
  licensePlate?: string;
  message?: string;
  modifications?: Modifications;
  ownerDocument?: string;
  state?: State;
  whoCreates?: WhoReports;
}

@Injectable()
export class Model {
  constructor(
    private patterns: Patterns
  ) { }

  public modelReceivable: Receivable = {
    cargoConsecutive: "",
    whoReports: {
      documentTypeId: "",
      documentTypeName: "",
      document: "",
      name: "",
    },
    amountInitial: "",
    reason: ""
  };

  public modelValidators = {
    cargoConsecutive: [Validators.required],
    whoReports: {
      document: [
        Validators.required,
        Validators.pattern(this.patterns.ONLY_NUMBERS.source),
        Validators.minLength(3),
        Validators.maxLength(15),
      ],
      documentTypeId: [Validators.required],
      documentTypeName: [Validators.required],
      name: [Validators.required],
    },
    amountInitial: [Validators.required],
    reason: [Validators.required],
  }
}
