import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatTooltip } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Utils } from 'src/app/core/resources/utils';
import { TypeMerchandiseService } from '../manual-creation-cargo/components/type-merchandise/type-merchandise.service';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';

@Component({
  selector: 'app-cargo-type-merchandise',
  templateUrl: './cargo-type-merchandise.component.html',
  styleUrls: ['./cargo-type-merchandise.component.scss']
})
export class CargoTypeMerchandiseComponent implements OnInit {
  @Input() inputFormControl: FormControl;
  @Input() options: OptionsAutocomplete;
  @Input() validate: string = '';
  formControltypeMerchandise: FormControl = new FormControl();
  @ViewChild('tooltipMerchandise', { static: true }) tooltipMerchandise: MatTooltip;
  filteredTypesMerchandise: Observable<{ id, description }[]>;
  showDefaultMerchandise = false;
  merchandiseDefault = {
    cargoNature: 'Carga Normal',
    cargoNatureId: '1',
    description: 'PRODUCTOS VARIOS',
    id: '009980'
  };

  constructor(
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private manualCreationCargoService: ManualCreationCargoService,
    private typeMerchandiseService: TypeMerchandiseService,

  ) { }

  ngOnInit() {
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.formControltypeMerchandise.setValidators(Validators.required);
    this.filteredTypesMerchandise = this.formControltypeMerchandise.valueChanges
      .pipe(
        startWith(''),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((data) => {
          try {
            this.spinner.show();
            if (!this.utils.isEmpty(data) && data.trim().length > 3) {
              return this.filterTypesMerchandise(data.trim() || '');
            } else {
              this.spinner.hide();
              return [];
            }
          } catch (e) {
            this.spinner.hide();
            return [];
          }
        })
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.options && changes.options.currentValue) {
        if (changes.options.currentValue.initialValue || changes.options.currentValue.initialValue === "") {
          if (changes.options.currentValue.initialValue) this.formControltypeMerchandise.setValue({ description: changes.options.currentValue.initialValue })
          else this.formControltypeMerchandise.setValue('');
        }
      }

      if (changes.validate) {
        switch (changes.validate.currentValue) {
          case 'touched':
            this.formControltypeMerchandise.markAsTouched();
            break;
          case 'untouched':
            this.formControltypeMerchandise.markAsUntouched();
            break;
          case 'enable':
            this.formControltypeMerchandise.enable();
            break;
          case 'disable':
            this.formControltypeMerchandise.disable();
            break;
          case 'disable&untouched':
            this.formControltypeMerchandise.markAsUntouched();
            this.formControltypeMerchandise.disable();
            break;
          case 'setValidators':
            this.formControltypeMerchandise.setValidators(Validators.required);
            this.formControltypeMerchandise.updateValueAndValidity();
            break;
          case 'clearValidators':
            this.formControltypeMerchandise.clearValidators();
            this.formControltypeMerchandise.updateValueAndValidity();
            break;
          default:
            break;
        }
      }
    }
  }

  private filterTypesMerchandise(value: string): Observable<{ id, description }[]> {
    const filterValue = value.toLowerCase();
    this.spinner.hide();
    return this.typeMerchandiseService.searchMerchandise(value).pipe(
      map(response => {
        if (response) {
          this.showDefaultMerchandise = false;
          this.tooltipMerchandise.hide();
          return response;
        } else {
          this.showDefaultMerchandise = true;
          this.tooltipMerchandise.show();
          return [];
        }
      })
    );
  }
  //!!metodo por revisar ya que con el de arriba no funciono
  // private suscribeTypeMerchandise(value: string) {
  //   if (value) {
  //     const filterValue = value.toLowerCase();
  //     this.spinner.show();
  //     this.typeMerchandiseService.searchMerchandise(filterValue)
  //       .subscribe(response => {
  //         this.spinner.hide();
  //         if (response) this.formControltypeMerchandise.setValue(response[0]);
  //       })
  //   }
  // }
  changeValue() {
    if (this.inputFormControl) this.inputFormControl.setValue('');
  }
  public onSelectValue($event: MatAutocompleteSelectedEvent) {
    this.inputFormControl.setValue({
      id: $event.option.value.id,
      description: $event.option.value.description,
      cargoNature: $event.option.value.cargoNature,
      cargoNatureId: $event.option.value.cargoNatureId
    });
  }

  public displayFn(data: any): string {
    return data ? data.description : data;
  }

  public returnFn(data: any): number | undefined {
    return data ? data.id : undefined;
  }
}
