import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CargoOptionsService {
  private reloadSubject = new Subject<any>();
  constructor(
    private _endpointResources: Endpoints,
    private _http: HttpClient
  ) { }

  reload$ = this.reloadSubject.asObservable();

  triggerReload() {
    this.reloadSubject.next();
  }

  fastPayment(idCargo: string, state: boolean, tripType: string) {
    let params = new HttpParams();
    params = params.append('accepted', `${state}`);
    if (tripType === 'Urbana' || tripType === 'Última milla') params = params.append('exception', `true`);
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.fastPayment + idCargo + '?', null, { params }
    );
  }

  restartCargo(cargoId: string) {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.get(
      `${environment.urlServerTeclogi}${this._endpointResources.restartLoad}${params}`
    )
  }

  deletePayment(cargoId: string, paymentId: string) {
    return this._http.delete(
      `${environment.urlServerTeclogi}${this._endpointResources.deletePaymentCargo}cargoId=${cargoId}&paymentId=${paymentId}`
    );
  }

  getGPSProviders() {
    return this._http.get(`${environment.urlServerTeclogi}${this._endpointResources.getGPSProviders}`);
  }
}
