<div class="px-4 pb-1 history-container">
  <div class="history-filter-container">
    <app-history-filter (emitlistHistoryParams)="getListHistory($event)" [totalHistory]="totalHistory"
      [companyId]="companyId"></app-history-filter>
  </div>
  <div id="scrollContainer" class="history-detail-container mb-2 px-2" [ngClass]="{'notResults': !listHistory?.length}">
    <app-history-and-metrics-company-detail [listHistory]="listHistorySlice"
      *ngIf="listHistory?.length"></app-history-and-metrics-company-detail>
    <div *ngIf="!listHistory.length" class="container_no_results">
      <div class="icon"><i class="fas fa-calendar-times"></i></div>
      <h4 class="text-bold text-center">No hay resultados que coincidan con la búsqueda</h4>
    </div>
  </div>
  <mat-paginator [ngClass]="{'display-none':listHistory.length === 0}" [length]="listHistory.length"
    [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"
    showFirstLastButtons>
  </mat-paginator>
</div>