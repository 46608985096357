import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdditionalCost } from 'src/app/core/interfaces/additionalCost';
import { AdditionalCostCargo } from 'src/app/core/interfaces/additionalCostCargo';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AdditionalServiceComponent } from '../../../additional-service/additional-service.component';
import { ManualCreationCargoService } from '../../manual-creation-cargo.service';
import { CargoResources } from '../../resources/cargo';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';

@Component({
  selector: 'app-creation-additional-cost-cargo',
  templateUrl: './creation-additional-cost-cargo.component.html',
  styleUrls: ['./creation-additional-cost-cargo.component.scss']
})
export class CreationAdditionalCostCargoComponent {

  @ViewChild(AdditionalServiceComponent, { static: false }) additionalServiceComponent: AdditionalServiceComponent;
  public listAdditionalService: AdditionalCost[] = [];
  modelFormAdditionalCost: AdditionalCostCargo[];

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private route: ActivatedRoute,
    public utils: Utils,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
  ) {
    this.route.data.subscribe((data: { aditionalServicesResolver: AdditionalCost[] }) => {
      if (data && data.aditionalServicesResolver) {
        this.listAdditionalService = this.utils.clone(data.aditionalServicesResolver);
        this.disabledAdditionalCostsSelected();
      }
    });
  }

  nextStep() {
    if (this.additionalServiceComponent.form.valid) {
      const formValue: AdditionalCostCargo[] = this.additionalServiceComponent.form.value;
      let reactiveForm: ReactiveForm;
      this.manualCreationCargoService.getCargoForm().setControl('additionalCosts', new FormArray([]));
      reactiveForm = new ReactiveForm(
        this.formBuilder,
        this.utils.clone(formValue).map((additionalCost) => {
          delete additionalCost.additionaInfoCost;
          return additionalCost;
        })
      );
      this.manualCreationCargoService.getCargoForm().setControl('additionalCosts', reactiveForm.form);
      // Valor del flete
      this.manualCreationCargoService.getComponentActive().setId(4);
      this.manualCreationCargoService.getComponentActive().getStep().setId(1);
      this.router.navigate([this.manualCreationCargoService.steps.cargoValue.url]);
    } else {
      let index = this.additionalServiceComponent.form.controls.findIndex((service) => service.invalid);
      if (index !== -1) {
        if (this.additionalServiceComponent.form.controls[index].errors)
          this.snackBarService.openSnackBar(FormMessages.MINIMUN_UTILITY_NOT_REACHED_DEFAULT + ` en el servicio ${index + 1}`, undefined, 'alert');
        else
          this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, `tarifa del servicio ${index + 1}`), undefined, 'alert');
      } else {
        this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
      }
    }
  }



  disabledAdditionalCostsSelected() {
    (this.manualCreationCargoService.formOriginadditionalCosts.value as AdditionalCostCargo[]).forEach((additionalCost) => {
      const index = this.listAdditionalService.findIndex((item) => {
        return item.name === additionalCost.type.name;
      });
      if (index >= 0 && additionalCost.type.id === this.listAdditionalService[index].id) {
        this.listAdditionalService[index].disabled = true;
      }
    });
    this.modelFormAdditionalCost = this.manualCreationCargoService.formOriginadditionalCosts.value;
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(3);
    this.manualCreationCargoService.getComponentActive().getStep().setId(2);
    this.router.navigate([this.manualCreationCargoService.steps.download.url]);
  }

  get isSomeAdditionalService(): boolean {
    if (this.additionalServiceComponent && this.additionalServiceComponent.form) {
      const formValue: AdditionalCostCargo[] = this.additionalServiceComponent.form.value;
      return !!formValue.length;
    }
    return false;
  }

}
