import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { BodyWorkType } from 'src/app/core/interfaces/bodyWorkType';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ManualCreationCargoService } from '../../manual-creation-cargo.service';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';

@Component({
  selector: 'app-vehicle-body-work-type',
  templateUrl: './vehicle-body-work-type.component.html',
  styleUrls: ['./vehicle-body-work-type.component.scss']
})
export class VehicleBodyWorkTypeComponent implements OnInit {
  @Input() inputFormControl: FormControl;
  @Input() validate: string = '';
  @Input() options: OptionsAutocomplete;

  bodyWorkList: BodyWorkType[];
  bodyWorkTypeCtrl: FormControl = new FormControl('');
  filteredOptions: Observable<BodyWorkType[]>;
  public vehicleCargo: VehicleCargo[];

  constructor(
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.bodyWorkTypeCtrl.setValidators(Validators.required);
  }

  ngOnChanges(changes) {

    if (changes) {
      if (changes.options && changes.options.currentValue) {
        if (changes.options.currentValue.initialValue || changes.options.currentValue.initialValue === "") {
          if (changes.options.currentValue.initialValue === "") this.bodyWorkTypeCtrl.setValue('');
          else {
            if (this.inputFormControl) this.bodyWorkTypeCtrl.setValue({ name: changes.options.currentValue.initialValue })
          }
        }
        if (changes.options.currentValue.initialVehicleTypeName || changes.options.currentValue.initialVehicleTypeName === "") {
          if (changes.options.currentValue.initialVehicleTypeName === "") this.bodyWorkList = [];
          else this.getVehicleType(changes.options.currentValue.initialVehicleTypeName);
        } else if (changes.options.currentValue.initialVehicleTypeId || changes.options.currentValue.initialVehicleTypeId === "") {
          if (changes.options.currentValue.initialVehicleTypeId === "") this.bodyWorkList = [];
          else this.getBodyworkByVehicleType(changes.options.currentValue.initialVehicleTypeId);
        }

      }
      if (changes.validate) {
        switch (this.validate) {
          case 'touched':
            this.bodyWorkTypeCtrl.markAsTouched();
            break;
          case 'untouched':
            this.bodyWorkTypeCtrl.markAsUntouched();
            break;
          case 'enable':
            this.bodyWorkTypeCtrl.enable();
            break;
          case 'disable':
            this.bodyWorkTypeCtrl.disable();
            break;
          case 'disable&untouched':
            this.bodyWorkTypeCtrl.markAsUntouched();
            this.bodyWorkTypeCtrl.disable();
            break;
          case 'setValidators':
            this.bodyWorkTypeCtrl.setValidators(Validators.required);
            this.bodyWorkTypeCtrl.updateValueAndValidity();
            break;
          case 'clearValidators':
            this.bodyWorkTypeCtrl.clearValidators();
            this.bodyWorkTypeCtrl.updateValueAndValidity();
            break;
          default:
            break;
        }
      }
    }
  }

  onSelectValue($event: MatAutocompleteSelectedEvent) {
    this.inputFormControl.setValue($event.option.value);
  }


  displayFn(data: any): string {
    return data ? data.name : data;
  }

  initAutocomplete() {
    this.filteredOptions = this.bodyWorkTypeCtrl.valueChanges
      .pipe(
        startWith(''),
        distinctUntilChanged(),
        map((data) => {
          const dataValue: string = typeof data === 'string' ? data.trim().toLowerCase() : typeof data === 'object' ? data.name.trim().toLowerCase() : '';
          if (dataValue.length) {
            return this.filter(dataValue);
          } else {
            return this.bodyWorkList;
          }
        })
      );
  }

  private filter(filterValue): BodyWorkType[] {
    return this.bodyWorkList.filter(bodyWork => bodyWork.name.toLowerCase().indexOf(filterValue) >= 0);
  }

  getVehicleType(vehicleTypeName) {
    this.spinner.show();
    this.manualCreationCargoService.getVehicleType().subscribe(
      (data: Catalog) => {
        this.spinner.hide();
        this.vehicleCargo = (data.catalog as VehicleCargo[]).filter((item: VehicleCargo) => {
          if (vehicleTypeName === item.name) {
            return item;
          }
        });
        if (this.vehicleCargo.length) this.getBodyworkByVehicleType(this.vehicleCargo[0].id);
        else this.bodyWorkList = [];
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getBodyworkByVehicleType(vehicleTypeId: string) {
    this.spinner.show();
    this.manualCreationCargoService.getBodyworkByVehicleType(vehicleTypeId).toPromise()
      .then((success) => {
        if (Array.isArray(success)) {
          this.bodyWorkList = success;
        } else {
          this.bodyWorkList = [];
        }
        this.initAutocomplete();
      })
      .catch((error) => {
        console.error(error);
        this.bodyWorkList = [];
      })
      .finally(() => this.spinner.hide());
  }

  changeValue() {
    if (this.inputFormControl) this.inputFormControl.setValue("");
  }
}
