export class AdditionalService {

    private uploadFor: string;
    private auxiliaryByUpload: number;
    private downloadFor: string;
    private auxiliaryByDownload: number;
    private bodyGuard: string;
    private bodyGuardType: string;

    public getUploadFor(): string {
        return this.uploadFor;
    }

    public setUploadFor(uploadFor: string): void {
        this.uploadFor = uploadFor;
    }

    public getAuxiliaryByUpload(): number {
        return this.auxiliaryByUpload;
    }

    public setAuxiliaryByUpload(auxiliaryByUpload: number): void {
        this.auxiliaryByUpload = auxiliaryByUpload;
    }

    public getDownloadFor(): string {
        return this.downloadFor;
    }

    public setDownloadFor(downloadFor: string): void {
        this.downloadFor = downloadFor;
    }

    public getAuxiliaryByDownload(): number {
        return this.auxiliaryByDownload;
    }

    public setAuxiliaryByDownload(auxiliaryByDownload: number): void {
        this.auxiliaryByDownload = auxiliaryByDownload;
    }

    public getBodyGuard(): string {
        return this.bodyGuard;
    }

    public setBodyGuard(bodyGuard: string): void {
        this.bodyGuard = bodyGuard;
    }

    public getBodyGuardType(): string {
        return this.bodyGuardType;
    }

    public setBodyGuardType(bodyGuardType: string): void {
        this.bodyGuardType = bodyGuardType;
    }


    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }
}
