import { Injectable, Input } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BoxTypeCargaService {

    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
    ) { }


}
