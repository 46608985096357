import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, } from '@angular/router';
import { FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { ModuleRole } from 'src/app/core/interfaces/moduleRole';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FunctionalitiesModuleRole } from 'src/app/core/interfaces/functionalitiesModuleRole';
import { FunctionalitiesService } from '../functionalities.service';
import { ViewChild } from '@angular/core';
import { FunctionalitiesListComponent } from '../functionalities-list/functionalities-list.component';
import { Utils } from 'src/app/core/resources/utils';


@Component({
  selector: 'app-functionalities-form',
  templateUrl: './functionalities-form.component.html',
  styleUrls: ['./functionalities-form.component.scss']
})
export class FunctionalitiesFormComponent implements OnInit {
  @Input() module: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    id: new FormControl('', Validators.required),
  });

  @ViewChild(FunctionalitiesListComponent, { static: false }) listFunctionalities: FunctionalitiesListComponent

  functionalitiesForm = new FormGroup({
    name: new FormControl('', Validators.required),
    id: new FormControl('', Validators.required),
    moduleId: new FormControl(''),
  });
  detailFunctionality: any;
  constructor(
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private functionalitiesService: FunctionalitiesService,
    public utils: Utils,
  ) { }

  ngOnInit() {
  }
  onSubmit() {
    if (!this.utils.errorMessagesCustomized(this.functionalitiesForm.get('name'), 'nombre')) {
      if (this.module.get('id').value) {
        let functionalitiesModuleRole: FunctionalitiesModuleRole = {
          name: this.functionalitiesForm.controls.name.value,
          moduleId: this.module.get('id').value
        }
        this.createFunctionality(functionalitiesModuleRole);
        this.functionalitiesForm.reset();
      } else {
        this.snackBarService.openSnackBar('No hay un modulo asociado a esta funcionalidad', undefined, 'alert');
      }
    }
  }

  createFunctionality(functionalitiesModuleRole) {
    this.spinner.show();
    this.functionalitiesService.createFunctionality(functionalitiesModuleRole).toPromise()
      .then((success: FunctionalitiesModuleRole) => {
        if (success) {
          this.snackBarService.openSnackBar('Funcionalidad creada');
          this.listFunctionalities.getFunctionalitiesByIdModule()

        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al crear la funcionalidad', undefined, 'error');
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrió un error al crear la funcionalidad', undefined, 'error');

      })
      .finally(() => {
        this.spinner.hide();
      });

  }
}




