export class Fmt {
    public static string(str: string, ...args: any[]) {
        str = (str as any).replaceAll('%%', '\u{ffff}');
        let index = -1;
        let args_pointer = 0;
        while (index = str.indexOf('%')) {
            if (index === -1)
                break;

            const fmt = str[index + 1];
            switch (fmt) {
                case 'c':
                    str = str.replace('%c', args[args_pointer][0]);
                    break;
                case 's':
                    str = str.replace('%s', args[args_pointer]);
                    break;
                case 'd':
                    const d = parseInt(`${args[args_pointer]}`);
                    str = str.replace('%d', `${d}`);
                    break;
                case 'f':
                    const f = parseFloat(`${args[args_pointer]}`);
                    str = str.replace('%f', `${f}`);
                    break;
                case 'u':
                    const u = parseInt(`${args[args_pointer]}`);
                    str = str.replace('%u', `${Math.abs(u)}`);
                    break;
            }
            args_pointer++;
        }
        return (str as any).replaceAll('\u{ffff}', '%');
    }
}
