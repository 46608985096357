import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { CargoApproval } from 'src/app/core/interfaces/cargo-approval';
import { CargoApprovalRequest } from 'src/app/core/interfaces/cargo-approval-request';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Patterns } from 'src/app/core/resources/patterns';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from '../dialog/dialog.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { EndCargoCheckComponent } from 'src/app/modules/cargo/end-cargo-check/end-cargo-check.component';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { CargoManager } from 'src/app/core/managers/cargo.manager';

@Component({
  selector: 'app-approve-cargo-dialog',
  templateUrl: './approve-cargo-dialog.component.html',
  styleUrls: ['./approve-cargo-dialog.component.scss'],
  providers: [DateFormatPipe, CargoManager]
})
export class ApproveCargoDialogComponent implements OnInit {
  public readonly currentDate = new Date();

  formMinistryCargos: FormGroup;
  loading = true;
  today: Date = new Date();

  cargosRNDC: Array<Cargo> = []; // Tienen remesa
  cargosNoRNDC: Array<Cargo> = []; // No tienen remesa

  constructor(
    public dialogRef: MatDialogRef<ApproveCargoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: Utils,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private fb: FormBuilder,
    private patterns: Patterns,
    private dialog: MatDialog,
    private cargoDetailService: CargoDetailService,
    public dateFormatPipe: DateFormatPipe,
    private cargoManager: CargoManager
  ) {
    this.spinner.show();
    this.cargosRNDC = (this.data.cargos as Array<Cargo>).filter((cargo: Cargo) => cargo.ministry);
    this.cargosNoRNDC = (this.data.cargos as Array<Cargo>).filter((cargo: Cargo) => !cargo.ministry);
  }

  ngOnInit() {
    // Crear el formulario con estructura dinámica
    this.formMinistryCargos = this.fb.group({
      cargos: this.fb.array([]),
      userId: this.authService.getUserSession().information.document,
      userName: this.authService.getUserSession().information.name,
      receiptDateDocuments: new Date()
    });

    this.initData();

    this.loading = false;
    this.spinner.hide();
  }

  private initData() {
    // Obtener la referencia al control 'cargos'
    const cargosFormArray = this.formMinistryCargos.get('cargos') as FormArray;

    // Iterar sobre cada cargo en la estructura de datos y agregarlo al formulario
    this.cargosRNDC.forEach(async cargo => {
      cargosFormArray.push(await this.createFormGroupCargo(cargo));
    });
  }

  private async createFormGroupCargo(cargo: Cargo) {
    const lastAddressOrigin: AddressCargo = cargo.cargoFeature.uploadDownload.origin.addresses[cargo.cargoFeature.uploadDownload.origin.addresses.length - 1];
    const minDate = cargo.dateLoad.slice(0, 10) + ' ' + lastAddressOrigin.time;
    const destinations = cargo.cargoFeature.uploadDownload.destination;
    const consignments = [];
    const isCargoWithoutInitialCompliment: boolean = destinations.some(dest => {
      return dest.addresses.some(add => {
        const date = DateManager.stringToDate(`${dest.downloadDate.slice(0, 10)} ${add.time} +0500`);
        const less72hrs = DateManager.isBefore(new Date(), DateManager.add(date, 3, 'days'));
        return add && add.consignments && !(add.dateTimeDownload && add.dateTimeDownload[0]) && less72hrs;
      })
    })

    if (this.cargoManager.isTripTypeNational(cargo) && isCargoWithoutInitialCompliment)
      await this.makeInitialCompliment(cargo);

    destinations.forEach((dest, i) => {
      dest.addresses.forEach((add, j) => {
        if ((add && add.consignments && add.consignments.length && this.cargoManager.isTripTypeNational(cargo))) {
          const consigmentForm = this.createFormGroupConsignment(cargo.dateLoad, add, i, j, lastAddressOrigin, minDate, destinations[i].downloadDate);
          !!consigmentForm && consignments.push(consigmentForm);
        }
      })
    })
    return this.fb.group({
      cargoId: [cargo.id],
      consignment: this.fb.array(consignments),
    });
  }

  private async makeInitialCompliment(cargo: Cargo) {
    const config = new MatDialogConfig();
    config.data = {
      title: `¿La carga ${cargo.consecutive} no tiene cumplido inicial, desea realizarlo?`,
      showNoBtn: true,
      showYesBtn: true,
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.EXTRA_SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    const openEndCargoCheck = (await this.dialog.open(DialogComponent, config).afterClosed().toPromise());

    if (!openEndCargoCheck || !openEndCargoCheck.state) {
      this.snackbarService.openSnackBar("Una carga sin cumplido inicial no puede continuar el proceso", "Aceptar", "alert");
      this.dialogRef.close();
    } else {
      const config = new MatDialogConfig();
      config.data = cargo;
      config.maxHeight = ModalEnum.MAX_HEIGHT;
      config.width = ModalEnum.LARGE_WIDTH;
      config.maxWidth = ModalEnum.MAX_WIDTH;
      const _modal = this.dialog.open(EndCargoCheckComponent, config);
      const result = await _modal.afterClosed().toPromise();
      if (!!result) {
        cargo = await this.cargoDetailService.detailCargoByConsecutive(`${cargo.consecutive}`).toPromise() as Cargo;
        const cargosFormArray = this.formMinistryCargos.get('cargos') as FormArray;
        cargosFormArray.push(await this.createFormGroupCargo(cargo));
      } else {
        this.snackbarService.openSnackBar("No se ha completado el cumplido inicial", "Aceptar", "alert");
        this.dialogRef.close();
      }
    }
  }

  private createFormGroupConsignment(dateload: string, address: AddressCargo, i: any, j: any, lastAddressOrigin: AddressCargo, minDate: any, downloadDate: string): FormGroup | false {

    const dateDownload = this.cargoManager.getDownloadDateBase(address, downloadDate);

    const [timeHours, timeMinutes] = lastAddressOrigin.time.split(':');
    const [timeHoursDownload, timeMinutesDownload] = address.time.split(':');
    const OriginTime = DateManager.add(
      DateManager.add(dateload, parseInt(timeHours), 'hours'),
      parseInt(timeMinutes), 'minutes'
    );
    const fullDownloadDate = DateManager.dateToString(DateManager.add(
      DateManager.add(downloadDate.substring(0, 10), parseInt(timeHoursDownload), 'hours'),
      parseInt(timeMinutesDownload), 'minutes'
    ));

    try {
      const form = this.fb.group({
        id: [address.consignments[0], [Validators.required]],
        destinationId: [i, [Validators.required]],
        addressId: [address.id, [Validators.required]],
        address: address.address, // Por comodidad para imprimir las direcciones, se eliminará del fomrulario al enviarlo
        amountDelivered: [address.cargoMeasure.totalWeigth, [Validators.required, Validators.pattern(this.patterns.ONLY_NUMBERS.source)]],
        upload: this.fb.group({
          estimatedTime: [lastAddressOrigin.time, [Validators.required]],
          dateLoad: [dateload, [Validators.required]],
          arrival: [DateManager.add(OriginTime, 16, 'minutes')],
          entry: [DateManager.add(OriginTime, 30, 'minutes')],
          departure: [
            DateManager.add(
              DateManager.add(OriginTime, lastAddressOrigin.timePact > 0 ? lastAddressOrigin.timePact : 1, 'hours'),
              lastAddressOrigin.minutePact,
              'minutes'
            )
          ]
        }),
        download: this.fb.group({
          arrival: [DateManager.stringToDate(dateDownload)],
          entry: [DateManager.add(dateDownload, 16, 'minutes')],
          departure: [DateManager.add(
            DateManager.add(dateDownload, address.timePact > 0 ? address.timePact : 1, 'hours'),
            address.minutePact,
            'minutes'
          )],
          estimatedTime: [address.time, [Validators.required]],
          dateDownload: [fullDownloadDate, [Validators.required]]
        })
      });
      form.get('upload.arrival').setValidators([Validators.required, this.checkDurationTimes(DateManager.add(OriginTime, 15, 'minutes')), this.checkMax()]);
      form.get('upload.entry').setValidators([Validators.required, this.checkDurationTimes(form.get('upload.arrival')), this.checkMax()]);
      form.get('upload.departure').setValidators([Validators.required, this.checkDurationTimes(form.get('upload.entry')), this.checkMax()]);
      form.get('download.arrival').setValidators([Validators.required, this.checkDurationTimes(form.get('upload.departure')), this.checkMax()]);
      form.get('download.entry').setValidators([Validators.required, this.checkDurationTimes(form.get('download.arrival')), this.checkMax()]);
      form.get('download.departure').setValidators([Validators.required, this.checkDurationTimes(form.get('download.entry')), this.checkMax()]);
      form.markAllAsTouched();
      return form;
    } catch (e) {
      // No tiene remesa pero si manifiesto? no debería pasar
      // this.snackbarService.openSnackBar("No se ha detectado remesa para la dirección " + consignacion.address, "Aceptar", "error")
      return false;
    }
  }

  private checkDurationTimes(minDate: Date | AbstractControl, minutePact?: number, timePact?: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control || !control.value || !minDate) return {};
      if (minDate instanceof Date) return DateManager.isSameOrBefore(control.value, minDate, 'seconds') ? { 'minDate': `${DateManager.dateToString(minDate, 'YYYY-MM-DD HH:mm')}` } : {};
      let minDateValue = minDate.value;
      if (this.utils.isDefined(minutePact) && this.utils.isDefined(timePact)) {
        minDateValue = DateManager.add(minDateValue, minutePact, "minutes");
        minDateValue = DateManager.add(minDateValue, timePact, "hours");
      }
      return minDate.value && DateManager.isSameOrBefore(control.value, minDateValue, 'seconds') ? { 'minDate': `${DateManager.dateToString(minDateValue, 'YYYY-MM-DD HH:mm')}` } : {}
    }
  }

  private checkMax(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control || !control.value) return {};
      return !DateManager.isSameOrBefore(control.value, this.today, 'seconds') ? { 'maxDate': `${DateManager.dateToString(this.today)}` } : {};
    }
  }

  private createConsignmentsWithoutMinistry(): Array<CargoApproval> {
    const cargos: Array<CargoApproval> = [];
    this.cargosNoRNDC.forEach(
      (cargo) => cargos.push({ cargoId: cargo.id, consignment: [] })
    );
    return cargos;
  }

  approve() {
    if (this.formMinistryCargos.invalid) {
      this.formMinistryCargos.markAllAsTouched();
      this.findErrors();
      return;
    }
    this.formMinistryCargos.value.receiptDateDocuments = DateManager.dateToString(this.formMinistryCargos.value.receiptDateDocuments);
    this.formatDatesCargo(this.formMinistryCargos.value.cargos);
    const cargosRequestForm: CargoApprovalRequest = this.utils.clone(this.formMinistryCargos.value);

    // Limpiar address
    for (const cargo of cargosRequestForm.cargos) {
      for (const consignment of cargo.consignment) {
        if (consignment['address'])
          delete consignment['address'];
        if (consignment['upload']) {
          delete consignment['upload']['estimatedTime'];
          delete consignment['upload']['dateLoad'];
        }
        if (consignment['download']) {
          delete consignment['download']['estimatedTime'];
          delete consignment['download']['dateDownload'];
          delete consignment['download']['timePact'];
          delete consignment['download']['minutePact'];
        }
      }
    }

    const cargosNoRNDC = this.createConsignmentsWithoutMinistry();
    for (const cargo of cargosNoRNDC) {
      if (!cargo.consignment.length) {
        delete cargo.consignment;
      }
      cargosRequestForm.cargos.push(cargo);
    }
    this.dialogRef.close(cargosRequestForm);
  }

  private findErrors() {
    let controls = {
      'upload.arrival': 'fecha de llegada del cargue',
      'upload.entry': 'fecha de entrada del cargue',
      'upload.departure': 'fecha de salida del cargue',
      'download.arrival': 'fecha de llegada del descargue',
      'download.entry': 'fecha de llegada del descargue',
      'download.departure': 'fecha de salida del descargue',
      'amountDelivered': 'cantidad entregada '
    };
    let haveErrors = false;
    (this.formMinistryCargos.controls.cargos as FormArray).controls.forEach((cargo, index) => {
      if (!haveErrors) {
        (cargo.get('consignment') as FormArray).controls.forEach((consignmentAdd, indexConsigment) => {
          Object.keys(controls).forEach(key => {
            if (!haveErrors && consignmentAdd.get(key) && consignmentAdd.get(key).errors) {
              haveErrors = true;
              if (consignmentAdd.get(key).errors.minDate)
                this.snackbarService.openSnackBar(`La ${controls[key]} de la carga ${index + 1} remesa ${indexConsigment + 1} debe ser mayor a ${consignmentAdd.get(key).errors.minDate}`, undefined, 'alert');
              else if (consignmentAdd.get(key).errors.maxDate)
                this.snackbarService.openSnackBar(`La ${controls[key]} de la carga ${index + 1} remesa ${indexConsigment + 1} debe ser menor a la fecha y hora actual`, undefined, 'alert');
              else this.utils.errorMessagesCustomized(consignmentAdd.get(key), `${controls[key]} de la carga ${index + 1} remesa ${indexConsigment + 1}`)
            }
          })
        })
      }
    });
  }

  formatDatesCargo(cargos: any[]) {
    cargos.forEach(cargo => {
      if (cargo && cargo.consignment) {
        cargo.consignment.forEach(consignmentAdd => {
          if (consignmentAdd && consignmentAdd.upload) {
            consignmentAdd.upload.arrival = this.dateToString(new Date(consignmentAdd.upload.arrival));
            consignmentAdd.upload.departure = this.dateToString(new Date(consignmentAdd.upload.departure));
            consignmentAdd.upload.entry = this.dateToString(new Date(consignmentAdd.upload.entry));
            consignmentAdd.download.arrival = this.dateToString(new Date(consignmentAdd.download.arrival));
            consignmentAdd.download.entry = this.dateToString(new Date(consignmentAdd.download.entry));
            consignmentAdd.download.departure = this.dateToString(new Date(consignmentAdd.download.departure));
          }
        })
      }
    })
  }

  private dateToString(value: Date) {
    if (!value.getHours() && !value.getMinutes()) return DateManager.dateToString(DateManager.add(value, 1, 'minutes'));
    return DateManager.dateToString(value);
  }

  public get formMinistryCargosControls(): AbstractControl[] {
    return this.formMinistryCargos && this.formMinistryCargos.get('cargos') ? (this.formMinistryCargos.get('cargos') as FormArray).controls : [];
  }

  public getFormMinistryCargoControls(_cargo: FormGroup): AbstractControl[] {
    return _cargo && _cargo.get('consignment') ? (_cargo.get('consignment') as FormArray).controls : [];
  }

  formatMinDateLoadEntry(date: string, time: string) {
    return DateManager.add(date.slice(0, 10) + ' ' + time, 15, 'minutes');
  }

  formatMinDateDownloadEntry(date: string | Date) {
    if (typeof date === "string")
      return DateManager.add(date.slice(0, 16), 15, 'minutes');
    return DateManager.add(date, 15, 'minutes');
  }

  formatMinDateDownloadDeparture(date: Date, minutePact: number, timePact: number) {
    let minDateValue = date;
    minDateValue = DateManager.add(minDateValue, minutePact, "minutes");
    minDateValue = DateManager.add(minDateValue, timePact, "hours");
    return minDateValue;
  }
}
