import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AuthService } from 'src/app/core/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class StandardFileService {

  constructor(
    private angularFireStorage: AngularFireStorage
  ) { }

  public loadFileToStorage(path: string, name: string, file: any) {
    return new Promise((resolve, reject) => {
      const storageRef = this.angularFireStorage.ref(`${path}/${name}`);
      const task = storageRef.put(file);
      task.then((success) => {

      }).catch((error) => {
      }).finally(() => {
        resolve(true);
      });
    });

  }

  public addMultipleFilesToStorages(directory: string, name: string, imageFile: any) {
    return new Promise((resolve, reject) => {
      const storageRef = this.angularFireStorage.ref(directory + '/' + name);
      const task = storageRef.put(imageFile);

      task
        .then((success) => {
          resolve({
            success,
            status: true
          });
        })
        .catch((error) => {
          reject({
            error,
            status: false
          });
        })
        .finally(() => {

        });
    });

  }

  public getURLDocument(path: string): Observable<any> {
    return new Observable(subscriber => {
      const storage = AuthService.fStorage;
      const pathReference = storage.ref(path);
      pathReference.getDownloadURL()
        .then((url) => {
          subscriber.next(url);
        })
        .catch((error) => {
          subscriber.next(error);
        })
        .finally(() => {
          subscriber.complete();
        });
    });
  }

}

