import { ContainerMeasure } from "src/app/core/interfaces/containerMeasure";
import { PackagingType } from "src/app/core/interfaces/packagingType";

export class ModelCargo {

  packagingTypes: PackagingType[] = [
    { code: '0', description: 'Paquetes. General Fraccionada en máximo 2 kgs por unidad de empaque' },
    { code: '6', description: 'Granel Líquido' },
    { code: '4', description: 'General Fraccionada (Bulto, Caja, Saco, Paca, Pieza)' },
    { code: '12', description: 'Cilindros' },
    { code: '15', description: 'Granel Sólido' },
    { code: '17', description: 'Varios' },
    { code: '18', description: 'Unidad sin empaque' },
    { code: '19', description: 'Carga Estibada' },
    { code: '4C', description: 'Caja de Madera Natural' },
    { code: '5L', description: 'Saco o Talega de Yute, Algodón, Fique o Cabuya' },
    { code: '5H', description: 'Saco o Talega de Polietileno o Polipropileno' },
    { code: '4G', description: 'Caja de Cartón' },
    { code: '1H', description: 'Bidón o Envase de plastico' },
    { code: '4H', description: 'Caja de plastico' },
    { code: '10', description: 'Un contenedor de 45 pies', hidden: true },
    { code: '9', description: 'Un contenedor de 40 pies', hidden: true },
    { code: '8', description: 'Dos contenedores de 20 pies', hidden: true },
    { code: '7', description: 'Un contenedor de 20 pies', hidden: true }
  ];

  containers: ContainerMeasure[] = [
    { size: 'Un contenedor de 45 pies', code: '10' },
    { size: 'Un contenedor de 40 pies', code: '9' },
    { size: 'Dos contenedores de 20 pies', code: '8' },
    { size: 'Un contenedor de 20 pies', code: '7' }
  ];

}
