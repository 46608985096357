
import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { Filter } from 'src/app/core/models/filter';
import { environment } from '../../../../environments/environment';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FreightListService {

  public paginationList: PaginationList = new PaginationList();

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    public utils: Utils,
    private authService: AuthService,
  ) { }

  resetPaginationList() {
    this.paginationList.setEnablePagingScroll(true);
    this.paginationList.setList([]);
    this.paginationList.setPageKey(1);
  }

  public postEnturnamiento(params) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlEnturnamiento,
      params
    );
  }

  public getListTurn(paramsFilter?) {
    let url: string = environment.urlServerTeclogi + this._endpointResources.urlEnturnamiento;

    if (paramsFilter) {
      if (paramsFilter.startsWith('&')) {
        paramsFilter = paramsFilter.replace('&', '');
      }
      url += '?' + paramsFilter;
    }

    return this._http.get(url);
  }
}
