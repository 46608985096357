<a class="nav-home" routerLink='/account/login'>
  Regresar
</a>

<div class="form-container">
  <ng-scrollbar #scrollable track="all" pointerEventsMethod="scrollbar">
    <!-- Step one -->
    <div class="container-form" *ngIf="registrationSteps.stepActive == 1">
      <!-- Process one -->
      <div class="step-process" *ngIf="registrationSteps.stepOne.processActive === 1">
        <form [formGroup]="registerForm.stepOne.stepOneProcessOne">
          <div class="steps-label">
            Paso {{registrationSteps.stepActive}} de {{registrationSteps.stepsTotal}}
          </div>
          <div class="title-1">
            Verificar empresa
          </div>
          <div class="separator-horizontal"></div>
          <mat-form-field>
            <mat-label>Compañía<span class="asterisk">*</span></mat-label>
            <input (keyup)="changeValue($event)" matInput style="text-transform:uppercase;" type="text"
              placeholder="Ejm: TECLOGI" formControlName="companyName">
            <mat-error *ngIf="registerForm.stepOne.stepOneProcessOne.get('companyName').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="registerForm.stepOne.stepOneProcessOne.get('companyName').errors?.notFound">
              Esta compañía no esta registrada
            </mat-error>
          </mat-form-field>
          <mat-checkbox class="mb-4" (change)="onChangeSubcompanyOption($event)" [(ngModel)]="showSubcompanyField"
            [ngModelOptions]="{standalone: true}" *ngIf="showSubcompanyOption">
            <span class="color-secondary-dark font-weight-normal">Soy cliente de {{
              registerForm.stepOne.stepOneProcessOne.get('companyName').value.toUpperCase()}}</span>
          </mat-checkbox>
          <mat-form-field *ngIf="showSubcompanyField">
            <mat-label>Nit cliente<span class="asterisk">*</span></mat-label>
            <input matInput onlyNumbersInt appDocumentType [documentTypeId]="'4'" type="text"
              placeholder="Ejm: 9876543210" [formControl]="subCompanyNit">
            <mat-error *ngIf="subCompanyNit.errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="subCompanyNit.errors?.minlength">
              {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'nit del cliente', minLengthNit) }}
            </mat-error>
          </mat-form-field>
          <div class="button-container mt-2">
            <button mat-raised-button color="primary" class="bg-color-primary"
              (click)="validateStepOne()">Continuar</button>
          </div>
          <mat-card-content>
            <div class="resend">
              ¿Ya tienes cuenta? <a routerLink='/account/login'>Iniciar sesión aquí</a>
            </div>
          </mat-card-content>
        </form>
      </div>
      <!-- Process two -->
      <div class="step-process" *ngIf="registrationSteps.stepOne.processActive === 2">
        <form [formGroup]="registerForm.stepOne.stepOneProcessTwo">
          <input type="hidden" formControlName="companyTypeId">
          <input type="hidden" formControlName="companyTypeName">
          <div class="title-1">
            Tu empresa es:
          </div>

          <div class="separator-horizontal"></div>

          <div class="company-types">

            <div *ngFor="let companyType of companyTypes; let i = index" class="company-type"
              (click)="selecTypeCompany(companyType)">
              <div class="container-icon">
                <div class="company-icon">
                  <img src="/assets/svg/icons/{{companyType.iconName}}.svg" alt="">
                </div>
              </div>
              <div class="data-type">
                <label class="label-field">{{companyType.name}}</label>
                <br>
                <span>{{companyType.description}}</span>
              </div>
            </div>

          </div>
          <mat-card-content>
            <div>
              <label class="label-back-step" (click)="stepBack(1, 1)">
                <figure>
                  <img src="/assets/svg/icons/icon-back.png" alt="">
                </figure>
                Atrás
              </label>
            </div>
          </mat-card-content>
        </form>
      </div>
    </div>
    <!-- Step two -->
    <div class="container-form" *ngIf="registrationSteps.stepActive === 2">
      <form [formGroup]="registerForm.stepTwo">
        <div class="steps-label">
          Paso {{registrationSteps.stepActive}} de {{registrationSteps.stepsTotal}}
        </div>
        <div class="title-1">
          Regístrate
        </div>
        <div class="separator-horizontal"></div>
        <mat-form-field class="mb-2">
          <mat-label>Tu nombre completo<span class="asterisk">*</span></mat-label>
          <input matInput type="text" id="inputPlaceholderUserName" placeholder="Nombres y apellidos"
            formControlName="userName">
          <mat-error *ngIf="registerForm.stepTwo.get('userName').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="mb-2">
          <mat-label>Tipo de documento<span class="asterisk">*</span></mat-label>
          <mat-select (selectionChange)="onChangeDocumentTypeId($event)" formControlName="documentTypeControl">
            <mat-option *ngFor="let documentType of documentTypes" [value]="documentType">
              {{documentType.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="registerForm.stepTwo.get('documentTypeControl').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Tu documento de identidad<span class="asterisk">*</span></mat-label>
          <input matInput type="text" id="inputPlaceholderUserIdentification" onlyNumbersInt
            placeholder="Número del documento" formControlName="document">
          <mat-error *ngIf="registerForm.stepTwo.get('document').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
        <mat-card-content>
          <div class="info-terms-conditions">
            Al registrarte aceptas las <a target="_blank" (click)='goToPrivacyPolicy()'>políticas de privacidad</a> y
            los <a>términos y condiciones de Teclogi</a>
          </div>
        </mat-card-content>
        <mat-card class="message-card" *ngIf="userAlreadyExist">
          <mat-card-content>
            <p>
              El número de cédula ya se encuentra registrado
            </p>
          </mat-card-content>
        </mat-card>
        <mat-card-content *ngIf="userAlreadyExist">
          <div class="go-login">
            <a routerLink='/account/login'>Iniciar sesión aquí</a>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" (click)="validateUser()">Registrarme</button>
        </mat-card-actions>
        <mat-card-content>
          <div>
            <label class="label-back-step" (click)="stepBack(1, 1)">
              <figure>
                <img src="/assets/svg/icons/icon-back.png" alt="">
              </figure>
              Atrás
            </label>
          </div>
        </mat-card-content>
      </form>
    </div>
    <!-- Step three -->
    <div class="container-form" *ngIf="registrationSteps.stepActive === 3">
      <div class="steps-label">
        Paso {{registrationSteps.stepActive}} de {{registrationSteps.stepsTotal}}
      </div>
      <div class="title-1">
        Información de contacto
      </div>
      <div class="separator-horizontal"></div>
      <!-- Process one -->
      <form [formGroup]="registerForm.stepThree.stepThreeProcessOne">
        <!-- Process one -->
        <div class="step-process">
          <mat-form-field>
            <mat-label>Número de celular<span class="asterisk">*</span></mat-label>
            <input matInput type="text" id="inputPlaceholderUserCellPhone" placeholder="Ejm: 300 123 456"
              formControlName="userCellPhone">
            <mat-error *ngIf="registerForm.stepThree.stepThreeProcessOne.get('userCellPhone').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Correo corporativo<span class="asterisk">*</span></mat-label>
            <input (keyup)="changeValueUserCorporateMail()" matInput type="email" [email]="true"
              id="inputPlaceholderUserCorporateMail" placeholder="Ejm: micorreo@correo.com"
              formControlName="userCorporateMail">
            <i *ngIf="registerForm.stepThree.stepThreeProcessOne.get('userCorporateMail').errors?.teclogiUser" matSuffix
              [routerLink]="['/account/login']" matTooltip="Iniciar sesion con mi cuenta de teclogi"
              class="fas fa-sign-in-alt cursor-pointer color-primary"></i>
            <mat-error *ngIf="registerForm.stepThree.stepThreeProcessOne.get('userCorporateMail').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="registerForm.stepThree.stepThreeProcessOne.get('userCorporateMail').errors?.pattern">
              {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
            </mat-error>
            <mat-error *ngIf="registerForm.stepThree.stepThreeProcessOne.get('userCorporateMail').errors?.teclogiUser">
              {{ utils.giveMessageError('TECLOGI_USER') }}
            </mat-error>
          </mat-form-field>
          <mat-card class="message-card" *ngIf="showMessageValidationCodeOne">
            <mat-card-content>
              <p>
                {{message}}
              </p>
            </mat-card-content>
          </mat-card>
          <div class="button-container">
            <button mat-raised-button color="primary" class="bg-color-primary"
              (click)="nextStep(registrationSteps.stepThree.stepNumber)">Siguiente</button>
          </div>
          <mat-card-content>
            <div>
              <label class="label-back-step" (click)="stepBack(2, 1)">
                <figure>
                  <img src="/assets/svg/icons/icon-back.png" alt="">
                </figure>
                Atrás
              </label>
            </div>
          </mat-card-content>
        </div>
      </form>
    </div>
    <!-- Step four -->
    <div class="container-form" *ngIf="registrationSteps.stepActive === 4">
      <form [formGroup]="registerForm.stepFour">
        <div class="steps-label">
          Paso {{registrationSteps.stepActive}} de {{registrationSteps.stepsTotal}}
        </div>
        <div class="title-1">
          Crea tu contraseña
        </div>
        <div class="separator-horizontal"></div>
        <div class="parameters-password">
          <div class="parameter">
            <div class="icon-parameter">

            </div>
            <div class="data-parameter">
              <span>Mínimo 6 letras</span>
            </div>
          </div>
          <div class="parameter">
            <div class="icon-parameter">

            </div>
            <div class="data-parameter">
              <span>Debe tener al menos un número</span>
            </div>
          </div>
          <div class="parameter">
            <div class="icon-parameter">

            </div>
            <div class="data-parameter">
              <span>Debe tener mínimo una letra mayúscula</span>
            </div>
          </div>
        </div>
        <mat-form-field>
          <mat-label>Tu contraseña<span class="asterisk">*</span></mat-label>
          <input matInput [type]="showPassword ? 'text' : 'password'" placeholder="Escribe una contraseña"
            formControlName="userPassword">
          <i matSuffix class="fas color-primary cursor-pointer"
            [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword }"
            (click)="showPassword = !showPassword"></i>
          <mat-error *ngIf="registerForm.stepFour.get('userPassword').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error
            *ngIf="registerForm.stepFour.get('userPassword').errors?.pattern && !registerForm.stepFour.get('userPassword').errors?.minlength">
            {{ utils.giveMessageError('INCORRECT_FORMAT', 'contraseña') }}
          </mat-error>
          <mat-error *ngIf="registerForm.stepFour.get('userPassword').errors?.minlength">
            {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'nombre', 6) }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Confirma tu contraseña<span class="asterisk">*</span></mat-label>
          <input matInput [type]="showConfirmPassword ? 'text' : 'password'"
            placeholder="Vuelve a escribir tu contraseña" formControlName="passwordConfirmation">
          <i matSuffix class="fas color-primary cursor-pointer"
            [ngClass]="{'fa-eye': !showConfirmPassword, 'fa-eye-slash': showConfirmPassword }"
            (click)="showConfirmPassword = !showConfirmPassword"></i>
          <mat-error *ngIf="registerForm.stepFour.get('passwordConfirmation').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
        <div class="separator-horizontal"></div>
        <mat-card class="message-card" *ngIf="showMessageValidationCodeTwo">
          <mat-card-content>
            <p>
              {{message}}
            </p>
          </mat-card-content>
        </mat-card>
        <mat-card-actions>
          <button mat-raised-button color="primary" (click)="nextStep(registrationSteps.stepFour.stepNumber)">Crear
            contraseña</button>
        </mat-card-actions>
        <mat-card-content>
          <div>
            <label class="label-back-step" (click)="stepBack(3, 2)">
              <figure>
                <img src="/assets/svg/icons/icon-back.png" alt="">
              </figure>
              Atrás
            </label>
          </div>
        </mat-card-content>
      </form>
    </div>
  </ng-scrollbar>
</div>