<div class="main_track-guide">
  <div id="imgtruck" class="carousel slide" data-ride="carousel" style="width: 100%">

    <div class="container-fluid p-0">
      <div class="content pb-5">
        <div class="text1">
          Sigue el estado de tu envío
        </div>
        <div class="container">
          <form class="form_container" id="formContact" [formGroup]="formTrackGuide" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col align-self-center" class="track-guide-aplication">
                <div class="row">
                  <div class="col-12 col-md-4 align-center">
                    <label class="label-field"> Escribe el número de guía </label>
                  </div>
                  <div class="col-12 col-md-4 align-center">
                    <mat-form-field>
                      <mat-label>Número de guía<span class="asterisk">*</span></mat-label>
                      <input matInput type="text" formControlName="guideId" placeholder="Ejm: 01234567890">
                      <mat-error *ngIf="formTrackGuide.get('guideId').errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-4 align-center button-container">
                    <button mat-raised-button color="primary" class="button bg-color-primary" type="submit">Buscar
                      guía</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>

    </div>
  </div>
</div>