import { Component, OnInit, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { City } from 'src/app/core/interfaces/city';
import { User } from 'src/app/core/interfaces/user';
import { Dialog } from 'src/app/core/resources/dialog';
import { Titles } from 'src/app/core/resources/titles';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { CatalogService } from 'src/app/core/services/catalog.service';
import { AccountService } from 'src/app/modules/account/account.service';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { DialogComponent } from '../dialog/dialog.component';
import { Patterns } from 'src/app/core/resources/patterns';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
@Component({
  selector: 'app-input-license-plate-trailer',
  templateUrl: './input-license-plate-trailer.html',
  styleUrls: ['./input-license-plate-trailer.scss']
})
export class InputLicensePlateTrailerComponent implements OnInit {

  inputFormControl: FormControl = new FormControl();
  title: string;
  listTrailers: Observable<[any]>;
  loading: boolean;
  trailerSelected: any = null;

  constructor(
    public dialogRef: MatDialogRef<InputLicensePlateTrailerComponent>,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: {
      dialog, title, inputFormControl
    },
    private dialog: Dialog,
    private vehiculeService: VehiclesService,
    public patterns: Patterns,
    public snackBarService: SnackBarService,
    public utils: Utils,
    public cdref: ChangeDetectorRef
  ) {
    if (this.paramsDialog && this.paramsDialog.title) {
      this.title = this.paramsDialog.title;
    }
    if (this.paramsDialog && this.paramsDialog.inputFormControl) {
      this.inputFormControl = this.paramsDialog.inputFormControl;
    }
  }

  ngOnInit() {
    this.inputFormControl.setValidators(Validators.required);
    this.listTrailers = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((data) => {
          try {
            if (data.trim().length > 5) {
              this.loading = true;
              return this.filterTrailer(data.trim() || '').toPromise().then(response => {
                return response;
              }).finally(() => {
                this.loading = false;
              });
            } else {
              return [];
            }
          } catch (e) {
            return [];
          }
        })
      );
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  private filterTrailer(value: string) {

    return this.vehiculeService.getTrailerList('?id=' + value).pipe(
      map(response => {
        if (response) {
          return response;
        } else {
          return [];
        }
      })
    );

  }

  displayTrailer(trailer?: any): any | undefined {
    return trailer ? trailer.id : '';
  }


  onSelectTrailer($event) {
    this.trailerSelected = $event.option.value;
    if (this.trailerSelected === null) {
      this.onConfirmTrailer();
    }
  }

  onConfirmTrailer() {
    this.dialogRef.close({
      state: true,
      data: this.trailerSelected
    });

  }

  confirmSelectedTrailer() {
    this.inputFormControl.markAsTouched();
    if (this.inputFormControl.valid && this.trailerSelected && this.trailerSelected.id) {
      this.dialog.openDialog({
        title: `¿Estás seguro que deseas asignar el trailer con placa ${this.trailerSelected.id} ?`,
      }).then(() => {
        this.onConfirmTrailer();
      }).catch(err => err);
    } else {
      if (this.inputFormControl.invalid) {
        if (this.utils.errorMessagesCustomized(this.inputFormControl, 'trailer')) return;
        else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert')
      } else {
        this.snackBarService.openSnackBar('Por favor selecciona una de las opciones para continuar', undefined, 'alert')
      }
    }
  }


  //getters

  get isDialog(): boolean {
    return !!(this.paramsDialog && this.paramsDialog.dialog)
  }

  ngOnDestroy() {
    this.cdref.detach();
  }
}
