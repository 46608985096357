import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { City } from 'src/app/core/interfaces/city';
import { CityService } from '../city.service';
import { FormControl } from '@angular/forms';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss']
})
export class CityListComponent implements OnInit {

  pageKey = 1;
  pageSize = 25;
  listCities: City[];
  scrollDistance = 1;
  throttle = 300;
  enablePagingScroll = true;
  optionsCity: OptionsAutocomplete = {
    title: 'Buscar ciudad',
    requireFullCity: true
  }
  cityControl: FormControl = new FormControl();
  citySub: Subscription;
  constructor(
    private router: Router,
    private cityService: CityService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
  ) { }

  ngOnInit() {
    this.getCities();
    this.setSubscriptionCity();
  }

  public getCities() {
    this.spinner.show();
    this.cityService.getAllCities(this.pageKey, this.pageSize).toPromise()
      .then((success: City[]) => {
        if (!this.utils.isDefined(success) || success.length < 1) {
          this.enablePagingScroll = false;
        } else {
          if (this.listCities && this.listCities.length) {
            this.listCities = this.listCities.concat(success);
          } else {
            this.listCities = success;
          }
          if (success.length < this.pageSize) {
            this.enablePagingScroll = false;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  setSubscriptionCity() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => {
        if (value && value.id) {
          CityService.citySelected = value;
          this.router.navigate(['administration/cities/detail', value.id]);
        }
      })
  }

  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getCities();
    }
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
  }
}
