import { Directive, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '../interfaces/role';
import { User } from '../interfaces/user';
import { Global } from '../resources/global';
import { AuthService } from '../services/authentication.service';
import * as _ from 'lodash';
import { NavItem } from '../interfaces/navItem';
import { RoleService } from '../services/role.service';
import { FunctionalitiesModuleRole } from '../interfaces/functionalitiesModuleRole';
import { ModuleRole } from '../interfaces/moduleRole';
import { PermissionRole } from '../resources/permission-role';
import { Utils } from '../resources/utils';

@Directive({
    selector: '[permission-display]'
})
export class PermissionDisplayDirective implements OnInit {

    @Input() module: string;
    @Input() functionality: string;
    roleUser: Role;

    constructor(
        private elementRef: ElementRef,
        private roleService: RoleService,
        private authService: AuthService,
        private global: Global,
        private renderer: Renderer2,
        private permissionRole: PermissionRole,
        public utils: Utils
    ) {
    }

    ngOnInit() {


    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngAfterViewInit() {
        this.roleUser = this.roleService.getRoleUser();
        if (!this.utils.isEmpty(this.module) && !this.utils.isEmpty(this.functionality)) {
            try {
                if (!this.permissionRole.hasPermission(this.module, this.functionality)) {
                    this.renderer.setStyle(
                        this.elementRef.nativeElement,
                        'display',
                        'none'
                    );
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

}
