<aside class="wrapper-user-form">
  <div class="col-md-12">
    <div class="row">

      <div class="col-md-12 wrapper-detail" *ngIf="user.information.document">
        <i *ngIf="paramsDialog && paramsDialog.driver" matTooltip="Cerrar"
          class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
        <div class="title-component mr-5">
          <div class="col-md-12 p-0">
            <div class="row">
              <div class="col-md-12 pr-0 pl-3">
                <i class="fas fa-user-edit"></i>
                Ver usuario
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <br>

        <form class="form-container" [formGroup]="formUser" *ngIf="formUser">
          <mat-tab-group>
            <!-- Información general -->
            <mat-tab label="Información general">
              <span class="separator-horizontal"></span>
              <div class="container">
                <div class="col-md-12">
                  <div class="row align-items-baseline">
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>Nombres<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="name">
                        <mat-error *ngIf="formUser.get('name').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>Tipo de documento<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="idDocumentType">
                          <mat-option *ngFor="let type of documenTypes" [value]="type['id']">
                            {{type['name']}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formUser.get('idDocumentType').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>N° de documento<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="document">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>Correo<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="email">
                        <mat-icon matSuffix class="cursor-pointer" color="primary"
                          *ngIf="formUser.get('email').errors && formUser.get('email').errors.pattern"
                          matTooltip="No se permiten caracteres especiales al principio('.test@mail.com'), caracteres especiales seguidos ('te..st@mail.com'), más de un arroba ('te@st@mail.com'), espacios ('te st@mail.com'), caracteres especiales al terminar el dominio ('test.@mail.com'), ni correos de extensión mayor a 100 caracteres">help</mat-icon>
                        <mat-error *ngIf="formUser.get('email').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                        <mat-error *ngIf="formUser.get('email').errors?.pattern">
                          {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
                        </mat-error>
                        <mat-error *ngIf="formUser.get('email').errors?.maxlength">
                          {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'correo', 100) }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field appearance="standard">
                        <mat-label>Número de Celular<span class="asterisk">*</span></mat-label>
                        <input matInput type="text" formControlName="phone">
                        <mat-error *ngIf="formUser.get('phone').errors?.required">
                          {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-4 mt-2">
                      <app-select-company [options]="optionsCompany" [inputFormControl]="company"
                        [validate]="validateCompany">
                      </app-select-company>
                    </div>

                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Documentos" *ngIf="user.role == 'Driver'">
              <span class="separator-horizontal"></span>
              <div class="container">
                <div class="col-md-12">
                  <app-user-documents *ngIf="reactiveFormDriver && reactiveFormDriver.form"
                    [form]="reactiveFormDriver.form" [hideBtn]="false" (emitToParent)="getDetailUserByDocument($event)">
                  </app-user-documents>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <br>
          <mat-card *ngIf="(user.role !== 'Driver' && user.role !== 'Owner')" permission-display
            [module]="permission.administration.module" [functionality]="permission.administration.changeRole"
            class="card-activation-user col-md-4">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center">
                    <span class="text-strong">
                      Asignación de rol
                    </span>
                  </div>
                  <br>
                  <div>
                    Seleccione el rol:
                  </div>
                  <mat-form-field appearance="outline" class="my-3">
                    <mat-label>Rol<span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="roleUserToActive">
                      <mat-option *ngFor="let role of roles" [value]="role.id">
                        {{role.name | roleTranslate}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formUser.get('roleUserToActive').errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button mat-raised-button color="primary" class="primary-secondary" type="button"
                    *ngIf="utils.isEmpty(user.roleId)" (click)="confirmActive()">
                    Asignar rol
                  </button>
                  <button mat-raised-button color="primary" class="primary-secondary" type="button"
                    *ngIf="!utils.isEmpty(user.roleId)" (click)="changeRole()">
                    Cambiar rol
                  </button>
                </div>
              </div>
            </div>
          </mat-card>

          <div class="d-flex flex-wrap align-items-center justify-content-around mt-3">
            <button mat-raised-button color="primary" *ngIf="showButtonInactivateActivateUser"
              class="button-width-auto primary-secondary mb-3 button-small" [ngClass]="{'warn': user.state.active}"
              type="button" (click)="user.state.active ? openDialogDeactivateUser() : confirmActive()">
              {{user.state.active ? 'Desactivar Usuario' : 'Activar Usuario'}}
            </button>
          </div>
        </form>

        <!-- Button back -->
        <div class="row md-form " *ngIf="disabledForm && !paramsDialog">
          <label class="label-back-step col-md-2" (click)="backView()">
            <figure>
              <img src="/assets/svg/icons/icon-back.png" alt="">
            </figure>
            Atrás
          </label>
        </div>

      </div>

    </div>
  </div>

</aside>

<mat-menu #menuOptionsDocuments="matMenu">
  <button mat-menu-item (click)="getURLDocument('ARL')"
    *ngIf="adminUserService.getExtraDocumentByType('ARL', originalUser)">
    ARL
  </button>
  <button mat-menu-item (click)="getURLDocument('EPS')"
    *ngIf="adminUserService.getExtraDocumentByType('EPS', originalUser)">
    EPS
  </button>
</mat-menu>

<!-- Modal Force Activation User-->
<ng-template #modalConfirmForceActivation let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title" *ngIf="user.information.document">
      ¿Estás seguro que deseas <br> forzar la activacíon de <br> {{user.information.name}}?
    </p>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary" (click)="hideModals()">No</button>
      <!--<button mat-raised-button color="primary" (click)="forzeActivation()">Si</button>-->
    </mat-card-actions>
  </div>
</ng-template>

<!-- Modal PDF TRUORA -->
<ng-template #modalPDFTruora let-modal>
  <div class="modal-body wrapper-modal modal-content-PDF" *ngIf="urlPDFTruora">
    <embed [src]="urlPDFTruora" type="application/pdf">
  </div>
</ng-template>