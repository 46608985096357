import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { LegalTermsService } from '../legal-terms.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { compareVersions } from 'compare-versions';
import { TermsAndConditions } from 'src/app/core/interfaces/termsAndConditions';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { TermsAndConditionsMessages } from 'src/app/core/messages/terms-and-conditions-messages.enum';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-form-legal-terms',
  templateUrl: './form-legal-terms.component.html',
  styleUrls: ['./form-legal-terms.component.scss'],
})
export class FormLegalTermsComponent implements OnInit {

  type: string;
  title: string;
  termsAndConditionsForm: FormGroup = new FormGroup({
    version: new FormControl(
      '',
      [
        Validators.required,
        (control: AbstractControl): ValidationErrors | null => {

          if (this.currentTerms)
            for (const t of this.currentTerms) {
              try {
                if (compareVersions(`${control.value}`, `${t.version}`) === 1)
                  continue;
                return { invalidVersion: 'La versión seleccionada es igual o inferior a una versión anterior' };
              } catch (e) {
                return { invalidFormat: 'El formato seleccionado es inválido' }
              }
            }
          return null;
        }
      ]
    ),
    state: new FormControl(
      true,
      Validators.compose(
        [Validators.required]
      )
    ),
    html: new FormControl(
      '',
      Validators.compose(
        [Validators.required]
      )
    )
  });;
  storageRef: any;
  uploadTask: any;
  uploadProgress: any;
  currentTerms: Array<TermsAndConditions> = [];

  constructor(
    private termsAndConditionsService: LegalTermsService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private angularFireStorage: AngularFireStorage,
    private router: Router
  ) {
    this.termsAndConditionsService.getListTerms().subscribe((result: Array<TermsAndConditions>) => this.currentTerms = result.filter(term => term.type === this.type));
  }

  ngOnInit() {
    const urlSegments = {
      'TermsAndConditions': { type: 'TermsAndConditions', title: 'Términos y condiciones' },
      'PrivacyPolicies': { type: 'PrivacyPolicies', title: 'Políticas de privacidad' },
      'PersonalDataPolicy': { type: 'PersonalDataPolicy', title: 'Política de tratamiento de datos personales' }
    };
    
    const url = this.router.url; 
    
    for (const segment in urlSegments) {
      if (url.includes(segment)) {
        this.type = urlSegments[segment].type;
        this.title = urlSegments[segment].title;
      }
    }
    
    
  }

  createTermsAndConditions() {
    this.spinner.show();
    const dateNow = DateManager.dateToString(new Date(), 'YYYY-MM-DD HH:mm ZZ');
    const data = {
      version: this.termsAndConditionsForm.value.version,
      state: this.termsAndConditionsForm.value.state,
      html: this.termsAndConditionsForm.value.html,
      type: this.type
    };

    this.termsAndConditionsService.createTermsAndConditions(data).subscribe(
      (success) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(TermsAndConditionsMessages.DONE);
        this.router.navigate(['administration/legal-terms/list']);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(TermsAndConditionsMessages.GENERAL_ERROR_DEFAULT, undefined, 'error');
      }
    );
  }

  handleFileInput($event) {
    if ($event.length) {
      this.termsAndConditionsForm.patchValue({
        file: $event[0]
      });
    }
  }

  public onSubmit() {
    this.termsAndConditionsForm.markAllAsTouched();
    if (this.termsAndConditionsForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.termsAndConditionsForm.get('version'), 'version')) return;
      else if (this.utils.errorMessagesCustomized(this.termsAndConditionsForm.get('html'), `texto ${this.title}` )) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert')
      return;
    } else {
      this.spinner.show();
      this.createTermsAndConditions();
    }
  }

  onChangeState($event) {
    this.termsAndConditionsForm.patchValue({
      state: JSON.parse($event.value)
    });
  }

}
