import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { FreightListService } from '../cargo-list/cargo-list.service';

@Component({
  selector: 'app-response-generate-journal',
  templateUrl: './response-generate-journal.component.html',
  styleUrls: ['./response-generate-journal.component.scss']
})
export class ResponseGenerateJournalComponent implements OnInit {

  public cargosJournalSuccess: Cargo[] = [];
  public cargosJournalError: Cargo[] = [];
  public error: Error;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: { cargosJournalSuccess: Cargo[], cargosJournalError: Cargo[], error: Error },
    public dialogRef: MatDialogRef<ResponseGenerateJournalComponent>,
    public utils: Utils,
    public cargoListService: FreightListService,
    public dialog: MatDialog,
  ) {
    this.validateDataDialog();
  }

  ngOnInit() {

  }

  validateDataDialog() {
    if (!this.utils.isEmpty(this.dialogParams) && !this.utils.isEmpty(this.dialogParams.cargosJournalSuccess)) {
      this.cargosJournalSuccess = this.dialogParams.cargosJournalSuccess;
    }
    if (!this.utils.isEmpty(this.dialogParams) && !this.utils.isEmpty(this.dialogParams.cargosJournalError)) {
      this.cargosJournalError = this.dialogParams.cargosJournalError;
    }
    if (!this.utils.isEmpty(this.dialogParams) && !this.utils.isEmpty(this.dialogParams.error)) {
      this.error = this.dialogParams.error;
    }
  }

  public onConfirm() {
    this.closeDialog({
      cargosError: this.cargosJournalError
    });
  }

  public onCancel() {
    this.closeDialog();
  }

  private closeDialog(params?) {
    this.dialogRef.close(params);
  }

}
