import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { AngularFireStorage } from '@angular/fire/storage';
import { Utils } from 'src/app/core/resources/utils';
import * as _ from 'lodash';
import { CargoPayment } from 'src/app/core/interfaces/cargoPayment';


@Component({
  selector: 'app-list-cargo-payments',
  templateUrl: './list-cargo-payments.component.html',
  styleUrls: ['./list-cargo-payments.component.scss'],
  providers: [AuthService, Global]
})

export class ListCargoPaymentsComponent implements OnInit {

  @ViewChild('modalElement', { static: true }) modalElement: ElementRef;
  instanceModalOpen: NgbModalRef;
  @Input() payments: CargoPayment[];
  @Input() cargo: Cargo;

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private angularFireStorage: AngularFireStorage,
    public utils: Utils
  ) {

  }

  ngOnInit() {

  }

  downloadFile(fileName: string) {

    const basePath = '/driver/' + this.cargo.driver + '/' + this.cargo.id + '/';

    if (fileName) {
      const pathReference = this.angularFireStorage.ref(basePath + '/' + fileName);
      pathReference.getDownloadURL().subscribe(
        (data) => {
          window.open(data);
        },
        (error) => {

        }
      );
    }
  }

  openModal() {

    this.instanceModalOpen = this.modalService.open(this.modalElement, { centered: true, size: 'lg', ariaLabelledBy: 'modal-basic-title' });
  }

  closeModal() {

    this.instanceModalOpen.close();
  }

}
