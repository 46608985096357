import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/core/shared.module'
import { MonitoringRoutingModule } from './monitoring-routing.module';
import { LoggingComponent } from './logging/logging.component';
import { FingerPrintsComponent } from './finger-prints/finger-prints.component';
import { FingerPrintItemComponent } from './finger-prints/finger-print-item/finger-print-item.component';
import { FingerPrintDetailComponent } from './finger-prints/finger-print-detail/finger-print-detail.component';
import { FingerPrintsFilterComponent } from './finger-prints/finger-prints-filter/finger-prints-filter.component';



@NgModule({
  declarations: [LoggingComponent, FingerPrintsComponent, FingerPrintItemComponent, FingerPrintDetailComponent, FingerPrintsFilterComponent],
  imports: [
    SharedModule.forRoot(),
    MonitoringRoutingModule,
  ],
  entryComponents: [
    FingerPrintDetailComponent
  ]
})
export class MonitoringModule { }
