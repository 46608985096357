import { Inject, Input } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';
import { Utils } from 'src/app/core/resources/utils';

@Component({
  selector: 'app-guide-list-details',
  templateUrl: './guide-list-details.component.html',
  styleUrls: ['./guide-list-details.component.scss']
})
export class GuideListDetailsComponent {

  @Input() guide: TrackGuide[];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<GuideListDetailsComponent>,
    public utils: Utils
  ) {
    if (this.dialogParams && this.dialogParams.guide) {
      this.guide = this.dialogParams.guide;
    }
  }

}
