<div class="container">
  <div id="mainFreightTypeLoad" class="col-md-12 mb-md-0 mb-12">
    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          Selecciona un tipo de carga
        </label>
      </div>
      <!-- Boxs -->
      <app-box-type-carga [options]="types" class="col-md-12 mb-md-0 mb-12"
        [TypeCargaSelectedName]="manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.name.value"
        (emitToParent)="selectTypeLoad($event)"></app-box-type-carga>
      <!-- Button -->
      <div class="col-md-12 mb-md-0 mb-12">
        <div class="row">
          <div class="button-container justify-content-center mt-3 w-100">
            <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
            <button mat-raised-button class="bg-color-primary" (click)="nextStep()">Siguiente</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
