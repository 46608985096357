<aside class="notification-container" infinite-scroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
  <button class="button" *ngFor="let notification of notifications" (click)="selectNotification(notification)" [ngClass]="{'notification-read': notification.state}">
    <div class="notification-item">
      <div class="notification-icon">
        <figure>
          <img src="/assets/img/LogoTeclogi.png">
        </figure>
      </div>
      <div class="notification-info">
        <div class="notification-head">

          <div class="notification-title">
            {{notification.title}}
          </div>
          <div class="notification-date">
            {{notification.date}}
          </div>
        </div>
        <div class="notification-datails">
          {{notification.description}}
        </div>
      </div>
    </div>
  </button>
</aside>
