<aside id="mainValidateTerms" class="min-h-100-margin">
  <div class="container p-0">
    <div class="col-md-12 mb-md-0 mb-12 h-100-margin">
      <div class="row">
        <div class="col-md-12" [ngClass]="{'not-content':!termsActive && response}">
          <div class="row" *ngIf="termsActive">
            <h4 class="text-bold">
              Hemos actualizado los Términos y Condiciones
              {{termsActive?.date ? 'el '+(termsActive.date | dateFormat:'only-date') :
              termsActive?.fingerprint?.date ? 'el '+(termsActive.fingerprint.date |
              dateFormat:'only-date'): ''}}
            </h4>
          </div>
          <div class="row ml-0" *ngIf="termsActive">
            Te invitamos a leer y aceptarlos para poder continuar.
          </div>
          <br>
          <div class="row" *ngIf="termsActive">
            <div class="container-html-terms col-md-12" [innerHtml]="termsActive.html">
            </div>
          </div>

          <div class="container_no_results" *ngIf="!termsActive && response">
            <div class="icon"><i class="fas fa-calendar-times"></i></div>
            <h4 class="text-bold text-center">No hay términos y Condiciones activos</h4>
          </div>
          <br><br>
          <div class="button-container" *ngIf="termsActive">
            <button mat-raised-button color="primary" class="button-width-auto bg-color-primary"
              (click)="confirmTerms()">Aceptar</button>
          </div>
        </div>
      </div>
      <div class="row">
      </div>
    </div>
  </div>
</aside>