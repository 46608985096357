<div class="modal-body wrapper-modal position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <!-- Icon Correct -->
  <p *ngIf="options.icon" class="confirm_icon confirm_icon-success">
    <img src="/assets/svg/icons/icon-correct-purple.svg" alt="">
  </p>
  <p *ngIf="options.iconError" class="confirm_icon confirm_icon-error">
    <img src="/assets/svg/icons/icon-error-purple.svg" alt="">
  </p>

  <!-- Title -->
  <p class="title">
    {{options.title}}
  </p>

  <!-- Description -->
  <p *ngIf="options.description" class="text-center">
    {{options.description}}
  </p>

  <!-- Bttns -->
  <div class="button-container mt-3 mb-3">
    <!-- Yes -->
    <button mat-raised-button class="button-width-auto bg-color-primary" color="primary" (click)="onConfirm(true)"
      *ngIf="options.showYesBtn">
      {{titleYes}}
    </button>
    <!-- No -->
    <button mat-raised-button class="primary-secondary button-width-auto" color="primary" (click)="onConfirm(false)"
      *ngIf="options.showNoBtn">
      {{titleNo}}
    </button>
  </div>
  <div class="button-container">
    <span *ngIf="options.showCancelBtn" class="color-secondary-dark text-strong btn-cancel" (click)="onConfirm()">
      {{titleCancel}}
    </span>
  </div>
</div>