<section class="px-3 px-md-5 py-5 position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="ref.close()"></i>
    <h2 class="title py-4 text-center">Legalizar viáticos</h2>
    <div class="d-flex flex-wrap travel-expense mb-3" *ngFor="let travelExpense of travelExpenses; let i = index;">
        <div class="w-100 d-flex flex-wrap flex-column flex-md-row p-2 bg-gray main-bar">
            <div class="col-md-4">
                <b class="bolded-text">{{travelExpense?.travelExpensesType?.name}}</b>
            </div>
            <div class="state col-md-4 offset-md-4">
                <mat-form-field>
                    <mat-label>Estado</mat-label>
                    <mat-select (selectionChange)="onChangeApprovalExpense(travelExpense, $event)"
                        [value]="travelExpense?.approval" [disabled]="!canChangeApprovedTravelExpenses && isClosed">
                        <mat-option *ngFor="let state of states" [value]="state">{{state | legalizeState}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 pt-2 px-2 pb-4 d-flex flex-wrap">
            <div class="col-md-4 col-lg-2">
                <b class="bolded-text">Desembolsado</b><br>
                <span>{{(travelExpense?.totalPaid || 0) | currency:'COP':'code'}}</span>
            </div>
            <div class="col-md-4 col-lg-2">
                <b class="bolded-text">Sin legalizar</b><br>
                <span>{{calculateUnlegalized(travelExpense) | currency:'COP':'code'}}</span>
            </div>
            <div class="col-md-4 col-lg-2">
                <b class="bolded-text">Rechazado</b><br>
                <span>{{calculateRejected(travelExpense) | currency:'COP':'code'}}</span>
            </div>
            <div class="col-md-4 col-lg-2">
                <b class="bolded-text">
                    Descripción
                </b><br>
                <div *ngIf="!showMoreState[i]">
                    <span>
                        {{travelExpense.travelExpensesDetail.length + (travelExpense.travelExpensesDetail.length > 1
                        ? " registros" : " registro")}}
                    </span>
                </div>
                <div *ngIf="showMoreState[i]">
                    <div class="d-flex"
                        *ngFor="let travelExpensesDetail of travelExpense.travelExpensesDetail; let j = index;">
                        <span class="w-100">{{travelExpensesDetail.description || '--'}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-2">
                <b class="bolded-text">Valor utilizado</b><br>
                <span *ngIf="!showMoreState[i]">
                    {{(travelExpense?.totalSpentValue || 0) | currency:'COP':'code'}}
                </span><br *ngIf="!showMoreState[i]">

                <div *ngIf="showMoreState[i]">
                    <div *ngFor="let travelExpensesDetail of travelExpense.travelExpensesDetail; let j = index;">
                        <span>
                            {{(travelExpensesDetail?.value || 0) | currency:'COP':'code'}}
                        </span><br>
                    </div>
                </div>
                <div (click)="showMore(i)" *ngIf="listPreviewImages[travelExpense.id]?.length">
                    <a role="button" class="bolded-text clickable" *ngIf="!showMoreState[i]">
                        Ver desglose
                        <i class="fas fa-chevron-down"></i>
                    </a>
                    <a role="button" class="bolded-text clickable" *ngIf="showMoreState[i]">
                        Ver menos
                        <i class="fas fa-chevron-up"></i>
                    </a>
                </div>
            </div>

            <div class="col-md-4 col-lg-2"
                *ngIf="listPreviewImages[travelExpense.id]?.length || canUploadImages(travelExpense)">
                <b class="bolded-text" *ngIf="listPreviewImages[travelExpense.id]?.length">
                    Fotos subidas
                </b>
                <app-image-thumbnail-list class="w-100"
                    [imagesWithFingerprint]="listPreviewImages[travelExpense.id]"></app-image-thumbnail-list>

            </div>
            <div class="col-12 text-end">
                <a role="button" class="bolded-text clickable align-self-end" *ngIf="canUploadImages(travelExpense)"
                    (click)="openFormAddTravelExpensesDetail(travelExpense)">
                    Agregar viáticos
                </a>
            </div>

        </div>
    </div>
    <app-general-table-legalization-travel-expenses [cargo]="data.cargo"
        [travelExpenses]="travelExpenses"></app-general-table-legalization-travel-expenses>
    <div class="row mt-3">
        <div class="col-12 text-center">
            <button mat-raised-button class="primary-secondary bg-color-primary" color="primary"
                (click)="closeDialog()">Cerrar</button>
        </div>
    </div>
</section>