<div class="row pr-5">
    <section class="main-container col-12" *ngFor="let vehicle of listLicensePlate; let i = index">
        <div class="row">
            <!-- Title -->
            <div class="col-12">
                <label class="title">
                    Datos del Contenedor vehículo N° {{i + 1}} ( {{vehicle.name}} )
                </label>
            </div>
            <!-- N° de Contenedor -->
            <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>N° de Contenedor</mat-label>
                    <input [value]="vehicle && vehicle.container ? vehicle.container : '' "
                        (keyup)="onKeyUp($event, vehicle,'container')" containerNumber matInput type="text"
                        angularFormsMask="CCCCDDDDDDD">
                </mat-form-field>
                <mat-error class="custom-mat-error"
                    *ngIf="vehicle.container && !patternContainer.test(vehicle.container)">{{
                    utils.giveMessageError('INVALID_CONTAINER_GENERAL') }}</mat-error>
            </div>
            <!-- Precinto -->
            <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>Precinto</mat-label>
                    <input [value]="vehicle && vehicle.seal ? vehicle.seal : '' "
                        (keyup)="onKeyUp($event, vehicle,'seal')" matInput type="text">
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="vehicle.container">
                <mat-form-field appearance="standard">
                    <mat-label>Fecha de vencimiento</mat-label>
                    <!-- <input (keyup)="onKeyUp($event, vehicle,'containerExpirationDate')" matInput [matDatepicker]="picker" placeholder="Seleccione una fecha"> -->
                    <input [value]="vehicle && vehicle.containerExpirationDate ? vehicle.containerExpirationDate : '' "
                        (dateChange)="selectedDate($event, vehicle)" matInput [matDatepicker]="picker"
                        placeholder="Seleccione una fecha" (click)="picker.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </section>
    <div class="col-12 button-container justify-content-center mt-3">
        <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
        <app-request-button></app-request-button>
        <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
    </div>
</div>