import { Component, OnInit, EventEmitter, Input } from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import { Global } from "src/app/core/resources/global";
import { ManualCreationCargoService } from "src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { Fmt } from "src/app/core/messages/fmt";
import { FormMessages } from "src/app/core/messages/form-messages.enum";

@Component({
  selector: "app-type-service",
  templateUrl: "./type-service.component.html",
  styleUrls: ["./type-service.component.scss"],
  providers: [AuthService, Global],
})
export class TypeServiceComponent implements OnInit {
  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
    private router: Router
  ) { }

  ngOnInit() { }

  selectTypeService(serviceSelectedName: string) {
    this.manualCreationCargoService.cargoForm.controls.cargoModel[
      "controls"
    ].serviceType.controls.name.setValue(serviceSelectedName);
  }

  nextStep() {
    if (this.manualCreationCargoService.isValidServiceType()) {

      const serviceTypeName = this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].serviceType.controls.name.value
      if (serviceTypeName === 'Contenedor vacío con carga de compensación' || serviceTypeName === 'Contenedor vacío express') {
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.name.setValue('CONTENEDOR VACIO');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature.setValue('Carga Normal');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNatureId.setValue('1');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.code.setValue('009990');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.type.setValue('No');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.code.setValue('18');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.description.setValue('Unidad sin empaque');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoNature.controls.code.setValue("009990");
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoNature.controls.description.setValue("CONTENEDOR VACIO");
      } else if (serviceTypeName === 'Carga consolidada') {
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.code.setValue('009880');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.type.setValue('No');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.name.setValue('MISCELANEOS CONTENIDOS EN PAQUETES ( PAQUETEO )');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature.setValue('Carga Normal');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNatureId.setValue('1');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.code.setValue('0');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.description.setValue('Paquetes. General Fraccionada en máximo 2 kgs por unidad de empaque');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoNature.controls.code.setValue("1");
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoNature.controls.description.setValue("Carga Normal");
      } else {
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.code.setValue('');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.type.setValue('');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.name.setValue('');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.description.setValue('');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature.setValue('');
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNatureId.setValue('');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.code.setValue('');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.description.setValue('');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoNature.controls.code.setValue("");
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoNature.controls.description.setValue("");
      }
      this.manualCreationCargoService.getComponentActive().setId(2);
      this.manualCreationCargoService.getComponentActive().getStep().setId(1);
      this.router.navigate([this.manualCreationCargoService.steps.vehicleType.url]);
    } else {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'el tipo de servicio'), undefined, 'alert');
    }
  }

  stepBack() {
    const cargoTypeName = this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.name.value;
    const tripTypeName = this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.name.value;
    if (cargoTypeName === 'Contenedor') {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(4);
      this.router.navigate([this.manualCreationCargoService.steps.containerMeasure.url]);
    }
    else if (tripTypeName === 'Última milla') {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(1);
      this.router.navigate([this.manualCreationCargoService.steps.typeTrip.url]);
    }
    else {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(3);
      this.router.navigate([this.manualCreationCargoService.steps.typeCargo.url]);
    }
  }
}
