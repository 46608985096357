import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TravelExpense } from 'src/app/core/interfaces/travel-expense';
import { Utils } from 'src/app/core/resources/utils';

@Component({
  selector: 'app-form-travel-expense-detail',
  templateUrl: './form-travel-expense-detail.component.html',
  styleUrls: ['./form-travel-expense-detail.component.scss']
})
export class FormTravelExpenseDetailComponent implements OnInit {
  name: string;
  constructor(
    private utils: Utils,
    @Inject(MAT_DIALOG_DATA) private data: { travelExpense: TravelExpense }
  ) {
    this.name = this.utils.getNestedValue(data, 'travelExpense.travelExpensesType.name');
  }

  ngOnInit() {
  }

}
