import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Cargo } from '../interfaces/cargo';
import { CargoGenerate } from '../interfaces/cargoGenerate';
import { Field } from '../interfaces/field';
import { DateFormatPipe } from '../pipe/dateFormat.pipe';
import { Endpoints } from '../resources/endpoints';
import { Utils } from '../resources/utils';
import { environment } from './../../../environments/environment';
import { AccountingFiles } from '../interfaces/accountingFiles';
import { CatalogItem } from '../interfaces/catalogItem';

@Injectable({ providedIn: 'root' })
export class CargoGenerateService {

    constructor(
        private http: HttpClient,
        private endpoints: Endpoints,
        private dateFormatPipe: DateFormatPipe,
        private utils: Utils
    ) { }

    public cargoGenerate(value: CargoGenerate, field: Field): Observable<any> {
        let params = new HttpParams();
        let endpoint = environment.urlServerTeclogi + this.endpoints.cargoGenerate + value.typeFile;

        if (value.typeFile !== 'receipts') {
            endpoint = environment.urlServerTeclogi + this.endpoints.generateAccountingFiles;
        }

        params = params.append('sendEmail', 'true');

        if (!this.utils.isEmpty(value.dateFrom)) {
            params = params.append('dateFrom', this.dateFormatPipe.transform(value.dateFrom, 'only-date-tz'));
        }

        if (!this.utils.isEmpty(value.dateUntil)) {
            params = params.append('dateUntil', this.dateFormatPipe.transform(value.dateUntil, 'only-date-tz'));
        }

        if (!this.utils.isEmpty(value.consecutive)) {
            params = params.append('consecutive', value.consecutive);
        }

        if (!this.utils.isEmpty(value.typeFile)) {
            params = params.append('fileType', value.typeFile);
        }

        return this.http.post(
            endpoint,
            field,
            {
                params
            }
        ).pipe(
            timeout(960000)
        );
    }

    public generateJournal(date: string, cargos: number[]): Observable<Cargo[]> {
        let params = new HttpParams();
        params = params.append('date', date);
        return this.http.post<Cargo[]>(
            environment.urlServerTeclogi + this.endpoints.cargoGenerate + 'journal',
            cargos,
            {
                params
            }
        );
    }

    public getAccountingTypes(): Observable<CatalogItem[]> {
        return this.http.get<CatalogItem[]>(
            environment.urlServerTeclogi + this.endpoints.accountingTypes,
        );
    }
}