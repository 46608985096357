<div class="row title-2 text-strong color-secondary-dark">
  <div class="col-md-12">
    <span *ngIf="!title">Placa<span class="asterisk" *ngIf="utils.isRequiredField(inputFormControl)">*</span></span>
    <span *ngIf="title">{{title}}<span class="asterisk" *ngIf="utils.isRequiredField(inputFormControl)">*</span></span>
  </div>
</div>
<div class="d-flex justify-content-start w-100">
  <div class="col-md-3 item flex-fill px-0 px-md-1" *ngFor="let i of amountInputs">
    <input type="text" name="tlInput" minlength="1" class="form-control p-0" #input (keyup)="onKeyUp($event, i)"
      (focus)="onFocus(i)" [disabled]="disabledInput">
  </div>
</div>
<div class="error-license-plate" *ngIf="inputFormControl.errors?.required">
  Campo obligatorio
</div>
<div class="error-license-plate" *ngIf="inputFormControl.errors?.pattern">
  Formato de placa inválido
</div>