import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateManager } from 'src/app/core/managers/date.manager';
import { StatusCode } from 'src/app/core/resources/status-code';
@Component({
  selector: 'app-finger-prints-filter',
  templateUrl: './finger-prints-filter.component.html',
  styleUrls: ['./finger-prints-filter.component.scss']
})
export class FingerPrintsFilterComponent implements OnInit {
  userDocument: FormControl = new FormControl();
  date: FormControl = new FormControl();
  statusCode: FormControl = new FormControl('Todos');
  maxDate: Date = new Date();
  fromNowFilter = DateManager.filter({ from: new Date() });
  @Output() emitFilters: EventEmitter<any> = new EventEmitter();
  constructor(
    public statusCodeList: StatusCode,
  ) { this.statusCodeList.statusCodes.unshift('Todos'); }

  ngOnInit() { }

  applyFilter() {
    const filterControls = [
      { control: this.userDocument, label: 'userDocument' },
      { control: this.date, label: 'date' },
      { control: this.statusCode, label: 'statusCode' },
    ];
    const filtersValues = filterControls
      .filter(({ control }) => control.value !== null && control.value !== '' && control.value !== 'Todos')
      .map(({ control, label }) => ({ label, value: label && label === 'date' ? DateManager.formatDate(control.value, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD ZZ') : control.value }));

    if (filtersValues.length > 0) {
      this.emitFilters.emit({ type: 'apply', filtersValues: filtersValues });
    } else {
      this.emitFilters.emit({ type: 'reset', filtersValues: null })
    }
  }

  cleanFilter() {
    this.userDocument.setValue('');
    this.date.setValue('');
    this.statusCode.setValue('Todos');
    sessionStorage.removeItem("_filterListFingerprints");
    this.emitFilters.emit({ type: 'reset', filtersValues: null })
  }

}
