<!-- Información - Conductor asignado -->
<div class="col-md-12 driver" *ngIf="driver && vehicle">
  <div class="row">
    <!-- Image -->
    <div class="col-md-3 driver__picture__column" *ngIf="driver.information.name ">
      <figure class="driver__picture driver__picture--loaded" *ngIf="driver.profilePicture">
        <img [src]="driver.profilePictureUrl" alt="">
      </figure>

      <figure class="driver__picture" *ngIf="!driver.profilePicture || !driver.information.name">
        <img src="/assets/svg/icons/icon-profile.png" alt="">
      </figure>

      <div class="driver__score__column">
        <div class="text-center driver__score" *ngIf="driver.teclogiScore && driver.state && driver.state.active">
          <img *ngFor="let star of scoreDriver" src="/assets/svg/icons/icon-star-purple.svg"
            [ngStyle]="{'filter':driver?.teclogiScore?.globalScore<=2&&'invert(32%) sepia(80%) saturate(7072%) hue-rotate(353deg) brightness(95%) contrast(133%)'}">
        </div>
        <div class="text-center driver__score"
          *ngIf="(!utils.isDefined(driver.state) || (!driver.state?.active && driver.state?.description === 'Pending')) && !(!utils.isDefined(vehicle.state) || (!vehicle.state.active && vehicle.state.description === 'Pending'))">
          <mat-card-actions>
            <br>
            <button class="button-width-auto" mat-raised-button color="primary" (click)="checkValidationUser()">
              Validar estudio de seguridad
            </button>
          </mat-card-actions>
        </div>

        <div class="text-center driver__score"
          *ngIf="(!utils.isDefined(vehicle.state) || (!vehicle.state.active && vehicle.state.description === 'Pending')) && (driver.state?.description !== 'Pending' || !driver.state || !driver.state.active)">
          <mat-card-actions>
            <button class="button-width-auto" mat-raised-button color="primary" (click)="checkValidateVehicle()">
              Validar Vehiculo
            </button>
          </mat-card-actions>
        </div>
      </div>
    </div>
    <!-- Detail -->
    <div class="col-md-9">
      <div class="row mx-0 px-2">

        <!-- Driver name -->
        <div class="col-md-4">
          <div class="field__card">
            <label class="field__card--label">
              Conductor
            </label>
            <span class="field__card--value" [ngClass]="{'underline': showDriverDetail}" (click)="openDetailDriver()">
              {{driver.information && driver.information.name ? (driver.information.name | capitalizeText):
              cargo.driver}}
            </span>
          </div>
        </div>

        <!-- Phone -->
        <div class="col-md-4" *ngIf="driver.phone && showPhoneDriver">
          <div class="field__card">
            <label class="field__card--label">Celular</label>
            <span class="field__card--value">
              {{driver && driver.phone ? (driver.phone | phoneNumber): '-'}}
            </span>
          </div>
        </div>

        <!-- Address -->
        <div class="col-md-4" *ngIf="driver.address">
          <div class="field__card">
            <label class="field__card--label">Dirección</label>
            <span class="field__card--value">
              {{driver.address}}
            </span>
          </div>
        </div>

        <!-- City -->
        <div class="col-md-4" *ngIf="driver.city">
          <div class="field__card">
            <label class="field__card--label">Ciudad</label>
            <span class="field__card--value">
              {{driver.city}}
            </span>
          </div>
        </div>

        <!-- Score Teclogi -->
        <div class="col-md-4" *ngIf="driver.teclogiScore && driver.state && driver.state.active">
          <div class="field__card">
            <label class="field__card--label">Calificación</label>
            <span class="field__card--value " [ngStyle]="{'color':driver.teclogiScore.globalScore<=2?'red':'black'}">
              {{driver.teclogiScore.globalScore | number}}
            </span>
          </div>
        </div>

        <!-- licenesPlate -->
        <div class="col-md-4" *ngIf="vehicle && !onlyDriver">
          <div class="field__card">
            <label class="field__card--label">
              Placa
            </label>
            <span class="field__card--value" [ngClass]="{'underline': showVehicleDetail}" (click)="openDetailVehicle()">
              {{vehicle.id ? vehicle.id : '-'}}
            </span>
          </div>
        </div>

        <!-- vehicleType -->
        <div class="col-md-4" *ngIf="vehicle && vehicle.vehicleType && !onlyDriver">
          <div class="field__card">
            <label class="field__card--label">Tipo de vehículo</label>
            <span class="field__card--value">
              {{vehicle.vehicleType ? (vehicle.vehicleType.name | capitalizeText) : '-'}}
            </span>
          </div>
        </div>

        <!-- State Driver -->
        <div class="col-md-4" *ngIf="driver && authService.getCompany().companyId === environment.rootNit">
          <div class="field__card">
            <label class="field__card--label">Estado Conductor</label>
            <span class="field__card--value text__color"
              [ngClass]="{'color--error': !driver.state || (utils.isDefined(driver.state.active) && !driver.state.active), 'color--success': driver.state && driver.state.active}">
              {{adminUserService.getUserState(driver)}}
            </span>
          </div>
        </div>

        <div class="col-md-4" *ngIf="onlyDriver && driver.information && driver.information.documentTypeName">
          <div class="field__card">
            <label class="field__card--label">Tipo de identificación</label>
            <span class="field__card--value">
              {{driver.information.documentTypeName}}
            </span>
          </div>
        </div>

        <div class="col-md-4" *ngIf="onlyDriver && driver.information && driver.information.document">
          <div class="field__card">
            <label class="field__card--label">N° identificación</label>
            <span class="field__card--value">
              {{driver.information.document}}
            </span>
          </div>
        </div>

        <div class="col-md-4" *ngIf="onlyDriver && driver.information && driver.operationId">
          <div class="field__card">
            <label class="field__card--label">Operación</label>
            <span class="field__card--value">
              {{driver.operationId | companyName}}
            </span>
          </div>
        </div>

        <!-- State Vehicle -->
        <div class="col-md-4" *ngIf="vehicle && !onlyDriver">
          <div class="field__card">
            <label class="field__card--label">Estado Vehículo</label>
            <span class="field__card--value text__color"
              [ngClass]="{'color--error': !vehicle.state || (utils.isDefined(vehicle.state.active) && !vehicle.state.active), 'color--success': vehicle.state && vehicle.state.active}">
              {{vehiclesService.getVehicleState(vehicle)}}
            </span>
          </div>
        </div>
        <!--Vehicle inspection-->
        <div class="col-md-4" *ngIf="cargo?.vehicleInspection || vehicle?.lastInspection">
          <div class="field__card">
            <label class="field__card--label">
              Inspección a las unidades de transporte de carga
              <i class="fas fa-eye font-secondary-dark" (click)="openInspectionVehicle()"></i>
            </label>
            <span class="field__card--value underline"
              [ngClass]="lastInspection?.approval ? 'color--success': 'color--error'" (click)="openInspectionVehicle()">
              {{lastInspection?.approval ? 'Aprobado' : 'Sin aprobar'}}
            </span>
          </div>
        </div>
        <!--Container inspection-->
        <div class="col-md-4" *ngIf="cargo.trailerInspection || hasContainer">
          <div class="field__card">
            <label class="field__card--label">
              Inspección a las unidades de carga
              <i class="fas fa-eye font-secondary-dark" (click)="openContainerChecklist()"></i>
            </label>
            <span class="field__card--value underline"
              [ngClass]="lastContainerInspection?.approval ? 'color--success': 'color--error'"
              (click)="openContainerChecklist()">
              {{
              lastContainerInspection?.approval ?
              'Aprobado' :
              (!!lastContainerInspection) ?
              'Sin aprobar' :
              'Crear'
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--NEW DESING-->
<div class="main-container">
  <div class="container-content" *ngIf="!vehicle && !owner && !administrator">
    <div class="container-photo">
      <figure class="driver__picture driver__picture--loaded"
        *ngIf="driver?.profilePictureUrl && driver?.information?.name">
        <img [src]="driver?.profilePictureUrl" alt="">
      </figure>
      <figure class="driver__picture driver__picture--loaded"
        *ngIf="!driver?.profilePictureUrl || !driver?.information?.name">
        <img src="/assets/svg/icons/icon-profile.png" alt="">
      </figure>
      <div class="driver__score__column">
        <div class="text-center driver__score" *ngIf="driver?.teclogiScore && driver?.state && driver?.state.active">
          <img *ngFor="let star of scoreDriver" src="/assets/svg/icons/icon-star-purple.svg"
            [ngStyle]="{'filter':driver?.teclogiScore?.globalScore<=2&&'invert(32%) sepia(80%) saturate(7072%) hue-rotate(353deg) brightness(95%) contrast(133%)'}">
        </div>
      </div>
      <div>
        <div class="text-center">
          <!--PHOTO
            <div>
              <label for="upload-photo">
                <i class="fas fa-camera i-picture" for="upload-photo"></i>
              </label>
              <input type="file" name="photo" id="upload-photo" (change)="setInstanceFileStorage($event)" />
            </div>-->
          <span class="field__card--value text__color" style="font-size: 17px;"
            [ngClass]="{'color--error': !driver.state || (utils.isDefined(driver.state.active) && !driver.state.active), 'color--success': driver.state && driver.state.active}">
            {{adminUserService.getUserState(driver)}}
          </span>
        </div>
        <div
          *ngIf="!utils.isDefined(driver?.state) || (!driver?.state?.active && driver?.state?.description === 'Pending')">
          <div class="button-container">
            <button class="button-width-auto bg-color-primary" mat-raised-button color="primary"
              (click)="checkValidationUser()">
              Validar estudio de seguridad
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-info">
      <div class="info-first-column">
        <div>
          <span class="field__card--title">
            {{driver && driver.information && driver.information.name ? (driver.information.name | capitalizeText):
            cargo && cargo.driver ? cargo.driver : '-'}}
          </span>
          <p class="field__card--label">
            Conductor
          </p>
        </div>
        <div class="icons-container">
          <i class="fa fa-exclamation-circle font-secondary-dark i-view-error color--error" aria-hidden="true"
            *ngIf="driver && utils.isDefined(driver.state) && driver.state.description === 'Inactive'"></i>
          <i class="fas fa-eye font-secondary-dark i-view-info" (click)="openDetailDriver()"></i>
          <i class="fas fa-exchange-alt font-secondary-dark i-view-info" (click)="changeDriver()" permission-display
          [module]="permission.administration.module" [functionality]="permission.administration.changeDriverVehicle"
          *ngIf="driver && utils.isDefined(driver.state) && driver.state.description !== 'Pending'"></i>
        </div>
      </div>
      <div class="separator_center"></div>
      <div class="container-text">
        <label class="field__card--label">
          Tipo de identificación
        </label>
        <label class="field__card--value">
          {{driver && driver.information && driver.information.documentTypeName ? driver.information.documentTypeName :
          '-'}}
        </label>
      </div>
      <div class="container-text">
        <label class="field__card--label">
          N° identificación
        </label>
        <label class="field__card--value">
          {{driver && driver.information && driver.information.document ? driver.information.document : '-'}}
        </label>
      </div>
      <div class="container-text">
        <label class="field__card--label">
          Celular
        </label>
        <label class="field__card--value">
          {{driver && driver.phone ? (driver.phone | phoneNumber): '-'}}
        </label>
      </div>
      <div class="container-text" *ngIf="driver && driver.city">
        <label class="field__card--label">
          Ciudad
        </label>
        <label class="field__card--value">
          {{driver.city}}
        </label>
      </div>
      <div class="container-text" *ngIf="driver && driver.address">
        <label class="field__card--label">
          Dirección
        </label>
        <label class="field__card--value">
          {{driver.address}}
        </label>
      </div>
      <div class="container-text" *ngIf="driver && driver.manifestQuantity
      ">
        <label class="field__card--label">
          N° de manifiestos
        </label>
        <label class="field__card--value">
          {{ driver.manifestQuantity }}
        </label>
        <div class="d-flex flex-column">
          <p class="show-more" (click)="openManifests()">Ver manifiestos</p>
        </div>
      </div>
      <div class="container-text" *ngIf="driver && !driver.manifestQuantity">
        <label class="field__card--label">
          Ver manifiestos:
        </label>
        <label class="field__card--value">
          {{ driver && driver.manifests && driver.manifests[0] ? driver.manifests[0].ministryId : '-' }}
        </label>
      </div>
    </div>
  </div>
  <!-- Owner -->
  <div class="container-owner" *ngIf="owner">
    <span class="field__card--title">
      {{owner && owner.information && owner.information.name ?
      (owner.information.name | capitalizeText):
      '-'}}
    </span>
    <p class="field__card--label">
      Propietario
    </p>
    <div class="separator_center"></div>
    <div class="container-text">
      <label class="field__card--label">
        Tipo de identificación
      </label>
      <label class="field__card--value">
        {{owner?.information?.documentTypeName}}
      </label>
    </div>
    <div class="container-text">
      <label class="field__card--label">
        N° identificación
      </label>
      <label class="field__card--value">
        {{owner?.information?.document}}
      </label>
    </div>
    <div class="container-text" *ngIf="owner?.address">
      <label class="field__card--label">
        Dirección
      </label>
      <label class="field__card--value">
        {{owner.address}}
      </label>
    </div>
  </div>
</div>

<!-- Admin -->
<div class="container-owner" *ngIf="administrator">
  <span class="field__card--title">
    {{administrator && administrator.name ? (administrator.name | capitalizeText):
    administrator.name}}
  </span>
  <p class="field__card--label">
    Administrador
  </p>
  <div class="separator_center"></div>
  <div class="container-text">
    <label class="field__card--label">
      Tipo de identificación
    </label>
    <label class="field__card--value">
      {{administrator.documentTypeName}}
    </label>
  </div>
  <div class="container-text">
    <label class="field__card--label">
      N° identificación
    </label>
    <label class="field__card--value">
      {{administrator.document}}
    </label>
  </div>
</div>
