import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { BoxTypeCargaService } from './box-type-carga.service';

@Component({
  selector: 'app-box-type-carga',
  templateUrl: './box-type-carga.component.html',
  styleUrls: ['./box-type-carga.component.scss'],
  providers: [AuthService, Global, BoxTypeCargaService]
})
export class BoxTypeCargaComponent implements OnInit {

  @Input() options: any;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Input() TypeCargaSelectedName: String;

  constructor(
  ) { }

  ngOnInit() {
  }

  public selectTypeLoad(data) {
    this.TypeCargaSelectedName = data.name;
    this.emitToParent.emit(data);
  }

}
