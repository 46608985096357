<div class="email-list">
    <form class="row d-flex align-items-center justify-content-start px-4" (ngSubmit)="onSubmit()" [formGroup]="form"
        *ngIf="hasPermissionToAdd">
        <div class="col-md-4">
            <mat-form-field appearance="standard">
                <mat-label>Correo electrónico<span class="asterisk">*</span></mat-label>
                <input matInput type="text" name="name" formControlName="name" autocomplete="false">
                <mat-error *ngIf="form.get('name').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
                <mat-error *ngIf="form.get('name').errors?.pattern">
                    {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
                </mat-error>
                <mat-error *ngIf="form.get('name').errors?.maxlength">
                    {{ utils.giveMessageError('MAX_LENGTH_EXCEED', 'correo', 100) }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="standard">
                <mat-label>Tipo<span class="asterisk">*</span></mat-label>
                <mat-select formControlName="type">
                    <mat-option *ngFor="let type of types" [value]="type.name">
                        {{type.name | emailTypeName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('type').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <div class="button-container justify-content-start">
                <button type="submit" mat-raised-button class="bg-color-primary">
                    Agregar correo
                </button>
            </div>
        </div>
    </form>
    <hr>
    <div class="m-3" *ngFor="let type of types">
        <mat-expansion-panel [expanded]="true" *ngIf="getActualEmails(type.name).length">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-strong">
                    {{type.name | emailTypeName}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex flex-row flex-wrap">
                <app-email-item *ngFor="let email of getActualEmails(type.name)" [email]="email"></app-email-item>
            </div>
        </mat-expansion-panel>
    </div>
</div>