import { Component, Input } from '@angular/core';
import { Scenario, ScenarioStatus } from 'src/app/core/interfaces/scenario';
import { RoutingService } from '../../routing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScenarioModalComponent } from '../scenario-modal/scenario-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';

@Component({
  selector: 'app-scenario-item',
  templateUrl: './scenario-item.component.html',
  styleUrls: ['./scenario-item.component.scss']
})
export class ScenarioItemComponent {

  @Input() scenario: Scenario;
  permission = Permission;

  constructor(
    private matDialog: MatDialog,
    public permissionRole: PermissionRole,
    private routingService: RoutingService,
    private spinner: NgxSpinnerService,
    public snackBarService: SnackBarService,
  ) { }

  /**
  * @description Gets the status of the scenario to executes the "openFullScenario" method
  */
  getFullScenario() {
    this.spinner.show();
    this.routingService.getScenarioStatus(this.scenario.token).subscribe(
      (success: ScenarioStatus) => {
        this.spinner.hide();
        if (success && success.status)
          this.openFullScenario(success);
        else
          this.snackBarService.openSnackBar(RoutingMessages.ROUTE_ALREADY_USED, undefined, 'error');
      }, () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }

  /**
  * @param {ScenarioStatus} scenario is the scenario's status
  * @description Opens a modal to check the scenario's status
  */
  private openFullScenario(scenario: ScenarioStatus) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { scenario };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(ScenarioModalComponent, dialogConfig);
  }
}
