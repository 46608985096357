export class LocationAddress {

    private lat: number = null;
    private lng: number = null;

    public getLat(): number {
        return this.lat;
    }

    public setLat(lat: number): void {
        this.lat = lat;
    }

    public getLng(): number {
        return this.lng;
    }

    public setLng(lng: number): void {
        this.lng = lng;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
