<div class="container main-container overflow-hidden">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <label class="title-component mt-4">{{data.title}}</label>
    <mat-dialog-content>
        <app-autocomplete-city [options]="optionsCity" [inputFormControl]="cityControl" [validate]="validate">
        </app-autocomplete-city>
    </mat-dialog-content>
    <div class="container-item" *ngIf="data.cityName">
        Ciudad seleccionada: <b>{{data.cityName}}</b>
    </div>
    <mat-card-actions class="button">
        <button mat-raised-button color="primary" (click)="SaveReteica()"
            class="mat-raised-button mat-button-base mat-primary button-width-auto">
            Guardar
        </button>
    </mat-card-actions>
</div>