import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { BoxTypeTripService } from './box-type-regime.service';
import { ModelCargo } from '../../resources/modelCargo';

@Component({
  selector: 'app-box-type-regime',
  templateUrl: './box-type-regime.component.html',
  styleUrls: ['./box-type-regime.component.scss'],
  providers: [AuthService, Global, BoxTypeTripService, ModelCargo]
})
export class BoxTypeRegimeComponent implements OnInit {

  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Input() tripSelectedName: String;
  @Input() regimenTripeSelect: String;

  constructor() { }

  ngOnInit() {
  }

  public selectRegimeType(data) {
    this.regimenTripeSelect = data;
    this.emitToParent.emit(data);
  }

}
