import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { Patterns } from 'src/app/core/resources/patterns';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoBillingPending } from 'src/app/core/interfaces/cargoBillingPending';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';

@Component({
  selector: 'app-cargo-update',
  templateUrl: './cargo-update.component.html',
  styleUrls: ['./cargo-update.component.scss']
})
export class CargoUpdateComponent implements OnInit {
  public numCargo = new FormControl(null, [Validators.required, Validators.pattern(this.patterns.BILL_FORMAT.source)]);
  public idsWithBill: Array<number> = [];
  public idsWithoutBill: Array<number> = [];
  public cargos: Cargo[] = [];
  typeBilling: string = 'bill';
  constructor(
    public dialogRef: MatDialogRef<CargoUpdateComponent>,
    private patterns: Patterns,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    public cargoListService: FreightListService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.idsWithBill = this.data.idsWithBill;
    this.idsWithoutBill = this.data.idsWithoutBill;
    this.cargos = this.data.cargos;
  }

  public closeDialog() {
    if (this.data.title === "Atar número de factura manualmente") {
      if (this.numCargo.invalid || this.numCargo.value === null) {
        this.snackBarService.openSnackBar("Por favor ingrese un formato de factura válido. Por ej: TCRG-1234", undefined, "alert")
      } else {
        this.pendingBillingCargo();
      }
    } else {

      this.dialogRef.close(this.numCargo.value);
    }

  }

  pendingBillingCargo() {
    this.spinner.show();
    let body: CargoBillingPending = {
      cargos: [],
      billingId: this.numCargo.value
    }
    body.cargos = this.cargos.map((cargo) => {
      return cargo.id;
    });
    this.cargoListService.pendingBillingCargo(body, this.typeBilling)
      .subscribe((success: BasicResponse) => {
        this.spinner.hide();
        if (success.message === 'Update') {
          this.snackBarService.openSnackBar('Se ha enviado la información correctamente');
          this.dialogRef.close({ state: true });
        } else if (success.message) {
          this.spinner.hide();
          this.snackBarService.openSnackBar(success.message, undefined, 'error');
          this.dialogRef.close();
        } else {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al realizar la facturación, contacte al Administrador', undefined, 'error');
        }


      },
        error => {
          this.snackBarService.openSnackBar('Ocurrió un error al enviar la información', undefined, 'error');
          this.spinner.hide();
        })
  }

}
