<div class="routing__form">
    <mat-horizontal-stepper labelPosition="bottom" #stepper class="routing__form--steps" [linear]="true">
        <mat-step [completed]="isValidStepOne()">
            <ng-template matStepLabel>Nombre</ng-template>
            <app-routing-basic-form *ngIf="stepper.selectedIndex===0" [form]="form"
                [stepper]="stepper"></app-routing-basic-form>
        </mat-step>
        <mat-step [completed]="isValidStepTwo()">
            <ng-template matStepLabel>Esquema</ng-template>
            <app-routing-scheme-form *ngIf="stepper.selectedIndex===1" [form]="form"
                [stepper]="stepper"></app-routing-scheme-form>
        </mat-step>
        <mat-step [completed]="isValidStepThree()">
            <ng-template matStepLabel>Flota</ng-template>
            <app-routing-fleet-form *ngIf="stepper.selectedIndex===2" [form]="form"
                [stepper]="stepper"></app-routing-fleet-form>
        </mat-step>
        <mat-step [completed]="isValidStepFour()">
            <ng-template matStepLabel>Orden</ng-template>
            <app-routing-address-form *ngIf="stepper.selectedIndex===3" [form]="form"></app-routing-address-form>
        </mat-step>
    </mat-horizontal-stepper>
</div>