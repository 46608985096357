<div class="m-0 ml-1 mx-sm-3 py-4 mb-0 h-100-margin">
  <div class="title-logs-container d-flex flex-row align-items-center mb-3 pl-4 pr-3">
    <h3 class="title text-bold"> <i class="fas fa-desktop mr-3"></i> Solicitudes por usuario</h3>
  </div>
  <div class="mx-3">
    <app-finger-prints-filter (emitFilters)="processFilters($event)"></app-finger-prints-filter>
  </div>
  <div *ngIf="fingerPrintList.length" class="card-container px-4 pb-4">
    <div id="scrollContainer" class="mat-card-container h-100 overflow-auto">
      <mat-card *ngFor="let fingerprint of fingerPrintListSlice" class="mb-2">
        <app-finger-print-item [fingerprint]="fingerprint"></app-finger-print-item>
      </mat-card>
    </div>
    <mat-paginator class="pl-3 pt-2 pl-sm-0 pt-sm-0" #paginator
      [ngClass]="{'display-none':fingerPrintList.length === 0}" [length]="fingerPrintList.length" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)" showFirstLastButtons>
    </mat-paginator>
  </div>
  <div *ngIf="!fingerPrintList.length" class="d-flex align-items-center justify-content-center flex-column h-100">
    <i class="fas fa-calendar-times mb-4"></i>
    <h4 class="text-center text-bold">No hay acciones relacionadas con la selección</h4>
  </div>
</div>