import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from "@angular/material";
import { CargoDetailComponent } from '../cargo-detail/cargo-detail.component';
import { Utils } from 'src/app/core/resources/utils';
import { AssignVehicleCargoComponent } from '../assign-vehicle-cargo/assign-vehicle-cargo.component';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { TripsToCargo } from 'src/app/core/enums/tripsPerVehicle.enum';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';

@Component({
  selector: 'app-avaliable-cargo-list',
  templateUrl: './avaliable-cargo-list.component.html',
  styleUrls: ['./avaliable-cargo-list.component.scss']
})
export class AvaliableCargoListComponent implements OnInit {
  @ViewChild(AssignVehicleCargoComponent, { static: false })
  assignVehicleCargoComponent: AssignVehicleCargoComponent;
  permission = Permission;
  invalidVehicle: boolean = true;
  rotateArrowStates: boolean[] = [];
  constructor(
    public dialogRef: MatDialogRef<AvaliableCargoListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public utils: Utils,
    public matDialog: MatDialog,
    public permissionRole: PermissionRole,
    public snackBarService: SnackBarService
  ) { }

  ngOnInit() { }
  onClickDiv(index: number) {
    this.rotateArrowStates[index] = !this.rotateArrowStates[index];
  }
  openDetailCargo(cargo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.utils.clone(cargo),
    };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(CargoDetailComponent, dialogConfig);
  }

  assignCargoToTurn(cargo) {
    if (cargo) {
      this.invalidVehicle = this.assignVehicleCargoComponent.secureTravelProtocol(this.dialogParams, 'autoAssign', cargo);
      if (this.invalidVehicle === false) {
        this.assignVehicleCargoComponent.getVehicleInfo(this.dialogParams.licensePlate, cargo);
      } else {
        this.snackBarService.openSnackBar("El vehiculo no cuenta con el numero de viajes minimos requeridos para esta carga", undefined, "alert")
      }
    }
  }

  onAssignVehicle($event) {
    if ($event && $event !== 'asignado') {
      this.confirmAssignCargo($event, this.dialogParams.licensePlate);
    } else {
      this.dialogRef.close();
    }
  }

  confirmAssignCargo(cargo, licensePlate: string) {
    const dialogConfig = new MatDialogConfig();
    const title = `¿Está seguro de asignar el vehículo ${licensePlate} a la carga ${cargo.consecutive}?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.assignVehicleCargoComponent.onSubmit(null, cargo, 'turns');
      }
    });
  }

  get tripsToCargo() {
    return TripsToCargo;
  }

  get PermissionAssignVehicle() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignVehicleFullAccess);
  }

  get amountsCargoEnum() {
    return AmountsCargoEnum;
  }

  get assignTurnVehicles(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignTurnVehicles);
  }


}
