import { AdditionalService } from "./additional-service.model";
import { Destination } from "./destination.model";
import { Origin } from "./origin.model";

export class UploadDownload {

    private origin: Origin = new Origin();
    private destination: Destination[] = [];
    private additionalService: AdditionalService;

    public getOrigin(): Origin {
        return this.origin;
    }

    public setOrigin(origin: Origin) {
        this.origin = new Origin();
        this.origin = this.origin.deserialize(origin);
    }

    public getDestination(): Destination[] {
        return this.destination || [new Destination()];
    }

    public setDestination(destination: Destination[]): void {
        destination.map((object) => {
            let destinationModel = new Destination();
            destinationModel = destinationModel.deserialize(object);
            this.destination.push(destinationModel);
        });
    }

    public getAdditionalService(): AdditionalService {
        return this.additionalService || new AdditionalService();
    }

    public setAdditionalService(additionalService: AdditionalService): void {
        this.additionalService = new AdditionalService();
        this.additionalService.deserialize(additionalService);
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
