import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Company } from 'src/app/core/interfaces/company';
import { Patterns } from 'src/app/core/resources/patterns';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Permission } from 'src/app/core/resources/permission';
import { EmailStructure } from 'src/app/core/interfaces/emailStructure';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss']
})
export class EmailFormComponent implements OnInit {
  permission = Permission;
  public emailControl: FormControl = new FormControl();
  public arrayMails: Array<EmailStructure> = [];
  public filteredOptions: Observable<EmailStructure[]>;
  public mailForm: FormGroup;
  private emails: EmailStructure[] = [];

  constructor(
    public patterns: Patterns,
    public snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<EmailFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public utils: Utils,
    private authService: AuthService
  ) {
    if (this.dialogParams.nit) {
      const companyActive: Company[] = this.authService.getCompanies().filter((company) => {
        if (company.companyId === this.dialogParams.nit) {
          return company;
        }
      });
      if (companyActive.length && !this.utils.isEmpty(companyActive[0].emails)) {
        const emails: EmailStructure[] = (companyActive[0].emails || []).filter((email) => {
          return email.type === 'tracking';
        });
        this.emails = this.utils.clone(emails);
      }
    }
    this.setDefaultEmails();
  }

  ngOnInit() {
    this.createMailForm();
    this.filteredOptions = this.emailControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(typeof val === 'object' ? val.name : val))
      );
  }

  filter(val: string): EmailStructure[] {
    return this.emails.filter(email =>
      email.name.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  private setDefaultEmails(): void {
    if (this.emails && this.emails.length) {
      this.emails.forEach((email) => {
        this.arrayMails.push(email);
      });
    }
  }

  createMailForm() {
    this.mailForm = new FormGroup({
      mail: new FormControl(
        '',
        Validators.compose(
          [Validators.required,
          Validators.email,
          ]
        )
      )
    });
  }

  public addEmail(): void {
    this.mailForm.get('mail').setValue(this.emailControl.value);
    const email = this.mailForm && this.mailForm.get('mail') && this.mailForm.get('mail').value ?
      this.patterns.EMAIL.test(this.mailForm.get('mail').value.trim()) : false;
    if (email) {
      this.emailControl.setValue('');
      this.arrayMails.push({
        name: this.mailForm.get('mail').value.trim(),
        type: 'tracking'
      });
      this.mailForm.get('mail').reset();
    } else this.snackBarService.openSnackBar('Por favor ingresar un correo valido', undefined, 'alert');

  }

  public onSubmit() {
    if (this.arrayMails.length == 0) {
      this.snackBarService.openSnackBar('Debe añadir como minimo un correo', undefined, 'alert');
    } else {
      this.close(true);
    }
  }

  public delect(index) {
    this.arrayMails.splice(index, 1);
  }

  public close(status) {
    this.dialogRef.close({
      state: status,
      value: this.arrayMails
    });
  }

  public displayEmail(email?: EmailStructure): string | undefined {
    return email ? email.name : undefined;
  }

}
