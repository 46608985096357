import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../resources/utils';
import { AuthService } from '../services/authentication.service';

@Pipe({
  name: 'gpsType'
})
export class GpsTypePipe implements PipeTransform {

  constructor(
    public utils: Utils,
    private authService: AuthService,
  ) { }

  transform(value: string): string {
    const name: string = this.utils.clone(value);
    const gps = this.authService.getGPS();
    if (gps && gps.length && name) {
      const gpsActive = gps.filter((item) => {
        if (item && item.name) {
          return item.name.toLocaleLowerCase().search(name.toLocaleLowerCase()) >= 0;
        }
      });
      if (gpsActive.length) {
        return gpsActive[0].id;
      }
    }
    return null;
  }

}
