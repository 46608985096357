import { NavItem } from '../interfaces/navItem';
import { CollectionPointZone } from '../interfaces/collectionPointZone';
import { CollectionPointType } from '../interfaces/collectionPointType';
import { Role } from '../interfaces/role';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Catalog } from '../interfaces/catalog';
import { CatalogItem } from '../interfaces/catalogItem';
import { Permission } from './permission';
import { ExcelField } from '../interfaces/fieldsColumnCargo';

export class Global {

  currentYear = new Date().getFullYear();
  permission = Permission;
  _k: string = "T3CL0g12020";
  pathImgLogoteclogi: string = "./assets/img/logo.png";
  pathMarkerOrigin: string = "/assets/img/pin-origin.png";
  pathImgTransparent: string = "/assets/img/transparent.png";
  pathMarkerDestination: string = "/assets/img/pin-destination.png";
  pathMarkerVehicle: string = "/assets/img/pin-vehicle-inverted.png";
  pathMarkerVehicleNoLastLocations: string = "/assets/img/banderita.png";
  pathMarkerLocation: string = "/assets/img/location.png";
  pathClusterMarker: string = "/assets/img/clusters-";
  pathLocationPin: string = "/assets/img/location-pin.png";
  markerTrackingFreightForwarder: string = 'trackingFreightForwarder';
  markerPointRoute: string = 'markerPointRoute';
  markerGeneral: string = 'markerGeneral';

  defaultRoles = {
    webUserPendingActivate: {
      id: 'webUserPendingActivate',
      nit: '',
      name: 'webUserPendingActivate'
    },
    Driver: {
      id: 'Driver',
      nit: '',
      name: 'Driver'
    }
  };

  guideState: string = "GuideState";

  typeFilesExport = {
    xls: {
      format: 'xlsx',
      name: 'XLSX',
      contentType: 'application/octet-stream'
    },
    csv: {
      format: 'csv',
      name: 'csv',
      contentType: 'application/csv'
    },
    txt: {
      format: 'txt',
      name: 'txt',
      contentType: 'application/txt'
    },
    pdf: {
      format: 'pdf',
      name: 'pdf',
      contentType: 'application/pdf'
    }
  };

  typesExportList = {
    collectionPoint: 1,
    freightForwarder: 2,
    reports: 3
  };

  companiesEnabledsToExport = [
    "8305072789" // gl
  ]

  //type fiels
  typeFiles: Array<Object> = [
    {
      idFile: 1,
      title: 'RUT',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 2,
      title: 'Estados financieros',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 3,
      title: 'Circular 018',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 4,
      title: 'Certificado Basc, OEA o Acuerdo de seguridad',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 5,
      title: 'Certificación de calidad y otros',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 6,
      title: 'Referencia bancaria',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 7,
      title: 'Referencia comercial',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    }
  ];

  // Menu options
  public menu: NavItem[] = [
    {
      displayName: 'Administración',
      name: 'administration',
      iconName: 'users-cog',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Crear Usuario',
          route: '/administration/admin-users/create',
          visible: false
        },
        {
          displayName: 'Usuarios',
          name: 'adminUsers',
          route: '/administration/admin-users/list',
          visible: false
        },
        {
          displayName: 'Términos Legales',
          name: 'adminTerms',
          route: '/administration/legal-terms/list',
          visible: false
        },
        {
          displayName: 'Ciudades',
          name: 'adminCities',
          route: '/administration/cities/list',
          visible: false
        },
        {
          displayName: 'Vehículos',
          name: 'adminVehicles',
          route: '/administration/vehicles/list',
          visible: false
        },
        {
          displayName: 'Trailers',
          name: 'adminTrailers',
          route: '/administration/trailers/list',
          visible: false
        },
        {
          displayName: 'Empresas',
          name: 'adminCompanies',
          route: '/administration/companies/list',
          visible: false
        },
        {
          displayName: 'Roles',
          name: 'adminRoles',
          route: '/administration/roles/list',
          visible: false
        },
        {
          displayName: 'Modulos',
          name: 'adminModules',
          route: '/administration/module/list',
          visible: false
        },
        {
          displayName: 'Configuración SaaS',
          name: 'updateCompany',
          route: '/administration/companies/config',
          visible: false
        }
      ]
    },
    {
      displayName: 'Financiero',
      iconName: 'money-bill-wave',
      name: 'payments',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Aprobar Servicios',
          route: '/cargo/list/approvePayments',
          name: 'approvePayments',
          visible: false
        },
        {
          displayName: 'Realizar Pagos',
          route: '/cargo/list/paymentCargo',
          name: 'paymentCargo',
          visible: false
        },
        {
          displayName: 'Facturacion',
          route: '/cargo/list/charges',
          name: 'charges',
          visible: false
        },
        {
          displayName: 'Cartera',
          name: 'adminReceivable',
          route: '/administration/receivable/list',
          visible: false
        },
        {
          displayName: 'Generación de Archívos',
          name: 'generateAccountingFiles',
          route: '/cargo/expenses-income',
          visible: false
        }
      ]
    },
    {
      displayName: 'Carga',
      name: 'cargo',
      iconName: 'truck-loading',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Reportes',
          route: '/reports/main',
          name: 'reports',
          visible: false,
          responsive: false
        },
        {
          displayName: 'Crear carga',
          name: ['createManualCargo', 'createCargoByGuides', 'createMassiveCargo'],
          route: '/cargo/create',
          visible: false
        },
        {
          displayName: 'Enturnamiento',
          name: 'turn',
          route: '/cargo/list/enturnamiento',
          visible: false
        },
        {
          displayName: 'Negociación B2B',
          name: 'cargoRequestNegotiation',
          route: '/cargo/list/cargoRequestNegotiation',
          visible: false
        },
        {
          displayName: 'Solicitudes',
          name: 'cargoRequest',
          route: '/cargo/list/request',
          visible: false
        },
        {
          displayName: 'Tracking Global',
          route: '/cargo/global-tracking',
          name: 'cargosInRoute',
          visible: false,
        },
        {
          displayName: 'Cargas en ruta',
          name: 'cargosInRoute',
          route: '/cargo/list/loadingRoutes',
          visible: false
        },
        {
          displayName: 'Cargas programadas',
          route: '/cargo/list/scheduledLoads',
          name: 'scheduledCargo',
          visible: false
        },
        {
          displayName: 'Cargas en negociación',
          route: '/cargo/list/loadsInNegotiation',
          name: 'cargosInNegotiation',
          visible: false
        },
        {
          displayName: 'Cargas terminadas',
          route: '/cargo/list/loadsFinished',
          name: 'finishedCargos',
          visible: false
        },
        {
          displayName: 'Cargas vencidas',
          route: '/cargo/list/expired',
          name: 'expiredCargo',
          visible: false
        },
        {
          displayName: 'Cargas eliminadas',
          route: '/cargo/list/deleted',
          name: 'deletedCargo',
          visible: false
        },
        {
          displayName: 'Rastrear cargas',
          name: 'searchCargo',
          route: '/cargo/search',
          visible: false
        },
        {
          displayName: 'Solicitudes de servicio',
          name: 'serviceRequests',
          route: '/cargo/list/service-requests',
          visible: false
        },
      ]
    },
    {
      displayName: 'Ruteo',
      iconName: 'fas fa-map-marked-alt',
      name: 'routing',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Crear Ruteo',
          name: 'createRouting',
          route: '/routing/create-route',
          visible: false
        },
        {
          displayName: 'Listado de ruteos',
          name: 'routingList',
          route: '/routing/route-list',
          visible: false
        },
      ]
    },
    {
      displayName: 'Guías',
      name: 'guide',
      iconName: 'file',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Cargar guías',
          name: [this.permission.guide.loadGuides, this.permission.guide.changeStateGuides, this.permission.guide.loadGuides],
          route: '/guide/upload-guides',
          visible: false
        },
        {
          displayName: 'Generar guías',
          name: 'generateGuides',
          route: '/guide/generate-guides',
          visible: false
        },
        {
          displayName: 'Generar guías',
          route: '/guide/seller-generate-guides',
          visible: false
        },
        {
          displayName: 'Rastreo de Guias',
          name: 'trackGuide',
          route: '/guide/track-guide',
          visible: true
        }
      ]
    },
    {
      displayName: 'Puntos',
      name: 'point',
      iconName: 'map-marker-alt',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Crear punto',
          name: 'createPoint',
          route: '/collection-point/create',
          visible: false
        },
        {
          displayName: 'Listado de puntos',
          name: 'listPoints',
          route: '/collection-point/list',
          visible: false
        }
      ]
    },
    {
      displayName: 'Calendario',
      iconName: 'calendar-alt',
      name: 'calendar',
      expanded: false,
      visible: false,
      childrens: [
        {
          displayName: 'CEDI',
          name: 'schedule-cedi',
          route: '/schedule/booking',
          visible: false
        },
      ]
    },
    {
      displayName: 'Rutas',
      name: 'routes',
      iconName: 'route',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        // {
        //   displayName: 'Novedades',
        //   name: [this.permission.guide.loadGuides, this.permission.guide.changeStateGuides, this.permission.guide.loadGuides],
        //   route: '/routes/news',
        //   visible: true
        // },
        {
          displayName: 'Rutas',
          name: this.permission.routes.routePlanning,
          route: '/routes/planning',
          visible: false
        },
        {
          displayName: 'Sitios Autorizados',
          name: this.permission.routes.routeSites,
          route: '/routes/sites',
          visible: false
        },
        // {
        //   displayName: 'Condiciones inseguras',
        //   name: 'unsafeConditions',
        //   route: '/routes/unsafe-conditions',
        //   visible: true
        // }
      ]
    },
    {
      displayName: 'Configuración',
      iconName: 'cog',
      name: 'setting',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        // {
        //     displayName: 'Documentos de la empresa',
        //     name: 'companyDocuments',
        //     route: '/settings/documents',
        //     visible: false
        // },
        {
          displayName: 'Cambiar contraseña',
          route: '/settings/password',
          name: 'changePassword',
          visible: false
        },
        {
          displayName: 'Términos y condiciones',
          route: '/terms',
          name: 'termsAndConditions',
          visible: false
        },
        {
          displayName: 'Politicas de privacidad',
          route: '/privacy-policy',
          name: 'privacyPolicy',
          visible: false
        },
        {
          displayName: 'Politicas tratamiento de datos personales',
          route: '/personal-data-policies',
          name: 'personalDataPolicies',
          visible: false
        },
        {
          displayName: 'HABEAS DATA',
          route: '/habeas-data',
          name: 'habeasData',
          visible: false
        },
        {
          displayName: 'Anexo Manifiesto de Carga',
          route: '/terms-manifest',
          name: 'termsManifest',
          visible: false
        },
        {
          displayName: 'Politicas BASC',
          route: '/basc-policies',
          name: 'bascPolicies',
          visible: false
        },
        {
          displayName: 'Pronto Pago',
          route: '/quick-pay',
          name: 'quickPay',
          visible: false
        }
      ]
    },
    {
      displayName: 'Soporte',
      iconName: 'ticket-alt',
      name: 'help-desk',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Tickets',
          name: 'listTicket',
          route: '/help-desk/list',
          visible: false
        },
      ]
    },
    {
      displayName: 'Monitoring',
      iconName: 'desktop',
      name: 'monitoring',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Logging',
          name: 'monitorlogging',
          route: '/monitoring/logging',
          visible: false
        },
        {
          displayName: 'Solicitudes por usuario',
          name: 'fingerprintslogging',
          route: '/monitoring/fingerprintslogging',
          visible: false
        },
      ]
    },
    {
      displayName: 'Analíticas',
      iconName: 'chart-bar',
      name: 'analytics',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Operaciones',
          name: 'analyticsOperations',
          route: '/analytics/operations',
          visible: false
        },
        {
          displayName: 'Vehículos',
          name: 'vehicleAnalytics',
          route: '/analytics/vehicleAnalytics',
          visible: false
        },
        {
          displayName: 'Rutas frecuentes',
          name: 'frequentRoutes',
          route: '/analytics/frequentRoutes',
          visible: false
        },
        {
          displayName: 'Despachos',
          name: 'dispatch',
          route: '/analytics/dispatch',
          visible: false
        },
        {
          displayName: 'Cargas detenidas',
          name: 'stopedCargos',
          route: '/analytics/stopedCargos',
          visible: false
        },
        {
          displayName: 'Facturación',
          name: 'analyticsBilling',
          route: '/analytics/analyticsBilling',
          visible: false
        },
      ]
    },

  ];

  collectionPointZones: CollectionPointZone[] = [
    {
      name: "Tuta"
    },
    {
      name: "Cogua"
    }
    ,
    {
      name: "Simijaca"
    }
  ];

  collectionPointTypes: CollectionPointType[] = [
    {
      name: "CALIENTE"
    },
    {
      name: "FRIA"
    }
  ];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '100px',
    maxHeight: '500px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese el texto aquí...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  public typesReports = [
    {
      id: 'addressDuration',
      name: 'Duracion en cada dirección'
    },
    {
      id: 'addressQuantity',
      name: 'Cantidad de unidades en cada dirección'
    },
    {
      id: 'routeDuration',
      name: 'Duracion total de cada carga'
    },
    {
      id: 'dayQuantity',
      name: 'Cantidad de unidades por días'
    },
    {
      id: 'durationTakeCargo',
      name: 'Duración en tomar la carga'
    }
  ];

  public documenTypes: CatalogItem[] = [
    {
      id: '1',
      name: 'Cédula de ciudadanía'
    },
    {
      id: '2',
      name: 'Pasaporte'
    },
    {
      id: '3',
      name: 'NIT'
    },
    {
      id: '4',
      name: 'Tarjeta de identidad'
    },
    {
      id: '5',
      name: 'Cédula de extranjería'
    }
  ];

  public fieldsGuide = [
    {
      id: 'guideId',
      title: 'Id. de Guía (*)',
      disabled: false
    },
    {
      id: 'origin',
      title: 'Origen (*)',
      disabled: false
    },
    {
      id: 'clientName',
      title: 'Cliente nombre (*)',
      disabled: false
    },
    {
      id: 'destination',
      title: 'Ciudad Destino (*)',
      disabled: false
    },
    {
      id: 'destinationAddress',
      title: 'Dirección de destino (*)',
      disabled: false
    },
    {
      id: 'product',
      title: 'Producto (*)',
      disabled: false
    },
    {
      id: 'clientId',
      title: 'Id. de Cliente',
      disabled: false
    },
    {
      id: 'clientEmail',
      title: 'Cliente email',
      disabled: false
    },
    {
      id: 'clientCellPhone',
      title: 'Cliente celular',
      disabled: false
    },
    {
      id: 'addressComplement',
      title: 'Complemento dirección',
      disabled: false
    },
    {
      id: 'neighborhood',
      title: 'Barrio',
      disabled: false
    },
    {
      id: 'lat',
      title: 'Latitud',
      disabled: false
    },
    {
      id: 'lng',
      title: 'Longitud',
      disabled: false
    },
    {
      id: 'zone',
      title: 'Zona',
      disabled: false
    },
    {
      id: 'deadLine',
      title: 'Fecha máxima para entregar',
      disabled: false
    },
    {
      id: 'productId',
      title: 'Id. de producto',
      disabled: false
    },
    {
      id: 'cost',
      title: 'Costo',
      disabled: false
    },
    {
      id: 'store',
      title: 'Tienda',
      disabled: false
    },
    {
      id: 'packagesNumber',
      title: 'Número de paquétes',
      disabled: false
    },
  ];

  public fieldsXLSXCargo: ExcelField[] = [
    {
      id: 'numberDocumentSender',
      title: 'Nº Documento remitente*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'tripName',
      title: 'Tipo de viaje*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'vehicleTypeName',
      title: 'Tipo de vehículo*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'bodyWorkTypeName',
      title: 'Tipo de carrocería*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'shippingCostTotal',
      title: 'Flete*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'dateCargo',
      title: 'Fecha de cargue*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'nit',
      title: 'NIT',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoTypeName',
      title: 'Tipo de carga',
      disabled: false,
      mandatory: false
    },
    {
      id: 'serviceTypeName',
      title: 'Tipo de servicio',
      disabled: false,
      mandatory: false
    },
    {
      id: 'productDescription',
      title: 'Descripción del producto',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureQuantity',
      title: 'Cantidad de paquetes',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureTotal',
      title: 'Medida total',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureTotalWeigth',
      title: 'Peso total',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureTotalAmount',
      title: 'Valor total',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureTotalType',
      title: 'Tipo de paquete',
      disabled: false,
      mandatory: false
    },
    {
      id: 'licensePlate',
      title: 'Placa',
      disabled: false,
      mandatory: false
    },
    {
      id: 'rate',
      title: 'Tarifa',
      disabled: false,
      mandatory: false
    },
    {
      id: 'advancePercentage',
      title: '% de anticipo',
      disabled: false,
      mandatory: false
    },
    {
      id: 'observations',
      title: 'Observaciones',
      disabled: false,
      mandatory: false
    },

  ];
  public errorsFieldsExcelDict = {
    cargoTypeName: {
      alias: 'Tipo de carga',
      field: 'cargoModel.cargoType.name'
    },
    serviceTypeName: {
      alias: 'Tipo de servicio',
      field: 'cargoModel.serviceType.name'
    },
    cargoMeasureQuantity: {
      alias: 'Cantidad de paquetes',
      field: 'cargoFeature.cargoMeasure.quantity'
    },
    cargoMeasureTotal: {
      alias: 'Medida total',
      field: 'cargoFeature.cargoMeasure.totalMeasurement'
    },
    cargoMeasureTotalWeigth: {
      alias: 'Peso total',
      field: 'cargoFeature.cargoMeasure.totalWeigth'
    },
    cargoMeasureTotalAmount: {
      alias: 'Valor de la carga',
      field: 'cargoFeature.cargoMeasure.amount'
    },
    cargoMeasureTotalType: {
      alias: 'Unidad',
      field: 'cargoFeature.cargoMeasure.unit'
    },
    advancePercentage: {
      alias: 'Porcentaje de anticipo',
      field: 'shippingCost.advancePercentage'
    },
    rate: {
      alias: 'Tarífa',
      field: 'shippingCost.rate'
    },
    licensePlate: {
      alias: 'Placa',
      field: 'licensePlate'
    },
  }
}
