import { CargoNature } from "./cargo-nature.model";
import { CargoType } from "./cargo-type.model";
import { OperationType } from "./operation-type.model";
import { PackagingType } from "./packaging-type.model";
import { ServiceType } from "./service-type.model";
import { TripType } from "./trip-type.model";


export class CargoModel {

    private tripType?: TripType;
    private cargoType?: CargoType;
    private serviceType?: ServiceType;
    private operationType?: OperationType;
    private cargoNature?: CargoNature;
    private packagingType?: PackagingType;

    public getTripType?(): TripType {
        return this.tripType || new TripType();
    }

    public setTripType?(tripType?: TripType): void {
        this.tripType = new TripType();
        this.tripType.deserialize(tripType);
    }

    public getCargoType?(): CargoType {
        return this.cargoType || new CargoType();
    }

    public setCargoType?(cargoType?: CargoType): void {
        this.cargoType = new CargoType();
        this.cargoType.deserialize(cargoType);
    }

    public getServiceType?(): ServiceType {
        return this.serviceType || new ServiceType();
    }

    public setServiceType?(serviceType?: ServiceType): void {
        this.serviceType = new ServiceType();
        this.serviceType.deserialize(serviceType);
    }

    public getOperationType?(): OperationType {
        return this.operationType || new OperationType();
    }

    public setOperationType?(operationType?: OperationType): void {
        this.operationType = new OperationType();
        this.operationType.deserialize(operationType);
    }

    public getCargoNature?(): CargoNature {
        return this.cargoNature || new CargoNature();
    }

    public setCargoNature?(cargoNature?: CargoNature): void {
        this.cargoNature = new CargoNature();
        this.cargoNature.deserialize(cargoNature);
    }

    public getPackagingType?(): PackagingType {
        return this.packagingType || new PackagingType();
    }

    public setPackagingType?(packagingType?: PackagingType): void {
        this.packagingType = new PackagingType();
        this.packagingType.deserialize(packagingType);
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
