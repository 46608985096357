<!--
<mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper>
  <mat-step [optional]="false"> -->
<ng-template matStepLabel>Datos del Vehiculo</ng-template>
<!--Form-Vehicle-->
<article class="wrapper__article position-relative" [ngClass]="{'m-0': isDialog}">
  <i *ngIf="isDialog" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <div class="wrapper__article__form" [ngClass]="{'py-5': isDialog}">
    <div class="wrapper__article__form--content" *ngIf="!this.onlyGps">
      <mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper class="stepper-form-vehicle">
        <mat-step>
          <ng-template matStepLabel>
            <span>Datos Básicos</span>
          </ng-template>
          <span class="separator-horizontal"></span>
          <!-- License Plate -->
          <div class="row" *ngIf="!vehicle">
            <app-license-plate [inputFormControl]="licensePlateControl"></app-license-plate>
          </div>
          <div class="w-100 mt-2"
            *ngIf="!vehicle && licensePlateControl?.value && licensePlateControl?.errors?.vehicleExistsCompany">
            <div class="col-md-12 alert alert-warning" role="alert">
              El vehículo con placa {{licensePlateControl.value}} ya existe, no podrás crearlo nuevamente.
              Si deseas ir al detalle de este <span class="underline color-secondary-dark"
                (click)="goToVehicle(licensePlateControl.value)">haz click aquí</span>
            </div>
          </div>
          <div class="w-100 mt-2" *ngIf="!vehicle && licensePlateControl?.value && 
            (licensePlateControl?.errors?.vehicleExists || licensePlateControl?.errors?.vehicleExistsNotExclusive)">
            <div class="col-md-12 alert alert-warning" role="alert">
              El vehículo con placa {{licensePlateControl.value}} ya existe, y pertenece a otra compañía.
              <span *ngIf="licensePlateControl?.errors?.vehicleExistsNotExclusive"
                class="underline color-secondary-dark" (click)="showModalVehicleCompany(existingVehicle)">Asociarlo a
                esta compañía</span>
            </div>
          </div>
          <hr>
          <!-- Driver -->
          <app-person-vehicle [form]="driverControls" [title]="'Conductor'"></app-person-vehicle>
          <hr>
          <!-- Company & License Plate -->
          <div class="row">
            <!--
            Se deshabilita la selección de compañía para dar lugar al deslizador de flota propia
            <div class="col-md-6" *ngIf="!dialogParams?.company">
              <app-select-company [options]="optionsCompany" [inputFormControl]="companySelected">
              </app-select-company>
            </div> -->
            <div class="col-md-6 container-flex container-flex_center mb-2">
              <mat-label>El vehículo es de flota propia</mat-label>
              &nbsp;&nbsp;
              <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="isOwnedFleet">
                <mat-button-toggle [value]="true" (change)="onChangeIsOwnedFleet($event)">
                  Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false" (change)="onChangeIsOwnedFleet($event)">
                  No
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <div class="col-md-6 container-flex container-flex_center">
              <mat-label>¿El conductor es el mismo propietario?</mat-label>
              &nbsp;&nbsp;
              <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="isSameOwnerCtrl">
                <mat-button-toggle [value]="true" (change)="onChangeIsSameOwner($event)">
                  Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false" (change)="onChangeIsSameOwner($event)">
                  No
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <!-- Owner -->
          <ng-container *ngIf="!isSameOwnerCtrl.value">
            <br>
            <app-person-vehicle [form]="ownerControls" [title]="'Propietario'"></app-person-vehicle>
          </ng-container>
          <hr>
          <!-- Administrator -->
          <div class="row">
            <div class="col-md-6">
              <mat-label>¿El Vehículo cuenta con Tenedor o Administrador?</mat-label>
              &nbsp;&nbsp;
              <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="hasAdministratorCtrl">
                <mat-button-toggle [value]="true" (change)="onChangeHasAdmin($event)">
                  Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false" (change)="onChangeHasAdmin($event)">
                  No
                </mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error class="mat-error-size"
                *ngIf="!isSameOwnerCtrl.value && ownerControls.value.information.documentTypeId==='3'&& !hasAdministratorCtrl.value">
                Se sugiere añadir administrador cuando el propietario es una empresa
              </mat-error>
            </div>
          </div>
          <!-- Administrator -->
          <ng-container *ngIf="hasAdministratorCtrl.value">
            <span class="separator-horizontal"></span>
            <app-person-vehicle [form]="administratorControls" [title]="'Administrador'"></app-person-vehicle>
          </ng-container>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>
            <span>Documentos</span>
          </ng-template>
          <span class="separator-horizontal"></span>
          <ng-container [formGroup]="this.formVehicle"
            *ngIf="this.form.get('vehicle.frontPicture') && this.form.get('vehicle.rightLateralPicture') && this.form.get('vehicle.leftLateralPicture') && this.form.get('vehicle.propertyCard')">
            <div class="form__vehicle_documents">
              <app-user-documents *ngIf="form && !utils.isEmpty(form.get('vehicle.id').value)" [role]="roles.DRIVER"
                [form]="form.get('driver')" [hideBtn]="true">
              </app-user-documents>
              <app-user-documents *ngIf="form && !utils.isEmpty(form.get('vehicle.id').value)" [role]="roles.OWNER"
                [form]="form.get('owner')" [hideBtn]="true">
              </app-user-documents>
              <app-vehicle-documents *ngIf="form && !utils.isEmpty(form.get('vehicle.id').value)" [form]="form"
                [hideBtn]="true">
              </app-vehicle-documents>
            </div>
          </ng-container>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>
            <span>Satelital</span>
          </ng-template>
          <!--GPS-->
          <ng-container>
            <h4 class="text-strong" *ngIf="configStepper?.title">{{configStepper.title}}</h4>
            <br *ngIf="configStepper?.title">
            <app-gps-vehicle [form]="gpsControls" [title]="'Datos de GPS'"></app-gps-vehicle>
          </ng-container>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>
            <span>Referencias</span>
          </ng-template>
          <app-vehicle-contacts [form]="form" [fromCreation]="true"></app-vehicle-contacts>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="wrapper__article__form--footer">
      <mat-card-actions>
        <button mat-raised-button class="primary-secondary button-width-auto" (click)="goBack(stepper)" type="button"
          *ngIf="stepActive !== 0">
          Atrás
        </button>
        <button mat-raised-button class="button-width-auto" (click)="goForward()" type="button" *ngIf="stepActive < 3">
          Guardar y continuar
        </button>
        <button mat-raised-button color="primary" type="button" class="button-width-auto" (click)="onSubmit()"
          *ngIf="stepActive === 3">
          Guardar
        </button>
      </mat-card-actions>
    </div>
  </div>
</article>

<!--
    <mat-card-actions *ngIf="this.vehicle" class="mat-card-actions-vehcle text-center">
      <button mat-raised-button color="primary" type="button" class="button-width-auto" (click)="this.onUpdate()">
        Actualizar
      </button>
    </mat-card-actions>
  </mat-step>
  <mat-step [optional]="true">
    <ng-template matStepLabel>Cuenta de Anticipos</ng-template>
    <app-bank-account (emitToParent)="createBankAcountAdvance(stepper,$event)" title='Advance' [id]='this.licensePlate'
      [vehicle]='this.vehicle'>
    </app-bank-account>
    <div>
      <mat-card-actions class="text-right">
        <button mat-raised-button color="primary" type="button" class="button-width-auto" matStepperPrevious>
          Volver
        </button>
        <button mat-raised-button color="primary" type="button" class="warn button-width-auto" matStepperNext>
          Omitir
        </button>
      </mat-card-actions>
    </div>
  </mat-step>
  <mat-step [optional]="true">
    <ng-template matStepLabel>Cuenta de Saldos</ng-template>
    <app-bank-account title='Balance' [id]='this.licensePlate' [vehicle]='this.vehicle'
      (emitToParent)="createBankAcountBalance(stepper,$event)">
    </app-bank-account>
    <div>
      <mat-card-actions class="text-right">
        <button mat-raised-button color="primary" type="button" class="button-width-auto" matStepperPrevious>
          Volver
        </button>
        <button mat-raised-button color="primary" type="button" class="warn button-width-auto"
          (click)='this.goToDetail()'>
          Omitir
        </button>
      </mat-card-actions>
    </div>

  </mat-step>
</mat-horizontal-stepper> -->