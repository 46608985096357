<div class="container">
  <div id="mainFreightItinerary" class="col-md-12 mb-md-0 mb-12 px-0">
    <!-- Date upload -->
    <div class="row container-date" *ngIf="viewActive === 1">
      <div class="col-md-12 mb-md-0 mb-12">
        <!-- Document sender -->
        <div class="row">
          <mat-form-field>
            <mat-label>Documento remitente<span class="asterisk">*</span></mat-label>
            <input type="text" matInput name="numberDocumentSenderForm" placeholder="Escribe el número del documento"
              [formControl]="manualCreationCargoService.formNumberDocumentSender">
            <i matSuffix class="fas fa-info-circle color-secondary-dark"
              matTooltip="Es el número del documento interno de identificación de transporte."
              matTooltipClass="tooltip__alert" matTooltipPosition="right"></i>
            <mat-error *ngIf="manualCreationCargoService.formNumberDocumentSender.errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-hint
              *ngIf="manualCreationCargoService.formNumberDocumentSender.value ==='' && manualCreationCargoService.getOwnerSelected().integration"
              class="text-bold color-secondary-dark">
              Recuerda que esta empresa tiene INTEGRACIÓN, por esto es importante poner en este campo el ID generado por
              la empresa correspondiente a este carga
            </mat-hint>
          </mat-form-field>
        </div>
        <!-- Datepicker -->
        <div class="row wrapper-date-cargo">
          <div class="container-label-datepicker">
            <label class="label-field">Selecciona la fecha de cargue</label>
          </div>
          <ngb-datepicker [(ngModel)]="dateSelected" [startDate]="dateSelected" class="datepicker-fixed"
            [minDate]="minDate" [displayMonths]="displayMonths" [showWeekNumbers]="showWeekNumbers"
            [outsideDays]="'hidden'" (select)="onDateSelect($event, 'upload')">
          </ngb-datepicker>
        </div>
      </div>
      <!-- Button -->
      <div class="col-md-12 mb-md-0 mb-12">
        <div class="row">
          <div class="button-container w-100">
            <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
            <button mat-raised-button class="bg-color-primary" (click)="nextStep()">Siguiente</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Date download -->
    <div class="row container-date" *ngIf="viewActive === 2">
      <div class="col-md-12 mb-md-0 mb-12">
        <div class="row wrapper-date-cargo">
          <div class="col-md-12 mb-md-0 mb-12">
            <div class="container-label-datepicker">
              <label class="label-field">Selecciona la fecha de descargue</label>
            </div>
            <ng-scrollbar #scrollable track="horizontal" pointerEventsMethod="scrollbar">
              <div class="container-itinerary-information">
                <div class="information-wrapper" *ngFor=" let destination of destinations; let indexDestination=index;">
                  <div class="value-itinerary">
                    {{destination['controls'].name.value}}
                  </div>
                  <mat-form-field class="cursor-pointer pt-2 pb-3 px-3 date-cards"
                    (click)="focusDownloadInput(indexDestination)"
                    [ngClass]="{'selected-date': indexDestination === indexDownloadActive}">
                    <mat-label>Destino {{ indexDestination + 1}}<span class="asterisk">*</span></mat-label>
                    <input *ngIf="!destination['controls'].downloadDate.value" readonly matInput type="text"
                      placeholder="Selecciona una fecha" autocomplete="false"
                      [formControl]="destination['controls'].downloadDate" class="cursor-pointer">
                    <input *ngIf="destination['controls'].downloadDate.value" readonly matInput type="text"
                      placeholder="Selecciona una fecha" autocomplete="false"
                      [value]="(formatDate(destination['controls'].downloadDate.value))" class="cursor-pointer">
                    <mat-error *ngIf="destination['controls'].downloadDate.errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                  </mat-form-field>

                </div>
              </div>
            </ng-scrollbar>
          </div>
          <!-- Datepicker -->
          <div class="col-md-12 mb-md-0 mb-12">
            <ngb-datepicker [(ngModel)]="dateSelected" [startDate]="dateSelected" class="datepicker-fixed"
              [minDate]="minDateDownload" [displayMonths]="displayMonths" [showWeekNumbers]="showWeekNumbers"
              [outsideDays]="'hidden'" (select)="onDateSelect($event, 'download')">
            </ngb-datepicker>
          </div>
        </div>
      </div>
      <!-- Button -->
      <div class="col-md-12 mb-md-0 mb-12">
        <div class="row">
          <div class="button-container w-100 pb-4">
            <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBackSameView()">Atrás</button>
            <button mat-raised-button class="bg-color-primary" (click)="nextStep()">Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
