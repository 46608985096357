import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home-landing',
    templateUrl: './home-landing.component.html',
    styleUrls: ['./home-landing.component.scss'],
})

export class HomeLandingComponent implements OnInit {
    public items = [
        {
            title: "T-Long Haul/ T-pool",
            body: "Aumenta tu eficiencia en comercio exterior y en tus entregas/distribución nacional. Rentable y flexible. Paga por lo que usas.",
            image: "/assets/img/Vehicle.png",
        },
        {
            title: "T-Warehouse",
            body: "Paga por lo que usas. Dark Store, almacenamiento temporal y permanente, plataformas cross docking, ITR.",
            image: "/assets/img/Stock.png",
        },
        {
            title: "T-Last Mile/ T-Home",
            body: "A tiempo y en el lugar correcto. Rutas planificadas y la mejor experiencia de entrega. Todos tus clientes felices con las entregas de sus compras en casa.",
            image: "/assets/img/RouteMap.png",
        },
        {
            title: "T-E Commerce / T-Fulfillment",
            body: "Omnicanalidad y Fulfillment Aumenta tus ventas al conectarte con tus clientes en todos los canales.",
            image: "/assets/img/Red.png",
        },
    ]
    public images = [
        { path: '/assets/images/logo-companies/bavaria.png' },
        { path: '/assets/images/logo-companies/exito.png' },
        { path: '/assets/images/logo-companies/frubana.png' },
        { path: '/assets/images/logo-companies/deprisa.png' },
        { path: '/assets/images/logo-companies/falabella.png' },
        { path: '/assets/images/logo-companies/tugo.png' },
    ]

    constructor(
        private router: Router,
    ) {
    }

    ngOnInit() {

    }

    navigateTo(url: string) {
        this.router.navigate([url]);
    }
}