import { Component } from '@angular/core';
import { Permission } from 'src/app/core/resources/permission';

@Component({
  selector: 'app-legal-terms',
  templateUrl: './legal-terms.component.html',
  styleUrls: ['./legal-terms.component.scss']
})
export class LegalTermsComponent {
  permission = Permission;
  activeTab: number = 0;

  onSelectTab($event: number) {
    this.activeTab = $event;
  }

}
