import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';

import { Global } from 'src/app/core/resources/global';
import { GenerateGuideService } from './generate-guide.service';
import { Guide } from 'src/app/core/interfaces/guide';
import * as _ from 'lodash';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { UploadGuideService } from '../upload-guide/upload-guide.service';
import { ListGenerateGuideService } from '../list-generate-guide/list-generate-guide.service';
import { FormControl } from '@angular/forms';
declare var google: any;
import { environment } from 'src/environments/environment';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-generate-guide',
  templateUrl: './generate-guide.component.html',
  styleUrls: ['./generate-guide.component.scss'],
  providers: [AuthService, Global]
})
export class GenerateGuideComponent implements OnInit {

  typeGuideActive: string = 'guidesInCollection';
  listGuides: Array<Guide>;
  searchGuideText: string;
  withOutLabel: boolean;
  companyFormControl: FormControl = new FormControl();
  companySub: Subscription;
  stateFormControl: FormControl = new FormControl();
  stateLoading = false;
  listStates = [];
  pageKey = 1;
  pageSize = 10;
  scrollDistance = 1;
  enablePagingScroll = true;
  throttle = 300;
  itemsSelecteds = [];
  startDate: Date;
  endDate: Date;
  optionAllCompanies: boolean;
  optionsCompany = {
    title: 'Compañía'
  };
  constructor(
    public generateGuideService: GenerateGuideService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private listGenerateGuideService: ListGenerateGuideService,
    private uploadGuideService: UploadGuideService,
    public utils: Utils,
    public authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.optionAllCompanies = this.authService.getUserSession().clientCompanyId === environment.rootNit;
    this.optionsCompany['optionAll'] = this.optionAllCompanies;
    this.applyFilter();
    this.companySub = this.companyFormControl.valueChanges.subscribe((value) => {
      this.stateFormControl.setValue(null);
      this.getGuideStates(value.nit);
    });
  }

  onSelectItem($event) {
    this.itemsSelecteds = $event;
  }

  getGuideStates(nit: string) {
    if (!this.utils.isEmpty(nit)) {
      this.stateLoading = true;
      this.uploadGuideService.getGuideStates(nit).toPromise()
        .then((data) => {
          this.listStates = data;
          this.listStates.unshift({
            id: -1,
            name: 'Todas'
          });
        })
        .catch(() => {
          this.listStates = [];
        })
        .finally(() => {
          this.stateLoading = false;
        });
    } else {
      this.listStates = [];
    }
  }

  cleanFilter() {
    this.stateFormControl.setValue('')
    this.searchGuideText = '';
    this.startDate = null;
    this.endDate = null;
    this.withOutLabel = null;
    this.resetValuesPagingScroll();
    this.getGuides();
  }

  applyFilter() {
    this.resetValuesPagingScroll();
    this.getGuides();
  }

  getGuides() {
    let loadDate, endDate;
    if (!this.utils.isEmpty(this.startDate)) {
      loadDate = DateManager.dateToString(this.startDate, 'YYYY-MM-DD ZZ');
    }

    if (!this.utils.isEmpty(this.endDate)) {
      endDate = DateManager.dateToString(this.endDate, 'YYYY-MM-DD ZZ');
    }
    this.spinner.show();
    this.generateGuideService.getGuidesByState(this.stateFormControl.value, this.searchGuideText, this.withOutLabel, this.pageKey, this.pageSize, (this.companyFormControl.value ? this.companyFormControl.value.nit : null), loadDate, endDate).subscribe(
      (data) => {
        if (this.utils.isDefined(data)) {
          if (data && data.length < 1) {
            this.enablePagingScroll = false;
          } else {
            if (this.listGuides && this.listGuides.length) {
              this.listGuides = this.listGuides.concat(data);
            } else {
              this.listGuides = data;
            }
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.resetValuesPagingScroll();
      }
    );
  }

  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getGuides();
    }
  }

  resetValuesPagingScroll() {
    this.enablePagingScroll = true;
    this.listGuides = [];
    this.itemsSelecteds = [];
    this.pageKey = 1;
  }

  generateAllGuides() {
    let thisCass = this;
    this.spinner.show();
    this.listGenerateGuideService.getAllGuidesPDF(this.stateFormControl.value, this.withOutLabel).subscribe(
      (response) => {
        var newBlob = new Blob([response], { type: "application/pdf" });
        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = "Guias_" + this.stateFormControl.value + ".pdf";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
          thisCass.spinner.hide();
        }, 100);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Ocurrió un error generando el archivo", undefined, 'error');
      }
    );
  }


  generateMultiplesGuides(isGuide?: boolean) {
    let guidesChecked: Array<any> = this.listGenerateGuideService.getGuidesSelected(this.itemsSelecteds, 'guideId');
    if (guidesChecked.length) {
      this.spinner.show();
      this.listGenerateGuideService.getMultipleGuidesPDF(guidesChecked, isGuide).subscribe(
        (response) => {
          this.snackBarService.openSnackBar('Se descargará el archivo en breve');
          // tslint:disable-next-line: max-line-length
          this.utils.downloadFile(response.body, 'Pago-' + ('Guias_' + this.stateFormControl.value));
          this.resetValuesPagingScroll();
          this.getGuides();
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Ocurrió un error generando el archivo", undefined, 'error');
        }
      );
    } else {
      this.snackBarService.openSnackBar("Debe seleccionar por lo menos una guía", undefined, 'alert');
    }
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
  }
}


