<section class="px-0 px-sm-3 pt-3 container">
    <main class="row">
        <div class="col-md-12 subcontainer px-0 px-sm-2">
            <div class="w-100 px-2">
                <div class="row">
                    <div class="col-md-8 title-component ml-3 mr-2 mx-sm-0">
                        <i class="fas fa-project-diagram"></i>
                        Planeación de rutas
                    </div>
                    <div class="col-md-4 text-right">
                        <button (click)="goToForm()" type="button" mat-raised-button color="accent"
                            matTooltip="Agregar una nueva ruta">
                            <i class="fas fa-plus"></i>
                            Crear Ruta
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="row">
                            <div class="col-md-3">
                                <app-autocomplete-city [options]="originOptions" [inputFormControl]="originControl">
                                </app-autocomplete-city>
                            </div>
                            <div class="col-md-3">
                                <app-autocomplete-city [options]="destinationOptions"
                                    [inputFormControl]="destinationControl">
                                </app-autocomplete-city>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-container">
                            <table class="w-100">
                                <thead>
                                    <th>
                                        Origen
                                        <a (click)="sort('origin')" role="button">
                                            <i class="fas" [ngClass]="{
                                                'fa-sort': sort_order === 0 || sort_key !== 'origin',
                                                'fa-sort-down': sort_order === 1 && sort_key === 'origin',
                                                'fa-sort-up': sort_order === 2 && sort_key === 'origin'
                                            }"></i>
                                        </a>
                                    </th>
                                    <th>
                                        Destino
                                        <a (click)="sort('destination')" role="button">
                                            <i class="fas" [ngClass]="{
                                                'fa-sort': sort_order === 0 || sort_key !== 'destination',
                                                'fa-sort-down': sort_order === 1 && sort_key === 'destination',
                                                'fa-sort-up': sort_order === 2 && sort_key === 'destination'
                                            }"></i>
                                        </a>
                                    </th>
                                    <th class="d-none d-sm-table-cell">
                                        Kms
                                        <a (click)="sort('distance')" role="button">
                                            <i class="fas" [ngClass]="{
                                                'fa-sort': sort_order === 0 || sort_key !== 'distance',
                                                'fa-sort-up': sort_order === 1 && sort_key === 'distance',
                                                'fa-sort-down': sort_order === 2 && sort_key === 'distance'
                                            }"></i>
                                        </a>
                                    </th>
                                    <th class="d-none d-md-table-cell">Puntos autorizados</th>
                                    <th class="d-none d-lg-table-cell">Novedades</th>
                                    <th class="d-none d-sm-table-cell">Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let route of routes">
                                        <td> {{route.origin.name}} </td>
                                        <td> {{route.destination.name}} </td>
                                        <td class="d-none d-sm-table-cell"> {{
                                            (
                                            route
                                            .itineraries
                                            [route.currentItinerary]
                                            .estimatedDistance
                                            / 1000
                                            ) | number : '1.2-2'}}
                                            kms</td>
                                        <td class="d-none d-md-table-cell">
                                            {{
                                            route
                                            ?.itineraries
                                            [route.currentItinerary]
                                            ?.authorized_stops
                                            ?.length || 'Sin'
                                            }} puntos
                                        </td>
                                        <td class="d-none d-lg-table-cell"> Sin novedades</td>
                                        <td>
                                            <a (click)="goToForm(route.id)" role="button" matTooltip="Editar"
                                                class="table-btn">
                                                <img src="../../../../assets/svg/icons/tl_ico__edit.svg" alt="">
                                            </a>

                                            <!-- <a (click)="remove(route.id)" role="button" matTooltip="Eliminar"
                                                class="table-btn">
                                                <img src="../../../../assets/svg/icons/tl_ico__trash.svg" alt="">
                                            </a> -->
                                        </td>
                                    </tr>
                                    <tr *ngIf="routes.length === 0">
                                        <td colspan="8" class="text-center">
                                            <hr>
                                            <br>
                                            <br>
                                            <img src="../../../../assets/svg/icons/tl_ico__caja_vacia.svg">
                                            <br>
                                            <p style="font-weight: bold;">
                                                {{
                                                !!filters.length ?
                                                "No hay resultados para ésta búsqueda" :
                                                "Aún no hay rutas creadas"
                                                }}
                                            </p>
                                            <br>
                                            <br>
                                            <br>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</section>