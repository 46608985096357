import { Component, Inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdditionalCostDeleted } from 'src/app/core/interfaces/additionalCostDeleted';
import { PermissionRole } from 'src/app/core/resources/permission-role';


@Component({
  selector: 'app-additional-costs-history',
  templateUrl: './additional-costs-history.component.html',
  styleUrls: ['./additional-costs-history.component.scss'],
  providers: [AuthService, Global]
})

export class AdditionalCostsHistoryComponent {
  permission = Permission;
  additionalCostsDeleted: Array<AdditionalCostDeleted> = [];

  constructor(
    private permissionRole: PermissionRole,
    public utils: Utils,
    @Inject(MAT_DIALOG_DATA) public dialogParams: { list: Array<AdditionalCostDeleted>  },
    public dialogRef: MatDialogRef<AdditionalCostsHistoryComponent>,
    public dialog: MatDialog,
  ) {
    if (this.dialogParams && this.dialogParams.list) {
      this.additionalCostsDeleted = this.dialogParams.list;
    } 
  }

  get showRateCargo() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showRateCargo);
  }

  get showCostCargo() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showCostCargo);
  }

  get title() {
    return 'Historico de Servicios Adicionales Eliminados';
  }

}
