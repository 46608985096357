<div class="modal-body wrapper-modal">
    <i matTooltip="Cerrar" class="fas fa-arrow-left fa-2xl close-icon-actions" (click)="goBack()"></i>

    <div class="title">
        Establezca las fechas de monitoreo del vehículo {{data.licensePlate}}
    </div>

    <p>El vehículo puede ser monitoreado por un máximo de 5 días.</p>

    <form [formGroup]="form" (submit)="confirm()">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Fecha de inicio<span class="asterisk">*</span></mat-label>
                    <input readonly type="text" id="setStateGuidesFormDate" matInput [matDatepicker]="startDate"
                        (click)="startDate.open()" formControlName="start" class="cursor-pointer" [min]="minStartDate"
                        [max]="maxStartDate" (dateChange)="onChangeStartDate($event)" [readonly]="!!data?.startDate">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Fecha de finalización<span class="asterisk">*</span></mat-label>
                    <input readonly type="text" id="setStateGuidesFormDate" matInput [matDatepicker]="endDate"
                        (click)="endDate.open()" formControlName="end" class="cursor-pointer" [min]="minEndDate"
                        [max]="maxEndDate" (dateChange)="onChangeEndDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-12 text-center">
                <button mat-raised-button color="primary" class="bg-color-primary" type="submit">Confirmar</button>
            </div>
        </div>
    </form>
</div>