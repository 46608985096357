<form class="routing__form--content py-3 px-2 px-sm-5" [formGroup]="form" (submit)="onSubmit()">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="routing__form--header">
        <h2 class="text-bold ">Crear capacidad</h2>
    </div>

    <div class="routing__form--body">
        <mat-form-field appearance="outline" class="plate-field">
            <mat-label>Nombre<span class="asterisk">*</span></mat-label>
            <input type="text" matInput formControlName="name">
            <mat-error *ngIf="form.get('name').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="plate-field">
            <mat-label>Medida<span class="asterisk">*</span></mat-label>
            <input type="text" matInput formControlName="measure">
            <mat-error *ngIf="form.get('measure').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="routing__form--footer button-container">
        <button mat-raised-button type="button" class="primary-secondary button-width-auto" color="primary"
            (click)="dialogRef.close()">
            Cancelar
        </button>
        <button mat-raised-button color="primary" type="submit"
            [ngClass]="{'bg-color-primary':isValidForm, 'disabled-btn':!isValidForm}">
            Crear
        </button>
    </div>

</form>