<section class="modal-body wrapper-modal position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon" (click)="dialogRef.close()"></i>
    <mat-tab-group>
        <mat-tab label="Roles y permisos">
            <app-master-testig-change-role></app-master-testig-change-role>
        </mat-tab>
        <!-- <mat-tab label="Data">
            [SECCION NO IMPLEMENTADA]
        </mat-tab> -->
    </mat-tab-group>
</section>