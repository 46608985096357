

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { ReportsComponent } from './reports.component';
import { MainComponent } from 'src/app/layout/main/main.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'main',
                component: ReportsComponent,
                data: { breadcrumb: 'Reportes' },
                canActivate: [UserActiveGuard, RoleGuard],
                resolve: {
                    UserResolver
                },
            },
            { path: '**', redirectTo: '/reports/main' }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ReportsRoutingModule { }
