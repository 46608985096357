<aside class="wrapper-vehicle-form">

  <div class="col-md-12">
    <div class="row">

      <div class="col-md-12 wrapper-detail px-0 px-sm-3">

        <div class="title-component">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-7">
                <i class="fas fa-id-card"></i>
                {{title}}
              </div>
            </div>
          </div>
        </div>

        <form class="form-container" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="text-strong">
                Información general
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- NIT  -->
              <div class="col-md-6">
                <app-select-company *ngIf="mode ==='create'" [options]="optionsCompany"
                  [inputFormControl]="companySelected"></app-select-company>
                <ng-container *ngIf="mode !== 'create' && roleData">
                  <div class="field__card">
                    <label class="field__card--label">Compañía</label>
                    <span class="field__card--value">
                      {{utils.isDefined(roleData.nit) ? roleData.nit : '-'}}
                    </span>
                  </div>
                </ng-container>
              </div>
              <!-- Name -->
              <div class="col-md-6">
                <mat-form-field appearance="standard">
                  <mat-label>Nombre<span class="asterisk">*</span></mat-label>
                  <input matInput type="text" name="nameForm" formControlName="name">
                  <mat-error *ngIf="form.get('name').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <span class="separator-horizontal"></span>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="text-strong">
                Permisos
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- Modules -->
              <div class="col-md-12">
                <div class="row">
                  <div formArrayName="modules" *ngFor="let module of formControlModules.controls; let i = index;"
                    class="col-md-6 col-lg-4">
                    <div class="row" *ngIf="canEditModule(module.value.name)">
                      <div class="col-md-12 font-primary-light text-strong px-2">
                        {{module.value.name | roleModule}}
                      </div>
                      <ng-container class="col-md-12">
                        <div class="col-md-12 px-0 px-sm-2">
                          <div class="col-md-12">
                            <mat-checkbox [checked]="everyPermissionIsActive(i)" class="text-bold"
                              (change)="onSelectAll($event, i)" [disabled]="!canEditPermissions">
                              Seleccionar todo
                            </mat-checkbox>
                          </div>
                        </div>
                        <div *ngFor="let functionality of getFormControlFunctionalities(i).controls; let iF = index;"
                          class="col-md-12 px-0 px-sm-2">
                          <div class="col-md-12" *ngIf="canEditPermission(functionality.value.name)">
                            <mat-checkbox [formControl]="functionality.get('visible')">
                              {{iF + 1}}. {{functionality.value.name | roleFunctionality}}
                            </mat-checkbox>
                          </div>
                        </div>
                      </ng-container>
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <span class="separator-horizontal"></span>
          <mat-card-actions class="mat-card-actions-vehcle text-center">
            <button mat-raised-button color="primary" type="submit" class="button-width-auto"
              *ngIf="canEditPermissions">
              Guardar
            </button>
          </mat-card-actions>

        </form>

        <!-- Button back -->
        <div class="row md-form">
          <label class="label-back-step col-md-2" (click)="goToList()">
            <figure>
              <img src="/assets/svg/icons/icon-back.png" alt="">
            </figure>
            Atrás
          </label>
        </div>

      </div>

    </div>
  </div>

</aside>