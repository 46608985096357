import { Component, OnInit } from '@angular/core';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';

@Component({
  selector: 'app-track-guide',
  templateUrl: './track-guide.component.html',
  styleUrls: ['./track-guide.component.scss']
})
export class TrackGuideComponent implements OnInit {

  detailActive: boolean = false;
  guide: TrackGuide;

  constructor() { }

  ngOnInit() {
    
  }

  changeView($event) {
    this.detailActive = $event.detailActive;
    this.guide = $event.guide;
  }

}
