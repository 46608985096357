export class BranchOffice {

    private id: number;
    private name: string;
    private subBranchOffices: BranchOffice[] = [];

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getSubBranchOffices(): BranchOffice[] {
        return this.subBranchOffices || [new BranchOffice()];
    }

    public setSubBranchOffices(subBranchOffices: BranchOffice[]): void {
        this.subBranchOffices = [];
        if (subBranchOffices) {
            subBranchOffices.map((object) => {
                let subBranchOfficesModel = new BranchOffice();
                subBranchOfficesModel = subBranchOfficesModel.deserialize(object);
                this.subBranchOffices.push(subBranchOfficesModel);
            });
        }
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

    public serialize() {
        let obj = {};
        for (const key in this) {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                if (this['get' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    obj[key.toString()] = (this[key]);
                }
            }
        }
        return obj;
    }

}
