<div class="row-point-item">
    <div class="row mb-2">
        <div class="col-md-4 mr-5 mr-md-0" *ngIf="fingerprint?.statusCode && fingerprint?.statusInfo"
            [ngStyle]="fingerPrintsService.getSemaphore(fingerprint)">
            Código:
            <span class="text-bold">{{fingerprint.statusCode}} {{fingerprint.statusInfo}}</span>
            <i class="fas fa-info-circle fa-lg" *ngIf="fingerPrintsService.getCode(fingerprint.statusCode)"
                matTooltipPosition="above" [matTooltip]="fingerPrintsService.getCode(fingerprint.statusCode)[1]"></i>
        </div>
        <div class="col-md-6" *ngIf="fingerprint?.method">
            <span class="text-bold" title="{{fingerprint.method}}">
                Método: {{fingerprint.method}}
            </span>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-4" *ngIf="fingerprint?.userName">
            <div class="label">
                Nombre
            </div>
            <div class="text-bold label-field">
                <span title="{{fingerprint.userName}}">
                    {{fingerprint.userName}}
                </span>
            </div>
        </div>
        <div class="col-md-4" *ngIf="fingerprint?.userDocument">
            <div class="label">
                Documento
            </div>
            <div class="text-bold label-field">
                <span title="{{fingerprint.userDocument}}">
                    {{fingerprint.userDocument}}
                </span>
            </div>
        </div>
        <div class="col-md-4" *ngIf="fingerprint?.date">
            <div class="label">
                Fecha
            </div>
            <div class="text-bold label-field">
                <span title="{{formatDate(fingerprint?.date)}}">
                  {{formatDate(fingerprint?.date)}}
                </span>
            </div>
        </div>
        <div class="icon-container">
            <i class="fas fa-eye fa-lg font-secondary-dark cursor-pointer" (click)="openDetail()"
                matTooltip="Ver detalle de la solicitud"></i>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12 uri text-break" *ngIf="fingerprint?.uri"
            [ngStyle]="fingerPrintsService.getSemaphore(fingerprint)">
            <span title="{{fingerprint.uri}}">
                Petición: {{fingerprint.uri}}
            </span>
        </div>
    </div>
</div>
