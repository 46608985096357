export enum RoutingMessages {
    //Fleet creation
    VEHICLES_EDITING = 'Por favor termine de editar los vehículos',
    FLEET_CREATED = 'Flota creada exitosamente',
    SOME_VEHICLE_IS_WRONG = 'Algún vehículo seleccionado es inválido',
    VEHICLE_ALREADY_IN_FLEET = 'Este vehículo ya está incluido en la flota',
    //Capacities
    CAPACITY_REPEATED = 'Ya existe una capacidad con ese nombre',
    UNABLE_TO_DETELE_CAPACITY = 'No es posible eliminar la única capacidad seleccionada',
    CAPACITY_DELETED = 'Se ha eliminado la capacidad exitosamente',
    CAPACITY_CREATED = 'Se ha creado la capacidad exitosamente',
    //File
    INVALID_FILE = 'El archivo no es válido o hubo errores al procesarlo',
    COLUMNS_MISSING = "Una o varias columnas requeridas no están llenas",
    //Confirm addresses
    SELECT_ADDRESS = "Selecciona una ubicación para la dirección %s",
    ADDRESS_WITHOUT_COORDS = "La dirección %d no está georeferenciada",
    //Create scenario
    SCENARIO_CREATED = 'Ruteo creado exitosamente',

    //Route access
    ROUTING_UNACCESS = 'No fue posible acceder al detalle del ruteo',
    OPTIMIZING = "Optimización del ruteo en proceso",
    ROUTE_ALREADY_USED = "Ya se usó este ruteo para crear cargas",
    //Change orders
    NO_ORDERS = "No es posible crear un ruteo sin ordenes",
    NO_VEHICLE_ASSIGNED = "La orden %d no tiene vehículo asignado",

}