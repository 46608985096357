import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegisterCargoPaymentService } from './register-cargo-payment.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { AngularFireStorage } from '@angular/fire/storage';
import { Utils } from 'src/app/core/resources/utils';
import * as _ from 'lodash';
import { CargoPayment } from 'src/app/core/interfaces/cargoPayment';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';


@Component({
  selector: 'app-register-cargo-payment',
  templateUrl: './register-cargo-payment.component.html',
  styleUrls: ['./register-cargo-payment.component.scss'],
  providers: [AuthService, Global, RegisterCargoPaymentService]
})

export class RegisterCargoPaymentComponent implements OnInit {

  registerPaymentForm: FormGroup;
  @ViewChild('modalElement', { static: true }) modalElement: ElementRef;
  instanceModalOpen: NgbModalRef;
  @Input() cargo: Cargo;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onRegisterPayment: EventEmitter<any> = new EventEmitter();
  typesPayment = [
    {
      value: 'extraAdvance',
      name: 'Abono'
    },
    {
      value: 'balance',
      name: 'Saldo'
    },
    {
      value: 'advance',
      name: 'Anticipo'
    }
  ];
  typePaymentSelected: string;
  maxValue = 0;
  totalPaid = 0;
  amountValid = true;
  advanceValue = 0;

  instancesFilesStorage: FileStorage[] = [];

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private registerCargoPaymentService: RegisterCargoPaymentService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private angularFireStorage: AngularFireStorage,
    public utils: Utils
  ) {

  }

  ngOnInit() {

    this.createMassCreationCargoForm();
  }

  createMassCreationCargoForm() {

    this.registerPaymentForm = new FormGroup({
      type: new FormControl(
        '',
        Validators.compose(
          [Validators.required]
        )
      ),
      amount: new FormControl(
        null
      ),
      proofs: new FormArray([])
    });

    this.addProofForm();
  }

  addProofForm() {

    this.formProof.push(
      this.formBuilder.group({
        name: [null]
      })
    );
    this.addInstanceFile();
  }

  addInstanceFile() {

    this.instancesFilesStorage.push({
      fileData: {
        file: null,
        name: '',
        uploaded: false,
        size: null,
        url: null
      },
      storageData: {
        storageRef: null,
        uploadTask: null,
        uploadProgress: null
      }
    });
  }

  get formFilesExportDynamic() {

    return this.registerPaymentForm.controls;
  }

  get formProof() {

    return this.formFilesExportDynamic.proofs as FormArray;
  }

  deleteFile(i: number, removeFieldForm?: boolean) {

    if (this.instancesFilesStorage[i].fileData.url) {
      this.angularFireStorage.storage.refFromURL(this.instancesFilesStorage[i].fileData.url).delete().then(
        (data) => {
          this.instancesFilesStorage[i].storageData.storageRef = null;
          this.instancesFilesStorage[i].storageData.uploadTask = null;
          this.instancesFilesStorage[i].storageData.uploadProgress = null;
          this.instancesFilesStorage[i].fileData = {
            file: null,
            name: '',
            uploaded: false,
            size: null,
            url: null
          };
          if (removeFieldForm) {
            this.formProof.removeAt(i);
            this.instancesFilesStorage.splice(i, 1);
          } else {
            this.formProof.controls[i].setValue({ name: null });
          }
        },
        (error) => {

        },
      );
    } else {
      if (removeFieldForm) {
        this.formProof.removeAt(i);
        this.instancesFilesStorage.splice(i, 1);
      }
    }
  }

  handleFileInput(e: Event, i: number) {

    this.instancesFilesStorage[i].fileData.file = e.target['files'][0];
    this.instancesFilesStorage[i].fileData.name = e.target['files'][0]['name']
    this.instancesFilesStorage[i].fileData.uploaded = true;
    this.instancesFilesStorage[i].fileData.size = this.utils.bytesToSize(this.instancesFilesStorage[i].fileData.file.size);

    this.uploadFileLetterRetirement(this.instancesFilesStorage[i].fileData, i, this.formProof.controls[i]);
  }

  uploadFileLetterRetirement(file: any, i: number, formControl: any) {

    const basePath = '/driver/' + this.cargo.driver + '/' + this.cargo.id + '/';
    const filePath = `${basePath}/${file['file'].name}`;

    this.instancesFilesStorage[i].storageData.storageRef = this.angularFireStorage.ref(filePath);
    this.instancesFilesStorage[i].storageData.uploadTask = this.angularFireStorage.upload(filePath, file['file']);
    this.instancesFilesStorage[i].storageData.uploadProgress = this.instancesFilesStorage[i].storageData.uploadTask.percentageChanges();

    this.instancesFilesStorage[i].storageData.uploadTask.then(
      (data) => {

        const thisClass = this;
        this.instancesFilesStorage[i].storageData.storageRef.getDownloadURL().subscribe(
          (urlFile) => {
            file.url = urlFile;
            formControl.setValue({ name: file['file'].name });
          }
        );
      },
      (error) => {

      }
    );
  }

  openModal() {

    this.advanceValue = Math.floor(this.cargo.shippingCost.totalCost * this.cargo.shippingCost.advancePercentage) / 100;
    if (this.cargo.shippingCost.totalPaid) {
      this.totalPaid = this.cargo.shippingCost.totalPaid;
    }
    this.maxValue = Math.floor(this.cargo.shippingCost.totalCost * 70) / 100;
    this.instanceModalOpen = this.modalService.open(this.modalElement, { centered: true, size: 'lg', ariaLabelledBy: 'modal-basic-title' });
  }

  closeModal() {

    this.registerPaymentForm.reset();
    this.instanceModalOpen.close();
  }

  confirmPayment() {

    this.instanceModalOpen.close();
  }

  onSubmit() {
    if (this.registerPaymentForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.registerPaymentForm.get('type'), 'tipo de pago')) return;
      else this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
    }
    else if (!this.amountValid) {
      this.snackBarService.openSnackBar('El monto ingresado supera el 70% del valor del flete', undefined, 'alert');
    }
    else {
      const data: CargoPayment = this.registerPaymentForm.value;
      if (this.getProofUrls().length) {
        data.proofs = this.getProofUrls();
      } else {
        delete data.proofs;
      }
      data.cargoId = this.cargo.id;
      this.spinner.show();
      this.registerCargoPaymentService.registerPayment(data).subscribe(
        (success) => {
          this.spinner.hide();
          this.onRegisterPayment.emit(success);
          this.closeModal();
          this.snackBarService.openSnackBar('Pago registrado exitosamente');
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error registrando el pago', undefined, 'error');
        }
      );
    }
  }

  onChangeTypePayment() {

    this.amountValid = true;
    this.registerPaymentForm.controls.amount.setValue('');
  }

  getProofUrls() {
    return _.filter(this.registerPaymentForm.value.proofs, (g) => {
      return g.name !== null;
    }).map((g) => {
      return g.name;
    });
  }

  onKeyUpAmount($event) {

    // tslint:disable-next-line: radix
    const currentMaxValue = this.totalPaid + parseInt(this.registerPaymentForm.controls.amount.value);
    // tslint:disable-next-line: radix
    this.amountValid = currentMaxValue <= this.maxValue;
  }

}
