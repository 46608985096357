<input #input [accept]="options && options.format ? options.format : '*'" class="display-none" type="file"
  (change)="handleFileInput($event)">
<ng-container *ngIf="!options || !options.type">
  <label class="file">
    <div class="icon-type" [ngClass]="{'file-loaded': (file.storageData.uploadProgress | async) === 100}">
      <img
        *ngIf="(file.storageData.uploadProgress | async) !== 100 && (!file?.fileData?.name || !visualFileName?.value )"
        src="assets/svg/icons/icon-upload.svg" alt="">
      <img *ngIf="(file.storageData.uploadProgress | async) === 100 || file?.fileData?.name || visualFileName?.value"
        src="assets/svg/icons/icon-correct.svg" alt="">
    </div>
    <!-- Normal File -->
    <div class="data-type" *ngIf="!options || !options.storage">
      <label *ngIf="file?.fileData?.name" class="label-field title-letter text-truncate mr-2">{{file.fileData.name |
        fileName}}</label>
      <br>
      <label class="label-field label-file" *ngIf="file?.fileData?.name">
        <a class="file-name-container d-flex align-items-center">
          <i class="fas fa-paperclip"></i>
          <span class="file-name" title="{{file.fileData.name | fileName}}">{{file.fileData.name | fileName}}</span>
          <span class="file-size" title="{{file.fileData.size}}">{{file.fileData.size}}</span>
        </a>
      </label>
      <span *ngIf="!file.fileData.name && options && !options.readOnly" class="label-load-file"
        (click)="openWindowFile()">
        <a>{{options?.title ? options.title : 'Cargar archivo'}}</a>
      </span>
    </div>
    <!-- With Storage -->
    <div class="data-type" *ngIf="options?.storage">
      <a class="file-name-container w-100 m-0 d-flex align-items-center"
        *ngIf="(file?.fileData?.name && (options.docUrl || docUrl)) || (visualFileName?.value && options?.firebasePath && !file?.fileData?.name) "
        [href]="docUrl" target="_blank">
        <span *ngIf="visualFileName?.value && options?.firebasePath && !file?.fileData?.name "
          class="label-field size-title text-truncate color-primary mb-0 cursor-pointer" title="Ver archivo cargado"><u
            class="cursor-pointer">{{visualFileName.value}}</u></span>
        <label *ngIf="file?.fileData?.name && (options.docUrl || docUrl)"
          class="label-field size-title text-truncate color-primary cursor-pointer mb-0" title="Ver archivo cargado"><u
            class="cursor-pointer">{{file.fileData.name | fileName}}</u></label>
      </a>
      <label *ngIf="file?.fileData?.name && !(options.docUrl || docUrl)"
        class="label-field size-title text-truncate">{{file.fileData.name | fileName}}</label>
      <br>
      <label *ngIf="(file.storageData.uploadProgress | async) === 100" class="label-field label-file">
        <a class="file-name-container d-flex align-items-center" *ngIf="file.fileData.url" [href]="file.fileData.url"
          target="_blank">
          <i class="fas fa-paperclip"></i>
          <span class="file-name" title="Ver archivo cargado">{{file.fileData.name | fileName}}</span>
          <span class="file-size" title="{{file.fileData.size}}">{{file.fileData.size}}</span>
        </a>
      </label>
      <span *ngIf="!(file.storageData.uploadProgress) && options && !options.readOnly" class="label-load-file"
        (click)="openWindowFile()">
        <a>{{options?.title ? options.title : 'Cargar archivo'}}</a>
      </span>
      <div *ngIf="(file.storageData.uploadProgress) && (file.storageData.uploadProgress | async) !== 100">
        <mat-progress-bar [value]="file.storageData.uploadProgress | async">
        </mat-progress-bar>
      </div>
    </div>
    <div class="delete-container" *ngIf="file.fileData.url || ((!options || !options.storage) && file.fileData.size)">
      <img (click)="deleteFile()" src="assets/svg/icons/icon-delete.svg" alt="">
    </div>
  </label>
</ng-container>
<ng-container *ngIf="options && options.type === 'input'">
  <mat-form-field appearance="standard" class="field__form cursor-pointer" (click)="openWindowFile()">
    <mat-label>{{options.title}}<span class="asterisk" *ngIf="options.required">*</span></mat-label>
    <input matInput type="text" class="field__form--input" readonly [formControl]="visualFileName" appPatternField
      [pattern]="options.pattern">
    <i class="fas fa-paperclip field__form--icon"></i>
  </mat-form-field>
</ng-container>