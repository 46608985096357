import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from 'src/app/core/shared.module';
import { CargoRoutingModule } from './cargo-routing.module';
import { ManualCreationCargoComponent } from './manual-creation-cargo/manual-creation-cargo.component';
import { TypeTripComponent } from './manual-creation-cargo/components/type-trip/type-trip.component';
import { TypeRegimeComponent } from './manual-creation-cargo/components/type-regime/typeRegime.component';
import { TypeCargoComponent } from './manual-creation-cargo/components/type-cargo/type-cargo.component';
import { AssignVehicleCargoComponent } from './assign-vehicle-cargo/assign-vehicle-cargo.component';
import { CargoItemComponent } from './cargo-item/cargo-item.component';
import { CargoItemRequestComponent } from './cargo-item-request/cargo-item-request.component';
import { AdditionalServiceComponent } from './additional-service/additional-service.component';
import { CalculatorMeasurementsComponent } from './manual-creation-cargo/components/calculator-measurements/calculatorMeasurements.component';
import { ContainerMeasureComponent } from './manual-creation-cargo/components/container-measure/containerMeasure.component';
import { DateCargoComponent } from './manual-creation-cargo/components/date-cargo/date-cargo.component';
import { DownloadComponent } from './manual-creation-cargo/components/download/download.component';
import { CargoValueComponent } from './manual-creation-cargo/components/cargo-value/cargo-value.component';
import { LoadMeasurementsComponent } from './manual-creation-cargo/components/load-measurements/load-measurements.component';
import { LocationsFreightComponent } from './manual-creation-cargo/components/cargo-locations/cargo-locations.component';
import { MethodPaymentComponent } from './manual-creation-cargo/components/method-payment/method-payment.component';
import { QuantityVehiclesComponent } from './manual-creation-cargo/components/quantity-vehicles/quantity-vehicles.component';
import { TotalValueComponent } from './manual-creation-cargo/components/total-value/totalValue.component';
import { TypeMerchandiseComponent } from './manual-creation-cargo/components/type-merchandise/type-merchandise.component';
import { TypeServiceComponent } from './manual-creation-cargo/components/type-service/type-service.component';
import { TypeVehicleComponent } from './manual-creation-cargo/components/type-vehicle/type-vehicle.component';
import { UploadDownloadMapComponent } from './manual-creation-cargo/components/upload-download-map/upload-download-map.component';
import { UploadComponent } from './manual-creation-cargo/components/upload/upload.component';
import { CargoDetailComponent } from './cargo-detail/cargo-detail.component';
import { CargoListComponent } from './cargo-list/cargo-list.component';
import { CargoCreateTabsComponent } from './cargo-tabs-create/cargo-tabs-create.component';
import { CargoTrackingComponent } from './cargo-tracking/cargo-tracking.component';
import { ListCargoPaymentsComponent } from './list-cargo-payments/list-cargo-payments.component';
import { RegisterCargoPaymentComponent } from './register-cargo-payment/register-cargo-payment.component';
import { MassCreationCargoComponent } from './mass-creation-cargo/mass-creation-cargo.component';
import { CreateCargoFromGuidesComponent } from '../guides/create-cargo-from-guides/create-cargo-from-guides.component';
import { ListCargoFromGuidesComponent } from '../guides/create-cargo-from-guides/list-cargo-from-guides/list-cargo-from-guides.component';
import { TripTypeDocumentsComponent } from './manual-creation-cargo/components/trip-type-documents/trip-type-documents.component';
import { CargoControlTrackingComponent } from './cargo-control-tracking/cargo-control-tracking.component';
import { CargoResources } from './manual-creation-cargo/resources/cargo';
import { UploadLocationComponent } from './manual-creation-cargo/components/upload-location/upload-location.component';
import { DownloadLocationComponent } from './manual-creation-cargo/components/download-location/download-location.component';
import { ThirdPartyConsignmentComponent } from './manual-creation-cargo/components/third-party-consignment/third-party-consignment.component';
import { FormThirdPartyConsignmentComponent } from './manual-creation-cargo/components/form-third-party-consignment/form-third-party-consignment.component';
import { UploadRouteComponent } from './upload-route/upload-route.component';
import { ShipingCostComponent } from './cargo-detail/components/shipping-cost/shipping-cost.component';
import { GeneralDataCargoComponent } from './cargo-detail/components/general-data-cargo/general-data-cargo.component';
import { DiscountsAndBonusesCargoComponent } from './discounts-and-bonuses-cargo/discounts-and-bonuses-cargo.component';
import { AdditionalCostsHistoryComponent } from './additional-costs-history/additional-costs-history.component';
import { ExternalManifestCargoComponent } from './cargo-detail/components/external-manifest-cargo/external-manifest-cargo.component';
import { AssignLicensePlateComponent } from './manual-creation-cargo/components/assign-license-plate/assign-license-plate.component';
import { CargoOptionsComponent } from './cargo-options/cargo-options.component';
import { FreightListService } from './cargo-list/cargo-list.service';
import { ChangeCompanyComponent } from './change-company/change-company.component';
import { CargoReteicaComponent } from './cargo-reteica/cargo-reteica.component';
import { CargoRenewDatesComponent } from './cargo-renew-dates/cargo-renew-dates.component';
import { CargoHistoryNewsComponent } from './cargo-history-news/cargo-history-news.component';
import { CargoSearchComponent } from './cargo-search/cargo-search.component';
import { CargoBillingComponent } from './cargo-billing/cargo-billing.component';
import { ItemAdditionalServiceComponent } from './item-additional-service/item-additional-service.component';
import { ListAdditionalServicesComponent } from './list-additional-services/list-additional-services.component';
import { CreationAdditionalCostCargoComponent } from './manual-creation-cargo/components/creation-additional-cost-cargo/creation-additional-cost-cargo.component';
import { UpdateAdditionalCostCargoComponent } from './cargo-detail/components/update-additional-cost-cargo/update-additional-cost-cargo.component';
import { CargoRatingComponent } from './cargo-rating/cargo-rating.component';
import { VehicleBodyWorkTypeComponent } from './manual-creation-cargo/components/vehicle-body-work-type/vehicle-body-work-type.component';
import { LoadDownloadCargoComponent } from './cargo-detail/components/load-download-cargo/load-download-cargo.component';
import { CargoUpdateComponent } from './cargo-update/cargo-update.component';
import { CargoCharacteristicComponent } from './cargo-detail/components/cargo-characteristic/cargo-characteristic.component';
import { CargoPaymentsComponent } from './cargo-payments/cargo-payments.component';
import { CargoListPaymentsComponent } from './cargo-list-payments/cargo-list-payments.component';
import { CargoItemPaymentsComponent } from './cargo-item-payments/cargo-item-payments.component';
import { CargoAdvancePercentageComponent } from './cargo-advance-percentage/cargo-advance-percentage.component';
import { CargoTypeComponent } from './cargo-detail/components/cargo-type/cargo-type.component';
import { BoxTypeTripComponent } from './manual-creation-cargo/components/box-type-trip/box-type-trip.component';
import { BoxTypeRegimeComponent } from './manual-creation-cargo/components/box-type-regime/box-type-regime.component';
import { BoxTypeCargaComponent } from './manual-creation-cargo/components/box-type-carga/box-type-carga.component';
import { CargoTypeMerchandiseComponent } from './cargo-type-merchandise/cargo-type-merchandisecomponent';
import { BoxTypeServiceComponent } from './manual-creation-cargo/components/box-type-service/box-type-service.component';
import { BoxContainerMeasureComponent } from './manual-creation-cargo/components/box-container-measure/box-container-measure.component';
import { DriverSummaryComponent } from './driver-summary/driver-summary.component';
import { CargoListTurnComponent } from './cargo-list-turn/cargo-list-turn.component';
import { CargoItemTurnComponent } from './cargo-item-turn/cargo-item-turn.component';
import { turnFilterComponent } from './turn-filter/turn-filter.component';
import { SummaryAdditionalCostCargoComponent } from './summary-additional-cost-cargo/summary-additional-cost-cargo.component';
import { EmailFormComponent } from './email-form/email-form.component';
import { CargoTurnFormComponent } from './cargo-turn-form/cargo-turn-form.component';
import { CargoEditTypeVehicleComponent } from './cargo-edit-type-vehicle/cargo-edit-type-vehicle.component';
import { CargoTrackingStatesHistoryComponent } from './cargo-tracking-states-history/cargo-tracking-states-history.component';
import { CargoLocationReportComponent } from './cargo-location-report/cargo-location-report.component';
import { CargoLastPointLocationComponent } from './cargo-last-point-location/cargo-last-point-location.component';
import { SummaryAnomalyCargoTrackingComponent } from './summary-anomaly-cargo-tracking/summary-anomaly-cargo-tracking.component';
import { SummaryAnomalyItemCargoTrackingComponent } from './summary-anomaly-item-cargo-tracking/summary-anomaly-item-cargo-tracking.component';
import { BellAlertComponent } from './bell-alert/bell-alert.component';
import { BoxSealComponent } from './manual-creation-cargo/components/box-seal/box-seal.component';
import { CargoValidators } from 'src/app/core/validators/cargo.validators';
import { MapTrackingComponent } from './map-tracking/map-tracking.component';
import { GlobalTrackingComponent } from './global-tracking/global-tracking.component';
import { GlobalTrackingListComponent } from './global-tracking-list/global-tracking-list.component';
import { InputValidators } from 'src/app/core/validators/input.validators';
import { CreateDiscountsAndBonusesComponent } from './create-discounts-and-bonuses/create-discounts-and-bonuses.component';
import { BranchOfficeOwnerCargoComponent } from './branch-office-owner-cargo/branch-office-owner-cargo.component';
import { RoundedCargoComponent } from './rounded-cargo/rounded-cargo.component';
import { confirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { CargoTabsLoadingRoutesComponent } from './cargo-tabs-loading-routes/cargo-tabs-loading-routes.component';
import { OpenDetailManifestsComponent } from './open-detail-manifests/open-detail-manifests.component';
import { CargoRequestAdvanceComponent } from './cargo-request-advance/cargo-request-advance.component';
import { ContainerChecklistComponent } from './container-checklist/container-checklist.component';
import { RateCostAdditionalServicesComponent } from './rate-cost-additional-services/rate-cost-additional-services.component';
import { ConfirmBellAlertsComponent } from './confirm-bell-alerts/confirm-bell-alerts.component';
import { ModalCargoUncaredComponent } from './modal-cargo-uncared/modal-cargo-uncared.component';
import { AvaliableCargoListComponent } from './avaliable-cargo-list/avaliable-cargo-list.component';
import { SummaryBalanceComponent } from './summary-balance/summary-balance.component';
import { ConventionsComponent } from 'src/app/shared/conventions/conventions.component';
import { ChangeDriverComponent } from './change-driver/change-driver.component';
import { RequestButtonComponent } from './manual-creation-cargo/components/request-button/request-button.component';
import { CargoGenerateFilterComponent } from './cargo-generate-filter/cargo-generate-filter.component';
import { CargoGenerateComponent } from './cargo-generate/cargo-generate.component';
import { CargoListExpensesIncomeComponent } from './cargo-list-expenses-income/cargo-list-expenses-income.component';
import { ResponseGenerateJournalComponent } from './response-generate-journal/response-generate-journal.component';
import { CreateCargoComponent } from './create-cargo/create-cargo.component';
import { CargoShippingCostDialogComponent } from './cargo-shipping-cost-dialog/cargo-shipping-cost-dialog.component';
import { TimeToUploadEvidencesComponent } from './time-to-upload-evidences/time-to-upload-evidences.component';
import { ApplicationsComponent } from './applications/applications.component';
import { CargoBillingXmlComponent } from './cargo-billing-xml/cargo-billing-xml.component';
import { CargoDriverDocumentidDialogComponent } from './cargo-driver-documentid-dialog/cargo-driver-documentid-dialog.component';
import { EndCargoCheckComponent } from './end-cargo-check/end-cargo-check.component';
import { RequestDatesToMonitorComponent } from './request-dates-to-monitor/request-dates-to-monitor.component';
import { DatePipe } from '@angular/common';
import { MassivePaymentsComponent } from './massive-payments/massive-payments.component';
import { ListMassivePaymentsLoadsComponent } from './list-massive-payments-loads/list-massive-payments-loads.component';
import { AlertAdvancePaymentComponent } from './alert-advance-payment/alert-advance-payment.component';
import { CargoComplimentsComponent } from './cargo-compliments/cargo-compliments.component';
import { TravelExpensesComponent } from './manual-creation-cargo/travel-expenses/travel-expenses.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LegalizeTravelExpensesComponent } from './legalize-travel-expenses/legalize-travel-expenses.component';
import { FormTravelExpenseDetailComponent } from './form-travel-expense-detail/form-travel-expense-detail.component';
import { TravelExpensesDetailComponent } from './travel-expenses-detail/travel-expenses-detail.component';
import { GeneralTableLegalizationTravelExpensesComponent } from './general-table-legalization-travel-expenses/general-table-legalization-travel-expenses.component';
import { CargoListServiceRequestsComponent } from './cargo-list-service-requests/cargo-list-service-requests.component';


@NgModule({
  imports: [
    SharedModule.forRoot(),
    CargoRoutingModule,
    ReactiveFormsModule
  ],
  declarations: [
    ManualCreationCargoComponent,
    TypeTripComponent,
    TypeRegimeComponent,
    TypeCargoComponent,
    ContainerMeasureComponent,
    TypeServiceComponent,
    TypeMerchandiseComponent,
    TypeVehicleComponent,
    QuantityVehiclesComponent,
    LoadMeasurementsComponent,
    UploadComponent,
    UploadDownloadMapComponent,
    DownloadComponent,
    DateCargoComponent,
    DateCargoComponent,
    AdditionalServiceComponent,
    CargoValueComponent,
    MethodPaymentComponent,
    TotalValueComponent,
    CalculatorMeasurementsComponent,
    CargoTrackingComponent,
    CargoListComponent,
    CargoItemComponent,
    CargoItemRequestComponent,
    CargoListTurnComponent,
    CargoItemTurnComponent,
    CargoTurnFormComponent,
    LocationsFreightComponent,
    CargoCreateTabsComponent,
    turnFilterComponent,
    CargoUpdateComponent,
    CargoDetailComponent,
    RegisterCargoPaymentComponent,
    AssignVehicleCargoComponent,
    ListCargoPaymentsComponent,
    MassCreationCargoComponent,
    CreateCargoFromGuidesComponent,
    ListCargoFromGuidesComponent,
    TripTypeDocumentsComponent,
    CargoControlTrackingComponent,
    UploadLocationComponent,
    DownloadLocationComponent,
    ThirdPartyConsignmentComponent,
    FormThirdPartyConsignmentComponent,
    UploadRouteComponent,
    ShipingCostComponent,
    CargoTypeComponent,
    BoxTypeCargaComponent,
    BoxTypeTripComponent,
    BoxSealComponent,
    BoxTypeServiceComponent,
    BoxTypeRegimeComponent,
    BoxContainerMeasureComponent,
    CargoCharacteristicComponent,
    GeneralDataCargoComponent,
    DiscountsAndBonusesCargoComponent,
    AdditionalCostsHistoryComponent,
    ExternalManifestCargoComponent,
    AssignLicensePlateComponent,
    CargoOptionsComponent,
    ChangeCompanyComponent,
    CargoReteicaComponent,
    CargoRenewDatesComponent,
    CargoHistoryNewsComponent,
    CargoSearchComponent,
    CargoBillingComponent,
    ResponseGenerateJournalComponent,
    ItemAdditionalServiceComponent,
    ListAdditionalServicesComponent,
    CreationAdditionalCostCargoComponent,
    UpdateAdditionalCostCargoComponent,
    CargoRatingComponent,
    VehicleBodyWorkTypeComponent,
    CargoEditTypeVehicleComponent,
    ConfirmBellAlertsComponent,
    LoadDownloadCargoComponent,
    CargoPaymentsComponent,
    CargoListPaymentsComponent,
    CargoItemPaymentsComponent,
    CargoAdvancePercentageComponent,
    CargoTypeMerchandiseComponent,
    DriverSummaryComponent,
    SummaryAdditionalCostCargoComponent,
    EmailFormComponent,
    CargoGenerateComponent,
    CargoGenerateFilterComponent,
    CargoListExpensesIncomeComponent,
    CargoTrackingStatesHistoryComponent,
    CargoLocationReportComponent,
    CargoLastPointLocationComponent,
    SummaryAnomalyCargoTrackingComponent,
    SummaryAnomalyItemCargoTrackingComponent,
    BellAlertComponent,
    MapTrackingComponent,
    GlobalTrackingComponent,
    GlobalTrackingListComponent,
    CreateDiscountsAndBonusesComponent,
    BranchOfficeOwnerCargoComponent,
    RoundedCargoComponent,
    confirmDeleteComponent,
    CargoTabsLoadingRoutesComponent,
    OpenDetailManifestsComponent,
    CargoRequestAdvanceComponent,
    ContainerChecklistComponent,
    RateCostAdditionalServicesComponent,
    ModalCargoUncaredComponent,
    AvaliableCargoListComponent,
    SummaryBalanceComponent,
    ChangeDriverComponent,
    RequestButtonComponent,
    CreateCargoComponent,
    CargoShippingCostDialogComponent,
    TimeToUploadEvidencesComponent,
    ApplicationsComponent,
    CargoBillingXmlComponent,
    CargoDriverDocumentidDialogComponent,
    EndCargoCheckComponent,
    RequestDatesToMonitorComponent,
    MassivePaymentsComponent,
    ListMassivePaymentsLoadsComponent,
    AlertAdvancePaymentComponent,
    CargoComplimentsComponent,
    TravelExpensesComponent,
    LegalizeTravelExpensesComponent,
    FormTravelExpenseDetailComponent,
    TravelExpensesDetailComponent,
    GeneralTableLegalizationTravelExpensesComponent,
    CargoListServiceRequestsComponent
  ],
  entryComponents: [
    CalculatorMeasurementsComponent,
    FormThirdPartyConsignmentComponent,
    AdditionalCostsHistoryComponent,
    DiscountsAndBonusesCargoComponent,
    ExternalManifestCargoComponent,
    CargoReteicaComponent,
    CargoRenewDatesComponent,
    RoundedCargoComponent,
    ChangeCompanyComponent,
    CargoReteicaComponent,
    CargoHistoryNewsComponent,
    CargoBillingComponent,
    CargoBillingXmlComponent,
    ResponseGenerateJournalComponent,
    CargoRatingComponent,
    CargoUpdateComponent,
    AdditionalServiceComponent,
    CargoEditTypeVehicleComponent,
    UpdateAdditionalCostCargoComponent,
    ConfirmBellAlertsComponent,
    ConventionsComponent,
    CargoAdvancePercentageComponent,
    SummaryAdditionalCostCargoComponent,
    CargoTurnFormComponent,
    VehicleBodyWorkTypeComponent,
    EmailFormComponent,
    CargoLocationReportComponent,
    CreateDiscountsAndBonusesComponent,
    OpenDetailManifestsComponent,
    CargoRequestAdvanceComponent,
    ContainerChecklistComponent,
    confirmDeleteComponent,
    OpenDetailManifestsComponent,
    RateCostAdditionalServicesComponent,
    ModalCargoUncaredComponent,
    AssignVehicleCargoComponent,
    AvaliableCargoListComponent,
    SummaryBalanceComponent,
    ChangeDriverComponent,
    CargoShippingCostDialogComponent,
    TimeToUploadEvidencesComponent,
    CargoDriverDocumentidDialogComponent,
    EndCargoCheckComponent,
    RequestDatesToMonitorComponent,
    MassivePaymentsComponent,
    AlertAdvancePaymentComponent,
    CargoComplimentsComponent,
    TravelExpensesComponent,
    LegalizeTravelExpensesComponent,
    FormTravelExpenseDetailComponent,
    TravelExpensesDetailComponent,
    GeneralTableLegalizationTravelExpensesComponent
  ],
  exports: [
    ManualCreationCargoComponent,
    TypeTripComponent,
    TypeRegimeComponent,
    TypeCargoComponent,
    ContainerMeasureComponent,
    TypeServiceComponent,
    TypeMerchandiseComponent,
    TypeVehicleComponent,
    QuantityVehiclesComponent,
    LoadMeasurementsComponent,
    UploadComponent,
    UploadDownloadMapComponent,
    DownloadComponent,
    DateCargoComponent,
    DateCargoComponent,
    AdditionalServiceComponent,
    CargoValueComponent,
    MethodPaymentComponent,
    TotalValueComponent,
    CalculatorMeasurementsComponent,
    CargoTrackingComponent,
    CargoListComponent,
    CargoItemComponent,
    CargoItemRequestComponent,
    CargoListTurnComponent,
    CargoItemTurnComponent,
    LocationsFreightComponent,
    CargoCreateTabsComponent,
    CargoUpdateComponent,
    turnFilterComponent,
    CargoDetailComponent,
    RegisterCargoPaymentComponent,
    ListCargoPaymentsComponent,
    TripTypeDocumentsComponent,
    CargoControlTrackingComponent,
    UploadLocationComponent,
    DownloadLocationComponent,
    ThirdPartyConsignmentComponent,
    FormThirdPartyConsignmentComponent,
    UploadRouteComponent,
    ShipingCostComponent,
    CargoTypeComponent,
    BoxTypeTripComponent,
    BoxSealComponent,
    BoxTypeServiceComponent,
    BoxTypeRegimeComponent,
    BoxTypeCargaComponent,
    BoxContainerMeasureComponent,
    CargoCharacteristicComponent,
    GeneralDataCargoComponent,
    DiscountsAndBonusesCargoComponent,
    AdditionalCostsHistoryComponent,
    CreateDiscountsAndBonusesComponent,
    ExternalManifestCargoComponent,
    CargoReteicaComponent,
    CargoRenewDatesComponent,
    RoundedCargoComponent,
    ChangeCompanyComponent,
    CargoReteicaComponent,
    CargoHistoryNewsComponent,
    CargoRatingComponent,
    ConfirmBellAlertsComponent,
    EmailFormComponent,
  ],
  providers: [
    CargoResources,
    FreightListService,
    CargoValidators,
    InputValidators,
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class CargoModule {

}
