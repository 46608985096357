import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { RoutingRoutingModule } from './routing-routing.module';

import { SharedModule } from 'src/app/core/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import { RoutingMapComponent } from './components/routing-map/routing-map.component';
import { RoutingFormComponent } from './components/routing-form/routing-form.component';
import { RoutingSchemeFormComponent } from './components/routing-scheme-form/routing-scheme-form.component';
import { RoutingFleetFormComponent } from './components/routing-fleet-form/routing-fleet-form.component';
import { RoutingBasicFormComponent } from './components/routing-basic-form/routing-basic-form.component';
import { RoutingAddressFormComponent } from './components/routing-address-form/routing-address-form.component';
import { RoutingConfirmAddressComponent } from './components/routing-confirm-address/routing-confirm-address.component';
import { RoutingCreateCargoComponent } from './components/routing-create-cargo/routing-create-cargo.component';
import { RoutingCreateFleetComponent } from './components/routing-create-fleet/routing-create-fleet.component';
import { RoutingCreateCapacityComponent } from './components/routing-create-capacity/routing-create-capacity.component';
import { ScenarioListComponent } from './components/scenario-list/scenario-list.component';
import { ScenarioItemComponent } from './components/scenario-item/scenario-item.component';
import { ScenarioModalComponent } from './components/scenario-modal/scenario-modal.component';
import { ScenarioDetailComponent } from './components/scenario-detail/scenario-detail.component';
import { RoutingChangeOrdersComponent } from './components/routing-change-orders/routing-change-orders.component';

@NgModule({
  declarations: [
    RoutingMapComponent,
    RoutingFormComponent,
    RoutingSchemeFormComponent,
    RoutingFleetFormComponent,
    RoutingBasicFormComponent,
    RoutingAddressFormComponent,
    RoutingConfirmAddressComponent,
    RoutingCreateCargoComponent,
    RoutingCreateFleetComponent,
    RoutingCreateCapacityComponent,
    ScenarioListComponent,
    ScenarioItemComponent,
    ScenarioModalComponent,
    ScenarioDetailComponent,
    RoutingChangeOrdersComponent,
  ],
  imports: [
    CommonModule,
    RoutingRoutingModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    DragDropModule,
    MatTableModule,
    MatSortModule,
    SharedModule.forRoot(),
  ],
  entryComponents: [
    RoutingCreateFleetComponent,
    RoutingCreateCapacityComponent,
    ScenarioModalComponent
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [

  ]
})
export class RoutingModule { }
