<div [ngClass]="{'evidences-container-detail': showEvidences, 'not-padding': expandedImage}" class="evidences-container"
  *ngIf="cargo && cargo.id && !notResults?.state; else notResultsView">
  <p *ngIf="!showEvidences" class="evidences-title">Cumplidos <span>Consecutivo#{{cargo.consecutive}}</span></p>
  <!-- Portadas por direccion -->
  <div *ngIf="!showEvidences" class="d-flex flex-wrap justify-content-around">
    <!-- Upload -->
    <div *ngIf="originAddresses?.length" class="d-flex flex-wrap justify-content-around">
      <div *ngFor="let address of originAddresses; let i=index">
        <div *ngIf="address?.imagesDocument?.length;else imagesTruck">
          <div *ngFor="let imageDocument of address.imagesDocument; let j=index;">
            <div *ngIf="j === 0" class="cover-page"
              (click)="showDetail(true, address,i, imageDocument?.type === 'pdf' ? imageDocument.sanitizeUrlPdf : imageDocument.url, imageDocument?.type, 'origin')">
              <p class="p-0 m-0 hidden-p">Origen ({{ utils.isDefined(cargo.cargoFeature.uploadDownload.origin.name) ?
                cargo.cargoFeature.uploadDownload.origin.name : '-' }}) Cargue {{i + 1}}: {{address.address}} </p>
              <p class="p-0 m-0 visible-p">Origen ({{ utils.isDefined(cargo.cargoFeature.uploadDownload.origin.name) ?
                cargo.cargoFeature.uploadDownload.origin.name : '-' }}) Cargue {{i + 1}}: {{address.address}} </p>
              <img [src]="imageDocument.url" class="cover-img"
                [ngClass]="{'pdf-type': getIsPdfType(imageDocument?.type)}" alt="">
            </div>
          </div>
        </div>
        <ng-template #imagesTruck>
          <div *ngIf="address?.imagesTruck?.length;else notEvidencesLoad">
            <div *ngFor="let imageTruck of address.imagesTruck; let j=index;">
              <div *ngIf="j === 0" class="cover-page"
                (click)="showDetail(true,address, i, imageTruck.type === 'pdf' ? imageTruck.sanitizeUrlPdf : imageTruck.url, imageTruck?.type, 'origin')">
                <p class="p-0 m-0 hidden-p">Origen ({{ utils.isDefined(cargo.cargoFeature.uploadDownload.origin.name) ?
                  cargo.cargoFeature.uploadDownload.origin.name : '-' }}) Cargue {{i + 1}}: {{address.address}} </p>
                <p class="p-0 m-0 visible-p">Origen ({{ utils.isDefined(cargo.cargoFeature.uploadDownload.origin.name) ?
                  cargo.cargoFeature.uploadDownload.origin.name : '-' }}) Cargue {{i + 1}}: {{address.address}} </p>
                <img [id]="imageTruck.url" [src]="imageTruck.url" class="cover-img"
                  [ngClass]="{'pdf-type': getIsPdfType(imageTruck?.type)}" alt="">
              </div>
            </div>
          </div>
        </ng-template>
        <!-- not evidences -->
        <ng-template #notEvidencesLoad>
          <div class="cover-page" (click)="showDetail(true,address, i, null, null, 'origin')">
            <p class="p-0 m-0 hidden-p">Origen ({{ utils.isDefined(cargo.cargoFeature.uploadDownload.origin.name) ?
              cargo.cargoFeature.uploadDownload.origin.name : '-' }}) Cargue {{i + 1}}: {{address.address}} </p>
            <p class="p-0 m-0 visible-p">Origen ({{ utils.isDefined(cargo.cargoFeature.uploadDownload.origin.name) ?
              cargo.cargoFeature.uploadDownload.origin.name : '-' }}) Cargue {{i + 1}}: {{address.address}} </p>
            <div class="d-flex h-100 w-100 align-items-center justify-content-center not-evidences-container">
              <div class="not-evidences">No se han subido evidencias</div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <!-- Download -->
    <div *ngIf="destinations?.length" class="d-flex flex-wrap justify-content-around download-container">
      <div *ngFor="let destination of destinations; let destinationIndex = index"
        class="d-flex flex-wrap justify-content-around">
        <div *ngFor=" let address of destination.addresses; let addressDestinationIndex = index;">
          <div *ngIf="address?.imagesDocument?.length;else imagesTruckDownload">
            <div *ngFor="let imageDocument of address.imagesDocument; let imagesDownloadIndex =index;">
              <div *ngIf="imagesDownloadIndex === 0" class="cover-page"
                (click)="showDetail(true, address,addressDestinationIndex, imageDocument?.type === 'pdf' ? imageDocument.sanitizeUrlPdf : imageDocument.url, imageDocument?.type, 'destination',  destination.downloadDate, destination.name)">
                <p class="p-0 m-0 hidden-p">Destino {{ destinationIndex + 1}} ({{destination.name}}) Descargue
                  {{addressDestinationIndex + 1}}: {{address.address}} </p>
                <p class="p-0 m-0 visible-p">Destino {{ destinationIndex + 1}} ({{destination.name}}) Descargue
                  {{addressDestinationIndex + 1}}: {{address.address}} </p>
                <img [src]="imageDocument.url" class="cover-img" alt=""
                  [ngClass]="{'pdf-type': getIsPdfType(imageDocument?.type)}">
              </div>
            </div>
          </div>
          <ng-template #imagesTruckDownload>
            <div *ngIf="address?.imagesTruck?.length; else notEvidencesDownload">
              <div *ngFor="let imageTruck of address.imagesTruck; let imagesDownloadIndex=index;">
                <div *ngIf="imagesDownloadIndex === 0" class="cover-page"
                  (click)="showDetail(true,address, addressDestinationIndex, imageTruck.type === 'pdf' ? imageTruck.sanitizeUrlPdf : imageTruck.url, imageTruck.type, 'destination', destination.downloadDate, destination.name)">
                  <p class="p-0 m-0 hidden-p">Destino {{ destinationIndex + 1}} ({{destination.name}}) Descargue
                    {{addressDestinationIndex + 1}}: {{address.address}} </p>
                  <p class="p-0 m-0 visible-p">Destino {{ destinationIndex + 1}} ({{destination.name}}) Descargue
                    {{addressDestinationIndex + 1}}: {{address.address}} </p>
                  <img [src]="imageTruck.url" class="cover-img" [ngClass]="{'pdf-type': getIsPdfType(imageTruck?.type)}"
                    alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <!-- not evidences -->
          <ng-template #notEvidencesDownload>
            <div class="cover-page"
              (click)="showDetail(true, address,addressDestinationIndex, null, null, 'destination',  destination.downloadDate, destination.name)">
              <p class="p-0 m-0 hidden-p">Destino {{ destinationIndex + 1}} ({{destination.name}}) Descargue
                {{addressDestinationIndex + 1}}: {{address.address}} </p>
              <p class="p-0 m-0 visible-p">Destino {{ destinationIndex + 1}} ({{destination.name}}) Descargue
                {{addressDestinationIndex + 1}}: {{address.address}} </p>
              <div class="d-flex h-100 w-100 align-items-center justify-content-center not-evidences-container">
                <div class="not-evidences">No se han subido evidencias</div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-photos" *ngIf="showEvidences">
    <app-public-cargo-evidences-detail [typeMainImageUrl]="typeMainImageUrl" [address]="addressSelected" [index]="index"
      [dataCargo]="dataCargo" [mainImageUrl]="mainImageUrl" [type]="type"
      (emitToParent)="processEvents($event)"></app-public-cargo-evidences-detail>
  </div>
</div>
<ng-template #notResultsView>
  <div class="d-flex w-100 flex-column align-items-center justify-content-center not-results-container">
    <div class="icon"><i class="fas fa-times-circle"></i></div>
    <div class="not-results">{{notResults?.label}}</div>
  </div>
</ng-template>