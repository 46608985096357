import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../resources/utils';

@Pipe({
    name: 'basicState'
})
export class BasicStatePipe implements PipeTransform {

    constructor(
        public utils: Utils
    ) { }

    transform(state: boolean) {
        if (this.utils.isDefined(state)) {
            if (state) {
                return 'Activo';
            } else {
                return 'Inactivo';
            }
        }
        return 'Pendiente';
    }

}