import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';

@Component({
  selector: 'app-conventions',
  templateUrl: './conventions.component.html',
  styleUrls: ['./conventions.component.scss']
})
export class ConventionsComponent implements OnInit {
  paymentConventions = [
    { text: "Menos de 15 días", color: "blue-left" },
    { text: "Entre 15 y 30 días", color: "orange-left" },
    { text: "Más de 30 días", color: "red-left" },
    { text: "Pagadas", color: "green-left" },
  ];
  trackingTimeConventions = [
    { text: `Hasta ${AmountsCargoEnum.HIGH_ALERT_MINS} minutos desde la última trazabilidad`, color: "blue-baby" },
    { text: `Entre ${AmountsCargoEnum.HIGH_ALERT_MINS} y ${AmountsCargoEnum.MEDIUM_ALERT_MINS} minutos desde la última trazabilidad`, color: "green-baby" },
    { text: `Entre ${AmountsCargoEnum.MEDIUM_ALERT_MINS} y ${AmountsCargoEnum.LOW_ALERT_MINS} minutos desde la última trazabilidad`, color: "yellow-baby" },
    { text: `Entre ${AmountsCargoEnum.LOW_ALERT_MINS} y ${AmountsCargoEnum.EMPTY_ALERT_MINS} minutos desde la última trazabilidad`, color: "red-baby" },
    { text: `Más de ${AmountsCargoEnum.EMPTY_ALERT_MINS} minutos desde la última trazabilidad`, color: "grey-baby" },
  ]
  valueConventions = [
    { text: `Hasta ${AmountsCargoEnum.LOW_END_SHORT} millones o riesgo bajo`, color: "success" },
    { text: `Entre ${AmountsCargoEnum.LOW_END_SHORT} y ${AmountsCargoEnum.MEDIUM_END_SHORT} millones o riesgo medio`, color: "warning" },
    { text: `Más de ${AmountsCargoEnum.MEDIUM_END_SHORT} millones o riesgo alto`, color: "danger" },
  ];
  vehicleConventions = [
    { text: "Cuentas registradas y aprobadas", color: "green-left" },
    { text: "Cuentas registradas sin aprobar", color: "blue-left" },
    { text: "Sin cuenta de anticipos", color: "orange-left" },
    { text: "Sin cuenta de saldos", color: "yellow-left" },
    { text: "Cuentas sin registrar", color: "red-left" },
  ];
  mapsConventions = [
    { text: "Ruta hecha por el conductor", color: "route-driver" },
    { text: "Ruta sugerida por el sistema", color: "route-system" }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<ConventionsComponent>,
  ) { }

  ngOnInit() {
  }

}
