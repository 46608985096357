import { BankAccount } from '../interfaces/userBank';
import { DriverLicense } from '../interfaces/UserDriverLicense';
import { UserState } from '../interfaces/userState';
import { UserTeclogiScore } from '../interfaces/userTeclogiScore';
import { Truora, ARL, EPS } from './driver';
import { Truora as TruoraModel } from './truora.model';
import { StateModel } from './user-state.model';
import _ from 'lodash';
import { TermsConditionsModel } from './terms-conditions.model';
import { InformationModel } from './information-user.model';
import { ArlModel } from './arl.model';
import { EpsModel } from './eps.model';
import { BranchOffice } from './branch-office.model';
import { BranchOffice as BranchOfficeInt } from '../interfaces/branch-offices';
import { DriverLicenseCategory } from '../interfaces/driverLicenseCategory';

export class UserModel {
  // Basic fields
  private uId: string;
  private email: string;
  private exclusive: boolean;
  private simpleRegimen: boolean;
  private phone: string;
  private role: string;
  private roleId: string;
  private city: string;
  private address: string;
  // Admin fields
  private operationId: string;
  private companyName: string;
  // Driver fields
  private expeditionDateId: string;
  private driverLicense: DriverLicense;
  private driverLicenseCategory?: DriverLicenseCategory[];
  private teclogiScore: UserTeclogiScore;
  private state: StateModel;
  private truora: TruoraModel;
  private profilePicture: string;
  private frontDocument: string;
  private backDocument: string;
  private arl: ArlModel;
  private eps: EpsModel;
  private urlProfilePicture: string;

  private password: string;
  private validatedByCompany: boolean;
  private termAndCondition: TermsConditionsModel;
  // New
  private licenseNumber: string;
  private bankAccount: BankAccount;
  private bankCertificate: string;
  private information: InformationModel;
  private branchOffices: BranchOffice = new BranchOffice();
  private clientCompanyId: string;
  constructor() { }

  public getUId(): string {
    return this.uId;
  }

  public setUId(uId: string): void {
    this.uId = uId;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string): void {
    this.email = email;
  }

  public isExclusive(): boolean {
    return this.exclusive;
  }

  public isSimpleRegimen(): boolean {
    return this.simpleRegimen;
  }

  public setExclusive(exclusive: boolean): void {
    this.exclusive = exclusive;
  }

  public getPhone(): string {
    return this.phone;
  }

  public setPhone(phone: string): void {
    this.phone = phone;
  }

  public getRole(): string {
    return this.role;
  }

  public setRole(role: string): void {
    this.role = role;
  }

  public getRoleId(): string {
    return this.roleId;
  }

  public setRoleId(roleId: string): void {
    this.roleId = roleId;
  }

  public getCity(): string {
    return this.city;
  }

  public setCity(city: string): void {
    this.city = city;
  }

  public getAddress(): string {
    return this.address;
  }

  public setAddress(address: string): void {
    this.address = address;
  }

  public getOperationId(): string {
    return this.operationId;
  }

  public setOperationId(operationId: string): void {
    this.operationId = operationId;
  }

  public setClientCompanyId(clientCompanyId: string): void {
    this.clientCompanyId = clientCompanyId;
  }

  public getClientCompanyId(): string {
    return this.clientCompanyId;
  }

  public getCompanyName(): string {
    return this.companyName;
  }

  public setCompanyName(companyName: string): void {
    this.companyName = companyName;
  }

  public getExpeditionDateId(): string {
    return this.expeditionDateId;
  }

  public setExpeditionDateId(expeditionDateId: string): void {
    this.expeditionDateId = expeditionDateId;
  }

  public getTeclogiScore(): UserTeclogiScore {
    return this.teclogiScore;
  }

  public setTeclogiScore(teclogiScore: UserTeclogiScore): void {
    this.teclogiScore = teclogiScore;
  }
  public getState(): StateModel {
    return this.state || new StateModel();
  }

  public setState(state: StateModel): void {
    this.state = new StateModel();
    this.state.deserialize(state);
  }

  public getTruora(): TruoraModel {
    return this.truora || new TruoraModel();
  }

  public setTruora(truora: TruoraModel): void {
    this.truora = new TruoraModel();
    this.truora.deserialize(truora);
  }

  public getProfilePicture(): string {
    return this.profilePicture;
  }

  public setProfilePicture(profilePicture: string): void {
    this.profilePicture = profilePicture;
  }

  public getFrontDocument(): string {
    return this.frontDocument;
  }

  public setFrontDocument(frontDocument: string): void {
    this.frontDocument = frontDocument;
  }

  public getBackDocument(): string {
    return this.backDocument;
  }

  public setBackDocument(backDocument: string): void {
    this.backDocument = backDocument;
  }

  public getArl(): ArlModel {
    return this.arl || new ArlModel();
  }
  public setArl(arl: ArlModel): void {
    this.arl = new ArlModel();
    this.arl.deserialize(arl);
  }

  public getEps(): EpsModel {
    return this.eps || new EpsModel();
  }

  public setEps(eps: EpsModel): void {
    this.eps = new EpsModel();
    this.eps.deserialize(eps);
  }

  public getDriverLicense(): DriverLicense {
    return this.driverLicense || new DriverLicense();
  }
  public setDriverLicense(driverLicense: DriverLicense): void {
    this.driverLicense = new DriverLicense();
    this.driverLicense.deserialize(driverLicense);
  }

  public getDriverLicenseCategory(): DriverLicenseCategory[] {
    return (this.driverLicenseCategory || [])
  }

  public setDriverLicenseCategory(driverLicenseCategory: DriverLicenseCategory[]): void {
    this.driverLicenseCategory = driverLicenseCategory;
  }

  public getUrlProfilePicture(): string {
    return this.urlProfilePicture;
  }

  public setUrlProfilePicture(urlProfilePicture: string): void {
    this.urlProfilePicture = urlProfilePicture;
  }

  public getPassword(): string {
    return this.password;
  }

  public setPassword(password: string): void {
    this.password = password;
  }

  public isValidatedByCompany(): boolean {
    return this.validatedByCompany;
  }

  public setValidatedByCompany(validatedByCompany: boolean): void {
    this.validatedByCompany = validatedByCompany;
  }

  public getTermAndCondition(): TermsConditionsModel {
    return this.termAndCondition || new TermsConditionsModel();
  }

  public setTermAndCondition(termAndCondition: TermsConditionsModel): void {
    this.termAndCondition = new TermsConditionsModel();
    this.termAndCondition.deserialize(termAndCondition);
  }

  public getLicenseNumber(): string {
    return this.licenseNumber;
  }

  public setLicenseNumber(licenseNumber: string): void {
    this.licenseNumber = licenseNumber;
  }

  public getBankAccount(): BankAccount {
    return this.bankAccount;
  }

  public setBankAccount(bankAccount: BankAccount): void {
    this.bankAccount = bankAccount;
  }

  public getBankCertificate(): string {
    return this.bankCertificate;
  }

  public setBankCertificate(bankCertificate: string): void {
    this.bankCertificate = bankCertificate;
  }

  public getInformation(): InformationModel {
    return this.information || new InformationModel();
  }

  public setInformation(information: InformationModel): void {
    this.information = new InformationModel();
    this.information.deserialize(information);
  }

  public getBranchOffices(): BranchOffice {
    return this.branchOffices || new BranchOffice();
  }

  public setBranchOffices(branchOffices: BranchOffice): void {
    this.branchOffices = new BranchOffice();
    this.branchOffices.deserialize(branchOffices);
  }

  public deserialize(input: any) {
    Object.assign(this, input);

    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        if (this["set" + key.charAt(0).toUpperCase() + key.slice(1)]) {
          this["set" + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
        }
      }
    }

    return this;
  }

  public serialize(): BranchOfficeInt {
    let obj: BranchOfficeInt = {};
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        if (this["get" + key.charAt(0).toUpperCase() + key.slice(1)]) {
          obj[key.toString()] = this[key];
        }
      }
    }
    return obj;
  }
}
