import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { OptionsDialog } from '../interfaces/options-dialog';
import { SizeDialog } from '../interfaces/size-dialog';
import { ModalEnum } from '../enums/modal.enum';

export class Dialog {

  constructor(
    private dialog: MatDialog
  ) {

  }

  openDialog(options: OptionsDialog, disableClose?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = options;
      dialogConfig.height = 'auto';
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      if (disableClose) {
        dialogConfig.disableClose = true;
      }
      const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        result ? result.state ? resolve(result) : reject(result) : reject();
      });
    });
  }

}
