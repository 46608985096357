import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewsComponent } from './news/news.component';
import { SitesComponent } from './sites/sites.component';
import { UnsafeConditionsComponent } from './unsafe-conditions/unsafe-conditions.component';
import { PlanningFormComponent } from './planning-form/planning-form.component';
import { PlanningRouteListComponent } from './planning-route-list/planning-route-list.component';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { UserResolver } from 'src/app/core/resolves/user.resolve';


const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Rutas' },
    children: [
      {
        path: 'news',
        data: { breadcrumb: 'Novedades' },
        component: NewsComponent,
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        },
      },
      {
        path: 'planning',
        data: { breadcrumb: 'Planeación' },
        children: [
          {
            path: 'form',
            component: PlanningFormComponent,
            data: { breadcrumb: 'Creación' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          {
            path: 'form/:id',
            component: PlanningFormComponent,
            data: { breadcrumb: 'Detalle' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          {
            path: '',
            component: PlanningRouteListComponent,
            data: { breadcrumb: 'Listado' },
            canActivate: [UserActiveGuard],
            resolve: {
              UserResolver
            },
          },
          { path: '**', redirectTo: '/routes/planning' }
        ]
      },
      {
        path: 'sites',
        data: { breadcrumb: 'Puntos de control y Sitios autorizados' },
        component: SitesComponent,
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        },
      },
      /*{
        path: 'unsafe-conditions',
        data: { breadcrumb: 'Condiciones inseguras' },
        component: UnsafeConditionsComponent,
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        },
      },*/
      { path: '**', redirectTo: '/routes/planning' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutesRoutingModule { }
