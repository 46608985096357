<div class="main">
    <div class="container-image-text container-overflow-h">
        <div class="container-text">
            <div class="title color-white">Propuestas de valor especializadas</div>
            <div class="button">
                <a class="text-button " routerLink='/contact'>
                    Contactenos
                </a>
            </div>
        </div>
    </div>
</div>
<div class="content row background-white only-padding">
    <div class="col-10">
        <div class="row container-overflow-h">
            <div class="col-12">
                <h1 class="tittle-purple">T-Long Haul/ T-pool</h1>
                <p>Aumenta tu eficiencia en <b>comercio exterior</b> y en tus entregas/distribución nacional.
                    <b>Rentable y flexible</b>. Paga por lo que usas.
                </p>
            </div>
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Marketplace camiones a carga
                    </div>
                    <ul>
                        <li>
                            Crea tus cargas en menos de 1 minuto y conecta con camiones en menos de 5 minutos.
                        </li>
                        <li>
                            Tarifas estables con la mayor disponibilidad.
                        </li>
                        <li>
                            Ahorra hasta un 40% de costos de transporte linea Pool Paga por lo que usas.
                        </li>
                        <li>
                            Planea y coordina nuestro servicio ágilmente.
                        </li>
                        <li>
                            Comparte tu viaje y ahorra costos. Paga por lo que usas
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="/assets/images/suggestions/cel-loggiapp.png" alt="loggiapp celphone">
            </div>
        </div>
        <hr>
        <div class="row container-overflow-h">
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="/assets/images/suggestions/Trazabilidad-tiempo-real-loggiapp.png" alt="loggiapp map">
            </div>
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Trazabilidad tiempo real
                    </div>
                    <ul>
                        <li>
                            Trazabilidad en tiempo real desde tu smartphone o PC..
                        </li>
                        <li>
                            Comparte la información del viaje con los actores interesados.
                        </li>
                        <li>
                            Fotografías cargues, descargues, PODS en tiempo real y KPIS.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr>
        <div class="row container-overflow-h">
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Amplia cobertura
                    </div>
                    <ul>
                        <li>
                            Servicio de ITR en Cartagena y Buenaventura.
                        </li>
                        <li>
                            Servicio de almacenamiento y plataformas cross docking a nivel nacional.
                        </li>
                        <li>
                            Servicio consolidado y expreso.
                        </li>
                        <li>
                            Transporte de mercancías especiales (Refrigerados, graneles, Break bulk)
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="/assets/images/suggestions/gps-loggiapp.png" alt="loggiapp map">
            </div>
        </div>
    </div>
</div>
<div class="content row only-padding">
    <div class="col-10">
        <div class="row container-overflow-h">
            <div class="col-12">
                <h1 class="tittle-purple">T- warehouse</h1>
                <p>Paga por lo que usas. Dark Store, almacenamiento temporal y permanente, plataformas cross
                    docking, ITR.
                </p>
            </div>
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Ventajas
                    </div>
                    <ul>
                        <li>
                            Capacidad almacenamiento en estantería, a piso, dark store, plataformas de cross
                            docking.
                        </li>
                        <li>
                            Capacidad de atención de bodegas exclusivas y multiclientes en instalaciones propias o
                            del
                            cliente.
                        </li>
                        <li>
                            WMS para administración de inventarios e interfases con sistemas ERP y TMS.
                        </li>
                        <li>
                            Desarrollo e implementación de diversas tecnologías como:
                            Voice to picking, Touch to picking.
                        </li>
                        <li>
                            No pagues mas moras.
                        </li>
                        <li>
                            Optimiza tu control con nuestra herramienta
                        </li>
                        <li>
                            Haz una mejor planeación de tus operaciones.
                        </li>
                        <li>
                            Reduce costos de almacenamiento rentando contenedores pagando el tiempo real de uso.
                        </li>
                        <li>
                            Si necesitas una Bodega nosotros la tenemos.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="assets/images/suggestions/logo1.png" alt="loggiapp">
                <img class="image-container" src="/assets/images/suggestions/logo2.png" alt="loggiapp">
            </div>
        </div>
    </div>
</div>
<div class="content row background-white only-padding">
    <div class="col-10">
        <div class="row container-overflow-h">
            <div class="col-12">
                <h1 class="tittle-purple">T-Last Mile/ T-Home</h1>
                <p>A tiempo y en el lugar correcto. Rutas planificadas y la mejor experiencia de entrega.
                    Todos tus clientes felices con las entregas de sus compras en casa.
                </p>
            </div>
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Ruteo inteligente
                    </div>
                    <ul>
                        <li>
                            Optimización de rutas hasta en un 40%
                        </li>
                        <li>
                            Parametrización rutas restricciones: Ventanas de recepción, capacidad de Vehículo,
                            capacidad
                            entrega, pico y placa y limitaciones de movilidad.
                        </li>
                        <li>
                            Zonificación según georeferenciación.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="/assets/images/suggestions/ruteo.png" alt="loggiapp root">
            </div>
        </div>
        <hr>
        <div class="row container-overflow-h">
            <div class="col-12 col-md-6 container-image">
                <img class=" image-container" src="/assets/images/suggestions/versatilidad-operativa-loggiapp.png" alt="loggiapp team">
            </div>
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Versatilidad operativa
                    </div>
                    <ul>
                        <li>
                            Flexibilidad en la tipología de vehículos.
                        </li>
                        <li>
                            Entregas Nocturnas.
                        </li>
                        <li>
                            Servicio programado y expreso
                            Entregas e instalación de producto dentro del domicilio.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr>
        <div class="row container-overflow-h">
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Servicio y Trazabilidad 360
                    </div>
                    <ul>
                        <li>
                            Trazabilidad en línea y tiempo real.
                        </li>
                        <li>
                            PODs en línea y KPIs
                        </li>
                        <li>
                            Medición causales rechazo
                        </li>
                        <li>
                            Servicio Backoffice
                        </li>
                        <li>
                            Logistica inversa
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="/assets/images/suggestions/pc-loggiapp.png" alt="loggiapp pc">
            </div>
        </div>
    </div>
</div>
<div class="content row only-padding">
    <div class="col-10">
        <div class="row container-overflow-h">
            <div class="col-12">
                <h1 class="tittle-purple">T- Ecommerce / T- Fulfillment</h1>
                <p>Omnicanalidad y Fulfillment Aumenta tus ventas al conectarte con tus clientes en todos los
                    canales.
                </p>
            </div>
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Omnicanalidad
                    </div>
                    <ul>
                        <li>
                            Desarrollo y operación de tiendas virtuales.
                        </li>
                        <li>
                            Implementación y operación de call centers.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="assets/images/suggestions/image-lapto-loggiapp.png" alt="loggiapp">
            </div>
        </div>
        <hr>
        <div class="row container-overflow-h">
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="/assets/images/suggestions/mask-group.png" alt="loggiapp team">
            </div>
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Dark Store
                    </div>
                    <ul>
                        <li>
                            Implementación y operación de dark stores en formatos de bajo costo y agilidad en los
                            envíos.
                        </li>
                        <li>
                            Ubicación estratégica en cada ciudad.
                        </li>
                        <li>
                            WMS para control y administración de inventarios.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr>
        <div class="row container-overflow-h">
            <div class="col-12 col-md-6">
                <div class="info-container">
                    <div class="tittle-gray">
                        Fulfillment
                    </div>
                    <ul>
                        <li>
                            Logística integral de recogida almacenamiento y entrega al cliente final.
                        </li>
                        <li>
                            Logística inversa, no te preocupes por las devoluciones nosotros nos encargamos.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 container-image">
                <img class="image-container" src="/assets/images/suggestions/image-cliente-loggiapp.jpg" alt="loggiapp pc">
            </div>
        </div>
    </div>
</div>