<form class="padding-modal position-relative" [formGroup]="GPSForm" (submit)="onSubmit()">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <span class="separator-horizontal"></span>
    <div class="title-2 header">
        <div class="titulo color-secondary-dark">
            Crear GPS
        </div>
    </div>
    <div class="row container-form">
        <div class="col-md-5">
            <mat-form-field appearance="standard">
                <mat-label>Nombre<span class="asterisk">*</span></mat-label>
                <input matInput type="text" formControlName="name">
                <mat-error *ngIf="GPSForm.get('name').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-5">
            <mat-form-field appearance="standard">
                <mat-label>URL<span class="asterisk">*</span></mat-label>
                <input matInput type="text" formControlName="id">
                <mat-error *ngIf="GPSForm.get('id').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-2 icon-add pl-md-0" (click)="addGPS()">
            <i class="fas fa-plus-circle"></i>
            <span>Añadir</span>
        </div>
    </div>
    <div class="container-list" *ngIf="arrayGPS && arrayGPS.length>0">
        <div class="row container-card-gps" *ngFor="let gps of arrayGPS; let i = index">
            <div class="col-4 word-wrap">
                <section class="title-item">Nombre</section>
                {{gps.name}}
            </div>
            <div class="col-7 word-wrap">
                <section class="title-item">url</section>
                <a href="{{gps.id}}">{{gps.id}}</a>
            </div>
            <div class="col-1">
                <i class="fas fa-times" (click)="deleteGPS(i)"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 container-button">
            <mat-card-actions class="text-center">
                <button mat-raised-button color="primary" type="submit" class="button-width-auto">
                    Guardar
                </button>
            </mat-card-actions>
        </div>
    </div>
</form>