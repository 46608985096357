<div class="tags-list p-1 p-sm-3">
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title class="text-strong">
                Mis etiquetas
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Las etiquetas te permitirán marcar las solicitudes de transporte de carga.</p>
        <div class="d-flex flex-wrap gap-0-100">
            <div *ngFor="let tag of tagList" class="ml-1 ml-sm-4 d-flex align-items-center my-2">
                <div class="tag-card">
                    <p class="text-bold mb-0 text-break">{{tag?.name}}</p>
                </div>
            </div>
        </div>
        <button mat-raised-button type="button" color="primary" class="bg-color-primary ml-1 ml-sm-4 mt-3"
            (click)="createTag()" permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.editTags">
            <i class="fas fa-plus-circle cursor-pointer"></i> <span class="d-none d-sm-inline">Crear etiqueta</span>
        </button>
    </mat-expansion-panel>
</div>