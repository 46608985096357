<form class="routing__form--content" [formGroup]="form" (submit)="onSubmit()">
    <div class="routing__form--header">
        <label class="routing__form--label">
            {{form?.get('fleetFromSchema')?.value ? 'Flota seleccionada' : 'Seleccionar flota'}}
        </label>
    </div>

    <div class="routing__form--body">

        <mat-card-actions class="fleet__actions_body" *ngIf="!form?.get('fleetFromSchema')?.value">
            <button mat-raised-button color="primary" class="bg-color-primary" type="button" (click)="createFleet()">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <mask id="mask0_1_15681" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30"
                        height="30">
                        <rect width="30" height="30" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1_15681)">
                        <path
                            d="M13.75 21.25H16.25V16.25H21.25V13.75H16.25V8.75H13.75V13.75H8.75V16.25H13.75V21.25ZM15 27.5C13.2708 27.5 11.6458 27.1719 10.125 26.5156C8.60417 25.8594 7.28125 24.9688 6.15625 23.8438C5.03125 22.7188 4.14063 21.3958 3.48438 19.875C2.82812 18.3542 2.5 16.7292 2.5 15C2.5 13.2708 2.82812 11.6458 3.48438 10.125C4.14063 8.60417 5.03125 7.28125 6.15625 6.15625C7.28125 5.03125 8.60417 4.14063 10.125 3.48438C11.6458 2.82812 13.2708 2.5 15 2.5C16.7292 2.5 18.3542 2.82812 19.875 3.48438C21.3958 4.14063 22.7188 5.03125 23.8438 6.15625C24.9688 7.28125 25.8594 8.60417 26.5156 10.125C27.1719 11.6458 27.5 13.2708 27.5 15C27.5 16.7292 27.1719 18.3542 26.5156 19.875C25.8594 21.3958 24.9688 22.7188 23.8438 23.8438C22.7188 24.9688 21.3958 25.8594 19.875 26.5156C18.3542 27.1719 16.7292 27.5 15 27.5ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z"
                            fill="white" />
                    </g>
                </svg>
                Agregar flota
            </button>
        </mat-card-actions>

        <div class="fleet__list" *ngIf="(fleets?.length && !form?.get('fleetFromSchema')?.value)||schemaFleetExists ">
            <div class="fleet__filter" *ngIf="!form?.get('fleetFromSchema')?.value">
                <mat-form-field appearance="outline" class="fleet__filter_item">
                    <mat-label>Buscar por nombre</mat-label>
                    <input type="text" matInput [formControl]="searchCtrl">
                </mat-form-field>
            </div>
            <div class="fleet" *ngFor="let fleet of filteredFleets" (click)="onSelectFleet(fleet)"
                [ngClass]="{'selected':isSelectedFleet(fleet), 'd-none':form?.get('fleetFromSchema')?.value && !isSelectedFleet(fleet)}">
                <div class="fleet__title pr-4">{{fleet.fleetName | titlecase}}</div>
                <div class="fleet__content pl-1 pl-md-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        class="">
                        <mask id="mask0_46_6954" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_46_6954)">
                            <path
                                d="M6 20C5.16667 20 4.45833 19.7083 3.875 19.125C3.29167 18.5417 3 17.8333 3 17H1V6C1 5.45 1.19583 4.97917 1.5875 4.5875C1.97917 4.19583 2.45 4 3 4H17V8H20L23 12V17H21C21 17.8333 20.7083 18.5417 20.125 19.125C19.5417 19.7083 18.8333 20 18 20C17.1667 20 16.4583 19.7083 15.875 19.125C15.2917 18.5417 15 17.8333 15 17H9C9 17.8333 8.70833 18.5417 8.125 19.125C7.54167 19.7083 6.83333 20 6 20ZM6 18C6.28333 18 6.52083 17.9042 6.7125 17.7125C6.90417 17.5208 7 17.2833 7 17C7 16.7167 6.90417 16.4792 6.7125 16.2875C6.52083 16.0958 6.28333 16 6 16C5.71667 16 5.47917 16.0958 5.2875 16.2875C5.09583 16.4792 5 16.7167 5 17C5 17.2833 5.09583 17.5208 5.2875 17.7125C5.47917 17.9042 5.71667 18 6 18ZM18 18C18.2833 18 18.5208 17.9042 18.7125 17.7125C18.9042 17.5208 19 17.2833 19 17C19 16.7167 18.9042 16.4792 18.7125 16.2875C18.5208 16.0958 18.2833 16 18 16C17.7167 16 17.4792 16.0958 17.2875 16.2875C17.0958 16.4792 17 16.7167 17 17C17 17.2833 17.0958 17.5208 17.2875 17.7125C17.4792 17.9042 17.7167 18 18 18ZM17 13H21.25L19 10H17V13Z"
                                fill="#495356" />
                        </g>
                    </svg>
                    <span>{{fleet.vehicleQuantity===1?'Vehículo':'Vehículos'}}</span>
                    <span class="text-bold">
                        {{fleet.vehicleQuantity}}
                    </span>
                </div>
                <div class="fleet__content pl-1 pl-md-3" *ngFor="let capacity of fleet?.capacityMeasures">
                    <i class="fas fa-truck-loading" style="color: #495356; width: 24px; height: 24px;"></i>
                    <span>{{capacity?.name}}</span>
                    <span class="text-bold">
                        {{capacity?.capacityIndex===1? fleet.capacity1 : capacity?.capacityIndex===2? fleet.capacity2 :
                        fleet.capacity3}} {{capacity?.measure}}
                    </span>
                </div>
                <i (click)="onEditFleet(fleet)" class="fas fa-pencil-alt color-primary cursor-pointer"
                    matTooltip="Editar flota"></i>
            </div>
        </div>
        <p class="alert alert-warning" role="alert" *ngIf="!fleets?.length">
            No hay flotas registradas o no fue posible cargarlas
        </p>
        <p class="alert alert-danger" role="alert"
            *ngIf="fleets?.length && form?.get('fleetFromSchema')?.value && !schemaFleetExists">
            No fue posible encontrar la flota asociada al esquema
        </p>
    </div>

    <div class="button-container routing__form--footer">
        <button mat-raised-button color="primary" type="submit"
            [ngClass]="{'bg-color-primary':isValidStep, 'disabled-btn':!isValidStep}">
            Continuar
        </button>
    </div>

</form>