export enum CheckListQuestion {
    IDP = "IDP",
    IPA = "IPA",
    LNM = "LNM",
    LCN = "LCN",
    CPM = "CPM",
    CNC = "CNC",
    RNS = "RNS",
    CHP = "CHP",
    MFR = "MFR",
    HRS = "HRS",
    LBS = "LBS",
    SYI = "SYI",
    PBO = "PBO",
    BHA = "BHA",
    SPA = "SPA",
    REA = "REA",
    CBA = "CBA",
    LHA = "LHA",
    DLA = "DLA",
    LRA = "LRA",
    LRF = "LRF",
    LHS = "LHS",
    LFF = "LFF",
    LCA = "LCA",
    LCS = "LCS",
    LCY = "LCY",
    LPU = "LPU",
    LAP = "LAP",
    RRA = "RRA",
    RRF = "RRF",
    RHS = "RHS",
    RFF = "RFF",
    RCA = "RCA",
    RCS = "RCS",
    RCY = "RCY",
    RPU = "RPU",
    RAP = "RAP",
    RUS = "RUS",
    RRS = "RRS",
    IER = "IER",
    IMD = "IMD",
    RHC = "RHC",
    IPF = "IPF",
    IUS = "IUS",
    IDL = "IDL",
    ISD = "ISD",
    IBS = "IBS",
    EVW = "EVW",
    EVB = "EVB",
    FHS = "FHS",
    FRD = "FRD",
    RRU = "RRU",
    LRU = "LRU",
    RBE = "RBE",
}

export enum CheckListQuestionVehicle {
    LAT = "LAT",
    PYC = "PYC",
    AAC = "AAC",
    SBP = "SBP",
    EVI = "EVI",
    EES = "EES",
    SDR = "SDR",
    SPT = "SPT",
    EPL = "EPL",
    EFP = "EFP",
    LDC = "LDC",
    LDT = "LDT",
    LDR = "LDR",
    FDE = "FDE",
    ASR = "ASR",
    EIV = "EIV",
    CDI = "CDI",
    CDH = "CDH",
    FBA = "FBA",
    CCD = "CCD",
    CCT = "CCT",
    LDE = "LDE",
    DID = "DID",
    DIT = "DIT",
    LPR = "LPR",
    STP = "STP",
    LST = "LST",
    CDC = "CDC",
    LIN = "LIN",
    AGV = "AGV",
    IGG = "IGG",
    QRP = "QRP",
    PMC = "PMC",
    BYC = "BYC",
    TCA = "TCA",
    PAR = "PAR",
    EPA = "EPA",
    SFT = "SFT",
    FPR = "FPR",
    LIM = "LIM",
    KDD = "KDD",
    BPA = "BPA",
    JDH = "JDH",
    COT = "COT",
    GEM = "GEM",
    CRU = "CRU",
    FDA = "FDA",
    PAF = "PAF",
    PIS = "PIS",
    EYB = "EYB",
    COR = "COR",
    LAI = "LAI",
    TEC = "TEC",
    PIN = "PIN",
    CAR = "CAR",
    LAR = "LAR",
    PYM = "PYM",
    AMR = "AMR",
    PLA = "PLA",
    TBC = "TBC",
    GOA = "GOA",
    GUA = "GUA",
    MDS = "MDS",
    CHR = "CHR",
    CSC = "CSC",
}

export interface CheckLists {
    question: CheckListQuestion | CheckListQuestionVehicle;
    response: 'B' | 'M' | 'N/A';

}
