import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Global } from 'src/app/core/resources/global';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ModalSelectionMapComponent } from 'src/app/shared/modal-selection-map/modal-selection-map.component';
import { RoutingService } from '../../routing.service';
import { Scenario } from 'src/app/core/interfaces/scenario';
import { Router } from '@angular/router';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { Fmt } from 'src/app/core/messages/fmt';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';

@Component({
  selector: 'app-routing-confirm-address',
  templateUrl: './routing-confirm-address.component.html',
  styleUrls: ['./routing-confirm-address.component.scss'],
})
export class RoutingConfirmAddressComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() refreshMap: EventEmitter<any> = new EventEmitter();

  constructor(
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private global: Global,
    private spinner: NgxSpinnerService,
    private routingService: RoutingService,
    private router: Router,
    private utils: Utils
  ) { }

  /**
  * @description Executes the method "showLocations"
  */
  ngOnInit(): void {
    this.showLocations();
  }

  /**
  * @description Creates an structure to show the markers and emits them to the parent
  */
  private showLocations(): void {
    let path = [];
    let clients = this.form.get('clients') as FormArray;
    clients.controls.forEach((client) => {
      if (client.value && client.value.lat && client.value.lng) {
        let point = {
          key: client.value.address,
          lat: client.value.lat,
          lng: client.value.lng,
          icon: this.global.pathMarkerDestination,
          contentInfoWindow: `<b>Dirección:</b> ${client.value.name}<br/>`,
          showMarker: true
        };
        path.push(point);
      }
    })
    this.refreshMap.emit([{ 'route': { path } }, true]);
  }

  /**
  * @description Executes the method "hideLocations" and sends to last step
  */
  goBack(): void {
    this.hideLocations();
    this.form.get('showConfirmAddresses').setValue(false);
  }

  /**
  * @description Emits an empty array to parent to hide the markers
  */
  private hideLocations(): void {
    this.refreshMap.emit([[], false]);
  }

  /**
  * @param {number} index is the index of the address to update
  * @description Opens a modal to update the location of an address
  */
  openModalCheckLocations(index: number): void {
    const client = (this.form.get('clients') as FormArray).at(index);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      clickMap: true
    };
    dialogConfig.height = ModalEnum.FORMS_HEIGHT;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(ModalSelectionMapComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result && result.state && result.data && result.data.location) {
        client.setValue({
          ...client.value,
          lat: result.data.location.lat,
          lng: result.data.location.lng,
        });
        this.showLocations();
      }
    });
    this.snackBarService.openSnackBar(Fmt.string(RoutingMessages.SELECT_ADDRESS, client.value.address), null, 'info');
  }

  /**
  * @description Verifies if the form's control "clients" are valid (with lat,lng) to allow pass to next step
  */
  onSubmit(): void {
    const clientsArr: FormArray = this.form.get('clients') as FormArray;
    let hasErrors = false;
    for (const [index, client] of clientsArr.controls.entries()) {
      if (client.value && (!client.value.lat || !client.value.lng)) {
        this.snackBarService.openSnackBar(Fmt.string(RoutingMessages.ADDRESS_WITHOUT_COORDS, index + 1), undefined, "alert");
        hasErrors = true;
        break;
      }
    }
    if (hasErrors) return;
    this.createRouting();
  }

  /**
  * @description Creates the scenario from form's values
  */
  private createRouting(): void {
    let scenario: Scenario = this.form.value;
    delete scenario['file'];
    delete scenario['showConfirmAddresses'];
    delete scenario['fleetFromSchema'];
    this.spinner.show();
    this.routingService.createScenario(scenario).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(RoutingMessages.SCENARIO_CREATED);
        this.router.navigate(['routing/route-list']);
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }

  /**
  * @returns {boolean} returns true if the form's control "clients" has lat and lng.
  * @description Verifies if the current step is valid
  */
  get isValidStep(): boolean {
    return this.form && this.form.get('clients') && this.form.get('clients').value && this.form.get('clients').value.every(client => client.lat && client.lng);
  }

}
