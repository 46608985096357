<div class="container">
  <div id="mainFreightTypeLoad" class="col-md-12 mb-md-0 mb-12">

    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          Selecciona un tipo de régimen
        </label>
      </div>
      <!-- Boxs -->
      <app-box-type-regime
        [regimenTripeSelect]="manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.regimeType.value"
        [tripSelectedName]=" manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.name.value"
        (emitToParent)="selectRegimeType($event)"></app-box-type-regime>

      <div class="w-100 row"
        *ngIf="(typeRegimen === 'DUTA' || typeRegimen === 'OTM') || (manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.regimeType.value === 'DUTA' || manualCreationCargoService.cargoForm.controls.cargoModel['controls'].tripType.controls.regimeType.value === 'OTM')">
        <div class="col-12 mb-md-0 mt-5">
          <label class="title">
            ¿En qué fecha finalizó la descarga del buque?
          </label>
        </div>
        <mat-form-field appearance="standard" class="col-lg-6">
          <mat-label>Fecha de descarga<span class="asterisk">*</span></mat-label>
          <input matInput [matDatepicker]="picker" (dateChange)="onDateSelected($event)" [formControl]="dateFieldDTA"
            placeholder="Seleccione una fecha" (click)="picker.open()" readonly>
          <mat-error *ngIf="dateFieldDTA.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="alert alert-warning mt-2 col-lg-6" role="alert" *ngIf="dtaAlert">
          Selecciona una fecha de descargue para poder continuar
        </div>
      </div>
    </div>

    <!-- Button -->
    <div class="col-md-12 mb-md-0 mb-12">
      <div class="row">
        <div class="button-container justify-content-center mt-3 w-100">
          <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
          <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>