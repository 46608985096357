import { Component, OnInit, EventEmitter, DoCheck, IterableDiffers, KeyValueDiffers, SimpleChanges, HostListener } from '@angular/core';
import { CollectionPoint } from 'src/app/core/interfaces/collectionPoint';
import { CollectionPointService } from '../collection-point.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Global } from 'src/app/core/resources/global';
import { User } from 'src/app/core/interfaces/user';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';


@Component({
  selector: 'app-collection-point-list',
  templateUrl: './collection-point-list.component.html',
  styleUrls: ['./collection-point-list.component.scss']
})
export class CollectionPointListComponent implements OnInit {

  listCollectionPoints: CollectionPoint[];

  farmNameFilter: string = "";

  throttle = 300;
  scrollDistance = 1;
  enablePagingScroll: boolean = true;
  pageKey: number = 1;
  pageSize: number = 25;

  constructor(
    private collectionPointService: CollectionPointService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public global: Global,
    public authService: AuthService,
    public utils: Utils
  ) {

  }

  ngOnInit() {
    this.getCollectionPoint();
  }

  getCollectionPoint() {
    this.spinner.show();
    this.collectionPointService.getCollectionPoint(this.pageKey, this.pageSize, this.farmNameFilter).subscribe(
      (success: CollectionPoint[]) => {

        if (this.utils.isDefined(success)) {
          if (success && success.length < 1) {
            this.enablePagingScroll = false;
          } else {
            if (this.listCollectionPoints && this.listCollectionPoints.length) {
              this.listCollectionPoints = this.listCollectionPoints.concat(success);
            } else {
              this.listCollectionPoints = success;
            }
          }
        } else {
          if (!this.utils.isDefined(success) && this.pageKey === 1) {
            this.listCollectionPoints = [];
          } else if (!this.utils.isDefined(success) && this.pageKey > 1) {
            this.enablePagingScroll = false;
          }
        }

        this.spinner.hide();
      },
      (error) => {
        this.listCollectionPoints = [];
        this.spinner.hide();
      }
    );
  }

  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getCollectionPoint();
    }
  }

  applyFilter() {
    this.listCollectionPoints = [];
    this.enablePagingScroll = true;
    this.pageKey = 1;
    this.pageSize = 25;
    this.getCollectionPoint();
  }

  cleanFilter() {
    this.listCollectionPoints = [];
    this.farmNameFilter = "";
    this.enablePagingScroll = true;
    this.pageKey = 1;
    this.pageSize = 25;
    this.getCollectionPoint();
  }

  onRefreshList() {
    this.cleanFilter();
  }

}
