<div class="file-input-wrapper">
  <input (change)="handleFileInput($event)" id="file" type="file" [accept]="'.'+options?.format"
    class="fancy-file-container">
  <label for="file"
    [ngStyle]="{'border':visualFileName?.value && formatError ? '2px solid #E2574C' : '1px solid rgba(0,0,0,.12)'}"
    class="fancy-file-content d-flex align-items-center justify-content-start pl-4">
    <i class="fas fa-file-upload mr-2"></i>
    <span [ngStyle]="{'color':visualFileName?.value ? formatError ? '#C41214' : '#000' : '#02D7DC' }"
      [ngClass]="{'file-title': !visualFileName?.value}" class="pr-4 cursor-pointer">{{ visualFileName?.value ?
      formatError ? 'Subir archivo' : visualFileName?.value : 'Subir archivo'}}</span>
  </label>
  <span *ngIf="options?.format"
    [ngClass]="{'type-doc-alert': !visualFileName?.value, 'doc-alert-success': visualFileName?.value, 'doc-alert-error': visualFileName?.value && formatError }"
    class="ml-1">
    {{ visualFileName?.value ? (formatError ? 'El archivo no es formato .' + (options?.format || '') : 'Archivo
    cargado') : 'Solo se permite archivos .' + (options?.format || '') }}
  </span>
</div>