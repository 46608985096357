import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { PlanningRoute } from 'src/app/core/interfaces/planning-route';
import { Utils } from 'src/app/core/resources/utils';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';


@Component({
  selector: 'planning-route-app-list',
  templateUrl: './planning-route-list.component.html',
  styleUrls: ['./planning-route-list.component.scss']
})
export class PlanningRouteListComponent implements OnInit {
  public sort_order = 0; //0 Normal, 1 ToLow, 2 ToUp
  public sort_key: 'origin' | 'destination' | 'distance' = 'origin';

  filters = [];
  originControl: FormControl = new FormControl('');
  originSub: Subscription;
  originOptions: OptionsAutocomplete = { title: "Ciudad de origen", appearance: "outline", showXMark: true }
  originCity = null;
  destinationControl: FormControl = new FormControl('');
  destinationSub: Subscription;
  destinationOptions: OptionsAutocomplete = { title: "Ciudad de destino", appearance: "outline", showXMark: true }
  destinationCity = null;
  routes: PlanningRoute[] = [];
  original_routes: PlanningRoute[] = [];

  constructor(
    private _router: Router,
    private _utils: Utils,
    private spinner: NgxSpinnerService,
    private planningRouteService: PlanningRouteService
  ) { }



  ngOnInit() {
    this.filter();
    this.setSubscription();
  }

  setSubscription() {
    this.originSub = this.originControl.valueChanges.subscribe(city => {
      if ((!city || city === "clean") && this.originCity) {
        this.originCity = null;
        this.filter();
      } else if (city && city.name && (!this.originCity || this.originCity !== city.name)) {
        this.originCity = city.name;
        this.filter();
      }
    })
    this.destinationSub = this.destinationControl.valueChanges.subscribe(city => {
      if ((!city || city === "clean") && this.destinationCity) {
        this.destinationCity = null;
        this.filter();
      } else if (city && city.name && (!this.destinationCity || this.destinationCity !== city.name)) {
        this.destinationCity = city.name;
        this.filter();
      }
    })
  }


  sort(key: 'origin' | 'destination' | 'distance') {
    const getField = (route) => {
      switch (key) {
        case 'origin':
        case 'destination':
          return route[key] && route[key].name ? route[key].name : null;
        case 'distance':
          return route && route.itineraries && (route.currentItinerary || route.currentItinerary == 0) &&
            route.itineraries[route.currentItinerary] && route.itineraries[route.currentItinerary].estimatedDistance
            ? route.itineraries[route.currentItinerary].estimatedDistance / 1000
            : null;
      }
    }
    const unsorted_routes = this._utils.clone(this.original_routes);
    if (this.sort_key === key)
      this.sort_order = (this.sort_order + 1) % 3;
    else this.sort_order = 1;
    this.sort_key = key;

    switch (this.sort_order) {
      case 0:
        this.routes = unsorted_routes;
        break;
      case 1:
        this.routes = unsorted_routes.sort((a, b) => {
          const va = getField(a);
          const vb = getField(b);
          if (!isNaN(va) && !isNaN(vb))
            return vb - va;
          return `${vb}`.localeCompare(`${va}`);
        });
        break;
      case 2:
        this.routes = unsorted_routes.sort((a, b) => {
          const va = getField(a);
          const vb = getField(b);
          if (!isNaN(va) && !isNaN(vb))
            return va - vb;
          return `${va}`.localeCompare(`${vb}`);
        });
        break;
      default:
        throw new Error('Sort overflow');
    }
  }

  goToForm(id?: string) {
    this._router.navigate(id ? ['/routes', 'planning', 'form', id] : ['/routes', 'planning', 'form']);
  }

  /*remove(id?: string) {
    if (id) {
      alert('Desactivando ruta: ' + id);
    }
  }*/

  filter() {
    this.spinner.show();
    this.planningRouteService.getRoutes(this.originCity, this.destinationCity).subscribe(
      (routes: PlanningRoute[]) => {
        this.spinner.hide();
        if (routes && routes.length) {
          this.original_routes = this.appendCurrentItinerary(routes);
        }
        else {
          this.original_routes = [];
        }
        this.routes = this.original_routes;
        this.sort_order = 0;
        this.sort_key = 'origin';
      },
      () => {
        this.spinner.hide();
        this.original_routes = [];
        this.routes = this.original_routes;
        this.sort_order = 0;
        this.sort_key = 'origin';
      }
    )
  }

  appendCurrentItinerary(routes: PlanningRoute[]): PlanningRoute[] {
    for (const i in routes) {
      for (const j in routes[i].itineraries) {
        if (routes[i].itineraries[j].active) {
          routes[i].currentItinerary = j;
        }
      }
    }
    return routes;
  }

  ngOnDestroy() {
    if (this.originSub) this.originSub.unsubscribe();
    if (this.destinationSub) this.destinationSub.unsubscribe();
  }
}
