<div class="row mx-4">
  <!-- Title -->
  <div class="col-md-12 d-flex flex-row align-items-center justify-content-center">
    <label class="title" [ngClass]="{'mt-3': !paramsDialog || emptyDialog }">
      Servicios Adicionales
    </label>
  </div>
</div>
<div class="row list-additional">
  <!-- Boxs -->
  <div class="col-md-12">
    <div class="row" *ngIf="canAttachService">
      <mat-form-field class="col-md-12">
        <mat-label>Agregar un servicio adicional</mat-label>
        <mat-select (selectionChange)="createAdditionalCostItem($event)" [(ngModel)]="selectedService">
          <mat-option *ngFor="let additionalService of listAdditionalService" [value]="additionalService">
            {{additionalService.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row" *ngIf="additionalServiceSuggestion">
      <div class="col-md-12 text-center text__color color--error text-strong e-message">
        <span>
          {{suggestionMessage}}
        </span>
        <span class="separator-horizontal"></span>
      </div>
    </div>
    <div class="row">
      <app-info-box class="col-md-12" *ngIf="!form?.value?.length" [title]="'Agrega un Servicio Adicional del listado'"
        [message]="'(Opcional)'"></app-info-box>
      <app-list-additional-services [cargo]="cargo" class="col-md-12 list-additional-service"
        *ngIf="form?.value?.length" [formArrayGroup]="form"
        (removeAdditionalCostItem)="removeAdditionalCostItem($event)"
        (editAdditionalCostItem)="editAdditionalCostItem($event)" [cargoId]="cargoId"
        [listAdditionalService]="listAdditionalService" [approvalState]="approvalState"
        [cargoApproval]="paramsDialog?.cargo?.approval" [companyId]="companyId">
      </app-list-additional-services>
    </div>
  </div>
</div>