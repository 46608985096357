import { Component } from '@angular/core';

@Component({
    selector: 'app-habeas-data',
    templateUrl: './habeasData.component.html',
    styleUrls: ['./habeasData.component.scss']
})

export class HabeasDataComponent {

}