<main>
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="title-component-box">
        <i *ngIf="type=='alert'" class="fas fa-bell fa-lg"></i>
        <i *ngIf="type=='error'" class="fas fa-exclamation-triangle"></i>
        <h3 class="title-component">{{title}}</h3>
    </div>
    <p class="subtitle-component">
        {{text}}
    </p>
    <hr>
    <div class="row">
        <div class="col-12" *ngFor="let cargo of cargos" style="margin-bottom: 1rem;">
            <app-cargo-item [cargoItemList]="cargo" typeList="loadingRoutes" (navigate)="close()"></app-cargo-item>
        </div>
    </div>
</main>