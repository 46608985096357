export class Titles {

    public titleApproveDocumentsAdvance = '¿Deseas aprobar los documentos de anticipos para pago?';
    public titleApproveDocumentsBalance = '¿Deseas aprobar los documentos de saldo para pago?';
    public titleNoApproveDocumentsAdvance = '¿Deseas rechazar los documentos de anticipos para pago?';
    public titleNoApproveDocumentsBalance = '¿Deseas rechazar los documentos de saldo para pago?';
    public titleModalExportXLSX = '¿Deseas exportar el Reporte?';
    public titleModalExportNovelties = '¿Deseas exportar en un archivo XLSX las Novedades?';
    public titleModalConfirm = 'Confirmar';
    public titleModalNo = 'No';
    public titleModalCancel = 'Cancelar';
    public errorExportFile = 'Ocurrió un error generando el archivo';
    public errorGetReports = 'Ocurrió un error consultando el reporte';
    public titlesModalApproveCargo = '¿Desea aprobar la carga por un total de ';
    public titlesModalApproveCargos = '¿Desea aprobar las cargas por un total de ';
    public titlenModalReturnApproveCargo = '¿Desea retornar al estado, "Pendiente por Aprobar" la carga con Id #';
    public titlesModalCashedCargo = '¿Desea confirmar el cobro de las cargas seleccionadas?';
    public titlesModalFileForPaymentsCargo = '¿Desea descargar el archivo para pagar las cargas seleccionadas?';
    public titleRefuseCargo = '¿Por que desea rechazar la carga?';
    public titleRefuseCargos = '¿Por que desea rechazar las cargas?';
    public titleBillCargo = 'Para confirmar el cobro, por favor ingrese el numero de factura';
    public titleIncomeCargo = 'Para confirmar el ingreso, por favor escriba el numero de Comprobante';
    public titleConfirmDriver = 'Confirmación De Conductor Asignado';
    public titleBottonRefuse = 'Rechazar';
    public titleConfirmeSave = '¡ Actualizacion de Informacion Bancaria !';


}
