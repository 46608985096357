<div class="container px-0">
  <div id="mainFreightCharacteristics" class="col-md-12 mb-md-0 mb-12">
    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title mb-3">
          ¿Cuántos vehículos necesita?
        </label>
      </div>
      <p class="font-weight-normal col-12 f-14">
        La cantidad de vehículos que escoja equivale al número de servicios de transporte a realizar
      </p>
      <div class="col-md-12 mb-md-0 mb-12 mt-3">
        <div class="row" *ngFor="let vehicle of manualCreationCargoService.vehiclesSelected; let i = index;">
          <div class="col-lg-4 mb-md-0 mb-4 container-vehicles-count">

            <div class="type-vehicle">
              <div class="vehicle">
                <div class="container-icon">
                  <div class="vehicle-icon">
                    <img src="{{getIconVehicle(vehicle)}}" alt="">
                  </div>
                </div>
                <div class="vehicle-title">
                  {{vehicle.name}}
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-2 mb-md-0 mb-2">
            <mat-card-content>
              <mat-form-field appearance="standard">
                <mat-label>Cantidad<span class="asterisk">*</span></mat-label>
                <input #quantity{{i}} type="number" matInput min="1"
                  [formControl]="quantityVehicles.get('quantity'+ vehicle.name)">
                <mat-error *ngIf="quantityVehicles.get('quantity'+ vehicle.name).errors?.required">
                  {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
                <mat-error *ngIf="quantityVehicles.get('quantity'+ vehicle.name).errors?.min">
                  {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED_SHORT', 'Cantidad', minQuantity) }}
                </mat-error>
              </mat-form-field>
            </mat-card-content>
          </div>
          <div class="col-lg-4 mb-md-0 mb-4">
            <mat-card-content>
              <app-vehicle-body-work-type [inputFormControl]="manualCreationCargoService.bodyworkTypeControls.at(i)"
                [options]="manualCreationCargoService.bodyworkTypeOptions.at(i).value"
                [validate]="manualCreationCargoService.bodyworkTypeValidates.at(i).value"></app-vehicle-body-work-type>
            </mat-card-content>
          </div>
          <div class="col-lg-2 mb-md-0 mb-2" *ngIf="vehicle.name=='TRACTOCAMION'">
            <mat-card-content>
              <mat-form-field appearance="standard">
                <mat-label>Numero de ejes</mat-label>
                <input type="number" min="2" matInput [formControl]="manualCreationCargoService.formAxles">
                <mat-error *ngIf="manualCreationCargoService.formAxles.errors?.min">
                  {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED_SHORT', 'N° de ejes', minAxles) }}
                </mat-error>
              </mat-form-field>
            </mat-card-content>
          </div>
          <div class="col-md-4 mb-md-0 mb-2 refri" *ngIf="vehicle.name ==='TRACTOCAMION' || getValidWork(vehicle)">
            <mat-checkbox class="example-margin" [(ngModel)]="manualCreationCargoService.vehiclesSelected[i].quality"
              (change)="onChangeIsRefrigerated($event, i)">¿Es refrigerado?</mat-checkbox>
          </div>
          <span class="separator-horizontal"></span>
        </div>
      </div>
      <!-- Button -->
      <div class="col-12 button-container justify-content-center mt-3">
        <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
        <app-request-button></app-request-button>
        <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
      </div>
    </div>
  </div>
</div>