import { Component, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CargoListComponent } from '../cargo-list/cargo-list.component';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/core/resources/permission';
@Component({
  selector: 'app-cargo-list-expenses-income',
  templateUrl: './cargo-list-expenses-income.component.html',
  styleUrls: ['./cargo-list-expenses-income.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CargoListExpensesIncomeComponent implements OnInit {
  private static openedModalUncared: boolean = false;
  @ViewChildren(CargoListComponent) cargoList: QueryList<CargoListComponent>;
  selectedTab: number = 0;
  permission = Permission;

  constructor(
    private spinner: NgxSpinnerService,
    private matDialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

  }

  onSelectTab($event: number) {
    //this.cargoList.toArray()[$event].cargoListFilterComponent.ngAfterViewInit();
  }

  resetFilters($event: boolean) {
    //this.cargoList.toArray().forEach(list => list.cargoListFilterComponent.cleanFilter())
  }

  get showListWithoutPayments() {
    return this.authService.getUserSession().clientCompanyId === environment.rootNit
  }

  get listPermissions() {
    if (this.showListWithoutPayments) {
      return [this.permission.payments.generateJournalCargo, this.permission.payments.generateAccountingFiles];
    }
    return [this.permission.payments.generateAccountingFiles];
  }

}
