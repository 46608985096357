import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { HttpClient } from "@angular/common/http";
import { Endpoints } from "src/app/core/resources/endpoints";
import { Global } from "src/app/core/resources/global";
import { Utils } from "src/app/core/resources/utils";
import { environment } from "../../../environments/environment";
import { Company } from "src/app/core/interfaces/company";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/core/services/authentication.service";
import { gpsType } from "src/app/core/interfaces/gpsType";

@Injectable({
  providedIn: "root",
})
export class SelectGpsService {
  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    private globalResources: Global,
    public utils: Utils,
    private authService: AuthService
  ) { }

  public getAllTypesGps() {
    return this.http.get<gpsType[]>(
      environment.urlServerTeclogi + this.endpointResources.getAllTypesGps
    );
  }

  public deleteGpsSelected(gpsName: string) {
    return this.http.delete(
      environment.urlServerTeclogi +
      this.endpointResources.getAllTypesGpsLower +
      this.endpointResources.deleteGPS +
      gpsName
    );
  }
}
