import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { BoxTypeServiceService } from './box-type-service.service';
import { ModelCargo } from '../../resources/modelCargo';

@Component({
  selector: 'app-box-type-service',
  templateUrl: './box-type-service.component.html',
  styleUrls: ['./box-type-service.component.scss'],
  providers: [AuthService, Global, BoxTypeServiceService, ModelCargo]
})
export class BoxTypeServiceComponent implements OnInit {

  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Input() serviceSelectedName: String;
  @Input() serviceTypeSelect: String;

  constructor() { }

  ngOnInit() {
  }

  public selectTypeService(data) {
    this.serviceTypeSelect = data;
    this.emitToParent.emit(data);
  }

}
