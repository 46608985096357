import { Component, OnInit, EventEmitter, DoCheck, IterableDiffers, KeyValueDiffers, SimpleChanges, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';

import { Global } from 'src/app/core/resources/global';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { CollectionPoint } from 'src/app/core/interfaces/collectionPoint';
import { CollectionPointZone } from 'src/app/core/interfaces/collectionPointZone';
import { CollectionPointService } from '../collection-point.service';
import { Utils } from 'src/app/core/resources/utils';
import { LocationPoint } from 'src/app/core/interfaces/locationPoint';
import { CollectionPointType } from 'src/app/core/interfaces/collectionPointType';
declare var google: any;
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'src/app/core/resources/permission';
import { DateManager } from 'src/app/core/managers/date.manager';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';

@Component({
  selector: 'app-collection-point-detail',
  templateUrl: './collection-point-detail.component.html',
  styleUrls: ['./collection-point-detail.component.scss'],
  providers: [AuthService, Global]
})
export class CollectionPointDetailComponent implements OnInit {
  @ViewChild("modalConfirmDeletePoint", { static: false }) modalConfirmDeletePoint: ElementRef;
  collectionPoint: CollectionPoint;
  formCollectionPoint: FormGroup;
  disabledForm: boolean;
  historyLocations = [];
  historyLocationSelected: LocationPoint;
  mode: string;
  collectionPointZones: CollectionPointZone[];
  collectionPointTypes: CollectionPointType[];
  instanceModalDeletePoint: NgbModalRef;
  typeLocation = {
    typeLocation: 'collectionPoint'
  }
  permission = Permission;
  keyMarker: string = 'collectionPoint';
  mapOptions: OptionsAutocomplete = {
    keyMarkerListener: this.keyMarker,
    clickMapIcon: this.global.pathMarkerDestination,
    initialClickMap: true,
  };
  public listLocations: Object = {};
  private tempListLocations: Object = {};
  constructor(
    private collectionPointService: CollectionPointService,
    private router: Router,
    public utils: Utils,
    private global: Global,
    private snackBarService: SnackBarService,
    private modalService: NgbModal,
    public cdRef: ChangeDetectorRef
  ) {
    this.mode = window.location.hash.substring(19);
    this.collectionPointZones = this.global.collectionPointZones;
    this.collectionPointTypes = this.global.collectionPointTypes;
    if (this.mode === "detail") {
      if (this.utils.isDefined(this.collectionPointService.collectionPointSelected)) {
        this.collectionPoint = this.collectionPointService.collectionPointSelected;
        this.collectionPointService.collectionPointSelected = null;
        this.disabledForm = true;
      } else {
        this.backView();
      }
    } else if (this.mode === "create") {
      this.collectionPoint = {};
      this.collectionPointService.collectionPointSelected = null;
      this.disabledForm = false;
    } else {
      this.backView();
    }
  }

  ngOnInit() {
    this.initFormCollectionPoint();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    this.processLocations();
    let locationActive = this.collectionPointService.findHistoryLocationActive(this.collectionPoint.historyLocations);
    if (locationActive.length) {
      this.selectHistoryLocation(locationActive[0]);
    }
  }

  initFormCollectionPoint() {
    this.formCollectionPoint = new FormGroup({
      id: new FormControl({
        value: this.collectionPoint.id || '',
        disabled: this.disabledForm
      }),
      zone: new FormControl({
        value: this.collectionPoint.zone || 'Tuta',
        disabled: this.disabledForm
      }),
      routeId: new FormControl({
        value: this.collectionPoint.routeId || '',
        disabled: this.disabledForm
      }),
      sapCode: new FormControl({
        value: this.collectionPoint.sapCode || '',
        disabled: this.disabledForm
      }),
      producer: new FormControl({
        value: this.collectionPoint.producer || '',
        disabled: this.disabledForm
      }),
      farmName: new FormControl({
        value: this.collectionPoint.farmName || '',
        disabled: this.disabledForm
      }, Validators.required),
      type: new FormControl({
        value: this.collectionPoint.type || 'FRIA',
        disabled: this.disabledForm
      }),
      schedule: new FormControl({
        value: this.collectionPoint.schedule || '',
        disabled: this.disabledForm
      }),
      average: new FormControl({
        value: this.collectionPoint.average || '',
        disabled: this.disabledForm
      }),
      lat: new FormControl({
        value: this.collectionPoint.location ? this.collectionPoint.location.lat : '',
        disabled: true
      }),
      lng: new FormControl({
        value: this.collectionPoint.location ? this.collectionPoint.location.lng : '',
        disabled: true
      })
    });
  }

  processLocations() {
    if (this.collectionPoint && this.collectionPoint.historyLocations && this.collectionPoint.historyLocations.length) {
      let path = this.collectionPoint.historyLocations;
      this.setInfoPoint(path);
      this.tempListLocations['collectionPoint'] = { path };
      this.listLocations = this.tempListLocations;
      this.mapOptions['route'] = this.listLocations;
      this.mapOptions = { ...this.mapOptions };
    }
  }

  setInfoPoint(path) {
    if (path && path.length) {
      path.forEach((point, index) => {
        point['key'] = `collectionPoint:${index}`
        point['contentInfoWindow'] = `
        <b>Fecha de creación:</b> ${point && point.date ? point.date.slice(0, 16) : '-'}
        `
        if (index === 0) {
          point['setCenter'] = true;
          point['zoom'] = 16;
        }
        point['showMarker'] = true;
        point['icon'] = this.global.pathMarkerDestination;
      });
    }
  }

  selectHistoryLocation(location: LocationPoint) {
    this.historyLocationSelected = location;
  }

  getForm() {
    return this.formCollectionPoint.controls;
  }

  onSubmit() {
    if (this.formCollectionPoint.valid) {
      let service = 'updateCollectionPoint';
      let collectionPoint: CollectionPoint = {
        zone: this.getForm().zone.value,
        sapCode: this.getForm().sapCode.value,
        producer: this.getForm().producer.value,
        farmName: this.getForm().farmName.value.toUpperCase(),
        type: this.getForm().type.value,
        average: this.getForm().average.value
      }

      if (_.isNumber(this.getForm().lat.value) && _.isNumber(this.getForm().lng.value)) {
        collectionPoint.location = {
          lat: this.getForm().lat.value,
          lng: this.getForm().lng.value,
          date: DateManager.dateToString(new Date())
        };
      }

      if (this.utils.isDefined(this.collectionPoint.id)) {
        collectionPoint.id = this.collectionPoint.id;
      }

      if (this.mode === 'create') {
        service = 'createCollectionPoint';
      }

      this.collectionPointService[service](collectionPoint).subscribe(
        (success) => {
          if (this.mode === "create") {
            this.snackBarService.openSnackBar('Punto creado correctamente');
            this.backView();
          } else {
            this.snackBarService.openSnackBar('Punto actualizado correctamente');
            this.disableFrom();
          }
        },
        (error) => {
          if (this.mode === "create") {
            this.snackBarService.openSnackBar("Ocurrió un error creando el punto", undefined, 'error');
          } else {
            this.snackBarService.openSnackBar("Ocurrió un error actualizando el punto", undefined, 'error');
          }
        }
      );
    } else {
      this.snackBarService.openSnackBar('El campo Finca es obligatorio', undefined, 'alert');
    }
  }

  enableFrom() {
    this.formCollectionPoint.controls.zone.enable();
    this.formCollectionPoint.controls.routeId.enable();
    this.formCollectionPoint.controls.sapCode.enable();
    this.formCollectionPoint.controls.producer.enable();
    this.formCollectionPoint.controls.farmName.enable();
    this.formCollectionPoint.controls.type.enable();
    this.formCollectionPoint.controls.schedule.enable();
    this.formCollectionPoint.controls.average.enable();
    this.disabledForm = false;
  }

  disableFrom() {
    this.formCollectionPoint.controls.zone.disable();
    this.formCollectionPoint.controls.routeId.disable();
    this.formCollectionPoint.controls.sapCode.disable();
    this.formCollectionPoint.controls.producer.disable();
    this.formCollectionPoint.controls.farmName.disable();
    this.formCollectionPoint.controls.type.disable();
    this.formCollectionPoint.controls.schedule.disable();
    this.formCollectionPoint.controls.average.disable();
    this.disabledForm = true;
  }

  onLoadMap($event) {
    if (this.mode === "create" || !this.disabledForm) {
      this.formCollectionPoint.controls.lat.setValue($event.latLng.lat());
      this.formCollectionPoint.controls.lng.setValue($event.latLng.lng());
    }
  }

  backView() {
    this.router.navigate(['/collection-point/list']);
  }

  deleteCollectionPoint() {
    this.collectionPointService.deleteCollectionPoint(this.collectionPoint.id).subscribe(
      (success) => {
        this.snackBarService.openSnackBar('Punto eliminado correctamente');
        this.hideModalConfirmDeletePoint();
        this.backView();
      },
      (error) => {
        this.snackBarService.openSnackBar("Ocurrió un error eliminando el punto", undefined, 'error');
      }
    );
  }

  openModalConfirmDeletePoint() {
    this.instanceModalDeletePoint = this.modalService.open(this.modalConfirmDeletePoint, {
      centered: true
    });
  }

  hideModalConfirmDeletePoint() {
    this.instanceModalDeletePoint.close();
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }

}
