import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from './../../../../environments/environment';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { Utils } from 'src/app/core/resources/utils';
// import { timeout } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UploadGuideService {

    public listGuideStates: Catalog;
    public guideStatesObject: object;

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        public utils: Utils
    ) {

    }

    setStateGuides(data, token) {
        let headers = new HttpHeaders({
            'tokenUser': token.token,
            'Content-Type': 'multipart/form-data'
        });

        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.uploadSimpleGuides,
            data,
            {
                headers
            }
        );
        // .pipe(
        //     timeout(300000)
        // );
    }

    uploadGuides(data, token) {
        const headers = new HttpHeaders({
            'tokenUser': token.token,
            'Content-Type': 'multipart/form-data'
        });

        return this._http.post(
            environment.urlServerTeclogi + this._endpointResources.uploadGuides,
            data,
            {
                headers
            }
        );
        // .pipe(
        //     timeout(300000)
        // );
    }

    // getGuideStates() {
    //     return this._http.get<Catalog>(
    //         environment.urlServerTeclogi + this._endpointResources.urlCatalog + '/GuideState'
    //     );
    // }

    getGuideStates(companyId?: string) {
        let params = new HttpParams();
        if (!this.utils.isEmpty(companyId)) {
            params = params.set('companyId', companyId);
        }
        return this._http.get<any[]>(
            environment.urlServerTeclogi + this._endpointResources.guideStates,
            {
                params
            }
        );
    }

    public getGuideStatesObject(): Catalog[] {
        if (UploadGuideService['guideStatesObject']) {
            return UploadGuideService['guideStatesObject'];
        } else {
            return null;
        }
    }

    public getGuideStatesInstance(): Catalog[] {
        if (UploadGuideService['listGuideStates']) {
            return UploadGuideService['listGuideStates'];
        } else {
            return null;
        }
    }

    public setGuideStatesObject(value) {
        UploadGuideService['guideStatesObject'] = this.utils.convertArrayToObject(value, 'id');
    }

    public setGuideStatesInstance(value) {
        UploadGuideService['listGuideStates'] = value;
    }

}
