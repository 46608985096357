<div id="mainFreightItinerary" class="col-md-12 mb-md-0 mb-12">
  <div class="row h-100">

    <mat-tab-group class="tab-group" (selectedIndexChange)="onSelectTab($event)" permission-tab
      [permissionModule]="permission.cargo.module"
      [permissionListName]="[permission.cargo.createManualCargo, permission.cargo.createCargoByGuides, permission.cargo.createMassiveCargo, permission.cargo.createCargoFromRoute]">
      <mat-tab label="Manual">
        <app-upload-download-map-freight></app-upload-download-map-freight>
      </mat-tab>
      <mat-tab label="Solicitud">
        <app-create-cargo>
        </app-create-cargo>
      </mat-tab>
      <mat-tab label="Por guías">
        <app-create-cargo-from-guides>
        </app-create-cargo-from-guides>
      </mat-tab>
      <mat-tab label="Masivas">
        <app-mass-creation-cargo></app-mass-creation-cargo>
      </mat-tab>
      <mat-tab label="Desde una ruta">
        <app-upload-route></app-upload-route>
      </mat-tab>
      <!-- <mat-tab label="Últimas solicitudes">
        <app-cargo-list *ngIf="showListLatestRequests" [typeList]="'latestLoads'">
          </app-cargo-list>
      </mat-tab> -->
    </mat-tab-group>

  </div>
</div>