import { CheckLists } from "./checkListsInspectVehicle";
import { ContainerStatus } from "./container-status";

export enum ContainerClassType {
  SD = 'SD',
  HC = 'HC',
  OP = 'OP',
  FR = 'FR',
  RE = 'RE',
  OT = 'OT'
}

export interface ContainerInspect {
  version?: number;
  id?: string;
  licensePlate: string;
  evaluatorFingerPrint?: object;
  confirmedInspectionFingerPrint?: object;
  checkLists: Array<CheckLists>;
  date?: string;
  countGood?: number;
  countRegular?: number;
  countBad?: number;
  countNotApply?: number;
  approval: boolean;
  observation?: string;
  container: string;
  safetySeal: string;
  indicativeSeal: string;
  shippingCompany: string;
  courtyard: string;
  size: number;
  classType: string;
  containerStatus: Array<ContainerStatus>;
  evaluatorObservation: string;
  altered: boolean;
  damanged?: boolean;
  contaminated?: boolean;
  improperRepaired?: boolean;
  crossContaminated?: boolean;
}
