import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Company, Model } from 'src/app/core/interfaces/company';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { CompanyResource } from 'src/app/core/interfaces/companyResource';
import { error } from 'console';

@Component({
  selector: 'app-company-resources',
  templateUrl: './company-resources.component.html',
  styleUrls: ['./company-resources.component.scss']
})
export class CompanyResourcesComponent implements OnInit {
  @Input() company: Company;
  @Output() public refreshList: EventEmitter<any> = new EventEmitter();
  resourceControl = new FormControl('');
  resources = [
    { name: "Logo principal", type: "logo", accept: "image/jpg", mustContain: "image" },
    { name: "Camara de comercio", type: "chamberOfCommerceCertificate", accept: "application/pdf", mustContain: "document" },
    { name: "RUT", type: "rut", accept: "application/pdf", mustContain: "document" }
  ];
  preview: SafeResourceUrl;
  basePath: string;
  companyResources: CompanyResource[] = [];
  companyResource: CompanyResource;
  constructor(
    public snackBarService: SnackBarService,
    public utils: Utils,
    public formBuilder: FormBuilder,
    public modelCompany: Model,
    public spinner: NgxSpinnerService,
    private companiesService: CompaniesService,
    private sanitizer: DomSanitizer,
    private angularFireStorage: AngularFireStorage,
  ) {
  }

  ngOnInit() {
    this.basePath = 'company/' + this.company.companyId + '/'
    this.getResources();
  }

  async getResources() {
    try {
      const data: any = await this.companiesService.getResourcesCompany(this.company.companyId).toPromise();
      this.companyResources = data;
    } catch (error) {
      console.error(error);
    }
  }

  changeSelect() {
    this.preview = null;
    this.companyResource = this.companyResources.find(resource => resource.fileType === this.resourceControl.value.type);
    if (!this.companyResource || !this.companyResource.path) {
      this.preview = undefined;
      return;
    }
    const pathReference = this.angularFireStorage.ref(this.companyResource.path);
    pathReference.getDownloadURL().subscribe(
      (url) => {
        this.preview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      },
      (error) => {
        this.snackBarService.openSnackBar(`Ocurrió un error al ver el recurso de ${this.resourceControl.value.name} de la compañía`, undefined, 'error');
      }
    );
  }

  onDrag(e, over: boolean) {
    const container = document.getElementById("dropcontainer");
    over ? container.classList.add("drag-active") : container.classList.remove("drag-active");
    e.preventDefault();
    e.stopPropagation();
  }
  onDrop(e) {
    this.onDrag(e, false);
    const files = e.dataTransfer.files;
    this.setInstanceFileStorage(files);
  }

  setInstanceFileStorage(e) {
    if (!e[0]) return;
    if (this.resourceControl.value.name === 'Logo principal') {
      if (!e[0].type.includes(this.resourceControl.value.mustContain)) {
        this.snackBarService.openSnackBar(FormMessages.INVALID_FORMAT, undefined, 'alert');
        return;
      }
    } else {
      if (e[0].type !== 'application/pdf') {
        this.snackBarService.openSnackBar('El archivo no es un PDF válido', undefined, 'alert');
        return;
      }
    }
    let instance = {
      fileData: { file: e[0], name: e[0]['name'], uploaded: true, size: this.utils.bytesToSize(e[0].size), url: null },
      storageData: { storageRef: null, uploadTask: null, uploadProgress: null }
    };
    this.uploadFileStorage(instance);
  }

  uploadFileStorage(instance: any) {
    let filePath = this.basePath + this.resourceControl.value.type;
    this.spinner.show()
    instance.storageData.storageRef = this.angularFireStorage.ref(filePath);
    instance.storageData.uploadTask = this.angularFireStorage.upload(filePath, instance.fileData.file);
    instance.storageData.uploadTask.then(
      (data) => {
        instance.storageData.storageRef.getDownloadURL().subscribe(
          (urlFile) => {
            this.uploadFile(filePath)
          }, (error) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar('El archivo no se subió correctamente', undefined, 'error');
          }
        );
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('El archivo no se subió correctamente', undefined, 'error');
      }
    );
  }

  uploadFile(path: string) {
    const typeName: string = this.resourceControl.value.type;
    let body: CompanyResource = {
      fileType: typeName,
      path: path
    };
    this.companiesService.uploadResourcesCompany(this.company.companyId, body).subscribe(
      async () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Información actualizada correctamente');
        await this.getResources();
        this.changeSelect();
        this.refreshList.emit(this.company.companyId);
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al actualizar la compañía', undefined, 'error');
      }
    );
  }
}
