export enum CargoMessages {
  NO_MANIFEST = "No hay un manifiesto asociado a esta carga",
  NO_UPLOAD_EVIDENCES = "No hay evidencias de cargue subidas",
  VALUE_ADVANCE_ON_ZERO = "El valor del anticipo es cero",
  SHIPPING_COST_PAID = "El flete ya fue pagado",
  ADVANCE_STATE_PAID = "El anticipo ya fue pagado",
  SHOW_REQUEST = 'Realizar solicitud',
  NOVELTY_UPDATED = 'Registro actualizado exitosamente',
  NOVELTY_UPDATE_ERROR = "No es posible actualizar este registro",
  ERROR_WEIGHT_CONSIGNMENT = "No se pudo editar el peso de esta remesa",
  SUCCESS_EDIT_WEIGHT_CONSIGNMENT = "Se editó el peso de la remesa",
  WEIGHT_LESS_THAN_ZERO = "El peso es menor o igual a cero",
  ERROR_CARGO_AMOUNT = "No se pudo cambiar el valor total de la carga",
  SUCCESS_EDIT_CARGO_AMOUNT = "Se editó el valor total de la carga",
  CARGO_AMOUNT_LESS_THAN_ZERO = "El valor total es menor o igual a cero",
  SAME_CITIES_ORIGIN_DESTINATIONS = "No es posible seleccionar este tipo de viaje si la ciudad de origen y destino son las mismas",
  DIFF_CITIES_ORIGIN_DESTINATIONS = "No es posible seleccionar este tipo de viaje si la ciudad de origen y destino son distintas",
  SUCCESSFULL_TURN_DELETE = "Se ha eliminado exitosamente el enturnamiento",
  SUCCESSFULL_TURN_EDIT = "Se ha editado exitosamente el enturnamiento",
  UNABLE_TO_ASSIGN_ERROR = "No es posible asignar %s a %s",
  CARGO_UPDATED = "La carga ha sido actualizada exitosamente",
  CARGO_UPDATE_ERROR = "Se actualizó la carga a excepción del campo de %s",
  CARGO_UPDATE_ERROR_DEFAULT = "Ocurrió un error actualizando la carga",
  SUCCESS_FAST_PAYMENT = "Se ha realizado la solicitud de pronto pago exitosamente",
  SUCCESS_FAST_PAYMENT_REVERT = "Se ha reversado la solicitud de pronto pago exitosamente",
  SUCCESS_RESTART_LOAD = "Se ha reiniciado la carga exitosamente",
  INVALID_DATE_RESTART_LOAD = "No se pueden reiniciar cargas con una fecha de descargue que supere 1 día respecto al día actual",
  PAYMENTS_IN_CARGO = "No se puede reiniciar la carga ya que tiene pagos de saldos",
  SUCCESS_RECEIVABLE_DELETE = "La cartera se ha eliminado existosamente",
  NO_COMPANY = "No se ha asociado una operación a la carga",
  NO_DOCUMENT_SENDER = 'No se ha diligenciado el documento del remitente',
  NO_CARGO_OWNER = "La carga no tiene un propietario asociado",
  NO_CARGO_OWNER_OFFICE = "No se ha diligenciado la sucursal del propietario de la carga",
  NO_TRIP_TYPE = "No se ha diligenciado el tipo de viaje",
  NO_LETTER_RETIREMENT = "No se ha subido la carta de retiro",
  NO_REGIME_TYPE = "No se ha diligenciado el tipo de régimen",
  NO_DUTA_DATE = 'No se ha diligenciado la fecha de descargue del buque',
  WRONG_REGIME_TYPE = 'Este tipo de régimen no es valido para el tipo de viaje seleccionado',
  NO_CARGO_TYPE = 'No se ha diligenciado el tipo de carga',
  NO_CONTAINER_MEASURE = 'No se han diligenciado las medidas del contenedor',
  NO_SERVICE_TYPE = 'No se ha diligenciado el tipo de servicio',
  WRONG_SERVICE_TYPE = 'El tipo de servicio no corresponde al tipo de carga seleccionado',
  NO_OBSERVATIONS = 'No se ha diligenciado la observación de la carga',
  NO_PACKAGING_TYPE = 'No se ha diligenciado el tipo de empaque',
  NO_PRODUCT_TYPE = 'No se ha diligenciado el tipo de mercancía',
  NO_TOTAL_WEIGHT = 'No se ha diligenciado el peso total de la carga',
  TOTAL_WEIGHT_TO_EMPTY_CONTAINERS = 'El peso total de la carga debe ser 0 para contenedores vacíos',
  NO_WEIGHT_UNIT = 'No se ha diligenciado la unidad de peso de la carga',
  OVERWEIGHT = "El peso total superó la capacidad permitida: ",
  NO_AMOUNT = 'No se ha diligenciado el valor total de la carga',
  AMOUNT_TO_EMPTY_CONTAINERS = 'El valor total de la carga debe ser 0 para contenedores vacíos',
  NO_VEHICLE_TYPE = 'No se ha diligenciado el tipo de vehículo',
  NO_BODYWORK_TYPE = 'No se ha diligenciado el tipo de carrocería',
  NO_ORIGIN_CITY = 'No se ha diligenciado la ciudad de origen',
  NO_RATE = 'No se ha diligenciado el valor de la tarifa',
  NO_TOTAL_COST = 'No se ha diligenciado el valor del flete',
  NO_ADVANCE_PERCENTAGE = 'No se ha diligenciado el porcentaje de anticipo',
  MAX_ADVANCE_PERCENTAGE_EXCEED = "Se ha excedido el porcentaje máximo de anticipo permitido",
  NO_PAYMENT_TYPE = "No se ha diligenciado el tiempo de pago",
  LOADDATE_BEFORE_TODAY = "La fecha de cargue es anterior al día actual",
  INVALID_DATE_RESTORE_LOAD = "Las cargas creadas antes del 15 de Enero 2024 no se podrán restaurar.",
  COMPLIMENT_CREATED = "Requisito de cumplido creado exitosamente",
  COMPLIMENT_UPDATED = "Requisito de cumplido actualizado exitosamente",
  COMPLIMENT_TITLE_CREATED = "Nombre de requisito agregado exitosamente",
  UNABLE_TO_CHECK_COMPLIMENTS = "No fue posible acceder al detalle de los requisitos de cumplido",
  ERROR_UPLOADING_FILE = "Ocurrió un error al subir el archivo",
  FULFILLMENT_CARGO_SAVED = "Requisitos de cumplido guardados correctamente",
}
