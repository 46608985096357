<div id="scrollContainer" class="history-detail-container" *ngFor="let itemHistory of listHistory">
  <mat-card class="mb-3 mt-1">
    <mat-card-content>
      <div class="card-container" *ngIf="itemHistory">
        <div class="row">
          <div *ngIf="itemHistory?.fingerprint?.date" class="col-12 col-md-6 col-lg-3">
            <span class="title-purple">
              {{ itemHistory.fingerprint.date | dateFormat:'only-date' }}
              {{ itemHistory.fingerprint.date | dateFormat:'time'}}
            </span>
          </div>
          <div *ngIf="getHistoryType(itemHistory)" class="col-12 col-md-6 col-lg-3">
            <span class="title-purple">
              Métrica: {{getHistoryType(itemHistory)}}
            </span>
          </div>
          <div *ngIf="itemHistory?.companyId" class="col-12 col-md-6 col-lg-3">
            <span class="title-purple text-break">
              Cliente: {{ itemHistory.companyId }}
            </span>
          </div>
          <div *ngIf="itemHistory?.id" class="col-12 col-md-6 col-lg-3">
            <span class="title-gray text-break">
              Id: {{ itemHistory.id }}
            </span>
          </div>
          <div *ngIf="itemHistory?.fingerprint?.userId" class="col-12 col-lg-6">
            <span class="title-gray">
              Documento responsable de la creación : {{ itemHistory.fingerprint.userId }}
            </span>
          </div>
          <div *ngIf="itemHistory?.fingerprint?.userName" class="col-12 col-lg-6">
            <span class="title-gray">
              Nombre responsable de la creación : {{ itemHistory.fingerprint.userName }}
            </span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>