import { Component, Input, OnInit } from '@angular/core';
import { FunctionalitiesModuleRole } from 'src/app/core/interfaces/functionalitiesModuleRole';
import { FunctionalitiesService } from '../functionalities.service';


@Component({
  selector: 'app-functionalities-item',
  templateUrl: './functionalities-item.component.html',
  styleUrls: ['./functionalities-item.component.scss']
})
export class FunctionalitiesItemComponent implements OnInit {
  @Input() funcionalities : FunctionalitiesModuleRole

  constructor() { }

  ngOnInit() {
  }

}
