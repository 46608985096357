import { Component, Inject, OnInit } from '@angular/core';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Permission } from 'src/app/core/resources/permission';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-modal-cargo-uncared',
  templateUrl: './modal-cargo-uncared.component.html',
  styleUrls: ['./modal-cargo-uncared.component.scss']
})
export class ModalCargoUncaredComponent implements OnInit {
  public type: string;
  public title: string;
  public text: string;
  public cargos: Cargo[];
  public permission = Permission;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      type:string,
      title: string,
      text: string,
      cargos: Cargo[]
    },
    public dialogRef: MatDialogRef<ModalCargoUncaredComponent>
  ) { }

  ngOnInit() {
    if (this.data) {
      this.type = this.data.type || this.type;
      this.cargos = this.data.cargos || this.cargos;
      this.title = this.data.title || this.title;
      this.text = this.data.text || this.text;
    }
  }
  public close(): void {
    this.dialogRef.close();
  }



}
