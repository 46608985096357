import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js';
import { ReceivableService } from '../receivable.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ReceivableCreateComponent } from '../receivable-create/receivable-create.component';
import { Receivable } from 'src/app/core/interfaces/receivable';
import { colorPalette } from 'src/app/core/enums/color-palette';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { CurrencyPipe } from '@angular/common';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Permission } from 'src/app/core/resources/permission';
@Component({
  selector: 'app-receivable-list',
  templateUrl: './receivable-list.component.html',
  styleUrls: ['./receivable-list.component.scss'],
  providers: [CurrencyPipe]
})
export class ReceivableListComponent implements OnInit {
  @ViewChild('doughnutChart1', { static: true }) doughnutChartRef1!: ElementRef;
  @ViewChild('doughnutChart2', { static: true }) doughnutChartRef2!: ElementRef;
  @ViewChild('doughnutChart3', { static: true }) doughnutChartRef3!: ElementRef;
  colorPalette = colorPalette;
  tab: number = 0;
  legendEnable = true;
  legendEnable2 = true;
  labelsChart1: string[] = [];
  labelsChart2: string[] = [];
  valuesChart1: number[] = [];
  valuesChart2: number[] = [];
  receivables: Receivable[] = [];
  status: string | undefined;
  filteredReceivablesPending = [];
  filteredReceivablesPaid = [];
  maxPending = {};
  maxPaid = {};
  colorsGraphPending: any;
  colorsGraphPaid: any;
  activeLabel: boolean = false;
  ownerName: string = '';
  emptyPaid: boolean = false;
  emptyPending: boolean = false;
  selectedTabIndex: number = 0;
  storageToObject = [];
  emptyStorage: boolean = false;
  licensePlateValue: string = '';
  resetPagination: boolean = false;
  cleanFields: boolean;
  permission = Permission;
  private doughnutChart1: Chart;
  private doughnutChart2: Chart;
  constructor(
    private receivableService: ReceivableService,
    private spinner: NgxSpinnerService,
    public matDialog: MatDialog,
    public snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.viewSessionStorage();
    if (this.emptyStorage) {
      this.getlist();
    }
    sessionStorage.setItem("_lastListReceivable", '');
  }
  initCharts(type?: string, value?): void {
    let paidLicensePlates = {};
    let pendingLicensePlates = {};
    if (type === 'document') {
      this.getUserReceivable(value);
      this.receivables.forEach((receivable) => {
        const { licensePlate, state } = receivable;
        if (licensePlate && state) {
          if (state.description === 'paid') {
            paidLicensePlates[licensePlate] = (paidLicensePlates[licensePlate] || 0) + 1;
          } else {
            pendingLicensePlates[licensePlate] = (pendingLicensePlates[licensePlate] || 0) + 1;
          }
        }
      });
      this.labelsChart1 = Object.keys(paidLicensePlates);
      this.valuesChart1 = Object.values(paidLicensePlates);
      this.labelsChart2 = Object.keys(pendingLicensePlates);
      this.valuesChart2 = Object.values(pendingLicensePlates);
      this.colorsGraphPending = this.generateRandomColors(this.receivables, colorPalette);
      this.colorsGraphPaid = this.generateRandomColors(this.receivables, colorPalette);
    } else {
      this.activeLabel = false;
      this.labelsChart1 = Object.keys(this.maxPaid);
      this.valuesChart1 = Object.values(this.maxPaid);
      this.labelsChart2 = Object.keys(this.maxPending);
      this.valuesChart2 = Object.values(this.maxPending);
      this.colorsGraphPending = this.generateRandomColors(this.filteredReceivablesPending, colorPalette);
      this.colorsGraphPaid = this.generateRandomColors(this.filteredReceivablesPaid, colorPalette);
    }

    if (this.doughnutChart1) {
      this.doughnutChart1.destroy();
    }
    if (this.doughnutChart2) {
      this.doughnutChart2.destroy();
    }

    if (this.doughnutChartRef1) {
      this.labelsChart1.length > 4 ? this.legendEnable = false : this.legendEnable = true;
      const ctx1 = this.doughnutChartRef1.nativeElement.getContext('2d');
      this.doughnutChart1 = new Chart(ctx1, {
        type: 'doughnut',
        data: {
          labels: this.labelsChart1,
          datasets: [{
            label: '# of Votes',
            data: this.valuesChart1,
            backgroundColor: this.colorsGraphPaid,
            borderColor: this.colorsGraphPaid,
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: this.legendEnable // Oculta el legend
          },
          tooltips: {
            enabled: true // Habilita los tooltips al pasar el mouse sobre la gráfica
          },
          title: {
            display: true,
            text: 'Top carteras abonadas por placa'
          }
        }
      });
    }



    if (this.doughnutChartRef2) {
      this.labelsChart2.length > 4 ? this.legendEnable2 = false : this.legendEnable2 = true;
      const ctx2 = this.doughnutChartRef2.nativeElement.getContext('2d');
      this.doughnutChart2 = new Chart(ctx2, {
        type: 'doughnut',
        data: {
          labels: this.labelsChart2,
          datasets: [{
            label: '# of Votes',
            data: this.valuesChart2,
            backgroundColor: this.colorsGraphPending,
            borderColor: this.colorsGraphPending,
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: this.legendEnable2 // Oculta el legend
          },
          tooltips: {
            enabled: true // Habilita los tooltips al pasar el mouse sobre la gráfica
          },
          title: {
            display: true,
            text: 'Top carteras pendientes por placa'
          }
        }
      });
    }

  }


  generateRandomColors(receivable, colorPalette: string[]): string[] {
    const count = receivable.length;
    const colors = [];
    let lastColor = null;
    let firstColor = null;
    for (let i = 0; i < count; i++) {
      let availableColors = colorPalette.filter(color => color !== lastColor || (receivable.length - 1 === i && color !== firstColor));
      const randomIndex = Math.floor(Math.random() * availableColors.length);
      const color = availableColors[randomIndex];
      colors.push(color);
      if (i === 0) {
        firstColor = color
      }
      lastColor = color;
    }
    return colors;
  }

  createReceivable() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(ReceivableCreateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.cleanFields = !this.cleanFields;
      }
    })
  }

  onSelectTab($event: number) {
    this.tab = $event;
    this.emptyPaid = false;
    this.emptyPending = false;
    switch ($event) {
      case 0:
        this.status = undefined;
        if (this.emptyStorage) {
          this.getlist();
        }
        break;
      case 1:
        this.status = 'paid';
        break;
      case 2:
        this.status = 'pending';
        break;
    }
    this.viewSessionStorage();
    if (this.status) {
      sessionStorage.setItem('_lastListReceivable', this.status);
      if (this.emptyStorage) {
        this.getlist(null, null, null, null, this.status);
      }
    } else {
      sessionStorage.setItem('_lastListReceivable', '');
    }


  }

  convertToObject(elements: string[]): any[] {
    return elements.map(elemento => {
      const [label, control] = elemento.split('=');
      return { label, control };
    });
  }

  viewSessionStorage() {
    if (sessionStorage.getItem("_filterListReceivables")) {
      this.storageToObject = this.convertToObject(sessionStorage.getItem("_filterListReceivables").split('&'));
      this.emptyStorage = false;
    } else {
      this.storageToObject = [];
      this.emptyStorage = true;
    }
  }

  getlist(cargoConsecutive?: string, receivableConsecutive?: string, document?: string, licensePlate?: string, state?: string) {
    this.spinner.show();
    this.filteredReceivablesPaid = [];
    this.filteredReceivablesPending = [];
    const observerListReceivables = {
      next: (data) => {
        if (!data) {
          this.receivables = [];
          this.emptyPaid = true;
          this.emptyPending = true;
        } else {
          this.receivables = data;
          this.receivables = this.receivables.filter(receivable => {
            if (receivable.state && receivable.state.description !== undefined) {
              return receivable.state.description !== "deleted";
            } else {
              return false;
            }
          });
          if (this.receivables.every(receivable => 'consecutive' in receivable)) this.receivables = this.receivables.sort((a, b) => a.consecutive - b.consecutive);
          this.getMaxReceivableValues();
          this.getEmptyData();
          setTimeout(() => {
            if (document) {
              this.initCharts('document', document);
            } else {
              this.initCharts();
            }
          }, 20);
        }
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.receivableService.getReceivableList(cargoConsecutive, receivableConsecutive, document, licensePlate, state, 'storage').subscribe(observerListReceivables);
  }

  processFilters($event) {
    if (($event && $event.type && $event.type === 'apply')) {
      const cargoConsecutive = this.getValueFromEvent($event.filtersValues, 'cargoConsecutive');
      const receivableConsecutive = this.getValueFromEvent($event.filtersValues, 'consecutive');
      const document = this.getValueFromEvent($event.filtersValues, 'document');
      const licensePlate = this.getValueFromEvent($event.filtersValues, 'licensePlate');
      this.getlist(cargoConsecutive, receivableConsecutive, document, licensePlate, this.status);
    } else {
      this.cleanAndReload();
    }
  }

  cleanAndReload() {
    this.emptyPaid = false;
    this.emptyPending = false;
    this.emptyStorage = true;
    this.resetPagination = !this.resetPagination;
    this.onSelectTab(this.tab);
  }
  refreshList() {
    this.resetPagination = !this.resetPagination;
    this.onSelectTab(this.tab);
  }

  getValueFromEvent(event: any, label: string): string {
    const filter = event.find((item: any) => item.label === label);
    return filter ? filter.value : null;
  }

  getFilteredReceivables(state: string): any[] {
    return this.receivables.filter(receivable => receivable.state.description === state);
  }

  getTotalValueReceivables(state: string) {
    return state === 'amountReceived' ? this.filteredReceivablesPaid.reduce((acc, obj) => acc + obj[state], 0) : this.filteredReceivablesPending.reduce((acc, obj) => acc + obj[state], 0);
  }

  getTotalBonusPerUser(parameter: string) {
    return this.filteredReceivablesPending.reduce((acc, obj) => acc + obj[parameter], 0)
  }

  getMaxReceivableValues() {
    this.filteredReceivablesPaid = this.receivables.filter(receivable => receivable.state.description === 'paid' && receivable.amountReceived);
    this.processDataReceivables(this.filteredReceivablesPaid, 'paid');
    this.filteredReceivablesPending = this.receivables.filter(receivable => receivable.state.description === 'pending' && receivable.amountInitial);
    this.processDataReceivables(this.filteredReceivablesPending, 'pending')
  }

  processDataReceivables(receivables, type) {
    let sortedArr = [];
    if (type === 'paid') {
      sortedArr = receivables.sort((a, b) => b.amountReceived - a.amountReceived);
    } else {
      sortedArr = receivables.sort((a, b) => b.amountInitial - a.amountInitial);
    }
    const uniqueLicensePlates = new Set();
    const resultArr = [];
    for (const obj of sortedArr) {
      if (uniqueLicensePlates.size >= 5) {
        break;
      }
      if (!uniqueLicensePlates.has(obj.licensePlate)) {
        if (type === 'paid') {
          resultArr.push({
            amountReceived: obj.amountReceived,
            licensePlate: obj.licensePlate,
          });
        } else {
          resultArr.push({
            amountInitial: obj.amountInitial,
            licensePlate: obj.licensePlate,
          });
        }
        uniqueLicensePlates.add(obj.licensePlate);
      }
    }
    if (type === 'paid') {
      this.maxPaid = {}
      resultArr.forEach(item => {
        const { licensePlate, amountReceived } = item;
        this.maxPaid[licensePlate] = amountReceived;
      });
    } else {
      this.maxPending = {}
      resultArr.forEach(item => {
        const { licensePlate, amountInitial } = item;
        this.maxPending[licensePlate] = amountInitial;
      });
    }
  }

  getUserReceivable(document) {
    const observerUser = {
      next: (data) => {
        this.spinner.hide();
        this.getEmptyData();
        this.activeLabel = true;
        if (data && data.information && data.information.name) this.ownerName = data.information.name;
      },
      error: () => {
        this.spinner.hide();
        this.activeLabel = false;
        this.ownerName = '';
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.receivableService.getUserReceivable(document).subscribe(observerUser);
  }

  getEmptyData() {
    this.filteredReceivablesPaid.length === 0 ? this.emptyPaid = true : this.emptyPaid = false;
    this.filteredReceivablesPending.length === 0 ? this.emptyPending = true : this.emptyPending = false;
  }
}
