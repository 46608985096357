<aside id="mainCollectionPointList" class="col-md-12S mb-0 h-70-margin">
  <div class="container-list h-100-margin">
    <div class="container-list-header">
      <div>
        <span class="cursor-pointer" (click)="goToList()">
          <i class="fas fa-angle-left"></i>
          Volver
        </span>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="title-component ml-3 mb-0" *ngIf="ticketform">
            <i class="fas fa-ticket-alt"></i>
            {{isCreate ? 'Creación de Caso': 'Ticket #' + ticketform.get('id').value }}
          </label>
        </div>
      </div>
    </div>
    <form class="form_container" id="formContact" [formGroup]="ticketform" (ngSubmit)="onSubmit()">
      <div class="scrollable-container">
        <div class="d-flex flex-md-row flex-column justify-content-center subject" *ngIf="!isCreate">
          <div>Asunto:</div>
          <div class="text-bold ml-0 ml-3">{{ticketform.get('subject').value}}</div>
        </div>
        <div class="w-100 d-flex flex-wrap justify-content-around align-items-center mt-3" *ngIf="!isCreate">
          <div class="d-flex justify-content-center align-items-center mw-card mx-md-5">
            <div class="type-card">
              <i [className]="currentType.icon"></i>
              {{currentType.title}}
            </div>
          </div>
          <div class="d-flex justify-content-around flex-wrap flex-fill m-3">
            <div class="d-flex flex-column flex-wrap flex-fill mx-3 mw-50">
              <div class="d-flex flex-wrap justify-content-between l-wrap">
                <div>Propietario</div>
                <div class="text-bold ml-3">{{ticketform.get('user').value? ticketform.get('user').value : '-'}}</div>
              </div>
              <div class="d-flex flex-wrap justify-content-between l-wrap">
                <div>Teléfono</div>
                <div class="text-bold ml-3">{{ticketform.get('phone').value? ticketform.get('phone').value : '-'}}</div>
              </div>
              <div class="d-flex flex-wrap justify-content-between l-wrap">
                <div>Correo electrónico</div>
                <div class="text-bold ml-3">{{ticketform.get('email').value? ticketform.get('email').value : '-'}}</div>
              </div>
            </div>
            <div class="d-flex flex-column flex-wrap flex-fill mx-3 mw-50">
              <div class="d-flex flex-wrap justify-content-between l-wrap">
                <div>Estado</div>
                <div class="text-bold ml-3">
                  <div class="circle" [ngStyle]="currentStateColor"></div>
                  {{ticketform.get('state').value}}
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-between l-wrap" *ngIf="ticketform.get('responsable').value">
                <div>Responsable</div>
                <div class="text-bold ml-3">{{ticketform.get('responsable').value}}</div>
              </div>
              <div class="d-flex flex-wrap justify-content-between l-wrap">
                <div>Fecha de creación</div>
                <div class="text-bold ml-3">{{ticketform.get('expeditionDate').value | dateFormat}}</div>
              </div>
              <div class="d-flex flex-wrap justify-content-between l-wrap"
                *ngIf="ticketform.get('endDate').value && ticketform.get('state').value==='Cerrado'">
                <div>Fecha de cierre</div>
                <div class="text-bold ml-3">{{ticketform.get('endDate').value | dateFormat}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex flex-wrap justify-content-around mt-3" *ngIf="isCreate">
            <label class="label-field col-12">Tipo de ticket</label>
            <div *ngFor="let type of types" class="type-card cursor-pointer" matTooltip="{{type.description}}"
              (click)="onSelectType(type.value)" [ngClass]="{'selected': ticketform.get('type').value=== type.value}">
              <i [className]="type.icon"></i>
              {{type.title}}
            </div>
          </div>
          <div class="col-12" *ngIf="isCreate">
            <mat-form-field appearance="standard">
              <mat-label>Asunto<span class="asterisk">*</span></mat-label>
              <input matInput type="text" formControlName="subject">
              <mat-error *ngIf="ticketform.get('subject').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <span class="separator-horizontal"></span>
        <mat-expansion-panel *ngIf="!isCreate && detailTicket && detailTicket.historyDescription">
          <mat-expansion-panel-header>
            <mat-panel-title class="title-purple">
              Historial
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul *ngFor="let Ticket of detailTicket.historyDescription" [ngClass]="{'my-response':isMe(Ticket.userName)}">
            <li class="title text-strong">
              {{Ticket.userName}}{{ticketform.get('user').value===Ticket.userName?'(Propietario)':''}}, {{Ticket.date |
              dateFormat:'dd-MM-yyyy, HH:mm'}}.
            </li>
            <div [innerHTML]="Ticket.description"></div><br>
          </ul>
        </mat-expansion-panel>
        <span class="separator-horizontal"></span>
        <div class="col-md-12 p-0" *ngIf="!ticketClosed && (hasPermissionToAnswer || isMyTicket)">
          <mat-form-field appearance="outline">
            <mat-label>{{isCreate?'Mensaje':'Responder'}}<span class="asterisk">*</span></mat-label>
            <textarea matInput formControlName="description" rows="5"
              placeholder="{{isCreate? 'Mensaje del ticket': 'Respuesta del ticket'}}"></textarea>
            <mat-error *ngIf="ticketform.get('description').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <span class="separator-horizontal"></span>
        <mat-card-actions class="mat-card-actions-vehcle text-center">
          <button mat-raised-button color="primary" type="button" class="button-width-auto primary-secondary mb-2"
            *ngIf="!ticketClosed && !isCreate &&(hasPermissionToAnswer || isMyTicket) && ticketform.get('state').value!=='En pausa'"
            (click)="changeStateTicket('En pausa')">
            Pausar ticket
          </button>
          <button mat-raised-button color="primary" type="button" class="button-width-auto primary-secondary mb-2"
            *ngIf="!ticketClosed && !isCreate && (hasPermissionToAnswer || isMyTicket)"
            (click)="changeStateTicket('Cerrado')">
            Cerrar ticket
          </button>
          <button mat-raised-button color="primary" type="button" class="button-width-auto"
            *ngIf="ticketClosed && !isCreate" (click)="changeStateTicket('En proceso')" permission-display
            [module]="permission.helpDesk.module" [functionality]="permission.helpDesk.reopenTicket">
            Re-abrir ticket
          </button>
          <button mat-raised-button color="primary" type="submit" class="button-width-auto"
            *ngIf="(!ticketClosed && (hasPermissionToAnswer || isMyTicket))||isCreate">
            {{isCreate? 'Crear ticket': 'Enviar respuesta'}}
          </button>
        </mat-card-actions>
      </div>
    </form>
  </div>
</aside>