<div class="container">
  <div id="mainFreightCharacteristics" class="col-md-12 mb-md-0 mb-12">
    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title"> Escribe el tipo de mercancía </label>
      </div>
      <!-- Boxs -->
      <div class="container">
        <div class="col-md-6 mb-md-0 mb-12 container-type-trips"
          *ngIf="
            !manualCreationCargoService.cargoForm.value.cargoModel
              .packagingType ||
            (manualCreationCargoService.cargoForm.value.cargoModel
              .packagingType &&
                manualCreationCargoService.cargoForm.value.cargoModel
                .packagingType.code !== '18') || (manualCreationCargoService.cargoForm.value.cargoModel
                .packagingType.code === '18' && (!manualCreationCargoService.cargoForm.value.cargoModel.serviceType.name.includes('Contenedor vacío')))">
          <div class="row">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Tipo de empaque<span class="asterisk">*</span></mat-label>
                <mat-select (selectionChange)="onSelectPackagingType($event)"
                  [formControl]="manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code')"
                  [returnWith]="returnFnPackagingType">
                  <div *ngFor="let packagingType of modelCargo.packagingTypes">
                    <mat-option *ngIf="!packagingType.hidden" [value]="packagingType.code">
                      {{ packagingType.description }}
                    </mat-option>
                  </div>
                </mat-select>
                <mat-error
                  *ngIf="manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code').errors?.required">
                  {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-md-0 mb-12 container-type-trips">
          <div class="row">
            <app-cargo-type-merchandise class="col-md-12" [inputFormControl]="merchandiseFormCtrl"
              [options]="optionsTypeMerchandise" [validate]="validate">
            </app-cargo-type-merchandise>
          </div>
        </div>
        <div class="alert alert-danger col-md-12 mb-md-0 mt-2 d-flex flex-row align-items-center" role="alert"
          *ngIf="activeDangerousProductType">
          <i class="fas fa-exclamation-triangle fa-lg mr-3"></i> <span class="text-bold">El tipo de mercancía
            seleccionada está catalogada como {{
            (manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature.value
            | uppercase) }}, SI selecciona este tipo la carga sera tipo {{
            (manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature.value
            | uppercase) }}</span>
        </div>
        <div class="col-md-12 mb-md-0 mb-12 container-type-trips">
          <div class="row">
            <div class="col-md-12">
              <mat-checkbox (change)="setDescriptionValidators($event)" class="example-margin"
                [formControl]="hasDescription">
                Describir la mercancía
              </mat-checkbox>
              <mat-form-field class="example-full-width" *ngIf="hasDescription && hasDescription.value">
                <mat-label>Descripción de la mercancía<span class="asterisk"
                    *ngIf="utils.isRequiredField(manualCreationCargoService.cargoForm.get('cargoFeature.productType.description'))">*</span></mat-label>
                <input matInput
                  [formControl]="manualCreationCargoService.cargoForm.get('cargoFeature.productType.description')" />
                <mat-error
                  *ngIf="manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').errors?.required">
                  {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- Button -->
        <div class="col-12 button-container justify-content-center mt-3">
          <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
          <app-request-button></app-request-button>
          <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>