import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { Permission } from 'src/app/core/resources/permission';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cargo-tabs-create',
  templateUrl: './cargo-tabs-create.component.html',
  styleUrls: ['./cargo-tabs-create.component.scss'],
  providers: [AuthService, Global, DatePipe]
})

export class CargoCreateTabsComponent implements OnInit {

  // DatePicker
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'visible';
  showListLatestRequests = false;
  permission = Permission;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private authService: AuthService
  ) {

  }

  ngOnInit() {

  }

  onSelectTab(event) {
    switch (event) {
      case 1:
        this.showListLatestRequests = true;
        break;
    }
  }

}
