import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { environment } from '../../../../environments/environment';
import { Cargo, RequestUpdateBody } from 'src/app/core/interfaces/cargo';
import { DiscountsAndBonuses } from 'src/app/core/interfaces/discountsAndBonuses';
import { Payments } from 'src/app/core/interfaces/payments';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Utils } from 'src/app/core/resources/utils';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { Destination } from 'src/app/core/interfaces/destination';

@Injectable({
  providedIn: 'root'
})
export class CargoDetailService {

  permission = Permission;

  constructor(
    private _http: HttpClient,
    private _endpointResources: Endpoints,
    private companyManager: CompanyManager,
    public snackBarService: SnackBarService,
    private permissionRole: PermissionRole,
    private utils: Utils,
  ) { }

  public acceptCargo(params) {

    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlAcceptCargo,
      params
    );
  }

  public refuseCargo(params) {

    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlRefuseCargo,
      params
    );
  }

  public negotiationCargo(params) {

    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlNegotiateCargo,
      params
    );
  }

  public requestCargo(idCargo: string) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.urlValidateRequestCargo + idCargo, null
    )
  }

  public addObservation(observation: any, idCargo: string) {

    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.addObservation + idCargo,
      observation
    );
  }

  public negotiationRequest(action: string, params) {

    return this._http.post(
      `${environment.urlServerTeclogi}${this._endpointResources.urlNegotiateRequestSubCompany}${action}`,
      params
    );
  }

  public cancelCargo(params) {

    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.urlCancelCargo,
      params
    );
  }

  public removeCargo(idCargo, data, hasPaymentsAndPermission = false) {
    let url = environment.urlServerTeclogi + this._endpointResources.urlRemoveCargo + idCargo;
    if (hasPaymentsAndPermission) url += '&deleteWithPayment=true'
    return this._http.delete(
      url,
      data
    );
  }


  public disassociate(idsCargo, type) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargo + "/" + type,
      {
        'cargoId': idsCargo,
        'type': "Delete"
      }
    );
  }

  public editInvoice(idsCargo, type, value) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargo + "/" + type,
      {
        'cargoId': idsCargo,
        'type': "Update",
        "value": value
      }
    );
  }
  public detailCargo(idCargo) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.urlDetailCargo + idCargo
    );
  }

  public detailCargoByConsecutive(consecutive: string) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.urlDetailCargoByConsecutive + consecutive
    );
  }

  public detailServiceRequestByConsecutive(consecutive: string) {
    //Pendiente definir como manejar params y headers
    const headers = new HttpHeaders().set('companyId', '9002585606');
    let params = new HttpParams();
    params = params.append('cargoHolderId', '9002585606');
    return this._http.get(
      `${environment.urlServerTeclogi}${this._endpointResources.urlCargoServiceRequestsDetail}/${consecutive}`, { headers, params }
    );
  }

  public getGuideStates() {
    return this._http.get<Catalog>(
      environment.urlServerTeclogi + this._endpointResources.urlCatalog + '/GuideState'
    );
  }

  public setGuideStates(guideId: string, state: string, nit: string, date: string) {
    let params = new HttpParams();
    params = params.append('companyId', nit);
    params = params.append('state', state);
    params = params.append('guideId', guideId);
    params = params.append('date', date);
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.setGuideStateFalabella,
      {},
      { params }
    );
  }

  public updateWeightConsignment(data) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateWeightConsignment,
      data
    );
  }

  public updateFreightForwarder(data) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargo,
      data
    );
  }

  public updateCargo(data) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargo,
      data
    );
  }

  public completeUpdateRequest(data, realCargo: Cargo) {
    const keys = [
      'driver', 'licensePlate', 'observation', 'observationDriver', 'dateLoad', 'shippingCost',
      'errorFieldsExcelCargo', 'cargoModel', 'cargoFeature', 'container', 'containerExpirationDate',
      'seal', 'numberDocumentSender', 'ministry', 'cargoOwner', 'idCompany', 'estimatedTime', 'distancy'
    ];
    const newCargo: RequestUpdateBody = {
      id: realCargo.id,
      state: realCargo.state,
      dateLoad: realCargo.dateLoad,
      shippingCost: realCargo.shippingCost
    };

    keys.forEach((key) => {
      if (key in data)
        newCargo[key] = data[key];
      else
        newCargo[key] = this.utils.getNestedValue(realCargo, key);
    });
    if (this.utils.getNestedValue(newCargo, 'cargoFeature.uploadDownload.origin.addresses'))
      newCargo.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
        if (address['idTracking'])
          delete address['idTracking'];
      });
    if (this.utils.getNestedValue(newCargo, 'cargoFeature.uploadDownload.destination'))
      newCargo.cargoFeature.uploadDownload.destination.forEach((destination) => {
        if (destination && destination.addresses)
          destination.addresses.forEach((address) => {
            if (address['idTracking'])
              delete address['idTracking'];
          });
      });

    return this.updateRequest(newCargo);
  }

  private updateRequest(data: RequestUpdateBody) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateRequest,
      data
    );
  }
  public updateLoadDownload(data, idCargo) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateLoadDownload + idCargo, data
    );
  }

  public getRouteGoogleCargo(idCargo: string) {
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.getRouteGoogleCargo + idCargo
    );
  }

  public saveRouteGoogleCargo(routeGoogle) {
    return this._http.post(
      environment.urlServerTeclogi + this._endpointResources.saveRouteGoogleCargo,
      routeGoogle
    );
  }

  public getCargoPDF(pdfType: string, cargoId: string) {
    let params = new HttpParams();
    params = params.append('pdfType', pdfType);
    params = params.append('cargoId', cargoId);
    return this._http.get(
      environment.urlServerTeclogi + this._endpointResources.cargoPDF,
      { params, responseType: 'blob' }
    );
  }

  public getHistoryDiscountsAndBonuses(cargo: Cargo, type: number, category?: 'amount' | 'amountRate'): DiscountsAndBonuses[] {
    if (cargo.shippingCost && cargo.shippingCost.modifications) {
      const history = cargo.shippingCost.modifications.filter((item) => {
        return item.type === type;
      });
      if (category) return history.filter((item) => category === 'amount' ? !!item.amount : !!item.amountRate);
      return history;
    }
    return [];
  }

  public getHistoryDiscountsAndBonusesAdditionalServices(additionalServiceGroupCtrl, type: number): DiscountsAndBonuses[] {
    if (additionalServiceGroupCtrl && additionalServiceGroupCtrl.financialInformation && additionalServiceGroupCtrl.financialInformation.modifications) {
      return additionalServiceGroupCtrl.financialInformation.modifications.filter((item) => {
        return item.type === type;
      });
    }
    return [];
  }

  public getHistoryPaid(cargo: Cargo): Payments[] {
    if (cargo.shippingCost && cargo.shippingCost.payments) {
      return cargo.shippingCost.payments.filter((item) => {
        return item;
      });
    }
    return [];
  }

  public addAdditionalcost(cargoId: string, body): Observable<BasicResponse> {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.post<BasicResponse>(
      environment.urlServerTeclogi + this._endpointResources.addAdditionalcost,
      body,
      { params }
    );
  }

  public updateAdditionalcost(cargoId: string, body): Observable<BasicResponse> {
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    return this._http.post<BasicResponse>(
      environment.urlServerTeclogi + this._endpointResources.updateAdditionalcost,
      body,
      { params }
    );
  }

  public updatecargoFeature(idCargo, newData) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updatecargoFeature + idCargo,
      newData
    );
  }

  public deletedAdditionalCosts(idCargo) {
    return this._http.get(
      `${environment.urlServerTeclogi}${this._endpointResources.deletedAdditionalCosts}?cargoId=${idCargo}`
    )
  }

  public isValidCargo(cargo: Cargo, showMessages: boolean = false): boolean {
    return this.isValidGeneralData(cargo, showMessages)
      && this.isValidTripType(cargo, showMessages)
      && this.isValidCargoCharacteristics(cargo, showMessages)
      && this.isValidUploadDownload(cargo, showMessages)
      && this.isValidShippingCost(cargo, showMessages);
  }

  public isValidGeneralData(cargo: Cargo, showMessages: boolean): boolean {
    if (!cargo.idCompany)
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_COMPANY, undefined, 'alert');
    else if (!cargo.numberDocumentSender)
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_DOCUMENT_SENDER, undefined, 'alert');
    else if (cargo.ministry && (!cargo.cargoOwner || !cargo.cargoOwner.name || !cargo.cargoOwner.documentNumber || !cargo.cargoOwner.documentType))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_CARGO_OWNER, undefined, 'alert');
    else if (cargo.ministry && !cargo.cargoOwner.branchOffice)
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_CARGO_OWNER_OFFICE, undefined, 'alert');
    else return true;

    return false;
  }

  public isValidTripType(cargo: Cargo, showMessages: boolean): boolean {
    const tripType = this.utils.getNestedValue(cargo, 'cargoModel.tripType.name');
    if (!tripType) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_TRIP_TYPE, undefined, 'alert');
      return false;
    }
    if (tripType === "Exportación" && !this.utils.getNestedValue(cargo, 'cargoModel.tripType.letterRetirement')) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_LETTER_RETIREMENT, undefined, 'alert');
      return false;
    }
    const regimeType = this.utils.getNestedValue(cargo, 'cargoModel.tripType.regimeType');
    if ((tripType === "Importación" || tripType === "Exportación") && !regimeType) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_REGIME_TYPE, undefined, 'alert');
      return false;
    }
    if (regimeType && ['OTM', 'DTA', 'DUTA'].includes(regimeType) && !this.utils.getNestedValue(cargo, 'cargoModel.tripType.date')) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_DUTA_DATE, undefined, 'alert');
      return false;
    }
    if (regimeType && ((regimeType === "OTM" && tripType === "Exportación") || (regimeType === "DTAI" && tripType === "Importación"))) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.WRONG_REGIME_TYPE, undefined, 'alert');
      return false;
    }
    const cargoType = this.utils.getNestedValue(cargo, 'cargoModel.cargoType.name');
    if (["Importación", "Exportación", "Nacional", "Internacional", "Urbana"].includes(tripType) && !cargoType) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_CARGO_TYPE, undefined, 'alert');
      return false;
    }
    const containerSize = this.utils.getNestedValue(cargo, 'cargoModel.cargoType.containerMeasure.size');
    if (cargoType && cargoType === "Contenedor" && !containerSize) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_CONTAINER_MEASURE, undefined, 'alert');
      return false;
    }
    const serviceType = this.utils.getNestedValue(cargo, 'cargoModel.serviceType.name');
    if (!serviceType) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_SERVICE_TYPE, undefined, 'alert');
      return false;
    }
    if (cargoType && ((cargoType === "Contenedor" && !serviceType.includes("Contenedor"))
      || (cargoType !== "Contenedor" && serviceType.includes("Contenedor")))) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.WRONG_SERVICE_TYPE, undefined, 'alert');
      return false;
    }
    if (!cargo.observation) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_OBSERVATIONS, undefined, 'alert');
      return false;
    }
    return true;
  }

  public isValidCargoCharacteristics(cargo: Cargo, showMessages: boolean): boolean {
    const productType = this.utils.getNestedValue(cargo, 'cargoFeature.productType.name');
    const totalWeigth = this.utils.getNestedValue(cargo, 'cargoFeature.cargoMeasure.totalWeigth');
    const unit = this.utils.getNestedValue(cargo, 'cargoFeature.cargoMeasure.unit');
    const amount = this.utils.getNestedValue(cargo, 'cargoFeature.cargoMeasure.amount');
    const vehicleType = this.utils.getNestedValue(cargo, 'cargoFeature.vehicleType.name');
    const bodyworkType = this.utils.getNestedValue(cargo, 'cargoFeature.vehicleType.bodyWorkType.name');

    if (!this.utils.getNestedValue(cargo, 'cargoModel.packagingType.description') || !this.utils.getNestedValue(cargo, 'cargoModel.packagingType.code'))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_PACKAGING_TYPE, undefined, 'alert');
    else if (!this.utils.getNestedValue(cargo, 'cargoFeature.productType.name') || !this.utils.getNestedValue(cargo, 'cargoFeature.productType.code'))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_PRODUCT_TYPE, undefined, 'alert');
    else if (!totalWeigth && productType !== "CONTENEDOR VACIO")
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_TOTAL_WEIGHT, undefined, 'alert');
    else if (totalWeigth && productType === "CONTENEDOR VACIO")
      showMessages && this.snackBarService.openSnackBar(CargoMessages.TOTAL_WEIGHT_TO_EMPTY_CONTAINERS, undefined, 'alert');
    else if (!unit)
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_WEIGHT_UNIT, undefined, 'alert');
    else if ((unit === "1" && totalWeigth > AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG) || (unit === "2" && totalWeigth > AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL))
      showMessages && this.snackBarService.openSnackBar(
        CargoMessages.OVERWEIGHT + (unit === "1" ? AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL), undefined, 'alert');
    else if (!amount && productType !== "CONTENEDOR VACIO")
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_AMOUNT, undefined, 'alert');
    else if (amount && productType === "CONTENEDOR VACIO")
      showMessages && this.snackBarService.openSnackBar(CargoMessages.AMOUNT_TO_EMPTY_CONTAINERS, undefined, 'alert');
    else if (!vehicleType)
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_VEHICLE_TYPE, undefined, 'alert');
    else if (!bodyworkType)
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_BODYWORK_TYPE, undefined, 'alert');
    else return true;

    return false;
  }

  public isValidUploadDownload(cargo: Cargo, showMessages: boolean): boolean {
    let hasErrors = false;
    if (!this.utils.getNestedValue(cargo, 'cargoFeature.uploadDownload.origin.name')) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_ORIGIN_CITY, undefined, 'alert');
      return false;
    }
    if (!cargo.dateLoad || DateManager.isBefore(DateManager.stringToDate(cargo.dateLoad, 'YYYY-MM-DD HH:mm ZZ'), new Date())) {
      showMessages && this.snackBarService.openSnackBar(CargoMessages.LOADDATE_BEFORE_TODAY, undefined, 'alert');
      return false;
    }
    const tripType = this.utils.getNestedValue(cargo, 'cargoModel.tripType.name');
    const originAddresses: AddressCargo[] = this.utils.getNestedValue(cargo, 'cargoFeature.uploadDownload.origin.addresses');
    if (!originAddresses || !originAddresses.length) {
      showMessages && this.snackBarService.openSnackBar("No hay direcciones de origen diligenciadas", undefined, 'alert');
      return false;
    }
    originAddresses.forEach((address, index) => {
      if (!hasErrors) {
        if (!address.address) {
          showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado la dirección ${index + 1} de origen`, undefined, 'alert');
          hasErrors = true;
        } else if (!address.location || !address.location.lat || !address.location.lng) {
          showMessages && this.snackBarService.openSnackBar(`No se han diligenciado las coordenadas de la dirección ${index + 1} de origen`, undefined, 'alert');
          hasErrors = true;
        } else if (cargo.ministry && tripType !== "Internacional" && (!address.thirdPartyConsignment || !address.thirdPartyConsignment.documentType || !address.thirdPartyConsignment.documentNumber || !address.thirdPartyConsignment.name || !address.thirdPartyConsignment.branchOffice)) {
          showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado el remitente de la dirección ${index + 1} de origen`, undefined, 'alert');
          hasErrors = true;
        } else if (!address.time) {
          showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado la hora de cargue de la dirección ${index + 1} de origen`, undefined, 'alert');
          hasErrors = true;
        }
      }
    })
    if (hasErrors) return false;

    let previousDate = cargo.dateLoad;
    const destinationCities: Destination[] = this.utils.getNestedValue(cargo, 'cargoFeature.uploadDownload.destination');
    if (!destinationCities || !destinationCities.length) {
      showMessages && this.snackBarService.openSnackBar("No hay destinos diligenciados", undefined, 'alert');
      return false;
    }
    destinationCities.forEach((city, index) => {
      if (!hasErrors && (!city.name)) {
        showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado la ciudad ${index + 1} de destino`, undefined, 'alert');
        hasErrors = true;
      } else if (!hasErrors && !city.downloadDate) {
        showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado fecha de descargue de la ciudad ${index + 1} de destino`, undefined, 'alert');
        hasErrors = true;
      } else if (!hasErrors && DateManager.isBefore(DateManager.stringToDate(city.downloadDate, 'YYYY-MM-DD HH:mm ZZ'), new Date())) {
        showMessages && this.snackBarService.openSnackBar(`La fecha de descargue de la ciudad ${index + 1} de destino es anterior a hoy`, undefined, 'alert');
        hasErrors = true;
      } else if (!hasErrors && DateManager.isBefore(DateManager.stringToDate(city.downloadDate, 'YYYY-MM-DD HH:mm ZZ'), DateManager.stringToDate(previousDate, 'YYYY-MM-DD HH:mm ZZ'))) {
        showMessages && this.snackBarService.openSnackBar(`La fecha de descargue de la ciudad ${index + 1} de destino es anterior a ${index === 0 ? 'la fecha de cargue' : 'la fecha del destino anterior'}`, undefined, 'alert');
        hasErrors = true;
      }
      previousDate = city.downloadDate;
      if (!city.addresses || !city.addresses.length) {
        showMessages && this.snackBarService.openSnackBar(`No hay direcciones diligenciadas para el destino ${index + 1}`, undefined, 'alert');
        return false;
      }
      !hasErrors && city.addresses.forEach((address, indexAddress) => {
        if (!hasErrors) {
          if (!address.address) {
            showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado la dirección ${indexAddress + 1} de la ciudad ${index + 1} de destino`, undefined, 'alert');
            hasErrors = true;
          } else if (!address.location || !address.location.lat || !address.location.lng) {
            showMessages && this.snackBarService.openSnackBar(`No se han diligenciado las coordenadas de la dirección ${indexAddress + 1} de la ciudad ${index + 1} de destino`, undefined, 'alert');
            hasErrors = true;
          } else if ((!address.cargoMeasure || !address.cargoMeasure.totalWeigth) && cargo.cargoFeature.productType.name !== "CONTENEDOR VACIO") {
            showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado el peso de la dirección ${indexAddress + 1} de la ciudad ${index + 1} de destino`, undefined, 'alert');
            hasErrors = true;
          } else if (cargo.ministry && tripType !== "Internacional" && (!address.thirdPartyConsignment || !address.thirdPartyConsignment.documentType || !address.thirdPartyConsignment.documentNumber || !address.thirdPartyConsignment.name || !address.thirdPartyConsignment.branchOffice)) {
            showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado el destinatario de la dirección ${indexAddress + 1} de la ciudad ${index + 1} de destino`, undefined, 'alert');
            hasErrors = true;
          } else if (!address.time) {
            showMessages && this.snackBarService.openSnackBar(`No se ha diligenciado la hora de descargue de la dirección ${indexAddress + 1} de la ciudad ${index + 1} de destino`, undefined, 'alert');
            hasErrors = true;
          }
        }
      })
    })
    return !hasErrors;
  }

  getUtilityCargo(cargo: Cargo) {
    const rate = this.utils.getNestedValue(cargo, 'shippingCost.rate') ? cargo.shippingCost.rate : 1;
    const totalCost = this.utils.getNestedValue(cargo, 'shippingCost.totalCost') ? cargo.shippingCost.totalCost : 1;
    return ((rate - totalCost) / rate) * 100;
  }

  public isValidShippingCost(cargo: Cargo, showMessages: boolean): boolean {
    const minUtility = this.companyManager.getUtilityByCompanyId(cargo.idCompany);
    const maxAdvancePercentage = this.companyManager.getAdvancePercentageByCompanyId(cargo.idCompany);
    if (!cargo.shippingCost || (!cargo.shippingCost.rate && !this.hasPermissionNoMinimumUtility(cargo)))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_RATE, undefined, 'alert');
    else if ((!cargo.shippingCost.freightCost || !cargo.shippingCost.totalCost) && !this.hasPermissionNoMinimumUtility(cargo))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_TOTAL_COST, undefined, 'alert');
    else if (this.getUtilityCargo(cargo) < minUtility && !this.hasPermissionNoMinimumUtility(cargo))
      showMessages && this.snackBarService.openSnackBar(Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, minUtility), undefined, 'alert');
    else if (!this.utils.isDefined(cargo.shippingCost.advancePercentage))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_ADVANCE_PERCENTAGE, undefined, 'alert');
    else if (cargo.shippingCost.advancePercentage > maxAdvancePercentage)
      showMessages && this.snackBarService.openSnackBar(CargoMessages.MAX_ADVANCE_PERCENTAGE_EXCEED, undefined, 'alert');
    else if (!this.utils.isDefined(cargo.shippingCost.paymentTime))
      showMessages && this.snackBarService.openSnackBar(CargoMessages.NO_PAYMENT_TYPE, undefined, 'alert');
    else return true;

    return false;
  }
  hasPermissionNoMinimumUtility(cargo) {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createCargosWithoutMinimumUtility
    )
      && cargo.cargoModel.tripType.name === "Urbana"
      && cargo.cargoFeature.productType.name === "CONTENEDOR VACIO";
  }

  public updateCargoLabels(idCargo, newData) {
    return this._http.put(
      environment.urlServerTeclogi + this._endpointResources.updateCargoLabels + idCargo,
      newData
    );
  }
}
