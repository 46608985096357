<!-- USER NO VALIDATE -->
<div id="containerUserInactive"
  *ngIf="authService.getUserSession().information.document && (!authService.userActive())">
  <span>Tu cuenta está pendiente por activar, <span class="underline" routerLink='/contact'>contacta al
      administrador</span></span>
</div>

<!--section1-->
<div id="imgtruck" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner ">
    <div class="carousel-item active">
      <img src="/assets/d.header/Foto1.png" class="d-block w-100" alt="...">
      <div class="overlay">
        <div class="container-fluid">
          <div class="row align-top-titles">
            <div class="col col-md-6 offset-1 text-left">
              <div class="tittles">
                <h1 class="text__tittles">Tecnología que <br> transforma la logística</h1>
                <h3 class="text__tittles--sub">Conectamos camiones disponibles en el mercado con <br>
                  carga de manera ágil y segura</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <!--section2-->
    <div class="col-12 col-lg text-center images__info">
      <img class="" src="/assets/d.header/Group.svg" alt="group">
      <p>Contamos con una gran oferta y variedad de vehículos y cargas </p>
    </div>
    <div class="col-12 col-lg text-center images__info">
      <img src="/assets/d.header/Group 2.svg" alt="group">
      <p>Facilitamos negociaciones ágiles y transparentes desde nuestra plataforma </p>
    </div>
    <div class="col-12 col-lg text-center images__info">
      <img src="/assets/d.header/031-maps-and-location.svg" alt="maps">
      <p>Optimizamos tiempos de entrega con nuestras rutas planificadas</p>
    </div>
    <!--section3-->
    <div class="col-12 col-lg-12 images__info  ">
      <h2 class="text__info--details">Únete a esta una nueva forma de transportar carga</h2>
    </div>
    <div class="col-12 col-lg-6 cards__cont">
      <img src="/assets/d.header/shutterstock_1171923346@2x.png" class="img-fluid" alt="soy transportador">
      <p class="cont__details text-center cont__datils--font">Soy transportador</p>
    </div>
    <div class="col-12 col-lg-6 cards__cont ">
      <img src="/assets/d.header/16613.png" class="img-fluid" alt="Soy expedidor">
      <p class="cont__details text-center">Soy expedidor</p>
    </div>
    <!--section4-->
    <div class="col-12 col-lg-12 text-center">
      <h2 class="text__information">Miles de transportadores y expedidores de carga del país <br> confían en
        nosotros</h2>
    </div>
    <div class="col-12 col-lg text-center">
      <div class="number"> 98 </div><br>
      <p class="text__info"> cargas disponibles para transportar </p>
    </div>
    <div class="col-lg text-center">
      <div class="number"> 312 </div><br>
      <p class="text__info"> camiones listos para cargar </p>
    </div>
    <div class="col-lg text-center ">
      <div class="number"> 36</div><br>
      <p class="text__info"> entregas exitosas en todo el país </p>
    </div>
  </div>
  <div class="col-12 col-lg text-center">
    <div class="number"> 98 </div><br>
    <p class="text__info"> cargas disponibles para transportar </p>
  </div>
  <div class="col-lg text-center">
    <div class="number"> 312 </div><br>
    <p class="text__info"> camiones listos para cargar </p>
  </div>
  <div class="col-lg text-center ">
    <div class="number"> 36</div><br>
    <p class="text__info"> entregas exitosas en todo el país </p>
  </div>
</div>
<div>
  <div class="">
    <img src="/assets/d.header/poniendo-logo01.png" class="img-fluid--logo" alt="Soy expedidor">
  </div>
</div>