export enum NotificationTypes {
    NEGOTIATION = '1',
    TRACKING = '2',
    DELAY_BETWEEN_POINTS = '3',
    CREATE_CARGO = '4',
    USER_DEACTIVATE = '5',
    GEOFENCE = '6',
    PANIC = '7',
    GPS_OFF = '8',
    ANTENNA = '9',
    VEHICLE_OFF = '10',
    LOW_BATTERY = '11',
    OPENED_DOOR = '12',
    DEFAULT_ALERT = '13'
}
