<form [formGroup]="form" (ngSubmit)="onSubmit()" class="cargo__billing px-2 px-md-5">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="cargo__billing__header">
    <h4 class="text-center text-strong">Facturación</h4>
    <hr>
    <div>
      <h5 class="text-center text-strong">Total a facturar ({{cargos.length}}):
        {{cargoListService.getTotalValueRateCargos(cargos) |
        currency:'COP':'code'}}</h5>
      <ul class="cargo__billing__list">
        <li *ngFor="let cargo of cargos;">
          <span class="text-strong">
            Carga {{cargo.consecutive}}:
          </span>
          <span>
            {{cargoListService.getTotalValueRateCargo(cargo) | currency:'COP':'code'}}
          </span>
          <figure class="icon__bill" *ngIf="hasSiigoIntegration">
            <img src="assets/img/logo_siigo.png">
          </figure>
        </li>
      </ul>
    </div>
  </div>
  <div class="cargo__billing__body">
    <mat-form-field appearance="standard" *ngIf="isBillIntegration">
      <mat-label>Fecha de Vencimiento<span class="asterisk"
          *ngIf="utils.isRequiredField(form.get('dueDate'))">*</span></mat-label>
      <input type="text" matInput [matDatepicker]="datePicker" formControlName="dueDate" (click)="datePicker.open()"
        (dateChange)="onChangeDatePicker($event)" readonly>
      <mat-error *ngIf="form.get('dueDate').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="standard" *ngIf="isBillIntegration">
      <mat-label>Centro de Costo<span class="asterisk"
          *ngIf="utils.isRequiredField(form.get('costCenter'))">*</span></mat-label>
      <input type="text" #inputAutoComplete matInput [formControl]="costCenterControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCostCenterSelected">
        <mat-option *ngFor="let costCenter of listCostCenter | async" [value]="costCenter">
          {{costCenter.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('costCenter').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>

    <div class="w-100 d-flex justify-content-between align-items-baseline flex-wrap" *ngIf="isBillIntegration">
      <label class="text-strong">¿Mostrar todos los centros de costo?&nbsp;&nbsp;</label>
      <mat-button-toggle-group #group="matButtonToggleGroup" [formControl]="showAllCostCenters" class="h-auto m-0">
        <mat-button-toggle [value]="true" (change)="toggleListCostCenter()">
          Si
        </mat-button-toggle>
        <mat-button-toggle [value]="false" (change)="toggleListCostCenter()">
          No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <mat-form-field appearance="standard" *ngIf="isBillIntegration && hasSiigoIntegration">
      <mat-label>Método de pago<span class="asterisk">*</span></mat-label>
      <mat-select formControlName="paymentType">
        <mat-option *ngFor="let payment of paymentTypes" [value]="{code: payment.id}" [disabled]="!payment.active">
          {{payment.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('paymentType').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>

    <mat-card-content *ngIf="isBillIntegration">
      <mat-form-field appearance="standard">
        <mat-label>Observaciones</mat-label>
        <textarea matInput formControlName="observations"></textarea>
      </mat-form-field>
    </mat-card-content>

    <mat-form-field appearance="standard" *ngIf="((isBill && !isBillIntegration) || !isBill) && hasSiigoIntegration">
      <mat-label>
        {{isBill && !isBillIntegration ? 'Ingrese el Nº de Factura' : 'Ingrese el Nº de Comprobante'}}<span
          class="asterisk" *ngIf="utils.isRequiredField(form.get('billingId'))">*</span>
      </mat-label>
      <input matInput formControlName="billingId" type="text">
      <mat-error *ngIf="form.get('billingId').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="w-100 d-flex justify-content-between align-items-baseline flex-wrap mb-2" *ngIf="isBillIntegration">
    <label class="text-strong">¿Desea enviar reporte a la DIAN?&nbsp;&nbsp;</label>
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="sendDIAN" class="h-auto">
      <mat-button-toggle [value]="true">
        Si
      </mat-button-toggle>
      <mat-button-toggle [value]="false">
        No
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="w-100 d-flex justify-content-between align-items-baseline flex-wrap mb-2" *ngIf="isBillIntegration">
    <label class="text-strong">¿Desea notificar a los clientes por correo?&nbsp;&nbsp;</label>
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="sendEmail" class="h-auto">
      <mat-button-toggle [value]="true">
        Si
      </mat-button-toggle>
      <mat-button-toggle [value]="false">
        No
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="cargo__billing__footer">
    <mat-card-actions class="">
      <button type="button" mat-raised-button class="primary-secondary button-width-auto" color="primary"
        (click)="cancel()">
        Cancelar
      </button>
      <button type="submit" mat-raised-button color="primary" class="button-width-auto">
        Facturar
      </button>
    </mat-card-actions>
  </div>
</form>