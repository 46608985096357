import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Tag } from 'src/app/core/interfaces/tag';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss']
})
export class TagFormComponent implements OnInit {

  minLengthTag = 2;
  maxLengthTag = 30;
  inputTag = new FormControl('', [Validators.required, Validators.minLength(this.minLengthTag), Validators.maxLength(this.maxLengthTag)])

  constructor(
    public dialogRef: MatDialogRef<TagFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title;
      tag: Tag;
      tagList: Tag[];
      confirmBtn;
    },
    private snackBarService: SnackBarService,
    public utils: Utils,
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.tag) this.inputTag.setValue(this.data.tag.name)
  }

  get tagError(): string {
    if (this.inputTag.valid && this.data && this.data.tagList) {
      const normalizedInputValue = this.inputTag.value.replace(/\s+/g, '').toLowerCase();
      const exists = this.data.tagList.some(tag => {
        const normalizedTagName = tag.name.replace(/\s+/g, '').toLowerCase();

        return (!this.data.tag || (this.data.tag && this.data.tag.name !== normalizedTagName)) &&
          normalizedTagName === normalizedInputValue;
      });

      if (exists) {
        return "Ya existe una etiqueta con este nombre";
      }
    }
    return "";


  }

  confirm() {
    this.inputTag.markAsTouched();
    if (this.utils.errorMessagesCustomized(this.inputTag, 'nombre', this.minLengthTag, this.maxLengthTag)) return;
    if (this.tagError) {
      this.snackBarService.openSnackBar(this.tagError, undefined, 'error');
      return;
    }
    let newTag: Tag = {
      name: this.inputTag.value,
    }
    if (this.data && this.data.tag && this.data.tag.id) newTag['id'] = this.data.tag.id;

    this.dialogRef.close({ tag: newTag })
  }

}
