<form [formGroup]="form" (ngSubmit)="createCargo()" class="py-4 px-2 px-sm-3 px-md-5 w-100">
    <div class="row">
        <h3 class="col-12 mt-3 mb-5 text-bold">Creación solicitud servicio de transporte</h3>
        <p class="col-12 my-3">
            <span class="text-bold">Contratante del servicio</span> (Propietario de la carga / Generador de carga /
            Contratante)
        </p>
        <div class="col-md-12">
            <app-select-company [options]="optionsCompany" [inputFormControl]="form.get('company')"
                [validate]="formValidate">
            </app-select-company>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>N° de identificador cliente</mat-label>
                <input matInput type="text" formControlName="doc">
            </mat-form-field>
        </div>

        <p class="col-12 my-3 text-bold">
            Origen y destino final
        </p>
        <app-autocomplete-city [inputFormControl]="form.get('origin')" class="col-md-6" [options]="originCityOptions"
            [validate]="formValidate">
        </app-autocomplete-city>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Fecha de cargue estimada<span class="asterisk">*</span></mat-label>
            <input matInput [min]="loadDateOptions.min" [matDatepicker]="pickerLoadDate" formControlName="dateLoad"
                name="loadDate" (click)="pickerLoadDate.open()" (dateChange)="updateDestinationDates()" readonly>
            <mat-datepicker-toggle matSuffix [for]="pickerLoadDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerLoadDate></mat-datepicker>
            <mat-error *ngIf="form.get('dateLoad').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>

        <div class="col-12 d-flex flex-wrap" *ngFor="let destination of destinations?.controls; let i = index">
            <label class="col-12 d-flex justify-content-between align-items-center">
                <p class="mb-0 text-bold">Destino {{i+1}}</p>
                <i matTooltip="Eliminar destino" class="fa-solid fa-xmark cursor-pointer" *ngIf="!!i"
                    (click)="deleteDestination(i)"></i>
            </label>
            <app-autocomplete-city [inputFormControl]="destination.get('destination')" class="col-md-6"
                [options]="destinationCityOptions" [validate]="formValidate">
            </app-autocomplete-city>

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Fecha de descargue estimada</mat-label>
                <input matInput [min]="endDateOptions[i].min" [matDatepicker]="pickerEndDate"
                    [formControl]="destination.get('downloadDate')" name="endDate" (click)="pickerEndDate.open()"
                    (dateChange)="updateDestinationDates()" readonly>
                <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerEndDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-12 mx-3">
            <button mat-raised-button class="primary-secondary button-width-auto" color="primary" type="button"
                (click)="addDestination()">
                Añadir destino
            </button>
        </div>

        <p class="col-12 my-3 text-bold">
            Tipo de viaje
        </p>

        <mat-form-field appearance="outline" class="col-md-6 mb-3">
            <mat-label>Tipo de viaje<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="tripType">
                <mat-option *ngFor="let type of tripTypesEnabled" [value]="type.name">
                    {{ type.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('tripType').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>


        <p class="col-12 my-3 text-bold">
            Etiquetas
        </p>

        <div class="col-md-6 col-xl-4" *ngFor="let tag of form.get('labels')?.value; let i = index">
            <div class="tag-card">
                <p class="text-bold">{{tag}}</p>
                <i class="fa-solid fa-xmark" (click)="clearTag(i)"></i>
            </div>
        </div>
        <mat-form-field class="col-md-6 col-xl-4" appearance="outline">
            <mat-label>Seleccionar etiqueta</mat-label>
            <mat-select [formControl]="tagControl" (selectionChange)="selectTag($event)">
                <mat-option *ngFor="let tag of tags" [value]="tag.name"
                    [disabled]="form.get('labels')?.value?.includes(tag.name)">
                    {{tag.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <p class="col-12 my-3 text-bold">
            Vehículo
        </p>
        <app-type-vehicle [inputFormControl]="vehicleTypeControl" [options]="optionsTypeVehicle" class="col-md-6"
            [validate]="formValidate"></app-type-vehicle>
        <div class="col-md-6">
            <mat-label class="text-bold">¿El vehículo es refrigerado?</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="refrigerated">
                <mat-radio-button [value]="'Refrigerado'">Sí</mat-radio-button>
                <mat-radio-button [value]="">No</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Cantidad de vehículos<span class="asterisk">*</span></mat-label>
            <input matInput type="text" onlyNumbersInt formControlName="quantity">
            <mat-error *ngIf="form.get('quantity').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('quantity').errors?.min">
                {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'cantidad de vehículos', 1) }}
            </mat-error>
        </mat-form-field>

        <p class="col-12 my-3 text-bold">
            Dimensiones de la carga
        </p>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Peso</mat-label>
            <input matInput type="text" onlyNumbersInt formControlName="weight">
            <mat-error *ngIf="form.get('weight').errors?.min">
                {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'peso', 1) }}
            </mat-error>
        </mat-form-field>

        <div class="col-md-6">
            <mat-label class="text-bold">Unidad de medida</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="unit">
                <mat-radio-button [value]="'1'">Kilos</mat-radio-button>
                <mat-radio-button [value]="'2'">Galones</mat-radio-button>
            </mat-radio-group>
        </div>

        <p class="col-12 my-3 text-bold">
            Cubicaje
        </p>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Volumen en m³</mat-label>
            <input matInput type="text" onlyNumbersDouble formControlName="measurements">
            <mat-error *ngIf="form.get('measurements').errors?.min">
                {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'volumen', 0.0001) }}
            </mat-error>
        </mat-form-field>

        <u class="col-md-6 align-self-center color-primary cursor-pointer" (click)="openDialogCalculatorMeasurements()">
            Calcular cubicaje
        </u>


        <p class="col-12 my-3 text-bold">
            Flete
        </p>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Valor del flete<span class="asterisk">*</span></mat-label>
            <input matInput type="text" currencyMask onlyNumbersInt min="1" placeholder="$000.000"
                formControlName="cost">
            <mat-error class="mb-3" *ngIf="form.get('cost').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('cost').errors?.min">
                {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', 1) }}
            </mat-error>
        </mat-form-field>

        <div class="col-md-12 mt-3">
            <p class="text-bold mb-3">Observaciones internas</p>
            <mat-form-field appearance="outline">
                <mat-label>Notas adicionales</mat-label>
                <input matInput type="text" formControlName="observation">
            </mat-form-field>
        </div>

        <div class="col-md-12 mt-3">
            <p class="text-bold mb-3">Requisitos adicionales para el conductor</p>
            <mat-form-field appearance="outline">
                <mat-label>Notas adicionales</mat-label>
                <input matInput type="text" formControlName="observationDriver">
            </mat-form-field>
        </div>
        <!-- <div class="col-md-12">
                            <b>Publicación</b>
                        </div>
                        <div class="col-md-9">
                            <mat-radio-group aria-label="Select an option" formControlName="refrigerated">
                                <mat-radio-button [value]="true">Privada: Visible solo para tus conductores
                                    asociados</mat-radio-button>
                                <mat-radio-button [value]="false">Pública: Visible para todos</mat-radio-button>
                            </mat-radio-group>
                        </div> -->
        <div class="col-md-3 offset-md-9">
            <button mat-raised-button color="accent" class="w-100" type="submit"
                [disabled]="form.invalid">Crear</button>
        </div>
    </div>
</form>
