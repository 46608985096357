import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DiscountsAndBonusesCargoService } from './discounts-and-bonuses-cargo.service';
import { Cargo, SicetacResponse } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import * as _ from 'lodash';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { DiscountsAndBonuses, DiscountsAndBonusesModel } from 'src/app/core/interfaces/discountsAndBonuses';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { CreateDiscountsAndBonusesComponent } from '../create-discounts-and-bonuses/create-discounts-and-bonuses.component';
import { CreateDiscountsAndBonuses } from 'src/app/core/interfaces/createDiscountAndBonuses';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ModalLicensePlateComponent } from '../modal-license-plate/modal-license-plate.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-discounts-and-bonuses-cargo',
  templateUrl: './discounts-and-bonuses-cargo.component.html',
  styleUrls: ['./discounts-and-bonuses-cargo.component.scss'],
  providers: [AuthService, Global, DiscountsAndBonusesCargoService, DiscountsAndBonusesModel, CargoItemService]
})

export class DiscountsAndBonusesCargoComponent extends ReactiveForm implements OnInit {

  @Input() private cargo: Cargo;
  typeModifications: any;
  showView = true;
  utilityCargoResul: number;
  permission = Permission;
  modifications: FormGroup;
  typeSub: Subscription;
  constructor(
    public matDialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private service: DiscountsAndBonusesCargoService,
    public formBuilder: FormBuilder,
    public utils: Utils,
    private model: DiscountsAndBonusesModel,
    @Inject(MAT_DIALOG_DATA) public dialogParams: { cargo: Cargo },
    public dialogRef: MatDialogRef<DiscountsAndBonusesCargoComponent>,
    public dialog: MatDialog,
    public cargoItemService: CargoItemService,
    public manualCreationCargoService: ManualCreationCargoService,
    private managerComany: CompanyManager,
    private permissionRole: PermissionRole
  ) {
    super(
      formBuilder,
      model.model
    );
    this.setValidatorsForm(
      this.model.modelValidators,
      this.form
    );
    this.lisenerType();
    this.getUtilityByCompany();
    this.typeModifications = this.refreshListTypeModification(0);
    this.modifications = new FormGroup({
      name: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    // let validators = [];
    // validators = validators.concat(this.model.modelValidators.amount);
    // validators.push(
    //   Validators.max(this.cargoData.shippingCost.totalCost)
    // );
    // this.form.controls.amount.setValidators(validators);
    this.updateForm({
      userId: this.authService.getUserSession().information.document,
      userName: this.authService.getUserSession().information.name,
      cargoId: this.cargoData.id
    });

    // Lock flete
    if (!this.canModifyAmount(true)) {
      this.form.controls.amount.disable();
    }
    //Lock tarifa
    if (!this.canModifyAmount(false)) {
      this.form.controls.amountRate.disable();
    }
  }

  lisenerType() {
    this.typeSub = this.form.get('type').valueChanges.subscribe(value => {
      if (value !== 0) {
        //this.form.get('amountRate').setValue('');
        // this.form.controls.amountRate.claerValidators();
        /* this.form.get('amountRate').setValidators(null);
        this.form.get('amountRate').reset(); */
      } else {
        this.form.get('amountRate').setValidators(this.model.modelValidators.amountRate);
      }
    });
  }

  onSubmit() {
    this.modifications.markAllAsTouched();
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('type'), 'tipo de acción')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('amount'), 'valor para el flete')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('typeModification.name'), 'tipo de modificación')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('amountRate'), 'valor para la tarifa')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
        return;
      }
    } else if (!this.validUtility) {
      this.snackBarService.openSnackBar(this.utilityCargoResul
        ? Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, this.utilityCargoResul)
        : FormMessages.MINIMUN_UTILITY_NOT_REACHED_DEFAULT, undefined, 'alert');
      return;
    } else if (this.negativeCost) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MIN_VALUE_NOT_REACHED, 'valor para el flete', 1), undefined, 'alert');
      return;
    } else if (this.totalRate < 0 || (this.totalRate == 0 && !this.hasPermissionTotalRateZero)) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MIN_VALUE_NOT_REACHED, 'valor para la tarifa', this.hasPermissionTotalRateZero ? 0 : 1), undefined, 'alert');
      return;
    } else if (this.form.get('amount').value <= 0 && this.form.get('amountRate').value <= 0) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'tarifa o flete'), undefined, 'alert');
    }
    else {
      this.showView = false;
      this.confirmAddDiscountsAndBonuses();
    }
  }

  confirmAddDiscountsAndBonuses() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas agregar ${this.form.get('type').value ? 'el descuento' : ' la bonificación'} a esta carga?`,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.showView = true;
      if (result && result.state) {
        this.addDiscountsAndBonuses();
      }
    });
  }

  addDiscountsAndBonuses() {
    this.spinner.show();
    this.service.shippingCostModify(this.form.value as DiscountsAndBonuses)
      .toPromise()
      .then((success: SicetacResponse) => {
        this.snackBarService.openSnackBar((this.form.get('type').value ? 'Descuento' : 'Bonificación') + ' aplicado correctamente');
        this.dialogRef.close({
          state: true,
          dataCargo: success
        });
      })
      .catch((error) => {
        console.error(error);
        this.snackBarService.openSnackBar('Ocurrió un error al relizar este proceso', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  onCancel() {
    this.dialogRef.close({
    });
  }
  get cargoData() {
    if (this.cargo && this.cargo.id) {
      return this.cargo;
    }
    if (this.dialogParams && this.dialogParams.cargo && this.dialogParams.cargo.id) {
      return this.dialogParams.cargo;
    }
  }

  get utilityCargo() {
    return this.cargoItemService.getUtilityCargo(
      this.cargoItemService.getNewValueRate(this.cargoData, this.form.get('amountRate').value, this.form.get('type').value),
      this.cargoItemService.getNewValueTotalCost(this.cargoData, this.form.get('amount').value, this.form.get('type').value)
    );
  }

  async getUtilityByCompany() {
    let result = await this.managerComany.getUtilityByCompanyId(this.cargoData.idCompany)
    this.utilityCargoResul = result;
  }

  get validUtility() {
    if (this.utilityCargo <= this.utilityCargoResul) {
      return this.permissionRole.hasPermission(this.permission.payments.module, this.permission.payments.editRateCargoUnrestricted)
    }
    return true;
  }

  public createTypeModification() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.matDialog.open(CreateDiscountsAndBonusesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  refreshListTypeModification(event) {
    this.spinner.show();
    this.service.getListTypeModification(event.value ? event.value : 0)
      .toPromise()
      .then((success) => {
        this.form.get('typeModification.name').setValue('');
        this.form.get('typeModification.id').setValue('');
        this.form.get('typeModification.type').setValue('');
        this.typeModifications = success;
      })
      .catch((error) => {
        console.error(error);
        this.snackBarService.openSnackBar('Ocurrió un error al relizar este proceso', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  refreshModifications($event) {
    this.form.get('typeModification.name').setValue($event.value.name);
    this.form.get('typeModification.id').setValue($event.value.id);
    this.form.get('typeModification.type').setValue($event.value.type);
  }

  get totalRate(): number {
    return this.cargoItemService.getNewValueRate(this.cargoData, this.form.get('amountRate').value, this.form.get('type').value)
  }

  get negativeCost() {
    return !!(this.cargoItemService.getNewValueTotalCost(this.cargoData, this.form.get('amount').value, this.form.get('type').value) < 0)
  }

  canModifyAmount(isAmount: boolean) {
    const isAllowed = this.dialogParams.cargo && this.dialogParams.cargo.approval && this.dialogParams.cargo.shippingCost;
    const isNotPaidOrCashed = isAmount ? !this.dialogParams.cargo.shippingCost.paid : !this.dialogParams.cargo.shippingCost.cashed;
    const notFullAccessRequired = this.dialogParams.cargo.approval !== 'Approved' && this.dialogParams.cargo.approval !== 'Rejected';
    const editAmountPermission = this.dialogParams.cargo.approval === 'Rejected' &&
      this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.bonusAndDiscountsInCargoRejected)
    return (isAllowed && isNotPaidOrCashed && (notFullAccessRequired || editAmountPermission)) || this.hasPermissionFullAccess;
  }
  get hasPermissionFullAccess(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.changeRateFullAccess
    );
  }

  get hasPermissionTotalRateZero(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.payments.module,
      this.permission.payments.editRateCargoUnrestricted
    );
  }

  get canCreateBonusOrPenaltyToCargo(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createBonusOrPenaltyToCargo
    );
  }

  ngOnDestroy() {
    if (this.typeSub) this.typeSub.unsubscribe();
  }
}
