<mat-form-field [appearance]="options?.appearance ? options?.appearance : 'standard'">
  <mat-label>{{ options?.title ? options.title : 'Compañia' }}<span class="asterisk"
      *ngIf="utils.isRequiredField(companyCtrl)">*</span></mat-label>
  <input (keyup)="changeValue()" matInput [matAutocomplete]="autocompleteCompanies" [formControl]="companyCtrl">
  <mat-autocomplete #autocompleteCompanies="matAutocomplete" [displayWith]="displayCompanyName"
    (optionSelected)="onSelectCompany($event)">
    <mat-option *ngFor="let company of listCompaniesAsync | async" [value]="company">
      <span>{{company.name}}</span>
    </mat-option>
  </mat-autocomplete>
  <i *ngIf="options?.showXMark && companyCtrl?.value?.companyId" matSuffix class="fas fa-times-circle"
    matTooltip="Borrar" (click)="cleanField()"></i>
  <mat-hint *ngIf="options?.hint" class="color-secondary-dark text-bold">
    {{ options?.hint }}
  </mat-hint>
  <mat-hint *ngIf="options?.validateBySiigo && companyCtrl.value?.companyId && !companyCtrl.value?.siigoId"
    class="color-secondary-dark text-bold">
    {{ options?.validateBySiigo }}
  </mat-hint>
  <mat-error *ngIf="companyCtrl.errors?.required">
    {{ utils.giveMessageError('MANDATORY_FIELD') }}
  </mat-error>
</mat-form-field>