<div class="last_point_location">
    <div class="last_point_location__title color-secondary-dark text-strong" *ngIf="title">
        {{title}}
    </div>
    <div class="last_point_location__container">
        <label class="last_point_location__title color-secondary-dark text-strong">
            Fecha:
        </label>
        <span class="last_point_location__value">
            {{lastPointLocation?.fingerprint?.date | date:'medium'}}
        </span>
    </div>
    <div class="last_point_location__container">
        <label class="last_point_location__title color-secondary-dark text-strong">
            Dirección:
        </label>
        <span class="last_point_location__value">
            {{lastPointLocation?.address}}
        </span>
    </div>
    <div class="last_point_location__container">
        <label class="last_point_location__title color-secondary-dark text-strong">
            Ciudad:
        </label>
        <span class="last_point_location__value">
            {{lastPointLocation?.city}}
        </span>
    </div>
    <div class="last_point_location__container">
        <label class="last_point_location__title color-secondary-dark text-strong">
            Último registro:
        </label>
        <span class="last_point_location__value">
            {{timeLastPoint}}
        </span>
    </div>
    <div class="last_point_location__container" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.editAnomalies">
        <button class="btn button-width-auto" type="button" (click)="updateLastPoint()">
            <i class="fas fa-edit font-primary-dark"></i>
            <span class="font-primary-dark">
                Editar
            </span>
        </button>
    </div>
</div>