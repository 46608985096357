import { Directive, ViewContainerRef } from '@angular/core';


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appDynamicHost]',
})
export class DynamicHostDirective {


  constructor(public viewContainerRef: ViewContainerRef) { }

}
