import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from 'src/environments/environment';
import { Notification } from 'src/app/core/interfaces/notification';

@Injectable({
  providedIn: 'root'
})
export class PreviewNotificationService {

  constructor(
  ) { }
}
