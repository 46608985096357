<div class="row-freight d-flex">
    <div class="w-100">
        <div class="row">
            <div class="col-md-3 col-sm-12">
                <b>Placa</b><br>
                {{ trailer.id }}
            </div>
            <div class="col-md-3 col-sm-12">
                <b>Modelo</b><br>
                {{ trailer.model }}
            </div>
            <div class="col-md-3 col-sm-12" *ngIf="trailer?.configuration?.description">
                <b>Configuración</b><br>
                {{ trailer?.configuration?.description }}
            </div>
            <div class="col-md-3 col-sm-12">
                <b>Carrocería</b><br>
                {{ trailer?.bodywork?.description }}
            </div>
            <div class="col-md-3 col-sm-12">
                <b>Marca</b><br>
                {{ trailer?.brand?.description }}
            </div>
            <div class="col-md-3 col-sm-12">
                <b>Propietario</b><br>
                <span class="link" (click)="goToOwner()">{{ trailer?.owner?.name }}</span>
            </div>
            <div class="col-md-3 col-sm-12" *ngIf="trailer.hasAdministrator">
                <b>Administrador</b><br>
                <span class="link" (click)="goToAdmin()">{{ trailer?.administrator?.name }}</span>
            </div>
            <div class="col-md-3 col-sm-12">
                <b>Ejes</b><br>
                {{ trailer.axles }}
            </div>
        </div>
    </div>
    <figure class="icon-arrow-container" (click)="openDetail()">
        <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
    </figure>
</div>