<div class="row-point-item">
  <div class="row container-date">
    <!-- Document -->
    <div class="col-md-6">
      <span class="title-purple" title="{{getDocumentType + user.information.document}}">
        {{getDocumentType}} {{user.information.document}}
      </span>
    </div>
    <!-- Role -->
    <div class="col-md-6" *ngIf="user.role">
      <span class="title-purple" title="Rol: {{user.role | roleTranslate}}">
        Rol: {{user.role | roleTranslate}}
      </span>
    </div>
  </div>

  <div class="row container-data">
    <!-- Name -->
    <div class="col-md-3">
      <div class="label">
        Nombre
      </div>
      <div class="text-bold label-field">
        <span title="{{user.information.name | capitalizeText}}">
          {{user.information.name | capitalizeText}}
        </span>
      </div>
    </div>
    <!-- # Phone -->
    <div class="col-md-3">
      <div class="label">
        Celular
      </div>
      <div class="text-bold label-field">
        <span title="{{user.phone | phoneNumber}}">
          {{user.phone | phoneNumber}}
        </span>
      </div>
    </div>
    <!-- Role -->
    <div class="col-md-4">
      <div class="label">
        Correo
      </div>
      <div class="text-bold label-field">
        <span title="{{user.email}}">
          {{user.email}}
        </span>
      </div>
    </div>
    <!-- Button detail -->
    <figure *ngIf="showDriverDetail" class="icon-arrow-container" (click)="selectUser()">
      <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
    </figure>
  </div>
</div>