<div class="main-header">
  <!-- <div (click)="sharedFb()">
    fb
  </div> -->
  <div class="header-logotype">
    <figure (click)="goHome()">
      <img [src]="primaryLogo" *ngIf="primaryLogo" alt="">
      <img [src]="secondaryLogo" *ngIf="primaryLogo && secondaryLogo" alt="" class="secondary-logo">
      <img *ngIf="!environment.production" class="test-env" src="/assets/img/wip.png" alt="test environment">
    </figure>
  </div>
  <div class="header-title row">
    <div [ngClass]="{'col-12':!(primaryLogo && secondaryLogo),'col-md-8':primaryLogo && secondaryLogo}">
      <h5 *ngIf="titleService.getTitle() && authService.sessionActive()" class="d-none d-md-block">
        {{titleService.getTitle()}}</h5>
    </div>
  </div>
  <div class="header-nav">
    <app-nav [orientation]="'horizontal'" *ngIf="!authService.sessionActive()"></app-nav>
    <!-- Session active -->
    <div>
      <ul *ngIf="authService.sessionActive()" class="container-menu-profile justify-content-end font-weight-bold">
        <li class="profile-data">
          <a>
            Hola , {{userName}}
          </a>

          <a class="link-sign-out" (click)="openModalConfirmLogOut(modalConfirmLogOut)">Cerrar sesión</a>
        </li>
        <li class="nav-notifications">
          <a>
            <button mat-button class="button" mat-button [matMenuTriggerFor]="menu">
              <i class="far fa-bell"></i>
            </button>
            <mat-menu class="container-notifications" #menu="matMenu" backdropClass="menu-notifications">
              <app-notifications #appNotification></app-notifications>
            </mat-menu>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #modalConfirmLogOut let-modal>
  <div class="modal-body wrapper-modal">
    <p class="title">
      ¿Estás seguro que deseas <br> cerrar esta sesión?
    </p>
    <mat-card-actions>
      <button mat-raised-button class="primary-secondary" color="primary"
        (click)="closeModalConfirmLogOut()">No</button>
      <button mat-raised-button color="primary" (click)="logOut()">Si</button>
    </mat-card-actions>
  </div>
</ng-template>