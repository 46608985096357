<div class="container">
    <div class="col-md-12 mb-md-0 mb-12 container-regime-type">
        <div class="regime-type" (click)="selectRegimeType('OTM')" *ngIf="tripSelectedName === 'Importación'"
            [ngClass]="{'option-step-selected': regimenTripeSelect === 'OTM'}">
            <div class="regime">
                OTM
            </div>
        </div>
        <div class="regime-type" (click)="selectRegimeType('DUTA')"
            *ngIf="tripSelectedName === 'Importación' || tripSelectedName === 'Exportación'"
            [ngClass]="{'option-step-selected': regimenTripeSelect === 'DUTA'}">
            <div class="regime">
                DUTA
            </div>
        </div>
        <div class="regime-type" (click)="selectRegimeType('DTAI')" *ngIf="tripSelectedName === 'Exportación'"
            [ngClass]="{'option-step-selected': regimenTripeSelect === 'DTAI'}">
            <div class="regime">
                DTAI
            </div>
        </div>
        <div class="regime-type" (click)="selectRegimeType('Nacionalizado')"
            *ngIf="tripSelectedName === 'Importación' || tripSelectedName === 'Exportación'"
            [ngClass]="{'option-step-selected': regimenTripeSelect === 'Nacionalizado'}">
            <div class="regime">
                Nacionalizado
            </div>
        </div>
    </div>
</div>
