import firebase from "firebase/app";
import "firebase/storage";
import { ConfigFirebase } from "../interfaces/configFirebase";
import { environment } from "src/environments/environment";

export class DynamicFirebase {
  private static instances: { [key: string]: firebase.app.App } = {};
  private static storageInstances: { [key: string]: firebase.storage.Storage } = {};
  public static set config(config: ConfigFirebase) {
    const appName = `app-${config.projectId}`;
    let dynamicApp: firebase.app.App;
    if (firebase.apps.some(app => app.name === appName)) dynamicApp = firebase.app(appName);
    else {
      dynamicApp = firebase.initializeApp(config, appName);
      this.instances[`app-${config.projectId}`] = dynamicApp;
      this.storageInstances[`app-${config.projectId}`] = dynamicApp.storage();
    }
  }

  public static async configFirebaseByCompanyId(companyId: string): Promise<boolean> {
    try {
      const config: ConfigFirebase = environment.listFirebaseConfig[companyId];
      if (config) {
        DynamicFirebase.config = config;
        return true;
      } else {
        throw new Error('Configuration not found for the given companyId');
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public static getStorageByCompanyId(companyId: string): firebase.storage.Storage {
    const config = environment.listFirebaseConfig[companyId];
    if (!config) throw new Error('Configuration not found for the given companyId');
    const appName = `app-${config.projectId}`;
    if (!this.instances[appName]) throw new Error(`Firebase app for companyId ${companyId} is not initialized`);
    return this.storageInstances[appName];
  }

  public static async deleteAllApps(): Promise<void> {
    let instanceNames = Object.keys(this.instances);
    if (instanceNames.length && firebase.apps.length) {
      const appsToDelete = firebase.apps.filter(app => instanceNames.includes(app.name));
      const deletePromises = appsToDelete.map(app => app.delete());
      await Promise.all(deletePromises);
      this.instances = {};
      this.storageInstances = {};
    }
  }
}
