import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModuleRole } from 'src/app/core/interfaces/moduleRole';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ModulesService } from 'src/app/modules/administration/module/modules.service'
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';


@Component({
  selector: 'app-module-form',
  templateUrl: './module-form.component.html',
  styleUrls: ['./module-form.component.scss']
})
export class ModuleFormComponent implements OnInit {


  moduleForm = new FormGroup({
    name: new FormControl('', Validators.required),
    id: new FormControl('', Validators.required),
  });
  isCreate: boolean;
  detailModule: ModuleRole;

  constructor(
    private router: Router,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private modulesService: ModulesService,
    private route: ActivatedRoute,
    public utils: Utils,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: ParamMap) => {
      if (params && params['id']) {
        this.isCreate = false;
        this.getModule(params['id'])

      } else {
        this.isCreate = true;
      }
    });
  }
  onSubmit() {
    if (this.isCreate) {
      if (!this.utils.errorMessagesCustomized(this.moduleForm.get('name'), 'nombre')) {
        let moduleRole: ModuleRole = {
          name: this.moduleForm.controls.name.value,
        }
        this.createModule(moduleRole);
      }
    }
  }

  createModule(moduleRole) {
    this.spinner.show();
    this.modulesService.createModule(moduleRole).toPromise()
      .then((success: ModuleRole) => {
        if (success) {
          this.router.navigate(['administration/module/detail', success.id]);
          this.snackBarService.openSnackBar('Modulo creado correctamente');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error creando el modulo', undefined, 'error');
        }
      })
      .catch((error) => {
        this.snackBarService.openSnackBar('Ocurrió un error creando el modulo', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });

  }

  getModule(id) {
    this.spinner.show();
    this.modulesService.getModule(id).toPromise()
      .then((success: ModuleRole) => {
        if (success && success) {
          this.moduleForm.patchValue(success);
          this.detailModule = success;
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

}


