export class ServiceType {

    private name: string;
    private cost: string;

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getCost(): string {
        return this.cost;
    }

    public setCost(cost: string): void {
        this.cost = cost;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this['set' + key.charAt(0).toUpperCase() + key.slice(1)]) {
                    this['set' + key.charAt(0).toUpperCase() + key.slice(1)](input[key]);
                }
            }
        }

        return this;
    }

}
