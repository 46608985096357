import { BasicPerson } from "../interfaces/basicPerson";
import { State } from "../interfaces/state";
import { UserTerms } from "../interfaces/userTerms";

export class UserClientModel {

    private _email: string = "";
    private _information: BasicPerson = {
        documentTypeId: '',
        documentTypeName: '',
        document: '',
        name: ''
    };
    private _clientCompanyId: string = "";
    private _phone: string = "";
    private _role: string = "";
    private _roleId: string = "";
    private _state: State = {
        active: false,
        description: ''
    };
    private _creationDate: string = "";
    private _lastUserTerm: UserTerms = {
        date: '',
        version: '',
        termId: '',
        state: false
    };
    private _uId: string = '';

    public get email(): string {
        return this._email;
    }

    public set email(value: string) {
        this._email = value;
    }

    public get information(): BasicPerson {
        return this._information;
    }

    public set information(value: BasicPerson) {
        this._information = value;
    }

    public get clientCompanyId(): string {
        return this._clientCompanyId;
    }

    public set clientCompanyId(value: string) {
        this._clientCompanyId = value;
    }

    public get phone(): string {
        return this._phone;
    }

    public set phone(value: string) {
        this._phone = value;
    }

    public get role(): string {
        return this._role;
    }

    public set role(value: string) {
        this._role = value;
    }

    public get roleId(): string {
        return this._roleId;
    }

    public set roleId(value: string) {
        this._roleId = value;
    }

    public get state(): State {
        return this._state;
    }

    public set state(value: State) {
        this._state = value;
    }

    public get creationDate(): string {
        return this._creationDate;
    }

    public set creationDate(value: string) {
        this._creationDate = value;
    }

    public get lastUserTerm(): UserTerms {
        return this._lastUserTerm;
    }

    public set lastUserTerm(value: UserTerms) {
        this._lastUserTerm = value;
    }

    public get uId(): string {
        return this._uId;
    }

    public set uId(value: string) {
        this._uId = value;
    }

    public deserialize(input: any) {
        Object.assign(this, input);

        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (this[key]) {
                    this[key] = input[key];
                }
            }
        }

        return this;
    }

}
