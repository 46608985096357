<form [formGroup]="form" (ngSubmit)="onSubmit()" class="external__manifest">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="col-md-12">
    <div class="title-component text-center mx-4">
      Cargar Manifiesto Externo
    </div>
  </div>
  <div class="col-md-12">
    <div class="row file-container">
      <div class="col-md-12">
        <app-file
          [options]="{title: 'Manifiesto Externo', path: 'Cargo/' + cargo.id + '/ExternalManifest', storage: true, format: '.pdf', storageName: 'externalManifest' }"
          [inputFormControl]="form.get('externalManifest')"></app-file>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>Número del manifiesto externo<span class="asterisk">*</span></mat-label>
          <input type="text" matInput formControlName="manifest">
          <mat-error *ngIf="form.get('manifest').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <br>
    <!-- Acciones -->
    <div class="row">
      <div class="col-md-12 text-center mb-2">
        <mat-card-actions class="actions-card">
          <button mat-raised-button color="primary" class="button-width-auto" type="submit">
            Guardar
          </button>
        </mat-card-actions>
      </div>
    </div>
  </div>
</form>