import { Validators } from "@angular/forms";
import { Fingerprint } from "./fingerprint";
import { LocationAddress } from "./locationAddress";
import { Temperature } from "./Temperature";
import { DurationTime } from "./durationTime";

export interface LastPointLocation {
  id?: number;
  location: LocationAddress;
  address: string;
  city: string;
  fingerprint: Fingerprint;
  anomaly?: string;
  news?: string;
  name?: string;
  observation?: string;
  temperature?: Temperature;
  anomalyDuration?: DurationTime;
}

export interface Traceability {
  id?: string;
  cargoId?: string;
  tracepoints?: LastPointLocation[];
}

export class LastPointLocationModel {

  constructor() {

  }

  public modelLastPointLocation: LastPointLocation = {
    location: {
      lat: 0,
      lng: 0
    },
    address: '',
    city: '',
    fingerprint: {
      userId: '',
      userName: '',
      date: ''
    },
    name: '',
    observation: '',
    temperature: {
      value: 0,
      unit: 'C'
    }
  }

  public validatorsModelLastPointLocation = {
    location: {
      lat: [
        Validators.required
      ],
      lng: [
        Validators.required
      ]
    },
    address: [
      Validators.required
    ],
    city: [
      Validators.required
    ],
    fingerprint: {
      userId: [
        Validators.required
      ],
      userName: [
        Validators.required
      ],
      date: [
        Validators.required
      ]
    },
    name: [
      Validators.required
    ]
  }
}
