import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from '../../manual-creation-cargo.service';
import { Router } from '@angular/router';
import { ModelCargo } from '../../resources/modelCargo';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';

@Component({
  selector: 'app-container-measure',
  templateUrl: './containerMeasure.component.html',
  styleUrls: ['./containerMeasure.component.scss'],
  providers: [AuthService, Global, ModelCargo]
})
export class ContainerMeasureComponent implements OnInit {

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    public snackBarService: SnackBarService,
    public modelCargo: ModelCargo
  ) {

  }

  ngOnInit() {

  }

  selectTypeSize(value: any) {
    this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.containerMeasure.controls.size.setValue(
      value.size
    );
  }

  nextStep() {
    // Tipo de servicio
    if (this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoType.controls.containerMeasure.controls.size.value) {
      this.manualCreationCargoService.getComponentActive().setId(1);
      this.manualCreationCargoService.getComponentActive().getStep().setId(5);
      this.router.navigate([this.manualCreationCargoService.steps.typeService.url]);
    } else {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'la medida del contenedor'), undefined, 'alert');
    }

  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(1);
    this.manualCreationCargoService.getComponentActive().getStep().setId(3);
    this.router.navigate([this.manualCreationCargoService.steps.typeCargo.url]);
  }

}
