import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[appDocumentType]',
})
export class DocumentTypeDirective {

  @Input() documentTypeId: string;

  constructor(
    public ngControl: NgControl
  ) {
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value);
  }

  onInputChange(event: any) {
    if (event.length > 9 && this.documentTypeId == '3') {
      this.ngControl.control.setValue(event.toString().substr(0, 9));
    }
    if (event.length > 10 && this.documentTypeId == '4') {
      this.ngControl.control.setValue(event.toString().substr(0, 10));
    }
  }
}
