<div *ngIf="isImage && !notFileLoaded && !errorFile" class="shadow-sm rounded text-center m-3">
  <img class="p-2" [src]="fileURLReference" *ngIf="!!fileURLReference" id="image">
</div>

<div *ngIf="!isImage && !notFileLoaded && !errorFile" class="shadow-sm rounded text-center m-3">
  <embed class="p-2" [src]="fileURLReference" type="application/pdf" *ngIf="!!fileURLReference">
</div>

<p class="alert alert-danger text-center" role="alert" *ngIf="errorFile">
  <mat-label class="font-weight-bold">
    <i class="fas fa-exclamation-triangle"></i>
    Ocurrió un error con el archivo, por favor cárguelo de nuevo
  </mat-label>
</p>

<p class="alert alert-warning text-center" role="alert" *ngIf="notFileLoaded">
  <mat-label class="font-weight-bold">
    <i class="fas fa-exclamation-triangle"></i>
    El archivo aun no ha sido cargado
  </mat-label>
</p>
