import { Validators } from "@angular/forms"
import { Patterns } from "../resources/patterns"
import { CreateDiscountsAndBonuses } from "./createDiscountAndBonuses";
import { Error } from './error';

export interface DiscountsAndBonuses {
  id?: string;
  type?: any;
  amount?: number;
  amountRate?: number;
  userId?: string;
  userName?: string;
  cargoId?: string;
  observation?: string;
  date?: string;
  typeModification?: CreateDiscountsAndBonuses;
  receiptId?: string;
  errorReceipt?: Error;
}

export class DiscountsAndBonusesModel {

  public model: DiscountsAndBonuses = {
    type: '',
    amount: 0,
    amountRate: 0,
    userId: '',
    userName: '',
    cargoId: '',
    observation: '',
    typeModification: {
      id: '',
      name: '',
      type: ''
    }
  };

  public modelValidators = {
    type: [
      Validators.required
    ],
    amount: [
      Validators.pattern(this.patterns.ONLY_NUMBERS.source)
    ],
    amountRate: [
      Validators.pattern(this.patterns.ONLY_NUMBERS.source)
    ],
    userId: [
      Validators.required
    ],
    userName: [
      Validators.required
    ],
    cargoId: [
      Validators.required
    ],
    observation: [
      Validators.minLength(5)
    ],
    typeModification: {
      name: [
        Validators.required
      ]
    }
  }

  constructor(
    private patterns: Patterns
  ) {

  }
}
