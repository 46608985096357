<div class="container">
    <div class="col-md-12 mb-md-0 mb-12 container-type-service">
        <!-- Carga expresa -->
        <div class="type-service" *ngIf="serviceSelectedName !== 'Contenedor'"
            (click)="selectTypeService('Carga expresa')"
            [ngClass]="{'option-step-selected': serviceTypeSelect === 'Carga expresa'}">
            <div class="service">
                <div class="container-icon">
                    <div class="service-icon">
                        <img src="/assets/svg/icons/icon-trolley-2.svg">
                    </div>
                </div>
                <div class="service-title">
                    Carga expresa
                </div>
            </div>
        </div>
        <!-- Carga consolidada -->
        <div class="type-service" *ngIf="serviceSelectedName !== 'Contenedor'"
            (click)="selectTypeService('Carga consolidada')"
            [ngClass]="{'option-step-selected': serviceTypeSelect === 'Carga consolidada'}">
            <div class="service">
                <div class="container-icon">
                    <div class="service-icon icon-truck">
                        <img src="/assets/svg/icons/icon-delivery-truck.svg">
                    </div>
                </div>
                <div class="service-title">
                    Carga consolidada
                </div>
            </div>
        </div>
        <!-- Contenedor vacío express -->
        <div class="type-service" *ngIf="serviceSelectedName === 'Contenedor'"
            (click)="selectTypeService('Contenedor vacío express')"
            [ngClass]="{'option-step-selected': serviceTypeSelect === 'Contenedor vacío express'}">
            <div class="service">
                <div class="service-title">
                    Contenedor vacío express
                </div>
            </div>
        </div>
        <!-- Contenedor vacío con carga de compensación -->
        <div class="type-service" *ngIf="serviceSelectedName === 'Contenedor'"
            (click)="selectTypeService('Contenedor vacío con carga de compensación')"
            [ngClass]="{'option-step-selected': serviceTypeSelect === 'Contenedor vacío con carga de compensación'}">
            <div class="service">
                <div class="service-title">
                    Contenedor vacío con carga de compensación
                </div>
            </div>
        </div>
        <!-- Contenedor lleno express -->
        <div class="type-service" *ngIf="serviceSelectedName === 'Contenedor'"
            (click)="selectTypeService('Contenedor lleno express')"
            [ngClass]="{'option-step-selected': serviceTypeSelect === 'Contenedor lleno express'}">
            <div class="service">
                <div class="service-title">
                    Contenedor lleno express
                </div>
            </div>
        </div>
        <!-- Contenedor lleno consolidado -->
        <div class="type-service" *ngIf="serviceSelectedName === 'Contenedor'"
            (click)="selectTypeService('Contenedor lleno consolidado')"
            [ngClass]="{'option-step-selected': serviceTypeSelect === 'Contenedor lleno consolidado'}">
            <div class="service">
                <div class="service-title">
                    Contenedor lleno consolidado
                </div>
            </div>
        </div>
    </div>
</div>