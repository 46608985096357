<aside id="mainListTicket" class="col-md-12 mb-md-0 px-0 px-md-2 mb-12 h-100-margin">
  <div class="container-list h-100-margin">
    <div class="container-list-header">
      <div class="row">
        <div class="col-md-10">
          <label class="title-component">
            <i class="fas fa-users"></i>
            Listado de Tickets
          </label>
        </div>
        <div class="col-md-2 header-list-options text-right">
          <button matTooltip="Crear Ticket" permission-display [module]="permission.helpDesk.module"
            [functionality]="permission.helpDesk.createTicket" mat-raised-button type="button" class="button-width-auto"
            (click)="goToCreate()">
            <i class="fas fa-plus-circle font-primary-dark"></i>
            <span class="font-primary-dark">Crear</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container-list-filter mb-3">
      <div class="row mx-md-2">
        <div class="col-md-3">
          <mat-form-field (keydown.enter)="handleKeyDown($event)">
            <mat-label>N° de Caso</mat-label>
            <input type="text" matInput [(ngModel)]="case">
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Tipo</mat-label>
            <mat-select [(ngModel)]="type">
              <mat-option *ngFor="let type of types" [value]="type.value">
                {{type.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field (keydown.enter)="handleKeyDown($event)">
            <mat-label>Estado</mat-label>
            <mat-select [(ngModel)]="state">
              <mat-option *ngFor="let state of states" [value]="state.value">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3 align-right px-0">
          <mat-card-actions>
            <button mat-raised-button class="primary-secondary" color="primary" (click)="cleanFilter()"
              title="Eliminar Filtros">
              <i class="far fa-trash-alt"></i>
            </button>
            <button mat-raised-button class="btn-filter" color="primary" type="button" (click)="applyFilter()"
              title="Aplicar Filtros">
              <i class="fas fa-search"></i>
            </button>
          </mat-card-actions>
        </div>
      </div>
    </div>
    <div class="container-list-body" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let ticket of listTickets; let i = index;">
        <app-item-ticket [ticket]="ticket"> </app-item-ticket>
      </div>
    </div>
  </div>

</aside>