import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Endpoints } from "src/app/core/resources/endpoints";
import { Global } from "src/app/core/resources/global";
import { environment } from "src/environments/environment";
import { Utils } from "src/app/core/resources/utils";
import { Company } from "src/app/core/interfaces/company";
import {
  ModelRoleComany,
  RoleComany,
} from "src/app/core/interfaces/roleCompany";
import { forkJoin, Observable } from "rxjs";
import { Role } from "src/app/core/interfaces/role";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  roleSelected: Role;

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    private globalResources: Global,
    public utils: Utils,
    private modelRoleComany: ModelRoleComany
  ) { }

  public getRolesByNit(nit: string) {
    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpointResources.rolesCompant}nit=${nit}`
    );
  }

  public getRol(id: string) {
    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpointResources.roleDetail}${id}`
    );
  }

  public getListCompaniesChildren(
    nit: string,
    pageKey: number,
    pageSize: number
  ) {
    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpointResources.getAllCompaniesChildrens}${nit}`
    );
  }

  public createCompany(
    companyData: Company,
  ) {
    let url = `${environment.urlServerTeclogi}${this.endpointResources.urlCreateCompany}`;

    return this.http.post(url, companyData);
  }

  public updateCompany(companyData: Company) {
    return this.http.put(
      `${environment.urlServerTeclogi}${this.endpointResources.urlUpdateCompany}`,
      companyData
    );
  }

  public createObjectCompany(data: Company, companyParent?: Company): Company {
    const company: Company = {
      name: data.name,
      companyId: data.companyId,
      ministry: data.ministry,
      validateFreight: data.validateFreight,
      exclusive: companyParent ? companyParent.exclusive : true,
      driverVisibility: companyParent ? true : false,
      webSite: data.webSite,
      emailSubject: "¡Su pedido va en camino!",
      emailBody: "¡Vamos en camino a entregar tu producto!",
      configuration: {
        reports: [
          {
            type: "addressDuration",
            units: "milliseconds",
            title: "Duración en cada dirección",
          },
          {
            type: "routeDuration",
            units: "milliseconds",
            title: "Duración total de cada carga",
          },
        ],
        serviceType: "express",
      },
    };

    return company;
  }

  public deleteCompany(nit: string) {
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.deleteCompany,
      [nit]
    );
  }

  public createRole(role: RoleComany) {
    const url = `${environment.urlServerTeclogi}${this.endpointResources.createRole}`;
    return this.http.post(url, role);
  }

  public updateRole(role: RoleComany) {
    const url = `${environment.urlServerTeclogi}${this.endpointResources.updateRole}`;
    return this.http.put(url, role);
  }

  public createMultipleRole(rolesCompanies: RoleComany[]): Observable<any[]> {
    const listPromises = [];
    const url = `${environment.urlServerTeclogi}${this.endpointResources.createRole}`;

    rolesCompanies.map((role: RoleComany) => {
      listPromises.push(this.http.post(url, role));
    });

    return forkJoin(listPromises);
  }

  public createListModules(modules) {
    let listModules = [];
    for (let key in modules) {
      if (typeof modules[key] === "object") {
        var objModule = { name: key, functionalities: [] };
        for (let functionality in modules[key]) {
          if (functionality !== "module") {
            objModule.functionalities.push({
              name: functionality,
              visible: false,
            });
          }
        }
        listModules.push(objModule);
      }
    }
    return listModules;
  }
}
