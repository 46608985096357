<div class="text-center">
    <b class="text-left">
        Espera, es muy pronto para subir evidencias. <br>
        Podrás subirlas dentro de:
    </b>
    <br>
    <br>
    <b class="time">{{timeLeft}}</b>
    <br>
    <br>
    <button mat-raised-button color="accent" (click)="close()">Aceptar</button>
</div>