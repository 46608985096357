import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from 'src/app/core/shared.module';
import { CreateGuidesFromExcelComponent } from './create-guides-from-excel/create-guides-from-excel.component';
import { GenerateGuideComponent } from './generate-guide/generate-guide.component';
import { GuideRoutingModule } from './guide-routing.module';
import { ListGenerateGuideComponent } from './list-generate-guide/list-generate-guide.component';
import { PrintGuideComponent } from './print-guide/print-guide.component';
import { UploadGuideComponent } from './upload-guide/upload-guide.component';
import { ChangeStateGuideComponent } from './change-state-guide/change-state-guide.component';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    GuideRoutingModule
  ],
  declarations: [
    UploadGuideComponent,
    GenerateGuideComponent,
    ListGenerateGuideComponent,
    CreateGuidesFromExcelComponent,
    PrintGuideComponent,
  ],
  entryComponents: [

  ],
  exports: [
    UploadGuideComponent,
    GenerateGuideComponent,
    ListGenerateGuideComponent,
    CreateGuidesFromExcelComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class GuideModule {

}
