import { Injectable } from '@angular/core';
import { LatLng, MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { of } from 'rxjs';
import { filter, catchError, tap, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { Utils } from '../resources/utils';
import { Cargo } from '../interfaces/cargo';
import { LocationAddress } from '../interfaces/locationAddress';
declare var google: any;

@Injectable()
export class GoogleService {
    private geocoder: any;

    constructor(
        public utils: Utils
    ) { }

    public getRouteDataCargo(cargo, travelMode?: string) {
        const promise = new Promise((resolve, reject) => {
            const locationsList = this.utils.getListGroupLocationsCargo(cargo);
            const directionsService = new google.maps.DirectionsService();
            let count = 1;
            let distancy = 0;
            let estimatedTime = 0;
            let responseDirections = [];

            for (const value of locationsList) {
                directionsService.route({
                    origin: value.origin,
                    destination: value.destination,
                    waypoints: value.waypoints,
                    travelMode: (travelMode || 'DRIVING')
                }, (response, status) => {
                    if (status === 'OK') {
                        if (response.routes.length) {
                            responseDirections.push(response);
                            if (response.routes[0].legs.length) {
                                distancy += response.routes[0].legs[0].distance.value;
                                estimatedTime += response.routes[0].legs[0].duration.value;
                                if (count === locationsList.length) {
                                    resolve({
                                        cargoDistancy: distancy,
                                        cargoEstimatedTime: estimatedTime,
                                        cargoRoute: responseDirections
                                    });
                                }
                                count++;
                            } else {
                                reject(false);
                            }
                        } else {
                            reject(false);
                        }
                    } else {
                        reject(false);
                    }
                });
            }
        });
        return promise;
    }

    public getRouteData(locationsList: Array<any>, travelMode?: string, alternatives?: boolean) {
        const promise = new Promise((resolve, reject) => {
            const directionsService = new google.maps.DirectionsService();
            const points = this.utils.getWaypointsObject(locationsList);

            directionsService.route({
                origin: points.origin,
                destination: points.destination,
                waypoints: points.waypoints,
                travelMode: (travelMode || 'DRIVING'),
                provideRouteAlternatives: alternatives
            }, (response, status) => {
                if (status === 'OK') {
                    if (response.routes.length) {
                        if (response.routes[0].legs.length) {
                            resolve({
                                cargoDistancy: response.routes[0].legs[0].distance.value,
                                cargoEstimatedTime: response.routes[0].legs[0].duration.value,
                                cargoRoute: response
                            });
                        }
                    }
                }
            });

        });
        return promise;
    }

    public calculateRouteCargo(cargo: Cargo, travelMode?: string) {
        const promise = new Promise((resolve, reject) => {

            const locationsList = this.utils.getListGroupLocationsCargo(cargo);
            const directionsService = new google.maps.DirectionsService();
            let count = 1;
            let responseDirections = [];

            for (const value of locationsList) {
                directionsService.route({
                    origin: value.origin,
                    destination: value.destination,
                    waypoints: value.waypoints,
                    travelMode: (travelMode || 'DRIVING')
                }, (response, status) => {
                    if (status === 'OK') {
                        if (response.routes.length) {
                            responseDirections.push(response);
                            if (count === locationsList.length) {
                                resolve(responseDirections);
                            }
                            count++;
                        }
                    }
                });
            }
        });
        return promise;
    }

    public createPath(locations: any) {
        const cargoPath = locations.map((location) => {
            let obj: { lat, lng, contentInfoWindow?} = {
                lat: location.latitude,
                lng: location.longitude
            };
            if (location.contentInfoWindow) {
                obj.contentInfoWindow = location.contentInfoWindow;
            }
            return obj;
        });
        return cargoPath;
    }

    public calculateMinRouteTime(origin: LocationAddress, destination: LocationAddress, callback: any, waypoints = [], travelMode: string = 'DRIVING') {
        const directionsService = new google.maps.DirectionsService();

        directionsService.route({
            origin: origin,
            destination: destination,
            waypoints: waypoints,
            travelMode: travelMode
        }, callback);
    }
}