import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, distinctUntilChanged, map } from 'rxjs/operators';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { ThirdParty } from 'src/app/core/interfaces/thirdParty';
import { Utils } from 'src/app/core/resources/utils';
import { ThirdPartyConsignmentService } from 'src/app/modules/cargo/manual-creation-cargo/components/third-party-consignment/third-party-consignment.service';

@Component({
  selector: 'app-autocomplete-thirdparty',
  templateUrl: './autocomplete-thirdparty.component.html',
  styleUrls: ['./autocomplete-thirdparty.component.scss']
})
export class AutocompleteThirdpartyComponent implements OnInit {

  @Input() options: OptionsAutocomplete;
  @Input() inputFormControl: FormControl;
  @Input() validate: string = '';
  loadingThirdParties: boolean = false;

  formControlThirdParty: FormControl = new FormControl();
  listThirdParties: ThirdParty[] = [];
  listThirdPartiesAsync: Observable<ThirdParty[]>;

  constructor(
    private thirdPartyConsignmentService: ThirdPartyConsignmentService,
    public utils: Utils
  ) { }

  async ngOnInit() {
    await this.getThirdParties();
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.formControlThirdParty.setValidators(Validators.required);
    this.listThirdPartiesAsync = this.formControlThirdParty.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map(thirdParty => thirdParty ? this.filterThirdParties(thirdParty) : this.listThirdParties ? this.listThirdParties.slice() : [])
    );
  }

  async getThirdParties() {
    this.loadingThirdParties = true;
    this.formControlThirdParty.disable();
    const currentThirdPartyList: ThirdParty[] = await this.thirdPartyConsignmentService.getThirdPartiesByParent().toPromise();
    this.loadingThirdParties = false;
    this.formControlThirdParty.enable();
    if (currentThirdPartyList && currentThirdPartyList.length) this.listThirdParties = currentThirdPartyList;
  }

  private filterThirdParties(value: any): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.listThirdParties.filter(thirdParty => thirdParty.name.toLowerCase().indexOf(filterValue) === 0);
    } else if (typeof value === 'object') {
      try {
        const filterValue = value.name.toLowerCase();
        return this.listThirdParties.filter(thirdParty => thirdParty.name.toLowerCase().indexOf(filterValue) === 0);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }

  displayThirdPartyName(thirdParty?: any): string | undefined {
    return thirdParty ? thirdParty.name : undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.options && changes.options.currentValue) {
        if (changes.options.currentValue.initialValue || changes.options.currentValue.initialValue === "") {
          changes.options.currentValue.initialValue
            ? this.setInitialThirdParty(changes.options.currentValue.initialValue)
            : this.formControlThirdParty.setValue("");
        }
      }

      if (changes.validate) {
        switch (this.validate) {
          case 'touched':
            this.formControlThirdParty.markAsTouched();
            break;
          case 'untouched':
            this.formControlThirdParty.markAsUntouched();
            break;
          case 'enable':
            this.formControlThirdParty.enable();
            break;
          case 'disable':
            this.formControlThirdParty.disable();
            break;
          case 'disable&untouched':
            this.formControlThirdParty.markAsUntouched();
            this.formControlThirdParty.disable();
            break;
          case 'setValidators':
            this.formControlThirdParty.setValidators(Validators.required);
            this.formControlThirdParty.updateValueAndValidity();
            break;
          case 'clearValidators':
            this.formControlThirdParty.clearValidators();
            this.formControlThirdParty.updateValueAndValidity();
            break;
          default:
            break;
        }
      }
    }
  }

  async setInitialThirdParty(document) {
    if (!this.listThirdParties.length) await this.getThirdParties();
    const thirdParty = this.listThirdParties.find((thirdParty) => thirdParty.document === document.toString());
    if (thirdParty) this.formControlThirdParty.setValue(thirdParty);
  }

  onSelectThirdParty($event: MatAutocompleteSelectedEvent) {
    if ($event && $event.option && $event.option.value && this.inputFormControl) this.inputFormControl.setValue($event.option.value);
  }

  changeValue() {
    if (this.inputFormControl) this.inputFormControl.setValue("");
  }
}
