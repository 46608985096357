import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dcarriers',
  templateUrl: './dcarriers.component.html',
  styleUrls: ['./dcarriers.component.scss'],
  providers: [AuthService]
})
export class DcarriersComponent implements OnInit {

  public data = [
    {
      "title": "Encuentra tu carga ideal",
      "information": "Enviamos buenas ofertas de carga para tu camión en cualquier momento y lugar.",
      "icon": "/assets/images/dcarriers/034-loupe.svg"
    },
    {
      "title": "Ahorra tiempo en tus recorridos",
      "information": "optimizamos tus rutas de acuerdo a citas de entrega y restricciones viales para mayor capacidad de reparto.",
      "icon": "/assets/images/dcarriers/012-location.svg"
    },
    {
      "title": "Aumenta tus ingresos",
      "information": "Aumentamos tus ingresos con un mayor número de viajes, reduciendo los tiempos muertos de inactividad.",
      "icon": "/assets/images/dcarriers/046-money.svg"
    },
    {
      "title": "Administra tus viajes",
      "information": "Brindamos una sola herramienta de trabajo para la gestión y negociación de tus viajes.",
      "icon": "/assets/images/dcarriers/035-app.svg"
    },
    {
      "title": "Comparte el estado de la ruta",
      "information": "Notificamos al cliente todas las novedades que tengas durante el tránsito.",
      "icon": "/assets/images/dcarriers/033-box-5.svg"
    }
  ]

  constructor(private _authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

}
