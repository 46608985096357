<aside class="wrapper-vehicle-form">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="col-md-12 ">
    <div class="row">
      <div class="col-md-12 wrapper-detail px-0">
        <!-- Title -->
        <div class="title-component">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-7">
                <i class="fas fa-truck-pickup"></i>
                Crear Conductor
              </div>
            </div>
          </div>
        </div>
        <div class="form-container">
          <!-- License Plate -->
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col-md-12">
                  <app-license-plate [inputFormControl]="licensePlate"></app-license-plate>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <span class="separator-horizontal"></span>
          <!-- Driver -->
          <app-person-vehicle [form]="form" [title]="'Conductor'"></app-person-vehicle>
          <span class="separator-horizontal"></span>
          <!-- Company -->
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col-md-6">
                  <app-select-company [options]="optionsCompany" [inputFormControl]="companySelected"
                    [validate]="validateCompany"></app-select-company>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <span class="separator-horizontal"></span>
          <!-- Button send -->
          <mat-card-actions class="mat-card-actions-vehcle text-center">
            <button mat-raised-button color="primary" type="button" class="button-width-auto" (click)="onSubmit()">
              Guardar
            </button>
          </mat-card-actions>
          <!-- Button back -->
          <div class="row md-form" *ngIf="!preconfigured">
            <label class="label-back-step col-md-2">
              <figure>
                <img src="/assets/svg/icons/icon-back.png" alt="">
              </figure>
              Atrás
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</aside>