<div class="container">
  <div class="col-md-12 mb-md-0 mb-12">

    <!-- Grid row -->
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-5">
        <label class="title-wellcome">
          ¿Quiénes somos?
        </label>
      </div>
      <div class="col-md-5">

        <div class="row">
          <h4 class="text-bold">
            Somos una compañía de tecnología al servicio de la logística
          </h4>
        </div>
        <br>
        <div class="row">
          Facilitamos la contratación de transporte de carga a través de una plataforma que une oferta y demanda de manera inteligente, simplificando las transacciones y los procesos.
        </div>
        <br>
        <div class="row">
          Transformamos la industria haciéndola más ágil y simple, con herramientas tecnológicas para digitalizar y automatizar procesos.
        </div>
        <br>
        <div class="row wrapper-comment">
          <h5>‘Contribuir al éxito de nuestros clientes es nuestro principal propósito.’</h5>
        </div>
        <div class="row">
          Tenemos más de 50 años de experiencia en el desarrollo de Tecnología para las distintas industrias
        </div>
        <div class="row wrapper-comment">
          <h5>‘Implementamos una logística más amigable con el medio ambiente’</h5>
        </div>
      </div>
    </div>
    <div class="row">

    </div>
  </div>
</div>
