import { Component, OnInit, Input } from '@angular/core';
import { ModuleRole } from 'src/app/core/interfaces/moduleRole';
import { Router } from '@angular/router';
import { ModulesService } from '../modules.service';


@Component({
  selector: 'app-module-item',
  templateUrl: './module-item.component.html',
  styleUrls: ['./module-item.component.scss']
})
export class ModuleItemComponent implements OnInit {

  @Input() module: ModuleRole;

  constructor(
    private router: Router,
    private modulesService: ModulesService
  ) { }

  ngOnInit() {
  }

  goToModule() {
    this.router.navigate(['administration/module/detail',this.module.id]);
  }

}
