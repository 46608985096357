<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="cargoData && showView"
  class="bonus-and-discounts-container position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="row ml-1 ml-sm-3 mb-3 d-flex align-items-center justify-content-start" *ngIf="canCreateBonusOrPenaltyToCargo">
    <button matTooltip="Crear Tipo De Modificacion" mat-raised-button type="button" class="button-width-auto"
      (click)="createTypeModification()">
      <i class="fas fa-plus-circle font-primary-dark"></i>
      <span class="font-primary-dark">Añadir <span class="d-none d-sm-inline">modificación</span></span>
    </button>
  </div>


  <!-- Current Value -->
  <div class="row">
    <div class="col-md-6">
      <div class="discounts-and-bonuses__header text-center">
        <h4 class="header__value text-strong">
          {{cargoItemService.getRealTotalCost(cargoData) - cargoData.shippingCost.totalPaid | currency:'COP':'code'}}
        </h4>
        <label class="header__title text-strong title-2">
          Valor actual del flete
        </label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="discounts-and-bonuses__header text-center">
        <h4 class="header__value text-strong">
          {{cargoData.shippingCost.rate | currency:'COP':'code'}}
        </h4>
        <label class="header__title text-strong title-2">
          Valor actual de la tarifa
        </label>
      </div>
    </div>
  </div>
  <span class="separator-horizontal"></span>
  <!-- Form -->
  <div class="discounts-and-bonuses__body">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Tipo de acción<span class="asterisk">*</span></mat-label>
          <mat-select (selectionChange)="refreshListTypeModification($event)" formControlName="type">
            <mat-option [value]="0">
              Bonificación
            </mat-option>
            <mat-option [value]="1">
              Descuento
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('type').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
        <form [formGroup]="modifications">
          <mat-form-field>
            <mat-label>Tipo de Modificacion<span class="asterisk">*</span></mat-label>
            <mat-select formControlName="name" (selectionChange)="refreshModifications($event)">
              <mat-option *ngFor="let type of typeModifications" [value]="type">
                {{type.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="modifications.get('name').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </form>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Valor para el flete<span class="asterisk">*</span></mat-label>
          <input type="text" currencyMask matInput formControlName="amount">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Valor para la tarifa<span class="asterisk">*</span></mat-label>
          <input type="text" currencyMask matInput formControlName="amountRate">
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Observación</mat-label>
          <textarea matInput formControlName="observation"></textarea>
          <mat-error *ngIf="form.get('observation').errors?.minlength">
            {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'observación', 5) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <span class="separator-horizontal"></span>
  <!-- New Value -->
  <div class="row" *ngIf="!validUtility">
    <div class="col-md-12 text-center text__color color--error text-strong">
      <span class="separator-horizontal"></span>
      <span class="">
        La utilidad no debe ser menor a {{utilityCargoResul}}%
      </span>
      <span class="separator-horizontal"></span>
    </div>
  </div>

  <div class="row">
    <!-- totalCost -->
    <div class="col-md-6">
      <div class="discounts-and-bonuses__header text-center">
        <span class="header__value text-strong title-1 font-secondary-dark"
          [ngClass]="{'text__color color--error': cargoItemService.getNewValueTotalCost(cargoData, form.get('amount').value, form.get('type').value) < 0}">
          {{cargoItemService.getNewValueTotalCost(cargoData, form.get('amount').value, form.get('type').value) |
          currency:'COP':'code'}}
        </span>
        <label class="header__title text-strong title-2"
          [ngClass]="{'text__color color--error': cargoItemService.getNewValueTotalCost(cargoData, form.get('amount').value, form.get('type').value) < 0}">
          {{cargoItemService.getNewValueTotalCost(cargoData, form.get('amount').value, form.get('type').value) >= 0 ?
          'Valor del flete aplicando ' + (form.get('type').value ? 'el descuento' : 'la bonificación') : 'El valor no
          puede quedar en negativo'}}
        </label>
        <label class="header__title text-strong title-2"
          [ngClass]="{'text__color color--error': form.get('type').value === 0 && cargoItemService.getNewValueTotalCost(cargoData, form.get('amount').value, form.get('type').value) > cargoItemService.getNewValueRate(cargoData, form.get('amountRate').value)}"
          *ngIf="form.get('type').value === 0 && cargoItemService.getNewValueTotalCost(cargoData, form.get('amount').value, form.get('type').value) > cargoItemService.getNewValueRate(cargoData, form.get('amountRate').value)">
          {{'El flete no puede quedar mayor a la tarífa'}}
        </label>
      </div>
    </div>
    <!-- rate -->
    <div class="col-md-6">
      <div class="discounts-and-bonuses__header text-center">
        <span class="header__value text-strong title-1 font-secondary-dark"
          [ngClass]="{'text__color color--error': cargoItemService.getNewValueRate(cargoData, form.get('amountRate').value, form.get('type').value) < 0}">
          {{cargoItemService.getNewValueRate(cargoData, form.get('amountRate').value, form.get('type').value) |
          currency:'COP':'code'}}
        </span>
        <label class="header__title text-strong title-2"
          [ngClass]="{'text__color color--error': cargoItemService.getNewValueRate(cargoData, form.get('amountRate').value, form.get('type').value) < 0}">
          {{cargoItemService.getNewValueRate(cargoData, form.get('amountRate').value , form.get('type').value) >= 0 ?
          'Valor de la tarifa aplicando ' + (form.get('type').value ? 'el descuento' : 'la bonificación') : 'El valor no
          puede quedar en negativo'}}
        </label>
      </div>
    </div>
  </div>
  <!-- Actions -->
  <div class="discounts-and-bonuses__footer">
    <div class="button-container">
      <button mat-raised-button class="primary-secondary button-width-auto" type="button" color="primary"
        (click)="onCancel()">Cancelar</button>
      <button mat-raised-button color="primary" class="button-width-auto bg-color-primary"
        type="submit">Guardar</button>
    </div>
  </div>

</form>