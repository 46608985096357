import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AdditionalCost } from 'src/app/core/interfaces/additionalCost';
import { Cargo } from 'src/app/core/models/cargo.model';

@Component({
  selector: 'app-list-additional-services',
  templateUrl: './list-additional-services.component.html',
  styleUrls: ['./list-additional-services.component.scss']
})
export class ListAdditionalServicesComponent implements OnInit {
  @Input() cargo: Cargo;
  @Input() cargoId: string;
  @Input() formArrayGroup: FormArray;
  @Input() companyId: string;
  @Input() approvalState: string;
  @Input() cargoApproval: string;
  @Input() public listAdditionalService: AdditionalCost[] = [];
  @Output() removeAdditionalCostItem: EventEmitter<number> = new EventEmitter();
  @Output() editAdditionalCostItem: EventEmitter<number> = new EventEmitter();
  constructor() { }

  ngOnInit() { }

}
