import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material';
import { DateManager } from 'src/app/core/managers/date.manager';
import { HistoryType, HistoryTypeDict } from 'src/app/core/enums/historyTypes.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompaniesService } from '../list-companies.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
@Component({
  selector: 'app-history-filter',
  templateUrl: './history-filter.component.html',
  styleUrls: ['./history-filter.component.scss'],
})
export class HistoryFilterComponent implements OnInit {
  @Output() emitlistHistoryParams: EventEmitter<any> = new EventEmitter();
  @Input() totalHistory = {};
  @Input() companyId: string;
  selectedHistoryOption: HistoryType[] = [HistoryType.MANIFESTS];
  stateFilterRequestHistory: FormControl = new FormControl([HistoryType.MANIFESTS]);
  dateFrom = new FormControl(DateManager.getFirstMonthDay(new Date()));
  dateUntil = new FormControl(new Date());
  historyOptions = Object.keys(HistoryTypeDict).map(key => {
    return { value: key, label: HistoryTypeDict[key] }
  });
  public optionsDateFrom = {
    maxDate: new Date()
  };
  public optionsDateUntil = {
    minDate: DateManager.getFirstMonthDay(new Date()),
    maxDate: new Date()
  };

  constructor(
    private spinner: NgxSpinnerService,
    private companiesService: CompaniesService,
    private snackBarService: SnackBarService,
    private utils: Utils,
  ) { }

  ngOnInit() {
    this.search();
  }

  setHistoryState(event: MatSelectChange) {
    this.selectedHistoryOption = event.value;
    this.search();
  }

  public onChangeDateFrom($event: MatDatepickerInputEvent<Date>) {
    const from: Date = $event.value;
    const threeMonthsLater: Date = DateManager.add(from, 3, 'months');
    const until: Date = this.dateUntil.value;

    if (DateManager.isBefore(threeMonthsLater, until))
      this.dateUntil.setValue(threeMonthsLater);

    this.optionsDateUntil.minDate = from;
    this.optionsDateUntil.maxDate = DateManager.isBefore(new Date(), threeMonthsLater)
      ? new Date()
      : threeMonthsLater;

    this.search();
  }

  getReport() {
    if (!this.stateFilterRequestHistory.value || !this.stateFilterRequestHistory.value.length) {
      this.snackBarService.openSnackBar(FormMessages.UNSELECTED_METRIC, undefined, 'alert');
      return;
    }
    this.spinner.show();
    this.companiesService.getReportHistoryCompanies(
      this.companyId,
      this.stateFilterRequestHistory.value,
      DateManager.dateToString(this.dateFrom.value, "YYYY-MM-DD ZZ"),
      DateManager.dateToString(this.dateUntil.value, "YYYY-MM-DD ZZ")
    ).subscribe({
      next: (success) => {
        this.spinner.hide();
        if (success && success.body)
          this.utils.downloadFile(
            success.body,
            `Reporte ${DateManager.dateToString(this.dateFrom.value, "YYYY-MM-DD")} - ${DateManager.dateToString(this.dateUntil.value, "YYYY-MM-DD")}`,
            'application/xslx'
          );
        else
          this.snackBarService.openSnackBar(FormMessages.NO_DATA_TO_EXPORT, undefined, 'error');
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    })
  }

  search() {
    const data = {
      type: this.stateFilterRequestHistory.value,
      startDate: DateManager.dateToString(this.dateFrom.value, "YYYY-MM-DD ZZ"),
      endDate: DateManager.dateToString(this.dateUntil.value, "YYYY-MM-DD ZZ")
    };
    this.emitlistHistoryParams.emit(data);
  }
}
