import { Component } from '@angular/core';

@Component({
    selector: 'app-quick-pay',
    templateUrl: './quick-pay.component.html',
    styleUrls: ['./quick-pay.component.scss']
})

export class QuickPayComponent {

}