import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Company } from 'src/app/core/interfaces/company';
import { Permission } from 'src/app/core/resources/permission';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { Tag } from 'src/app/core/interfaces/tag';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { TagFormComponent } from './tag-form/tag-form.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  permission = Permission;
  @Input() company: Company;
  @Output() public refreshList: EventEmitter<any> = new EventEmitter();
  tagList: Tag[] = [];

  constructor(
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private listCompaniesService: CompaniesService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getTagList();
  }

  private getTagList() {
    //Aquí se usaría un servicio para traer el listado actualizado.
    this.spinner.show();
    this.listCompaniesService.getLabelTags().subscribe(
      (success) => {
        this.spinner.hide();
        if (success && success.length) this.tagList = success;
      },
      (error) => {
        this.spinner.hide();
      }
    )
  }

  createTag() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Nueva etiqueta',
      tagList: this.tagList,
      confirmBtn: 'Confirmar'
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(TagFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.tag) {
        this.spinner.show();
        const body = {
          id: this.tagList.length + 1,
          name: result.tag.name
        }
        const arrayBody = [];
        arrayBody.push(body);
        this.listCompaniesService.addLabelTag(arrayBody).subscribe(
          (success) => {
            this.spinner.hide();
            this.tagList.push(result.tag);
            this.refreshList.emit();
            this.snackBarService.openSnackBar("Etiqueta creada correctamente");
          },
          (error) => {
            this.spinner.hide();
          }
        )
      }
    });
  }

  /*editTag(tag: Tag) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Editar etiqueta',
      tagList: this.tagList,
      tag,
      confirmBtn: 'Guardar'
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(TagFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.tag) {
        //Aquí se usaría un servicio para actualizar esa tag y luego actualizar el listado
        let currentTag = this.tagList.find(temptag => temptag.name === tag.name);
        if (currentTag) currentTag.name = result.tag.name; //PENDIENTE DE REVISIOOOON
        this.snackBarService.openSnackBar("Etiqueta editada correctamente");
      }
    });
  }

  deleteTag(tag: Tag) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Deseas eliminar la etiqueta ${tag.name}?`,
      labelButton1: 'Cancelar',
      labelButton2: 'Eliminar',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.refuse === "Eliminar") {
        //Aquí se usaría un servicio para eliminar esa tag y luego actualizar el listado
        let currentTagIndex = this.tagList.findIndex(temptag => temptag.name === tag.name);
        if (currentTagIndex !== -1) this.tagList.splice(currentTagIndex, 1); //PENDIENTE DE REVISIOOOON
        this.snackBarService.openSnackBar("Etiqueta eliminada correctamente");
      }
    });
  }*/

}
