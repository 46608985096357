<div class="image-thumbnail-container" *ngIf="images && images.length">
    <button class="scroll-button left" type="button" (click)="scrollLeft()">&#8592;</button>
    <div class="image-thumbnail-list" #thumbnailContainer>
        <div class="thumbnail" *ngFor="let image of images; let i = index">
            <img [src]="image" (click)="openImageNewTab(image)" (error)="onErrorRenderImage($event)"
                (change)="handleFileInput($event)" alt="Image Thumbnail" class="thumbnail-image" />
            <!--<button class="remove-button" (click)="removeImage(i)">x</button>-->
        </div>
    </div>
    <button class="scroll-button right" type="button" (click)="scrollRight()">&#8594;</button>
</div>
<div class="image-thumbnail-container" *ngIf="imagesWithFingerprint && imagesWithFingerprint.length">
    <button class="scroll-button left" type="button" (click)="scrollLeft()">&#8592;</button>
    <div class="image-thumbnail-list" #thumbnailContainer>
        <div class="thumbnail" *ngFor="let image of imagesWithFingerprint; let i = index">
            <img [src]="image.path" (click)="openImageNewTab(image.path)" (error)="onErrorRenderImage($event)"
                (change)="handleFileInput($event)" alt="Image Thumbnail" class="thumbnail-image" />
            <i class="fas fa-info-circle" permission-display [module]="permission.cargo.module"
                [functionality]="permission.cargo.evidencesFingerprint" *ngIf="image.fingerprint"
                [matTooltip]="getFingerprintToolTip(image.fingerprint)" matTooltipClass="custom-tooltip"></i>
            <!--<button class="remove-button" (click)="removeImage(i)">x</button>-->
        </div>
    </div>
    <button class="scroll-button right" type="button" (click)="scrollRight()">&#8594;</button>
</div>