<div class="container">
    <div class="col-md-12 mb-md-0 mb-12 container-type-trips">
        <div *ngFor="let option of options">
            <div class="type-trip" (click)="selectTrip(option)"
                [ngClass]="{'option-step-selected': (tripSelectedName === option.name && !option.disabled), 'disabled-option':option.disabled}">
                <div class="trip">
                    <div class="trip-icon">
                        <img src="{{option.image}}">
                    </div>
                    <div class="trip-title">
                        {{option.name}}
                    </div>
                </div>
                <mat-icon class="tip-icon" *ngIf="option.tip" matTooltip="{{option.tip}}" matTooltipPosition="above">
                    help
                </mat-icon>
            </div>
        </div>
    </div>
</div>