<div class="p-3 position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <label class="title-component mt-3 text-center w-100">
        Detalle de la solicitud
    </label>
    <div class="d-flex flex-column" *ngIf="fingerprint">
        <div class="d-flex flex-sm-row flex-column mb-2" *ngIf="fingerprint?.statusCode"
            [ngStyle]="fingerPrintsService.getSemaphore(fingerprint)">
            <div class="col-sm-2">Código <i class="fas fa-info-circle fa-lg"
                    *ngIf="fingerPrintsService.getCode(fingerprint.statusCode)" matTooltipPosition="above"
                    [matTooltip]="fingerPrintsService.getCode(fingerprint.statusCode)[1]"></i>
            </div>
            <div class="py-1 pl-0 col-sm-10 text-bold">
                {{fingerprint.statusCode}} {{fingerprint.statusInfo}}
            </div>
        </div>
        <div class="d-flex flex-sm-row flex-column mb-2" *ngIf="fingerprint?.method">
            <div class="col-sm-2">Método:</div>
            <div class="request-item px-3 py-1 col-sm-10">{{fingerprint.method}}</div>
        </div>
        <div class="d-flex flex-sm-row flex-column mb-2" *ngIf="fingerprint?.date">
            <div class="col-sm-2">Fecha:</div>
            <div class="request-item px-3 py-1 col-sm-10">{{formatDate(fingerprint?.date)}}</div>
        </div>
        <div class="d-flex flex-sm-row flex-column mb-2" *ngIf="fingerprint?.userName && fingerprint?.userDocument">
            <div class="col-sm-2">Usuario:</div>
            <div class="request-item px-3 py-1 col-sm-10 text-break">
                {{fingerprint.userName}} ({{fingerprint.userDocument}})
            </div>
        </div>
        <div class="d-flex flex-sm-row flex-column mb-2" *ngIf="fingerprint?.uri">
            <div class="col-sm-2">Petición:</div>
            <div class="request-item px-3 py-1 col-sm-10 text-break">{{fingerprint.uri}}</div>
        </div>
        <div class="d-flex flex-sm-row flex-column mb-2" *ngIf="fingerprint?.headers">
            <div class="col-sm-2">Encabezado:</div>
            <div class="request-item px-3 py-1 col-sm-10 d-flex flex-column">
                <div class="text-break" *ngIf="fingerprint?.headers?.tokenUser">
                    Token: {{fingerprint.headers.tokenUser}}
                </div>
                <div class="text-break" *ngIf="fingerprint?.headers?.appType">
                    AppType: {{fingerprint.headers.appType}}
                </div>
                <div class="text-break" *ngIf="fingerprint?.headers?.userSession">
                    Usuario: {{fingerprint.headers.userSession}}
                </div>
                <div class="text-break" *ngIf="fingerprint?.headers?.version">
                    Versión: {{fingerprint.headers.version}}
                </div>
                <div class="text-break" *ngIf="fingerprint?.headers?.companyId">
                    Compañía: {{fingerprint.headers.companyId}}
                </div>
            </div>
        </div>
        <div class="d-flex flex-sm-row flex-column mb-2" *ngIf="fingerprint?.requestEntity">
            <div class="col-sm-2">Entidad de la solicitud:</div>
            <div class="request-item px-3 py-1 col-sm-10 text-break">{{fingerprint.requestEntity}}</div>
        </div>
        <div class="d-flex flex-sm-row flex-column mb-2" *ngIf="fingerprint?.responseEntity">
            <div class="col-sm-2">Entidad de la respuesta:</div>
            <div class="request-item px-3 py-1 col-sm-10 text-break">{{fingerprint.responseEntity}}</div>
        </div>
    </div>
</div>