import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Cargo } from "src/app/core/models/cargo.model";
import { Endpoints } from "src/app/core/resources/endpoints";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ChangeCompanyService {

    

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints
    ) { }


    updateCompany(idCargo:String,nitCompany:String) {
        return this._http.put(
            environment.urlServerTeclogi + this._endpointResources.changeCompany+"cargoId="+idCargo+"&nit="+nitCompany,{}  
        );
    }


}
