<div class="icon-alertRNDC" (click)="state='Open'" *ngIf="state==='Closed'"
    matTooltip="Problemas en la plataforma del Mininisterio de Transporte">
    <i class="fas fa-exclamation-triangle"></i>
    <p class="mb-0">RNDC</p>
</div>
<div class="box-alertRNDC" *ngIf="state==='Open'">
    <mat-accordion>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header class="header-alertRNDC">
                <mat-panel-title class="text-bold text-alertRNDC mr-0">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-exclamation-triangle icon"></i>
                        <span>Problemas en la plataforma del Mininisterio de Transporte</span>
                        <i class="fas fa-times ml-4" (click)="state='Closed'"></i>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <p class="mt-3">Las siguientes operaciones no podrán ser registradas en el RNDC:</p>
                <ul>
                    <li class="text-bold">Creación de vehículos</li>
                    <li class="text-bold">Creación de terceros (conductor, propietario, administrador)</li>
                    <li class="text-bold">Generación manifiesto</li>
                    <li class="text-bold">Generación de remesa</li>
                </ul>
                <p class="mb-0">Se hará un intento automático y se informará cuando la operación sea exitosa.</p>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>