import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/user';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Company, Model } from 'src/app/core/interfaces/company';
import { AccountService } from 'src/app/modules/account/account.service';
import { Permission } from 'src/app/core/resources/permission';
import { MatAutocompleteSelectedEvent, MatDialogRef, MAT_DIALOG_DATA, MatTabGroup } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { AdminUsersService } from '../../admin-users/admin-users.service';
import { environment } from 'src/environments/environment';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ShippingCost } from 'src/app/core/enums/shipping-cost.enum';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { BasicCompany } from 'src/app/core/interfaces/basicCompany';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-form-company',
  templateUrl: './form-company.component.html',
  styleUrls: ['./form-company.component.scss'],
  providers: [AuthService, Model]
})
export class DetailCompanyComponent extends ReactiveForm implements OnInit {

  public environment = environment;
  permission = Permission;
  companyUser: Company;
  company: Company;
  title: string;
  listCommercial: Observable<User[]>
  formControlCommercial: FormControl = new FormControl();
  optionsCity = {
    title: 'Ciudad'
  }
  cityControl: FormControl = new FormControl('', Validators.required);
  citySub: Subscription;
  cityValidate = '';
  showPasswordRNDC: boolean = false;
  formControlNitRndc = new FormControl('');
  formControlStateRndc = new FormControl('Activo');
  roles = [
    { alias: 'Generadora de carga', id: '1', name: 'CARGO_GENERATOR' },
    { alias: 'Empresa de transporte', id: '2', name: 'TRANSPORT_COMPANY' },
  ];
  formControlRole = new FormControl('', Validators.required);
  formControlExpirationDate = new FormControl('', Validators.required);
  fromNowFilter = DateManager.filter({ from: new Date() });
  minUtility = 0;
  minUtilityAdittionalService = ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST;
  maxAdvancePercentage = AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES;
  minAdvancePercentage = 0;
  listCompanies: BasicCompany[] = [];
  listTabs = ['Información general', 'Requisitos de cumplido', 'Correos', 'Centros de Costo', 'Recursos', 'Métricas e históricos', 'Configuración', 'Etiquetas'];
  activeTab: string = 'Información general';
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  constructor(
    private authService: AuthService,
    private companiesService: CompaniesService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private router: Router,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private accountService: AccountService,
    public modelCompany: Model,
    private permissionRole: PermissionRole,
    public adminUsersService: AdminUsersService,
    public cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {
    super(
      formBuilder,
      modelCompany.model,
      modelCompany.model
    );
  }

  ngOnInit() {
    this.companyUser = this.authService.getCompany();
    this.listCompanies = this.accountService.listRegisterCompanies();
    this.minUtilityAdittionalService = this.companyUser.companyId === environment.rootNit ? ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST_TECLOGI : ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST;
    this.form.addControl('externalId', new FormControl(false, Validators.required));
    this.setValidatorsForm(this.modelCompany.companyValidators, this.form);
    this.setSubscriptionCity();

    if (window.location.hash.split('/').filter((aa) => aa === 'detail').length) {
      const params = this.route.snapshot.params;
      if (!this.utils.objIsEmpty(params)) {
        if (this.utils.isDefined(params.id)) {
          this.setDetailCompany(params.id);
        } else {
          this.goToList();
        }
      } else {
        this.goToList();
      }
    } else if (window.location.hash.split('/').filter((aa) => aa === 'create').length) {
      this.authService.getUserSession() && this.authService.getUserSession().information && this.authService.getUserSession().information.name && this.formControlCommercial.setValue(this.authService.getUserSession().information.name)
      this.title = 'Nueva Empresa';
      this.setValidations();
    } else {
      this.goToList();
    }
    this.formControlNitRndc.disable();
    this.formControlStateRndc.disable();
    this.formControlCommercial.disable();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  setSubscriptionCity() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => this.form.get('municipalityCode').setValue(value && value.id ? value.id : ''));
  }

  setDetailCompany(id?: string) {
    this.disabledFieldsForm({
      companyId: '',
    }, this.form);
    if (this.utils.isDefined(this.companiesService.compnySelected)) {
      this.company = this.companiesService.compnySelected;
      this.setValidations();
      this.setOptions();
      this.title = this.company.name;
      this.company && this.company.businessPartner && this.company.businessPartner.name && this.formControlCommercial.setValue(this.company.businessPartner.name);
      this.updateForm(this.company);
      if (this.company && this.company.rndcCredentials && this.company.rndcCredentials.companyId)
        this.formControlNitRndc.setValue(this.company.rndcCredentials.companyId);
      if (this.company && this.company.rndcCredentials && (this.company.rndcCredentials.state || this.company.rndcCredentials.state === false))
        this.formControlStateRndc.setValue(this.company.rndcCredentials.state ? "Activo" : "Inactivo");
      if (this.company && this.company.role && this.company.role.name)
        this.formControlRole.setValue(this.roles.filter(role => role.name === this.company.role.name)[0]);
      if (this.company && this.company.insuranceInformation && this.company.insuranceInformation.expirationDate) {
        this.formControlExpirationDate.setValue(DateManager.stringToDate(this.company.insuranceInformation.expirationDate, 'DD/MM/YYYY'));
      }
    } else if (this.utils.isDefined(id)) {
      this.getCompany(id, false);
    } else {
      this.goToList();
    }
  }

  public onSelectCommercial($event: MatAutocompleteSelectedEvent) {
    if ($event.option.value) {
      this.form.get('businessPartner').setValue($event.option.value);
    }
  }

  public getName(data: any): string {
    return data ? data.name : data;
  }

  getCompany(id?: string, updateCompanies = true) {

    this.accountService.validateEntity(2, id ? id : this.company.companyId).subscribe(
      (success: Company) => {

        if (success && success.companyId) {
          this.company = success;
          this.setValidations();
          this.setOptions();
          this.title = this.company.name;
          this.company && this.company.businessPartner && this.company.businessPartner.name && this.formControlCommercial.setValue(this.company.businessPartner.name);
          this.form.patchValue(this.company);
          if (this.company && this.company.rndcCredentials && this.company.rndcCredentials.companyId)
            this.formControlNitRndc.setValue(this.company.rndcCredentials.companyId);
          if (this.company && this.company.rndcCredentials && (this.company.rndcCredentials.state || this.company.rndcCredentials.state === false))
            this.formControlStateRndc.setValue(this.company.rndcCredentials.state ? "Activo" : "Inactivo");
          if (this.company && this.company.role && this.company.role.name)
            this.formControlRole.setValue(this.roles.filter(role => role.name === this.company.role.name)[0]);
          if (this.company && this.company.insuranceInformation && this.company.insuranceInformation.expirationDate) {
            this.formControlExpirationDate.setValue(DateManager.stringToDate(this.company.insuranceInformation.expirationDate, 'DD/MM/YYYY'));
          }
          if (updateCompanies) this.authService.getAllCompanies().toPromise().finally();

        } else {
          this.company = null;
          this.goToList();
        }
      },
      (error) => {

        this.company = null;
        this.goToList();
      }
    );
  }

  setValidations() {
    const fields = ['insuranceInformation.policyNumber', 'insuranceInformation.companyName', 'insuranceInformation.expirationDate', 'rndcCredentials.userName', 'rndcCredentials.password', 'colorConfiguration.primaryColor', 'colorConfiguration.secondaryColor'];
    fields.forEach(field => {
      this.isPrincipalCompany ? this.form.get(field).setValidators(Validators.required) : this.form.get(field).disable();
    })
    this.isPrincipalCompany ? this.formControlExpirationDate.setValidators(Validators.required) : this.formControlExpirationDate.disable();
    //for (let i = 0; i <= 3; i++) this.isPrincipalCompany ? this.getPrefix(i).get('name').setValidators(Validators.required) : this.getPrefix(i).disable();
  }

  setOptions() {
    this.optionsCity['initialMunicipalityCode'] = this.company && this.company.municipalityCode ? this.company.municipalityCode : '';
    this.optionsCity = { ...this.optionsCity };
  }

  onSubmit() {
    if (!this.dataDialog || !this.dataDialog.newThirdParty) {
      this.saveDataCompany();
    }
  }

  saveDataCompany() {
    if (this.title === 'Nueva Empresa') {
      this.createCompany();
    } else {
      this.updateCompany();
    }
  }

  createCompany() {
    if (this.verifyFields()) {
      const company: Company = this.form.value;
      company.exclusive = (company.exclusive);
      company.emailSubject = '¡Su pedido va en camino!';
      company.emailBody = '¡Vamos en camino a entregar tu producto!';
      company.configuration = {
        reports: [
          {
            type: 'addressDuration',
            units: 'milliseconds',
            title: 'Duración en cada dirección'
          },
          {
            type: 'routeDuration',
            units: 'milliseconds',
            title: 'Duración total de cada carga'
          }
        ],
        serviceType: 'express'
      };
      company.businessPartner = {
        documentTypeId: `${this.authService.getUserSession().information.documentTypeId}`,
        documentTypeName: `${this.authService.getUserSession().information.documentTypeName}`,
        document: `${this.authService.getUserSession().information.document}`,
        name: `${this.authService.getUserSession().information.name}`
      }
      this.spinner.show();
      this.companiesService.createCompany(
        company
      ).subscribe(
        (success: Company) => {
          if (success && !success.message) {
            this.createRoles(success);
            this.authService.getAllCompanies().toPromise().finally();
          } else if (success && success.message) {
            this.spinner.hide();
            this.snackBarService.openSnackBar(success.message, undefined, 'error');
          } else {
            this.spinner.hide();
            this.snackBarService.openSnackBar('Ocurrió un error al crear la compañía', undefined, 'error');
          }
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al crear la compañía', undefined, 'error');
        }
      );
    }
  }

  createRoles(company) {
    const rolesCompanies = this.companiesService.getModelRole(company.companyId);
    this.companiesService.createMultipleRole(rolesCompanies).subscribe(
      (success) => {
        this.finishCreateCompany();
        this.snackBarService.openSnackBar("La empresa y sus roles se crearon correctamente", undefined, "success");
      },
      (error) => {
        this.finishCreateCompany();
        this.snackBarService.openSnackBar("Hubo un error al crear los roles de la empresa", undefined, "error");
      }
    );
  }

  finishCreateCompany() {
    this.spinner.hide();
    this.goToList();
  }

  getDetailCompanyParent() {
    this.authService.getDetailCompany(this.companyUser.companyId).subscribe(
      (success) => {
        if (this.title === 'Nueva Empresa') {
          this.goToList();
        }
      },
      (error) => {
        if (this.title === 'Nueva Empresa') {
          this.goToList();
        }
      }
    );
  }

  updateCompany() {
    if (this.verifyFields()) {
      const companyParent = this.companyUser && this.companyUser.companyId === environment.rootNit ? null : this.companyUser;
      const newCompany: Company = { ...this.company, ...this.form.value };
      if (newCompany && newCompany.rndcCredentials && newCompany.rndcCredentials.userName && newCompany.rndcCredentials.password) newCompany.rndcCredentials.companyId = this.company.companyId;
      this.spinner.show();
      this.companiesService.updateCompany(
        newCompany
      ).subscribe(
        (success) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Información actualizada correctamente');
          this.getCompany(this.company.companyId);
          if (this.company.companyId === this.authService.getCompany().companyId) {
            this.spinner.show();
            this.authService.getFullUserByEmail(this.authService.getUserSession().email).subscribe(
              () => {
                this.spinner.hide();
              },
              () => {
                this.spinner.hide();
              }
            );
          }
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al actualizar la compañía', undefined, 'error');
        }
      );
    }
  }

  onSelectTab($event) {
    const selectedIndex = $event;
    const selectedTabLabel = this.tabGroup && this.tabGroup._tabs && this.tabGroup._tabs.toArray()[selectedIndex] && this.tabGroup._tabs.toArray()[selectedIndex].textLabel ? this.tabGroup._tabs.toArray()[selectedIndex].textLabel : '';
    this.activeTab = this.listTabs.includes(selectedTabLabel) ? selectedTabLabel : '';
  }

  goToList() {

    this.router.navigate(['administration/companies/list']);
  }

  onSelectCity($event) {
    if ($event.data && $event.data.id) {
      this.form.get('municipalityCode').setValue($event.data.id);
    }
  }

  onSelectAddress($event) {
    this.form.get('address').setValue($event);
  }

  onSelectRole($event) {
    this.form.get('role.id').setValue($event.value.id);
    this.form.get('role.name').setValue($event.value.name);
  }

  onChangeDatePicker($event) {
    this.form.get('insuranceInformation.expirationDate').setValue(DateManager.dateToString($event.value, 'DD/MM/YYYY'));
  }

  verifyFields(): boolean {
    this.cityValidate = 'touched';
    if (this.utils.errorMessagesCustomized(this.form.get('companyId'), 'NIT', 9, 9)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('name'), 'razón social', 3)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('email'), 'correo electrónico', null, 100)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('municipalityCode'), 'ciudad')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('address'), 'dirección')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('phone'), 'teléfono')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('insuranceInformation.policyNumber'), 'no. de poliza')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('insuranceInformation.companyName'), 'SOAT')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('insuranceInformation.expirationDate'), 'fecha de expiración')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('role.name'), 'rol')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('contact.name'), 'nombres para facturación', 3)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('contact.lastName'), 'apellidos para facturación', 3)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('contact.email'), 'correo para facturación', null, 100)) return false;
    // else if (this.utils.errorMessagesCustomized(this.getPrefix(0).get('name'), 'prefijo de manifiestos')) return false;
    // else if (this.utils.errorMessagesCustomized(this.getPrefix(1).get('name'), 'prefijo de remesas')) return false;
    // else if (this.utils.errorMessagesCustomized(this.getPrefix(2).get('name'), 'prefijo de cargas nacionales')) return false;
    // else if (this.utils.errorMessagesCustomized(this.getPrefix(3).get('name'), 'prefijo de cargas urbanas')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('utility'), 'utilidad', null, null, this.minUtility, 100)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('utilityAdditionalService'), 'utilidad de servicios adicionales', null, null, this.minUtilityAdittionalService, 100)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('advancePercentage'), 'porcentaje de anticipo', null, null, this.minAdvancePercentage, this.maxAdvancePercentage)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('rndcCredentials.userName'), 'usuario del RNDC')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('rndcCredentials.password'), 'contraseña del RNDC')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('colorConfiguration.primaryColor'), 'color principal')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('colorConfiguration.secondaryColor'), 'color secundario')) return false;
    else if (this.form.invalid) {
      this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT);
      return false
    } else return true;
  }

  get hasPermissionToAddEmails(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.createEmailCompany
    ) && !!this.company;
  }

  // getPrefix(index) {
  //   return this.form.get('rndcPrefix').controls[index] as FormControl;
  // }

  get isPrincipalCompany(): boolean {
    return !!(this.listCompanies.length && this.company && this.company.companyId &&
      this.listCompanies.some(company => company.nit === this.company.companyId) &&
      this.authService.getCompany() && this.authService.getCompany().companyId === this.company.companyId);
  }

  get createUpdatePermission(): boolean {
    return !!((this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.updateCompany) && window.location.hash.split('/').filter((aa) => aa === 'detail').length) || (this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.createCompany) && window.location.hash.split('/').filter((aa) => aa === 'create').length));
  }

  ngOnDestroy() {
    this.cdRef.detach();
    if (this.citySub) this.citySub.unsubscribe();
  }
}

