import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor() { }

  public static getContrast(argb_color_1: number, argb_color_2: number): number {
    const K = 0.03928;

    const urc1 = this.getUnitPerChanel(this.red(argb_color_1));
    const ugc1 = this.getUnitPerChanel(this.green(argb_color_1));
    const ubc1 = this.getUnitPerChanel(this.blue(argb_color_1));

    const crc1 = urc1 > K ? Math.pow(((urc1 + 0.055) / 1.055), 2.4) : (urc1 / 12.92);
    const cgc1 = ugc1 > K ? Math.pow(((ugc1 + 0.055) / 1.055), 2.4) : (ugc1 / 12.92);
    const cbc1 = ubc1 > K ? Math.pow(((ubc1 + 0.055) / 1.055), 2.4) : (ubc1 / 12.92);

    const luminance_c1 = (crc1 * 0.2126) + (cgc1 * 0.7152) + (cbc1 * 0.0722);

    const urc2 = this.getUnitPerChanel(this.red(argb_color_2));
    const ugc2 = this.getUnitPerChanel(this.green(argb_color_2));
    const ubc2 = this.getUnitPerChanel(this.blue(argb_color_2));

    const crc2 = urc2 > K ? Math.pow(((urc2 + 0.055) / 1.055), 2.4) : (urc2 / 12.92);
    const cgc2 = ugc2 > K ? Math.pow(((ugc2 + 0.055) / 1.055), 2.4) : (ugc2 / 12.92);
    const cbc2 = ubc2 > K ? Math.pow(((ubc2 + 0.055) / 1.055), 2.4) : (ubc2 / 12.92);

    const luminance_c2 = (crc2 * 0.2126) + (cgc2 * 0.7152) + (cbc2 * 0.0722);

    return (luminance_c1 > luminance_c2 ?
      (luminance_c1 + 0.05) / (luminance_c2 + 0.05) :
      (luminance_c2 + 0.05) / (luminance_c1 + 0.05) - 1) / 20;
  }

  public static getUnitPerChanel(chanel: number): number {
    return chanel / 255;
  }

  /**
   * 
   * @param chanel CHANEL_INTENSITY: number 0-255
   * @example input 0 output "00", input 0xf output "0f"
   * @returns string as hex int representation from 00 to ff
   */
  private static safeChanel(chanel: number) {
    const value = Math.ceil(chanel).toString(16);
    return value.length > 1 ? value : `0${value}`;
  }

  /**
   * 
   * @param A numeric alpha value 0-255
   * @param R numeric red value 0-255
   * @param G numeric green value 0-255
   * @param B numeric blue value 0-255
   * @returns argb numeric representation example 0xff0000ff = 4278190335 | 0xffffffff = 4294967295
   */
  public static composeArgb(A: number, R: number, G: number, B: number) {
    const hex = `0x${this.safeChanel(A)}${this.safeChanel(R)}${this.safeChanel(G)}${this.safeChanel(B)}`;
    return parseInt(hex);
  }

  public static alpha(argb: number): number {
    return parseInt(`0x${argb.toString(16).slice(0, 2)}`);
  }

  public static red(argb: number): number {
    return parseInt(`0x${argb.toString(16).slice(2, 4)}`);
  }

  public static green(argb: number): number {
    return parseInt(`0x${argb.toString(16).slice(4, 6)}`);
  }

  public static blue(argb: number): number {
    return parseInt(`0x${argb.toString(16).slice(6, 8)}`);
  }
}
