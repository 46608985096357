import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './../../core/services/authentication.service';
import { filter, map } from 'rxjs/operators';
import { NavItem } from './../../core/interfaces/navItem';
import { Global } from 'src/app/core/resources/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { version } from 'package.json';
import { FirebaseApi } from 'src/app/core/classes/firebase-api';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { environment } from 'src/environments/environment';
import { MasterTestingMenuDialogComponent } from 'src/app/shared/master-testing-menu-dialog/master-testing-menu-dialog.component';
import { Companies } from 'src/app/core/resources/companies';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicFirebaseService } from 'src/app/core/services/dynamic-firebase.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  public environment = environment;
  menu: NavItem[];
  activeNav: string;
  userName: string;
  onNavigationEnd: any;
  message;
  showNavResponsive: boolean = false;
  firebaseUserUnsubscribe;
  firebaseRoleUnsubscribe;
  isAbleToGetUser: boolean = false;
  isAbleToGetRole: boolean = false;
  currentRole: string;
  currentRoleId: string;
  @Input() orientation: string;
  version: string

  constructor(
    private router: Router,
    public authService: AuthService,
    private cdRef: ChangeDetectorRef,
    public global: Global,
    private modalService: NgbModal,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public dynamicService: DynamicFirebaseService
  ) {
    this.menu = this.global.menu;
    this.onNavigationEnd = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => {
        return e;
      })
    );
  }

  ngOnInit() {
    this.validateSession();
    this.version = version;
  }

  validateSession() {
    if (this.authService.sessionActive()) {
      if (this.authService.getUserSession().information.name) {
        this.userName = this.authService.getUserSession().information.name.split(" ")[0];
        if (this.authService.getUserSession().role) this.currentRole = this.authService.getUserSession().role;
        if (this.authService.getUserSession().information.document) this.subscribeToUserChanges(this.authService.getUserSession().information.document);
        if (this.authService.getUserSession().roleId) {
          this.currentRoleId = this.authService.getUserSession().roleId;
          this.subscribeToRoleChanges(this.authService.getUserSession().roleId);
        }
      }
    } else {
      this.userName = "";
    }
    this.cdRef.detectChanges();
    this.subscribeActiveMenu();
  }

  async subscribeToUserChanges(document: string) {

    this.isAbleToGetUser = false;
    if (this.firebaseUserUnsubscribe) this.firebaseUserUnsubscribe();
    try {
      const doc = await FirebaseApi.firestore.collection('UserClient').doc(document).get();
      if (doc.exists) {
        this.firebaseUserUnsubscribe = FirebaseApi.firestore.collection('UserClient').doc(document).onSnapshot((snapshot) => {
          if (snapshot.exists) {
            let currentUser = snapshot.data();
            if (!this.isAbleToGetUser) this.isAbleToGetUser = true;
            else if (currentUser && currentUser.roleId && currentUser.role && currentUser.roleId !== this.currentRoleId) {
              this.currentRole = currentUser.role;
              this.currentRoleId = currentUser.roleId;
              this.changesOnPermissionsMessage(`Se te ha asignado el Rol de ${this.currentRole}, ¿Deseas recargar para obtener los nuevos permisos?`);
              this.subscribeToRoleChanges(this.currentRoleId);
            }
          }
        });
      }
    } catch (e) { }
  }

  async subscribeToRoleChanges(roleId: string) {

    this.isAbleToGetRole = false;
    if (this.firebaseRoleUnsubscribe) this.firebaseRoleUnsubscribe();
    try {
      const doc = await FirebaseApi.firestore.collection('Rol').doc(roleId).get();
      if (doc.exists) {
        this.firebaseUserUnsubscribe = FirebaseApi.firestore.collection('Rol').doc(roleId).onSnapshot((snapshot) => {
          if (snapshot.exists) {
            if (!this.isAbleToGetRole) this.isAbleToGetRole = true;
            else {
              this.changesOnPermissionsMessage(`Se te han asignado nuevos permisos, ¿Deseas recargar para obtenerlos?`);
            }
          }
        });
      }
    } catch (e) { }
  }

  changesOnPermissionsMessage(message: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: message,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        window.location.reload();
      }
    });
  }

  subscribeActiveMenu() {
    this.activeNav = window.location.hash.substring(1);
    this.onNavigationEnd.subscribe(arg => {
      if (arg && arg.url) {
        this.activeNav = arg.url;
      }
    });
  }

  refreshDataCompanyOthersComponents() {
    switch (this.router.url) {
      case '/validation':
        break;
      case '/documents':

        break;
    }
  }

  openOptionsProfile(tooltip: any) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open();
    }
  }

  openModalConfirmLogOut(modal) {
    this.modalService.open(modal, { keyboard: false, centered: true, ariaLabelledBy: 'modal-basic-title' });
  }

  closeModalConfirmLogOut() {
    this.modalService.dismissAll();
  }

  async logOut() {
    const responseSetToken = await this.authService.updateUId();
    if (responseSetToken) {
      this.authService.logOut().then(
        async (data) => {
          this.userName = "";
          await this.dynamicService.deleteAllFirebaseInstances();
          this.closeModalConfirmLogOut();
          this.authService.clearSession();
          location.reload();
        },
        (error) => {
        }
      );
    }
  }



  openMasterTools() {
    const config = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.EXTRA_SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    this.dialog.open(MasterTestingMenuDialogComponent, config);
  }

  viewDocuments() {
    this.router.navigate(['/documents']);
  }

  changePassword() {
    this.router.navigate(['/password']);
  }

  onOpen(item: NavItem) {
    item.expanded = true;
  }

  onClose(item: NavItem) {
    item.expanded = false;
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
    this.showNavResponsive = !this.showNavResponsive;
  }

  onClickItemMenu(itemChildren: NavItem, item: NavItem) {
    if (itemChildren.route) {
      this.router.navigate([itemChildren.route]);
      item.expanded = false;
    } else if (itemChildren.displayName === 'Cerrar sesión') {
      this.closeModalConfirmLogOut();
    }
    this.showNavResponsive = !this.showNavResponsive;
  }

  isItemActive(item: NavItem): boolean {
    return item && item.childrens && item.childrens.some(itemChildren => this.activeNav === itemChildren.route);
  }

  canBeShown(permission: NavItem): boolean {
    return Companies.companiesAccess(permission.route, this.authService.getUserSession().clientCompanyId, sessionStorage.getItem('_companyId'));
  }

  expansionPanelHeader(navItem: NavItem) {
    if ((!navItem.childrens || navItem.childrens.length === 0) && navItem.route) {
      this.router.navigate([navItem.route]);
    }
  }

  toggleNavResponsive() {
    this.showNavResponsive = !this.showNavResponsive;
  }

  redirectionSignIn() {
    return !!(this.authService.getUserSession().email.includes('seti' || 'zonafranca'));
  }

  ngOnDestroy() {
    if (this.firebaseUserUnsubscribe) this.firebaseUserUnsubscribe();
    if (this.firebaseRoleUnsubscribe) this.firebaseRoleUnsubscribe();
    this.cdRef.detach();
  }
}
