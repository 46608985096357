<mat-form-field [appearance]="options?.appearance ? options?.appearance : 'standard'">
    <mat-label>Placa del vehículo<span class="asterisk"
            *ngIf="utils.isRequiredField(licensePlateCtrl)">*</span></mat-label>
    <input type="text" matInput [matAutocomplete]="auto" [formControl]="licensePlateCtrl" (keyup)="changeValue()">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectVehicle($event)" [displayWith]="displayVehicle">
        <mat-option *ngFor="let vehicle of listVehicles | async" [value]="vehicle" [disabled]="!hasDriver(vehicle)">
            <span>{{vehicle?.id}}|<small>{{hasDriver(vehicle)? vehicle.driver.name : 'Sin conductor' }}</small></span>
        </mat-option>
        <mat-option [value]="''" (click)="openDialogCreateVehicle()" class="font-secondary-dark">
            <i class="fas fa-plus-circle mr-1"></i>
            Crear nuevo vehículo
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="licensePlateCtrl.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
    </mat-error>
</mat-form-field>