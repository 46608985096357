import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AdditionalCostCargo } from 'src/app/core/interfaces/additionalCostCargo';
import { Permission } from 'src/app/core/resources/permission';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';
import { PermissionRole } from 'src/app/core/resources/permission-role';

@Component({
  selector: 'app-summary-additional-cost-cargo',
  templateUrl: './summary-additional-cost-cargo.component.html',
  styleUrls: ['./summary-additional-cost-cargo.component.scss']
})
export class SummaryAdditionalCostCargoComponent implements OnInit {

  @Input() additionalCosts?: AdditionalCostCargo[];
  permission = Permission;
  public environment = environment;

  constructor(
    public authService: AuthService,
    private permissionRole: PermissionRole,
    public dialogRef: MatDialogRef<SummaryAdditionalCostCargoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any
  ) {
    if (this.dialogParams && this.dialogParams.additionalCosts) {
      this.additionalCosts = this.dialogParams.additionalCosts;
    }
  }

  ngOnInit() {
  }

  showFingerPrintInfo(show: boolean, additionalCost: AdditionalCostCargo) {
    additionalCost['show'] = show;
  }

  getTotalBonusesOrDiscounts(additionalCost: AdditionalCostCargo, type: 'discount' | 'bonus', category: 'amount' | 'amountRate'): number {
    let total = 0;
    const testType = type === "bonus" ? 0 : 1;
    if (additionalCost.financialInformation && additionalCost.financialInformation.modifications && additionalCost.financialInformation.modifications.length)
      additionalCost.financialInformation.modifications.forEach(modification => {
        if (modification.type === testType)
          total += modification[category];
      })
    return total;
  }

  get showAdditionalCost() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showCostCargo) || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostUpdate) || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostCreate)
  }

  get showAdditionalRate() {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showRateCargo) || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostUpdate) || this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.additionalCostCreate)
  }

  get isDialog() {
    return this.dialogParams && this.dialogParams.additionalCosts;
  }

}
