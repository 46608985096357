import { Component, Input, OnInit } from '@angular/core';
import { CostCenter } from 'src/app/core/interfaces/costCenter';

@Component({
  selector: 'app-cost-center-item',
  templateUrl: './cost-center-item.component.html',
  styleUrls: ['./cost-center-item.component.scss']
})
export class CostCenterItemComponent implements OnInit {

  @Input() costCenter: CostCenter;
  @Input() activeTab: string;
  constructor() { }

  ngOnInit() {
  }

}
