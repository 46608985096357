<div class="container">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="close()"></i>

    <p class="title">
        Aceptación iniciada por el usuario
    </p>

    <p>A continuación encontrará una guía de paso a paso para la aceptación de términos iniciada por el conductor.</p>

    <ol>
        <li>
            Envíele el siguiente link
            <span style="text-decoration: underline; cursor: pointer;" (click)="copyWpToClipboard()">
                https://wa.me/573173678485?text=Buenas%20dia
            </span>
            &nbsp;
            <i style="cursor: pointer;" class="far fa-copy" (click)="copyWpToClipboard()"></i>
            al conductor/usuario
        </li>
        <li>
            Una vez el conductor establezca comunicación con el chatbot Sara, esta le requerirá el número de cédula y le
            pedirá la aceptación de términos y condiciones vigentes.
        </li>
    </ol>
    <div class="text-center w-100">
        <button mat-raised-button color="accent" (click)="close()">Aceptar</button>
    </div>
</div>