<form class="padding-modal position-relative" [formGroup]="form" *ngIf="form" (submit)="onSubmit()">
    <i *ngIf="dialogParams && dialogParams.form" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
        (click)="dialogRef.close()"></i>
    <span class="separator-horizontal"></span>
    <div class="title-2 row ml-1 header">
        <div class="titulo color-secondary-dark pl-0 mb-3 mb-md-0 col-12 col-md-4">
            {{title ? title: 'Datos de GPS'}}
        </div>
        <div class="btn-gps pl-0 col-6 col-md-4" permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.showCreateGps" (click)="createGPS()">
            <i class="fas fa-plus-circle fa-lg mr-1"></i>Crear
        </div>
        <div class="btn-gps pl-0 col-6 col-md-4" permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.showDeleteGps" (click)="deleteGPS()">
            <i class="fas fa-trash fa-lg mr-1"></i>Eliminar
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <app-select-gps [inputFormControl]="form.get('gpsType')" [options]="optionsGps"
                [validate]="validate"></app-select-gps>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="standard">
                <mat-label>Usuario del GPS<span class="asterisk"
                        *ngIf="utils.isRequiredField(form.get('userGps'))">*</span></mat-label>
                <input matInput type="text" name="userGps" formControlName="userGps">
                <mat-error *ngIf="form.get('userGps').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="standard">
                <mat-label>Contraseña del GPS<span class="asterisk"
                        *ngIf="utils.isRequiredField(form.get('passwordGps'))">*</span></mat-label>
                <input matInput type="text" name="passwordGps" formControlName="passwordGps">
                <mat-error *ngIf="form.get('passwordGps').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="updateGPS">
        <div class="col-md-12 button-container mb-3 mt-3">
            <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
                (click)="closeDialog()" type="button">
                Cancelar
            </button>
            <button mat-raised-button color="primary" type="submit" class="button-width-auto bg-color-primary">
                Guardar
            </button>
        </div>
    </div>
</form>