<div class="container">
  <div id="mainAssignLicensePlate" class="col-md-12 mb-md-0 mb-12">

    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          ¿A qué vehículo(s) quieres asignar?
        </label>
      </div>
      <div class="col-md-12 mb-md-0 mb-12">
        <div class="row" *ngFor="let vehicle of listLicensePlate">
          <div class="col-md-4 container-vehicles-count">
            <div class="type-vehicle">
              <div class="vehicle">
                <div class="container-icon">
                  <div class="vehicle-icon">
                    <img src="{{getIconVehicle(vehicle)}}" alt="">
                  </div>
                </div>
                <div class="vehicle-title">
                  {{vehicle.name}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="standard">
              <mat-label>Tipo de carrocería</mat-label>
              <input type="text" matInput [value]="vehicle.bodyWorkType.name" [disabled]="true">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="standard"
              [class.mat-form-field-invalid]="!patterns.GET_REGEX('LICENSE_PLATES').test(vehicle.licensePlate)">
              <mat-label>Placa</mat-label>
              <input matInput type="text" maxlength="7" [value]="vehicle.licensePlate"
                (keyup)="onKeyUp($event, vehicle)" [pattern]="patterns.GET_REGEX('LICENSE_PLATES')">
              <mat-hint class="mat-error" *ngIf="!patterns.GET_REGEX('LICENSE_PLATES').test(vehicle.licensePlate)">
                <b>{{ utils.giveMessageError('INCORRECT_FORMAT', 'placa') }}</b>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-12 button-container justify-content-center mt-3">
        <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
        <app-request-button></app-request-button>
        <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Guardar y
          continuar</button>
      </div>
    </div>

  </div>
</div>