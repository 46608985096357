import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { PAYMENT_TYPES } from 'src/app/core/enums/payment-types.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DiscountsAndBonuses } from 'src/app/core/interfaces/discountsAndBonuses';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { UserClient } from 'src/app/core/interfaces/userClient';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Permission } from 'src/app/core/resources/permission';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CargoOptionsService } from '../cargo-options/cargo-options.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cargo-shipping-cost-dialog',
  templateUrl: './cargo-shipping-cost-dialog.component.html',
  styleUrls: ['./cargo-shipping-cost-dialog.component.scss'],
  providers: [CurrencyPipe],
})
export class CargoShippingCostDialogComponent {
  permission = Permission;
  typePayment = "DiscountsAndBonuses";
  shippingCostToDisplay: Array<DiscountsAndBonuses> = [];
  users = {};
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { shippingCostToDisplay: Array<DiscountsAndBonuses>, typePayment?: string, cargo: Cargo },
    public ref: MatDialogRef<CargoShippingCostDialogComponent>,
    private adminUsersService: AdminUsersService,
    private router: Router,
    public matDialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    private service: CargoOptionsService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private permissionRole: PermissionRole,
    private authService: AuthService,
    private utils: Utils,
  ) {
    if (data && data.shippingCostToDisplay instanceof Array)
      this.shippingCostToDisplay = data.shippingCostToDisplay;
    if (data && data.typePayment) this.typePayment = data.typePayment;
  }

  ngOnInit() {
    for (const shippingCost of this.shippingCostToDisplay) {
      this.getUser(shippingCost.userId);
    }
  }

  getUser(id: string) {
    if (!id || this.users[id]) return;
    this.adminUsersService.getUserClientByDocument(id).subscribe(
      (success: UserClient[]) => {
        if (success && success.length) this.users[id] = success[0];
      }
    );
  }

  getTypePaid(shippingCostType: PAYMENT_TYPES): string {
    const dictionary = {
      "advance": "Anticipo",
      "extraAdvance": "Extra anticipo",
      "balance": "Saldo",
      "additionalServicesLoad": "Servicio cargue",
      "additionalServicesDownload": "Servicio descargue",
      "additionalServicesLoadStandBy": "Stand by",
      "travelExpenses": "Viáticos",
    }
    if (dictionary[shippingCostType]) return dictionary[shippingCostType];
    return '-';
  }

  canDeletePayment(payment: DiscountsAndBonuses): boolean {
    return this.utils.isDefined(payment.id) && (payment.type !== 'balance' ||
      (payment.type === 'balance' && this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.deleteBalancePayment)));
  }

  openDialogDelete(payment: DiscountsAndBonuses) {
    if (!this.canDeletePayment(payment)) {
      let error = '';
      if (!this.utils.isDefined(payment.id)) error = "Solo es posible eliminar pagos posteriores al 16 de Febrero de 2024";
      else if (payment.type === 'balance' && (!this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.deleteBalancePayment)))
        error = "No cuentas con el permiso necesario para eliminar el pago del saldo";
      else error = "No es posible eliminar este pago";
      this.snackBarService.openSnackBar(error, undefined, 'alert');
      return;
    }
    const config = new MatDialogConfig();
    const amount = payment.amount ? payment.amount : payment.amountRate;
    config.data = {
      title: `¿Estás seguro que deseas eliminar este pago?`,
      description: `No se podrá reversar esta operación, ¿Deseas continuar?`,
      messageList: [
        `Carga: ${this.data.cargo.consecutive}`,
        `Valor: ${amount ? this.currencyPipe.transform(amount, "COP", "code") : '0 COP'}`,
        `Fecha: ${DateManager.formatDate(payment.date, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD hh:mm a')}`,
      ],
      showYesBtn: true,
      showNoBtn: true
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.matDialog
      .open(DialogComponent, config)
      .afterClosed()
      .subscribe(
        (result) => {
          if (result && result.state) this.deletePayment(payment);
        }
      );
  }

  deletePayment(payment: DiscountsAndBonuses) {
    this.spinner.show();
    this.service.deletePayment(this.data.cargo.id, payment.id).subscribe(
      (response) => {
        this.spinner.hide();
        if (response && response['message'] && response['message'] === "Delete") {
          this.snackBarService.openSnackBar("Se ha eliminado el pago");
          this.shippingCostToDisplay = this.shippingCostToDisplay.filter((shippingCost) => !shippingCost.id || shippingCost.id !== payment.id);
          if (!this.shippingCostToDisplay.length) this.ref.close();
        } else {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        }
      }, () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }
}
