export const Permission = {
  administration: {
    module: "administration",
    adminTerms: "adminTerms",
    adminPrivacy: "adminPrivacy",
    adminDataPolicies: "adminDataPolicies",
    adminVehicles: "adminVehicles",
    adminTrailers: "adminTrailers",
    adminCompanies: "adminCompanies",
    adminCities: "adminCities",
    adminUsers: "adminUsers",
    showDocumentsPendingDrivers: "showDocumentsPendingDrivers",
    validateDocumentsPendingDrivers: "validateDocumentsPendingDrivers",
    showSecurityAnalysis: "showSecurityAnalysis",
    disableEnableUsers: "disableEnableUsers",
    disableEnableDriver: "disableEnableDriver",
    checkUser: "checkUser",
    setupMonitorIntegration: "setupMonitorIntegration",
    truoraConfiguration: "truoraConfiguration",
    activateByScore: "activateByScore",
    createTermsConditions: "createTermsConditions",
    createPrivacyPolicy: "createPrivacyPolicy",
    downloadFileRegistreBank: "downloadFileRegistreBank",
    createVehicle: "createVehicle",
    updateVehicle: "updateVehicle",
    activateVehicle: "activateVehicle",
    updateBankAdvance: "updateBankAdvance",
    updateBankBalance: "updateBankBalance",
    createCompany: "createCompany",
    updateCompany: "updateCompany",
    createRoles: "createRoles",
    updateRoles: "updateRoles",
    changeRole: "changeRole",
    adminRoles: "adminRoles",
    adminModules: "adminModules",
    changeDriverVehicle: "changeDriverVehicle",
    validateAndRemarkVehicleReferences: "validateAndRemarkVehicleReferences",
    showCreateGps: "showCreateGps",
    modifyBasicFieldsUser: "modifyBasicFieldsUser",
    showDeleteGps: "showDeleteGps",
    editCompliments: "editCompliments",
    createCompanyComplimentType: "createCompanyComplimentType",
    editTags: "editTags",
    updateCity: "updateCity",
    linkCities: "linkCities",
    addSubCities: "addSubCities",
    deleteSubCities: "deleteSubCities",
    createEmailCompany: "createEmailCompany",
    forceVehicleActivation: "forceVehicleActivation",
    deactivateVehicle: "deactivateVehicle",
    validateVehicleDocuments: "validateVehicleDocuments",
    editAdminVehicle: "editAdminVehicle",
    editOwnerVehicle: "editOwnerVehicle",
    editGpsVehicle: "editGpsVehicle",
    editReferencesVehicle: "editReferencesVehicle",
    reactivateVehicleByInactivity: "reactivateVehicleByInactivity",
    changeFeeToOwnedFleet: "changeFeeToOwnedFleet"
  },
  cargo: {
    module: "cargo",
    createRoundedCargo: "createRoundedCargo",
    createManualCargo: "createManualCargo",
    changeManifestRequirement: "changeManifestRequirement",
    createCargoByGuides: "createCargoByGuides",
    createMassiveCargo: "createMassiveCargo",
    createCargoFromRoute: "createCargoFromRoute",
    cargoRequest: "cargoRequest",
    cargoRequestNegotiation: "cargoRequestNegotiation",
    cargosInRoute: "cargosInRoute",
    scheduledCargo: "scheduledCargo",
    cargosInNegotiation: "cargosInNegotiation",
    finishedCargos: "finishedCargos",
    reports: "reports",
    searchCargo: "searchCargo",
    cargoItemFinancier: "cargoItemFinancier",
    registerLocation: "registerLocation",
    showManifestError: "showManifestError",
    rateCargo: "rateCargo",
    uploadExternalManifest: "uploadExternalManifest",
    editTravelExpenses: "editTravelExpenses",
    readDriverDetails: "readDriverDetails",
    readManifest: "readManifest",
    watchLiveGPS: "watchLiveGPS",
    modifyShippingCost: 'modifyShippingCost',
    readCargoCompliments: "readCargoCompliments",
    readDeletedCargoCompliments: "readDeletedCargoCompliments",
    editCargoCompliments: "editCargoCompliments",
    complimentsFingerprint: "complimentsFingerprint",
    uploadEvidences: 'uploadEvidences',
    uploadExtraDocumentsCargo: 'uploadExtraDocumentsCargo',
    confirmLicensePlate: 'confirmLicensePlate',
    assignLicensePlate: 'assignLicensePlate',
    startCargo: 'startCargo',
    removeCargo: 'removeCargo',
    cancelCargo: 'cancelCargo',
    approvePayments: 'approvePayments',
    expiredCargo: 'expiredCargo',
    showRateCargo: 'showRateCargo',
    showTotalCostCargo: 'showTotalCostCargo',
    showPaymentCargo: 'showPaymentCargo',
    showAnomaliesCargo: 'showAnomaliesCargo',
    restoreCargo: 'restoreCargo',
    finishCargo: 'finishCargo',
    changeCompany: 'changeCompany',
    reportAnomalies: 'reportAnomalies',
    changeRteica: 'changeRteica',
    transportedValueSemaphore: 'transportedValueSemaphore',
    paymentSemaphore: 'paymentSemaphore',
    approveSemaphore: 'approveSemaphore',
    showBillingData: 'showBillingData',
    deletedCargo: 'deletedCargo',
    changeRateFullAccess: 'changeRateFullAccess',
    changeCargoLoadDate: 'changeCargoLoadDate',
    additionalCostCreate: 'additionalCostCreate',
    additionalCostUpdate: 'additionalCostUpdate',
    additionalCostDelete: 'additionalCostDelete',
    showCostCargo: 'showCostCargo',
    editAnomalies: 'editAnomalies',
    continueTripWithoutEvidences: "continueTripWithoutEvidences",
    removeEvidences: "removeEvidences",
    editCargo: "editCargo",
    assignVehicleFullAccess: "assignVehicleFullAccess",
    advanceRequest: "advanceRequest",
    useCarNearExpirationDocuments: "useCarNearExpirationDocuments",
    approveBalanceDriver: "approveBalanceDriver",
    specialLicensePlate: "specialLicensePlate",
    confirmationAdditionalMandatoryServices:
      "confirmationAdditionalMandatoryServices",
    editWeightConsignment: "editWeightConsignment",
    editCargoAmount: "editCargoAmount",
    bonusAndDiscountsInCargoRejected: "bonusAndDiscountsInCargoRejected",
    sendEmailReportsLastLocations: "sendEmailReportsLastLocations",
    createCargosWithoutMinimumUtility: "createCargosWithoutMinimumUtility",
    showCreationDateCargo: "showCreationDateCargo",
    editCargoTags: "editCargoTags",
    restartCargo: "restartCargo",
    approveCargoUnrestricted: "approveCargoUnrestricted",
    removeCargoUnrestricted: "removeCargoUnrestricted",
    removeCargoWithPayments: "removeCargoWithPayments",
    createRequestFromManualCreationCargo: "createRequestFromManualCreationCargo",
    createCargoFromRequest: "createCargoFromRequest",
    loadDownloadStatePoints: "loadDownloadStatePoints",
    trackingReports: "trackingReports",
    addDocumentsSupport: "addDocumentsSupport",
    saveDriverDocuments: "saveDriverDocuments",
    sendEmailReport: "sendEmailReport",
    editAdvance: "editAdvance",
    deletePayment: "deletePayment",
    deleteBalancePayment: "deleteBalancePayment",
    editTypeVehicle: "editTypeVehicle",
    addObservation: "addObservation",
    earlyUploadEvidences: "earlyUploadEvidences",
    readVehicleDetails: "readVehicleDetails",
    turn: "turn",
    turnCreation: "turnCreation",
    editTurn: "editTurn",
    deleteTurn: "deleteTurn",
    viewAvailableLoads: "viewAvailableLoads",
    assignAvailableLoads: "assignAvailableLoads",
    viewTurnVehicles: "viewTurnVehicles",
    assignTurnVehicles: "assignTurnVehicles",
    additionalServicesWithoutUtilityRestriction: "additionalServicesWithoutUtilityRestriction",
    viewApproveInfo: "viewApproveInfo",
    approvedBelowSicetac: "approvedBelowSicetac",
    evidencesFingerprint: "evidencesFingerprint",
    startServiceWithArlExpired: "startServiceWithArlExpired",
    startServiceWithEpsExpired: "startServiceWithEpsExpired",
    enableSimultaneousInrouteLoading: "enableSimultaneousInrouteLoading",
    useCarWithExpiredDocuments: "useCarWithExpiredDocuments",
    payWithTravelExpenses: "payWithTravelExpenses",
    changeApprovedTravelExpenses: "changeApprovedTravelExpenses",
    createBonusOrPenaltyToCargo: "createBonusOrPenaltyToCargo",
    serviceRequests: "serviceRequests"
  },
  payments: {
    module: "payments",
    approveBankAdvance: "approveBankAdvance",
    approveBankBalance: "approveBankBalance",
    approvePayments: "approvePayments",
    charges: "charges",
    createCostCenter: "createCostCenter",
    associateCostCenter: "associateCostCenter",
    paymentExtraAdvance: "paymentExtraAdvance",
    billingXML: "billingXML",
    reverseApproval: "reverseApproval",
    paymentBalance: "paymentBalance",
    paymentAdvance: "paymentAdvance",
    paymentCargo: "paymentCargo",
    generateAccountingFiles: 'generateAccountingFiles',
    downloadJournalCargoPDF: 'downloadJournalCargoPDF',
    generateJournalCargo: 'generateJournalCargo',
    editSimpleRegimen: "editSimpleRegimen",
    modifyAdditionalCostBilled: "modifyAdditionalCostBilled",
    modifyAdditionalCostpayed: "modifyAdditionalCostpayed",
    addAdditionalServiceCargoPaid: "addAdditionalServiceCargoPaid",
    addAdditionalServiceCargoCashed: "addAdditionalServiceCargoCashed",
    addAdditionalServiceCargoApproved: "addAdditionalServiceCargoApproved",
    editRateCargoUnrestricted: 'editRateCargoUnrestricted',
    fastPaymentRequest: "fastPaymentRequest",
    adminReceivable: "adminReceivable",
    createReceivable: "createReceivable",
    deleteReceivable: "deleteReceivable",
    viewPaymentsReceivables: "viewPaymentsReceivables",
    billingWithFlexibilityChoice: "billingWithFlexibilityChoice",
    unrestrictedAdvancePayments: "unrestrictedAdvancePayments",
    unrestrictedAdvancePaymentsRequest: "unrestrictedAdvancePaymentsRequest"
  },
  guide: {
    module: "guide",
    loadGuides: "loadGuides",
    changeStateGuides: "changeStateGuides",
    createGuidesFromExcel: "createGuidesFromExcel",
    generateGuides: "generateGuides",
    trackGuide: "trackGuide",
  },
  point: {
    module: "point",
    createPoint: "createPoint",
    listPoints: "listPoints",
    removePoint: "removePoint",
    updatePoint: "updatePoint",
  },
  routes: {
    module: "routes",
    routePlanning: "routePlanning",
    routeSites: "routeSites"
  },
  setting: {
    module: "setting",
    companyDocuments: "companyDocuments",
    changePassword: "changePassword",
    termsAndConditions: "termsAndConditions",
    privacyPolicy: "privacyPolicy",
    personalDataPolicies: "personalDataPolicies",
    habeasData: "habeasData",
    termsManifest: "termsManifest",
    bascPolicies: "bascPolicies",
    quickPay: "quickPay"
  },
  helpDesk: {
    module: "help-desk",
    createTicket: "createTicket",
    listTicket: "listTicket",
    replyTechnicalTicket: "replyTechnicalTicket",
    replyWebTicket: "replyWebTicket",
    replyMobileTicket: "replyMobileTicket",
    replyOtherTicket: "replyOtherTicket",
    reopenTicket: "reopenTicket",
  },
  monitoring: {
    module: "monitoring",
    monitorlogging: "monitorlogging",
    fingerprints: "fingerprintslogging",
  },
  analytics: {
    module: "analytics",
    analyticsOperations: "analyticsOperations",
    vehicleAnalytics: "vehicleAnalytics",
    frequentRoutes: "frequentRoutes",
    dispatch: "dispatch",
    stopedCargos: "stopedCargos",
    analyticsBilling: "analyticsBilling",
  },
  notifications: {
    module: "notifications",
    negotiationNotifications: "negotiationNotifications",
    trackingNotifications: "trackingNotifications",
    delayBetweenPointsNotifications: "delayBetweenPointsNotifications",
    userDeactivateNotifications: "userDeactivateNotifications",
    createCargoNotifications: "createCargoNotifications",
    geofenceNotifications: "geofenceNotifications",
    panicNotifications: "panicNotifications",
  },
  routing: {
    module: "routing",
    createRouting: "createRouting",
    routingList: "routingList",
  },
};

export const PermissionJLRB = {
  administration: {
    module: "administration",
    adminVehicles: "adminVehicles",
    adminUsers: "adminUsers",
    adminRoles: "adminRoles",
    adminTrailers: "adminTrailers",
    disableEnableUsers: "disableEnableUsers",
    disableEnableDriver: "disableEnableDriver",
    createRoles: "createRoles",
    updateRoles: "updateRoles",
    changeRole: "changeRole",
    modifyBasicFieldsUser: "modifyBasicFieldsUser",
    validateDocumentsPendingDrivers: "validateDocumentsPendingDrivers",
    showSecurityAnalysis: "showSecurityAnalysis",
    activateByScore: "activateByScore",
    checkUser: "checkUser",
    updateVehicle: "updateVehicle",
    createVehicle: "createVehicle",
    downloadFileRegistreBank: "downloadFileRegistreBank",
    activateVehicle: "activateVehicle",
    validateAndRemarkVehicleReferences: "validateAndRemarkVehicleReferences",
    forceVehicleActivation: "forceVehicleActivation",
    editOwnerVehicle: "editOwnerVehicle",
    editGpsVehicle: "editGpsVehicle",
    deactivateVehicle: "deactivateVehicle",
    editAdminVehicle: "editAdminVehicle",
    validateVehicleDocuments: "validateVehicleDocuments",
    editReferencesVehicle: "editReferencesVehicle"
  },
  cargo: {
    module: "cargo",
    readDriverDetails: "readDriverDetails",
    addDocumentsSupport: "addDocumentsSupport",
    saveDriverDocuments: "saveDriverDocuments",
    updateBankAdvance: "updateBankAdvance",
    updateBankBalance: "updateBankBalance"
  },
  payments: {
    module: "payments",
    editSimpleRegimen: "editSimpleRegimen",
    approveBankAdvance: "approveBankAdvance",
    approveBankBalance: "approveBankBalance"
  }
};
