import { Directive, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '../interfaces/role';
import { User } from '../interfaces/user';
import { Global } from '../resources/global';
import { AuthService } from '../services/authentication.service';
import * as _ from 'lodash';
import { NavItem } from '../interfaces/navItem';
import { RoleService } from '../services/role.service';
import { FunctionalitiesModuleRole } from '../interfaces/functionalitiesModuleRole';
import { ModuleRole } from '../interfaces/moduleRole';
import { PermissionRole } from '../resources/permission-role';

@Directive({
    selector: '[permission-tab]'
})
export class PermissionTabDirective implements OnInit {

    @Input() permissionModule: string;
    @Input() permissionListName: string[];
    roleUser: Role;
    alreadyClick: boolean;
    constructor(
        private elementRef: ElementRef,
        private roleService: RoleService,
        private authService: AuthService,
        private global: Global,
        private renderer: Renderer2,
        private permissionRole: PermissionRole
    ) {
    }

    ngOnInit() {


    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngAfterViewInit() {

        this.roleUser = this.roleService.getRoleUser();

        if (this.permissionModule && this.permissionListName && this.permissionListName.length) {
            try {
                this.permissionListName.map((permission, i) => {
                    if (!this.permissionRole.hasPermission(this.permissionModule, permission)) {
                        this.renderer.setStyle(
                            this.elementRef.nativeElement.children[0].children[1].children[0].children[0].children[i],
                            'display',
                            'none'
                        );
                        this.renderer.setStyle(
                            this.elementRef.nativeElement.children[1].children[i],
                            'display',
                            'none'
                        );
                    } else {
                        if (!this.alreadyClick) {
                            this.elementRef.nativeElement.children[0].children[1].children[0].children[0].children[i].click();
                        }
                        this.alreadyClick = true;
                    }
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

}
