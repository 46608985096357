import { Validators } from "@angular/forms";
import { type } from "os";
import { Patterns } from "../resources/patterns";

export interface CreateDiscountsAndBonuses {
  type?: string;
  name?: string;
  id?: string;
}

export class CreateDiscountsAndBonusesModel {

  public model: CreateDiscountsAndBonuses = {
    type: '',
    name: '',
    id: '',
  };
  public modelValidators = {
    type: [
      Validators.required
    ],
    name: [
      Validators.required, Validators.minLength(6)
    ]
  }

  constructor(
    private patterns: Patterns
  ) { }
}
