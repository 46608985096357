import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-city-location-form',
  templateUrl: './city-location-form.component.html',
  styleUrls: ['./city-location-form.component.scss']
})
export class CityLocationFormComponent implements OnInit {
  public location: google.maps.LatLngLiteral;
  public control: FormControl = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CityLocationFormComponent>,
  ) { }

  ngOnInit() { }

  onLocation(e) {
    this.location = e.location;
  }

  confirm() {
    if (!!this.location && !!this.location.lat && !!this.location.lng) {
      const city = {
        id: this.data.city.id,
        name: this.data.city.name,
        location: this.location
      }
      this.dialogRef.close(city);
    }
  }
}
