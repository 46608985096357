import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../list-vehicles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Company } from 'src/app/core/interfaces/company';
import { BankAccount } from 'src/app/core/interfaces/userBank';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { FormControl } from '@angular/forms';
import { Patterns } from 'src/app/core/resources/patterns';
import { SelectCompanyComponent } from 'src/app/shared/select-company/select-company.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ConventionsComponent } from 'src/app/shared/conventions/conventions.component';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-list-vehicles',
  templateUrl: './list-vehicles.component.html',
  styleUrls: ['./list-vehicles.component.scss'],
  providers: [AuthService, PermissionRole]
})
export class ListVehiclesComponent implements OnInit {
  @ViewChild(SelectCompanyComponent, { static: false }) selectComp: SelectCompanyComponent;
  showAllCompanies: boolean;
  permission = Permission;
  licensePlate: string;
  companyNitCtrl: FormControl = new FormControl();
  company: Company;
  filterlicensePlate: string = "";
  vehicleState: boolean = null;
  stateAccountBanck: string = '';
  stateAccount: string = '';
  activeSelectItem = false;
  itemsSelecteds: Array<Vehicle> = [];
  freightList: Array<Vehicle> = [];
  typeregister: 'balance' | 'advance';
  listStateAccountBank = [
    {
      value: '0',
      name: 'Todos'
    },
    {
      value: '1',
      name: 'Anticipo Registrado'
    },
    {
      value: '2',
      name: 'Saldo Registrado'
    },
    {
      value: '3',
      name: 'Anticipo y Saldo Registrado'
    },
    {
      value: '5',
      name: 'Anticipo sin Registrar'
    },
    {
      value: '6',
      name: 'Saldo sin Registrar'
    },
    {
      value: '4',
      name: 'Anticipo y Saldo sin Registrar'
    }

  ];
  optionsCompany = {
    title: 'Compañía',
    initialNit: ''
  };
  constructor(
    private authService: AuthService,
    public service: VehiclesService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private router: Router,
    public permissionRole: PermissionRole,
    private patterns: Patterns,
  ) { }

  /**
  * @description Initializes optionsCompany, resets the paginationlist and executes getVehicles
  */
  ngOnInit(): void {
    this.showAllCompanies = this.authService.userIsFromRootNit();
    this.optionsCompany['optionAll'] = this.showAllCompanies;
    this.optionsCompany.initialNit = this.getInitNit();
    this.service.resetPaginationList();
    this.getVehicles();
  }

  /**
  * @returns {string | null} returns the user's company nit if doesn't belong to a principal company, null otherwise 
  * @description Gets the initial nit if user doesn't belong to a principal company
  */
  private getInitNit(): string | null {
    if (!this.showAllCompanies) return this.authService.getCompany().companyId;
    return null;
  }

  /**
  * @param {string} licencePlate is the value of the filter by license plate
  * @param {string} bankAccountState is the value of the filter by bank account's state
  * @param {boolean} vehicleState is the value of the filter by vehicle's state
  * @description Gets the list of vehicles with filters applied and assigns it into service.paginationList's list
  */
  private getVehicles(licencePlate?: string, bankAccountState?: string, vehicleState?: boolean): void {
    const nit: string = this.companyNitCtrl && this.companyNitCtrl.value && this.companyNitCtrl.value.companyId ? this.companyNitCtrl.value.companyId : this.getInitNit();
    const holderCompany: string = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
    this.spinner.show();
    this.service.getListVehicles(
      nit,
      holderCompany,
      this.service.paginationList.getPageKey(),
      this.service.paginationList.getPageSize(),
      licencePlate,
      bankAccountState,
      vehicleState
    ).subscribe(
      (success: []) => {

        if (!this.utils.isDefined(success) || success.length < 1) {
          this.service.paginationList.setEnablePagingScroll(false);
        } else {
          if (this.service.paginationList.getList().length) {
            this.service.paginationList.setList(
              this.service.paginationList.getList().concat(success));
          } else {
            this.service.paginationList.setList(success);
          }
          if (success.length < this.service.paginationList.getPageSize()) {
            this.service.paginationList.setEnablePagingScroll(false);
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.service.paginationList.setList([]);
        this.spinner.hide();
      }
    );
  }

  /**
  * @param {'balance'|'advance'} type is the type of the bank account
  * @description Gets the list of vehicles with the param bank account type, and passes it to "getFileForInscriptionAccount" method
  */
  getlistBankAccount(type: 'balance' | 'advance'): void {
    let listObject: Array<BankAccount> = [];
    let vehiclesWithoutAccount: string[] = [];
    this.typeregister = type;
    if (type === 'advance') {
      listObject = this.itemsSelecteds.map((accounts: Vehicle) => {
        if (!accounts.bankAccountAdvance) vehiclesWithoutAccount.push(accounts.id);
        const data = {
          ...accounts.bankAccountAdvance,
          licensePlate: accounts.id
        };
        return data;
      });
    } else {
      listObject = this.itemsSelecteds.map((accounts) => {
        if (!accounts.bankAccountBalance) vehiclesWithoutAccount.push(accounts.id);
        const data = {
          ...accounts.bankAccountBalance,
          licensePlate: accounts.id
        };
        return data;
      });
    }
    if (vehiclesWithoutAccount.length) {
      this.snackBarService.openSnackBar(
        'Los vehículos ' + vehiclesWithoutAccount.join(', ') + " no tienen diligenciada la información bancaría completa", undefined, 'error'
      );
      return;
    }
    this.getFileForInscriptionAccount(listObject, this.typeregister);

  }

  /**
  * @param {BankAccount[]} data is the list of bank accounts to filter
  * @param {'balance'|'advance'} type is the type of the bank account
  * @description Gets the file for inscription account and downloads it
  */
  private getFileForInscriptionAccount(data?: BankAccount[], type?: 'balance' | 'advance') {

    this.service.getFileForInscriptionAccount(data, type).subscribe(
      (success) => {
        this.applyFilter();
        this.spinner.hide();
        this.snackBarService.openSnackBar('Se descargará el archivo en breve');
        this.utils.downloadFile(success.body, 'inscripcion de-' + (type === 'balance' ? 'Saldo' : 'Anticipo') + '-Cuentas-' + DateManager.dateToString(new Date(), 'YYYY-MM-DD'));
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al enviar la informacion', undefined, 'error');
      }
    );
  }

  /**
  * @description Updates the paginationList variables and executes "getVehicles" method
  */
  onScrollDown() {
    if (this.service.paginationList.getEnablePagingScroll()) {
      this.service.paginationList.setPageKey(
        this.service.paginationList.getPageKey() + 1
      );
      this.getVehicles();
    }
  }

  /**
  * @description Resets the paginationList variables
  */
  private resetValuesPagingScroll() {
    this.service.paginationList.setEnablePagingScroll(true);
    this.service.paginationList.setList([]);
    this.service.paginationList.setPageKey(1);
  }

  /**
  * @description Validates if the license plate filter is correct, if it is make a search resetValuesPagingScroll and getVehicles methods
  */
  applyFilter() {
    if (!this.validatePlate(this.filterlicensePlate)) {
      this.snackBarService.openSnackBar("Formato de placa incorrecto", undefined, 'alert');
      return;
    }
    this.itemsSelecteds = [];
    this.stateAccount = this.stateAccountBanck;
    this.resetValuesPagingScroll();
    this.getVehicles(this.filterlicensePlate, this.stateAccountBanck, this.vehicleState);
  }

  /**
  * @description Cleans all filters and executes resetValuesPagingScroll and getVehicles methods
  */
  cleanFilter() {
    this.itemsSelecteds = [];
    this.companyNitCtrl.setValue({
      nit: null,
      businessName: 'Todas'
    });
    this.selectComp.companyCtrl.setValue('');
    this.stateAccountBanck = '0';
    this.filterlicensePlate = "";
    this.vehicleState = null;
    this.resetValuesPagingScroll();
    this.getVehicles();
  }

  /**
  * @description Navigates to vehicles/create
  */
  goToCreate() {
    this.router.navigate(['administration/vehicles/create']);
  }

  /**
  * @param {{ vehicle: Vehicle, selected: boolean }} $event is the vehicle selected from vehicle-item
  * @description If the $event.selected is true makes a push of the vehicle into itemsSelected array, otherwise removes the vehicle from array
  */
  onSelectItem($event: { vehicle: Vehicle, selected: boolean }): void {
    if ($event.selected && !this.itemsSelecteds.includes($event.vehicle)) {
      this.itemsSelecteds.push($event.vehicle);
    }
    else if (this.itemsSelecteds.includes($event.vehicle) && !$event.selected) {
      let index = this.itemsSelecteds.indexOf($event.vehicle);
      if (index !== -1) this.itemsSelecteds.splice(index, 1);
    }
  }

  /**
  * @param {string} plate is the license plate to check
  * @description Checks the validity of plate param as license plate
  */
  private validatePlate(plate: string): boolean {
    return this.patterns.GET_REGEX('LICENSE_PLATES').test(plate);
  }

  /**
  * @description Opens a dialog with the vehicle's conventions
  */
  openConventions(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle: true
    };
    dialogConfig.autoFocus = false;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(ConventionsComponent, dialogConfig);
  }

  /**
  * @description Cleans the service's vehicleSelected
  */
  ngOnDestroy(): void {
    this.service.vehicleSelected = null;
  }
}
