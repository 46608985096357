<div class="modal-body wrapper-modal main-container">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="title">{{data?.title}}</div>
  <form>
    <mat-card-content>
      <div class="md-form">
        <label class="label-field" *ngIf="data?.inspect?.id && data.inspect.approval">Aprobado por:</label>
        <label class="label-field" *ngIf="!data?.inspect?.id  || ( data?.inspect?.id && !data.inspect.approval)">
          Creado por:
        </label>
        <input type="text" class="form-control" [value]="userInformation" readonly>
      </div>
    </mat-card-content>
  </form>
  <div class="container-checklist">
    <form [formGroup]="form" class="mb-3">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Contenedor No<span class="asterisk">*</span></mat-label>
            <input matInput formControlName="container">
            <mat-error *ngIf="form.get('container').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Sello Indicativo<span class="asterisk">*</span></mat-label>
            <input matInput formControlName="indicativeSeal">
            <mat-error *ngIf="form.get('indicativeSeal').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Sello de Seguridad<span class="asterisk">*</span></mat-label>
            <input matInput formControlName="safetySeal">
            <mat-error *ngIf="form.get('safetySeal').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Patio<span class="asterisk">*</span></mat-label>
            <input matInput formControlName="courtyard">
            <mat-error *ngIf="form.get('courtyard').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Naviera<span class="asterisk">*</span></mat-label>
            <input matInput formControlName="shippingCompany">
            <mat-error *ngIf="form.get('shippingCompany').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="opcion-decription">
      <div *ngFor="let kvoption of options | keyvalue:originalOrder">{{kvoption.key}} = {{kvoption.value}} </div>
    </div>
    <mat-card-content class="container-options">
      <div class="content-form" *ngFor="let element of checkLists; let i = index;">
        <label class="label-field font">
          {{questions[element.question]}}
        </label>
        <mat-radio-group class="radio-group" [(ngModel)]="element.response">
          <mat-radio-button *ngFor=" let option of options | keyvalue:originalOrder" [value]="option.key"
            [disabled]="!!data?.inspect?.id">
            {{option.key}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-card-content>
    <mat-card-content class="container-options">
      <div class="content-form">
        <label class="label-field font">
          ¿La unidad escogida presenta alguna alteración física?
        </label>
        <form [formGroup]="form">
          <mat-radio-group class="radio-group" formControlName="altered">
            <mat-radio-button [value]="true" [disabled]="!!data?.inspect?.id">Sí</mat-radio-button>
            <mat-radio-button [value]="false" [disabled]="!!data?.inspect?.id">No</mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
    </mat-card-content>
    <mat-card-content class="container-options">
      <div class="content-form">
        <label class="label-field font">
          Tamaño
        </label>
        <mat-radio-group class="radio-group" [(ngModel)]="tmpsize">
          <mat-radio-button [value]="20" [disabled]="!!data?.inspect?.id">20"</mat-radio-button>
          <mat-radio-button [value]="40" [disabled]="!!data?.inspect?.id">40"</mat-radio-button>
          <mat-radio-button [value]="-1" [disabled]="!!data?.inspect?.id">Otro</mat-radio-button>
        </mat-radio-group>

        <form [formGroup]="form">
          <mat-form-field appearance="standard" [hidden]="tmpsize !== -1">
            <mat-label>¿Cual?</mat-label>
            <input type="number" matInput min="1" step="1"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57" name="size" formControlName="size" />
          </mat-form-field>
        </form>
      </div>
    </mat-card-content>
    <mat-card-content class="container-options">
      <div class="content-form">
        <label class="label-field font">
          Clase
        </label>
        <form [formGroup]="form">
          <mat-radio-group class="radio-group" formControlName="classType">
            <mat-radio-button *ngFor=" let option of classTypes | keyvalue:originalOrder" [value]="option.key"
              [disabled]="!!data?.inspect?.id">
              {{option.value}}
            </mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
    </mat-card-content>
    <div *ngIf="containerStatus.length > 0">
      <hr>
      <h3>Estado del contenedor</h3>
      <div class="row" *ngFor="let status of containerStatus">
        <div class="col">
          <div class="row damangeRegister">
            <div class="col-md-4"><b>Ubicación:</b> {{status.containerLocation}}</div>
            <div class="col-md-4"><b>Tipo:</b> {{status.damageType}}</div>
            <div class="col-md-4"><b>Dimensión:</b> {{status.damageDimension}}</div>
            <div class="col-12"><b>Observación:</b> {{status.damageObs}}</div>
          </div>
        </div>
      </div>
    </div>
    <form #formDirective="ngForm" [formGroup]="damageForm" class="row align-items-center"
      (submit)="AttachDamangeObs(formDirective)" *ngIf="!data?.inspect?.id">
      <mat-form-field class="col-md-4" appearance="standard">
        <mat-label>Ubicación</mat-label>
        <mat-select name="containerLocation" formControlName="containerLocation">
          <mat-option *ngFor="let damageOption of containerLocationDamageOptions" [value]="damageOption">
            {{ damageOption }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="damageForm.get('containerLocation').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-4" appearance="standard">
        <mat-label>Tipo de Daño</mat-label>
        <mat-select matNativeControl name="damangeType" formControlName="damageType">
          <mat-option *ngFor="let damageTypeOption of damageTypeOptions" [value]="damageTypeOption.value">{{
            damageTypeOption.label }}</mat-option>
        </mat-select>
        <mat-error *ngIf="damageForm.get('damageType').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-4" appearance="standard">
        <mat-label>Dimensiones</mat-label>
        <input type="text" matInput name="damangeDimension" formControlName="damageDimension" />
        <mat-error *ngIf="damageForm.get('damageDimension').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
      <figure class="col-md-12 mt-2">
        <img src="../../../../assets/img/container-inspection-damage.png" width="100%">
      </figure>
      <mat-form-field class="col-md-12 mb-2" appearance="outline">
        <mat-label>Observaciones del daño</mat-label>
        <textarea matInput name="damangeObs" formControlName="damageObs"></textarea>
        <mat-error *ngIf="damageForm.get('damageObs').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="damageForm.get('damageObs').errors?.minlength">
          {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'observaciones', 8) }}
        </mat-error>
      </mat-form-field>
      <div class="col-12 text-center">
        <button mat-raised-button color="primary" class="button-width-auto" type="submit">Agregar Daño</button>
      </div>
    </form>
    <hr>
    <form class="row form_container" [formGroup]="form">
      <mat-form-field class="col-md-12" appearance="outline">
        <mat-label>Observación<span class="asterisk">*</span></mat-label>
        <textarea matInput formControlName="evaluatorObservation"></textarea>
        <mat-error *ngIf="form.get('evaluatorObservation').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('evaluatorObservation').errors?.minlength">
          {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'observaciones', 8) }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <!-- Btn-->
  <mat-card-actions>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id" class="button-width-auto"
      (click)="downloadInspection()">
      Descargar
    </button>
    <button mat-raised-button color="primary" *ngIf="!data?.inspect?.id" class="button-width-auto"
      (click)="saveInspection()">
      Guardar
    </button>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id && !data.inspect.approval"
      class="primary-secondary button-width-auto" (click)="confirmRejection()">
      Rechazar
    </button>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id && !data.inspect.approval"
      class="button-width-auto" (click)="approve(true)">
      Aprobar
    </button>
  </mat-card-actions>
</div>