import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CargoReport } from "../interfaces/cargoReport";
import { Endpoints } from "../resources/endpoints";
import { Utils } from "../resources/utils";

@Injectable({ providedIn: 'root' })
export class CargoReportService {

  constructor(
    private http: HttpClient,
    private endpoints: Endpoints,
    public utils: Utils
  ) { }

  public getReport(paramsReport: CargoReport): Observable<any> {
    let params = new HttpParams();
    let options: any = {};

    if (!this.utils.isEmpty(paramsReport.reportType)) {
      params = params.append('reportType', paramsReport.reportType);
    }
    if (!this.utils.isEmpty(paramsReport.driver)) {
      params = params.append('driver', paramsReport.driver);
    }
    if (!this.utils.isEmpty(paramsReport.licensePlate)) {
      params = params.append('licensePlate', paramsReport.licensePlate);
    }
    if (!this.utils.isEmpty(paramsReport.isExcel)) {
      params = params.append('isExcel', paramsReport.isExcel.toString());
      options.observe = 'response';
      options.responseType = 'blob';
    }

    options.params = params;

    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpoints.getReports}`,
      options
    );
  }

  public getTrips(response): Array<any> {
    let trips = [];

    if (!this.utils.isEmpty(response) && !this.utils.isEmpty(response.reports)) {
      trips = Object.keys(response.reports).map((key) => {
        return response.reports[key];
      });
    }

    return trips;
  }

}