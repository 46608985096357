import { Component, OnInit, EventEmitter, DoCheck, IterableDiffers, KeyValueDiffers, SimpleChanges, HostListener, Input, ElementRef, ViewChild, Output } from '@angular/core';
import { CollectionPoint } from 'src/app/core/interfaces/collectionPoint';
import { CollectionPointService } from '../collection-point.service';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'src/app/core/resources/permission';


@Component({
  selector: 'app-collection-point-item',
  templateUrl: './collection-point-item.component.html',
  styleUrls: ['./collection-point-item.component.scss']
})
export class CollectionPointItemComponent implements OnInit {

  @Input() point: CollectionPoint;
  @Output() onRefreshList = new EventEmitter();
  @ViewChild("modalConfirmDeletePoint", { static: false }) modalConfirmDeletePoint: ElementRef;
  instanceModalDeletePoint: NgbModalRef;
  permission = Permission;

  constructor(
    private collectionPointService: CollectionPointService,
    private router: Router,
    private snackBarService: SnackBarService,
    private modalService: NgbModal
  ) {

  }

  ngOnInit() {

  }

  selectCollectionPoint() {
    this.collectionPointService.collectionPointSelected = this.point;
    this.router.navigate(['/collection-point/detail']);
  }

  deleteCollectionPoint() {
    this.collectionPointService.deleteCollectionPoint(this.point.id).subscribe(
      (success) => {
        this.snackBarService.openSnackBar('Punto eliminado correctamente');
        this.hideModalConfirmDeletePoint();
        this.onRefreshList.emit();
      },
      (error) => {
        this.snackBarService.openSnackBar("Ocurrió un error eliminando el punto", undefined, 'error');
      }
    );
  }

  openModalConfirmDeletePoint() {
    this.instanceModalDeletePoint = this.modalService.open(this.modalConfirmDeletePoint, {
      centered: true
    });
  }

  hideModalConfirmDeletePoint() {
    this.instanceModalDeletePoint.close();
  }

}
