import { Component, Inject, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/core/interfaces/scenario';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RoutingService } from '../../routing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoutingMessages } from 'src/app/core/messages/routing-messages.enum';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';

@Component({
  selector: 'app-routing-address-form',
  templateUrl: './routing-address-form.component.html',
  styleUrls: ['./routing-address-form.component.scss'],
})
export class RoutingAddressFormComponent {
  @Input() form: FormGroup;
  constructor(
    public utils: Utils,
    public snackBarService: SnackBarService,
    private routingService: RoutingService,
    private matDialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }

  /**
  * @param {File} $event Is the file in format XLSX to create the clients array
  * @description Receives the file from input, creates the structure of form's clients and updates the form to pass to next step 
  */
  handleFileInput($event: File): void {
    if (!$event) return;
    this.spinner.show();
    this.routingService.getClientsFromExcel($event).subscribe(
      (response: { clients: Client[] }) => {
        this.spinner.hide();
        if (response && response.clients && response.clients.length)
          this.initFormFromClients(response.clients, $event);
        else
          this.snackBarService.openSnackBar(RoutingMessages.INVALID_FILE, undefined, 'error');
      },
      (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message) {
          const messages = error.error.message.split(";");
          this.showErrors(messages);
        }
        else this.snackBarService.openSnackBar(RoutingMessages.COLUMNS_MISSING, undefined, 'error');
        this.form.get('file').setValue(null);
      }
    )
  }

  /**
  * @param {string[]} errors Is the list of errors generated
  * @description Shows a modal indicating the errors generated
  */
  private showErrors(errors: string[]) {
    const config = new MatDialogConfig();
    config.data = {
      iconError: true,
      title: `Ocurrió un error al procesar la orden`,
      description: 'Por favor solucione los errores presentes en la orden: ',
      messageList: errors,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.matDialog.open(DialogComponent, config);
  }

  /**
  * @param {Client[]} clients Is the clients array to initializes the form's client control
  * @param {File} $event Is the file in format XLSX
  * @description Receives the file from input, creates the structure of form's clients and updates the form to pass to next step 
  */
  private initFormFromClients(clients: Client[], $event: File) {
    let clientsArr = new FormArray([]);
    clients.forEach(client => {
      if (client && client.address)
        clientsArr.push(new FormControl(client));
    });
    if (clientsArr.controls.length) {
      if (this.form.get('clients')) this.form.removeControl('clients');
      this.form.addControl('clients', clientsArr);
      this.form.get('file').setValue($event);
      this.form.get('showConfirmAddresses').setValue(true);
    }
  }

  /**
  * @description Verifies if the form's control "file" is valid to allow pass to next step
  */
  onSubmit(): void {
    if (this.form.get('file').invalid) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'ningún archivo'), undefined, 'alert');
      return;
    }
    this.form.get('showConfirmAddresses').setValue(true);
  }

  /**
  * @returns {boolean} returns true if the file is uploaded and the addresses array exists.
  * @description Verifies if the current step is valid
  */
  get isValidStep(): boolean {
    return !!this.form && !!this.form.get('file') && this.form.get('file').valid && !!this.form.get('clients');
  }


}
