import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/authentication.service';

@Pipe({
    name: 'taxesOwnFleet'
})
export class TaxesOwnFleetPipe implements PipeTransform {

    constructor(
        private authService: AuthService
    ) { }

    transform(value: number, vehicleIsOwnFleet: boolean = false): number {
        try {
            const company = this.authService.getCompany();

            if (company.chargeOwnFleet != null && !company.chargeOwnFleet) {
                if (vehicleIsOwnFleet) {
                    return 0;
                } else {
                    return value;
                }
            }
        } catch (e) {
            console.error(e);
        }
        return value;
    }

}
