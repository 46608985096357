export const INCIDENT_ASSETS = [
  { id: "1", name: "Me detuve a dormir", asset: "tl_ico__dormir_m.svg" },
  { id: "2", name: "Me detuve a comer", asset: "tl_ico__comer_m.svg" },
  { id: "3", name: "Cargando combustible", asset: "tl_ico__combustible_m.svg" },
  { id: "4", name: "Me pinché / varé", asset: "tl_ico__pinche_vare_c.svg" },
  { id: "5", name: "Tuve un accidente", asset: "tl_ico__accidente_c.svg" },
  { id: "6", name: "La vía está cerrada", asset: "tl_ico__via_cerrada_c.svg" },
  { id: "7", name: "Otro", asset: "tl_ico__otro_m.svg" },
  { id: "8", name: "Entregado", asset: "tl_ico__entregado_m.svg" },
  { name: "Sin novedad", asset: "tl_ico__sin_novedad_m.svg" },
  { name: "Sin Novedad", asset: "tl_ico__sin_novedad_m.svg" },
  { name: "Cargando", asset: "tl_ico__cargando_m.svg" },
  { name: "Descargando", asset: "tl_ico__descargando_m.svg" },
  { name: "Apagado", asset: "tl_ico__dormir_m.svg" },
];