import { Component, OnInit, EventEmitter, DoCheck, IterableDiffers, KeyValueDiffers, SimpleChanges, HostListener, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';

import { Global } from 'src/app/core/resources/global';
import { Router, ActivatedRoute } from '@angular/router';
import { ItemCargoFromGuidesService } from './item-cargo-from-guides.service';
import { from } from 'rxjs';
import { Observable } from 'object-observer/dist/object-observer.min.js';
import { PlatformLocation } from '@angular/common';
import { Guide } from 'src/app/core/interfaces/guide';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-item-cargo-from-guides',
  templateUrl: './item-cargo-from-guides.component.html',
  styleUrls: ['./item-cargo-from-guides.component.scss'],
  providers: [AuthService, Global]
})
export class ItemCargoFromGuidesComponent implements OnInit {

  @Input() guide: Guide;
  @Input() print: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public itemCargoFromGuidesService: ItemCargoFromGuidesService,
    private _authService: AuthService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit() {

  }

  changeValue(guide: Guide) {
    if (guide.checked) {
      if (guide.addressee && !guide.addressee['lat'] && !guide.addressee['lng']) {
        let geocode = new google.maps.Geocoder();
        geocode.geocode({ address: guide.addressee.address }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK && results.length) {
            guide.addressee['lat'] = results[0].geometry.location.lat();
            guide.addressee['lng'] = results[0].geometry.location.lng();
          } else {
            guide.addressee['lat'] = 0;
            guide.addressee['lng'] = 0;
          }
        });
      }
      if (guide.sender && !guide.sender['lat'] && !guide.sender['lng']) {
        let geocode = new google.maps.Geocoder();
        geocode.geocode({ address: guide.sender.address }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK && results.length) {
            guide.sender['lat'] = results[0].geometry.location.lat();
            guide.sender['lng'] = results[0].geometry.location.lng();
          } else {
            guide.sender['lat'] = 0;
            guide.sender['lng'] = 0;
          }
        });
      }
    }
  }

  printGuide(guideId: string) {
    this.spinner.show();
    this.itemCargoFromGuidesService.getGuidePDF(guideId).subscribe(
      (response) => {
        this.spinner.hide();
        var newBlob = new Blob([response], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = "Guia_" + guideId + ".pdf";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getIdSectionPrint(guideId): String {
    return "guidePrint" + guideId;
  }

}
