<div class="h-100 container-map" [ngClass]="{'dialog-container': dialogParams && dialogParams.clickMap}">
  <i *ngIf="dialogParams && dialogParams.clickMap" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>
  <button id="confirmChangesMap" (click)="applyChanges()" type="button"></button>
  <div *ngIf="dialogParams && dialogParams.clickMap" class="autocomplete-address-container">
    <app-autocomplete-address [title]="'Dirección'" [value]="dataMap?.address" [addOptions]="false"
      (emitToParent)="onSelectAddress($event)"></app-autocomplete-address>
  </div>

  <agm-map id="mapContainer" [zoom]="zoom" [latitude]="center.lat" [longitude]="center.lng"
    (mapClick)="onMapClick($event)">
    <agm-marker-cluster [imagePath]="pathClusterMarker">
      <agm-marker *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng"
        [iconUrl]="marker.iconUrl">
        <agm-info-window *ngIf="marker.data">
          <div *ngIf="marker.data?.anomaly">
            <b>{{marker.data?.anomaly}}</b>
          </div>
          <div *ngIf="marker.data?.date">
            <b>Fecha:</b> {{marker.data?.date | dateFormat}}
          </div>
          <div *ngIf="marker.data?.fingerprint">
            <b>Usuario:</b> {{marker.data?.fingerprint.userName}}
          </div>
          <div *ngIf="!utils.isEmpty(marker.data?.formWeb)">
            <b>{{marker.data?.formWeb ? 'Registro por Web' : 'Registro por Conductor'}}</b>
          </div>
        </agm-info-window>
      </agm-marker>
    </agm-marker-cluster>
    <agm-polyline [strokeColor]="polyline.strokeColor" *ngFor="let polyline of polylines">
      <div *ngIf="polyline.id === 'routeGoogle' || polyline.id === 'pointsHistorySimulate'">
        <agm-polyline-point *ngFor="let point of polyline.points" [latitude]="point.lat()" [longitude]="point.lng()">
        </agm-polyline-point>
      </div>
      <div *ngIf="polyline.id !== 'routeGoogle' && polyline.id !== 'pointsHistorySimulate'">
        <agm-polyline-point *ngFor="let point of polyline.points" [latitude]="point.latitude"
          [longitude]="point.longitude">
        </agm-polyline-point>
      </div>
    </agm-polyline>
  </agm-map>
  <div class="col-md-12 text-center">
    <mat-card-actions *ngIf="dialogParams && dialogParams.clickMap">
      <button mat-raised-button color="primary" type="button" class="button-width-auto" (click)="sendDataMapa()">
        Guardar
      </button>
    </mat-card-actions>
  </div>

</div>