<form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()">
  <mat-card>
    <div class="row title-2 text-strong color-secondary-dark">
      <div class="col-md-12">
        {{title}}
      </div>
    </div>
    <mat-card-content>
      <div class="row">
        <!-- documentType -->
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>Tipo identificación<span class="asterisk">*</span></mat-label>
            <input matInput type="text" name="brandTl" formControlName="documentTypeName"
              [matAutocomplete]="autocompleteDocumentType" autocomplete="off">
            <mat-autocomplete autoActiveFirstOption #autocompleteDocumentType="matAutocomplete"
              (optionSelected)="onChangeDocumentType($event)">
              <mat-option *ngFor="let type of documenTypes" [value]="type">
                {{type.name}}
              </mat-option>
            </mat-autocomplete>
            <span class="pattern pattern--invalid"
              *ngIf="form.get('documentTypeId').errors && form.get('documentTypeId').errors.required">
              Seleccione un tipo de documento
            </span>
          </mat-form-field>
        </div>
        <!-- document -->
        <div class="col-md-4">
          <app-input-document-user [inputFormControl]="documentControl" [options]="optionsDocumentUser"
            [validate]="validate">
          </app-input-document-user>
        </div>
        <!-- Name -->
        <div class="col-md-4">
          <mat-form-field appearance="standard">
            <mat-label>Nombre<span class="asterisk">*</span></mat-label>
            <input matInput type="text" name="tlInput" formControlName="name">
            <mat-error *ngIf="form.get('name').errors?.required">
              {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-error *ngIf="form.get('name').errors?.pattern">
              {{ utils.giveMessageError('INCORRECT_FORMAT', 'nombre') }}
            </mat-error>
          </mat-form-field>
        </div>
        <!-- City -->
        <div class="col-md-4" *ngIf="form.get('documentTypeId').value === '3'">
          <app-autocomplete-city [options]="optionsCity" [inputFormControl]="cityControl" [validate]="validate">
          </app-autocomplete-city>
        </div>
        <!-- Address -->
        <div class="col-md-4" *ngIf="form.get('documentTypeId').value === '3' && form.get('address')">
          <app-autocomplete-address [title]="'Dirección'" [simpleAddress]="true" [required]="true"
            (emitToParent)="onSelectAddress($event)"></app-autocomplete-address>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <button #formElement class="display-none" type="submit">
  </button>
</form>