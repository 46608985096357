import { ModuleRole } from "./moduleRole";
import { Permission } from "./../resources/permission";

export interface RoleComany {
  id?: string;
  nit: string;
  name: string;
  modules: ModuleRole[];
}

export class ModelRoleComany {

  adminDefaultCompany: RoleComany = {
    nit: null,
    name: "Administrador",
    modules: [
      {
        name: "administration",
        functionalities: [
          {
            name: "setupMonitorIntegration",
            visible: false
          },
          {
            name: "truoraConfiguration",
            visible: false,
          },
          {
            name: "updateBankBalance",
            visible: false,
          },
          {
            name: "adminCompanies",
            visible: false,
          },
          {
            name: "adminModules",
            visible: false,
          },
          {
            name: "changeRole",
            visible: false,
          },
          {
            name: "validateDocumentsPendingDrivers",
            visible: false,
          },
          {
            name: "showSecurityAnalysis",
            visible: false,
          },
          {
            name: "updateRoles",
            visible: false,
          },
          {
            name: "downloadFileRegistreBank",
            visible: false,
          },
          {
            name: "changeDriverVehicle",
            visible: false,
          },
          {
            name: "validateAndRemarkVehicleReferences",
            visible: false,
          },
          {
            name: "createVehicle",
            visible: false,
          },
          {
            name: "createTermsConditions",
            visible: false,
          },
          {
            name: "updateCompany",
            visible: false,
          },
          {
            name: "adminVehicles",
            visible: false,
          },
          {
            name: "adminTrailers",
            visible: false,
          },
          {
            name: "adminCities",
            visible: false,
          },
          {
            name: "createCompany",
            visible: false,
          },
          {
            name: "createRoles",
            visible: false,
          },
          {
            name: "updateVehicle",
            visible: false,
          },
          {
            name: "activateVehicle",
            visible: false,
          },
          {
            name: "activateByScore",
            visible: false,
          },
          {
            name: "adminRoles",
            visible: false,
          },
          {
            name: "showDocumentsPendingDrivers",
            visible: false,
          },
          {
            name: "adminUsers",
            visible: true,
          },
          {
            name: "disableEnableDriver",
            visible: false,
          },
          {
            name: "disableEnableUsers",
            visible: false,
          },
          {
            name: "adminTerms",
            visible: false,
          },
          {
            name: "checkUser",
            visible: false,
          },
          {
            name: "updateBankAdvance",
            visible: false,
          },
          {
            name: "showCreateGps",
            visible: false,
          },
          {
            name: "modifyBasicFieldsUser",
            visible: false,
          },
          {
            name: "showDeleteGps",
            visible: false,
          },
          {
            name: "editTags",
            visible: false,
          },
          {
            name: "editCompliments",
            visible: false,
          },
          {
            name: "createCompanyComplimentType",
            visible: false,
          },
          {
            name: "updateCity",
            visible: false,
          },
          {
            name: "linkCities",
            visible: false,
          },
          {
            name: "addSubCities",
            visible: false,
          },
          {
            name: "deleteSubCities",
            visible: false,
          },
          {
            name: "createEmailCompany",
            visible: false,
          },
          {
            name: "forceVehicleActivation",
            visible: false,
          },
          {
            name: "deactivateVehicle",
            visible: false,
          },
          {
            name: "validateVehicleDocuments",
            visible: false
          },
          {
            name: "editAdminVehicle",
            visible: false
          },
          {
            name: "editOwnerVehicle",
            visible: false
          },
          {
            name: "editGpsVehicle",
            visible: false
          },
          {
            name: "editReferencesVehicle",
            visible: false
          },
          {
            name: "reactivateVehicleByInactivity",
            visible: false
          },
          {
            name: "changeFeeToOwnedFleet",
            visible: false
          }
        ],
      },
      {
        name: "payments",
        functionalities: [
          {
            name: "charges",
            visible: false,
          },
          {
            name: "createCostCenter",
            visible: false
          },
          {
            name: "associateCostCenter",
            visible: false
          },
          {
            name: "paymentBalance",
            visible: false,
          },
          {
            name: "reverseApproval",
            visible: false,
          },
          {
            name: "paymentExtraAdvance",
            visible: false,
          },
          {
            name: "billingXML",
            visible: false,
          },
          {
            name: "approvePayments",
            visible: false,
          },
          {
            name: "approveBankAdvance",
            visible: false,
          },
          {
            name: "paymentCargo",
            visible: false,
          },
          {
            name: "uploadExtraDocumentsCargo",
            visible: false,
          },
          {
            name: "paymentAdvance",
            visible: false,
          },
          {
            name: "approveBankBalance",
            visible: false,
          },
          {
            name: "editSimpleRegimen",
            visible: false,
          },
          {
            name: "modifyAdditionalCostBilled",
            visible: false,
          },
          {
            name: "modifyAdditionalCostpayed",
            visible: false,
          },
          {
            name: "addAdditionalServiceCargoPaid",
            visible: false,
          },
          {
            name: "addAdditionalServiceCargoCashed",
            visible: false,
          },
          {
            name: "addAdditionalServiceCargoApproved",
            visible: false,
          },
          {
            name: "editRateCargoUnrestricted",
            visible: false,
          },
          {
            name: "fastPaymentRequest",
            visible: false,
          },
          {
            name: "adminReceivable",
            visible: false,
          },
          {
            name: "createReceivable",
            visible: false,
          },
          {
            name: "deleteReceivable",
            visible: false,
          },
          {
            name: "viewPaymentsReceivables",
            visible: false,
          },
          {
            name: "generateAccountingFiles",
            visible: false
          },
          {
            name: "billingWithFlexibilityChoice",
            visible: false
          },
          {
            name: "unrestrictedAdvancePayments",
            visible: false
          },
          {
            name: "unrestrictedAdvancePaymentsRequest",
            visible: false
          }
        ],
      },
      {
        name: "setting",
        functionalities: [
          {
            name: "changePassword",
            visible: true,
          },
          {
            name: "companyDocuments",
            visible: false,
          },
          {
            name: "termsAndConditions",
            visible: false,
          },
          {
            name: "privacyPolicy",
            visible: false,
          },
          {
            name: "personalDataPolicies",
            visible: false,
          },
          {
            name: "habeasData",
            visible: false,
          },
          {
            name: "termsManifest",
            visible: false,
          },
          {
            name: "bascPolicies",
            visible: false,
          },
          {
            name: "quickPay",
            visible: false,
          },
        ],
      },
      {
        name: "cargo",
        functionalities: [
          {
            name: "cancelCargo",
            visible: false,
          },
          {
            name: "uploadEvidences",
            visible: false,
          },
          {
            name: "readCargoCompliments",
            visible: false,
          },
          {
            name: "readDeletedCargoCompliments",
            visible: false,
          },
          {
            name: "editCargoCompliments",
            visible: false,
          },
          {
            name: "complimentsFingerprint",
            visible: false
          },
          {
            name: "approvePayments",
            visible: false,
          },
          {
            name: "confirmLicensePlate",
            visible: false,
          },
          {
            name: "modifyShippingCost",
            visible: false,
          },
          {
            name: "viewApproveInfo",
            visible: false
          },
          {
            name: "approvedBelowSicetac",
            visible: false
          },
          {
            name: "createMassiveCargo",
            visible: false,
          },
          {
            name: "createCargoFromRoute",
            visible: false,
          },
          {
            name: "createCargoByGuides",
            visible: false,
          },
          {
            name: "cargosInRoute",
            visible: true,
          },
          {
            name: "showTotalCostCargo",
            visible: false,
          },
          {
            name: "cargosInNegotiation",
            visible: false,
          },
          {
            name: "createManualCargo",
            visible: false,
          },
          {
            name: "changeManifestRequirement",
            visible: false,
          },
          {
            name: "searchCargo",
            visible: false,
          },
          {
            name: "createRoundedCargo",
            visible: false,
          },
          {
            name: "scheduledCargo",
            visible: true,
          },
          {
            name: "createCargoByRoute",
            visible: false,
          },
          {
            name: "entunamiento",
            visible: false,
          },
          {
            name: "cargoRequest",
            visible: false,
          },
          {
            name: "cargoRequestNegotiation",
            visible: false,
          },
          {
            name: "expiredCargo",
            visible: false,
          },
          {
            name: "deletedCargo",
            visible: true,
          },
          {
            name: "rateCargo",
            visible: true,
          },
          {
            name: "uploadExternalManifest",
            visible: false,
          },
          {
            name: "editTravelExpenses",
            visible: false,
          },
          {
            name: "readDriverDetails",
            visible: false,
          },
          {
            name: "readManifest",
            visible: false,
          },
          {
            name: "watchLiveGPS",
            visible: false,
          },
          {
            name: "assignLicensePlate",
            visible: false,
          },
          {
            name: "removeCargo",
            visible: false,
          },
          {
            name: "showRateCargo",
            visible: true,
          },
          {
            name: "createRequestFromManualCreationCargo",
            visible: true,
          },
          {
            name: "createCargoFromRequest",
            visible: true,
          },
          {
            name: "cargoItemFinancier",
            visible: false,
          },
          {
            name: "reports",
            visible: true,
          },
          {
            name: "finishedCargos",
            visible: true,
          },
          {
            name: "startCargo",
            visible: false,
          },
          {
            name: "registerLocation",
            visible: false,
          },
          {
            name: "showManifestError",
            visible: false
          },
          {
            name: "showPaymentCargo",
            visible: false,
          },
          {
            name: "showAnomaliesCargo",
            visible: false,
          },
          {
            name: "restoreCargo",
            visible: false,
          },
          {
            name: "editCargo",
            visible: true,
          },
          {
            name: "finishCargo",
            visible: false,
          },
          {
            name: "changeCompany",
            visible: false,
          },
          {
            name: "reportAnomalies",
            visible: false,
          },
          {
            name: "changeRteica",
            visible: false,
          },
          {
            name: "transportedValueSemaphore",
            visible: false,
          },
          {
            name: "paymentSemaphore",
            visible: false,
          },
          {
            name: "approveSemaphore",
            visible: false,
          },
          {
            name: "showBillingData",
            visible: false,
          },
          {
            name: "changeRateFullAccess",
            visible: false,
          },
          {
            name: "advanceRequest",
            visible: false,
          },
          {
            name: "additionalCostCreate",
            visible: false,
          },
          {
            name: "additionalCostUpdate",
            visible: false,
          },
          {
            name: "additionalCostDelete",
            visible: false,
          },
          {
            name: "useCarNearExpirationDocuments",
            visible: false,
          },
          {
            name: "changeCargoLoadDate",
            visible: false,
          },
          {
            name: "showCostCargo",
            visible: false,
          },
          {
            name: "approveBalanceDriver",
            visible: true,
          },
          {
            name: "specialLicensePlate",
            visible: false,
          },
          {
            name: "confirmationAdditionalMandatoryServices",
            visible: false,
          },
          {
            name: "editWeightConsignment",
            visible: false,
          },
          {
            name: "editCargoAmount",
            visible: false,
          },
          {
            name: "bonusAndDiscountsInCargoRejected",
            visible: false,
          },
          {
            name: "sendEmailReportsLastLocations",
            visible: false,
          },
          {
            name: "createCargosWithoutMinimumUtility",
            visible: false,
          },
          {
            name: "showCreationDateCargo",
            visible: false,
          },
          {
            name: "editCargoTags",
            visible: false,
          },
          {
            name: "editCargoCompliments",
            visible: false,
          },
          {
            name: "restartCargo",
            visible: false,
          },
          {
            name: "approveCargoUnrestricted",
            visible: false,
          },
          {
            name: "removeCargoUnrestricted",
            visible: false,
          },
          {
            name: "removeCargoWithPayments",
            visible: false,
          },
          {
            name: "loadDownloadStatePoints",
            visible: false,
          },
          {
            name: "trackingReports",
            visible: false,
          },
          {
            name: "addDocumentsSupport",
            visible: false,
          },
          {
            name: "saveDriverDocuments",
            visible: false,
          },
          {
            name: "sendEmailReport",
            visible: false,
          },
          {
            name: "editAdvance",
            visible: false,
          },
          {
            name: "deletePayment",
            visible: false,
          },
          {
            name: "deleteBalancePayment",
            visible: false,
          },
          {
            name: "editTypeVehicle",
            visible: false,
          },
          {
            name: "addObservation",
            visible: false,
          },
          {
            name: "earlyUploadEvidences",
            visible: false
          },
          {
            name: "readVehicleDetails",
            visible: false
          },
          {
            name: "turn",
            visible: false
          },
          {
            name: "turnCreation",
            visible: false
          },
          {
            name: "editTurn",
            visible: false
          },
          {
            name: "deleteTurn",
            visible: false
          },
          {
            name: "viewAvailableLoads",
            visible: false
          },
          {
            name: "assignAvailableLoads",
            visible: false
          },
          {
            name: "viewTurnVehicles",
            visible: false
          },
          {
            name: "assignTurnVehicles",
            visible: false
          },
          {
            name: "evidencesFingerprint",
            visible: false
          },
          {
            name: "startServiceWithArlExpired",
            visible: false
          },
          {
            name: "startServiceWithEpsExpired",
            visible: false
          },
          {
            name: "enableSimultaneousInrouteLoading",
            visible: false
          },
          {
            name: "serviceRequests",
            visible: false
          }
        ],
      },
      {
        name: "guide",
        functionalities: [
          {
            name: "trackGuide",
            visible: false,
          },
          {
            name: "loadGuides",
            visible: false,
          },
          {
            name: "generateGuides",
            visible: false,
          },
          {
            name: "changeStateGuides",
            visible: false,
          },
          {
            name: "createGuidesFromExcel",
            visible: false,
          },
        ],
      },
      {
        name: "help-desk",
        functionalities: [
          {
            name: "listTicket",
            visible: false,
          },
          {
            name: "createTicket",
            visible: false,
          },
          {
            name: 'replyTechnicalTicket',
            visible: false,
          },
          {
            name: 'replyWebTicket',
            visible: false,
          },
          {
            name: 'replyMobileTicket',
            visible: false,
          },
          {
            name: 'replyOtherTicket',
            visible: false,
          },
          {
            name: 'reopenTicket',
            visible: false,
          },
        ],
      },
      {
        name: "point",
        functionalities: [
          {
            name: "removePoint",
            visible: false,
          },
          {
            name: "updatePoint",
            visible: false,
          },
          {
            name: "listPoints",
            visible: false,
          },
          {
            name: "createPoint",
            visible: false,
          },
        ],
      },
      {
        name: "routes",
        functionalities: [
          {
            name: "removePoint",
            visible: false,
          },
          {
            name: "updatePoint",
            visible: false,
          }
        ],
      },
      {
        name: "monitoring",
        functionalities: [
          {
            name: "monitorlogging",
            visible: false,
          },
          {
            name: "fingerprintslogging",
            visible: false,
          },
        ],
      },
      {
        name: "analytics",
        functionalities: [
          {
            name: "analyticsOperations",
            visible: false,
          },
        ],
      },
      {
        name: "vehicleAnalytics",
        functionalities: [
          {
            name: "vehicleAnalytics",
            visible: false,
          },
        ],
      },
      {
        name: "frequentRoutes",
        functionalities: [
          {
            name: 'frequentRoutes',
            visible: false
          }
        ]
      },
      {
        name: "dispatch",
        functionalities: [
          {
            name: 'dispatch',
            visible: false
          }
        ]
      },
      {
        name: "stopedCargos",
        functionalities: [
          {
            name: 'stopedCargos',
            visible: false
          }
        ]
      },
      {
        name: "analyticsBilling",
        functionalities: [
          {
            name: 'analyticsBilling',
            visible: false
          }
        ]
      },
      {
        name: "notifications",
        functionalities: [
          {
            name: "negotiationNotifications",
            visible: false,
          },
          {
            name: "trackingNotifications",
            visible: false,
          },
          {
            name: "delayBetweenPointsNotifications",
            visible: false,
          },
          {
            name: "createCargoNotifications",
            visible: false,
          },
          {
            name: "userDeactivateNotifications",
            visible: false,
          },
          {
            name: "geofenceNotifications",
            visible: false,
          },
          {
            name: "panicNotifications",
            visible: false,
          }
        ]
      },
      {
        name: "routing",
        functionalities: [
          {
            name: "createRouting",
            visible: false,
          },
          {
            name: "routingList",
            visible: false,
          }
        ]
      }
    ],
  };

  reader: RoleComany = {
    nit: null,
    name: "Lector",
    modules: [
      {
        name: "administration",
        functionalities: [
          {
            name: "setupMonitorIntegration",
            visible: false
          },
          {
            name: "truoraConfiguration",
            visible: false,
          },
          {
            name: "updateBankBalance",
            visible: false,
          },
          {
            name: "adminCompanies",
            visible: false,
          },
          {
            name: "adminModules",
            visible: false,
          },
          {
            name: "changeRole",
            visible: false,
          },
          {
            name: "validateDocumentsPendingDrivers",
            visible: false,
          },
          {
            name: "showSecurityAnalysis",
            visible: false,
          },
          {
            name: "updateRoles",
            visible: false,
          },
          {
            name: "downloadFileRegistreBank",
            visible: false,
          },
          {
            name: "changeDriverVehicle",
            visible: false,
          },
          {
            name: "validateAndRemarkVehicleReferences",
            visible: false,
          },
          {
            name: "createVehicle",
            visible: false,
          },
          {
            name: "createTermsConditions",
            visible: false,
          },
          {
            name: "updateCompany",
            visible: false,
          },
          {
            name: "adminVehicles",
            visible: false,
          },
          {
            name: "adminTrailers",
            visible: false,
          },
          {
            name: "adminCities",
            visible: false,
          },
          {
            name: "createCompany",
            visible: false,
          },
          {
            name: "createRoles",
            visible: false,
          },
          {
            name: "updateVehicle",
            visible: false,
          },
          {
            name: "activateVehicle",
            visible: false,
          },
          {
            name: "activateByScore",
            visible: false,
          },
          {
            name: "adminRoles",
            visible: false,
          },
          {
            name: "showDocumentsPendingDrivers",
            visible: false,
          },
          {
            name: "adminUsers",
            visible: false,
          },
          {
            name: "disableEnableDriver",
            visible: false,
          },
          {
            name: "disableEnableUsers",
            visible: false,
          },
          {
            name: "adminTerms",
            visible: false,
          },
          {
            name: "checkUser",
            visible: false,
          },
          {
            name: "updateBankAdvance",
            visible: false,
          },
          {
            name: "showCreateGps",
            visible: false,
          },
          {
            name: "modifyBasicFieldsUser",
            visible: false,
          },
          {
            name: "showDeleteGps",
            visible: false,
          },
          {
            name: "editTags",
            visible: false,
          },
          {
            name: "editCompliments",
            visible: false,
          },
          {
            name: "createCompanyComplimentType",
            visible: false,
          },
          {
            name: "updateCity",
            visible: false,
          },
          {
            name: "linkCities",
            visible: false,
          },
          {
            name: "addSubCities",
            visible: false,
          },
          {
            name: "deleteSubCities",
            visible: false,
          },
          {
            name: "createEmailCompany",
            visible: false,
          },
          {
            name: "forceVehicleActivation",
            visible: false,
          },
          {
            name: "deactivateVehicle",
            visible: false,
          },
          {
            name: "validateVehicleDocuments",
            visible: false
          },
          {
            name: "editAdminVehicle",
            visible: false
          },
          {
            name: "editOwnerVehicle",
            visible: false
          },
          {
            name: "editGpsVehicle",
            visible: false
          },
          {
            name: "editReferencesVehicle",
            visible: false
          },
          {
            name: "reactivateVehicleByInactivity",
            visible: false
          },
          {
            name: "changeFeeToOwnedFleet",
            visible: false
          }
        ],
      },
      {
        name: "payments",
        functionalities: [
          {
            name: "charges",
            visible: false,
          },
          {
            name: "createCostCenter",
            visible: false
          },
          {
            name: "associateCostCenter",
            visible: false
          },
          {
            name: "paymentBalance",
            visible: false,
          },
          {
            name: "reverseApproval",
            visible: false,
          },
          {
            name: "paymentExtraAdvance",
            visible: false,
          },
          {
            name: "billingXML",
            visible: false,
          },
          {
            name: "approvePayments",
            visible: false,
          },
          {
            name: "approveBankAdvance",
            visible: false,
          },
          {
            name: "paymentCargo",
            visible: false,
          },
          {
            name: "uploadExtraDocumentsCargo",
            visible: false,
          },
          {
            name: "paymentAdvance",
            visible: false,
          },
          {
            name: "approveBankBalance",
            visible: false,
          },
          {
            name: "editSimpleRegimen",
            visible: false,
          },
          {
            name: "modifyAdditionalCostBilled",
            visible: false,
          },
          {
            name: "modifyAdditionalCostpayed",
            visible: false,
          },
          {
            name: "addAdditionalServiceCargoPaid",
            visible: false,
          },
          {
            name: "addAdditionalServiceCargoCashed",
            visible: false,
          },
          {
            name: "addAdditionalServiceCargoApproved",
            visible: false,
          },
          {
            name: "editRateCargoUnrestricted",
            visible: false,
          },
          {
            name: "fastPaymentRequest",
            visible: false,
          },
          {
            name: "adminReceivable",
            visible: false,
          },
          {
            name: "createReceivable",
            visible: false,
          },
          {
            name: "deleteReceivable",
            visible: false,
          },
          {
            name: "viewPaymentsReceivables",
            visible: false,
          },
          {
            name: "generateAccountingFiles",
            visible: false
          },
          {
            name: "billingWithFlexibilityChoice",
            visible: false
          },
          {
            name: "unrestrictedAdvancePayments",
            visible: false
          },
          {
            name: "unrestrictedAdvancePaymentsRequest",
            visible: false
          }
        ],
      },
      {
        name: "setting",
        functionalities: [
          {
            name: "changePassword",
            visible: true,
          },
          {
            name: "companyDocuments",
            visible: false,
          },
          {
            name: "termsAndConditions",
            visible: false,
          },
          {
            name: "privacyPolicy",
            visible: false,
          },
          {
            name: "personalDataPolicies",
            visible: false,
          },
          {
            name: "habeasData",
            visible: false,
          },
          {
            name: "termsManifest",
            visible: false,
          },
          {
            name: "bascPolicies",
            visible: false,
          },
          {
            name: "quickPay",
            visible: false,
          },
        ],
      },
      {
        name: "cargo",
        functionalities: [
          {
            name: "continueTripWithoutEvidences",
            visible: false,
          },
          {
            name: "cancelCargo",
            visible: false,
          },
          {
            name: "uploadEvidences",
            visible: false,
          },
          {
            name: "readCargoCompliments",
            visible: false,
          },
          {
            name: "readDeletedCargoCompliments",
            visible: false,
          },
          {
            name: "complimentsFingerprint",
            visible: false
          },
          {
            name: "editCargoCompliments",
            visible: false,
          },
          {
            name: "approvePayments",
            visible: false,
          },
          {
            name: "confirmLicensePlate",
            visible: false,
          },
          {
            name: "modifyShippingCost",
            visible: false,
          },
          {
            name: "viewApproveInfo",
            visible: false
          },
          {
            name: "approvedBelowSicetac",
            visible: false
          },
          {
            name: "createMassiveCargo",
            visible: false,
          },
          {
            name: "createCargoFromRoute",
            visible: false,
          },
          {
            name: "createCargoByGuides",
            visible: false,
          },
          {
            name: "cargosInRoute",
            visible: true,
          },
          {
            name: "showTotalCostCargo",
            visible: false,
          },
          {
            name: "cargosInNegotiation",
            visible: false,
          },
          {
            name: "createManualCargo",
            visible: false,
          },
          {
            name: "changeManifestRequirement",
            visible: false,
          },
          {
            name: "searchCargo",
            visible: false,
          },
          {
            name: "createRoundedCargo",
            visible: false,
          },
          {
            name: "scheduledCargo",
            visible: true,
          },
          {
            name: "createCargoByRoute",
            visible: false,
          },
          {
            name: "entunamiento",
            visible: false,
          },
          {
            name: "cargoRequest",
            visible: false,
          },
          {
            name: "cargoRequestNegotiation",
            visible: false,
          },
          {
            name: "expiredCargo",
            visible: false,
          },
          {
            name: "deletedCargo",
            visible: true,
          },
          {
            name: "rateCargo",
            visible: true,
          },
          {
            name: "uploadExternalManifest",
            visible: false,
          },
          {
            name: "editTravelExpenses",
            visible: false,
          },
          {
            name: "readDriverDetails",
            visible: false,
          },
          {
            name: "readManifest",
            visible: false,
          },
          {
            name: "watchLiveGPS",
            visible: false,
          },
          {
            name: "assignLicensePlate",
            visible: false,
          },
          {
            name: "removeCargo",
            visible: false,
          },
          {
            name: "showRateCargo",
            visible: true,
          },
          {
            name: "createRequestFromManualCreationCargo",
            visible: false,
          },
          {
            name: "createCargoFromRequest",
            visible: false,
          },
          {
            name: "cargoItemFinancier",
            visible: false,
          },
          {
            name: "reports",
            visible: true,
          },
          {
            name: "finishedCargos",
            visible: true,
          },
          {
            name: "startCargo",
            visible: false,
          },
          {
            name: "registerLocation",
            visible: false,
          },
          {
            name: "showManifestError",
            visible: false
          },
          {
            name: "showPaymentCargo",
            visible: false,
          },
          {
            name: "showAnomaliesCargo",
            visible: false,
          },
          {
            name: "restoreCargo",
            visible: false,
          },
          {
            name: "editCargo",
            visible: false,
          },
          {
            name: "finishCargo",
            visible: false,
          },
          {
            name: "changeCompany",
            visible: false,
          },
          {
            name: "reportAnomalies",
            visible: false,
          },
          {
            name: "changeRteica",
            visible: false,
          },
          {
            name: "transportedValueSemaphore",
            visible: false,
          },
          {
            name: "paymentSemaphore",
            visible: false,
          },
          {
            name: "approveSemaphore",
            visible: false,
          },
          {
            name: "showBillingData",
            visible: false,
          },
          {
            name: "changeRateFullAccess",
            visible: false,
          },
          {
            name: "assignVehicleFullAccess",
            visible: false,
          },
          {
            name: "advanceRequest",
            visible: false,
          },
          {
            name: "additionalCostCreate",
            visible: false,
          },
          {
            name: "additionalCostUpdate",
            visible: false,
          },
          {
            name: "additionalCostDelete",
            visible: false,
          },
          {
            name: "useCarNearExpirationDocuments",
            visible: false,
          },
          {
            name: "changeCargoLoadDate",
            visible: false,
          },
          {
            name: "showCostCargo",
            visible: false,
          },
          {
            name: "approveBalanceDriver",
            visible: true,
          },
          {
            name: "specialLicensePlate",
            visible: false,
          },
          {
            name: "confirmationAdditionalMandatoryServices",
            visible: false,
          },
          {
            name: "editWeightConsignment",
            visible: false,
          },
          {
            name: "editCargoAmount",
            visible: false
          },
          {
            name: "bonusAndDiscountsInCargoRejected",
            visible: false,
          },
          {
            name: "sendEmailReportsLastLocations",
            visible: false,
          },
          {
            name: "createCargosWithoutMinimumUtility",
            visible: false,
          },
          {
            name: "showCreationDateCargo",
            visible: false,
          },
          {
            name: "editCargoTags",
            visible: false,
          },
          {
            name: "editCargoCompliments",
            visible: false,
          },
          {
            name: "restartCargo",
            visible: false,
          },
          {
            name: "approveCargoUnrestricted",
            visible: false,
          },
          {
            name: "removeCargoUnrestricted",
            visible: false,
          },
          {
            name: "removeCargoWithPayments",
            visible: false,
          },
          {
            name: "loadDownloadStatePoints",
            visible: false,
          },
          {
            name: "trackingReports",
            visible: false,
          },
          {
            name: "addDocumentsSupport",
            visible: false,
          },
          {
            name: "saveDriverDocuments",
            visible: false,
          },
          {
            name: "sendEmailReport",
            visible: false,
          },
          {
            name: "editAdvance",
            visible: false,
          },
          {
            name: "deletePayment",
            visible: false,
          },
          {
            name: "deleteBalancePayment",
            visible: false,
          },
          {
            name: "editTypeVehicle",
            visible: false,
          },
          {
            name: "addObservation",
            visible: false,
          },
          {
            name: "earlyUploadEvidences",
            visible: false
          },
          {
            name: "readVehicleDetails",
            visible: false
          },
          {
            name: "turn",
            visible: false
          },
          {
            name: "turnCreation",
            visible: false
          },
          {
            name: "editTurn",
            visible: false
          },
          {
            name: "deleteTurn",
            visible: false
          },
          {
            name: "viewAvailableLoads",
            visible: false
          },
          {
            name: "assignAvailableLoads",
            visible: false
          },
          {
            name: "viewTurnVehicles",
            visible: false
          },
          {
            name: "assignTurnVehicles",
            visible: false
          },
          {
            name: "evidencesFingerprint",
            visible: false
          },
          {
            name: "startServiceWithArlExpired",
            visible: false
          },
          {
            name: "startServiceWithEpsExpired",
            visible: false
          },
          {
            name: "enableSimultaneousInrouteLoading",
            visible: false
          },
          {
            name: "useCarWithExpiredDocuments",
            visible: false
          },
          {
            name: "payWithTravelExpenses",
            visible: false
          },
          {
            name: "changeApprovedTravelExpenses",
            visible: false
          },
          {
            name: "createBonusOrPenaltyToCargo",
            visible: false
          },
          {
            name: "serviceRequests",
            visible: false
          }
        ],
      },
      {
        name: "guide",
        functionalities: [
          {
            name: "trackGuide",
            visible: false,
          },
          {
            name: "loadGuides",
            visible: false,
          },
          {
            name: "generateGuides",
            visible: false,
          },
          {
            name: "changeStateGuides",
            visible: false,
          },
          {
            name: "createGuidesFromExcel",
            visible: false,
          },
        ],
      },
      {
        name: "help-desk",
        functionalities: [
          {
            name: "listTicket",
            visible: false,
          },
          {
            name: "createTicket",
            visible: false,
          },
          {
            name: 'replyTechnicalTicket',
            visible: false,
          },
          {
            name: 'replyWebTicket',
            visible: false,
          },
          {
            name: 'replyMobileTicket',
            visible: false,
          },
          {
            name: 'replyOtherTicket',
            visible: false,
          },
          {
            name: 'reopenTicket',
            visible: false,
          },
        ],
      },
      {
        name: "point",
        functionalities: [
          {
            name: "removePoint",
            visible: false,
          },
          {
            name: "updatePoint",
            visible: false,
          },
          {
            name: "listPoints",
            visible: false,
          },
          {
            name: "createPoint",
            visible: false,
          },
        ],
      },
      {
        name: "routes",
        functionalities: [
          {
            name: "removePoint",
            visible: false,
          },
          {
            name: "updatePoint",
            visible: false,
          }
        ],
      },
      {
        name: "monitoring",
        functionalities: [
          {
            name: "monitorlogging",
            visible: false,
          },
          {
            name: "fingerprintslogging",
            visible: false,
          },
        ],
      },
      {
        name: "analytics",
        functionalities: [
          {
            name: "analyticsOperations",
            visible: false,
          },
        ],
      },
      {
        name: "vehicleAnalytics",
        functionalities: [
          {
            name: "vehicleAnalytics",
            visible: false,
          },
        ],
      },
      {
        name: "frequentRoutes",
        functionalities: [
          {
            name: 'frequentRoutes',
            visible: false
          }
        ]
      },
      {
        name: "dispatch",
        functionalities: [
          {
            name: 'dispatch',
            visible: false
          }
        ]
      },
      {
        name: "stopedCargos",
        functionalities: [
          {
            name: 'stopedCargos',
            visible: false
          }
        ]
      },
      {
        name: "analyticsBilling",
        functionalities: [
          {
            name: 'analyticsBilling',
            visible: false
          }
        ]
      },
      {
        name: "notifications",
        functionalities: [
          {
            name: "negotiationNotifications",
            visible: false,
          },
          {
            name: "trackingNotifications",
            visible: false,
          },
          {
            name: "delayBetweenPointsNotifications",
            visible: false,
          },
          {
            name: "createCargoNotifications",
            visible: false,
          },
          {
            name: "userDeactivateNotifications",
            visible: false,
          },
          {
            name: "geofenceNotifications",
            visible: false,
          },
          {
            name: "panicNotifications",
            visible: false,
          }
        ]
      },
      {
        name: "routing",
        functionalities: [
          {
            name: "createRouting",
            visible: false,
          },
          {
            name: "routingList",
            visible: false,
          }
        ]
      }
    ],
  };
}
