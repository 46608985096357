import { Injectable } from '@angular/core';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { FreightListService } from 'src/app/modules/cargo/cargo-list/cargo-list.service';

@Injectable({
  providedIn: 'root'
})
export class ListOperationsService {

  constructor(
    private freightListService: FreightListService,
    private utils: Utils
  ) { }

  /**
* This method consumes a service that bring the loads in route sending one parameter.
*/
  async getLoadsToPayByParameters(parameter: string, value: string): Promise<Cargo[]> {
    try {
      let data: Cargo[] = await this.freightListService.getListCargo(`${parameter}=${value}&state=Start%20service`).toPromise();
      data = data.filter((cargo: Cargo) => {
        return cargo.shippingCost.advanceState || cargo.shippingCost.requestedAdvance;
      })
      let loads = [];
      if (data && data.length) {
        loads = this.sortLoadsData(data);
        loads = this.excludeFinishedLoads(loads);
      }
      return loads;
    } catch (error) {
      return [];
    }
  }

  /**
  * this method sort by endDate of the first address.
  */
  sortLoadsData(data: Cargo[]): Cargo[] {
    // Order data
    data.sort((a, b) => {
      const endDateA = this.utils.getNestedValue(a, 'cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate');
      const endDateB = this.utils.getNestedValue(b, 'cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate');
      if (endDateA === undefined || endDateA === null) return 1;
      if (endDateB === undefined || endDateB === null) return -1;
      const dateA = new Date(a.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate);
      const dateB = new Date(b.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate);
      return dateA.getTime() - dateB.getTime();
    });
    return data;
  }

  excludeFinishedLoads(loads: Cargo[]): Cargo[] {
    if (loads.every(load => load.shippingCost && this.utils.isDefined(load.shippingCost.advanceState))) {
      let lastFalseIndex = loads.reduceRight((acc, load, index) => {
        return load.shippingCost.advanceState === false && acc === -1 ? index : acc;
      }, -1);
      if (lastFalseIndex !== -1) loads = loads.slice(0, lastFalseIndex + 1);
    }
    return loads;
  }
}
