import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable({
  providedIn: 'root',
})

export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) { }

  openSnackBar(message: string, action?: string, type?: 'success' | 'error' | 'info' | 'alert', duration = 5000, actionCallback?: () => void) {
    if (!action) {
      action = 'x';
    }
    const realtype = this.typeMessage(type);
    const snackBarRef = this._snackBar.open(message, action, {
      duration,
      panelClass: [realtype],
      // panelClass: ['success-snackbar']
    });

    if (actionCallback) {
      snackBarRef.onAction().subscribe(() => {
        actionCallback();
      });
    }
  }

  private typeMessage(type) {
    switch (type) {
      case 'success':
        return 'success-snackbar';
      case 'error':
        return 'error-snackbar';
      case 'info':
        return 'info-snackbar';
      case 'alert':
        return 'alert-snackbar';
      default:
        return 'success-snackbar';
    }
  }

}
