<main class="container">
        <div class="">
                <div class='row' style="max-width: 700px; margin: auto; padding-top: 50px;">
                        <div class='col-md-8'>
                                <p><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 243.00px; text-align: center;">
                                                <img alt="" src="./assets/images/bascPolicy/INNOVBO.png"
                                                        style="width: 243.00px; height: 63.64px; margin-left: 0.00px; margin-top: 4.09px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                                                        title="">

                                                <img src="./assets/images/bascPolicy/super_transporte.png"
                                                        style="height: 45.64px; margin-left: 0.00px; margin-top: 4.09px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);">
                                        </span></p>
                        </div>
                        <div class='col-md-4'>
                                <p><span></span></p>
                                <p class="c8"><span class="c16">C&Oacute;DIGO: SG01-PO05</span></p>
                                <p class="c8"><span class=c16>VERSI&Oacute;N 02 &nbsp;</span></p>
                                <p class="c8"><span class=c16>VIGENCIA: 08-MAY-2023</span></p>
                        </div>
                </div>
                <h3 style="text-align: center;font-weight: bold;">HABEAS DATA</h3>

                <p class="c3"><span class="c4">INNOVBO S.A.S.</span><span class="c13">&nbsp;identificada con NIT
                                901343057-1
                                con domicilio principal en la ciudad de Bogot&aacute; Calle 26 # 96J - 66 Piso 6 Oficina
                                6.6, Colombia,
                                Tel&eacute;fono 3174284803 y p&aacute;gina web </span><span class="c15 c13 c30"><a
                                        class="c12"
                                        href="https://www.google.com/url?q=http://www.innovbo.com&amp;sa=D&amp;source=editors&amp;ust=1697002803444533&amp;usg=AOvVaw2NWpIdW9OoeupINfgPydaB">www.innovbo.com</a></span><span
                                class="c0">, empresa dedicada a prestar servicios de transporte terrestre automotor en
                                la modalidad de
                                carga, es consciente de la importancia de la seguridad, privacidad y confidencialidad de
                                los datos
                                personales de sus funcionarios, clientes y proveedores. </span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">La presente pol&iacute;tica de tratamiento de datos personales se
                                aplicar&aacute;
                                a
                                todas las bases de datos y/o archivos que contengan datos personales, estableciendo el
                                procedimiento
                                &oacute;ptimo para conocer, actualizar, rectificar, o solicitar la eliminaci&oacute;n de
                                los datos
                                personales que est&eacute;n bajo nuestra responsabilidad y permitir a sus titulares
                                ejercer su derecho
                                de
                                h&aacute;beas data.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">Este documento est&aacute; orientado a dar cumplimiento al
                                art&iacute;culo 15 de
                                nuestra constituci&oacute;n pol&iacute;tica, con la finalidad de proteger y garantizar
                                los aspectos
                                relacionados con el tratamiento de datos personales, las libertades y los derechos que
                                tienen todas las
                                personas. </span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO I</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">DEFINICIONES</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">De conformidad con la legislaci&oacute;n vigente, se presentan las
                                siguientes
                                definiciones, las cuales ser&aacute;n aplicadas e implementadas acogiendo los criterios
                                de
                                interpretaci&oacute;n que garanticen el cumplimiento de los principios y postulados que
                                rigen los
                                derechos
                                fundamentales relacionados con el habeas data y protecci&oacute;n de datos
                                personales.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">Para la interpretaci&oacute;n de las pol&iacute;ticas contenidas en este
                                manual,
                                le
                                pedimos tener en cuenta las siguientes definiciones:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c4">AUTORIZACI&Oacute;N</span><span class="c0">: Consentimiento previo,
                                expreso e
                                informado del titular para llevar a cabo el tratamiento de datos personales.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">AVISO DE PRIVACIDAD:</span><span class="c0">&nbsp;Comunicaci&oacute;n
                                verbal o
                                escrita generada por el responsable, dirigida al titular para el tratamiento de sus
                                datos personales,
                                mediante la cual se le informa acerca de la existencia de las pol&iacute;ticas de
                                tratamiento de
                                informaci&oacute;n que le ser&aacute;n aplicables, la forma de acceder a las mismas y
                                las finalidades
                                del
                                tratamiento que se pretende dar a los datos personales.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c4">DATO P&Uacute;BLICO:&nbsp;</span><span class="c0">Es el dato que no sea
                                semiprivado,
                                privado o sensible. Son considerados datos p&uacute;blicos, entre otros, los datos
                                relativos al estado
                                civil
                                de las personas, su profesi&oacute;n u oficio y a su calidad de comerciante o de
                                servidor
                                p&uacute;blico.
                                Por su naturaleza, los datos p&uacute;blicos pueden estar contenidos, entre otros, en
                                registros
                                p&uacute;blicos, documentos p&uacute;blicos, gacetas y boletines oficiales y sentencias
                                judiciales
                                debidamente ejecutoriadas que no est&eacute;n sometidas a reserva.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c4">DATO SEMIPRIVADO:&nbsp;</span><span class="c0">Es el dato que no tienen
                                naturaleza
                                &iacute;ntima, reservada, ni p&uacute;blica y cuyo conocimiento o divulgaci&oacute;n
                                puede interesar no
                                s&oacute;lo a su titular sino a cierto sector o grupo de personas.</span></p>
                <p class="c2"><span class="c1"></span></p>
                <p class="c3"><span class="c4">DATO PERSONAL:</span><span class="c0">&nbsp;Cualquier informaci&oacute;n
                                vinculada o
                                que pueda asociarse a una o varias personas naturales determinadas o
                                determinables.</span></p>
                <p class="c2"><span class="c1"></span></p>
                <p class="c3"><span class="c4">DATOS SENSIBLES:</span><span class="c0">&nbsp;Aquellos datos que afectan
                                la
                                intimidad
                                del titular o cuyo uso indebido puede generar su discriminaci&oacute;n.</span></p>
                <p class="c3"><span class="c4">EMPLEADO:</span><span class="c0">&nbsp;Persona natural que en virtud de
                                un
                                contrato
                                de trabajo se obliga a prestar un servicio personal a otra persona natural o
                                jur&iacute;dica, bajo la
                                continuada dependencia o subordinaci&oacute;n de la segunda y mediante
                                remuneraci&oacute;n.</span></p>
                <p class="c2"><span class="c1"></span></p>
                <p class="c3"><span class="c4">EX EMPLEADO:</span><span class="c13">&nbsp;Persona natural que estuvo
                                vinculada
                                laboralmente con&nbsp;</span><span class="c1">INNOVBO S.A.S.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">ENCARGADO DEL TRATAMIENTO:</span><span class="c13">&nbsp;Persona natural
                                o
                                jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con
                                otros, realice el
                                tratamiento de datos personales por cuenta de&nbsp;</span><span class="c4">INNOVBO
                                S.A.S.</span><span class="c0">&nbsp;como responsable de los datos.</span></p>
                <p class="c2"><span class="c1"></span></p>
                <p class="c3"><span class="c4">HABEAS DATA: </span><span class="c13">Derecho que tiene toda persona de
                                conocer,
                                actualizar y rectificar la informaci&oacute;n que se haya recogido sobre ella en
                                archivos y bancos de
                                datos
                                de naturaleza p&uacute;blica o privada.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">INCIDENTES DE SEGURIDAD:&nbsp;</span><span class="c0">Se refiere a la
                                violaci&oacute;n de los c&oacute;digos de seguridad o la p&eacute;rdida, robo y/o acceso
                                no autorizado
                                de
                                informaci&oacute;n de una base de datos administrada por el responsable del tratamiento
                                o por su
                                encargado.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c4">INFORMACI&Oacute;N ALMACENADA EN LA BASE DE DATOS:&nbsp;</span><span
                                class="c0">Es la
                                clasificaci&oacute;n de los datos personales almacenados en cada base de datos,
                                agrupados por
                                categor&iacute;as y subcategor&iacute;as, de acuerdo con la naturaleza de los
                                mismos.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">POL&Iacute;TICA DE TRATAMIENTO:</span><span class="c13">&nbsp;Se refiere
                                al
                                presente
                                documento, como pol&iacute;tica de tratamiento de datos personales aplicada
                                por&nbsp;</span><span class="c4">INNOVBO S.A.S.</span><span class="c0">&nbsp;de
                                conformidad con los lineamientos de la
                                legislaci&oacute;n vigente en la materia.</span></p>
                <p class="c2"><span class="c1"></span></p>
                <p class="c3"><span class="c4">PROVEEDOR:</span><span class="c13">&nbsp;Toda persona natural o
                                jur&iacute;dica
                                que
                                preste alg&uacute;n servicio a&nbsp;</span><span class="c4">INNOVBO S.A.S.</span><span
                                class="c0">&nbsp;en virtud de una relaci&oacute;n contractual o a los asociados a
                                trav&eacute;s de una
                                figura de convenio o acuerdo comercial o de servicios.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">PROCEDENCIA DE LOS DATOS PERSONALES:&nbsp;</span><span class="c0">La
                                procedencia
                                de
                                los datos se refiere a si estos son recolectados del titular de la informaci&oacute;n o
                                suministrados
                                por
                                terceros y si se cuenta con la autorizaci&oacute;n para el tratamiento o existe una
                                causal de
                                exoneraci&oacute;n, de acuerdo con lo establecido en el art&iacute;culo 10 de la Ley
                                1581 de
                                2012.</span>
                </p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">RECLAMOS PRESENTADOS POR LOS TITULARES:&nbsp;</span><span
                                class="c0">Corresponde
                                a la
                                informaci&oacute;n de los reclamos presentados por los titulares ante el responsable y/o
                                el encargado
                                del
                                tratamiento, seg&uacute;n sea el caso, dentro de un semestre calendario (enero &ndash;
                                junio y julio
                                &ndash;
                                diciembre).</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">RESPONSABLE DEL TRATAMIENTO:</span><span class="c13">&nbsp;Persona
                                natural o
                                jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con
                                otros, decida sobre
                                la
                                base de datos y/o el tratamiento de los datos, para efectos de esta pol&iacute;tica,
                                ejercer&aacute;
                                como
                                responsable, en principio,&nbsp;</span><span class="c1">INNOVBO S.A.S.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">TITULAR:</span><span class="c13">&nbsp;Persona natural o jur&iacute;dica
                                cuyos
                                datos
                                personales sean objeto de tratamiento, sea asociado, ex asociado, proveedor, empleado o
                                ex empleado
                                de&nbsp;</span><span class="c4">INNOVBO S.A.S.</span><span class="c13">&nbsp;o cualquier
                                tercero
                                que,
                                en raz&oacute;n de una relaci&oacute;n personal, familiar o jur&iacute;dica con el
                                asociado, suministre
                                datos personales a&nbsp;</span><span class="c4">INNOVBO S.A.S.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">TRANSFERENCIA:</span><span class="c13">&nbsp;Se refiere al env&iacute;o
                                por parte
                                de&nbsp;</span><span class="c4">INNOVBO S.A.S.</span><span class="c0">&nbsp;como
                                responsable del
                                tratamiento o un encargado de los datos, dentro o fuera del territorio nacional para el
                                tratamiento
                                efectivo
                                de datos personales.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">TRANSMISI&Oacute;N:</span><span class="c0">&nbsp;Se refiere a la
                                comunicaci&oacute;n
                                de datos personales por parte del responsable al encargado, ubicado dentro o fuera del
                                territorio
                                nacional,
                                para que el encargado, por cuenta del responsable, trate datos personales.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">TRANSFERENCIA INTERNACIONAL DE DATOS PERSONALES:&nbsp;</span><span
                                class="c0">La
                                informaci&oacute;n relacionada con la transferencia internacional de datos personales
                                comprende la
                                identificaci&oacute;n del destinatario como responsable del tratamiento, el pa&iacute;s
                                en el que se
                                encuentra ubicado y si la operaci&oacute;n est&aacute; cobijada por una
                                declaraci&oacute;n de
                                conformidad
                                emitida por la Delegatura para la Protecci&oacute;n de Datos Personales de la
                                Superintendencia de
                                Industria
                                y Comercio o por una causal de excepci&oacute;n en los t&eacute;rminos se&ntilde;alados
                                en el
                                art&iacute;culo 26 de la Ley 1581 de 2012.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">TRATAMIENTO:</span><span class="c0">&nbsp;Cualquier operaci&oacute;n o
                                conjunto
                                de
                                operaciones sobre datos personales, tales como la recolecci&oacute;n, almacenamiento,
                                uso,
                                circulaci&oacute;n o supresi&oacute;n. Para la ampliaci&oacute;n de algunos
                                t&eacute;rminos que no se
                                encuentran incluidos dentro de la lista anterior, lo invitamos a que se remita a la Ley
                                1581 de 2012 y
                                al
                                Decreto 1377 de 2013.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c22"><span class="c1">T&Iacute;TULO II</span></p>
                <p class="c22"><span class="c1">MARCO LEGAL APLICABLE</span></p>
                <p class="c3"><span class="c0">La presente Pol&iacute;tica se encuentra definida dentro de las
                                siguientes
                                normas:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_4-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Art&iacute;culo 15 de la Constituci&oacute;n
                                        Pol&iacute;tica
                                        de
                                        Colombia que consagra los derechos a la intimidad, buen nombre y a la
                                        protecci&oacute;n de Datos
                                        Personales o h&aacute;beas data.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_4-0">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Ley 1266 de 2008 por la cual se desarrollaron
                                        los
                                        art&iacute;culos 15 y 20 de la Constituci&oacute;n Pol&iacute;tica relacionados
                                        con el conocimiento,
                                        actualizaci&oacute;n y rectificaci&oacute;n de la informaci&oacute;n que se
                                        recolecta sobre las
                                        personas
                                        en bancos de datos y los dem&aacute;s derechos y libertades y garant&iacute;as
                                        constitucionales
                                        relacionadas con la recolecci&oacute;n y tratamiento y circulaci&oacute;n de
                                        datos
                                        personales.</span>
                        </li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_4-0">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Ley 1581 de 2012 por la cual se dictan
                                        disposiciones
                                        generales
                                        para la protecci&oacute;n de Datos Personales.</span></li>
                </ul>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_4-0">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Ley 527 de 1999 Ley de Comercio
                                        Electr&oacute;nico</span><span class="c26 c35">, </span><span class="c13">Por
                                        medio de la cual se
                                        define y reglamenta el acceso y uso
                                        de los mensajes de datos, del comercio electr&oacute;nico y de las firmas
                                        digitales, y se establecen
                                        las
                                        entidades de certificaci&oacute;n y se dictan otras disposiciones</span><span
                                        class="c13 c26">.</span>
                        </li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_4-0">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Decreto 1377 de 2013 mediante el cual se
                                        reglamenta
                                        parcialmente
                                        la Ley 1581 de 2012 en aspectos relacionados con la autorizaci&oacute;n del
                                        Titular de la
                                        Informaci&oacute;n, las transferencias de Datos Personales y la responsabilidad
                                        demostrada frente al
                                        tratamiento de Datos Personales, entre otros. Ambos decretos compilados en el
                                        Decreto &Uacute;nico
                                        Reglamentario 1074 de 2015.</span></li>
                </ul>
                <p class="c3 c6 c38"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_4-0">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Decreto 886 de 2014 mediante el cual se
                                        reglamenta
                                        parcialmente
                                        la ley 1581 de 2012 en lo relacionado con el Registro Nacional de Bases de
                                        Datos.</span></li>
                </ul>
                <p class="c3 c6 c38"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_4-0">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Cualquier otra disposici&oacute;n que modifique,
                                        reglamente,
                                        sustituya o derogue las normas anteriormente mencionadas.</span></li>
                </ul>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO III</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">APLICACI&Oacute;N</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c13">La pol&iacute;tica de tratamiento de datos personales y su manual se
                                aplicar&aacute;n obligatoriamente a todos los datos de car&aacute;cter personal
                                registrados en soportes
                                f&iacute;sicos o digitales que sean susceptibles de ser tratados por&nbsp;</span><span
                                class="c4">INNOVBO S.A.S</span><span class="c13">&nbsp;como responsable de dichos datos.
                                El r&eacute;gimen de
                                protecci&oacute;n de datos personales que se establece en el presente documento no se
                                aplicar&aacute; a
                                las
                                bases de datos o archivos indicados en el art&iacute;culo 2 de la ley 1581 de
                                2012.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO IV</span></p>
                <p class="c27"><span class="c1">PRINCIPIOS PARA EL TRATAMIENTO DE DATOS PERSONALES</span></p>
                <p class="c32"><span class="c0">El procesamiento de datos personales debe regirse bajo los siguientes
                                principios
                                aplicados por INNOVBO S.A.S.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">LEGALIDAD</span><span class="c0">: En el uso, captura, recolecci&oacute;n
                                y
                                tratamiento de datos personales, se dar&aacute; aplicaci&oacute;n a las disposiciones
                                vigentes y
                                aplicables
                                que rigen el tratamiento de datos personales y dem&aacute;s derechos fundamentales
                                conexos.</span></p>
                <p class="c2"><span class="c1"></span></p>
                <p class="c3"><span class="c4">LIBERTAD</span><span class="c0">: El Tratamiento s&oacute;lo puede
                                ejercerse con
                                el
                                consentimiento previo, expreso e informado del Titular. Los datos personales no
                                podr&aacute;n ser
                                obtenidos
                                o divulgados sin previa autorizaci&oacute;n, o en ausencia de mandato legal,
                                estatutario, o judicial que
                                releve el consentimiento.</span></p>
                <p class="c2"><span class="c1"></span></p>
                <p class="c3"><span class="c4">FINALIDAD</span><span class="c13">: El uso, captura, recolecci&oacute;n y
                                tratamiento
                                de datos personales a los que tenga acceso y sean acopiados y
                                recogidos&nbsp;</span><span class="c4">INNOVBO
                                S.A.S</span><span class="c0">&nbsp;estar&aacute;n subordinados y atender&aacute;n una
                                finalidad
                                leg&iacute;tima, la cual debe serle informada al respectivo titular de los datos
                                personales.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">VERACIDAD O CALIDAD</span><span class="c0">: La informaci&oacute;n sujeta
                                a
                                tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible.
                                Se proh&iacute;be
                                el
                                tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</span>
                </p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">TRANSPARENCIA</span><span class="c13">: Debe garantizarse el derecho del
                                Titular
                                a
                                obtener de&nbsp;</span><span class="c4">INNOVBO S.A.S</span><span class="c0">&nbsp;en
                                cualquier
                                momento y sin restricciones, informaci&oacute;n acerca de la existencia de datos que le
                                concierne.</span>
                </p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">ACCESO Y CIRCULACI&Oacute;N RESTRINGIDA</span><span class="c0">: Los
                                datos
                                personales, salvo la informaci&oacute;n p&uacute;blica, no podr&aacute;n estar
                                disponibles en Internet u
                                otros medios de divulgaci&oacute;n o comunicaci&oacute;n masiva, salvo que el acceso sea
                                t&eacute;cnicamente
                                controlable para brindar un conocimiento restringido s&oacute;lo a los titulares o
                                terceros
                                autorizados.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">SEGURIDAD:</span><span class="c13">&nbsp;Los datos personales e
                                informaci&oacute;n
                                usada, capturada, recolectada y sujeta a tratamiento por&nbsp;</span><span
                                class="c4">INNOVBO
                                S.A.S</span><span class="c0">, ser&aacute; objeto de protecci&oacute;n en la medida en
                                que los recursos
                                t&eacute;cnicos y est&aacute;ndares m&iacute;nimos as&iacute; lo permitan, a
                                trav&eacute;s de la
                                adopci&oacute;n de medidas tecnol&oacute;gicas de protecci&oacute;n, protocolos, y todo
                                tipo de medidas
                                administrativas que sean necesarias para otorgar seguridad a los registros evitando su
                                alteraci&oacute;n,
                                modificaci&oacute;n, p&eacute;rdida, consulta, y en general en contra de cualquier uso o
                                acceso no
                                autorizado.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c4">CONFIDENCIALIDAD</span><span class="c0">: Todas las personas que
                                intervienen en
                                el
                                Tratamiento de datos personales que no tengan naturaleza de p&uacute;blicos est&aacute;n
                                obligadas a
                                garantizar la reserva de la informaci&oacute;n, inclusive despu&eacute;s de finalizada
                                la
                                relaci&oacute;n
                                con alguna de las labores que comprende el tratamiento, pudiendo s&oacute;lo realizar
                                suministro o
                                comunicaci&oacute;n de datos personales cuando ello corresponda al desarrollo de las
                                actividades
                                autorizadas
                                en la presente ley y en los t&eacute;rminos de la misma.</span></p>
                <p class="c14 c6"><span class="c1"></span></p>
                <p class="c14"><span class="c1">T&Iacute;TULO V</span></p>
                <p class="c14 c6"><span class="c1"></span></p>
                <p class="c14"><span class="c1">TRATAMIENTO DE DATOS SENSIBLES</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">Se podr&aacute; hacer uso y tratamiento de los datos catalogados como
                                sensibles
                                cuando:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_3-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c0">El Titular haya dado su autorizaci&oacute;n
                                        expl&iacute;cita
                                        a
                                        dicho tratamiento, salvo en los casos que por ley no sea requerido el
                                        otorgamiento de dicha
                                        autorizaci&oacute;n.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_3-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">El Tratamiento sea necesario para salvaguardar el
                                        inter&eacute;s
                                        vital del titular y este se encuentre f&iacute;sica o jur&iacute;dicamente
                                        incapacitado. En estos
                                        eventos, los representantes legales deber&aacute;n otorgar su
                                        autorizaci&oacute;n.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_3-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">El Tratamiento se refiere a datos que sean
                                        necesarios para el
                                        reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.</span>
                        </li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_3-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">El Tratamiento tenga una finalidad
                                        hist&oacute;rica,
                                        estad&iacute;stica o cient&iacute;fica. En este evento deber&aacute;n adoptarse
                                        las medidas
                                        conducentes
                                        a la supresi&oacute;n de identidad de los Titulares.</span></li>
                </ul>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO VI</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">DERECHOS DE LOS TITULARES PARA EL TRATAMIENTO DE DATOS</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c0">El Titular de los datos personales tendr&aacute;n los siguientes
                                derechos:</span>
                </p>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_8-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Conocer, actualizar y rectificar sus datos
                                        personales frente
                                        a
                                        los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se
                                        podr&aacute; ejercer,
                                        entre otros, frente a datos parciales, inexactos, incompletos, fraccionados, que
                                        induzcan a error, o
                                        aquellos cuyo Tratamiento est&eacute; expresamente prohibido o no haya sido
                                        autorizado.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_8-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Solicitar prueba de la autorizaci&oacute;n
                                        otorgada al
                                        Responsable del Tratamiento salvo cuando expresamente se except&uacute;e como
                                        requisito para el
                                        Tratamiento, de conformidad con lo previsto en el art&iacute;culo 10 de la
                                        presente ley.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_8-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Ser informado por el Responsable del Tratamiento
                                        o el
                                        Encargado
                                        del Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos
                                        personales.</span>
                        </li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_8-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Presentar ante la Superintendencia de Industria y
                                        Comercio
                                        quejas
                                        por infracciones a lo dispuesto en la presente ley y las dem&aacute;s normas que
                                        la modifiquen,
                                        adicionen o complementen.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_8-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Revocar la autorizaci&oacute;n y/o solicitar la
                                        supresi&oacute;n
                                        del dato cuando en el Tratamiento no se respeten los principios, derechos y
                                        garant&iacute;as
                                        constitucionales y legales. La revocatoria y/o supresi&oacute;n proceder&aacute;
                                        cuando la
                                        Superintendencia de Industria y Comercio haya determinado que en el Tratamiento
                                        el Responsable o
                                        Encargado han incurrido en conductas contrarias a esta ley y a la
                                        Constituci&oacute;n.</span></li>
                </ul>
                <ul class="c9 lst-kix_list_5-0 start">
                        <li class="c5 c37 li-bullet-0"><span class="c0">Acceder en forma gratuita a sus datos personales
                                        que hayan
                                        sido
                                        objeto de Tratamiento.</span></li>
                </ul>
                <p class="c37 c6"><span class="c0"></span></p>
                <p class="c14"><span class="c1">T&Iacute;TULO VII</span></p>
                <p class="c14 c6"><span class="c1"></span></p>
                <p class="c14"><span class="c1">AUTORIZACI&Oacute;N PARA EL TRATAMIENTO </span></p>
                <p class="c6 c14"><span class="c1"></span></p>
                <p class="c3"><span class="c13">La recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o
                                supresi&oacute;n de
                                datos personales por parte de&nbsp;</span><span class="c4">INNOVBO S.A.S</span><span
                                class="c0">&nbsp;requiere del consentimiento libre, previo, expreso e informado del
                                titular de los
                                mismos.
                                La autorizaci&oacute;n deber&aacute; ser obtenida por cualquier medio que pueda ser
                                objeto de consulta y
                                verificaci&oacute;n posterior.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">La informaci&oacute;n que se recauda es solo para dar cumplimiento legal
                                a lo
                                establecido en la circular 0170 de la DIAN y dando cumplimiento a las pol&iacute;ticas
                                Sarlaft de la
                                empresa, esta informaci&oacute;n no es compartida con terceros para ning&uacute;n
                                concepto.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c4">INNOVBO S.A.S</span><span class="c0">&nbsp;utilizar&aacute; los
                                mecanismos
                                con
                                que cuenta actualmente; e implementar&aacute; y adoptar&aacute; las acciones tendientes
                                y necesarias
                                para
                                mantener registros o mecanismos t&eacute;cnicos o tecnol&oacute;gicos id&oacute;neos de
                                cu&aacute;ndo y
                                c&oacute;mo obtuvo autorizaci&oacute;n por parte de los titulares de datos personales
                                para el
                                tratamiento de
                                los mismos. Para dar cumplimiento a lo anterior, se podr&aacute;n establecer archivos
                                f&iacute;sicos o
                                repositorios electr&oacute;nicos realizados de manera directa o a trav&eacute;s de
                                terceros contratados
                                para
                                tal fin.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c1">CASOS EN QUE NO SE REQUIERE LA AUTORIZACI&Oacute;N</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c0">La autorizaci&oacute;n del Titular no ser&aacute; necesaria cuando se
                                trate
                                de:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_11-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Informaci&oacute;n requerida por una entidad
                                        p&uacute;blica o
                                        administrativa en ejercicio de sus funciones legales o por orden
                                        judicial.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_11-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Datos de naturaleza p&uacute;blica.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_11-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Casos de urgencia m&eacute;dica o
                                        sanitaria.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_11-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Tratamiento de informaci&oacute;n autorizado por
                                        la ley para
                                        fines hist&oacute;ricos, estad&iacute;sticos o cient&iacute;ficos.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_11-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Datos relacionados con el Registro Civil de las
                                        Personas.</span>
                        </li>
                </ul>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO VIII</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">RECOLECCI&Oacute;N DE LA INFORMACI&Oacute;N</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c13">En desarrollo de los principios de finalidad y
                                libertad,&nbsp;</span><span class="c4">INNOVBO S.A.S</span><span class="c0">&nbsp;se
                                limitar&aacute; a solicitar aquellos
                                datos
                                personales que son estrictamente pertinentes y adecuados para la finalidad con que son
                                recolectados para
                                el
                                cumplimiento del objeto social, igualmente no har&aacute; uso de medios enga&ntilde;osos
                                o fraudulentos
                                para
                                recolectar y realizar tratamiento de datos personales.&nbsp;</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO IX</span></p>
                <p class="c10 c6"><span class="c0"></span></p>
                <p class="c10"><span class="c1">RESPONSABLE DE LA ATENCI&Oacute;N DE CONSULTAS Y RECLAMOS</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c13">Toda solicitud de consulta, reclamo, petici&oacute;n de Titulares de
                                datos
                                personales bajo el tratamiento de </span><span class="c4">INNOVBO S.A.S.</span><span
                                class="c0">,
                                para
                                ejercer sus derechos a conocer, actualizar y rectificar ser&aacute;n atendidas por el
                                &aacute;rea
                                administrativa.</span></p>
                <p class="c3 c6"><span class="c13 c39"></span></p>
                <p class="c3"><span class="c13">Dichas solicitudes ser&aacute;n recibidas al correo electr&oacute;nico
                        </span><span class="c13 c15"><a class="c12"
                                        href="mailto:datospersonales@innovbo.com">datospersonales@innovbocom</a></span><span
                                class="c0">,
                                para
                                dar el tr&aacute;mite que corresponda.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO X</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">PROCEDIMIENTOS DE CONSULTA Y RECLAMOS PARA LA ACTUALIZACI&Oacute;N,
                                RECTIFICACI&Oacute;N, CANCELACI&Oacute;N Y OPOSICI&Oacute;N SOBRE SUS DATOS
                                PERSONALES.</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c13">Los Titulares podr&aacute;n consultar la informaci&oacute;n personal del
                                Titular
                                que
                                repose en&nbsp;</span><span class="c4">INNOVBO S.A.S&nbsp;</span><span class="c13">y a
                                su
                                vez&nbsp;</span><span class="c4">INNOVBO S.A.S&nbsp;</span><span
                                class="c0">deber&aacute;
                                suministrar
                                a &eacute;stos toda la informaci&oacute;n contenida en su carpeta de asociado de
                                negocio.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c13">La solicitud podr&aacute; ser presentada por cualquiera de los medios
                                dispuestos
                                por&nbsp;</span><span class="c4">INNOVBO S.A.S</span><span class="c0">.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c15 c4">CONSULTAS</span><span class="c4">&nbsp;</span><span
                                class="c0">&nbsp;</span>
                </p>
                <p class="c3"><span class="c13">Los titulares podr&aacute;n solicitar a&nbsp;</span><span
                                class="c4">INNOVBO
                                S.A.S</span><span class="c13">&nbsp; y la informaci&oacute;n contenida en su carpeta de
                                asociado de
                                negocio,
                                que no sea propiedad intelectual o de car&aacute;cter confidencial de </span><span
                                class="c4">INNOVBO
                                S.A.S</span><span class="c0">&nbsp;pero s&iacute; y en todo momento copia de la
                                informaci&oacute;n
                                proporcionada por este.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c13">Una vez recibida la consulta ser&aacute; atendida en un t&eacute;rmino
                                m&aacute;ximo
                                de </span><span class="c4">diez (10) d&iacute;as h&aacute;biles</span><span
                                class="c13">&nbsp;contados a
                                partir de la fecha de recibo de la misma. Cuando no fuere posible atender la consulta
                                dentro de dicho
                                t&eacute;rmino, se informar&aacute; al interesado, expresando los motivos de la demora y
                                se&ntilde;alando la
                                fecha en que se atender&aacute; su consulta, la cual en ning&uacute;n caso podr&aacute;
                                superar los
                        </span><span class="c4">cinco (5) d&iacute;as h&aacute;biles siguientes </span><span
                                class="c0">al
                                vencimiento
                                del primer t&eacute;rmino</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c15 c4">RECLAMOS</span></p>
                <p class="c3"><span class="c13">El Titular o sus causahabientes que consideren que la informaci&oacute;n
                                contenida
                                en una base de datos debe ser objeto de correcci&oacute;n, actualizaci&oacute;n o
                                supresi&oacute;n, o
                                cuando
                                adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley,
                                podr&aacute;n
                                presentar un reclamo ante&nbsp;</span><span class="c4">INNOVBO S.A.S</span><span
                                class="c0">.,
                                bajos
                                las siguientes reglas:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_7-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c13">El reclamo se formular&aacute; mediante
                                        comunicaci&oacute;n
                                        escrita dirigida a </span><span class="c4">INNOVBO S.A.S., </span><span
                                        class="c0">con la
                                        identificaci&oacute;n del Titular, la descripci&oacute;n de los hechos que dan
                                        lugar al reclamo, la
                                        direcci&oacute;n, y acompa&ntilde;ando los documentos que se quiera hacer
                                        valer.</span></li>
                </ul>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c34"><span class="c13">Si el reclamo resulta incompleto, se requerir&aacute; al interesado
                                dentro
                                de
                                los </span><span class="c4">cinco (5) d&iacute;as</span><span
                                class="c13">&nbsp;siguientes a la
                                recepci&oacute;n del reclamo para que subsane las fallas. Transcurridos </span><span
                                class="c4">dos (2)
                                meses</span><span class="c0">&nbsp;desde la fecha del requerimiento, sin que el
                                solicitante presente la
                                informaci&oacute;n requerida, se entender&aacute; que ha desistido del reclamo.</span>
                </p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3 c34"><span class="c13">En caso que </span><span class="c4">INNOVBO S.A.S.</span><span
                                class="c13">&nbsp;reciba un reclamo que no sea competente para resolverlo, la empresa
                                dar&aacute;
                                traslado a
                                quien corresponda en un t&eacute;rmino m&aacute;ximo de</span><span class="c4">&nbsp;dos
                                (2) d&iacute;as
                                h&aacute;biles</span><span class="c0">&nbsp;e informar&aacute; al Titular.</span></p>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_6-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Una vez recibido el reclamo completo,
                                </span><span class="c4">INNOVBO S.A.S.</span><span class="c13">&nbsp;incluir&aacute; en
                                        la base de datos
                                        una
                                        leyenda que diga </span><span class="c4">&quot;reclamo en
                                        tr&aacute;mite&quot;</span><span class="c13">&nbsp;y el motivo del mismo, en un
                                        t&eacute;rmino no mayor a </span><span class="c4">dos
                                        (2)
                                        d&iacute;as h&aacute;biles</span><span class="c0">. Dicha leyenda deber&aacute;
                                        mantenerse hasta que
                                        el
                                        reclamo sea decidido.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_6-0">
                        <li class="c3 c5 li-bullet-0"><span class="c13">El t&eacute;rmino m&aacute;ximo para atender el
                                        reclamo
                                        ser&aacute; de </span><span class="c4">quince (15) d&iacute;as
                                        h&aacute;biles</span><span class="c13">&nbsp;contados a partir del d&iacute;a
                                        siguiente a la fecha de su recibo. Cuando no
                                        fuere
                                        posible atender el reclamo dentro de dicho t&eacute;rmino, se informar&aacute;
                                        al interesado los
                                        motivos
                                        de la demora y la fecha en que se atender&aacute; su reclamo, la cual en
                                        ning&uacute;n caso
                                        podr&aacute;
                                        superar los </span><span class="c4">ocho (8) d&iacute;as
                                        h&aacute;biles</span><span class="c0">&nbsp;siguientes al vencimiento del primer
                                        t&eacute;rmino.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <p class="c3"><span class="c41 c15 c4">REQUISITO DE PROCEDIBILIDAD</span></p>
                <p class="c3"><span class="c0">El Titular, sus causahabientes, su representante y/o apoderado, o quien
                                se
                                determine
                                por estipulaci&oacute;n a favor de otro; s&oacute;lo podr&aacute; presentar queja ante
                                la
                                Superintendencia
                                de Industria y Comercio una vez haya agotado el tr&aacute;mite de Consulta o Reclamo
                                directamente ante
                                la
                                compa&ntilde;&iacute;a. </span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c15 c4 c41">ACTUALIZACI&Oacute;N Y/O RECTIFICACI&Oacute;N</span></p>
                <p class="c3"><span class="c4">INNOVBO S.A.S</span><span class="c0">&nbsp;tiene la obligaci&oacute;n de
                                rectificar y actualizar a solicitud del titular, la informaci&oacute;n de &eacute;ste
                                que resulte ser
                                incompleta o inexacta, de conformidad con el procedimiento y los t&eacute;rminos arriba
                                se&ntilde;alados. Al
                                respecto se tendr&aacute; en cuenta lo siguiente:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">En las solicitudes de rectificaci&oacute;n y actualizaci&oacute;n de
                                datos
                                personales
                                el titular debe indicar las correcciones a realizar y aportar la documentaci&oacute;n
                                que avale su
                                petici&oacute;n, en los casos que aplique.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c15 c4">REVOCATORIA DE LA AUTORIZACI&Oacute;N</span><span
                                class="c0">&nbsp;</span>
                </p>
                <p class="c3"><span class="c13">Los titulares de los datos personales pueden revocar el consentimiento
                                al
                                tratamiento de sus datos personales en cualquier momento, siempre y cuando no lo impida
                                una
                                disposici&oacute;n legal o contractual. Para ello,&nbsp;</span><span class="c4">INNOVBO
                                S.A.S</span><span class="c0">&nbsp;deber&aacute; establecer mecanismos sencillos y
                                gratuitos que
                                permitan al
                                titular revocar su consentimiento, al menos por el mismo medio por el que lo
                                otorg&oacute;. Se
                                deber&aacute;
                                tener en cuenta que existen dos modalidades en las que la revocaci&oacute;n del
                                consentimiento puede
                                darse.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c13">La primera, puede ser sobre la totalidad de las finalidades consentidas,
                                esto
                                es,
                                que&nbsp;</span><span class="c4">INNOVBO S.A.S</span><span class="c0">&nbsp;deba dejar
                                de tratar
                                por
                                completo los datos del titular; la segunda, puede ocurrir sobre tipos de tratamiento
                                determinados, como
                                por
                                ejemplo para fines publicitarios o de estudios de mercado.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">Con la segunda modalidad, esto es, la revocaci&oacute;n parcial del
                                consentimiento,
                                se mantienen a salvo otros fines del tratamiento que el responsable, de conformidad con
                                la
                                autorizaci&oacute;n otorgada puede llevar a cabo y con los que el titular est&aacute; de
                                acuerdo.&nbsp;</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO XI</span></p>
                <p class="c27"><span class="c1">DEBERES DE INNOVBO S.A.S PARA EL TRATAMIENTO DE DATOS PERSONALES.</span>
                </p>
                <p class="c16 c6 c42"><span class="c1"></span></p>
                <p class="c3"><span class="c4">INNOVBO S.A.S</span><span class="c0">, cuando act&uacute;e como
                                responsable
                                del
                                tratamiento de datos personales, cumplir&aacute; con los siguientes deberes:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Garantizar al Titular, en todo tiempo, el pleno y
                                        efectivo
                                        ejercicio del derecho de h&aacute;beas data.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Solicitar y conservar, copia de la respectiva
                                        autorizaci&oacute;n
                                        otorgada por el titular.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Informar debidamente al titular sobre la
                                        finalidad de la
                                        recolecci&oacute;n y los derechos que le asisten por virtud de la
                                        autorizaci&oacute;n otorgada por
                                        el
                                        Titular.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Conservar la informaci&oacute;n bajo las
                                        condiciones de
                                        seguridad
                                        necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o
                                        acceso no autorizado
                                        o
                                        fraudulento.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Garantizar que la informaci&oacute;n que se
                                        suministre al
                                        encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable
                                        y
                                        comprensible.</span>
                        </li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Actualizar la informaci&oacute;n, comunicando de
                                        forma
                                        oportuna
                                        al encargado del tratamiento, todas las novedades respecto de los datos que
                                        previamente le haya
                                        suministrado y adoptar las dem&aacute;s medidas necesarias para que la
                                        informaci&oacute;n
                                        suministrada a
                                        este se mantenga actualizada.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Rectificar la informaci&oacute;n cuando sea
                                        incorrecta y
                                        comunicar lo pertinente al encargado del tratamiento.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Suministrar al encargado del tratamiento,
                                        seg&uacute;n el
                                        caso,
                                        &uacute;nicamente datos cuyo tratamiento est&eacute; previamente
                                        autorizado.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Exigir al encargado del tratamiento en todo
                                        momento, el
                                        respeto a
                                        las condiciones de seguridad y privacidad de la informaci&oacute;n del
                                        Titular.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Tramitar las consultas y reclamos
                                        formulados.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Informar al encargado del tratamiento cuando
                                        determinada
                                        informaci&oacute;n se encuentra en discusi&oacute;n por parte del Titular, una
                                        vez se haya
                                        presentado la
                                        reclamaci&oacute;n y no haya finalizado el tr&aacute;mite respectivo.</span>
                        </li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Informar a solicitud del Titular sobre el uso
                                        dado a sus
                                        datos.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Informar a la autoridad de protecci&oacute;n de
                                        datos cuando
                                        se
                                        presenten violaciones a los c&oacute;digos de seguridad y existan riesgos en la
                                        administraci&oacute;n de
                                        la informaci&oacute;n de los Titulares.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Hacer uso de los datos personales s&oacute;lo
                                        para aquellas
                                        finalidades para las que se encuentra debidamente facultado.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_1-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Cumplir las instrucciones y requerimientos que
                                        imparta la
                                        Superintendencia de Industria y Comercio.</span></li>
                </ul>
                <p class="c27"><span class="c1">T&Iacute;TULO XII</span></p>
                <p class="c27"><span class="c4">PRERROGATIVAS Y DEM&Aacute;S DERECHOS DE LOS TITULARES DE LA
                                INFORMACI&Oacute;N.&nbsp;</span><span class="c0">&nbsp;</span></p>
                <p class="c6 c29"><span class="c0"></span></p>
                <p class="c3"><span class="c0">En atenci&oacute;n y en consonancia con lo dispuesto en la normatividad
                                vigente y
                                aplicable en materia de protecci&oacute;n de datos personales, el titular de los datos
                                personales tiene
                                los
                                siguientes derechos:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_9-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c13">Acceder, conocer, rectificar y actualizar sus
                                        datos
                                        personales
                                        frente a&nbsp;</span><span class="c4">INNOVBO S.A.S</span><span class="c0">, en
                                        su
                                        condici&oacute;n de responsable del tratamiento.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_9-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Modificar y revocar la autorizaci&oacute;n y/o
                                        solicitar la
                                        supresi&oacute;n del dato cuando en el tratamiento no se respeten los
                                        principios, derechos y
                                        garant&iacute;as constitucionales y legales vigentes.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_9-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Solicitar de forma gratuita la
                                        verificaci&oacute;n de sus
                                        datos
                                        personales que hayan sido objeto de tratamiento.</span></li>
                </ul>
                <p class="c14"><span class="c1">T&Iacute;TULO XIII</span></p>
                <p class="c14 c6"><span class="c1"></span></p>
                <p class="c14"><span class="c1">SEGURIDAD DE LA INFORMACI&Oacute;N Y MEDIDAS DE SEGURIDAD</span></p>
                <p class="c2"><span class="c0"></span></p>
                <p class="c3"><span class="c13">En desarrollo del principio de seguridad establecido en la normatividad
                                vigente,&nbsp;</span><span class="c4">INNOVBO S.A.S</span><span
                                class="c0">&nbsp;adoptar&aacute;
                                las
                                medidas t&eacute;cnicas, humanas y administrativas que sean necesarias para otorgar
                                seguridad a los
                                registros evitando su alteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no
                                autorizado o
                                fraudulento.&nbsp;</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c10"><span class="c4">T&Iacute;TULO XIV </span></p>
                <p class="c10 c6"><span class="c0"></span></p>
                <p class="c10"><span class="c1">TRANSFERENCIA INTERNACIONAL DE DATOS PERSONALES</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c0">Se proh&iacute;be la transferencia de datos personales de cualquier tipo
                                a
                                pa&iacute;ses que no cumplan con los est&aacute;ndares fijados por la Superintendencia
                                de Industria y
                                Comercio y la presente ley.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c3"><span class="c0">Esta prohibici&oacute;n no regir&aacute; cuando se trate de:</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_2-0 start">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Informaci&oacute;n respecto de la cual el Titular
                                        haya
                                        otorgado
                                        su autorizaci&oacute;n expresa e inequ&iacute;voca para la transferencia.</span>
                        </li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_2-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Intercambio de datos de car&aacute;cter
                                        m&eacute;dico, cuando
                                        as&iacute; lo exija el Tratamiento del Titular por razones de salud o higiene
                                        p&uacute;blica.</span>
                        </li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_2-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Transferencias bancarias o burs&aacute;tiles,
                                        conforme a la
                                        legislaci&oacute;n que les resulte aplicable.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_2-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Transferencias acordadas en el marco de tratados
                                        internacionales
                                        en los cuales la Rep&uacute;blica de Colombia sea parte, con fundamento en el
                                        principio de
                                        reciprocidad.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_2-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Transferencias necesarias para la
                                        ejecuci&oacute;n de un
                                        contrato
                                        entre el Titular y el Responsable del Tratamiento, o para la ejecuci&oacute;n de
                                        medidas
                                        precontractuales siempre y cuando se cuente con la autorizaci&oacute;n del
                                        Titular.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c9 lst-kix_list_2-0">
                        <li class="c3 c5 li-bullet-0"><span class="c0">Transferencias legalmente exigidas para la
                                        salvaguardia del
                                        inter&eacute;s p&uacute;blico, o para el reconocimiento, ejercicio o defensa de
                                        un derecho en un
                                        proceso
                                        judicial.</span></li>
                </ul>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">T&Iacute;TULO XV</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c1">DATOS PERSONALES DE NI&Ntilde;OS, NI&Ntilde;AS Y ADOLESCENTES</span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">INNOVBO S.A.S.</span><span class="c0">&nbsp;no realiza de forma directa
                                el
                                tratamiento de datos personales de menores de edad. Sin embargo, la empresa recolecta y
                                trata los datos
                                personales de los hijos menores de edad de sus funcionarios, con la finalidad de cumplir
                                con las
                                obligaciones que impone la ley a los empleadores en relaci&oacute;n con las afiliaciones
                                a parafiscales
                                y al
                                sistema de seguridad social.</span></p>
                <p class="c3 c6"><span class="c0"></span></p>
                <p class="c14"><span class="c1">T&Iacute;TULO XVI</span></p>
                <p class="c14 c6"><span class="c1"></span></p>
                <p class="c14"><span class="c1">AVISO DE PRIVACIDAD</span></p>
                <p class="c2"><span class="c0"></span></p>
                <p class="c3"><span class="c13">El aviso de privacidad de tratamiento de los datos personales
                                est&aacute; para
                                la
                                disposici&oacute;n y consulta a trav&eacute;s de correo
                                electr&oacute;nico&nbsp;</span><span class="c15 c18 c13">datospersonales</span><span
                                class="c15 c18 c13"><a class="c12"
                                        href="mailto:aaaaaaaaaaaaaa@INNOVBO.com">@INNOVBO.com</a></span><span
                                class="c13">&nbsp;o en la
                                p&aacute;gina web &nbsp;</span><span class="c15 c13 c18"><a class="c12"
                                        href="https://www.google.com/url?q=http://www.INNOVBO.com&amp;sa=D&amp;source=editors&amp;ust=1697002803473007&amp;usg=AOvVaw260VE72SAw8zAcc1pG3k_I">www.INNOVBO.com</a></span><span
                                class="c13">&nbsp;dispuestos por la compa&ntilde;&iacute;a. </span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c10"><span class="c4">T&Iacute;TULO XVII</span></p>
                <p class="c10 c6"><span class="c0"></span></p>
                <p class="c10"><span class="c1">MODIFICACI&Oacute;N DE LA POL&Iacute;TICA DE TRATAMIENTO DE DATOS
                                PERSONALES</span>
                </p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3"><span class="c4">INNOVBO S.A.S. </span><span class="c0">se reserva el derecho de modificar
                                la
                                Politica de Tratamiento de Datos Personales en cualquier momento. Cualquier cambio
                                ser&aacute;
                                notificado de
                                forma oportuna a los titulares de los datos personales a trav&eacute;s de los medios
                                establecidos por la
                                compa&ntilde;&iacute;a.</span></p>

                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c14"><span class="c1">CEO</span></p>
                <p class="c14"><span class="c1">INNOVBO S.A.S.</span></p>
                <p class="c3 c6"><span class="c1"></span></p>
                <p class="c10 c6"><span class="c1"></span></p>
                <p class="c3 c6"><span class="c1"></span></p>
        </div>
</main>