<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="titulo color-secondary-dark pt-4 mt-0">
    {{title}}
  </div>
  <section class="discounts-and-bonuses">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Tipo de acción<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="type">
            <mat-option [value]="0">
              Bonificación
            </mat-option>
            <mat-option [value]="1">
              Descuento
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('type').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Nombre<span class="asterisk">*</span></mat-label>
          <input matInput formControlName="name">
          <mat-error *ngIf="form.get('name').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="form.get('name').errors?.minlength">
            {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'nombre', 5) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="button-container">
      <button mat-raised-button class="primary-secondary button-width-auto bg-color-primary" type="button"
        color="primary" (click)="onCancel()">Cancelar</button>
      <button mat-raised-button color="primary" class="button-width-auto bg-color-primary"
        type="submit">Guardar</button>
    </div>
  </section>
</form>