import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Utils } from 'src/app/core/resources/utils';
import { Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material';
import { isEmpty } from 'rxjs/operators';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import haversine from "haversine-distance";
import { UploadService } from './upload.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Patterns } from 'src/app/core/resources/patterns';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { DateManager } from 'src/app/core/managers/date.manager';
import { GoogleService } from 'src/app/core/services/google.service';
import { CargoFeature } from 'src/app/core/interfaces/cargoFeature';
import { Companies } from 'src/app/core/resources/companies';

@Component({
  selector: 'app-upload-freight',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [AuthService, Global]
})

export class UploadComponent implements OnInit {
  stimated_time: any;
  timeList: Array<Object> = [];
  minLengthCellphone: number = 9;
  maxLengthCellphone: number = 12;
  hourList: Array<object> = [];
  minuteList: Array<object> = [];
  minTimePact: number = 1;
  constructor(
    public UploadService: UploadService,
    public manualCreationCargoService: ManualCreationCargoService,
    public snackBarService: SnackBarService,
    public utils: Utils,
    private router: Router,
    private fb: FormBuilder,
    private patterns: Patterns,
    private googleService: GoogleService,
    private authService: AuthService
  ) {
    this.timeList = this.utils.getListTime();
    this.hourList = this.utils.getListHour();
    this.minuteList = this.utils.getListMinute();
  }

  ngOnInit() {
    this.setValidatorsUpload();
  }

  setValidatorsUpload() {
    this.addresses.forEach(address => {
      if (this.isTeclogi) {
        let randomTime = this.calculateTime;
        let emptyTimePact: boolean = !address.get('timePact').value && !address.get('minutePact').value;
        if (randomTime && randomTime === 60) emptyTimePact && address.get('timePact').setValue(1);
        else emptyTimePact && address.get('minutePact').setValue(randomTime);
      } else {
        address.get('timePact').setValidators(Validators.min(this.minTimePact));
        !address.get('timePact').value && address.get('timePact').setValue(this.minTimePact);
      }
      address.get('time').setValidators(Validators.required);
      address.get('cellphone').setValidators([Validators.minLength(this.minLengthCellphone), Validators.maxLength(this.maxLengthCellphone), Validators.pattern(this.patterns.CELLPHONEANDLANDLINE.source)]);
    })
  }

  get addresses() {
    return (this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.origin.addresses') as FormArray).controls;
  }

  get addressesDst() {
    return (this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.destination') as FormArray).controls;
  }

  onSelectTime($event: MatButtonToggleChange, key: string, index: number) {
    this.manualCreationCargoService.getAddressGroupActive(index).get(key).setValue($event.value);
    const addressControl = this.getAddressGroupActive(index);
    const address: AddressCargo = addressControl.value;
    this.setValidationsTime(address, index);
    //check next address if exists
    const addresses: AddressCargo[] = this.getAddresses();
    const currentIndex: number = addresses.findIndex(addressIn => addressIn.location.lat === address.location.lat && addressIn.location.lng === address.location.lng);
    if (currentIndex !== -1) {
      if (addresses && addresses[currentIndex + 1] && addresses[currentIndex + 1].time) this.setValidationsTime(addresses[currentIndex + 1], Number(addresses[currentIndex + 1].id))
    }
  }

  private getAddressGroupActive(index: number): AbstractControl {
    let ctrl: AbstractControl;
    if (index >= 0) ctrl = this.manualCreationCargoService.cargoForm.get(`cargoFeature.uploadDownload.origin.addresses.${index}`);
    return ctrl;
  }

  onKeyUp($event: any, key: string, index: number) {
    this.manualCreationCargoService.getAddressGroupActive(index).get(key).setValue(
      $event.target.value
    );
  }

  findErrorOnOrigin() {
    let haveErrors = false;
    for (let [i, address] of this.addresses.entries()) {
      if (!haveErrors) {
        if (this.utils.errorMessagesCustomized(address.get('time'), `hora de cargue de la dirección ${i + 1}`)) {
          haveErrors = true;
          break;
        }
        else if (this.utils.errorMessagesCustomized(address.get('cellphone'), `celular de la dirección ${i + 1}`, this.minLengthCellphone, this.maxLengthCellphone)) {
          haveErrors = true;
          break;
        }
      } else break;
    }
    return haveErrors;
  }

  setValidationsTime(address: AddressCargo, index: number) {
    this.isValidTime(address,
      (valid: boolean, travelTime: number) => {
        const required = DateManager.durationFormat(travelTime, 'seconds')
        const timeRequired = `${required.days ? required.days + ' día/s, ' : ''}${required.hours} hora/s y ${required.minutes} minuto/s`;
        this.normalize(index);
        if (!valid) this.manualCreationCargoService.estimatedTimesOrigin[index] = timeRequired;
        else this.manualCreationCargoService.estimatedTimesOrigin[index] = false;
      }
    );
  }

  public isValidTime(address, callback) {
    const addresses = this.getAddresses();
    const locations = [];
    addresses.forEach(address => locations.push(address.location))
    const currentIndex = locations.findIndex(elem => elem.lat === address.location.lat && elem.lng === address.location.lng);
    if (currentIndex > 0) {
      this.googleService.calculateMinRouteTime(
        locations[currentIndex],
        locations[currentIndex - 1],
        (response, status) => {
          if (status !== 'OK')
            return;
          const times: Array<number> = [];
          const distance: Array<number> = [];
          const description: Object = {}
          response.routes.forEach(route => {
            route.legs.forEach(leg => {
              times.push(leg.duration.value);
              distance.push(leg.distance.value);
              description[`${leg.duration.value}`] = { duration: leg.duration.text, distance: leg.distance.text };
            });
          });
          const origin = addresses[currentIndex - 1];
          let timePact: number = (origin.timePact * 60 * 60) + (origin.minutePact * 60);
          let min = ((4 / 5) * Math.min(...times)) + timePact;
          const destination = addresses[currentIndex];
          const initialTime = DateManager.setTimeToDate(
            DateManager.stringToDate(origin.date.slice(0, 10)),
            parseInt(origin.time.split(':')[0]) + (origin.timeType === "PM" && parseInt(origin.time.split(':')[0]) < 12 ? 12 : 0),
            parseInt(origin.time.split(':')[1])
          );
          const finalTime = DateManager.setTimeToDate(
            DateManager.stringToDate(destination.date.slice(0, 10)),
            parseInt(destination.time.split(':')[0]) + (destination.timeType === "PM" && parseInt(destination.time.split(':')[0]) < 12 ? 12 : 0),
            parseInt(destination.time.split(':')[1])
          );
          const initialUTC = initialTime.getTime();
          const finalUTC = finalTime.getTime();
          const duration = (finalUTC - initialUTC) / 1000;
          const isValid: boolean = !((duration - min) < 0);
          callback(isValid, min);
          return;
        }
      );
    }
    callback(true, 0);
  }

  getAddresses(): Array<AddressCargo> {
    let addressesMod = this.utils.clone(this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.get('origin.addresses').value);
    addressesMod.forEach((address: AddressCargo, index: number) => {
      address['date'] = this.dateLoad;
      address['id'] = index.toString();
    })
    return addressesMod;
  }

  public normalize(iAddress: number) {
    if (this.manualCreationCargoService.estimatedTimesOrigin[iAddress] === undefined) this.manualCreationCargoService.estimatedTimesOrigin[iAddress] = false;
  }

  async nextStep() {
    this.addresses.forEach(address => address.markAllAsTouched());

    const isValidDates = await this.manualCreationCargoService.isValidAllDateTimeLoad();


    if (this.manualCreationCargoService.isValidUploadAddressesAdditionalData() && isValidDates) {
      // cargue
      this.manualCreationCargoService.getComponentActive().setId(3);
      this.manualCreationCargoService.getComponentActive().getStep().setId(2);
      this.router.navigate([this.manualCreationCargoService.steps.download.url]);
    }
    else {
      if (!this.manualCreationCargoService.isValidUploadAddressesAdditionalData()) {
        if (!this.findErrorOnOrigin()) this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'error');
      }
      else this.snackBarService.openSnackBar(Fmt.string(FormMessages.TIME_INVALID2, 'cargue'), undefined, 'alert');
    }
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(2);
    this.manualCreationCargoService.getComponentActive().getStep().setId(6);
    this.router.navigate([this.manualCreationCargoService.steps.sizeLoad.url]);
  }



  //GETTERS
  private get dateLoad(): string {
    return this.manualCreationCargoService.cargoForm.value.dateLoad;
  }

  getPreviosAddTime(index: number): string {
    let addresses: AddressCargo[] = this.getAddresses();
    if (index >= 0 && addresses && addresses.length && addresses[index] && addresses[index - 1]) return addresses[index - 1].time;
    return '';
  }

  get isTeclogi(): boolean {
    return this.authService.getUserSession().clientCompanyId === Companies.companiesNIT.TECLOGI;
  }

  get calculateTime(): number {
    const min = 45;
    const max = 60;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

}
