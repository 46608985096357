import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Titles } from 'src/app/core/resources/titles';
import { Company } from 'src/app/core/interfaces/company';
import { SelectCompanyService } from './state-guide.service';
import { MatAutocompleteSelectedEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { UploadGuideService } from '../upload-guide/upload-guide.service';
import { CargoDetailService } from '../../cargo/cargo-detail/cargo-detail.service';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-state-guide',
  templateUrl: './state-guide.component.html',
  styleUrls: ['./state-guide.component.scss'],
  providers: [AuthService, SelectCompanyService]
})
export class StateGuideComponent extends ReactiveForm implements OnInit {
  @Input() nit: string;
  @Input() idCompany: string;
  @Input() guideId: string;
  listCompanies: Company[];
  listCompaniesAsync: Observable<any[]>;
  companyCtrl: FormControl = new FormControl('');
  listGuideStates = [];
  @ViewChild('timePicker', { static: true }) timePicker: ElementRef;
  timeValue: string;

  constructor(
    private authService: AuthService,
    private titles: Titles,
    public utils: Utils,
    private global: Global,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<StateGuideComponent>,
    public formBuilder: FormBuilder,
    private uploadGuideService: UploadGuideService,
    private cargoDetailService: CargoDetailService,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: {
      dialog, nit, guideId
    },
  ) {
    super(formBuilder, {
      state: '',
      date: '',
      time: ''
    });
    this.setValidatorsForm({
      state: [
        Validators.required,
      ],
      date: [
        Validators.required,
      ],
      time: [
        Validators.required,
      ]
    }, this.form);
    this.form.get('date').setValue(
      DateManager.dateToString(new Date(), 'YYYY-MM-DD')
    );
    this.validateParamsDialog();
  }

  ngOnInit() {
    this.getStatesGuides(this.nit);
  }

  validateParamsDialog() {
    if (this.paramsDialog) {
      if (this.paramsDialog.nit) {
        this.nit = this.paramsDialog.nit;
      }
      if (this.paramsDialog.guideId) {
        this.guideId = this.paramsDialog.guideId;
      }
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('state'), 'estado')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('date'), 'fecha')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('time'), 'hora')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
        return;
      }
    } else {
      const date = DateManager.formatDate(this.form.value.date + ' ' + this.timeValue, 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD HH:mm ZZ')
      this.spinner.show();
      this.cargoDetailService.setGuideStates(this.guideId, this.form.get('state').value, this.nit, date).subscribe(
        (data) => {
          this.closeDialog(true);
          this.spinner.hide();
          this.snackBarService.openSnackBar('Se ha actualizado el estado de la guía ' + this.guideId + ' correctamente');
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error actualizando el estado de la guía ' + this.guideId, undefined, 'error');
        }
      );
    }
  }

  getStatesGuides(nit) {
    if (!this.utils.isEmpty(nit)) {
      this.uploadGuideService.getGuideStates(nit).subscribe(
        (data) => {
          if (data && data.length) {
            this.listGuideStates = data;
          } else {
            this.listGuideStates = [];
          }
        },
        (error: any[]) => {
          this.snackBarService.openSnackBar('Ocurrió un error al traer los estados de las guías', undefined, 'error');
          this.listGuideStates = [];
        }
      );
    }
  }


  closeDialog(state) {
    this.dialogRef.close({
      state
    });
  }

  onChangeDatePicker($event) {
    this.form.patchValue({
      date: DateManager.dateToString($event.value, 'YYYY-MM-DD')
    });
  }

  onChangeTime() {

    const timeSplit = this.timePicker.nativeElement.value.split(':');
    let hours;
    let minutes;
    let meridian;

    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = 'PM';
      hours -= 12;
    } else if (hours < 12) {
      meridian = 'AM';
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = 'PM';
    }

    if (hours && minutes && meridian) {
      this.timeValue = hours + ':' + minutes + ' ' + meridian;
    } else {
      this.timeValue = null;
    }
  }

}
