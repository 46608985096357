import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../../../core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManualCreationCargoService } from '../../cargo/manual-creation-cargo/manual-creation-cargo.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Utils } from 'src/app/core/resources/utils';
import { environment } from 'src/environments/environment';
import { DocumentTypeEnum } from 'src/app/core/enums/document-type.enum';
import { AccountService } from '../account.service';
import { Patterns } from 'src/app/core/resources/patterns';
import { AuthMessages } from 'src/app/core/messages/auth-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import firebase from 'firebase/app';
import "firebase/auth";
import { MatCheckboxChange, MatDialog, MatDialogConfig } from '@angular/material';
import { ValidateTermsComponent } from '../validate-terms/validate-terms.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { FirebaseApi } from 'src/app/core/classes/firebase-api';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { UserClient } from 'src/app/core/interfaces/userClient';
import { Global } from 'src/app/core/resources/global';
import { UserTerms } from 'src/app/core/interfaces/userTerms';
import { MetadataFormComponent } from '../metadata-form/metadata-form.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./../account.component.scss', './login.component.scss'],
  providers: [AuthService],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  showMessageValidation = false;
  message = "";
  showPassword: boolean = false;
  companyId: string = '';
  withoutGoogle: boolean = false;
  loading: boolean = false;
  showSubcompanyOption: boolean = false;
  showMetadataForm: boolean = false;
  sso: any = null;
  public urlDefaultLogo = this.utils.getDefaultUrlLogo('mainLogo');

  constructor(
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private router: Router,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    public manualCreationCargoService: ManualCreationCargoService,
    public utils: Utils,
    private accountService: AccountService,
    private patterns: Patterns,
    private matDialog: MatDialog,
    private global: Global
  ) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      userCorporateMail: ['', [Validators.required, Validators.pattern(this.patterns.EMAIL.source)]],
      password: ['', Validators.required]
    });
  }

  getForm() {
    return this.loginForm.controls;
  }

  async changeValue($event) {
    this.showMessageValidation = false;
    this.message = '';
    if ($event && $event.srcElement && $event.srcElement.value) this.validateCompany();
    else {
      this.withoutGoogle = false;
      this.loginForm.get('companyName').setErrors({ required: true });
    }
  }

  async onSubmit() {
    if (this.loginForm.get('companyName').invalid) {
      if (this.utils.errorMessagesCustomized(this.loginForm.get('companyName'), 'compañia')) return;
    }
    if ((this.companyId === environment.rootNit) && !this.withoutGoogle) await this.withGoogle();
    else await this.logInWithoutGoogle();
  }


  onChangeSubcompanyOption(event: MatCheckboxChange) {
    this.withoutGoogle = event.checked;
  }

  private comparableString(string: string): string {
    return string.normalize('NFD').replace(/\p{Diacritic}/gu, '').toUpperCase();
  }

  async validateCompany() {
    this.showSubcompanyOption = false;
    let inputCompany = this.loginForm.get('companyName').value.toUpperCase().replace(/\s+/g, ' ').trim();

    let matchCompany = this.accountService
      .listRegisterCompanies()
      .find(company => {
        const commonName = this.comparableString(company.commonName);
        const businessName = this.comparableString(company.businessName);
        const search = this.comparableString(inputCompany);
        return [commonName, businessName].includes(search);
      });
    if (matchCompany && matchCompany.nit)
      this.companyId = matchCompany.nit;
    else {
      this.loginForm.get('companyName').setErrors({ notFound: true });
      this.companyId = ''
    };
    sessionStorage.setItem('_companyId', this.companyId);
    if (this.companyId) {
      const loadConfig = await FirebaseApi.configFirebaseByCompanyId(this.companyId);
      if (loadConfig) {
        this.loginForm.get('companyName').setErrors(null);
      } else {
        this.loginForm.get('companyName').setErrors({ notFound: true });
        return;
      }
    }
    if (matchCompany) {
      if (matchCompany.commonName === 'TECLOGI') {
        this.withoutGoogle = false;
        this.showSubcompanyOption = true;
      } else this.withoutGoogle = true;
    } else this.withoutGoogle = false;

  }

  async logInWithoutGoogle() {
    if (this.loginForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.loginForm.get('companyName'), 'compañia')) return;
      else if (this.utils.errorMessagesCustomized(this.loginForm.get('userCorporateMail'), 'correo')) return;
      else if (this.utils.errorMessagesCustomized(this.loginForm.get('password'), 'contraseña')) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    this.signIn();
  }

  signIn() {
    this.spinner.show();
    const email = (this.getForm().userCorporateMail.value as string); //.toLowerCase()

    this.authService.signIn(email, this.getForm().password.value)
      .then((result) => {
        this.authService.setToken(result.user.uid);
        this.getDetailUserByEmail(email);
      }).catch((error) => {
        var errorMessage = '';
        this.showMessageValidation = true;
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = "La dirección de correo electrónico ya está en uso por otra cuenta."
            break
          case 'auth/wrong-password':
            errorMessage = "La contraseña no es válida o el usuario no tiene una contraseña."
            break;
          case "auth/user-disabled":
            errorMessage = "La cuenta de usuario ha sido deshabilitada por un administrador.";
            break;
          case "auth/user-not-found":
            errorMessage = "El usuario no está registrado";
            break;
          case "auth/internal-error":
            errorMessage = "Usuario o contraseña incorrectos";
            break;
          default:
            errorMessage = 'Ocurrió un error para acceder a la cuenta';
            break;
        }
        this.message = errorMessage;
        this.spinner.hide();
      });
  }

  async withGoogle() {
    this.authService.signInGoogle().then((user) => {
      this.authService.setToken(user.user.uid);
      this.getDetailUserByEmail(user.user.email, user);
    });
  }

  withCredential(credential: firebase.auth.AuthCredential) {
    this.authService.signInCredential(credential)
      .then(
        user => {
          this.authService.setToken(user.user.uid);
          this.getDetailUserByEmail(user.user.email, user);
        },
        error => {
          switch (error.code) {
            case 'auth/admin-restricted-operation':
              this.snackBarService.openSnackBar(AuthMessages.ACCOUNT_NOT_FOUND, undefined, "error");
              break;
            case 'auth/user-disabled':
              this.snackBarService.openSnackBar(AuthMessages.ACCOUNT_DISABLED, undefined, "error");
              break;
            default:
              this.snackBarService.openSnackBar(error.message, undefined, "error");
              break;
          }
        }
      );
  }

  private getInformationFromMeta(meta) {
    let documentTypeId, documentTypeName, document, sigla;

    [sigla, document] = meta.additionalUserInfo.profile.document_id.split(':');
    switch (sigla) {
      case DocumentTypeEnum.CEDULA_CIUDADANIA:
        documentTypeId = '1';
        documentTypeName = 'Cédula de ciudadanía';
        break;
      case DocumentTypeEnum.PASAPORTE:
        documentTypeId = '2';
        documentTypeName = 'Pasaporte';
        break;
      case DocumentTypeEnum.NIT:
        documentTypeId = '3';
        documentTypeName = 'NIT';
        break;
      default:
        throw new Error();
    }

    return {
      document,
      documentTypeId,
      documentTypeName,
      name: `${meta.additionalUserInfo.profile.name} ${meta.additionalUserInfo.profile.lastname ? meta.additionalUserInfo.profile.lastname : ''}`,
    };
  }

  getDetailUserByEmail(email: string, sso_meta?: any) {
    const $ = this.authService.getFullUserByEmail(email)
      .subscribe(
        {
          next: () => {
            this.spinner.hide();
            if (
              this.utils.isDefined(this.authService.getUserSession().information.document) &&
              !this.utils.isEmpty(this.authService.getUserSession().information.document)
            ) {
              // this.router.navigate(['/home']);
              location.reload();
            } else if (!!sso_meta) {
              // Registrar al usuario desde el SSO
              let user: UserClient = null;
              try {
                user = {
                  information: this.getInformationFromMeta(sso_meta),
                  email: sso_meta.user.email,
                  clientCompanyId: environment.rootNit,
                  phone: sso_meta.additionalUserInfo.profile.phone,
                  role: this.global.defaultRoles.webUserPendingActivate.id,
                  lastUserTerm: null
                }
              } catch (e) {
                this.requireMetadataForLogin(sso_meta);
                return;
              }
              this.termAndCondition(sso_meta, user);
            } else {
              this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
              this.logOut();
            }
          },
          error: () => {
            this.logOut();
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
            this.spinner.hide();
          },
          complete: () => {
            $.unsubscribe();
          }
        }
      );
  }

  private requireMetadataForLogin(sso_meta) {
    this.sso = sso_meta;
    this.showMetadataForm = true;
  }

  public onSubmitBasicData(result) {
    if (!!result && !!result.documentType && !!result.document && !!result.phone) {
      this.sso.additionalUserInfo.profile.phone = result.phone;
      this.sso.additionalUserInfo.profile.document_id = `${result.documentType}:${result.document}`;
      const user = {
        information: this.getInformationFromMeta(this.sso),
        email: this.sso.user.email,
        clientCompanyId: environment.rootNit,
        phone: this.sso.additionalUserInfo.profile.phone,
        role: this.global.defaultRoles.webUserPendingActivate.id,
        lastUserTerm: null
      }
      this.termAndCondition(this.sso, user);
    }
  }

  public returnToLoginForm() {
    this.authService.logOut();
    this.showMetadataForm = false;
    this.sso = null;
  }

  private termAndCondition(sso_meta, user) {
    const credential = sso_meta.credential;
    const config = new MatDialogConfig();
    config.data = { create: true };
    config.height = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.matDialog.open(ValidateTermsComponent, config).afterClosed().subscribe(
      {
        next: (termsActive: UserTerms) => {
          if (!!termsActive) {
            user.lastUserTerm = {
              date: termsActive.date,
              version: termsActive.version,
              termId: termsActive.termId,
              state: true
            };
            const $createUser = this.accountService.createUser(user).subscribe(
              {
                next: () => {
                  this.logOut().then(
                    () => this.withCredential(credential)
                  );
                },
                error: (error) => {
                  const user = this.authService.getCurrentUserFirebase();
                  if (user) {
                    user.delete();
                  }
                  this.authService.logOut();
                  this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
                },
                complete: () => { $createUser.unsubscribe(); }
              }
            );
          }
        }
      }
    );
  }


  logOut() {
    return this.authService.logOut().then(() => this.authService.clearSession());
  }
}
