<div #mainFreightUpload class="upload__download">
  <div class="upload__download-form" [ngSwitch]="viewActive" *ngIf="manualCreationCargoService.cargoForm">
    <ng-scrollbar [disableOnBreakpoints]="false">
      <div class="width-100 upload__download-form-scroll">
        <!-- Owner -->
        <div class="col-md-12" *ngSwitchCase="'upload'">
          <div *ngIf="!allowCreatingWithoutTruora" class="row" permission-display [module]="permission.cargo.module"
            [functionality]="permission.cargo.changeManifestRequirement">
            <div class="col-md-12">
              <label class="text-strong">¿Requiere Manifiesto?&nbsp;&nbsp;</label>
              <mat-button-toggle-group #group="matButtonToggleGroup"
                [formControl]="manualCreationCargoService.cargoForm.get('ministry')"
                (change)="onChangeManifestOption()">
                <mat-button-toggle [value]="true">
                  Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <app-third-party-consignment [title]="'Propietario de la Carga'"
                [inputFormControl]="manualCreationCargoService.cargoForm['controls'].cargoOwner"
                (emitToParent)="onSelectOwner($event)"></app-third-party-consignment>
            </div>
          </div>
          <div class="row" *ngIf="ownerSelected && ownerSelected.branchOffices">
            <div class="col-md-12">
              <app-branch-office-owner-cargo [title]="'Sucursal'"
                [config]="{value: manualCreationCargoService.getBranchOfficeSelected()}"
                (selectedBranch)="onSelectBranch($event)" [companyNit]="ownerSelected.companyId">
              </app-branch-office-owner-cargo>
            </div>
          </div>
          <div class="row" *ngIf="ownerSelected && branchOfficeSelected && branchOfficeSelected.subBranchOffices">
            <div class="col-md-12">
              <app-branch-office-owner-cargo [title]="'Sub Sucursal'"
                [config]="{value: manualCreationCargoService.getSubBranchOfficeSelected()}"
                (selectedBranch)="onSelectSubBranch($event)" [listBranch]="branchOfficeSelected.subBranchOffices">
              </app-branch-office-owner-cargo>
            </div>
          </div>
        </div>
        <br>
        <app-upload-location *ngSwitchCase="'upload'" [cleanCity]="cleanCity" [companySelected]="ownerSelected"
          (emitToParent)="receiveFromChildren($event)" (removeAddressMarker)="addressRemovedOrigin($event)"
          (clearAllMarkers)="removeAllMarkersOrigin($event)">
        </app-upload-location>
        <app-download-location *ngSwitchCase="'destination'" [cleanCity]="cleanCity"
          (emitToParent)="receiveFromChildren($event)" (removeAddressMarker)="removeOneMarkerOneDestination($event)"
          (removeAllDestinationMarkers)="removeAllMarkersOneDestination($event)">
        </app-download-location>
      </div>
    </ng-scrollbar>
  </div>
  <!-- Map -->
  <div class="upload__download-map">
    <app-standard-map [options]="mapOptions" (clickMap)="onLoadMap($event)"></app-standard-map>
  </div>
</div>