<section class="py-4">
    <form class="text-center" [formGroup]="form" (submit)="onSubmit($event)">
        <div class="w-50 m-auto">
            <mat-form-field appearance="outline">
                <mat-label>Seleccione el rol:</mat-label>
                <mat-select formControlName="role">
                    <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name | roleTranslate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <button mat-raised-button color="primary" class="primary-secondary" type="submit">Cambiar</button>
    </form>
</section>