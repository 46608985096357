import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../resources/utils';

@Pipe({
    name: 'PaymentType'
})
export class PaymentTypePipe implements PipeTransform {

    constructor(
        public utils: Utils
    ) { }

    transform(value: string): string {
        let title = this.utils.clone(value);
        switch (value) {
            case 'advance':
                title = 'Anticipo';
                break;
            case 'extraAdvance':
                title = 'Sobre Anticipo';
                break;
            case 'additionalCost':
                title = 'Servicio Adicional';
                break;
            case 'balance':
                title = 'Saldo';
                break;
        }
        return title;
    }

}
