<section class="row-freight" [ngClass]="{'item-list-selected': isSelected, 'cursor-pointer': canSelectVehicles}"
  [ngStyle]="this.semaphoreState()" (click)="selectVehicle()">

  <div class="row container-date">
    <div class="col-md-2">
      <span class="title-purple" title="{{(vehicle?.state && utils.isDefined(vehicle.state.active) || vehicle?.stateCompany && utils.isDefined(vehicle.stateCompany.active)) ?
          (stateVehicle ? 'Activo' : 'Inactivo'): '-'}}">
        Estado: {{(vehicle?.state && utils.isDefined(vehicle.state.active) || vehicle?.stateCompany &&
        utils.isDefined(vehicle.stateCompany.active)) ?
        (stateVehicle ? 'Activo' : 'Inactivo'): '-'}}
      </span>
    </div>
    <div class="col-md-3">
      <span class="title-purple"
        title="{{utils.isDefined(vehicle) && utils.isDefined(vehicle.vehicleType) && utils.isDefined(vehicle.vehicleType.name) ? vehicle.vehicleType.name : '-'}}">
        Tipo: {{utils.isDefined(vehicle) && utils.isDefined(vehicle.vehicleType) &&
        utils.isDefined(vehicle.vehicleType.name) ? vehicle.vehicleType.name : '-'}}
      </span>
    </div>
  </div>

  <div class="row container-data">
    <div class="col-md-2">
      <div class="label">
        Placa
      </div>
      <div class="text-bold label-field">
        <span title="{{vehicle.id}}">
          {{vehicle.id}}
        </span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="label">
        Propietario
      </div>
      <div class="text-bold label-field">
        <div
          title="{{utils.isDefined(vehicle.owner) && utils.isDefined(vehicle.owner.name) ? vehicle.owner.name : '-'}}">
          {{utils.isDefined(vehicle.owner) && utils.isDefined(vehicle.owner.name) ? vehicle.owner.name : '-'}}
        </div>
        <div
          title="{{utils.isDefined(vehicle.owner) && utils.isDefined(vehicle.owner.document) ? vehicle.owner.document : '-'}}">
          {{utils.isDefined(vehicle.owner) && utils.isDefined(vehicle.owner.document) ? vehicle.owner.document : '-'}}
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="label">
        N° Documento Conductor
      </div>
      <div class="text-bold label-field">
        <span
          title="{{utils.isDefined(vehicle.driver) && utils.isDefined(vehicle.driver.document) ? vehicle.driver.document : '-'}}">
          {{utils.isDefined(vehicle.driver) && utils.isDefined(vehicle.driver.document) ? vehicle.driver.document :
          '-'}}
        </span>
      </div>
    </div>
    <div class="col-md-2">
      <div class="label">
        SOAT:
      </div>
      <div class="text-bold label-field"
        title="{{utils.isDefined(vehicle) && utils.isDefined(vehicle.validSOAT) && utils.isDefined(vehicle.validSOAT.active) ? (vehicle.validSOAT.active ? 'Activo' : 'Inactivo') : '-'}}">
        {{utils.isDefined(vehicle) && utils.isDefined(vehicle.validSOAT) && utils.isDefined(vehicle.validSOAT.active) ?
        (vehicle.validSOAT.description | StateUser) : '-'}}
      </div>
    </div>
    <div class="col-md-2">
      <div class="label">
        RTM:
      </div>
      <div class="text-bold label-field"
        title="{{utils.isDefined(vehicle) && utils.isDefined(vehicle.validRTM) && utils.isDefined(vehicle.validRTM.active) ? (vehicle.validRTM.active ? 'Activo' : 'Inactivo') : '-'}}">
        {{utils.isDefined(vehicle) && utils.isDefined(vehicle.validRTM) && utils.isDefined(vehicle.validRTM.active) ?
        (vehicle.validRTM.description | StateUser) : '-'}}
      </div>
    </div>
  </div>

  <div class="vehicle__alert"
    *ngIf="utils.isDefined(vehicle) && utils.isDefined(vehicle.state) && utils.isDefined(vehicle.state.active) && !vehicle.state.active && utils.isDefined(vehicle.state.description)">
    <div class="alert alert-warning">
      <span class="title-purple" title="{{vehicle.state.description | StateUser}}">
        <i class="fas fa-exclamation-triangle"></i>
        {{vehicle.state.description | StateUser}}
      </span>
    </div>
  </div>
  <div class="vehicle__alert" *ngIf="showAlertBank">
    <div class="alert alert-warning">
      <i class="fas fa-exclamation-triangle"></i>
      {{'La informacion y los documentos bancarios para el pago, en el vehículo -'}}
      <span class="font-weight-bold">{{vehicle.id}}</span>
      {{'- no están validados.'}}
      <small><span class="font-wight-bold underline text-uppercase" (click)="openDetail()">Ir a Validar</span></small>
    </div>
  </div>
  <figure class="icon-arrow-container" (click)="openDetail()">
    <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
  </figure>
</section>
