import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import { Utils } from "src/app/core/resources/utils";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { SelectGpsService } from "./select-gps.service";
import { gpsType } from "src/app/core/interfaces/gpsType";

@Component({
  selector: "app-select-gps",
  templateUrl: "./select-gps.component.html",
  styleUrls: ["./select-gps.component.scss"],
  providers: [AuthService, SelectGpsService],
})
export class SelectGpsComponent implements OnInit {
  @Input() options;
  @Input() inputFormControl: FormControl;
  @Input() validate: string;
  gpsControl = new FormControl();
  gpsSub: Subscription;
  gpsTypes: gpsType[];
  filteredOptions: Observable<string[]>;
  public listGeneralGpsType: Observable<gpsType[]>;
  constructor(private gpsService: SelectGpsService, public utils: Utils) {
    this.getListGpsTypes();
  }

  ngOnInit() {
    this.options && this.options.initialValue ? this.gpsControl.setValue(this.options.initialValue) : this.gpsControl.setValue('');
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    if (validator && validator.required) this.gpsControl.setValidators(Validators.required);
    this.gpsSub = this.gpsControl.valueChanges.subscribe(() => this.inputFormControl && this.inputFormControl.setValue(null));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.validate && this.validate === 'touched') {
      if (this.inputFormControl && this.inputFormControl.invalid) this.gpsControl.markAsTouched();
    }
  }

  getListGpsTypes() {
    this.gpsService.getAllTypesGps().subscribe(
      (success: any) => {
        this.gpsTypes = success.catalog;
        this.createInstanceAutocomplete();
      },
      (error) => { }
    );
  }

  private createInstanceAutocomplete(): void {
    this.listGeneralGpsType = this.gpsControl.valueChanges.pipe(
      startWith(""),
      distinctUntilChanged(),
      map((value) => {
        if (value) {
          return this.gpsTypes.filter((option) => {
            return (
              option.name
                .toLowerCase()
                .indexOf(value.trim().toLowerCase().toString()) !== -1
            );
          });
        } else return [];

      })
    );
  }

  onSelectGps() {
    this.inputFormControl.setValue(this.gpsControl.value);
  }

  ngOnDestroy() {
    if (this.gpsSub) this.gpsSub.unsubscribe();
  }
}
