<ng-template #modalElement let-modal>
  <div class="modal-body wrapper-modal">
    <form (ngSubmit)="onSubmit()" [formGroup]="registerPaymentForm">
      <p class="title">
        Registro de Pagos
      </p>
      <div class="body-content">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4">
            <div class="md-form">
              <div class="text-center">Saldo</div>
              <label class="label-field color-secondary-dark text-center">
                {{ cargo.shippingCost && utils.isDefined(cargo.shippingCost.totalCost) && utils.isDefined(cargo.shippingCost.totalPaid) ?
                ((cargo.shippingCost.totalCost - cargo.shippingCost.totalPaid) | currency:'COP':'code')
                : cargo.shippingCost && utils.isDefined(cargo.shippingCost.totalCost) ? (cargo.shippingCost.totalCost | currency:'COP':'code') : '-' }}
              </label>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-4">
            <div class="md-form">
              <div class="text-center">Saldo Anticipo</div>
              <label class="label-field color-secondary-dark text-center">
                {{ (cargo.shippingCost.totalPaid >= advanceValue ? 'Pagado' : (advanceValue - cargo.shippingCost.totalPaid) | currency:'COP':'code' ) }}
              </label>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-3">
            <div class="md-form">
              <div class="text-center">Total pagado</div>
              <label class="label-field color-secondary-dark text-center">
                {{ cargo.shippingCost.totalPaid | currency:'COP':'code' }}
              </label>
            </div>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6">
            <mat-card-content>
              <div class="md-form">
                <label for="typePaymentField" class="label-field">Tipo de pago</label>
                <mat-form-field>
                  <mat-select formControlName="type" id="typePaymentField" [(ngModel)]="typePaymentSelected" (selectionChange)="onChangeTypePayment()">
                    <mat-option value="extraAdvance">
                      Sobre anticipo
                    </mat-option>
                    <mat-option value="advance" *ngIf="cargo.shippingCost.totalPaid < advanceValue">
                      Anticipo
                    </mat-option>
                    <mat-option value="balance">
                      Saldo
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-card-content>
          </div>
          <div class="col-12 col-sm-12 col-md-6">
            <mat-card-content *ngIf="typePaymentSelected === 'extraAdvance'">
              <div class="md-form">
                <label for="amoutField" class="label-field">Valor del pago</label>
                <input mask="separator.0" thousandSeparator="." matInput class="form-control" name="amountPayment" formControlName="amount" id="amoutField" min="0" (keyup)="onKeyUpAmount($event)">
                <span *ngIf="!amountValid" class="txt-amount-invalid">
                  El monto ingresado supera el 70% del valor del flete.
                </span>
              </div>
            </mat-card-content>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-12 text-right">
            <label class="title-add-evidency color-secondary-dark" (click)="addProofForm()">Agregar nueva evidencia de pago</label>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="row">
              <div *ngFor="let proof of formProof.controls; let i = index" class="document-types col-12 col-sm-12 col-md-6">
                <button class="btn-delete-proof" (click)="deleteFile(i, true)" *ngIf="instancesFilesStorage.length > 1">-</button>
                <label class="document-type" [formGroup]="proof">
                  <input type="file" *ngIf="!instancesFilesStorage[i].fileData.url" id="fileLetterRetirement{{i}}" class="display-none" (change)="handleFileInput($event, i)">
                  <div class="icon-type" [ngClass]="{'file-loaded': (instancesFilesStorage[i].storageData.uploadProgress | async) === 100}">
                    <img *ngIf="(instancesFilesStorage[i].storageData.uploadProgress | async) !== 100" src="assets/svg/icons/icon-upload.svg" alt="">
                    <img *ngIf="(instancesFilesStorage[i].storageData.uploadProgress | async) === 100" src="assets/svg/icons/icon-correct.svg" alt="">
                  </div>
                  <div class="delete-container" *ngIf="instancesFilesStorage[i].fileData.url">
                    <img (click)="deleteFile(i)" src="assets/svg/icons/icon-delete.svg" alt="">
                  </div>
                  <div class="data-type">
                    <label class="label-field title-letter">Evidencia de pago {{i + 1}}</label>
                    <br>
                    <label *ngIf="(instancesFilesStorage[i].storageData.uploadProgress | async) === 100" class="label-field label-file">
                      <a class="file-name-container" *ngIf="instancesFilesStorage[i].fileData.url" [href]="instancesFilesStorage[i].fileData.url" target="_blank">
                        <i class="fas fa-paperclip"></i>
                        <span class="file-name" title="{{instancesFilesStorage[i].fileData.name}}">{{instancesFilesStorage[i].fileData.name}}</span>
                        <span class="file-size" title="{{instancesFilesStorage[i].fileData.size}}">{{instancesFilesStorage[i].fileData.size}}</span>
                      </a>
                    </label>
                    <span *ngIf="!(instancesFilesStorage[i].storageData.uploadProgress)" class="label-load-file">
                      <a>Cargar archivo</a>
                    </span>
                    <div *ngIf="(instancesFilesStorage[i].storageData.uploadProgress) && (instancesFilesStorage[i].storageData.uploadProgress | async) !== 100">
                      <mat-progress-bar [value]="instancesFilesStorage[i].storageData.uploadProgress | async">
                      </mat-progress-bar>
                    </div>
                  </div>
                </label>
              </div>
            </div>

          </div>
        </div>

      </div>
      <mat-card-actions>
        <button mat-raised-button class="primary-secondary" color="primary" (click)="closeModal()">Cancelar</button>
        <button mat-raised-button color="primary" type="submit">Confirmar pago</button>
      </mat-card-actions>
    </form>
  </div>
</ng-template>
