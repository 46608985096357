import { Component, ElementRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReceivableService } from '../receivable.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-receivable-modifications-detail',
  templateUrl: './receivable-modifications-detail.component.html',
  styleUrls: ['./receivable-modifications-detail.component.scss']
})
export class ReceivableModificationsDetailComponent implements OnInit {
  @Input() listReceivables: any;
  @Input() holder: any;
  @Input() type: string;
  @Output() modificationApproved: EventEmitter<any> = new EventEmitter();
  rotateArrowStates: boolean[] = [];
  onClickDiv(index: number) {
    this.rotateArrowStates[index] = !this.rotateArrowStates[index];
  }

  constructor(
    public dialogRef: MatDialogRef<ReceivableModificationsDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      receivable,
      holder?
    },
    public receivableService: ReceivableService,
    public spinner: NgxSpinnerService,
    public snackBarService: SnackBarService
  ) { }

  ngOnInit() { }
  modificationState(state: boolean, receivableId: string, modificationId: string) {
    const stateString = state.toString();
    this.spinner.show();
    const observerModifications = {
      next: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(state ? "Se ha aprobado la modificación exitosamente" : "Se ha procesado correctamente la no aprobación de la modificación a la cartera", undefined, "success");
        this.modificationApproved.emit(true);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Hubo un error procesando la aprobación", undefined, "error")
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.receivableService.approveModificationReceivable(stateString, receivableId, modificationId).subscribe(observerModifications);
  }

  getValidReceivables(modifications) {
    return modifications.filter(modification => modification.state && modification.state.description && modification.state.description !== 'Rejected');
  }
}
