<div class="modal-body wrapper-modal m-2 position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <p class="title mx-5">
        Asocie la ciudad principal
    </p>
    <app-autocomplete-city [inputFormControl]="cityControl" [options]="optionsCity" [validate]="validate">
    </app-autocomplete-city>
    <div class="mt-2 field__card">
        <div>
            <div class="field__card--label">Ciudad</div>
            <div class="field__card--value">{{city.name}}</div>
        </div>
        <div class="mt-1">
            <div class="field__card--label">Reteica actual</div>
            <div class="field__card--value">{{reteica(city)}}</div>
        </div>
        <div *ngIf="newMainCity" class="mt-1">
            <div class="field__card--label">Nuevo reteica</div>
            <div class="field__card--value">{{reteica(newMainCity)}}</div>
        </div>
    </div>
    <mat-card-actions class="mb-0">
        <button mat-raised-button class="primary button-width-auto" color="primary" (click)="changeMainCity()">
            Confirmar
        </button>
    </mat-card-actions>
</div>