import { Component, OnInit } from '@angular/core';
import { FirebaseApi } from 'src/app/core/classes/firebase-api';

@Component({
  selector: 'app-alertrndc',
  templateUrl: './alertrndc.component.html',
  styleUrls: ['./alertrndc.component.scss']
})
export class AlertrndcComponent {
  state: 'Open' | 'Closed' = 'Open';
}
