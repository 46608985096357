import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { VehiclesService } from '../../vehicles/list-vehicles.service';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { MatDialog, MatDialogConfig, PageEvent } from "@angular/material";
import { DetailVehicleComponent } from '../../vehicles/detail-vehicle/detail-vehicle.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { ReceivableModificationsDetailComponent } from '../receivable-modifications-detail/receivable-modifications-detail.component';
import { Receivable } from 'src/app/core/interfaces/receivable';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ReceivableService } from '../receivable.service';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
@Component({
  selector: 'app-receivable-detail',
  templateUrl: './receivable-detail.component.html',
  styleUrls: ['./receivable-detail.component.scss'],
  providers: [
    VehiclesService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivableDetailComponent implements OnInit {
  @Input() receivables;
  @Input() storageInputs;
  @Input() emptyStorage: boolean;
  @Input() resetPagination: boolean;
  @Input() cleanFields: boolean;
  @Output() emitFilters: EventEmitter<any> = new EventEmitter();
  @Output() refreshListReceivable: EventEmitter<boolean> = new EventEmitter();
  vehicle: Vehicle;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSizeOptions: number[] = [2, 5]; // Opciones de tamaño de página
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  receivablesSlice = [];
  permission = Permission;
  constructor(
    public vehiclesService: VehiclesService,
    public matDialog: MatDialog,
    private spinner: NgxSpinnerService,
    private receivableService: ReceivableService,
    private snackBarService: SnackBarService,
    public permissionRole: PermissionRole
  ) { }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = "Carteras por página";
    if (sessionStorage.getItem('_pageSizeReceivable')) sessionStorage.removeItem('_pageSizeReceivable')
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.receivables && changes.receivables.currentValue) {
      if (this.receivables) {
        if (sessionStorage.getItem('_pageSizeReceivable')) {
          this.receivablesSlice = this.receivables.slice(0, Number(sessionStorage.getItem('_pageSizeReceivable')))
        } else {
          this.receivablesSlice = this.receivables.slice(0, 2);
        }
      }
    }
    if (changes && changes.resetPagination && this.resetPagination !== undefined) {
      this.pageIndex = 0;
    }
  }

  getDetailVehicle(licensePlate: string, type: string, receivable?) {
    if (!this.showVehicleDetail) return;
    this.spinner.show();
    if (licensePlate) {
      this.vehiclesService.getVehicle(licensePlate).subscribe(
        (success: any) => {
          this.spinner.hide();
          if (success) {
            this.vehicle = success;
            if (type === 'detail') this.openDetailVehicle();
            if (type === 'info' && receivable) this.openDetailModifications(receivable);
          } else {
            this.vehicle = null;
          }
        },
        (error) => {
          this.spinner.hide();
          this.vehicle = null;
        }
      );
    }
  }

  openDetailVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle: this.vehicle,
      receivable: true,
      close: true,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DetailVehicleComponent, dialogConfig);
  }

  onPageChange(event: PageEvent) {
    sessionStorage.setItem('_pageSizeReceivable', event.pageSize.toString());
    this.pageIndex = event && (event.pageIndex || event.pageIndex === 0) ? event.pageIndex : this.pageIndex;
    this.pageSize = event && event.pageSize ? event.pageSize : this.pageSize;
    const startIndex = this.pageIndex * this.pageSize;
    let endIndex = startIndex + this.pageSize;
    if (endIndex > this.receivables.length) {
      endIndex = this.receivables.length
    }
    // Actualiza el arreglo 'receivables' con los elementos de la página actual
    this.receivablesSlice = this.receivables.slice(startIndex, endIndex);
  }

  openDetailModifications(receivable: Receivable) {
    if (this.vehicle && this.vehicle.bankAccountBalance && this.vehicle.bankAccountBalance.document) {
      var holder = {
        name: this.vehicle.bankAccountBalance.name,
        document: this.vehicle.bankAccountBalance.document,
        documentTypeName: this.vehicle.bankAccountBalance.documentTypeName
      };
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      receivable: receivable,
      holder: holder
    }
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(ReceivableModificationsDetailComponent, dialogConfig);
  }

  removeReceivable(receivableId: string, receivableConsecutive: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Está seguro que quiere eliminar la cartera #${receivableConsecutive}?`,
      textArea: true,
      description: "Por favor indique la razón por la cual desea eliminar la cartera: "
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.message) {
        const deleteObserver = {
          next: () => {
            this.refreshListReceivable.emit(true);
            this.spinner.hide();
            this.snackBarService.openSnackBar(CargoMessages.SUCCESS_RECEIVABLE_DELETE, undefined, "success");
          },
          error: (error) => {
            this.spinner.hide();
            if (error && error.error && error.error.message) this.snackBarService.openSnackBar(error.error.message, undefined, 'error');
            else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
          },
          complete: () => {
            this.spinner.hide();
          }
        };
        this.spinner.show();
        let observation = {
          message: result.message
        }
        this.receivableService.deleteReceivable(receivableId, observation).subscribe(deleteObserver);
      }
    });
  }

  get showVehicleDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readVehicleDetails);
  }
}


