import { Injectable } from '@angular/core';
import haversine from "haversine-distance";
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  zero: boolean = false;
  times: any;
  active = [];
  timeOrigin24h: string;
  addressOrigin = [];
  address: string = "";
  originAddLat: Number = 0;
  originAddLng: Number = 0;
  destinyAddLat: Number = 0;
  destinyAddLng: Number = 0;
  stimated_time: any;
  timesDiff = [];
  time = [];
  timeOrigin = [];
  timeOrigin1 = {
    time: "",
    timeType: ""
  };
  addressTimeDestination = []
  firstOrigin: string = '';

  constructor() { }



  calcRoute(originLat, originLng, destinyLat, destinyLng, address, addressOrigin, timeOrigin) {
    let start = { latitude: originLat, longitude: originLng };
    let end = { latitude: destinyLat, longitude: destinyLng };

    // Calcula la distancia en kilómetros entre los dos puntos
    if ((start.latitude && start.longitude) !== (end.latitude && end.longitude)) {

      let distance = haversine(start, end) / 1000;
      const velocity = 45;

      // Calcula el tiempo de viaje en horas y minutos
      let time = distance / velocity
      let hours_time = Math.trunc(distance / velocity)
      let minutes_time = (time - hours_time).toString().slice(2, 4);

      if (minutes_time[0] === '0') {
        minutes_time = (time - hours_time).toString().slice(3, 4);
      }
      if (Number(minutes_time) > 60) {
        hours_time = hours_time + 1;
        let minutesInt = Number(minutes_time) - 60;
        let minutesStr = minutesInt.toString();
        minutes_time = minutesStr;
      }

      this.time.push({
        hours_time,
        minutes_time,
        address,
        addressOrigin,
        timeOrigin
      })
      return this.time
    } else {
      this.zero = true;
      return this.zero
    }


  }

  captureDataCoordinates(origin_add: any, destiny_add: any) {
    this.time = [];
    origin_add.forEach(origin => {
      this.originAddLat = origin.value.location.lat;
      this.originAddLng = origin.value.location.lng;
      this.firstOrigin = origin.value.address;
      this.addressOrigin.push(origin.value.address);
      this.timeOrigin.push({
        time: origin.value.time,
        timeType: origin.value.timeType
      })
    })

    this.timeOrigin1.time = this.timeOrigin[0].time;
    this.timeOrigin1.timeType = this.timeOrigin[0].timeType;
    this.addressTimeDestination = []
    destiny_add.forEach(destiny => {

      destiny.value.addresses.forEach(address => {
        this.destinyAddLat = address.location.lat;
        this.destinyAddLng = address.location.lng;
        //toma cada  direccion de cada destino
        this.address = address.address;

        this.addressTimeDestination.push({
          address: this.address,
          active: false
        })
        this.stimated_time = this.calcRoute(this.originAddLat, this.originAddLng, this.destinyAddLat, this.destinyAddLng, this.addressTimeDestination, this.addressOrigin[0], this.timeOrigin1);
        this.addressOrigin = [];
        this.addressOrigin[0] = this.address;
        this.originAddLat = this.destinyAddLat;
        this.originAddLng = this.destinyAddLng;
      })
    })
    this.time[0].addressOrigin = this.firstOrigin
    return this.stimated_time
  }

  convert24(origin_add) {
    this.timeOrigin = [];
    origin_add.forEach(origin => {
      this.timeOrigin.push({
        time: origin.value.time,
        timeType: origin.value.timeType
      })
    })
    this.times = this.timeOrigin[0].time + ' ' + this.timeOrigin[0].timeType;
    this.timeOrigin24h = DateFormatPipe.prototype.transform('01-01-1990 ' + this.times, 'time-24');
    return this.timeOrigin24h
  }



}
