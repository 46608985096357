<section class="main-container">
    <main class="row">
        <div class="col-md-12 subcontainer">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 title-component">
                        <i class="fas fa-project-diagram"></i>
                        Puntos autorizados
                    </div>
                    <div class="col-md-4 text-right">
                        <button (click)="create()" type="button" mat-raised-button color="accent"
                            matTooltip="Agregar un nuevo punto autorizado">
                            <i class="fas fa-plus"></i>
                            Crear punto
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="row">
                            <app-autocomplete-city [options]="cityOptions" [inputFormControl]="cityControl">
                            </app-autocomplete-city>
                            <mat-form-field class="col-md-3" appearance="outline">
                                <mat-label>Tipo</mat-label>
                                <mat-select (selectionChange)="onFilterType($event.value)">
                                    <mat-option *ngFor="let service of services"
                                        [value]="service.id">{{service.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-container">
                            <table class="w-100">
                                <thead>
                                    <th>
                                        Creación
                                        <a role="button">
                                            <i class="fas" [ngClass]="{
                                                'fa-sort-up': sort_order == 0,
                                                'fa-sort-down': sort_order == 1,
                                                'fa-sort': sort_order == 2
                                            }"></i>
                                        </a>
                                    </th>
                                    <th>Tipo</th>
                                    <th>Ciudad</th>
                                    <th>Nombre</th>
                                    <th>Coordenadas</th>
                                    <th>Dirección</th>
                                    <th>Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let site of sites">

                                        <td> {{site?.fingerPrint?.date | dateFormat}} </td>
                                        <td> {{site?.details?.pointType?.name}} </td>
                                        <td> {{site?.city?.name}}</td>
                                        <td> {{site?.details?.name}} </td>
                                        <td> {{site?.location?.lat}}, {{site?.location?.lng}} </td>
                                        <td> {{site?.address}}</td>
                                        <td>
                                            <a type="button" style="color: #584796;" matTooltip="Editar" role="button"
                                                class="table-btn" (click)="edit(site)">
                                                <i class="fas fa-edit"></i>
                                            </a>
                                            <a type="button" style="color: #ee3344;" role="button" matTooltip="Eliminar"
                                                class="table-btn" (click)="deactive(site)">
                                                <i class="fas fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="sites.length === 0">
                                        <td colspan="8" class="text-center">
                                            <hr>
                                            <br>
                                            <br>
                                            <img src="../../../../assets/svg/icons/tl_ico__caja_vacia.svg">
                                            <br>
                                            <p style="font-weight: bold;">
                                                {{
                                                !!filters.length ?
                                                "No hay resultados para ésta búsqueda" :
                                                "Aún no hay puntos autorizados creados"
                                                }}
                                            </p>
                                            <br>
                                            <br>
                                            <br>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</section>