<div class="position-relative">
    <mat-form-field appearance="outline" (mouseenter)="onHover()">
        <mat-label>{{title}}</mat-label>
        <mat-icon matPrefix>
            <img [src]="icon" alt="">
        </mat-icon>
        <input matInput matGoogleMapsAutocomplete type="text" autocomplete="on" (focus)="onFocus()" (blur)="onBlur()"
            (onAutocompleteSelected)="onAutocompleteSelected($event)" (onLocationSelected)="onLocationSelected($event)"
            [country]="['co', 've', 'ec']" [formControl]="control" name="complete-address" id="complete-address-input">
        <mat-icon matSuffix *ngIf="currentlyDraggable" class="draggable">
            <ng-content></ng-content>
        </mat-icon>
    </mat-form-field>
    <img *ngIf="deleteButton" (click)="onRemove()" class="remove-btn"
        src="../../../../../assets/svg/icons/tl_ico__x _purple.svg" alt="Remove button">
</div>