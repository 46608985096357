import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { CargoResources } from '../../../manual-creation-cargo/resources/cargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ShippingCost } from 'src/app/core/interfaces/shippingCost';
import { CargoDetailService } from '../../cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatAutocompleteSelectedEvent, MatTooltip } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap, map } from 'rxjs/operators';
import { TypeMerchandiseService } from '../../../manual-creation-cargo/components/type-merchandise/type-merchandise.service';
import { BodyWorkType } from 'src/app/core/models/body-work-type.model';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { ModelCargo } from '../../../manual-creation-cargo/resources/modelCargo';
import { PackagingType } from 'src/app/core/interfaces/packagingType';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';

@Component({
  selector: 'app-cargo-characteristic',
  templateUrl: './cargo-characteristic.component.html',
  styleUrls: ['./cargo-characteristic.component.scss'],
  providers: [AuthService, Global, ModelCargo]
})
export class CargoCharacteristicComponent extends ReactiveForm implements OnInit {

  @ViewChild('tooltipMerchandise', { static: true }) tooltipMerchandise: MatTooltip;
  otherFreightCost: string;
  showOtherFreightCost: boolean = false;
  @Input() cargo: Cargo;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  filteredTypesMerchandise: Observable<{ id, description }[]>;
  filteredPackagingTypes: PackagingType[];
  public dataVehicle: any;
  public productTypeCtrl: FormControl = new FormControl('', Validators.required);
  productTypeSub: Subscription;
  public vehicleCargo: VehicleCargo[];
  public vehicleSelect = [];
  minAmount: number = 1;
  minTotalWeight: number = 1;
  optionsTypeVehicle: OptionsAutocomplete = {
    showAutocomplete: true
  }
  vehicleTypeControl: FormControl = new FormControl('', Validators.required);
  vehicleTypeSub: Subscription;
  messageTypeMerchandise: string;
  optionsTypeMerchandise: OptionsAutocomplete = {}
  validateTypeMerchandise: string = '';
  validateTypeVehicle: string = '';
  validateBodyworkType: string = '';
  bodyworkTypeControl: FormControl = new FormControl('', Validators.required);
  bodyworkTypeSub: Subscription;
  optionsBodyworkType: OptionsAutocomplete = {
    title: 'Tipo de carrocería'
  };
  onUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  public amountsCargoEnum = AmountsCargoEnum;

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private router: Router,
    public formBuilder: FormBuilder,
    public utils: Utils,
    private cargoResources: CargoResources,
    private snackBarService: SnackBarService,
    private cargoDetail: CargoDetailService,
    private spinner: NgxSpinnerService,
    public modelCargo: ModelCargo,
    private typeMerchandiseService: TypeMerchandiseService,
  ) {
    super(
      formBuilder,
      utils.clone(cargoResources.cargoMock),
      utils.clone(cargoResources.cargoMock)
    );
  }

  ngOnInit() {
    this.setSubscriptionTypeMerchandise();
    this.filteredPackagingTypes = this.modelCargo.packagingTypes.filter(pack => !pack.hidden);
    this.form.patchValue(this.cargo);
    this.messageTypeMerchandise = `El tipo de mercancía seleccionada está catalogada como ${this.cargoNature.get('description').value}`;
    this.optionsTypeMerchandise['initialValue'] = this.cargo.cargoFeature.productType && this.cargo.cargoFeature.productType.name ? this.cargo.cargoFeature.productType.name : 'Sin Tipo de Carga';
    this.getVehicleType();
    if ([CargoStateEnum.CREATED, CargoStateEnum.ACCEPTED].includes(this.cargo.state)) {
      this.packagingType.disable();
      this.validateTypeMerchandise = 'disable';
      this.cargoMeasure.get('totalWeigth').disable();
      this.cargoMeasure.get('unit').disable();
    } else if (this.cargo.cargoModel.serviceType && (this.cargo.cargoModel.serviceType.name === "Carga consolidada" ||
      this.cargo.cargoModel.serviceType.name.includes("Contenedor vacío"))) {
      this.packagingType.disable();
      this.validateTypeMerchandise = 'disable';
    }
    this.setValidators();
    this.setOptions();
    this.setSubscription();
  }

  setOptions() {
    this.optionsTypeVehicle['initialValue'] = this.vehicleType && this.vehicleType.get('name') && this.vehicleType.get('name').value ? this.vehicleType.get('name').value : '';
    this.optionsTypeVehicle = { ...this.optionsTypeVehicle };
    this.optionsBodyworkType['initialValue'] = this.vehicleType && this.vehicleType.get('bodyWorkType') && this.vehicleType.get('bodyWorkType.name') && this.vehicleType.get('bodyWorkType.name').value ? this.vehicleType.get('bodyWorkType.name').value : '';
    this.optionsBodyworkType['initialVehicleTypeName'] = this.vehicleType && this.vehicleType.get('name') && this.vehicleType.get('name').value ? this.vehicleType.get('name').value : '';
    this.optionsBodyworkType = { ...this.optionsBodyworkType };
  }

  setSubscription() {
    this.vehicleTypeSub = this.vehicleTypeControl.valueChanges
      .subscribe(value => {
        if (value) {
          this.vehicleType.get('name').setValue(value.name);
          this.getVehicleType();
          this.optionsBodyworkType['initialVehicleTypeId'] = value.id ? value.id : '';
          this.optionsBodyworkType['initialVehicleTypeName'] = null;
          this.optionsBodyworkType['initialValue'] = '';
          this.optionsBodyworkType = { ...this.optionsBodyworkType };
        } else this.vehicleType.get('name').setValue('');
        this.vehicleType.get('bodyWorkType.name').setValue('');
      })
    this.bodyworkTypeSub = this.bodyworkTypeControl.valueChanges
      .subscribe(value => this.vehicleType.get('bodyWorkType.name').setValue(value && value.name ? value.name : ''));
  }

  setSubscriptionTypeMerchandise() {
    this.productTypeSub = this.productTypeCtrl.valueChanges.subscribe(value => {
      this.cargoNature.get('code').setValue(value && value.cargoNatureId ? value.cargoNatureId : '');
      this.cargoNature.get('description').setValue(value && value.cargoNature ? value.cargoNature : '');
      this.productType.get('name').setValue(value && value.description ? value.description : '');
      this.productType.get('code').setValue(value && value.id ? value.id : '');
      this.productType.get('description').setValue('');
      this.minAmount = value && value.description === "CONTENEDOR VACIO" ? 0 : 1;
      this.minTotalWeight = value && value.description === "CONTENEDOR VACIO" ? 0 : 1;
      this.setValidators();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.cargo && changes.cargo.currentValue) {
      this.form.patchValue(changes.cargo.currentValue);
      if (changes.cargo.currentValue.cargoFeature.productType) {
        this.optionsTypeMerchandise['initialValue'] = changes.cargo.currentValue.cargoFeature.productType.name ? changes.cargo.currentValue.cargoFeature.productType.name : 'Sin Tipo de Carga';
        this.optionsTypeMerchandise = { ...this.optionsTypeMerchandise };
      }
      if ([CargoStateEnum.CREATED, CargoStateEnum.ACCEPTED].includes(changes.cargo.currentValue.state)) {
        this.packagingType.disable();
        this.validateTypeMerchandise = 'disable';
        this.cargoMeasure.get('totalWeigth').disable();
        this.cargoMeasure.get('unit').disable();
      } else if (changes.cargo.currentValue.cargoModel.serviceType && (changes.cargo.currentValue.cargoModel.serviceType.name === "Carga consolidada" ||
        changes.cargo.currentValue.cargoModel.serviceType.name.includes("Contenedor vacío"))) {
        this.packagingType.disable();
        this.validateTypeMerchandise = 'disable';
        if (changes.cargo.currentValue.cargoModel.serviceType.name.includes("Contenedor vacío")) {
          this.minAmount = 0;
          this.minTotalWeight = 0;
        } else {
          this.minAmount = 1;
          this.minTotalWeight = 1;
        }
        this.setValidators();
      } else {
        this.packagingType.enable();
        this.validateTypeMerchandise = 'enable';
        this.cargoMeasure.get('totalWeigth').enable();
        this.cargoMeasure.get('unit').enable();
        this.minAmount = 1;
        this.minTotalWeight = 1;
        this.setValidators();
      }
    }
  }

  get activeDangerousProductType() {
    return this.cargoNature && this.cargoNature.get('description') && this.cargoNature.get('description').value && (this.cargoNature.get('description').value === 'Carga Peligrosa' || this.cargoNature.get('description').value === 'Desechos Peligrosos')
  }

  public getWeightVehicule(idvehicle) {
    this.manualCreationCargoService.getVehicleWeightById(idvehicle).subscribe(response => {
      this.dataVehicle = response;
      this.setValidators();
    })
  }

  setValidators() {
    this.cargoMeasure.get('amount').setValidators([Validators.required, Validators.min(this.minAmount)]);
    this.cargoMeasure.get('totalWeigth').setValidators(
      [Validators.required, Validators.min(this.minTotalWeight), Validators.max(this.dataVehicle ? this.dataVehicle.weight : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG)]
    );
    this.productType.setValidators(Validators.required);
    this.packagingType.get('description').setValidators(Validators.required);
    this.cargoMeasure.get('unit').setValidators(Validators.required);
    this.vehicleType.get('name').setValidators(Validators.required);
    this.vehicleType.get('bodyWorkType.name').setValidators(Validators.required);
    this.form.updateValueAndValidity();
  }

  public getVehicleType() {
    this.spinner.show();
    this.manualCreationCargoService.getVehicleType().subscribe(
      (data: Catalog) => {
        this.spinner.hide();
        this.vehicleCargo = (data.catalog as VehicleCargo[]).filter((item: VehicleCargo) => {
          if (this.form.value && this.form.value.cargoFeature && this.form.value.cargoFeature.vehicleType
            && this.form.value.cargoFeature.vehicleType.name && this.form.value.cargoFeature.vehicleType.name === item.name) {
            return item;
          }
        });
        if (this.vehicleCargo.length) {
          this.getWeightVehicule(this.vehicleCargo[0].id);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  public onSelectPackagingType($event) {
    if ($event && $event.value) {
      this.packagingType.get('description').setValue($event.value);
      this.packagingType.get('code').setValue(this.modelCargo.packagingTypes.find(pack => pack.description === $event.value).code);
    }
  }

  public displayFn(data: any): string {
    return data ? data.description : data;
  }

  public returnFn(data: any): number | undefined {
    return data ? data.id : undefined;
  }

  public onSubmit() {
    this.setValidators();
    this.validateTypeVehicle = 'touched';
    this.validateTypeMerchandise = 'touched';
    this.validateBodyworkType = 'touched';
    delete this.form.get('cargoFeature').value.uploadDownload;
    this.form.get('cargoFeature').markAllAsTouched();
    const cargoFeature = this.utils.clone(this.form.get('cargoFeature').value);
    if (this.utils.errorMessagesCustomized(this.packagingType.get('description'), 'tipo de empaque')) return;
    else if (this.utils.errorMessagesCustomized(this.cargoNature.get('description'), 'tipo de mercancía')) return;
    else if (this.utils.errorMessagesCustomized(this.cargoMeasure.get('totalWeigth'), 'peso total', null, null, this.minTotalWeight, this.dataVehicle ? this.dataVehicle.weight : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG)) return;
    else if (this.cargoMeasure.get('totalWeigth').value > (this.dataVehicle ? this.dataVehicle.weight : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG))
      this.snackBarService.openSnackBar(this.utils.giveMessageError('MAX_VALUE_EXCEED', 'peso total', this.dataVehicle ? this.dataVehicle.weight : AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG), undefined, 'alert');
    else if (this.utils.errorMessagesCustomized(this.cargoMeasure.get('unit'), 'tipo de unidad')) return;
    else if (this.utils.errorMessagesCustomized(this.cargoMeasure.get('amount'), 'valor de la carga', null, null, this.minAmount)) return;
    else if (this.utils.errorMessagesCustomized(this.vehicleType.get('name'), 'tipo de vehículo')) return;
    else if (this.utils.errorMessagesCustomized(this.vehicleType.get('bodyWorkType.name'), 'tipo de carrocería')) return;
    else this.updateCargoCharacterristic(cargoFeature);
  }

  public updateCargoCharacterristic(cargoFeature) {

    delete cargoFeature.productType.cargoNatureId;
    delete cargoFeature.productType.cargoNature;
    if (!this.canBeRefrigerated) cargoFeature.vehicleType.quality = "";


    let data = { id: this.cargo.id, state: this.cargo.state };
    if (this.packagingType.value && this.packagingType.value.description && this.packagingType.value.code && this.cargoNature.value.code) {
      data['cargoModel'] = {};
      data['cargoModel']['packagingType'] = {};
      data['cargoModel']['packagingType']['code'] = this.packagingType.value.code;
      data['cargoModel']['packagingType']['description'] = this.packagingType.value.description;

      if (this.vehicleType.value.quality && this.vehicleType.value.quality === "Refrigerado"
        && this.cargoNature.value.code !== "2" && this.cargoNature.value.code !== "5") {
        data['cargoModel']['cargoNature'] = {};
        data['cargoModel']['cargoNature']['code'] = "7";
        data['cargoModel']['cargoNature']['description'] = "Refrigerada";
      } else {
        data['cargoModel']['cargoNature'] = this.cargoNature.value;
      }

      data['cargoModel']['operationType'] = {};
      if (this.productType.value.name === "CONTENEDOR VACIO") {
        data['cargoModel']['operationType']['code'] = "V";
        data['cargoModel']['operationType']['description'] = "Contenedor vacío";
      } else if (this.form.get('cargoModel.serviceType.name').value === "Carga consolidada") {
        data['cargoModel']['operationType']['code'] = "P";
        data['cargoModel']['operationType']['description'] = "Paqueteo";
      } else if (
        (this.form.get('cargoModel.tripType.name').value === "Exportación" || this.form.get('cargoModel.tripType.name').value === "Importación") &&
        this.form.get('cargoModel.cargoType.name').value === "Contenedor"
      ) {
        data['cargoModel']['operationType']['code'] = "C";
        data['cargoModel']['operationType']['description'] = "Contenedor cargado";
      } else {
        data['cargoModel']['operationType']['code'] = "G";
        data['cargoModel']['operationType']['description'] = "General";
      }
    }
    const errorsFromExcel = ['cargoMeasureQuantity', 'cargoMeasureTotal', 'cargoMeasureTotalWeigth', 'cargoMeasureTotalAmount', 'cargoMeasureTotalType'];
    data['cargoFeature'] = cargoFeature;
    data['cargoFeature'].cargoMeasure.amount = Number(data['cargoFeature'].cargoMeasure.amount);
    data['cargoFeature'].cargoMeasure.totalWeigth = Number(data['cargoFeature'].cargoMeasure.totalWeigth);

    if (this.cargo.errorFieldsExcelCargo) {
      data['errorFieldsExcelCargo'] = this.cargo.errorFieldsExcelCargo;
      errorsFromExcel.forEach(error => {
        data['errorFieldsExcelCargo'][error] = null;
      });
    }

    if (this.cargo.cargoFeature && this.cargo.cargoFeature.vehicleType &&
      (data['cargoFeature']['vehicleType'].name !== this.cargo.cargoFeature.vehicleType.name ||
        (this.cargo.cargoFeature.vehicleType.bodyWorkType &&
          data['cargoFeature']['vehicleType']['bodyWorkType'].name !== this.cargo.cargoFeature.vehicleType.bodyWorkType.name))) {
      data['licensePlate'] = null;
      data['driver'] = null;
    }

    this.spinner.show();
    const $updateCargoFeature = this.cargoDetail.completeUpdateRequest(data, this.cargo).subscribe({
      next: (success) => {
        this.spinner.hide();
        this.emitToParent.emit(this.cargo.consecutive);
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATED);
      },
      error: (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATE_ERROR_DEFAULT, undefined, 'error');
      },
      complete: () => {
        $updateCargoFeature.unsubscribe();
      }
    });
  }

  public getBodyworkByVehicleType(vehicle) {
    this.spinner.show();
    this.manualCreationCargoService.getBodyworkByVehicleType(vehicle.id).toPromise()
      .then((success) => {
        if (Array.isArray(success)) {
          this.vehicleSelect = success;
        } else {
          this.vehicleSelect = [];
        }
      })
      .catch((error) => {
        console.error(error);
        vehicle.bodyWorkList = [];
      })
      .finally(() => this.spinner.hide());
  }

  ngOnDestroy() {
    if (this.vehicleTypeSub) this.vehicleTypeSub.unsubscribe();
    if (this.bodyworkTypeSub) this.bodyworkTypeSub.unsubscribe();
    if (this.productTypeSub) this.productTypeSub.unsubscribe();
  }

  public get productType(): FormGroup {
    return this.form.get('cargoFeature.productType') as FormGroup;
  }

  public get vehicleType(): FormGroup {
    return this.form.get('cargoFeature.vehicleType') as FormGroup;
  }
  public get cargoMeasure(): FormGroup {
    return this.form.get('cargoFeature.cargoMeasure') as FormGroup;
  }

  public get packagingType(): FormGroup {
    return this.form.get('cargoModel.packagingType') as FormGroup;
  }

  public get cargoNature(): FormGroup {
    return this.form.get('cargoModel.cargoNature') as FormGroup;
  }

  public get isRefrigerated(): FormGroup {
    return this.form.get('cargoFeature.vehicleType.quality') as FormGroup;
  }

  public get canBeRefrigerated(): boolean {
    const vehicle = this.vehicleType.get('name').value;
    const bodywork = this.vehicleType.get('bodyWorkType.name').value;
    return vehicle === "TRACTOCAMION" || (vehicle === 'CAMION' && bodywork === "FURGON") || (vehicle === 'CAMIONETA' && bodywork === "FURGON");
  }
}
