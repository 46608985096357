import {
  Component,
  OnInit,
  Inject,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
  Renderer2,
  NgZone,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatTooltip } from "@angular/material";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Subscription } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import { City } from "src/app/core/interfaces/city";
import { AddressCargo } from "src/app/core/models/address-cargo.model";
import { Titles } from "src/app/core/resources/titles";
import { CatalogService } from "src/app/core/services/catalog.service";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { ManualCreationCargoService } from "src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service";
import PlaceResult = google.maps.places.PlaceResult;
import { MapComponent } from "../map/map.component";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { ModalSelectionMapComponent } from "../modal-selection-map/modal-selection-map.component";

declare var google: any;

@Component({
  selector: "app-autocomplete-address",
  templateUrl: "./autocomplete-address.component.html",
  styleUrls: ["./autocomplete-address.component.scss"]
})
export class AutocompleteAddressComponent implements OnInit {
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Input() required: boolean;
  @Input() title: string;
  @Input() value: string = "";
  @Input() address: string;
  @Input() options: object;
  @Input() addOptions: boolean = true;
  @Input() simpleAddress: boolean;
  @Input() addLatLong: boolean;
  @Input() internationalCode: string;
  @Input() disabled: boolean = false;
  @Input() idAddress;
  @Input() appearance: string = 'standard';
  @Input() dinamicStyles: boolean = false;
  @ViewChild("inputAutocomplete", { static: false }) inputAutocomplete: ElementRef;
  addressSelected: string;
  isValid: boolean = true;
  active: boolean = false;
  newAddressSelected: FormControl = new FormControl();
  newAddressSub: Subscription;
  locationAddress = {
    lat: null,
    lng: null,
  };

  constructor(
    private catalogService: CatalogService,
    private manualCreationCargoService: ManualCreationCargoService,
    private renderer: Renderer2,
    private snackBarService: SnackBarService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    public cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.required) {
      this.newAddressSelected.setValidators([Validators.required]);
    } else {
      this.newAddressSelected.clearValidators();
    }
    this.newAddressSelected.updateValueAndValidity();
  }
  ngAfterViewInit(): void {
    this.cdRef.markForCheck();
    setTimeout(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.inputAutocomplete.nativeElement,
        {
          componentRestrictions: { country: this.internationalCode ? this.internationalCode.toLowerCase() : "co" },
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          this.addressSelected = this.inputAutocomplete.nativeElement.value;
          this.locationAddress = {
            lat: place && place.geometry && place.geometry.location && place.geometry.location.lat() ? place.geometry.location.lat() : null,
            lng: place && place.geometry && place.geometry.location && place.geometry.location.lng() ? place.geometry.location.lng() : null,
          };
          this.newAddressSelected.setValue(this.addressSelected);
          this.isValid = true;
          this.executeEmit(this.addressSelected);
        });
      });
    }, 1000);

  }

  private executeEmit(address: string): void {
    if (this.simpleAddress) {
      if (this.addLatLong) {
        if (this.idAddress || this.idAddress === 0) this.emitToParent.emit({ location: this.locationAddress, address: address, idAddress: this.idAddress })
        else this.emitToParent.emit({ location: this.locationAddress, address: address });

      } else {
        this.emitToParent.emit(address);
      }
    } else if (this.locationAddress.lat === null && this.locationAddress.lng === null) {
      this.emitToParent.emit({
        data: {
          address
        },
        isValid: this.isValid,
        options: this.options
      });
    } else {
      this.emitToParent.emit({
        data: {
          address,
          location: this.locationAddress
        },
        isValid: this.isValid,
        options: this.options
      });
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.address && changes.address.currentValue && this.inputAutocomplete) {
        this.renderer.setProperty(this.inputAutocomplete.nativeElement, 'value', changes.address.currentValue);
      }
      if (changes.value) {
        this.addressSelected = changes.value.currentValue;
      }
      if (changes.internationalCode) {
        this.ngAfterViewInit();
      }
    }
  }

  selectAddressOnMap() {
    if (this.simpleAddress) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        clickMap: true
      };
      dialogConfig.height = ModalEnum.FORMS_HEIGHT;
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.LARGE_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      const dialogRef = this.dialog.open(ModalSelectionMapComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.state && result.data) {
          this.value = result.data.address;
          if (this.addLatLong) {
            if (this.idAddress || this.idAddress === 0) this.emitToParent.emit({ location: result.data.location, address: this.value, idAddress: this.idAddress })
            else this.emitToParent.emit({ location: result.data.location, address: this.value });
          } else {
            this.emitToParent.emit(this.value);
          }
        }
      });
    } else {
      this.emitToParent.emit({
        data: {
          focusInput: true
        },
        options: this.options
      });
    }
    this.snackBarService.openSnackBar('Selecciona una ubicación en el mapa', null, 'info');
  }

  changeInput() {
    if (this.value === "" && (this.idAddress || this.idAddress === 0)) {
      this.emitToParent.emit({ type: 'clear', idAddress: this.idAddress });
    } else this.emitToParent.emit("");
  }

  openAddressCorrection() {
    this.active = true;
    this.newAddressSelected.setValue(this.addressSelected);
    this.newAddressSub = this.newAddressSelected.valueChanges
      .pipe(debounceTime(400))
      .subscribe((value) => {
        this.executeEmit(value);
      });
  }

  closeAlias() {
    this.active = false;
  }

  ngOnDestroy() {
    this.cdRef.detach();
    if (this.newAddressSub) this.newAddressSub;
  }
}
