import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FreightListService } from './cargo-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Filter } from 'src/app/core/models/filter';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { Titles } from 'src/app/core/resources/titles';
import { CurrencyPipe } from '@angular/common';
import { Company } from 'src/app/core/interfaces/company';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { EmailFormComponent } from '../email-form/email-form.component';
import { Permission } from 'src/app/core/resources/permission';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { CargoOptionsService } from '../cargo-options/cargo-options.service';
import { ConfirmBellAlertsComponent } from '../confirm-bell-alerts/confirm-bell-alerts.component';
import moment from 'moment';
import { AssignVehicleCargoService } from '../assign-vehicle-cargo/assign-vehicle-cargo.service';
import { FreightListService as listTurn } from '../cargo-list-turn/cargo-list-turn.service';
import { ListFilterComponent } from 'src/app/shared/list-filter/list-filter.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { ConventionsComponent } from 'src/app/shared/conventions/conventions.component';
import { BellAlertService } from '../bell-alert/bell-alert.service';
import { CargoService } from 'src/app/core/services/cargo.service';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';

@Component({
  selector: 'app-cargo-list',
  templateUrl: './cargo-list.component.html',
  styleUrls: ['./cargo-list.component.scss'],
  providers: [CurrencyPipe, CargoManager]
})

export class CargoListComponent implements OnInit {
  turn = [];
  //@ViewChild(CargoListFilterComponent, { static: false }) cargoListFilterComponent: CargoListFilterComponent;
  @ViewChild(ListFilterComponent, { static: false }) listFilterComponent: ListFilterComponent;
  @Input() typeList: string;
  @Output() resetFilters: EventEmitter<boolean> = new EventEmitter();
  paramsFilter = '';
  optionsFilter: Filter;
  filterPayments = '';
  filterAproval = 'Pending approval';
  filterTypePayment = 'balance';
  filterDriver = true;
  filterTypeOfDate = true;
  permission = Permission;
  // filterCharges = false;
  onGetListCargo: any;
  activeSelectItem = false;
  activeSelectItemExtraAdvance = false;
  activeSelectItemToPay = false;
  activeSelectItemApproval = false;
  itemsSelecteds: Array<Cargo> = [];
  idsWithoutBill: Array<number> = [];
  unfilteredList: Array<Cargo> = [];
  idsWithBill: Array<number> = [];
  filters: string = '';
  company: Company;
  nitCompany: string = '';
  showBells: boolean = false;
  cargoTabsLoadingRoutes = ["loadingRoutes", "emptyContainers", "urbanRoutes", "lastMileRoutes", "nationalRoutes", 'exportRoutes', 'importRoutes', "internationalRoutes"];
  public amountsCargoEnum = AmountsCargoEnum;
  public paginationList: PaginationList = new PaginationList();
  filteredCargosTurns = {};
  filterErrorManifest = false;
  // refreshListTurn = this.assignVehicleCargoService.getRefreshListTurn();
  constructor(
    public service: FreightListService,
    private spinner: NgxSpinnerService,
    public authService: AuthService,
    public permissionRole: PermissionRole,
    public global: Global,
    public utils: Utils,
    public titles: Titles,
    private router: Router,
    public dialog: MatDialog,
    private cargoDetailService: CargoDetailService,
    private snackBarService: SnackBarService,
    public cargoListService: FreightListService,
    public cargoManager: CargoManager,
    private cargoOptionsService: CargoOptionsService,
    private assignVehicleCargoService: AssignVehicleCargoService,
    private listTurn: listTurn,
    public bellAlertService: BellAlertService,
    public cargoService: CargoService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.company = this.authService.getCompany();
    this.typeList = window.location.hash.substring(13);
    if (this.typeList === 'scheduledLoads') this.getTurns();
  }

  ngOnInit() {
    this.paginationList.setPageSize(10);
    this.onGetListCargo = this.getListCargo.bind(this);
    this.activeSelectItem = this.utils.enableSelectItemByList(this.typeList);
    sessionStorage.setItem('_lastList', this.typeList.toString());
    localStorage.removeItem('hasOpenEval');
    this.optionsFilter = this.service.getOptionsFilter(this.typeList);
    if (
      this.typeList === 'loadsInNegotiation' &&
      this.paginationList.getPageKey() === 1 &&
      this.paginationList.getList().length === 0
    ) {
      this.getListCargoNegotiation();
    } else if (
      this.typeList === 'cargoRequestNegotiation' &&
      this.paginationList.getPageKey() === 1 &&
      this.paginationList.getList().length === 0
    ) {
      this.getListCargoRequestNegotiation();
    }
    if (this.typeList === 'loadingRoutes') {
      this.showConfirmBellAlerts();
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  showConfirmBellAlerts() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialoRef = this.dialog.open(ConfirmBellAlertsComponent, dialogConfig);
    dialoRef.afterClosed().subscribe(() => {
      this.showBells = true;
    });
  }

  ngOnDestroy(): void {
    this.resetPagging();
    this.cdRef.detach();
  }

  getListName() {
    let listName = '';
    switch (this.typeList) {
      case 'loadingRoutes':
        listName = 'Cargas en Ruta';
        break;
      case 'latestLoads':
        listName = 'últimas Cargas';
        break;
      case 'scheduledLoads':
        listName = 'Cargas Programadas';
        break;
      case 'loadsInNegotiation':
        listName = 'Cargas en Negociación';
        break;
      case 'loadsFinished':
        listName = 'Cargas Terminadas';
        break;
      case 'approvePayments':
        listName = 'Cargas para Aprobación de Pago';
        break;
      case 'paymentAdvanceCargo':
      case 'paymentExtraAdvanceCargo':
      case 'paymentAdditionalCostsCargo':
      case 'paymentBalanceCargo':
        listName = 'Cargas para Pago';
        break;
      case 'charges':
        listName = 'Cargas Pendientes de Cobrar';
        break;
      case 'request':
        listName = 'Solicitudes';
        break;
      case 'cargoRequestNegotiation':
        listName = 'Negociación B2B';
        break;
      case 'expired':
        listName = 'Cargas Vencidas';
        break;
      case 'deleted':
        listName = 'Cargas eliminadas';
        break;
      case 'withoutPayment':
        listName = 'Cargas sin pagos';

    }
    return listName;
  }

  getListCargoNegotiation() {
    this.spinner.show();
    this.service.getListCargoNegotiation(this.authService.getUserSession().information.document).subscribe(
      (data: any[]) => {
        if (data && data.length) {
          this.unfilteredList = data;
          this.paginationList.setList(this.cargoListService.getOrderedListByTypeOfDate(this.unfilteredList, this.cargoTabsLoadingRoutes.includes(this.typeList)));
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getListCargoRequestNegotiation() {
    this.spinner.show();
    this.service.getListCargoRequestNegotiation(
      this.company.companyId,
      (this.company.parentCompany && this.company.parentCompany.length ? 'Negotiator' : 'Creator')
    ).subscribe(
      (data: any) => {
        if (data && data.length) {
          this.unfilteredList = data;
          this.paginationList.setList(this.cargoListService.getOrderedListByTypeOfDate(this.unfilteredList, this.cargoTabsLoadingRoutes.includes(this.typeList)));
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  onScrollDown() {
    if (
      this.paginationList.getEnablePagingScroll() &&
      this.typeList !== 'loadsInNegotiation' &&
      this.typeList !== 'cargoRequestNegotiation' &&
      (((this.service.isPaymentCargo(this.typeList) && this.filters.search('paymentType=true') > 0) || (this.service.isPaymentCargo(this.typeList) &&
        this.filters.search('paid=true') === -1)) || !this.service.isPaymentCargo(this.typeList))
    ) {
      this.paginationList.setPageKey(
        this.paginationList.getPageKey() + 1
      );
      if (this.cargoTabsLoadingRoutes.includes(this.typeList)) {
        this.paginationList.setList(this.cargoListService.getOrderedListByTypeOfDate(
          this.unfilteredList,
          this.cargoTabsLoadingRoutes.includes(this.typeList),
          (this.paginationList.getPageKey() - 1) * this.paginationList.getPageSize(),
          ((this.paginationList.getPageKey() - 1) * this.paginationList.getPageSize()) + this.paginationList.getPageSize(), null, this.filterErrorManifest));
      } else this.getListCargo(this.filters);
    }
  }

  onFilter($event) {
    this.filters = $event;
    this.nitCompany = ""
    let valueNit = $event.split('&').filter((filter) => {
      return (filter.search('nit=') >= 0);
    });
    if (valueNit[0]) {
      this.nitCompany = valueNit[0].split('=')[1];
    }
    this.resetPagging();
    this.getListCargo(this.filters);
  }

  cleanFilters($event) {
    this.resetFilters.emit($event);
  }

  setTypeOfDate($event: boolean) {
    this.filterTypeOfDate = $event;
  }

  refreshActualList() {
    this.resetPagging();
    this.getListCargo(this.filters);
  }

  refreshList($event?) {
    if ($event) this.filters = $event;
    this.resetPagging();
    this.getListCargo(this.filters);
  }

  resetPagging() {
    this.paginationList.setPageKey(1);
    this.unfilteredList = [];
    this.paginationList.setList([]);
    this.paginationList.setEnablePagingScroll(true);
    this.activeSelectItemExtraAdvance = false;
    this.activeSelectItemApproval = false;
    this.activeSelectItemToPay = true;
  }

  getListCargo($event) {
    let trackingTypeName = '';
    if ($event.includes('orderBy')) {
      let regex = /&orderBy=[^&]*/g;
      let match = regex.exec($event);
      trackingTypeName = match ? match[0].split('=')[1] : '';
      $event = $event.replace(regex, '');
    }
    this.filterErrorManifest = false;
    if ($event.includes('errorManifest=true')) {
      this.filterErrorManifest = true;
      $event = $event.replace('&errorManifest=true', '');
    }
    let service = null;
    this.itemsSelecteds = [];
    let lastPointExistance;
    let lastPointLapse;
    this.spinner.show();
    if (this.service.isPaymentCargo(this.typeList) && $event.search('paymentType=true') === -1) {
      // Payment
      service = this.service.getListCargoToPay(
        $event,
        this.paginationList.getPageKey(),
        this.paginationList.getPageSize()
      );
      this.activeSelectItemToPay = true;
    } else {
      // General
      if ($event.includes('lastPointExistance')) {
        if ($event.includes('&lastPointExistance=true')) {
          $event = $event.replace('&lastPointExistance=true', '');
          lastPointExistance = true;
        } else {
          $event = $event.replace('&lastPointExistance=false', '');
          lastPointExistance = false;
        }
      }
      var regex = new RegExp("(&?lastPointLapse=\\w+)", "g");
      if ($event.includes('lastPointLapse')) {
        if ($event.match(regex) && $event.match(regex).length) lastPointLapse = parseInt($event.match(regex)[0].split("=")[1]);
        $event = $event.replace(regex, "");
      }
      this.activeSelectItemToPay = false;
      if ($event.search('paymentType=advanceState') > -1) {
        this.activeSelectItemExtraAdvance = true;
      } else {
        this.activeSelectItemExtraAdvance = false;
      }
      if ($event.search('approval=Pending') > -1 &&
        this.permissionRole.hasPermission(
          this.permission.payments.module,
          this.permission.payments.approvePayments
        )) {
        this.activeSelectItemApproval = true;
      } else {
        this.activeSelectItemApproval = false;
      }
      if (this.typeList === 'charges' || this.typeList === 'approvePayments')
        $event = $event.replace('&approval=all', '');
      $event = $event.replace('&paymentType=true', '&paid=true');
      $event = $event.replace('&paymentType=advanceState', '');
      service = this.service.getListCargo(
        $event,
        this.paginationList.getPageKey(),
        this.paginationList.getPageSize(),
        this.typeList
      );
    }

    service.subscribe(
      (data: any) => {
        this.spinner.hide();
        if (this.service.isPaymentCargo(this.typeList) && $event.search('paid=true') === -1) {
          if (data && data.length) {
            data = data.map((item) => {
              if (item && item.cargo) {
                const cargo: Cargo = item.cargo;
                if (!this.utils.isEmpty(item.validatedBankAccount)) {
                  cargo.validatedBankAccount = item.validatedBankAccount;
                }
                return cargo;
              }
            });
          }
        }

        if (!this.utils.isDefined(data) || data.length === 0) {
          this.paginationList.setEnablePagingScroll(false);
          data = [];
        }

        if (this.cargoTabsLoadingRoutes.includes(this.typeList)) {
          this.unfilteredList = this.cargoListService.getOrderedListByTypeOfDate(data, lastPointExistance, lastPointLapse);
          let uncaredCargos = this.unfilteredList.filter(cargo => {
            if (cargo && cargo.cargoFeature && cargo.cargoFeature.cargoMeasure && cargo.cargoFeature.cargoMeasure.amount) {
              const isEmpty = this.cargoManager.getProductNameCargo(cargo) === 'CONTENEDOR VACIO';
              let validationTime;
              if (cargo.lastPointLocation && cargo.lastPointLocation.fingerprint && cargo.lastPointLocation.fingerprint.date) {
                validationTime = this.bellAlertService.validateTimeCargo(cargo.lastPointLocation.fingerprint.date, cargo, isEmpty);
              } else if (cargo.stateTracking && (cargo.stateTracking === "Start trip" || cargo.stateTracking === "Destination arrived") && cargo.cargoFeature.uploadDownload && cargo.cargoFeature.uploadDownload.origin && cargo.cargoFeature.uploadDownload.origin.addresses && cargo.cargoFeature.uploadDownload.origin.addresses.length > 0 && cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime && cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate) {
                validationTime = this.bellAlertService.validateTimeCargo(cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate, cargo, isEmpty);
              }
              if (validationTime) {
                const late = validationTime.diff - validationTime.amount;
                return late > AmountsCargoEnum.UNCARED_ALARM_MINS;
              }
            }
            return false;
          })
          let errorManifestCargo = this.unfilteredList.filter(cargo => {
            return cargo.manifestError && cargo.manifestError.error && !cargo.manifestAuthorization;
          })
          if (lastPointExistance !== false) BellAlertService.stack(uncaredCargos);
          if (this.filterErrorManifest !== true) CargoService.stack(errorManifestCargo);
          this.paginationList.setList(this.cargoListService.getOrderedListByTypeOfDate(
            this.unfilteredList,
            this.cargoTabsLoadingRoutes.includes(this.typeList),
            (this.paginationList.getPageKey() - 1) * this.paginationList.getPageSize(),
            ((this.paginationList.getPageKey() - 1) * this.paginationList.getPageSize()) + this.paginationList.getPageSize(), trackingTypeName, this.filterErrorManifest));
        }
        else {
          if (this.paginationList.getList().length) this.unfilteredList = this.unfilteredList.concat(data);
          else this.unfilteredList = data;
          if (this.typeList === 'scheduledLoads') this.sortTurnCargo();
          this.paginationList.setList(this.cargoListService.getOrderedListByTypeOfDate(this.unfilteredList, this.cargoTabsLoadingRoutes.includes(this.typeList)));
        }
      },
      (error) => {
        this.spinner.hide();
        if (!(this.paginationList.getList().length > 0))
          this.resetPagging();
      }
    );
  }

  onSelectItem($event) {
    this.itemsSelecteds = $event;
  }

  sortTurnCargo() {
    this.filteredCargosTurns = {};
    this.turn.forEach(turn => {
      if (this.filteredCargosTurns[turn.origin.id]) {
        if (this.filteredCargosTurns[turn.origin.id][turn.vehicleType]) {
          this.filteredCargosTurns[turn.origin.id][turn.vehicleType].push(turn)
        } else {
          this.filteredCargosTurns[turn.origin.id][turn.vehicleType] = [];
          this.filteredCargosTurns[turn.origin.id][turn.vehicleType].push(turn);
        }

      } else {
        this.filteredCargosTurns[turn.origin.id] = [];
        this.filteredCargosTurns[turn.origin.id][turn.vehicleType] = [];
        this.filteredCargosTurns[turn.origin.id][turn.vehicleType].push(turn);
      }
    })
  }

  get excludeItemsList() {
    if (this.service.isPaymentCargo(this.typeList)) {
      return {
        field: 'validatedBankAccount',
        value: false
      };
    }
  }

  getFilteredCargo(consecutive: string, vehicleType: string, origin: string, dateLoad: string) {
    if (this.paginationList.getList().every(cargo => cargo.state === CargoStateEnum.CREATED)) {
      let matches = [];
      let dateLoadFormatted = moment(dateLoad).utc().format('YYYY-MM-DD ZZ');
      if (this.filteredCargosTurns && this.filteredCargosTurns[origin] && this.filteredCargosTurns[origin][vehicleType]) matches = this.filteredCargosTurns[origin][vehicleType].filter(turn => this.validDates(dateLoadFormatted, turn.date));
      return { cargoConsecutive: consecutive, matches: matches };
    }
    return { cargoConsecutive: consecutive, matches: [] };
  }

  validDates(originDateLoad, turnDate) {
    switch (originDateLoad) {
      case moment(turnDate, 'YYYY-MM-DD ZZ').utc().format('YYYY-MM-DD ZZ'):
      case moment(turnDate, 'YYYY-MM-DD ZZ').add(1, 'days').utc().format('YYYY-MM-DD ZZ'):
      case moment(turnDate, 'YYYY-MM-DD ZZ').add(2, 'days').utc().format('YYYY-MM-DD ZZ'):
        return true;
      default:
        return false;
    }
  }

  getActiveItemSelected(cargo?: Cargo) {
    let state = false;
    if (this.activeSelectItem &&
      (this.isCargoToGeneralPay(cargo) ||
        this.isCargoToPayExtraAdvance() ||
        this.isCargoToCharge() ||
        this.isCargoToApproval() ||
        this.isCargoWithoutPayment(cargo))) {
      state = true;
    }
    return state;
  }

  private isCargoWithoutPayment(cargo: Cargo) {
    return ([CargoStateEnum.START_SERVICE, CargoStateEnum.END_SERVICE].includes(cargo.state));
  }

  isCargoToApproval() {
    let state = false;
    if (
      this.typeList === 'approvePayments'
      &&
      this.activeSelectItemApproval
    ) {
      state = true;
    }
    return state;
  }

  isCargoToCharge() {
    let state = false;
    if (
      this.typeList === 'charges'
    ) {
      state = true;
    }
    return state;
  }

  isCargoToPayExtraAdvance() {
    let state = false;
    if (
      this.service.isPaymentCargo(this.typeList) &&
      this.activeSelectItemExtraAdvance
    ) {
      state = true;
    }
    return state;
  }

  isCargoToGeneralPay(cargo: Cargo) {
    let state = false;
    if (
      this.service.isPaymentCargo(this.typeList) &&
      this.utils.isDefined(cargo) &&
      this.utils.isDefined(cargo.validatedBankAccount) &&
      cargo.validatedBankAccount === true
    ) {
      state = true;
    }
    return state;
  }

  get showSelectAll() {
    let state = false;
    if (this.activeSelectItem &&
      (this.isCargoToPayExtraAdvance() ||
        this.isCargoToCharge() ||
        this.isCargoToApproval() ||
        this.activeSelectItemToPay)) {
      state = true;
    }
    return state;
  }

  navigateTo(url: string) {
    this.router.navigate([url])

  }

  public showDialog($event?: Company) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.data = {
      nit: $event.companyId
    }
    const dialoRef = this.dialog.open(EmailFormComponent, dialogConfig);
    dialoRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        if (result.value && result.value.length) {
          this.spinner.show();
          this.cargoListService.sendReport($event.companyId, result.value).subscribe(
            (success) => {
              this.authService.getAllCompanies().subscribe((success) => {
                this.spinner.hide();
                this.snackBarService.openSnackBar('Reporte enviado correctamente');
              }, () => {
                this.spinner.hide();
                this.snackBarService.openSnackBar('Reporte enviado correctamente');
              });
              // this.utils.downloadFile(success, 'Reporte', 'application/xls');
            }, (error) => {
              this.spinner.hide();
              this.snackBarService.openSnackBar('Ocurrió un error al enviar reporte', undefined, 'error');
            });
        }
      };
    })
  }

  getTurns() {
    let filterData = '';
    let currentDateFormatted = moment().subtract(2, 'days').format('YYYY-MM-DD ZZ');
    filterData += '&date=' + currentDateFormatted;
    // filterData += '&state=Available'
    const turnObserver = {
      next: (data) => {
        this.spinner.hide();
        if (data && data.length) this.turn = data.filter(turn => turn.state && turn.state !== 'Deleted');
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("No se ha podido enviar la información", undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.listTurn.getListTurn(filterData).subscribe(turnObserver);
  }

  get showConventionsPayment(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.paymentSemaphore) || this.optionsFilter.showFilterAprovedCargo;
  }
  get showConventionsTransportedValue(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.transportedValueSemaphore) && !this.optionsFilter.showFilterAprovedCargo;
  }
  get showConventionsSelectItems(): boolean {
    return this.showSelectAll;
  }
  get showConventionsPausedCargos(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.cargosInRoute) && this.cargoTabsLoadingRoutes.includes(this.typeList);
  }
  get showFilterManifestError(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showManifestError);
  }

  openConventions() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { tracking: true };
    if (this.optionsFilter.showFilterAprovedCargo) dialogConfig.data['payment'] = true;
    else if (this.showConventionsTransportedValue) dialogConfig.data['transported'] = true;
    else if (this.showConventionsPayment) dialogConfig.data['payment'] = true;

    if (this.showConventionsSelectItems) dialogConfig.data['select'] = true;
    if (this.showConventionsPausedCargos) dialogConfig.data['paused'] = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(ConventionsComponent, dialogConfig);
  }

  get hasOperationsInline(): boolean {//Inline into tab
    let types = ["withoutPayment"];
    return types.includes(this.typeList);
  }
  get hasOperationsInlineLeft(): boolean {
    return this.cargoTabsLoadingRoutes.includes(this.typeList);
  }
  get hasOperationsTop(): boolean {
    let typesWithoutPermissions = ["approvePayments"];
    let chargesPermissionXML: boolean = this.typeList === "charges" && !this.permissionRole.hasPermission(
      this.permission.payments.module, this.permission.payments.billingXML);
    return typesWithoutPermissions.includes(this.typeList) || chargesPermissionXML;
  }
  get hasOperationsTopExtra(): boolean {
    let chargesPermissionXML: boolean = this.typeList === "charges" && this.permissionRole.hasPermission(
      this.permission.payments.module, this.permission.payments.billingXML);
    return chargesPermissionXML;
  }
  get hasOperations(): boolean {
    return this.hasOperationsInline || this.hasOperationsInlineLeft || this.hasOperationsTop || this.hasOperationsTopExtra;
  }

}
