import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CargoListComponent } from '../cargo-list/cargo-list.component';
import { BellAlertService } from '../bell-alert/bell-alert.service';
import { MatDialog, MatDialogConfig, MatTabGroup } from '@angular/material';
import { ModalCargoUncaredComponent } from '../modal-cargo-uncared/modal-cargo-uncared.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { CargoService } from 'src/app/core/services/cargo.service';
@Component({
  selector: 'app-cargo-tabs-loading-routes',
  templateUrl: './cargo-tabs-loading-routes.component.html',
  styleUrls: ['./cargo-tabs-loading-routes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CargoTabsLoadingRoutesComponent implements OnInit {
  private static openedModalUncared: boolean = false;
  @ViewChildren(CargoListComponent) cargoList: QueryList<CargoListComponent>;
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  selectedTab: number = 0;
  activeTab: string = 'Todas las Cargas';
  permission = Permission;
  constructor(
    private permissionRole: PermissionRole,
    private matDialog: MatDialog
  ) { }
  loadingRouteTabs = {
    loadingRoutes: 'Todas las Cargas',
    emptyContainers: 'Contenedores vacíos',
    urbanRoutes: 'Cargas urbanas',
    lastMileRoutes: 'Cargas de última milla',
    nationalRoutes: 'Cargas nacionales',
    exportRoutes: 'Cargas de exportación',
    importRoutes: 'Cargas de importación',
    internationalRoutes: 'Cargas internacionales'
  }
  ngOnInit(): void {
    BellAlertService.onUncareStacked.subscribe(
      () => {
        if (
          this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.registerLocation)
          && !CargoTabsLoadingRoutesComponent.openedModalUncared
          && BellAlertService.cargos
        ) {
          CargoTabsLoadingRoutesComponent.openedModalUncared = true;
          const config = new MatDialogConfig();
          config.data = {
            type: 'alert',
            title: 'Cargas con Alarma Desatendida',
            text: 'Las alarmas que son desatendidas, por seguridad, generan un reporte por correo electrónico.',
            cargos: BellAlertService.cargos
          };
          config.maxHeight = ModalEnum.MAX_HEIGHT;
          config.width = ModalEnum.LARGE_WIDTH;
          config.maxWidth = ModalEnum.MAX_WIDTH;
          config.minHeight = '40vh';
          config.autoFocus = false;
          const ref = this.matDialog.open(ModalCargoUncaredComponent, config);
          ref.afterClosed().subscribe(() => {
            CargoTabsLoadingRoutesComponent.openedModalUncared = false;
          });
        }
      }
    )
    CargoService.onUncareStacked.subscribe(
      () => {
        if (
          this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.showManifestError)
          && !this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.registerLocation)
          && !CargoTabsLoadingRoutesComponent.openedModalUncared
          && CargoService.cargos
        ) {
          CargoTabsLoadingRoutesComponent.openedModalUncared = true;
          const config2 = new MatDialogConfig();
          config2.data = {
            type: 'error',
            title: 'Cargas con error en manifiesto',
            text: 'Le recomendamos revisar el error antes de finalizar la ruta.',
            cargos: CargoService.cargos
          };
          config2.maxHeight = ModalEnum.MAX_HEIGHT;
          config2.width = ModalEnum.LARGE_WIDTH;
          config2.maxWidth = ModalEnum.MAX_WIDTH;
          config2.minHeight = '40vh';
          config2.autoFocus = false;
          const ref2 = this.matDialog.open(ModalCargoUncaredComponent, config2);
          ref2.afterClosed().subscribe(() => {
            CargoTabsLoadingRoutesComponent.openedModalUncared = false;
          });
        }
      }
    )
    if (sessionStorage.getItem("_activeTab")) {
      let storageTab = sessionStorage.getItem("_activeTab");
      if (storageTab && this.loadingRouteTabs[storageTab]) {
        let index = Object.keys(this.loadingRouteTabs).indexOf(storageTab);
        if (index !== -1) this.tabGroup.selectedIndex = index;
        this.activeTab = this.loadingRouteTabs[storageTab];
      }
    }
  }
  asIsOrder(a, b) {
    return 1;
  }

  onSelectTab($event: number) {
    const selectedIndex = $event;
    this.activeTab = this.tabGroup && this.tabGroup._tabs && this.tabGroup._tabs.toArray()[selectedIndex] && this.tabGroup._tabs.toArray()[selectedIndex].textLabel ? this.tabGroup._tabs.toArray()[selectedIndex].textLabel : '';
  }
}
