import { Component, OnInit, Inject } from '@angular/core';
import { RateCostAdditionalServicesModel } from 'src/app/core/interfaces/rateCostAdditionalServices';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DiscountsAndBonusesCargoService } from '../discounts-and-bonuses-cargo/discounts-and-bonuses-cargo.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { RateCostAdditionalServicesService } from './rate-cost-additional-services.service';
import { AdditionalServiceService } from '../additional-service/additional-service.service';
import { Utils } from 'src/app/core/resources/utils';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { ShippingCost } from 'src/app/core/enums/shipping-cost.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';



@Component({
  selector: 'app-rate-cost-additional-services',
  templateUrl: './rate-cost-additional-services.component.html',
  styleUrls: ['./rate-cost-additional-services.component.scss'],
  providers: [RateCostAdditionalServicesModel]
})
export class RateCostAdditionalServicesComponent extends ReactiveForm implements OnInit {
  permission = Permission;
  additionalServices: Array<string> = [];
  typeModifications: any;
  utilityCargoResult: number;
  minAmount: number = 1;
  modifications: FormControl = new FormControl('', Validators.required);
  constructor(
    public dialogRef: MatDialogRef<RateCostAdditionalServicesComponent>,
    private model: RateCostAdditionalServicesModel,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public discountsAndBonusesCargoService: DiscountsAndBonusesCargoService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private managerCompany: CompanyManager,
    public cargoItemService: CargoItemService,
    private authService: AuthService,
    private permissionRole: PermissionRole,
    public utils: Utils,
    private rateCostAdditionalService: RateCostAdditionalServicesService,
    private additionalServiceService: AdditionalServiceService,
    private manualCreationCargoService: ManualCreationCargoService
  ) {
    super(
      formBuilder,
      model.model
    );
    this.setValidatorsForm(
      this.model.modelValidators,
      this.form
    );
    this.getUtilityByCompany();
    this.typeModifications = this.refreshListTypeModification(0);
  }

  ngOnInit() {
    this.form.get('amountRate').setValidators(Validators.min(this.minAmount));
    this.form.get('amount').setValidators(Validators.min(this.minAmount));
    this.updateForm({
      userId: this.authService.getUserSession().information.document,
      userName: this.authService.getUserSession().information.name,
      cargoId: this.data.cargo.id,
    });
    this.data.enablePermissionModifyRate ? this.form.get('amount').enable() : this.form.get('amount').disable();
  }

  refreshModifications($event) {
    this.form.get('typeModification.name').setValue($event.value.name);
    this.form.get('typeModification.id').setValue($event.value.id);
    this.form.get('typeModification.type').setValue($event.value.type);
  }

  checkValidations() {
    if (!this.form.get('amountRate').value && !this.form.get('amount').value) {
      this.form.get('amountRate').setValidators(Validators.min(this.minAmount))
      this.form.get('amount').setValidators(Validators.min(this.minAmount))
      !this.form.get('amountRate').value && this.form.get('amountRate').setErrors({ min: 1 });
      !this.form.get('amount').value && this.form.get('amount').setErrors({ min: 1 });
    } else {
      this.form.get('amountRate').clearValidators();
      this.form.get('amount').clearValidators();
      this.form.get('amountRate').setErrors(null);
      this.form.get('amount').setErrors(null);
    }
    this.form.updateValueAndValidity();
  }

  refreshListTypeModification(event) {
    this.spinner.show();
    this.discountsAndBonusesCargoService.getListTypeModification(event.value ? event.value : 0)
      .toPromise()
      .then((success) => {
        this.typeModifications = success;
      })
      .catch((error) => {
        console.error(error);
        this.snackBarService.openSnackBar('Ocurrió un error al realizar este proceso', undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  get utilityCargo() {
    return this.cargoItemService.getUtilityCargo(
      this.cargoItemService.getNewValueRateAdditionals(this.data.finantialInfo.value, this.form.get('amountRate').value, this.form.get('type').value),
      this.cargoItemService.getNewValueCostAdditionals(this.data.finantialInfo.value, this.form.get('amount').value, this.form.get('type').value)

    );
  }

  async getUtilityByCompany() {
    let result = await this.managerCompany.getutilityAdditionalServicesByCompanyId(this.data.companyId);
    this.utilityCargoResult = result;
  }

  onSubmit() {
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('type'), 'tipo de acción')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('amountRate'), 'valor para la tarifa')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('typeModification.name'), 'tipo de modificación')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('amount'), 'valor para el costo')) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
        return;
      }
    } else if (!this.validUtility) {
      this.snackBarService.openSnackBar(this.utilityCargoResult
        ? Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, this.utilityCargoResult)
        : FormMessages.MINIMUN_UTILITY_NOT_REACHED_DEFAULT, undefined, 'alert');
      return;
    } else if (this.cargoItemService.getNewValueCostAdditionals(this.data.finantialInfo.value, this.form.get('amount').value, this.form.get('type').value) < 0) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MIN_VALUE_NOT_REACHED, 'valor para el flete', 1), undefined, 'alert');
      return;
    } else if (this.cargoItemService.getNewValueRateAdditionals(this.data.finantialInfo.value, this.form.get('amountRate').value, this.form.get('type').value) < 0) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MIN_VALUE_NOT_REACHED, 'valor para la tarifa', 1), undefined, 'alert');
      return;
    } else if (this.form.get('amount').value <= 0 && this.form.get('amountRate').value <= 0) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'tarifa o flete'), undefined, 'alert');
      return;
    } else {
      this.spinner.show();
      this.rateCostAdditionalService.modifyValuesAdditionalCost(this.form.value, this.data.adittionalServiceId).subscribe(
        () => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Se editó exitosamente el servicio", undefined, 'success');
          this.dialogRef.close({ state: true });
          const newValues = {
            serviceId: this.data.adittionalServiceId,
            rate: this.cargoItemService.getNewValueRateAdditionals(this.data.finantialInfo.value, this.form.get('amountRate').value, this.form.get('type').value),
            cost: this.cargoItemService.getNewValueCostAdditionals(this.data.finantialInfo.value, this.form.get('amount').value, this.form.get('type').value)
          }
          this.additionalServiceService.editAdditionalCostItem.emit(newValues);
        }, (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Ocurrió un error al editar el servicio", undefined, 'error');
        });


      if (this.data.adittionalServiceId === undefined || this.data.adittionalServiceId === null || this.data.adittionalServiceId === "") {
        this.snackBarService.openSnackBar("No es posible agregar bonos y descuentos al servicio adicional por favor contactar al equipo de desarrollo", undefined, "error");
      }
    }
  }


  //getters
  get validUtility() {
    if (this.utilityCargo < this.utilityCargoResult)
      return this.permissionRole.hasPermission(this.permission.payments.module, this.permission.payments.editRateCargoUnrestricted)
    return true;
  }

  get MinUtility() {
    return ShippingCost.MINIMUN_UTILITY_ADDITIONAL_COST;
  }
}


