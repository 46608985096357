import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SigFiles } from 'src/app/core/interfaces/sigFiles';
import { TreeFolder } from 'src/app/core/interfaces/treeFolder';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SigService {

  public treeFolders: TreeFolder[] = [];
  public folderSelected: TreeFolder;

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    private globalResources: Global,
    public utils: Utils,
    private authService: AuthService,
  ) { }

  public getFilesByNit(): Observable<SigFiles> {
    let params = new HttpParams();
    params = params.append('companyId', this.authService.getUserSession().clientCompanyId);
    return this.http.get<SigFiles>(
      `${environment.urlServerTeclogi}${this.endpointResources.companyDocuments}`,
      { params }
    );
  }

  public setFolderSelected(value: TreeFolder): void {
    SigService['folderSelected'] = value;
  }

  public getFolderSelected(): TreeFolder {
    return SigService['folderSelected'];
  }

}
