import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ChangeCompanyService } from './change-company.service';

@Component({
  selector: 'app-change-company',
  templateUrl: './change-company.component.html',
  styleUrls: ['./change-company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {

  public companySelected: FormControl = new FormControl('', Validators.required);
  public cargo: Cargo
  optionsCompany = {
    title: 'Compañía',
    initialNit: this.dialogParams.cargo.idCompany
  };
  validateCompany: string = '';
  constructor(
    private changeCompanyService: ChangeCompanyService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<ChangeCompanyComponent>,
    public utils: Utils,
    private snackBarService: SnackBarService,

  ) { }

  ngOnInit() {

    if (this.dialogParams && this.dialogParams.cargo) this.cargo = this.dialogParams.cargo;
  }

  changeCompany() {
    this.validateCompany = 'touched';
    if (this.utils.isDefined(this.companySelected) && this.utils.isDefined(this.companySelected.value)) {
      const nitCompany = this.companySelected.value.companyId;
      const idCargo = this.cargo.id;
      this.changeCompanyService.updateCompany(idCargo, nitCompany).subscribe(
        (data) => {
          this.dialogRef.close({
            state: true
          });
        }, (error) => {
          this.dialogRef.close({
            state: false
          });
        });
    } else {
      this.snackBarService.openSnackBar('Debe seleccionar una compañia', undefined, 'alert');
    }


  }

}
