import { Directive, ElementRef, Output, EventEmitter, OnInit, Input, HostListener, SimpleChanges } from '@angular/core';
import { Utils } from '../resources/utils';

@Directive({
  selector: '[select-item-list]'
})
export class SelectItemListDirective implements OnInit {

  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onSelectItem: EventEmitter<any> = new EventEmitter();
  @Input() public type: string;
  @Input() public item: any;
  @Input() public active: boolean;
  @Input() public list: any;
  @Input() public exclude: any;
  isCheckedAll = false;

  constructor(
    private elementRef: ElementRef,
    public utils: Utils
  ) { }

  public ngOnInit() {
    if (this.active) {
      if (this.type === 'selectAll') {
        this.elementRef.nativeElement.insertAdjacentHTML(
          'beforeend',
          `
                    <div class="check-all-title">
                        Seleccionar todo
                    </div>
                    `
        );
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.list && changes.list.currentValue) {
      if (changes.list.currentValue.length === 0) this.isCheckedAll = false;
    }
  }

  @HostListener('click') onClick() {
    if (this.active) {
      if (this.type !== 'selectAll') {
        this.item.checked ? this.item.checked = false : this.item.checked = true;
        const guidesList = this.utils.getItemsCheked(this.list);
        this.onSelectItem.emit(guidesList);
      } else {
        this.checkAllItemsList();
        this.updateSelectAllText();
      }
    }
  }

  checkAllItemsList() {
    let listChecked;
    this.isCheckedAll = !this.isCheckedAll;
    if (this.isCheckedAll) listChecked = this.utils.selectAllItems(this.list, this.exclude);
    else listChecked = this.utils.deselectAllItems(this.list);
    const guidesList = this.utils.getItemsCheked(listChecked);
    this.onSelectItem.emit(guidesList);
  }

  private updateSelectAllText() {
    const text = this.isCheckedAll ? 'Deseleccionar todo' : 'Seleccionar todo';
    this.elementRef.nativeElement.innerHTML = `
      <div class="check-all-title">
        ${text}
      </div>
    `;
  }
}
