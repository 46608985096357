import { Injectable } from "@angular/core";
import { AuthService } from "./authentication.service";
import { Utils } from "../resources/utils";
import { CargoTracking } from "../interfaces/cargoTracking.interface";

@Injectable()
export class FirebaseDatabase {

  constructor(private utils: Utils) {

  }

  public getRefDatabase(pathDatabase: string): firebase.database.Reference {
    const refDatabase: firebase.database.Reference = AuthService.fDatabase.ref(pathDatabase);
    return refDatabase;
  }

  public getOldDataRealTime(path: string): Promise<CargoTracking> {
    return new Promise((resolve, reject) => {
      let oldDataTracking: CargoTracking = {
        listAnomalies: [],
        listLocations: []
      };
      const refFirebaseActive = this.getRefDatabase(path);
      this.lisenerDataReference(refFirebaseActive)
        .then(async (data) => {
          oldDataTracking = await this.getDataTrackingSnapshot(data);
          refFirebaseActive.off();
          resolve(oldDataTracking);
        });
    });
  }

  public lisenerDataReference(refFirebaseActive: firebase.database.Reference): Promise<any> {
    return new Promise((resolve) => {
      refFirebaseActive.on("value", async (resp) => {
        resolve(resp);
      }).bind(this);
    });
  }

  public getDataTrackingSnapshot(snapshot: firebase.database.DataSnapshot): CargoTracking {
    const listAnomalies = [];
    const listLocations = [];
    let count = 0;
    if (snapshot) {
      snapshot.forEach((childSnapshot) => {
        const item = childSnapshot.val();
        const key = childSnapshot.key;
        const location = this.parseLocations(item, key);
        if (location) {
          const locationData = this.utils.clone(location);
          const latLng = {
            lat: location.lat,
            lng: location.lng
          }
          delete locationData.lat;
          delete locationData.lng;
          listLocations.push({
            position: count,
            forced: !!locationData.anomaly,
            ...latLng,
            ...locationData
          });
          if (locationData.hasOwnProperty('anomaly')) {
            listAnomalies.push({
              id: "pointReport",
              ...latLng,
              data: locationData
            });
          }
        }
        count++;
      });
    }
    return {
      listAnomalies,
      listLocations
    };
  }

  private parseLocations(item, key): any {
    let itemParse = {
      key,
      lat: null,
      lng: null,
    };
    let itemBase = this.utils.clone(item);

    if (item.hasOwnProperty('latLng')) {
      if (item.latLng.hasOwnProperty('latitude') && item.latLng.hasOwnProperty('longitude')) {
        itemParse.lat = itemBase.latLng.latitude;
        itemParse.lng = itemBase.latLng.longitude;
      } else if (item.latLng.hasOwnProperty('lat') && item.latLng.hasOwnProperty('lng')) {
        itemParse.lat = itemBase.latLng.lat;
        itemParse.lng = itemBase.latLng.lng;
      }
      delete itemBase.latLng;
    }

    if (item.hasOwnProperty('fingerprintBasic')) {
      itemBase.fingerprint = item.fingerprintBasic;
      if (item.hasOwnProperty('date')) {
        itemBase.fingerprint.date = item.date;
        delete item.date;
      }
      delete item.fingerprintBasic;
    }

    if (item.hasOwnProperty('name')) {
      itemBase.anomaly = item.name;
      delete item.name;
    }

    if (item.hasOwnProperty('formWeb')) {
      itemBase.fromWeb = item.formWeb;
    }

    itemParse = {
      ...itemParse,
      ...itemBase,
    }
    return itemParse;
  }

}
