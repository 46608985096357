import { AngularFireStorage } from '@angular/fire/storage';
import { EventEmitter, Injectable, Output } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../environments/environment';
import { Vehicle } from 'src/app/core/interfaces/vehicle';

@Injectable({
  providedIn: 'root'
})
export class AssignVehicleCargoService {
  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints
  ) { }

  getVehicleById(idVehicle: string, holderCompany?: string) {
    let url = `${environment.urlServerTeclogi}${this.endpointResources.getVehiclesById}${idVehicle}`;
    if (holderCompany) url += '&holderCompany=' + holderCompany;
    return this.http.get<Vehicle[]>(url);
  }
}
