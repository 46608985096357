import { Component, Input } from '@angular/core';
import { HistoryTypeDict } from 'src/app/core/enums/historyTypes.enum';
import { HistoryCompany } from 'src/app/core/interfaces/historyCompany';
@Component({
  selector: 'app-history-and-metrics-company-detail',
  templateUrl: './history-and-metrics-company-detail.component.html',
  styleUrls: ['./history-and-metrics-company-detail.component.scss']
})
export class HistoryAndMetricsCompanyDetailComponent {
  @Input() listHistory: HistoryCompany[] = [];

  getHistoryType(history: HistoryCompany): HistoryTypeDict {
    if (history && history.serviceType && HistoryTypeDict[history.serviceType])
      return HistoryTypeDict[history.serviceType];
    return null;
  }
}
