import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CollectionPoint } from 'src/app/core/interfaces/collectionPoint';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from 'src/environments/environment';
import { TermsAndConditions } from 'src/app/core/interfaces/termsAndConditions';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class LegalTermsService {
    public static driverHasValidTerms: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public static ownerHasValidTerms: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public static administratorHasValidTerms: BehaviorSubject<boolean> = new BehaviorSubject(false);

    collectionPointSelected: CollectionPoint;

    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints
    ) {

    }

    public requestTermsAndPoliciesAcceptance(document: string, name: string, phone: string) {
        return this.http.put(`${environment.urlServerTeclogi}${this.endpointResources.requestTermsAndPoliciesAcceptance}?userId=${document}&name=${name}&phone=${phone}`, {}, {});
    }

    public getListTerms() {
        
        return this.http.get(
            environment.urlServerTeclogi + this.endpointResources.terms
        );
    }

    public getAll() {
        return this.http.get(
            environment.urlServerTeclogi + this.endpointResources.getAllPolicies
        );
    }

    public createTermsAndConditions(data: any) {

        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.terms,
            data
        );
    }

    public downloadTerms(url: string) {

        return this.http.get(url, { responseType: 'blob' });
    }

    public getTermsActive() {

        return this.http.get<TermsAndConditions[]>(
            environment.urlServerTeclogi + this.endpointResources.termsActive
        );
    }

}
