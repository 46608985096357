import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Guide } from 'src/app/core/interfaces/guide';
import { environment } from './../../../../environments/environment';
import { Utils } from 'src/app/core/resources/utils';

@Injectable({
    providedIn: 'root'
})
export class GenerateGuideService {

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        public utils: Utils
    ) {

    }

    getAllGuidesInCollection() {
        return this._http.get<Guide[]>(
            environment.urlServerTeclogi + this._endpointResources.getGuides + 'state=Created'
        );
    }

    getAllGuidesInDistribution() {
        return this._http.get<Guide[]>(
            environment.urlServerTeclogi + this._endpointResources.getGuides + 'state=In warehouse'
        );
    }

    getGuidesByState(state: any, searchGuideText: string, withOutLabel: any, pageKey: number, pageSize: number, companyNit: string, loadDate: string, endDate: string) {
        let params = new HttpParams();
        let url = environment.urlServerTeclogi + this._endpointResources.getGuides;

        if (!this.utils.isEmpty(state) && state != '-1') {
            params = params.append('stateCode', state);
        }
        if (!this.utils.isEmpty(pageKey)) {
            params = params.append('pageKey', pageKey.toString());
        }
        if (!this.utils.isEmpty(pageSize)) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (!this.utils.isEmpty(searchGuideText)) {
            params = params.append('guideId', searchGuideText);
        }
        if (!this.utils.isEmpty(withOutLabel)) {
            params = params.append('withOutLabel', withOutLabel);
        }
        if (!this.utils.isEmpty(companyNit) && companyNit != '-1') {
            params = params.append('companyId', companyNit);
        }
        if (!this.utils.isEmpty(loadDate)) {
            params = params.append('creationDate', loadDate);
        }
        if (!this.utils.isEmpty(endDate)) {
            params = params.append('endDate', endDate);
        }

        return this._http.get<Guide[]>(
            url,
            {
                params
            }
        );
    }


    getGuidesSelected(guides) {
        return _.filter(guides, function (g) {
            return g.checked >= true;
        });
    }

}
