import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from "@angular/core";
import { CargoControlCargoTrackingService } from "./cargo-control-tracking.service";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { Utils } from "src/app/core/resources/utils";
import { AddressCargo } from "src/app/core/interfaces/addressCargo";
import { Cargo } from "src/app/core/interfaces/cargo";
import { CargoEvidenceComponent } from "../cargo-evidence/cargo-evidence.component";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ChangeStateGuideComponent } from "../../guides/change-state-guide/change-state-guide.component";
import { Permission } from "src/app/core/resources/permission";
import { CargoAdvancePercentageComponent } from "../cargo-advance-percentage/cargo-advance-percentage.component";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { Dialog } from "src/app/core/resources/dialog";
import { CompanyManager } from "src/app/core/managers/company.manager";
import { CurrencyPipe } from "@angular/common";
import { ConfirmComponent } from "src/app/shared/confirm/confirm.component";
import { CargoManager } from "src/app/core/managers/cargo.manager";
import { FreightForwarderEvidenceService } from "../cargo-evidence/cargo-evidence.service";
import { GoogleService } from "src/app/core/services/google.service";
import { observable, Observable, Subscription } from "rxjs";
import { Driver } from "src/app/core/models/driver";
import { Vehicle } from "src/app/core/interfaces/vehicle";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { TimeToUploadEvidencesComponent } from "../time-to-upload-evidences/time-to-upload-evidences.component";
import { CargoService } from "src/app/core/services/cargo.service";
import { DateManager } from "src/app/core/managers/date.manager";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { ImageByAddress } from "src/app/core/interfaces/imageByAddress";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";

declare var google: any;

@Component({
  selector: "app-cargo-control-tracking",
  templateUrl: "./cargo-control-tracking.component.html",
  styleUrls: ["./cargo-control-tracking.component.scss"],
  providers: [CargoControlCargoTrackingService, CurrencyPipe, CargoManager],
})
export class CargoControlTrackingComponent implements OnInit, OnDestroy {
  @Input() driver: Driver;
  @Input() vehicle: Vehicle;
  @Input() typeAddress: string;
  @Input() parent: number;
  @Input() index: number;
  @Input() address: AddressCargo;
  @Input() cargo: Cargo;
  @Output() loadedEvidences: EventEmitter<any> = new EventEmitter();
  addressList = {};
  @ViewChild(ChangeStateGuideComponent, { static: false })
  changeStateGuideComponent: ChangeStateGuideComponent;
  permission = Permission;
  showEvidenceDownloadWithoutFinishCargo: boolean = false;
  allowedToFinish: boolean = false;
  showBtnEndCargo: boolean = false;
  private subscriptions: Array<Subscription> = [];
  evidenceRemoved: boolean = false;
  showBtnWithoutEvidence: boolean = false;
  changeState: boolean = true;
  secondsLeft = null;
  timeLeft = null;

  constructor(
    public dialog: MatDialog,
    public utils: Utils,
    private permissionRole: PermissionRole,
    private snackBarService: SnackBarService,
    private dialogs: Dialog,
    private companyManager: CompanyManager,
    private currencyPipe: CurrencyPipe,
    private cargoManager: CargoManager,
    private cargoEvidenceService: FreightForwarderEvidenceService,
    private googleService: GoogleService,
    private cargoService: CargoService,
  ) { }
  /**
* This method launch the methods that evaluates the evidences and the addresses.
*/
  ngOnInit() {
    this.showEndAddress();
    this.loadEvidenceState();
  }
  /**
* This method unsuscribe all the suscriptions inside the class.
*/
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
  /**
* This method checks if the current address have loaded evidences or not.
*/
  loadEvidenceState() {
    this.cargoEvidenceService
      .getImageByAddress(
        this.cargo.id,
        this.address.address,
        this.address.id,
        this.parent
      )
      .toPromise()
      .then((data: ImageByAddress) => {
        if (data && data.imageByAddressFingerprint && data.imageByAddressFingerprint.length) this.showEvidenceDownloadWithoutFinishCargo = true;
        else this.showEvidenceDownloadWithoutFinishCargo = false;
      })
      .catch((error) => {
        console.error(error);
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      });
  }
  /**
  * This method evaluates the parameter and open a dialog to put an advance percentage or simply upload evidences.
  * @param {number} i (number) is the option could be: 0 or 1.
  */
  onSelectOption(i: number) {
    if (this.companyManager.getOnlyDriverByCompanyNit(this.cargo.idCompany)) {
      switch (i) {
        case 0:
          if (this.typeAddress === "Origin" && this.index == 0) {
            this.openDialogNewAdvancePercentage();
          } else {
            this.uploadEvidences();
          }
          break;
        case 1:
          this.evidenceRemoved = true;
          this.openDialogEvidences();
          break;
      }
    } else {
      this.dialogs.openDialog({
        title:
          "Upss esta compañia esta restringida para simular las accciones del Conductor",
        iconError: true,
        hideBtnConfirm: true,
      }).then().catch(err => err);
    }
  }
  /**
  * This method evaluates the conditions to upload evidences.
  */
  private uploadEvidences(): void {
    if (
      this.isValidDate() ||
      this.utils.isEmpty(this.cargoManager.getTypeCargo(this.cargo)) ||
      this.cargoManager.getTypeCargo(this.cargo) === "Urbana" ||
      this.permissionRole.hasPermission(Permission.cargo.module, Permission.cargo.earlyUploadEvidences)
    ) {
      this.openDialogEvidences(!this.showEvidenceDownloadWithoutFinishCargo, this.parent, this.index);
    } else {
      const config: MatDialogConfig = {
        data: {
          cargo: this.cargo,
          maxHeight: ModalEnum.MAX_HEIGHT,
          height: ModalEnum.MEDIUM_HEIGHT,
          width: ModalEnum.SMALL_WIDTH,
          maxWidth: ModalEnum.MAX_WIDTH,
          disableClose: true
        }
      };
      this.dialog
        .open(TimeToUploadEvidencesComponent, config)
        .afterClosed()
        .subscribe(
          (secondsLeft) => {
            if (CargoService.secondsLeftToUploadEvidences === null) {
              CargoService.secondsLeftToUploadEvidences = secondsLeft;
              CargoService.cron();
            }
          }
        );
    }
  }

  /**
  * This method open a dialog window that allows to change the percentage of the advance of the load.
  */
  public openDialogNewAdvancePercentage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      viewType: !this.isLastAddress() ? "viewAdvance" : null,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(
      CargoAdvancePercentageComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (this.isLastAddress() && result.state) {
        this.cargo.shippingCost = result.cargo.shippingCost;
        if (this.cargo.shippingCost.valueAdvance > 0) {
          this.dialogs.openDialog({
            title:
              "El Anticipo se ha actualizado, debe esperar a que se realice el pago para poder finalizar la carga",
            icon: true,
            hideBtnConfirm: true,
          }).then().catch(err => err);
        } else {
          this.onSelectOption(0);
        }
      } else {
        if (result && result.state) {
          if (this.showEvidenceDownloadWithoutFinishCargo) {
            this.openDialogEvidences();
          } else {
            this.openDialogEvidences(true, this.parent, this.index);
          }
        }
      }
    });
  }
  /**
  * This method set the idtracking of the addresses.
  */
  showEndAddress() {
    const addressOrigin =
      this.cargo.cargoFeature.uploadDownload.origin.addresses.map((address) => {
        address["idTracking"] = "o-" + address.id;
        return address;
      });
    let addressDestination = [];
    let addressList = [];
    this.addressList = {};

    this.cargo.cargoFeature.uploadDownload.destination.map((destination, i) => {
      const addressListDestination = destination.addresses.map((address) => {
        address["idTracking"] = destination.id + "-" + address.id;
        return address;
      });
      addressDestination = addressDestination.concat(addressListDestination);
    });
    addressList = addressOrigin.concat(addressDestination);
    addressList = addressList
      .filter((address) => {
        return (
          address.state !== "Pickup load" && address.state !== "Cargo unloaded"
        );
      })
      .map((address, i) => {
        if (i === 0) {
          this.addressList[address.idTracking.toString()] = address;
        }
        return address;
      });
  }
  /**
  * This method emit to parent the confirmation of the evidences loaded.
  * @param {Cargo} $event (Cargo) is the information of the load.
  */
  onLoadedEvidences($event: Cargo) {
    this.loadedEvidences.emit($event);
  }
  /**
* This method open a dialog window to add the evidences.
* @param {boolean} addEvidency (boolean) indicates if need to add evidence or only see the evidence.
* @param {number} paren (number) is the index of the destination if the destination exists.
* @param {number} index (number) is the index of the address if the address exists.
*/
  openDialogEvidences(addEvidency?: boolean, paren?: number, index?: number) {
    let params: any = {
      cargo: this.cargo,
      index: this.index,
      parent: this.parent,
      typeAddress: this.typeAddress,
      address: this.address,
      showEndCargoBtn: this.showBtnEndCargo,
      changeState: this.changeState,
      evidenceRemoved: this.evidenceRemoved,
      showBtnWithoutEvidence: this.showBtnWithoutEvidence,
      isLastAddress: this.isLastAddress(),
      driver: this.driver,
      vehicle: this.vehicle
    };
    if (this.utils.isDefined(addEvidency)) {
      params.addEvidency = addEvidency;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = params;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(CargoEvidenceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      const saved = !!(result && result.state);
      this.showEvidenceDownloadWithoutFinishCargo = saved;
      if (saved) {
        const cargo: Cargo = result.data as Cargo;
        this.loadedEvidences.emit(cargo);
      }
    });
  }
  /**
  * This method check if the duration of the load match with the stimated initial time calculated.
  * @returns {boolean} (boolean) returns if the stimated time is correct or not.
  */
  isValidDate() {
    if (
      !this.utils.isEmpty(
        this.cargo
          .cargoFeature
          .uploadDownload
          .origin
          .addresses[0]
          .durationTime
          .endDate
      )
    ) {
      const date = this.cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate;
      const formatDate: any = DateManager.stringToDate(date, "YYYY-MM-DD HH:mm ZZ");
      const formatCurrentDate: any = new Date();
      const secondsCalculate = Math.abs(formatDate - formatCurrentDate) / 1000;
      const estimatedTime = this.cargo.estimatedTime;
      const validTime = estimatedTime * (4 / 5);
      return secondsCalculate >= validTime;
    }
    return true;
  }
  /**
  * This method allows to see the button to upload evidences
  * @returns {boolean} (boolean) returns if the user can upload evidences or not.
  */
  public showBtnUploadEvidences(): boolean {
    return (
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadEvidences
      ) ||
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadExtraDocumentsCargo
      )
    );
  }
  /**
* This method checks if the address is the last address.
* @returns {boolean} (boolean) returns if is o not the last address.
*/
  private isLastAddress(): boolean {
    const destination =
      this.cargo.cargoFeature.uploadDownload.destination.filter(
        (destination) => destination.id === this.parent
      );
    if (destination.length) {
      return (
        destination[0].addresses[destination[0].addresses.length - 1].id ===
        this.index.toString()
      );
    }
    return false;
  }

  /**
  * This method evaluate the current state and the state that the user want to set and evaluate the conditions of this change.
  * @param {string} step (string) is the next step that the user want to set.
  */
  public nextStep(step: string) {
    switch (step) {
      case "ARRIVED_TO_PICKUP_CARGO":
        this.subscriptions.push(
          this.isItPossibleToArriveOnTime.subscribe((valid) => {
            if (valid) this.setStatusAddress("Arrived to pickup cargo");
          })
        );
        break;
      case "PICKUP_LOAD":
        this.belowSicetac(this.cargo)
        break;
      case "SHOW_EVIDENCE":
        if (this.isLastAddress()) {
          this.showBtnWithoutEvidence = false;
          this.showBtnEndCargo = false;
          this.changeState = false;
        }
        this.onSelectOption(1);
        break;
      case "DESTINATION_ARRIVED":
        const hasManifestError: boolean = this.cargo.manifestError && this.cargo.manifestError.error && this.cargo.ministryError;
        const permissionSpecialLicensePlate = this.permissionRole.hasPermission(
          this.permission.cargo.module,
          this.permission.cargo.specialLicensePlate
        );
        const isSpecialLicensePlate: boolean = permissionSpecialLicensePlate && this.cargo.licensePlate === "C0N111";

        if (hasManifestError && !isSpecialLicensePlate) {
          this.snackBarService.openSnackBar(FormMessages.MANIFEST_ERROR, undefined, 'alert');
          return;
        }
        this.subscriptions.push(
          this.isItPossibleToArriveOnTime.subscribe((valid) => {
            if (valid) this.setStatusAddress("Destination arrived");
          })
        );
        break;
      case "CARGO_UNLOADED":
        if (
          this.permissionRole.hasPermission(
            this.permission.cargo.module,
            this.permission.cargo.uploadEvidences
          ) ||
          this.permissionRole.hasPermission(
            this.permission.cargo.module,
            this.permission.cargo.uploadExtraDocumentsCargo
          )
        ) {
          this.showBtnWithoutEvidence = true;
        }
        this.onSelectOption(0);
        break;
      case "DRIVER_HAS_FINISHED_SERVICE":
        this.showBtnEndCargo = true;
        this.changeState = false;
        if (
          this.permissionRole.hasPermission(
            this.permission.cargo.module,
            this.permission.cargo.uploadEvidences
          ) ||
          this.permissionRole.hasPermission(
            this.permission.cargo.module,
            this.permission.cargo.uploadExtraDocumentsCargo
          )
        ) {
          this.showBtnWithoutEvidence = true;
        }
        this.onSelectOption(0);
        break;
      case "END_SERVICE":
        break;
    }
  }

  /**
  * This method consumes the service that change the status of the address.
  * @param {string} status (string) is the status that wanted to change.
  */
  setStatusAddress(status: string) {
    let data = {
      idCargo: this.cargo.id,
      addressId: this.address.id,
      address: this.address.address,
      type: this.typeAddress,
      imageTruck: [],
      imageDocument: [],
    };

    if (this.typeAddress.toLowerCase() === "destination")
      data["destinationId"] = this.address.idTracking.split("-")[0];

    this.cargoEvidenceService.setStateAddressTracking(data, status).subscribe(
      (success: Cargo) => {
        this.snackBarService.openSnackBar(
          "Se ha modificado el estado de la ruta"
        );
        this.onLoadedEvidences(success);
      },
      (error) => {
        console.error(error);
        this.snackBarService.openSnackBar(
          "Ha ocurrido un error al modificar el estado de la ruta",
          undefined,
          "error"
        );
      }
    );
  }

  // Origin
  // ARRIVED_TO_PICKUP_CARGO
  public get showDriverArrivedToPickupCargo(): boolean {
    const isOrigin = this.typeAddress.toLowerCase() == "origin";
    return (
      this.address.state === undefined &&
      isOrigin &&
      (this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadEvidences
      ) ||
        this.permissionRole.hasPermission(
          this.permission.cargo.module,
          this.permission.cargo.uploadExtraDocumentsCargo
        ))
    );
  }

  // PICKUP_LOAD
  public get showDriverPickupLoadButton(): boolean {
    const isOrigin = this.typeAddress.toLowerCase() == "origin";
    return (
      this.address.state === "Arrived to pickup cargo" &&
      isOrigin &&
      (this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadEvidences
      ) ||
        this.permissionRole.hasPermission(
          this.permission.cargo.module,
          this.permission.cargo.uploadExtraDocumentsCargo
        ))
    );
  }

  // Destination
  // DESTINATION_ARRIVED
  public get showDriverArrivedToDownloadCargo(): boolean {
    const isDestination = this.typeAddress.toLowerCase() == "destination";
    return (
      this.address.state === undefined &&
      isDestination &&
      (this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadEvidences
      ) ||
        this.permissionRole.hasPermission(
          this.permission.cargo.module,
          this.permission.cargo.uploadExtraDocumentsCargo
        ))
    );
  }

  // CARGO_UNLOADED
  public get showDriverDownloadedButton(): boolean {
    const isDestination = this.typeAddress.toLowerCase() == "destination";
    this.allowedToFinish = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.finishCargo
    );

    const allowToUploadEvidences =
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadEvidences
      ) ||
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadExtraDocumentsCargo
      );
    if (this.showEvidenceDownloadWithoutFinishCargo) {
      return (
        this.address.state === "Destination arrived" &&
        isDestination &&
        this.allowedToFinish &&
        this.isLastAddress()
      );
    } else {
      if (this.allowedToFinish && this.isLastAddress()) {
        return this.address.state === "Destination arrived" && isDestination;
      } else
        return (
          this.address.state === "Destination arrived" &&
          isDestination &&
          !this.isLastAddress() &&
          allowToUploadEvidences
        );
    }
  }

  public get showPickupEvidenceButton(): boolean {
    const isOrigin = this.typeAddress.toLowerCase() == "origin";
    return (
      (this.address.state === "Pickup load" && isOrigin) ||
      (this.address.state === "Destination arrived" &&
        !isOrigin &&
        this.showEvidenceDownloadWithoutFinishCargo) ||
      (this.address.state === "Cargo unloaded" && !isOrigin) ||
      (this.address.state === "Destination arrived" && this.isLastAddress()) ||
      (this.address.state === "Driver has finished service" && this.isLastAddress())
    );
  }

  public get showDriverFinishedService(): boolean {
    const isDestination = this.typeAddress.toLowerCase() == "destination";
    this.allowedToFinish = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.finishCargo
    );
    return (
      this.cargo.stateTracking === "Driver has finished service" && isDestination
      && this.address.state === "Cargo unloaded" && this.isLastAddress() && this.allowedToFinish
    );
  }

  public get permissionTodownload(): boolean {
    return (
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadEvidences
      ) ||
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.uploadExtraDocumentsCargo
      ) ||
      this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.finishCargo
      )
    );
  }

  /**
  * This method return all the addresses related with origin and destination of the load.
  * @returns {Array<AddressCargo>} (Array<AddressCargo>) returns the array of addresses.
  */
  public getAddresses(): Array<AddressCargo> {
    const origins = this.cargo.cargoFeature.uploadDownload.origin;
    const destinations = this.cargo.cargoFeature.uploadDownload.destination;
    const allAddresses = [];
    for (const i in origins.addresses) allAddresses.push(origins.addresses[i]);
    for (const destination of destinations)
      for (const i in destination.addresses) {
        allAddresses.push(destination.addresses[i]);
      }
    return allAddresses;
  }

  public isItPossibleToArriveOnTime: Observable<boolean> = new Observable(
    (observer) => {
      const addresses = this.getAddresses();
      let currentIndex = addresses.indexOf(this.address);

      let duration = 0;
      let min = 0;

      if (currentIndex > 0) {
        this.googleService.calculateMinRouteTime(
          addresses[currentIndex].location,
          addresses[currentIndex - 1].location,
          (response, status) => {
            if (status !== "OK") {
              observer.error(status);
              return;
            }

            const times: Array<number> = [];
            const distance: Array<number> = [];
            const description: Object = {};

            response.routes.forEach((route) => {
              route.legs.forEach((leg) => {
                times.push(leg.duration.value);
                distance.push(leg.distance.value);

                description[`${leg.duration.value}`] = {
                  duration: leg.duration.text,
                  distance: leg.distance.text,
                };
              });
            });
            min = (4 / 5) * Math.min(...times); // Minimo tiempo estimado en segundos
            duration = DateManager.dateDiff(new Date(), null, addresses[currentIndex - 1].durationTime.endDate, "YYYY-MM-DD HH:mm ZZ", 'seconds');
            const isValid: boolean = !(duration - min < 0);
            observer.next(isValid);
            observer.complete();
            return;
          }
        );
      }
      observer.next(true);
    }
  );

  public belowSicetac(cargo: Cargo) {
    if (cargo.freightValueApprovedBelowSicetac || !cargo.belowSicetac) {
      this.onSelectOption(0);
    } else {
      const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: true,
      });
      const min = formatter.format(cargo.minimumApprovedValueSicetac);
      if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
        dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
        dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
          dialogConfig.data = {
            title: '¿Desea aprobar el flete por debajo del SICETAC?',
            descriptionHTML: `El flete mínimo permitido por SICETAC es de <strong>${min}</strong>, si desea ajustarlo por favor realice una bonificación para ajustarse al valor mínimo permitido.`,
            closeModal: true,
            isTracking: true,
          };
          const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.state) {
              this.cargoService.approvedBelowSiceTac(cargo.id).subscribe(
                () => {
                  this.openConfirmBelowSicetac();
                  this.onSelectOption(0);
                },
                (error) => {
                  this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
                  this.onSelectOption(0);
                }
              );
            } else {
              this.onSelectOption(0);
            }
          })
        }
      } else {
        this.snackBarService.openSnackBar(`El flete está por debajo del SICETAC, el flete mínimo es de ${min}`, undefined, 'error');
        this.onSelectOption(0);
      }
    }
  }

  openConfirmBelowSicetac() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: `Aprobación exitosa`,
      icon: true,
      description: `Se ha aprobado la carga por un flete que está por debajo del valor mínimo permitido por el SICETAC`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    this.dialog.open(DialogComponent, dialogConfig);
  }
}
