<div class="container">
  <div id="mainFreightTypeLoad" class="col-md-12 mb-md-0 mb-12">
    <!-- Listado de tipos de viaje -->
    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          Selecciona un tipo de viaje
        </label>
      </div>
      <!-- Boxs -->
      <app-box-type-trip [options]="types"
        [tripSelectedName]="manualCreationCargoService.cargoForm.value.cargoModel.tripType.name"
        (emitToParent)="selectTrip($event)"></app-box-type-trip>
      <span class="separator-horizontal"></span>
      <div class="col-12 mb-md-0 mb-12">
        <label class="title"> Selecciona perfil de riesgo </label>
      </div>
      <mat-form-field class="col-md-6 col-xl-4" appearance="outline">
        <mat-label>Perfil de riesgo de la carga<span class="asterisk">*</span></mat-label>
        <mat-select (selectionChange)="onSelectRiskProfile($event)"
          [formControl]="manualCreationCargoService.formRiskProfile.get('name')">
          <mat-option *ngFor="let riskProfile of riskProfileName" [value]="riskProfile">
            {{riskProfile}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="manualCreationCargoService.formRiskProfile.get('name').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
      <span class="separator-horizontal"></span>
      <div class="col-12 mb-md-0 mb-12">
        <label class="title"> Etiquetas </label>
      </div>
      <div class="col-md-6 col-xl-4"
        *ngFor="let tag of manualCreationCargoService.cargoForm.get('labels')?.value; let i = index">
        <div class="tag-card">
          <p class="text-bold">{{tag}}</p>
          <i class="fas fa-times" (click)="clearTag(i)"></i>
        </div>
      </div>
      <mat-form-field class="col-md-6 col-xl-4" appearance="outline">
        <mat-label>Etiqueta (opcional)</mat-label>
        <mat-select [formControl]="tagControl" (selectionChange)="selectTag($event)">
          <mat-option *ngFor="let tag of tags" [value]="tag.name"
            [disabled]="manualCreationCargoService.cargoForm.get('labels')?.value?.includes(tag.name)">
            {{tag.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Button -->
      <div class="col-md-12 mb-md-0 mb-12">
        <div class="row">
          <div class="button-container justify-content-center mt-3 w-100">
            <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
            <button mat-raised-button class="bg-color-primary" (click)="nextStep()">Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>