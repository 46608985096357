<a class="nav-home" routerLink='/account/login'>
  Regresar
</a>
<div class="form-container justify-content-center align-items-center">
  <div class="password-container">
    <!-- Step one -->
    <div class="container-form" *ngIf="forgotPasswordSteps.stepActive == 1">
      <form [formGroup]="forgotPasswordForm" (submit)="onSubmit()">
        <div class="title-1">
          Recupera tu contraseña
        </div>
        <div class="separator-horizontal"></div>
        <div class="title-2 text-bold mb-2">
          Por favor ingresa tu correo
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Correo<span class="asterisk">*</span></mat-label>
          <input matInput type="email" id="inputUserCorporativeMail" placeholder="Ejm: micorreo@correo.com"
            formControlName="userCorporateMail">
          <mat-error *ngIf="forgotPasswordForm.get('userCorporateMail').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
          <mat-error *ngIf="forgotPasswordForm.get('userCorporateMail').errors?.pattern">
            {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
          </mat-error>
        </mat-form-field>
        <div class="button-container mt-3">
          <button mat-raised-button color="primary" class="bg-color-primary">Enviar</button>
        </div>
      </form>
    </div>

    <!-- Step two -->
    <div class="container-form" *ngIf="forgotPasswordSteps.stepActive == 2">
      <form>
        <div class="title-1">
          Recupera tu contraseña
        </div>
        <div class="separator-horizontal"></div>
        <div class="alert alert-info w-100 mt-2" role="alert">
          <b>* Recibiras un correo electrónico al correo registrado con URL para restablecer tu contraseña.</b>
        </div>
        <mat-card-content>
          <div class="resend">
            ¿No recibiste el correo? <a (click)="forgotPasswordSteps.stepActive = 1">Reenviar</a>
          </div>
        </mat-card-content>
      </form>
    </div>

  </div>

</div>