import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dshippers',
  templateUrl: './dshippers.component.html',
  styleUrls: ['./dshippers.component.scss'],
  providers: [AuthService]
})
export class DshippersComponent implements OnInit {

  public data = [
    {
      "title": "Envía tus cargas a todo el país",
      "information": "Ingresa toda la información de tu carga y recibe varias ofertas de transportistas de manera rápida y simple.",
      "icon": "/assets/images/dshippers/034-loupe (1).svg"
    },
    {
      "title": "Sigue tus envíos",
      "information": " Visualiza la trazabilidad de tu envío en tiempo real y conoce todas sus novedades.",
      "icon": "/assets/images/dshippers/018-navigator.svg"
    },
    {
      "title": "Administra la información",
      "information": "Encuentra toda la información de tus cargas y gestiona tus envíos actuales y futuros con una sola herramienta.",
      "icon": "/assets/images/dshippers/050-laptop.svg"
    },
    {
      "title": "Reduce los costos logísticos",
      "information": "Ahorra dinero en tus envíos con la reducción de fletes sin intermediarios en la cadena.",
      "icon": "/assets/images/dshippers/050-laptop.svg"
    }
  ];

  constructor(private _authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

}
